import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';

@Injectable({
  providedIn: 'root',
})
export class TransferenciaInativoService {
  private localStorage: LocalStorage = new LocalStorage();
  private headers = new HttpHeaders();
  private cabecalho: any;
  private filial: string;
  data: Date = new Date();
  mes: number = this.data.getMonth() + 1;
  ano: number = this.data.getFullYear();
  competencia: string = this.ano.toString() + this.mes.toString();
  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }
  url_acesso: any = this.acesso.validarUrl();
  buscarColaborador(matricula: number) {
    return this.http.get(`${this.url_acesso}cadastro-ativo-matricula/${matricula}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }
  buscarUsuario(matricula: number) {
    return this.http.get(
      this.url_acesso +
        `elogios-penalidades/usuario/${this.competencia}/${matricula}/${this.filial}`,
      { headers: this.cabecalho }
    );
  }
  buscarNumOrd(matricula: number | string) {
    return this.http.get(`${this.url_acesso}numord/${this.filial}/${matricula}`, {
      headers: this.cabecalho,
    });
  }

  buscarInativo(numord: string | number) {
    return this.http.get(`${this.url_acesso}transferencia-inativos/listar/${numord}`, {
      headers: this.cabecalho,
    });
  }
  buscarTiposAposentadoria() {
    return this.http.get(`${this.url_acesso}transferencia-inativos/listar-tipo-aposentadoria/`, {
      headers: this.cabecalho,
    });
  }
  buscarTipoProporcao(numord: string | number) {
    return this.http.get(`${this.url_acesso}transferencia-inativos/listar-proporcao/${numord}`, {
      headers: this.cabecalho,
    });
  }
  buscarClassificacaoAto() {
    return this.http.get(`${this.url_acesso}falta-ao-trabalho/listar-id-classificacao-ato`, {
      headers: this.cabecalho,
    });
  }
}
