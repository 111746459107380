import { Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
//import * as d3 from 'd3';



@Component({
  selector: 'app-scat',
  templateUrl: './scat.component.html',
  styleUrls: ['./scat.component.css']
})
export class ScatComponent implements OnInit {

  constructor() { }

  solicitacao: EChartsOption = {
      color: ['#44af69','#bf3100','#faca00'],

      // Global text styles
      textStyle: {
          fontFamily: 'Roboto, Arial, Verdana, sans-serif',
          fontSize: 13
      },

      // Chart animation duration
      animationDuration: 750,

      // Setup grid
      grid: {
          left: 0,
          right: 40,
          top: 35,
          bottom: 0,
          containLabel: true
      },

      // Add legend
      legend: {
          data: ['Em Andamento', 'Aguardando Andamento', 'Concluído'],
          itemHeight: 8,
          itemGap: 20
      },

      // Add tooltip
      tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.75)',
          padding: [10, 15],
          textStyle: {
              fontSize: 13,
              fontFamily: 'Roboto, sans-serif'
          }
      },

      // Horizontal axis
      xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: ['01/2019', '02/2019', '03/2019', '04/2019', '05/2019', '06/2019', '07/2019'],
          axisLabel: {
              color: '#333'
          },
          axisLine: {
              lineStyle: {
                  color: '#999'
              }
          },
          splitLine: {
              show: true,
              lineStyle: {
                  color: '#eee',
                  type: 'dashed'
              }
          }
      }],

      // Vertical axis
      yAxis: [{
          type: 'value',
          axisLabel: {
              color: '#333'
          },
          axisLine: {
              lineStyle: {
                  color: '#999'
              }
          },
          splitLine: {
              lineStyle: {
                  color: '#eee'
              }
          },
          splitArea: {
              show: true,
              areaStyle: {
                  color: ['rgba(250,250,250,0.1)', 'rgba(0,0,0,0.01)']
              }
          }
      }],

      // Add series
      series: [
          {
              name: 'Concluído',
              type: 'line',
              data: [10, 12, 21, 54, 260, 830, 710],
              areaStyle: {
                  /*normal: {
                      opacity: 0.25
                  }*/
              },
              smooth: true,
              symbolSize: 7,
              itemStyle: {
                  //normal: {
                  //    borderWidth: 2
                  //}
              }
          },
          {
              name: 'Aguardando Andamento',
              type: 'line',
              smooth: true,
              symbolSize: 7,
              itemStyle: {
                  //normal: {
                  //    borderWidth: 2
                  //}
              },
              areaStyle: {
                  //normal: {
                  //    opacity: 0.25
                  //}
              },
              data: [30, 182, 434, 791, 390, 30, 10]
          },
          {
              name: 'Em Andamento',
              type: 'line',
              smooth: true,
              symbolSize: 7,
              itemStyle: {
                  //normal: {
                  //    borderWidth: 2
                  //}
              },
              areaStyle: {
                  //normal: {
                  //    opacity: 0.25
                  //}
              },
              data: [1320, 1132, 601, 234, 120, 90, 20]
          }
      ]
  } 

  txEficiencia15: EChartsOption = {
      // Colors
    color: '#181818',

    // Global text styles
    textStyle: {
        fontFamily: 'Roboto, Arial, Verdana, sans-serif',
        fontSize: 13
    },

    // Add title
    title: {
        text: 'Taxa de Eficiência',
        subtext: 'Atendimentos em até 15 dias',
        left: 'center',
        textStyle: {
            fontSize: 16,
            fontWeight: 500
        },
        subtextStyle: {
            fontSize: 12
        }
    },

    // Add series
    series: [
        //{
        //    type: 'pie',
        //    radius: radius,
        //    //hoverAnimation: false,
        //    data: [
        //        {name: 'other', value: 40, label: labelBottom, itemStyle: backStyle},
        //        {name: 'Eficiência', value: 60, label: labelTop}
        //    ]
        //}
    ]
  };

  txEficiencia30: EChartsOption = {
    // Colors
    color: '#181818',

    // Global text styles
    textStyle: {
        fontFamily: 'Roboto, Arial, Verdana, sans-serif',
        fontSize: 13
    },

    // Add title
    title: {
        text: 'Taxa de Eficiência',
        subtext: 'Atendimentos em até 30 dias',
        left: 'center',
        textStyle: {
            fontSize: 16,
            fontWeight: 500
        },
        subtextStyle: {
            fontSize: 12
        }
    },

    // Add series
    series: [
        {
            //type: 'pie',
            //radius: radius,
            //hoverAnimation: false,
            //data: [
            //    {name: 'other', value: 15, label: labelBottom, itemStyle: backStyle},
            //    {name: 'Eficiência', value: 85, label: labelTop}
            //]
        }
    ]
  };

  qtdAtraso: EChartsOption = {
    // Colors
    color: '#faca00',

    // Global text styles
    textStyle: {
        fontFamily: 'Roboto, Arial, Verdana, sans-serif',
        fontSize: 13
    },

    // Add title
    title: {
        text: 'Quantidade de Solicitações',
        subtext: 'Em Atraso',
        left: 'center',
        textStyle: {
            fontSize: 16,
            fontWeight: 500
        },
        subtextStyle: {
            fontSize: 12
        }
    },

    // Add series
    series: [
        {
            //type: 'pie',
            //radius: radius,
            //hoverAnimation: false,
            //data: [
            //    {name: 'other', value: 15, label: labelBottom, itemStyle: backStyle},
            //    {name: 'Solicitações', value: 85, label: labelTop}
            //]
        }
    ]
  };

  qtdAguardando: EChartsOption = {
    // Colors
    color: '#faca00',

    // Global text styles
    textStyle: {
        fontFamily: 'Roboto, Arial, Verdana, sans-serif',
        fontSize: 13
    },

    // Add title
    title: {
        text: 'Quantidade de Solicitações',
        subtext: 'Aguardando Informação',
        left: 'center',
        textStyle: {
            fontSize: 16,
            fontWeight: 500
        },
        subtextStyle: {
            fontSize: 12
        }
    },

    // Add series
    series: [
        {
            //type: 'pie',
            //radius: radius,
            //hoverAnimation: false,
            //data: [
            //    {name: 'other', value: 15, label: labelBottom, itemStyle: backStyle},
            //    {name: 'Solicitações', value: 85, label: labelTop}
            //]
        }
    ]
  };
  
  download(evento:any){//event.path[4].childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0]
    const canva = evento.path[4].childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0];
    const img    = canva.toDataURL("image/png");

    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = 'grafico.jpg';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        a.remove()
      };
      xhr.open('GET', img); // This is to download the canvas Image
      xhr.send();
  }
  ngOnInit() {
    window.scrollTo(0, 0);
  }



}

const  radius = [52, 65];
const labelBottom = {
  color: '#333',
  show: true,
  position: 'center',
  formatter: function (params) {
      return '\n\n' + (100 - params.value) + '%'
  },
  fontWeight: 500,
  lineHeight: 35,
  rich: {
      a: {}
  },
  emphasis: {
      color: '#333'
  }
};

// Background item style
const backStyle = {
  color: '#eee',
  emphasis: {
      color: '#eee'
  }
};

// Top text label
const labelTop = {
  show: true,
  position: 'center',
  formatter: '{b}\n',
  fontSize: 15,
  lineHeight: 50,
  rich: {
      a: {}
  }
};
