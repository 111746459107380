<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <h4 class="text-white">Carregando...</h4>
</ngx-spinner>
<div class="row component">
  <div class="col-lg-12 col-xl-12 form-group" id="colaboradores">
    <div class="card">
      <div class="card-header pb-3 mb-4 border-bottom">
        <div class="form-group header-elements-inline">
          <h3 class="card-title">Listagem de Censos</h3>
          <div class="header-elements"></div>
        </div>
      </div>

      <div class="card-body">
        <div class="row px-2 pb-2">
          <div class="flex-grow-1">
            <h5 class="text-md-left font-weight-semibold">
              Perfis Encontrados: {{ retornarTamanhoListagem() }}
            </h5>
          </div>
          <div class="mx-auto d-print-none">
            <button
              class="btn btn-sm btn-outline-primary"
              id="openModalButton"
              data-toggle="modal"
              data-target="#filtroModal"
              data-backdrop="static"
              data-keyboard="false"
              data-backdrop="static"
            >
              <i class="fa fa-filter pr-2"></i>
              <span>Filtrar</span>
            </button>
            <button
              class="btn btn-sm btn-outline-primary ml-2"
              (click)="exportAsXLSX()"
            >
              <i class="far fas fa-file-excel pr-2" style="font-size: 15px"></i>
              <span>Exportar Excel</span>
            </button>
            <button
              class="btn btn-sm btn-outline-primary ml-2"
              (click)="exportAsPDF()"
            >
              <i
                class="far far fas fa-file-pdf pr-1"
                style="font-size: 15px"
              ></i>
              <span>Exportar PDF</span>
            </button>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-lg-12 form-group text-center">
            <div class="table-responsive table-sm">
              <table class="table table-striped table-hover mb-0">
                <thead class="sticky-top bg-white border-bottom">
                  <tr>
                    <th class="text-left">
                      Tipo
                      <a
                        (click)="ordenarPorTipo()"
                        class="btn-link text-primary ml-1 d-print-none"
                        title="Ordenar por ordem alfabética"
                      >
                        <i
                          class="fa"
                          [class]="
                            ordemTipo == 0
                              ? 'fa-sort'
                              : ordemTipo == 1
                              ? 'fa-arrow-down'
                              : 'fa-arrow-up'
                          "
                        ></i>
                      </a>
                    </th>
                    <th class="d-none d-print-table-cell">Informações</th>
                    <th class="d-none d-print-table-cell">Filiais</th>
                    <th class="d-none d-print-table-cell">Datas</th>
                    <th class="esconder-mobile col-2 d-print-none">
                      Data início
                      <a
                        (click)="ordemPorDataInicio()"
                        class="btn-link text-primary ml-1 d-print-none"
                        title="Ordenar por ordem alfabética"
                      >
                        <i
                          class="fa"
                          [class]="
                            ordemDataInicio == 0
                              ? 'fa-sort'
                              : ordemDataInicio == 1
                              ? 'fa-arrow-down'
                              : 'fa-arrow-up'
                          "
                        ></i>
                      </a>
                    </th>
                    <th class="d-print-none">
                      Data Final
                      <a
                        class="btn-link text-primary ml-1 d-print-none"
                        title="Ordenar por ordem alfabética"
                        (click)="ordemPorDataFim()"
                      >
                        <i
                          class="fa"
                          [class]="
                            ordemDataFim == 0
                              ? 'fa-sort'
                              : ordemDataFim == 1
                              ? 'fa-arrow-down'
                              : 'fa-arrow-up'
                          "
                        ></i>
                      </a>
                    </th>
                    <th>
                      Avanço
                      <a
                        (click)="ordemPorAvanco()"
                        class="btn-link text-primary ml-1 d-print-none"
                        title="Ordenar avanço"
                      >
                        <i
                          class="fa"
                          [class]="
                            ordemAvanco == 0
                              ? 'fa-sort'
                              : ordemAvanco == 1
                              ? 'fa-arrow-up'
                              : 'fa-arrow-down'
                          "
                        ></i>
                      </a>
                    </th>
                    <th>
                      Total <span class="d-print-none"> Colaboradores </span>
                      <a
                        (click)="ordemPorColab()"
                        class="btn-link text-primary ml-1 d-print-none"
                        title="Ordenar colaboradores"
                      >
                        <i
                          class="fa"
                          [class]="
                            ordemColab == 0
                              ? 'fa-sort'
                              : ordemColab == 1
                              ? 'fa-arrow-down'
                              : 'fa-arrow-up'
                          "
                        ></i>
                      </a>
                    </th>
                    <th class="d-print-none">Ações</th>
                    <th class="d-none d-print-table-cell">Criado por</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let item of listaFiltrada"
                    style="cursor: pointer"
                  >
                    <td
                      class="text-left"
                      data-toggle="modal"
                      data-target="#modalMaisAcoes"
                      (click)="selecionarItem(item)"
                    >
                      {{ item.tipo }}
                    </td>
                    <td class="d-none d-print-table-cell">
                      <ul>
                        <li
                          *ngFor="let item of item.informacao"
                          class="text-left"
                        >
                          {{ item }}
                        </li>
                      </ul>
                    </td>
                    <td class="d-none d-print-table-cell">
                      <ul>
                        <li
                          *ngFor="let item of item.filial"
                          class="text-left lista-filial"
                        >
                          {{ item.Nome }}
                        </li>
                      </ul>
                    </td>
                    <td class="d-none d-print-table-cell">
                      <div class="d-flex flex-column">
                        <div class="d-flex flex-column p-4 border-bottom">
                          <div><b> Data início</b></div>
                          <div>{{ item.dataInicio }}</div>
                        </div>
                        <div class="d-flex flex-column p-4">
                          <span> <b> Data Fim </b> </span>
                          <span>{{ item.dataFim }}</span>
                        </div>
                      </div>
                    </td>
                    <td
                      data-toggle="modal"
                      data-target="#modalMaisAcoes"
                      (click)="selecionarItem(item)"
                      class="d-print-none"
                    >
                      {{ item.dataInicio }}
                    </td>
                    <td
                      data-toggle="modal"
                      data-target="#modalMaisAcoes"
                      (click)="selecionarItem(item)"
                      class="d-print-none"
                    >
                      {{ item.dataFim }}
                    </td>
                    <td
                      data-toggle="modal"
                      data-target="#modalMaisAcoes"
                      (click)="selecionarItem(item)"
                    >
                      {{ item.avanco }}
                    </td>
                    <td
                      data-toggle="modal"
                      data-target="#modalMaisAcoes"
                      (click)="selecionarItem(item)"
                    >
                      {{ item.colaboradores }}
                    </td>
                    <td class="d-print-none">
                      <button
                        class="btn btn-sm btn-outline-info mx-2"
                        data-toggle="modal"
                        data-target="#infoModal"
                        (click)="selecionarItem(item)"
                      >
                        <i class="fa-solid fa-circle-info"></i>
                      </button>
                      <button
                        class="btn btn-sm btn-outline-danger"
                        (click)="excluirCenso()"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                    <td class="d-none d-print-table-cell">{{ item.criado }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- modal de filtros -->
  <div class="modal" id="filtroModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="container">
            <div class="row align-items-start">
              <h5 class="modal-title">Filtros</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="row pt-1">
              <button
                class="btn btn-sm btn-outline-primary ml-auto"
                (click)="desmarcarTodos()"
              >
                <i class="fa fa-filter pr-2"></i>
                <span>Limpar filtros</span>
              </button>
              <button
                class="btn btn-sm btn-outline-primary ml-2 mr-auto mr-md-0"
                (click)="selecionarTodos()"
              >
                <i class="fa fa-download pr-2"></i>
                <span>Selecionar Todos</span>
              </button>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <form id="filtro-form">
            <fieldset class="form-group">
              <h6 class="border-bottom py-2 mb-2">Tipos</h6>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="anual"
                  id="tipoAnual"
                />
                <label class="form-check-label" for="tipoAnual"> Anual </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="aniversario"
                  id="tipoAniversario"
                />
                <label class="form-check-label" for="tipoAniversario">
                  Aniversário
                </label>
              </div>
            </fieldset>
            <fieldset class="form-group">
              <h6 class="border-bottom py-2 mb-2">Situação</h6>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="concluida"
                  id="situacaoConcluida"
                />
                <label class="form-check-label" for="situacaoConcluida">
                  Concluído
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="andamento"
                  id="situacaoAndamento"
                />
                <label class="form-check-label" for="situacaoAndamento">
                  Em andamento
                </label>
              </div>
            </fieldset>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            aria-label="Close"
            (click)="habilitarFiltros()"
          >
            Buscar
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- modal info -->
  <div class="modal fade" id="infoModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div>
          <div class="modal-header border-bottom py-2 mt-2 px-3">
            <h5 class="modal-title" id="exampleModalLabel">
              Informações do Censo
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body px-3">
          <div>
            <h5>Informações</h5>
            <div class="d-flex flex-column g-1 pb-3 border-bottom">
              <p
                class="p-0 m-0"
                *ngFor="let item of censoSelecionado?.informacao"
              >
                {{ item }}
              </p>
            </div>
          </div>

          <div class="mt-3 pb-1 border-bottom">
            <h5>Datas</h5>
            <div class="row">
              <div class="form-group col-md-6">
                <label class="font-weight-semibold">Data Início</label>
                <input
                  class="form-control"
                  type="date"
                  disabled
                  value="{{ formatarDataInput(censoSelecionado?.dataInicio) }}"
                />
              </div>
              <div class="form-group col-md-6">
                <label class="font-weight-semibold">Data Fim</label>
                <input
                  class="form-control"
                  type="date"
                  disabled
                  value="{{ formatarDataInput(censoSelecionado?.dataFim) }}"
                />
              </div>
            </div>
          </div>
          <div class="py-3 border-bottom">
            <h5 class="mt-0">Filiais</h5>
            <div
              class="table-responsive"
              style="
                overflow-y: auto;
                max-height: 220px;
                -webkit-box-shadow: -1px -1px 15px -2px
                  rgba(184, 184, 184, 0.74);
                box-shadow: -1px -1px 15px -2px rgba(184, 184, 184, 0.74);
              "
            >
              <table class="table table-striped table-hover mb-0">
                <thead class="bg-teal-400">
                  <tr>
                    <th>Código</th>
                    <th>Nome</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of censoSelecionado?.filial">
                    <td>
                      {{ item.Codigo }}
                    </td>
                    <td>
                      {{ item.Nome }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="py-3">
            <h5>Informações adicionais</h5>
            <div class="d-flex flex-column g-1">
              <p class="p-0 m-0">Criado por: {{ censoSelecionado?.criado }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- modal mais ações -->
  <div class="modal" tabindex="-1" id="modalMaisAcoes">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-header pb-2 border-bottom">
          <h5 class="modal-title">
            Censo {{ censoSelecionado?.tipo }} ({{
              censoSelecionado?.dataInicio
            }}
            - {{ censoSelecionado?.dataFim }})
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
              <div class="mh-100">
                <i
                  class="fa fas fa-list-ul fa-x1 mr-2 text-info"
                  aria-hidden="true"
                ></i>
                <label
                  [ngStyle]="{ cursor: 'pointer' }"
                  data-dismiss="modal"
                  aria-label="Close"
                  (click)="irParaPendentes()"
                  class="w-50 h6 text-lg-left font-weight-semibold text-info"
                >
                  Colaboradores pendentes
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
