import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Data } from 'src/app/service/interfaces/Data';
import { CadastroComplementarService } from './cadastro-complementar.service';
import { ErroService } from 'src/app/service/erros/erro.service';

@Component({
  selector: 'app-cadastro-complementar',
  templateUrl: './cadastro-complementar.component.html',
  styleUrls: ['./cadastro-complementar.component.css'],
})
export class CadastroComplementarComponent implements OnInit {
  formCadastro: FormGroup;
  infoColaborador: InfoColaborador;
  listaPrevComplementar: any[];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private cadastroService: CadastroComplementarService,
    public validaErro: ErroService
  ) {}

  ngOnInit() {
    this.formCadastro = this.fb.group({
      dtFalecimento: [''],
      certidaoFalecimento: [''],
      prevComplementar: ['', Validators.required],
      processo: [''],
      processoJudicial: this.fb.group({
        adNoturno: ['', Validators.required],
        servExtr: ['', Validators.required],
      }),
      dtInicioTrabalho: [''],
      dtFinalTrabalho: [''],
    });
    this.infoColaborador = history.state.usuario;
    this.buscarDados();
    if (!this.infoColaborador) {
      this.router.navigate(['/colaboradores']);
    }
  }

  buscarDados(): void {
    this.spinner.show();
    const matriculaColaborador = this.infoColaborador.matricula;
    this.cadastroService.buscarDadosCadastro(matriculaColaborador).subscribe({
      next: (data: CadastroComplementar) => {
        this.spinner.hide();
        this.montarInformacoesForm(data);
      },
      error: (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      },
    });
  }

  submitCadastro(): void {
    if (this.formCadastro.invalid) {
      new AlertaModal('alerta', 'Atenção!', 'Preencha os campos corretamente.', 'p');
      this.formCadastro.markAllAsTouched();
    } else {
      this.atualizarDados();
    }
  }

  atualizarDados(): void {
    this.spinner.show();
    const objAtualizacao = this.montarObjCadastro();
    console.log(objAtualizacao);
    const matriculaColaborador = this.infoColaborador.matricula;
    this.cadastroService.atualizarCadastro(objAtualizacao, matriculaColaborador).subscribe({
      next: () => {
        this.buscarDados();
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'Cadastro atualizado com sucesso.', 'p');
      },
      error: (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      },
    });
  }

  montarObjCadastro(): CadastroComplementar {
    const dadosForm = this.formCadastro.getRawValue();

    return {
      DtFalecimento: new Data(dadosForm.dtFalecimento),
      CertidaoFalecimento: dadosForm.certidaoFalecimento,
      PrevidenciaComplementar: Number(dadosForm.prevComplementar),
      NumeroProcesso: dadosForm.processo,
      AdicionalNoturno: Number(dadosForm.processoJudicial.adNoturno),
      ServicoExtra: Number(dadosForm.processoJudicial.servExtr),
      DtInicioTrabalho: new Data(dadosForm.dtInicioTrabalho),
      DtFinalTrabalho: new Data(dadosForm.dtFinalTrabalho),
    };
  }

  // 0 falso 1 true

  montarInformacoesForm(cadastroComplementar: CadastroComplementar): void {
    console.log(cadastroComplementar.PrevidenciaComplementar);
    console.log(cadastroComplementar);
    this.formCadastro.setValue({
      dtFalecimento: this.formatarDataRecebida(cadastroComplementar.DtFalecimento.ToDate),
      certidaoFalecimento: cadastroComplementar.CertidaoFalecimento,
      prevComplementar: cadastroComplementar.PrevidenciaComplementar,
      processo: cadastroComplementar.NumeroProcesso,
      processoJudicial: {
        adNoturno: cadastroComplementar.AdicionalNoturno,
        servExtr: cadastroComplementar.ServicoExtra,
      },
      dtInicioTrabalho: this.formatarDataRecebida(cadastroComplementar.DtInicioTrabalho.ToDate),
      dtFinalTrabalho: this.formatarDataRecebida(cadastroComplementar.DtFinalTrabalho.ToDate),
    });
  }

  limparForm(): void {
    this.formCadastro.setValue({
      dtFalecimento: '',
      certidaoFalecimento: '',
      prevComplementar: '',
      processo: '',
      processoJudicial: this.fb.group({
        adNoturno: '',
        servExtr: '',
      }),
      dtInicioTrabalho: '',
      dtFinalTrabalho: '',
    });
  }

  formatarDataRecebida(data: string): string {
    if (data !== '') {
      const arrayData = data.split('/');
      const dataFormatada = `${arrayData[2]}-${arrayData[1]}-${arrayData[0]}`;
      return dataFormatada;
    } else {
      return '';
    }
  }
  // todasAsPropriedadesVazias(infoColaborador: InfoColaborador): boolean {
  //   const keys = Object.keys(infoColaborador);

  //   return keys.every((key) => infoColaborador[key] === '');
  // }
}

export interface InfoColaborador {
  cpf: string;
  nome: string;
  matricula: string;
  numOrdem: string;
  vinculo: any;
  situacao: Situacao;
}

export interface Situacao {
  Codigo: number;
  Descricao: string;
}

export interface CadastroComplementar {
  DtFalecimento: Data;
  CertidaoFalecimento: string;
  PrevidenciaComplementar: any;
  NumeroProcesso: string;
  AdicionalNoturno: number;
  ServicoExtra: number;
  DtInicioTrabalho: Data;
  DtFinalTrabalho: Data;
}
