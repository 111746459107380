import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AcessoService } from '../../service/acesso/acesso.service';
import { Anexo } from './anexo.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { Arquivo } from './anexo.component';

@Injectable({
  providedIn: 'root',
})
export class AnexoService {
  urlAcesso: string;
  headers = new HttpHeaders();
  filialAtual: string = '';
  cabecalho: any;
  localStorage: LocalStorage = new LocalStorage();

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filialAtual = this.localStorage.FilialAtual.toString();
      this.urlAcesso = this.acesso.validarUrl();
    }
  }

  buscarTipoArquivo(pagina: string) {
    return this.http.get(`${this.urlAcesso}uploadarquivo/${pagina}`, { headers: this.cabecalho });
  }

  salvarAnexo(anexo: Anexo[]) {
    return this.http.post(`${this.urlAcesso}anexo/`, anexo, { headers: this.cabecalho });
  }

  obterAnexoBase64(anexo: Arquivo) {
    return this.http.post(`${this.urlAcesso}anexos/obterb64`, anexo, { headers: this.cabecalho });
  }

  AlterarAnexo(anexo: Anexo) {
    return this.http.put(`${this.urlAcesso}uploadarquivo/alterardescricao`, anexo, {
      headers: this.cabecalho,
    });
  }

  excluirAnexo(anexo: Anexo) {
    return this.http.put(`${this.urlAcesso}uploadarquivo/deletararquivo-unico`, anexo, {
      headers: this.cabecalho,
    });
  }

  buscarNumorder(matricula: string) {
    return this.http.get(`${this.urlAcesso}numord/${this.filialAtual}/${matricula}/`, {
      headers: this.cabecalho,
    });
  }

  buscarAnexos(numOrdem: string, grupo: string, compChave: string) {
    let params = new HttpParams().set('compChave', compChave || 0).set('grupo', grupo);
    return this.http.get(`${this.urlAcesso}anexos/compchave/${this.filialAtual}/${numOrdem}`, {
      headers: this.cabecalho,
      params: params,
    });
  }

  buscarPendencias(numOrdem: string) {
    return this.http.get(
      `${this.urlAcesso}cadastro-publico/anexos-marcados-obter/${this.filialAtual}/${numOrdem}`,
      { headers: this.cabecalho }
    );
  }
}
