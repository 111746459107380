import {Component, Input} from '@angular/core';
import {Cadastro} from 'src/app/service/classes/cadastro/cadastro';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {ErroService} from 'src/app/service/erros/erro.service';
import {Observacao, ObservacoesDiversasComponent} from '../observacoes-diversas.component';
import {ObservacoesDiversasService} from './../observacoes-diversas.service';
import Swal from 'sweetalert';

@Component({
  selector: 'app-observacoes-diversas-cadastro',
  templateUrl: './observacoes-diversas-cadastro.component.html',
  styleUrls: ['./observacoes-diversas-cadastro.component.css'],
})
export class ObservacoesDiversasCadastroComponent {
  @Input() cadastro: boolean = true;
  @Input() colaborador: Cadastro = null;
  @Input() observacaoAtual: any = null;
  formObservacoes: FormGroup;
  formValues: any;

  constructor(
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private validaErro: ErroService,
    private observacaoService: ObservacoesDiversasService,
    private observacoesComponente: ObservacoesDiversasComponent
  ) {
    this.formObservacoes = this.fb.group({
      Data: ['', Validators.required],
      Observacao: ['', Validators.required],
    });
    this.formValues = this.formObservacoes.value;
  }

  limparForm() {
    this.formObservacoes.reset();
    this.formObservacoes.patchValue(this.formValues);
  }

  setarObservacaoExistente(observacao: Observacao) {
    //obrigatórios
    this.formObservacoes.get('Data').setValue(this.formatarDataHifen(observacao.dtcseq));
    this.formObservacoes.get('Data').disable();
    this.formObservacoes.get('Observacao').setValue(observacao.obs);
  }

  formatarDataHifen(data: string): string {
    let ano = data.slice(0, 4);
    let mes = data.slice(4, 6);
    let dia = data.slice(6, 8);

    return `${ano}-${mes}-${dia}`;
  }

  montarObservacao() {
    let dataAtual = new Date();
    let ano = dataAtual.getFullYear().toString();
    let mes = dataAtual.getMonth().toString();
    if (mes.length == 1) {
      mes = '0' + mes;
    }
    let dia = dataAtual.getDay().toString();
    if (dia.length == 1) {
      dia = '0' + dia;
    }
    let hora = dataAtual.getHours().toString();
    if (hora.length == 1) {
      hora = '0' + hora;
    }
    let minuto = dataAtual.getMinutes().toString();
    if (minuto.length == 1) {
      minuto = '0' + minuto;
    }
    console.log('get date data atual', ano + mes + dia);
    console.log('get time data atual', hora + minuto);

    let observacaoMontada: ObservacaoEnvio = {
      Sigla: 'FAPP',
      Empfil: parseInt(this.colaborador.Filial.Codigo),
      Mat: parseInt(this.colaborador.Matricula),
      Tipo: '014',
      CompChave: '',
      DTCSeq: this.formObservacoes.get('Data').value.replace(/-/g, ''),
      Obs: this.formObservacoes.get('Observacao').value,
      DataRet: ano + mes + dia,
      HoraRet: hora + minuto,
      CodCad: '',
    };
    console.log(observacaoMontada);
    return observacaoMontada;
  }

  lancarObservacao(observacao?: Observacao) {
    this.spinner.show();
    this.formObservacoes.markAllAsTouched();
    if (this.formObservacoes.valid) {
      let botaoFechar = document.getElementById('botaoFechar');
      console.log(this.formObservacoes);
      let observacaoLancar = this.montarObservacao();
      if (observacao) {
        this.observacaoService.atualizarObservacao(observacaoLancar).subscribe(
          (data) => {
            Swal('Sucesso', 'Observação atualizada com sucesso.', 'success');
            this.observacoesComponente.buscarObservacoes();
            botaoFechar.click();
            this.spinner.hide();
          },
          (err) => {
            Swal('Erro', 'Não foi possível atualizar essa observação.', 'error');
            this.spinner.hide();
          }
        );
      } else {
        this.observacaoService.inserirObservacao(observacaoLancar).subscribe(
          (data) => {
            Swal('Sucesso', 'Observação lançada com sucesso.', 'success');
            this.observacoesComponente.buscarObservacoes();
            botaoFechar.click();
            this.spinner.hide();
          },
          (err) => {
            Swal('Erro', 'Não foi possível inserir essa observação.', 'error');
            this.spinner.hide();
          }
        );
      }
    } else {
      Swal('Atenção', 'Por favor, preencha todos os campos corretamente.', 'warning');
      this.spinner.hide();
    }
  }
}

export interface ObservacaoEnvio {
  Sigla: string;
  Empfil: number;
  Mat: number;
  Tipo: string;
  CompChave?: string;
  DTCSeq: string;
  Obs?: string;
  DataRet?: string;
  HoraRet?: string;
  CodCad: string;
}
