<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>


<div *ngIf="!telaFormulario" class="content d-flex justify-content-center align-items-center">
  <form class="login-form" #telainicial="ngForm" [formGroup]="formTelaInicial">
    <div class="card  bg-dark mb-0 text-ligth">
      <div class="card-body">
        <div class="text-center mb-3">
          <h5 class="mb-0">Tarefas</h5>
        </div>
        <div class="s-12 col-sm-12 col-md-12 col-lg-12 form-group">
          <label class="justify-content-start">Serviço</label>
          <select class="form-control" type="text" formControlName="codServico_form"
            [ngStyle]="((temErro || formTelaInicial.controls['codServico_form'].dirty) && formTelaInicial.controls['codServico_form'].status == 'INVALID') ? {'border-color':'red'} : null">
            <option value=""> Selecione...</option>
            <option *ngFor="let servico of listaServicos" value="{{servico.Codigo}}"> {{servico.Descricao}}</option>
          </select>
          <span class="form-text text-danger" *ngIf="formTelaInicial.get('codServico_form').invalid && temErro">
            Campo Obrigatório
          </span>
          <span class="form-text text-danger"
            *ngIf="formTelaInicial.value.codServico_form.length > 0 && formTelaInicial.get('codServico_form').hasError('codServicoError')">
            Código inválido
          </span>
        </div>

        <div class=" text-center">
          <button type="button" (click)="buscarTarefasPorCodigo()" class="btn btn-primary">Buscar</button>
        </div>
      </div>
    </div>
  </form>
</div>



<div class="card" *ngIf="telaFormulario">
  <div class="card-header">
    <h3 class="card-title" id="target"> Tarefas</h3>
  </div>

  <form [formGroup]="formTarefas" #tarefas='ngForm'>
    <div class="card-body">
      <div class="row">

        <div class="col-md-2 form-group">
          <label class="font-weight-semibold">Código</label>
          <input (keypress)='somenteNumero($event)' class="form-control" type="text" formControlName="codigoTarefa"
            placeholder="Código"
            [ngStyle]="((temErro || formTarefas.controls['codigoTarefa'].dirty) && formTarefas.controls['codigoTarefa'].status == 'INVALID') ? {'border-color':'red'} : null" />
          <span class="form-text text-danger"
            *ngIf="temErro && formTarefas.controls['codigoTarefa'].hasError('required')">
            Campo obrigatório
          </span>
        </div>

        <div class="col-md-6 form-group">
          <label class="font-weight-semibold">Descrição</label>
          <input class="form-control" type="text" formControlName="descricao" placeholder="Descrição"
            [ngStyle]="((temErro || formTarefas.controls['descricao'].dirty) && formTarefas.controls['descricao'].status == 'INVALID') ? {'border-color':'red'} : null" />
          <span class="form-text text-danger" *ngIf="temErro && formTarefas.controls['descricao'].hasError('required')">
            Campo obrigatório
          </span>
        </div>



        <div class="col-md-10 col-lg-2 form-group">
          <label class="justify-content-start">Tarefa Pai ?</label>
          <select class="form-control" type="text" formControlName="ePai" (change)="criarPai()"
            [ngStyle]="((temErro || formTarefas.controls['ePai'].dirty) && formTarefas.controls['ePai'].status == 'INVALID') ? {'border-color':'red'} : null">
            <option value=""> Selecione...</option>
            <option value="true"> Sim
            <option value="false"> Não
            </option>
          </select>
          <span class="form-text text-danger" *ngIf="temErro && formTarefas.controls['ePai'].hasError('required')">
          </span>
        </div>


        <div class="col-md-10 col-lg-2 form-group">
          <div class="text-center" style="white-space:nowrap">
            <label class="font-weight-semibold">Tarefa Pai</label><br>
            <button (click)="clickTarefaVinculada(formTarefas.value)" [disabled]='isPai' type="button"
              class="btn btn-secondary btn-circle" data-target="#configuracaoUsuario" data-toggle="modal"
              data-target="#configuracaoTarefa"
              style=" width: 55px;height: 33px;text-align: center;padding: 6px 0;font-size: 14px;line-height: 1.428571429;border-radius: 16px;">
              <i class="fa fa-sitemap fa-lg"></i>
            </button>
          </div>
        </div>

      </div>
    </div>

    <div class="card-footer text-right">
      <button *ngIf="formTarefas.valid ||  tarefaSelecionada != null" type="button" (click)="limparCampos()"
        class="btn btn-danger mr-2">Limpar</button>
      <button class="btn btn-primary" [disabled]="(!isPai && tarefaSelecionada == null) || formTarefas.invalid"
        (click)="submitCadastroTarefa()" type="button">Salvar</button>
    </div>

  </form>
</div>


<div class="card" *ngIf="telaFormulario">
  <div class="card-header">
    <h3 class="card-title"> Listagem de Tarefas</h3>
  </div>


  <div class="card-body">
    <div class="d-flex justify-content-center align-items-center">
    </div>
    <div class="form-group row">
      <div class="input-group col-lg-12" *ngIf="listaTodasTarefas != null && listaTodasTarefas.length > 0">
        <input (keyup)='onKeyUp($event.target)' type="text" class="form-control" name="searchText" value=""
          placeholder="Pesquisar Tarefas">
        <span class="input-group-append">
          <span class="input-group-text"><i class="fa fa-search"></i></span>
        </span>
      </div>
    </div>

    <div *ngIf="listaTodasTarefas != null && listaTodasTarefas.length > 0" class="table-responsive"
      style="max-height:45vh">
      <table class="table table-striped table-hover">
        <thead class="text-center">
          <tr class="text-center" style="white-space:nowrap">
            <th class="sticky-top bg-white">Excluir/Editar</th>
            <th class="sticky-top bg-white">Código</th>
            <th class="sticky-top bg-white">Código Pai</th>
            <th class="sticky-top bg-white">Descrição</th>
            <th class="sticky-top bg-white">Serviço</th>

            <!-- <th class="sticky-top bg-white">Usuários Da Tarefa</th> -->

          </tr>
        </thead>
        <tbody id="lista-tarefas">
          <tr *ngFor="let tarefa of listaTodasTarefas | filtrarTarefa: filter; let i = index" class="text-center"
            style="white-space:nowrap">
            <td>
              <div>
                <button class="btn btn-danger mr-2" (click)="deletar(tarefa, i)">
                  <i class="fa fa-trash"></i>
                </button>
                <button class="btn bg-teal-400" (click)="carregarTarefa(tarefa)">
                  <i class="fa fa-pencil"></i>
                </button>
              </div>
            </td>
            <td>{{tarefa.Codigo}}</td>
            <td>{{tarefa.CodigoPai}}</td>
            <td>{{tarefa.Descricao}}</td>
            <td>{{tarefa.CodigoServico}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="listaTarefas == [] || listaTarefas.length === 0"
      class="d-flex justify-content-center align-items-center" style="height:20vh">
      <h5 class="font-weight-semibold">Não há tarefa para ser exibida</h5>
    </div>
  </div>

  <!-- Modal Usuario Tarefa-->


  <!-- modalFilhos -->
  <div class="modal fade" id="configuracaoTarefa" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="justify-content-start mt-2">Tarefa Pai</h5>
          <button type="button" id="closeModal" (click)="cancelarModal()" class="close"
            data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <button type="button" id="closeModal2" class="close" data-dismiss="modal" aria-label="Close">
        </button>
        <div class="modal-body">
          <div class="text-center">
            <div class="col-12 table-responsive text-center">
              <div class="row form-group text-center">
                <table class="table table-striped table-hover mb-0 text-center">
                  <thead class="bg-teal-400">
                    <tr class="text-center" style="white-space:nowrap">
                      <th>Selecionar</th>
                      <th>Código</th>
                      <th>Nome</th>
                      <th>Qtd.Filho</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody *ngIf="listaTarefas == [] || listaTarefas.length === 0">
                    <tr class="text-center" style="white-space:nowrap">
                      <td></td>
                      <td>
                        <div class="align-items-right">
                          <h5 class="font-weight-semibold">Não há tarefa para ser exibida</h5>
                        </div>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>

                  <tbody *ngFor="let tarefa of listaTarefas; let i = index">
                    <tr class="text-center" style="white-space:nowrap">
                      <td>
                        <div class="form-check">
                          <input [disabled]="isPai" (change)="selecionarTarefa(tarefa, onChange($event.target))"
                            class="form-check-input" value="{{tarefa.Codigo}}" type="radio" name="tipo"
                            id="{{tarefa.Codigo}}">
                          <label class="form-check-label" for="flexRadioDefault1">
                          </label>
                        </div>
                      </td>
                      <td (click)='selecionarTipoTarefa(tarefa)'>{{tarefa.Codigo}}</td>
                      <td (click)='selecionarTipoTarefa(tarefa)'>{{tarefa.Descricao}}</td>
                      <td (click)='selecionarTipoTarefa(tarefa)'>{{contarFilhos(tarefa)}}</td>
                      <td (click)='selecionarTipoTarefa(tarefa)'><i class="fa fa-angle-right nl-3" aria-hidden="true"></i></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button *ngIf="habilitarCancelar" (click)="cancelarModal()" type="button" class="btn btn-danger"
            data-dismiss="modal" data-toggle="modal" data-target="#configuracaoTarefa">Cancelar</button>
          <button *ngIf="habilitarVoltar" (click)='voltarNivel(listaTarefas[0])' type="button"
            class="btn btn-danger">Voltar</button>

          <button *ngIf="habilitarSalvar" type="button" [disabled]="isPai || tarefaSelecionada ==  null "
            (click)="salvarTarefaPai()" class="btn btn-primary">Salvar</button>
        </div>
      </div>
    </div>
  </div>