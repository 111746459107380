import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AcessoService} from 'src/app/service/acesso/acesso.service';
import {LocalStorage} from 'src/app/service/classes/localstorage/LocalStorage';
import {Crypto} from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root',
})
export class CadastroAprendizService {
  localStorage: LocalStorage;
  urlAcesso: string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  empresaFilial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.empresaFilial = this.localStorage.FilialAtual.toString();
      this.urlAcesso = this.acesso.validarUrl();
    }
  }

  buscarParametros(empresaFilial: string) {
    return this.http.get(`${this.urlAcesso}complementacao-cadastral/parametros/${empresaFilial}`, {
      headers: this.cabecalho,
    });
  }

  salvarAprendiz(aprendiz: any, numeroOrdem: string) {
    return this.http.post(
      `${this.urlAcesso}colaborador/contrato-aprendiz/${numeroOrdem}`,
      aprendiz,
      {headers: this.cabecalho}
    );
  }

  editarAprendiz(aprendiz: any, numeroOrdem: string) {
    return this.http.put(
      `${this.urlAcesso}colaborador/contrato-aprendiz/${numeroOrdem}`,
      aprendiz,
      {headers: this.cabecalho}
    );
  }

  buscarSupervisor(matricula: string) {
    return this.http.get(
      `${this.urlAcesso}complementacao-cadastral/supervisor/${matricula}/${this.empresaFilial}`,
      {headers: this.cabecalho}
    );
  }

  buscarAprendiz(numeroOrdem: string) {
    return this.http.get(`${this.urlAcesso}colaborador/contratos-aprendiz/${numeroOrdem}`, {
      headers: this.cabecalho,
    });
  }
}
