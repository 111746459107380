import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root',
})
export class ProntuarioMedicoService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  url_acesso: string;
  cabecalho: any;
  filial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl();

    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);

      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  buscarColaboradorMatricula(matricula: string) {
    return this.http.get(`${this.url_acesso}cadastro-todos-matricula/${matricula}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarProntuario(matricula: number) {
    return this.http.get(`${this.url_acesso}prontuario-medico/listar/${this.filial}/${matricula}`, {
      headers: this.cabecalho,
    });
  }

  deletarProntuario(empfil: string, matricula: number, dtInsp: string) {
    return this.http.delete(
      `${this.url_acesso}prontuario-medico/excluir/${empfil}/${matricula}/${dtInsp}`,
      {
        headers: this.cabecalho,
      }
    );
  }

  downloadPdf(empfil: string, matricula: number, tipo: string) {
    return this.http.get(
      `${this.url_acesso}relatorios-pdf/download-pdf/${empfil}/${matricula}/${tipo}`,
      {
        headers: this.cabecalho,
      }
    );
  }


  inserirProntuario(prontuario: any) {
    return this.http.post(`${this.url_acesso}prontuario-medico/incluir`, prontuario, {
      headers: this.cabecalho,
    });
  }

  atualizarProntuario(prontuario: any) {
    console.log('payload', prontuario);
    return this.http.put(`${this.url_acesso}prontuario-medico/atualizar`, prontuario, {
      headers: this.cabecalho,
    });
  }

  buscarCodAfa() {
    return this.http.get(`${this.url_acesso}prontuario-medico/afastamentos/listar`, {
      headers: this.cabecalho,
    });
  }

  buscarCodMot() {
    return this.http.get(`${this.url_acesso}prontuario-medico/motivos/listar`, {
      headers: this.cabecalho,
    });
  }

  formatarDataBarra(data: string): string {
    if (!data || data.length !== 8) return data;
    const ano = data.substring(0, 4);
    const mes = data.substring(4, 6);
    const dia = data.substring(6, 8);

    return `${dia}/${mes}/${ano}`;
  }
}
