<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<header>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title font-weight-semibold" id="target">
        <a
          class="fa fa-arrow-left pr-3 text-dark btn"
          aria-hidden="true"
          routerLink="/colaboradores"
          style="cursor: pointer; bottom: 2px"
        ></a>
        Profissional da Educação
      </h3>
    </div>

    <div class="card-body row">
      <div class="form-group col-md-2">
        <label class="justify-content-start font-weight-semibold">Matricula </label>
        <input
          type="text"
          placeholder="Funcionario"
          class="form-control"
          disabled
          [value]="infoColaborador.matricula"
        />
      </div>

      <div class="form-group col-md-6">
        <label class="justify-content-start font-weight-semibold">Nome do Colaborador </label>
        <input
          type="text"
          placeholder="Funcionario"
          class="form-control"
          disabled
          [value]="infoColaborador.nome"
        />
      </div>
      <div class="form-group col-md-4">
        <label class="justify-content-start font-weight-semibold">Vínculo </label>
        <input
          type="text"
          placeholder="Funcionario"
          class="form-control"
          disabled
          [value]="infoColaborador.vinculo"
        />
      </div>
      <!-- <div class="form-group col-md-3">
        <label class="justify-content-start font-weight-semibold">Situação </label>
        <input
          type="text"
          placeholder="Funcionario"
          class="form-control"
          disabled
          [value]="infoColaborador.situacao.Codigo + ' - ' + infoColaborador.situacao.Descricao"
        />
      </div> -->
    </div>
  </div>
</header>
<main>
  <div class="card">
    <form class="card-body mt-2" [formGroup]="formCadastro">
      <div class="row">
        <fieldset class="col-md-12">
          <legend>
            <h6 class="font-weight-semibold">Dados do Profissional</h6>
          </legend>
          <div class="row">
            <div class="col-md-6 form-group">
              <label class="font-weight-semibold">Qualificação Profissional</label>
              <select
                class="form-control"
                formControlName="qualificacaoProfissional"
                [ngClass]="
                  formCadastro.get('qualificacaoProfissional').invalid &&
                  formCadastro.get('qualificacaoProfissional').touched
                    ? 'is-invalid'
                    : null
                "
                (change)="buscarDescricao($event, listaQualificacao)"
              >
                <option value="">Selecione...</option>
                <option
                  *ngFor="let qualificacao of listaQualificacao"
                  [value]="qualificacao.Codigo"
                >
                  {{ qualificacao.Descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formCadastro.get('qualificacaoProfissional').invalid &&
                  formCadastro.get('qualificacaoProfissional').touched
                "
              >
                Campo Obrigatório
              </span>
              <textarea
                name=""
                id=""
                class="form-group col-12 mt-2"
                style="resize: none"
                rows="4"
                disabled
                #qualificacao
                >{{ qualificacaoSelecionada?.Descricao_Completa }}</textarea
              >
            </div>
            <div class="col-md-6 form-group">
              <label class="font-weight-semibold">Categoria Profissional</label>
              <select
                class="form-control"
                formControlName="categoriaProfissional"
                [ngClass]="
                  formCadastro.get('categoriaProfissional').invalid &&
                  formCadastro.get('categoriaProfissional').touched
                    ? 'is-invalid'
                    : null
                "
                (change)="buscarDescricao($event, listaCategoria)"
              >
                <option value="">Selecione...</option>
                <option *ngFor="let categoria of listaCategoria" [value]="categoria.Codigo">
                  {{ categoria.Descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formCadastro.get('categoriaProfissional').invalid &&
                  formCadastro.get('categoriaProfissional').touched
                "
              >
                Campo Obrigatório
              </span>
              <textarea
                name=""
                id=""
                class="form-group col-12 mt-2"
                rows="4"
                style="resize: none"
                disabled
                #categoria
                >{{ categoriaSelecionada?.Descricao_Completa }}</textarea
              >
            </div>
          </div>
        </fieldset>
      </div>
    </form>
    <div class="card-footer mt-2 justify-content-end d-flex">
      <button (click)="submitCadastro()" class="btn btn-primary">
        Submeter
        <i class="fa fa-paper-plane ml-2"></i>
      </button>
    </div>
  </div>
</main>
