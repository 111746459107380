<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div>
    <div *ngIf="!editando" >

        <div *ngIf="!carregado" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
                <span class="visually-hidden"></span>
            </div>
        </div>

        <div *ngIf="carregado" class="row mb-3">
            <div class="col-2">
                <label da for="tCamisa">Tamanho da camisa:</label>
                <p>{{caractPessoal.TamanhoCamisa}} cm</p>
            </div>
            <div class="col-2">
                <label for="tCalca">Numeração da calça:</label>
                <p>{{caractPessoal.TamanhoCalca}} cm</p>
            </div>
            <div class="col-2">
                <label for="tSapato">Numeração do sapato:</label>
                <p>{{caractPessoal.TamanhoSapato}} cm</p>
            </div>
            <div class="col-2">
                <label for="tCabeca">Medida da cabeça:</label>
                <p>{{caractPessoal.TamanhoCabeca}} cm</p>
            </div>
            <div class="col-2">
                <label for="altura">Altura:</label>
                <p>{{caractPessoal.Altura}} m</p>
            </div>
            <div class="col-2">
                <label for="peso">Peso:</label>
                <p>{{caractPessoal.Peso}} kg</p>
            </div>
        </div>
        <div *ngIf="carregado" class="row mb-3">
            <div class="col-3">
                <label for="corOlhos">Cor dos olhos:</label>
                <p>{{caractPessoal.CorOlhos}}</p>
            </div>
            <div class="col-3">
                <label for="corCabelo">Cor do cabelo:</label>
                <p>{{caractPessoal.CorCabelo}}</p>
            </div>
            <div class="col-3">
                <label for="raca">Raça/Cor da Pele:</label>
                <p>{{caractPessoal.Raca.Descricao}}</p>
            </div>
        </div>
    </div>
    <div *ngIf="editando" >
        <form [formGroup]="formCaracteristicasPessoais">
            <div class="row mb-3">
                <div class="col-2">
                    <label da for="tCamisa">Tamanho da camisa</label>
                    <input placeholder="00 cm" name="tCamisa" class="form-control" formControlName="tCamisa"
                        (keypress)='somenteNumero($event)'>
                </div>
                <div class="col-2">
                    <label for="tCalca">Numeração da calça</label>
                    <input placeholder="00 cm" name="tCalca" class="form-control" formControlName="tCalca"
                        (keypress)='somenteNumero($event)'>
                </div>
                <div class="col-2">
                    <label for="tSapato">Numeração do sapato</label>
                    <input placeholder="00 cm" name="tSapato" class="form-control" formControlName="tSapato"
                        (keypress)='somenteNumero($event)'>
                </div>
                <div class="col-2">
                    <label for="tCabeca">Medida da cabeça</label>
                    <input placeholder="00 cm" pla name="tCabeca" class="form-control" formControlName="tCabeca"
                        (keypress)='somenteNumero($event)'>
                </div>
                <div class="col-2">
                    <label for="altura">Altura</label>
                    <input placeholder="0.00 m" name="altura" class="form-control" formControlName="altura"
                        (keypress)='somenteNumero($event)'>
                </div>
                <div class="col-2">
                    <label for="peso">Peso</label>
                    <input placeholder="00.00 kg" name="peso" class="form-control" formControlName="peso"
                        (keypress)='somenteNumero($event)'>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-3">
                    <label for="corOlhos">Cor dos olhos</label>
                    <input placeholder="Cor dos olhos" name="corOlhos" class="form-control" formControlName="corOlhos"
                        (keypress)='somenteLetras($event)'>
                </div>
                <div class="col-3">
                    <label for="corCabelo">Cor do cabelo</label>
                    <input placeholder="Cor do cabelo" name="corCabelo" class="form-control" formControlName="corCabelo"
                        (keypress)='somenteLetras($event)'>
                </div>
                <div class="col-3">
                    <label for="raca">Raça/Cor da Pele</label>
                    <select name="raca" class="form-control" formControlName="raca">
                        <option value="">selecione...</option>
                        <option *ngFor="let raca of listaRacaCorDePele" value={{raca.Descricao}}> {{raca.Descricao}}
                        </option>
                    </select>
                </div>
            </div>
        </form>
    </div>
    
        <div class="row d-flex justify-content-end">
            <button *ngIf="habilitarLimparCaracteristica()" type="button" class="btn btn-danger mr-3"
                (click)="limparCamposCaracteristicas()">Cancelar</button>
            <button *ngIf="!editando && carregado" type="button" class="btn btn-primary"
                (click)="editarCaracteristicas()">Editar</button>
            <button *ngIf="editando" type="button" class="btn btn-primary"
                (click)="salvarCaracteristicas()">Salvar</button>
        </div>
    
</div>