import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AcessoService } from "../../service/acesso/acesso.service";
import { RepresentanteLegal } from "./representante-legal.component";
import { LocalStorage } from "src/app/service/classes/localstorage/LocalStorage";
import { Crypto } from "src/app/service/classes/crypto/crypto";

@Injectable({
  providedIn: "root",
})
export class RepresentanteLegalService {
  localStorage: LocalStorage = new LocalStorage();
  url_acesso: string;
  headers = new HttpHeaders();
  cabecalho: any = null;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl();
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(
        new Crypto().decryptUsingAES256(localStorage.getItem("util"))
      );
      this.cabecalho = this.headers.append("sessao", this.localStorage.Sessao);
    }
  }

  buscarListaRepresentanteLegal() {
    return this.http.get(`${this.url_acesso}representante-legal/`, {
      headers: this.cabecalho,
    });
  }

  buscarUf() {
    return this.http.get(`${this.url_acesso}estados/`, {
      headers: this.cabecalho,
    });
  }

  buscarTipoRepresentante() {
    return this.http.get(
      `${this.url_acesso}representante-legal/tipo-representante`,
      { headers: this.cabecalho }
    );
  }

  buscarCep(cep: string) {
    return this.http.get(`${this.url_acesso}cep/${cep}`, {
      headers: this.cabecalho,
    });
  }

  buscarTipoConta() {
    return this.http.get(`${this.url_acesso}pensao-alimenticia/tipo-conta`, {
      headers: this.cabecalho,
    });
  }

  buscarOperacao(banco: number) {
    return this.http.get(
      `${this.url_acesso}pensao-alimenticia/operacao/${banco}`,
      {
        headers: this.cabecalho,
      }
    );
  }

  buscarBancos() {
    return this.http.get(`${this.url_acesso}bancos/`, {
      headers: this.cabecalho,
    });
  }

  salvarRepresentante(representante: RepresentanteLegal) {
    return this.http.post(
      `${this.url_acesso}representante-legal/`,
      representante,
      { headers: this.cabecalho }
    );
  }
  alterarRepresentante(representante: RepresentanteLegal) {
    return this.http.put(
      `${this.url_acesso}representante-legal/`,
      representante,
      { headers: this.cabecalho }
    );
  }

  excluirRepresentante(representante: RepresentanteLegal) {
    return this.http.delete(
      `${this.url_acesso}representante-legal/${representante.NumeroRepresentante}`,
      { headers: this.cabecalho }
    );
  }
}
