import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ErroService } from "../../service/erros/erro.service";
import Swal from "sweetalert";
import { RepresentanteLegalService } from "./representante-legal.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Data } from "src/app/service/interfaces/Data";
import { AlertaModal } from "../componentes-funcionais/alerta-modal/alerta-modal.component";
import { Bancos } from "src/app/service/interfaces/Bancos";

@Component({
  selector: "app-representante-legal",
  templateUrl: "./representante-legal.component.html",
  styleUrls: ["./representante-legal.component.css"],
})
export class RepresentanteLegalComponent implements OnInit {
  @Output() selecionouRepresentante = new EventEmitter();
  @Output() listaRepresentantes = new EventEmitter();

  formRepresentanteLegal: FormGroup;
  step: number = 1;
  temErro: boolean;
  listaRepresentanteLegal: any[];
  listaRepLegalFiltrada: any[];
  filtroRepLegal: string = "";
  mostrarForm: boolean = false;
  listaUf: any[];
  listaTipoRepresentante: any[];
  listaOperacao: any[];
  listaBancos: Bancos[];
  contaMask: string;
  loadingCep: boolean = false;
  editar: boolean = false;
  idExcluindo: string = "";
  loading: boolean = true;

  constructor(
    public fb: FormBuilder,
    private validaErro: ErroService,
    private representanteLegalService: RepresentanteLegalService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.formRepresentanteLegal = this.fb.group({
      dadosPessoais: this.fb.group({
        dataInicio: [
          "",
          [Validators.min(1900 - 0o1 - 0o1), Validators.max(9999 - 31 - 12)],
        ],
        dataFim: [
          "",
          [Validators.min(1900 - 0o1 - 0o1), Validators.max(9999 - 31 - 12)],
        ],
        numeroRepresentante: [{ value: "", disabled: true }],
        nome: ["", [Validators.required]],
        tipoRepresentante: ["", [Validators.required]],
        cpf: ["", [Validators.required]],
        identidade: ["", [Validators.required]],
        orgaoExpedidor: ["", [Validators.required]],
        ufExpedicao: ["", [Validators.required]],
        telefone: ["", [Validators.required]],
      }),
      endereco: this.fb.group({
        cep: ["", [Validators.required]],
        logradouro: [{ value: "", disabled: true }],
        numeroEndereco: ["", [Validators.required]],
        complemento: [""],
        bairro: [{ value: "", disabled: true }],
        uf: [{ value: "", disabled: true }],
        cidade: [{ value: "", disabled: true }],
      }),
      dadosBancarios: this.fb.group({
        banco: ["", [Validators.required]],
        agencia: ["", [Validators.required]],
        operacao: ["", [Validators.required]],
        numeroConta: ["", [Validators.required]],
      }),
    });
    //this.abrirModalRepLegal();
    this.buscarListaRepresentanteLegal();
    // this.mask();
  }

  abrirModalRepLegal(): void {
    document.getElementById("btnRepLegal").click();
  }

  somenteNumero(event) {
    return (
      event.charCode >= 48 &&
      event.charCode <= 57 /*|| (event.keyCode == 45 || event.charCode == 46)*/
    );
  }

  somenteLetras(event) {
    return (
      (event.charCode >= 65 && event.charCode <= 90) ||
      (event.charCode >= 97 &&
        event.charCode <= 122) /*|| (event.charCode === 32)*/
    );
  }

  buscarListaRepresentanteLegal(): void {
    this.representanteLegalService.buscarListaRepresentanteLegal().subscribe(
      (data: any) => {
        this.listaRepresentanteLegal = data;
        this.listaRepLegalFiltrada = this.listaRepresentanteLegal;
        this.loading = false;
        this.listaRepresentantes.next(this.listaRepresentanteLegal);
      },
      (error) => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, "p");
      }
    );
  }

  set searchRepLegal(value: string) {
    this.filtroRepLegal = value;
    this.listaRepLegalFiltrada = this.listaRepresentanteLegal.filter(
      (repLegal) =>
        repLegal.NomeRepresentante.toLocaleLowerCase().indexOf(
          this.filtroRepLegal.toLocaleLowerCase()
        ) > -1
    );
  }

  get searchRepLegal() {
    return this.filtroRepLegal;
  }

  abrirModalAdd() {
    this.step = 1;
    this.editar = false;
    this.mostrarForm = true;
    this.limparCampos();
    this.buscarUf();
    this.buscarTipoRepresentante();
    this.buscarBancos();

    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("nome")
      .setValidators([Validators.required]);
    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("nome")
      .updateValueAndValidity();
  }

  fecharModalAdd() {
    this.step = 1;
    this.mostrarForm = false;
    this.temErro = false;
    this.editar = false;
    this.limparCampos();
  }

  buscarUf() {
    this.representanteLegalService.buscarUf().subscribe(
      (data: any) => {
        this.listaUf = data;
      },
      (error) => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, "p");
      }
    );
  }

  buscarTipoRepresentante() {
    this.listaTipoRepresentante = [];
    this.representanteLegalService.buscarTipoRepresentante().subscribe(
      (data: any) => {
        this.listaTipoRepresentante = data;
      },
      (error) => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, "p");
      }
    );
  }

  verificaData(dataInicio: AbstractControl, dataFim: AbstractControl) {
    if (dataInicio.value.length == 8 && dataFim.value.length == 8) {
      const dataInicioInvertida = Number(
        `${dataInicio.value.substr(4, 4)}${dataInicio.value.substr(
          2,
          2
        )}${dataInicio.value.substr(0, 2)}`
      );
      const dataFimInvertida = Number(
        `${dataFim.value.substr(4, 4)}${dataFim.value.substr(
          2,
          2
        )}${dataFim.value.substr(0, 2)}`
      );

      if (dataInicioInvertida >= dataFimInvertida) {
        dataFim.setValue("");
        new AlertaModal(
          "alerta",
          "Atenção",
          "A data final tem que ser posterior a data inicial.",
          "p"
        );
      }
    }
  }

  onCepChange(): void {
    const cep = this.formRepresentanteLegal.get("endereco").get("cep").value;

    if (cep.toString().length === 8) {
      this.loadingCep = true;

      this.representanteLegalService.buscarCep(cep).subscribe(
        (data: Endereco) => {
          if (!data.Valido) {
            new AlertaModal("erro", "Atenção", "CEP Inválido", "p");
            this.formRepresentanteLegal.get("endereco").get("cep").setValue("");
            this.formRepresentanteLegal
              .get("endereco")
              .get("cep")
              .updateValueAndValidity();
          } else {
            this.carregarEndereco(data);
          }
          this.loadingCep = false;
        },
        (error) => {
          this.loadingCep = false;
          new AlertaModal("erro", "Erro", error.error.Mensagem, "p");
        }
      );
    }
  }

  carregarEndereco(endereco: Endereco): void {
    const formEndereco = this.formRepresentanteLegal.get("endereco");

    formEndereco.get("logradouro").setValue(endereco.Logradouro);
    formEndereco.get("cidade").setValue(endereco.Cidade);
    formEndereco.get("bairro").setValue(endereco.Bairro);
    formEndereco.get("complemento").setValue(endereco.Complemento);
    formEndereco
      .get("numeroEndereco")
      .setValue(endereco.Numero == 0 ? "" : endereco.Numero);
    formEndereco.get("uf").setValue(endereco.Uf);
  }

  buscarOperacao(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.listaOperacao = [];
      const banco = this.formRepresentanteLegal
        .get("dadosBancarios")
        .get("banco").value;
      if (banco !== "") {
        this.representanteLegalService.buscarOperacao(banco).subscribe(
          (data: any[]) => {
            this.listaOperacao = data;
            resolve();
          },
          (error) => {
            this.validaErro.retornoErro(error);
            reject();
          }
        );
      }
    });
  }

  buscarBancos(): void {
    this.listaBancos = [];
    this.representanteLegalService.buscarBancos().subscribe(
      (data: Bancos[]) => {
        this.listaBancos = data;
        this.listaBancos.forEach((banco) => {
          banco.Codigo = banco.Codigo.trim();
        });
      },
      (error) => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, "p");
      }
    );
  }

  mask(): void {
    const conta = this.formRepresentanteLegal.value.dadosBancarios.numeroConta;

    if (conta.length >= 4) {
      this.temErro = false;
      if (conta.length >= 9) {
        this.contaMask = "0".repeat(8) + "-0";
      } else {
        this.contaMask = "0".repeat(conta.length) + "-0";
      }
    } else {
      this.contaMask = "0000-0";
    }
  }

  submitRepresentante(): void {
    const valido = this[`validaEtapa${this.step}`]();
    if (this.step == 1 && valido) {
      this.temErro = false;
      this.step += 1;
    } else if (this.step == 2 && valido) {
      this.temErro = false;
      this.step += 1;
    } else if (this.step == 3 && valido) {
      this.temErro = false;
      this.editar ? this.alterarRepresentante() : this.salvarRepresentante();
    }
  }

  validaEtapa1(): boolean {
    if (this.formRepresentanteLegal.get("dadosPessoais").valid) {
      return true;
    } else {
      new AlertaModal(
        "alerta",
        "Atenção",
        "Verifique os campos incorretos",
        "p"
      );
      this.temErro = true;
      return false;
    }
  }

  validaEtapa2() {
    if (this.formRepresentanteLegal.get("endereco").valid) {
      return true;
    } else {
      new AlertaModal(
        "alerta",
        "Atenção",
        "Verifique os campos incorretos",
        "p"
      );
      this.temErro = true;
      return false;
    }
  }

  validaEtapa3() {
    if (this.formRepresentanteLegal.get("dadosBancarios").valid) {
      return true;
    } else {
      new AlertaModal(
        "alerta",
        "Atenção",
        "Verifique os campos incorretos",
        "p"
      );
      this.temErro = true;
      return false;
    }
  }

  salvarRepresentante(): void {
    this.spinner.show();
    const representanteLegal = this.montarRepresentante();
    console.log(representanteLegal);
    this.representanteLegalService
      .salvarRepresentante(representanteLegal)
      .subscribe(
        (data) => {
          this.spinner.hide();
          new AlertaModal(
            "sucesso",
            "Sucesso",
            "Representante legal criado com sucesso!",
            "p"
          );
          this.limparCampos();
          this.step = 1;
          this.fecharModalAdd();
          this.buscarListaRepresentanteLegal();
        },
        (error) => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, "p");
        }
      );
  }

  alterarRepresentante(): void {
    this.spinner.show();
    const representanteLegal = this.montarRepresentante();
    this.representanteLegalService
      .alterarRepresentante(representanteLegal)
      .subscribe(
        (data) => {
          this.spinner.hide();
          new AlertaModal(
            "sucesso",
            "Sucesso",
            "Representante legal alterado com sucesso!",
            "p"
          );
          this.step = 1;
          this.editar = false;
          this.limparCampos();
          this.fecharModalAdd();
          this.buscarListaRepresentanteLegal();
        },
        (error) => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, "p");
        }
      );
  }

  montarRepresentante(): RepresentanteLegal {
    const { dadosPessoais, endereco, dadosBancarios } =
      this.formRepresentanteLegal.getRawValue();

    return {
      NumeroRepresentante: dadosPessoais.numeroRepresentante,
      NomeRepresentante: dadosPessoais.nome,
      Cpf: dadosPessoais.cpf,
      TipoRepresentante: dadosPessoais.tipoRepresentante,
      NumeroProcesso: "",
      DataImplantacao:
        dadosPessoais.dataInicio != ""
          ? new Data(this.formatarDataEnvio(dadosPessoais.dataInicio))
          : null,
      DataVencimento:
        dadosPessoais.datafim != ""
          ? new Data(this.formatarDataEnvio(dadosPessoais.dataFim))
          : null,

      Identidade: {
        Numero: dadosPessoais.identidade.replace(/[.-]/g, ""),
        OrgaoEmissor: dadosPessoais.orgaoExpedidor,
        Uf: dadosPessoais.ufExpedicao,
      },
      Endereco: {
        Logradouro: endereco.logradouro,
        Complemento: endereco.complemento,
        Numero: endereco.numeroEndereco,
        Bairro: endereco.bairro,
        Cidade: endereco.cidade,
        Uf: endereco.uf,
        Cep: endereco.cep,
      },
      Telefone: {
        Ddd: dadosPessoais.telefone.substring(0, 2),
        Numero: dadosPessoais.telefone.substring(2, 11),
      },
      DadosBancarios: {
        Banco: dadosBancarios.banco,
        Agencia: dadosBancarios.agencia,
        ContaCorrente: dadosBancarios.numeroConta,
        Operacao: dadosBancarios.operacao,
      },
      FlagsRepresentanteLegal: {
        Flag01: "0",
        Flag02: "0",
        Flag03: "0",
        Flag04: "0",
        Flag05: "0",
        Flag06: "0",
        Flag07: "0",
        Flag08: "0",
        Flag09: "0",
        Flag10: "0",
      },
    };
  }

  selecionarRepresentante(representante: RepresentanteLegal) {
    this.selecionouRepresentante.emit(representante);
    document.getElementById("close").click();
  }

  editarRepresentante(representante: RepresentanteLegal): void {
    console.log("representante selecionado rep", representante);
    this.step = 1;
    this.editar = true;
    this.mostrarForm = true;
    if (!this.listaUf) {
      this.buscarUf();
    }
    if (!this.listaBancos) {
      this.buscarBancos();
    }
    this.buscarTipoRepresentante();
    representante.DataImplantacao.SetData
      ? this.formRepresentanteLegal
          .get("dadosPessoais")
          .get("dataInicio")
          .setValue(this.formatarData(representante.DataImplantacao.ToDate))
      : null;
    representante.DataVencimento.SetData
      ? this.formRepresentanteLegal
          .get("dadosPessoais")
          .get("dataFim")
          .setValue(this.formatarData(representante.DataVencimento.ToDate))
      : null;

    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("nome")
      .setValue(representante.NomeRepresentante);
    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("cpf")
      .setValue(representante.Cpf.padStart(11, "0"));
    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("identidade")
      .setValue(representante.Identidade.Numero);
    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("orgaoExpedidor")
      .setValue(representante.Identidade.OrgaoEmissor);
    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("ufExpedicao")
      .setValue(representante.Identidade.Uf);
    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("numeroRepresentante")
      .setValue(representante.NumeroRepresentante);
    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("tipoRepresentante")
      .setValue(representante.TipoRepresentante);
    representante.Telefone.Ddd == "0" || representante.Telefone.Ddd == ""
      ? this.formRepresentanteLegal
          .get("dadosPessoais")
          .get("telefone")
          .setValue("")
      : this.formRepresentanteLegal
          .get("dadosPessoais")
          .get("telefone")
          .setValue(representante.Telefone.Ddd + representante.Telefone.Numero);
    this.formRepresentanteLegal
      .get("endereco")
      .get("cep")
      .setValue(representante.Endereco.Cep);
    this.formRepresentanteLegal
      .get("endereco")
      .get("numeroEndereco")
      .setValue(representante.Endereco.Numero);
    this.formRepresentanteLegal
      .get("endereco")
      .get("logradouro")
      .setValue(representante.Endereco.Logradouro);
    this.formRepresentanteLegal
      .get("endereco")
      .get("complemento")
      .setValue(representante.Endereco.Complemento);
    this.formRepresentanteLegal
      .get("endereco")
      .get("bairro")
      .setValue(representante.Endereco.Bairro);
    this.formRepresentanteLegal
      .get("endereco")
      .get("uf")
      .setValue(representante.Endereco.Uf);
    this.formRepresentanteLegal
      .get("endereco")
      .get("cidade")
      .setValue(representante.Endereco.Cidade);
    this.formRepresentanteLegal
      .get("dadosBancarios")
      .get("banco")
      .setValue(representante.DadosBancarios.Banco);
    this.formRepresentanteLegal
      .get("dadosBancarios")
      .get("agencia")
      .setValue(representante.DadosBancarios.Agencia);
    this.formRepresentanteLegal
      .get("dadosBancarios")
      .get("numeroConta")
      .setValue(representante.DadosBancarios.ContaCorrente);
    this.buscarOperacao().then(() => {
      this.formRepresentanteLegal
        .get("dadosBancarios")
        .get("operacao")
        .setValue(representante.DadosBancarios.Operacao.replace(/^0/, ""));
      console.log(
        "valor operação:",
        this.formRepresentanteLegal.get("dadosBancarios").get("operacao").value
      );
    });
  }

  excluirRepresentante(representante: RepresentanteLegal): void {
    this.representanteLegalService
      .excluirRepresentante(representante)
      .subscribe(
        (data) => {
          new AlertaModal(
            "sucesso",
            "Sucesso",
            "Exclusão do representante legal realizada com sucesso!",
            "p"
          );
          this.editar = false;
          this.buscarListaRepresentanteLegal();
          this.idExcluindo = "";
        },
        (error) => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, "p");
          this.idExcluindo = "";
        }
      );
  }

  deletar(representante: RepresentanteLegal, index: any): void {
    this.idExcluindo = index;
    Swal({
      title: "Você tem certeza?",
      text: "Esta operação não pode ser desfeita.",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        this.excluirRepresentante(representante);
      } else {
        this.idExcluindo = "";
      }
    });
  }

  limparCampos(): void {
    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("dataInicio")
      .setValue("");
    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("dataFim")
      .setValue("");
    this.formRepresentanteLegal.get("dadosPessoais").get("nome").setValue("");
    this.formRepresentanteLegal.get("dadosPessoais").get("cpf").setValue("");
    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("identidade")
      .setValue("");
    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("orgaoExpedidor")
      .setValue("");
    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("ufExpedicao")
      .setValue("");
    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("numeroRepresentante")
      .setValue("");
    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("tipoRepresentante")
      .setValue("");
    this.formRepresentanteLegal
      .get("dadosPessoais")
      .get("telefone")
      .setValue("");
    this.formRepresentanteLegal.get("endereco").get("cep").setValue("");
    this.formRepresentanteLegal
      .get("endereco")
      .get("numeroEndereco")
      .setValue("");
    this.formRepresentanteLegal.get("endereco").get("logradouro").setValue("");
    this.formRepresentanteLegal.get("endereco").get("complemento").setValue("");
    this.formRepresentanteLegal.get("endereco").get("bairro").setValue("");
    this.formRepresentanteLegal.get("endereco").get("uf").setValue("");
    this.formRepresentanteLegal.get("endereco").get("cidade").setValue("");
    this.formRepresentanteLegal.get("dadosBancarios").get("banco").setValue("");
    this.formRepresentanteLegal
      .get("dadosBancarios")
      .get("agencia")
      .setValue("");
    this.formRepresentanteLegal
      .get("dadosBancarios")
      .get("numeroConta")
      .setValue("");
    this.formRepresentanteLegal
      .get("dadosBancarios")
      .get("operacao")
      .setValue("");

    this.formRepresentanteLegal.markAsPristine();
  }

  formatarData(data: string): string {
    let dataSplit = data.split("/");
    const dataFormatada = `${dataSplit[2]}-${dataSplit[1]}-${dataSplit[0]}`;
    return dataFormatada;
  }

  formatarDataEnvio(data: string): string {
    let dataSplit = data.split("-");
    const dataFormatada = `${dataSplit[2]}${dataSplit[1]}${dataSplit[0]}`;
    return dataFormatada;
  }
}

export interface RepresentanteLegal {
  NumeroRepresentante: number;
  NomeRepresentante: string;
  Cpf: string;
  TipoRepresentante: string;
  NumeroProcesso: string;
  DataImplantacao: Data;
  DataVencimento: Data;
  Identidade: Identidade;
  Endereco: Endereco;
  Telefone: Telefone;
  DadosBancarios: DadosBancarios;
  FlagsRepresentanteLegal: FlagsRepresentanteLegal;
}

interface DataImplantacao {
  SetData?: Data;
}

interface DataVencimento {
  SetData?: Data;
}

interface Identidade {
  Numero: string;
  OrgaoEmissor: string;
  Uf: string;
}

interface Endereco {
  Logradouro: string;
  Complemento?: string;
  Numero: number;
  Bairro: string;
  Cidade: string;
  Uf: string;
  Cep: number;
  Valido?: true;
}

interface Telefone {
  Ddd: string;
  Numero: string;
}

interface DadosBancarios {
  Banco: string;
  Agencia: string;
  ContaCorrente: string;
  Operacao: string;
}

interface informacaoBasicaRepresentante {
  Nome: string;
  Numero: number;
}

interface FlagsRepresentanteLegal {
  Flag01: string;
  Flag02: string;
  Flag03: string;
  Flag04: string;
  Flag05: string;
  Flag06: string;
  Flag07: string;
  Flag08: string;
  Flag09: string;
  Flag10: string;
}
