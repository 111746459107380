import  { DadosBasicos } from "./Pessoal/DadosBasicos";
import  { Contato } from "./Pessoal/Contato";
import { Endereco } from "../../classes/Endereco-Padrao/endereco";

export class Pessoal{
    DadosBasicos: DadosBasicos =  new DadosBasicos();
    Endereco: Endereco = new Endereco();
    Contato:Contato = new Contato();

    constructor(){
        
    }

}