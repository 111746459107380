import { Data } from "../../../service/interfaces/Data";

export class ComplementoAsa {
    DataAltercao: Data;
    Valor: number;
    Verba: number;
    Trans: number;
    TipoPag: number;
    Obs: string;
    DataCompetencia: Data =  new Data();
    TipoProcessamento: string
    Flag: string

    constructor(){};
}