import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Permissao } from './pse-permisao-empresa-filial.component';


@Injectable({
  providedIn: 'root'
})
export class PsePermissaoEmpresaFilialService {
  localStorage: LocalStorage = new LocalStorage();
  headers: HttpHeaders = new HttpHeaders();
  cabecalho: any = null; 
  urlAcesso: string = '';
  filial: string ='';

  constructor(private acesso: AcessoService, private http: HttpClient) {
    this.urlAcesso = this.acesso.validarUrl();
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString(); 
    }
  }

  salvarPermissao(permissao: Permissao){
    return this.http.put(`${this.urlAcesso}usuario/vinculo`, permissao,{headers:this.cabecalho});
  }

  ativarPermissao(permissao: Permissao){
    return this.http.put(`${this.urlAcesso}usuario/ativar`, permissao,{headers:this.cabecalho});
  }

  desativarPermissao(permissao: Permissao){
    return this.http.put(`${this.urlAcesso}usuario/desativar`,permissao,{headers:this.cabecalho});
  }
  
  buscarTipo() {
    return this.http.get(`${this.urlAcesso}politica-seguranca/tipos-usuario`, {headers:this.cabecalho});
  }

  buscarNome(codigoUnicoUsuario:string){
    return this.http.get(`${this.urlAcesso}usuario/${codigoUnicoUsuario}`, {headers:this.cabecalho});
  }

  buscarSituacao(codigo:string, codigoCliente:string, empfil:string){
    return this.http.get(`${this.urlAcesso}usuario/filial/${codigo}/${codigoCliente}/${empfil}`, {headers:this.cabecalho})
  }
  
  buscarFilial(codigoUnicoUsuario:string, codigoCliente:string){
    return this.http.get(`${this.urlAcesso}pse/usuario/empresa-filial/${codigoCliente}/${codigoUnicoUsuario}`,{headers:this.cabecalho})
  }
}

