import { Component, ViewChild } from '@angular/core';
import { LicencaPremioCadastroComponent } from './licenca-premio-cadastro/licenca-premio-cadastro.component';
import { LicencaPremioService } from './licenca-premio.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import Swal from 'sweetalert';
import { ErroService } from 'src/app/service/erros/erro.service';
import { InformacoesBasicas } from 'src/app/service/classes/cadastro/InformacoesBasicas/InformacoesBasicas';
import { ObservacoesFichaFuncionalComponent } from '../componentes-funcionais/observacoes-ficha-funcional/observacoes-ficha-funcional.component';
import { AnexoComponent } from '../anexo/anexo.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-licenca-premio',
  templateUrl: './licenca-premio.component.html',
  styleUrls: ['./licenca-premio.component.css'],
})
export class LicencaPremioComponent {
  @ViewChild(LicencaPremioCadastroComponent, { static: false })
  modal: LicencaPremioCadastroComponent;
  @ViewChild(ObservacoesFichaFuncionalComponent)
  modalObservacoes: ObservacoesFichaFuncionalComponent;
  @ViewChild(AnexoComponent) modalAnexo: AnexoComponent;
  listaLicenca: Licenca[] = [];
  formColaborador: FormGroup;
  matricula: number = null;
  colaborador: Cadastro;
  loading: boolean = false;
  cadastroLicenca: boolean = true;
  formLicencaPremio: FormGroup;
  formTelaInicial: FormGroup;
  empfil: string;
  tipoPagina: string = '018';
  localStorage: LocalStorage;

  validaTelaInicial: boolean = false;
  telaFormulario: boolean = false;

  constructor(
    public fb: FormBuilder,
    private licencaPremioService: LicencaPremioService,
    private spinner: NgxSpinnerService,
    private validaErro: ErroService
  ) {
    this.localStorage = this.decryptLocalStorage();
    this.empfil = this.localStorage.FilialAtual.toString();
    this.formColaborador = this.fb.group({
      matricula: [''],
      nome: [''],
    });

    this.formTelaInicial = this.fb.group({
      matricula: ['', Validators.required],
      nome: [''],
    });
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }

  buscarColaboradorMatricula(matricula) {
    this.spinner.show("premio");
    this.licencaPremioService.buscarColaboradorMatricula(matricula).subscribe(
      (data: Cadastro) => {
        console.log(data);
        this.setarFormColaborador(data);
        this.colaborador = data;
        this.spinner.hide("premio");
      },
      (error) => {
        new AlertaModal('erro', 'Erro!', error.error.Mensagem, 'p');
        this.retornarTelaInicial();
        this.spinner.hide("premio");
      }
    );
  }

  matriculaListener(evento) {
    this.matricula = evento.Matricula;
    this.buscarColaboradorMatricula(this.matricula);
    this.modalAnexo.inicializarComponente(evento);
  }

  setarFormColaborador(data) {
    this.formColaborador.get('matricula').disable();
    this.formColaborador.get('matricula').setValue(this.matricula);
    this.formColaborador.get('nome').disable();
    this.formColaborador.get('nome').setValue(data.InformacoesBasicas.NomeCompleto);
    this.loading = false;
    this.buscarLicenca();
  }

  retornarTelaInicial() {
    this.matricula = null;
    this.formColaborador.reset();
    this.formColaborador.markAsPristine();
  }

  buscarDados() {
    if (this.formTelaInicial.valid) {
      const matricula = this.formTelaInicial.get('matricula').value;
      this.matricula = matricula;

      this.spinner.show();
      this.licencaPremioService.buscarColaboradorMatricula(matricula).subscribe({
        next: (cadastro: Cadastro) => {
          this.formTelaInicial.get('matricula').disable();
          this.formTelaInicial.get('nome').disable();

          console.log('colaborador', cadastro);
          this.colaborador = cadastro;
          this.formTelaInicial.get('nome').setValue(cadastro.InformacoesBasicas.NomeCompleto);

          this.licencaPremioService.buscarLicenca(+cadastro.Matricula).subscribe({
            next: (licencas: Licenca[]) => {
              console.log('licencas', licencas);
              this.listaLicenca = licencas;
              this.spinner.hide();
              this.telaFormulario = true;
            },
            error: (err) => {
              this.spinner.hide();
              alert('Erro ao buscar licenças. Por favor, tente novamente.');
            },
          });
        },
        error: (err) => {
          console.error(err);
          this.spinner.hide();
          alert('Erro ao buscar informações do colaborador. Por favor, tente novamente.');
        },
      });
    } else {
      alert('Por favor, preencha todos os campos corretamente.');
    }
  }

  buscarLicenca() {
    this.spinner.show();

    this.licencaPremioService.buscarLicenca(+this.matricula).subscribe(
      (data: Licenca[]) => {
        console.log('data', data);
        this.listaLicenca = data;
        this.spinner.hide();
      },
      (err) => {
        console.error(err);
        this.spinner.hide();
      }
    );
  }

  aoAbrirModal(index?: number, Sequencial?: number) {
    this.modal.buscarIdClassificacaoAto();
    this.modal.buscarFormasQuitacao();
    if (this.modal && this.modal.formLicencaPremio) {
      this.modal.formLicencaPremio.reset();

      if (index !== undefined) {
        const licencaSelecionada = this.listaLicenca[index];

        const formatarDataParaInput = (dataObj) => {
          if (!dataObj || !dataObj.SetData || dataObj.SetData.length !== 8) return '';
          const ano = dataObj.SetData.substring(0, 4);
          const mes = dataObj.SetData.substring(4, 6);
          const dia = dataObj.SetData.substring(6, 8);
          return `${ano}-${mes}-${dia}`;
        };

        this.modal.formLicencaPremio
          .get('DataInicio')
          .setValue(formatarDataParaInput(licencaSelecionada.DataInicio));
        this.modal.formLicencaPremio
          .get('DataFim')
          .setValue(formatarDataParaInput(licencaSelecionada.DataFim));
        this.modal.formLicencaPremio.get('NumDias').setValue(licencaSelecionada.NumDias);
        this.modal.formLicencaPremio.get('QtdFaltas').setValue(licencaSelecionada.QtdFaltas);
        this.modal.formLicencaPremio.get('SitLip').setValue(licencaSelecionada.SitLip);
        this.modal.formLicencaPremio.get('TpLip').setValue(licencaSelecionada.TpLip);
        this.modal.formLicencaPremio.get('Condesp').setValue(licencaSelecionada.Condesp);
        this.modal.formLicencaPremio
          .get('NumeroProcesso')
          .setValue(licencaSelecionada.NumeroProcesso);
        this.modal.formLicencaPremio.get('NumeroDoe').setValue(licencaSelecionada.NumeroDoe);
        this.modal.formLicencaPremio
          .get('IdClassificacaoAto')
          .setValue(licencaSelecionada.IdClassificacaoAto);
        this.modal.formLicencaPremio
          .get('FormaQuitacao')
          .setValue(licencaSelecionada.FormaQuitacao);
        this.modal.formLicencaPremio.get('NumeroAto').setValue(licencaSelecionada.NumeroAto);
        this.modal.formLicencaPremio.get('AnoAto').setValue(licencaSelecionada.AnoAto);
        this.modal.formLicencaPremio
          .get('DtAto')
          .setValue(formatarDataParaInput(licencaSelecionada.DtAto));
        this.modal.formLicencaPremio.get('AnoDoe').setValue(licencaSelecionada.AnoDoe);
        this.modal.formLicencaPremio
          .get('DtDoe')
          .setValue(formatarDataParaInput(licencaSelecionada.DtDoe));
        this.modal.formLicencaPremio.get('DtComp').setValue(licencaSelecionada.DtComp);
        this.modal.formLicencaPremio.get('TpProc').setValue(licencaSelecionada.TpProc);

        this.modal.licencaindex = index;
        this.modal.Seq = Sequencial;
      } else {
        this.modal.licencaindex = null;
        this.cadastroLicenca = true;
      }
    }
  }

  downloadPdf() {
    this.spinner.show();

    this.licencaPremioService.downloadPdf(this.empfil, this.matricula, this.tipoPagina).subscribe(
      (data: any) => {
        console.log(data);

        this.spinner.hide();

        const pdf = `data:application/pdf;base64,${data.Base64}`;
        const fileName = `${data.Nome}.pdf`;

        const downloadLink = document.createElement('a');

        downloadLink.href = pdf;

        downloadLink.download = fileName;

        downloadLink.click();
      },
      (err) => {
        console.error(err);
        this.spinner.hide();
      }
    );
  }

  formatarData2(dataStr: string): string {
    if (!dataStr) return '';
    const ano = dataStr.substr(0, 4);
    const mes = dataStr.substr(4, 2);
    const dia = dataStr.substr(6, 2);

    return `${dia}/${mes}/${ano}`;
  }

  deletarLicenca(licenca: Licenca) {
    console.log('licenca', licenca);
    Swal({
      title: 'Deseja realmente excluir essa licença?',
      text: 'Essa ação não pode ser desfeita.',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
    }).then((result) => {
      this.spinner.show()
      if (result) {
        this.licencaPremioService
          .deletarLicenca(licenca.Empfil, licenca.Mat, licenca.Seq)
          .subscribe(
            (data) => {
              this.buscarLicenca();
              Swal({
                title: 'Excluído!',
                text: 'A licença foi excluída com sucesso.',
                icon: 'success',
              });
              this.spinner.hide()
            },
            (err: HttpErrorResponse) => {
              if (err.status === 500) {
                Swal('Erro', 'Não foi possível excluir essa licença.', 'error');
                this.spinner.hide()
              } else {
                const errorMessage =
                  err.error?.Mensagem || 'Ocorreu um erro ao excluir essa licença.';
                Swal('Erro', errorMessage, 'error');
                this.spinner.hide()
              }
            }
          );
      }else this.spinner.hide();
    });
  }

  formaQuitacaoDescricoes = {
    '1': '1 - PAGO',
    '2': '2 - QUITADO',
    '3': '3 - CONVERTIDO EM TEMPO DE SERVIÇO',
    '4': '4 - PERDA DE DIREITO',
  };

  getDescricaoFormaQuitacao(codigo: string): string {
    return this.formaQuitacaoDescricoes[codigo] || `${codigo} - Descrição não disponível`;
  }

  idClassificacaoAtoDescricoes = {
    '1': '1 - PORTARIA',
    '2': '2 - RESOLUCAO/PGJ',
    '3': '3 - DECRETO GOVERNAMENTAL',
    '4': '4 - REGISTRO DE EXPEDIENTE - RE',
    '5': '5 - OFICIO',
    '6': '6 - MEMORANDO-DEP/PGJ',
    '7': '7 - PORTARIA/SG/PGJ',
    '8': '8 - REQUERIMENTO AO PGJ/RN',
    '9': '9 - DESPACHO - PGJA',
    '10': '10 - MEMORANDO-ENG.',
    '11': '11 - DESPACHO PGJ',
    '12': '12 - MEMORANDO-ASSEJUR',
    '13': '13 - FOLGA - PLANTAO',
    '14': '14 - CONTRATO DE TRABALHO',
    '15': '15 - MEMORANDO',
    '16': '16 - MEMORANDO - DEPLAN',
    '17': '17 - RESOLUCAO/CSMP',
    '18': '18 - MEMORANDO-DCI',
    '19': '19 - MEMORANDO-SINF',
    '20': '20 - CERTIDAO/CPJ',
    '22': '22 - AVISO/PGJ',
    '23': '23 - ATO GOVERNAMENTAL',
    '24': '24 - RESOLUCAO/CPJ',
    '25': '25 - PORTARIA EXTERNA',
    '26': '26 - PORTARIA/FERIAS',
    '27': '27 - INFORMACAO',
    '28': '28 - DECLARACAO',
    '29': '29 - ATO CONJUNTO',
    '30': '30 - CONVENIO',
    '31': '31 - MEMORANDO-CONV E CONT',
    '32': '32 - MEMORANDO CAOP',
    '33': '33 - RESOLUCAO/CGMP',
    '34': '34 - ATESTADO MÉDICO',
    '35': '35 - CERTIDAO',
    '36': '36 - MEMORANDO-SG',
    '37': '37 - CERTIDAO/CSMP',
    '38': '38 - REGISTRO EXTERNO',
    '39': '39 - PORTARIA/CCMP/RN',
    '40': '40 - MEMORANDO ST',
    '41': '41 - MEMORANDO ST',
    '42': '42 - MEMORANDO',
    '43': '43 - MEMORANDO-BIB',
    '44': '44 - MEMORANDO MATERIAL E PATRIMONIO',
    '45': '45 - ORDEM ADMINISTRATIVA',
    '46': '46 - TERMO DE ADESAO',
    '47': '47 - RESOLUCAO CONJUNTA',
    '48': '48 - PROCESSO',
    '52': '52 - REQ AO PGJ',
    '55': '55 - OUTROS',
    '56': '56 - MEMORANDO-ARPC',
    '58': '58 - TERMO DE CESSAO',
    '61': '61 - DECISAO CSMP',
    '62': '62 - RESOLUCAO ADMINISTRATIVA',
    '63': '63 - PROCESSO TRABALHISTA, COM INICIO TSVE POR DECISAO JUDICIAL',
    '64': '64 - PROCESSO DE REINTEGRACAO POR DECISAO JUDICIAL',
    '65': '65 - PROCESSO DE REINTEGRACAO POR ANISTIA LEGAL',
    '66': '66 - PROCESSO DE REVERSAO DE SERVIDOR PUBLICO',
    '67': '67 - PROCESSO DE RECONDUCAO DE SERVIDOR PUBLICO',
    '68': '68 - PROCESSO DE REINCLUSAO DE MILITAR',
    '69': '69 - PROCESSO - OUTROS',
  };

  getDescricaoidClassificacaoAto(codigo: string): string {
    return this.idClassificacaoAtoDescricoes[codigo] || `${codigo} - Descrição não disponível`;
  }
}

export interface Licenca {
  Empfil: string;
  Documento: 'S' | 'N';
  Obs: 'S' | 'N';
  Mat: number;
  Seq: number;
  Sigla: string;
  DataInicio: { SetData: string; ToDate: string; IsDate: boolean; Empty: boolean };
  DataFim: { SetData: string; ToDate: string; IsDate: boolean; Empty: boolean };
  NumDias: number;
  QtdFaltas: number;
  SitLip: string;
  TpLip: string;
  Condesp: string;
  NumeroProcesso: string;
  NumeroDoe: string;
  IdClassificacaoAto: number;
  NumeroAto: string;
  AnoAto: string;
  DtAto: { SetData: string; ToDate: string; IsDate: boolean; Empty: boolean };
  AnoDoe: string;
  DtDoe: { SetData: string; ToDate: string; IsDate: boolean; Empty: boolean };
  DtComp: { SetData: string; ToDate: string; IsDate: boolean; Empty: boolean };
  TpProc: string;
  Flag: string;
  FormaQuitacao: number;
}
