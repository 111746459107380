<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<app-pesquisar-matricula
  *ngIf="matricula == null"
  [titulo]="'Outras Documentações'"
  [inputText]="true"
  (colaboradorEmitter)="matriculaListener($event)"
></app-pesquisar-matricula>

<section class="container" *ngIf="matricula != null">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title font-weight-semibold">
        <i
          class="fa fa-arrow-left pr-3 btn"
          aria-hidden="true"
          (click)="retornarTelaInicial()"
          style="cursor: pointer; bottom: 2px"
        ></i
        >Outras Documentações
      </h3>
    </div>
    <div class="card-body">
      <form #colaborador="ngForm" class="row" [formGroup]="formColaborador">
        <div class="col-md-2 form-group">
          <label class="font-weight-semibold">Matrícula</label>

          <input
            type="text"
            class="form-control"
            placeholder="Matrícula"
            mask="0*"
            formControlName="matricula"
          />

          <span class="form-text" *ngIf="loading"
            >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
          ></span>
        </div>

        <div class="col-md-10 form-group">
          <label class="font-weight-semibold">Nome do Colaborador</label>
          <input type="text" class="form-control" placeholder="Nome" formControlName="nome" />
        </div>
      </form>
    </div>
  </div>

  <div class="card d-flex justify-content-center">
    <div class="card-header d-inline-flex justify-content-between">
      <h3 class="card-title font-weight-semibold">Documentações disponíveis</h3>
    </div>
    <div class="col-md-12">
      <div class="card animated zoomIn text-left" style="background-color: #ffffff">
        <table class="table table-striped border-blue text-left mt-2">
          <thead class="bg-teal-400">
            <tr>
              <th>Documentações</th>
              <th style="text-align: right;">Download PDF</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td class=" d-flex justify-content-end ">
                  <span class="mr-3">Selecionar Todos</span>
                <input type="checkbox" id="select-all" (click)="toggleAllCheckboxes($event)" />
              </td>
            </tr>
            <tr>
              <td>
                <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                <span class="ml-1">Promoções</span>
              </td>
              <td style="text-align: right">
                <div class="d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="downloadPdf('010')"
                  >
                    <i class="bi bi-download"></i> Download
                  </button>
                  <input type="checkbox" class="document-checkbox ml-3" value="010" />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <i class="fa fa-user-md" aria-hidden="true"></i>
                <span class="ml-1">Prontuário Médico</span>
              </td>
              <td style="text-align: right">
                <div class="d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="downloadPdf('013')"
                  >
                    <i class="bi bi-download"></i> Download
                  </button>
                  <input type="checkbox" class="document-checkbox ml-3" value="013" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <i class="fa fa-stethoscope" aria-hidden="true"></i>
                <span class="ml-1">Faltas ao Trabalho</span>
              </td>
              <td style="text-align: right">
                <div class="d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="downloadPdf('007')"
                  >
                    <i class="bi bi-download"></i> Download
                  </button>
                  <input type="checkbox" class="document-checkbox ml-3" value="007" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <i class="fa fa-tags" aria-hidden="true"></i>
                <span class="ml-1">Licença Prêmio</span>
              </td>
              <td style="text-align: right">
                <div class="d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="downloadPdf('018')"
                  >
                    <i class="bi bi-download"></i> Download
                  </button>
                  <input type="checkbox" class="document-checkbox ml-3" value="018" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                <span class="ml-1">Form. Básica e Espec</span>
              </td>
              <td style="text-align: right">
                <div class="d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="downloadPdf('009')"
                  >
                    <i class="bi bi-download"></i> Download
                  </button>
                  <input type="checkbox" class="document-checkbox ml-3" value="009" />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <i class="fa fa-bullhorn" aria-hidden="true"></i>
                <span class="ml-1">Comissões</span>
              </td>
              <td style="text-align: right">
                <div class="d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="downloadPdf('110')"
                  >
                    <i class="bi bi-download"></i> Download
                  </button>
                  <input type="checkbox" class="document-checkbox ml-3" value="110" />
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td style="text-align: right">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  (click)="downloadSelectedPdfs()"
                >
                  <i class="bi bi-download"></i> Download Selecionados
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>
