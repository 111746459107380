import { Pipe, PipeTransform } from "@angular/core"


@Pipe({name : 'filtrarItemMenu'})
export class filtrarItemMenu implements PipeTransform{
    transform(itemMenu : any[], filtrarItemMenu : string) {
        filtrarItemMenu = filtrarItemMenu.toLowerCase()
        if (filtrarItemMenu != "") {
           itemMenu = itemMenu.filter(im => im.Descricao.toLowerCase().includes(filtrarItemMenu) || im.CodigoItemMenu.includes(filtrarItemMenu) )
           return itemMenu
           
        }else{
            return itemMenu
        }
    }

}