import { Pipe, PipeTransform } from '@angular/core';
import { Aviso } from './avisos.component';

@Pipe({
  name: 'filtrarAvisos'
})

export class AvisosPipe implements PipeTransform {
  transform(aviso: Aviso[], filtrarAviso: string) {
    filtrarAviso = filtrarAviso.toLowerCase();
    if (filtrarAviso != "") {
      aviso = aviso.filter(aviso => aviso.DataFinal.toLowerCase().includes(filtrarAviso) || aviso.DataInicial.toLowerCase().includes(filtrarAviso) || aviso.Mensagem.toLowerCase().includes(filtrarAviso) || aviso.Usuario.toLowerCase().includes(filtrarAviso));
      return aviso;
    } 
    else {
      return aviso;
    }
  }
}

