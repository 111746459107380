<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" name="observacao" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>


<app-pesquisar-matricula
  *ngIf="matricula == null"
  [titulo]="'Observações Diversas'"
  [inputText]="true"
  (colaboradorEmitter)="matriculaListener($event)"
></app-pesquisar-matricula>

<section class="container" *ngIf="matricula != null">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title font-weight-semibold">
        <i
          class="fa fa-arrow-left pr-3 btn"
          aria-hidden="true"
          (click)="retornarTelaInicial()"
          style="cursor: pointer; bottom: 2px"
        ></i
        >Observações Diversas
      </h3>
    </div>
    <div class="card-body">
      <form #colaborador="ngForm" class="row" [formGroup]="formColaborador">
        <div class="col-md-2 form-group">
          <label class="font-weight-semibold">Matrícula</label>

          <input
            type="text"
            class="form-control"
            placeholder="Matrícula"
            mask="0*"
            formControlName="matricula"
          />

          <span class="form-text" *ngIf="loading"
            >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
          ></span>
        </div>

        <div class="col-md-10 form-group">
          <label class="font-weight-semibold">Nome do Colaborador</label>
          <input type="text" class="form-control" placeholder="Nome" formControlName="nome" />
        </div>
      </form>
    </div>
  </div>

  <div class="card d-flex justify-content-center">
    <div class="card-header d-inline-flex justify-content-between">
      <h3 class="card-title font-weight-semibold">Lista de Observações</h3>
      <button
        type="button"
        class="btn btn-outline-primary"
        data-toggle="modal"
        data-target="#cadastroObservacoesModal"
        (click)="aoAbrirModal()"
      >
        <i class="fa fa-plus"></i> Adicionar Observacão
      </button>
    </div>
    <div class="card-body table-responsive mt-2">
      <table
        class="table table-striped border-blue text-center mt-2"
        *ngIf="listaObservacoes.length > 0"
      >
        <thead class="bg-teal-400">
          <tr>
            <th>Data</th>
            <th>Observação</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody *ngFor="let obs of listaObservacoes; let i = index">
          <tr>
            <td>{{ formatarDataBarra(obs.dtcseq) }}</td>
            <td>{{ obs.obs }}</td>
            <td class="d-flex justify-content-center">
              <button
                type="button"
                class="btn btn-outline-primary mr-2"
                data-toggle="modal"
                data-target="#cadastroObservacoesModal"
                (click)="aoAbrirModal(obs)"
              >
                <i class="fa fa-pencil"></i>
              </button>
              <button
                type="button"
                class="btn btn-outline-danger"
                (click)="deletarObservacao(obs.dtcseq)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        *ngIf="listaObservacoes.length === 0"
        class="p-4 d-flex justify-content-center align-items-center"
      >
        <h6 class="font-weight-semibold">Não há dados de observacoes para esse colaborador.</h6>
      </div>
    </div>
  </div>
</section>

<div class="modal fade" id="cadastroObservacoesModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-observacoes-diversas-cadastro
        [cadastro]="cadastroObservacao"
        [colaborador]="colaborador"
        [observacaoAtual]="observacaoAtual"
      ></app-observacoes-diversas-cadastro>
    </div>
  </div>
</div>
