import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ErroService } from '../../../../service/erros/erro.service';
import { Beneficio } from '../classe/Beneficio';
import { CadastroBeneficioService } from './cadastro-beneficio.service';
import { PartesBeneficio } from '../classe/PartesBeneficio';
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { empty } from 'rxjs';
import { AlertaModal } from 'src/app/componentes/componentes-funcionais/alerta-modal/alerta-modal.component';

@Component({
  selector: 'app-cadastro-beneficio',
  templateUrl: './cadastro-beneficio.component.html',
  styleUrls: ['./cadastro-beneficio.component.css']
})
export class CadastroBeneficioComponent implements OnInit {
  editar: boolean = false;
  temErro: boolean = false;
  badgeValoracao: string = "0,00";

  //listagem dos select inputs
  tpBeneficio: Array<any> = [];
  grupo: Array<any> = [];
  vrbExterna: Array<any> = [];
  vrbEmpresa: Array<any> = [];
  vrbFuncionario: Array<any> = [];
  verbas: Array<any> = [];

  formBeneficio: FormGroup;
  constructor(public fb: FormBuilder, private validaErro: ErroService, private cadastroService: CadastroBeneficioService, private spinner: NgxSpinnerService, private router: Router) { }

  async ngOnInit() {
    this.formBeneficio = this.fb.group({
      dtcomp: ['', Validators.compose([Validators.required])],
      tpEmpresaCheckBox: [false],
      tpFuncCheckBox: [false],
      codigo: ['', Validators.compose([Validators.required])],
      descricao: ['', Validators.compose([Validators.required])],
      grupo: ['', Validators.compose([Validators.required])],
      f03: [''],

      verbaEmpresa: [{ value: '', disabled: true }],
      valorEmpresa: [{ value: '', disabled: true }],
      tipoEmpresa: [{ value: '', disabled: true }],
      verbaFuncionario: [{ value: '', disabled: true }],
      valorFuncionario: [{ value: '', disabled: true }],
      tipoFuncionario: [{ value: '', disabled: true }],

      frequencia: [''],
      validade: [''],
      dataInicial: ['', Validators.compose([Validators.required,])],
      dataFinal: ['', Validators.compose([Validators.required,])],
      idadeMinimaMeses: [''],
      idadeMaximaMeses: [''],
      constanteExternaVrb: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      f02: ['', Validators.compose([Validators.required])],
      verba: ['', Validators.compose([Validators.required])],
    });
    await this.carregarPropriedades()

    if (history.state.data) {
      this.carregarCampos();
    }
  }

  async carregarPropriedades() {
    this.listarComboTipoBeneficio();
    this.listarComboGrupo();
    this.listaVerbasEmpresa();
    this.listaVerbasFuncionario();
    this.listarVerbas();
  }
  formatarDataEdit(data: string): string {

    let horario = data.split(' ')
    let formatada = horario[0].split("/")
    return `${formatada[2]}-${formatada[1]}-${formatada[0]}`
  }
  
  formatarData(data: string): string {
    
    let formatada = data.split("-")
    return `${formatada[0]}${formatada[1]}${formatada[2]}`
  }

  carregarCampos() {
    this.editar = true;
    const beneficio: Beneficio = history.state.data;
    const empresa: PartesBeneficio = beneficio.PartesBeneficio.filter(parte => parte.Parte == 'E')[0];
    const funcionario: PartesBeneficio = beneficio.PartesBeneficio.filter(parte => parte.Parte == 'F')[0];
    console.log(this.formatarDataEdit(beneficio.DataInicial))
    this.badgeValoracao = beneficio.ConstanteExterna.Valor.toString() === '0' ? '0,00' : beneficio.ConstanteExterna.Valor.toString();
    this.formBeneficio.controls['dtcomp'].setValue(beneficio.ConstanteExterna.ANomes ? beneficio.ConstanteExterna.ANomes.substr(0, 4) + "-" + beneficio.ConstanteExterna.ANomes.substr(-2) : '');
    this.dtcompChange()
    this.formBeneficio.controls['codigo'].setValue(beneficio.Codigo);
    this.formBeneficio.controls['descricao'].setValue(beneficio.Descricao);
    this.formBeneficio.controls['grupo'].setValue(beneficio.Grupo.Codigo);
    this.formBeneficio.controls['f03'].setValue(beneficio.F03 === '0' ? '' : beneficio.F03);
    if (empresa.Valor !== 0 && empresa.Tipo.trim() !== '') {
      this.formBeneficio.controls['tpEmpresaCheckBox'].setValue(true);
      this.formBeneficio.controls['verbaEmpresa'].setValue(empresa ? empresa.Verba.Cod : '');
      this.formBeneficio.controls['valorEmpresa'].setValue(empresa ? empresa.Valor : '');
      this.formBeneficio.controls['tipoEmpresa'].setValue(empresa ? empresa.Tipo : '');
      this.empresaCheckBox();
    }
    if (funcionario.Valor !== 0 && funcionario.Tipo.trim() !== '') {
      this.formBeneficio.controls['tpFuncCheckBox'].setValue(true);
      this.formBeneficio.controls['verbaFuncionario'].setValue(funcionario ? funcionario.Verba.Cod : '');
      this.formBeneficio.controls['valorFuncionario'].setValue(funcionario ? funcionario.Valor : '');
      this.formBeneficio.controls['tipoFuncionario'].setValue(funcionario ? funcionario.Tipo : '');
      this.funcionarioCheckBox();
    }

    this.formBeneficio.controls['frequencia'].setValue(beneficio.Frequencia);
    this.formBeneficio.controls['validade'].setValue(beneficio.ValidadeMeses);
    this.formBeneficio.controls['dataInicial'].setValue(this.formatarDataEdit(beneficio.DataInicial));
    this.formBeneficio.controls['dataFinal'].setValue(this.formatarDataEdit(beneficio.DataFinal));
    this.formBeneficio.controls['idadeMinimaMeses'].setValue(beneficio.IdadeMinimaMeses == 0 ? '' : beneficio.IdadeMinimaMeses);
    this.formBeneficio.controls['idadeMaximaMeses'].setValue(beneficio.IdadeMaximaMeses == 0 ? '' : beneficio.IdadeMaximaMeses);
    this.formBeneficio.controls['constanteExternaVrb'].setValue(beneficio.ConstanteExterna.Verbas);
    this.formBeneficio.controls['f02'].setValue(beneficio.F02);
    this.formBeneficio.controls['verba'].setValue(beneficio.Verba.Cod == 0 ? '' : beneficio.Verba.Cod);
    this.formBeneficio.get('codigo').disable();
  }

  dtcompChange() {
    let { dtcomp } = this.formBeneficio.value;
    var [ano, mes] = dtcomp.split("-");
    dtcomp = ano + mes;
    if (dtcomp.length == 6) {
      this.listaVerbasExterna();
    }
  }

  alteraValoracao() {
    const [valoracao] = this.vrbExterna.filter(item => item.Verbas == this.formBeneficio.value.constanteExternaVrb);

    this.badgeValoracao = valoracao.Valor.toFixed(2).replace('.', ',');
  }

  //listagem para select input
  listarComboTipoBeneficio() {
    this.spinner.show();

    this.cadastroService.listarComboTipoBeneficio()
      .subscribe(
        (data: any) => {
          this.tpBeneficio = data;
          this.spinner.hide();

        },
        error => this.validaErro.retornoErro(error)
      )
  }

  listarComboGrupo() {
    this.spinner.show();

    this.cadastroService.listarComboGrupo()
      .subscribe(
        (data: any) => {
          this.grupo = data;
          this.spinner.hide();
        },
        error => this.validaErro.retornoErro(error)
      )
  }

  listaVerbasExterna() {
    this.spinner.show();
    let competencia = this.formBeneficio.value.dtcomp;
    var [ano, mes] = competencia.split("-");
    competencia = ano + mes;


    this.cadastroService.listaVerbasExterna(competencia)
      .subscribe(
        (data: any) => {
          const constanteExternaVrb = this.formBeneficio.get('constanteExternaVrb');
          this.vrbExterna = data;
          constanteExternaVrb.enable()
          this.spinner.hide();
        },
        error => this.validaErro.retornoErro(error)
      )
  }

  listaVerbasEmpresa() {
    this.spinner.show();

    this.cadastroService.listaVerbasEmpresa()
      .subscribe(
        (data: any) => {
          this.vrbEmpresa = data;
          this.spinner.hide();
        },
        error => this.validaErro.retornoErro(error)
      )
  }

  listaVerbasFuncionario() {
    this.spinner.show();

    this.cadastroService.listaVerbasFuncionario()
      .subscribe(
        (data: any) => {
          this.vrbFuncionario = data;
          this.spinner.hide();
        },
        error => this.validaErro.retornoErro(error)
      )
  }

  listarVerbas() {
    this.spinner.show();

    this.cadastroService.listarVerbas()
      .subscribe(
        (data: any) => {
          this.verbas = data;
          this.spinner.hide();
        },
        error => this.validaErro.retornoErro(error)
      )
  }

  empresaCheckBox() {
    const valor = this.formBeneficio.get('valorEmpresa');
    const verba = this.formBeneficio.get('verbaEmpresa');
    const tipo = this.formBeneficio.get('tipoEmpresa');
    if (this.formBeneficio.value.tpEmpresaCheckBox) {
      valor.enable();
      verba.enable();
      tipo.enable();
      valor.setValidators(Validators.required);
      verba.setValidators(null);
      tipo.setValidators(Validators.required);
      tipo.setValidators(Validators.required);
      valor.updateValueAndValidity();
      verba.updateValueAndValidity();
      tipo.updateValueAndValidity();
    } else {
      valor.disable();
      verba.disable();
      tipo.disable();
      valor.setValidators(null);
      verba.setValidators(null);
      tipo.setValidators(null);
      valor.setValue('');
      verba.setValue('');
      tipo.setValue('');
      valor.updateValueAndValidity();
      verba.updateValueAndValidity();
      tipo.updateValueAndValidity();
    }

  }

  funcionarioCheckBox() {
    const valor = this.formBeneficio.get('valorFuncionario');
    const verba = this.formBeneficio.get('verbaFuncionario');
    const tipo = this.formBeneficio.get('tipoFuncionario');
    if (this.formBeneficio.value.tpFuncCheckBox) {
      valor.enable();
      verba.enable();
      tipo.enable();
      valor.setValidators(Validators.required);
      verba.setValidators(null);
      tipo.setValidators(Validators.required);
      valor.updateValueAndValidity();
      verba.updateValueAndValidity();
      tipo.updateValueAndValidity();
    } else {
      valor.disable();
      verba.disable();
      tipo.disable();
      valor.setValidators(null);
      verba.setValidators(null);
      tipo.setValidators(null);
      valor.setValue('');
      verba.setValue('');
      tipo.setValue('');
      valor.updateValueAndValidity();
      verba.updateValueAndValidity();
      tipo.updateValueAndValidity();
    }
  }

  submitBeneficio() {
    if (this.formBeneficio.valid) {

      if (!this.formBeneficio.get('tpEmpresaCheckBox').value && !this.formBeneficio.get('tpFuncCheckBox').value) {
        this.temErro = true;
        new AlertaModal("alerta", "Atenção", "Informe ao menos uma opção para a origem do recurso.", 'p');
      } else {
        if (this.validarValorPartesVerba()) {
          this.temErro = false;

          if (!this.editar) {
            this.salvarBeneficio();
          } else {
            this.editarBeneficio();
          }
        } else {
          this.temErro = true;
          new AlertaModal("alerta", "Atenção", "Valor das partes envolvidas, não deve ultrapassar o valor da verba escolhida.", 'p');
        }
      }
    }
    else {
      this.temErro = true;
      new AlertaModal("alerta", "Atenção", "Preencha todos os campos!", 'p');
    }
  }

  validaDataRangeBeneficio(): string {
    const beneficio = this.formBeneficio.getRawValue();
    if (new Date(beneficio.dataInicial.substr(4, 4) + '-' + beneficio.dataInicial.substr(2, 2) + '-' + beneficio.dataInicial.substr(0, 2) + 'T00:00:00') > new Date(beneficio.dataFinal.substr(4, 4) + '-' + beneficio.dataFinal.substr(2, 2) + '-' + beneficio.dataFinal.substr(0, 2) + 'T00:00:00')) {
      return "Data Inicial não pode ser maior que a Data Final";
    }
    /*if (new Date(beneficio.dataFinal.substr(4, 4) + '-' + beneficio.dataFinal.substr(2, 2) + '-' + beneficio.dataFinal.substr(0, 2) + 'T00:00:00') < new Date(beneficio.dataInicial.substr(4, 4) + '-' + beneficio.dataInicial.substr(2, 2) + '-' + beneficio.dataInicial.substr(0, 2) + 'T00:00:00')) {
      return "Data Final não pode ser menor que a Data Inicial";
    }*/
    return "";
  }

  validarValorPartesVerba(): boolean {
    const beneficio = this.formBeneficio.getRawValue();
    beneficio.valorEmpresa = beneficio.valorEmpresa === '' ? 0 : beneficio.valorEmpresa;
    beneficio.valorFuncionario = beneficio.valorFuncionario === '' ? 0 : beneficio.valorFuncionario;

    if (beneficio.tipoEmpresa === '%' || beneficio.tipoFuncionario === '%') {
      if (beneficio.tipoEmpresa === '%' && beneficio.tipoFuncionario === '%') {
        return (beneficio.valorEmpresa + beneficio.valorFuncionario) <= 100;
      }

      if (beneficio.tipoEmpresa === '%') {
        if (beneficio.valorEmpresa > 100) {
          return false;
        } else {
          return ((beneficio.valorEmpresa / 100) * +(this.badgeValoracao.replace(',', '.'))) + beneficio.valorFuncionario <= +(this.badgeValoracao.replace(',', '.'));
        }
      }
      if (beneficio.tipoFuncionario === '%') {
        if (beneficio.valorFuncionario > 100) {
          return false;
        } else {
          return ((beneficio.valorFuncionario / 100) * +(this.badgeValoracao.replace(',', '.'))) + beneficio.valorEmpresa <= +(this.badgeValoracao.replace(',', '.'));
        }
      }
    }
    else {
      return ((+(this.badgeValoracao.replace(',', '.'))) >= (beneficio.valorEmpresa + beneficio.valorFuncionario));
    }
  }

  salvarBeneficio() {
    const beneficio: Beneficio = this.montaBeneficio();
    this.spinner.show();

    this.cadastroService.salvarBeneficio(beneficio)
      .subscribe(
        data => {
          new AlertaModal("sucesso", "Sucesso", "Benefício cadastrado!", 'p');
          this.router.navigate(['/beneficios/quadro-beneficios/']);
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          this.validaErro.retornoErro(error);
        }
      );
  }

  voltar() {
    this.formBeneficio.reset();
    this.router.navigate(['/beneficios/quadro-beneficios/']);
  }

  editarBeneficio() {
    const beneficio: Beneficio = this.montaBeneficio();
    this.spinner.show();


    this.cadastroService.editarBeneficio(beneficio)
      .subscribe(
        data => {
          new AlertaModal("sucesso", "Sucesso", "Benefício alterado!", 'p');
          this.router.navigate(['/beneficios/quadro-beneficios/']);
          this.spinner.hide();
        },
        error => {
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        }
      );
  }

  montaBeneficio(): Beneficio {
    const beneficio = this.formBeneficio.getRawValue();
    return {
      Codigo: beneficio.codigo,
      Descricao: beneficio.descricao,
      Criterio: '',
      Grupo: { Codigo: beneficio.grupo },
      F03: beneficio.f03 === '' ? '0' : beneficio.f03,
      PartesBeneficio: [
        {
          Parte: "E",
          CodigoBeneficio: beneficio.codigo,
          Valor: beneficio.valorEmpresa,
          Verba: { Cod: beneficio.verba },
          Tipo: beneficio.tipoEmpresa
        },
        {
          Parte: 'F',
          CodigoBeneficio: beneficio.codigo,
          Valor: beneficio.valorFuncionario,
          Verba: { Cod: beneficio.verba },
          Tipo: beneficio.tipoFuncionario
        }
      ],
      Frequencia: beneficio.frequencia == '' ? 999 : beneficio.frequencia,
      ValidadeMeses: beneficio.validade,
      DataInicial: this.formatarData(beneficio.dataInicial),
      DataFinal: beneficio.dataFinal != '' ? this.formatarData(beneficio.dataFinal) : '',
      IdadeMinimaMeses: beneficio.idadeMinimaMeses,
      IdadeMaximaMeses: beneficio.idadeMaximaMeses,
      ConstanteExterna: {
        Verbas: beneficio.constanteExternaVrb,
        ANomes: `${beneficio.dtcomp.substr(2, 4)}${beneficio.dtcomp.substr(0, 2)}`,
        Valor: this.badgeValoracao
      },
      F02: beneficio.f02,
      Verba: { Cod: beneficio.verba },
    }
  }
  corrigeDatas(dataAConverter) {
    let [data, resto] = dataAConverter.split(" ");
    let [dia, mes, ano] = data.split("/");
    return `${ano}-${mes}-${dia}`;
  }

}


