import { ProfilePDF } from './profile-PDF';
import { Ferias } from './interface-ferias/ferias';
import { Router } from '@angular/router';
import { distinct } from 'rxjs/operators';
import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileService } from './profile.service';
import { CadastroService } from '../cadastro/cadastro.service';
import { ErroService } from '../../service/erros/erro.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { Cadastro } from '../../service/classes/cadastro/cadastro';
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { FormBuilder, Validators } from '@angular/forms';
import { AnexoComponent } from '../anexo/anexo.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { SalarioService } from './componentes/salario/salario.service';
import { CadastroEmail } from 'src/app/service/interfaces/CadastroEmail';
import { ColaboradoresService } from '../colaboradores/colaboradores.service';
import { Usuario } from './../cadastro-publico/models/interfaces';
import { Sexo } from '../cadastro/interface/sexo';
import { Stiuacao } from 'src/app/service/classes/cadastro/stiuacao';
import { CadastroPublicoService } from '../cadastro-publico/cadastro-publico.service';
import { AnexoService } from '../anexo/anexo.service';
import { Dependente } from '../dependentes/dependentes';
import { DocumentosPerfilComponent } from './componentes/documentos-perfil/documentos-perfil.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  @ViewChild(AnexoComponent, { static: false }) anexoComponent: AnexoComponent;
  @ViewChild(DocumentosPerfilComponent, { static: false })
  documentosPerfilComponent: DocumentosPerfilComponent;

  localStorage: LocalStorage;

  listaDependente: Dependente[];
  listaPendencias: any[];

  uf: any[] = [];
  bancos: any[];
  bancoUsuario: {
    Codigo: string;
    Nome: string;
  };

  matricula: string;
  cadastroUsuario: Cadastro;
  cadastroUsuarioTemporario = new Cadastro();
  docImage: string = '';
  tipoUsuario: string;
  idadeMinima: number = 18;
  emailCredencial: string = '';
  confirmarSenha: string;
  senhaAtual: string;
  novaSenha: string;
  formMudaSenha: any;
  rotaParametro: any;
  dadosUsuario: any = {
    Pessoal: {
      Nome: '',
      Sexo: '',
      Matricula: '',
      Data_nascimento: '',
      Sindicato: '',
      Mae: '',
      Pai: '',
      Data_admissao: '',
      Cor: '',
      Nacionalidade: '',
      Vinculo: '',
      Estado_civil: '',
    },
    Contato: {
      Email: '',
      Celular: '',
      Telefone: '',
      Cep: '',
      Endereco: '',
      Bairro: '',
      Municipio: '',
      Complemento: '',
      Estado: '',
      Cidade: '',
    },
    Documentos: {
      Cpf: '',
      Rg: '',
      Emissor: '',
      Uf: '',
      Cnh: '',
      Categoria_cnh: '',
      Data_expedicao_cnh: '',
      Data_validade_cnh: '',
      Carteira_trabalho: '',
      Serie_ctps: '',
      Data_emissao_ctps: '',
      Uf_ctps: '',
      Pis: '',
      Titulo_eleitor: '',
      Sessao_eleitoral: '',
      Estrangeiro: '',
    },
    Salario: {
      Banco: '',
      Agencia: '',
      Operacao: '',
      Conta: '',
      Salario: '',
    },
    Ferias: {
      Saldo: '',
      Data_vencimento: '',
      Proximo_vencimento: '',
    },
  };

  existeCredencial: boolean = true;
  enviarNovoEmail: boolean = null;
  digitarEmail: boolean = false;
  telaDependente: boolean = true;
  showDropDown = false;
  btDeleteDoc: boolean = true;
  showinput: boolean = false;
  temErroSenha: boolean = false;
  ColaboradorInativo: boolean = true;
  tabelaPerfil = [];
  mudancaTab: boolean = false;
  documentosPendentesData: any;
  mensagemEmail: string;

  constructor(
    private cdref: ChangeDetectorRef,
    public profileService: ProfileService,
    private validaErro: ErroService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private anexoService: AnexoService,
    private salarioService: SalarioService,
    private colaboradoresService: ColaboradoresService,
    private cadastroPublicoService: CadastroPublicoService
  ) {
    this.localStorage = this.decryptLocalStorage();
    const numOrd = this.localStorage.NumeroOrdemUsuario;
    this.route.params.subscribe((params) => (this.rotaParametro = params['numOrdem']));
    this.tipoUsuario = this.localStorage.TipoUsuario;
    const navigation = this.router.getCurrentNavigation();

    if (this.tipoUsuario == '1' && numOrd != this.rotaParametro) {
      this.router.navigate([`/perfil`, numOrd]);
    }
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.cadastroUsuario = new Cadastro();
    this.buscarCadastro();

    this.buscarUf();
    this.formMudaSenha = this.fb.group({
      email: [
        '',
        Validators.compose([Validators.required, Validators.email, Validators.maxLength(50)]),
      ],
    });
  }

  copiarTexto(inputElement: HTMLInputElement) {
    const texto = inputElement.value;
    navigator.clipboard
      .writeText(texto)
      .then(() => {
        console.log('Texto copiado para a área de transferência');
      })
      .catch((err) => {
        console.error('Erro ao copiar texto: ', err);
      });
  }

  formataCPF(cpf: string) {
    if (cpf != null) {
      // Colocar zero a esquerda do cpf
      if (cpf.length < 11) {
        const resto = 11 - cpf.length;
        cpf = '0'.repeat(resto > 0 ? resto : 0) + cpf;
      }
      //retira os caracteres indesejados...
      cpf = cpf.replace(/[^\d]/g, '');
      //realizar a formatação...
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      return '00000000';
    }
  }

  emissao(evento) {
    this.telaDependente = evento;
  }

  attCadastro(cadastro) {
    this.cadastroUsuario = cadastro;
  }

  clickMudancaTabs() {
    this.mudancaTab = !this.mudancaTab;
  }

  criarCredenciais(emailFornecido: string = null): void {
    const { Email1, Email2 } = this.cadastroUsuario.Pessoal.Contato;
    if (emailFornecido == null && Email1.trim() === '' && Email2.trim() === '') {
      Swal('Atenção', 'Este colaborador não possui um email informado.', 'warning');
    } else {
      this.spinner.show();

      const dados = {
        Email: emailFornecido
          ? emailFornecido.trim()
          : Email1.trim() !== ''
          ? Email1.trim()
          : Email2.trim() !== '',
        Cpf: this.cadastroUsuario.Documentos.Cpf,
        Nome: this.cadastroUsuario.InformacoesBasicas.NomeCompleto,
        EmpresaFilial: this.localStorage.FilialAtual,
        NomeEmpresaFilial: this.localStorage.NomeFilialAtual,
        NumeroDeOrdem: this.cadastroUsuario.NumeroDeOrdem,
        CodigoCliente: this.localStorage.ClienteId,
        Tipo: '1',
      };

      this.profileService.criarCredenciais(dados).subscribe(
        (data) => {
          document.getElementById('fecharModalCredencial').click();
          this.spinner.hide();
          Swal('Sucesso', 'Credenciais enviado para o email.', 'success');
          this.existeCredencial = true;
          this.enviarNovoEmail = null;
          this.emailCredencial = '';
        },
        (error) => {
          this.existeCredencial = false;
          this.enviarNovoEmail = null;
          this.emailCredencial = '';
          this.spinner.hide();
          this.validaErro.retornoErro(error);
        }
      );
    }
  }

  buscarExisteUsuario() {
    this.profileService.buscarExisteUsuario(this.cadastroUsuario.Documentos.Cpf).subscribe(
      (data: boolean) => (this.existeCredencial = data),
      (error) => this.validaErro.retornoErro(error)
    );
  }
  retornarMatricula() {
    this.profileService.retornarMatricula().subscribe((matricula) => {
      this.matricula = matricula;
    });
  }
  // Busca o cadastro de acordo com o número de ordem recebido por parâmetro
  buscarCadastro() {
    this.profileService.buscarCadastro(this.rotaParametro).subscribe(
      (data) => {
        this.cadastroUsuario = JSON.parse(JSON.stringify(data));
        this.profileService.salvarMatricula(this.cadastroUsuario.Matricula);
        this.retornarMatricula();
        // this.buscarDocumentosCadastro(this.cadastroUsuario.Matricula);
        this.cadastroUsuario.Pessoal.Endereco.Cep = this.padLeft(
          this.cadastroUsuario.Pessoal.Endereco.Cep.toString(),
          8
        );
        this.dadosUsuario.Pessoal.Nome = this.cadastroUsuario.InformacoesBasicas.NomeCompleto;
        this.buscarDependentes(this.cadastroUsuario.Matricula);
        this.buscarExisteUsuario();
        if (
          this.cadastroUsuario.Stiuacao.Descricao.includes('ATIVO') ||
          this.cadastroUsuario.Stiuacao.Descricao.includes('EM ADMISSAO') ||
          this.cadastroUsuario.Stiuacao.Codigo == 6
        ) {
          this.ColaboradorInativo = false;
        }

        this.buscarBanco();
        this.cdref.detectChanges();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        //Swal.fire('Erro', 'Não foi possível obter as informações das filiais.', 'error');
      }
    );
  }

  buscarDependentes(matricula) {
    this.profileService.buscarDependentes(matricula).subscribe(
      (data) => {
        this.listaDependente = JSON.parse(JSON.stringify(data));
      },
      (error) => {
        this.validaErro.retornoErro(error);
        //Swal.fire('Erro', 'Não foi possível obter as informações das filiais.', 'error');
      }
    );
  }

  buscarBanco() {
    this.salarioService
      .buscarBancos()
      .pipe(distinct())
      .subscribe(
        (data) => {
          this.bancos = JSON.parse(JSON.stringify(data));

          const bancoEscolhido = this.bancos.filter(
            (banco) => banco.Codigo == this.cadastroUsuario.DadosBancarios.Banco
          );
          if (bancoEscolhido.length > 0) {
            this.bancoUsuario = bancoEscolhido[0];
          } else {
            this.bancoUsuario = { Codigo: '0', Nome: '0' };
          }
        },
        (error) => {
          this.validaErro.retornoErro(error);
        }
      );
  }

  closeModalSenha() {
    document.getElementById('close').click();
    this.formMudaSenha.controls['email'].setValue('');
    this.temErroSenha = false;
    this.enviarNovoEmail = null;
  }

  enviarSenha() {
    this.mudarValidacaoSendEmail();
    this.spinner.show();
    if (!this.formMudaSenha.valid) {
      this.temErroSenha = true;
      Swal('Atenção!', 'preencha o campo corretamente', 'warning');
    } else {
      const cadastro = this.montarCadastroEmail();
      this.profileService.enviarEmail(cadastro).subscribe(
        (data) => {
          this.spinner.hide();
          this.closeModalSenha();
          Swal('Sucesso', 'Email com a nova senha foi enviado corretamente.', 'success');
        },
        (error) => {
          this.spinner.hide();
          this.enviarNovoEmail = null;
          this.validaErro.retornoErro(error);
        }
      );
      return false;
    }
    this.spinner.hide();
  }
  mudarValidacaoSendEmail(): void {
    if (this.enviarNovoEmail) {
      this.formMudaSenha.controls['email'].setValidators(Validators.required, dataValidator);
      this.formMudaSenha.controls['email'].updateValueAndValidity();
    } else {
      this.formMudaSenha.controls['email'].setValidators(null);
      this.formMudaSenha.controls['email'].updateValueAndValidity();
    }
  }

  buscarDocumentosCadastro(matricula: string) {
    this.profileService.getDocumentos(matricula).subscribe(
      (data) => {
        let documentos: any = data;
        documentos = documentos.filter((documento) => documento.MatriculaDependente.trim() == '0');
        this.cadastroUsuario.UploadDocumentos = documentos.map((documento) => {
          if (documento.Extensao.trim() == 'imagem') {
            documento.ArquivoBase64 = 'data:image/jpeg;base64,' + documento.ArquivoBase64;
          }

          return documento;
        });
      },
      (error) => this.validaErro.retornoErro(error)
    );
  }

  buscarUf() {
    this.profileService.buscarEstado().subscribe(
      (data) => {
        this.uf = JSON.parse(JSON.stringify(data));
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  // Retorna uma string de um número formatado com zero à esquerda
  padLeft(num: string, tamanho: number): string {
    let s: string = num;

    while (s.length < tamanho) {
      s = '0' + s;
    }
    return s;
  }

  generatePdf(cadastro: Cadastro): void {
    const profilePdf: ProfilePDF = new ProfilePDF();
    profilePdf.gerarPDF(cadastro);
  }
  montarCadastroEmail(): CadastroEmail {
    const Email = this.formMudaSenha.getRawValue();

    return {
      Email: this.enviarNovoEmail ? Email.email : this.cadastroUsuario.Pessoal.Contato.Email1,
      Cpf: this.cadastroUsuario.Documentos.Cpf,
      CodFilial: Number(this.cadastroUsuario.Filial.Codigo),
      Sigla: 'FAPP',
      NomeFilial: String(this.localStorage.NomeFilialAtual),
    };
  }

  ativarCadastro(): void {
    const pendencias = this.listaPendencias.filter((item) => item.Pendente == 1);
    const mensagem =
      'Deseja efetivar o cadastro de ' +
      this.cadastroUsuario.InformacoesBasicas.NomeCompleto +
      '?' +
      (pendencias.length > 0
        ? ' Esse colaborador tem ' +
          pendencias.length +
          ' documento(s) pendente(s), caso deseje realmente prosseguir, confirme abaixo.'
        : '');
    Swal({
      title: mensagem,
      text: 'Confirme para executar a ativação do cadastro.',
      icon: pendencias.length > 0 ? 'warning' : 'info',
      dangerMode: pendencias.length > 0 ? true : false,
      buttons: {
        cancel: {
          text: 'Cancelar',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        const clientePublico = this.localStorage.ClientePublico;
        const colabEfet = {
          NomeCompleto: this.cadastroUsuario.InformacoesBasicas.NomeCompleto,
          Empfil: this.localStorage.FilialAtual,
          NumeroDeOrdem: this.cadastroUsuario.NumeroDeOrdem,
          Matricula: this.cadastroUsuario.Matricula,
          Cpf: this.cadastroUsuario.Documentos.Cpf,
          Etapa: this.cadastroUsuario.Etapa,
          NomeSetor: this.cadastroUsuario.InformacoesBasicas.Setor,
          NomeCargo: this.cadastroUsuario.InformacoesBasicas.Cargo,
          Email: this.cadastroUsuario.Pessoal.Contato.Email1,
          TipoComplemento: '',
          Sexo: {
            Valor: this.cadastroUsuario.Pessoal.DadosBasicos.Sexo.Valor,
            Descricao: this.cadastroUsuario.Pessoal.DadosBasicos.Sexo.Descricao,
          },
          Situacao: {
            Codigo: this.cadastroUsuario.Stiuacao.Codigo,
            Descricao: this.cadastroUsuario.Stiuacao.Descricao,
          },
        };
        if (clientePublico) {
          colabEfet.Etapa = 4;
          this.efetivarCadastroPublico(colabEfet);
          location.reload();
        } else {
          this.efetivarCadastro(colabEfet);
          location.reload();
        }
      }
    });
  }

  efetivarCadastroPublico(colaborador: any): void {
    this.spinner.show();
    // this.efetivarParametros.NumeroDeOrdem = colaborador.NumeroDeOrdem;
    // this.efetivarParametros.Matricula = colaborador.Matricula;
    // this.efetivarParametros.Etapa = 4;

    this.colaboradoresService.efetivarCadastroPublico(colaborador.NumeroDeOrdem).subscribe(
      (data) => {
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'Efetivado com sucesso!', 'p');
        // this.limpaFiltro = true;
        // this.buscarColaboradoresFiltrados();
        this.criarUsuario(colaborador);
        scrollTo(0, 0);
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  efetivarCadastro(colaborador: any): void {
    this.spinner.show();
    // this.efetivarParametros.NumeroDeOrdem = colaborador.NumeroDeOrdem;
    // this.efetivarParametros.Matricula = colaborador.Matricula;
    // this.efetivarParametros.Etapa = 4;

    this.colaboradoresService.efetivarCadastro(colaborador.NumeroDeOrdem).subscribe(
      (data) => {
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'Efetivado com sucesso!', 'p');
        // this.limpaFiltro = true;
        // this.buscarColaboradoresFiltrados();
        this.criarUsuario(colaborador);
        scrollTo(0, 0);
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  criarUsuario(cadastro: any): void {
    const usuario: Usuario = this.montaUsuario(cadastro);

    this.spinner.show();
    this.cadastroPublicoService.criarUsuario(usuario).subscribe(
      (data) => {
        this.spinner.hide();

        new AlertaModal('sucesso', 'Sucesso!', 'Usuario Criado.', 'p');
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  montaUsuario(colaborador: Colaborador): Usuario {
    var filial = this.localStorage.FilialAtual.toString();
    var nomeFilial = this.localStorage.NomeFilialAtual;
    var codigoCliente = this.localStorage.ClienteId.toString();

    return {
      Email: colaborador.Email,
      Cpf: colaborador.Cpf,
      Nome: colaborador.NomeCompleto,
      EmpresaFilial: filial,
      NomeEmpresaFilial: nomeFilial,
      NumeroDeOrdem: Number(colaborador.NumeroDeOrdem),
      CodigoCliente: codigoCliente,
    };
  }

  montaListaPendencias(listaPendencias: Array<any>) {
    this.listaPendencias = listaPendencias;
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}

export interface Colaborador {
  Cpf: string;
  Email: string;
  Empfil: string;
  Etapa: number;
  Matricula: string;
  NomeCargo: string;
  NomeCompleto: string;
  NomeSetor: string;
  NumeroDeOrdem: string;
  Sexo: Sexo;
  Situacao: Stiuacao;
}
