import { Stiuacao } from 'src/app/service/classes/cadastro/stiuacao';
import { Supervisor } from 'src/app/service/interfaces/Supervisor';
import { Matricula } from './../../contracheque/contracheque.component';
import { ObservacoesFichaFuncionalService } from './observacoes-ficha-funcional.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import Swal from 'sweetalert';
import { Permissao } from 'src/app/service/permissoes/permissoes.service';

@Component({
  selector: 'app-observacoes-ficha-funcional',
  templateUrl: './observacoes-ficha-funcional.component.html',
  styleUrls: ['./observacoes-ficha-funcional.component.css'],
})
export class ObservacoesFichaFuncionalComponent {
  @Output() gatilhoAtivado = new EventEmitter<any>();
  @Input() colaborador: any;
  @Input() tipo: string;
  @Input() permissoes: Permissao[];
  podeEditar = true;
  localStorage: LocalStorage;
  compchave: string = '';
  cadastro: boolean = true;
  formObservacoes: FormGroup;
  listaObservacoesAuxiliar = [];
  listaObservacoes: any = [];
  filialAtual: string = '';
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private observacoesService: ObservacoesFichaFuncionalService
  ) {
    this.localStorage = this.decryptLocalStorage();

    this.filialAtual = String(this.localStorage.FilialAtual);
  }

  ngOnInit() {
    this.formObservacoes = this.fb.group({
      observacao: ['', Validators.required],
      data: ['', Validators.required],
    });
  }

  abrirModal(data?: any, tipo?: string) {
    if (data && tipo) {
      this.tipo = tipo;
    }
    this.limparForm();
    switch (this.tipo) {
      case '703':
        // Tempo de Serviço
        this.compchave = data.DtIni.SetData + data.DtFim.SetData;
        break;
      case '007':
        // Faltas ao Trabalho
        this.compchave =
          data.DtInicio.SetData + data.DtVolta.SetData + data.CodAfa + data.CodMot + data.Seq;
        break;
      case '010':
        // Promoções
        this.compchave = data.DtComp + data.TpProc + data.Seq;
        break;
      case '013':
        // Prontuario Médico
        this.compchave = data.DtInsp.SetData;
        break;
      case '009':
        // Formação Básica e Específica
        this.compchave = data.DtTermino.SetData + data.CodCrs;
        break;
      case '018':
        // Licença Prêmio
        this.compchave = data.Seq;
        break;
      case '110':
        // Comissão
        this.compchave = data.Seq;
        break;
      case '207':
        // Experiência Profissional
        this.compchave = '';
        break;
      case '208':
        // Lançamento Financeiro 
        this.compchave = data.CodigoVerba.padStart(3, '0') + data.Transacao.padStart(2, '0');
        break;
      case '012':
        // Pensão Alimentícia
        this.compchave = data.CodigoPensao;
        break;
      case '014':
        // Complemento Cadastral /Estágio

        this.compchave =
          data.estagiario.NumeroOrdem.padStart(7, '0') +
          data.contrato.NumeroProcesso +
          data.contrato.DataInicial.SetData +
          data.contrato.DataFinal.SetData;
        break;
      case '049':
        // Complemento Cadastral / Cessão
        this.compchave =
          data.cessao.NumeroOrdem.padStart(7, '0') +
          data.contrato.NumeroProcesso +
          data.contrato.DataInicial.SetData +
          data.contrato.DataFinal.SetData;
        //numord+numeroProcesso+VirInicial+Virgfinal
        break;
      case '016':
        // Processos
        //Deferido == Situacao 3
        if (
          data.Situacao != 3 ||
          (data.Situacao == 3 && this.permissoes.find((p) => p.Descricao === 'Deferido'))
        ) {
          this.podeEditar = true;
        } else {
          this.podeEditar = false;
        }
        this.compchave = data.NumeroProcesso + data.DataRet.SetData + data.HoraRet;
        break;
      case '300':
        // Situação Funcional
        // compchave  dtinicio
        this.compchave = '';
        break;
      default:
        Swal('Erro', 'Tipo de página/observação não encontrado', 'error').then(() => {
          document.getElementById('btnCancelarObservacoesModal').click();
        });
        return;
    }
    this.buscarObservacoes();
  }

  buscarObservacoes() {
    this.spinner.show('buscarObs');
    this.observacoesService
      .buscarObservacoes(this.filialAtual, this.colaborador.Matricula, this.tipo, this.compchave)
      .subscribe(
        (data: any) => {
          if (data) {
            let listaObs = data.sort((a, b) => a.Seq - b.Seq);
            this.unirObservacoes(listaObs);
          } else {
            this.listaObservacoes = [];
          }
          this.spinner.hide('buscarObs');
        },
        (err) => {
          console.error(err);
          this.spinner.hide('buscarObs');
        }
      );
  }

  formatarDataBarra(data: string): string {
    let ano = data.slice(0, 4);
    let mes = data.slice(4, 6);
    let dia = data.slice(6, 8);

    return dia + '/' + mes + '/' + ano;
  }

  unirObservacoes(dadosBase: any[]) {
    this.listaObservacoesAuxiliar = [];
    this.listaObservacoes = [];
    dadosBase.forEach((item) => {
      let dtcseq = item.DTCSeq;
      let obs = item.Obs;

      if (!this.listaObservacoesAuxiliar[dtcseq]) {
        this.listaObservacoesAuxiliar[dtcseq] = [];
      }

      this.listaObservacoesAuxiliar[dtcseq].push(obs.trim());
    });
    this.listaObservacoes = Object.keys(this.listaObservacoesAuxiliar).map((dtcseq) => {
      return { obs: this.listaObservacoesAuxiliar[dtcseq].join('\n'), dtcseq: dtcseq };
    });
  }

  montarObservacao() {
    let dataAtual = new Date();
    let ano = dataAtual.getFullYear().toString();
    let mes = dataAtual.getMonth().toString();
    if (mes.length == 1) {
      mes = '0' + mes;
    }
    let dia = dataAtual.getDay().toString();
    if (dia.length == 1) {
      dia = '0' + dia;
    }
    let hora = dataAtual.getHours().toString();
    if (hora.length == 1) {
      hora = '0' + hora;
    }
    let minuto = dataAtual.getMinutes().toString();
    if (minuto.length == 1) {
      minuto = '0' + minuto;
    }
    console.log('this.colabor', this.colaborador);
    let observacaoMontada: ObservacaoEnvio = {
      Sigla: 'FAPP',
      Empfil: parseInt(this.filialAtual),
      Mat: parseInt(this.colaborador.Matricula),
      Tipo: this.tipo,
      CompChave: this.compchave,
      DTCSeq: this.formObservacoes.get('data').value.replace(/-/g, ''),
      Obs: this.formObservacoes.get('observacao').value,
      DataRet: ano + mes + dia,
      HoraRet: hora + minuto,
      CodCad: '',
    };
    console.log('obs montada', observacaoMontada);
    return observacaoMontada;
  }
  limparForm() {
    this.formObservacoes.reset();
    this.formObservacoes.get('data').enable();
    this.cadastro = true;
  }
  formatarDataHifen(data: string): string {
    let ano = data.slice(0, 4);
    let mes = data.slice(4, 6);
    let dia = data.slice(6, 8);

    return `${ano}-${mes}-${dia}`;
  }

  editarObservacao(obs: { obs: string; dtcseq: string }) {
    this.formObservacoes.get('observacao').setValue(obs.obs.trim());
    this.formObservacoes.get('data').setValue(this.formatarDataHifen(obs.dtcseq));
    this.formObservacoes.get('data').disable();
    this.formObservacoes.updateValueAndValidity();
    this.cadastro = false;
  }

  cadastrarOuAtualizarObservacao() {
    if (this.formObservacoes.valid) {
      if (this.cadastro) {
        this.spinner.show('createOrUpdate');
        this.observacoesService.inserirObservacao(this.montarObservacao()).subscribe(
          (data) => {
            this.buscarObservacoes();
            this.limparForm();
            this.gatilhoAtivado.emit();
            this.spinner.hide('createOrUpdate');
          },
          (err) => {
            console.error(err);
            this.spinner.hide('createOrUpdate');
          }
        );
      } else {
        this.spinner.show('createOrUpdate');
        this.observacoesService.atualizarObservacao(this.montarObservacao()).subscribe(
          (data) => {
            this.buscarObservacoes();
            this.limparForm();
            this.spinner.hide('createOrUpdate');
          },
          (err) => {
            console.error(err);
            this.spinner.hide('createOrUpdate');
          }
        );
      }
      this.cadastro = true;
    } else {
      this.formObservacoes.get('observacao').markAsTouched();
      this.formObservacoes.get('data').markAllAsTouched();
    }
  }

  deletarObservacao(obs: any) {
    Swal({
      title: 'Deseja realmente excluir essa observação?',
      text: 'Essa ação não pode ser desfeita.',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
    }).then((result) => {
      if (result) {
        this.spinner.show('deleteObservacao');
        this.observacoesService
          .deletarObservacao(
            this.filialAtual,
            this.colaborador.Matricula,
            this.tipo,
            obs.dtcseq,
            this.compchave
          )
          .subscribe(
            () => {
              this.buscarObservacoes();
              this.limparForm();
              this.gatilhoAtivado.emit();
              this.spinner.hide('deleteObservacao');
            },
            (err) => {
              console.error(err);
            }
          );
      }
    });
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}

export interface ObservacaoEnvio {
  Sigla: string;
  Empfil: number;
  Mat: number;
  Tipo: string;
  CompChave?: string;
  DTCSeq: string;
  Obs?: string;
  DataRet?: string;
  HoraRet?: string;
  CodCad: string;
}
