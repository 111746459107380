import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { CadastroPublicoService } from 'src/app/componentes/cadastro-publico/cadastro-publico.service';
import { Endereco } from 'src/app/service/classes/Endereco-Padrao/endereco';
import Swal from 'sweetalert';
import { ErroService } from 'src/app/service/erros/erro.service';
import { EnderecoService } from './endereco.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { ContatosSecundarios } from 'src/app/componentes/cadastro-publico/models/interfaces';
import { TelefonesSecundarios } from 'src/app/service/interfaces/TelefonesSecundarios';

@Component({
  selector: 'app-endereco',
  templateUrl: './endereco.component.html',
  styleUrls: ['./endereco.component.css'],
})
export class EnderecoComponent implements OnInit {
  @Input() cadastroUsuario: Cadastro;
  @Input() ColaboradorInativo: boolean;
  @Input() mudancaTab: boolean;
  @Output() alterarCadastro: EventEmitter<any> = new EventEmitter();

  localStorage: LocalStorage;

  formEndereco: FormGroup;
  formContato: FormGroup;

  tipoUsuario: string;

  displayContato: boolean = false;
  displayEndereco: boolean = false;
  loadingCep: boolean = false;
  cepValido: boolean = true;
  temErro: boolean = false;
  loading: boolean = false;
  exclusao: boolean = false;
  listaContatosSecundarios: TelefonesSecundarios[];
  listaAuxiliar: TelefonesSecundarios[];

  readonly botoes = [
    {
      label: 'Editar',
      color: 'warning',
      icon: 'fa-pencil-square-o',
      action: () => (this.displayEndereco = !this.displayEndereco),
      show: () => !this.displayEndereco && !this.ColaboradorInativo && this.tipoUsuario != '1',
    },
    {
      label: 'Cancelar',
      color: 'danger',
      action: () => this.cancelarEdicaoEndereco(),
      show: () => this.displayEndereco,
    },
    {
      label: 'Salvar',
      color: 'primary',
      action: () => this.submitEndereco(),
      show: () => this.displayEndereco,
    },
  ];
  readonly botoesTelefone = [
    {
      label: 'Editar',
      color: 'warning',
      icon: 'fa-pencil-square-o',
      action: () => (this.displayContato = !this.displayContato),
      show: () => !this.displayContato && !this.ColaboradorInativo && this.tipoUsuario != '1',
    },
    {
      label: 'Cancelar',
      color: 'danger',
      action: () => this.cancelarEdicaoContato(),
      show: () => this.displayContato,
    },
    {
      label: 'Salvar',
      color: 'primary',
      action: () => this.submitContato(),
      show: () => this.displayContato,
    },
  ];

  readonly camposEndereco = [
    {
      label: 'CEP',
      nome: 'cep',
      placeholder: 'CEP',
      nomeControle: 'cep',
      tamanho: 3,
    },
    {
      label: 'Endereço',
      nome: 'endereco',
      placeholder: 'Endereço',
      nomeControle: 'endereco',
      tamanho: 9,
    },
    {
      label: 'Número',
      nome: 'numero',
      placeholder: 'Número',
      nomeControle: 'numero',
      tamanho: 2,
    },
    {
      label: 'Bairro',
      nome: 'bairro',
      placeholder: 'Bairro',
      nomeControle: 'bairro',
      tamanho: 4,
    },
    {
      label: 'Município',
      nome: 'municipio',
      placeholder: 'Município',
      nomeControle: 'municipio',
      tamanho: 4,
    },
    {
      label: 'UF',
      nome: 'uf',
      placeholder: 'UF',
      nomeControle: 'uf',
      tamanho: 2,
    },
    {
      label: 'Complemento',
      nome: 'complemento',
      placeholder: 'Complemento',
      nomeControle: 'complemento',
      tamanho: 3,
    },
  ];

  readonly colunas = [
    { label: 'CEP', key: 'cep' },
    { label: 'Endereço', key: 'logradouro' },
    { label: 'Número', key: 'numero' },
    { label: 'Bairro', key: 'bairro' },
    { label: 'Município - UF', key: 'cidadeUf' },
    { label: 'Complemento', key: 'complemento' },
  ];

  constructor(
    private fb: FormBuilder,
    private cadastroPublicoService: CadastroPublicoService,
    private validaErro: ErroService,
    private enderecoService: EnderecoService
  ) {
    this.localStorage = this.decryptLocalStorage();

    this.tipoUsuario = this.localStorage.TipoUsuario;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cadastroUsuario']?.currentValue && this.cadastroUsuario.NumeroDeOrdem) {
      this.ngOnInit();
      this.listaContatosSecundarios = [...this.cadastroUsuario.Pessoal.Endereco.Telefones];
      this.listaAuxiliar = [...this.listaContatosSecundarios];
      this.carrregarCampos();
    }
    if (changes['mudancaTab']?.currentValue && this.displayEndereco == true) {
      this.cancelarEdicaoEndereco();
    }
  }

  ngOnInit() {
    this.formEndereco = this.fb.group({
      cep: [
        { value: '', disabled: false },
        Validators.compose([Validators.required, Validators.minLength(8)]),
      ],
      endereco: [{ value: '', disabled: true }, [Validators.required]],
      numero: [
        { value: '', disabled: true },
        Validators.compose([Validators.required, Validators.pattern('[0-9]*')]),
      ],
      bairro: [{ value: '', disabled: true }, [Validators.required]],
      municipio: [{ value: '', disabled: true }, [Validators.required]],
      complemento: [{ value: '', disabled: true }],
      uf: [{ value: '', disabled: true }, [Validators.required]],
    });

    this.formContato = this.fb.group({
      celularSecundario: [''],
      tipoSecundario: [''],
    });
    this.cadastroUsuario.Pessoal.Endereco.Valido = true;
  }

  styleControleFormulario(controle: AbstractControl): any {
    return controle.touched &&
      (this.temErro || controle.hasError('required') || controle.status === 'INVALID')
      ? { 'border-color': 'red' }
      : null;
  }

  campoInvalido(controle: AbstractControl): boolean {
    return controle.touched && (this.temErro || controle.hasError('required'));
  }

  valorColuna(key: string): string | number {
    const endereco = this.cadastroUsuario.Pessoal.Endereco;
    switch (key) {
      case 'cep':
        return this.formataCep(endereco.Cep);
      case 'logradouro':
        return endereco.Logradouro;
      case 'numero':
        return endereco.Numero;
      case 'bairro':
        return endereco.Bairro;
      case 'cidadeUf':
        return `${endereco.Cidade} - ${endereco.Estado}`;
      case 'complemento':
        return `${endereco.ComplementoEndereco}`;
      default:
        return '';
    }
  }

  cancelarEdicaoEndereco() {
    this.displayEndereco = !this.displayEndereco;
    this.carrregarCampos();
    this.formEndereco.markAsUntouched();
    this.cepValido = true;
  }
  cancelarEdicaoContato() {
    this.displayContato = false;
    this.listaContatosSecundarios = this.cadastroUsuario.Pessoal.Endereco.Telefones;
    this.formContato.markAsUntouched();
  }

  async submitEndereco() {
    try {
      if (!this.formEndereco.valid) {
        Swal('Atenção', 'Todos os campos são obrigatórios', 'warning');
        this.temErro = true;
      } else {
        this.salvarEndereco();
        this.temErro = false;
      }
    } catch (err) {
      this.validaErro.retornoErro(err);
    }
  }
  async submitContato() {
    const { celularSecundario, tipoSecundario } = this.formContato.value;
    try {
      if (celularSecundario !== '' && tipoSecundario !== '') {
        Swal('Atenção', 'Adicione um contato para salvar', 'warning');
      } else {
        this.salvarContato();
        this.temErro = false;
      }
    } catch (err) {
      this.validaErro.retornoErro(err);
    }
  }

  salvarContato() {
    this.loading = true;
    const endereco = this.montarEnderecoOuContato(true);
    this.enderecoService
      .AlterarCadastroPerfil(endereco, this.cadastroUsuario.NumeroDeOrdem)
      .subscribe(
        (data) => {
          this.displayContato = false;
          this.atualizarEnderecoOuContato(true);
          Swal('Sucesso', 'O cadastro foi alterado', 'success');
          this.loading = false;
          this.exclusao = false;
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.loading = false;
        }
      );
  }
  salvarEndereco() {
    this.loading = true;

    const endereco = this.montarEnderecoOuContato(false);
    this.enderecoService
      .AlterarCadastroPerfil(endereco, this.cadastroUsuario.NumeroDeOrdem)
      .subscribe(
        (data) => {
          this.displayEndereco = false;
          this.atualizarEnderecoOuContato(false);

          Swal('Sucesso', 'O cadastro foi alterado', 'success');
          this.loading = false;
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.loading = false;
        }
      );
  }

  montarEnderecoOuContato(incluirTelefones: boolean): Endereco {
    const endereco = this.formEndereco.getRawValue();
    let telefones = [];
    telefones = this.exclusao ? this.listaAuxiliar : this.listaContatosSecundarios;

    return {
      Logradouro: endereco.endereco,
      ComplementoEndereco: endereco.complemento.toUpperCase(),
      Numero: endereco.numero,
      Bairro: endereco.bairro,
      Cidade: endereco.municipio,
      Cep: endereco.cep,
      Uf: endereco.uf,
      Estado: endereco.uf, // Não está claro se essa duplicação é intencional
      Telefones: telefones,
    };
  }

  atualizarEnderecoOuContato(incluirTelefones: boolean): void {
    const { municipio, cep, endereco, bairro, numero, uf, complemento } =
      this.formEndereco.getRawValue();

    this.cadastroUsuario.Pessoal.Endereco = {
      Bairro: bairro,
      Cep: cep,
      Cidade: municipio,
      ComplementoEndereco: complemento,
      Estado: uf,
      Logradouro: endereco,
      Numero: numero,
      Uf: uf,
      Telefones: this.listaContatosSecundarios,
    };
  }

  carrregarCampos(endereco: Endereco = null) {
    if (endereco === null) endereco = this.cadastroUsuario.Pessoal.Endereco;

    this.formEndereco.get('municipio').setValue(endereco.Cidade);
    this.formEndereco.get('cep').setValue(endereco.Cep);
    this.formEndereco.get('endereco').setValue(endereco.Logradouro);
    this.formEndereco.get('bairro').setValue(endereco.Bairro);
    this.formEndereco
      .get('numero')
      .setValue(endereco.Numero == 0 || endereco.Numero == '' ? '' : endereco.Numero);
    this.formEndereco
      .get('uf')
      .setValue(!endereco.Uf || endereco.Uf == '' ? endereco.Estado : endereco.Uf);
    this.formEndereco.get('complemento').setValue(endereco.ComplementoEndereco);

    this.bloqueiaCamposEndereco(endereco.Valido);
  }

  bloqueiaCamposEndereco(cepValido: Boolean) {
    if (cepValido) {
      this.formEndereco.controls['endereco'].enable();
      this.formEndereco.controls['bairro'].enable();
      this.formEndereco.controls['numero'].enable();
      this.formEndereco.controls['complemento'].enable();
    } else {
      this.formEndereco.controls['endereco'].disable();
      this.formEndereco.controls['bairro'].disable();
      this.formEndereco.controls['numero'].disable();
      this.formEndereco.controls['complemento'].disable();
    }
  }

  formataCep(cep) {
    if (cep != undefined) return cep.toString().substr(0, 5) + '-' + cep.toString().substr(5, 3);
  }

  onCepChange() {
    let cep = this.formEndereco.get('cep').value.toString();
    this.cepValido = true;

    if (cep.length == 8) {
      this.buscarEndereco();
    }
  }

  buscarEndereco() {
    const { cep } = this.formEndereco.value;
    this.loadingCep = true;

    this.cadastroPublicoService.buscarCep(cep).subscribe(
      (data: Endereco) => {
        this.carrregarCampos(data);
        this.cepValido = data.Valido;
        this.loadingCep = false;
      },
      (error) => {
        this.loadingCep = false;
        this.validaErro.retornoErro(error);
      }
    );
  }
  removerContatoSecundario(contato) {
    this.exclusao = true;
    if (this.listaAuxiliar.length === 0) {
      this.listaAuxiliar = this.listaContatosSecundarios;
    }
    console.log('this.listaAuxiliar', this.listaAuxiliar);
    const indexContatoSecundario = this.listaContatosSecundarios.indexOf(contato);
    const indexListaAux = this.listaAuxiliar.indexOf(contato);
    if (indexContatoSecundario !== -1 && indexListaAux !== -1) {
      this.listaAuxiliar[indexListaAux].Telefone = '';
      this.listaContatosSecundarios.splice(indexContatoSecundario, 1);
    }
  }
  uppercase(event) {
    event.target.value = event.target.value.toUpperCase();
  }
  adicionarContatoSecundario() {
    if (this.listaContatosSecundarios.length === 3) {
      Swal(
        'Atenção',
        'Só é possível adicionar até 3 contatos. Exclua um e tente novamente',
        'warning'
      );
    } else {
      this.exclusao = false;
      const { celularSecundario, tipoSecundario } = this.formContato.value;
      if (celularSecundario && tipoSecundario && celularSecundario.length >= 10) {
        const dadosContatoSecundario: TelefonesSecundarios = {
          DDD: celularSecundario.substring(0, 2),
          Telefone: celularSecundario,
          Tipo: tipoSecundario,
          TelefoneAntigo: celularSecundario,
        };
        this.listaAuxiliar.push(dadosContatoSecundario);
        this.listaContatosSecundarios.push(dadosContatoSecundario);
        this.formContato.patchValue({
          celularSecundario: '',
          tipoSecundario: '',
        });
      } else {
        this.formContato.markAllAsTouched();
        Swal('Atenção', 'Preencha o campo de telefone corretamente para adicionar', 'warning');
      }
    }
  }

  formatarNumero(numero) {
    let pt1 = numero.substring(0, 2);
    let pt2 = numero.substring(2, numero.length - 4);
    let pt3 = numero.substring(numero.length - 4);
    return `(${pt1}) ${pt2}-${pt3}`;
  }
  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}
