import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { EChartsOption } from 'echarts';
import { ColaboradoresComponent } from '../colaboradores/colaboradores.component';
import { TestBed } from '@angular/core/testing';
//import {getFolhaData} from './dados.js';
import { HomePageService } from './home-page.service';
import { ErroService } from '../../service/erros/erro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Folha } from './classes/folha';
import { Colaboradores } from './classes/colaboradores';
import { Lancamento } from './classes/lancamento';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { state } from '@angular/animations';
import { Cadastro } from '../cadastro/interface/cadastro';

const dispositivo = function (navigatorObject: any) {
  return navigatorObject.appVersion.includes('Mobile') ? 'movel' : 'desktop';
};
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit {
  @Input() colaborador: Cadastro = null;
  public variavel: boolean = true;
  eventosListar: evento[] = [];
  localStorage: LocalStorage;

  folhaLoading: boolean = false;
  colaboradoresLoading: boolean = false;
  colaboradorFaturamentoLoading: boolean = false;
  lancamentoFolhaPontoLoading: boolean = false;

  retorno_gerencial = new Colaboradores();
  folhas = new Folha();
  colaboradoresAux = new Colaboradores();
  lancamentoFolhaPontoAux: Lancamento[] = [];
  folha: EChartsOption;
  colaboradores: EChartsOption;
  colaboradorFaturamento: EChartsOption;
  lancamentoFolhaPonto: EChartsOption = {};
  carregaCharts: boolean = false;
  carregaChartsColaboradores: boolean = false;
  carregaChartslancamentoFolhaPontoAux: boolean = false;
  folhaValida: boolean = false;

  constructor(
    public auth: AuthService,
    private home_service: HomePageService,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}

  ngOnInit() {
    this.localStorage = this.decryptLocalStorage();
    window.scrollTo(0, 0);
    this.buscarHome();
    this.buscarHomeVariacaoFolha();
    this.buscarEventosListar();
    this.refreshChart('folha');
    this.refreshChart('folhaColaborador');
    this.refreshChart('lancamentoFolhaPonto');
  }

  moverParaRotaColaborador(codigo: number) {
    localStorage.setItem('colaboradores', new Crypto().encryptUsingAES256(String(codigo)));
    this.router.navigate(['colaborador']);
  }
  setStorageColaborador(valor: string) {
    localStorage.setItem('colaboradores', new Crypto().encryptUsingAES256(valor));
    if (valor == '0' && this.retorno_gerencial.Ativos > 0) {
      this.router.navigate(['/colaboradores']);
    } else if (valor == '1' && this.retorno_gerencial.Ferias > 0) {
      this.router.navigate(['/colaboradores']);
    } else if (valor == '2' && this.retorno_gerencial.Pendentes > 0) {
      this.router.navigate(['/colaboradores'], {
        state: { filtro: ['97', '98'] },
      });
    }
  }

  datasSubstring(str: string) {
    return str.substring(3, 10);
  }

  //***************************************
  // ROTINA DOS GRÁFICOS
  //***************************************

  download(evento: any) {
    const canva =
      evento.path[4].childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0];
    const img = canva.toDataURL('image/png');

    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';

    // Rotinas dos gráficos
    xhr.onload = function () {
      let a = document.createElement('a');
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = 'grafico.jpg';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.remove();
    };
    xhr.open('GET', img); // This is to download the canvas Image
    xhr.send();
  }

  sideMenuButtonClick() {
    setTimeout(() => {
      this.resizeCharts();
    }, 50);
  }

  async refreshChart(chart: String) {
    if (chart == 'folha') {
      this.folhaLoading = true;
      this.folha = await this.getFolhaChart();
      this.folhaLoading = false;
    } else if (chart == 'colaborador') {
      this.colaboradoresLoading = true;
      this.colaboradores = await this.getColaboradoresChart();
      this.colaboradoresLoading = false;
    } else if (chart == 'folhaColaborador') {
      this.colaboradorFaturamento = await this.getFuncColabChart();
    } else if (chart == 'lancamentoFolhaPonto') {
      this.lancamentoFolhaPontoLoading = true;
      this.lancamentoFolhaPonto = await this.getLancamentoFolhaPontoChart();
      this.lancamentoFolhaPontoLoading = false;
    }
  }

  getFolhaChart(): EChartsOption {
    return {
      textStyle: {
        fontFamily: 'Roboto, Arial, Verdana, sans-serif',
        fontSize: 13,
      },
      // Chart animation duration
      animationDuration: 750,

      // Setup grid
      grid: {
        left: 0,
        right: 30,
        top: 30,
        bottom: 0,
        containLabel: true,
      },

      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.75)',
        padding: [10, 15],
        textStyle: {
          fontSize: 20,
          fontFamily: 'Roboto, sans-serif',
        },
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(0,0,0,0.025)',
          },
        },
      },

      legend: {
        data: ['Folha', 'Colaboradores'],
        orient: 'horizontal',
        //x: 'center'
      },
      xAxis: [
        {
          type: 'category',
          //data:this.getFolhaData(),
          data: ['15/04/2023', '16/04/2023', '17/04/2023'],
          axisPointer: {
            type: 'shadow',
          },
        },
      ],
      yAxis: [
        {
          //name: 'Folha',
          type: 'value',
          axisLabel: {
            color: '#333',
            formatter: 'R$ {value}',
          },
          axisLine: {
            lineStyle: {
              color: '#999',
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#eee',
              type: 'dashed',
            },
          },
        },
        {
          type: 'value',
          axisLabel: {
            color: '#333',
          },
          axisLine: {
            lineStyle: {
              color: '#999',
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#eee',
              type: 'dashed',
            },
          },
        },
      ],
      series: [
        {
          name: 'Folha',
          type: 'bar',
          data: [30, 29, 37],
          barCategoryGap: '50%',
        },
      ],
    };
  }

  getColaboradoresChart(): EChartsOption {
    return {
      textStyle: {
        fontFamily: 'Roboto, Arial, Verdana, sans-serif',
        fontSize: 13,
      },
      // Chart animation duration
      animationDuration: 750,

      grid: {
        left: 0,
        right: 30,
        top: 30,
        bottom: 0,
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.75)',
        padding: [10, 15],
        textStyle: {
          fontSize: 20,
          fontFamily: 'Roboto, sans-serif',
        },
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(0,0,0,0.025)',
          },
        },
      },

      legend: {
        data: ['Colaboradores teste'],
      },
      xAxis: [
        {
          type: 'category',
          data: ['15/04/2023', '16/04/2023', '17/04/2023'],
          axisPointer: {
            type: 'shadow',
          },
        },
      ],
      yAxis: {
        type: 'value',
        name: 'Qtd',

        axisLabel: {
          color: '#333',
        },
        axisLine: {
          lineStyle: {
            color: '#999',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#eee',
            type: 'dashed',
          },
        },
      },
      series: [
        {
          name: 'Colaboradores',
          type: 'line',
          data: [30, 29, 37],
        },
      ],
    };
  }

  getFuncColabChart(): EChartsOption {
    return {
      textStyle: {
        fontFamily: 'Roboto, Arial, Verdana, sans-serif',
        fontSize: 13,
      },
      // Chart animation duration
      animationDuration: 750,

      // Setup grid
      grid: {
        left: 0,
        right: 30,
        top: 30,
        bottom: 0,
        containLabel: true,
      },

      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.75)',
        padding: [10, 15],
        textStyle: {
          fontSize: 20,
          fontFamily: 'Roboto, sans-serif',
        },
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(0,0,0,0.025)',
          },
        },
      },

      legend: {
        data: ['Folha', 'Colaboradores'],
        orient: 'horizontal',
        //x: 'center'
      },
      xAxis: [
        {
          type: 'category',
          //data:this.getFolhaData(),
          data: ['15/04/2023', '16/04/2023', '17/04/2023'],
          axisPointer: {
            type: 'shadow',
          },
        },
      ],
      yAxis: [
        {
          //name: 'Folha',
          type: 'value',
          axisLabel: {
            color: '#333',
            formatter: 'R$ {value}',
          },
          axisLine: {
            lineStyle: {
              color: '#999',
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#eee',
              type: 'dashed',
            },
          },
        },
        {
          type: 'value',
          //name: 'Colaboradores',
          // max:30,
          // min:15,
          axisLabel: {
            color: '#333',
          },
          axisLine: {
            lineStyle: {
              color: '#999',
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#eee',
              type: 'dashed',
            },
          },
        },
      ],
      series: [
        {
          name: 'Folha',
          type: 'bar',
          data: [40, 59, 69],
          barCategoryGap: '50%',
          label: {
            /*normal: {
              textStyle: {
                color: '#682d19'
              },
              position: 'left',
              show: false,
              formatter: '{b}'
            }
          },
          itemStyle: {
            normal: {
              color: '#faca00',
            }*/
          },
        },

        {
          name: 'Colaboradores',
          type: 'line',
          yAxisIndex: 1,
          data: this.getQuantidadeColaboradoresData(),
          label: {
            /*normal: {
              textStyle: {
                color: '#682d19'
              },
              position: 'left',
              show: false,
              formatter: '{b}'
            }
          },
          itemStyle: {
            normal: {
              color: '#181818',
            }*/
          },
        },
      ],
    };
  }

  getLancamentoFolhaPontoChart(): EChartsOption {
    return {
      textStyle: {
        fontFamily: 'Roboto, Arial, Verdana, sans-serif',
        fontSize: 13,
      },
      // Chart animation duration
      animationDuration: 750,
      grid: {
        left: 0,
        right: 30,
        top: 30,
        bottom: 0,
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.75)',
        padding: [10, 15],
        textStyle: {
          fontSize: 13,
          fontFamily: 'Roboto, sans-serif',
        },
        axisPointer: {
          type: 'shadow',
          shadowStyle: {
            color: 'rgba(0,0,0,0.025)',
          },
        },
      },
      legend: {
        data: ['Folha de Ponto'],
        orient: 'horizontal',
        //x: 'center'
      },
      xAxis: [
        {
          type: 'category',
          data: this.getSituacoesData(),
          axisPointer: {
            type: 'shadow',
          },
        },
      ],
      yAxis: {
        type: 'value',
        //name: | Quantidade,
        //  min:15,
        //  max:30,
        //nameLocation: right,
        // nameTextStyle:{
        //     align:center
        // },
        splitNumber: this.obterQuantidadeLinhasDivisorias(),
        axisLabel: {
          color: '#333',
        },
        axisLine: {
          lineStyle: {
            color: '#999',
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#eee',
            type: 'dashed',
          },
        },
      },
      series: [
        {
          name: 'Lancamentos',
          type: 'bar',
          data: this.getQuantidadePorSituacaoData(),
          label: {
            /*normal: {
              textStyle: {
                color: '#682d19'
              },
              position: 'left',
              show: false,
              formatter: '{b}'
            }*/
          },
          itemStyle: {
            /*normal: {
              color: '#faca00'
            }*/
          },
        },
      ],
    };
  }

  obterQuantidadeLinhasDivisorias() {
    let maior = 0;
    this.lancamentoFolhaPontoAux.forEach((element) => {
      maior = element.Quantidade > maior ? element.Quantidade : maior;
    });
    return maior;
  }
  resizeCharts() {
    try {
      //this.colaboradores.resize();
      //this.folha.resize();
      //this.colaboradorFaturamento.resize();
    } catch (err) {
      //this.chart.resize() gera um erro de .resize() is not a function, mesmo os graficos redimensionando.
    }
  }

  //Busca os dados gerenciais atraves da WebApi - Home
  buscarHome() {
    this.home_service.buscarHome().subscribe(
      (data) => {
        this.retorno_gerencial = JSON.parse(JSON.stringify(data));
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  folhaAux: any[] = [];

  //Busca os valores da folha atraves da WebApi - Home
  buscarHomeVariacaoFolha() {
    this.carregaCharts = false;
    this.home_service.buscarHomeVariacaoFolha().subscribe(
      (data) => {
        this.folhas = JSON.parse(JSON.stringify(data));

        if (Object.values(this.folhas).length > 0 && Object.values(this.folhas).length <= 3) {
          if (Object.values(this.folhas).length == 1) {
            this.folhaAux = [this.folhas[0].Valor];
          } else if (Object.values(this.folhas).length == 2) {
            this.folhaAux = [
              this.folhas[0].Valor,
              this.folhas[0].DataFolha.ToDate,
              this.folhas[1].Valor,
              this.folhas[1].DataFolha.ToDate,
            ];
          } else if (Object.values(this.folhas).length == 3) {
            this.folhaValida = true;
            this.folhaAux = [
              this.folhas[0].Valor,
              this.folhas[0].DataFolha.ToDate,
              this.folhas[1].Valor,
              this.folhas[1].DataFolha.ToDate,
              this.folhas[2].Valor,
              this.folhas[2].DataFolha.ToDate,
            ];
          }
          this.carregaCharts = true;
        }
        this.buscarHomeCurvaColaboradores();
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  //Busca os colaboradores atraves da WebApi - Home
  buscarHomeCurvaColaboradores() {
    this.carregaChartsColaboradores = false;
    this.home_service.buscarHomeCurvaColaboradores().subscribe(
      (data) => {
        this.colaboradoresAux = JSON.parse(JSON.stringify(data));

        if (
          Object.values(this.colaboradoresAux).length > 0 &&
          Object.values(this.colaboradoresAux).length <= 3
        ) {
          this.carregaChartsColaboradores = true;
        }

        this.carregaChart();
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarEventosListar() {
    const sigla = 'FAPP';
    const empfil = this.localStorage.FilialAtual.toString();
    this.home_service.buscarEventosListar(sigla, empfil).subscribe((dados: any) => {
      this.eventosListar = dados;
    });
  }

  //Busca os colaboradores atraves da WebApi - Home
  /* buscarHomeLancamentoFolhaPonto(){
      this.carregaChartslancamentoFolhaPontoAux = false; 
      this.home_service.buscarHomeLancamentoFolhaPonto()
      .subscribe(
        data  => {
          this.lancamentoFolhaPontoAux = JSON.parse(JSON.stringify(data));

          if(Object.values(this.lancamentoFolhaPontoAux).length > 0){
              this.carregaChartslancamentoFolhaPontoAux = true;
          }

          this.carregaChart();

        },
        error  => { 
          this.spinner.hide();
          this.validaErro.retornoErro(error);
        }
      );
    }*/
  //Carrega os gráficos
  carregaChart() {
    if (this.carregaCharts == true) {
      this.folha = this.getFolhaChart();
    }
    if (this.carregaChartsColaboradores == true) {
      this.colaboradores = this.getColaboradoresChart();
    }
    if (this.carregaChartsColaboradores == true && this.carregaCharts == true) {
      this.colaboradorFaturamento = this.getFuncColabChart();
    }

    /*if(this.carregaChartslancamentoFolhaPontoAux == true){
        this.lancamentoFolhaPonto = this.getLancamentoFolhaPontoChart();
    }*/
  }

  //Demonstra a quantidade de colaboradores;
  getQuantidadeColaboradoresData() {
    if (Object.values(this.colaboradoresAux).length == 1) {
      return [this.colaboradoresAux[0].QuantidadeFuncionario];
    } else if (Object.values(this.colaboradoresAux).length == 2) {
      return [
        this.colaboradoresAux[0].QuantidadeFuncionario,
        this.colaboradoresAux[1].QuantidadeFuncionario,
      ];
    } else if (Object.values(this.colaboradoresAux).length == 3) {
      return [
        this.colaboradoresAux[0].QuantidadeFuncionario,
        this.colaboradoresAux[1].QuantidadeFuncionario,
        this.colaboradoresAux[2].QuantidadeFuncionario,
      ];
    }
  }

  //Demonstra as datas do colocaboradores;
  getColaboradoresData() {
    if (Object.values(this.colaboradoresAux).length == 1) {
      return [this.datasSubstring(this.colaboradoresAux[0].DataColaboradores.ToDate)];
    } else if (Object.values(this.colaboradoresAux).length == 2) {
      return [
        this.datasSubstring(this.colaboradoresAux[0].DataColaboradores.ToDate),
        this.datasSubstring(this.colaboradoresAux[1].DataColaboradores.ToDate),
      ];
    } else if (Object.values(this.colaboradoresAux).length == 3) {
      return [
        this.datasSubstring(this.colaboradoresAux[0].DataColaboradores.ToDate),
        this.datasSubstring(this.colaboradoresAux[1].DataColaboradores.ToDate),
        this.datasSubstring(this.colaboradoresAux[2].DataColaboradores.ToDate),
      ];
    }
  }

  //Demonstra as datas da folha;
  getFolhaData() {
    if (Object.values(this.folhaAux).length == 2 && Object.values(this.folhaAux).length <= 6) {
      return [this.datasSubstring(this.folhaAux[1])];
    } else if (
      Object.values(this.folhaAux).length == 4 &&
      Object.values(this.folhaAux).length <= 6 &&
      Object.values(this.colaboradoresAux).length == 3 &&
      this.folhaValida == true
    ) {
      return [
        this.datasSubstring(this.folhaAux[1]),
        this.datasSubstring(this.folhaAux[3]),
        this.datasSubstring(this.colaboradoresAux[2].DataColaboradores.ToDate),
      ];
    } else if (
      Object.values(this.folhaAux).length == 4 &&
      Object.values(this.folhaAux).length <= 6
    ) {
      return [this.datasSubstring(this.folhaAux[1]), this.datasSubstring(this.folhaAux[3])];
    } else if (Object.values(this.folhaAux).length == 6) {
      return [
        this.datasSubstring(this.folhaAux[1]),
        this.datasSubstring(this.folhaAux[3]),
        this.datasSubstring(this.folhaAux[5]),
      ];
    }
  }

  //Retorna o valor da Folha
  getFolhaDataValor() {
    this.folhaAux = this.folhaAux.map((e) => e);
    return [this.folhaAux[0], this.folhaAux[2], this.folhaAux[4]];

    // this.folhaAux = this.folhaAux[0].map(e => e);
    // return this.folhaAux;
  }

  // Retorna as situações sobre os envios da folha de ponto
  getSituacoesData() {
    let situacoes: string[] = [];
    this.lancamentoFolhaPontoAux.forEach((element) => {
      situacoes.push(element.Situacao);
    });
    return situacoes;
  }

  // Retorna quantas folhas existem para cada situação
  getQuantidadePorSituacaoData() {
    let quantidade: number[] = [];
    this.lancamentoFolhaPontoAux.forEach((element) => {
      quantidade.push(element.Quantidade);
    });
    return quantidade;
  }

  onResize() {
    this.resizeCharts();
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}

export interface evento {
  Empfil: number;
  Sigla: string;
  DescEvento: string;
  Evento: string;
  Qtd: number;
}
