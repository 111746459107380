import { Pipe, PipeTransform, KeyValueDiffers } from '@angular/core';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(value: any[], [Nome, Vencimento, Codigo, EmpresaFilial]: any[]): any {
    return value.filter(item =>{
      let valido = true;
      if(Nome!= '' && !item.Nome.toUpperCase().includes(Nome.toUpperCase())){ valido = false};

      if(valido) return item;

    })
  }

}
