<button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#configuracaoCliente">
    <i class="fa fa-cog" aria-hidden="true"></i>
</button>

<div class="modal fade" id="configuracaoCliente" tabindex="-1" role="dialog"
    aria-labelledby="configuracaoClienteLabel" aria-hidden="true">
    <div class="modal-dialog " role="document">
        <div class="modal-content">

            <div class="card" *ngIf="contadorPag == 1">
                <div class="card-header">
                    <h5 id="configuracaoClienteLabel">Configurações Cliente</h5>
                </div>
                <div class="card-body wizard">
                    <div  class="steps clearfix">
                        <ul role="tablist">
                            <li role="tab" [ngClass]="{'current': step == 0 , 'done':stepcount > 0  }" class="first" aria-disabled="false" aria-selected="true"><a id="steps-uid-0-t-0" aria-controls="steps-uid-0-p-0" class="" ><span class="current-info audible">current step: </span><span [ngClass]="{'pointer': stepcount > 0}" class="number">1</span> Informações Básicas</a></li>
                            <li role="tab" [ngClass]="{'current': step == 1 , 'disabled': stepcount < 1 ,'done':stepcount > 1 }" aria-disabled="true"><a id="steps-uid-0-t-1" aria-controls="steps-uid-0-p-1" ><span [ngClass]="{'pointer': stepcount > 1}" class="number">2</span> Dados Pessoais</a></li>
                            <li role="tab" [ngClass]="{'current': step == 2 , 'disabled': stepcount < 2 ,'done':stepcount > 2 }" aria-disabled="true"><a id="steps-uid-0-t-2" aria-controls="steps-uid-0-p-2" class="disabled"><span [ngClass]="{'pointer': stepcount > 2}" class="number ">3</span> Documentos</a></li>
                            <!-- <li role="tab" [ngClass]="{'current': step == 3 , 'disabled': stepcount < 3 }" class="last" aria-disabled="true"><a id="steps-uid-0-t-3" aria-controls="steps-uid-0-p-3" class="disabled" ><span  class="number">4</span> Conclusão</a></li> -->
                        </ul>
                    </div>

                    <form [formGroup]="formServicoCliente" (ngSubmit)="submitServicoCliente()">
                    <div class="form-check row col-md-12 justify-content-center">
                        <div class="col-12 table-responsive text-center">
                            <div class="row form-group text-center">
                                <table class="table table-striped table-hover mb-0 text-center">
                                    <thead class="bg-teal-400">
                                        <tr class="text-start" >
                                            <th style="text-align:left;">
                                                Serviços do Cliente
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            <td style="text-align:left;">
                                                <div class="form-check" *ngFor="let servico of listaServicos">
                                                        <div>
                                                            <input class="form-check-input" (change)="onChangeProvince(servico, $event)"name="{{servico.Descricao}}" type="checkbox" id="{{servico.Codigo}}">
                                                            <label class="form-check-label" for="{{servico}}">{{servico.Descricao}}</label>
                                                        </div>
                                                        <span class="form-text text-danger"
                                                        *ngIf="campoObrigatorio">
                                                        Campo obrigatório
                                                      </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
                </div>

                <div class="card-footer">
                    <div class="row d-flex flex-row-reverse bd-highlight">
                        <div class="col-2 p-2 bd-highlight">
                            <button (click)="passartela2Dois()" type="button" class="btn btn-primary ">Proximo</button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="card" *ngIf="contadorPag == 2">
                <div class="card-header">
                    <h5>Configurações Cliente</h5>
                </div>
                <div class="card-body  wizard">
                    <div class="steps clearfix">
                        <ul role="tablist">
                            <li role="tab" [ngClass]="{'current': step == 0 , 'done':stepcount > 0  }" class="first" aria-disabled="false" aria-selected="true"><a id="steps-uid-0-t-0" aria-controls="steps-uid-0-p-0" class=""><span class="current-info audible">current step: </span><span [ngClass]="{'pointer': stepcount > 0}" class="number">1</span> Informações Básicas</a></li>
                            <li role="tab" [ngClass]="{'current': step == 1 , 'disabled': stepcount < 1 ,'done':stepcount > 1 }" aria-disabled="true"><a id="steps-uid-0-t-1" aria-controls="steps-uid-0-p-1"  ><span [ngClass]="{'pointer': stepcount > 1}" class="number">2</span> Dados Pessoais</a></li>
                            <li role="tab" [ngClass]="{'current': step == 2 , 'disabled': stepcount < 2 ,'done':stepcount > 2 }" aria-disabled="true"><a id="steps-uid-0-t-2" aria-controls="steps-uid-0-p-2" class="disabled"><span [ngClass]="{'pointer': stepcount > 2}" class="number ">3</span> Documentos</a></li>
                            <!-- <li role="tab" [ngClass]="{'current': step == 3 , 'disabled': stepcount < 3 }" class="last" aria-disabled="true"><a id="steps-uid-0-t-3" aria-controls="steps-uid-0-p-3" class="disabled" ><span  class="number">4</span> Conclusão</a></li> -->
                        </ul>
                    </div>
                    <div class="form-check row col-md-12 justify-content-center">
                        <div class="col-12 table-responsive text-center">
                            <div class="row form-group text-center">
                                <table class="table table-striped table-hover mb-0 text-center">
                                    <thead class="bg-teal-400">
                                        <tr class="text-start" style="white-space:nowrap">
                                            <th style="text-align:left;">
                                                Grupos do Cliente
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="text-align:left;">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="flexCheckDefault4">
                                                    <label class="form-check-label" for="flexCheckDefault4"
                                                        style="width: 100px;">
                                                       BPO - Ayugate
                                                    </label>
                                                </div>

                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="flexCheckDefault5">
                                                    <label class="form-check-label" for="flexCheckDefault5"
                                                        style="width: 100px;">
                                                        Elo - Elogica
                                                    </label>
                                                </div>

                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="flexCheckDefault6">
                                                    <label class="form-check-label" for="flexCheckDefault6"
                                                        style="width: 100px;">
                                                        BPO - Ayugate
                                                    </label>
                                                </div>

                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="flexCheckDefault7">
                                                    <label class="form-check-label" for="flexCheckDefault7"
                                                        style="width: 100px;">
                                                        Elo - Elogica
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row d-flex flex-row-reverse">
                        <div class="row">
                            <div class="col-5 p-2 mr-2 ml-2">
                                <button type="button" (click)="voltarTelaUm()" class="btn btn-danger ">Voltar</button>
                            </div>
                            <div class="col-5 p-2 ">
                                <button type="button" (click)="passarTelaTres()"
                                    class="btn btn-primary ">Proximo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="contadorPag == 3">
                <div class="card-header">
                    <h5>Configurações Cliente</h5>
                </div>
                <div class="card-body  wizard">
                    <div  class="steps clearfix">
                        <ul role="tablist">
                            <li role="tab" [ngClass]="{'current': step == 0 , 'done':stepcount > 0  }" class="first" aria-disabled="false" aria-selected="true"><a id="steps-uid-0-t-0" aria-controls="steps-uid-0-p-0" class="" ><span class="current-info audible">current step: </span><span [ngClass]="{'pointer': stepcount > 0}" class="number">1</span> Informações Básicas</a></li>
                            <li role="tab" [ngClass]="{'current': step == 1 , 'disabled': stepcount < 1 ,'done':stepcount > 1 }" aria-disabled="true"><a id="steps-uid-0-t-1" aria-controls="steps-uid-0-p-1" ><span [ngClass]="{'pointer': stepcount > 1}" class="number">2</span> Dados Pessoais</a></li>
                            <li role="tab" [ngClass]="{'current': step == 2 , 'disabled': stepcount < 2 ,'done':stepcount > 2 }" aria-disabled="true"><a id="steps-uid-0-t-2" aria-controls="steps-uid-0-p-2" class="disabled"><span [ngClass]="{'pointer': stepcount > 2}" class="number ">3</span> Documentos</a></li>
                            <!-- <li role="tab" [ngClass]="{'current': step == 3 , 'disabled': stepcount < 3 }" class="last" aria-disabled="true"><a id="steps-uid-0-t-3" aria-controls="steps-uid-0-p-3" class="disabled" ><span  class="number">4</span> Conclusão</a></li> -->
                        </ul>
                    </div>
                    <div class="form-check row col-md-12 justify-content-center">
                        <div class="col-12 table-responsive text-center">
                            <div class="row form-group text-center">
                                <table class="table table-striped table-hover mb-0 text-center">
                                    <thead class="bg-teal-400">

                                        <tr class="text-start" style="white-space:nowrap">
                                            <th style="text-align:left;">
                                                Tarefas do Cliente

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style="text-align:left;">
                                                <div class="form-check">

                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="flexCheckDefault8">
                                                    <label class="form-check-label" for="flexCheckDefault8"
                                                        style="width: 100px;">
                                                        001 - Tabelas
                                                    </label>

                                                </div>

                                                <div class="form-check">

                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="flexCheckDefault9">
                                                    <label class="form-check-label" for="flexCheckDefault9"
                                                        style="width: 100px;">
                                                        002 - Relatorios
                                                    </label>

                                                </div>

                                                <div class="form-check">

                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="flexCheckDefault10">
                                                    <label class="form-check-label" for="flexCheckDefault10"
                                                        style="width: 100px;">
                                                        003 - Reinf
                                                    </label>

                                                </div>

                                                <div class="form-check">

                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="flexCheckDefault11">
                                                    <label class="form-check-label" for="flexCheckDefault11"
                                                        style="width: 100px;">
                                                        002 - Ponto
                                                    </label>
                                                </div>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="card-footer">
                    <div class="row d-flex flex-row-reverse">
                        <div class="row">
                            <div class="col-5 p-2 mr-2 ml-2">
                                <button type="button" (click)="voltarTelaDois()" class="btn btn-danger ">Voltar</button>
                            </div>
                            <div class="col-5 p-2 ">
                                <button type="button" (click)="salvar()" class="btn btn-primary ">Salvar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
