import { Component, OnInit } from '@angular/core';
import { ParametrosService } from './parametros.service';
import { FormBuilder, FormArray, FormControl, FormGroup } from '@angular/forms';
import { element } from 'protractor';

@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.css']
})
export class ParametrosComponent implements OnInit {

  parametros: any;
  pesquisarFlag: string = '';
  formParametros: FormGroup;
  loading:boolean = true;
  constructor(private parametrosService: ParametrosService, public fb:FormBuilder) { 

  }

  ngOnInit() {
    this.formParametros =  this.fb.group({
      flags: this.fb.array([]),
    });
    this.buscarFlags();

  }

  get flags(): FormArray {
    return this.formParametros.get('flags') as FormArray;
  }

  carregarFlags(flags: any): void{
    const chaveFlags = Object. keys(flags);
    let listaFlagsValor = this.formParametros.get('flags').value;
    
    chaveFlags.forEach((chave, index) => {
      listaFlagsValor[index] = flags[chave];
    });
    this.formParametros.get('flags').setValue(listaFlagsValor);
  }
  retornarValores(): any{
    let flags = {}
        
    this.flags.value.forEach((element,index) => {
      flags[`F${(index+1)}`] = element;
    });
    
    return flags;
  }

  abrirModal(){
    document.getElementById('modal').click();
  }
  
  buscarFlags(){
    this.parametrosService.buscarFlags('tbcad')
    .subscribe(
      async (data: any) => {
        await Object.keys(data).forEach(element => {
          this.flags.push(new FormControl('0'))
        });
        
        this.parametros = data;
        this.loading = false;

      },
      error => {}
    )
  }

  returnZero() {
    return 0
  }

}
