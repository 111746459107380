import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { ProfissionalAtualizacao } from './profissional-educacao.component';

@Injectable({
  providedIn: 'root',
})
export class ProfissionalEducacaoService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any;
  filial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }
  url_acesso: any = this.acesso.validarUrl();

  buscarCategoria() {
    return this.http.get(`${this.url_acesso}categoria/profissional/educacao/`, {
      headers: this.cabecalho,
    });
  }

  buscarQualificacao() {
    return this.http.get(`${this.url_acesso}qualificacao/profissional/educacao/`, {
      headers: this.cabecalho,
    });
  }

  buscarDados(matricula: string) {
    return this.http.get(`${this.url_acesso}profissional/educacao/${matricula}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  atualizarDados(matricula: string, ProfissionalAtualizacao: ProfissionalAtualizacao) {
    return this.http.put(
      `${this.url_acesso}profissional/educacao/${matricula}/${this.filial}/${ProfissionalAtualizacao.categoriaProfissional}/${ProfissionalAtualizacao.qualificacaoProfissional}`,
      {},
      {
        headers: this.cabecalho,
      }
    );
  }

  // profissional/educacao/{matricula}/{empfil}/{categProfis}/{qualifProfid}
}
