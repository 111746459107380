<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div *ngIf="!telaFormulario" class="content d-flex justify-content-center align-items-center">
  <form class="login-form" #telainicial="ngForm" [formGroup]="formTelaInicial">
    <div class="card bg-dark mb-0 text-ligth">
      <div class="card-body">
        <div class="text-center mb-3">
          <h5 class="mb-0">Situação Funcional</h5>
        </div>
        <div class="s-12 col-sm-12 col-md-12 col-lg-12 form-group">
          <label class="justify-content-start">Matrícula</label>
          <input
            type="text"
            placeholder="Matrícula"
            class="form-control"
            mask="0*"
            (keyup.enter)="buscarDados()"
            formControlName="matricula_form"
            maxlength="9"
            [ngClass]="
              formTelaInicial.controls['matricula_form'].status == 'INVALID' && validaTelaInicial
                ? 'is-invalid'
                : null
            "
          />

          <span
            class="form-text text-danger"
            *ngIf="formTelaInicial.get('matricula_form').invalid && validaTelaInicial"
          >
            Campo Obrigatório
          </span>
          <span
            class="form-text text-danger"
            *ngIf="
              formTelaInicial.value.matricula_form.length > 0 &&
              formTelaInicial.get('matricula_form').hasError('matriculaError')
            "
          >
            Matrícula inválida
          </span>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-primary" (click)="buscarDados()">Buscar</button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="card" *ngIf="telaFormulario">
  <div class="card-header">
    <h3>
      <i
        class="fa fa-arrow-left pr-3 btn"
        aria-hidden="true"
        (click)="retornarTelaInicial()"
        style="cursor: pointer; bottom: 2px"
      ></i
      ><i class="fa fa-pencil-square-o 2x mr-1"></i>Situação Funcional
    </h3>
  </div>
  <form
    [formGroup]="formSituacaoFuncional"
    (ngSubmit)="submitSituacaoFuncional(situacaoFuncionalForm)"
    #situacaoFuncionalForm="ngForm"
  >
    <div class="card-body">
      <label
        style="
          background-color: rgba(192, 192, 192, 0.18);
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 15px;
          padding-bottom: 5px;
          border-radius: 10px;
          width: 100%;
          margin-bottom: 20px;
        "
      >
        <div class="row mb-3">
          <div class="col-md-6">
            <label>Nome</label>
            <input type="text" class="form-control" formControlName="nome" readonly />
          </div>
          <div class="col-md-2">
            <label>Matrícula</label>
            <input
              type="text"
              class="form-control"
              placeholder="Matrícula"
              formControlName="matricula_readonly"
              readonly
            />
          </div>
          <div class="col-md-4">
            <label>Vínculo</label>
            <input type="text" class="form-control" formControlName="vinculo" readonly />
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-8">
            <label>Cargo</label>
            <input type="text" class="form-control" formControlName="cargoInicial" readonly />
          </div>

          <div class="col-md-4">
            <label>Nível</label>
            <input type="text" class="form-control" formControlName="nivelAtual" readonly />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-8">
            <label>Função</label>
            <input type="text" class="form-control" formControlName="funcaoInicial" readonly />
          </div>
          <div class="col-md-4">
            <label>Referência</label>
            <input type="text" class="form-control" formControlName="referenciaInicial" readonly />
          </div>
        </div>
      </label>

      <div class="row align-items-start">
        <div [ngClass]="flag02 != '' ? 'col-md-8' : 'col-md-12'" class="form-group">
          <label class="font-weight-semibold">Evento</label>
          <select
            class="form-control"
            formControlName="evento"
            name="evento"
            (change)="onEventoChange()"
            [ngClass]="
              (temErro || formSituacaoFuncional.controls['evento'].touched) &&
              formSituacaoFuncional.controls['evento'].status == 'INVALID'
                ? 'is-invalid'
                : null
            "
          >
            <option value="">Selecione...</option>
            <option *ngFor="let evento of listaEvento" value="{{ evento.Codigo }}">
              {{ evento.Descricao }}
            </option>
          </select>
          <span
            class="form-text text-danger"
            *ngIf="
              (temErro || formSituacaoFuncional.controls['evento'].touched) &&
              formSituacaoFuncional.controls['evento'].hasError('required')
            "
          >
            Campo obrigatório
          </span>
        </div>
        <div *ngIf="flag02 != '' && clientePublico" class="form-group col-md-4">
          <label class="font-weight-semibold">Data Início do Evento</label>
          <input
            class="form-control"
            type="date"
            formControlName="dtEvento"
            max="9999-12-31"
            [ngClass]="
              (temErro || formSituacaoFuncional.controls['dtEvento'].touched) &&
              formSituacaoFuncional.controls['dtEvento'].status == 'INVALID'
                ? 'is-invalid'
                : null
            "
          />

          <span
            class="form-text text-danger"
            *ngIf="
              (temErro || formSituacaoFuncional.controls['dtEvento'].touched) &&
              formSituacaoFuncional.controls['dtEvento'].hasError('required')
            "
          >
            Campo obrigatório
          </span>
        </div>
        <!-- <div class="form-group col-md-4">
          <label for="">&nbsp;</label>
          <button
            data-toggle="modal"
            data-target="#observacoesTempoServicoModal"
            type="button"
            *ngIf="flag02 !== ''"
            class="btn btn-primary btn-block"
            (click)="modalObservacoes.abrirModal()"
          >
            <i class="fa fa-plus mr-1" aria-hidden="true"></i> Observação
          </button>
        </div> -->
        <div class="col-md-5 form-group">
          <label [ngSwitch]="flag02" class="font-weight-semibold">
            <ng-container *ngSwitchCase="'1'">Cargo</ng-container>
            <ng-container *ngSwitchCase="'2'">Cargo em comissão</ng-container>
            <ng-container *ngSwitchCase="'3'">Função gratificada</ng-container>
          </label>

          <select
            *ngIf="flag02 !== ''"
            (change)="mudarNivel($event)"
            class="form-control"
            type="text"
            [disabled]="flag02 === '2'"
            formControlName="funcao"
            [ngClass]="
              (temErro || formSituacaoFuncional.controls['funcao'].touched) &&
              formSituacaoFuncional.controls['funcao'].status == 'INVALID'
                ? 'is-invalid'
                : null
            "
          >
            <option value="">Selecione...</option>
            <option *ngFor="let funcao of listaFuncao" value="{{ funcao['Codigo'] }}">
              {{ funcao['Codigo'] }} - {{ funcao['Descricao'] }}
            </option>
          </select>
          <span
            class="form-text text-danger"
            *ngIf="
              (temErro || formSituacaoFuncional.controls['funcao'].touched) &&
              formSituacaoFuncional.controls['funcao'].hasError('required')
            "
          >
            Campo obrigatório
          </span>
          <span class="form-text" *ngIf="loadingFuncaoNivel"
            >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
          ></span>
        </div>

        <div *ngIf="flag02 !== '1' && flag02 !== ''" class="col-md-4 form-group">
          <label class="font-weight-semibold">Referência</label>
          <select
            class="form-control"
            type="text"
            formControlName="referencia"
            [ngClass]="
              (temErro || formSituacaoFuncional.controls['referencia'].touched) &&
              formSituacaoFuncional.controls['referencia'].status == 'INVALID'
                ? 'is-invalid'
                : null
            "
          >
            <option value="">Selecione...</option>

            <option *ngFor="let nivel of listaNivel" [value]="nivel.Codigo">
              {{ nivel.Codigo }} - {{ nivel.Descricao }}
            </option>
          </select>
          <span
            class="form-text text-danger"
            *ngIf="
              (temErro || formSituacaoFuncional.controls['referencia'].touched) &&
              formSituacaoFuncional.controls['referencia'].hasError('required')
            "
          >
            Campo obrigatório
          </span>
          <span class="form-text" *ngIf="loadingFuncaoNivel"
            >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
          ></span>
        </div>
        <div *ngIf="flag02 === '1'" class="col-md-4 form-group">
          <label class="font-weight-semibold">Nível</label>
          <select
            class="form-control"
            type="text"
            formControlName="nivel"
            [ngClass]="
              (temErro || formSituacaoFuncional.controls['nivel'].touched) &&
              formSituacaoFuncional.controls['nivel'].status == 'INVALID'
                ? 'is-invalid'
                : null
            "
          >
            <option value="">Selecione...</option>

            <option *ngFor="let nivel of listaNivel" [value]="nivel['Codigo']">
              {{ nivel['Codigo'] }} - {{ nivel['Descricao'] }}
            </option>
          </select>
          <span
            class="form-text text-danger"
            *ngIf="
              (temErro || formSituacaoFuncional.controls['nivel'].touched) &&
              formSituacaoFuncional.controls['nivel'].hasError('required')
            "
          >
            Campo obrigatório
          </span>
          <span class="form-text" *ngIf="loadingFuncaoNivel"
            >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
          ></span>
        </div>

        <div *ngIf="flag02 != '' && clientePublico" class="form-group col-md-3">
          <label class="font-weight-semibold">Ato/Portaria</label>
          <input
            class="form-control"
            type="text"
            formControlName="atoPortaria"
            placeholder="Ato/Portaria"
            (input)="capslock($event)"
            maxlength="50"
            [ngClass]="
              (temErro || formSituacaoFuncional.controls['atoPortaria'].touched) &&
              formSituacaoFuncional.controls['atoPortaria'].status == 'INVALID'
                ? 'is-invalid'
                : null
            "
          />
          <span
            class="form-text text-danger"
            *ngIf="
              (temErro || formSituacaoFuncional.controls['atoPortaria'].touched) &&
              formSituacaoFuncional.controls['atoPortaria'].hasError('required')
            "
          >
            Campo obrigatório
          </span>
        </div>

        <div *ngIf="flag02 != '' && clientePublico" class="form-group col-md-4">
          <label class="font-weight-semibold">Motivo</label>
          <select
            class="form-control"
            type="text"
            (change)="onMotivoChange()"
            formControlName="motivo"
            [ngClass]="
              (temErro || formSituacaoFuncional.controls['motivo'].touched) &&
              formSituacaoFuncional.controls['motivo'].status == 'INVALID'
                ? 'is-invalid'
                : null
            "
          >
            <option value="">Selecione...</option>
            <option *ngFor="let motivo of listaMotivos" [value]="motivo.Codigo">
              {{ motivo.Descricao }}
            </option>
          </select>
          <span
            class="form-text text-danger"
            *ngIf="
              (temErro || formSituacaoFuncional.controls['motivo'].touched) &&
              formSituacaoFuncional.controls['motivo'].hasError('required')
            "
          >
            Campo obrigatório
          </span>
        </div>

        <div
          *ngIf="flag02 == '2' && filiais.length >= 1 && transferencia == '1'"
          class="col-md-4 form-group"
        >
          <label class="font-weight-semibold">Filial de destino</label>
          <select
            class="form-control"
            type="text"
            formControlName="filialDestino"
            (change)="onFilialDestinoChange()"
            [ngClass]="
              (temErro || formSituacaoFuncional.controls['filialDestino'].touched) &&
              formSituacaoFuncional.controls['filialDestino'].invalid
                ? 'is-invalid'
                : null
            "
          >
            <option value="">Selecione...</option>
            <option *ngFor="let filial of filiais" value="{{ filial.Codigo }}">
              {{ filial.Codigo }} - {{ filial.Nome }}
            </option>
          </select>
          <span
            class="form-text text-danger"
            *ngIf="
              (temErro || formSituacaoFuncional.controls['filialDestino'].touched) &&
              formSituacaoFuncional.controls['filialDestino'].hasError('required')
            "
          >
            Campo obrigatório
          </span>
        </div>

        <div
          *ngIf="flag02 == '2' && filiais.length >= 1 && transferencia == '1'"
          class="col-md-4 form-group"
        >
          <label class="font-weight-semibold">Lotação</label>
          <select
            class="form-control"
            type="text"
            formControlName="lotacao"
            [ngClass]="
              (temErro || formSituacaoFuncional.controls['lotacao'].touched) &&
              formSituacaoFuncional.controls['lotacao'].status == 'INVALID'
                ? 'is-invalid'
                : null
            "
          >
            <option value="">Selecione...</option>
            <option *ngFor="let lotacao of listaLotacoes" value="{{ lotacao.Codigo }}">
              {{ lotacao.Codigo }} - {{ lotacao.Descricao }}
            </option>
          </select>
          <span
            class="form-text text-danger"
            *ngIf="
              (temErro || formSituacaoFuncional.controls['lotacao'].touched) &&
              formSituacaoFuncional.controls['lotacao'].hasError('required')
            "
          >
            Campo obrigatório
          </span>
        </div>
        <div *ngIf="flag02 != '' && clientePublico" class="form-group col-md-4">
          <label class="font-weight-semibold">Data do Ato</label>
          <input
            class="form-control"
            type="date"
            formControlName="dataAto"
            max="9999-12-31"
            [ngClass]="
              (temErro || formSituacaoFuncional.controls['dataAto'].touched) &&
              formSituacaoFuncional.controls['dataAto'].status == 'INVALID'
                ? 'is-invalid'
                : null
            "
          />

          <span
            class="form-text text-danger"
            *ngIf="
              (temErro || formSituacaoFuncional.controls['dataAto'].touched) &&
              formSituacaoFuncional.controls['dataAto'].hasError('required')
            "
          >
            Campo obrigatório
          </span>
        </div>
        <div *ngIf="flag02 != '' && clientePublico" class="form-group col-md-4">
          <label class="font-weight-semibold">Data Diário Oficial</label>
          <input
            class="form-control"
            type="date"
            formControlName="dataDoe"
            max="9999-12-31"
            [ngClass]="
              (temErro || formSituacaoFuncional.controls['dataDoe'].touched) &&
              formSituacaoFuncional.controls['dataDoe'].status == 'INVALID'
                ? 'is-invalid'
                : null
            "
          />
          <span
            class="form-text text-danger"
            *ngIf="
              (temErro || formSituacaoFuncional.controls['dataDoe'].touched) &&
              formSituacaoFuncional.controls['dataDoe'].hasError('required')
            "
          >
            Campo obrigatório
          </span>
        </div>
        <!-- <div *ngIf="flag02 != ''" class="form-group col-md-4">
          <label for="dataObs" class="font-weight-semibold"
            >Data Observação<small> (Opcional)</small></label
          >
          <input
            class="form-control"
            type="date"
            id="dataObs"
            formControlName="dataObs"
            max="9999-12-31"
          />
        </div> -->
        <div *ngIf="flag02 != ''" class="form-group col-md-12">
          <label for="obs" class="font-weight-semibold">Observação<small> (Opcional)</small></label>
          <textarea
            type="text"
            id="obs"
            rows="5"
            class="form-control"
            formControlName="observacao"
            placeholder="Insira aqui sua observação..."
          ></textarea>
        </div>
      </div>
      <div class="row ml-1 mb-3">
        <div *ngIf="flag02 != '' && clientePublico" class="">
          <app-anexo
            [pagina]="'017'"
            [matricula]="formTelaInicial.get('matricula_form').value"
            [nomeUsuario]="formSituacaoFuncional.get('nome').value"
            [notHaveCpf]="false"
            [enviarSeparadamente]="true"
          ></app-anexo>
        </div>
      </div>
    </div>
    <div class="card-footer text-right">
      <button class="btn btn-primary" type="submit">Salvar</button>
    </div>
  </form>
</div>

<div class="modal fade" id="observacoesTempoServicoModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-observacoes-ficha-funcional
        [colaborador]="{ Matricula: formTelaInicial.get('matricula_form').value }"
        tipo="300"
      >
      </app-observacoes-ficha-funcional>
    </div>
  </div>
</div>
