import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root'
})
export class CadastroContratoExperienciaService {
  localStorage: LocalStorage;
  urlAcesso:string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  filial: string;



  constructor(private http: HttpClient, private acesso: AcessoService) { 
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.urlAcesso = this.acesso.validarUrl();
    }
  }

  buscarParametros(empresaFilial: string){
    return this.http.get(`${this.urlAcesso}complementacao-cadastral/parametros/${empresaFilial}`, {headers:this.cabecalho});
  }

  salvarContrato(contrato: any, numeroOrdem: string){
    return this.http.post(`${this.urlAcesso}colaborador/contrato-experiencia/${numeroOrdem}`, contrato, {headers:this.cabecalho});
  }

  editarContrato(contrato: any, numeroOrdem: string){
    return this.http.put(`${this.urlAcesso}colaborador/contrato-experiencia/${numeroOrdem}`, contrato, {headers:this.cabecalho});
  }
  
  buscarNivel(cargo: string){
    return this.http.get(`${this.urlAcesso}cadastro-publico/nivel/${this.filial}/${cargo}`,{headers:this.cabecalho});
  }


}

