import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AcessoService} from 'src/app/service/acesso/acesso.service';
import {LocalStorage} from 'src/app/service/classes/localstorage/LocalStorage';
import {Crypto} from 'src/app/service/classes/crypto/crypto';
import {Formacao} from './formacao-basica.component';
import {ItemExp} from '../experiencia-profissional/experiencia-profissional.component';

@Injectable({
  providedIn: 'root',
})
export class FormacaoBasicaService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  url_acesso: string;
  cabecalho: any;
  filial: string;

  listaCursos: ItemExp[] = [];

  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl();

    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  formatarDataBarra(data: string) {
    let ano = data.substring(0, 4);
    let mes = data.substring(4, 6);
    let dia = data.substring(6, 8);

    return dia + '/' + mes + '/' + ano;
  }

  formatarDataHifen(data: string): string {
    let ano = data.slice(0, 4);
    let mes = data.slice(4, 6);
    let dia = data.slice(6, 8);

    return `${ano}-${mes}-${dia}`;
  }

  buscarFormacoes(matricula: number) {
    return this.http.get(`${this.url_acesso}form-basica-espec/listar/${this.filial}/${matricula}`, {
      headers: this.cabecalho,
    });
  }

  buscarCursos() {
    return this.http.get(`${this.url_acesso}experiencia-profissional/listar-cursos`, {
      headers: this.cabecalho,
    });
  }

  incluirFormacoes(formacao: Formacao) {
    return this.http.post(`${this.url_acesso}form-basica-espec/incluir`, formacao, {
      headers: this.cabecalho,
    });
  }

  atualizarFormacoes(formacao: Formacao) {
    return this.http.put(`${this.url_acesso}form-basica-espec/atualizar`, formacao, {
      headers: this.cabecalho,
    });
  }

  deletarFormacao(formacao: Formacao) {
    return this.http.delete(
      `${this.url_acesso}form-basica-espec/excluir/${formacao.Empfil}/${formacao.Mat}/${formacao.DtTermino.SetData}/${formacao.CodCrs}`,
      {
        headers: this.cabecalho,
      }
    );
  }

  downloadPdf(empfil: string, matricula: number, tipo: string) {
    return this.http.get(
      `${this.url_acesso}relatorios-pdf/download-pdf/${empfil}/${matricula}/${tipo}`,
      {
        headers: this.cabecalho,
      }
    );
  }

  inserirLista(listaNome: string, lista: ItemExp[]) {
    if (listaNome == 'cursos') {
      this.listaCursos = lista;
    }
  }

  retornarLista(listaNome: string) {
    if (listaNome == 'cursos') {
      return this.listaCursos;
    }
  }
}
