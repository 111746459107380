import {DatePipe} from '@angular/common';
import {ServerDate} from 'src/app/service/date/serverdate.service';
import {ErroService} from 'src/app/service/erros/erro.service';

export class Data {
  datepipe?: DatePipe = new DatePipe('pt-br');

  SetData: string = '';
  ToDate?: string;
  IsDate?: boolean;
  Empty?: boolean;

  constructor(data: string = null) {
    if (data != null) {
      if (data.includes('/')) {
        // data = data.replace(/\//g, '');
        this.SetData = this.getDateFormatAnoMesDia(data);
        this.ToDate = data;
      } else {
        data = data.replace(/-/g, '');
        this.SetData = data;
        this.ToDate = this.getDateComBarraAnoMesDia(data);
      }
    }
  }

  dataComBarra?(data: string): string {
    const dia = data.substr(0, 2);
    const mes = data.substr(2, 2);
    const ano = data.substr(4, 4);

    return `${dia}/${mes}/${ano}`;
  }

  /**
   * Recebe uma quantidade de dias como parametro e retorna o valor somado a data atual no formato - 18/08/2022 00:00 - 23/08/2022 00:00
   * @param dateNow
   * @param days
   */
  getDateHoursRangeNowAddDays?(dateNow: string, days: number) {
    if (!dateNow.includes('/')) {
      return '';
    }
    var format = dateNow.split('/');
    if (format.length === 3) {
      let parseDate = new Date(`${+format[2]}-${+format[1]}-${+format[0]}`);
      /* Sem hora
         * console.log(new Date(`${+format[2]}-${+format[1]}-${+format[0]}`));
         * Com hora
        console.log(new Date(`${format[2]}-${format[1]}-${format[0]}T00:00:00`));*/
      return (
        this.datepipe.transform(parseDate, 'dd/MM/yyyy') +
        ' 00:00' +
        ' - ' +
        this.datepipe.transform(
          new Date(
            parseDate.setDate(
              new Date(`${+format[2]}-${+format[1]}-${+format[0]}`).getDate() + days
            )
          ),
          'dd/MM/yyyy'
        ) +
        ' 00:00'
      ); //this.datepipe.transform(new Date(parseDate.setDate(new Date(`${+format[2]}-${+format[1]}-${+format[0]}`).getDate() + days)), 'dd/MM/yyyy') + ' 00:00';
    }
  }

  /**
   * Recebe uma quantidade de dias como parametro e retorna o valor somado a data atual no formato - 18/08/2022 - 23/08/2022
   * @param dateNow
   * @param days
   */
  getDateRangeNowAddDays?(dateNow: string, days: number) {
    if (!dateNow.includes('/')) {
      return '';
    }
    var format = dateNow.split('/');
    if (format.length === 3) {
      let parseDate = new Date(`${+format[2]}-${+format[1]}-${+format[0]}`);
      return (
        this.datepipe.transform(parseDate, 'dd/MM/yyyy') +
        ' - ' +
        this.datepipe.transform(
          new Date(
            parseDate.setDate(
              new Date(`${+format[2]}-${+format[1]}-${+format[0]}`).getDate() + days
            )
          ),
          'dd/MM/yyyy'
        )
      );
    }
  }

  /**
   * Recebe uma data no formato: 18/08/2022 e retorna uma data(String) com formato: 20220818(yyyyMMdd)
   * @param data
   */
  getDateFormatAnoMesDia?(data: string): string {
    if (!data.includes('/')) {
      return '';
    }
    var format = data.split('/');
    if (format.length === 3) {
      return format[2] + format[1] + format[0];
    }
    return '';
  }

  /**
   * Recebe uma data no formato: 18/08/2022 e retorna uma data(String) com formato: 18082022(ddMMyyyy)
   * @param data
   */
  getDateFormatDiaMesAno?(data: string): string {
    if (!data.includes('/')) {
      return '';
    }
    var format = data.split('/');
    if (format.length === 3) {
      return format[0] + format[1] + format[2];
    }
    return '';
  }

  /**
   * Recebe uma data no formato: 18082022 e retorna uma data(String) com formato: 18/08/2022(dd/MM/yyyy)
   * @param data
   */
  getDateComBarraDiaMesAno?(data: string): string {
    const dia = data.substr(0, 2);
    const mes = data.substr(2, 2);
    const ano = data.substr(4, 4);

    return `${dia}/${mes}/${ano}`;
  }

  /**
   * Recebe uma data no formato: 20220818 e retorna uma data(String) com formato: 18/08/2022(dd/MM/yyyy)
   * @param data
   */
  getDateComBarraAnoMesDia?(data: string): string {
    const ano = data.substr(0, 4);
    const mes = data.substr(4, 2);
    const dia = data.substr(6, 2);

    return `${dia}/${mes}/${ano}`;
  }

  /**
   * Recebe uma data no formato: 01052022(ddMMyyyy) OU 01052022 - 08:35:00 e retorna uma data(Date) com formato: Object Date
   * @param data
   */
  getStringToDate?(data: string): Date {
    var t;
    if (data.includes('-')) {
      t = data.split('-');
    } else if (data.includes('/')) {
      t = data.split('/');
      return new Date(`${+t[2]}-${+t[1]}-${+t[0]}`);
    }
    const dia = t[0].trim().substr(0, 2);
    const mes = t[0].trim().substr(2, 2);
    const ano = t[0].trim().substr(4, 4);
    if (t.length === 1) {
      return new Date(`${+ano}-${+mes}-${+dia}`);
    }
    const [hora, minuto, segundo] = t[1].trim().split(':');

    return new Date(`${+ano}-${+mes}-${+dia}T${+hora}:${+minuto}:${+segundo}`);
  }
}
