import { Injectable } from '@angular/core';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { CaracteristicasPessoais } from './caracteristicas-pessoais.component';

@Injectable({
  providedIn: 'root'
})
export class CaracteristicasPessoaisService {
  localStorage: LocalStorage;

  url_acesso:string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  filial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) { 
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.url_acesso = this.acesso.validarUrl();
    }
  }

  buscarTipoComorbidade(){
    return this.http.get(`${this.url_acesso}informacoestipocomorbidade`, {headers:this.cabecalho})
  }

  buscarCaracteristicaPessoal(matricula: string){
    return this.http.get(`${this.url_acesso}caracteristicas/pessoais/${matricula}`, {headers:this.cabecalho})
  }

  buscarRaca() {
    return this.http.get(`${this.url_acesso}parametros/cor_pele`, { headers: this.cabecalho });
  }
  
  SalvarCaracteristica(caracteristica: CaracteristicasPessoais, matricula: string) {
    return this.http.put(`${this.url_acesso}caracteristicas/pessoais/${matricula}/${this.filial}`, caracteristica,{ headers: this.cabecalho });
  }
}
