import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErroService } from 'src/app/service/erros/erro.service';
import { BancosLogicosService } from './manutencao-bd-logicos.service';
import Swal from 'sweetalert';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from 'src/app/componentes/componentes-funcionais/alerta-modal/alerta-modal.component';

@Component({
  selector: 'app-manutencao-bd-logicos',
  templateUrl: './manutencao-bd-logicos.component.html',
  styleUrls: ['./manutencao-bd-logicos.component.css']
})
export class ManutencaoBdLogicosComponent implements OnInit {

  localStorage: LocalStorage;

  formTelaInicial: UntypedFormGroup;
  formBancosLogicos: UntypedFormGroup;

  listaServicos: any[];
  listaBancosLogicos: any[] = [];
  listaBancosFisicos: any[];

  idExcluindo: number;
  tipoUsuario: string;

  clientePublico: boolean;
  loadingColaborador: boolean;
  telaFormulario: boolean = false;
  temErro: boolean = false;

  constructor(public fb: UntypedFormBuilder, private bdLogicosService: BancosLogicosService, private validaErro: ErroService, private spinner: NgxSpinnerService) {
    this.localStorage = this.decryptLocalStorage();
    this.tipoUsuario = this.localStorage.TipoUsuario;
    this.clientePublico = this.localStorage.ClientePublico === true;
  }

  ngOnInit() {
    this.formBancosLogicos = this.fb.group({
      codigo: ['', [Validators.required, Validators.maxLength(20)]],
      descricao: ['', [Validators.required, Validators.maxLength(80)]],
      codServicoFisico: ['', Validators.required],
      tipoUso: ['', Validators.required],
      bDadosFisicos: ['', Validators.required]
    });

    this.formTelaInicial = this.fb.group({
      codServico_form: ['', [Validators.required]],
    });

    this.buscarServicos()
  }

  buscarServicos(): void {
    this.bdLogicosService.buscarServicosPse()
      .subscribe(
        (data: any[]) => {
          this.listaServicos = data
          this.spinner.hide();
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        }
      );
  }

  submitBancoLogico(): void {
    if (this.formBancosLogicos.invalid) {
      this.temErro = true;
      // this.campoObrigatorio = true
      new AlertaModal('alerta','Atenção!','Preencha todos os campos obrigatórios!','p')
    }
    else {
      const bancoMontado = this.montarBancosLogicos()

      let existeBanco = this.listaBancosLogicos.filter((banco: BancosLogicos) => banco.CodigoBanco == this.formBancosLogicos.controls["codigo"].value).length

      if (existeBanco > 0) {
        this.alterarBancoLogico(bancoMontado)
      }
      else {
        this.salvarBancoLogico(bancoMontado);
      }
    }
  }

  salvarBancoLogico(banco: BancosLogicos) {
    if (banco == null) {new AlertaModal('alerta','Atenção!','Preencha todos os campos obrigatórios!','p')}
    else {
      this.spinner.show()
      this.bdLogicosService.salvarBdadosLogico(banco)
        .subscribe(
          data => {
            this.buscarBancosPorCodigo()
            new AlertaModal('sucesso','Sucesso!','Banco Lógico salvo com sucesso!','p')
            this.limparCampos()
            this.spinner.hide()
          },
          error => {
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
            this.spinner.hide()
          })
    }
  }

  alterarBancoLogico(banco: BancosLogicos) {
    this.spinner.show()
    this.bdLogicosService.alterarBancoLogico(banco)
      .subscribe(
        data => {
          this.buscarBancosPorCodigo()
          new AlertaModal('sucesso','Sucesso!','Banco Lógico alterado com sucesso!','p')
          this.limparCampos()
          this.spinner.hide()
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide()
        })
  }
//aleteração swal pendente
  deletar(bancoLogico: BancosLogicos, index: number): void {
    this.idExcluindo = index;
    Swal({
      title: "Você tem certeza?",
      text: "Esta operação não pode ser desfeita.",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true
        }
      }
    })
      .then((willDelete) => {
        if (willDelete) {
          this.excluirbancoLogico(bancoLogico);
        }

      });
    this.idExcluindo = null;
  }

  excluirbancoLogico(bancoLogico: BancosLogicos) {
    this.spinner.show()
    this.bdLogicosService.deletarBancoLogico(bancoLogico.CodigoServico, bancoLogico.CodigoBanco)
      .subscribe(
        data => {
          new AlertaModal('sucesso','Sucesso!','Banco Lógico excluído com sucesso!','p')
          this.limparCampos()
          this.buscarBancosPorCodigo()
          this.spinner.hide()
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide()
        })
  }

  limparCampos(): void {
    this.formBancosLogicos.controls["codigo"].enable()
    this.formBancosLogicos.controls['codigo'].setValue("")
    this.formBancosLogicos.controls['descricao'].setValue("")
    this.formBancosLogicos.controls['codServicoFisico'].setValue("")
    this.formBancosLogicos.controls['tipoUso'].setValue("")
    this.formBancosLogicos.controls['bDadosFisicos'].setValue("")
    this.formBancosLogicos.markAsPristine()
  }

  carregarBanco(banco: BancosLogicos): void {
    if (banco != null) {
      this.limparCampos()
      this.temErro = false

      this.formBancosLogicos.setValue({
        codigo: banco.CodigoBanco,
        descricao: banco.Descricao,
        codServicoFisico: banco.BancoFisico,
        tipoUso: banco.TipoUso,
        bDadosFisicos: banco.BancoFisico
      });
      this.formBancosLogicos.controls["codigo"].disable()
    } else {
      new AlertaModal('alerta','Atenção!','Banco Lógico vazio ou nulo!','p')
    }
  }

  buscarBancosFisicos(): void {
    this.bdLogicosService.buscarBancosFisicos(this.formTelaInicial.get('codServico_form').value)
      .subscribe(
        (data: any[]) => {
          this.listaBancosFisicos = data
          this.spinner.hide();
          if (!this.telaFormulario) { this.telaFormulario = !this.telaFormulario }
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        }
      );
  }

  buscarBancosPorCodigo(): void {
    this.bdLogicosService.buscarBancosLogicos(this.formTelaInicial.get('codServico_form').value)
      .subscribe(
        (data: any[]) => {
          this.listaBancosLogicos = data
          this.spinner.hide();
          if (!this.telaFormulario) { this.telaFormulario = !this.telaFormulario }
          this.buscarBancosFisicos()
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        }
      );
  }

  montarBancosLogicos(): BancosLogicos {
    const banco = this.formBancosLogicos.getRawValue()
    return {
      CodigoServico: this.formTelaInicial.controls['codServico_form'].value,
      CodigoBanco: banco.codigo,
      Descricao: banco.descricao,
      CodServicoBdFisico: banco.codServicoFisico,
      TipoUso: banco.tipoUso,
      BancoFisico: banco.bDadosFisicos,
      CodBdFisico: banco.bDadosFisicos
    }
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }
}

export interface BancosLogicos {
  CodigoServico: string
  CodigoBanco: string
  Descricao: string
  CodServicoBdFisico: string
  TipoUso: string
  BancoFisico: string
  CodBdFisico: string


}
