import { Component, ViewChild } from '@angular/core';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert';
import { ObservacoesDiversasService } from './observacoes-diversas.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { ObservacoesDiversasCadastroComponent } from './observacoes-diversas-cadastro/observacoes-diversas-cadastro.component';

@Component({
  selector: 'app-observacoes-diversas',
  templateUrl: './observacoes-diversas.component.html',
  styleUrls: ['./observacoes-diversas.component.css'],
})
export class ObservacoesDiversasComponent {
  @ViewChild(ObservacoesDiversasCadastroComponent) modal: ObservacoesDiversasCadastroComponent;
  matricula: number = null;
  colaborador: Cadastro = null;
  loading: boolean = false;
  formColaborador: FormGroup;
  localStorage: LocalStorage;

  listaObservacoes: Observacao[] = [];
  listaObservacoesAuxiliar: any = [];
  observacaoAtual: Observacao = null;
  cadastroObservacao: boolean = true;

  constructor(
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private observacoesService: ObservacoesDiversasService
  ) {
    this.localStorage = this.decryptLocalStorage();
    console.log('localstorage', this.localStorage);
    this.formColaborador = this.fb.group({
      matricula: ['', Validators.required],
      nome: [''],
    });
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }

  matriculaListener(evento) {
    this.colaborador = evento;
    this.matricula = parseInt(this.colaborador.Matricula);
    this.setarFormColaborador(evento);
  }

  setarFormColaborador(data) {
    this.formColaborador.get('matricula').disable();
    this.formColaborador.get('matricula').setValue(this.matricula);
    this.formColaborador.get('nome').disable();
    this.formColaborador.get('nome').setValue(data.InformacoesBasicas.NomeCompleto);
    this.loading = false;
    this.buscarObservacoes();
  }

  retornarTelaInicial() {
    this.matricula = null;
    this.formColaborador.reset();
    this.formColaborador.markAsPristine();
  }

  buscarObservacoes() {
    this.spinner.show('observacao');
    this.observacoesService
      .buscarObservacoes(this.colaborador.Filial.Codigo, this.colaborador.Matricula)
      .subscribe(
        (data: any) => {
          if (data) {
            let listaObs = data.sort((a, b) => a.Seq - b.Seq);
            console.log('buscar observações', listaObs);
            this.unirObservacoes(listaObs);
          } else {
            this.listaObservacoes = [];
          }
          this.spinner.hide('observacao');
        },
        (err) => {
          console.error(err);
          this.spinner.hide('observacao');
        }
      );
  }

  unirObservacoes(dadosBase: any[]) {
    this.listaObservacoesAuxiliar = [];
    this.listaObservacoes = [];
    dadosBase.forEach((item) => {
      let dtcseq = item.DTCSeq;
      let obs = item.Obs;

      if (!this.listaObservacoesAuxiliar[dtcseq]) {
        this.listaObservacoesAuxiliar[dtcseq] = [];
      }

      // this.listaObservacoesAuxiliar[dtcseq].push(obs);
      this.listaObservacoesAuxiliar[dtcseq].push(obs.trim());
    });
    console.log('listaobservacoesaux', this.listaObservacoesAuxiliar);
    // this.listaObservacoes = Object.keys(this.listaObservacoesAuxiliar).map((dtcseq) => {
    //   return { obs: this.listaObservacoesAuxiliar[dtcseq].join(' '), dtcseq: dtcseq };
    // });
    this.listaObservacoes = Object.keys(this.listaObservacoesAuxiliar).map((dtcseq) => {
      return { obs: this.listaObservacoesAuxiliar[dtcseq].join('\n'), dtcseq: dtcseq };
    });

    console.log('array observações', this.listaObservacoes);
  }

  formatarDataBarra(data: string): string {
    let ano = data.slice(0, 4);
    let mes = data.slice(4, 6);
    let dia = data.slice(6, 8);

    return dia + '/' + mes + '/' + ano;
  }

  aoAbrirModal(observacao?: Observacao) {
    this.modal.limparForm();
    if (observacao) {
      this.cadastroObservacao = false;
      this.observacaoAtual = observacao;
      this.modal.setarObservacaoExistente(observacao);
    } else {
      this.cadastroObservacao = true;
      this.modal.formObservacoes.get('Data').enable();
    }
  }

  deletarObservacao(dtcseq: string) {
    Swal({
      title: 'Deseja realmente excluir essa observação?',
      text: 'Essa ação não pode ser desfeita.',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
    }).then((result) => {
      this.spinner.show()
      if (result)
        this.observacoesService
          .deletarObservacao(this.colaborador.Filial.Codigo, this.colaborador.Matricula, dtcseq)
          .subscribe(
            (data) => {
              this.buscarObservacoes();
              Swal('Sucesso', 'Observação deletada com sucesso.', 'success');
              this.spinner.hide();
            },
            (err) => {
              Swal('Erro', 'Não foi possível deletar essa observação.', 'error');
              this.spinner.hide();
            }
          );
    });
  }
}

export interface Observacao {
  dtcseq: string;
  obs: string;
}
