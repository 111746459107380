<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="card">
  <div class="card-header">
    <h3 class="card-title" id="target"> Serviços</h3>
  </div>

  <form [formGroup]="formCadastroServico">
    <div class="card-body">
      <div class="row">
        <div class="col-md-3 form-group">
          <label class="font-weight-semibold">Código do Serviço</label>
          <div class="input-group">
            <input (keypress)='somenteLetras($event)' formControlName="codigo" class="form-control" type="text"
              placeholder="Código"
              [ngStyle]="temErro && formCadastroServico.controls['codigo'].status == 'INVALID' ? {'border-color':'red'}:null" />
          </div>
          <small class="form-text text-danger"
            *ngIf="temErro && formCadastroServico.controls['codigo'].status == 'INVALID'">Digite o Código</small>
        </div>

        <div class="col-md-4 form-group">
          <label class="font-weight-semibold">Descrição do Serviço</label>
          <input (keypress)='somenteLetras($event)' formControlName="descricao" class="form-control" type="text"
            placeholder="Descrição do Serviço"
            [ngStyle]="temErro && formCadastroServico.controls['descricao'].status == 'INVALID' ? {'border-color':'red'}:null" />
          <small class="form-text text-danger"
            *ngIf="temErro && formCadastroServico.controls['descricao'].status == 'INVALID'">
            Digite a Descrição do Serviço</small>
        </div>

        <div class="col-md-3 form-group">
          <label class="font-weight-semibold">Denominação do Cliente</label>
          <input formControlName="denominacaoCliente" class="form-control" type="text" placeholder="Denominação Cliente"
            [ngStyle]="temErro && formCadastroServico.controls['denominacaoCliente'].status == 'INVALID' ? {'border-color':'red'}:null" />
          <small class="form-text text-danger"
            *ngIf="temErro && formCadastroServico.controls['denominacaoCliente'].status == 'INVALID'">Digite a
            Denominação do Cliente</small>
        </div>

        <div class="col-md-2 form-group">
          <label class="font-weight-semibold">Prefixo do Serviço</label>
          <input formControlName="prefixoServico" class="form-control" type="text" placeholder="Prefixo do Serviço"
            [ngStyle]="temErro && formCadastroServico.controls['prefixoServico'].status == 'INVALID' ? {'border-color':'red'}:null" />
          <small class="form-text text-danger"
            *ngIf="temErro && formCadastroServico.controls['prefixoServico'].hasError('required')">
            Digite o Prefixo do Serviço</small>
          <small class="form-text text-danger"
            *ngIf="formCadastroServico.controls['prefixoServico'].hasError('maxlength')">
            máximo de letras (3)</small>
        </div>
      </div>
    </div>

    <div class="card-footer text-right">
      <button class="btn btn-primary pl-3 pr-3 mr-2" (click)="salvarServico()"
        [disabled]="formCadastroServico.status == 'INVALID'" type="button">Salvar</button>
      <button *ngIf="formCadastroServico.valid" class="btn btn-danger" (click)="limparCampos()"
        type="button">Cancelar</button>
    </div>
  </form>
</div>

<div class="card">

  <div class="card-header">
    <h3 class="card-title"> Listagem de Serviços</h3>
  </div>

  <div class="card-body">
    <div class="form-group row">
      <div class="input-group col-lg-12">
        <input type="number" class="form-control" name="searchText" (keyup)='filter' type="text"
          placeholder="Pesquisar Serviço">
        <span class="input-group-append">
          <span class="input-group-text"><i class="fa fa-search"></i></span>
        </span>
      </div>
    </div>

    <div class="table-responsive" style="max-height:45vh">
      <table class="table table-striped table-hover">
        <thead class="text-start">
          <tr class="text-center" style="white-space:nowrap">
            <th class="sticky-top  bg-white">Excluir/Editar</th>
            <th class="sticky-top  bg-white">Código Serviço</th>
            <th class="sticky-top  bg-white">Descrição do Serviço</th>
            <th class="sticky-top  bg-white"> Prefixo do Serviço</th>
          </tr>
        </thead>

        <tbody id="lista-processos">
          <tr *ngFor="let servico of listaServicos | filtrarServico: filter" class="text-center"
            style="white-space:nowrap">
            <td>
              <div>
                <button class="btn btn-danger mr-2" (click)="deletar(servico)">
                  <i class="fa fa-trash"></i>
                </button>
                <button class="btn bg-teal-400" (click)="carregarCampos(servico)">
                  <i class="fa fa-pencil"></i>
                </button>
              </div>
            </td>
            <td>{{servico.Codigo}}</td>
            <td>{{servico.Descricao}}</td>
            <td>{{servico.PrefixoServico}}</td>
        </tbody>
      </table>
    </div>
  </div>
</div>