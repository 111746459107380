<!-- Container -->
<div class="flex-fill">

    <!-- Error title -->
    <div class="text-center mb-3">
            <h1 class="error-title offline-title">Ops!</h1>
        <h5>Algum problema ocorreu. Por favor tente mais tarde.</h5>
    </div>
    <!-- /error title -->
</div>
<!-- /container -->