<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <h4 class="text-white">Carregando...</h4>
</ngx-spinner>
<div class="row component">
    <div class="col-lg-12 col-xl-12 form-group" id="colaboradores">
        <div class="card">
            <div class="card-header pb-3 mb-4 border-bottom">
                <div class="form-group header-elements-inline">
                    <h3 class="card-title">Colaboradores Pendentes - Anual(01/11/2023 - 12/12/2023)</h3>
                    <div class="header-elements"></div>
                </div>
            </div>

            <div class="card-body">
                <div class="row px-2 pb-2">
                    <div class="flex-grow-1">
                        <h5 class="text-center text-md-left font-weight-semibold d-print-flex">
                            Colaboradores Encontrados: {{ retornarTamanhoListagem() }}
                        </h5>
                    </div>
                    <div class="mx-auto">
                        <button class="btn btn-sm btn-outline-primary mr-2 d-print-none"
                            (click)="alterarStatusTodos(true)">
                            <i class="fa fa-lock pr-2"></i>
                            <span>Bloquear Todos</span>
                        </button>
                        <button class="btn btn-sm btn-outline-primary mr-2 d-print-none"
                            (click)="alterarStatusTodos(false)">
                            <i class="fa-solid fa-lock-open pr-2"></i>
                            <span>Liberar Todos</span>
                        </button>
                        <button class="btn btn-sm btn-outline-primary d-print-none" id="openModalButton"
                            data-toggle="modal" data-target="#filtroModal" data-backdrop="static" data-keyboard="false"
                            data-backdrop="static">
                            <i class="fa fa-filter pr-2"></i>
                            <span>Filtrar</span>
                        </button>
                        <button class="btn btn-sm btn-outline-primary ml-2 d-print-none" (click)="exportAsXLSX()">
                            <i class="far fas fa-file-excel pr-2 " style="font-size: 15px"></i>
                            <span>Exportar Excel</span>
                        </button>
                        <button class="btn btn-sm btn-outline-primary ml-2 d-print-none" (click)="exportAsPDF()">
                            <i class="far far fas fa-file-pdf pr-1 " style="font-size: 15px"></i>
                            <span>Exportar PDF</span>
                        </button>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-lg-12 form-group ">
                        <div class="table-responsive table-sm">
                            <table class="table table-striped table-hover mb-0">
                                <thead class="sticky-top bg-white border-bottom">
                                    <tr>
                                        <th class="text-center d-print-none">
                                            Gênero

                                            <a class="btn-link text-primary ml-1" title="Ordenar por ordem alfabética">


                                            </a>
                                        </th>
                                        <th class="text-left esconder-mobile col-3">
                                            Colaborador
                                            <a (click)="ordenarPorLetra()" class="btn-link text-primary ml-1"
                                                title="Ordenar por ordem alfabética">
                                                <i class="fa d-print-none" [class]="
                            ordemLetra == 0
                              ? 'fa-sort'
                              : ordemLetra == 1
                              ? 'fa-arrow-down'
                              : 'fa-arrow-up'
                          "></i>

                                            </a>
                                        </th>
                                        <th>
                                            Matrícula
                                            <a (click)="ordemPorNumero()" class="btn-link text-primary ml-1"
                                                title="Ordenar por ordem alfabética">
                                                <i class="fa d-print-none" [class]="
                            ordemNumero == 0
                              ? 'fa-sort'
                              : ordemNumero == 1
                              ? 'fa-arrow-down'
                              : 'fa-arrow-up'
                          "></i>
                                            </a>
                                        </th>
                                        <th>
                                            CPF
                                            <a class="btn-link text-primary ml-1" title="Ordenar avanço">

                                            </a>
                                        </th>
                                        <th>
                                            Status
                                            <a class="btn-link text-primary ml-1" title="Ordenar colaboradores">

                                            </a>
                                        </th>
                                        <th class="d-print-none">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of listaFiltrada" style="cursor: pointer">

                                        <td class="text-center d-print-none" data-target="#modalMaisAcoes">
                                            <img [src]="
                                     item.sexo === '1'
                                     ? 'assets/img/avatar-masculino.svg'
                                      : 'assets/img/avatar-feminino.svg'
                                         " height="40"
                                                [alt]="item.sexo === '1' ? 'Avatar masculino' : 'Avatar feminino'" />

                                        <td data-toggle="modal" data-target="#modalMaisAcoes"
                                            (click)="selecionarItem(item)">
                                            {{ item.colaborador }}
                                        </td>
                                        <td data-toggle="modal" data-target="#modalMaisAcoes"
                                            (click)="selecionarItem(item)">
                                            {{ item.matricula }}
                                        </td>
                                        <td data-toggle="modal" data-target="#modalMaisAcoes"
                                            (click)="selecionarItem(item)">
                                            {{ item.cpf }}
                                        </td>
                                        <td data-toggle="modal" data-target="#modalMaisAcoes"
                                            (click)="selecionarItem(item)">
                                            {{ item.status }}
                                        </td>
                                        <td>
                                            <button class="btn btn-sm btn-outline-primary mx-2 d-print-none"
                                                data-toggle="modal" data-target="#primaryModal"
                                                (click)="selecionarItem(item)">
                                                <i class="fa-solid"
                                                    [ngClass]="{'fa-lock': item.status === 'Bloqueado', 'fa-lock-open': item.status === 'Abstenção'}"></i>
                                            </button>
                                            <button class="btn btn-sm btn-outline-danger d-print-none"
                                                (click)="excluirCenso()">
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- modal de filtros -->
    <div class="modal" id="filtroModal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="container">
                        <div class="row align-items-start">
                            <h5 class="modal-title">Filtros</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="row pt-1">
                            <button class="btn btn-sm btn-outline-primary ml-auto" (click)="desmarcarTodos()">
                                <i class="fa fa-filter pr-2"></i>
                                <span>Limpar filtros</span>
                            </button>
                            <button class="btn btn-sm btn-outline-primary ml-2 mr-auto mr-md-0"
                                (click)="selecionarTodos()">
                                <i class="fa fa-download pr-2"></i>
                                <span>Selecionar Todos</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="modal-body">
                    <form id="filtro-form">
                        <table class="table">
                            <tbody>

                                <tr>
                                    <td>
                                        <h6 class=" py-2 mb-2">Status</h6>

                                        <table class="table table-striped">
                                            <tbody>

                                                <tr>
                                                    <td class="border-bottom">
                                                        <div class="form-check ">
                                                            <input class="form-check-input" type="checkbox"
                                                                value="bloqueado" id="tipoBloqueado" />
                                                            <label class="form-check-label" for="tipoBloqueado">
                                                                Bloqueado </label>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div class="form-check ">
                                                            <input class="form-check-input" type="checkbox"
                                                                value="abstenção" id="tipoAbstenção" />
                                                            <label class="form-check-label" for="tipoAbstenção">
                                                                Abstenção </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <h6 class=" py-2 mb-2">Gênero</h6>

                                        <table class="table table-striped">
                                            <tbody>

                                                <tr>
                                                    <td>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                                value="masculino" id="generoMasculino" />
                                                            <label class="form-check-label" for="generoMasculino">
                                                                Masculino </label>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox"
                                                                value="feminino" id="generoFeminino" />
                                                            <label class="form-check-label" for="generoFeminino">
                                                                Feminino </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>


                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close"
                        (click)="habilitarFiltros()">
                        Buscar
                    </button>
                </div>
            </div>
        </div>
    </div>



</div>