import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AcessoService } from '../../service/acesso/acesso.service';
import { Exoneracao, Nomeacao, Promocao } from './situacao-funcional.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root',
})
export class SituacaoFuncionalService {
  url_acesso: string;
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any = null;
  tpProcessamento: string = 'EM';

  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl();
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
    }
  }

  buscarParametros(filialAtual: string, matricula: string) {
    return this.http.get(
      `${this.url_acesso}situacao-funcional/${this.tpProcessamento}/${filialAtual}/${matricula}`,
      { headers: this.cabecalho }
    );
  }

  buscarFuncoes(filialAtual: string, grupo: string) {
    return this.http.get(`${this.url_acesso}funcoes-niveis/${filialAtual}/${grupo}`, {
      headers: this.cabecalho,
    });
  }
  buscarNiveis(filialAtual: string, grupo: string, codigo: string) {
    return this.http.get(`${this.url_acesso}funcoes-niveis/${filialAtual}/${grupo}/${codigo}`, {
      headers: this.cabecalho,
    });
  }

  buscarNomeacao(filialAtual: string) {
    return this.http.get(
      `${this.url_acesso}situacao-funcional-nomeacao/${this.tpProcessamento}/${filialAtual}`,
      { headers: this.cabecalho }
    );
  }

  buscarCargosMotivoTransferencia(filialDestino: string) {
    return this.http.get(
      `${this.url_acesso}situacao-funcional-nomeacao-transferencia/${this.tpProcessamento}/${filialDestino}`,
      { headers: this.cabecalho }
    );
  }

  buscarPromocao(filialAtual: string) {
    return this.http.get(
      `${this.url_acesso}situacao-funcional-promocao/${this.tpProcessamento}/${filialAtual}`,
      { headers: this.cabecalho }
    );
  }

  salvarPromocao(promocao: Promocao) {
    return this.http.post(`${this.url_acesso}situacao-funcional/promocao`, promocao, {
      headers: this.cabecalho,
    });
  }

  salvarNomeacao(nomeacao: Nomeacao) {
    return this.http.post(`${this.url_acesso}situacao-funcional/nomeacao`, nomeacao, {
      headers: this.cabecalho,
    });
  }

  salvarExoneracao(exoneracao: Exoneracao) {
    return this.http.post(`${this.url_acesso}situacao-funcional/exoneracao`, exoneracao, {
      headers: this.cabecalho,
    });
  }

  buscarDados(filialAtual: string, matricula: string) {
    return this.http.get(
      `${this.url_acesso}historico-promocoes/${this.tpProcessamento}/${filialAtual}/${matricula}`,
      { headers: this.cabecalho }
    );
  }
}
