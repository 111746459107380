<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<app-pesquisar-matricula *ngIf="matricula == null" [label]="'Matrícula'" [titulo]="'Rescisão'" [inputText]="true"
  (colaboradorEmitter)="matriculaListener($event)"></app-pesquisar-matricula>

<div class="container" *ngIf="matricula != null">
  <div>
    <form [formGroup]="rescisaoFormGroup" (ngSubmit)="onSubmit(rescisaoForm)" #rescisaoForm='ngForm'>
      <div class="col-12">
        <div class="card shadow-sm">
          <div class="card-header header-elements-inline">
            <h3><i class="fa fa-arrow-left pr-3 btn   " aria-hidden="true" (click)="retornarTelaInicial()"
                style="cursor: pointer; bottom: 2px;"></i><i class=" fa fa-pencil-square-o 2x mr-1"></i>Rescisão de
              Contrato de Trabalho</h3>
          </div>
          <div class="col-12 card-body">
            <div class="col-12">
              <div class="row">
                <div class="col-md-6 col-lg-5 col-xl-3 form-group">
                  <label class="font-weight-semibold">Matrícula do Funcionário</label>
                  <div class="input-group">
                    <input
                      [ngStyle]="temErro && rescisaoFormGroup.controls['Matricula'].hasError('required') || rescisaoFormGroup.controls['Matricula'].dirty && rescisaoFormGroup.controls['Matricula'].status == 'INVALID'  ? {'border-color':'red'} : null"
                      autofocus class="form-control" formControlName="Matricula" placeholder="Matrícula" mask="0*"
                      autofocus type="text">
                  </div>
                  <!-- <span class="form-text" *ngIf="carrega_rescisao">Carregando <i
            class="fa fa-spinner fa-spin" aria-hidden="false"></i></span> -->
                  <span class="form-text text-danger" *ngIf="temErro &&
                                rescisaoFormGroup.controls['Matricula'].hasError('required')">
                    Campo obrigatório
                  </span>
                </div>
                <div class="col-md-6 col-lg-7 col-xl-6 form-group">
                  <label class="font-weight-semibold">Nome Completo</label>
                  <input formControlName="Nome" name="Status" type="text" class="form-control" placeholder="Nome"
                    readonly />
                  <span class="form-text text-danger" *ngIf="temErro &&
                                rescisaoFormGroup.controls['Nome'].hasError('required')">
                    Pesquise por Matrícula
                  </span>
                </div>

                <div class="col-lg-9 col-xl-3 form-group">
                  <label class="font-weight-semibold">Tipo da Rescisão</label>
                  <select
                    [ngStyle]="temErro && rescisaoFormGroup.controls['TipoRescisao'].hasError('required') ? {'border-color':'red'} : null"
                    formControlName="TipoRescisao" class="form-control" (change)="tipoDeAviso()">
                    <option value=""> Selecione...</option>
                    <option *ngFor="let rescisao of tpRescisao" value="{{rescisao.Codigo}}">
                      {{rescisao.Descricao}}
                    </option>
                  </select>
                  <span class="form-text text-danger" *ngIf="temErro &&
                                rescisaoFormGroup.controls['TipoRescisao'].hasError('required')">
                    Campo obrigatório
                  </span>
                </div>
                <div *ngIf="rescisaoFormGroup.value.TipoRescisao  == '80'"
                  class="col-md-6 col-lg-5 col-xl-3 form-group">
                  <label class="font-weight-semibold">Número de certidão de óbito</label>
                  <input formControlName="NumeroObito" type="text" class="form-control"
                    placeholder="Certidão de óbito" />
                </div>
                <div class="col-md-6 col-lg-3 col-xl-2 form-group">
                  <label class="font-weight-semibold">Data de Rescisão</label>
                  <input
                    [ngStyle]=" temErro && rescisaoFormGroup.controls['DataRescisao'].hasError('required') || rescisaoFormGroup.controls['DataRescisao'].dirty && rescisaoFormGroup.controls['DataRescisao'].status == 'INVALID' ? {'border-color':'red'} : null"
                    type="date" formControlName="DataRescisao" class="form-control"
                    (blur)="verificaData(onChange($event.target))" (change)="addDezDias(onChange($event.target))"
                    style="padding-right: 0 ;" />
                  <span class="form-text text-danger" *ngIf="temErro &&
                                    rescisaoFormGroup.controls['DataRescisao'].hasError('required')">
                    Campo obrigatório
                  </span>
                  <span class="form-text text-danger"
                    *ngIf="rescisaoFormGroup.controls['DataRescisao'].dirty && rescisaoFormGroup.controls['DataRescisao'].hasError('data')">
                    Data Inválida
                  </span>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-2 form-group">
                  <label class="font-weight-semibold">Data de Homologação</label>
                  <input
                    [ngStyle]=" temErro && rescisaoFormGroup.controls['DataHomologacao'].hasError('required') || rescisaoFormGroup.controls['DataHomologacao'].dirty && rescisaoFormGroup.controls['DataHomologacao'].status == 'INVALID' ? {'border-color':'red'} : null"
                    type="date" formControlName="DataHomologacao" class="form-control"
                    (blur)="verificaData(onChange($event.target))" style="padding-right: 0 ;" />
                  <span class="form-text text-danger" *ngIf="temErro &&
                                rescisaoFormGroup.controls['DataHomologacao'].hasError('required')">
                    Campo obrigatório
                  </span>
                  <span class="form-text text-danger"
                    *ngIf="rescisaoFormGroup.controls['DataHomologacao'].dirty && rescisaoFormGroup.controls['DataHomologacao'].hasError('data')">
                    Data Inválida
                  </span>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-2 form-group">
                  <label class="font-weight-semibold">Data de Pagamento</label>
                  <input
                    [ngStyle]="temErro && rescisaoFormGroup.controls['DataPagamento'].hasError('required') || rescisaoFormGroup.controls['DataPagamento'].dirty && rescisaoFormGroup.controls['DataPagamento'].status == 'INVALID' ? {'border-color':'red'} : null"
                    type="date" formControlName="DataPagamento" class="form-control"
                    (blur)="verificaData(onChange($event.target))" style="padding-right: 0 ;" />
                  <span class="form-text text-danger" *ngIf="temErro &&
                                rescisaoFormGroup.controls['DataPagamento'].hasError('required')">
                    Campo obrigatório
                  </span>
                  <span class="form-text text-danger"
                    *ngIf="rescisaoFormGroup.controls['DataPagamento'].dirty && rescisaoFormGroup.controls['DataPagamento'].hasError('data')">
                    Data Inválida
                  </span>
                </div>
                <div class="col-md-6 col-lg-3 col-xl-2 form-group">
                  <label class="font-weight-semibold">
                    Visão Prévia <small class="font-weight-semibold">(opcional)</small>
                  </label>
                  <select formControlName="VisaoPrevia" class="form-control">
                    <option [value]="0">Normal</option>
                    <option [value]="1">Verbas</option>
                    <option [value]="2">Instruções</option>
                  </select>
                </div>

              </div>
            </div>
          </div>
          <div *ngIf="!existeAviso " class="card-footer text-right">
            <button *ngIf="ativarLimparCampos()" type="button" (click)="limparTodosCampos()" class="btn btn-danger mr-2">
              Limpar
            </button>
            <!-- <button routerLink="home" class="btn btn-danger mr-2" type="button">Cancelar</button> -->
            <button class="btn btn-success mr-2 pull-left" type="button" [disabled]='carrega_rescisao'
              (click)="obterPrevia()">
              Simular Rescisão
            </button>
            <!-- <button class="btn btn-success mr-2" type="button" [disabled]='carrega_rescisao' (click)="obterRelatorio($event, 1)">Baixar Relatório</button> -->
            <button class="btn btn-primary" type="submit" [disabled]='disabledER'>Efetuar Rescisão</button>
          </div>
        </div>
      </div>
      <div *ngIf="existeAviso" class="col-12">
        <div class="card shadow-sm">
          <div class="card-header">
            <h3>Aviso Prévio</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 form-group mb-0">
                <div class="col-11 row form-group ">
                  <div class="form-group mr-1">
                    <input type="radio" value="2" id="trabalhado" name="TipoAvisoPrevio"
                      formControlName="TipoAvisoPrevio" class="form-check-input-styled">
                    <label for="trabalhado" class="font-weight-bold ml-1">Trabalhado</label>
                  </div>
                  <div class="form-group ">
                    <input type="radio" value="1" id="indenizado" name="TipoAvisoPrevio"
                      formControlName="TipoAvisoPrevio">
                    <label for="indenizado" class="font-weight-bold ml-1">Indenizado</label>
                  </div>
                </div>
              </div>
              <div
                *ngIf="rescisaoFormGroup.value.TipoRescisao  === '20' && rescisaoFormGroup.value.TipoAvisoPrevio === 1"
                class="col-12 form-group">

                <div #divDispensarIndenizacao class="form-check form-check-left"
                  (click)="rescisao.DispensarIndenizacao = rescisao.DispensarIndenizacao == '1' ? '0' : '1'">
                  <label for="divDispensarIndenizacao">
                    <div class="uniform-checker">
                      <span
                        [ngClass]="rescisaoFormGroup.value.DispensarIndenizacao == '1' ? 'checked bg-primary' : '' ">
                        <input formControlName="DispensarIndenizacao" name="DispensarIndenizacao" value="1"
                          type="checkbox" class="form-input-styled">
                      </span>
                    </div>
                    <p class="font-weight-bold">Dispensar Indenização</p>
                  </label>
                </div>
              </div>
              <div *ngIf="rescisaoFormGroup.value.TipoAvisoPrevio === '2'"
                class="col-md-6 col-lg-9 col-xl-7 form-group">
                <label class="font-weight-semibold">Motivo</label>
                <select
                  [ngStyle]="temErro && rescisaoFormGroup.controls['CodigoAvisoPrevio'].hasError('required') || rescisaoFormGroup.controls['CodigoAvisoPrevio'].dirty && rescisaoFormGroup.controls['CodigoAvisoPrevio'].status == 'INVALID' ? {'border-color':'red'} : null"
                  formControlName="CodigoAvisoPrevio" class="form-control">
                  <option value="">Selecione...</option>
                  <option [value]="avisoPrev.Codigo" *ngFor="let avisoPrev of tpAvisoPrevio">
                    {{avisoPrev.Descricao}}
                  </option>
                </select>
                <span class="form-text text-danger" *ngIf="temErro &&
                            rescisaoFormGroup.controls['CodigoAvisoPrevio'].hasError('required')">
                  Campo obrigatório
                </span>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-2 form-group">
                <label class="font-weight-semibold">Data Início</label>
                <input
                  [ngStyle]="temErro && rescisaoFormGroup.controls['DataInicioAvisoPrevio'].hasError('required') || rescisaoFormGroup.controls['DataInicioAvisoPrevio'].dirty && rescisaoFormGroup.controls['DataInicioAvisoPrevio'].status == 'INVALID' ? {'border-color':'red'} : null"
                  type="date" (change)="calcularDtFimAvPrevio($event)" formControlName="DataInicioAvisoPrevio"
                  class="form-control" style="padding-right: 0 ;" />
                <span class="form-text text-danger" *ngIf="temErro &&
                            rescisaoFormGroup.controls['DataInicioAvisoPrevio'].hasError('required')">
                  Campo obrigatório
                </span>
                <span class="form-text text-danger"
                  *ngIf="rescisaoFormGroup.controls['DataInicioAvisoPrevio'].dirty && rescisaoFormGroup.controls['DataInicioAvisoPrevio'].hasError('data')">
                  Data Inválida
                </span>
              </div>
              <div class="col-md-6 col-lg-3 col-xl-1 form-group">
                <label class="font-weight-semibold">Qtd. Dias</label>
                <input
                  [ngStyle]="(rescisaoFormGroup.controls['QuantidadeDiasAvisoPrevio'].status == 'INVALID' && temErro) ? {'border-color':'red'} : null"
                  type="text" pattern="[0-9]+" formControlName="QuantidadeDiasAvisoPrevio" class="form-control"
                  id="qtdDias" placeholder="Dias" readonly />
              </div>
              <div class="col-md-6 col-lg-3 col-xl-2 form-group">
                <label class="font-weight-semibold">Data Final</label>
                <input type="date" formControlName="DataFinalAvisoPrevio" class="form-control"
                  style="padding-right: 0 ;" />
              </div>
              <!-- campo disabeld  -->
              <div *ngIf="rescisaoFormGroup.value.TipoAvisoPrevio == 2 && rescisaoFormGroup.value.TipoRescisao == '35' "
                class="col-md-6 col-lg-4 col-xl-3 form-group mt-4">
                <div #divComprovouNvEmprego class="form-check form-check-left"
                  (click)="ComprovouNovoEmprego = ComprovouNovoEmprego == true ? false : true">
                  <label for="divComprovouNvEmprego ">
                    <div class="uniform-checker">
                      <span [ngClass]="ComprovouNovoEmprego ? 'checked bg-primary' : '' ">
                        <input formControlName="ComprovouNovoEmprego" name="ComprovouNovoEmprego" value="true"
                          type="checkbox" class="form-input-styled">
                      </span>
                    </div>
                    <p class="font-weight-bold">Comprovou Novo Emprego</p>
                  </label>
                </div>
              </div>
              <div *ngIf="rescisaoFormGroup.value.TipoAvisoPrevio == 2 && rescisaoFormGroup.value.TipoRescisao == '35' "
                class="col-md-6 col-lg-6 col-xl-2 form-group">
                <label class="font-weight-semibold">Data de Interrupção</label>
                <input type="date" formControlName="DataInterrupcaoAvisoPrevio" class="form-control"
                  style="padding-right: 0 ;" />
              </div>
            </div>
          </div>
          <div class="card-footer text-right">
            <button type="button" (click)="limparCampos()" class="btn btn-danger mr-2">
              Limpar
            </button>
            <button class="btn btn-success mr-2 pull-left" type="button" [disabled]='carrega_rescisao'
              (click)="obterPrevia()">
              Simular Rescisão
            </button>
            <button class="btn btn-primary" type="submit" [disabled]='disabledER'>Efetuar Rescisão</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="row component m">
    <div class="col-lg-12 col-xl-12 form-group" id="listaRescisao">
      <div class="card">
        <div class="card-header">
          <div class="form-group header-elements-inline">
            <h3 class="card-title"> Listagem de Rescisões </h3>
            <div class="header-elements">
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="form-group row">
            <div class="input-group col-lg-12">
            
            </div>
          </div>


          <div class="form-group row d-none d-md-block">
            <div class="col-lg-12 form-group text-center">
              <div class="table-responsive" style="max-height:45vh">
                <table *ngIf="listaRescisao.length > 0" class="table table-striped table-hover">
                  <thead class="text-start">
                    <tr class="text-start" style="white-space:nowrap">
                      <th class="sticky-top col-2 bg-white">Ação</th>
                      <th class="sticky-top col-2 bg-white">Status</th>
                      <th class="sticky-top col-2 bg-white">Data Rescisão</th>
                      <th class="sticky-top col-2 bg-white">Homologação</th>
                      <th class="sticky-top col-2 bg-white">Data Pagamento</th>
                      <th class="sticky-top col-2 bg-white">Motivo de Afastamento</th>
                      <th class="sticky-top col-2 bg-white">Tipo</th>
                    </tr>
                  </thead>
                  <tbody id="lista-processos">
                    <tr *ngFor="let rescisao of listaRescisao" class="text-start" style="white-space:nowrap">
                      <td>
                        <div>
                          <!-- Botão só ativa se o campo ( permiteCancelar ) do objeto de rescisão estiver true-->
                          <button type="button" *ngIf="validaCancelar(rescisao)" data-toggle="modal"
                            class="btn btn-secondary" (click)="verificaRescisaoClicada(rescisao)"
                            data-target="#modalListaRescisoes">
                            <i class="fa fa-exclamation"></i>
                          </button>
                        </div>
                      </td>
                      <td>{{rescisao.Calculada ? "CALCULADA" : "ABERTA"}}</td>
                      <td>{{rescisao.Data.ToDate == "" ? "NÃO INFORMADO" : rescisao.Data.ToDate}}</td>
                      <td>
                        {{
                        rescisao.DataHomologacao.ToDate == "" ? "NÃO INFORMADA" :
                        rescisao.DataHomologacao.ToDate
                        }}
                      </td>
                      <td>
                        {{
                        rescisao.DataPagamento.ToDate == "" ? "NÃO INFORMADA" :
                        rescisao.DataPagamento.ToDate
                        }}
                      </td>
                      <td>{{rescisao.NomeAfastamento}}</td>
                      <td>{{rescisao.TipoDeProcessamento == "RS" ? "RESCISÃO - RS" : "COMPLEMENTAR - " +
                        rescisao.TipoDeProcessamento}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="listaRescisao !== null && listaRescisao.length === 0"
                class="d-flex justify-content-center align-items-center" style="height:20vh">
                <h5 class="font-weight-semibold">Não há rescisão para ser exibida</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  modal Lista Ações Rescisão -->
  <div class="modal fade " id="modalListaRescisoes" tabindex="-1" role="dialog"
    aria-labelledby="modalListaColaboradoresLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: 1px solid rgb(101, 103, 104);">
          <h5 class="modal-title mb-2" id="modalListaColaboradoresLabel">
            Ação Desejada
          </h5>
          <button type="button" id="closeModalbt" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div (click)="reabrirRescisaoModal()" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left"
              *ngIf="isCalculada">
              <i class="fa fa-pencil-square-o fa-danger mr-1 text-info" aria-hidden="true"></i>
              <label [ngStyle]="{cursor: 'pointer'}" class="w-50 h6 font-weight-semibold text-info">Reabrir
                Rescisão</label>
            </div>
            <div (click)="cancelarRescisaoModal()" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left"
              *ngIf="isCalculada">
              <i class="fa fa-trash-o mr-2 text-danger" aria-hidden="true"></i>
              <label [ngStyle]="{cursor: 'pointer'}" class="w-50 h6 font-weight-semibold text-danger">Cancelar
                Rescisão</label>
            </div>
            <div (click)="calculaRescisaoDefinitiva()" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left"
              *ngIf="!isCalculada">
              <i class="fa fa-calculator fa-danger mr-1 text-info" aria-hidden="true"></i>
              <label [ngStyle]="{cursor: 'pointer'}" class="w-50 h6 font-weight-semibold text-info">Cálculo
                Definitivo</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal Lista Ações Rescisão fim -->
  <!-- MODAL -->
  <div class="modal fade" tabindex="-1" role="dialog" id="modal-previa-rescisao">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Prévia da Rescisão</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 form-group table-responsive">
              <div class="row form-group text-center">
                <table class="table table-striped table-hover mb-0">
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Verba</th>
                      <th>Nome</th>
                      <th>Transação</th>
                      <th>Oco</th>
                      <th>Pag</th>
                      <th>Refer</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let verba of verbasVantagem">
                      <td>Vantagens</td>
                      <td>{{verba.Verba}}</td>
                      <td>{{verba.NomeVerba}}</td>
                      <td>{{verba.Trans}}</td>
                      <td>{{verba.Oco}}</td>
                      <td>{{verba.Pag}}</td>
                      <td>{{verba.Refer}}</td>
                      <td>{{verba.Valor}}</td>
                    </tr>
                    <tr *ngFor="let verba of verbasDesconto">
                      <td>Descontos</td>
                      <td>{{verba.Verba}}</td>
                      <td>{{verba.NomeVerba}}</td>
                      <td>{{verba.Trans}}</td>
                      <td>{{verba.Oco}}</td>
                      <td>{{verba.Pag}}</td>
                      <td>{{verba.Refer}}</td>
                      <td>{{verba.Valor}}</td>
                    </tr>
                    <tr *ngFor="let verba of verbasOutros">
                      <td>Outros</td>
                      <td>{{verba.Verba}}</td>
                      <td>{{verba.NomeVerba}}</td>
                      <td>{{verba.Trans}}</td>
                      <td>{{verba.Oco}}</td>
                      <td>{{verba.Pag}}</td>
                      <td>{{verba.Refer}}</td>
                      <td>{{verba.Valor}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2 form-group">
              <label class="font-weight-semibold">Total Vantagens</label>
              <input type="text" class="form-control" [(ngModel)]="totalVantagens" readonly />
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2 form-group">
              <label class="font-weight-semibold">Total Descontos</label>
              <input type="text" class="form-control" [(ngModel)]="totalDescontos" readonly />
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2 form-group">
              <label class="font-weight-semibold">Total Líquido</label>
              <input type="text" class="form-control" [(ngModel)]="totalLiquido" readonly />
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2 form-group">
              <label class="font-weight-semibold">Total FGTS</label>
              <input type="text" class="form-control" [(ngModel)]="totalFgts" readonly />
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2 form-group">
              <label class="font-weight-semibold">Total Remuneração</label>
              <input type="text" class="form-control" [(ngModel)]="totalRemuneracao" readonly />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="obterRelatorioPrevia()">
            Baixar Relatório
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL -->
  <div class="modal fade" tabindex="-1" role="dialog" id="modal-localiza-funcionario">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Localizar o funcionário</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Implementar rotina aqui!</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        </div>
      </div>
    </div>
  </div>
</div>