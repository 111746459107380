import { Pipe, PipeTransform } from "@angular/core"
import { Tarefa } from "./tarefas.component" 

@Pipe({name : 'filtrarTarefa'})
export class filtrarTarefa implements PipeTransform{
    transform(tarefa : Tarefa[], filtrarTarefa : string) {
        filtrarTarefa = filtrarTarefa.toLowerCase()
        if (filtrarTarefa != "") {
           tarefa = tarefa.filter(taref => taref.Descricao.toLowerCase().includes(filtrarTarefa) || String(taref.Codigo).includes(filtrarTarefa))
           return tarefa  
        }else{
            return tarefa
        }
    }
}