import { Component } from '@angular/core';
import type { EChartsOption } from 'echarts';

@Component({
  selector: 'app-colaboradores-home',
  templateUrl: './colaboradores-home.component.html',
  styleUrls: ['./colaboradores-home.component.css']
})
export class ColaboradoresHomeComponent {
  option: EChartsOption = {
    color: ['#C4C8CE', '#2C54A4', '#4F5663'],
    
    legend: {
      orient: 'vertical',
      right: '15px',
      top: 'center'
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: '50%',
        center: ['30%', '50%'],
        width: 'max',
        
        label: {
          formatter: '{c}%',
          position: 'inside'
        },
        data: [
          { value: 25, name: 'Afastados' },
          { value: 25, name: 'Em férias' },
          { value: 50, name: 'Ativos' },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          }
        }
      }
    ]
  };  
}
