<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="card">
  <div class="card-header">
    <h3 class="card-title">
      <i
        class="fa fa-arrow-left pr-3 btn"
        aria-hidden="true"
        style="cursor: pointer; bottom: 2px"
        (click)="retornarTelaColaboradores()"
      ></i>
      Nome Social
    </h3>
  </div>
  <div class="card-body row">
    <!-- *ngIf="colaborador" -->

    <div class="form-group col-md-2">
      <label class="justify-content-start font-weight-semibold">Matricula </label>
      <input
        type="text"
        placeholder="Matrícula"
        class="form-control"
        [value]="infoColaborador.matricula"
        disabled
      />
    </div>

    <div class="form-group col-md-6">
      <label class="justify-content-start font-weight-semibold">Nome do Colaborador </label>
      <input
        type="text"
        placeholder="Colaborador"
        class="form-control"
        [value]="infoColaborador.nome"
        disabled
      />
    </div>
    <div class="form-group col-md-4">
      <label class="justify-content-start font-weight-semibold">Vínculo </label>
      <input
        type="text"
        placeholder="Vínculo"
        class="form-control"
        [value]="infoColaborador.vinculo"
        disabled
      />
    </div>
  </div>
</div>

<div class="card">
  <form class="card-body" [formGroup]="formCadastro">
    <div class="row">
      <fieldset class="col-md-12">
        <legend>
          <h6 class="font-weight-semibold">Dados do Nome Social</h6>
        </legend>
        <div class="row">
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold" for="processo">Processo</label>
            <select
              class="form-control"
              formControlName="processo"
              id="processo"
              [ngClass]="
                formCadastro.get('processo').invalid && formCadastro.get('processo').touched
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option *ngFor="let item of listaProcessos" [value]="item.Processo">
                {{ item.Processo }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="formCadastro.get('processo').invalid && formCadastro.get('processo').touched"
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold" for="numero">Número</label>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                formControlName="numero"
                id="numero"
                maxlength="4"
                placeholder="Número"
                [ngClass]="
                  formCadastro.get('numero').invalid && formCadastro.get('numero').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <!-- qual length max? -->
            </div>
            <span
              class="form-text text-danger"
              *ngIf="formCadastro.get('numero').invalid && formCadastro.get('numero').touched"
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold" for="iniVigencia">Início do Processo</label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="iniVigencia"
                id="iniVigencia"
                placeholder="Início Vigência"
                [ngClass]="
                  formCadastro.get('iniVigencia').invalid && formCadastro.get('iniVigencia').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <!-- qual length max? -->
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('iniVigencia').invalid && formCadastro.get('iniVigencia').touched
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-6 form-group">
            <label class="font-weight-semibold" for="nome">Nome</label>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                formControlName="nome"
                id="nome"
                placeholder="Nome"
                maxlength="80"
                [ngClass]="
                  formCadastro.get('nome').invalid && formCadastro.get('nome').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <!-- qual length max? -->
            </div>
            <span
              class="form-text text-danger"
              *ngIf="formCadastro.get('nome').invalid && formCadastro.get('nome').touched"
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold" for="aPartirDe">A Partir De</label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="aPartirDe"
                id="aPartirDe"
                max="9999-12-31"
                [ngClass]="
                  formCadastro.get('aPartirDe').invalid && formCadastro.get('aPartirDe').touched
                    ? 'is-invalid'
                    : null
                "
              />
            </div>
            <span
              class="form-text text-danger"
              *ngIf="formCadastro.get('aPartirDe').invalid && formCadastro.get('aPartirDe').touched"
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold" for="dtFim">Até <small> (Opcional)</small></label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="dtFim"
                id="dtFim"
                max="9999-12-31"
                [ngClass]="
                  formCadastro.get('dtFim').invalid && formCadastro.get('dtFim').touched
                    ? 'is-invalid'
                    : null
                "
              />
            </div>
            <span
              class="form-text text-danger"
              *ngIf="formCadastro.get('dtFim').invalid && formCadastro.get('dtFim').touched"
            >
              Campo obrigatório
            </span>
          </div>
        </div>
      </fieldset>
    </div>
  </form>
  <div class="card-footer mt-4 d-flex justify-content-end">
    <button type="submit" class="btn btn-primary" (click)="submitCadastro()">
      Submeter
      <i class="fa fa-paper-plane ml-2"></i>
    </button>
  </div>
</div>
