import Swal from 'sweetalert';
import { Cadastro } from '../../service/classes/cadastro/cadastro';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { cpfValidator } from 'src/app/service/Validators/cpfValidator';
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { Data } from 'src/app/service/interfaces/Data';
import { ActivatedRoute, Router } from '@angular/router';
import { DependentesService } from './dependentes.service';
import { ErroService } from 'src/app/service/erros/erro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileService } from '../profile/profile.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { Dependente } from './dependentes';
import { Anexo, AnexoComponent } from '../anexo/anexo.component';
import { async } from 'rxjs';

@Component({
  selector: 'app-dependentes',
  templateUrl: './dependentes.component.html',
  styleUrls: ['./dependentes.component.css'],
})
export class DependentesComponent implements OnInit, OnChanges {
  @Input() telaCadastro: boolean = true;
  @Input() matricula: string = '';
  @Input() ColaboradorInativo: boolean = true;
  @Input() estadoCivil: string = '';
  @Output() mudandoTela = new EventEmitter<boolean>();
  @Input() listaDependente: Dependente[];
  @ViewChild(AnexoComponent, { static: false }) anexoComponent: AnexoComponent;
  @Input() cadastroUsuario: Cadastro;
  localStorage: LocalStorage;
  dependente: Dependente;
  telaDependente: boolean = true;
  editarDependente: boolean = false;

  // existeDependente:boolean = false VARIAVEL DE TESTE PARA TROCAR INFORMAÇÃO ENTRE COMPONENTES
  tipoDependecias: any[] = [];
  uf: any[] = [];
  sexo: any[] = [];
  cidade: any[] = [];
  cpfAntigo: string;
  listaAnexo: Anexo[];
  ArrayMenorDependente: any[] = ['3', '6', '8', '10', '11', '23'];
  UploadDocumentos: Array<any> = [];
  cadastroDependente: boolean = false;
  numeroDeOrdem: any;
  numeroDependente: any;
  codigo_uf: any;
  formDependente: any;
  salarioFamiliarCheckBox: boolean = false;
  irrfCheckBox: boolean = false;
  documentoObrigatorio: boolean = false;
  validaDependente: boolean = false;
  listaMotivoInicio: any[] = [];
  listaMotivoFim: any[] = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private dependenteService: DependentesService,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.route.params.subscribe((params) => (this.numeroDeOrdem = params['numOrdem']));
    this.route.params.subscribe((params) => (this.numeroDependente = params['numeroDependente']));
  }

  ngOnInit() {
    this.localStorage = this.decryptLocalStorage();
    this.formDependente = this.fb.group({
      cpf: ['', Validators.compose([Validators.required, cpfValidator])],
      nomeCompleto: ['', Validators.compose([Validators.required])],
      sexo: ['', Validators.compose([Validators.required])],
      tipoDependencia: ['', Validators.compose([Validators.required])],
      dataNascimento: ['', Validators.compose([Validators.required])],
      uf: ['', Validators.compose([Validators.required])],
      cidade: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      dataFinal: [''],
      motivoInicio: [''],
      motivoFim: [''],
      dataInicialAC: [''],
      dataEntrega: [''],
      cartorio: [''],
      certidao: [''],
      search: [''],
      
    });
    this.buscarUf();
    this.buscarTipoDependencia();
    this.buscarSexo();
    this.buscarMotivoInicio();
    this.buscarMotivoFim();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  verificarDependente(): void {
    if (this.numeroDependente != 9999) {
      const dependente: Dependente = this.listaDependente.find(
        (depen) => depen.Numero == this.numeroDependente
      );
      this.montarTelaDependente(dependente);
    }
  }

  buscarTipoDependencia(): void {
    this.tipoDependecias = [];
    this.spinner.show();
    this.dependenteService.buscarTipoDependencia().subscribe(
      (data) => {
        this.tipoDependecias = JSON.parse(JSON.stringify(data));
        this.buscarSexo();
        this.spinner.hide();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  capslock(event): void {
    event.target.value = event.target.value.toUpperCase();
  }

  buscarUf() {
    this.spinner.show();
    this.dependenteService.buscarEstado().subscribe(
      (data) => {
        this.uf = JSON.parse(JSON.stringify(data));
        this.spinner.hide();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  buscarSexo(): void {
    this.spinner.show();
    this.sexo = [];
    this.dependenteService.buscarSexo().subscribe(
      (data) => {
        this.sexo = JSON.parse(JSON.stringify(data));
        this.spinner.hide();
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarCidade(codigo: string = null) {
    this.cidade = [];
    if (this.formDependente.get('uf').value != '') {
      this.spinner.show();
      this.buscarCodigoEstado(this.uf, this.formDependente.get('uf').value);

      this.dependenteService.buscarCidade(this.codigo_uf).subscribe(
        (data: any) => {
          this.cidade = data;
          codigo
            ? this.formDependente.controls['cidade'].setValue(codigo)
            : this.formDependente.controls['cidade'].setValue();
          this.formDependente.get('cidade').enable();
          this.spinner.hide();
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        }
      );
    } else {
      this.formDependente.controls['cidade'].setValue('');
      this.formDependente.get('cidade').disable();
    }
  }

  buscarMotivoInicio() {
    this.spinner.show();
    this.dependenteService.buscarMotivoInicio().subscribe({
      next: (data: any) => {
        this.listaMotivoInicio = data;
        this.spinner.hide();
      },
      error: (erro) => {
        this.validaErro.retornoErro(erro);
        this.spinner.hide();
      },
    });
  }
  buscarMotivoFim() {
    this.spinner.show();
    this.dependenteService.buscarMotivoFim().subscribe({
      next: (data: any) => {
        this.listaMotivoFim = data;
        this.spinner.hide();
      },
      error: (erro) => {
        this.validaErro.retornoErro(erro);
        this.spinner.hide();
      },
    });
  }

  montarTelaDependente(dependente: Dependente) {
    if (dependente) {
      this.formDependente.controls['uf'].setValue(dependente.Ufnatural.toString());
      this.buscarCidade(dependente.CodigoMunicipio);
      this.spinner.show();
      this.cpfAntigo = dependente.Cpf ? String(dependente.Cpf) : '';
      this.irrfCheckBox = Boolean(dependente.SdependenteIr);
      this.salarioFamiliarCheckBox = Boolean(dependente.SdependenteSf);
      this.formDependente.controls['cpf'].setValue(
        dependente.Cpf.toString().length < 11
          ? '0' + dependente.Cpf.toString()
          : dependente.Cpf.toString()
      );
      this.formDependente.controls['nomeCompleto'].setValue(dependente.Nome.toString());
      this.formDependente.controls['sexo'].setValue(dependente.Sexo.toString());
      this.formDependente.controls['tipoDependencia'].setValue(
        dependente.TipoDependente.valor.toString()
      );
      this.formDependente.controls['dataNascimento'].setValue(
        this.dependenteService.formatarDataHifen(dependente.DataNascimento.SetData)
      );
      this.formDependente.controls['cidade'].setValue(dependente.CodigoMunicipio.toString());
      this.formDependente.controls['dataEntrega'].setValue(
        this.dependenteService.formatarDataHifen(dependente.Dtent.SetData.toString())
      );
      dependente.DependenteSf === 1
        ? (this.salarioFamiliarCheckBox = true)
        : (this.salarioFamiliarCheckBox = false);
      dependente.DependenteIr === 1 ? (this.irrfCheckBox = true) : (this.irrfCheckBox = false);
      this.formDependente.controls['cartorio'].setValue(dependente.NumeroDocumento.toString());
      this.formDependente.controls['motivoInicio'].setValue(dependente.MotivoInicioDependencia);
      this.formDependente.controls['motivoFim'].setValue(dependente.MotivoFimDependencia);
      this.spinner.hide();
    }
  }

  deletarIcon(indice) {
    let numeroIndex = indice;
    this.UploadDocumentos.splice(numeroIndex, 1);
  }

  validarCadastroDependente() {
    const dependente = this.formDependente.value;
    this.listaAnexo = this.anexoComponent.retornarValores();
    if (this.formDependente.invalid) {
      Swal('Preencha todos os campos Obrigatórios!', 'Verifique os campos incorretos. ', 'warning');
      this.validaDependente = true;
      window.scrollTo(0, 0);
    } else if (this.listaAnexo.length <= 0 && !this.editarDependente) {
      Swal(
        'Insira ao menos uma certidão!',
        'Clique em Anexar Certidão e selecione um arquivo.',
        'warning'
      );
      this.validaDependente = true;
      window.scrollTo(0, 0);
    } else if (dependente.sexo.toString() == '0') {
      Swal('Preencha todos os campos Obrigatórios!', 'Verifique os campos incorretos. ', 'warning');
      this.validaDependente = true;
      window.scrollTo(0, 0);
    } else {
      this.editarDependente ? this.atualizarDependente() : this.salvarDependente();
    }
  }

  verificarMenor() {
    const { tipoDependencia, dataNascimento } = this.formDependente.value;
    this.ArrayMenorDependente.find((arrayMotivo) => {
      if (arrayMotivo === tipoDependencia && !this.ValidarValoresDatas(dataNascimento)) {
        this.formDependente.controls['dataNascimento'].setValue('');
      }
    });
  }

  montarDependente(): Dependente {
    const dependente = this.formDependente.getRawValue();
    let tipoDep = this.tipoDependecias.find(
      (dep) => dep.valor == parseInt(this.formDependente.get('tipoDependencia').value)
    );
    return {
      Sigla: 'FAPP',
      EmpresaFilial: Number(this.localStorage.FilialAtual.toString()),
      Matricula: Number(this.matricula),
      Numero: 1,
      Tipo: tipoDep.valor,
      Filiacao: 1,
      Nome: dependente.nomeCompleto,
      DataNascimento: {
        SetData: this.dependenteService.formatarDataBarra(dependente.dataNascimento),
        ToDate: this.dependenteService.formatarDataSemEspaco(dependente.dataNascimento),
        Empty: false,
        IsDate: true,
      },
      InstrucaoTipo: 0,
      InstrucaoSituacao: 0,
      Nacionalidade: 10,
      Ufnatural: dependente.uf,
      Sexo: dependente.sexo,
      IdentidadeNumero: '1',
      IdentidadeSer: '0',
      IdentidadeUf: '',
      Cpf: dependente.cpf,
      NumeroDocumento: dependente.cartorio,
      Dtent: {
        SetData:
          dependente.dataEntrega !== ''
            ? this.dependenteService.formatarDataBarra(dependente.dataEntrega)
            : '',
        ToDate:
          dependente.dataEntrega !== ''
            ? this.dependenteService.formatarDataSemEspaco(dependente.dataEntrega)
            : '',
        Empty: dependente.dataEntrega !== '' ? false : true,
        IsDate: true,
      },
      DependenteIr: this.irrfCheckBox ? 1 : 0,
      DependenteSf: this.salarioFamiliarCheckBox ? 1 : 0,
      Snumero: 0,
      Stipo: 0,
      SdependenteIr: 0,
      SdependenteSf: 0,
      LocalNascimento: '',
      MotivoDependente: dependente.tipoDependencia,
      MotivoInicioDependencia: this.formDependente.get('motivoInicio').value,

      MotivoFimDependencia: this.formDependente.get('motivoFim').value,

      TipoLogradouro: '',
      DescricaoLogradouro: '',
      NumeroLogradouro: '',
      Complemento: '',
      Bairro: '',
      CodigoMunicipio: dependente.cidade,
      Cep: '',
      Uf: '',
      Telefone: '',
      Email: '',
      UploadDocumentos: this.listaAnexo ? this.listaAnexo : null,
      CpfAntigo: this.cpfAntigo,
      TipoDependente: {
        descricao: tipoDep.descricao,
        valor: tipoDep.valor,
      },
    };
  }

  verificaTipoDependente() {
    const existeDependenteVinculado = this.listaDependente
      ? this.listaDependente.some((dep) => {
          return (
            (dep.Tipo === 1 && this.estadoCivil == '2') ||
            (dep.Tipo === 2 && this.estadoCivil == '6')
          );
        })
      : true;
    const existeEstadoCivilConjuge = this.estadoCivil == '2' || this.estadoCivil == '6';

    return existeEstadoCivilConjuge && !existeDependenteVinculado;
  }

  ValidarValoresDatas(data) {
    var dia,
      mes,
      ano,
      complementoData = 'T00:00:00';
    dia = data.substring(0, 2);
    mes = data.substring(2, 4);
    ano = data.substring(4, 8);

    let dateAtual: Date = new Date();
    var time = new Date(ano + '-' + mes + '-' + dia + complementoData);

    let auxTime = time.getFullYear();
    let auxDate = dateAtual.getFullYear();
    if (!this.validarData(dia + '/' + mes + '/' + ano)) {
      return true;
    }
    if (auxDate - 18 <= auxTime) {
      return true;
    } else {
      Swal(
        ' Não é Possível cadastrar maiores de 18 anos para esse tipo de dependência ',
        'Por favor, preencha uma data válida.',
        'warning'
      );
      return false;
    }
  }

  async buscarUltimoNumDoc() {
    const documentosOrdenados = await this.UploadDocumentos.sort((a, b) => {
      if (Number(a.NumDoc) > Number(b.NumDoc)) return -1;
      else if (Number(a.NumDoc) < Number(b.NumDoc)) return 1;
      else return 0;
    });
    return documentosOrdenados.length == 0 ? 1 : Number(documentosOrdenados[0].NumDoc) + 1;
  }

  buscarCodigoEstado(estado, uf): any {
    estado.some((obj) => {
      if (obj.SiglaUf == uf) {
        this.codigo_uf = obj.Codigo;
        return true;
      }
    });
  }

  buscarDependentes(matricula) {
    this.dependenteService.buscarDependentes(matricula).subscribe(
      (data) => {
        this.listaDependente = JSON.parse(JSON.stringify(data));
        this.verificarDependente();
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  // buscarCadastro(): void {
  //   this.dependenteService.buscarCadastro(this.numeroDeOrdem).subscribe(
  //     (data) => {
  //       this.cadastroUsuario = JSON.parse(JSON.stringify(data));
  //       this.buscarDependentes(this.cadastroUsuario.Matricula);
  //     },
  //     (error) => {
  //       this.validaErro.retornoErro(error);
  //     }
  //   );
  // }
  urlIgual(): boolean {
    const currentUrl = this.route.snapshot.url.toString();
    return currentUrl.includes('cadastro-publico') || currentUrl.includes('pensao-previdencia');
  }
  // Verifica se data é valida
  validarData(data) {
    var reg = /[^\d\/\.]/gi; // Mascara = dd/mm/aaaa | dd.mm.aaaa
    var valida = data.replace(reg, ''); // aplica mascara e valida sÃ³ numeros
    if (valida && valida.length == 10) {
      // Ã© vÃ¡lida, entÃ£o ;)
      var ano = data.substr(6),
        mes = data.substr(3, 2),
        dia = data.substr(0, 2),
        M30 = ['04', '06', '09', '11'],
        v_mes = /(0[1-9])|(1[0-2])/.test(mes),
        v_ano = /(19[1-9]\d)|(20\d\d)|2100/.test(ano),
        rexpr = new RegExp(mes),
        fev29 = ano % 4 ? 28 : 29;

      if (v_mes && v_ano) {
        if (mes == '02') return dia >= 1 && dia <= fev29;
        else if (rexpr.test(M30.toString())) return /((0[1-9])|([1-2]\d)|30)/.test(dia);
        else return /((0[1-9])|([1-2]\d)|3[0-1])/.test(dia);
      }
    }
    return false;
  }

  limparDependentes() {
    this.formDependente.get('cpf').setValue('');
    this.formDependente.get('nomeCompleto').setValue('');
    this.formDependente.get('sexo').setValue('');
    this.formDependente.get('tipoDependencia').setValue('');
    this.formDependente.get('dataNascimento').setValue('');
    this.formDependente.get('uf').setValue('');
    this.formDependente.get('cidade').setValue('');
    this.formDependente.get('dataFinal').setValue('');
    this.formDependente.get('motivoInicio').setValue('');
    this.formDependente.get('motivoFim').setValue('');
    this.formDependente.get('dataInicialAC').setValue('');
    this.formDependente.get('dataEntrega').setValue('');
    this.formDependente.get('cartorio').setValue('');
    this.formDependente.get('certidao').setValue('');
    this.formDependente.get('search').setValue('');
    this.salarioFamiliarCheckBox = false;
    this.irrfCheckBox = false;
    this.formDependente.markAsPristine();
    this.formDependente.markAsUntouched();
  }

  mudarTela(dependente: Dependente = null) {
    this.limparDependentes();
    this.conferirCPF();
    if (dependente) {
      this.editarDependente = true;
      this.formDependente.get('cpf').disable();
      // this.buscarCadastro();
      this.cadastroDependente = true;
      this.telaDependente = false;
      if (!this.route.snapshot.url.toString().includes('pensao-previdencia')) {
        window.scrollTo(0, 0);
      }

      this.montarTelaDependente(dependente);
    } else if (this.cadastroDependente == true) {
      this.cadastroDependente = false;
      this.telaDependente = true;
      let value = '';
      this.formDependente.patchValue({ search: value });
      window.scrollTo(0, 0);
      this.cidade = null;
      // this.tipoDependecias = null;
    } else {
      this.formDependente.get('cpf').enable();
      this.cadastroDependente = true;
      this.telaDependente = false;
      this.editarDependente = false;
      window.scrollTo(0, 0);
    }

    //importante
    this.validaDependente = false;
    this.mudandoTela.emit(this.telaDependente);
  }

  conferirCPF() {
    for (let dep of this.listaDependente) {
      if (dep.Cpf == this.formDependente.get('cpf').value) {
        Swal('Esse dependente já está cadastrado.', 'Tente novamente com um novo CPF. ', 'warning');
        this.formDependente.get('cpf').setValue('');
      }
    }
  }

  atualizarDependente(): void {
    this.spinner.show();
    const dependente: Dependente = this.montarDependente();
    this.dependenteService.atualizarDependente(dependente).subscribe(
      (data) => {
        this.spinner.hide();
        this.finalizarCadastro();
        this.buscarDependentes(this.matricula);
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  salvarDependente(): void {
    this.spinner.show();
    const dependente: Dependente = this.montarDependente();
    this.dependenteService.salvarDependente(dependente).subscribe(
      (data) => {
        this.spinner.hide();
        this.finalizarCadastro();
        this.buscarDependentes(this.matricula);
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  finalizarCadastro(): void {
    Swal({
      title: this.editarDependente
        ? 'Atualização realizada com sucesso'
        : 'Cadastro realizado com sucesso!',
      text: 'Aguardando envio de informações do Benefício.',
      icon: 'success',
      closeOnClickOutside: false,
    }).then((result) => {
      if (result) {
        this.mudarTela();
      }
    });
  }
  verificarErros() {
    return !this.listaDependente.some((dependente) => {
      return (
        dependente.Cpf === null ||
        dependente.DataNascimento.SetData === '' ||
        dependente.Nome === '' ||
        dependente.Sexo === null ||
        dependente.MotivoDependente === null ||
        dependente.Uf === '' ||
        dependente.CodigoMunicipio === ''
      );
    });
  }
  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}
