import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function tituloValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      if (control.value.length == 12) {
        let primeiroNumero = parseInt(control.value[0]);
        let segundoNumero = parseInt(control.value[1]);
        let terceiroNumero = parseInt(control.value[2]);
        let quartoNumero = parseInt(control.value[3]);
        let quintoNumero = parseInt(control.value[4]);
        let sextoNumero = parseInt(control.value[5]);
        let setimoNumero = parseInt(control.value[6]);
        let oitavoNumero = parseInt(control.value[7]);
        let nonoNumero = control.value[8];
        let decimoNumero = control.value[9];
        let digitoVerificador1 = parseInt(control.value[10]);
        let digitoVerificador2 = parseInt(control.value[11]);

        let unidadeFederativa = parseInt(nonoNumero + decimoNumero);

        if (unidadeFederativa < 1 || unidadeFederativa > 28) {
          return {
            tituloInvalido: true,
            mensagem: 'Não foi possível encontrar a unidade federativa.',
          };
        }

        let restoDigito1 =
          (primeiroNumero * 2 +
            segundoNumero * 3 +
            terceiroNumero * 4 +
            quartoNumero * 5 +
            quintoNumero * 6 +
            sextoNumero * 7 +
            setimoNumero * 8 +
            oitavoNumero * 9) %
          11;
        (unidadeFederativa == 1 || unidadeFederativa == 2) && restoDigito1 == 0
          ? (restoDigito1 = 1)
          : null;
        restoDigito1 == 10 ? (restoDigito1 = 0) : null;
        if (restoDigito1 != digitoVerificador1) {
          return {tituloInvalido: true, mensagem: 'Dígito verificador incorreto.'};
        }

        let restoDigito2 =
          (parseInt(nonoNumero) * 7 + parseInt(decimoNumero) * 8 + restoDigito1 * 9) % 11;
        (unidadeFederativa == 1 || unidadeFederativa == 2) && restoDigito2 == 0
          ? (restoDigito2 = 1)
          : null;
        restoDigito2 == 10 ? (restoDigito2 = 0) : null;
        if (restoDigito2 != digitoVerificador2) {
          return {tituloInvalido: true, mensagem: 'Dígito verificador incorreto.'};
        }
      }
      return null;
    }
  };
}
