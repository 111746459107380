import { Component, OnInit } from '@angular/core';
import { UploadService } from './upload.service';
import { ErroService} from '../../service/erros/erro.service';
import Swal from 'sweetalert';
import { filter } from 'rxjs/operators';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  tipos:any;
  matricula:string;
  tipoTela:string;
  empfil: string;

  
  filesUpload: any[] = [ 
    {
      ArquivoBase64:"",
      Matricula:"",
      Empfil:"",
      Grupo:"",
      Tipo :"",
      Codigo:"",
      Extensao : "",
      Numdoc: "001",
    }
  ];

  constructor(private uploadService:UploadService, private validaErro:ErroService, ) { }
  
  ngOnInit() {
    localStorage.setItem("documentoEnviado", "false");
    this.tipoTela = localStorage.getItem("tipoTela");
    this.empfil = localStorage.getItem("filial_atual");
    this.pegaTipo(this.tipoTela);
  }

  pegaTipo(situacao:string ){
    this.uploadService.pegarTipo(situacao)
    .subscribe(
      data=> {this.tipos = data}, 
      error => {
        this.validaErro.retornoErro(error);
      }
    )
  }
  
  detectFiles(event, posicao) {
    let files = event.target.files;
    if (files) {
      document.getElementById('imageName ' + posicao).innerText =  files[0].name;
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => { 
          this.filesUpload[posicao].ArquivoBase64 = e.target.result.split(',')[1];
          this.filesUpload[posicao].Extensao =  e.target.result.split(',')[0].includes("image") ? "imagem" : "documento";
          this.filesUpload.push({
                                  ArquivoBase64:"",
                                  Matricula:"",
                                  Empfil: "",
                                  Grupo: "",
                                  Tipo: "",
                                  Codigo: "",
                                  Extensao: "" ,
                                });
        }
        reader.readAsDataURL(file);
      }
    }
  }

  escolheTipo(evento:any, posicao){
    this.matricula= localStorage.getItem("matricula");

    const tipoEscolhido = evento.target.value;
    const tipo = this.tipos.filter(elemento => elemento.Tipo == tipoEscolhido)[0];
    const numord = (this.filesUpload.filter(elemento =>  elemento.Tipo == tipo.Tipo).length + 1).toString().padStart(3,"0");

    this.filesUpload[posicao].Grupo = tipo.Grupo;
    this.filesUpload[posicao].Tipo = tipo.Tipo;
    this.filesUpload[posicao].Codigo = tipo.Codigo;
    this.filesUpload[posicao].Empfil = this.empfil;
    this.filesUpload[posicao].Matricula = this.matricula;
    this.filesUpload[posicao].Numdoc = numord;

  }

  salvar(){
    this.filesUpload.splice(-1,1)

    const verificar = this.filesUpload.filter(elemento => elemento.ArquivoBase64 == "" || elemento.Grupo == "");
    if(verificar.length > 0 || this.filesUpload.length == 0){
      //ou o tipo ou a imagem nÃ£o foram preenchidos
      this.filesUpload = [ 
        {
          ArquivoBase64:"",
          Matricula:"",
          Empfil:"",
          Grupo:"",
          Tipo :"",
          Codigo:"",
          Extensao : "",
          Numdoc: "001",
        }
      ];
      new AlertaModal('alerta', "Atenção", "Informe ao menos um documento.", 'p')
      return false;
    }
    this.uploadService.salvar(this.filesUpload)
    .subscribe(
      data => {
        if(data){
          localStorage.setItem("documentoEnviado", "true");
          //Swal("Sucesso", "Documentos salvos com sucesso.", "succsess");
          this.filesUpload = [ 
            {
              ArquivoBase64:"",
              Matricula:"",
              Empfil:"",
              Grupo:"",
              Tipo :"",
              Codigo:"",
              Extensao : "",
              Numdoc: "001",
            }
          ];
        }
      },
      error =>{
        this.filesUpload = [ 
          {
            ArquivoBase64:"",
            Matricula:"",
            Empfil:"",
            Grupo:"",
            Tipo :"",
            Codigo:"",
            Extensao : "",
            Numdoc: "001",
          }
        ];
        this.validaErro.retornoErro(error)}
    );
    return true;
  }

  retornaFiles(){
    this.filesUpload.splice(-1,1)
    return this.filesUpload;
  }
  vazio(){
    if(this.filesUpload.length <= 1){
      return true;
    }
    return false;
  }
  delete(posicao){
    this.filesUpload.splice(parseInt(posicao),1);
  }
  btUpload(id){
    document.getElementById(id).click();
  }
}
