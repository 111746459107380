import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InfoColaborador } from '../cadastro-complementar/cadastro-complementar.component';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';
import { Data } from 'src/app/service/interfaces/Data';
import { NgxSpinnerService } from 'ngx-spinner';
import { NomeSocialService } from './nome-social.service';
import { ErroService } from 'src/app/service/erros/erro.service';

@Component({
  selector: 'app-nome-social',
  templateUrl: './nome-social.component.html',
  styleUrls: ['./nome-social.component.css'],
})
export class NomeSocialComponent implements OnInit {
  formCadastro: FormGroup;
  infoColaborador: InfoColaborador;
  listaProcessos: any[] = [];
  nomeSocial: NomeSocial;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private nomeSocialService: NomeSocialService,
    public validaErro: ErroService
  ) {
    this.formCadastro = this.fb.group({
      processo: [{ value: '', disabled: true }],
      numero: [{ value: '', disabled: true }],
      iniVigencia: [{ value: '', disabled: true }],
      nome: ['', Validators.required],
      aPartirDe: ['', Validators.required],
      dtFim: [''],
    });
  }

  ngOnInit() {
    if (history.state.usuario) {
      this.infoColaborador = history.state.usuario;
    } else {
      this.retornarTelaColaboradores();
    }
    this.buscarProcessos();
  }

  retornarTelaColaboradores(): void {
    this.router.navigate(['/colaboradores/']);
  }

  buscarDados(): void {
    this.spinner.show();
    this.nomeSocialService.buscarNomeSocial(this.infoColaborador.numOrdem).subscribe({
      next: (data: any) => {
        this.nomeSocial = data;
        this.montarInformacoesForm(this.nomeSocial);
        this.formCadastro.markAsUntouched();
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro!', 'Não foi possível buscar os processos.', 'p');
      },
    });
  }

  buscarProcessos(): void {
    this.spinner.show();
    this.nomeSocialService.buscarListaProcessos(this.infoColaborador.numOrdem).subscribe({
      next: (data: any) => {
        this.listaProcessos = data;
        this.buscarDados();
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro!', 'Não foi possível buscar os processos.', 'p');
      },
    });
  }

  submitCadastro(): void {
    if (this.formCadastro.invalid) {
      new AlertaModal('alerta', 'Atenção!', 'Preencha os campos corretamente.', 'p');
      this.formCadastro.markAllAsTouched();
    } else {
      const nomeSocialObj: NomeSocial = this.montarNomeSocial();
      this.nomeSocial.NumeroOrdem
        ? this.atualizarNomeSocial(nomeSocialObj)
        : this.cadastrarNomeSocial(nomeSocialObj);
    }
  }

  cadastrarNomeSocial(nomeSocial: NomeSocial): void {
    this.nomeSocialService.cadastrarNomeSocial(nomeSocial).subscribe({
      next: (data: any) => {
        this.spinner.hide();
        this.buscarDados();
        new AlertaModal('sucesso', 'Sucesso!', 'Cadastro realizado com sucesso.', 'p');
      },
      error: (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      },
    });
  }

  atualizarNomeSocial(nomeSocial: NomeSocial): void {
    this.nomeSocialService.atualizarNomeSocial(nomeSocial).subscribe({
      next: (data: any) => {
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'Cadastro atualizado com sucesso.', 'p');
        this.buscarDados();
      },
      error: (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      },
    });
  }

  habilitarCampos() {
    this.formCadastro.get('iniVigencia')?.enable();
    this.formCadastro.get('numero')?.enable();
    this.formCadastro.get('processo')?.enable();
  }
  desabilitarCampos() {
    this.formCadastro.get('iniVigencia')?.disable();
    this.formCadastro.get('numero')?.disable();
    this.formCadastro.get('processo')?.disable();
  }

  montarNomeSocial(): NomeSocial {
    this.habilitarCampos();
    const form = this.formCadastro.value;
    console.log(form);
    this.desabilitarCampos();

    const inicioVigencia = form.iniVigencia !== '' ? form.iniVigencia : '00000000';
    const dataFinal = form.dtFim !== '' ? form.dtFim : '00000000';
    return {
      Matricula: this.infoColaborador.matricula,
      PssSequencial: form.numero,
      InicioVigencia: new Data(inicioVigencia),
      ApartirDe: new Data(form.aPartirDe),
      NomeSocial: form.nome,
      DataFinal: new Data(dataFinal),
    };
  }

  montarInformacoesForm(nomeSocial: NomeSocial): void {
    const processoSelecionado = this.listaProcessos ? this.listaProcessos[0] : '';
    this.formCadastro.setValue({
      processo: processoSelecionado.Processo,
      numero: processoSelecionado.Sequencial,
      iniVigencia: this.formatarDataRecebida(nomeSocial.InicioVigencia.ToDate),
      nome: nomeSocial.NomeSocial.toUpperCase(),
      aPartirDe: this.formatarDataRecebida(nomeSocial.ApartirDe.ToDate),

      dtFim: this.formatarDataRecebida(nomeSocial.DataFinal.ToDate),
    });
  }

  formatarDataRecebida(data: string): string {
    if (data !== '') {
      const arrayData = data.split('/');
      const dataFormatada = `${arrayData[2]}-${arrayData[1]}-${arrayData[0]}`;
      return dataFormatada;
    } else {
      return '';
    }
  }
}

export interface NomeSocial {
  PssSequencial: number;
  Matricula?: string;
  NumeroOrdem?: string;
  InicioVigencia: Data;
  NomeSocial: string;
  ApartirDe: Data;
  DataFinal: Data;
}

export interface Processo {
  Processo: string;
  Sequencial: string;
}
