import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MigracaoService } from './migracao.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErroService } from 'src/app/service/erros/erro.service';

@Component({
  selector: 'app-migracao',
  templateUrl: './migracao.component.html',
  styleUrls: ['./migracao.component.css']
})
export class MigracaoComponent implements OnInit {

  rotaParametro:string;
  ip: string;
  filialAtual: string;
  CodigoTarefa:string;

  constructor(private spinner: NgxSpinnerService, private migracaoService: MigracaoService, private route: ActivatedRoute, private validaErro: ErroService, private router: Router) {
    this.route.params.subscribe(params => this.rotaParametro = params['id']);
    this.buscarIp();
   }

  ngOnInit() {
    localStorage.clear();
    this.spinner.show();
  }

  buscarDadosMigracao(){
    this.migracaoService.buscarDadosMigracao(this.montarLoginMigracao())
    .subscribe(
      (data: any) => {
        const { Login } = data;
        localStorage.setItem("sessao", Login.Sessao);
        this.filialAtual = data.EmpresaFilial;
        this.CodigoTarefa = data.CodigoTarefa;

        if(Login.Usuario.Clientes.length > 1) this.buscarCliente(data)
        else this.efetuarLogin(Login.Usuario, data.CodigoTarefa);
      },
      error => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    )

  }

  buscarCliente(dadosLogin: any){
    const { Login } = dadosLogin;
    this.migracaoService.buscarLoginCliente(Login, dadosLogin.CodigoCliente)
    .subscribe(
      data => this.efetuarLogin(data, dadosLogin.CodigoTarefa),
      error => this.validaErro.retornoErro(error)
    )
  }

  efetuarLogin(dadosLogin: any, codigoTarefa: string) {
    localStorage.setItem("codigoUsuario", dadosLogin.CodigoUnico)   
    localStorage.setItem("nome", dadosLogin.Nome);
    localStorage.setItem("matricula_usuario", dadosLogin.Matricula.toString());
    localStorage.setItem("tipoUsuario", dadosLogin.Tipo);
    localStorage.setItem("numOrd", dadosLogin.NumeroDeOrdem.toString());
    localStorage.setItem("menu", JSON.stringify(dadosLogin.Menu));     
    localStorage.setItem("clienteId", dadosLogin.ClienteSelecionado.IdCliente);
    localStorage.setItem("filiais", JSON.stringify(dadosLogin.ClienteSelecionado.Filiais));
    localStorage.setItem("filial_atual", this.filialAtual);
    localStorage.setItem("nome_filial_atual", dadosLogin.ClienteSelecionado.Filiais.find(filial => filial.Codigo == this.filialAtual).Nome);
    localStorage.setItem("login",'true');
    this.spinner.hide();
    window.location.href =  `/${CodTarefa[codigoTarefa]}`
  }

  buscarIp(){
    this.migracaoService.buscarIp()
    .subscribe(
      (data: any) => {

        this.ip = data.ip;
        this.buscarDadosMigracao();
      },
      error => this.validaErro.retornoErro(error)
    )
  }


  montarLoginMigracao(): LoginMigracao{
    return {
      Browser: this.migracaoService.buscarBrowser(),
      CodigoUnico: this.rotaParametro,
      Ip: this.ip,
      Os:  this.migracaoService.buscarOs()
    }
  }

}

export interface LoginMigracao{
  CodigoUnico: string;
  Ip: string;
  Os: string;
  Browser: string;
}

export enum CodTarefa{
  '020020020' = 'ferias',
  '010008090' = 'ferias',
  '010020020020' = 'ferias',
  '010003010' = 'cadastro-privado',
  '010010003010' = 'cadastro-privado',
  '010008130' = 'processos',
  '010010007130' = 'processos',
  '020010020' = 'lancamento-financeiro',
  '010020010020' = 'lancamento-financeiro',
  '010010005' = 'situacao-funcional',
  '030030050' = 'contracheque',
  '020010070' = 'contracheque',
  '010030030050' = 'contracheque',
  '010020010070' = 'contracheque',
  '010001' = 'colaboradores',
  '010002' = 'colaboradores',
  '010010001' = 'colaboradores',
  '010010002' = 'colaboradores',
  '010004010' = 'transferencia',
  '010010004010' = 'transferencia',
  '010010004' = 'transferencia'
}
