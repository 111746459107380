import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Cliente } from './cadastro-cliente.component';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  localStorage: LocalStorage = new LocalStorage();
  url_acesso:string;
  headers = new HttpHeaders();
  cabecalho: any;
  tpProcessamento: string = "EM";
  filialAtual: string;
  
  constructor(private http: HttpClient, private acesso: AcessoService) { 
    this.url_acesso = this.acesso.validarUrl();
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filialAtual = this.localStorage.FilialAtual.toString(); 
    }
  }

  salvarCliente(cliente: Cliente){
    return this.http.post(`${this.url_acesso}pse/configuracao-cliente`, cliente, {headers:this.cabecalho});
  }

  editarCliente(cliente: Cliente){  // Alterar para rota que vai ser criada de salvar cliente
    return this.http.put(`${this.url_acesso}pse/cliente`, cliente, {headers:this.cabecalho});
  }
  
  deletarCliente(codUnico: number){
    return this.http.delete(this.url_acesso + `pse/cliente/${codUnico}`, {headers:this.cabecalho});
  }

  buscarServicosPse(){
    return this.http.get(this.url_acesso + `pse/servicos`, {headers:this.cabecalho});
  }

  buscarClientesPse(){
    return this.http.get(this.url_acesso + `pse/clientes`, {headers:this.cabecalho});
  }

  buscarCliente(codUnico: number){
    return this.http.get(this.url_acesso + `pse/cliente/${codUnico}`, {headers:this.cabecalho});
  }
  
}
