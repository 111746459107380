<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="card">
  <form [formGroup]="formCadastroUsuario">
    <div class="card-header">
      <div class="row">
        <h3 class="card-title">Usuário</h3>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-3">

        <div class="col-3 pl-2 pr-2">
          <label for="Cpf">CPF</label><input name="Cpf" (keypress)='somenteNumero($event)' [mask]="mascaraCpf()"
            formControlName="cpf" type="txt" class="form-control" placeholder="000.000.000-00" aria-label="Cpf"
            aria-describedby="basic-addon1"
            [ngStyle]="temErro && formCadastroUsuario.controls['cpf'].status == 'INVALID' ? {'border-color':'red'}: null" />
          <small class="form-text text-danger"
            *ngIf="temErro && formCadastroUsuario.controls['cpf'].hasError('required')">Digite um CPF</small>
          <small class="form-text text-danger"
            *ngIf="temErro && formCadastroUsuario.controls['cpf'].hasError('minlength')">Mínimo de numeros (11)</small>
        </div>
        <div class="col-6">
          <label for="nome">Nome</label><input (keypress)='somenteLetras($event)' name="nome" formControlName="nome"
            type="text" class="form-control" placeholder="Nome Completo" aria-label="Nome"
            aria-describedby="basic-addon1"
            [ngStyle]="temErro && formCadastroUsuario.controls['nome'].status == 'INVALID' ? {'border-color':'red'}:null" />
          <small class="form-text text-danger"
            *ngIf="temErro && formCadastroUsuario.controls['nome'].hasError('required')">Digite um Nome</small>
          <small class="form-text text-danger"
            *ngIf="temErro && formCadastroUsuario.controls['nome'].hasError('minlength')">Mínimo de letras (8)</small>
        </div>
        <div class="col-3">
          <label for="TipoUsuario">Tipo</label><select
            [ngStyle]="temErro && formCadastroUsuario.controls['tipo'].status == 'INVALID' ? {'border-color':'red'}: null"
            class="form-control form-control-uniform" name="tipos" formControlName="tipo" required>
            <option value=""> Selecione...</option>
            <option *ngFor="let tp of listaTipoUsuario" value="{{tp.CodigoTipoUsuario}}">{{tp.Descricao}}</option>
          </select>
          <small class="form-text text-danger"
            *ngIf="temErro && formCadastroUsuario.controls['tipo'].status == 'INVALID'">Selecione uma Opção</small>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-3">
          <label for="Senha Temp">Senha Temporária</label><input name="Senhatemp" formControlName="senhaTemporaria"
            type="password" id="password" class="form-control" placeholder="Senha Temporária"
            aria-label="SenhaTemporaria" aria-describedby="basic-addon1"
            [ngStyle]="temErro && formCadastroUsuario.controls['senhaTemporaria'].status == 'INVALID' ? {'border-color':'red'}:null" />
          <i class="fa fa-eye" *ngIf='!revelarOlho' (click)="revelarSenha()"
            style="position: absolute;top: 40px;right: 20px;cursor: pointer;"></i>
          <i class="fa fa-eye-slash" *ngIf='revelarOlho' (click)="revelarSenha()"
            style="position: absolute;top: 40px;right: 20px;cursor: pointer;"></i>
          <small class="form-text text-danger"
            *ngIf="temErro && formCadastroUsuario.controls['senhaTemporaria'].hasError('required')">Digite uma
            Senha</small>
          <small class="form-text text-danger"
            *ngIf="temErro && formCadastroUsuario.controls['senhaTemporaria'].hasError('minlength')">Mínimo de
            caracteres (8)</small>
        </div>
        <div class="col-6">
          <label for="nome">Email</label><input name="nome" formControlName="email" type="text" class="form-control"
            placeholder="Nome Completo" aria-label="Nome" aria-describedby="basic-addon1"
            [ngStyle]="temErro && formCadastroUsuario.controls['email'].status == 'INVALID' ? {'border-color':'red'}:null" />
          <small class="form-text text-danger"
            *ngIf="temErro && formCadastroUsuario.controls['email'].hasError('required')">Digite um Nome</small>
          <small class="form-text text-danger"
            *ngIf="temErro && formCadastroUsuario.controls['email'].hasError('email')">Email invalido</small>
        </div>
        <div class="col-2 form-group text-center">
          <label class="font-weight-semibold">Configurações</label><br>
          <button type="button" class="btn btn-secondary btn-circle" data-toggle="modal"
            data-target="#configuracaoUsuario"
            style=" width: 55px;height: 33px;text-align: center;padding: 6px 0;font-size: 14px;line-height: 1.428571429;border-radius: 16px;">
            <i aria-hidden="true" class="fa fa-cog"></i>
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-2">
          <label for="teste">Inserir login</label>
          <input type="checkbox" style="position: relative; top: 3px; left: 6px;" (change)="toogleCampoLogin()">
        </div>
      </div>
      <div class="row">
        <div class="col-3" *ngIf="habilitarCampoLogin">
          <input  name="teste" type="text" [ngStyle]="temErro && formCadastroUsuario.controls['tipo'].status == 'INVALID' ? {'border-color':'red'}: null" class="form-control form-control-uniform" name="tipos" formControlName="login" required placeholder="digite o login aqui">
        </div>
      </div>
      

    </div>
  </form>

  <div class="card-footer bg-transparent">
    <div class="row d-flex flex-row-reverse bd-highlight">
      <div class="col-1 p-2 bd-highlight">
        <button type="submit" (click)="submitUsuario()"
          [disabled]="formCadastroUsuario.status == 'INVALID'"
          class="btn btn-primary pl-3 pr-3">Salvar</button>
      </div>
      <div class="col-1 p-2 bd-highlight">
        <button
          *ngIf="formCadastroUsuario.status == 'VALID' || (this.servicosSelecionados.length != 0 || this.empfilSelecionada.length != 0)"
          type="button" (click)="limparCampos()" class="btn btn-danger">Cancelar</button>
      </div>
    </div>
  </div>
</div>

<div class="row component m">
  <div class="col-lg-12 col-xl-12 form-group" id="colaboradores">
    <div class="card">
      <div class="card-header">
        <div class="form-group header-elements-inline">
          <h3 class="card-title"> Lista de Usuários</h3>
          <div class="header-elements">
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="form-group row">
          <div class="input-group col-lg-12">
            <input type="text" class="form-control" name="searchText" value="" placeholder="Pesquisar Usuário"
              (keyup)='onKeyUp( $event.target)'>
            <span class="input-group-append">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
            </span>
          </div>
        </div>
        <div class="row align-baseline">
          <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 form-group">
            <h5 class="font-weight-semibold">Usuários Encontrados </h5>
          </div>
        </div>
        <div class="form-group row d-none d-md-block">
          <div class="col-lg-12 form-group text-center">
            <div class="table-responsive" style="max-height:45vh">
              <table class="table table-striped table-hover">
                <thead class="text-start">
                  <tr class="text-start" style="white-space:nowrap">
                    <th class="sticky-top  bg-white">Excluir/Editar</th>
                    <th class="sticky-top  bg-white">Nome </th>
                    <th class="sticky-top  bg-white">Login</th>
                    <th class="sticky-top  bg-white">Tipo</th>
                    <th class="sticky-top  bg-white">CPF</th>
                  </tr>
                </thead>
                <tbody id="lista-processos">
                  <tr *ngFor="let us of listaUsuarios | filtrarUsuario: filter;" class="text-start"
                    style="white-space:nowrap">
                    <td>
                      <div>
                        <button (click)="deletar(us)" class="btn btn-danger mr-2">
                          <i class="fa fa-trash"></i>
                        </button>
                        <button (click)='editar(us)' class="btn bg-teal-400">
                          <i class="fa fa-pencil"></i>
                        </button>
                      </div>
                    </td>
                    <td>{{us.Nome}}</td>
                    <td>{{us.Login}}</td>
                    <td>{{us.Tipo}}</td>
                    <td>{{formataCPF(us.Cpf)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" id="configuracaoUsuario" tabindex="-1"
  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel">Configuração</h4>
        <button type="button" (click)="limparModal()" class="close" id="closeModalConfig" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <button type="button" class="close" id="closeSubmit" data-dismiss="modal"></button>
      <div class="modal-body">
        <div class="card" *ngIf="contadorPag == 1">
          <div class="card-header">
            <h5>Serviços</h5>
          </div>
          <div class="card-body  wizard">
            <div class="steps clearfix pb-3">
              <ul role="tablist">
                <li role="tab"><a id="steps-uid-0-t-1" aria-controls="steps-uid-2-p-2"><span
                      class="number">1</span>Serviços do Usuário</a></li>
                <li role="tab" aria-disabled="true"><a id="steps-uid-0-t-1" aria-controls="steps-uid-2-p-2"><span
                      class="number">2</span>Empifil</a></li>
              </ul>
            </div>
            <div class="col">
            </div>
            <div class="form-check row d-flex justify-content-center">
              <div class="col-7 table-responsive text-center">
                <div class="row form-group text-center" [formGroup]="formConfiguracaoUsuario">
                  <table class="table table-striped table-hover mb-0 text-center">
                    <thead class="bg-teal-400">
                      <tr class="text-start" style="white-space:nowrap">
                        <th style="text-align:left;">
                          Escolha um Serviços
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <a *ngIf="marcarTodosServicosAux || servicosSelecionados.length == 0"
                        (click)="marcarTodosServicos()" style="color: rgb(84, 84, 238);"
                        class="d-flex justify-content-end mr-2">Selecionar todos</a>
                      <a *ngIf="!marcarTodosServicosAux && servicosSelecionados.length > 0"
                        (click)="desmarcarTodosServicos()" style="color: rgb(84, 84, 238);"
                        class="d-flex justify-content-end mr-2">Desmarcar todos</a>
                      <tr>
                        <td style="text-align:left;">
                          <div class="form-check" *ngFor="let servico of listaServicos; let i = index">
                            <input class="form-check-input "
                              (change)="selecionarServicos(servico,  onChange($event.target));"
                              name="{{servico.Descricao}}" type="checkbox" id="{{servico.Codigo}}"
                              value="{{servico.Codigo}}">
                            <label class="form-check-label" for="{{servico.Codigo}}">{{servico.Descricao}}</label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row d-flex flex-row-reverse">
              <div class="col-1 p-2 ">
                <button [disabled]="this.servicosSelecionados.length == 0 " style="right: 50px;"
                  (click)="passartela('passar')" type="button" class="btn btn-primary ">Proxímo</button>
              </div>
            </div>
          </div>
        </div>
        <div class="card" *ngIf="contadorPag == 2">
          <div class="card-header">
            <h5>Configurações Usuário</h5>
          </div>
          <div class="card-body  wizard">
            <div class="steps clearfix pb-3">
              <ul role="tablist">
                <li role="tab"><a id="steps-uid-0-t-1" aria-controls="steps-uid-2-p-2"><span class="number"><i
                        class="fa fa-check" aria-hidden="true"></i></span>Serviços do Usuário</a></li>
                <li role="tab" aria-disabled="true"><a id="steps-uid-0-t-1" aria-controls="steps-uid-2-p-2"><span
                      class="number">2</span>Empifil</a></li>
              </ul>
            </div>
            <div class="form-check row d-flex justify-content-center">
              <div class="col-7 table-responsive text-center">
                <div class="row form-group text-center" [formGroup]="formConfiguracaoUsuario">
                  <table class="table table-striped table-hover mb-0 text-center">
                    <thead class="bg-teal-400">
                      <tr class="text-start" style="white-space:nowrap">
                        <th style="text-align:left;">
                          Escolha uma Empifil
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <a *ngIf="marcarTodasEmpfilAux || empfilSelecionada.length == 0" (click)="marcarTodasEmpfil()"
                        style="color: rgb(84, 84, 238);" class="d-flex justify-content-end mr-2">Selecionar todos</a>
                      <a *ngIf="!marcarTodasEmpfilAux && empfilSelecionada.length > 0" (click)="desmarcarTodasEmpfil()"
                        style="color: rgb(84, 84, 238);" class="d-flex justify-content-end mr-2">Desmarcar todos</a>
                      <tr>
                        <td style="text-align:left;">
                          <div class="form-check" formArrayName="Empfil"
                            *ngFor="let empfil of listaEmpfil; let i = index">
                            <input class="form-check-input " (change)="selecionarEmpfil(empfil,  onChange($event.target))"
                              name="{{empfil.Descricao}}" type="checkbox" id="{{empfil.Codigo}}">
                            <label class="form-check-label" for="{{empfil}}">{{empfil.Descricao}}</label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row d-flex flex-row-reverse">
              <div class="row">
                <div class="col-3 p-2 mr-2 ">
                  <button type="button" (click)="passartela('voltar')" class="btn btn-secondary">Voltar</button>
                </div>
                <div class="col-3 p-2 ml-2">
                  <button type="button" (click)="salvarModal('closeModalConfig2')"
                    class="btn btn-primary ">Salvar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>