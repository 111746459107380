import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Usuario } from './cadastro-usuario.component';


@Injectable({
  providedIn: 'root'
})
export class CadastroUsuarioService {
  localStorage: LocalStorage = new LocalStorage();
  url_acesso: string;
  headers = new HttpHeaders();
  cabecalho: any;
  filial: string;


  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl();
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString(); 
    }
  }
  
  BuscarTodosUsuarios(codigoCliente){
    return this.http.get(this.url_acesso + `pse/usuario/${codigoCliente}`, { headers: this.cabecalho })
  }

  buscarUsuarios(codigoUsuario, codigoCliente){
    return this.http.get(this.url_acesso + `pse/usuario/obter/${codigoUsuario}/${codigoCliente}`, { headers: this.cabecalho })
  }

  buscarServicosPse(){
    return this.http.get(this.url_acesso + `pse/servicos`, { headers: this.cabecalho });
  }

  buscarEmpfil(codigoCliente){
    return this.http.get(this.url_acesso + `pse/usuario/listar-filial/${codigoCliente}`, { headers: this.cabecalho })
  }

  buscarTipoUsuario() {
    return this.http.get(`${this.url_acesso}politica-seguranca/tipos-usuario`, {headers:this.cabecalho});
  }
  
  deletarUsuario(codigoUsuario){
    return this.http.delete(`${this.url_acesso}pse/usuario/${codigoUsuario}`,{headers:this.cabecalho})
  }

  salvarUsuarioPse(usuario:Usuario){
    return this.http.post(`${this.url_acesso}pse/usuario`,usuario,{headers:this.cabecalho})
  }

  editarUsuarioPse(usuario:Usuario){
    return this.http.put(`${this.url_acesso}pse/usuario`,usuario,{headers:this.cabecalho})
  }
}