import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Permissao } from './permissao-empft.component';

@Injectable({
  providedIn: 'root'
})
export class PermissaoEmpftService {
  localStorage: LocalStorage = new LocalStorage();
  headers: HttpHeaders = new HttpHeaders();
  cabecalho: any = null; 
  urlAcesso: string = '';
  filial: string = '';
  constructor(private acesso: AcessoService, private http: HttpClient) {
    this.urlAcesso = this.acesso.validarUrl();
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString(); 
    }
  }
  
  salvarPermissao(permissao: Permissao){
    return this.http.get(`${this.urlAcesso}cadastro-publico/parametros/${this.filial}`,{headers:this.cabecalho});
  }

  buscarTipo() {
    return this.http.get(`${this.urlAcesso}tiposusuario`, {headers:this.cabecalho});
  }
}
