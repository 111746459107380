<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>


<div class="content justify-content-center align-items-center" [ngStyle]="{'display': telaPrincipal ? 'flex' : 'none'}"  >
  <form class="login-form" #telainicial="ngForm" [formGroup]="formTelaInicial" (ngSubmit)="onSubmitFormTelaInicial()">
    <div class="card  bg-dark mb-0 text-ligth">
      <div class="card-body">

        <div class="text-center mb-3  ">
          <h5 class="mb-0">
            Elogio e Penalidade</h5>

        </div>

        <div class="s-12 col-sm-12 col-md-12 col-lg-12 form-group ">
          <label class="justify-content-start d-block">Matrícula</label>

          <div class="input-group mb-3">
            <input type="text" maxlength="4" class="form-control" placeholder="Matrícula"
              formControlName="matricula_form" (keypress)='somenteNumero($event)' (keydown)="detectarBackSpace($event)">
            <div class="input-group-prepend ">
              <button type="button" class="btn bg-primary  border-primary" id="basic-addon1"
                [disabled]="formTelaInicial.controls['matricula_form'].invalid" style="box-shadow: none;" 
                (click)="buscaPorMatricula()" ><i
                  class="fa fa-search " aria-hidden="true" ></i></button>
            </div>
          </div>
          <span *ngIf="matriculaFormError || (matriculaFormError && formTelaInicial.controls['matricula_form'].hasError('required'))"
            class="form-text text-danger">
            Campo obrigatório
          </span>

        </div>

        <div *ngIf="resultado.Nome != null && !formTelaInicial.controls['matricula_form'].hasError('required')" class="justify-content-center w-80 rounded-top rounded-bottom pt-2 hoverable"
          style="background-color: #728098; margin-bottom: 10%;cursor: pointer;  "
          (click)="onSubmitFormTelaInicial()" >
          <div class="row ">
            <div class="col-4">            </div>
            <div class="col-6"><img style="width: 5em" 
              src="{{resultado.Sexo == '1' ? 'assets/img/avatar-masculino.svg' : 'assets/img/avatar-feminino.svg'}}"
               width="30" height="40" alt=""></div>
            <div class="col-4">            </div>

          </div>
          <div class="row pt-2">
            
            <div class="col-1">            </div>
            <div class="col-10 text-center"><h4 class="h3  pb-2" style="font-size: 15px;">{{resultado.Nome}}</h4></div>
          </div>
        </div>

        <!-- <div class=" text-center">
          <button type="submit" class="btn btn-primary"
            [disabled]="  continuar == false || (formTelaInicial.get('matricula_form').invalid) ">Continuar</button>
        </div> -->
      </div>
    </div>
  </form>
</div>


<div [ngStyle]="{'display': telaPrincipal ? 'none' : ''}" class="card" id="telaPrincipalForm">

  <div class="card-title pb-0" [ngStyle]="{'display': telaPrincipal ? 'none' : ''}"  >
    <div class="row " style="margin-top: 7px; margin-bottom: 0;">
      <div class=" ml-2" style="top: 4px;">
        <h3 class="mb-0"><i class="fa fa-arrow-left pr-3 btn   " aria-hidden="true" (click)="retornarTelaInicial()" style="cursor: pointer; bottom: 2px;"></i></h3>
      </div>
      <div class=" col-10 p-0 align-bottom"> 
        <h3 class="card-title p-0  mb-0 mt-0">Elogio e Penalidade</h3>
      </div>
      
    </div>
  <hr class="mt-0">
  </div>
  <form [formGroup]="formPrincipal"  #formPrincipalForm='ngForm' >
    <div class="card-body">

      <div class="row">
        <div class="col-md-3 col-lg-2 form-group">
          <label class="font-weight-semibold">Matrícula</label>
          <div class="input-group">
            <input autofocus [placeholder]="resultado.Matricula" autofocus type="text" disabled class="form-control">
            <div class="input-group-append">
            </div>
          </div>
        </div>

        <div class="col-md-6 form-group">
          <label class="font-weight-semibold">Nome</label>
          <input class="form-control" type="text" [placeholder]="resultado.Nome"  disabled />
        </div>
      </div>


      <div class="row">
        <!-- INPUT DO TIPO -->
        <div class="col-md-10 col-lg-2 form-group">
          <label class="font-weight-semibold">Tipo</label>
          <select class="form-control" type="text" formControlName="tipo_form" [ngStyle]="tipoFormError ? {'border-color': 'red'} : null">
            <option value="" > Selecione...</option>
            <option *ngFor="let tipo of listTiposEPE" value="{{tipo.Descricao}}" > {{tipo.Descricao}} </option>

          </select>

          <span class="form-text text-danger" *ngIf="tipoFormError && formPrincipal.controls['tipo_form'].hasError('required')">
            Campo obrigatório
          </span>
        </div>
        <!-- INPUT DO TIPO -->

        <!-- INPUT DA DATA -->
        <div class="col-md-10 col-lg-2 form-group">
          <label class="justify-content-start font-weight-semibold">Data</label>
          <div class=" mb-0">

            <div class="input-group"  id="div_data_input" >
              <span class="input-group-prepend" >
                <span class="input-group-text"><i class="icon-calendar5"></i></span>
              </span>
              <input type="text" class="form-control pickadate" id="data_input" placeholder="Data&hellip;" formControlName="data_form"  [ngStyle]="onNgEstilo()" >
              <input type="text" [ngStyle]="{'display': editado ? '' : 'none'}" class="form-control" id="data_disabled" placeholder="Data&hellip;"  disabled  >
            </div>
          </div>
          <span class="form-text text-danger p-0"
          *ngIf="dataFormError"
                            >
                            Campo obrigatório
          </span>

        </div>
        <!-- INPUT DA DATA -->

        <!-- INPUT DO NOME DO AUTOR -->
        <div class="col-md-6 form-group">
          <label class="font-weight-semibold">Autor</label>
          <input class="form-control" type="text" formControlName="autor_form" maxlength="80" placeholder="Autor" [ngStyle]="autorFormError ? {'border-color': 'red'} : null"/>
          <span class="form-text text-danger" *ngIf="autorFormError && formPrincipal.controls['autor_form'].hasError('required')">
            Campo obrigatório
          </span>
        </div>
        <!-- INPUT DO NOME DO AUTOR -->
      </div>

      <!-- INPUT DO MOTIVO -->
      <div class="row">
        <div class=" col-md-10 form-group">
          <label class="font-weight-semibold">Motivo</label>
          <textarea class="form-control" name="" id="motivo_text" cols="30" rows="1" formControlName="motivo_form"
            maxlength="300" placeholder="Adicione um motivo..." (keyup)="onContador($event)" [ngStyle]="motivoFormError ? {'border-color': 'red'} : null"></textarea>
            <small class="d-flex flex-row-reverse bd-highlight" [ngStyle]="valorMaximoMotivo - motivoContador.length <= 10 ? {'color':'red'} : null">{{motivoContador.length}}/{{valorMaximoMotivo}}</small>
          <span class="form-text text-danger" *ngIf="motivoFormError && formPrincipal.controls['motivo_form'].hasError('required')">
            Campo obrigatório
          </span>
        </div>
        <div *ngIf="resultado.Matricula">

          <app-anexo [pagina]="'004'" [matricula]="resultado.Matricula.toString()" [enviarSeparadamente]="true" [perfil]="false" [compChave]="resultado.Matricula.toString()"></app-anexo>

        </div>
      </div>
      <!-- INPUT DO MOTIVO -->

    </div>

    <div class="card-footer text-right ">
      <button type="button" (click)="limparCampos()" class="btn btn-danger mr-2" *ngIf="formPrincipal.valid"
        >Limpar</button>
      <button class="btn btn-primary" type="submit" [disabled]="formPrincipal.invalid " (click)="onSubmitFormPrincipal()">Salvar</button>
    </div>

  </form>
</div>


<div class="card mt-3" [ngStyle]="{'display': telaPrincipal ? 'none' : ''}">
  <div class="card-header">
    <h3 class="card-title"> Lista de Elogios e Penalidades</h3>
  </div>


  <div class="card-body">
    <div class="d-flex justify-content-center align-items-center">
    </div>
    <div class="form-group row">
      <div class="input-group col-lg-12">
        <input (keyup)='onKeyUp($event.target)' type="text" class="form-control" name="searchText" value=""
          placeholder="Pesquisar Elogios e Penalidades">
        <span class="input-group-append">
          <span class="input-group-text"><i class="fa fa-search"></i></span>
        </span>
      </div>
    </div>

    <div class="table-responsive " style="max-height:45vh" *ngIf="listEPE.length > 0 ||listEPE.length!= [] ">
      <table class="table table-striped table-hover" style="border-collapse: separate; border-spacing: 0;">
        <thead class="text-start " >
          <tr class="text-start sticky-top" style="white-space:nowrap; border-left: 5px solid black;">
            <th class=" sticky-top bg-white ">Excluir / Editar</th>
            <th class="col-md-2 sticky-top bg-white">Data</th>
            <th class="col-md-2 sticky-top bg-white">Autor</th>
            <th class="col-md-6 sticky-top bg-white">Motivo</th>

            <!-- <th class="sticky-top bg-white">Usuários Da Tarefa</th> -->

          </tr>
        </thead>
        <tbody id="lista-elogiosEPenalidades">
          <tr *ngFor="let objeto of listEPE | filtrarElogioEPenalidade: filter; let i = index" 
            style="white-space:nowrap;" >
            <td [ngClass]="objeto.Tipo.toLowerCase() == 'elogio' ? 'border-success' : 'border-danger'" class="text-start border-left-2" style="border-top: 0px; border-bottom: 0px;">
              <div class="row">
                <button class="btn btn-danger mr-2" (click)="excluirElogioPersonalidade(objeto, i)">
                  <i class="fa fa-trash"></i>
                </button>
                <button class="btn bg-teal-400" (click)="editarElogioPersonalidade(objeto)">
                  <i class="fa fa-pencil"></i>
                </button>
              </div>

            </td>

            <td>{{dataTransform(objeto.Data)}}</td>
            <td>{{objeto.Autor}}</td>
            <td>{{objeto.Motivo}}</td>
          </tr>

        </tbody>
      </table>
    </div>

    <div class="d-flex justify-content-center align-items-center" style="height:20vh" *ngIf="listEPE.length === 0 || listEPE == [] ">
      <h5 class="font-weight-semibold">Não há elogios ou penalidades para serem exibidos</h5>
    </div>
  </div>
