import { Injectable } from '@angular/core';
import { OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders, HttpClientModule} from '@angular/common/http';
import { AcessoService } from '../../service/acesso/acesso.service';
import{Transferencia} from './transferencia-funcionario.component'
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root'
})

export class TransferenciaFuncionarioService {
  localStorage: LocalStorage;
  headers = new HttpHeaders();
  cabecalho: HttpHeaders;//this.headers.append('sessao', localStorage.getItem('sessao'));
  filial: string;// = localStorage.getItem('filial_atual');
  url_acesso: any;

  constructor(private http: HttpClient,private acesso: AcessoService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      //this.tipoUsuario = this.localStorage.TipoUsuario;
      this.url_acesso = this.acesso.validarUrl();
    }
  }
    

  buscarFilial() {
    var url_acesso = this.acesso.validarUrl();
    return this.http.get(url_acesso +'filiais',{headers:this.cabecalho});
  }

  transferir(transferencia: Transferencia){
    return this.http.post(`${this.url_acesso}transferencia`, transferencia, {headers:this.cabecalho});
  }
  buscarColaborador(matricula: string){
    return this.http.get(`${this.url_acesso}cadastro-ativo-matricula/${matricula}/${this.filial}`,{headers:this.cabecalho});
  }
  buscarDepartamento(filial : any) {
    return this.http.get(`${this.url_acesso}lotacao/${filial}`,{headers:this.cabecalho});
  }

  buscarSetor(filial, codigo) {
    var cod_lotacao = codigo.substring(0, 5);

    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}lotacao/${filial}/${cod_lotacao}` ,{headers:this.cabecalho});
  }
  buscarCadastro(numOrdem: number)
  {
    return this.http.get(`${this.url_acesso}cadastro/${numOrdem}/0000000/EM/${this.filial}`,{headers:this.cabecalho});

  }

  buscarFiliais() {
    return this.http.get(`${this.url_acesso}filiais/`,{headers:this.cabecalho});
  }
}
