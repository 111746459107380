import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Servico } from './servicos.component';

@Injectable({
  providedIn: 'root'
})
export class ServicosService {
  localStorage: LocalStorage = new LocalStorage();
  url_acesso: string;
  headers = new HttpHeaders();
  cabecalho: any = null;
  filial: string = '';

  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl();
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString(); 
    }
  }

  buscarServicosPse(){
    return this.http.get(`${this.url_acesso}pse/servicos`, { headers: this.cabecalho });
  }

  excluirServicoPse(servico: string){
    return this.http.delete(`${this.url_acesso}pse/servico/${servico}`, { headers: this.cabecalho });  
  }

  salvarServicoPse(servico : Servico){
    return this.http.post(`${this.url_acesso}pse/servico`, servico, {headers:this.cabecalho});
  }

  editarServicoPse(servico : Servico){
    return this.http.put(`${this.url_acesso}pse/servico`, servico, {headers:this.cabecalho});
  }
}
