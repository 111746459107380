<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="modal-header">
  <h3 class="modal-title">
    {{ prontuarioindex !== null ? 'Atualização' : 'Inclusão' }} de Prontuário Médico
  </h3>
  <button type="button" class="close" id="botaoFechar" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="formProntuario" class="modal-body row">
  <div class="form-group col-6">
    <label for="Dtinsp" class="font-weight-semibold">Data de Insp <small></small></label>
    <input
      type="date"
      max="9999-12-31"
      class="form-control"
      id="Dtinsp"
      formControlName="Dtinsp"
      [readonly]="prontuarioindex !== null && prontuarioindex !== undefined"
      [ngClass]="{
        'is-invalid': formProntuario.get('Dtinsp').invalid && formProntuario.get('Dtinsp').touched
      }"
    />
    <div
      *ngIf="formProntuario.get('Dtinsp').invalid && formProntuario.get('Dtinsp').touched"
      class="invalid-feedback"
    >
      Campo obrigatório.
    </div>
  </div>

  <div class="form-group col-6">
    <label for="Crm" class="font-weight-semibold">CRM <small></small></label>
    <input
      type="text"
      class="form-control"
      id="Crm"
      formControlName="Crm"
      placeholder="A00"
      style="text-transform: uppercase"
      [ngClass]="{
        'is-invalid': formProntuario.get('Crm').invalid && formProntuario.get('Crm').touched
      }"
    />
    <div
      *ngIf="formProntuario.get('Crm').invalid && formProntuario.get('Crm').touched"
      class="invalid-feedback"
    >
      Campo obrigatório.
    </div>
  </div>

  <div class="form-group col-6">
    <label for="CodAfa">Código de Afastamento:</label>
    <select
      class="form-control"
      id="CodAfa"
      formControlName="CodAfa"
      [ngClass]="{
        'is-invalid': formProntuario.get('CodAfa').invalid && formProntuario.get('CodAfa').touched
      }"
    >
      <option value="null">Selecione uma opção</option>
      <option *ngFor="let item of buscarCodAfa" [value]="item.Codigo">
        {{ item.Codigo }} - {{ item.Descricao }}
      </option>
    </select>
    <span
      class="form-text text-danger"
      *ngIf="formProntuario.get('CodAfa').invalid && formProntuario.get('CodAfa').touched"
    >
      Campo Obrigatório
    </span>
  </div>

  <div class="form-group col-6">
    <label for="CodMot">Código Motivo:</label>
    <select
      class="form-control"
      id="CodMot"
      formControlName="CodMot"
      [ngClass]="{
        'is-invalid': formProntuario.get('CodMot').invalid && formProntuario.get('CodMot').touched
      }"
    >
      <option value="null">Selecione uma opção</option>
      <option *ngFor="let item of buscarCodMot" [value]="item.Codigo">
        {{ item.Codigo }} - {{ item.Descricao }}
      </option>
    </select>
    <span
      class="form-text text-danger"
      *ngIf="formProntuario.get('CodMot').invalid && formProntuario.get('CodMot').touched"
    >
      Campo Obrigatório
    </span>
  </div>

  <div class="form-group col-6">
    <label for="Dt1LM" class="font-weigth-semibold">Data Inicial<small></small></label>
    <input
      type="date"
      max="9999-12-31"
      class="form-control"
      id="Dt1LM"
      formControlName="Dt1LM"
      [ngClass]="{
        'is-invalid': formProntuario.get('Dt1LM').invalid && formProntuario.get('Dt1LM').touched
      }"
    />
    <span
      class="form-text text-danger"
      *ngIf="formProntuario.get('Dt1LM').invalid && formProntuario.get('Dt1LM').touched"
    >
      Campo Obrigatório
    </span>
  </div>

  <div class="form-group col-6">
    <label for="Dt2LM" class="font-weigth-semibold">Data Final<small></small></label>
    <input
      type="date"
      max="9999-12-31"
      class="form-control"
      id="Dt2LM"
      formControlName="Dt2LM"
      [ngClass]="{
        'is-invalid': formProntuario.get('Dt2LM').invalid && formProntuario.get('Dt2LM').touched
      }"
    />
    <span
      class="form-text text-danger"
      *ngIf="formProntuario.get('Dt2LM').invalid && formProntuario.get('Dt2LM').touched"
    >
      Campo Obrigatório
    </span>
  </div>
</form>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>

  <button
    *ngIf="prontuarioindex !== null"
    type="button"
    class="btn btn-primary"
    (click)="lancarProntuario()"
  >
    Atualizar
  </button>
  <button
    *ngIf="prontuarioindex === null"
    type="button"
    class="btn btn-primary"
    (click)="lancarProntuario()"
  >
    Cadastrar
  </button>
</div>
