import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { SituacaoFalecimento } from './situacao-funcional-falecimento.component';

@Injectable({
  providedIn: 'root'
})
export class SituacaoFuncionalFalecimentoService {
  localStorage: LocalStorage = new LocalStorage();
  url_acesso:string;
  headers = new HttpHeaders();
  cabecalho: any = null;
  tpProcessamento: string = "EM";
  filialAtual:string = '';

  constructor(private http: HttpClient, private acesso: AcessoService) { 

    this.url_acesso = this.acesso.validarUrl();
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filialAtual = this.localStorage.FilialAtual.toString(); 
    }
  }

  salvarSituacaoFalecimento(situacaoFalecimento: SituacaoFalecimento){
    return this.http.post(`${this.url_acesso}situacao-funcional/falecimento`, situacaoFalecimento, {headers:this.cabecalho});
  }

  alterarSituacaoFalecimento(situacaoFalecimento: SituacaoFalecimento) {
    return this.http.put(`${this.url_acesso}situacao-funcional/falecimento`, situacaoFalecimento, { headers: this.cabecalho });
  }
  
  buscarColaborador(matricula: string, competencia: string){
    return this.http.get(`${this.url_acesso}lista-nome/${this.tpProcessamento}/${competencia}/${this.filialAtual}/${matricula}`,{headers:this.cabecalho});
  }
  
  obterNumord(matricula: string){
    return this.http.get(`${this.url_acesso}numord/${this.filialAtual}/${matricula}`,{headers:this.cabecalho});
  }
  
  buscarColaboradorFalecimento(matricula: string) {
    return this.http.get(`${this.url_acesso}situacao-funcional-falecimento/${this.filialAtual}/${matricula}`, { headers: this.cabecalho });
  }
  
}
