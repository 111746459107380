import { Pipe, PipeTransform } from "@angular/core"
import { TipoUsuario } from "./cadastro-tipo-usuario.component"

@Pipe({name: 'filtrarTipoUsaurio'})
export class filtroTipoUsuario implements PipeTransform {
    transform(listaTipoUsuairo: TipoUsuario[], event: Event) {
        
        var filtroTipoUsuairo = (event.target as HTMLInputElement).value;
        if (filtroTipoUsuairo != "") {
            filtroTipoUsuairo = filtroTipoUsuairo.toLowerCase()
            listaTipoUsuairo = listaTipoUsuairo.filter(list => list.CodigoTipoUsuario.toLowerCase().includes(filtroTipoUsuairo) || list.TipoDoUsuario.toLowerCase().includes(filtroTipoUsuairo) || list.Descricao.toLowerCase().includes(filtroTipoUsuairo))
            return listaTipoUsuairo
        }
        else {
            return listaTipoUsuairo
        }
    }
}