import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AcessoService } from '../../service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root'
})
export class CancelarRescisaoService {
  localStorage: LocalStorage;
  url_acesso:string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  filial: string;


  constructor(private http: HttpClient, private acesso: AcessoService) { 
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.url_acesso = this.acesso.validarUrl();
    }
  }

  buscarColaborador(cpf: string){
    return this.http.get(`${this.url_acesso}cadastro/${cpf}/${this.filial}`,{headers:this.cabecalho});
  }

  cancelarRescisao(matricula: string, competencia: string){
    return this.http.put(`${this.url_acesso}rescisao/${competencia}/RS/${this.filial}/${matricula}`, {}, {headers:this.cabecalho});
  }


  buscarColaboradorMatricula(matricula: string){
    return this.http.get(`${this.url_acesso}cadastro-ativo-matricula/${matricula}/${this.filial}`,{headers:this.cabecalho});
  }

}
