import {Component, Input} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {ErroService} from 'src/app/service/erros/erro.service';
import {Formacao, FormacaoBasicaComponent} from '../formacao-basica.component';
import {Cadastro} from 'src/app/service/classes/cadastro/cadastro';
import {dataAtualOuAnteriorValidator} from 'src/app/service/Validators/dataMenorIgualValidator';
import Swal from 'sweetalert';
import {FormacaoBasicaService} from './../formacao-basica.service';
import {ItemExp} from '../../experiencia-profissional/experiencia-profissional.component';

@Component({
  selector: 'app-formacao-basica-cadastro',
  templateUrl: './formacao-basica-cadastro.component.html',
  styleUrls: ['./formacao-basica-cadastro.component.css'],
})
export class FormacaoBasicaCadastroComponent {
  @Input() cadastro: boolean = true;
  @Input() colaborador: Cadastro = null;
  @Input() formacaoAtual: Formacao = null;
  formFormacoes: FormGroup;
  formValues: any;
  listaCursos: ItemExp[] = [];
  listaConceitos: ItemExp[] = [
    {
      Codigo: 'APROVADO',
      Descricao: 'APROVADO',
    },
    {
      Codigo: 'REPROVADO',
      Descricao: 'REPROVADO',
    },
    {
      Codigo: 'OTIMO',
      Descricao: 'OTIMO',
    },
    {
      Codigo: 'BOM',
      Descricao: 'BOM',
    },
    {
      Codigo: 'REGULAR',
      Descricao: 'REGULAR',
    },
    {
      Codigo: 'RUIM',
      Descricao: 'RUIM',
    },
    {
      Codigo: '',
      Descricao: 'NENHUM',
    },
    {
      Codigo: 'CURSANDO',
      Descricao: 'CURSANDO',
    },
  ];

  constructor(
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private validaErro: ErroService,
    private formacaoBasicaService: FormacaoBasicaService,
    private formacaoBasicaComponent: FormacaoBasicaComponent
  ) {}

  ngOnInit() {
    this.buscarCursos();
    this.formFormacoes = this.fb.group({
      CodCrs: ['', Validators.required],
      DtInicio: ['', [Validators.required, dataAtualOuAnteriorValidator()]],
      DtTermino: ['', [Validators.required, dataAtualOuAnteriorValidator()]],
      Quadro: [''],
      NumPos: [''],
      Conceito: [''],
      Entidade: [''],
      CargHor: [''],
      Nota: [''],
      Obs1: [''],
      Obs2: [''],
    });
    this.formValues = this.formFormacoes.value;
  }

  limparForm() {
    this.formFormacoes.reset();
    this.formFormacoes.patchValue(this.formValues);
  }

  lancarFormacao(formacao?: Formacao) {
    this.spinner.show();
    console.log(this.formFormacoes);
    this.formFormacoes.markAllAsTouched();
    if (this.formFormacoes.valid) {
      if (
        this.verificaData(this.formFormacoes.get('DtInicio'), this.formFormacoes.get('DtTermino'))
      ) {
        let botaoFechar = document.getElementById('botaoFechar');
        let formacaoMontada = this.montarFormacao();
        if (formacao) {
          this.formacaoBasicaService.atualizarFormacoes(formacaoMontada).subscribe(
            (data) => {
              Swal('Sucesso', 'Formação atualizada com sucesso.', 'success');
              this.formacaoBasicaComponent.buscarFormacoes();
              botaoFechar.click();
              this.spinner.hide();
            },
            (err) => {
              Swal('Erro', 'Não foi possível atualizar essa formação.', 'error');
              this.spinner.hide();
            }
          );
        } else {
          this.formacaoBasicaService.incluirFormacoes(formacaoMontada).subscribe(
            (data) => {
              Swal('Sucesso', 'Formação lançada com sucesso.', 'success');
              this.formacaoBasicaComponent.buscarFormacoes();
              botaoFechar.click();
              this.spinner.hide();
            },
            (err) => {
              Swal('Erro', 'Não foi possível inserir essa formação.', 'error');
              this.spinner.hide();
            }
          );
        }
      }
    } else {
      Swal('Atenção', 'Por favor, preencha todos os campos corretamente.', 'warning');
      this.spinner.hide();
    }
  }

  verificaData(dataInicial: AbstractControl, dataFinal: AbstractControl): boolean {
    if (dataInicial.value && dataFinal.value) {
      const dataInicialDate = new Date(dataInicial.value);
      const dataFinalDate = new Date(dataFinal.value);

      if (dataInicialDate >= dataFinalDate) {
        dataFinal.setValue('');
        Swal('Atenção', 'A data final deve ser posterior à inicial.', 'warning');
        this.spinner.hide();
        return false;
      } else {
        return true;
      }
    }
  }

  montarFormacao(): Formacao {
    let formacaoMontada: Formacao = {
      //obrigatórios
      Sigla: 'FAPP',
      CodTof: '00000000000000',
      Empfil: this.colaborador.Filial.Codigo,
      Mat: parseInt(this.colaborador.Matricula),
      DtInicio: {
        SetData: this.formFormacoes.get('DtInicio').value.replace(/-/g, ''),
        ToDate: this.formacaoBasicaService.formatarDataBarra(
          this.formFormacoes.get('DtInicio').value.replace(/-/g, '')
        ),
        Empty: false,
        IsDate: true,
      },
      DtTermino: {
        SetData: this.formFormacoes.get('DtTermino').value.replace(/-/g, ''),
        ToDate: this.formacaoBasicaService.formatarDataBarra(
          this.formFormacoes.get('DtTermino').value.replace(/-/g, '')
        ),
        Empty: false,
        IsDate: true,
      },
      CodCrs: this.formFormacoes.get('CodCrs').value,

      //opcionais
      Obs1: this.formFormacoes.get('Obs1').value ? this.formFormacoes.get('Obs1').value : '',
      Obs2: this.formFormacoes.get('Obs2').value ? this.formFormacoes.get('Obs2').value : '',
      Quadro: this.formFormacoes.get('Quadro').value ? this.formFormacoes.get('Quadro').value : 0,
      NumPos: this.formFormacoes.get('NumPos').value ? this.formFormacoes.get('NumPos').value : 0,
      Conceito: this.formFormacoes.get('Conceito').value
        ? this.formFormacoes.get('Conceito').value
        : '',
      Entidade: this.formFormacoes.get('Entidade').value
        ? this.formFormacoes.get('Entidade').value
        : '',
      CargHor: this.formFormacoes.get('CargHor').value
        ? this.formFormacoes.get('CargHor').value
        : 0,
      Nota: this.formFormacoes.get('Nota').value ? this.formFormacoes.get('Nota').value : 0,
    };
    console.log('formacao montada', formacaoMontada);
    return formacaoMontada;
  }

  buscarCursos() {
    this.listaCursos = this.formacaoBasicaService.retornarLista('cursos');
    if (this.listaCursos.length == 0) {
      this.spinner.show();
      this.formacaoBasicaService.buscarCursos().subscribe(
        (data: any) => {
          this.listaCursos = data;
          this.formacaoBasicaService.inserirLista('cursos', this.listaCursos);
          this.spinner.hide();
        },
        (error) => {
          Swal('Erro', error.error.Mensagem, 'error');
          this.spinner.hide();
        }
      );
    }
  }

  setarFormacaoExistente(formacao: Formacao) {
    this.formFormacoes.get('CodCrs').setValue(formacao.CodCrs);
    this.formFormacoes
      .get('DtInicio')
      .setValue(this.formacaoBasicaService.formatarDataHifen(formacao.DtInicio.SetData));
    this.formFormacoes
      .get('DtTermino')
      .setValue(this.formacaoBasicaService.formatarDataHifen(formacao.DtTermino.SetData));

    formacao.Quadro != 0 ? this.formFormacoes.get('Quadro').setValue(formacao.Quadro) : null;
    formacao.NumPos != 0 ? this.formFormacoes.get('NumPos').setValue(formacao.NumPos) : null;
    formacao.Conceito != '' ? this.formFormacoes.get('Conceito').setValue(formacao.Conceito) : null;
    formacao.Entidade != '' ? this.formFormacoes.get('Entidade').setValue(formacao.Entidade) : null;
    formacao.CargHor != 0 ? this.formFormacoes.get('CargHor').setValue(formacao.CargHor) : null;
    formacao.Nota != 0 ? this.formFormacoes.get('Nota').setValue(formacao.Nota) : null;
    formacao.Obs1 != '' ? this.formFormacoes.get('Obs1').setValue(formacao.Obs1) : null;
    formacao.Obs2 != '' ? this.formFormacoes.get('Obs2').setValue(formacao.Obs2) : null;
  }
}
