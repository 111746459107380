<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="col-12">
    <form [formGroup]="cancelarRescisaoFormGroup" #cancelarRescisaoForm='ngForm'>
        <div class="col-12">
            <div class="card shadow-sm">
                <div class="card-header header-elements-inline">
                    <h3>Cancelar Rescisão</h3>
                </div>
                <div class="col-12 card-body">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-md-6 col-lg-5 col-xl-3 form-group">
                                    <label class="font-weight-semibold">Matrícula do Funcionário</label>
                                    <div class="input-group">
                                        <input [ngStyle]="(cancelarRescisaoFormGroup.controls['matricula'].status == 'INVALID' && validaCancelarRecisao) ? {'border-color':'red'} : null" [(ngModel)]="rescisao.Matricula" formControlName="matricula" name="matricula" type="text" class="form-control" placeholder="Matricula" mask="0*" autofocus/>                               
                                            <div class="input-group-append">
                                            <button (click)="buscarColaborador()" class="btn" style="background-color:#faca00; color:white" type="button">
                                                <i class="fa fa-search"></i>&nbsp;
                                            </button>
                                        </div>
                                    </div>
                            </div>
                            <div class="col-md-6 col-lg-7 col-xl-6 form-group">
                                <label class="font-weight-semibold">Nome Completo</label>
                                <input [(ngModel)]="rescisao.Nome" formControlName="Nome" name="Status" type="text" class="form-control" placeholder="Nome" readonly />
                            </div>
                            <div class="col-md-3 col-lg-3 col-xl-3 form-group">
                                <label class="font-weight-semibold">Competência</label>
                                <input [(ngModel)]="competencia" formControlName="competencia" name="competencia" type="text" class="form-control" mask="00/0000" placeholder="Competência"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-right">
                    <button (click)="limpar()" class="btn btn-danger mr-2" type="button">Limpar</button>
                    <button class="btn btn-primary" type="button" (click)="onSubmit()">Efetuar Cancelamento</button>
                </div>
            </div>
        </div>
    </form>

</div>
