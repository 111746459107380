<div class="modal-header">
  <h3 class="modal-title">{{ cadastro ? 'Cadastrar' : 'Atualizar' }} Informações</h3>
  <button type="button" class="close" id="botaoFechar" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form #estagio="ngForm" class="modal-body mt-2" [formGroup]="formSaude" (ngSubmit)="submitForm()">
  <div class="row">
    <div class="col-md-4 form-group">
      <label class="font-weight-semibold" for="tpDeficiencia">Tipo de Deficiência</label>
      <select
        class="form-control"
        id="tpDeficiencia"
        formControlName="tpDeficiencia"
        [ngClass]="
          formSaude.get('tpDeficiencia').invalid && formSaude.get('tpDeficiencia').touched
            ? 'is-invalid'
            : null
        "
      >
        <option value="">Selecione...</option>
        <option value="1">FISICA</option>
        <option value="2">VISUAL</option>
        <option value="3">AUDITIVA</option>
        <option value="4">MENTAL</option>
        <option value="5">INTELECTUAL</option>
        <option value="6">DOENCA CRONICA</option>
        <option value="7">CARDIOPATA</option>
        <option value="8">TABAGISTA</option>
      </select>
      <span
        class="form-text text-danger"
        *ngIf="formSaude.get('tpDeficiencia').invalid && formSaude.get('tpDeficiencia').touched"
      >
        Campo Obrigatório
      </span>
    </div>
    <div class="col-md-4 form-group">
      <label class="font-weight-semibold">Reabilitado</label>
      <select
        class="form-control"
        formControlName="reabilitado"
        [ngClass]="
          formSaude.get('reabilitado').invalid && formSaude.get('reabilitado').touched
            ? 'is-invalid'
            : null
        "
      >
        <option value="">Selecione...</option>
        <option [ngValue]="true">Sim</option>
        <option [ngValue]="false">Não</option>
      </select>
      <span
        class="form-text text-danger"
        *ngIf="formSaude.get('reabilitado').invalid && formSaude.get('reabilitado').touched"
      >
        Campo Obrigatório
      </span>
    </div>
    <div class="col-md-4 form-group">
      <label class="font-weight-semibold" for="readaptado">Readaptado</label>
      <select
        class="form-control"
        id="readaptado"
        formControlName="readaptado"
        [ngClass]="
          formSaude.get('readaptado').invalid && formSaude.get('readaptado').touched
            ? 'is-invalid'
            : null
        "
      >
        <option value="">Selecione...</option>
        <option [ngValue]="true">Sim</option>
        <option [ngValue]="false">Não</option>
      </select>
      <span
        class="form-text text-danger"
        *ngIf="formSaude.get('readaptado').invalid && formSaude.get('readaptado').touched"
      >
        Campo Obrigatório
      </span>
    </div>

    <div class="col-md-4 form-group">
      <label class="font-weight-semibold" for="dtInicio">Data Inicial</label>
      <input
        type="date"
        id="dtInicio"
        formControlName="dtInicio"
        class="form-control pr-0"
        max="9999-12-31"
        [ngClass]="
          formSaude.get('dtInicio').invalid && formSaude.get('dtInicio').touched
            ? 'is-invalid'
            : null
        "
      />
      <span
        class="form-text text-danger"
        *ngIf="formSaude.get('dtInicio').invalid && formSaude.get('dtInicio').touched"
      >
        {{
          formSaude.get('dtInicio').hasError('dataInvalida')
            ? 'Data deve ser igual ou menor à data atual'
            : 'Campo Obrigatório'
        }}
      </span>
    </div>
    <div class="col-md-4 form-group">
      <label class="font-weight-semibold" for="dtFinal">Data Final <small>(opcional)</small></label>
      <input
        type="date"
        id="dtFinal"
        formControlName="dtFinal"
        class="form-control pr-0"
        max="9999-12-31"
        [ngClass]="
          formSaude.get('dtFinal').invalid && formSaude.get('dtFinal').touched ? 'is-invalid' : null
        "
      />
      <span
        class="form-text text-danger"
        *ngIf="formSaude.get('dtFinal').invalid && formSaude.get('dtFinal').touched"
      >
        {{
          formSaude.get('dtFinal').hasError('dataInvalida')
            ? 'Data deve ser igual ou menor à data atual'
            : 'Campo Obrigatório'
        }}
      </span>
    </div>
    <div class="col-md-4 form-group">
      <label class="font-weight-semibold" for="cota">Preenche Cota</label>
      <select
        class="form-control"
        id="cota"
        formControlName="cota"
        [ngClass]="
          formSaude.get('cota').invalid && formSaude.get('cota').touched ? 'is-invalid' : null
        "
      >
        <option value="">Selecione...</option>
        <option [ngValue]="true">Sim</option>
        <option [ngValue]="false">Não</option>
      </select>
      <span
        class="form-text text-danger"
        *ngIf="formSaude.get('cota').invalid && formSaude.get('cota').touched"
      >
        Campo Obrigatório
      </span>
    </div>
    <div class="col-md-12 form-group">
      <label class="font-weight-semibold" for="descricao">Descritivo da Deficiência</label>

      <textarea
        class="form-control"
        formControlName="descricaoDeficiencia"
        id="descricao"
        rows="5"
        [ngClass]="
          formSaude.get('descricaoDeficiencia').invalid &&
          formSaude.get('descricaoDeficiencia').touched
            ? 'is-invalid'
            : null
        "
      ></textarea>
      <span
        class="form-text text-danger"
        *ngIf="
          formSaude.get('descricaoDeficiencia').invalid &&
          formSaude.get('descricaoDeficiencia').touched
        "
      >
        Campo Obrigatório
      </span>
    </div>
  </div>
</form>
<div class="modal-footer mt-2 justify-content-end row">
  <button (click)="submitForm()" class="btn btn-primary">
    {{ cadastro ? 'Cadastrar' : 'Atualizar' }}
  </button>
</div>
