import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { TipoUsuario } from './cadastro-tipo-usuario.component'

@Injectable({
  providedIn: 'root'
})
export class CadastroTipoUsuarioService {
  localStorage: LocalStorage = new LocalStorage();
  urlAcesso: string ;
  headers: HttpHeaders = new HttpHeaders();
  cabecalho: any = null;
  filial: string = '';

  constructor(private acesso: AcessoService, private http: HttpClient) { 
    this.urlAcesso = this.acesso.validarUrl();
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString(); 
    }
  }

  salvarTipoUsuario(tipoUsuario: TipoUsuario){
    return this.http.post(`${this.urlAcesso}politica-seguranca/tipo-usuario`, tipoUsuario,{headers:this.cabecalho});
  }

  editarTipoUsuario(tipoUsuario: TipoUsuario){
    return this.http.put(`${this.urlAcesso}politica-seguranca/tipo-usuario`, tipoUsuario,{headers:this.cabecalho});
  }

  deletarTipoUsuario(codigo: string) {
    return this.http.delete(this.urlAcesso + `politica-seguranca/tipo-usuario/${codigo}`, { headers: this.cabecalho });
}

  buscarServicosPse(){
    return this.http.get(this.urlAcesso + `pse/servicos`, {headers:this.cabecalho});
  }

  listarTipoUsuario(){
    return this.http.get(this.urlAcesso + `politica-seguranca/tipos-usuario`, {headers:this.cabecalho});
  }
  
  buscarTarefasPorCodigo(codigoServico: number){
    return this.http.get(`${this.urlAcesso}pse/tarefas/${codigoServico}`,{headers:this.cabecalho});
  } 

  buscarTiposUsuario() {
    return this.http.get(`${this.urlAcesso}politica-seguranca/tipos-usuario`, {headers:this.cabecalho});
  }

  buscarTipoUsuario(codigo: string) {
    return this.http.get(`${this.urlAcesso}politica-seguranca/tipos-usuario/${codigo}`, {headers:this.cabecalho});
  }
  
  buscarArvoreTarefas(servico: any){
    return this.http.get(`${this.urlAcesso}pse/tarefas/arvore/${servico}`,{headers:this.cabecalho})
  }
  
  buscarArvoreTarefasEditar(servico: any, tipoUsuario: any ){
    return this.http.get(`${this.urlAcesso}pse/tarefas/arvore/${servico}/${tipoUsuario}`,{headers:this.cabecalho})
  }
}
