import { CancelarRescisaoService } from './cancelar-rescisao.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {Router} from '@angular/router';
import { Rescisao } from './../rescisao/interfaces/rescisao'
import { ErroService} from '../../service/erros/erro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';



@Component({
  selector: 'app-cancelar-rescisao',
  templateUrl: './cancelar-rescisao.component.html',
  styleUrls: ['./cancelar-rescisao.component.css']
})
export class CancelarRescisaoComponent implements OnInit {

  cancelarRescisaoFormGroup: any;
  rescisao: Rescisao = new Rescisao;
  validaCancelarRecisao = false;
  competencia:string ;
  competenciaAux:string ;

  constructor(private fb: FormBuilder,
    private cancelarRescisaoService: CancelarRescisaoService,
    private validaErro:ErroService,
    private myRoute: Router,
    private spinner: NgxSpinnerService) { }



  ngOnInit() {
    this.cancelarRescisaoFormGroup = this.fb.group(
      {
        matricula: ['', Validators.required],
        Nome: ['', Validators.required],
        competencia: ['',  Validators.required ]
      });
  }

  organizarCompetencia(){
    this.competenciaAux = this.competencia.substring(2,6) + this.competencia.substring(0,2)
  }

  validarCampos(){
    if(this.cancelarRescisaoFormGroup.invalid){
      new AlertaModal("alerta","Atenção!", "Preencha todos os campos corretamente", "p");
      this.validaCancelarRecisao = true;
      return false;
    }else{
      return true;
    }

  }



  onSubmit(){
    if(!this.validarCampos()){
      new AlertaModal("alerta","Atenção!", "Rescisão não pode ser cancelada!", "p");
    }
    else{
      this.spinner.show();
      this.organizarCompetencia()
      this.cancelarRescisaoService.cancelarRescisao(this.rescisao.Matricula, this.competenciaAux)
      .subscribe(
        data => {
          if(data){
            new AlertaModal("sucesso", "Sucesso!", "Rescisão cancelada!", "p");
            this.spinner.hide();
            this.limpar()
          }
        },
        error =>  {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        }
      );
    }
  }
  buscarColaborador(){
    if(this.cancelarRescisaoFormGroup.controls['matricula'].status == "INVALID"){
      new AlertaModal("alerta","Atenção!", "Digite uma matricula válida!", "p");
      this.cancelarRescisaoFormGroup.controls['matricula'].setValue("");
      this.cancelarRescisaoFormGroup.controls['nome'].setValue("");
      this.cancelarRescisaoFormGroup.controls['competencia'].setValue("");

      return false;
    }
    this.spinner.show();

    this.cancelarRescisaoService.buscarColaboradorMatricula(this.cancelarRescisaoFormGroup.value.matricula)
    .subscribe(
      data => {
        this.rescisao.Matricula = data["Matricula"];
        this.rescisao.Nome = data['InformacoesBasicas'].NomeCompleto;
        this.spinner.hide()
      },
      error => {
        this.spinner.hide()
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        this.cancelarRescisaoFormGroup.controls['Matricula'].setValue("");
      })
    }



  verificaCpf(cpf){
    if(!this.validarCpf(cpf)){
      this.cancelarRescisaoFormGroup.controls['Cpf'].setValue("");
    }
  }

  validarCpf(c) {

    var i;

    if((c = c.replace(/[^\d]/g,"")).length != 11)
    return false;

    if (c == "00000000000")
    return false;

    var r;
    var s = 0;

    for (i=1; i<=9; i++)
    s = s + parseInt(c[i-1]) * (11 - i);

    r = (s * 10) % 11;

    if ((r == 10) || (r == 11))
    r = 0;

    if (r != parseInt(c[9]))
    return false;

    s = 0;

    for (i = 1; i <= 10; i++)
    s = s + parseInt(c[i-1]) * (12 - i);

    r = (s * 10) % 11;

    if ((r == 10) || (r == 11))
    r = 0;

    if (r != parseInt(c[10]))
    return false;

    return true;
  }
  limpar(){
    this.cancelarRescisaoFormGroup.controls['matricula'].setValue("");
    this.cancelarRescisaoFormGroup.controls['competencia'].setValue("");
    this.cancelarRescisaoFormGroup.controls['Nome'].setValue("");
  }

}
