<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<app-pesquisar-matricula
  *ngIf="matricula == null"
  [label]="'Matrícula'"
  [titulo]="'Processos'"
  [inputText]="true"
  (colaboradorEmitter)="matriculaListener($event)"
></app-pesquisar-matricula>

<div class="container" *ngIf="matricula != null">
  <!-- formulário processos -->
  <div class="card">
    <div class="card-header">
      <h3 class="card-title" id="target">
        <i
          class="fa fa-arrow-left pr-3 btn"
          aria-hidden="true"
          (click)="retornarTelaInicial()"
          style="cursor: pointer; bottom: 2px"
        ></i>
        Processos
      </h3>
    </div>
    <div>
      <div class="card-body">
        <div class="row">
          <!-- MATRICULA E NOME ( HEADER ) -->
          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Matrícula</label>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                [ngModel]="matriculaAux"
                [disabled]="true"
                mask="0*"
                maxlength="9"
                placeholder="Matrícula"
                [ngStyle]="
                  (temErro || formProcessos.controls['matricula'].dirty) &&
                  formProcessos.controls['matricula'].status == 'INVALID'
                    ? { 'border-color': 'red' }
                    : null
                "
              />
            </div>
            <span class="form-text text-danger" *ngIf="validaMatricula"> Campo obrigatório </span>
          </div>

          <div class="col-md-10 form-group">
            <label class="font-weight-semibold">Nome</label>
            <input
              class="form-control"
              type="text"
              [ngModel]="nomeAux"
              [disabled]="true"
              placeholder="Nome"
              (keypress)="somenteLetras($event)"
              [ngStyle]="
                temErro && formProcessos.controls['nome'].hasError('required')
                  ? { 'border-color': 'red' }
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formProcessos.controls['nome'].hasError('required')"
            >
              Campo obrigatório
            </span>
          </div>
          <!-- FIM MATRICULA E NOME ( HEADER ) -->
        </div>
      </div>
    </div>
  </div>

  <!-- LISTAGEM PROCESSOS -->
  <div *ngIf="formProcessos.get('nome').value != ''" class="card">
    <div class="card-header d-flex justify-content-between">
      <h3 class="card-title">Listagem de Processos</h3>
      <div>
        <button
          type="button"
          class="btn btn-outline-primary ml-3"
          data-toggle="modal"
          data-target="#cadastroProcessoModal"
          (click)="limparCampos()"
        >
          <i class="fa fa-plus"></i> Adicionar Processo
        </button>
      </div>
    </div>

    <div class="card-body">
      <div
        *ngIf="listaProcessos == null"
        class="d-flex justify-content-center align-items-center"
        style="height: 20vh"
      >
        <span class="form-text"><i class="fa fa-spinner fa-spin" aria-hidden="false"></i></span>
      </div>
      <div class="form-group row">
        <div class="input-group col-lg-12">
          <input
            type="number"
            class="form-control"
            name="searchText"
            value=""
            placeholder="Pesquisar Número do Processo"
            [(ngModel)]="searchText"
          />
          <span class="input-group-append">
            <span class="input-group-text"><i class="fa fa-search"></i></span>
          </span>
        </div>
      </div>

      <div class="table-responsive" style="max-height: 45vh">
        <table
          *ngIf="listaProcessos != null && listaProcessos.length > 0"
          class="table table-striped table-hover"
        >
          <thead class="text-center">
            <tr class="text-center" style="white-space: nowrap">
              <th class="sticky-top bg-white">Número</th>
              <th class="sticky-top bg-white">Assunto</th>
              <th class="sticky-top bg-white">Início</th>
              <th class="sticky-top bg-white">Fim</th>
              <th class="sticky-top bg-white">Situação</th>
              <th class="sticky-top bg-white p-0"></th>
            </tr>
          </thead>
          <tbody
            id="lista-processos"
            *ngFor="let processo of listaProcessos | pesquisaPipe : searchText; let i = index"
          >
            <tr class="text-center" style="white-space: nowrap">
              <td>{{ processo.NumeroProcesso }}</td>
              <td>{{ processo.Assunto }}</td>
              <td>{{ processo.DataInicio.ToDate }}</td>
              <td>{{ processo.DataFim.ToDate }}</td>
              <td>{{ buscarSituacaoNome(processo.Situacao) }}</td>
              <td>
                <div>
                  <button
                    class="btn btn-sm btn-outline-info"
                    data-toggle="collapse"
                    [attr.data-target]="'#collapseProcesso' + i"
                  >
                    <i class="fa fa-info"></i>
                  </button>
                  <button
                    class="btn btn-sm btn-outline-primary mx-2"
                    (click)="editarProcesso(processo)"
                    *ngIf="localStorage.TipoUsuario !== '1'"
                    data-toggle="modal"
                    data-target="#cadastroProcessoModal"
                  >
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button
                    type="button"
                    [ngClass]="{
                      'btn-outline-primary': processo.Obs === 'N',
                      'btn-primary': processo.Obs === 'S'
                    }"
                    class="btn mr-2"
                    data-toggle="modal"
                    data-target="#observacoesProcessosModal"
                    (click)="modalObservacoes.abrirModal(processo)"
                  >
                    <i class="fa-solid fa-book fa-lg"></i>
                  </button>
                  <button
                    type="button"
                    [ngClass]="{
                      'btn-outline-primary': processo.Doc === 'N',
                      'btn-primary': processo.Doc === 'S'
                    }"
                    class="btn mr-2"
                    data-toggle="modal"
                    data-target="#anexoProcessosModal"
                    (click)="anexoComponent.abrirModal(processo)"
                  >
                    <i class="fa fa-file"></i>
                  </button>
                  <button
                    class="btn btn-sm btn-outline-danger"
                    (click)="deletar(processo, i)"
                    *ngIf="localStorage.TipoUsuario !== '1'"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
            <td colspan="12" class="collapse px-0" [attr.id]="'collapseProcesso' + i">
              <table class="table table-bordered pb-3">
                <thead class="text-center">
                  <tr class="text-center table-primary">
                    <th>Requerente</th>
                    <th>Reflexo Financeiro</th>
                    <th *ngIf="clientePublico">Nº Ato</th>
                    <th *ngIf="clientePublico">Data do Ato</th>
                    <th *ngIf="clientePublico">Nº DOE</th>
                    <th *ngIf="clientePublico">Data do DOE</th>
                    <!-- <th *ngIf="clientePublico">Anexos</th> -->
                  </tr>
                </thead>
                <tbody id="lista-processos">
                  <tr class="text-center table-primary">
                    <td>{{ processo.Requerente }}</td>
                    <td>{{ processo.ReflexoFinanceiro }}</td>
                    <td *ngIf="clientePublico">
                      {{ processo.NumeroAto ? processo.NumeroAto : 'NÃO INFORMADO' }}
                    </td>
                    <td *ngIf="clientePublico">
                      {{ processo.DataAto.ToDate ? processo.DataAto.ToDate : 'NÃO INFORMADO' }}
                    </td>
                    <td *ngIf="clientePublico">
                      {{
                        processo.NumeroDiarioOficial
                          ? processo.NumeroDiarioOficial
                          : 'NÃO INFORMADO'
                      }}
                    </td>
                    <td *ngIf="clientePublico">
                      {{
                        processo.DataDiarioOficial.ToDate
                          ? processo.DataDiarioOficial.ToDate
                          : 'NÃO INFORMADO'
                      }}
                    </td>
                    <!-- <td>
                      <app-anexo
                        [pagina]="'016'"
                        [matricula]="formProcessos.get('matricula').value"
                        [enviarSeparadamente]="true"
                        [perfil]="false"
                        [compChave]="processo.NumeroProcesso"
                        [listaProcessos]="true"
                      ></app-anexo>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </td>
          </tbody>
        </table>
      </div>

      <div
        *ngIf="listaProcessos !== null && listaProcessos.length === 0"
        class="d-flex justify-content-center align-items-center"
        style="height: 20vh"
      >
        <h5 class="font-weight-semibold">Não há processos para ser exibido</h5>
      </div>
    </div>
  </div>
  <!-- FIM LISTAGEM PROCESSOS -->
</div>

<div class="modal fade" id="observacoesProcessosModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-observacoes-ficha-funcional
        (gatilhoAtivado)="buscarProcessos(matricula.toString())"
        [colaborador]="{ Matricula: matricula }"
        tipo="016"
        [permissoes]="permissoesService.permissoes"
      >
      </app-observacoes-ficha-funcional>
    </div>
  </div>
</div>
<div class="modal fade" id="anexoProcessosModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-anexo
        [permissoes]="permissoesService.permissoes"
        (gatilhoAtivado)="buscarProcessos(matricula.toString())"
        [pagina]="'016'"
        [matricula]="matricula"
        [enviarSeparadamente]="true"
        [modal]="true"
      ></app-anexo>
    </div>
  </div>
</div>

<div
  #cadastroProcessoModal
  class="modal fade"
  id="cadastroProcessoModal"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <app-processos-cadastro></app-processos-cadastro>
    </div>
  </div>
</div>
