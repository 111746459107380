export class Setor{
    Sigla: string
    Cod: string
    Empfil: string
    Nome: string
    NomeCompleto: string
    Nivel: string
    Tipo: string
    Efetivo: string
    Tpctad: string
    Ctad: string
    Tpctac: string
    Ctac: string

    F01: string
    F02: string
    F03: string
    F04: string
    F05: string
    F06: string
    F07: string
    F08: string
    F09: string
    F10: string
    Ccusto: string
    Hempfil: string
    Descricao: string
    Inivalid: string
    Fimvalid: string
    Siglaent: string
    Opcao: string
    CodLot: string
    NomFil: string
    QuantDeSetores: number
    QuantVagasPlanejadas: number
    QuantVagasEfetivadas: number

    constructor(){};
 }
            
           
            
            
     
       