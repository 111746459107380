import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { TempoServicoService } from './tempo-servico.service';
import Swal from 'sweetalert';
import {
  TempoServico,
  TempoServicoCadastroComponent,
} from './tempo-servico-cadastro/tempo-servico-cadastro.component';
import { ObservacoesFichaFuncionalComponent } from '../componentes-funcionais/observacoes-ficha-funcional/observacoes-ficha-funcional.component';
import { AnexoComponent } from '../anexo/anexo.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Component({
  selector: 'app-tempo-servico',
  templateUrl: './tempo-servico.component.html',
  styleUrls: ['./tempo-servico.component.css'],
})
export class TempoServicoComponent {
  @ViewChild(TempoServicoCadastroComponent) modal: TempoServicoCadastroComponent;
  @ViewChild(ObservacoesFichaFuncionalComponent)
  modalObservacoes: ObservacoesFichaFuncionalComponent;
  @ViewChild(AnexoComponent) modalAnexo: AnexoComponent;
  matricula: number = null;
  colaborador: Cadastro = null;
  loading: boolean = false;
  formColaborador: FormGroup;
  cadastroServico: boolean = true;
  servicoAtual: TempoServico;
  listaTempoServico: any[] = [];
  localStorage: LocalStorage;
  empfil: string;
  tipoObs: string = '703';
  tipoDoc: string = '103';

  constructor(
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private tempoServicoService: TempoServicoService
  ) {
    this.localStorage = this.decryptLocalStorage();
    this.empfil = this.localStorage.FilialAtual.toString();
    this.formColaborador = this.fb.group({
      matricula: ['', Validators.required],
      nome: [''],
    });
  }
  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }

  matriculaListener(evento) {
    this.colaborador = evento;
    this.matricula = parseInt(this.colaborador.Matricula);
    this.setarFormColaborador(evento);
    this.modalAnexo.inicializarComponente(evento);
  }

  retornarTelaInicial() {
    this.matricula = null;
    this.formColaborador.reset();
    this.formColaborador.markAsPristine();
  }

  setarFormColaborador(data) {
    this.formColaborador.get('matricula').disable();
    this.formColaborador.get('matricula').setValue(this.matricula);
    this.formColaborador.get('nome').disable();
    this.formColaborador.get('nome').setValue(data.InformacoesBasicas.NomeCompleto);
    this.loading = false;
    this.buscarTempoServico();
  }

  buscarTempoServico() {
    this.spinner.show("servico");
    this.tempoServicoService
      .buscarListaTempoServico(this.colaborador.Filial.Codigo, this.matricula)
      .subscribe(
        (data: any) => {
          this.listaTempoServico = data;
          console.log('lista serviço', this.listaTempoServico);
          this.spinner.hide("servico");
        },
        (err) => {
          console.error(err);
          this.spinner.hide("servico");
        }
      );
  }

  deletarTempoServico(servico: TempoServico) {
    Swal({
      title: 'Deseja realmente excluir esse item?',
      text: 'Essa ação não pode ser desfeita.',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
    }).then((result) => {
      this.spinner.show();
      if (result) {
        this.tempoServicoService
          .deletarTempoServico(servico.Nid, servico.Empfil, servico.Mat, servico.Numord)
          .subscribe(
            (data) => {
              console.log('deletar falta', data);
              this.buscarTempoServico();
              this.spinner.hide();
            },
            (err) => {
              console.error(err);
              this.spinner.hide()
            }
            );
      }else this.spinner.hide();
    });
  }

  downloadPdf() {
    this.spinner.show();

    this.tempoServicoService.downloadPdf(this.empfil, this.matricula, this.tipoDoc).subscribe(
      (data: any) => {
        console.log(data);

        this.spinner.hide();

        const pdf = `data:application/pdf;base64,${data.Base64}`;
        const fileName = `${data.Nome}.pdf`;

        const downloadLink = document.createElement('a');

        downloadLink.href = pdf;

        downloadLink.download = fileName;
        
        downloadLink.click();
      },
      (err) => {
        console.error(err);
        this.spinner.hide();
      }
    );
  }

  aoAbrirModal(servico?: TempoServico) {
    this.modal.limparForm();
    if (servico) {
      this.cadastroServico = false;
      this.servicoAtual = servico;
      this.modal.setarServicoExistente(servico);
    } else {
      this.cadastroServico = true;
    }
  }

  calcularDiasAtual(dataInicio: string) {
    let dataAtual = new Date();
    const ano = dataAtual.getFullYear().toString();
    let mes = (dataAtual.getMonth() + 1).toString();
    let dia = dataAtual.getDate().toString();

    mes = mes.length === 1 ? '0' + mes : mes;
    dia = dia.length === 1 ? '0' + dia : dia;

    const dataAtualString = ano + mes + dia;

    return this.tempoServicoService.calcularDias(dataInicio, dataAtualString);
  }
}
