import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AcessoService } from '../../service/acesso/acesso.service';
import {HttpHeaders, HttpClientModule ,} from '@angular/common/http';
import { LoginMigracao } from './migracao.component';

@Injectable({
  providedIn: 'root'
})
export class MigracaoService {
  headers = new HttpHeaders();
  url_acesso = this.acesso.validarUrl();

  constructor(private http: HttpClient, private acesso: AcessoService) { }

  buscarDadosMigracao(dados: LoginMigracao){
    return this.http.post(`${this.url_acesso}logins-legado/`, dados);
  }

  buscarIp() {
    return this.http.get('https://api.ipify.org?format=json');
  }

  buscarLoginCliente(dadosLogin: any, codigoCliente: string) {
    this.headers = this.headers.append('sessao',dadosLogin.Sessao);

    return this.http.post(`${this.url_acesso}logins/${dadosLogin.Usuario.CodigoUnico}/${codigoCliente}` , null ,{headers:this.headers});
  }

  public buscarOs(){
    var OSName = "Unknown";

    if (window.navigator.userAgent.indexOf("Windows")!= -1) return "Windows";
    if (window.navigator.userAgent.indexOf("Mac")    != -1) return "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11")    != -1) return "UNIX";
    if (window.navigator.userAgent.indexOf("Linux")  != -1) return "Linux";
    else return "outro";
  }

  public buscarBrowser() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'outro';
    }
  }
}
