<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div *ngIf="!telaFormulario" class="content d-flex justify-content-center align-items-center">
    <form class="login-form" #telainicial="ngForm" [formGroup]="formTelaInicial">
        <div class="card  bg-dark mb-0 text-ligth">
            <div class="card-body">
                <div class="text-center mb-3">
                    <h5 class="mb-0">Item de Menu</h5>
                </div>
                <div class="s-12 col-sm-12 col-md-12 col-lg-12 form-group">
                    <label class="justify-content-start">Serviço</label>
                    <select class="form-control" type="text" formControlName="codServico_form"
                        [ngStyle]="((temErro || formTelaInicial.controls['codServico_form'].dirty) && formTelaInicial.controls['codServico_form'].status == 'INVALID') ? {'border-color':'red'} : null">
                        <option value=""> Selecione...</option>
                        <option *ngFor="let servico of listaServicos" value="{{servico.Codigo}}"> {{servico.Descricao}}
                        </option>
                    </select>
                    <span class="form-text text-danger"
                        *ngIf="formTelaInicial.get('codServico_form').invalid && temErro">
                        Campo Obrigatório
                    </span>
                    <span class="form-text text-danger"
                        *ngIf="formTelaInicial.value.codServico_form.length > 0 && formTelaInicial.get('codServico_form').hasError('codServicoError')">
                        Código inválido
                    </span>
                </div>

                <div class=" text-center">
                    <button type="button" (click)="buscarItensMenuTb()" class="btn btn-primary">Buscar</button>
                </div>
            </div>
        </div>
    </form>
</div>


<div class="card" *ngIf="telaFormulario">
    <div class="card-header">
        <h3 class="card-title" id="target"><i class="fa fa-arrow-left pr-3 btn" aria-hidden="true"
                (click)="retornarTelaInicial()" style="cursor: pointer; bottom: 2px;"></i> Manutenção de Item do Menu
        </h3>
    </div>

    <form [formGroup]="formItemMenu" #tiposUsuario="ngForm" (ngSubmit)="submitItemMenu()">
        <div class="card-body">
            <div class="row">
                <div class="col-md-2 form-group">
                    <label class="font-weight-semibold">Código</label>
                    <input formControlName="codigo" id="codigo" type="text" mask="0*" class="form-control"
                        maxlength="15" placeholder="Código"
                        [ngStyle]="formItemMenu.get('codigo').invalid && (formItemMenu.get('codigo').dirty || temErro) ? {'border-color': 'red'} : null ">
                    <span *ngIf="formItemMenu.get('codigo').hasError('required') && temErro"
                        class="form-text text-danger">
                        Campo obrigatório
                    </span>
                    <span *ngIf="formItemMenu.get('codigo').hasError('maxlength') && temErro"
                        class="form-text text-danger">
                        Máximo de letras atingida
                    </span>

                </div>

                <div class="col-md-3 form-group">
                    <label class="font-weight-semibold">Descrição</label>
                    <div class="form-group">
                        <input formControlName="descricao" class="form-control" placeholder="Descrição" maxlength="200"
                            type="text"
                            [ngStyle]="formItemMenu.get('descricao').invalid && (formItemMenu.get('descricao').dirty || temErro) ? {'border-color': 'red'} : null ">
                        <span *ngIf="formItemMenu.get('descricao').hasError('required') && temErro"
                            class="form-text text-danger">
                            Campo obrigatório </span>
                        <span *ngIf="formItemMenu.get('descricao').hasError('maxlength') && temErro"
                            class="form-text text-danger">
                            Máximo de letras atingida </span>
                    </div>
                </div>

                <div class="col-md-4 form-group">
                    <label class="font-weight-semibold">Ícone do Menu</label>
                    <div class="form-group">
                        <input formControlName="icone" class="form-control" placeholder="Ícone" maxlength="50"
                            type="text"
                            [ngStyle]="formItemMenu.get('icone').invalid && (formItemMenu.get('icone').dirty || temErro) ? {'border-color': 'red'} : null ">
                        <span *ngIf="formItemMenu.get('icone').hasError('required') && temErro"
                            class="form-text text-danger">
                            Campo obrigatório </span>
                    </div>
                </div>

                <div class="col-md-10 col-lg-2 form-group">
                    <div class="text-center" style="white-space:nowrap">
                        <label class="font-weight-semibold">Tarefa Vinculada</label><br>
                        <button type="button" class="btn btn-secondary btn-circle" [disabled]='isPai'
                            (click)="clickTarefaVinculada(formItemMenu.value)" data-toggle="modal"
                            data-target="#configuracaoTarefa"
                            style=" width: 55px;height: 33px;text-align: center;padding: 6px 0;font-size: 14px;line-height: 1.428571429;border-radius: 16px;">
                            <i class="fa fa-sitemap fa-lg"></i>
                        </button>
                    </div>
                </div>

                <div class="col-md-2 form-group">
                    <label class="font-weight-semibold">Link</label>
                    <div class="form-group">
                        <input formControlName="link" class="form-control" placeholder="Link" maxlength="200"
                            type="text"
                            [ngStyle]="formItemMenu.get('link').invalid && (formItemMenu.get('link').dirty || temErro) ? {'border-color': 'red'} : null ">
                        <span *ngIf="formItemMenu.get('link').hasError('required') && temErro"
                            class="form-text text-danger">
                            Campo obrigatório </span>
                    </div>
                </div>

                <div class="col-md-2 form-group">
                    <div class="form-group">
                        <label class="justify-content-start">Exibir no Menu</label>
                        <select class="form-control" type="text" formControlName="exibeMenu"
                            [ngStyle]="((temErro || formItemMenu.controls['exibeMenu'].dirty) && formItemMenu.controls['exibeMenu'].status == 'INVALID') ? {'border-color':'red'} : null">
                            <option value=""> Selecione...</option>
                            <option value="true"> Sim
                            <option value="false"> Não
                            </option>
                        </select>
                        <span *ngIf="formItemMenu.get('exibeMenu').hasError('required') && temErro"
                            class="form-text text-danger">
                            Campo obrigatório </span>
                    </div>
                </div>

                <div class="col-md-10 col-lg-2 form-group">
                    <label class="justify-content-start">Item Pai ?</label>
                    <select class="form-control" type="text" formControlName="ePai" (change)="criarPai()"
                        [ngStyle]="((temErro || formItemMenu.controls['ePai'].dirty) && formItemMenu.controls['ePai'].status == 'INVALID') ? {'border-color':'red'} : null">
                        <option value=""> Selecione...</option>
                        <option value="true"> Sim
                        <option value="false"> Não
                        </option>
                    </select>
                    <span class="form-text text-danger"
                        *ngIf="temErro && formItemMenu.controls['ePai'].hasError('required')">
                        Campo obrigatório
                    </span>
                </div>

                <div class="col-md-10 col-lg-2 form-group">
                    <div class="text-center" style="white-space:nowrap">
                        <label class="font-weight-semibold">Item Pai</label><br>
                        <button (click)="clickItemPai(formItemMenu.value)" [disabled]='isItemPai' type="button"
                            class="btn btn-secondary btn-circle" data-target="#configuracaoItemMenu" data-toggle="modal"
                            data-target="#configuracaoItemMenu"
                            style=" width: 55px;height: 33px;text-align: center;padding: 6px 0;font-size: 14px;line-height: 1.428571429;border-radius: 16px;">
                            <i class="fa fa-sitemap fa-lg"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer text-right">
            <button type="button" *ngIf="formItemMenu.valid  || tarefaSelecionada != null || itemPaiSelecionado != null"
                (click)="limparCampos()" class="btn btn-danger mr-2">Limpar</button>
            <button type="submit" class="btn btn-primary"
                [disabled]="!editando && ((itemPaiSelecionado == null && !isItemPai) || formItemMenu.invalid || tarefaSelecionada == null)">Salvar</button>
        </div>
    </form>
</div>

<div class="card" *ngIf="telaFormulario">
    <div class="card-header">
        <h3 class="card-title"> Listagem de Itens do Menu</h3>
    </div>
    <div class="card-body">
        <div *ngIf="listaItensMenu && listaItensMenu.length > 0" class="input-group col-lg-12">
            <input type="text" class="form-control" name="searchText" value="" placeholder="Pesquisar Usuário"
                (keyup)='filter = $event.target'>
            <span class="input-group-append">
                <span class="input-group-text"><i class="fa fa-search"></i></span>
            </span>
        </div>
        <div class="table-responsive" style="max-height:50vh">
            <h5 class="text-center mt-3 mb-3" *ngIf="listaItensMenu.length == 0">Serviço sem tarefas vinculadas</h5>
            <table *ngIf="listaItensMenu && listaItensMenu.length > 0" class="table table-striped table-hover">
                <thead class="text-center">
                    <tr class="text-center" style="white-space:nowrap">
                        <th class="sticky-top  bg-white">Excluir/Editar</th>
                        <th class="sticky-top  bg-white">Código</th>
                        <th class="sticky-top  bg-white">Descrição</th>
                        <th class="sticky-top  bg-white">Link</th>
                        <th class="sticky-top  bg-white">Exibe no menu</th>
                        <!-- Novo campo criado por Rodrigo, aguardando implementar-->
                    </tr>
                </thead>
                <tbody id="lista-itemMenu">

                    <tr *ngFor="let item of listaItensMenu | filtrarItemMenu:filter;" class="text-center">
                        <td>
                            <div>
                                <button class="btn btn-danger mr-2" (click)="deletar(item)">
                                    <i class="fa fa-trash"></i>

                                </button>
                                <button class="btn btn-primary mr-2 " (click)="carregarItemMenu(item)">
                                    <i class="fa fa-pencil"></i>
                                </button>
                            </div>
                        </td>
                        <td>{{item.CodigoItemMenu}}</td>
                        <td>{{item.Descricao}}</td>
                        <td>{{item.Link}}</td>
                        <td>{{item.ExibirItemMenu == true? "Sim" : "Não"}}</td>
                        <!-- Novo campo criado por Rodrigo, aguardando implementar-->
                    </tr>
                </tbody>
            </table>

        </div>

        <div *ngIf="!listaItensMenu" class="row  d-flex justify-content-center align-items-center mt-3">
            <i class="fa fa-spinner fa-spin fa-2x "></i>
        </div>

    </div>
</div>

<!-- modalFilhos -->
<div class="modal fade" id="configuracaoTarefa" tabindex="-1" role="dialog" aria-labelledby="configuracaoTarefaLabel"
    data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="justify-content-start mt-2">Tarefa Pai</h5>
                <button type="button" id="closeModalTarefa" (click)="montarListaInicial()" class="close"
                    data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="col-12 table-responsive text-center">
                        <div class="row form-group text-center">
                            <table class="table table-striped table-hover mb-0 text-center">
                                <thead class="bg-teal-400">
                                    <tr class="text-center" style="white-space:nowrap">
                                        <th>Selecionar</th>
                                        <th>Código</th>
                                        <th>Nome</th>
                                        <th>Qtd.Filho(s)</th>
                                    </tr>
                                </thead>

                                <tbody *ngFor="let tarefa of listaTarefas; let i = index">
                                    <tr class="text-center" style="white-space:nowrap">
                                        <td>
                                            <div class="form-check">
                                                <input [disabled]="isPai"
                                                    (change)="selecionarTarefa(tarefa, onChange($event.target))"
                                                    class="form-check-input" value="{{tarefa.Codigo}}" type="radio"
                                                    name="tipo" id="{{tarefa.Codigo}}T">
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                </label>
                                            </div>
                                        </td>
                                        <td (click)='selecionarTipoTarefa(tarefa)'>{{tarefa.Codigo}}</td>
                                        <td (click)='selecionarTipoTarefa(tarefa)'>{{tarefa.Descricao}}</td>
                                        <td (click)='selecionarTipoTarefa(tarefa)'>{{contarFilhos(tarefa)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button *ngIf="habilitarCancelar && !habilitarVoltar"
                    (click)="montarListaInicial(); cancelarModalTarefa()" type="button" data-dismiss="modal"
                    class="btn btn-danger">Cancelar</button>
                <button *ngIf="!habilitarCancelar && habilitarVoltar" (click)="voltarNivel(listaTarefas[0])"
                    type="button" class="btn btn-danger">Voltar</button>
                <button type="button" (click)="salvarTarefaPai(formItemMenu.value)"
                    class="btn btn-primary">Salvar</button>
            </div>
        </div>
    </div>
</div>

<!-- modal Item Menu -->
<div class="modal fade" id="configuracaoItemMenu" tabindex="-1" role="dialog"
    aria-labelledby="configuracaoItemMenuLabel" data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="justify-content-start mt-2">Item Menu Pai</h5>
                <button type="button" id="closeModalItem" (click)="montarItensInicial()" class="close"
                    data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                    <div class="col-12 table-responsive text-center">
                        <div class="row form-group text-center">
                            <table class="table table-striped table-hover mb-0 text-center">
                                <thead class="bg-teal-400">
                                    <tr class="text-center" style="white-space:nowrap">
                                        <th>Selecionar</th>
                                        <th>Código</th>
                                        <th>Nome</th>
                                        <th>Qtd.Filho(s)</th>
                                    </tr>
                                </thead>

                                <tbody *ngFor="let item of listaItensReduzida; let i = index">
                                    <tr class="text-center" style="white-space:nowrap">
                                        <td>
                                            <div class="form-check">
                                                <input [disabled]="isItemPai"
                                                    (change)="selecionarItem(item, onChange($event.target))"
                                                    class="form-check-input" value="{{item.CodigoItemMenu}}"
                                                    type="radio" name="tipo" id="{{item.CodigoItemMenu}}IM">
                                                <!-- [checked]="checkRb -->
                                                <label class="form-check-label" for="flexRadioDefault1">
                                                </label>
                                            </div>
                                        </td>
                                        <td (click)='selecionarItemPai(item)'>{{item.CodigoItemMenu}}</td>
                                        <td (click)='selecionarItemPai(item)'>{{item.Descricao}}</td>
                                        <td (click)='selecionarItemPai(item)'>{{contarFilhosItem(item)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button *ngIf="habilitarCancelarItem" (click)="montarItensInicial(); cancelarModalItem()"
                    data-dismiss="modal" type="button" class="btn btn-danger">
                    Cancelar
                </button>
                <button *ngIf="habilitarVoltarItem" (click)="voltarNivelItem(listaItensReduzida[0])" type="button"
                    class="btn btn-danger">Voltar</button>
                <button type="button" (click)="salvarItemPai()" class="btn btn-primary">Salvar</button>
            </div>
        </div>
    </div>
</div>