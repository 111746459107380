import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroFuncional',
})
export class FiltroFuncionalPipe implements PipeTransform {
  transform(items: any[], buscaTexto: string): any[] {
    if (!items) {
      return [];
    }
    if (!buscaTexto) {
      return items;
    }

    buscaTexto = buscaTexto.toLowerCase();

    return items.filter((item) => {
      const nomeFuncao = item.NomeFuncao ? item.NomeFuncao.toLowerCase() : '';
      const nomeNivel = item.NomeNivel ? item.NomeNivel.toLowerCase() : '';

      return nomeFuncao.includes(buscaTexto) || nomeNivel.includes(buscaTexto);
    });
  }
}
