import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';
import { InfoColaborador } from '../cadastro-complementar/cadastro-complementar.component';
import {
  CadastroSaudeComponent,
  InformacoesSaude,
} from './cadastro-saude/cadastro-saude.component';
import { InformacoesSaudeService } from './informacoes-saude.service';
import { ErroService } from 'src/app/service/erros/erro.service';

@Component({
  selector: 'app-informacoes-saude',
  templateUrl: './informacoes-saude.component.html',
  styleUrls: ['./informacoes-saude.component.css'],
})
export class InformacoesSaudeComponent implements OnInit {
  @ViewChild(CadastroSaudeComponent, { static: false }) cadastroSaude: CadastroSaudeComponent;
  formSaude: FormGroup;
  infoColaborador: InfoColaborador;
  cadastro: boolean = true;
  listaInformacoesSaude: InformacoesSaude[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private informacoesService: InformacoesSaudeService,
    public validaErro: ErroService
  ) {}

  ngOnInit() {
    this.infoColaborador = history.state.usuario;
    if (!this.infoColaborador) {
      this.router.navigate(['/colaboradores']);
    }
    this.buscarListaSaude();
  }

  buscarListaSaude() {
    this.spinner.show();
    this.informacoesService.buscarListaInformacoesSaude(this.infoColaborador.matricula).subscribe({
      next: (data: any) => {
        this.listaInformacoesSaude = data;

        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      },
    });
  }

  editarInformacoes(informacoesSaude: InformacoesSaude): void {
    this.cadastro = false;
    this.cadastroSaude.montarInformacoesForm(informacoesSaude);
  }

  deletarInfo(info: any) {}

  abrirModal() {
    this.cadastro = true;
    this.cadastroSaude.limparForm();
    this.cadastroSaude.formSaude.markAsUntouched();
  }

  simOuNao(value: string) {
    if (value === 'true') {
      return 'Sim';
    } else {
      return 'Não';
    }
  }
}
