import { Component, Input, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { CadastroService } from 'src/app/componentes/cadastro/cadastro.service';
import { distinct } from 'rxjs/operators';
import { ErroService } from 'src/app/service/erros/erro.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert';
import { SalarioService } from './salario.service';
import { TipoUsuario } from '../../../../service/enum/tipoUsuario';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { Bancos } from 'src/app/service/interfaces/Bancos';
import { info } from 'console';
import { async } from 'rxjs';

@Component({
  selector: 'app-salario',
  templateUrl: './salario.component.html',
  styleUrls: ['./salario.component.css'],
})
export class SalarioComponent implements OnInit, OnChanges {
  @Input() dadosBancario: any;
  @Input() ColaboradorInativo: boolean;
  @Input() numeroOrdem: string;
  @Input() bancos: Bancos[];
  @Input() bancoUsuario: Bancos;

  localStorage: LocalStorage;

  formSalario: FormGroup;

  agencias: any[];
  operacoes: any[];

  codigoBanco: number | string;

  tipoUsuarioEnum: TipoUsuario;
  tipoUsuario: string;
  contaMask: string;

  displayDadosBancarios: boolean = false;
  loading: boolean = false;
  temErro: boolean = false;

  readonly bancosInfo: any[] = [
    { value: 'Banco', label: 'Banco' },
    { value: 'Agencia', label: 'Agência' },
    { value: 'Operacao', label: 'Operação' },
    { value: 'Conta', label: 'Conta' },
    { value: 'Salario', label: 'Salário' },
  ];

  readonly camposSalario = [
    { formControlName: 'banco', label: 'Banco' },
    { formControlName: 'agencia', label: 'Agência' },
    { formControlName: 'operacao', label: 'Operação' },
  ];

  readonly botoesDadosBancarios = [
    {
      label: 'Editar',
      color: 'warning',
      icon: 'fa-pencil-square-o',
      action: () => this.editarDadosBancarios(),
      show: () =>
        !this.displayDadosBancarios && !this.ColaboradorInativo && this.tipoUsuario != '1',
    },
    {
      label: 'Cancelar',
      color: 'danger',
      action: () => this.cancelarEdicao(),
      show: () => this.displayDadosBancarios,
    },
    {
      label: 'Salvar',
      color: 'primary',
      action: () => this.submitSalario(),
      show: () => this.displayDadosBancarios,
    },
  ];

  constructor(
    private cadastroService: CadastroService,
    private validaErro: ErroService,
    private fb: FormBuilder,
    private salarioService: SalarioService
  ) {}

  ngOnInit(): void {
    this.localStorage = this.decryptLocalStorage();
    this.tipoUsuario = this.localStorage.TipoUsuario;
    this.formSalario = this.fb.group({
      banco: ['', [Validators.required, Validators.minLength(2)]],
      agencia: ['', Validators.required],
      operacao: ['', Validators.required],
      conta: ['', [Validators.required, Validators.maxLength(15)]],
      salario: [''],
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dadosBancario'] != undefined && this.dadosBancario.Banco) {
      this.dadosBancario = changes['dadosBancario'].currentValue;
    }
  }

  campoData(fieldName: string): any[] {
    switch (fieldName) {
      case 'banco':
        return this.bancos;
      case 'agencia':
        return this.agencias;
      case 'operacao':
        return this.operacoes;
    }
  }

  validacaoRegex(value: string) {
    const regex = /^0+$/; //Valida se a string tem apenas zeros
    const validacaoRegex = regex.test(value);
    return validacaoRegex;
  }

  visualizacaoValoresDadosBancarios(info) {
    const value = this.dadosBancario[info.value];
    const validacaoRegex = this.validacaoRegex(value);
    if (!value || (info.value !== 'Salario' && validacaoRegex)) {
      return 'Não informado';
    }

    if (info.value === 'Banco') {
      return this.bancoUsuario.Nome;
    }

    if (info.value === 'Salario') {
      return this.formatarSalario(value);
    }

    return value;
  }

  editarDadosBancarios() {
    this.displayDadosBancarios = true;
    this.formSalario.get('banco').setValue(this.dadosBancario.Banco);
    this.carregarOpAg(this.bancoUsuario.Nome);
    this.carregarCampos();
  }

  cancelarEdicao() {
    this.displayDadosBancarios = false;
    this.temErro = false;
    this.agencias = null;
    this.operacoes = null;
    this.formSalario.markAsUntouched();
  }

  mask(event) {
    const { conta } = this.formSalario.value;

    if (conta.length >= 6) {
      if (conta.length == 14) {
        this.contaMask = '0'.repeat(14) + '-0';
      } else {
        this.contaMask = '0'.repeat(conta.length) + '-0';
      }
    } else {
      this.contaMask = '00000-0';
    }
  }

  buscarBanco() {
    this.loading = true;
    this.salarioService
      .buscarBancos()
      .pipe(distinct())
      .subscribe(
        (data) => {
          this.bancos = JSON.parse(JSON.stringify(data));

          const bancoEscolhido = this.bancos.filter(
            (banco) => banco.Codigo == this.dadosBancario.Banco
          );
          this.bancoUsuario = bancoEscolhido[0];
          this.carregarOpAg(this.bancoUsuario.Nome);
          this.loading = false;
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.loading = false;
        }
      );
  }

  carregarCampos() {
    const { Banco, Agencia, Operacao, Conta, Salario } = this.dadosBancario;
    console.log(this.dadosBancario);
    this.formSalario.get('banco').setValue(this.validacaoRegex(Banco) ? '0' : Banco);
    this.formSalario.get('agencia').setValue(this.validacaoRegex(Agencia) ? '0' : Agencia);
    this.formSalario.get('operacao').setValue(this.validacaoRegex(Operacao) ? '0' : Operacao);
    this.formSalario.get('conta').setValue(this.validacaoRegex(Conta) ? '' : Conta);

    // Formatar o valor do salario e atribuir ao campo
    const salarioString = Salario.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const salarioFormatado = salarioString.replace(/\./g, '').replace(',', '.');

    this.formSalario.get('salario').setValue(salarioFormatado);
    this.formSalario.get('salario').disable();
  }

  mudancaBancos(eventoMudanca) {
    this.codigoBanco = eventoMudanca.target.value;
    console.log(this.codigoBanco);
    if (this.codigoBanco == 0) {
      this.formSalario.get('agencia').setValue('0');
      this.formSalario.get('operacao').setValue('0');
      this.formSalario.get('conta').setValue('');
      this.formSalario.get('agencia').disable();
      this.formSalario.get('operacao').disable();
      this.formSalario.get('conta').disable();
    } else {
      console.log('codigo diferente de 0', this.codigoBanco);
      const bancoEscolhido = this.bancos.filter((banco) => banco.Codigo == this.codigoBanco);
      this.bancoUsuario = bancoEscolhido[0];
      this.carregarOpAg(this.bancoUsuario.Nome);
    }
  }

  carregarOpAg(nomeBanco) {
    this.agencias = [];
    this.operacoes = [];
    this.cadastroService
      .buscarAgencia(nomeBanco)
      .pipe(distinct())
      .subscribe(
        (data) => {
          this.agencias = JSON.parse(JSON.stringify(data));
        },
        (error) => {
          this.validaErro.retornoErro(error);
        }
      );

    this.cadastroService
      .buscarOperacoes(nomeBanco)
      .pipe(distinct())
      .subscribe(
        (data) => {
          this.operacoes = JSON.parse(JSON.stringify(data));
          console.log(this.operacoes);
        },
        (error) => {
          this.validaErro.retornoErro(error);
        }
      );

    if (this.formSalario.get('banco').value != '0') {
      this.formSalario.get('agencia').enable();
      this.formSalario.get('operacao').enable();
      this.formSalario.get('conta').enable();
      if (
        this.formSalario.get('banco').value == this.dadosBancario.Banco &&
        (this.dadosBancario.Agencia != 0 ||
          this.dadosBancario.Operacao != 0 ||
          this.dadosBancario.Conta != 0)
      ) {
        this.formSalario.get('agencia').setValue(this.dadosBancario.Agencia);
        this.formSalario.get('operacao').setValue(this.dadosBancario.Operacao);
        this.formSalario.get('conta').setValue(this.dadosBancario.Conta);
      }
    } else {
      this.formSalario.get('agencia').disable();
      this.formSalario.get('operacao').disable();
      this.formSalario.get('conta').disable();
    }
  }

  async submitSalario() {
    try {
      if (
        !this.formSalario.valid ||
        this.formSalario.get('operacao').value == '0' ||
        this.formSalario.get('agencia').value == '0'
      ) {
        Swal('Atenção', 'Todos os campos são obrigatórios', 'warning');
        this.temErro = true;
      } else {
        this.SalvarSalario();
        this.temErro = false;
      }
    } catch (err) {
      this.validaErro.retornoErro(err);
    }
  }

  SalvarSalario() {
    this.loading = true;

    const banco = this.formSalario.value;

    this.salarioService.alterarBanco(banco, this.numeroOrdem).subscribe(
      (data) => {
        this.displayDadosBancarios = false;
        Swal('Sucesso', 'O cadastro foi alterado', 'success');
        this.loading = false;
        if (banco) {
          this.dadosBancario.Agencia = banco.agencia;
          this.dadosBancario.Operacao = banco.operacao;
          this.dadosBancario.Conta = banco.conta;
          this.dadosBancario.Banco = banco.banco;
        }
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.loading = false;
      }
    );
  }

  formatarSalario(numeroString) {
    const numero = parseFloat(numeroString).toFixed(2);
    const partes = numero.split('.');
    const parteInteira = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const parteDecimal = partes[1];

    return 'R$' + parteInteira + ',' + parteDecimal;
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}
