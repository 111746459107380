import { Component, Input, SimpleChanges } from '@angular/core';
import { PromocoesService } from '../promocoes.service';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Promocao, PromocoesComponent } from '../promocoes.component';
import { ErroService } from 'src/app/service/erros/erro.service';
import Swal from 'sweetalert';
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';




export interface Item {
  Cod: string;
  Nome: string;
  
}

@Component({
  selector: 'app-promocoes-cadastro',
  templateUrl: './promocoes-cadastro.component.html',
  styleUrls: ['./promocoes-cadastro.component.css']
})
export class PromocoesCadastroComponent {
  
  
  @Input() cadastro: boolean = true;
  @Input() colaborador: Cadastro = null;
  @Input() promocaoAtual: Promocao = null;
  promocaoindex: number = null
  formPromocoes: FormGroup;
  listaMotivos: Item[] = [];
  listaPromocoes: Item[] = [];
  listaClassificacaoAto: Item[] = [];
  listaCargosFuncoes: any[] = [];
  listaNiveisCargo: any[] = [];
  listaObservacoes: any[] = [];
  Seq: number = null;
  listaFuncoes: any[] = [];
  listaCargos: any[] = [];

  

  constructor(
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private validaErro: ErroService,
    private promocoesService: PromocoesService,
    private promocaoComponent: PromocoesComponent
  ) 
  { 
    
    this.formPromocoes = this.fb.group({
    Sigla: [''],
    Empfil: [null],
    Mat: [null],
    DtInicio: [Validators.required],
    CodFun: [null],
    CodCar: [null],
    CodNiv: [{ value: null, disabled: true }],
    Observacao: [null, Validators.required],
    DescCarFun: [''],
    NumeroProcesso: [''],
    NumeroDOE: [''],
    IdClassificacaoAto: [null],
    AtoPro: [''],
    AnoAto: [''],
    DtAto: [''],
    AnoDoe: [''],
    DtDoe: [''],
    Mudanca: [null],
    DtComp: [''],
    NumeroDoe: [''],
    NumeroAto: ['']
  }, { validators: requireOneOf(['CodFun', 'CodCar']) }); 
}

  
  
  ngOnInit() {
   console.log("formulario promocoes",this.formPromocoes)
  }
  
  ngOnChanges(changes: SimpleChanges) {
    console.log("formulario promocoes ng changes",this.formPromocoes)
    
    if (changes.colaborador && changes.colaborador.currentValue) {
      const colaboradorAtualizado = changes.colaborador.currentValue;
      
      if (colaboradorAtualizado.InformacoesBasicas && colaboradorAtualizado.InformacoesBasicas.Vinculo) {
        console.log('Colaborador mudou', colaboradorAtualizado);
        const grupo = String(colaboradorAtualizado.InformacoesBasicas.Vinculo.Grupo);
        
        
        this.carregarDadosAuxiliares(grupo);
      }
    }
  }

  carregarDadosAuxiliares(grupo: string) {
    this.buscarIdClassificacaoAto();
    this.buscarObservacoes();
    console.log('Grupo do colaborador:', grupo);
    this.buscarCargosFuncoes(Number(grupo));
  }
  

  
  filtrarCargosFuncoes() {
    this.listaFuncoes = this.listaCargosFuncoes.filter(cargoFuncao => cargoFuncao.Grupo === 2);
    this.listaCargos = this.listaCargosFuncoes.filter(cargoFuncao => cargoFuncao.Grupo === 1);
  }

  buscarCargosFuncoes(grupo: number) {
    const matricula = this.colaborador.Matricula;
    const empfil = this.colaborador.Filial.Codigo;
  
    this.promocoesService.buscarCargosFuncoes(+matricula, empfil).subscribe(
      (data: any[]) => {
        this.listaCargosFuncoes = data;
        this.filtrarCargosFuncoes(); 
        this.spinner.hide();
      },
      (err) => {
        console.error('Erro ao buscar cargos/funções:', err);
        this.spinner.hide();
      }
    );
  }
  

  buscarNiveisCargo(CodFun: string) {
    console.log('buscarNiveisCargo', typeof CodFun);
    console.log('lista cargos', this.listaCargosFuncoes);
    const matricula = this.colaborador;
    const empfil = this.colaborador.Filial.Codigo;

    const control = this.formPromocoes.get('CodNiv');
    const descCarFunControl = this.formPromocoes.get('DescCarFun');

    if (CodFun == 'null') {
      control.setValue(null);
      control.disable();
      descCarFunControl.setValue('');
      return;
    } else {
      control.enable();
    }


    this.promocoesService.buscarNiveisCargo(CodFun).subscribe(
      (data: any[]) => {
        this.listaNiveisCargo = data;
        console.log(this.listaNiveisCargo);
        this.spinner.hide();
      },
      (err) => {
        console.error('Erro ao buscar níveis de cargo:', err);
        this.spinner.hide();
      }
    );
  }





  buscarIdClassificacaoAto() {
    this.listaClassificacaoAto = this.promocoesService.retornarLista('classificacao');
    console.log(this.listaClassificacaoAto)
    if (this.listaPromocoes.length == 0) {
      this.spinner.show();
      this.promocoesService.buscarIdClassificacaoAto().subscribe(
        (data: Item[]) => {
          this.listaClassificacaoAto = data;
          this.promocoesService.inserirLista('classificacao', this.listaClassificacaoAto);
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.validaErro.retornoErro(err);
        }
      );
    }
  }




  montarPromocao() {


    let promocao = {

        Sigla: 'FAPP',
        Mat: this.colaborador.Matricula,
        Empfil: this.colaborador.Filial.Codigo,
        DtInicio: this.formPromocoes.get('DtInicio').value?.replace(/-/g, '') ?? '',
        CodFun: this.formPromocoes.get('CodFun').value ?? '',
        CodCar: this.formPromocoes.get('CodCar').value ?? '',
        CodNiv: this.formPromocoes.get('CodNiv').value ?? '',
        Observacao: this.formPromocoes.get('Observacao').value ?? '',
        DescCarFun: this.formPromocoes.get('DescCarFun').value ?? '',
        NumeroProcesso: this.formPromocoes.get('NumeroProcesso').value ?? '',
        NumeroDoe: this.formPromocoes.get('NumeroDoe').value ?? '',
        IdClassificacaoAto: parseInt(this.formPromocoes.get('IdClassificacaoAto').value) ?? null,
        AtoPro: this.formPromocoes.get('AtoPro').value ?? '',
        AnoAto: this.formPromocoes.get('DtAto').value?.substring(0, 4) ?? '',
        DtAto: this.formPromocoes.get('DtAto').value
          ? {
              SetData: this.formPromocoes.get('DtAto').value.replace(/-/g, ''),
              ToDate: this.promocoesService.formatarDataBarra(
                this.formPromocoes.get('DtAto').value.replace(/-/g, '')
              ),
              Empty: false,
              IsDate: true,
            }
          : '00000000',
        AnoDoe: this.formPromocoes.get('DtDoe').value?.substring(0, 4) ?? '',
        DtDoe: this.formPromocoes.get('DtDoe').value
          ? {
              SetData: this.formPromocoes.get('DtDoe').value.replace(/-/g, ''),
              ToDate: this.promocoesService.formatarDataBarra(
                this.formPromocoes.get('DtDoe').value.replace(/-/g, '')
              ),
              Empty: false,
              IsDate: true,
            }
          : '00000000',
        Mudanca: '',
        DtComp: this.formPromocoes.get('DtComp').value?.replace(/-/g, '') ?? '',
        Seq: this.Seq ?? 0,
    };

    

    return { Promocao: promocao };
}






  lancarPromocao() {
    this.spinner.show();
    this.formPromocoes.markAllAsTouched();
    let botaoFechar = document.getElementById('botaoFechar');
    console.log('payload form puro', this.formPromocoes);
    if (this.formPromocoes.valid) {
      let promocaoLancar = this.montarPromocao().Promocao;
      console.log('payload', promocaoLancar);

      if (this.promocaoindex !== null && this.promocaoindex !== undefined) {

        this.promocoesService.atualizarPromocao(promocaoLancar).subscribe(
          (data) => {
            Swal('Sucesso', 'Promoção atualizada com sucesso.', 'success');
            this.promocaoComponent.buscarPromocoes();
            if (botaoFechar) botaoFechar.click();
            console.log('atualizado', data)
            this.spinner.hide();
          },
          (err: HttpErrorResponse) => {
            if (err.status === 500) {
              Swal('Erro', 'Não foi possível atualizar essa Promoção.', 'error');
            } else {
              const errorMessage = err.error?.Mensagem || 'Ocorreu um erro ao atualizar essa Promoção.';
              Swal('Erro', errorMessage, 'error');
            }
            this.spinner.hide();
          }
        );
      } else {

        this.promocoesService.inserirPromocao(promocaoLancar).subscribe(
          (data) => {
            Swal('Sucesso', 'Promoção cadastrada com sucesso.', 'success');
            this.promocaoComponent.buscarPromocoes();
            if (botaoFechar) botaoFechar.click();
            this.spinner.hide();
          },
          (err: HttpErrorResponse) => {
            if (err.status === 500) {
              Swal('Erro', 'Não foi possível inserir essa Promoção.', 'error');
            } else {
              const errorMessage = err.error?.Mensagem || 'Ocorreu um erro ao atualizar essa Promoção.';
              Swal('Erro', errorMessage, 'error');
            }
            this.spinner.hide();
          }
        );
      }
    } else {
      Swal('Atenção', 'Por favor, preencha todos os campos corretamente.', 'warning');
      this.spinner.hide();
    }
  }



  buscarObservacoes() {
    this.promocoesService.buscarObservacoes().subscribe(
      (data: Item[]) => {
        this.listaObservacoes = data;
      },
      (err) => {
        console.error('Erro ao buscar observações:', err);
      }
    );
  }



}

function requireOneOf(fields: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup;
    const isAtLeastOneFilled = fields.some(fieldName => {
      const fieldValue = formGroup.get(fieldName)?.value;
      return fieldValue !== null && fieldValue !== '';
    });

    return isAtLeastOneFilled ? null : { requireOneOf: true };
  };
}