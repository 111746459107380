import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function validarDataMaiorOuIgual(dataInicio?): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const dataCampo = control.value; // Valor do campo a ser validado
    const dataCampoFormatada = dataCampo.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2-$3-$1');
    const dataInicioFormatada = dataInicio?.replace(/(\d{4})-(\d{2})-(\d{2})/, '$2-$3-$1');
    const dataAtual = new Date(); // Data atual
    dataAtual.setHours(0,0,0,0);

    // console.log('data atual', dataAtual);
    if (!dataCampo) {
      return null;
    }

    const dataCampoObj = new Date(dataCampoFormatada);

    // dataCampoObj.setHours(23);
    // dataCampoObj.setMinutes(59);
    // dataCampoObj.setSeconds(59);

    if (dataInicio) {
      const dataInicioObj = new Date(dataInicioFormatada);
      // dataInicioObj.setHours(23);
      // dataInicioObj.setMinutes(59);
      // dataInicioObj.setSeconds(59);
      if (dataCampoObj > dataInicioObj) {
        return null;
      } else {
        return { dataMenorQueInicio: true };
      }
    } else {
      // console.log('data campo', dataCampoObj);
      if (dataCampoObj < dataAtual) {
        return { dataMenorQueAtual: true };
      } else {
        return null;
      }
    }
  };
}
