import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root'
})
export class ControlePontoService {
  url_acesso: string;
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any;
  filial: string;

  constructor(private httpClient: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.url_acesso = this.acesso.validarUrl();
    }
  }

  buscarFluxo() {
    return this.httpClient.get(`${this.url_acesso}lancamento-fluxo/${this.filial}`, { headers: this.cabecalho })
  }

  buscarFluxoPorMatricula(matricula) {
    return this.httpClient.get(`${this.url_acesso}lancamento-fluxo/${this.filial}/${matricula}`, { headers: this.cabecalho })
  }

  buscarColabSupervisor() {
    return this.httpClient.get(`${this.url_acesso}lancamento-fluxo/supervisor/${this.filial}`, { headers: this.cabecalho })
  }

  buscarSituacao(matricula) {
    return this.httpClient.get(`${this.url_acesso}lancamento-fluxo/situacao/${this.filial}/${matricula}`, { headers: this.cabecalho })
  }

  buscarSetor() {
    return this.httpClient.get(`${this.url_acesso}lancamento-fluxo/setores/${this.filial}`, { headers: this.cabecalho })
  }

  montarPdf(matricula) {
    return this.httpClient.get(`${this.url_acesso}lancamento-fluxo/pdf/${this.filial}/${matricula}`, { headers: this.cabecalho })
  }

  EnviarFluxo(listaFluxos) {
    return this.httpClient.post(`${this.url_acesso}lancamento-fluxo/${this.filial}`, listaFluxos, { headers: this.cabecalho })
  }

  guardarFluxo(listaFluxos) {
    return this.httpClient.put(`${this.url_acesso}lancamento-fluxo/${this.filial}`, listaFluxos, { headers: this.cabecalho })
  }

  EnviarMensagemSituacao(valor) {
    return this.httpClient.put(`${this.url_acesso}lancamento-fluxo/situacao/${this.filial}`, valor, { headers: this.cabecalho })
  }

  verificarAssinatura() {
    return this.httpClient.get(`${this.url_acesso}lancamento-fluxo/assinatura/validacao/${this.filial}`, { headers: this.cabecalho })
  }

  getDate() {
    return this.httpClient.get(`${this.url_acesso}getdatenow`, { headers: this.cabecalho })
  }
}
