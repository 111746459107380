import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HomePageService } from 'src/app/componentes/home-page/home-page.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ErroService } from 'src/app/service/erros/erro.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Data } from 'src/app/service/interfaces/Data';
import { setTimeout } from 'timers';
import Swal from 'sweetalert';
import { ControlePontoService } from 'src/app/componentes/controle-ponto/controle-ponto.service';
import { ExcelService } from 'src/app/service/arquivo/excel.service';
import { Anexo, Arquivo, Ponto, SituacaoFluxo } from '../controle-ponto';
import { AssinaturaAnexoService } from '../../assinatura-anexo/assinatura-anexo.service';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { ServerDate } from 'src/app/service/date/serverdate.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import * as CryptoJS from 'crypto-js';
import * as Moment from 'src/assets/js/plugins/ui/moment/moment.min'
import * as DateRange from 'src/assets/js/plugins/pickers/pickadate/picker';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';

export class FrenchDateProvider extends NativeDateAdapter {

  parse(value: string) {
    let it = value.split('/');
    if (it.length == 3) {
      return new Date(+it[2], +it[1] - 1, +it[0], 12);
    }
  }

  format(date: Date, displayFormat: Object) {
    return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  }

}

@Component({
  selector: 'app-controle-colaborador',
  templateUrl: './controle-colaborador.component.html',
  styleUrls: ['./controle-colaborador.component.css'],


  // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
  // `MatMomentDateModule` in your applications root module. We provide it at the component level
  // here, due to limitations of our example generation script
})
export class ControleColaboradorComponent implements OnInit {
  @Input() perfil: boolean;
  @Output() arquivoenviado = new EventEmitter<Arquivo>();

  searchText: string;
  FormDinamiocoFluxo: FormGroup;
  FormData: FormGroup;
  encrypted: any = "";
  localStorage: LocalStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));;

  listaPontosEditada: Ponto[] = [];
  listarPontos: Ponto[] = [];
  listaSituacao: SituacaoFluxo[] = []

  data: Data = new Data();
  temErro: boolean = false;
  editando = false;
  enviado = false;
  editavel: boolean = false;
  temAssinatura: boolean = false;

  situacaoFluxo: SituacaoFluxo
  tela = 1;
  getMatricula: any = 20;
  getSituacao: any;
  getMes: any;
  etapa: any;
  btnSitucao: number;
  getMensagem: string = "";
  mensagem: string = "";
  formatDate: string = "";
  erro: boolean = false;

  //variaveis modal assinatura
  formAnexo: FormGroup;
  formData: FormGroup;

  listaArquivos: Arquivo[] = [];
  listaTipoArquivo: any[] = [];
  meses: string[] =
    ['Janeiro', 'Fevereiro', 'Março',
      'Abril', 'Maio', 'junho',
      'Julho', 'Agosto', 'Setembro',
      'Outrubro', 'Novembro', 'Dezembro'];
  listaAnexo: Anexo[] = [];
  ListaArquivos: Arquivo[] = [];
  arquivosEnviadoSessaoAtual: Anexo[] = [];

  selectedFile: Set<File>;

  loading: boolean = false;
  editado: boolean = false;

  ArquivoSelecionadoAtual: Anexo;
  /*empfil: string = localStorage.getItem('filial_atual');
  NumeroDeOrdem: string = localStorage.getItem('numOrd');
  matricula: string = localStorage.getItem('matricula_usuario');
  TipoUsuario: number = Number(localStorage.getItem('tipoUsuario'));*/
  numDocDisponivel: number;
  indexSelecionado: number = undefined;

  constructor(private serverDate: ServerDate, private datepipe: DatePipe, private dateAdapter: DateAdapter<any>, private fb: FormBuilder, private assinaturaService: AssinaturaAnexoService, private controlePontoService: ControlePontoService, private home_service: HomePageService, private spinner: NgxSpinnerService, private validaErro: ErroService, private excelService: ExcelService) {
    this.dateAdapter.setLocale('pt-BR');
  }

  ngOnInit() {
    //this.encrypted = this.encryptUsingAES256();
    /*this.formData = this.fb.group({
      dataTeste: ['', [Validators.required]]
    });*/
    this.FormDinamiocoFluxo = this.fb.group({
      fluxos: new FormArray([]),
      matricula: [""],
      rejeicao: [""],
      preencher: [""]
    });

    this.formAnexo = this.fb.group({
      arquivos: [''],
      arquivoselecionado: [''],
      string64: [''],
      descricao: [''],
      tipoArquivo: ['foto']
    });


    this.FormData = this.fb.group({
      data: [''],
      hora: ['']
    })

    //"01/05/2022"
    //"01/05/2022 00:00"
    //"18/08/2022 - 23/08/2022"
    //"18/08/2022 00:00 - 23/08/2022 00:00"

    this.getDate();
    this.validarAssinatura()
    this.validacaoTela();
    this.chamarBuscarFluxo()
  }

  tokenFromUI: string = "EloCrypto1.0";

  request: string = "Carlos";


  onBlur() {

   /* var fullDate = (document.getElementById("dateTest") as HTMLInputElement).value;*/
    /*if (fullDate) {
      this.erro = true;
      this.formData.get('dataTeste').setValidators(Validators.required);
      this.formData.get('dataTeste').updateValueAndValidity();
    } else {
      this.erro = false;
      this.formData.get('dataTeste').clearValidators();
      this.formData.get('dataTeste').updateValueAndValidity();
    }*/
  }

  enviarData() {
    var fullDate = (document.getElementById("dateTest") as HTMLInputElement).value;
    if (fullDate) {
      this.erro = false;
      this.formData.get('dataTeste').clearValidators();
      this.formData.get('dataTeste').updateValueAndValidity();
    } else {
      this.erro = true;
      this.formData.get('dataTeste').setValidators(Validators.required);
      this.formData.get('dataTeste').updateValueAndValidity();
    }
  }

  encryptUsingAES256(): string {
    return CryptoJS.AES.encrypt(
      JSON.stringify(this.request), CryptoJS.enc.Utf8.parse(this.tokenFromUI), {
      keySize: 12,
      iv: CryptoJS.enc.Utf8.parse(this.tokenFromUI),
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
  }

  decryptUsingAES256(chave: string): string {
    return CryptoJS.AES.decrypt(
      chave, CryptoJS.enc.Utf8.parse(this.tokenFromUI), {
      keySize: 12,
      iv: CryptoJS.enc.Utf8.parse(this.tokenFromUI),
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
  }

  /*encryptUsingAES256(msg: string): string {
    var decrypted: string;

    var request: string;
    var responce: string;
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(request), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  decryptUsingAES256(encrypted: string): string {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let decrypted: string;
    decrypted = CryptoJS.AES.decrypt(
      encrypted, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }*/

  getDate() {
    //const envrypted = AES.encrypt('Hello All', 'MYKEY4DEMO').toString();
    /*this.controlePontoService.getDate().subscribe(
      (data: any) => {
      },
      error => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        this.spinner.hide();
      }
    )*/
    this.serverDate.getDateNow().subscribe(
      (data: any) => {
        
      },
      error => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
      }
    );
    /*this.serverDate.getDateNow().subscribe(
      (data: any) => {
      },
      error => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
      }
    );*/
    /*
    //this.decryptUsingAES256();
    this.formatDate = this.datepipe.transform(new Date(), 'dd/MM/yyyy') + ' - ' + this.datepipe.transform(new Date(new Date().setDate(new Date().getDate() + 5)), 'dd/MM/yyyy');*/
  }



  get f() { return this.FormDinamiocoFluxo.controls; }
  get formFluxo() { return this.f.fluxos as FormArray; }

  //cosntruir formDinamico
  editarLista() {
    const numeroFluxos = this.listarPontos.length;
    if (this.formFluxo.length < numeroFluxos) {
      for (let i = this.formFluxo.length; i < numeroFluxos; i++) {
        this.formFluxo.push(this.fb.group({
          Dia: [this.listarPontos[i].Dia, [Validators.required]],
          Entrada: [this.formatarHora(this.listarPontos[i].Entrada), [Validators.required, Validators.maxLength(5), Validators.minLength(4)]],
          InicioIntervalo: [this.formatarHora(this.listarPontos[i].InicioIntervalo), [Validators.required, Validators.maxLength(5), Validators.minLength(4)]],
          FimIntervalo: [this.formatarHora(this.listarPontos[i].FimIntervalo), [Validators.required, , Validators.maxLength(5), Validators.minLength(4)]],
          Saida: [this.formatarHora(this.listarPontos[i].Saida), [Validators.required, Validators.maxLength(5), Validators.minLength(4)]],
          HoraExtra: [this.formatarHora(this.listarPontos[i].HoraExtra), [Validators.required, , Validators.maxLength(5), Validators.minLength(4)]],
        }));
      }
    } else {
      for (let i = this.formFluxo.length; i >= numeroFluxos; i--) {
        this.formFluxo.removeAt(i);
      }
    }
    this.editando = true;
  }

  buscarFluxos() {
    this.spinner.show();
    this.controlePontoService.buscarFluxo().subscribe(
      (data: Ponto[]) => {
        this.listarPontos = data
        this.getMatricula = data[0].Matricula;
        this.getMensagem = data[0].Mensagem;
        this.getSituacao = data[0].Situacao;
        this.getMes = data[0].MesDoFluxo;
        this.spinner.hide();
        this.buscarSituacao(data[0].Matricula);
      },
      error => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        this.spinner.hide();
      }
    )
  }

  buscarFluxosPorMatricula(matricula) {
    this.spinner.show();
    this.controlePontoService.buscarFluxoPorMatricula(matricula).subscribe(
      (data: Ponto[]) => {
        this.listarPontos = data;
        this.getMatricula = data[0].Matricula;
        this.getMensagem = data[0].Mensagem;
        this.getSituacao = data[0].Situacao;
        this.getMes = data[0].MesDoFluxo;
        this.spinner.hide();
        this.buscarSituacao(data[0].Matricula);
      },
      error => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        this.spinner.hide();
      }
    )
  }

  buscarSituacao(matricula) {
    this.spinner.show();
    this.controlePontoService.buscarSituacao(matricula).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.situacaoFluxo = data;
        this.mensagem = data.Mensagem;
        this.editavel = data.Editavel;
        this.etapa = data.Etapa;
        if (this.tela == 1) this.mensagemRejeicao();
      },
      error => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        this.spinner.hide();
      }
    )
  }

  validarAssinatura() {
    this.controlePontoService.verificarAssinatura().subscribe(
      data => {
        if (data == false) {
          document.getElementById("ativarValidacao").click()
        }
      },
      error => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        this.spinner.hide();
      }
    )
  }

  submitSituacao(situacao, etapa) {
    this.spinner.show();
    this.controlePontoService.EnviarMensagemSituacao(this.montarSituacao(situacao, etapa)).subscribe(
      data => {
        this.spinner.hide();
        this.etapa = etapa;
      },
      error => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        this.spinner.hide();
      }
    )
  }

  submitFluxo(listaPonto) {
    this.spinner.show();
    this.controlePontoService.EnviarFluxo(listaPonto).subscribe(
      data => {
        this.spinner.hide();
        this.submitSituacao(1, 2);
        this.editavel = false;
      },
      error => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        this.spinner.hide();
      }
    )
  }

  guardarFluxo() {
    this.montarLista();
    this.spinner.show();
    this.controlePontoService.guardarFluxo(this.listaPontosEditada).subscribe(
      data => {
        this.spinner.hide();
        this.editavel = true;
      },
      error => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        this.spinner.hide();
      }
    )
  }

  salvarEdicaoFluxoRh() {
    this.montarLista();
    this.spinner.show();
    this.controlePontoService.EnviarFluxo(this.listaPontosEditada).subscribe(
      data => {
        this.spinner.hide();
        this.submitSituacao(3, 3);
        this.editavel = true;
        new AlertaModal("alerta","Sucesso!", "Operação realizada com sucesso.", "p");
      },
      error => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        this.spinner.hide();
      }
    )
  }

  enviarLista() {
    Swal({
      title: "Você tem certeza?",
      text: "Esta operação não pode ser desfeita.",
      icon: "info",
      dangerMode: false,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true
        }
      }
    }).then((willDelete) => {
      if (willDelete) {
        this.montarLista();
        if (this.editando) {
          this.submitFluxo(this.listaPontosEditada);
          this.fecharModal("fecharModalEnviar");
        }
        else this.submitFluxo(this.listarPontos);
      }
    });
  }

  validacaoTela() {
    if (this.tela == 1) {
    }
    else if (this.tela == 2) {
      this.FormDinamiocoFluxo.get('rejeicao').setValidators([Validators.required, Validators.minLength(5)]);
      this.FormDinamiocoFluxo.get('matricula').setValidators(Validators.required);
    }
    else if (this.tela == 3) {
      this.FormDinamiocoFluxo.get('rejeicao').setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(100)]);
      this.FormDinamiocoFluxo.get('matricula').setValidators(Validators.required);
    }
  }

  montarLista() {
    if (this.editando && this.formFluxo.valid) {
      this.listaPontosEditada = this.FormDinamiocoFluxo.controls.fluxos.value;
      for (let i = 0; i < this.listaPontosEditada.length; i++) {
        this.listaPontosEditada[i].Entrada = this.formatarHora(this.listaPontosEditada[i].Entrada);
        this.listaPontosEditada[i].Saida = this.formatarHora(this.listaPontosEditada[i].Saida);
        this.listaPontosEditada[i].InicioIntervalo = this.formatarHora(this.listaPontosEditada[i].InicioIntervalo);
        this.listaPontosEditada[i].FimIntervalo = this.formatarHora(this.listaPontosEditada[i].FimIntervalo);
        this.listaPontosEditada[i].HoraExtra = this.formatarHora(this.listaPontosEditada[i].HoraExtra);
        this.listaPontosEditada[i].Situacao = this.getSituacao;
        this.listaPontosEditada[i].Matricula = this.getMatricula;
        this.listaPontosEditada[i].Mensagem = this.getMensagem;
        this.listaPontosEditada[i].MesDoFluxo = this.getMes;
      }

      this.listarPontos = this.listaPontosEditada;
      this.editando = false;

      new AlertaModal('sucesso', "Sucesso!", "Operação realizada com sucesso", "p");

    } else if (!this.editando && this.formFluxo.valid) {

      new AlertaModal('sucesso', "Sucesso!", "Operação realizada com sucesso", "p");

    } else {

      new AlertaModal('alerta',"Atenção!", "Preencha todos os fluxos", "p");

    }
    this.enviado = true;
  }

  montarSituacao(codSituacao, etapa,): any {
    return {
      Codigo: codSituacao,
      Matricula: this.getMatricula,
      Mensagem: codSituacao == 2 ? this.FormDinamiocoFluxo.get('rejeicao').value : "",
      Etapa: etapa,
      Editavel: codSituacao == 2 || codSituacao == 0 ? true : false,
    }
  }

  pesquisarPorColaborador() {
    if (this.FormDinamiocoFluxo.get('matricula').invalid) {
      new AlertaModal('alerta',"Atenção!", "Preencha corretamente o campo 'Matricula do Funcionário'", "p");
      this.temErro = true;
    }
    else {
      this.buscarFluxosPorMatricula(Number(this.FormDinamiocoFluxo.get('matricula').value));
      this.buscarSituacao(Number(this.FormDinamiocoFluxo.get('matricula').value));
      this.temErro = false;
    }
  }

  pesquisarColaborador(matricula: string) {
    this.buscarFluxosPorMatricula(matricula)
  }

  preencherAutomatico() {
    if (this.FormDinamiocoFluxo.get("preencher").value == 1) {
      for (let i = 0; i < this.formFluxo.length; i++) {
        this.formFluxo.controls[i].get("Entrada").setValue("08:00");
        this.formFluxo.controls[i].get("InicioIntervalo").setValue("12:00");
        this.formFluxo.controls[i].get("FimIntervalo").setValue("13:00");
        this.formFluxo.controls[i].get("Saida").setValue("17:00");
      }
    }
    else if (this.FormDinamiocoFluxo.get("preencher").value == 2) {
      for (let i = 0; i < this.formFluxo.length; i++) {
        this.formFluxo.controls[i].get("Entrada").setValue("09:00");
        this.formFluxo.controls[i].get("InicioIntervalo").setValue("12:00");
        this.formFluxo.controls[i].get("FimIntervalo").setValue("13:00");
        this.formFluxo.controls[i].get("Saida").setValue("18:00");
      }
    }
    else {
      for (let i = 0; i < this.formFluxo.length; i++) {
        this.formFluxo.controls[i].get("Entrada").setValue("00:00");
        this.formFluxo.controls[i].get("InicioIntervalo").setValue("00:00");
        this.formFluxo.controls[i].get("FimIntervalo").setValue("00:00");
        this.formFluxo.controls[i].get("Saida").setValue("00:00");
      }
    }
  }

  voltarListaColaboradores() {
    this.listarPontos = []
  }

  limparCampos() {
    for (let i = 0; i < this.formFluxo.length; i++) {
      this.formFluxo.controls[i].get("Entrada").setValue(this.listarPontos[i].Entrada);
      this.formFluxo.controls[i].get("InicioIntervalo").setValue(this.listarPontos[i].InicioIntervalo);
      this.formFluxo.controls[i].get("FimIntervalo").setValue(this.listarPontos[i].FimIntervalo);
      this.formFluxo.controls[i].get("Saida").setValue(this.listarPontos[i].Saida);
      this.formFluxo.controls[i].get("HoraExtra").setValue(this.listarPontos[i].HoraExtra);
    }
    this.FormDinamiocoFluxo.get("preencher").setValue("");
  }

  mensagemRejeicao() {
    if (this.mensagem != '') {
      new AlertaModal('Atenção!',"Fluxo Rejeitado", `${this.mensagem}`, "p");
    }
  }

  fecharModal(id: string) {
    document.getElementById(id).click();
  }

  formatarHora(hora): string {
    if (hora.length < 4) {
      const resto = 4 - hora.length;
      hora = '0'.repeat(resto > 0 ? resto : 0) + hora;
    }
    hora = hora.replace(/[^\d]/g, "");
    return hora.replace(/(\d{2})(\d{2})/, "$1:$2");
  }

  mascaraHora(): string {
    return "00:00";
  }

  somenteNumero(evento) {
    return ((evento.charCode >= 48 && evento.charCode <= 57));
  }

  cancelarEdicao() {
    this.editando = false;
    this.limparCampos();
  }

  chamarBuscarFluxo() {
    if (this.tela == 1) {
      this.buscarFluxos();
    }
  }

  //modal assinatura
  onArquivoChange(evento): void {
    this.arquivos.reset();
    const { files } = evento.target;
    const arquivo = files[0];

    if (arquivo != null) {
      this.arquivos.setValue(arquivo);
      this.retornaArquivo();
      let reader = new FileReader();
      reader.readAsDataURL(arquivo);
      reader.onload = (e: any) => {

        this.string64.setValue(e.target.result);
      }

    } else {
      this.arquivos.setValue('');
    }
  }

  get arquivoselecionado(): FormControl {
    return this.formAnexo.get('arquivoselecionado') as FormControl;
  }
  get string64(): FormControl {
    return this.formAnexo.get('string64') as FormControl;
  }
  get arquivos(): FormControl {
    return this.formAnexo.get('arquivos') as FormControl;
  }
  get descricao(): FormControl {
    return this.formAnexo.get('arquivos') as FormControl;
  }

  criaAnexo() {
    this.listaArquivos.push(this.montaArquivos())
    let anexo_a: Anexo = {
      NomeUsuario: localStorage.getItem('nome'),
      Comentario: "",
      ArquivoSalvo: false,
      Arquivos: this.listaArquivos
    }
    this.listaAnexo.push(anexo_a);
    return this.listaAnexo;
  }

  limparCamposAssinaruta(): void {
    this.arquivos.setValue('');
    this.arquivoselecionado.setValue('');
  }
  fecharModalAssinatura() {
    const a = document.createElement("button")
    a.setAttribute("data-dismiss", "modal")
    a.click()
    document.getElementById("modalClose").click()
  }

  salvarAnexo(): void {
    let arquivo_a: Arquivo = this.montaArquivos();
    this.assinaturaService.salvarAnexo(this.criaAnexo()).subscribe(
      data => {
        new AlertaModal('sucesso',"Sucesso!", "Assinatura cadastrada!", "p")
        this.fecharModalAssinatura()
      }, error => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
      });

    this.fecharModalAssinatura()
  }

  retornaArquivo() {
    this.arquivoenviado.emit(this.arquivos.value);
  }

  montaArquivos(): Arquivo {
    const date = new Date();
    let arquivo: Arquivo = null;

    if (this.arquivos.value != '') {

      arquivo = {

        IdUsuario: localStorage.getItem('codigoUsuario'),
        NomeUsuario: localStorage.getItem('nome'),
        Nome: this.arquivos.value.name,
        ArquivoBase64: this.string64.value,
        Matricula: 0,
        Empfil: localStorage.getItem('filial_atual'),
        Grupo: '021',
        Tipo: '000',
        Codigo: `021000`,
        Extensao: this.arquivos.value.type.substring(6),
        NumDoc: '01',
        Numord: localStorage.getItem('numOrd'),
        CompChave: '0',
        Pagina: '01',
        DataCadastro: new Data(date.getDay().toString().padStart(2, '0') + date.getMonth() + date.getFullYear()),
        Descricao: "ASSINATURA"

      };
      return arquivo;
    }

    return arquivo;

  }

  clickParaTeste() {
    var fullDate = (document.getElementById("setpicker") as HTMLInputElement).value;
    const a = document.getElementById("teste")
  }

  teste() {
  
  }
}


