<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div [class]="telaDependente ? 'card shadow-sm' : ''" *ngIf="telaDependente">
  <div class="card-header">
    <div class="d-flex justify-content-between">
      <h5 class="card-title text-uppercase text-muted" *ngIf="!urlIgual()">
        <i class="fa fa-users"></i>&nbsp;<span>Dependentes</span>
      </h5>
      <h4 *ngIf="urlIgual()">Dependentes</h4>
      <button
        *ngIf="listaDependente && (!ColaboradorInativo || urlIgual())"
        class="btn btn-outline-warning btn-sm"
        (click)="mudarTela()"
        type="button"
      >
        <i class="fa fa-plus-circle"></i>&nbsp;
        <span>Adicionar</span>
      </button>
    </div>
    <hr class="mb-0" />
  </div>

  <div class="card-body">
    <p *ngIf="verificaTipoDependente()" class="text-center alert-warning p-2 mt-2 rounded">
      <i class="fa fa-exclamation-circle fa-3x my-2"></i><br />
      Parece que não há dependentes cadastrados para o estado civil selecionado. Por favor,
      certifique-se de cadastrar pelo menos um dependente (cônjuge ou companheiro(a)).
    </p>
    <div
      class="row d-flex justify-content-center"
      *ngIf="listaDependente && listaDependente.length > 0"
    >
      <!--  -->
      <p *ngIf="verificarErros()" class="text-center alert-warning p-2 mt-2 rounded">
        <i class="fa fa-info-circle fa-3x my-2"></i><br />
        Por favor, preencha todas as informações obrigatórias para garantir a integridade dos dados
        e a precisão das informações. Se você identificar algum dependente com informações
        incompletas, atualize os dados necessários.
      </p>
      <div
        class="col-md-4 col-lg-3 p-2 card-mobile"
        *ngFor="let dep of listaDependente"
        [ngClass]="urlIgual() ? 'col-xl-3' : 'col-xl-6'"
      >
        <div class="card shadow-sm">
          <ng-template
            [ngIf]="
              !(
                dep.Cpf === null ||
                dep.DataNascimento.SetData === '' ||
                dep.Nome === '' ||
                dep.Sexo === null ||
                dep.Tipo === null ||
                dep.Uf === '' ||
                dep.CodigoMunicipio === ''
              )
            "
          >
            <a
              class="btn-link text-primary"
              data-toggle="tooltip"
              data-placement="left"
              title="Atualize os dados necessários!"
            >
              <i
                class="fa fa-info-circle fa-2x mt-3 my-1 d-flex justify-content-center fs-6 text-warning"
              ></i>
            </a>
          </ng-template>
          <div class="card-header bg-light">
            <img
              [src]="
                dep.Sexo === 1
                  ? 'assets/img/avatar-masculino.svg'
                  : 'assets/img/avatar-feminino.svg'
              "
              class="card-img-top"
            />
          </div>
          <div class="card-body">
            <button
              type="button"
              (click)="mudarTela(dep)"
              class="btn btn-outline-primary btn-block nome-mobile"
            >
              <span>
                <strong>{{ dep.TipoDependente.descricao }}: </strong> {{ dep.Nome }}</span
              >
              &nbsp;<i class="fa fa-chevron-right"></i>&nbsp;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row d-flex justify-content-center"
    *ngIf="listaDependente && listaDependente.length <= 0"
  >
    <div class="col-md-4 col-lg-3 col-xl-6 p-2">
      <p class="text-center">Sem Dependentes</p>
    </div>
  </div>
  <div *ngIf="!listaDependente" class="d-flex justify-content-center align-items-center">
    <i class="fa fa-refresh fa-spin fa"></i>
  </div>
</div>

<!-- Cadastro dependente-->

<div *ngIf="cadastroDependente" [class]="telaDependente ? 'content clearfix' : ''">
  <div class="card">
    <div class="card-header">
      <h3 class="font-size-md cursor-pointer" (click)="mudarTela()">
        <i class="fa fa-arrow-left mr-2" aria-hidden="true"></i> Cadastro Dependente
      </h3>
      <hr class="mb-0" />
    </div>
    <div class="card-body">
      <ng-container *ngIf="cadastroUsuario">
        <div class="row">
          <div class="form-group col-md-1">
            <label class="justify-content-start font-weight-semibold">Matricula </label>
            <input
              type="text"
              placeholder="Funcionario"
              class="form-control"
              disabled
              [value]="cadastroUsuario.Matricula"
            />
          </div>

          <div class="form-group col-md-5">
            <label class="justify-content-start font-weight-semibold">Nome do Colaborador </label>
            <input
              type="text"
              placeholder="Funcionario"
              class="form-control"
              disabled
              [value]="cadastroUsuario.InformacoesBasicas.NomeCompleto"
            />
          </div>
          <div class="form-group col-md-3">
            <label class="justify-content-start font-weight-semibold">Vínculo </label>
            <input
              type="text"
              placeholder="Funcionario"
              class="form-control"
              disabled
              [value]="
                cadastroUsuario.InformacoesBasicas.Vinculo.Codigo +
                ' - ' +
                cadastroUsuario.InformacoesBasicas.Vinculo.Nome
              "
            />
          </div>
          <div class="form-group col-md-3">
            <label class="justify-content-start font-weight-semibold">Situação </label>
            <input
              type="text"
              placeholder="Funcionario"
              class="form-control"
              disabled
              [value]="cadastroUsuario.Stiuacao.Codigo + ' - ' + cadastroUsuario.Stiuacao.Descricao"
            />
          </div>
        </div>
        <hr />
      </ng-container>
      <form #dependente="ngForm" [formGroup]="formDependente" novalidade autocomplete="off">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="justify-content-start">CPF </label>
              <input
                type="text"
                placeholder="CPF"
                [ngClass]="
                  (formDependente.controls['cpf'].status == 'INVALID' && validaDependente) ||
                  (formDependente.controls['cpf'].hasError('cpfError') &&
                    formDependente.controls['cpf'].touched)
                    ? 'is-invalid'
                    : ''
                "
                class="form-control"
                name="cpf"
                mask="000.000.000-00"
                formControlName="cpf"
                required
                (input)="conferirCPF()"
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  (formDependente.controls['cpf'].touched &&
                    formDependente.controls['cpf'].hasError('required')) ||
                  (formDependente.controls['cpf'].touched &&
                    formDependente.controls['cpf'].hasError('cpfError'))
                "
                >{{
                  formDependente.controls['cpf'].hasError('cpfError')
                    ? 'Digite um CPF válido'
                    : 'Campo Obrigatório'
                }}</span
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="justify-content-start">Nome Completo </label>
              <input
                type="text"
                placeholder="Nome Completo"
                [ngStyle]="
                  (formDependente.controls['nomeCompleto'].status == 'INVALID' &&
                    validaDependente) ||
                  (formDependente.controls['nomeCompleto'].hasError('composto') &&
                    formDependente.controls['nomeCompleto'].dirty) ||
                  (formDependente.controls['nomeCompleto'].hasError('simbolo') &&
                    formDependente.controls['nomeCompleto'].dirty) ||
                  (formDependente.controls['nomeCompleto'].hasError('tamanho') &&
                    formDependente.controls['nomeCompleto'].dirty)
                    ? { 'border-color': 'red' }
                    : null
                "
                class="form-control"
                formControlName="nomeCompleto"
                name="nomeCompleto"
                required
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  formDependente.controls['nomeCompleto'].dirty &&
                  formDependente.controls['nomeCompleto'].hasError('required')
                "
                >Digite o Nome Valido</span
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Sexo </label>
              <select
                [ngStyle]="
                  formDependente.controls['sexo'].status == 'INVALID' && validaDependente
                    ? { 'border-color': 'red' }
                    : null
                "
                class="form-control form-control-uniform"
                formControlName="sexo"
                name="sexo"
                required
              >
                <option value="">Selecione...</option>
                <option
                  *ngFor="let sex of sexo | orderBy : 'Descricao' : false"
                  value="{{ sex.Valor }}"
                >
                  {{ sex.Descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formDependente.controls['sexo'].dirty &&
                  formDependente.controls['sexo'].hasError('required')
                "
                >Selecione o Sexo</span
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Tipo de Dependência</label>
              <select
                [ngStyle]="
                  formDependente.controls['tipoDependencia'].status == 'INVALID' && validaDependente
                    ? { 'border-color': 'red' }
                    : null
                "
                class="form-control form-control-uniform"
                (change)="verificarMenor()"
                formControlName="tipoDependencia"
                name="tipoDependencia"
                required
              >
                <option value="">Selecione...</option>
                <option *ngFor="let tipo of tipoDependecias" [value]="tipo.valor">
                  {{ tipo.descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formDependente.controls['tipoDependencia'].dirty &&
                  formDependente.controls['tipoDependencia'].hasError('required')
                "
                >Selecione o Tipo de Dependência</span
              >
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label class="justify-content-start">Data de Nascimento </label>
              <input
                [ngStyle]="
                  (formDependente.controls['dataNascimento'].status == 'INVALID' &&
                    validaDependente) ||
                  (formDependente.controls['dataNascimento'].hasError('data') &&
                    formDependente.controls['dataNascimento'].dirty)
                    ? { 'border-color': 'red' }
                    : null
                "
                class="form-control"
                (change)="verificarMenor()"
                formControlName="dataNascimento"
                type="date"
                max="9999-12-31"
                required
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  (formDependente.controls['dataNascimento'].dirty &&
                    formDependente.controls['dataNascimento'].hasError('required')) ||
                  (formDependente.controls['dataNascimento'].dirty &&
                    formDependente.controls['dataNascimento'].hasError('data'))
                "
                >Digite uma Data Válida</span
              >
            </div>
          </div>
          <div class="col-md-3">
            <div class="for-group">
              <label>UF </label>
              <select
                [ngStyle]="
                  formDependente.controls['uf'].status == 'INVALID' && validaDependente
                    ? { 'border-color': 'red' }
                    : null
                "
                class="form-control form-control-uniform"
                (change)="buscarCidade()"
                formControlName="uf"
                name="uf"
                required
              >
                <option value="">Selecione...</option>
                <option *ngFor="let estado of uf" value="{{ estado.SiglaUf }}">
                  {{ estado.Nome }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formDependente.controls['uf'].dirty &&
                  formDependente.controls['uf'].hasError('required')
                "
                >Selecione o UF</span
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="justify-content-start">Município </label>
              <select
                [ngStyle]="
                  formDependente.controls['cidade'].status == 'INVALID' && validaDependente
                    ? { 'border-color': 'red' }
                    : null
                "
                class="form-control form-control-uniform"
                name="cidade"
                formControlName="cidade"
                required
              >
                <option value="">Selecione...</option>
                <option
                  *ngFor="let cid of cidade | orderBy : 'Nome' : false"
                  value="{{ cid.Codigo }}"
                >
                  {{ cid.Nome }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="justify-content-start"> Início do motivo da dependência </label>
              <select
                [ngStyle]="
                  formDependente.controls['motivoInicio'].status == 'INVALID' && validaDependente
                    ? { 'border-color': 'red' }
                    : null
                "
                class="form-control form-control-uniform"
                name="motivoInicio"
                formControlName="motivoInicio"
                required
              >
                <option value="">Selecione...</option>
                <option
                  *ngFor="let motivo of listaMotivoInicio | orderBy : 'Nome' : false"
                  value="{{ motivo.MotivoInicioDependencia }}"
                >
                  {{ motivo.MotivoInicioDependencia }}
                </option>
              </select>
              <!-- [ngStyle]="((perfilDependente.NumeroDocumento == '' && perfilDependente.Dtent.SetData != '') && validaDependente) ? {'border-color':'red'} : ''" -->
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="justify-content-start"> Fim do motivo da dependência </label>
              <select
                [ngStyle]="
                  formDependente.controls['motivoFim'].status == 'INVALID' && validaDependente
                    ? { 'border-color': 'red' }
                    : null
                "
                class="form-control form-control-uniform"
                name="motivoFim"
                formControlName="motivoFim"
              >
                <option value="">Selecione...</option>
                <option
                  *ngFor="let motivo of listaMotivoFim | orderBy : 'Nome' : false"
                  value="{{ motivo.MotivoFimDependencia }}"
                >
                  {{ motivo.MotivoFimDependencia }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="justify-content-start"
                >Número do Documento <small>(opcional)</small>
              </label>
              <input
                class="form-control"
                [ngStyle]="
                  formDependente.controls['cartorio'].status == 'INVALID' && validaDependente
                    ? { 'border-color': 'red' }
                    : null
                "
                (input)="capslock($event)"
                formControlName="cartorio"
                placeholder="Número do Documento"
                name="cartorio"
                type="text"
              />
              <!-- [ngStyle]="((perfilDependente.NumeroDocumento == '' && perfilDependente.Dtent.SetData != '') && validaDependente) ? {'border-color':'red'} : ''" -->
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label class="justify-content-start">Data de Entrega <small>(opcional)</small></label>
              <input
                type="date"
                class="form-control"
                formControlName="dataEntrega"
                [ngStyle]="
                  formDependente.controls['dataEntrega'].status == 'INVALID' && validaDependente
                    ? { 'border-color': 'red' }
                    : null
                "
                name="dataEntrega"
              />
              <!-- [ngStyle]="((perfilDependente.NumeroDocumento != '' && perfilDependente.Dtent.SetData == '') && validaDependente) ? {'border-color':'red'} : ''" -->
            </div>
          </div>
          <div class="col-md-12">
            <div class="card-deck">
              <div class="card mb-3 mt-2">
                <div class="row mt-3 mb-2">
                  <div class="col-12 ml-2 mr-2">
                    <div class="form-group">
                      <div
                        #divAtivos
                        class="form-check form-check-left"
                        (click)="irrfCheckBox = irrfCheckBox == true ? false : true"
                      >
                        <label
                          [ngClass]="
                            irrfCheckBox
                              ? 'form-check-label font-weight-semibold'
                              : 'form-check-label'
                          "
                          for="divAtivos"
                        >
                          <div class="uniform-checker">
                            <span [ngClass]="irrfCheckBox ? 'checked bg-primary' : ''">
                              <input
                                type="checkbox"
                                class="form-input-styled"
                                checked=""
                                data-fouc=""
                                [(ngModel)]="irrfCheckBox"
                                [ngModelOptions]="{ standalone: true }"
                              />
                            </span>
                          </div>
                          Salário Família
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card mb-3 mt-2">
                <div class="row mt-3 mb-2">
                  <div class="col-12 ml-2 mr-2">
                    <div class="form-group">
                      <div
                        #divAtivos
                        class="form-check form-check-left"
                        (click)="
                          salarioFamiliarCheckBox = salarioFamiliarCheckBox == true ? false : true
                        "
                      >
                        <label
                          [ngClass]="
                            salarioFamiliarCheckBox
                              ? 'form-check-label font-weight-semibold'
                              : 'form-check-label'
                          "
                          for="divAtivos"
                        >
                          <div class="uniform-checker">
                            <span [ngClass]="salarioFamiliarCheckBox ? 'checked bg-primary' : ''">
                              <input
                                type="checkbox"
                                class="form-input-styled"
                                checked=""
                                [(ngModel)]="salarioFamiliarCheckBox"
                                [ngModelOptions]="{ standalone: true }"
                              />
                            </span>
                          </div>
                          Imposto de Renda Retido na Fonte
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <app-anexo
                pagina="011"
                [matricula]="matricula"
                [enviarSeparadamente]="true"
                [compChave]="
                  formDependente.get('cpf').value.length == 11
                    ? formDependente.get('cpf').value
                    : ''
                "
                [desabilitado]="editarDependente"
              >
              </app-anexo>
            </div>
          </div>
        </div>

        <div class="card-footer text-right">
          <button type="button" class="btn btn-danger mr-2" (click)="mudarTela()">Cancelar</button>
          <button type="button" (click)="validarCadastroDependente()" class="btn btn-primary">
            Salvar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
