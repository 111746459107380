import { AbstractControl,  ValidatorFn  } from '@angular/forms';

export function pisValidator(control: AbstractControl): { [key: string]: boolean } | null { 
  
    if(control.value === "")
    {
      return null;
    }

    let numeroPIS = control.value
   
    let  validade =  99
    let multiplicadorBase = "3298765432";
    let total = 0;
    let resto = 0;
    let multiplicando = 0;
    let multiplicador = 0;
    let digito = 99;

    // Retira a mascara
   // let numeroPIS = pis.replace(/[^\d]+/g, '');

    if (numeroPIS.length !== 11 ||
      numeroPIS === "00000000000" ||
      numeroPIS === "11111111111" ||
      numeroPIS === "22222222222" ||
      numeroPIS === "33333333333" ||
      numeroPIS === "44444444444" ||
      numeroPIS === "55555555555" ||
      numeroPIS === "66666666666" ||
      numeroPIS === "77777777777" ||
      numeroPIS === "88888888888" ||
      numeroPIS === "99999999999") {
      
     
        validade = 0
      }else{
        for (var i = 0; i < 10; i++) {
          multiplicando = parseInt(numeroPIS.substring(i, i + 1));
          multiplicador = parseInt(multiplicadorBase.substring(i, i + 1));
          total += multiplicando * multiplicador;
      }
     
      resto = 11 - total % 11;
      resto = resto === 10 || resto === 11 ? 0 : resto;
      digito = parseInt("" + numeroPIS.charAt(10));
      if (resto === digito){
       
       
        validade = 1
        
      }else{
       
       validade = 0
      }
      }
      
    //   return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (numeroPIS !== undefined  &&  validade === 0) {
            return { 'pis': true };
        }
        return null
    };
   
    




   


    
