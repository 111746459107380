import { Component, ViewChild } from '@angular/core';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProntuarioMedicoService } from './prontuario-medico.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { ProntuarioMedicoCadastroComponent } from './prontuario-medico-cadastro/prontuario-medico-cadastro.component';
import Swal from 'sweetalert';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { ObservacoesFichaFuncionalComponent } from '../componentes-funcionais/observacoes-ficha-funcional/observacoes-ficha-funcional.component';
import { AnexoComponent } from '../anexo/anexo.component';

@Component({
  selector: 'app-prontuario-medico',
  templateUrl: './prontuario-medico.component.html',
  styleUrls: ['./prontuario-medico.component.css'],
})
export class ProntuarioMedicoComponent {
  @ViewChild(ProntuarioMedicoCadastroComponent) modal: ProntuarioMedicoCadastroComponent;
  @ViewChild(ObservacoesFichaFuncionalComponent)
  modalObservacoes: ObservacoesFichaFuncionalComponent;
  @ViewChild(AnexoComponent) modalAnexo: AnexoComponent;

  matricula: number = null;
  formColaborador: FormGroup;
  colaborador: Cadastro = null;
  loading: boolean = false;
  listaProntuario: Prontuario[] = [];
  cadastroProntuario: boolean = true;
  prontuarioAtual: Prontuario = null;
  localStorage: LocalStorage;
  empfil: string;
  tipoPagina: string = '013';

  constructor(
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private prontuarioService: ProntuarioMedicoService
  ) {
    this.localStorage = this.decryptLocalStorage();
    console.log('localstorage', this.localStorage);
    this.formColaborador = this.fb.group({
      matricula: ['', Validators.required],
      nome: [''],
    });
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }

  buscarColaboradorMatricula(matricula) {
    this.spinner.show("medico");
    this.prontuarioService.buscarColaboradorMatricula(matricula).subscribe(
      (data: Cadastro) => {
        console.log(data);
        this.empfil = data.Filial.Codigo;
        this.setarFormColaborador(data);
        this.colaborador = data;
        this.spinner.hide("medico");
      },
      (error) => {
        new AlertaModal('erro', 'Erro!', error.error.Mensagem, 'p');
        this.retornarTelaInicial();
        this.spinner.hide("medico");
      }
    );
  }

  matriculaListener(evento) {
    this.matricula = evento.Matricula;
    this.buscarColaboradorMatricula(this.matricula);
    this.modalAnexo.inicializarComponente(evento);
  }

  setarFormColaborador(data) {
    this.formColaborador.get('matricula').disable();
    this.formColaborador.get('matricula').setValue(this.matricula);
    this.formColaborador.get('nome').disable();
    this.formColaborador.get('nome').setValue(data.InformacoesBasicas.NomeCompleto);
    this.loading = false;
    this.buscarProntuario();
  }

  retornarTelaInicial() {
    this.matricula = null;
    this.formColaborador.reset();
    this.formColaborador.markAsPristine();
  }

  buscarProntuario() {
    this.spinner.show();
    this.prontuarioService.buscarProntuario(this.matricula).subscribe(
      (data: Prontuario[]) => {
        console.log('data', data);
        this.listaProntuario = data;
        this.spinner.hide();
      },
      (err) => {
        console.error(err);
        this.spinner.hide();
      }
    );
  }

  deletarProntuario(prontuario: Prontuario) {
    console.log('prontuario', prontuario);
    Swal({
      title: 'Deseja realmente excluir esse Prontuário?',
      text: 'Essa ação não pode ser desfeita.',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
    }).then((result) => {
      this.spinner.show();
      if (result) {
        const dtinsp = prontuario.DtInsp.SetData; // Convert the object to a string
        this.prontuarioService
          .deletarProntuario(prontuario.Empfil, prontuario.Mat, dtinsp)
          .subscribe(
            (data) => {
              this.buscarProntuario();
              Swal({
                title: 'Excluído!',
                text: 'O prontuário foi excluído com sucesso.',
                icon: 'success',
              });
              this.spinner.hide();
            },
            (err) => {
              Swal('Erro', 'Não foi possível deletar esse prontuário.', 'error');
              this.spinner.hide();
            }
          );
      }else this.spinner.hide();
    });
  }

  aoAbrirModal(index?: number, Sequencial?: number) {
    this.modal.formProntuario.reset();

    if (index !== undefined) {
      const prontuarioSelecionado = this.listaProntuario[index];
      const formatarData = (data) =>
        typeof data === 'string' && data ? data.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') : '';

      const formatarDataParaInput = (dataObj) => {
        if (!dataObj || !dataObj.SetData || dataObj.SetData.length !== 8) return '';
        const ano = dataObj.SetData.substring(0, 4);
        const mes = dataObj.SetData.substring(4, 6);
        const dia = dataObj.SetData.substring(6, 8);
        return `${ano}-${mes}-${dia}`;
      };

      

      this.modal.formProntuario.get('Empfil').setValue(prontuarioSelecionado.Empfil);
      this.modal.formProntuario.get('Mat').setValue(prontuarioSelecionado.Mat);
      this.modal.formProntuario.get('Crm').setValue(prontuarioSelecionado.Crm);
      this.modal.formProntuario
        .get('Dtinsp')
        .setValue(
          prontuarioSelecionado.DtInsp ? formatarDataParaInput(prontuarioSelecionado.DtInsp) : ''
        );
      this.modal.formProntuario.get('CodAfa').setValue(prontuarioSelecionado.CodAfa);
      this.modal.formProntuario.get('CodMot').setValue(prontuarioSelecionado.CodMot);
      this.modal.formProntuario
        .get('Dt1LM')
        .setValue(
          prontuarioSelecionado.Dt1LM ? formatarDataParaInput(prontuarioSelecionado.Dt1LM) : ''
        );
      this.modal.formProntuario
        .get('Dt2LM')
        .setValue(
          prontuarioSelecionado.Dt2LM ? formatarDataParaInput(prontuarioSelecionado.Dt2LM) : ''
        );

      this.modal.prontuarioindex = index;
      this.modal.Seq = Sequencial;
    } else {
      this.modal.prontuarioindex = null;
      this.cadastroProntuario = true;
    }
  }

  codAfaDescricoes = {
    '33': '33 - BENEFICIO INSS',
    '36': '36 - AUXILIO MATERNIDADE',
  };

  getDescricaoCodAfa(codigo: string): string {
    return this.codAfaDescricoes[codigo] || `${codigo} - Descrição não disponível`;
  }

  codMotDescricoes = {
    '20': '33 - ACIDENTE DE TRABALHO',
    '44': '44 - BENEFICIO INSS',
    '3': '3 - LICENCA GESTANTE',
    '2': '2 - TRATAMENTO DE SAUDE',
  };

  getDescricaoCodMot(codigo: string): string {
    return this.codMotDescricoes[codigo] || `${codigo} - Descrição não disponível`;
  }

  downloadPdf() {
    this.spinner.show();

    this.prontuarioService.downloadPdf(this.empfil, this.matricula, this.tipoPagina).subscribe(
      (data: any) => {
        console.log(data);

        this.spinner.hide();

        const pdf = `data:application/pdf;base64,${data.Base64}`;
        const fileName = `${data.Nome}.pdf`;

        const downloadLink = document.createElement('a');

        downloadLink.href = pdf;

        downloadLink.download = fileName;

        downloadLink.click();
      },
      (err) => {
        console.error(err);
        this.spinner.hide();
      }
    );
  }
}

export interface Prontuario {
  Empfil: string;
  Documento: 'S' | 'N';
  Obs: 'S' | 'N';
  Mat: number;
  Crm: string;
  DtInsp: { SetData: string; ToDate: string; IsDate: boolean; Empty: boolean };
  CodAfa: number;
  CodMot: number;
  Dt1LM: { SetData: string; ToDate: string; IsDate: boolean; Empty: boolean };
  Dt2LM: { SetData: string; ToDate: string; IsDate: boolean; Empty: boolean };
  Seq: number;
}
