import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colunaVazia',
})
export class ColunaVaziaPipe implements PipeTransform {
  transform(value: any): string {
    const IS_EMPTY = value === null || value === undefined || value === '';
    if (IS_EMPTY) {
      return 'NÃO INFORMADO';
    }
    return value;
  }
}
