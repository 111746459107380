import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExperienciaProfissionalService } from './experiencia-profissional.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import Swal from 'sweetalert';
import { ExperienciaProfissionalCadastroComponent } from './experiencia-profissional-cadastro/experiencia-profissional-cadastro.component';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { ObservacoesFichaFuncionalComponent } from '../componentes-funcionais/observacoes-ficha-funcional/observacoes-ficha-funcional.component';
import { AnexoComponent } from '../anexo/anexo.component';

@Component({
  selector: 'app-experiencia-profissional',
  templateUrl: './experiencia-profissional.component.html',
  styleUrls: ['./experiencia-profissional.component.css'],
})
export class ExperienciaProfissionalComponent {
  @ViewChild('Cursos') Cursos: ExperienciaProfissionalCadastroComponent;
  @ViewChild('Habilidades') Habilidades: ExperienciaProfissionalCadastroComponent;
  @ViewChild('Funcoes') Funcoes: ExperienciaProfissionalCadastroComponent;
  @ViewChild('Requisitos') Requisitos: ExperienciaProfissionalCadastroComponent;
  @ViewChild('Atividades') Atividades: ExperienciaProfissionalCadastroComponent;
  @ViewChild(ObservacoesFichaFuncionalComponent)
  modalObservacoes: ObservacoesFichaFuncionalComponent;
  @ViewChild(AnexoComponent) modalAnexo: AnexoComponent;

  matricula: number = null;
  numeroDeOrdem: number = null;
  colaborador: Cadastro = null;
  loading: boolean = false;
  formColaborador: FormGroup;
  localStorage: LocalStorage;

  listaExperiencias: Experiencia[] = [];
  listaCursos: ItemExp[] = [];
  listaHabilidades: ItemExp[] = [];
  listaFuncoes: ItemExp[] = [];
  listaRequisitos: ItemExp[] = [];
  listaAtividades: ItemExp[] = [];
  listaAvaliacoes: ItemExp[] = [];

  constructor(
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private expProfissionalService: ExperienciaProfissionalService
  ) {
    this.localStorage = this.decryptLocalStorage();
    this.formColaborador = this.fb.group({
      matricula: ['', Validators.required],
      nome: [''],
    });
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }

  matriculaListener(evento) {
    this.colaborador = evento;
    this.numeroDeOrdem = parseInt(this.colaborador.NumeroDeOrdem);
    this.matricula = parseInt(this.colaborador.Matricula);
    this.setarFormColaborador(evento);
    this.modalAnexo.inicializarComponente(evento);
  }

  setarFormColaborador(data) {
    this.spinner.show('experiencia');
    this.formColaborador.get('matricula').disable();
    this.formColaborador.get('matricula').setValue(this.matricula);
    this.formColaborador.get('nome').disable();
    this.formColaborador.get('nome').setValue(data.InformacoesBasicas.NomeCompleto);
    this.loading = false;
    // this.buscarCursos();
    // this.buscarHabilidades();
    // this.buscarFuncoes();
    // this.buscarRequisitos();
    // this.buscarAtividades();
    // this.buscarAvaliacoes();
    // this.buscarExperiencias();
    this.expProfissionalService.buscarTodasListagens(this.numeroDeOrdem.toString()).subscribe({
      next: (data) => {
        this.listaExperiencias = data[0] as Experiencia[];
        this.listaCursos = data[1] as ItemExp[];
        this.listaHabilidades = data[2] as ItemExp[];
        this.listaFuncoes = data[3] as ItemExp[];
        this.listaRequisitos = data[4] as ItemExp[];
        this.listaAtividades = data[5] as ItemExp[];
        this.listaAvaliacoes = data[6] as ItemExp[];
        this.spinner.hide('experiencia');
      },
      error: (err) => {
        this.spinner.hide('experiencia');
        Swal('Erro', err.error.Mensagem, 'error');

        console.log(err);
      },
    });
  }

  retornarTelaInicial() {
    this.matricula = null;
    this.formColaborador.reset();
    this.formColaborador.markAsPristine();
    this.listaExperiencias = [];
    this.listaCursos = [];
    this.listaHabilidades = [];
    this.listaFuncoes = [];
    this.listaRequisitos = [];
    this.listaAtividades = [];
    this.Cursos.resetar();
    this.Habilidades.resetar();
    this.Funcoes.resetar();
    this.Requisitos.resetar();
    this.Atividades.resetar();
  }

  buscarExperiencias() {
    this.spinner.show();
    this.expProfissionalService.buscarExperiencias(this.colaborador.NumeroDeOrdem).subscribe(
      (data: any) => {
        this.listaExperiencias = data;
        this.spinner.hide();
      },
      (error) => {
        Swal('Erro', error.error.Mensagem, 'error');
        this.retornarTelaInicial();
        this.spinner.hide();
      }
    );
  }

  buscarCursos() {
    this.spinner.show();
    this.expProfissionalService.buscarCursos().subscribe(
      (data: any) => {
        this.listaCursos = data;
        this.spinner.hide();
      },
      (error) => {
        Swal('Erro', error.error.Mensagem, 'error');
        this.retornarTelaInicial();
        this.spinner.hide();
      }
    );
  }

  buscarHabilidades() {
    this.spinner.show();
    this.expProfissionalService.buscarHabilidades().subscribe(
      (data: any) => {
        this.listaHabilidades = data;
        this.spinner.hide();
      },
      (error) => {
        Swal('Erro', error.error.Mensagem, 'error');
        this.retornarTelaInicial();
        this.spinner.hide();
      }
    );
  }

  buscarFuncoes() {
    this.spinner.show();
    this.expProfissionalService.buscarFuncoes().subscribe(
      (data: any) => {
        this.listaFuncoes = data;
        this.spinner.hide();
      },
      (error) => {
        Swal('Erro', error.error.Mensagem, 'error');
        this.retornarTelaInicial();
        this.spinner.hide();
      }
    );
  }

  buscarRequisitos() {
    this.spinner.show();
    this.expProfissionalService.buscarRequisitos().subscribe(
      (data: any) => {
        this.listaRequisitos = data;
        this.spinner.hide();
      },
      (error) => {
        Swal('Erro', error.error.Mensagem, 'error');
        this.retornarTelaInicial();
        this.spinner.hide();
      }
    );
  }

  buscarAtividades() {
    this.spinner.show();
    this.expProfissionalService.buscarAtividades().subscribe(
      (data: any) => {
        this.listaAtividades = data;
        this.spinner.hide();
      },
      (error) => {
        Swal('Erro', error.error.Mensagem, 'error');
        this.retornarTelaInicial();
        this.spinner.hide();
      }
    );
  }

  buscarAvaliacoes() {
    this.spinner.show();
    this.expProfissionalService.buscarAvaliacoes().subscribe(
      (data: any) => {
        this.listaAvaliacoes = data;
        this.spinner.hide();
      },
      (error) => {
        Swal('Erro', error.error.Mensagem, 'error');
        this.retornarTelaInicial();
        this.spinner.hide();
      }
    );
  }

  unirExperiencias(): Experiencia[] {
    const expSelecionadas = this.Cursos.extrairSelecionados().concat(
      this.Habilidades.extrairSelecionados(),
      this.Funcoes.extrairSelecionados(),
      this.Requisitos.extrairSelecionados(),
      this.Atividades.extrairSelecionados()
    );
    return expSelecionadas;
  }

  enviarExperiencias() {
    let expSelecionadas = this.unirExperiencias();
    if (expSelecionadas.length == 0) {
      Swal({
        title: 'Não há nenhuma experiência selecionada',
        text: 'Caso tenha desmarcado todas as experiências, elas serão apagadas do perfil do colaborador.',
        icon: 'warning',
        buttons: ['Cancelar', 'Confirmar'],
      }).then((result) => {
        if (result) this.spinner.show();
        this.expProfissionalService.excluirExperiencias(this.colaborador.NumeroDeOrdem).subscribe(
          (data) => {
            this.buscarExperiencias();
          },
          (err) => {
            Swal('Erro', 'Não foi possível atualizar essas experiências.', 'error');
            this.spinner.hide();
          }
        );
      });
    } else {
      this.expProfissionalService.enviarExperiencias(expSelecionadas).subscribe(
        (data) => {
          Swal('Sucesso', 'Experiências profissionais atualizadas com sucesso.', 'success');
        },
        (err) => {
          Swal('Erro', 'Não foi possível adicionar essas experiências.', 'error');
        }
      );
    }
  }
}

export interface Experiencia {
  Avaliacao: string;
  CodCan?: string;
  CodTof?: string;
  Codigo: string;
  Numord: number;
  Sigla: string;
  Tipo: string;
}

export interface ItemExp {
  Codigo: string;
  Descricao: string;
}
