import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertaModal } from 'src/app/componentes/componentes-funcionais/alerta-modal/alerta-modal.component';
import { InfoColaborador } from '../../cadastro-complementar/cadastro-complementar.component';
import { Data } from 'src/app/service/interfaces/Data';
import { InformacoesSaudeService } from '../informacoes-saude.service';
import { get } from 'http';
import { Parametro } from 'src/app/service/interfaces/Parametro';
import { InformacoesSaudeComponent } from '../informacoes-saude.component';
import { ErroService } from 'src/app/service/erros/erro.service';

@Component({
  selector: 'app-cadastro-saude',
  templateUrl: './cadastro-saude.component.html',
  styleUrls: ['./cadastro-saude.component.css'],
})
export class CadastroSaudeComponent implements OnInit {
  @Input() cadastro: boolean = true;
  @Input() matricula: string = '';
  formSaude: FormGroup;
  infoColaborador: InfoColaborador;
  listaTpDeficiencia: any[];
  listaReabilitado: any[];
  listaReadaptado: any[];
  listaCota: any[];

  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private informacoesService: InformacoesSaudeService,
    private informacoesSaude: InformacoesSaudeComponent,
    public validaErro: ErroService
  ) {}

  ngOnInit() {
    this.formSaude = this.fb.group({
      tpDeficiencia: ['', Validators.required],
      reabilitado: ['', Validators.required],
      readaptado: ['', Validators.required],
      dtInicio: ['', Validators.required],
      dtFinal: [''],
      cota: ['', Validators.required],
      descricaoDeficiencia: ['', Validators.required],
    });
  }

  submitForm(): void {
    if (this.formSaude.invalid) {
      new AlertaModal('alerta', 'Atenção!', 'Preencha os campos corretamente.', 'p');
      this.formSaude.markAllAsTouched();
    } else {
      this.cadastro ? this.cadastrarInfo() : this.atualizarInfo();
    }
  }

  cadastrarInfo(): void {
    this.spinner.show();
    const objCadastro = this.montarObjSaude();
    this.informacoesService.cadastrarInfo(objCadastro, this.matricula).subscribe({
      next: () => {
        this.informacoesSaude.buscarListaSaude();
        this.limparForm();
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'Cadastro realizado com sucesso.', 'p');
      },
      error: (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      },
    });
  }

  atualizarInfo(): void {
    this.spinner.show();
    const objAtualizacao = this.montarObjSaude();
    this.informacoesService.atualizarInfo(objAtualizacao, this.matricula).subscribe({
      next: () => {
        this.informacoesSaude.buscarListaSaude();
        this.spinner.hide();

        new AlertaModal('sucesso', 'Sucesso!', 'Cadastro atualizado com sucesso.', 'p');
      },
      error: (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      },
    });
  }

  limparForm(): void {
    this.formSaude.setValue({
      tpDeficiencia: '',
      reabilitado: '',
      readaptado: '',
      dtInicio: '',
      dtFinal: '',
      cota: '',
      descricaoDeficiencia: '',
    });
    this.formSaude.markAsUntouched();
  }

  montarObjSaude(): InformacoesSaude {
    const dadosForm = this.formSaude.value;

    return {
      TipoDeficiencia: { Codigo: dadosForm.tpDeficiencia },
      Reabilitado: dadosForm.reabilitado,
      Readaptado: dadosForm.readaptado,
      DataInicial: new Data(dadosForm.dtInicio),
      DataFinal: new Data(dadosForm.dtFinal),
      PreencherCota: dadosForm.cota,
      DescricaoComorbidade: dadosForm.descricaoDeficiencia.trim(),
    };
  }

  montarInformacoesForm(informacoesSaude: InformacoesSaude): void {
    this.formSaude.setValue({
      tpDeficiencia: informacoesSaude.TipoDeficiencia.Codigo,
      reabilitado: informacoesSaude.Reabilitado,
      readaptado: informacoesSaude.Readaptado,
      dtInicio: this.formatarDataRecebida(informacoesSaude.DataInicial.ToDate),
      dtFinal: this.formatarDataRecebida(informacoesSaude.DataFinal.ToDate),
      cota: informacoesSaude.PreencherCota,
      descricaoDeficiencia: informacoesSaude.DescricaoComorbidade,
    });
  }

  formatarDataRecebida(data: string): string {
    if (data !== '') {
      const arrayData = data.split('/');
      const dataFormatada = `${arrayData[2]}-${arrayData[1]}-${arrayData[0]}`;
      return dataFormatada;
    } else {
      return '';
    }
  }
}

export interface InformacoesSaude {
  TipoDeficiencia: Parametro;
  Reabilitado: string;
  Readaptado: string;
  DataInicial: Data;
  DataFinal: Data;
  PreencherCota: string;
  DescricaoComorbidade: string;
}
