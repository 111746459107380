import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AcessoService } from '../../service/acesso/acesso.service';
import { Processo } from './processos.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root',
})
export class ProcessosService {
  urlAcesso: string;

  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any;
  tpProcessamento: string = 'EM';
  competencia: string = '';
  filialAtual: string = '';
  tipoUsuario: string = '';

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filialAtual = this.localStorage.FilialAtual.toString();
      this.tipoUsuario = this.localStorage.TipoUsuario;
      this.urlAcesso = this.acesso.validarUrl();
    }
  }

  buscarSituacao() {
    return this.http.get(
      `${this.urlAcesso}processo/situacao/${this.tpProcessamento}/${this.competencia}/${this.filialAtual}`,
      { headers: this.cabecalho }
    );
  } // http://localhost/EloWebApi/api/processo/situacao/EM/201909/320105 - > GET (Listar situação)

  buscarTipoReflexo() {
    return this.http.get(
      `${this.urlAcesso}processo/tipoReflexo/${this.tpProcessamento}/${this.competencia}/${this.filialAtual}`,
      { headers: this.cabecalho }
    );
  } //http://localhost/EloWebApi/api/processo/tipoReflexo/201909/EM/320105 -> GET (Listar Tipo reflexo)
  buscarProcessos(matricula: string) {
    return this.http.get(
      `${this.urlAcesso}lista-processos/${this.tpProcessamento}/${this.competencia}/${this.filialAtual}/${matricula}`,
      { headers: this.cabecalho }
    );
  } // http://localhost/EloWebApi/api/lista-processos/201909/EM/320105 -> GET (Listar processo)

  alterarProcesso(processo: Processo) {
    return this.http.post(
      `${this.urlAcesso}processo/alterar/${this.tpProcessamento}/${this.competencia}/${this.filialAtual}`,
      processo,
      { headers: this.cabecalho }
    );
  } // http://localhost/EloWebApi/api/processo/alterar/EM/201909/320105 -> POST (Alterar processo)

  salvarProcesso(processo: Processo) {
    return this.http.post(
      `${this.urlAcesso}processo/${this.tpProcessamento}/${this.competencia}/${this.filialAtual}`,
      processo,
      { headers: this.cabecalho }
    );
  } //http://localhost/EloWebApi/api/processo/EM/201909/320105  -> POST (Salvar processo)

  excluirProcesso(processo: Processo) {
    return this.http.delete(
      `${this.urlAcesso}processo/${this.tpProcessamento}/${this.competencia}/${this.filialAtual}/${processo.Sequencial}/${this.tipoUsuario}`,
      { headers: this.cabecalho }
    );
    //http://localhost/EloWebApi/api/processo/EM/201909/320105/numprocesso -> DELETE (Deletar processo)
  }

  buscarColaborador(matricula: string) {
    return this.http.get(
      `${this.urlAcesso}lista-nome/${this.tpProcessamento}/${this.competencia}/${this.filialAtual}/${matricula}`,
      { headers: this.cabecalho }
    );
  }

  buscarCompetenciaAberta() {
    return this.http.get(
      `${this.urlAcesso}competenciaAberta/${this.tpProcessamento}/${this.filialAtual}`,
      { headers: this.cabecalho }
    );
  } // http://localhost/EloWebApi/api/competenciaaberta/EM/301101 -> GET (Retornar competência aberta)
}
