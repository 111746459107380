<!-- <p>tipo-beneficiario works!</p> -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<div class="card">
  <div class="card-header">
    <h3 class="card-title" id="target">Tipo de Beneficiário</h3>
  </div>
  <form #beneficio="ngForm" (ngSubmit)="salvarTipoBeneficio(beneficio)" [formGroup]="formTpBeneficio" id="form-tipo-beneficiario">
    <div *ngIf="loadingSpinner" class="row  d-flex justify-content-center align-items-center mt-3">
      <i class="fa fa-refresh fa-spin fa-2x "></i>
      <h4 class="ml-2">Aguarde...</h4>
    </div>
    <div *ngIf="!loadingSpinner" class="card-body">
      <div class="row">
        <div class="col-md-2 form-group">
          <label>Código</label>
          <input [ngStyle]="temErro && formTpBeneficio.controls['Codigo'].hasError('required') || formTpBeneficio.controls['Codigo'].dirty && formTpBeneficio.controls['Codigo'].status == 'INVALID' ? {'border-color':'red'} : null" mask="0*" maxlength="3" type="text" formControlName="Codigo" class="form-control" name="codigo" placeholder="Código" />

          <span class="form-text text-danger"
                *ngIf="temErro && formTpBeneficio.controls['Codigo'].hasError('required')">
            Campo obrigatório
          </span>
        </div>
        <div class="col-md-6 form-group">
          <label>Descrição</label>
          <input [ngStyle]="temErro && formTpBeneficio.controls['Descricao'].hasError('required') || formTpBeneficio.controls['Descricao'].dirty && formTpBeneficio.controls['Descricao'].status == 'INVALID' ? {'border-color':'red'} : null" type="text" formControlName="Descricao" class="form-control" name="descricao" placeholder="Descrição" maxlength="50" />

          <span class="form-text text-danger"
                *ngIf="temErro && formTpBeneficio.controls['Descricao'].hasError('required')">
            Campo obrigatório
          </span>
        </div>

        <div class="col-md-2 form-group">
          <label>Data Inicial</label>
          <input [ngStyle]="temErro && formTpBeneficio.controls['DataInicial'].hasError('required') || formTpBeneficio.controls['DataInicial'].dirty && formTpBeneficio.controls['DataInicial'].status == 'INVALID' ? {'border-color':'red'} : null" type="date" formControlName="DataInicial" class="form-control calendario formato-data" name="dtinicial" style="padding-right: 0;"/>
          <span class="form-text text-danger"
                *ngIf="temErro && formTpBeneficio.controls['DataInicial'].hasError('required')">
            Campo obrigatório
          </span>
          <span class="form-text text-danger"
                *ngIf="formTpBeneficio.controls['DataInicial'].dirty && formTpBeneficio.controls['DataInicial'].hasError('data')">
            Data Inválida
          </span>
        </div>
        <div class="col-md-2 form-group">
          <label>Data Final</label>
          <div class="input-group">
            <input [ngStyle]="temErro && formTpBeneficio.controls['DataFinal'].hasError('required') || formTpBeneficio.controls['DataFinal'].dirty && formTpBeneficio.controls['DataFinal'].status == 'INVALID' ? {'border-color':'red'} :null" type="date" formControlName="DataFinal" class="form-control calendario formato-data" name="DataFinal" style="padding-right: 0;" />
          </div>
          <span class="form-text text-danger"
                *ngIf="temErro && formTpBeneficio.controls['DataFinal'].hasError('required')">
            Campo obrigatório
          </span>
          <span class="form-text text-danger"
                *ngIf="formTpBeneficio.controls['DataFinal'].dirty && formTpBeneficio.controls['DataFinal'].hasError('data')">
            Data Inválida
          </span>
        </div>
      </div>
      <div>
        <!--> Card tipo beneficiarios<-->
        <div *ngIf="!editar" class="col-md-12 form-group d-flex align-items-center">
          <label for="ativa-flags" class="m-0"> Usar Marcações Especiais <small>(opcional)</small></label>
          <input class="ml-1" type="checkbox" formControlName="especialCheckBox" id="ativa-flags" (change)="especialCheckBox()" value="s" />
        </div>
        <div *ngIf="editar" class="col-md-12 form-group d-flex align-items-center">
          <label for="ativa-flags" class="m-0">Alterar Marcações Especiais <small>(opcional)</small></label>
          <input class="ml-1" type="checkbox" formControlName="especialCheckBox" id="ativa-flags" (change)="especialCheckBox()" value="s" />
        </div>
        <div class="row pl-2" [style.display]="isVisibleFlag ? 'flex' : 'none'">
          <div class="col-md-3 col-sm-4 form-group">
            <input type="text" formControlName="F01" class="form-control" name="F01" [validation]="false" mask="0" placeholder="F01" />
          </div>
          <div class="col-md-3 col-sm-4 form-group">
            <input type="text" formControlName="F02" class="form-control" name="F02" [validation]="false" mask="0" placeholder="F02" />
          </div>
          <div class="col-md-3 col-sm-4 form-group">
            <input type="text" formControlName="F03" class="form-control" name="F03" [validation]="false" mask="0" placeholder="F03" />
          </div>
          <div class="col-md-3 col-sm-4 form-group">
            <input type="text" formControlName="F04" class="form-control" name="F04" [validation]="false" mask="0" placeholder="F04" />
          </div>
          <div class="col-md-3 col-sm-4 form-group">
            <input type="text" formControlName="F05" class="form-control" name="F05" [validation]="false" mask="0" placeholder="F05" />
          </div>
          <div class="col-md-3 col-sm-4 form-group">
            <input type="text" formControlName="F06" class="form-control" name="F06" [validation]="false" mask="0" placeholder="F06" />
          </div>
          <div class="col-md-3 col-sm-4 form-group">
            <input type="text" formControlName="F07" class="form-control" name="F07" [validation]="false" mask="0" placeholder="F07" />
          </div>
          <div class="col-md-3 col-sm-4 form-group">
            <input type="text" formControlName="F08" class="form-control" name="F08" [validation]="false" mask="0" placeholder="F08" />
          </div>
          <div class="col-md-3 col-sm-4 form-group">
            <input type="text" formControlName="F09" class="form-control" name="F09" [validation]="false" mask="0" placeholder="F09" />
          </div>
          <div class="col-md-3 col-sm-4 form-group">
            <input type="text" formControlName="F10" class="form-control" name="F10" [validation]="false" mask="0" placeholder="F10" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <p id="mensagem-tipobeneficiario"></p>
      </div>
    </div>
    <div class="card-footer">
      <div class="text-right">
        <!--> Card botões<-->
        <div class="btn-group">
          <button type="button" *ngIf="editar" (click)="cancelarEditar(beneficio)" class="btn btn-danger mr-2">
            <span>Cancelar</span>
          </button>
          <button type="submit" class="btn btn-primary mr-2">
            <span>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="table-responsive" >
  <div class="card ">
    <!--> Card div tabelas<-->
    <div class="card-header">
      <h3 class="card-title">Tipos de Beneficiários Cadastrados</h3>
    </div>
    <div class="card-body">
      <div class="table-responsive "style="max-height:45vh">
        <table class="table table-striped table-hover">
          <thead class="text-center">
            <tr class="text-center" style="white-space:nowrap">
              <th class="sticky-top  bg-white">Excluir/Editar</th>
              <th class="sticky-top  bg-white">Código</th>
              <th class="sticky-top  bg-white">Descrição</th>
              <th class="sticky-top  bg-white">Data Inicial</th>
              <th class="sticky-top  bg-white">Data Final</th>
            </tr>
          </thead>
          <tbody *ngIf="listaTipoBeneficiario && listaTipoBeneficiario.length > 0" id="lista-tipo-beneficiario">
            <tr *ngFor="let tipoColaborador of listaTipoBeneficiario" class="text-center" style="white-space:nowrap">
              <td> <button type="button" class="btn btn-danger mr-2" (click)="deletarTipoBeneficio(tipoColaborador.Codigo)"><i class="fa fa-trash" aria-hidden="true"></i></button>  <button type="button" class="btn bg-teal-400" (click)="carregarCampos(tipoColaborador)"><i class="fa fa-pencil" aria-hidden="true"></i></button> </td>
              <td>{{ tipoColaborador.Codigo }}</td>
              <td class="text-uppercase">{{ tipoColaborador.Descricao }}</td>
              <td>{{ tipoColaborador.DataInicial.substr(0, 10) }}</td>
              <td>{{ tipoColaborador.DataFinal.substr(0, 10) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="!listaTipoBeneficiario" class="row  d-flex justify-content-center align-items-center mt-3">
        <i class="fa fa-refresh fa-spin fa-2x "></i>
        <h4 class="ml-2">Aguarde...</h4>
      </div>
      <div *ngIf="listaTipoBeneficiario && listaTipoBeneficiario.length == 0">
        <div class="d-flex justify-content-center align-items-center mt-3">
          <h4>Não há dados para ser exibido</h4>
        </div>
      </div>
    </div>
  </div>
</div>
