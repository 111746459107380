import { Pipe, PipeTransform } from "@angular/core"
import { Usuario } from "./cadastro-usuario.component";

@Pipe({name : 'filtrarUsuario'})
export class filtrarUsuario implements PipeTransform{
    transform(usuario : Usuario[], filtrarUsuario : string) {
        filtrarUsuario = filtrarUsuario.toLowerCase()
        if (filtrarUsuario != "") {
           usuario = usuario.filter(us => us.Nome.toLowerCase().includes(filtrarUsuario) || us.Cpf.includes(filtrarUsuario) )
           return usuario
           
        }else{
            return usuario
        }
    }

}