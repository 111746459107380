<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<div class="card">
  <div class="card-header">
    <h3 class="font-weight-semibold">
      <i
        class="fa fa-arrow-left pr-3 btn"
        aria-hidden="true"
        (click)="voltarBusca()"
        style="cursor: pointer; bottom: 2px"
      ></i
      >Cadastro de Estágio
    </h3>
  </div>
  <form #estagio="ngForm" [formGroup]="formEstagio" (ngSubmit)="submitEstagio()">
    <div class="card-body">
      <div class="row" *ngIf="matricula && navigation">
        <div class="form-group col-md-2">
          <label class="justify-content-start font-weight-semibold">Matricula </label>
          <input
            type="text"
            placeholder="Funcionario"
            class="form-control"
            disabled
            [value]="matricula"
          />
        </div>

        <div class="form-group col-md-10">
          <label class="justify-content-start font-weight-semibold">Nome do Colaborador </label>
          <input
            type="text"
            placeholder="Funcionario"
            class="form-control"
            disabled
            [value]="navigation.nome"
          />
        </div>
      </div>
      <hr />
      <fieldset>
        <legend><h6 class="font-weight-semibold">Dados do Estágio</h6></legend>
        <div class="row">
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Número do Processo</label>
            <input
              type="text"
              formControlName="numeroProcesso"
              form
              class="form-control"
              placeholder="Número processo"
              [ngClass]="
                formEstagio.get('numeroProcesso').invalid &&
                (temErro || formEstagio.get('numeroProcesso').touched)
                  ? 'is-invalid'
                  : null
              "
              maxlength="30"
              mask="0*"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('numeroProcesso').invalid &&
                (temErro || formEstagio.get('numeroProcesso').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Natureza do Estágio</label>
            <select
              class="form-control"
              formControlName="natureza"
              [ngClass]="
                formEstagio.get('natureza').invalid &&
                (temErro || formEstagio.get('natureza').touched)
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option
                *ngFor="let naturezaEstagio of listaNaturezaEstagio"
                value="{{ naturezaEstagio.Codigo }}"
              >
                {{ naturezaEstagio.Descricao }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('natureza').invalid &&
                (temErro || formEstagio.get('natureza').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Nível do Estágio</label>
            <select
              class="form-control"
              formControlName="nivel"
              [ngClass]="
                formEstagio.get('nivel').invalid && (temErro || formEstagio.get('nivel').touched)
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option
                *ngFor="let nivelEstagio of listaNivelEstagio"
                value="{{ nivelEstagio.Codigo }}"
              >
                {{ nivelEstagio.Descricao }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('nivel').invalid && (temErro || formEstagio.get('nivel').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Área de Atuação</label>
            <input
              type="text"
              class="form-control"
              placeholder="Área de atuação"
              formControlName="areaAtuacao"
              [ngClass]="
                formEstagio.get('areaAtuacao').invalid &&
                (temErro || formEstagio.get('areaAtuacao').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('areaAtuacao').invalid &&
                (temErro || formEstagio.get('areaAtuacao').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Número da Apólice</label>
            <input
              type="text"
              class="form-control"
              placeholder="Número apólice"
              formControlName="numApolice"
              [ngClass]="
                formEstagio.get('numApolice').invalid &&
                (temErro || formEstagio.get('numApolice').touched)
                  ? 'is-invalid'
                  : null
              "
              mask="0*"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('numApolice').invalid &&
                (temErro || formEstagio.get('numApolice').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Função do Estágio</label>
            <select
              class="form-control"
              formControlName="funcao"
              [ngClass]="
                formEstagio.get('funcao').invalid && (temErro || formEstagio.get('funcao').touched)
                  ? 'is-invalid'
                  : null
              "
              (change)="setarValorBolsa()"
            >
              <option value="">Selecione...</option>
              <option *ngFor="let funcao of listaFuncoesEstagio" value="{{ funcao.Codigo }}">
                {{ funcao.Codigo }} - {{ funcao.Descricao }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('funcao').invalid && (temErro || formEstagio.get('funcao').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Valor da Bolsa</label>
            <input
              type="text"
              class="form-control"
              placeholder="Valor da bolsa"
              mask="separator.2"
              [thousandSeparator]="'.'"
              [decimalMarker]="','"
              prefix="R$ "
              formControlName="valorBolsa"
              [ngClass]="
                formEstagio.get('valorBolsa').invalid &&
                (temErro || formEstagio.get('valorBolsa').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('valorBolsa').invalid &&
                (temErro || formEstagio.get('valorBolsa').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Previsão de Término</label>
            <input
              type="date"
              formControlName="dataPrevisaoTermino"
              class="form-control"
              max="9999-12-31"
              [ngClass]="
                formEstagio.get('dataPrevisaoTermino').invalid &&
                (temErro || formEstagio.get('dataPrevisaoTermino').touched)
                  ? 'is-invalid'
                  : null
              "
              style="padding-right: 0"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('dataPrevisaoTermino').invalid &&
                (temErro || formEstagio.get('dataPrevisaoTermino').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Data Inicial</label>
            <input
              type="date"
              formControlName="dataInicial"
              class="form-control"
              [readonly]="editar"
              max="9999-12-31"
              (change)="verificaData('dataInicial', 'dataFinal')"
              [ngClass]="
                formEstagio.get('dataInicial').invalid &&
                (temErro || formEstagio.get('dataInicial').touched)
                  ? 'is-invalid'
                  : null
              "
              style="padding-right: 0"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('dataInicial').invalid &&
                (temErro || formEstagio.get('dataInicial').touched)
              "
            >
              {{
                formEstagio.get('dataInicial').hasError('dataMenorQueAtual')
                  ? 'Data deve ser maior que o dia atual'
                  : 'Campo Obrigatório'
              }}
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Data Final</label>
            <input
              type="date"
              formControlName="dataFinal"
              class="form-control"
              [readonly]="editar"
              max="9999-12-31"
              (change)="verificaData('dataInicial', 'dataFinal')"
              [ngClass]="
                formEstagio.get('dataFinal').invalid &&
                (temErro || formEstagio.get('dataFinal').touched)
                  ? 'is-invalid'
                  : null
              "
              style="padding-right: 0"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('dataFinal').invalid &&
                (temErro || formEstagio.get('dataFinal').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>
          <h6 class="font-weight-semibold">Instituição de ensino</h6>
        </legend>
        <div class="row">
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">CNPJ</label>
            <select
              class="form-control"
              (change)="carregarInstEnsino()"
              formControlName="instEnsinoCnpj"
              [ngClass]="
                formEstagio.get('instEnsinoCnpj').invalid &&
                (temErro || formEstagio.get('instEnsinoCnpj').touched)
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">CNPJ</option>
              <option
                *ngFor="let instEnsino of listaInstituicaoEnsino"
                value="{{ instEnsino.CNPJ }}"
              >
                {{ instEnsino.CNPJ | cnpj }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('instEnsinoCnpj').invalid &&
                (temErro || formEstagio.get('instEnsinoCnpj').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-6 form-group">
            <label class="font-weight-semibold">Razão social</label>
            <input
              type="text"
              formControlName="instEnsinoRazaoSocial"
              class="form-control"
              placeholder="Razão social"
              readonly
              [ngClass]="
                formEstagio.get('instEnsinoRazaoSocial').invalid &&
                (temErro || formEstagio.get('instEnsinoRazaoSocial').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('instEnsinoRazaoSocial').invalid &&
                (temErro || formEstagio.get('instEnsinoRazaoSocial').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">CEP</label>
            <input
              type="text"
              formControlName="instEnsinoCep"
              class="form-control"
              placeholder="CEP"
              readonly
              mask="00.000-000"
              [ngClass]="
                formEstagio.get('instEnsinoCep').invalid &&
                (temErro || formEstagio.get('instEnsinoCep').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('instEnsinoCep').invalid &&
                (temErro || formEstagio.get('instEnsinoCep').touched)
              "
            >
              Campo Obrigatório
            </span>
            <!-- <span class="form-text" *ngIf="loadingCepInstEnsino">Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i></span> -->
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">UF</label>
            <input
              type="text"
              class="form-control"
              placeholder="UF"
              readonly
              formControlName="instEnsinoUf"
              [ngClass]="
                formEstagio.get('instEnsinoUf').invalid &&
                (temErro || formEstagio.get('instEnsinoUf').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('instEnsinoUf').invalid &&
                (temErro || formEstagio.get('instEnsinoUf').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Município</label>
            <input
              type="text"
              formControlName="instEnsinoMunicipio"
              class="form-control"
              placeholder="Logradouro"
              readonly
              [ngClass]="
                formEstagio.get('instEnsinoMunicipio').invalid &&
                (temErro || formEstagio.get('instEnsinoMunicipio').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('instEnsinoMunicipio').invalid &&
                (temErro || formEstagio.get('instEnsinoMunicipio').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Bairro</label>
            <input
              type="text"
              formControlName="instEnsinoBairro"
              class="form-control"
              placeholder="Bairro"
              readonly
              [ngClass]="
                formEstagio.get('instEnsinoBairro').invalid &&
                (temErro || formEstagio.get('instEnsinoBairro').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('instEnsinoBairro').invalid &&
                (temErro || formEstagio.get('instEnsinoBairro').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Logradouro</label>
            <input
              type="text"
              formControlName="instEnsinoLogradouro"
              class="form-control"
              placeholder="Logradouro"
              readonly
              [ngClass]="
                formEstagio.get('instEnsinoLogradouro').invalid &&
                (temErro || formEstagio.get('instEnsinoLogradouro').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('instEnsinoLogradouro').invalid &&
                (temErro || formEstagio.get('instEnsinoLogradouro').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Número</label>
            <input
              type="text"
              formControlName="instEnsinoNumero"
              class="form-control"
              placeholder="Número"
              readonly
              mask="0*"
              [ngClass]="
                formEstagio.get('instEnsinoNumero').invalid &&
                (temErro || formEstagio.get('instEnsinoNumero').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('instEnsinoNumero').invalid &&
                (temErro || formEstagio.get('instEnsinoNumero').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>
          <h6 class="font-weight-semibold">Agente de integração</h6>
        </legend>
        <div class="row">
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">CNPJ</label>
            <select
              (change)="carregarAgenteIntegracao()"
              class="form-control"
              formControlName="agenteIntegracaoCnpj"
              [ngClass]="
                formEstagio.get('agenteIntegracaoCnpj').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoCnpj').touched)
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">CNPJ</option>
              <option *ngFor="let agente of listaAgenteIntegracao" value="{{ agente.CNPJ }}">
                {{ agente.CNPJ | cnpj }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('agenteIntegracaoCnpj').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoCnpj').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-6 form-group">
            <label class="font-weight-semibold">Razão social</label>
            <input
              type="text"
              class="form-control"
              placeholder="Razão social"
              readonly
              formControlName="agenteIntegracaoRazaoSocial"
              [ngClass]="
                formEstagio.get('agenteIntegracaoRazaoSocial').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoRazaoSocial').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('agenteIntegracaoRazaoSocial').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoRazaoSocial').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">CEP</label>
            <input
              type="text"
              class="form-control"
              placeholder="CEP"
              readonly
              mask="00.000-000"
              formControlName="agenteIntegracaoCep"
              [ngClass]="
                formEstagio.get('agenteIntegracaoCep').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoCep').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('agenteIntegracaoCep').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoCep').touched)
              "
            >
              Campo Obrigatório
            </span>
            <!-- <span class="form-text" *ngIf="loadingCepAgentIntegracao">Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i></span> -->
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">UF</label>
            <input
              type="text"
              class="form-control"
              placeholder="UF"
              readonly
              formControlName="agenteIntegracaoUf"
              [ngClass]="
                formEstagio.get('agenteIntegracaoUf').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoUf').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('agenteIntegracaoUf').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoUf').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Município</label>
            <input
              type="text"
              class="form-control"
              placeholder="Município"
              readonly
              formControlName="agenteIntegracaoMunicipio"
              [ngClass]="
                formEstagio.get('agenteIntegracaoMunicipio').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoMunicipio').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('agenteIntegracaoMunicipio').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoMunicipio').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Bairro</label>
            <input
              type="text"
              class="form-control"
              placeholder="Bairro"
              readonly
              formControlName="agenteIntegracaoBairro"
              [ngClass]="
                formEstagio.get('agenteIntegracaoBairro').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoBairro').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('agenteIntegracaoBairro').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoBairro').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Logradouro</label>
            <input
              type="text"
              class="form-control"
              placeholder="Logradouro"
              readonly
              formControlName="agenteIntegracaoLogradouro"
              [ngClass]="
                formEstagio.get('agenteIntegracaoLogradouro').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoLogradouro').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('agenteIntegracaoLogradouro').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoLogradouro').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Número</label>
            <input
              type="text"
              class="form-control"
              placeholder="Número"
              readonly
              mask="0*"
              formControlName="agenteIntegracaoNumero"
              [ngClass]="
                formEstagio.get('agenteIntegracaoNumero').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoNumero').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('agenteIntegracaoNumero').invalid &&
                (temErro || formEstagio.get('agenteIntegracaoNumero').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>
          <h6 class="font-weight-semibold">Benefícios - Vale Transporte</h6>
        </legend>
        <div class="row">
          
          <!-- Campo 1 -->
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Opção 1</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <select
                  name="vtcod1"
                  class="form-control"
                  formControlName="vtcod1"
                  (change)="verificarSelecao('vtcod1', $event)"
                >
                  <option value="">Selecione...</option>
                  <option *ngFor="let vale of ValeTransporte" [value]="vale.Codigo">
                    {{ vale.Codigo }} - R${{ vale.Valor }}
                  </option>
                </select>
              </div>
              <input
                type="text"
                placeholder="QTD"
                name="vtval1"
                class="form-control"
                formControlName="vtval1"
                mask="0*"
                [ngClass]="{
                  'is-invalid': quantidadeObrigatoria.vtcod1 && 
                                formEstagio.get('vtval1').invalid && 
                                (formEstagio.get('vtval1').touched || formEstagio.get('vtval1').dirty)
                }"
                [required]="quantidadeObrigatoria.vtcod1"
                [disabled]="!quantidadeObrigatoria.vtcod1" 
              />
            </div>
          
            <span class="form-text text-danger" *ngIf="quantidadeObrigatoria.vtcod1 && formEstagio.get('vtval1').invalid && (formEstagio.get('vtval1').touched || formEstagio.get('vtval1').dirty)">
              Campo Obrigatório
            </span>
          </div>
          
      
          <!-- Campo 2 -->
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Opção 2</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <select
                  name="vtcod2"
                  class="form-control"
                  formControlName="vtcod2"
                  (change)="verificarSelecao('vtcod2', $event)"
                >
                  <option value="">Selecione...</option>
                  <option *ngFor="let vale of ValeTransporte" [value]="vale.Codigo">{{ vale.Codigo }} - R${{ vale.Valor }}</option>
                </select>
              </div>
              <input
                type="text"
                placeholder="QTD"
                name="vtval2"
                class="form-control"
                formControlName="vtval2"
                mask="0*"
                [ngClass]="{
                  'is-invalid': quantidadeObrigatoria.vtcod2 && 
                                formEstagio.get('vtval2').invalid && 
                                (formEstagio.get('vtval2').touched || formEstagio.get('vtval2').dirty)
                }"
                [required]="quantidadeObrigatoria.vtcod2"
                [disabled]="!quantidadeObrigatoria.vtcod1" 
              />
            </div>
            <span class="form-text text-danger" *ngIf="quantidadeObrigatoria.vtcod2 && formEstagio.get('vtval2').invalid && (formEstagio.get('vtval2').touched || formEstagio.get('vtval2').dirty)">
              Campo Obrigatório
            </span>
          </div>
      
          <!-- Campo 3 -->
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Opção 3</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <select
                  name="vtcod3"
                  class="form-control"
                  formControlName="vtcod3"
                  (change)="verificarSelecao('vtcod3', $event)"
                >
                  <option value="">Selecione...</option>
                  <option *ngFor="let vale of ValeTransporte" [value]="vale.Codigo">{{ vale.Codigo }} - R${{ vale.Valor }}</option>
                </select>
              </div>
              <input
                type="text"
                placeholder="QTD"
                name="vtval3"
                class="form-control"
                formControlName="vtval3"
                mask="0*"
                [ngClass]="{
                  'is-invalid': quantidadeObrigatoria.vtcod3 && 
                                formEstagio.get('vtval3').invalid && 
                                (formEstagio.get('vtval3').touched || formEstagio.get('vtval3').dirty)
                }"
                [required]="quantidadeObrigatoria.vtcod3"
                [disabled]="!quantidadeObrigatoria.vtcod1" 
              />
            </div>
            <span class="form-text text-danger" *ngIf="quantidadeObrigatoria.vtcod3 && formEstagio.get('vtval3').invalid && (formEstagio.get('vtval3').touched || formEstagio.get('vtval3').dirty)">
              Campo Obrigatório
            </span>
          </div>
      
          <!-- Campo 4 -->
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Opção 4</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <select
                  name="vtcod4"
                  class="form-control"
                  formControlName="vtcod4"
                  (change)="verificarSelecao('vtcod4', $event)"
                >
                  <option value="">Selecione...</option>
                  <option *ngFor="let vale of ValeTransporte" [value]="vale.Codigo">{{ vale.Codigo }} - R${{ vale.Valor }}</option>
                </select>
              </div>
              <input
                type="text"
                placeholder="QTD"
                name="vtval4"
                class="form-control"
                formControlName="vtval4"
                mask="0*"
                [ngClass]="{
                  'is-invalid': quantidadeObrigatoria.vtcod4 && 
                                formEstagio.get('vtval4').invalid && 
                                (formEstagio.get('vtval4').touched || formEstagio.get('vtval4').dirty)
                }"
                [required]="quantidadeObrigatoria.vtcod4"
                [disabled]="!quantidadeObrigatoria.vtcod1" 
              />
            </div>
            <span class="form-text text-danger" *ngIf="quantidadeObrigatoria.vtcod4 && formEstagio.get('vtval4').invalid && (formEstagio.get('vtval4').touched || formEstagio.get('vtval4').dirty)">
              Campo Obrigatório
            </span>
          </div>
      
        </div>
      </fieldset>
      
      

      
      

      <fieldset>
        <legend><h6 class="font-weight-semibold">Supervisor</h6></legend>
        <div class="row">
          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Matrícula</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Matrícula"
                mask="0*"
                formControlName="supervisorMatricula"
                [ngClass]="
                  formEstagio.get('supervisorMatricula').invalid &&
                  (temErro || formEstagio.get('supervisorMatricula').touched)
                    ? 'is-invalid'
                    : null
                "
              />
              <span class="input-group-append">
                <button
                  type="button"
                  class="input-group-text bg-blue border-blue"
                  (click)="buscarSupervisor()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </span>
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('supervisorMatricula').invalid &&
                (temErro || formEstagio.get('supervisorMatricula').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Empresa filial</label>
            <input
              type="text"
              class="form-control"
              placeholder="Empresa filial"
              readonly
              formControlName="supervisorEmpfil"
              [ngClass]="
                formEstagio.get('supervisorEmpfil').invalid &&
                (temErro || formEstagio.get('supervisorEmpfil').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('supervisorEmpfil').invalid &&
                (temErro || formEstagio.get('supervisorEmpfil').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Nº de Ordem</label>
            <input
              type="text"
              class="form-control"
              placeholder="Nº de Ordem"
              readonly
              formControlName="supervisorNumOrd"
              [ngClass]="
                formEstagio.get('supervisorNumOrd').invalid &&
                (temErro || formEstagio.get('supervisorNumOrd').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('supervisorNumOrd').invalid &&
                (temErro || formEstagio.get('supervisorNumOrd').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-6 form-group">
            <label class="font-weight-semibold">Nome</label>
            <input
              type="text"
              class="form-control"
              placeholder="Nome"
              readonly
              formControlName="supervisorNome"
              [ngClass]="
                formEstagio.get('supervisorNome').invalid &&
                (temErro || formEstagio.get('supervisorNome').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEstagio.get('supervisorNome').invalid &&
                (temErro || formEstagio.get('supervisorNome').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="card-footer text-right">
      <button (click)="voltarBusca()" class="btn btn-danger mr-2">
        {{ visualizacao ? 'Voltar' : 'Cancelar' }}
      </button>
      <button type="submit" class="btn btn-primary" *ngIf="!visualizacao">
        {{ editar ? 'Atualizar' : 'Submeter' }}
        <i class="fa fa-paper-plane ml-2"></i>
      </button>
    </div>
  </form>
</div>
