import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { CadastroCessaoService } from './cadastro-cessao.service';
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { Parametro } from 'src/app/service/interfaces/Parametro';
import Swal from 'sweetalert';
import { NavigationExtras, Router } from '@angular/router';
import { ErroService } from 'src/app/service/erros/erro.service';
//import { Router } from '@angular/compiler/src/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Data } from 'src/app/service/interfaces/Data';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from 'src/app/componentes/componentes-funcionais/alerta-modal/alerta-modal.component';
import { ContratosCessao } from 'src/app/service/interfaces/ContratosCessao';
import { validarDataMaiorOuIgual } from '../../processos/data-validator';
import { dataAtualOuAnteriorValidator } from 'src/app/service/Validators/dataMenorIgualValidator';
import { AgenteIntegracao } from 'src/app/service/interfaces/AgenteIntegracao';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { EnderecoViaCep } from '../../cadastro-publico/models/interfaces';
import { cnpjValidator } from 'src/app/service/Validators/cnpjValidator';
import { ComplementoCadastralService } from '../complemento-cadastral.service';

@Component({
  selector: 'app-cadastro-cessao',
  templateUrl: './cadastro-cessao.component.html',
  styleUrls: ['./cadastro-cessao.component.css'],
})
export class CadastroCessaoComponent implements OnInit {
  numeroOrdem: string;
  temErro: boolean = false;
  editar: boolean = false;
  visualizacao: boolean = false;
  cargoCBO: string = '';
  localStorage: LocalStorage;
  formCessao: FormGroup;
  listaRegimeTrabalho: Parametro[];
  listaRegimePrevidencia: Parametro[];
  listaModalidade: Parametro[];
  listaCategoria: Parametro[];
  listaCargoCbo: Parametro[];
  listaInstitutoPrevidencia: Parametro[];
  listaIdClassificacaoAto: any[];
  listaCedente: AgenteIntegracao[];
  remuneracaoContinua: boolean = false;
  colaborador: Cadastro;
  navigation: any;
  loadingCep: boolean;
  constructor(
    public fb: FormBuilder,
    private cessaoService: CadastroCessaoService,
    private complementoCadastralService: ComplementoCadastralService,
    private route: Router,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.localStorage = this.decryptLocalStorage();
    this.numeroOrdem = this.localStorage.NumeroOrdemUsuario.toString();
    this.formCessao = this.fb.group({
      numeroProcesso: ['', Validators.required],
      numeroProcessoAnterior: [''],
      regimeTrabalho: ['', Validators.required],
      regimePrevidencia: ['', Validators.required],
      modalidade: ['', Validators.required],
      dataInicial: ['', [Validators.required, dataAtualOuAnteriorValidator()]],
      dataFinal: [''],
      origemDataAdmCedente: ['', Validators.required],
      origemCategoria: ['', Validators.required],
      cnpjCedente: ['', [Validators.required, cnpjValidator()]],
      matriculaCedente: [''],
      cboCargo: ['', Validators.required],
      cargo: ['', Validators.required],
      cargoComissionado: [''],
      cboComissao: [''],

      aliquota: ['', Validators.required],
      patronal: ['', Validators.required],
      institutoPrevidencia: ['', Validators.required],
      remuneracaoContinua: [false],

      cedenteRazaoSocial: ['', Validators.required],
      cedenteCep: ['', Validators.required],
      cedenteLogradouro: ['', Validators.required],
      cedenteTipoLogradouro: [''],
      cedenteNumero: [''],
      cedenteMunicipio: [''],
      cedenteBairro: ['', Validators.required],
      cedenteUf: ['', Validators.required],
      cedenteComplemento: [''],
      cedenteTelefone: [''],
      cedenteEmail: [''],
      cedenteContato: [''],

      idClassificacaoAto: [''],
      numeroDoe: [''],
      dtDoe: [''],
      numeroAto: [''],
      dtAto: [''],
    });
    this.buscarParametro();

    if (history.state.data) {
      console.log('history state', history.state.data);
      this.navigation = history.state.data;
      this.numeroOrdem = history.state.data.cessao.NumeroOrdem;
      this.colaborador = history.state.data.colaborador;
      // if (history.state.data.cessao) {
      // } else {
      //   this.numeroOrdem = history.state.data.NumeroOrdem;
      // }
      console.log('numord', this.numeroOrdem);
      this.editar = history.state.data.editar;
      if (history.state.data.visualizacao) {
        this.visualizacao = true;
        this.formCessao.disable();
      }
      this.cargoCBO = this.editar
        ? history.state.data.cessao.Cargo
        : history.state.data.cessao.CodCBO;
      console.log(this.numeroOrdem, this.editar, this.cargoCBO);

      history.state.data.cessao.NumeroProcesso
        ? this.carregarCessao(history.state.data.cessao)
        : this.formCessao.get('cboCargo').setValue(this.cargoCBO);

      this.formCessao.get('cboCargo').disable();

      // !history.state.data.cessao.ContratosCessao
      //   ? this.carregarCessao(history.state.data.cessao)
      //   : ''; //caso seja pra adicionar nova cessao
    } else {
      this.route.navigate(['/complemento-cadastral/cessao']);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('mudancas');
    if (changes.visualizacao) {
      this.visualizacao = changes.visualizacao.currentValue;
      console.log('Novo valor recebido:', this.visualizacao);
      // Faça algo com o novo valor...
    }
  }

  buscarParametro(): void {
    this.spinner.show();
    const empresaFilial = this.localStorage.FilialAtual.toString();
    this.cessaoService.buscarParametros(empresaFilial).subscribe(
      (data: any) => {
        this.listaRegimeTrabalho = data.RegimesTrabalho;
        this.listaRegimePrevidencia = data.RegimesPrevidencia;
        this.listaModalidade = data.Modalidades;
        this.listaCargoCbo = data.CargosCBO;
        this.listaCategoria = data.CategoriasTrabalhador;
        this.listaCedente = data.AgenteIntegracao;
        this.listaInstitutoPrevidencia = data.InstitutoPrevidencia;
        console.log('cargo cbo', this.cargoCBO);
        console.log(data);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
    this.spinner.show();
    this.cessaoService.buscarClassificacaoAto().subscribe(
      (data: any) => {
        this.listaIdClassificacaoAto = data;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  carregarCessao(cessao: ContratosCessao): void {
    console.log('carregar cessao', cessao);
    this.formCessao.setValue({
      numeroProcesso: cessao.NumeroProcesso,
      regimeTrabalho: cessao.TipoRegimeTrabalho,
      numeroProcessoAnterior: cessao.NumeroProcessoAnterior,
      regimePrevidencia: cessao.TipoRegimePrevidencia,
      modalidade: cessao.Modalidade,
      dataInicial: this.corrigeDatas(cessao.DataInicial.ToDate),
      dataFinal: this.corrigeDatas(cessao.DataFinal.ToDate),
      origemDataAdmCedente: this.corrigeDatas(cessao.DataAdmissaoCedente.ToDate),
      origemCategoria: cessao.CategoriaOrigem,
      cnpjCedente: cessao.CnpjCedente,
      //novo in
      cedenteRazaoSocial: cessao.DescricaoCedente,
      cedenteCep: cessao.Endereco.Cep,
      cedenteUf: cessao.Endereco.UF,
      cedenteMunicipio: cessao.Endereco.Cidade,
      cedenteBairro: cessao.Endereco.Bairro,
      cedenteLogradouro: cessao.Endereco.Logradouro,
      cedenteNumero: cessao.Endereco.Numero != 0 ? cessao.Endereco.Numero : '',
      cedenteComplemento: cessao.Endereco.Complemento,
      cedenteTelefone:
        cessao.Contato.Telefone === 0
          ? ''
          : cessao.Contato.DDDTelefone.toString() + cessao.Contato.Telefone.toString(),
      cedenteEmail: cessao.Contato.Email1,
      cedenteContato: cessao.Contato.ContatoNome,
      cedenteTipoLogradouro: '0',
      //novo fim
      matriculaCedente: cessao.MatriculaCedente,
      cboCargo: cessao.Cargo,
      cargo: cessao.CargoDescricao,
      cargoComissionado: cessao.CargoComissionadoDescricao,
      cboComissao: cessao.CargoComissionado == '0' ? '' : cessao.CargoComissionado,
      //novo in
      aliquota: cessao.Aliquota,
      patronal: cessao.Patronal,
      institutoPrevidencia: cessao.InformacaoInstitutoPrev,
      remuneracaoContinua: cessao.InformacaoRemuneracao == 'S' ? true : false,
      numeroDoe: cessao.DiarioOficial.Numero,
      dtDoe:
        cessao.DiarioOficial.DataDiarioOficial.ToDate != ''
          ? this.corrigeDatas(cessao.DiarioOficial.DataDiarioOficial.ToDate)
          : '',
      idClassificacaoAto: cessao.Ato.ClassificacaoAto,
      numeroAto: cessao.Ato.Numero,
      dtAto: cessao.Ato.Data.ToDate != '' ? this.corrigeDatas(cessao.Ato.Data.ToDate) : '',
      //novo fim
    });
    cessao.InformacaoRemuneracao == 'S' ? this.mudarRemuneracaoContinua() : null;
    cessao.Endereco.Cep ? this.carregarCep : null;
  }

  verificaData(dataInicial: AbstractControl, dataFinal: AbstractControl) {
    if (dataInicial.value && dataFinal.value) {
      const dataInicialDate = new Date(dataInicial.value);
      const dataFinalDate = new Date(dataFinal.value);

      if (dataInicialDate >= dataFinalDate) {
        dataFinal.setValue('');
        Swal('Atenção', 'A data final deve ser posterior à inicial.', 'warning');
      }
    }
  }

  mudarRemuneracaoContinua() {
    this.remuneracaoContinua = !this.remuneracaoContinua;
    this.formCessao.get('remuneracaoContinua').setValue(this.remuneracaoContinua);
  }

  carregarCep(): void {
    const cep = this.formCessao.get('cedenteCep').value;
    if (cep.length == 8) {
      this.loadingCep = true;
      this.cessaoService.buscarCep(cep).subscribe(
        (data: EnderecoViaCep) => {
          this.formCessao.get('cedenteUf').setValue(data.uf);
          this.formCessao.get('cedenteMunicipio').setValue(data.cidade);
          this.formCessao.get('cedenteBairro').setValue(data.bairro);
          this.formCessao.get('cedenteLogradouro').setValue(data.logradouro);
          this.loadingCep = false;
          if (!data.Valido) {
            new AlertaModal('erro', 'Atenção', 'CEP inválido', 'p');
            this.formCessao.get('cedenteCep').setErrors({ invalid: true });
          }
        },
        (error) => {
          this.loadingCep = false;
        }
      );
    } else {
      this.formCessao.get('cedenteUf').setValue('');
      this.formCessao.get('cedenteMunicipio').setValue('');
      this.formCessao.get('cedenteBairro').setValue('');
      this.formCessao.get('cedenteLogradouro').setValue('');
    }
  }

  submitCessao(): void {
    this.verificaData(this.formCessao.get('dataInicial'), this.formCessao.get('dataFinal'));
    if (this.formCessao.invalid) {
      new AlertaModal('alerta', 'Atenção!', 'Preencha todos os campos corretamente.', 'p');
      this.temErro = true;
    } else {
      if (
        this.formCessao.get('cboCargo').value === '' &&
        this.formCessao.get('cboComissionado').value === ''
      ) {
        new AlertaModal(
          'alerta',
          'Atenção!',
          'O campo de cargo ou cargo comisionado deve ser preenchido',
          'p'
        );
      } else {
        const cessao = this.montarCessao();
        console.log('cessao enviada', cessao, this.formCessao);

        this.editar ? this.editarCessao(cessao) : this.salvarCessao(cessao);
      }
    }
  }

  editarCessao(cessao: ContratosCessao): void {
    this.spinner.show();

    this.cessaoService.editarCessao(cessao, this.numeroOrdem).subscribe(
      async (data) => {
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'A cessão foi editada!', 'p');
        this.route.navigate(['/complemento-cadastral/cessao']);
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  salvarCessao(cessao: ContratosCessao): void {
    this.spinner.show();
    this.cessaoService.salvarCessao(cessao, this.numeroOrdem).subscribe(
      async (data) => {
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'A cessão foi inserida!', 'p');
        this.route.navigate(['/complemento-cadastral/cessao']);
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  montarCessao(): ContratosCessao {
    return {
      Cargo: this.formCessao.get('cboCargo').value,
      CargoComissionado: this.formCessao.get('cboComissao').value,
      CargoComissionadoDescricao: this.formCessao.get('cargoComissionado').value,
      CargoDescricao: this.formCessao.get('cargo').value,
      CategoriaOrigem: this.formCessao.get('origemCategoria').value,
      CnpjCedente: this.formCessao.get('cnpjCedente').value,
      DescricaoCedente: this.formCessao.get('cedenteRazaoSocial').value,
      Endereco: {
        TipoLogradouro: this.formCessao.get('cedenteTipoLogradouro').value
          ? this.formCessao.get('cedenteTipoLogradouro').value
          : '',
        Logradouro: this.formCessao.get('cedenteLogradouro').value,
        Numero: this.formCessao.get('cedenteNumero').value,
        Cep: this.formCessao.get('cedenteCep').value,
        Complemento: this.formCessao.get('cedenteComplemento').value,
        Bairro: this.formCessao.get('cedenteBairro').value,
        UF: this.formCessao.get('cedenteUf').value,
        Cidade: this.formCessao.get('cedenteMunicipio').value,
      },
      Contato: {
        DDDTelefone: parseInt(this.formCessao.get('cedenteTelefone').value.substring(0, 2)),
        Telefone: parseInt(this.formCessao.get('cedenteTelefone').value.substring(2)),
        DDDCelular: 0,
        Celular: 0,
        Email1: this.formCessao.get('cedenteEmail').value,
        Email2: '',
        ContatoNome: this.formCessao.get('cedenteContato').value,
      },
      DataAdmissaoCedente: new Data(this.formCessao.get('origemDataAdmCedente').value),
      DataFinal:
        this.formCessao.get('dataFinal').value !== ''
          ? new Data(this.formCessao.get('dataFinal').value)
          : new Data('00000000'),
      DataInicial: new Data(this.formCessao.get('dataInicial').value),
      MatriculaCedente: this.formCessao.get('matriculaCedente').value,
      Modalidade: this.formCessao.get('modalidade').value,
      NumeroProcesso: this.formCessao.get('numeroProcesso').value,
      NumeroProcessoAnterior: this.formCessao.get('numeroProcessoAnterior').value,
      TipoRegimePrevidencia: this.formCessao.get('regimePrevidencia').value,
      TipoRegimeTrabalho: this.formCessao.get('regimeTrabalho').value,
      Ato: {
        AnoAto: '',
        ClassificacaoAto: this.formCessao.get('idClassificacaoAto').value,
        Data: this.formCessao.get('dtAto').value
          ? new Data(this.formCessao.get('dtAto').value)
          : null,
        Numero: this.formCessao.get('numeroAto').value,
      },
      DiarioOficial: {
        DataDiarioOficial: this.formCessao.get('dtDoe').value
          ? new Data(this.formCessao.get('dtDoe').value)
          : null,
        Numero: this.formCessao.get('numeroDoe').value,
      },
      Aliquota: this.formCessao.get('aliquota').value,
      Patronal: this.formCessao.get('patronal').value,
      InformacaoInstitutoPrev: this.formCessao.get('institutoPrevidencia').value,
      InformacaoRemuneracao: this.formCessao.get('remuneracaoContinua').value == true ? 'S' : 'N',
    };
  }
  converteDatas(datas) {
    var [ano, mes, dia] = datas.split('-');
    return `${dia}/${mes}/${ano}`;
  }

  corrigeDatas(datas) {
    var [dia, mes, ano] = datas.split('/');
    return `${ano}-${mes}-${dia}`;
  }

  voltarBusca() {
    const navigationExtras: NavigationExtras = {
      state: {
        usuario: this.navigation,
      },
    };
    this.route.navigate(['/complemento-cadastral/cessao'], navigationExtras);
    this.complementoCadastralService.emitChange({
      tipoComplemento: 'Cessão',
      numord: this.numeroOrdem,
    });
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }

  mascaraAliquotaPatronal(valor, formcontrol: string) {
    var valorAlterado = valor.target.value;
    valorAlterado = valorAlterado.replace(/\D/g, ''); // Remove todos os não dígitos
    valorAlterado = valorAlterado.replace(/^0+/, '');
    if (valorAlterado.length > 6) {
      valorAlterado = valorAlterado.slice(0, -1);
    }
    valorAlterado = valorAlterado.padStart(6, '0'); //adiciona 0 iniciais
    valorAlterado = valorAlterado.replace(/(\d+)(\d{4})$/, '$1,$2');
    this.formCessao.get(formcontrol).setValue(valorAlterado);
  }
}
