import { RetornoFerias } from 'src/app/service/interfaces/RetornoFerias';
import { Pipe, PipeTransform } from '@angular/core';
import { Ferias } from 'src/app/service/interfaces/Ferias';



@Pipe({
  name: 'situacaoFeriasFiltro',

})
// pipe usado para filtrar as listas atravez do codigo
export class situacaoFeriasFiltroPipe implements PipeTransform {
  transform(colaborador: RetornoFerias[], situacao: number): any {
    if ( situacao == 14 || situacao == 15) {
      return colaborador.filter(colaborador => colaborador.Parcelas.find(fer => parseInt(fer.Status.Codigo) == situacao))
        .sort((a: RetornoFerias, b: RetornoFerias) => {
          return a.DadosColaborador.NomeColaborador > b.DadosColaborador.NomeColaborador ? 1 :
            a.DadosColaborador.NomeColaborador < b.DadosColaborador.NomeColaborador ? -1 : 0;
        });
    } else {
      return colaborador.filter(colaborador => Number(colaborador.Parcelas[0].Status.Codigo) === 1 || Number(colaborador.Parcelas[0].Status.Codigo)===3)
        .sort((a: RetornoFerias, b: RetornoFerias) => {
          return a.DadosColaborador.NomeColaborador > b.DadosColaborador.NomeColaborador ? 1 :
            a.DadosColaborador.NomeColaborador < b.DadosColaborador.NomeColaborador ? -1 : 0;
        });
    }

  }

}



