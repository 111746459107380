import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Validacao {

  constructor() { }

  validarCpf(c) {
        
    var i;
  
    if((c = c.replace(/[^\d]/g,"")).length != 11)
    return false;
  
    if (c == "00000000000")
    return false;
  
    var r;
    var s = 0;   
  
    for (i=1; i<=9; i++)
    s = s + parseInt(c[i-1]) * (11 - i); 
  
    r = (s * 10) % 11;
  
    if ((r == 10) || (r == 11)) 
    r = 0;
  
    if (r != parseInt(c[9]))
    return false;
  
    s = 0;
  
    for (i = 1; i <= 10; i++)
    s = s + parseInt(c[i-1]) * (12 - i);
  
    r = (s * 10) % 11;
  
    if ((r == 10) || (r == 11)) 
    r = 0;
  
    if (r != parseInt(c[10]))
    return false;
  
    return true;
  }

  validarPis(pis){

    let multiplicadorBase = "3298765432";
    let total = 0;
    let resto = 0;
    let multiplicando = 0;
    let multiplicador = 0;
    let digito = 99;

    // Retira a mascara
    let numeroPIS = pis.replace(/[^\d]+/g, '');

    if (numeroPIS.length !== 11 ||
      numeroPIS === "00000000000" ||
      numeroPIS === "11111111111" ||
      numeroPIS === "22222222222" ||
      numeroPIS === "33333333333" ||
      numeroPIS === "44444444444" ||
      numeroPIS === "55555555555" ||
      numeroPIS === "66666666666" ||
      numeroPIS === "77777777777" ||
      numeroPIS === "88888888888" ||
      numeroPIS === "99999999999") {
      //alert('false'); 
      return false;
      }else{
        for (var i = 0; i < 10; i++) {
          multiplicando = parseInt(numeroPIS.substring(i, i + 1));
          multiplicador = parseInt(multiplicadorBase.substring(i, i + 1));
          total += multiplicando * multiplicador;
        }
     
        resto = 11 - total % 11;
        resto = resto === 10 || resto === 11 ? 0 : resto;
        digito = parseInt("" + numeroPIS.charAt(10));
        if (resto === digito){
            //alert('true');
            return  true
            
        }else{
            //alert('false');
            return  false
        }
      }
  }

  converteDatas(data,tipo){
    // 0 para converter em yyyymmdd
    // 1 para converter em dd/mm/yyyy

    var dia, mes, ano;

    dia = data.substring(0, 2);
    mes = data.substring(2, 4);
    ano = data.substring(4, 8);
    
    if(tipo == 0){
      return ano + mes + dia;
    }else{
      return dia + '/' + mes + '/' + ano;
    }
  }

  // Verifica se data é valida
  validarData(data) {
    var reg = /[^\d\/\.]/gi;  // Mascara = dd/mm/aaaa | dd.mm.aaaa
    var valida = data.replace(reg,'');    // aplica mascara e valida só numeros
    if (valida && valida.length == 10) {  // é válida, então ;)
      var ano = data.substr(6),
      mes = data.substr(3,2),
      dia = data.substr(0,2),
      M30 = ['04','06','09','11'],
      v_mes = /(0[1-9])|(1[0-2])/.test(mes),
      v_ano = /(19[1-9]\d)|(20\d\d)|2100/.test(ano),
      rexpr = new RegExp(mes),
      fev29 = ano % 4? 28: 29;

        if (v_mes && v_ano) {
          if (mes == '02') return (dia >= 1 && dia <= fev29);
          else if (rexpr.test(M30.toString())) return /((0[1-9])|([1-2]\d)|30)/.test(dia);
          else return /((0[1-9])|([1-2]\d)|3[0-1])/.test(dia);
        }
    }
    return false;
  }
  
}


