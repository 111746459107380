import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Input } from '@angular/core';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  localStorage: LocalStorage = new LocalStorage();
  constructor(private myRoute: Router) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }

  @Input() loading: boolean = false;

  verificaLogin() {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      if (this.localStorage.Sessao != "" && this.localStorage.Sessao != null && this.localStorage.Sessao != undefined) {
        if (!this.localStorage.Login) {
          this.localStorage.Login = true;
          localStorage.setItem("util", new Crypto().encryptUsingAES256(JSON.stringify(this.localStorage)));
        }
      }
    }
  }

  validaSessao() {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      if (this.localStorage.Sessao == "" || this.localStorage.Sessao == null || this.localStorage.Sessao == undefined) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  validaLogin() {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      if (!this.localStorage.Login) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  logout() {
    localStorage.clear();
    this.myRoute.navigate(["login"]);
    //window.location.href = "/";
  }
}
