import { Pipe, PipeTransform } from '@angular/core';
import { HistoricoTransferencia } from './historico-transferencia';

@Pipe({
  name: 'filtroHistoricoTransferencia'
})
export class FiltroHistoricoTransferenciaPipe implements PipeTransform {

  transform(historicoTransferencia: HistoricoTransferencia[], filtroRegistro: string) {
    filtroRegistro = filtroRegistro.toLowerCase()
    if (filtroRegistro != "") {
      historicoTransferencia = historicoTransferencia.filter(registro => registro.CargoDestino.toLowerCase().includes(filtroRegistro) || String(registro.MatriculaDestino).includes(filtroRegistro) ||
        registro.CustoDestino.toLowerCase().includes(filtroRegistro) || registro.FuncaoDestino.toLowerCase().includes(filtroRegistro) || registro.LotacaoDestino.toLowerCase().includes(filtroRegistro) ||
        String(registro.MatriculaOrigem).includes(filtroRegistro)
      );
      return historicoTransferencia
    } else {
      return historicoTransferencia
    }
  }

}
