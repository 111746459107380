import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AcessoService } from '../../../service/acesso/acesso.service';
import {HttpHeaders, HttpClientModule ,} from '@angular/common/http';
import { objetoEmailLink } from './classes/objetoEmailLink';

@Injectable({
  providedIn: 'root'
})
export class RecuperarSenhaService {


  headers = new HttpHeaders();
  url_acesso = this.acesso.validarUrl();

  constructor(private http: HttpClient, private acesso: AcessoService) { }

  buscarIp() {
    return this.http.get('https://api.ipify.org?format=json');
  }

  enviarEmail(email: string){
    var objeto = new objetoEmailLink();
    objeto.Email = email;
    objeto.Url = "http://"+ window.location.href.split('/')[2];
    return this.http.post(`${this.url_acesso}usuario/recuperar-senha/email`, objeto, {headers: this.headers});
  }
}