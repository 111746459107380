import { Pipe, PipeTransform } from "@angular/core"


@Pipe({ name: 'filtrarVerba' })
export class filtrarVerba implements PipeTransform {
  transform(listaFinanceiro: any, event: Event) {
    var filtrarVerba = listaFinanceiro != undefined && event ? (event.target as HTMLInputElement).value : "";
    if (filtrarVerba != "") {
      listaFinanceiro = listaFinanceiro.filter(verb => verb.CodigoVerba.includes(filtrarVerba))
      return listaFinanceiro

    } else {
      return listaFinanceiro
    }
  }

}
