        <h3>Upload de Documentos</h3>
    <div >
        <table class="table">
            <thead>
                <tr>
                <th scope="col">Tipo</th>
                <th scope="col">Documento</th>
                <th width="7" scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let teste of filesUpload">
                    <th scope="row">
                        <select class="form-control" (change)="escolheTipo($event, filesUpload.indexOf(teste))" >
                            <option [value]="0">Selecione...</option>
                            <option [value]="tipo.Tipo" *ngFor="let tipo of tipos">{{tipo.Descricao}}</option>
                        </select>
                    </th>
                    <td >
                        <div class="row d-flex align-items-center">
                            <button (click)="btUpload(filesUpload.indexOf(teste))" class="btn btn-primary"><i class="fa fa-plus" aria-hidden="true"></i></button>
                            <p class="ml-1 font-weight-semibold" id="imageName {{filesUpload.indexOf(teste) }}">Inserir um novo documento</p>
                        </div>
                        
                        <input class="form-control" type="file" style="display: none;" (change)="detectFiles($event,filesUpload.indexOf(teste))" id={{filesUpload.indexOf(teste)}}  >
                    </td>
                    <td class="mb-1">
                        <button *ngIf='filesUpload.indexOf(teste) != filesUpload.length -1' (click)="delete(filesUpload.indexOf(teste))" class="btn btn-danger"><i class="fa fa-trash" aria-hidden="true"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- <div class="text-right mb-5 mr-3">
        <button class="btn btn-primary" (click)="salvar()"> Enviar Documentos</button>
    </div> -->


