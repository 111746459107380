import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ContrachequeService } from './contracheque.service';
import { Data } from 'src/app/service/interfaces/Data';
import { KeyValue } from '@angular/common';
import { ErroService } from 'src/app/service/erros/erro.service';
import { Router } from '@angular/router';
import { ProfileService } from '../profile/profile.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipoUsuario } from '../../service/enum/tipoUsuario';
import Swal from 'sweetalert';
import { Parametro } from 'src/app/service/interfaces/Parametro';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';

@Component({
  selector: 'app-contracheque',
  templateUrl: './contracheque.component.html',
  styleUrls: ['./contracheque.component.css'],
})
export class ContrachequeComponent implements OnInit {
  localStorage: LocalStorage;

  formContracheque: UntypedFormGroup;
  formcolaborador: UntypedFormGroup;
  formModal: UntypedFormGroup;
  formTeste: UntypedFormGroup;

  listaVerba: Verba[] = [];
  listaTipoProcessamento: Parametro[] = [];
  listaMatricula: Matricula[] = [];
  tipoProcessamentoComp: any[];

  totalContracheque: any;
  usuario: object;
  numOrdem: string;
  matriculaSelecionada: number;
  relatorioB64: string;
  tipoUsuario: string;
  cadastroColaborador: Cadastro;
  cpfColaborador: string;
  matricula: number = null;
  tipoUsuarioEnum = TipoUsuario;
  // tipoDaBusca: number = 0;

  loadingContracheque: boolean = false;
  loadingMatriculas: boolean = false;
  loadingDownload: boolean = false;
  loadingTipoProcessamento: boolean = false;
  emitirContraCheque: boolean = false;
  matriculaInvalida: boolean = false;
  modalAnterior: boolean = false;
  telaTrace: boolean = false;
  periodoInputFormatado: string;
  periodoObjFormatado: string;

  constructor(
    private fb: UntypedFormBuilder,
    private contrachequeService: ContrachequeService,
    private validaErro: ErroService,
    private router: Router,
    private perfilService: ProfileService,
    private spinner: NgxSpinnerService
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.localStorage = this.decryptLocalStorage();
    this.tipoUsuario = this.localStorage.TipoUsuario;

    if (navigation.extras.state) {
      console.log(navigation);
      this.usuario = navigation.extras.state.usuario;
      this.matricula = navigation.extras.state.usuario.matricula;
    }
  }

  ngOnInit() {
    this.formcolaborador = this.fb.group({
      matricula: [''],
      nome: [{ value: '', disabled: true }],
      vinculo: [{ value: '', disabled: true }],
    });
    this.formContracheque = this.fb.group({
      periodo: [''],
      tipoProcessamento: [''],
    });
    this.formModal = this.fb.group({
      nomeServidor: [''],
      matDig: [''],
      numOrd: [''],
      localTrabalho: [''],
      municipio: [''],
      dataAdmissao: [''],
      dataNascimento: [''],
      cargoEfetivado: [''],
      nivel: [''],
      cargoComissionado: [''],
      referencia: [''],
      vinculo: [''],
      situacao: [''],
      identidade: [''],
      cpf: [''],
      orgaoPagador: [''],
      depSF: [''],
      depIR: [''],
      cargaHoraria: [''],
      competencia: [''],
    });
    if (this.usuario) {
      this.carregarDadosUsuario(this.usuario['nome'], this.usuario['matricula']);
    } else if (this.tipoUsuario === TipoUsuario.portal) {
      this.buscarDadosUsuario();
    }
  }

  buscarDadosUsuario(): void {
    this.spinner.show();
    const matricula = this.formcolaborador.get('matricula').value;
    this.perfilService.buscarCadastroMatricula(matricula).subscribe(
      (data: any) => {
        const nome = data.InformacoesBasicas.NomeCompleto;
        const cpf = data.Documentos.Cpf;
        this.spinner.hide();

        this.carregarDadosUsuario(nome, cpf);
      },
      (error) => this.validaErro.retornoErro(error)
    );
  }

  carregarDadosUsuario(nome: string, matricula: string) {
    this.formcolaborador.get('matricula').setValue(matricula);
    if (this.tipoUsuario == TipoUsuario.portal) this.formcolaborador.get('matricula').disable();

    this.formcolaborador.get('nome').setValue(nome);
    this.formcolaborador.get('nome').disable();
    this.buscarColaboradorMatricula(matricula);
  }

  buscarColaborador(matricula): void {
    this.loadingMatriculas = true;
    this.contrachequeService.buscarColaborador(matricula).subscribe(
      (data: Matricula[]) => {
        this.formcolaborador.get('nome').setValue(data[0].NomeColaborador);
        this.listaMatricula = data;
        this.listaMatricula.map((matricula) => {
          matricula.Competencias = this.agruparCompetencias(matricula.Competencias);
          return matricula;
        });
        this.loadingMatriculas = false;
      },
      (error) => {
        this.listaMatricula = [];
        this.formcolaborador.get('nome').setValue('');
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
        this.loadingMatriculas = false;
      }
    );
  }

  buscarColaboradorMatricula(matricula) {
    this.matriculaInvalida = false;
    this.contrachequeService.buscarColaboradorGeral(matricula).subscribe(
      (data: Cadastro) => {
        this.cadastroColaborador = data;
        let vinculo =
          data.InformacoesBasicas.Vinculo.Codigo + ' - ' + data.InformacoesBasicas.Vinculo.Nome;
        this.formcolaborador.get('vinculo').setValue(vinculo);
        this.cpfColaborador = data.Documentos.Cpf;
        this.formcolaborador.get('vinculo').setValue;
        this.buscarColaborador(matricula);
      },
      (error) => {
        this.limparCampos();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  abrirModalContracheque(matriculaIndice: number, previa: boolean): void {
    this.limparContracheque();
    this.matriculaSelecionada = matriculaIndice;
    if (previa) {
      this.modalAnterior = false;
      this.onPeriodoClickPrevia();
    } else {
      this.modalAnterior = true;
    }
  }

  limparContracheque(): void {
    this.formContracheque.get('periodo').setValue('');
    this.formContracheque.get('tipoProcessamento').setValue('');
    this.totalContracheque = null;
    this.relatorioB64 = null;
    this.listaTipoProcessamento = [];
    this.listaVerba = [];
  }

  limparCampos() {
    this.formcolaborador.get('nome').setValue('');
    this.formcolaborador.get('matricula').setValue('');
    this.formcolaborador.get('vinculo').setValue('');
    this.listaMatricula = [];
  }

  buscarContracheque(): void {
    this.loadingContracheque = true;
    const { tipoProcessamento } = this.formContracheque.value;
    const matriculaEscolhida = this.listaMatricula[this.matriculaSelecionada];

    this.contrachequeService
      .buscarContracheque(matriculaEscolhida, this.periodoObjFormatado, tipoProcessamento)
      .subscribe(
        (data: any) => this.carregarContracheque(data),
        (error) => {
          this.loadingContracheque = false;
          new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
        }
      );
  }

  buscarPrevia(): void {
    // console.log('local storage', this.localStorage);
    this.loadingContracheque = true;
    const matriculaEscolhida = this.listaMatricula[this.matriculaSelecionada];
    // console.log('tipoProcessamento value', this.formContracheque.get('tipoProcessamento').value);
    // console.log('matricula', matriculaEscolhida);
    // const mes = String(new Date().getMonth() + 1).padStart(2, '0');
    // const ano = new Date().getFullYear();
    // const dtComp = `${ano}${mes}`;
    let parametro = this.listaTipoProcessamento.find(
      (proc) => proc.Codigo == this.formContracheque.get('tipoProcessamento').value
    );
    console.log('parametro', parametro);

    this.contrachequeService
      .buscarPrevia(
        matriculaEscolhida,
        '0',
        this.formContracheque.get('tipoProcessamento').value,
        parametro.DtComp
      )
      .subscribe(
        (data: any) => this.carregarContracheque(data),
        (error) => {
          new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
          this.listaVerba = null;
          this.totalContracheque = null;
          this.relatorioB64 = null;
          this.loadingContracheque = false;
        }
      );
  }

  carregarContracheque(contracheque?: any) {
    if (contracheque) {
      this.listaVerba = contracheque.Verbas.filter(
        (verba: Verba) =>
          verba.ValorFicha != '0,00' && (verba.VerbaC01 == '1' || verba.VerbaC01 == '2')
      );
      this.totalContracheque = contracheque.Total;
      this.relatorioB64 = contracheque.Arquivob64;
      this.loadingContracheque = false;
    }
  }

  obterNomeCompetencia(competencia: string): string {
    const meses = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outrubro',
      'Novembro',
      'Dezembro',
    ];
    const competenciaMes = Number(competencia.substr(4, 2));
    const competenciaAno = competencia.substr(0, 4);

    return `${meses[competenciaMes - 1]} de ${competenciaAno}`;
  }

  downloadRelatorio() {
    this.loadingDownload = true;
    const linkSource = 'data:application/pdf;base64,' + this.relatorioB64;
    const downloadLink = document.createElement('a');
    const fileName = 'Contracheque.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    this.loadingDownload = false;
  }

  agruparCompetencias(listaCompetencias: any) {
    const competencias = listaCompetencias.reduce((result, currentValue) => {
      (result[currentValue['Competencia']] = result[currentValue['Competencia']] || []).push(
        currentValue
      );
      return result;
    }, {});

    return competencias;
  }

  onTipoChange(): void {
    this.buscarContracheque();
  }

  onPeriodoChange(): void {
    const periodo = this.formContracheque.get('periodo').value;
    this.periodoObjFormatado = this.formatarDataCompObjeto(periodo);
    const matriculaEscolhida = this.listaMatricula[this.matriculaSelecionada];
    if (this.periodoObjFormatado.length === 6) {
      this.loadingTipoProcessamento = true;
      this.formContracheque.get('tipoProcessamento').setValue('');
      this.listaTipoProcessamento = [];
      this.relatorioB64 = null;
      this.listaVerba = [];
      this.totalContracheque = null;
      this.contrachequeService
        .buscarTipoProcessamento(this.periodoObjFormatado, matriculaEscolhida.NumeroOrdem)
        .subscribe(
          (data: Parametro[]) => {
            this.listaTipoProcessamento = data;
            // console.log(this.listaTipoProcessamento);
            if (data.length == 1) {
              this.formContracheque.get('tipoProcessamento').setValue(data[0].Codigo);
              this.buscarContracheque();
            }
            this.loadingTipoProcessamento = false;
          },
          (error) => {
            this.loadingTipoProcessamento = false;
            new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
          }
        );
    } else {
      new AlertaModal('erro', 'Erro', 'Formato de data inválido', 'p');
    }
  }

  onPeriodoClickPrevia(): void {
    const matriculaEscolhida = this.listaMatricula[this.matriculaSelecionada];
    this.loadingTipoProcessamento = true;
    this.formContracheque.get('tipoProcessamento').setValue('');
    this.listaTipoProcessamento = [];
    this.relatorioB64 = null;
    this.listaVerba = [];
    this.totalContracheque = null;
    //adicionar nova busca de processamentos com comp aqui

    this.spinner.show();
    this.contrachequeService.buscarTipoProcessamentoCompetencia().subscribe(
      (data) => {
        this.spinner.hide();
        this.listaTipoProcessamento = JSON.parse(JSON.stringify(data));
        console.log('comps recebidas', this.tipoProcessamentoComp);
        this.loadingTipoProcessamento = false;
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
        this.loadingTipoProcessamento = false;
      }
    );

    // this.contrachequeService.buscarTipoProcessamentoPrevia().subscribe(
    //   (data: Parametro[]) => {
    //     this.listaTipoProcessamento = data;
    //     this.loadingTipoProcessamento = false;
    //   },
    //   (error) => {
    //     this.loadingTipoProcessamento = false;
    //     new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
    //   }
    // );
  }

  formatarDataCompInput(evento: any): void {
    const data = evento.target.value;
    let mes = data.slice(0, 2);
    let ano = data.slice(3, 7);
    let anoAtual = new Date().getFullYear();

    if (mes > 12) {
      evento.target.value = '12';
    }
    if (ano > anoAtual) {
      evento.target.value = mes + anoAtual;
    }
    this.formContracheque.get('periodo')?.setValue(evento.target.value);
  }

  formatarDataCompObjeto(dataInput: string) {
    const mesComp = dataInput.substring(0, 2);
    const anoComp = dataInput.substring(2, 7);
    return anoComp + mesComp;
  }

  keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
  };

  matriculaListener(evento) {
    if (evento.Matricula) {
      this.matricula = evento.Matricula;
    } else {
      this.matricula = evento[0].Matricula;
    }

    this.formcolaborador.get('matricula').setValue(this.matricula);
    // this.formcolaborador.get('matricula').disable();
    this.buscarColaboradorMatricula(this.matricula);
  }

  retornarTelaInicial() {
    if (this.tipoUsuario == '1') {
      const rota = '/perfil/' + this.numOrdem;
      this.router.navigate([rota]);
    } else {
      this.matricula = null;
      this.limparCampos();
    }
  }

  // checkTipoDeBusca(tipo: number) {
  //   if (tipo === 1) {
  //     this.tipoDaBusca = 0;
  //     this.telaTrace = false;
  //     this.formContracheque.get('periodo').setValue('');
  //     this.relatorioB64 = null;
  //     this.listaVerba = [];
  //     this.totalContracheque = null;
  //   } else if (tipo === 2) {
  //     this.tipoDaBusca = 1;
  //     this.telaTrace = false;
  //     this.relatorioB64 = null;
  //     this.listaVerba = [];
  //     this.totalContracheque = null;
  //     this.formContracheque.get('tipoProcessamento').setValue('');
  //   } else if (tipo === 3) {
  //     this.telaTrace = true;
  //     this.relatorioB64 = null;
  //     this.listaVerba = [];
  //     this.totalContracheque = null;
  //     this.formContracheque.get('tipoProcessamento').setValue('');
  //   }
  // }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}

interface Verba {
  Ano: string;
  CodigoProcessamento: string;
  CodigoVerba: string;
  empresaFilial: number;
  Funcao: string;
  Matricula: string;
  Mes: string;
  NomeVerba: string;
  Ocorrencia: string;
  Pagos: string;
  TransacaoVerba: string;
  ValorFicha: string;
  ValorHora: string;
  VerbaC01: string;
  VerbaC17: string;
  TempoVerba: any;
}

export interface Matricula {
  Ativo: number;
  CodigoAfastamento: string;
  Competencias: any[];
  CodigoLotacao: string;
  DataAdmissao: Data;
  DataAfastamento: Data;
  EmpFil: string;
  Matricula: string;
  NomeFilial: string;
  NomeLotacao: string;
  NumeroOrdem: string;
  NomeColaborador: string;
  DescAfa: string;
}
