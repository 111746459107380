<div class="content d-flex flex-row flex-wrap">
  <div class="col-md-4">
    <div style="background-color: #181818" class="card animated zoomIn text-center">
      <a
        [ngStyle]="retorno_gerencial.Ativos > 0 ? { color: 'inherit', cursor: 'pointer' } : null"
        (click)="setStorageColaborador('0')"
      >
        <div class="card-body">
          <h1 class="mr-auto font-weight-semibold mb-0 text-light">
            {{ retorno_gerencial.Ativos }}
          </h1>
          <p class="text-light card-text">Colaboradores Ativos</p>
        </div>
        <div class="card-footer text-muted p-0">
          <p class="font-weight-semibold mb-0 text-light">Gerenciar Colaboradores</p>
        </div>
      </a>
    </div>
  </div>

  <div class="col-md-4">
    <div style="background-color: #5d5d5c" class="card animated zoomIn text-center">
      <a
        [ngStyle]="retorno_gerencial.Ferias > 0 ? { color: 'inherit', cursor: 'pointer' } : null"
        (click)="setStorageColaborador('1')"
      >
        <div class="card-body">
          <h1 class="mr-auto font-weight-semibold mb-0 text-light">
            {{ retorno_gerencial.Ferias }}
          </h1>
          <p class="text-light card-text">Colaboradores de Férias</p>
        </div>
        <div class="card-footer text-muted p-0">
          <p class="font-weight-semibold mb-0 text-light">Gerenciar Férias</p>
        </div>
      </a>
    </div>
  </div>

  <div class="col-md-4">
    <div class="card animated zoomIn text-center" style="background-color: #faca00">
      <a
        [ngStyle]="retorno_gerencial.Pendentes > 0 ? { color: 'inherit', cursor: 'pointer' } : null"
        (click)="setStorageColaborador('2')"
      >
        <div class="card-body">
          <h1 class="mr-auto font-weight-semibold mb-0 text-light" style="color: #181818">
            {{ retorno_gerencial.Pendentes }}
          </h1>
          <p class="text-light card-text" style="color: #181818">Admissões Pendentes</p>
        </div>
        <div class="card-footer text-muted p-0">
          <p style="color: #181818" class="font-weight-semibold mb-0 text-light">
            Gerenciar Admissões
          </p>
        </div>
      </a>
    </div>
  </div>

  <div class="col-md-4">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h5 class="card-title">Folha X Colaboradores</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a
              style="cursor: pointer"
              class="fa teste"
              data-toggle="collapse"
              data-target="#faturamentoColaborador"
              aria-expanded="true"
              aria-controls="faturamentoColaborador"
            ></a>
            <a
              style="cursor: pointer"
              class="fa fa-refresh"
              (click)="refreshChart('folhaColaborador')"
            ></a>
            <a
              style="cursor: pointer"
              class="list-icons-item fa fa-download"
              (click)="download($event)"
            ></a>
          </div>
        </div>
      </div>
      <div id="faturamentoColaborador" class="collapse show">
        <div class="card-body">
          <div
            echarts
            [options]="colaboradorFaturamento"
            class="chart has-fixed-heightd-flex d-flex justify-content-center"
          ></div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h5 class="card-title">Colaboradores</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a
              style="cursor: pointer"
              class="fa teste"
              data-toggle="collapse"
              data-target="#colaborador"
              aria-expanded="true"
              aria-controls="colaborador"
            ></a>
            <a
              style="cursor: pointer"
              class="fa fa-refresh"
              (click)="refreshChart('colaborador')"
            ></a>
            <a
              style="cursor: pointer"
              class="list-icons-item fa fa-download"
              (click)="download($event)"
            ></a>
          </div>
        </div>
      </div>
      <div id="colaborador" class="collapse show">
        <div class="card-body">
          <div
            echarts
            [options]="colaboradores"
            class="chart has-fixed-heightd-flex d-flex justify-content-center"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="card">
      <div class="card-header header-elements-inline">
        <h5 class="card-title">Folha</h5>
        <div class="header-elements">
          <div class="list-icons">
            <a
              style="cursor: pointer"
              class="fa teste"
              data-toggle="collapse"
              data-target="#folha"
              aria-expanded="true"
              aria-controls="folha"
            ></a>
            <a style="cursor: pointer" class="fa fa-refresh" (click)="refreshChart('folha')"></a>
            <a
              style="cursor: pointer"
              class="fa fa-download list-icons-item"
              (click)="download($event)"
            ></a>
          </div>
        </div>
      </div>

      <div id="folha" class="collapse show">
        <div class="card-body" id="bodyFolha">
          <div
            echarts
            [options]="folha"
            id="folhaChart"
            class="chart has-fixed-heightd-flex d-flex justify-content-center"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12">
    <div class="card animated zoomIn text-left" style="background-color: #ffffff">
      <table
        class="table table-striped border-blue text-left mt-2"
        *ngIf="eventosListar.length > 0"
      >
        <thead class="bg-teal-400" style="background-color: #5d5d5c">
          <tr>
            <th>Data Comp</th>
            <th>Tipo Proc</th>
            <th>Evento</th>
            <th>Descrição do Evento</th>
            <th style="text-align: center">Quantidade</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let evento of eventosListar; let i = index">
            <td>{{ formatarData(evento.DtComp) }}</td>
            <td>{{ evento.TpProc }}</td>
            <td>{{ evento.Evento }}</td>
            <td>{{ evento.DescEvento }}</td>
            <td style="text-align: center">{{ evento.Qtd }}</td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="eventosListar.length === 0" class="p-3">Nenhum evento cadastrado.</div>
    </div>
  </div>
</div>
