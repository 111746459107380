import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { RepresentanteLegalComponent } from '../representante-legal/representante-legal.component';
import { PensaoPrevidenciariaService } from './pensao-previdenciaria.service';
import { Parametro } from 'src/app/service/interfaces/Parametro';
import { Data } from 'src/app/service/interfaces/Data';
import { Endereco } from 'src/app/service/interfaces/Endereco';
import { ContaBancaria } from 'src/app/service/interfaces/ContaBancaria';
import { TituloEleitor } from 'src/app/service/interfaces/TituloEleitor';
import { ErroService } from 'src/app/service/erros/erro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ParametrosComponent } from '../parametros/parametros.component';
import { Sexo } from '../../service/classes/cadastro/Pessoal/DadosBasicos/Sexo';
import { Vinculo } from '../../service/classes/cadastro/InformacoesBasicas/vinculo';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { zeroValidator } from 'src/app/service/Validators/zeroValidator';
import { cpfValidator } from 'src/app/service/Validators/cpfValidator';
import { tituloValidator } from 'src/app/service/Validators/tituloValidator';
import { Cadastro } from '../../service/classes/cadastro/cadastro';
import { Nacionalidade } from '../cadastro-publico/models/interfaces';
import { setReadOnly } from './../colaboradores/colaboradores-home/echarts';

@Component({
  selector: 'app-pensao-previdenciaria',
  templateUrl: './pensao-previdenciaria.component.html',
  styleUrls: ['./pensao-previdenciaria.component.css'],
})
export class PensaoPrevidenciariaComponent implements OnInit {
  @ViewChild(RepresentanteLegalComponent, { static: false })
  representanteLegalComponent: RepresentanteLegalComponent;
  @ViewChild(ParametrosComponent, { static: false }) parametrosComponent: ParametrosComponent;
  @ViewChild('cardPensionista', { static: false }) cardPensionista: ElementRef;

  localStorage: LocalStorage;

  formPrevidencia: FormGroup;
  formExSegurado: FormGroup;
  formRateio: FormGroup;

  listaBanco: any[] = [];
  listaAgencias: any[] = [];
  listaAgenciasResponsavel: any[] = [];
  listaUf: Parametro[] = [];
  listaCondicao: Parametro[] = [];
  listaEstadoCivil: Parametro[] = [];
  listaGrauInstrucao: Parametro[] = [];
  listaGrauParentesco: Parametro[] = [];
  listaMotivoDepedencia: Parametro[] = [];
  listaMotivoAfastamento: Parametro[] = [];
  listaNacionalidade: Parametro[] = [];
  listaParidade: Parametro[] = [];
  listaSexo: Parametro[] = [];
  listaTipoConta: Parametro[] = [];
  listaTipoCota: Parametro[] = [];
  listaTipoDependente: Parametro[] = [];
  listaTipoFamilia: Parametro[] = [];
  listaTipoIsencao: Parametro[] = [];
  listaTipoPensao: Parametro[] = [];
  listaTipoRateio: Parametro[] = [];
  listaVinculo: Parametro[] = [];
  listaCodigoAfastamento: Parametro[] = [];
  listaMunicipioNascimento: any[] = [];
  listaMunicipioEndereco: any[] = [];
  listaTipoBeneficiario: Parametro[] = [];
  listaMotivoCessacao: Parametro[] = [];

  pensionistaEditando: Familia;
  exSegurado: ExSegurado = {} as ExSegurado;
  menorIdade: boolean = false;
  step: number = 1;

  loadingCep: boolean = false;
  loadingExSegurado: boolean = false;
  loadingMunicipioNascimento: boolean = false;
  loadingMunicipioEndereco: boolean = false;
  editar: boolean = false;
  adicao: boolean = false;
  visualizacao: boolean = false;
  temErro: boolean = false;
  teste: boolean = false;
  abertoCardExSegurado: boolean = false;
  abertoCardCadastro: boolean = true;
  representanteSelecionado = false;
  cpfPensionistaEdicao: string = '';

  steps: Step[] = [
    {
      step: 1,
      formControlName: 'dadosPessoais',
      currentElementId: 'dados-pessoais-pensionista',
      nextElementId: 'documentos-pensionista',
    },
    {
      step: 2,
      formControlName: 'documentos',
      currentElementId: 'documentos-pensionista',
      nextElementId: 'dados-pagamento-pensionista',
    },
    {
      step: 3,
      formControlName: 'dadosPagamento',
      currentElementId: 'dados-pagamento-pensionista',
      nextElementId: 'info-esocial-pensionista',
    },
    {
      step: 4,
      formControlName: 'infoEsocial',
      currentElementId: 'info-esocial-pensionista',
      nextElementId: 'dependentes-pensionista',
    },
    {
      step: 5,
      formControlName: 'dependentes',
      currentElementId: 'dependentes-pensionista',
      nextElementId: 'dados-pessoais-pensionista',
    },
  ];
  listaDependente: any;
  matriculaPensionista: string;
  cadastroUsuario: Cadastro;
  listaRateioCodaf20: Familia[];
  primeiraBusca: boolean = true;
  telefonesAuxiliares: ContatoExtra[] = [];

  constructor(
    public fb: FormBuilder,
    private pensaoPrevidenciaService: PensaoPrevidenciariaService,
    public validaErro: ErroService,
    private spinner: NgxSpinnerService
  ) {}

  public get nacionalidade(): typeof Nacionalidade {
    return Nacionalidade;
  }

  ngOnInit() {
    this.localStorage = this.decryptLocalStorage();

    this.initializeObject();
    this.formExSegurado = this.fb.group({
      matricula: [''],
      nome: [''],
    });
    this.formPrevidencia = this.fb.group({
      dadosPessoais: this.fb.group({
        numeroOrdem: [''],
        nome: ['', Validators.required],
        dataNascimento: ['', Validators.required],
        sexo: ['', Validators.required],
        estadoCivil: ['', Validators.required],
        grauInstrucao: ['', Validators.required],
        nacionalidade: ['', Validators.required],
        ufNascimento: ['', Validators.required],
        municipioNascimento: [{ value: '', disabled: true }, Validators.required],
        nomePai: [''],
        nomeMae: ['', Validators.required],
        familia: ['', Validators.required],
        grauParentesco: ['', Validators.required],
        motivo: ['', Validators.required],
      }),
      documentos: this.fb.group({
        identidade: ['', [Validators.required, zeroValidator()]],
        dataExpedicao: ['', Validators.required],
        orgaoEmissor: ['', Validators.required],
        identidadeUf: ['', Validators.required],
        cpf: ['', [Validators.required, cpfValidator]],
        tituloEleitor: ['', [Validators.required, Validators.minLength(12), tituloValidator()]],
        secaoEleitoral: ['', [Validators.required, zeroValidator()]],
        zonaEleitoral: ['', [Validators.required, zeroValidator()]],
        ufEleitoral: ['', Validators.required],
        cep: ['', Validators.required],
        rua: ['', Validators.required],
        numero: ['', [Validators.required, zeroValidator()]],
        complemento: [''],
        bairro: ['', Validators.required],
        enderecoUf: ['', Validators.required],
        enderecoMunicipio: ['', Validators.required],
        celular: ['', Validators.required],
        tipoContato: [''],
        telefoneAuxiliar: [''],
      }),
      dadosPagamento: this.fb.group({
        vinculo: ['', Validators.required],
        agencia: ['', [Validators.required, zeroValidator()]],
        banco: ['', Validators.required],
        tipoConta: ['', Validators.required],
        contaCorrente: ['', [Validators.required, zeroValidator()]],
        data1Pagamento: ['', Validators.required],
        tipoRateio: ['', Validators.required],
        rateio: ['', [Validators.required, zeroValidator()]],
        tipoPensao: ['', Validators.required],
        comParidade: ['', Validators.required],
        beneficioNumeroProcesso: ['', [Validators.required, zeroValidator()]],
        beneficioDataFim: [''],
        beneficioDataLimite: ['', Validators.required],
        beneficioTipoCota: ['', Validators.required],
        beneficioNumeroReserva: [''],
        codigoAfastamento: ['', Validators.required],
        motivoAfastamento: [''],
        prazo: [''],
        dataAfastamentoFim: [''],
        dataFastamentoInicio: [''],
        condicao: [''],
        isencaoIrDataInicial: [''],
        isencaoIrDataFinal: [''],
        responsavelNumero: [''],
        responsavelNome: [''],
        resposavelDataInicio: [''],
        resposavelDataFim: [''],
        resposavelDataVencimento: [''],
        resposavelNumeroProcesso: [''],
        responsavelBanco: [''],
        responsavelAgencia: [''],
        responsavelOperacao: [''],
        responsavelConta: ['', zeroValidator()],
      }),
      infoEsocial: this.fb.group({
        dataPublicacao: ['', Validators.required],
        tipo: ['', Validators.required],
        infoOrigem: [''],
        derJudicial: [''],
        dataCessacao: [''],
        motCessacao: [''],
        dataReativacao: [''],
        dataFinanceiro: [''],
      }),
    });
    this.formRateio = this.fb.group({
      rateioTotal: [''],
    });
    this.buscarParametros();
    this.buscarBancos();
    this.buscarMotivoCessacao();
    this.buscarTipoBeneficiario();
  }

  buscarEnter(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.buscarExSegurado();
    }
  }

  retornarTelaInicial() {
    this.exSegurado = {} as ExSegurado;
    this.formExSegurado.get('matricula').setValue('');
    this.formExSegurado.get('nome').setValue('');
    this.limparForm();
    this.primeiraBusca = true;
  }

  buscarParametros(): void {
    this.spinner.show();

    this.pensaoPrevidenciaService.buscarParametros().subscribe(
      (data: any) => {
        // this.listaBanco = data.Bancos;
        this.listaUf = data.Estados;
        this.listaGrauParentesco = data.GrausParentesco;
        this.listaEstadoCivil = data.EstadosCivil;
        this.listaGrauInstrucao = data.GrausInstrucao;
        this.listaMotivoDepedencia = data.MotivosDependencia;
        this.listaNacionalidade = data.Nacionalidades;
        this.listaParidade = data.Paridades;
        this.listaSexo = data.Sexos;
        this.listaTipoConta = data.TiposConta;
        this.listaTipoDependente = data.TiposDependente;
        this.listaTipoFamilia = data.TiposFamilia;
        this.listaTipoIsencao = data.TiposIsencao;
        this.listaTipoPensao = data.TiposPensao;
        this.listaTipoRateio = data.TiposRateio;
        this.listaTipoCota = data.TiposCota;
        this.listaCondicao = data.Condicao;
        this.listaMotivoAfastamento = data.MotivosAfastamento;
        this.listaVinculo = data.Vinculos;
        this.listaCodigoAfastamento = data.Situacao;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarDependentes(matricula) {
    this.pensaoPrevidenciaService.buscarDependentes(matricula).subscribe(
      (data) => {
        this.listaDependente = JSON.parse(JSON.stringify(data));
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarBancos() {
    this.spinner.show();
    this.pensaoPrevidenciaService.buscarBancos().subscribe(
      (data: any) => {
        this.listaBanco = data;
        this.spinner.hide();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  buscarAgencias(event?, responsavel?: boolean): void {
    let codigoBanco = '';
    if (event) {
      codigoBanco = event.target.value;
    } else {
      if (responsavel) {
        codigoBanco = this.formPrevidencia.get('dadosPagamento').get('responsavelBanco').value;
      } else {
        codigoBanco = this.formPrevidencia.get('dadosPagamento').get('banco').value;
      }
    }

    if (responsavel) {
      this.listaAgenciasResponsavel = [];
      // this.formPrevidencia.get('dadosPagamento').get('responsavelAgencia').setValue('');
    } else {
      this.listaAgencias = [];
      // this.formPrevidencia.get('dadosPagamento').get('agencia').setValue('');
    }
    if (codigoBanco != '') {
      const bancoAtual = this.listaBanco.find(
        (banco) => codigoBanco.toString().trim() == banco.Codigo.toString().trim()
      );
      if (bancoAtual) {
        this.spinner.show();
        this.pensaoPrevidenciaService.buscarAgencias(bancoAtual.Nome).subscribe(
          (data: any) => {
            if (responsavel) {
              this.listaAgenciasResponsavel = data;
              const agenciaAtual = this.formPrevidencia
                .get('dadosPagamento')
                .get('responsavelAgencia').value;
              console.log('agencia atual', agenciaAtual);
              this.formPrevidencia.get('dadosPagamento').get('responsavelAgencia').setValue('');
              console.log('agencia atual', agenciaAtual);
              this.formPrevidencia
                .get('dadosPagamento')
                .get('responsavelAgencia')
                .setValue(agenciaAtual);
              console.log(
                'valor agencia form',
                this.formPrevidencia.get('dadosPagamento').get('responsavelAgencia').value
              );
            } else {
              this.listaAgencias = data;
            }
            // this.buscarOperacoes(nomeBanco);
            this.spinner.hide();
          },
          (error) => {
            this.validaErro.retornoErro(error);
            this.spinner.hide();
          }
        );
      } else {
        this.pensionistaEditando
          ? Swal(
              'Atenção',
              'Não foi possível encontrar o banco ou buscar as agências para o banco do pensionista: ' +
                this.pensionistaEditando.Pensionista.Nome,
              'warning'
            )
          : Swal(
              'Atenção',
              'Não foi possível encontrar o banco ou buscar as agências desse pensionista.',
              'warning'
            );
      }
    }
  }

  buscarTipoBeneficiario() {
    this.spinner.show();
    this.pensaoPrevidenciaService.buscarTipoBeneficiario().subscribe(
      (data: any) => {
        this.listaTipoBeneficiario = data;
        this.spinner.hide();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  buscarMotivoCessacao() {
    this.spinner.show();
    this.pensaoPrevidenciaService.buscarMotivoCessacao().subscribe(
      (data: any) => {
        this.listaMotivoCessacao = data;
        this.spinner.hide();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  buscarExSegurado(adicao?: boolean, edicao?: boolean): void {
    this.resetForm();
    const matricula = this.formExSegurado.get('matricula').value.trim();
    this.loadingExSegurado = true;

    this.formExSegurado.get('nome').setValue('');

    if (matricula === '') {
      this.loadingExSegurado = false;
      Swal('Atenção', 'Informe uma matrícula', 'warning');
      return;
    }

    this.pensaoPrevidenciaService.buscarExSegurado(matricula).subscribe(
      (data: ExSegurado) => {
        this.step = 1;
        this.loadingExSegurado = false;
        this.exSegurado = data;

        (this.exSegurado.Identidade.DataDeExpedicao.ToDate = this.exSegurado.Identidade
          .DataDeExpedicao.IsDate
          ? this.exSegurado.Identidade.DataDeExpedicao.ToDate
          : 'Não Informada'),
          (this.exSegurado.Cpf = this.formataCPF(this.exSegurado.Cpf));
        console.log('length', this.exSegurado.Familia.length);
        if (this.exSegurado.Familia.length == 0) {
          this.novoCadastro();
        } else {
          if (this.cpfPensionistaEdicao != '' && this.exSegurado.Familia.length != 0) {
            let pensionistaEdicao = this.exSegurado.Familia.find(
              (pensionista) => pensionista.Pensionista.Cpf == this.cpfPensionistaEdicao
            );
            this.carregarPensionista(true, pensionistaEdicao, adicao, edicao);
          } else {
            let pensionistaBuscado = this.exSegurado.Familia.find(
              (pensionista) => pensionista.Pensionista.Matricula == matricula
            );
            if (pensionistaBuscado) {
              this.carregarPensionista(true, pensionistaBuscado);
              this.formExSegurado.get('matricula').setValue(this.exSegurado.Matricula);
            } else {
              this.carregarPensionista(true, this.exSegurado.Familia[0]);
            }
          }
        }

        this.formExSegurado.get('nome').setValue(this.exSegurado.Nome);
        this.formExSegurado.get('nome').disable();
        this.cpfPensionistaEdicao = '';
        this.buscarCadastro();
        this.primeiraBusca = false;

        this.exSegurado.Familia = this.exSegurado.Familia.sort((a: Familia, b: Familia) => {
          if (a.Pensionista.Nome > b.Pensionista.Nome) {
            return 1;
          } else if (a.Pensionista.Nome < b.Pensionista.Nome) {
            return -1;
          } else {
            return 0;
          }
        });
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.loadingExSegurado = false;
      }
    );
  }

  buscarCadastro() {
    this.pensaoPrevidenciaService.buscarCadastro(this.exSegurado.NumeroDeOrdem).subscribe(
      (data) => {
        this.cadastroUsuario = JSON.parse(JSON.stringify(data));
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  carregarPensionista(
    visualizacao: boolean,
    pensionistaSelecionado?: Familia,
    adicaoPassada?: boolean,
    edicaoPassada?: boolean
  ): void {
    this.pensionistaEditando = null;
    this.adicao = false;
    this.visualizacao = visualizacao;
    this.telefonesAuxiliares = [];

    if (this.cpfPensionistaEdicao != '' && adicaoPassada) {
      document.getElementById('dependentes-pensionista').classList.add('active');
      document.getElementById('dados-pessoais-pensionista').classList.remove('active');
      document.getElementById('documentos-pensionista').classList.remove('active');
      document.getElementById('dados-pagamento-pensionista').classList.remove('active');
      document.getElementById('info-esocial-pensionista').classList.remove('active');

      setTimeout(() => {
        if (document.getElementById('dependentes-tab')) {
          document.getElementById('dependentes-tab').classList.add('active');
          document.getElementById('dados-pessoais-tab').classList.remove('active');
          document.getElementById('documentos-tab').classList.remove('active');
          document.getElementById('info-esocial-tab').classList.remove('active');
          document.getElementById('dados-pessoais-tab').classList.remove('active');
        }
      }, 300);
    } else {
      console.log(document.getElementById('dados-pessoais-tab'));
      // document.getElementById('dados-pessoais-pensionista').classList.add('active');
      // document.getElementById('dependentes-pensionista').classList.remove('active');
      // document.getElementById('documentos-pensionista').classList.remove('active');
      // document.getElementById('dados-pagamento-pensionista').classList.remove('active');
      // document.getElementById('info-esocial-pensionista').classList.remove('active');

      setTimeout(() => {
        if (document.getElementById('dados-pessoais-tab')) {
          document.getElementById('dados-pessoais-tab').click();
          // document.getElementById('dependentes-tab').classList.remove('active');
          // document.getElementById('documentos-tab').classList.remove('active');
          // document.getElementById('info-esocial-tab').classList.remove('active');
          // document.getElementById('dados-pessoais-tab').classList.remove('active');
        }
      }, 300);
    }

    if (pensionistaSelecionado && !visualizacao) {
      this.editar = true;
      this.step = 1;
    } else {
      this.resetForm();
      this.editar = false;
    }
    this.pensionistaEditando = pensionistaSelecionado;
    if (pensionistaSelecionado) {
      this.telefonesAuxiliares = pensionistaSelecionado.Pensionista.Contato;
      this.matriculaPensionista = this.pensionistaEditando.Pensionista.Matricula;
      this.buscarDependentes(this.pensionistaEditando.Pensionista.Matricula);

      this.formPrevidencia.setValue({
        dadosPessoais: {
          numeroOrdem: pensionistaSelecionado.Pensionista.NumeroDeOrdem,
          nome: pensionistaSelecionado.Pensionista.Nome,
          dataNascimento: this.formatarDataParaForm(
            pensionistaSelecionado.Pensionista.DataNascimento.SetData
          ),
          sexo: pensionistaSelecionado.Pensionista.Sexo,
          estadoCivil: pensionistaSelecionado.Pensionista.EstadoCivil,
          grauInstrucao: pensionistaSelecionado.Pensionista.GrauInstrucao,
          nacionalidade: pensionistaSelecionado.Pensionista.Nacionalidade,
          ufNascimento: pensionistaSelecionado.Pensionista.EstadoNaturalidade,
          municipioNascimento: pensionistaSelecionado.Pensionista.MunicipioNaturalidade,
          nomePai: pensionistaSelecionado.Pensionista.NomePai
            ? pensionistaSelecionado.Pensionista.NomePai
            : '',
          nomeMae: pensionistaSelecionado.Pensionista.NomeMae,
          familia: pensionistaSelecionado.Pensionista.SequencialFamilial,
          grauParentesco: pensionistaSelecionado.Pensionista.TipoDependente,
          motivo: pensionistaSelecionado.Pensionista.MotivoDependente,
        },
        documentos: {
          identidade: pensionistaSelecionado.Pensionista.Identidade.RegistroGeral,
          dataExpedicao:
            pensionistaSelecionado.Pensionista.Identidade.DataDeExpedicao.SetData == '00000000'
              ? null
              : this.formatarDataParaForm(
                  pensionistaSelecionado.Pensionista.Identidade.DataDeExpedicao.SetData
                ),
          orgaoEmissor: pensionistaSelecionado.Pensionista.Identidade.OrgaoEmissor.toUpperCase(),
          identidadeUf: pensionistaSelecionado.Pensionista.Identidade.UF,
          cpf: pensionistaSelecionado.Pensionista.Cpf,
          tituloEleitor:
            pensionistaSelecionado.Pensionista.TituloEleitor.Numero != '0'
              ? pensionistaSelecionado.Pensionista.TituloEleitor.Numero
              : null,
          secaoEleitoral:
            pensionistaSelecionado.Pensionista.TituloEleitor.SecaoEleitoral != '0'
              ? pensionistaSelecionado.Pensionista.TituloEleitor.SecaoEleitoral
              : null,
          zonaEleitoral:
            pensionistaSelecionado.Pensionista.TituloEleitor.ZonaEleitoral != '0'
              ? pensionistaSelecionado.Pensionista.TituloEleitor.ZonaEleitoral
              : null,
          ufEleitoral:
            pensionistaSelecionado.Pensionista.TituloEleitor.UfEleitoral != '0'
              ? pensionistaSelecionado.Pensionista.TituloEleitor.UfEleitoral
              : null,
          cep: pensionistaSelecionado.Pensionista.Endereco.Cep,
          rua: pensionistaSelecionado.Pensionista.Endereco.Logradouro,
          numero: pensionistaSelecionado.Pensionista.Endereco.Numero.toString(),
          complemento: pensionistaSelecionado.Pensionista.Endereco.Complemento,
          bairro: pensionistaSelecionado.Pensionista.Endereco.Bairro,
          enderecoUf: pensionistaSelecionado.Pensionista.Endereco.UF,
          enderecoMunicipio: pensionistaSelecionado.Pensionista.Endereco.Cidade,
          celular:
            pensionistaSelecionado.Pensionista.DDD.toString() +
            pensionistaSelecionado.Pensionista.Telefone,
          tipoContato: '',
          telefoneAuxiliar: '',
        },
        dadosPagamento: {
          vinculo: pensionistaSelecionado.Pensionista.Vinculo,
          agencia: pensionistaSelecionado.Pensionista.ContaBancaria.Agencia
            ? pensionistaSelecionado.Pensionista.ContaBancaria.Agencia
            : '',
          banco: pensionistaSelecionado.Pensionista.ContaBancaria.Banco,
          tipoConta: pensionistaSelecionado.Pensionista.ContaBancaria.TipoConta,
          contaCorrente: pensionistaSelecionado.Pensionista.ContaBancaria.NumeroConta
            ? pensionistaSelecionado.Pensionista.ContaBancaria.NumeroConta
            : '',
          data1Pagamento: this.formatarDataParaForm(
            pensionistaSelecionado.Pensionista.DataPrimeiroPagamento.SetData
          ),
          tipoRateio: pensionistaSelecionado.Pensionista.Rateio.Tipo,
          rateio: this.tratarRateio(pensionistaSelecionado.Pensionista.Rateio.Percentual),
          tipoPensao: pensionistaSelecionado.Pensionista.Tipo,
          comParidade: '0', //pensionista.Pensionista.Flags.Flag6,
          beneficioNumeroProcesso: pensionistaSelecionado.Pensionista.NumeroProcesso,
          beneficioDataFim: this.formatarDataParaForm(
            pensionistaSelecionado.Pensionista.DataFimBeneficio.SetData
          ),
          beneficioDataLimite: this.formatarDataParaForm(
            pensionistaSelecionado.Pensionista.DataLimiteRetroativo.SetData
          ),
          beneficioTipoCota: pensionistaSelecionado.Pensionista.TipoCota,
          beneficioNumeroReserva: pensionistaSelecionado.Pensionista.NumeroReserva,
          codigoAfastamento: pensionistaSelecionado.Pensionista.Situacao.CodigoAfastamento,
          motivoAfastamento: pensionistaSelecionado.Pensionista.Situacao.Motivo,
          prazo: pensionistaSelecionado.Pensionista.Situacao.Prazo,
          dataAfastamentoFim: this.formatarDataParaForm(
            pensionistaSelecionado.Pensionista.Situacao.DataRetorno.SetData
          ),
          dataFastamentoInicio: this.formatarDataParaForm(
            pensionistaSelecionado.Pensionista.Situacao.DataAfastamento.SetData
          ),
          condicao: pensionistaSelecionado.Pensionista.Isencao.Condicao,
          isencaoIrDataInicial: this.formatarDataParaForm(
            pensionistaSelecionado.Pensionista.Isencao.DataInicial.SetData
          ),
          isencaoIrDataFinal: this.formatarDataParaForm(
            pensionistaSelecionado.Pensionista.Isencao.DataFinal.SetData
          ),
          responsavelNumero: pensionistaSelecionado.Representante
            ? pensionistaSelecionado.Representante.Numero
            : '',
          responsavelNome: pensionistaSelecionado.Representante
            ? pensionistaSelecionado.Representante.Nome
            : '',
          resposavelDataInicio: pensionistaSelecionado.Representante
            ? this.formatarDataParaForm(pensionistaSelecionado.Representante.DataInicial.SetData)
            : '',
          resposavelDataFim: pensionistaSelecionado.Representante
            ? this.formatarDataParaForm(pensionistaSelecionado.Representante.DataFinal.SetData)
            : '',
          resposavelDataVencimento: pensionistaSelecionado.Representante
            ? this.formatarDataParaForm(pensionistaSelecionado.Representante.DataVencimento.SetData)
            : '',
          resposavelNumeroProcesso: pensionistaSelecionado.Representante
            ? pensionistaSelecionado.Representante.NumeroProcesso
            : '',
          responsavelBanco: pensionistaSelecionado.Representante
            ? pensionistaSelecionado.Representante.CodigoBanco
            : '',
          responsavelAgencia: pensionistaSelecionado.Representante
            ? pensionistaSelecionado.Representante.CodigoAgencia
            : '',
          responsavelOperacao: pensionistaSelecionado.Representante
            ? pensionistaSelecionado.Representante.Operacao
            : '',
          responsavelConta: pensionistaSelecionado.Representante
            ? pensionistaSelecionado.Representante.ContaCorrente
            : '',
        },
        infoEsocial: {
          dataPublicacao: this.formatarDataParaForm(
            pensionistaSelecionado.Pensionista.Beneficio.DtPublicacao.SetData
          ),
          tipo: pensionistaSelecionado.Pensionista.Beneficio.Tipo,
          infoOrigem: pensionistaSelecionado.Pensionista.Beneficio.InformacaoOriginou,
          derJudicial: pensionistaSelecionado.Pensionista.Beneficio.DeterminacaoJudicial,
          dataCessacao: this.formatarDataParaForm(
            pensionistaSelecionado.Pensionista.Beneficio.DtCessacao.SetData
          ),
          motCessacao:
            pensionistaSelecionado.Pensionista.Beneficio.MotivoCessacao == '0'
              ? ''
              : pensionistaSelecionado.Pensionista.Beneficio.MotivoCessacao,
          dataReativacao: this.formatarDataParaForm(
            pensionistaSelecionado.Pensionista.Beneficio.DtReativacao.SetData
          ),
          dataFinanceiro: this.formatarDataParaForm(
            pensionistaSelecionado.Pensionista.Beneficio.DtFinanceiro.SetData
          ),
        },
      });
      if (this.parametrosComponent) {
        this.parametrosComponent.carregarFlags(pensionistaSelecionado.Pensionista.Flags);
      }

      pensionistaSelecionado.Pensionista.Nacionalidade == this.nacionalidade.Brasileiro.toString()
        ? this.onUfNascimentoChange()
        : null;
      this.onDataNascimentoChange();
      this.aoMudarNacionalidade();
      this.buscarAgencias();
      pensionistaSelecionado.Representante ? this.buscarAgencias(null, true) : null;
      this.onCepChange();
      this.carregarRepresentantePensionista();
    }

    if (visualizacao) {
      this.formPrevidencia.disable();
    } else {
      this.formPrevidencia.enable();
    }
    if (!this.primeiraBusca) {
      setTimeout(() => {
        this.cardPensionista.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
  }

  carregarRepresentantePensionista(): void {
    const dadosPagamentoForm = this.formPrevidencia.get('dadosPagamento');
    console.log('tem numero representante?', dadosPagamentoForm.get('responsavelNumero').value);
    if (dadosPagamentoForm.get('responsavelNumero').value != '') {
      console.log('entrei no add validação');
      this.representanteSelecionado = true;
      dadosPagamentoForm.get('resposavelDataInicio').enable();
      dadosPagamentoForm.get('resposavelDataFim').enable();
      dadosPagamentoForm.get('resposavelDataVencimento').enable();
      dadosPagamentoForm.get('resposavelNumeroProcesso').enable();
      this.adicionarValidacaoRepresentante();
      this.limparValidacoesConta();
    } else {
      console.log('entrei no retirar validação');
      dadosPagamentoForm.get('resposavelDataInicio').disable();
      dadosPagamentoForm.get('resposavelDataFim').disable();
      dadosPagamentoForm.get('resposavelDataVencimento').disable();
      dadosPagamentoForm.get('resposavelNumeroProcesso').disable();
      this.limparValidacaoRepresentante();
    }
  }

  aoMudarNacionalidade() {
    const { nacionalidade } = this.formPrevidencia.value.dadosPessoais;
    if (nacionalidade == this.nacionalidade.Brasileiro || nacionalidade == '') {
      this.formPrevidencia
        .get('dadosPessoais')
        .get('ufNascimento')
        .addValidators(Validators.required);
      this.formPrevidencia
        .get('dadosPessoais')
        .get('municipioNascimento')
        .addValidators(Validators.required);
      this.formPrevidencia.get('dadosPessoais').get('ufNascimento').enable();
      this.formPrevidencia.get('dadosPessoais').get('municipioNascimento').enable();
    } else {
      this.formPrevidencia.get('dadosPessoais').get('ufNascimento').clearValidators();
      this.formPrevidencia.get('dadosPessoais').get('municipioNascimento').clearValidators();
      this.formPrevidencia.get('dadosPessoais').get('ufNascimento').setValue('');
      this.formPrevidencia.get('dadosPessoais').get('municipioNascimento').setValue('');
      this.formPrevidencia.get('dadosPessoais').get('ufNascimento').disable();
      this.formPrevidencia.get('dadosPessoais').get('municipioNascimento').disable();
    }
    this.formPrevidencia.get('dadosPessoais').get('ufNascimento').updateValueAndValidity();
    this.formPrevidencia.get('dadosPessoais').get('municipioNascimento').updateValueAndValidity();
  }

  onCepChange(): void {
    const { cep } = this.formPrevidencia.get('documentos').value;

    if (cep.length == 8) {
      this.loadingCep = true;

      this.pensaoPrevidenciaService.buscarCep(cep).subscribe(
        (data: Endereco) => {
          this.carregarEndereco(data);
          this.loadingCep = false;
        },
        (error) => {
          this.loadingCep = false;
          this.validaErro.retornoErro(error);
        }
      );
    }
  }

  carregarEndereco(endereco: Endereco): void {
    const formDocumentos = this.formPrevidencia.get('documentos');
    formDocumentos.get('rua').setValue(endereco.Logradouro);
    formDocumentos.get('enderecoMunicipio').setValue(endereco.Cidade);
    formDocumentos.get('bairro').setValue(endereco.Bairro);
    formDocumentos.get('complemento').setValue(endereco.Complemento);
    formDocumentos.get('enderecoUf').setValue(endereco.Uf);

    formDocumentos.get('enderecoUf').disable();
    formDocumentos.get('enderecoMunicipio').disable();
  }

  submitPrevidencia(): void {
    const valido: boolean = this.formPrevidencia.valid;
    if (!valido) {
      this.formPrevidencia.markAllAsTouched();
      Swal('Atenção', 'Verifique os campos inválidos.', 'warning');
    } else {
      this.temErro = false;
      this.editar ? this.editarPensao() : this.salvarPensao();
    }
  }

  onRepresentanteChange(evento): void {
    console.log('evento representante change', evento);
    if (evento.NumeroRepresentante) {
      this.representanteSelecionado = true;
      const dadosPagamentoForm = this.formPrevidencia.get('dadosPagamento');
      dadosPagamentoForm.get('responsavelNome').enable();
      dadosPagamentoForm.get('responsavelNumero').enable();
      dadosPagamentoForm.get('responsavelNome').setValue(evento.NomeRepresentante);
      dadosPagamentoForm.get('responsavelNumero').setValue(evento.NumeroRepresentante);
      dadosPagamentoForm.get('resposavelDataInicio').enable();
      dadosPagamentoForm.get('resposavelDataFim').enable();
      dadosPagamentoForm.get('resposavelDataVencimento').enable();
      dadosPagamentoForm.get('resposavelNumeroProcesso').enable();
      dadosPagamentoForm.get('responsavelBanco').setValue(evento.DadosBancarios.Banco);
      dadosPagamentoForm.get('responsavelAgencia').setValue(evento.DadosBancarios.Agencia);
      dadosPagamentoForm.get('responsavelOperacao').setValue(evento.DadosBancarios.Operacao);
      dadosPagamentoForm.get('responsavelConta').setValue(evento.DadosBancarios.ContaCorrente);
      this.adicionarValidacaoRepresentante();
      this.limparValidacoesConta();
      this.buscarAgencias(null, true);
    }
  }

  limparRepresentante(): void {
    Swal({
      title: 'Atenção',
      text: 'Tem certeza de que deseja limpar os dados do representante legal?',
      icon: 'warning',
      dangerMode: true,
      buttons: ['Cancelar', 'Confirmar'],
      closeOnClickOutside: false,
      closeOnEsc: false,
    }).then((confirmacao) => {
      if (confirmacao) {
        this.representanteSelecionado = false;
        const dadosPagamentoForm = this.formPrevidencia.get('dadosPagamento');
        dadosPagamentoForm.get('responsavelNome').setValue('');
        dadosPagamentoForm.get('responsavelNome').disable();
        dadosPagamentoForm.get('responsavelNumero').setValue('');
        dadosPagamentoForm.get('responsavelNumero').disable();
        dadosPagamentoForm.get('resposavelDataInicio').setValue('');
        dadosPagamentoForm.get('resposavelDataInicio').disable();
        dadosPagamentoForm.get('resposavelDataFim').setValue('');
        dadosPagamentoForm.get('resposavelDataFim').disable();
        dadosPagamentoForm.get('resposavelDataVencimento').setValue('');
        dadosPagamentoForm.get('resposavelDataVencimento').disable();
        dadosPagamentoForm.get('resposavelNumeroProcesso').setValue('');
        dadosPagamentoForm.get('resposavelNumeroProcesso').disable();
        dadosPagamentoForm.get('responsavelBanco').setValue('');
        dadosPagamentoForm.get('responsavelBanco').disable();
        dadosPagamentoForm.get('responsavelAgencia').setValue('');
        dadosPagamentoForm.get('responsavelAgencia').disable();
        dadosPagamentoForm.get('responsavelOperacao').setValue('');
        dadosPagamentoForm.get('responsavelOperacao').disable();
        dadosPagamentoForm.get('responsavelConta').setValue('');
        dadosPagamentoForm.get('responsavelConta').disable();
        this.limparValidacaoRepresentante();
        this.adicionarValidacaoConta();
      } else {
        // Aqui você pode colocar o código a ser executado se o usuário cancelar
        Swal('Limpeza cancelada!', 'Você manteve os dados do representante legal.', 'info');
      }
    });
  }

  limparValidacaoRepresentante(): void {
    const dadosPagamentoForm = this.formPrevidencia.get('dadosPagamento');
    dadosPagamentoForm.get('responsavelNome').clearValidators();
    dadosPagamentoForm.get('responsavelNumero').clearValidators();
    dadosPagamentoForm.get('resposavelDataInicio').clearValidators();
    // dadosPagamentoForm.get('resposavelDataFim').clearValidators();
    // dadosPagamentoForm.get('resposavelDataVencimento').clearValidators();
    dadosPagamentoForm.get('resposavelNumeroProcesso').clearValidators();

    dadosPagamentoForm.get('responsavelNome').updateValueAndValidity();
    dadosPagamentoForm.get('responsavelNumero').updateValueAndValidity();
    dadosPagamentoForm.get('resposavelDataInicio').updateValueAndValidity();
    // dadosPagamentoForm.get('resposavelDataFim').updateValueAndValidity();
    // dadosPagamentoForm.get('resposavelDataVencimento').updateValueAndValidity();
    dadosPagamentoForm.get('resposavelNumeroProcesso').updateValueAndValidity();

    dadosPagamentoForm.get('resposavelDataInicio').disable();
    dadosPagamentoForm.get('resposavelDataFim').disable();
    dadosPagamentoForm.get('resposavelDataVencimento').disable();
    dadosPagamentoForm.get('resposavelNumeroProcesso').disable();
  }

  adicionarValidacaoRepresentante(): void {
    const dadosPagamentoForm = this.formPrevidencia.get('dadosPagamento');
    dadosPagamentoForm.get('resposavelDataInicio').enable();
    dadosPagamentoForm.get('resposavelDataFim').enable();
    dadosPagamentoForm.get('resposavelDataVencimento').enable();
    dadosPagamentoForm.get('resposavelNumeroProcesso').enable();

    dadosPagamentoForm.get('responsavelNome').setValidators([Validators.required]);
    dadosPagamentoForm.get('responsavelNumero').setValidators([Validators.required]);
    dadosPagamentoForm.get('resposavelDataInicio').setValidators([Validators.required]);
    // dadosPagamentoForm.get('resposavelDataFim').setValidators([Validators.required]);
    // dadosPagamentoForm.get('resposavelDataVencimento').setValidators([Validators.required]);
    dadosPagamentoForm.get('resposavelNumeroProcesso').setValidators([Validators.required]);

    dadosPagamentoForm.get('responsavelNome').updateValueAndValidity();
    dadosPagamentoForm.get('responsavelNumero').updateValueAndValidity();
    dadosPagamentoForm.get('resposavelDataInicio').updateValueAndValidity();
    // dadosPagamentoForm.get('resposavelDataFim').updateValueAndValidity();
    // dadosPagamentoForm.get('resposavelDataVencimento').updateValueAndValidity();
    dadosPagamentoForm.get('resposavelNumeroProcesso').updateValueAndValidity();
  }

  limparValidacoesConta(): void {
    const dadosPagamentoForm = this.formPrevidencia.get('dadosPagamento');
    dadosPagamentoForm.get('agencia').clearValidators();
    dadosPagamentoForm.get('banco').clearValidators();
    dadosPagamentoForm.get('tipoConta').clearValidators();
    dadosPagamentoForm.get('contaCorrente').clearValidators();
    dadosPagamentoForm.get('agencia').updateValueAndValidity();
    dadosPagamentoForm.get('banco').updateValueAndValidity();
    dadosPagamentoForm.get('tipoConta').updateValueAndValidity();
    dadosPagamentoForm.get('contaCorrente').updateValueAndValidity();
  }

  adicionarValidacaoConta(): void {
    const dadosPagamentoForm = this.formPrevidencia.get('dadosPagamento');
    dadosPagamentoForm.get('agencia').setValidators([Validators.required]);
    dadosPagamentoForm.get('banco').setValidators([Validators.required]);
    dadosPagamentoForm.get('tipoConta').setValidators([Validators.required]);
    dadosPagamentoForm.get('contaCorrente').setValidators([Validators.required]);
    dadosPagamentoForm.get('agencia').enable();
    dadosPagamentoForm.get('banco').enable();
    dadosPagamentoForm.get('tipoConta').enable();
    dadosPagamentoForm.get('contaCorrente').enable();
    dadosPagamentoForm.get('agencia').updateValueAndValidity();
    dadosPagamentoForm.get('banco').updateValueAndValidity();
    dadosPagamentoForm.get('tipoConta').updateValueAndValidity();
    dadosPagamentoForm.get('contaCorrente').updateValueAndValidity();
  }

  onUfNascimentoChange(): void {
    this.listaMunicipioNascimento = [];
    this.loadingMunicipioNascimento = true;
    const uf = this.listaUf.find(
      (uf) => uf.Descricao == this.formPrevidencia.get('dadosPessoais').get('ufNascimento').value
    );
    this.pensaoPrevidenciaService.buscarMunicipio(uf.Codigo).subscribe(
      (data: any) => {
        if (!this.visualizacao) {
          this.formPrevidencia.get('dadosPessoais').get('municipioNascimento').enable();
        }
        this.loadingMunicipioNascimento = false;
        this.listaMunicipioNascimento = data;
        if (this.editar) {
          this.formPrevidencia
            .get('dadosPessoais')
            .get('municipioNascimento')
            .setValue(this.pensionistaEditando.Pensionista.MunicipioNaturalidade);
        }
      },
      (error) => {
        this.loadingMunicipioNascimento = false;
        this.validaErro.retornoErro(error);
      }
    );
  }

  onUfEnderecoChange(): void {
    this.loadingMunicipioEndereco = true;

    const uf = this.listaUf.find(
      (uf) => uf.Descricao == this.formPrevidencia.get('documentos').get('enderecoUf').value
    );
    this.pensaoPrevidenciaService.buscarMunicipio(uf.Codigo).subscribe(
      (data: any) => {
        this.loadingMunicipioEndereco = false;
        this.listaMunicipioEndereco = data;
      },
      (error) => {
        this.loadingMunicipioEndereco = false;
        this.validaErro.retornoErro(error);
      }
    );
  }

  onDataNascimentoChange(): void {
    let dataNascimento: string = this.formPrevidencia
      .get('dadosPessoais')
      .get('dataNascimento')
      .value.replace(/-/g, '');
    if (dataNascimento.length === 8) {
      const dataFormatada = `${dataNascimento.substr(0, 4)}-${dataNascimento.substr(
        4,
        2
      )}-${dataNascimento.substr(6, 2)}`;
      const dataNascimentoDate = new Date(dataFormatada);
      const hoje = new Date();
      let idade = hoje.getFullYear() - dataNascimentoDate.getFullYear();
      const mesAtual = hoje.getMonth() + 1;
      if (
        mesAtual < dataNascimentoDate.getMonth() + 1 ||
        (mesAtual === dataNascimentoDate.getMonth() + 1 &&
          hoje.getDate() < dataNascimentoDate.getDate())
      ) {
        idade--;
      }
      this.menorIdade = idade < 18;
      if (this.menorIdade) {
        this.removerValidacaoTitulo();
        // this.limparValidacoesConta();
        // this.adicionarValidacaoRepresentante();
      } else {
        this.adicionarValidacaoTitulo();
        // this.limparValidacaoRepresentante();
        // this.adicionarValidacaoConta();
      }
    }
  }

  adicionarValidacaoTitulo(): void {
    const documentosForm = this.formPrevidencia.get('documentos');
    documentosForm
      .get('tituloEleitor')
      .setValidators([Validators.required, Validators.minLength(12), tituloValidator()]);
    documentosForm.get('secaoEleitoral').setValidators([Validators.required, zeroValidator()]);
    documentosForm.get('zonaEleitoral').setValidators([Validators.required, zeroValidator()]);
    documentosForm.get('ufEleitoral').setValidators([Validators.required]);
    documentosForm.get('tituloEleitor').updateValueAndValidity();
    documentosForm.get('secaoEleitoral').updateValueAndValidity();
    documentosForm.get('zonaEleitoral').updateValueAndValidity();
    documentosForm.get('ufEleitoral').updateValueAndValidity();
  }

  removerValidacaoTitulo(): void {
    const documentosForm = this.formPrevidencia.get('documentos');
    documentosForm.get('tituloEleitor').clearValidators();
    documentosForm.get('secaoEleitoral').clearValidators();
    documentosForm.get('zonaEleitoral').clearValidators();
    documentosForm.get('ufEleitoral').clearValidators();
    documentosForm.get('tituloEleitor').updateValueAndValidity();
    documentosForm.get('secaoEleitoral').updateValueAndValidity();
    documentosForm.get('zonaEleitoral').updateValueAndValidity();
    documentosForm.get('ufEleitoral').updateValueAndValidity();
  }

  salvarPensao(): void {
    this.spinner.show();
    const pensao: PensaoPrevidenciaria = this.montarPensaoPrevidencia();
    this.pensaoPrevidenciaService.salvarPensao(pensao).subscribe(
      (data) => {
        this.cpfPensionistaEdicao = pensao.Pensionista.Cpf;
        this.buscarExSegurado(true);
        this.novoCadastro();
        this.spinner.hide();
        Swal(
          'Sucesso',
          'Previdência cadastrada, agora você pode adicionar dependentes para esse pensionista.',
          'success'
        );
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  editarPensao(): void {
    this.spinner.show();
    const pensao: PensaoPrevidenciaria = this.montarPensaoPrevidencia();
    this.pensaoPrevidenciaService.editarPensao(pensao).subscribe(
      (data) => {
        // this.step = 1;

        this.cpfPensionistaEdicao = pensao.Pensionista.Cpf;
        // this.novoCadastro();
        this.buscarExSegurado(false, true);

        this.spinner.hide();
        Swal('Sucesso', 'Previdência editada', 'success');
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  limparForm() {
    this.adicao = false;
    this.editar = false;
    this.visualizacao = false;
    this.pensionistaEditando = null;
    this.step = 1;
    this.formPrevidencia.markAsUntouched();
  }

  novoCadastro(): void {
    this.limparForm();

    this.formPrevidencia.enable();
    this.adicao = true;
    this.editar = false;
    this.visualizacao = false;
    this.matriculaPensionista = null;
    this.telefonesAuxiliares = [];
    if (this.step != 1) {
      setTimeout(() => {
        document.getElementById('dados-pessoais-pensionista').classList.add('show');
        document.getElementById('dados-pessoais-pensionista').classList.add('active');
        document.getElementById('documentos-pensionista').classList.remove('active');
        document.getElementById('dados-pagamento-pensionista').classList.remove('active');
        document.getElementById('info-esocial-pensionista').classList.remove('active');
        document.getElementById('dependentes-pensionista').classList.remove('active');

        document.getElementById('dados-pessoais-tab').classList.add('active');
        document.getElementById('documentos-tab').classList.remove('active');
        document.getElementById('info-esocial-tab').classList.remove('active');
        document.getElementById('dados-pessoais-tab').classList.remove('active');
        document.getElementById('dependentes-tab').classList.remove('active');
      }, 300);
    }
    this.step = 1;
    if (!this.primeiraBusca) {
      setTimeout(() => {
        this.cardPensionista.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
    this.formPrevidencia.setValue({
      dadosPessoais: {
        numeroOrdem: '',
        nome: '',
        dataNascimento: '',
        sexo: '',
        estadoCivil: '',
        grauInstrucao: '',
        nacionalidade: '',
        ufNascimento: '',
        municipioNascimento: '',
        nomePai: '',
        nomeMae: '',
        familia: '',
        grauParentesco: '',
        motivo: '',
      },
      documentos: {
        identidade: '',
        dataExpedicao: '',
        orgaoEmissor: '',
        identidadeUf: '',
        cpf: '',
        tituloEleitor: '',
        secaoEleitoral: '',
        zonaEleitoral: '',
        ufEleitoral: '',
        cep: '',
        rua: '',
        numero: '',
        complemento: '',
        bairro: '',
        enderecoUf: '',
        enderecoMunicipio: '',
        celular: '',
        tipoContato: '',
        telefoneAuxiliar: '',
      },
      dadosPagamento: {
        vinculo: '',
        agencia: '',
        banco: '',
        tipoConta: '',
        contaCorrente: '',
        data1Pagamento: '',
        tipoRateio: '',
        rateio: '',
        tipoPensao: '',
        comParidade: '',
        beneficioNumeroProcesso: '',
        beneficioDataFim: '',
        beneficioDataLimite: '',
        beneficioTipoCota: '',
        beneficioNumeroReserva: '',
        codigoAfastamento: '',
        motivoAfastamento: '',
        prazo: '',
        dataAfastamentoFim: '',
        dataFastamentoInicio: '',
        condicao: '',
        isencaoIrDataInicial: '',
        isencaoIrDataFinal: '',
        responsavelNumero: '',
        responsavelNome: '',
        resposavelDataInicio: '',
        resposavelDataFim: '',
        resposavelDataVencimento: '',
        resposavelNumeroProcesso: '',
        responsavelBanco: '',
        responsavelAgencia: '',
        responsavelOperacao: '',
        responsavelConta: '',
      },
      infoEsocial: {
        dataPublicacao: '',
        tipo: '',
        infoOrigem: '',
        derJudicial: '',
        dataCessacao: '',
        motCessacao: '',
        dataReativacao: '',
        dataFinanceiro: '',
      },
    });
    this.carregarRepresentantePensionista();
    this.formPrevidencia.markAsPristine();
  }

  montarPensaoPrevidencia(): PensaoPrevidenciaria {
    const { dadosPessoais, documentos, dadosPagamento, infoEsocial } =
      this.formPrevidencia.getRawValue();
    const dataAtual = new Date().toISOString().slice(0, 10);

    const pensionistaMontado = {
      ExSegurado: this.exSegurado,
      Pensionista: {
        EmpresaFilial: this.exSegurado.EmpresaFilial.Codigo,

        NumeroDeOrdem: this.editar ? this.pensionistaEditando.Pensionista.NumeroDeOrdem : '',
        Matricula: this.editar ? this.pensionistaEditando.Pensionista.Matricula : '',
        DigitoMatricula: '0',
        SequencialFamilial: dadosPessoais.familia,
        Nome: dadosPessoais.nome,
        NomeMae: dadosPessoais.nomeMae,
        NomePai: dadosPessoais.nomePai,
        Tipo: dadosPagamento.tipoPensao,
        Rateio: {
          Percentual: dadosPagamento.rateio,
          Tipo: dadosPagamento.tipoRateio,
        },
        DataNascimento: new Data(dadosPessoais.dataNascimento),
        Sexo: dadosPessoais.sexo,
        EstadoCivil: dadosPessoais.estadoCivil,
        Nacionalidade: dadosPessoais.nacionalidade,
        //Naturalidade: '',
        MunicipioNaturalidade: dadosPessoais.municipioNascimento,
        EstadoNaturalidade: dadosPessoais.ufNascimento,

        Vinculo: dadosPagamento.vinculo,
        GrauInstrucao: dadosPessoais.grauInstrucao,
        TipoDependente: dadosPessoais.grauParentesco,
        MotivoDependente: dadosPessoais.motivo,

        Endereco: {
          Bairro: documentos.bairro,
          Cep: documentos.cep,
          Cidade: documentos.enderecoMunicipio,
          Complemento: documentos.complemento,
          Logradouro: documentos.rua,
          Uf: documentos.enderecoUf,
          Numero: documentos.numero.toString(),
        },
        DDD: Number(documentos.celular.substr(0, 2)),
        Telefone: documentos.celular.substr(2),
        Identidade: {
          DataDeExpedicao: new Data(documentos.dataExpedicao),
          OrgaoEmissor: documentos.orgaoEmissor,
          RegistroGeral: documentos.identidade,
          UF: documentos.identidadeUf,
        },
        TituloEleitor: {
          Numero: documentos.tituloEleitor,
          SecaoEleitoral: documentos.secaoEleitoral,
          ZonaEleitoral: documentos.zonaEleitoral,
          UfEleitoral: documentos.ufEleitoral,
        }, //TODO
        Cpf: documentos.cpf,

        TipoReajuste: '9',
        CentroDeCusto: '', //vazio segundo danilo
        QuantidadeDependentes: '', //'sem utilidade, até mesmo no VB', Danilo
        DataAdmissao: new Data(this.exSegurado.DataAdmissao.ToDate),
        Lotacao: this.exSegurado.Lotacao.Codigo,
        DataAtualizacao: new Data(dataAtual),

        ContaBancaria: {
          Agencia: dadosPagamento.agencia,
          Banco: dadosPagamento.banco,
          NumeroConta: dadosPagamento.contaCorrente,
          TipoConta: dadosPagamento.tipoConta,
        },

        Flags: { ...this.parametrosComponent.retornarValores() },
        NumeroProcesso: dadosPagamento.beneficioNumeroProcesso,
        DataFimBeneficio: dadosPagamento.beneficioDataFim
          ? new Data(dadosPagamento.beneficioDataFim)
          : new Data('00000000'),
        DataLimiteRetroativo: new Data(dadosPagamento.beneficioDataLimite),
        TipoCota: dadosPagamento.beneficioTipoCota,
        NumeroReserva: dadosPagamento.beneficioNumeroReserva,
        DataPrimeiroPagamento: new Data(dadosPagamento.data1Pagamento),
        Situacao: {
          CodigoAfastamento: dadosPagamento.codigoAfastamento,
          DataAfastamento: dadosPagamento.dataFastamentoInicio
            ? new Data(dadosPagamento.dataFastamentoInicio)
            : new Data('00000000'),
          DataRetorno: dadosPagamento.dataAfastamentoFim
            ? new Data(dadosPagamento.dataAfastamentoFim)
            : new Data('00000000'),
          Motivo: dadosPagamento.motivoAfastamento,
          Prazo: dadosPagamento.prazo,
        },
        Isencao: {
          Condicao: dadosPagamento.condicao,
          DataFinal: dadosPagamento.isencaoIrDataFinal
            ? new Data(dadosPagamento.isencaoIrDataFinal)
            : new Data('00000000'),
          DataInicial: dadosPagamento.isencaoIrDataInicial
            ? new Data(dadosPagamento.isencaoIrDataInicial)
            : new Data('00000000'),
        },
        Beneficio: {
          DtPublicacao: new Data(infoEsocial.dataPublicacao),
          Tipo: infoEsocial.tipo,
          InformacaoOriginou: infoEsocial.infoOrigem,
          DeterminacaoJudicial: infoEsocial.derJudicial,
          DtCessacao: infoEsocial.dataCessacao
            ? new Data(infoEsocial.dataCessacao)
            : new Data('00000000'),
          MotivoCessacao: infoEsocial.motCessacao,
          DtReativacao: infoEsocial.dataReativacao
            ? new Data(infoEsocial.dataReativacao)
            : new Data('00000000'),
          DtFinanceiro: infoEsocial.dataFinanceiro
            ? new Data(infoEsocial.dataFinanceiro)
            : new Data('00000000'),
        },
        Contato: this.telefonesAuxiliares,
      },
      Representante: {
        DataFinal: dadosPagamento.resposavelDataFim
          ? new Data(dadosPagamento.resposavelDataFim)
          : new Data('00000000'),
        DataInicial: dadosPagamento.responsavelNumero
          ? new Data(dadosPagamento.resposavelDataInicio)
          : new Data('00000000'),
        DataVencimento: dadosPagamento.resposavelDataVencimento
          ? new Data(dadosPagamento.resposavelDataVencimento)
          : new Data('00000000'),
        FlagsRepresentante: { ...this.parametrosComponent.retornarValores() },
        Numero: dadosPagamento.responsavelNumero,
        NumeroProcesso: dadosPagamento.responsavelNumero
          ? dadosPagamento.resposavelNumeroProcesso
          : '',
        CodigoAgencia: dadosPagamento.responsavelNumero ? dadosPagamento.responsavelAgencia : '',
        CodigoBanco: dadosPagamento.responsavelNumero ? dadosPagamento.responsavelBanco : '',
        ContaCorrente: dadosPagamento.responsavelNumero ? dadosPagamento.responsavelConta : '',
        Operacao: dadosPagamento.responsavelNumero ? dadosPagamento.responsavelOperacao : '',
      },
    };

    return pensionistaMontado;
  }

  abiriModalIndicadores(): void {
    this.parametrosComponent.abrirModal();
  }

  resetForm(): void {
    this.formPrevidencia.reset({
      dadosPessoais: {
        sexo: '',
        estadoCivil: '',
        grauInstrucao: '',
        nacionalidade: '',
        ufNascimento: '',
        municipioNascimento: '',
        familia: '',
        grauParentesco: '',
        motivo: '',
      },
      documentos: {
        identidadeUf: '',
        ufEleitoral: '',
        enderecoUf: '',
      },
      dadosPagamento: {
        vinculo: '',
        banco: '',
        tipoConta: '',
        tipoRateio: '',
        tipoPensao: '',
        comParidade: '',
        beneficioTipoCota: '',
        codigoAfastamento: '',
        motivoAfastamento: '',
        condicao: '',
      },
      infoEsocial: {
        dataPublicacao: '',
        tipo: '',
        infoOrigem: '',
        derJudicial: '',
        dataCessacao: '',
        motCessacao: '',
        dataReativacao: '',
        dataFinanceiro: '',
      },
    });
  }

  avancarEtapas() {
    const currentStep = this.steps.find((s) => s.step === this.step);

    if (!currentStep) return;

    if (this.formPrevidencia.get(currentStep.formControlName).valid) {
      document.getElementById(currentStep.currentElementId).classList.remove('active');
      document.getElementById(currentStep.nextElementId).classList.add('active');
      if (currentStep.step == 5) {
        this.step = 1;
      } else {
        this.step = currentStep.step + 1;
        // if(this.step == 4){
        //   this.buscarDependentes(this.form)
        // }
      }
    } else {
      this.formPrevidencia.get(currentStep.formControlName).markAllAsTouched();
      Swal('Atenção', 'Preencha os campos corretamente.', 'warning');
    }
  }

  formataCPF(cpf): string {
    // Colocar zero a esquerda do cpf
    if (cpf.length < 11) {
      const resto = 11 - cpf.length;
      cpf = '0'.repeat(resto > 0 ? resto : 0) + cpf;
    }

    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, '');

    //realizar a formataÃ§Ã£o...
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  formatarDataParaForm(data: string): string {
    const ano = data.substr(0, 4);
    const mes = data.substr(4, 2);
    const dia = data.substr(6, 2);

    return `${ano}-${mes}-${dia}`;
  }

  adicionarContatoSecundario() {
    let tipo = this.formPrevidencia.get('documentos').get('tipoContato').value;
    let ddd = this.formPrevidencia.get('documentos').get('telefoneAuxiliar').value.substring(0, 2);
    let numero = this.formPrevidencia.get('documentos').get('telefoneAuxiliar').value.substring(2);

    let novoContato = {
      Tipo: tipo,
      DDDTelefone: ddd,
      Telefone: numero,
    };

    this.telefonesAuxiliares.push(novoContato);

    this.formPrevidencia.get('documentos').get('tipoContato').setValue('');
    this.formPrevidencia.get('documentos').get('telefoneAuxiliar').setValue('');
  }

  apagarContatoSecundario(telefoneEscolhido: string) {
    let indexContato = this.telefonesAuxiliares.findIndex(
      (telefone) => telefone.Telefone == telefoneEscolhido
    );
    if (indexContato != -1) {
      this.telefonesAuxiliares.splice(indexContato, 1);
    }
  }

  somarRateioFamilia() {
    let familia = this.exSegurado.Familia;
    let soma;
    if (familia.length > 0) {
      soma = familia
        .filter((pensionista) => pensionista.Pensionista.Situacao.CodigoAfastamento == 20)
        .filter((pensionista) => pensionista.Pensionista.Rateio.Tipo == '1')
        .reduce((total, item) => {
          return total + (parseFloat(item.Pensionista.Rateio.Percentual.replace(/,/g, '.')) || 0);
        }, 0);
      return soma.toString().replace(/\./g, ',');
    } else {
      return 0;
    }
  }

  montarFormRateio() {
    this.listaRateioCodaf20 = [];
    this.formRateio = this.fb.group({
      rateioTotal: [''],
    });
    this.listaRateioCodaf20 = this.exSegurado.Familia.filter(
      (pensionista) => pensionista.Pensionista.Situacao.CodigoAfastamento == 20
    );

    this.formRateio.get('rateioTotal').setValue(this.somarRateioFamilia());
    this.listaRateioCodaf20.forEach((pensionista, i) => {
      this.formRateio.addControl('nome' + i, new FormControl(pensionista.Pensionista.Nome));
      this.formRateio.addControl(
        'matricula' + i,
        new FormControl(pensionista.Pensionista.Matricula)
      );
      this.formRateio.addControl(
        'rateio' + i,
        new FormControl(this.tratarRateio(pensionista.Pensionista.Rateio.Percentual))
      );
      this.formRateio.addControl('tipo' + i, new FormControl(pensionista.Pensionista.Rateio.Tipo));
    });
  }

  tratarRateio(valor: string): string {
    let [parteInteira, parteDecimal] = valor.split(',');
    if (!parteDecimal) {
      parteDecimal = '00';
    }

    parteDecimal = parteDecimal.padEnd(2, '0');
    parteInteira = parteInteira.padStart(3, '0');

    return `${parteInteira},${parteDecimal}`;
  }

  atualizarTotalRateio() {
    let soma = 0;
    this.listaRateioCodaf20.forEach((pensionista, i) => {
      if (this.formRateio.get('tipo' + i).value == '1') {
        soma = soma + parseFloat(this.formRateio.get('rateio' + i).value.replace(/,/g, '.'));
      }
    });
    this.formRateio.get('rateioTotal').setValue(soma);
    if (soma > 100) {
      this.formRateio.get('rateioTotal').setErrors({ maiorQue100: true });
    } else {
      this.formRateio.get('rateioTotal').setErrors(null);
    }
  }

  mascaraRateio(form: string, input: string, tipo: string, evento) {
    let valor = evento.target.value;

    valor = valor.replace(/\D/g, ''); // Remove todos os não dígitos
    valor = valor.replace(/^0+/, '');
    if (valor.length > 5) {
      valor = valor.slice(0, -1);
    }
    valor = valor.padStart(5, '0'); //adiciona 0 iniciais
    valor = valor.replace(/(\d+)(\d{2})$/, '$1,$2'); // Adiciona a parte de centavos
    if (form == 'formRateio') {
      this[form].get(input).setValue(valor);
    } else {
      this[form].get('dadosPagamento').get(input).setValue(valor);
    }

    if (tipo == '1') {
      parseFloat(valor.replace(/,/g, '.')) >= 100.0
        ? this[form].get(input).setValue('100,00')
        : null;
      if (form == 'formRateio') {
        this.atualizarTotalRateio();
      }
    }

    if (valor == '' || valor == '0' || valor == '000,00') {
      if (form == 'formPrevidencia') {
        this[form].get('dadosPagamento').get(input).setErrors({ zero: true });
      } else {
        this[form].get(input).setErrors({ zero: true });
      }
    } else {
      if (form == 'formPrevidencia') {
        this[form].get('dadosPagamento').get(input).setErrors(null);
      } else {
        this[form].get(input).setErrors(null);
      }
    }
  }

  salvarRateios() {
    if (this.formRateio.valid) {
      this.spinner.show();
      this.pensaoPrevidenciaService.editarRateios(this.montarListaRateio()).subscribe(
        (data) => {
          Swal('Sucesso', 'Previdência editada', 'success');
          this.spinner.hide();
          this.buscarExSegurado();
          document.getElementById('fecharRateio').click();
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        }
      );
    } else {
      this.formRateio.markAllAsTouched();
      Swal('Atenção', 'Verifique os campos inválidos.', 'warning');
    }
  }

  montarListaRateio(): any[] {
    let listaRateio = [];
    console.log('form rateio', this.formRateio.value);
    this.listaRateioCodaf20.forEach((pensionista, i) => {
      listaRateio.push({
        EmpresaFilial: this.exSegurado.EmpresaFilial.Codigo,
        NumeroDeOrdem: this.exSegurado.NumeroDeOrdem,
        Matricula: this.formRateio.get('matricula' + i).value,
        Rateio: {
          Percentual: this.formRateio.get('rateio' + i).value,
          Tipo: this.formRateio.get('tipo' + i).value,
        },
      });
    });
    return listaRateio;
  }

  mudarCondicaoIsencaoIR() {
    let valorCondicao = this.formPrevidencia.get('dadosPagamento').get('condicao').value;
    if (valorCondicao == 'S' || valorCondicao == '') {
      this.formPrevidencia.get('dadosPagamento').get('isencaoIrDataInicial').setValue('');
      this.formPrevidencia.get('dadosPagamento').get('isencaoIrDataFinal').setValue('');

      this.formPrevidencia.get('dadosPagamento').get('isencaoIrDataInicial').clearValidators();
      this.formPrevidencia.get('dadosPagamento').get('isencaoIrDataFinal').clearValidators();
    }
    if (valorCondicao == 'N') {
      this.formPrevidencia
        .get('dadosPagamento')
        .get('isencaoIrDataInicial')
        .addValidators(Validators.required);
    }
    this.formPrevidencia.get('dadosPagamento').get('isencaoIrDataInicial').updateValueAndValidity();
    this.formPrevidencia.get('dadosPagamento').get('isencaoIrDataFinal').updateValueAndValidity();
  }

  retornarCodaf(codigo: number) {
    let afastamento = this.listaCodigoAfastamento.find((a) => a.Codigo == codigo.toString());

    if (afastamento) {
      return ' - ' + afastamento.Descricao;
    } else {
      return '';
    }
  }

  initializeObject(): void {
    this.exSegurado.Matricula = '';
    this.exSegurado.Cpf = '';
    this.exSegurado.DataFalecimento = {} as Data;
    this.exSegurado.Identidade = {} as Identidade;
    this.exSegurado.Vinculo = {} as Parametro;
    this.exSegurado.Familia = [] as Familia[];
    this.exSegurado.EmpresaFilial = {} as Parametro;
    this.exSegurado.Lotacao = {} as Parametro;
    this.exSegurado.Situacao = {} as Situacao;
    this.exSegurado.Identidade.DataDeExpedicao = {} as Data;
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}

interface ExSegurado {
  Cpf: string;
  Matricula: string;
  NumeroDeOrdem: string;
  Nome: string;
  NomePai: string;
  NomeMae: string;
  DataFalecimento: Data;
  DataAfastamento: Data;
  Identidade: Identidade;
  EmpresaFilial: Parametro;
  Lotacao: Parametro;
  CodigoCusto: string;
  Situacao: Situacao;
  Vinculo: Parametro;
  Nivel: string;
  DataAdmissao: Data;
  Familia: Familia[];
  Sexo: string;
}

interface ContatoExtra {
  DDDTelefone: string;
  Telefone: string;
  Tipo: string;
}

interface Identidade {
  DataDeExpedicao: Data;
  OrgaoEmissor: string;
  RegistroGeral: number;
  UF: string;
}

interface Situacao {
  Codigo: string;
  Descricao: string;
  Motivo: String;
  DataAfastamento: Data;
}

export interface PensaoPrevidenciaria {
  ExSegurado: ExSegurado;
  Pensionista: PensionistaPrevidenciario;
  Representante: RepresentantePrevidenciario;
}

interface PensionistaPrevidenciario {
  EmpresaFilial: string;
  NumeroDeOrdem: string;
  Matricula: string;
  DigitoMatricula: string;
  SequencialFamilial: string;
  Nome: string;
  NomeMae: string;
  NomePai: string;
  Tipo: string;
  Rateio: RateioPensionista;
  DataNascimento: Data;
  Sexo: string;
  EstadoCivil: string;
  Nacionalidade: string;
  //	Naturalidade: string;
  MunicipioNaturalidade: string;
  EstadoNaturalidade: string;
  CentroDeCusto: string;
  Lotacao: string;
  Vinculo: string;
  GrauInstrucao: string;
  TipoDependente: string;
  MotivoDependente: string;
  Endereco: Endereco;
  DDD: number;
  Telefone: string;
  Identidade: Identidade;
  TituloEleitor: TituloEleitor; //TODO
  Cpf: string;
  QuantidadeDependentes: string;
  DataAtualizacao: Data;
  ContaBancaria: ContaBancaria;
  Flags: Flags;
  TipoReajuste: string;
  NumeroProcesso: string;
  DataFimBeneficio: Data;
  DataLimiteRetroativo: Data;
  TipoCota: string;
  NumeroReserva: string;
  DataPrimeiroPagamento: Data;
  DataAdmissao: Data;
  Situacao: Afastamento;
  Isencao: IsencaoPrevidenciaria;
  Beneficio: BeneficioPrevidenciario;
  Contato: ContatoExtra[];
}

interface IsencaoPrevidenciaria {
  Condicao: string;
  DataInicial: Data;
  DataFinal: Data;
}

interface RateioPensionista {
  Tipo: string;
  Percentual: string;
}

interface Flags {
  Flag1: string;
  Flag2: string;
  Flag3: string;
  Flag4: string;
  Flag5: string;
  Flag6: string;
  Flag7: string;
  Flag8: string;
  Flag9: string;
  Flag10: string;
}

interface RepresentantePrevidenciario {
  Numero: string;
  Nome?: string;
  DataInicial: Data;
  DataFinal: Data;
  DataVencimento: Data;
  NumeroProcesso: string;
  FlagsRepresentante: Flags;
  CodigoAgencia: string;
  CodigoBanco: string;
  ContaCorrente: string;
  Operacao: string;
}

interface BeneficioPrevidenciario {
  DtPublicacao: Data;
  Tipo: string;
  InformacaoOriginou: string;
  DeterminacaoJudicial: string;
  DtCessacao: Data;
  MotivoCessacao: string;
  DtReativacao: Data;
  DtFinanceiro: Data;
}

interface Familia {
  Pensionista: PensionistaPrevidenciario;
  Representante: RepresentantePrevidenciario;
}

interface Afastamento {
  CodigoAfastamento: number;
  Motivo: number;
  DataAfastamento: Data;
  DataRetorno: Data;
  Prazo: number;
}

interface Step {
  step: number;
  formControlName: string;
  currentElementId: string;
  nextElementId: string;
}
