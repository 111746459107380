import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErroService } from 'src/app/service/erros/erro.service';
import { TarefasService } from './tarefas.service';
import Swal from 'sweetalert';
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { Data } from '@angular/router';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';

@Component({
  selector: 'app-tarefas',
  templateUrl: './tarefas.component.html',
  styleUrls: ['./tarefas.component.css']
})
export class TarefasComponent implements OnInit {

  localStorage: LocalStorage;

  formTelaInicial: FormGroup;
  formTarefas: FormGroup;

  listaServicos: any[];
  listaTodasTarefas: any[] = [];
  listaTarefas: any[] = [];

  filter: string = "";
  tipoUsuario: string;
  filialAtual: string = '';
  nomeTipoTarefa: string;
  idExcluindo: string = "";
  nivelTarefa: number;
  tarefaSelecionada: any = null;
  desabilitarBt: any;

  clientePublico: boolean;
  loadingColaborador: boolean;
  habilitarVoltar: boolean = false;
  telaFormulario: boolean = false;
  temErro: boolean = false;
  isPai: boolean = true;
  habilitarCancelar: boolean;
  habilitarSalvar: boolean = true;

  constructor(public fb: FormBuilder, private tarefasService: TarefasService, private validaErro: ErroService, private spinner: NgxSpinnerService) {
    this.localStorage = this.decryptLocalStorage();
    this.tipoUsuario = this.localStorage.TipoUsuario;
    this.clientePublico = this.localStorage.ClientePublico === true;
    this.filialAtual = String(this.localStorage.FilialAtual)
  }

  ngOnInit() {
    this.formTarefas = this.fb.group({
      descricao: ['', [Validators.required]],
      codigoTarefa: ['', [Validators.required]],
      ePai: ['', [Validators.required]],
      tarefaPai: '',
      nivel: 0
    });

    this.formTelaInicial = this.fb.group({
      codServico_form: ['', [Validators.required]],
    });

    this.buscarServicos()
  }

  buscarTarefa(codigo: string): void {
    this.tarefasService.buscarTarefas(codigo).subscribe((data: any[]) => {
      this.listaTodasTarefas = data
      this.listaTarefas = data
      this.montarListaInicial()


    }, error => this.validaErro.retornoErro(error))
  }

  buscarTarefasPorCodigo(): void {
    const codigo = this.formTelaInicial.get('codServico_form').value;
    if (codigo != '') {
      this.temErro = false
      this.loadingColaborador = true;
      this.tarefasService.buscarTarefasPorCodigo(codigo)
        .subscribe(
          (data: any) => {
            this.loadingColaborador = false;
            this.listaTarefas = data
            this.buscarTarefa(codigo)
            this.telaFormulario = !this.telaFormulario
          },
          error => this.validaErro.retornoErro(error)
        );
    } else {
      this.loadingColaborador = false;
      this.temErro = true
      new AlertaModal('alerta','Atenção','Você precisa digitar um código.','p')
    }
  }

  buscarServicos(): void {
    this.spinner.show()
    this.tarefasService.buscarServicosPse()
      .subscribe(
        (data: any[]) => {
          this.listaServicos = data
          this.spinner.hide();
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        }
      );
  }

  cancelarModal() {
    this.listaTarefas = this.listaTodasTarefas.filter(valor => valor.Nivel == 0);
    this.fecharModal("closeModal");
    this.tarefaSelecionada = null;
    for (let i = 0; i < this.listaTarefas.length; i++) {
      let element = <HTMLInputElement>document.getElementById(this.listaTarefas[i].Codigo);
      if (element != null) element.checked = false;
    }
  }

  montarListaInicial() {
    this.listaTarefas = this.listaTodasTarefas.filter(valor => valor.Nivel == 0);
    if (this.listaTarefas.length == 0) { this.habilitarVoltar = false; this.habilitarCancelar = false; this.habilitarSalvar = false }
    else {
      this.habilitarVoltar = false;
      this.habilitarCancelar = true;
      this.habilitarSalvar = true;
    }
  }

  selecionarTipoTarefa(tarefa: Tarefa) {
    if (tarefa.TemFilho == true && this.isPai == false) {
      this.habilitarVoltar = true
      this.habilitarCancelar = false;
      let codigoPai = tarefa.Codigo
      this.listaTarefas = this.listaTodasTarefas.filter(valor => valor.CodigoPai == codigoPai)
      setTimeout(a => this.checarTarefa(), 300)

    } else if (tarefa.TemFilho == true && this.isPai == true) {
      new AlertaModal('alerta','Atenção','Tarefa pai não pode ser alocada em outra tarefa.','p')
    }
    else {
      new AlertaModal('alerta','Atenção','Essa tarefa não possui filho(s).','p')
    }
  }

  checarTarefa() {
    for (let i = 0; i < this.listaTarefas.length; i++) {
      if (this.tarefaSelecionada != null && this.listaTarefas[i].Codigo == this.tarefaSelecionada.Codigo) {
        let element = <HTMLInputElement>document.getElementById(String(this.listaTarefas[i].Codigo))
        element.checked = true;
      }
    }
  }

  somenteLetras(evento) {
    return ((evento.charCode >= 58 && evento.charCode <= 90) || (evento.charCode >= 97 && evento.charCode <= 252) || (evento.charCode === 32));
  }

  somenteNumero(evento) {
    return ((evento.charCode >= 48 && evento.charCode <= 57));
  }

  submitCadastroTarefa(): void {
    if (this.formTarefas.invalid) {
      if (!this.isPai && this.tarefaSelecionada == null) {
        new AlertaModal('alerta','Atenção','Selecione uma tarefa pai!','p')
      } else {
        this.temErro = true;
        new AlertaModal('alerta','Atenção','Preencha todos os campos obrigatórios!','p')
      }
    }
    else {
      let existeTarefa = this.listaTarefas.filter((item: Tarefa) => item.Codigo == this.formTarefas.controls["codigoTarefa"].value).length
      if (existeTarefa > 0) {
        this.editarTarefa()
      }
      else {
        this.salvarCadastroCTarefa();
      }
    }
  }

  editarTarefa() {
    const tarefa = this.montarTarefaEditada()
    this.spinner.show()
    this.tarefasService.editarTarefa(tarefa)
      .subscribe(
        data => {
          this.buscarTarefa(tarefa.CodigoServico)
          new AlertaModal('sucesso',"Sucesso","Tarefa alterada com sucesso!",'p')
          this.limparCampos()
          this.spinner.hide()
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide()
        })
  }

  salvarCadastroCTarefa() {
    const tarefa = this.montarTarefa()
    this.spinner.show()
    this.tarefasService.salvarTarefa(tarefa)
      .subscribe(
        data => {
          new AlertaModal('sucesso',"Sucesso","Tarefa salva com sucesso!",'p')
          this.buscarTarefa(tarefa.CodigoServico)
          this.limparCampos()
          this.spinner.hide()
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide()
        })
  }

  montarTarefa(): Tarefa {
    const tarefa = this.formTarefas.getRawValue()
    let length = 0

    if (this.tarefaSelecionada == undefined || this.tarefaSelecionada.length == 0) { this.tarefaSelecionada = null }
    if (this.tarefaSelecionada != null && tarefa.ePai == "false") { length = 1 }

    return {
      Codigo: tarefa.codigoTarefa,
      Descricao: tarefa.descricao,
      CodigoServico: this.formTelaInicial.get('codServico_form').value,
      TemFilho: false,
      TarefaPai: tarefa.ePai,
      CodigoPai: length > 0 ? this.tarefaSelecionada.Codigo : "0",
      Nivel: length > 0 ? this.tarefaSelecionada.Nivel + 1 : 0
    }
  }

  montarTarefaEditada(): Tarefa {
    const tarefa = this.formTarefas.getRawValue()

    return {
      Codigo: tarefa.codigoTarefa,
      Descricao: tarefa.descricao,
      CodigoServico: this.formTelaInicial.get('codServico_form').value,
      TemFilho: this.tarefaSelecionada.TemFilho,
      TarefaPai: tarefa.ePai,
      CodigoPai: this.tarefaSelecionada.CodigoPai,
      Nivel: this.tarefaSelecionada.Nivel,
    }
  }

  //modal confrimar
  deletar(item: Tarefa, index: any): void {
    this.idExcluindo = index;
    Swal({
      title: "Você tem certeza?",
      text: "Esta operação não pode ser desfeita.",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true
        }
      }
    })
      .then((willDelete) => {
        if (willDelete) {
          this.excluirTarefa(item);
        }
      });
    this.idExcluindo = '';
  }

  excluirTarefa(item: Tarefa) {
    this.spinner.show()
    this.tarefasService.deletarTarefa(item.CodigoServico, item.Codigo)
      .subscribe(
        data => {
          new AlertaModal('sucesso',"Sucesso",'Tarefa excluída com sucesso!','p')
          this.limparCampos()
          this.buscarTarefa(item.CodigoServico)
          this.spinner.hide()
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide()
        })
  }

  contarFilhos(tarefa: Tarefa): number {
    let codigoPai = tarefa.Codigo
    let lista = this.listaTodasTarefas.filter(valor => valor.CodigoPai == codigoPai)
    let contador = lista.length
    return contador
  }

  fecharModal(id) {
    document.getElementById(id).click()
  }

  voltarNivel(tarefa: Tarefa) {
    this.nivelTarefa = tarefa.Nivel
    this.nivelTarefa--
    if (this.nivelTarefa != 0) {
      setTimeout(a => this.checarTarefa(), 300)
      let nivel = this.nivelTarefa
      let temporario: Array<Tarefa> = this.listaTodasTarefas.filter((valor: Tarefa) => valor.Nivel == nivel && valor.Codigo == tarefa.CodigoPai)
      this.listaTarefas = this.listaTodasTarefas.filter((valor: Tarefa) => valor.Nivel == temporario[0].Nivel && valor.CodigoPai == temporario[0].CodigoPai)
      this.habilitarVoltar = true
      this.habilitarCancelar = false

    } else if (this.nivelTarefa == 0) {
      setTimeout(a => this.checarTarefa(), 300)
      let nivel = this.nivelTarefa
      this.listaTarefas = this.listaTodasTarefas.filter(valor => valor.Nivel == nivel)
      this.habilitarVoltar = false
      this.habilitarCancelar = true
    }
  }
  // manter checado o raido quando selecionar alguma tarefa
  carregarTarefa(tarefa: Tarefa) {
    var tarefaEditada = this.listaTodasTarefas.filter((listaTarefa: Tarefa) => tarefa.Codigo == listaTarefa.Codigo)
    this.formTarefas.setValue({
      codigoTarefa: tarefaEditada[0].Codigo,
      descricao: tarefaEditada[0].Descricao,
      tarefaPai: tarefaEditada[0].CodigoPai,
      ePai: tarefaEditada[0].Nivel == 0 ? true : false,
      nivel: tarefaEditada[0].Nivel
    });

    this.listaTarefas = this.listaTodasTarefas.filter(valor => valor.Nivel == tarefaEditada[0].Nivel && tarefaEditada[0].Codigo == valor.Codigo);
    if (this.listaTarefas.length != 0) {

      this.listaTarefas.forEach(function (tarefaAux) {
        if (tarefa.Codigo == tarefaAux.codigoTarefa) {
          let element = <HTMLInputElement>document.getElementById(tarefaAux.Codigo);
          if (element != null) { element.checked = true; }
        }
      });
      this.formTarefas.get('codigoTarefa').disable();
      this.formTarefas.get('ePai').disable();
      this.isPai = true
      this.tarefaSelecionada = this.listaTarefas[0]
    }
    window.scroll(0, 0);
  }

  clickTarefaVinculada(item: any) {
    this.listaTarefas = this.listaTodasTarefas.filter(valor => valor.Nivel == item.nivel - 1);
    if (this.listaTarefas.length != 0) {
      if (this.formTarefas.get('codigoTarefa').disabled) { this.formTarefas.get('codigoTarefa').enable(); }
      this.listaTarefas.forEach(function (tarefa) {
        if (tarefa.Codigo == item.tarefaPai) { //codigo pai em um dos 2
          let element = <HTMLInputElement>document.getElementById(tarefa.Codigo);
          if (element != null) { element.checked = true; }
        }
      });
      if (item.nivel > 0) { this.habilitarVoltar = true; this.habilitarCancelar = false; };

    } else {
      this.montarListaInicial()
    }
  }

  salvarTarefaPai() {
    if (this.tarefaSelecionada != null) {
      this.fecharModal("closeModal2")
    } else {
      new AlertaModal('alerta','Atenção','Selecione uma Tarefa Pai!','p')
    }
  }

  limparCampos(): void {
    this.formTarefas.controls['codigoTarefa'].setValue("");
    this.formTarefas.controls['descricao'].setValue("");
    this.formTarefas.controls['ePai'].setValue("");
    this.formTarefas.controls['tarefaPai'].setValue("");
    this.formTarefas.controls['nivel'].setValue("")
    this.tarefaSelecionada = null

    this.formTarefas.get('codigoTarefa').enable();
    this.formTarefas.get('ePai').enable();
    this.formTarefas.markAsPristine();
    this.temErro = false;
    this.isPai = true;
  }

  selecionarTarefa(tarefa: string, isChecked: boolean) {
    if (isChecked && this.isPai == false) {
      this.tarefaSelecionada = tarefa
    } else if (this.isPai == true) {
      new AlertaModal('alerta','Atenção','Operação invalida!','p')
      isChecked = false
    }
    else {
      this.tarefaSelecionada = null
    }
  }

  criarPai(): boolean {
    const codigo = this.formTarefas.get('ePai').value;
    if (codigo == "true") { this.isPai = true }
    else { this.isPai = false }
    return this.desabilitarBt
  }

  limparTela() {
    this.formTarefas.reset({
      codigo: '',
      descricao: '',
      tipo: '',
      dataValidade: '',
      prazoAlterSenha: '',
      seqObrigatoria: '',
      necessAutentAuxiliar: '',
      tarefaPai: ''
    });
    this.listaTarefas = null;
  }

  onKeyUp(target : any) {
    if(target instanceof EventTarget) {
      var elemento = target as HTMLInputElement;
      this.filter = elemento.value;
    }
  }
  onChange(target : any) {
    if(target instanceof EventTarget) {
      var elemento = target as HTMLInputElement;
      return elemento.checked;
    }
  }
  
  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }
}

export interface Tarefa {
  Codigo: number;
  Descricao: string;
  CodigoServico: string;
  TemFilho: boolean;
  CodigoPai: number;
  Nivel: number;
  TarefaPai: boolean
}

export interface filho {
  filhoFilho: [];
  Descricao: string;
  Codigo: string;
}
