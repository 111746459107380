import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function dataAtualOuAnteriorValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      // Se a data não estiver definida, não há erro de validação
      return null;
    }

    const dataSelecionada = new Date(control.value);
    const dataAtual = new Date();

    // Verifica se a data selecionada é maior que a data atual
    if (dataSelecionada > dataAtual) {
      return {dataInvalida: true, mensagem: 'A data não pode ser maior que a data atual.'};
    }

    // Se a data for válida (igual ou anterior à data atual), retorna null
    return null;
  };
}
