export class Verba{
    Codigo           : string;
    Nome             : string;
    ExclusaoPossivel : string;
    ValorMaximo      : string;
    C01              : string;
    C02              : string;
    C12              : string;
    C20              : string;
    C21              : string;
    C23              : string;
    Flag35           : string;
    Flag36           : string;
    DTINIVAL         : string;
    DTFIMVAL         : string;
    ErroFisico       : boolean;
}