import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { ErroService } from 'src/app/service/erros/erro.service';
import Swal from 'sweetalert';
import { AvisosService } from './avisos.service';

@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.css']
})
export class AvisosComponent implements OnInit {

  localStorage: LocalStorage;

  formAvisos: FormGroup;

  @Input() ativarLista: boolean = true;

  listaAvisos: Aviso[] = [];
  listaTipoAviso: TipoAviso[] = [];

  filter: string = "";
  mensagemContador: string = "";
  formatDate: string = "";

  idAviso: number;
  valorMaximoMsn: number = 5000;

  temErro: boolean = false;
  editando: boolean = false;

  constructor(private spinner: NgxSpinnerService, private fb: FormBuilder, private avisoService: AvisosService, private validaError: ErroService, private datepipe: DatePipe) {
    this.localStorage = this.decryptLocalStorage();
   }

  ngOnInit() {
    this.formAvisos = this.fb.group(
      {
        prazo: ["", [Validators.required]],
        mensagem: ["", [Validators.required, Validators.minLength(5), Validators.maxLength(this.valorMaximoMsn)]],
        tipoAviso: ["", [Validators.required]]
      }
    );

    this.buscarAvisos();
    this.buscarTipoAviso();
    //setar o valor do input no formulário
    setInterval(() => { this.formAvisos.get("prazo").setValue((document.getElementById("setpicker") as HTMLInputElement).value); }, 1000);
  }

  buscarAvisos(): void {
    this.avisoService.buscarAvisos().subscribe(
      (data: Aviso[]) => {
        this.listaAvisos = data;
        this.limparCampos();
      },
      erro => {
        this.validaError.retornoErro(erro);
      }
    ); 
  } 

  buscarTipoAviso() {
    this.spinner.show();
    this.avisoService.buscarTipoAvisos().subscribe(
      (data: TipoAviso[]) => {
        this.listaTipoAviso = data;
        this.spinner.hide();
      },
      erro => {
        this.validaError.retornoErro(erro);
        this.spinner.hide();
      }
    )
  }

  submitAviso() {
    if (this.formAvisos.valid) {
      if (this.editando) {
        this.editarAviso(this.idAviso, this.montarAviso());
      }
      else {
        this.salvarAviso();
      }
    } else {
      Swal('Atenção', 'Preencha Todos os Campos.', 'warning');
    }
  }

  //montar o objeto que vai ser enviado para o back
  montarAviso(): Aviso {
    const aviso = this.formAvisos.getRawValue();
    let fullDate = (document.getElementById("setpicker") as HTMLInputElement).value;
    let splitFullDate = fullDate.split('-');
    let inicio = splitFullDate[0].split(" ");
    let fim = splitFullDate[1].split(" ");

    return {
      DataInicial: this.formatarDataEnvio(inicio[0]),
      HoraInicial: inicio[1].length == 4 ? "0" + inicio[1] + ":00" : inicio[1] + ":00",
      DataFinal: this.formatarDataEnvio(fim[1]),
      HoraFinal: fim[2].length == 4 ? "0" + fim[2] + ":00" : fim[2] + ":00",
      Mensagem: aviso.mensagem.toUpperCase(),
      TipoAviso: aviso.tipoAviso,
      Usuario: this.localStorage.NomeUsuario.toUpperCase()
    }
  }

    onKeyUp(target : any) {
    if(target instanceof EventTarget) {
      var elemento = target as HTMLInputElement;
      return elemento.value;
    }
  }

  salvarAviso() {
    this.spinner.show();
    this.avisoService.salvarAvisos(this.montarAviso()).subscribe(
      () => {
        this.limparCampos();
        this.buscarAvisos();
        this.spinner.hide();
        Swal('Sucesso', 'Preencha Todos os Campos.', 'success');
      },
      erro => {
        this.validaError.retornoErro(erro);
        this.spinner.hide();
      }
    )
  }

  limparCampos() {
    this.formAvisos.setValue({
      prazo: '',
      mensagem: '',
      tipoAviso: ''
    });

    this.editando = false;
    this.mensagemContador = '';
    this.temErro = false;
    //limpar o input das datas
    (document.getElementById("setpicker") as HTMLInputElement).value = "";
  }

  excluirAviso(id: number) {
    this.spinner.show();
    this.avisoService.excluirAvisos(id).subscribe(
      () => {
        this.spinner.hide();
        this.buscarAvisos();
      },
      erro => {
        this.validaError.retornoErro(erro);
        this.spinner.hide();
      }
    )
  }

  //aviso para confirmar o excluir 
  deletarAviso(id: number) {
    Swal({
      title: "Você tem certeza?",
      text: "Esta operação não pode ser desfeita.",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true
        }
      }
    })
      .then((willDelete) => {
        if (willDelete) {
          this.excluirAviso(id);
        }
      });
  }

  carregarCampos(aviso: Aviso) {
    this.idAviso = aviso.Id;
    this.mensagemContador = aviso.Mensagem;
    this.formAvisos.setValue({
      prazo: `${this.formatarDataObter(aviso.DataInicial)} ${aviso.HoraInicial.substring(0, 5)} - ${this.formatarDataObter(aviso.DataFinal)} ${aviso.HoraFinal}`,
      mensagem: aviso.Mensagem,
      tipoAviso: aviso.TipoAviso
    });
    window.scroll(0, 0);
    this.editando = true;
    //setar o valo no input das datas
    (document.getElementById("setpicker") as HTMLInputElement).value =
      `${this.formatarDataObter(aviso.DataInicial)} ${aviso.HoraInicial.substring(0, 5)} - ${this.formatarDataObter(aviso.DataFinal)} ${aviso.HoraFinal.substring(0, 5)}`;
  }

  editarAviso(id: number, aviso: Aviso) {
    this.spinner.show();
    this.avisoService.editarAvisos(id, aviso).subscribe(
      () => {
        this.buscarAvisos();
        this.limparCampos();
        this.spinner.hide();
        Swal('Sucesso', 'Preencha Todos os Campos.', 'success');
      },
      erro => {
        this.validaError.retornoErro(erro);
        this.spinner.hide();
      }
    );
  }

  //formatar a data para envair para o back
  formatarDataEnvio(data: string): string {
    let dataSplit = data.split("/");
    const dataFormatada = `${dataSplit[2]}${dataSplit[1]}${dataSplit[0]}`;
    return dataFormatada;
  }

  //formatar a data que vem do back
  formatarDataObter(data: string): string {
    let dia = data.substring(6, 8);
    let mes = data.substring(4, 6);
    let ano = data.substring(0, 4);
    return `${dia}/${mes}/${ano}`;
  }

  mascaraHora(): string {
    return "00:00";
  }

  validarContadorMsn(): boolean {
    if (this.mensagemContador.length - this.valorMaximoMsn <= 100){
      return true
    }
    return false
  }

  obterMensagem(aviso: Aviso) {
    if (aviso.Mensagem.length > 25) {
      Swal(`Tipo Mensagem: ${aviso.TipoAviso}`, `Mensagem: ${aviso.Mensagem}`);
    }
  }

  getDate(): string {
    this.formatDate = this.datepipe.transform(new Date(), 'dd/MM/yyyy') +
      ' - ' + this.datepipe.transform(new Date(new Date().setDate(new Date().getDate() + 5)), 'dd/MM/yyyy');
    return (this.formatDate);
  }

  // calcula a diferença de dias para mudar a cor dos <td>
  diferencaDias(aviso: Aviso): number {
    const dia = new Date().getDate().toString().length == 1 ? `0${new Date().getDate()}` : `${new Date().getDate()}`;
    const mes = new Date().getMonth().toString().length == 1 ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`;
    const ano = new Date().getFullYear();

    const diaFinal = aviso.DataFinal.substring(6, 8);
    const mesFinal = aviso.DataFinal.substring(4, 6);
    const anoFinal = aviso.DataFinal.substring(0, 4);

    return ((((Number(anoFinal) * 365) + (Number(mesFinal) * 30) + Number(diaFinal)) - ((ano * 365) + (Number(mes) * 30) + Number(dia))));
  }

  // colocar a cor em hexadecimal(CSS) referente a diferença de dias 
  coresData(aviso: Aviso): string {
    if (this.diferencaDias(aviso) >= 31) {
      return "solid #0ad30a";
    }
    else if (this.diferencaDias(aviso) < 31 && this.diferencaDias(aviso) > 7) {
      return "solid #ece118";
    }
    else if (this.diferencaDias(aviso) < 7) {
      return "solid #fc2a0e";
    }
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }

}

export class Aviso {
  Id?: number;
  DataInicial: string;
  HoraInicial: string;
  DataFinal: string;
  HoraFinal: string;
  Mensagem: string;
  TipoAviso: string;
  Usuario: string;
}

//classe do back InformacaoGenerica
export class TipoAviso {
  Codigo: string;
  Tipo: string;
  Descricao: string;
}
