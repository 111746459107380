<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<app-representante-legal
  (selecionouRepresentante)="onRepresentanteChange($event)"
></app-representante-legal>

<div class="content d-flex justify-content-center align-items-center" *ngIf="!exSegurado.Matricula">
  <div class="card bg-dark mb-0 text-ligth">
    <!-- <div class="card-header header-elements-inline">
      <h3>Pensão Previdenciária</h3>
    </div> -->

    <div class="card-body">
      <div class="text-center mb-3">
        <h5 class="mb-0">
          <!-- <i class="fa fa-pencil-square-o 2x mr-1"></i> -->
          Pensão Previdenciária
        </h5>
      </div>

      <form #previdencia="ngForm" [formGroup]="formExSegurado" class="row">
        <div class="col-md-12 form-group">
          <label class="font-weight-semibold">Matrícula</label>
          <div class="input-group">
            <input
              class="form-control"
              type="text"
              mask="0*"
              formControlName="matricula"
              placeholder="Matrícula"
              max="9"
              (keydown)="buscarEnter($event)"
            />
            <span class="input-group-append">
              <button
                type="button"
                (click)="buscarExSegurado()"
                [disabled]="loadingExSegurado"
                class="input-group-text bg-blue border-blue"
              >
                <i
                  class="fa"
                  [ngClass]="loadingExSegurado ? 'fa-spinner fa-spin' : 'fa-search'"
                ></i>
              </button>
            </span>
          </div>
        </div>

        <!-- <div class="col-md-10 form-group d-none">
          <label class="font-weight-semibold">Nome do Colaborador</label>
          <input
            class="form-control"
            type="text"
            formControlName="nome"
            placeholder="Nome do Ex-segurado"
            maxlength="80"
            [ngClass]="
              (temErro || formExSegurado.get('nome').touched) &&
              formExSegurado.get('nome').status === 'INVALID'
                ? 'is-invalid'
                : null
            "
          />
        </div> -->
      </form>
      <!-- <div *ngIf="loadingExSegurado" class="d-flex justify-content-center py-1">
        <i class="fa fa-spinner fa-spin" aria-hidden="false"></i>
      </div> -->
    </div>
  </div>
</div>

<section id="showCard" class="card" *ngIf="exSegurado.Matricula">
  <div class="card-header header-elements-inline">
    <div class="card-title">
      <h3>
        <i
          class="fa fa-arrow-left pr-3 btn"
          aria-hidden="true"
          (click)="retornarTelaInicial()"
          style="cursor: pointer; bottom: 2px"
        ></i
        >Pensão Previdenciária - Dados do Ex-segurado
      </h3>
    </div>
  </div>

  <div class="card-body">
    <div
      class="col-12 d-flex flex-column justify-content-between mt-2 align-items-center flex-md-wrap"
    >
      <img
        *ngIf="exSegurado.Sexo && exSegurado.Sexo != '0'"
        [src]="
          exSegurado.Sexo == '1'
            ? 'assets/img/avatar-masculino.svg'
            : 'assets/img/avatar-feminino.svg'
        "
        width="90"
      />
      <h4 class="card-title text-capitalize py-2 flex-grow-1 text-center">
        <span>{{ exSegurado.Nome.toLowerCase() }}</span>
      </h4>
    </div>
    <div class="table-responsive shadow-sm">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="text-muted">Matrícula</th>
            <th class="text-muted">Situação</th>

            <th class="text-muted">Vínculo</th>
            <th class="text-muted">Data de Falecimento</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ exSegurado.Matricula }}</td>
            <td>{{ exSegurado.Situacao.Codigo }} - {{ exSegurado.Situacao.Descricao }}</td>

            <td>
              {{ exSegurado.Vinculo.Codigo }} -
              {{ exSegurado.Vinculo.Descricao }}
            </td>
            <td>
              <u>{{ exSegurado.DataFalecimento.ToDate }}</u>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ul class="nav nav-tabs mt-3">
      <li class="nav-item">
        <a class="nav-link active" data-toggle="tab" href="#dados-pessoais">
          <i class="fa fa-user"></i>&nbsp;
          <span>Dados Pessoais</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#familia">
          <i class="fa fa-people-roof"></i>&nbsp;
          <span>Família</span>
        </a>
      </li>
    </ul>

    <div class="tab-content">
      <div class="tab-pane fade show active" id="dados-pessoais">
        <p class="lead text-uppercase text-muted ml-4">Dados Pessoais do Ex-segurado</p>
        <hr />
        <div class="table-responsive pb-3">
          <table class="table table-striped">
            <tbody class="tabela-pensao">
              <tr>
                <th class="text-muted">Nome</th>
                <td>{{ exSegurado.Nome }}</td>
                <th class="text-muted">Matrícula</th>
                <td>
                  {{ exSegurado.Matricula }}
                </td>
              </tr>
              <tr>
                <th class="text-muted">Nome da Mãe</th>
                <td>{{ exSegurado.NomeMae }}</td>
                <th class="text-muted">Nome do Pai</th>
                <td>
                  {{ exSegurado.NomePai }}
                </td>
              </tr>
              <tr>
                <th class="text-muted">CPF</th>
                <td>{{ exSegurado.Cpf }}</td>
                <th class="text-muted">RG</th>
                <td>
                  {{ exSegurado.Identidade.RegistroGeral }}
                </td>
              </tr>
              <tr>
                <th class="text-muted">Órgão Emissor RG - UF</th>
                <td>{{ exSegurado.Identidade.OrgaoEmissor }} - {{ exSegurado.Identidade.UF }}</td>
                <th class="text-muted">Data de Emissão RG</th>
                <td>
                  {{ exSegurado.Identidade.DataDeExpedicao.ToDate }}
                </td>
              </tr>
              <tr>
                <th class="text-muted">Último Local de Trabalaho</th>
                <td>
                  {{ exSegurado.EmpresaFilial.Codigo }} - {{ exSegurado.EmpresaFilial.Descricao }}
                </td>
                <th class="text-muted">Lotação</th>
                <td>{{ exSegurado.Lotacao.Codigo }} - {{ exSegurado.Lotacao.Descricao }}</td>
              </tr>
              <tr>
                <th class="text-muted">Código Afastamento</th>
                <td>{{ exSegurado.Situacao.Codigo }} - {{ exSegurado.Situacao.Descricao }}</td>
                <th class="text-muted">Nível</th>
                <td>{{ exSegurado.Nivel }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="tab-pane fade show" id="familia">
        <div class="d-flex justify-content-between">
          <p class="lead text-uppercase text-muted">Família do Ex-segurado</p>
          <button class="btn btn-outline-warning" (click)="novoCadastro()">
            <i class="fa fa-plus-circle mr-2"></i>Adicionar
          </button>
        </div>
        <hr />
        <div class="table-responsive" *ngIf="exSegurado.Familia.length > 0">
          <table class="table">
            <tbody class="tabela-pensao">
              <tr>
                <th class="text-muted">Matrícula</th>
                <th class="text-muted">Pensionista</th>
                <th class="text-muted">Rateio</th>
                <th class="text-muted">Situação</th>
                <th class="text-muted text-center">Ações</th>
              </tr>
              <tr *ngFor="let familia of exSegurado.Familia">
                <td>{{ familia.Pensionista.Matricula }}</td>
                <td>{{ familia.Pensionista.Nome }}</td>
                <td>
                  {{ familia.Pensionista.Rateio.Percentual
                  }}{{ familia.Pensionista.Rateio.Tipo == '1' ? ' %' : null }}
                </td>
                <td>
                  {{ familia.Pensionista.Situacao.CodigoAfastamento
                  }}{{ retornarCodaf(familia.Pensionista.Situacao.CodigoAfastamento) }}
                </td>
                <td class="d-flex justify-content-center">
                  <button
                    type="button"
                    class="btn mr-2"
                    [ngClass]="
                      editar &&
                      familia.Pensionista.Matricula == pensionistaEditando.Pensionista.Matricula
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                    "
                    (click)="carregarPensionista(false, familia)"
                  >
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button
                    type="button"
                    class="btn mr-2"
                    [ngClass]="
                      visualizacao &&
                      familia.Pensionista.Matricula == pensionistaEditando.Pensionista.Matricula
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                    "
                    (click)="carregarPensionista(true, familia)"
                  >
                    <i class="fa fa-eye"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td></td>
                <td class="font-weight-bold">TOTAL</td>
                <td class="font-weight-bold">{{ somarRateioFamilia() }} %</td>
                <td></td>
                <td class="d-flex justify-content-center">
                  <button
                    type="button"
                    class="btn btn-outline-primary mr-2"
                    data-toggle="modal"
                    data-target="#rateioModal"
                    (click)="montarFormRateio()"
                  >
                    <i class="fa fa-pencil"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p *ngIf="exSegurado.Familia.length == 0" class="text-center">
          Não existem pensionistas cadastrados.
        </p>
      </div>
    </div>
  </div>
</section>

<section
  class="card"
  #cardPensionista
  *ngIf="exSegurado.Matricula && (adicao || editar || visualizacao)"
>
  <div class="card-header header-elements-inline">
    <h3 class="card-title">{{ adicao ? 'Cadastro' : 'Dados' }} do Pensionista</h3>
  </div>
  <div class="card-body wizard">
    <ul class="nav nav-tabs mt-3" *ngIf="!adicao">
      <li class="nav-item">
        <a
          class="nav-link active"
          data-toggle="tab"
          href="#dados-pessoais-pensionista"
          id="dados-pessoais-tab"
          (click)="this.step = 1"
        >
          <i class="fa fa-user"></i>&nbsp;
          <span>Dados Pessoais</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          data-toggle="tab"
          href="#documentos-pensionista"
          id="documentos-tab"
          (click)="this.step = 2"
        >
          <i class="fa fa-file"></i>&nbsp;
          <span>Documentos</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          data-toggle="tab"
          href="#dados-pagamento-pensionista"
          id="dados-pagamento-tab"
          (click)="this.step = 3"
        >
          <i class="fa fa-money"></i>&nbsp;
          <span>Dados Pagamento</span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          data-toggle="tab"
          href="#info-esocial-pensionista"
          id="info-esocial-tab"
          (click)="this.step = 4"
        >
          <i class="fa fa-info"></i>&nbsp;
          <span>Informações eSocial</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="listaDependente">
        <a
          class="nav-link"
          data-toggle="tab"
          href="#dependentes-pensionista"
          id="dependentes-tab"
          (click)="this.step = 5"
        >
          <i class="fa fa-people-group"></i>&nbsp;
          <span>Dependentes</span>
        </a>
      </li>
    </ul>
    <div class="steps clearfix pb-3" *ngIf="adicao">
      <ul role="tablist">
        <li
          role="tab"
          [ngClass]="{ current: step == 1, done: step > 1 }"
          class="first"
          aria-disabled="false"
          aria-selected="true"
        >
          <a id="steps-uid-1-t-1" aria-controls="steps-uid-1-p-1" class=""
            ><span class="current-info audible">current step: </span
            ><span [ngClass]="{ pointer: step > 1 }" class="number">1</span> Dados Pessoais</a
          >
        </li>
        <li
          role="tab"
          [ngClass]="{
            current: step == 2,
            disabled: step < 2,
            done: step > 2
          }"
          aria-disabled="true"
        >
          <a id="steps-uid-2-t-2" aria-controls="steps-uid-2-p-2"
            ><span [ngClass]="{ pointer: step > 2 }" class="number">2</span>Documentos</a
          >
        </li>
        <li
          role="tab"
          [ngClass]="{
            current: step == 3,
            disabled: step < 3,
            done: step > 3
          }"
          aria-disabled="true"
        >
          <a id="steps-uid-3-t-3" aria-controls="steps-uid-3-p-3" class="disabled"
            ><span [ngClass]="{ pointer: step > 3 }" class="number">3</span> Dados Pagamento</a
          >
        </li>
        <li
          role="tab"
          [ngClass]="{
            current: step == 4,
            disabled: step < 4,
            done: step > 4
          }"
          aria-disabled="true"
        >
          <a id="steps-uid-4-t-4" aria-controls="steps-uid-4-p-4" class="disabled"
            ><span [ngClass]="{ pointer: step > 4 }" class="number">4</span> Informações eSocial</a
          >
        </li>
        <li
          role="tab"
          [ngClass]="{
            current: step == 5,
            disabled: step < 5,
            done: step > 5
          }"
          aria-disabled="true"
        >
          <a id="steps-uid-5-t-5" aria-controls="steps-uid-5-p-5" class="disabled"
            ><span [ngClass]="{ pointer: step > 5 }" class="number">5</span> Dependentes</a
          >
        </li>
      </ul>
    </div>

    <form #previdencia="ngForm" [formGroup]="formPrevidencia">
      <div class="tab-content">
        <div class="tab-pane fade show active" id="dados-pessoais-pensionista">
          <p class="lead text-uppercase text-muted">Dados Pessoais do Pensionista</p>
          <hr />
          <div class="row" formGroupName="dadosPessoais">
            <div class="col-md-2 form-group">
              <label class="font-weight-semibold">Nº Ordem</label>
              <input
                type="text"
                formControlName="numeroOrdem"
                class="form-control"
                mask="0*"
                placeholder="Nº Ordem"
                [ngClass]="
                  formPrevidencia.get('dadosPessoais').get('numeroOrdem').invalid &&
                  (temErro || formPrevidencia.get('dadosPessoais').get('numeroOrdem').touched)
                    ? 'is-invalid'
                    : null
                "
                readonly
              />
              <span
                class="form-text text-danger"
                *ngIf="formPrevidencia.get('dadosPessoais').get('numeroOrdem').invalid && temErro"
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="form-group col-md-7">
              <label class="font-weight-semibold">Nome Completo </label>
              <input
                type="text"
                formControlName="nome"
                class="form-control"
                placeholder="Nome Completo"
                pattern="[A-Za-záãâéêíóôõúçÁÃÂÉÊÍÓÔÕÚÇ\s]+$"
                [ngClass]="
                  formPrevidencia.get('dadosPessoais').get('nome').invalid &&
                  formPrevidencia.get('dadosPessoais').get('nome').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('dadosPessoais').get('nome').invalid &&
                  formPrevidencia.get('dadosPessoais').get('nome').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-md-3 form-group">
              <label class="font-weight-semibold">Data Nascimento</label>
              <input
                type="date"
                formControlName="dataNascimento"
                class="form-control"
                (change)="onDataNascimentoChange()"
                max="9999-12-31"
                [ngClass]="
                  formPrevidencia.get('dadosPessoais').get('dataNascimento').invalid &&
                  formPrevidencia.get('dadosPessoais').get('dataNascimento').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('dadosPessoais').get('dataNascimento').invalid &&
                  formPrevidencia.get('dadosPessoais').get('dataNascimento').touched
                "
              >
                {{
                  formPrevidencia.get('dadosPessoais').get('dataNascimento').hasError('data')
                    ? 'Data Inválida'
                    : 'Campo Obrigatório'
                }}
              </span>
            </div>
            <div class="col-md-2 form-group">
              <label class="font-weight-semibold">Sexo </label>
              <select
                formControlName="sexo"
                class="form-control"
                [ngClass]="
                  formPrevidencia.get('dadosPessoais').get('sexo').invalid &&
                  formPrevidencia.get('dadosPessoais').get('sexo').touched
                    ? 'is-invalid'
                    : null
                "
              >
                <option value="">Selecione...</option>
                <option *ngFor="let sexo of listaSexo" value="{{ sexo.Codigo }}">
                  {{ sexo.Descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('dadosPessoais').get('sexo').invalid &&
                  formPrevidencia.get('dadosPessoais').get('sexo').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-md-2 form-group">
              <label class="font-weight-semibold">Estado Civil </label>
              <select
                formControlName="estadoCivil"
                class="form-control"
                [ngClass]="
                  formPrevidencia.get('dadosPessoais').get('estadoCivil').invalid &&
                  formPrevidencia.get('dadosPessoais').get('estadoCivil').touched
                    ? 'is-invalid'
                    : null
                "
              >
                <option value="">Selecione...</option>
                <option
                  *ngFor="let estadoCivil of listaEstadoCivil"
                  value="{{ estadoCivil.Codigo }}"
                >
                  {{ estadoCivil.Descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('dadosPessoais').get('estadoCivil').invalid &&
                  formPrevidencia.get('dadosPessoais').get('estadoCivil').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-md-3 form-group">
              <label class="font-weight-semibold">Nacionalidade </label>
              <select
                formControlName="nacionalidade"
                class="form-control"
                [ngClass]="
                  formPrevidencia.get('dadosPessoais').get('nacionalidade').invalid &&
                  formPrevidencia.get('dadosPessoais').get('nacionalidade').touched
                    ? 'is-invalid'
                    : null
                "
                (change)="aoMudarNacionalidade()"
              >
                <option value="">Selecione...</option>
                <option
                  *ngFor="let nacionalidade of listaNacionalidade"
                  value="{{ nacionalidade.Codigo }}"
                >
                  {{ nacionalidade.Descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('dadosPessoais').get('nacionalidade').invalid &&
                  formPrevidencia.get('dadosPessoais').get('nacionalidade').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-md-2 form-group">
              <label class="font-weight-semibold">UF </label>
              <select
                formControlName="ufNascimento"
                (change)="onUfNascimentoChange()"
                class="form-control"
                [ngClass]="
                  formPrevidencia.get('dadosPessoais').get('ufNascimento').invalid &&
                  formPrevidencia.get('dadosPessoais').get('ufNascimento').touched
                    ? 'is-invalid'
                    : null
                "
              >
                <option value="">UF</option>
                <option *ngFor="let uf of listaUf" value="{{ uf.Descricao }}">
                  {{ uf.Descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('dadosPessoais').get('ufNascimento').invalid &&
                  formPrevidencia.get('dadosPessoais').get('ufNascimento').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-md-3 form-group">
              <label class="font-weight-semibold">Município </label>
              <select
                formControlName="municipioNascimento"
                class="form-control"
                [ngClass]="
                  formPrevidencia.get('dadosPessoais').get('municipioNascimento').invalid &&
                  formPrevidencia.get('dadosPessoais').get('municipioNascimento').touched
                    ? 'is-invalid'
                    : null
                "
              >
                <option value="">Selecione...</option>
                <option
                  *ngFor="let municipio of listaMunicipioNascimento"
                  value="{{ municipio.Nome }}"
                >
                  {{ municipio.Nome }}
                </option>
              </select>
              <span
                class="form-text"
                *ngIf="
                  loadingMunicipioNascimento &&
                  formPrevidencia.get('dadosPessoais').get('ufNascimento').value ==
                    nacionalidade.Brasileiro
                "
                >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
              ></span>
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('dadosPessoais').get('municipioNascimento').invalid &&
                  formPrevidencia.get('dadosPessoais').get('municipioNascimento').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-md-4 form-group">
              <label class="font-weight-semibold">Grau de Instrução </label>
              <select
                formControlName="grauInstrucao"
                class="form-control"
                [ngClass]="
                  formPrevidencia.get('dadosPessoais').get('grauInstrucao').invalid &&
                  formPrevidencia.get('dadosPessoais').get('grauInstrucao').touched
                    ? 'is-invalid'
                    : null
                "
              >
                <option value="">Selecione...</option>
                <option
                  *ngFor="let grauInstrucao of listaGrauInstrucao"
                  value="{{ grauInstrucao.Codigo }}"
                >
                  {{ grauInstrucao.Descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('dadosPessoais').get('grauInstrucao').invalid &&
                  formPrevidencia.get('dadosPessoais').get('grauInstrucao').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-md-4 form-group">
              <label class="font-weight-semibold">Nome da Mãe</label>
              <input
                formControlName="nomeMae"
                type="text"
                class="form-control"
                placeholder="Nome da Mãe"
                maxlength="80"
                [ngClass]="
                  formPrevidencia.get('dadosPessoais').get('nomeMae').invalid &&
                  formPrevidencia.get('dadosPessoais').get('nomeMae').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('dadosPessoais').get('nomeMae').invalid &&
                  formPrevidencia.get('dadosPessoais').get('nomeMae').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-md-4 form-group">
              <label class="font-weight-semibold">Nome do Pai <small>(opcional)</small></label>
              <input
                formControlName="nomePai"
                type="text"
                class="form-control"
                placeholder="Nome do Pai"
                maxlength="80"
                [ngClass]="
                  formPrevidencia.get('dadosPessoais').get('nomePai').invalid &&
                  formPrevidencia.get('dadosPessoais').get('nomePai').touched
                    ? 'is-invalid'
                    : null
                "
              />
            </div>
            <legend class="font-weight-semibold mx-2">Dependência</legend>
            <div class="col-md-4 form-group">
              <label class="font-weight-semibold">Família </label>
              <select
                formControlName="familia"
                class="form-control"
                [ngClass]="
                  formPrevidencia.get('dadosPessoais').get('familia').invalid &&
                  formPrevidencia.get('dadosPessoais').get('familia').touched
                    ? 'is-invalid'
                    : null
                "
              >
                <option value="">Selecione...</option>
                <option
                  *ngFor="let tipoFamilia of listaTipoFamilia"
                  value="{{ tipoFamilia.Codigo }}"
                >
                  {{ tipoFamilia.Descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('dadosPessoais').get('familia').invalid &&
                  formPrevidencia.get('dadosPessoais').get('familia').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-md-4 form-group">
              <label class="font-weight-semibold">Grau de Parentesco </label>
              <select
                formControlName="grauParentesco"
                class="form-control"
                [ngClass]="
                  formPrevidencia.get('dadosPessoais').get('grauParentesco').invalid &&
                  formPrevidencia.get('dadosPessoais').get('grauParentesco').touched
                    ? 'is-invalid'
                    : null
                "
              >
                <option value="">Selecione...</option>
                <option
                  *ngFor="let grauParentesco of listaGrauParentesco"
                  value="{{ grauParentesco.Codigo }}"
                >
                  {{ grauParentesco.Descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('dadosPessoais').get('grauParentesco').invalid &&
                  formPrevidencia.get('dadosPessoais').get('grauParentesco').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-md-4 form-group">
              <label class="font-weight-semibold">Motivo </label>
              <select
                formControlName="motivo"
                class="form-control"
                [ngClass]="
                  formPrevidencia.get('dadosPessoais').get('motivo').invalid &&
                  formPrevidencia.get('dadosPessoais').get('motivo').touched
                    ? 'is-invalid'
                    : null
                "
              >
                <option value="">Selecione...</option>
                <option
                  *ngFor="let motivoDependencia of listaMotivoDepedencia"
                  value="{{ motivoDependencia.Codigo }}"
                >
                  {{ motivoDependencia.Descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('dadosPessoais').get('motivo').invalid &&
                  formPrevidencia.get('dadosPessoais').get('motivo').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
          </div>
        </div>
        <div class="tab-pane fade show" id="documentos-pensionista">
          <p class="lead text-uppercase text-muted">Documentos do Pensionista</p>
          <hr />
          <div class="row" formGroupName="documentos">
            <fieldset class="col-12">
              <legend class="font-weight-semibold">Identificação</legend>
              <div class="row">
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">CPF</label>
                  <input
                    formControlName="cpf"
                    type="text"
                    class="form-control"
                    mask="000.000.000-00"
                    placeholder="CPF"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('cpf').invalid &&
                      formPrevidencia.get('documentos').get('cpf').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('cpf').invalid &&
                      formPrevidencia.get('documentos').get('cpf').touched
                    "
                  >
                    {{
                      formPrevidencia.get('documentos').get('cpf').hasError('cpfError')
                        ? 'CPF Inválido'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">RG</label>
                  <input
                    formControlName="identidade"
                    type="text"
                    class="form-control"
                    mask="0*"
                    placeholder="Identidade"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('identidade').invalid &&
                      formPrevidencia.get('documentos').get('identidade').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('identidade').invalid &&
                      formPrevidencia.get('documentos').get('identidade').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">Orgão Emissor</label>
                  <input
                    formControlName="orgaoEmissor"
                    type="text"
                    oninput="this.value = this.value.toUpperCase()"
                    maxlength="5"
                    class="form-control"
                    placeholder="Orgão Emissor"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('orgaoEmissor').invalid &&
                      formPrevidencia.get('documentos').get('orgaoEmissor').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('orgaoEmissor').invalid &&
                      formPrevidencia.get('documentos').get('orgaoEmissor').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">UF </label>
                  <select
                    formControlName="identidadeUf"
                    class="form-control"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('identidadeUf').invalid &&
                      formPrevidencia.get('documentos').get('identidadeUf').touched
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">UF</option>
                    <option *ngFor="let uf of listaUf" value="{{ uf.Descricao }}">
                      {{ uf.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('identidadeUf').invalid &&
                      formPrevidencia.get('documentos').get('identidadeUf').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Data de Expedição</label>
                  <input
                    formControlName="dataExpedicao"
                    type="date"
                    max="9999-12-31"
                    class="form-control"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('dataExpedicao').invalid &&
                      formPrevidencia.get('documentos').get('dataExpedicao').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('dataExpedicao').invalid &&
                      formPrevidencia.get('documentos').get('dataExpedicao').touched
                    "
                  >
                    {{
                      formPrevidencia.get('documentos').get('dataExpedicao').hasError('data')
                        ? 'Data Inválida'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
              </div>
            </fieldset>

            <fieldset class="col-12">
              <legend class="font-weight-semibold">
                Título de Eleitor
                <small *ngIf="menorIdade">(opcional)</small>
              </legend>
              <div class="row">
                <div class="col-md-4 form-group">
                  <label class="font-weight-semibold">Título de Eleitor</label>
                  <input
                    formControlName="tituloEleitor"
                    type="text"
                    class="form-control"
                    [mask]="!menorIdade ? '0000 0000 0000' : ''"
                    minlength="12"
                    placeholder="Título de Eleitor"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('tituloEleitor').invalid &&
                      formPrevidencia.get('documentos').get('tituloEleitor').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('tituloEleitor').invalid &&
                      formPrevidencia.get('documentos').get('tituloEleitor').touched
                    "
                  >
                    {{
                      formPrevidencia.get('documentos').get('tituloEleitor').hasError('minlength')
                        ? 'Mínimo de 12 dígitos'
                        : formPrevidencia
                            .get('documentos')
                            .get('tituloEleitor')
                            .hasError('tituloInvalido')
                        ? 'Título inválido'
                        : 'Campo obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Zona Eleitoral </label>
                  <input
                    type="text"
                    mask="0*"
                    maxlength="5"
                    class="form-control"
                    formControlName="zonaEleitoral"
                    placeholder="Zona Eleitoral"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('zonaEleitoral').invalid &&
                      formPrevidencia.get('documentos').get('zonaEleitoral').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('zonaEleitoral').touched &&
                      formPrevidencia.get('documentos').get('zonaEleitoral').invalid
                    "
                  >
                    {{
                      formPrevidencia.get('documentos').get('zonaEleitoral').errors?.zero
                        ? 'Valor inválido'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Seção Eleitoral </label>
                  <input
                    type="text"
                    mask="0*"
                    maxlength="6"
                    class="form-control"
                    formControlName="secaoEleitoral"
                    placeholder="Seção Eleitoral"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('secaoEleitoral').invalid &&
                      formPrevidencia.get('documentos').get('secaoEleitoral').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('secaoEleitoral').touched &&
                      formPrevidencia.get('documentos').get('secaoEleitoral').invalid
                    "
                  >
                    {{
                      formPrevidencia.get('documentos').get('secaoEleitoral').errors?.zero
                        ? 'Valor inválido'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">UF </label>
                  <select
                    class="form-control"
                    formControlName="ufEleitoral"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('ufEleitoral').invalid &&
                      formPrevidencia.get('documentos').get('ufEleitoral').touched
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">UF</option>
                    <option *ngFor="let uf of listaUf" value="{{ uf.Descricao }}">
                      {{ uf.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('ufEleitoral').touched &&
                      formPrevidencia.get('documentos').get('ufEleitoral').hasError('required')
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
              </div>
            </fieldset>

            <fieldset class="col-12">
              <legend class="font-weight-semibold">Endereço</legend>
              <div class="row">
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">CEP</label>
                  <input
                    formControlName="cep"
                    (keyup)="onCepChange()"
                    type="text"
                    class="form-control"
                    mask="00000-000"
                    placeholder="CEP"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('cep').invalid &&
                      formPrevidencia.get('documentos').get('cep').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('cep').invalid &&
                      formPrevidencia.get('documentos').get('cep').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                  <span class="form-text" *ngIf="loadingCep"
                    >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
                  ></span>
                </div>
                <div class="col-md-8 form-group">
                  <label class="font-weight-semibold">Rua</label>
                  <input
                    formControlName="rua"
                    type="text"
                    class="form-control"
                    placeholder="Rua"
                    maxlength="60"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('rua').invalid &&
                      formPrevidencia.get('documentos').get('rua').touched
                        ? 'is-invalid'
                        : null
                    "
                    readonly="true"
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('rua').invalid &&
                      formPrevidencia.get('documentos').get('rua').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">Número</label>
                  <input
                    formControlName="numero"
                    type="number"
                    class="form-control"
                    placeholder="Número"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('numero').invalid &&
                      formPrevidencia.get('documentos').get('numero').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('numero').invalid &&
                      formPrevidencia.get('documentos').get('numero').touched
                    "
                  >
                    {{
                      formPrevidencia.get('documentos').get('numero').errors?.zero
                        ? 'Valor inválido'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-4 form-group">
                  <label class="font-weight-semibold">Complemento <small>(opcional)</small> </label>
                  <input
                    formControlName="complemento"
                    type="text"
                    class="form-control"
                    placeholder="Complemento"
                    maxlength="30"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('complemento').invalid &&
                      formPrevidencia.get('documentos').get('complemento').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('complemento').invalid &&
                      formPrevidencia.get('documentos').get('complemento').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">Bairro</label>
                  <input
                    formControlName="bairro"
                    type="text"
                    class="form-control"
                    placeholder="Bairro"
                    maxlength="40"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('bairro').invalid &&
                      formPrevidencia.get('documentos').get('bairro').touched
                        ? 'is-invalid'
                        : null
                    "
                    readonly="true"
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('bairro').invalid &&
                      formPrevidencia.get('documentos').get('bairro').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">UF </label>
                  <select
                    formControlName="enderecoUf"
                    class="form-control"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('enderecoUf').invalid &&
                      formPrevidencia.get('documentos').get('enderecoUf').touched
                        ? 'is-invalid'
                        : null
                    "
                    readonly="true"
                  >
                    <option value="">UF</option>
                    <option *ngFor="let uf of listaUf" value="{{ uf.Descricao }}">
                      {{ uf.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('enderecoUf').invalid &&
                      formPrevidencia.get('documentos').get('enderecoUf').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">Município</label>
                  <input
                    formControlName="enderecoMunicipio"
                    type="text"
                    class="form-control"
                    placeholder="Município"
                    maxlength="40"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('enderecoMunicipio').invalid &&
                      formPrevidencia.get('documentos').get('enderecoMunicipio').touched
                        ? 'is-invalid'
                        : null
                    "
                    readonly="true"
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('enderecoMunicipio').invalid &&
                      formPrevidencia.get('documentos').get('enderecoMunicipio').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">Celular</label>
                  <input
                    formControlName="celular"
                    type="text"
                    inputmode="numeric"
                    class="form-control"
                    placeholder="Celular"
                    mask
                    telefoneMask
                    minlength="10"
                    [ngClass]="
                      formPrevidencia.get('documentos').get('celular').invalid &&
                      formPrevidencia.get('documentos').get('celular').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('celular').invalid &&
                      formPrevidencia.get('documentos').get('celular').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
              </div>
            </fieldset>
            <fieldset class="col-12">
              <legend class="font-weight-semibold">Contatos</legend>
              <div class="row">
                <div class="col-md-5 form-group">
                  <label class="font-weight-semibold">Tipo de Contato </label>
                  <select class="form-control" formControlName="tipoContato">
                    <option value="">Selecione o tipo de contato</option>
                    <option value="Celular">Celular</option>
                    <option value="Residencial">Residencial (fixo)</option>
                    <option value="Comercial">Comercial (fixo)</option>
                  </select>
                </div>
                <div class="col-md-5 form-group">
                  <label class="font-weight-semibold">Telefone</label>
                  <input
                    formControlName="telefoneAuxiliar"
                    autocomplete="off"
                    type="text"
                    class="form-control telefone"
                    inputmode="numeric"
                    placeholder="(00) 00000-0000"
                    mask
                    telefoneMask
                    minlength="10"
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('documentos').get('telefoneAuxiliar').invalid &&
                      formPrevidencia.get('documentos').get('telefoneAuxiliar').touched
                    "
                  >
                    Mínimmo de 10 dígitos.
                  </span>
                </div>
                <div class="col-md-2 form-group d-flex flex-column justify-content-end">
                  <button
                    type="button"
                    class="btn btn-primary"
                    [disabled]="
                      formPrevidencia.get('documentos').get('tipoContato').value == '' ||
                      formPrevidencia.get('documentos').get('telefoneAuxiliar').value == ''
                    "
                    (click)="adicionarContatoSecundario()"
                  >
                    <i class="fa fa-plus-circle mr-2"></i>Adicionar
                  </button>
                </div>
              </div>
              <div class="table-responsive shadow-sm mb-3">
                <table class="table">
                  <thead class="thead-light">
                    <tr>
                      <th class="text-muted">Tipo de Contato</th>
                      <th class="text-muted">Número</th>
                      <th class="text-muted text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="telefonesAuxiliares.length == 0">
                      <td colspan="3" class="text-center">
                        Ainda não foram adicionados contatos secundários.
                      </td>
                    </tr>

                    <ng-container *ngIf="telefonesAuxiliares.length >= 0">
                      <tr *ngFor="let contato of telefonesAuxiliares">
                        <td>{{ contato.Tipo }}</td>
                        <td>({{ contato.DDDTelefone }}) {{ contato.Telefone }}</td>
                        <td class="d-flex justify-content-center">
                          <button
                            type="button"
                            class="btn btn-outline-danger"
                            (click)="apagarContatoSecundario(contato.Telefone)"
                            [disabled]="!editar"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </td></tr
                    ></ng-container>
                  </tbody>
                </table>
              </div>
              <p class="text-center alert-warning p-2 mt-2 rounded">
                <i class="fa fa-info-circle fa-3x my-2"></i><br />
                Clique no botão inferior 'Salvar' para enviar a lista de contatos atualizada. Caso
                ele não esteja disponível, seu formulário está incompleto.
              </p>
            </fieldset>
          </div>
        </div>
        <div class="tab-pane fade show" id="dados-pagamento-pensionista">
          <p class="lead text-uppercase text-muted">Dados Pagamento do Pensionista</p>
          <hr />
          <div class="row" formGroupName="dadosPagamento">
            <div class="d-flex flex-column justify-content-end col-md-12 form-group">
              <label class="font-weight-semibold">Vínculo</label>
              <select
                formControlName="vinculo"
                class="form-control"
                [ngClass]="
                  formPrevidencia.get('dadosPagamento').get('vinculo').invalid &&
                  formPrevidencia.get('dadosPagamento').get('vinculo').touched
                    ? 'is-invalid'
                    : null
                "
              >
                <option value="">Selecione...</option>
                <option *ngFor="let vinculo of listaVinculo" value="{{ vinculo.Codigo }}">
                  {{ vinculo.Descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('dadosPagamento').get('vinculo').invalid &&
                  formPrevidencia.get('dadosPagamento').get('vinculo').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>

            <fieldset class="col-12">
              <legend class="font-weight-bold">
                Conta Corrente para Crédito
                <small *ngIf="representanteSelecionado">(opcional)</small>
              </legend>
              <div class="row">
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold"
                    >Banco <small *ngIf="representanteSelecionado">(opcional)</small></label
                  >
                  <select
                    formControlName="banco"
                    class="form-control"
                    (change)="buscarAgencias($event)"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('banco').invalid &&
                      formPrevidencia.get('dadosPagamento').get('banco').touched
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let banco of listaBanco" value="{{ banco.Codigo }}">
                      {{ banco.Nome }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('banco').invalid &&
                      formPrevidencia.get('dadosPagamento').get('banco').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold"
                    >Agência <small *ngIf="representanteSelecionado">(opcional)</small></label
                  >
                  <select
                    formControlName="agencia"
                    class="form-control"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('agencia').invalid &&
                      formPrevidencia.get('dadosPagamento').get('agencia').touched
                        ? 'is-invalid'
                        : null
                    "
                    [disabled]="!listaAgencias"
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let agencia of listaAgencias" value="{{ agencia.CodAgencia }}">
                      {{ agencia.CodAgencia }} {{ agencia.NomeAgencia }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('agencia').invalid &&
                      formPrevidencia.get('dadosPagamento').get('agencia').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <!-- <div class="col-md-2 form-group">
                  <label class="font-weight-semibold"
                    >Agência <small *ngIf="menorIdade">(opcional)</small></label
                  >
                  <input
                    formControlName="agencia"
                    type="text"
                    class="form-control"
                    placeholder="Agência"
                    mask="0*"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('agencia').invalid &&
                      formPrevidencia.get('dadosPagamento').get('agencia').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('agencia').invalid &&
                      formPrevidencia.get('dadosPagamento').get('agencia').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div> -->
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold"
                    >Tipo de Conta <small *ngIf="representanteSelecionado">(opcional)</small></label
                  >
                  <select
                    formControlName="tipoConta"
                    class="form-control"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('tipoConta').invalid &&
                      formPrevidencia.get('dadosPagamento').get('tipoConta').touched
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let tipoConta of listaTipoConta" value="{{ tipoConta.Codigo }}">
                      {{ tipoConta.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('tipoConta').invalid &&
                      formPrevidencia.get('dadosPagamento').get('tipoConta').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold"
                    >Conta Corrente
                    <small *ngIf="menorIdade && representanteSelecionado">(opcional)</small></label
                  >
                  <input
                    formControlName="contaCorrente"
                    type="text"
                    class="form-control"
                    mask="0*"
                    placeholder="Conta corrente"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('contaCorrente').invalid &&
                      formPrevidencia.get('dadosPagamento').get('contaCorrente').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('contaCorrente').invalid &&
                      formPrevidencia.get('dadosPagamento').get('contaCorrente').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
              </div>
            </fieldset>

            <fieldset class="col-12">
              <legend class="font-weight-bold">Benefício</legend>
              <div class="row">
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">Data 1º Pagamento</label>
                  <input
                    formControlName="data1Pagamento"
                    type="date"
                    class="form-control"
                    max="9999-12-31"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('data1Pagamento').invalid &&
                      formPrevidencia.get('dadosPagamento').get('data1Pagamento').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('data1Pagamento').invalid &&
                      formPrevidencia.get('dadosPagamento').get('data1Pagamento').touched
                    "
                  >
                    {{
                      formPrevidencia.get('dadosPagamento').get('data1Pagamento').hasError('data')
                        ? 'Data Inválida'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Tipo de Rateio</label>
                  <select
                    formControlName="tipoRateio"
                    class="form-control"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('tipoRateio').invalid &&
                      formPrevidencia.get('dadosPagamento').get('tipoRateio').touched
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option
                      *ngFor="let tipoRateio of listaTipoRateio"
                      value="{{ tipoRateio.Codigo }}"
                    >
                      {{ tipoRateio.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('tipoRateio').invalid &&
                      formPrevidencia.get('dadosPagamento').get('tipoRateio').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">Rateio</label>
                  <input
                    formControlName="rateio"
                    type="text"
                    class="form-control"
                    placeholder="Rateio"
                    (input)="
                      mascaraRateio(
                        'formPrevidencia',
                        'rateio',
                        formPrevidencia.get('dadosPagamento').get('tipoRateio').value,
                        $event
                      )
                    "
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('rateio').invalid &&
                      formPrevidencia.get('dadosPagamento').get('rateio').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('rateio').invalid &&
                      formPrevidencia.get('dadosPagamento').get('rateio').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Tipo de Pensão</label>
                  <select
                    formControlName="tipoPensao"
                    class="form-control"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('tipoPensao').invalid &&
                      formPrevidencia.get('dadosPagamento').get('tipoPensao').touched
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option
                      *ngFor="let tipoPensao of listaTipoPensao"
                      value="{{ tipoPensao.Codigo }}"
                    >
                      {{ tipoPensao.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('tipoPensao').invalid &&
                      formPrevidencia.get('dadosPagamento').get('tipoPensao').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">Com Paridade</label>
                  <select
                    formControlName="comParidade"
                    class="form-control"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('comParidade').invalid &&
                      formPrevidencia.get('dadosPagamento').get('comParidade').touched
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option value="0">Não</option>
                    <option value="1">Sim</option>
                    <option value="2">Judicial</option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('comParidade').invalid &&
                      formPrevidencia.get('dadosPagamento').get('comParidade').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">Número do Processo</label>
                  <input
                    formControlName="beneficioNumeroProcesso"
                    type="text"
                    class="form-control"
                    placeholder="Número do Processo"
                    maxlength="30"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('beneficioNumeroProcesso')
                        .invalid &&
                      formPrevidencia.get('dadosPagamento').get('beneficioNumeroProcesso').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('beneficioNumeroProcesso')
                        .invalid &&
                      formPrevidencia.get('dadosPagamento').get('beneficioNumeroProcesso').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold"
                    >Data Fim do Benefício <small>(opcional)</small></label
                  >
                  <input
                    formControlName="beneficioDataFim"
                    type="date"
                    class="form-control"
                    max="9999-12-31"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('beneficioDataFim').invalid &&
                      formPrevidencia.get('dadosPagamento').get('beneficioDataFim').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('beneficioDataFim').invalid &&
                      formPrevidencia.get('dadosPagamento').get('beneficioDataFim').touched
                    "
                  >
                    {{
                      formPrevidencia.get('dadosPagamento').get('beneficioDataFim').hasError('data')
                        ? 'Data Inválida'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Data Limite Retroativo</label>
                  <input
                    formControlName="beneficioDataLimite"
                    type="date"
                    class="form-control"
                    max="9999-12-31"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('beneficioDataLimite').invalid &&
                      formPrevidencia.get('dadosPagamento').get('beneficioDataLimite').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('beneficioDataLimite').invalid &&
                      formPrevidencia.get('dadosPagamento').get('beneficioDataLimite').touched
                    "
                  >
                    {{
                      formPrevidencia
                        .get('dadosPagamento')
                        .get('beneficioDataLimite')
                        .hasError('data')
                        ? 'Data Inválida'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">Tipo de Cota</label>
                  <select
                    formControlName="beneficioTipoCota"
                    class="form-control"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('beneficioTipoCota').invalid &&
                      formPrevidencia.get('dadosPagamento').get('beneficioTipoCota').touched
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let tipoCota of listaTipoCota" value="{{ tipoCota.Codigo }}">
                      {{ tipoCota.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('beneficioTipoCota').invalid &&
                      formPrevidencia.get('dadosPagamento').get('beneficioTipoCota').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold"
                    >Número Reserva <small>(opcional)</small></label
                  >
                  <input
                    formControlName="beneficioNumeroReserva"
                    type="text"
                    class="form-control"
                    placeholder="Número Reserva"
                    mask="0*"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('beneficioNumeroReserva').invalid &&
                      formPrevidencia.get('dadosPagamento').get('beneficioNumeroReserva').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('beneficioNumeroReserva').invalid &&
                      formPrevidencia.get('dadosPagamento').get('beneficioNumeroReserva').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
              </div>
            </fieldset>

            <fieldset class="col-12">
              <legend class="font-weight-bold">Situação de Pagamento</legend>
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="font-weight-semibold">Código Afastamento</label>
                  <select
                    formControlName="codigoAfastamento"
                    class="form-control"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('codigoAfastamento').invalid &&
                      formPrevidencia.get('dadosPagamento').get('codigoAfastamento').touched
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option
                      *ngFor="let afastamento of listaCodigoAfastamento"
                      value="{{ afastamento.Codigo }}"
                    >
                      {{ afastamento.Codigo }} - {{ afastamento.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('codigoAfastamento').invalid &&
                      formPrevidencia.get('dadosPagamento').get('codigoAfastamento').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-6 form-group">
                  <label class="font-weight-semibold"
                    >Motivo Afastamento <small>(opcional)</small></label
                  >
                  <select
                    formControlName="motivoAfastamento"
                    class="form-control"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('motivoAfastamento').invalid &&
                      formPrevidencia.get('dadosPagamento').get('motivoAfastamento').touched
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option
                      *ngFor="let motivoAfastamento of listaMotivoAfastamento"
                      value="{{ motivoAfastamento.Codigo }}"
                    >
                      {{ motivoAfastamento.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('motivoAfastamento').invalid &&
                      formPrevidencia.get('dadosPagamento').get('motivoAfastamento').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-4 form-group">
                  <label class="font-weight-semibold">Prazo <small>(opcional)</small></label>
                  <input
                    formControlName="prazo"
                    type="text"
                    class="form-control"
                    placeholder="Prazo"
                    mask="0*"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('prazo').invalid &&
                      formPrevidencia.get('dadosPagamento').get('prazo').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('prazo').invalid &&
                      formPrevidencia.get('dadosPagamento').get('prazo').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-4 form-group">
                  <label class="font-weight-semibold"
                    >Data Afastamento - Início <small>(opcional)</small></label
                  >
                  <input
                    formControlName="dataFastamentoInicio"
                    type="date"
                    class="form-control"
                    max="9999-12-31"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('dataFastamentoInicio').invalid &&
                      formPrevidencia.get('dadosPagamento').get('dataFastamentoInicio').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('dataFastamentoInicio').invalid &&
                      formPrevidencia.get('dadosPagamento').get('dataFastamentoInicio').touched
                    "
                  >
                    {{
                      formPrevidencia
                        .get('dadosPagamento')
                        .get('dataFastamentoInicio')
                        .hasError('data')
                        ? 'Data Inválida'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-4 form-group">
                  <label class="font-weight-semibold"
                    >Data Afastamento - Fim <small>(opcional)</small></label
                  >
                  <input
                    formControlName="dataAfastamentoFim"
                    type="date"
                    class="form-control"
                    max="9999-12-31"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('dataAfastamentoFim').invalid &&
                      formPrevidencia.get('dadosPagamento').get('dataAfastamentoFim').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('dataAfastamentoFim').invalid &&
                      formPrevidencia.get('dadosPagamento').get('dataAfastamentoFim').touched
                    "
                  >
                    {{
                      formPrevidencia
                        .get('dadosPagamento')
                        .get('dataAfastamentoFim')
                        .hasError('data')
                        ? 'Data Inválida'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
              </div>
            </fieldset>

            <fieldset class="col-12">
              <legend class="font-weight-bold">Isenções de IR e Previdência</legend>
              <div class="row">
                <div class="col-md-4 form-group">
                  <label class="font-weight-semibold">Condição <small>(opcional)</small></label>
                  <select
                    formControlName="condicao"
                    class="form-control"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('condicao').invalid &&
                      formPrevidencia.get('dadosPagamento').get('condicao').touched
                        ? 'is-invalid'
                        : null
                    "
                    (change)="mudarCondicaoIsencaoIR()"
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let condicao of listaCondicao" value="{{ condicao.Codigo }}">
                      {{ condicao.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('condicao').invalid &&
                      formPrevidencia.get('dadosPagamento').get('condicao').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-4 form-group">
                  <label class="font-weight-semibold"
                    >Data Inicial
                    <small
                      *ngIf="formPrevidencia.get('dadosPagamento').get('condicao').value != 'N'"
                      >(opcional)</small
                    ></label
                  >
                  <input
                    formControlName="isencaoIrDataInicial"
                    type="date"
                    class="form-control"
                    max="9999-12-31"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('isencaoIrDataInicial').invalid &&
                      formPrevidencia.get('dadosPagamento').get('isencaoIrDataInicial').touched
                        ? 'is-invalid'
                        : null
                    "
                    [readonly]="formPrevidencia.get('dadosPagamento').get('condicao').value == 'S'"
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('isencaoIrDataInicial').invalid &&
                      formPrevidencia.get('dadosPagamento').get('isencaoIrDataInicial').touched
                    "
                  >
                    {{
                      formPrevidencia
                        .get('dadosPagamento')
                        .get('isencaoIrDataInicial')
                        .hasError('data')
                        ? 'Data Inválida'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-4 form-group">
                  <label class="font-weight-semibold">Data Final <small>(opcional)</small></label>
                  <input
                    formControlName="isencaoIrDataFinal"
                    type="date"
                    class="form-control"
                    max="9999-12-31"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('isencaoIrDataFinal').invalid &&
                      formPrevidencia.get('dadosPagamento').get('isencaoIrDataFinal').touched
                        ? 'is-invalid'
                        : null
                    "
                    [readonly]="formPrevidencia.get('dadosPagamento').get('condicao').value == 'S'"
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('isencaoIrDataFinal').invalid &&
                      formPrevidencia.get('dadosPagamento').get('isencaoIrDataFinal').touched
                    "
                  >
                    {{
                      formPrevidencia
                        .get('dadosPagamento')
                        .get('isencaoIrDataFinal')
                        .hasError('data')
                        ? 'Data Inválida'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
              </div>
            </fieldset>

            <fieldset class="col-12">
              <legend class="font-weight-semibold">
                Responsável Legal <small *ngIf="!menorIdade">(opcional)</small>
              </legend>
              <div class="row">
                <div class="d-flex flex-column justify-content-end col-md-2 form-group">
                  <button
                    type="button"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('responsavelNumero').value == ''
                    "
                    (click)="visualizacao ? null : representanteLegalComponent.abrirModalRepLegal()"
                    class="form-control bg-blue border-blue"
                  >
                    <i class="fa fa-search"></i> Pesquisar
                  </button>
                  <button
                    type="button"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('responsavelNumero').value != ''
                    "
                    (click)="visualizacao ? null : limparRepresentante()"
                    class="form-control bg-blue border-blue"
                  >
                    <i class="fa fa-search"></i> Limpar
                  </button>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">Número</label>
                  <input
                    formControlName="responsavelNumero"
                    type="text"
                    class="form-control"
                    mask="0*"
                    placeholder="Número"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('responsavelNumero').invalid &&
                      formPrevidencia.get('dadosPagamento').get('responsavelNumero').touched
                        ? 'is-invalid'
                        : null
                    "
                    readonly="true"
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('responsavelNumero').invalid &&
                      formPrevidencia.get('dadosPagamento').get('responsavelNumero').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-8 form-group">
                  <label class="font-weight-semibold">Nome</label>
                  <input
                    formControlName="responsavelNome"
                    type="text"
                    class="form-control"
                    placeholder="Nome"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('responsavelNome').invalid &&
                      formPrevidencia.get('dadosPagamento').get('responsavelNome').touched
                        ? 'is-invalid'
                        : null
                    "
                    readonly="true"
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('responsavelNome').invalid &&
                      formPrevidencia.get('dadosPagamento').get('responsavelNome').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Data Início</label>
                  <input
                    formControlName="resposavelDataInicio"
                    type="date"
                    class="form-control"
                    max="9999-12-31"
                    placeholder="dd/mm/aaaa"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('resposavelDataInicio').invalid &&
                      formPrevidencia.get('dadosPagamento').get('resposavelDataInicio').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('resposavelDataInicio').invalid &&
                      formPrevidencia.get('dadosPagamento').get('resposavelDataInicio').touched
                    "
                  >
                    {{
                      formPrevidencia
                        .get('dadosPagamento')
                        .get('resposavelDataInicio')
                        .hasError('data')
                        ? 'Data Inválida'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Data Fim <small>(opcional)</small></label>
                  <input
                    formControlName="resposavelDataFim"
                    type="date"
                    class="form-control"
                    max="9999-12-31"
                    placeholder="dd/mm/aaaa"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('resposavelDataFim').invalid &&
                      formPrevidencia.get('dadosPagamento').get('resposavelDataFim').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('resposavelDataFim').invalid &&
                      formPrevidencia.get('dadosPagamento').get('resposavelDataFim').touched
                    "
                  >
                    {{
                      formPrevidencia
                        .get('dadosPagamento')
                        .get('resposavelDataFim')
                        .hasError('data')
                        ? 'Data Inválida'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold"
                    >Data Vencimento <small>(opcional)</small></label
                  >
                  <input
                    formControlName="resposavelDataVencimento"
                    type="date"
                    class="form-control"
                    max="9999-12-31"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('resposavelDataVencimento')
                        .invalid &&
                      formPrevidencia.get('dadosPagamento').get('resposavelDataVencimento').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('resposavelDataVencimento')
                        .invalid &&
                      formPrevidencia.get('dadosPagamento').get('resposavelDataVencimento').touched
                    "
                  >
                    {{
                      formPrevidencia
                        .get('dadosPagamento')
                        .get('resposavelDataVencimento')
                        .hasError('data')
                        ? 'Data Inválida'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Número do Processo</label>
                  <input
                    formControlName="resposavelNumeroProcesso"
                    type="text"
                    class="form-control"
                    placeholder="Número Processo"
                    maxlength="30"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('resposavelNumeroProcesso')
                        .invalid &&
                      formPrevidencia.get('dadosPagamento').get('resposavelNumeroProcesso').touched
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('resposavelNumeroProcesso')
                        .invalid &&
                      formPrevidencia.get('dadosPagamento').get('resposavelNumeroProcesso').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Banco</label>
                  <select
                    formControlName="responsavelBanco"
                    class="form-control"
                    (change)="buscarAgencias($event, true)"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('responsavelBanco').invalid &&
                      formPrevidencia.get('dadosPagamento').get('responsavelBanco').touched
                        ? 'is-invalid'
                        : null
                    "
                    readonly="true"
                  >
                    <option value="">Banco</option>
                    <option *ngFor="let banco of listaBanco" value="{{ banco.Codigo }}">
                      {{ banco.Nome }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('responsavelBanco').invalid &&
                      formPrevidencia.get('dadosPagamento').get('responsavelBanco').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Agência</label>
                  <select
                    formControlName="responsavelAgencia"
                    class="form-control"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('responsavelAgencia').invalid &&
                      formPrevidencia.get('dadosPagamento').get('responsavelAgencia').touched
                        ? 'is-invalid'
                        : null
                    "
                    readonly="true"
                  >
                    <option value="">Agência</option>
                    <option
                      *ngFor="let agencia of listaAgenciasResponsavel"
                      value="{{ agencia.CodAgencia }}"
                    >
                      {{ agencia.CodAgencia }} {{ agencia.NomeAgencia }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('responsavelAgencia').invalid &&
                      formPrevidencia.get('dadosPagamento').get('responsavelAgencia').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Operação</label>
                  <input
                    formControlName="responsavelOperacao"
                    class="form-control"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('responsavelOperacao').invalid &&
                      formPrevidencia.get('dadosPagamento').get('responsavelOperacao').touched
                        ? 'is-invalid'
                        : null
                    "
                    type="text"
                    placeholder="Operação"
                    readonly="true"
                  />

                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('responsavelOperacao').invalid &&
                      formPrevidencia.get('dadosPagamento').get('responsavelOperacao').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Número da Conta</label>
                  <input
                    formControlName="responsavelConta"
                    type="text"
                    class="form-control"
                    placeholder="Número da Conta"
                    [ngClass]="
                      formPrevidencia.get('dadosPagamento').get('responsavelConta').invalid &&
                      formPrevidencia.get('dadosPagamento').get('responsavelConta').touched
                        ? 'is-invalid'
                        : null
                    "
                    readonly="true"
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formPrevidencia.get('dadosPagamento').get('responsavelConta').invalid &&
                      formPrevidencia.get('dadosPagamento').get('responsavelConta').touched
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="tab-pane fade show" id="info-esocial-pensionista">
          <p class="lead text-uppercase text-muted">Informações eSocial do Pensionista</p>
          <hr />
          <div class="row" formGroupName="infoEsocial">
            <div class="col-md-3 form-group">
              <label class="font-weight-semibold">Data Publicação</label>
              <input
                formControlName="dataPublicacao"
                type="date"
                class="form-control"
                max="9999-12-31"
                [ngClass]="
                  formPrevidencia.get('infoEsocial').get('dataPublicacao').invalid &&
                  formPrevidencia.get('infoEsocial').get('dataPublicacao').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('infoEsocial').get('dataPublicacao').invalid &&
                  formPrevidencia.get('infoEsocial').get('dataPublicacao').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-md-9 form-group">
              <label class="font-weight-semibold">Tipo de Beneficiário</label>
              <select
                formControlName="tipo"
                class="form-control"
                [ngClass]="
                  formPrevidencia.get('infoEsocial').get('tipo').invalid &&
                  formPrevidencia.get('infoEsocial').get('tipo').touched
                    ? 'is-invalid'
                    : null
                "
              >
                <option value="">Selecione...</option>
                <option *ngFor="let tipo of listaTipoBeneficiario" value="{{ tipo.Codigo }}">
                  {{ tipo.Codigo }} - {{ tipo.Grupo }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('infoEsocial').get('tipo').invalid &&
                  formPrevidencia.get('infoEsocial').get('tipo').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>

            <div class="col-md-8 form-group">
              <label class="font-weight-semibold"
                >Informação Que Originou <small>(opcional)</small></label
              >
              <input
                formControlName="infoOrigem"
                type="text"
                class="form-control"
                maxlength="200"
                placeholder="Insira aqui a informação"
                [ngClass]="
                  formPrevidencia.get('infoEsocial').get('infoOrigem').invalid &&
                  formPrevidencia.get('infoEsocial').get('infoOrigem').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('infoEsocial').get('infoOrigem').invalid &&
                  formPrevidencia.get('infoEsocial').get('infoOrigem').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-md-4 form-group">
              <label class="font-weight-semibold"
                >Determinação Judicial <small>(opcional)</small></label
              >
              <select
                formControlName="derJudicial"
                class="form-control"
                [ngClass]="
                  formPrevidencia.get('infoEsocial').get('derJudicial').invalid &&
                  formPrevidencia.get('infoEsocial').get('derJudicial').touched
                    ? 'is-invalid'
                    : null
                "
              >
                <option value="">Selecione...</option>
                <option value="N">NÃO</option>
                <option value="S">SIM</option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('infoEsocial').get('derJudicial').invalid &&
                  formPrevidencia.get('infoEsocial').get('derJudicial').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>

            <div class="col-md-3 form-group">
              <label class="font-weight-semibold">Data Cessação <small>(opcional)</small></label>
              <input
                formControlName="dataCessacao"
                type="date"
                class="form-control"
                max="9999-12-31"
                [ngClass]="
                  formPrevidencia.get('infoEsocial').get('dataCessacao').invalid &&
                  formPrevidencia.get('infoEsocial').get('dataCessacao').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('infoEsocial').get('dataCessacao').invalid &&
                  formPrevidencia.get('infoEsocial').get('dataCessacao').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-md-3 form-group">
              <label class="font-weight-semibold">Motivo Cessação <small>(opcional)</small></label>
              <select
                formControlName="motCessacao"
                class="form-control"
                [ngClass]="
                  formPrevidencia.get('infoEsocial').get('motCessacao').invalid &&
                  formPrevidencia.get('infoEsocial').get('motCessacao').touched
                    ? 'is-invalid'
                    : null
                "
              >
                <option value="">Selecione...</option>
                <option *ngFor="let motivo of listaMotivoCessacao" value="{{ motivo.Codigo }}">
                  {{ motivo.Codigo }} - {{ motivo.Descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('infoEsocial').get('motCessacao').invalid &&
                  formPrevidencia.get('infoEsocial').get('motCessacao').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-md-3 form-group">
              <label class="font-weight-semibold">Data Reativação <small>(opcional)</small></label>
              <input
                formControlName="dataReativacao"
                type="date"
                class="form-control"
                max="9999-12-31"
                [ngClass]="
                  formPrevidencia.get('infoEsocial').get('dataReativacao').invalid &&
                  formPrevidencia.get('infoEsocial').get('dataReativacao').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('infoEsocial').get('dataReativacao').invalid &&
                  formPrevidencia.get('infoEsocial').get('dataReativacao').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="col-md-3 form-group">
              <label class="font-weight-semibold">Data Financeiro <small>(opcional)</small></label>
              <input
                formControlName="dataFinanceiro"
                type="date"
                class="form-control"
                max="9999-12-31"
                [ngClass]="
                  formPrevidencia.get('infoEsocial').get('dataFinanceiro').invalid &&
                  formPrevidencia.get('infoEsocial').get('dataFinanceiro').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  formPrevidencia.get('infoEsocial').get('dataFinanceiro').invalid &&
                  formPrevidencia.get('infoEsocial').get('dataFinanceiro').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
          </div>
        </div>
        <div class="tab-pane fade show" id="dependentes-pensionista">
          <p class="lead text-uppercase text-muted">Dependentes do Pensionista</p>
          <hr />
          <app-dependentes
            [listaDependente]="listaDependente"
            [matricula]="matriculaPensionista"
            [cadastroUsuario]="cadastroUsuario"
          ></app-dependentes>
        </div>

        <app-parametros *ngIf="!visualizacao"></app-parametros>
        <button
          type="button"
          class="btn btn-primary"
          (click)="abiriModalIndicadores()"
          *ngIf="!visualizacao"
        >
          Indicadores do Cálculo
        </button>
      </div>
    </form>
  </div>
  <div class="card-footer d-flex justify-content-end" *ngIf="step != 5">
    <!-- <button class="btn btn-danger mr-2" (click)="limparForm()">Cancelar</button> -->
    <button
      class="btn btn-primary"
      (click)="avancarEtapas()"
      *ngIf="adicao && formPrevidencia.invalid"
    >
      Avançar<i class="fa fa-arrow-right ml-2"></i>
    </button>
    <button
      class="btn btn-primary"
      (click)="submitPrevidencia()"
      *ngIf="(!visualizacao && !adicao) || (adicao && formPrevidencia.valid)"
    >
      Salvar<i class="fa fa-paper-plane ml-2"></i>
    </button>
    <button
      class="btn btn-primary"
      (click)="carregarPensionista(false, pensionistaEditando)"
      *ngIf="visualizacao"
    >
      Editar<i class="fa fa-pencil ml-2"></i>
    </button>
  </div>
</section>

<div
  class="modal fade"
  id="rateioModal"
  tabindex="-1"
  aria-labelledby="rateioModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="rateioModalLabel">Edição de Rateio</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          id="fecharRateio"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #rateio="ngForm" [formGroup]="formRateio">
          <section class="row" *ngFor="let pensionista of listaRateioCodaf20; let i = index">
            <div class="form-group col-md-6">
              <label class="font-weight-semibold">Nome Completo </label>
              <input
                type="text"
                [formControlName]="'nome' + i"
                class="form-control"
                placeholder="Nome Completo"
                maxlength="80"
                readonly
              />
            </div>
            <div class="col-md-3 form-group">
              <label class="font-weight-semibold">Tipo de Rateio</label>
              <select
                [formControlName]="'tipo' + i"
                class="form-control"
                (change)="atualizarTotalRateio()"
                [ngClass]="
                  formRateio.get('tipo' + i).invalid && formRateio.get('tipo' + i).touched
                    ? 'is-invalid'
                    : null
                "
                required
              >
                <option value="">Selecione...</option>
                <option *ngFor="let tipoRateio of listaTipoRateio" value="{{ tipoRateio.Codigo }}">
                  {{ tipoRateio.Descricao }}
                </option>
              </select>
              <span
                class="form-text text-danger"
                *ngIf="formRateio.get('tipo' + i).invalid && formRateio.get('tipo' + i).touched"
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="form-group col-md-3">
              <label class="font-weight-semibold"
                >Rateio<span *ngIf="formRateio.get('tipo' + i).value == '1'">(%)</span>
              </label>
              <input
                type="text"
                [formControlName]="'rateio' + i"
                class="form-control"
                placeholder="Rateio"
                (input)="
                  mascaraRateio(
                    'formRateio',
                    'rateio' + i,
                    formRateio.get('tipo' + i).value,
                    $event
                  )
                "
                required
                [ngClass]="
                  formRateio.get('rateio' + i).invalid && formRateio.get('rateio' + i).touched
                    ? 'is-invalid'
                    : null
                "
              />
              <span
                class="form-text text-danger"
                *ngIf="formRateio.get('rateio' + i).invalid && formRateio.get('rateio' + i).touched"
              >
                Campo Obrigatório
              </span>
            </div>
          </section>
          <section class="row">
            <div class="form-group col-md-12">
              <label class="font-weight-semibold">Total(%) </label>
              <input
                type="text"
                formControlName="rateioTotal"
                class="form-control"
                placeholder="Rateio Total"
                [ngClass]="formRateio.get('rateioTotal').invalid ? 'is-invalid' : null"
                readonly
              />
              <span class="form-text text-danger" *ngIf="formRateio.get('rateioTotal').invalid">
                Total excede o limite de 100%
              </span>
            </div>
          </section>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="salvarRateios()">Salvar</button>
      </div>
    </div>
  </div>
</div>
