<div class="card shadow-sm">
  <div class="card-header">
    <div class="d-flex justify-content-between">
      <h5 class="card-title text-uppercase text-muted">
        <i class="fa fa-money"></i>&nbsp;
        <span>Dados do Crédito</span>
      </h5>
      <!-- botões -->
      <div class="btn-group" *ngIf="!loading && dadosBancario.Banco">
        <ng-container *ngFor="let botao of botoesDadosBancarios">
          <button
            class="btn"
            [class.btn-outline-warning]="botao.color === 'warning'"
            [class.btn-outline-danger]="botao.color === 'danger'"
            [class.btn-outline-primary]="botao.color === 'primary'"
            [class.btn-sm]="true"
            [class.mx-2]="botao.label === 'Salvar'"
            [class.mr-2]="botao.label === 'Editar'"
            type="botao"
            *ngIf="botao.show()"
            (click)="botao.action()"
          >
            <i *ngIf="botao.icon" class="fa {{ botao.icon }}"></i
            >{{ botao.label === 'Editar' ? '&nbsp;' : '' }}
            <span>{{ botao.label }}</span>
          </button>
        </ng-container>
      </div>
    </div>
    <hr class="mb-0" />
  </div>
  <div class="card-body">
    <!-- tabela -->
    <div *ngIf="!displayDadosBancarios && dadosBancario.Banco && !loading" class="table-responsive">
      <table
        #tabelaSalario
        class="table table-striped shadow-sm"
        *ngIf="bancosInfo && bancoUsuario"
      >
        <thead>
          <tr>
            <th *ngFor="let info of bancosInfo" class="text-muted">{{ info.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-uppercase">
            <ng-container *ngFor="let info of bancosInfo">
              <td>
                {{ visualizacaoValoresDadosBancarios(info) }}
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- formulario -->
    <div *ngIf="displayDadosBancarios && dadosBancario.Banco">
      <form
        *ngIf="!loading && bancos && agencias && operacoes"
        [formGroup]="formSalario"
        autocomplete="off"
      >
        <div class="row">
          <div *ngFor="let campo of camposSalario" class="form-group col-xl-6">
            <label class="text-muted font-weight-bold">{{ campo.label }}</label>
            <select
              class="form-control form-control-uniform"
              [formControlName]="campo.formControlName"
              [ngStyle]="
                (formSalario.controls[campo.formControlName].status == 'INVALID' ||
                  formSalario.controls[campo.formControlName].value == '0') &&
                (temErro || formSalario.controls[campo.formControlName].touched)
                  ? { 'border-color': 'red' }
                  : null
              "
              (change)="campo.label == 'Banco' ? mudancaBancos($event) : null"
              required
            >
              <option value="0">Selecione...</option>
              <option
                *ngFor="let item of campoData(campo.formControlName) | orderBy : 'Nome' : false"
                [value]="
                  campo.label == 'Banco'
                    ? item.Codigo
                    : campo.label == 'Agência'
                    ? item.CodAgencia
                    : item.TipoOperacao
                "
              >
                {{
                  campo.label == 'Banco'
                    ? item.Nome
                    : campo.label == 'Agência'
                    ? item.CodAgencia + ' - ' + item.NomeAgencia
                    : item.NomeOperacao
                }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                (formSalario.controls[campo.formControlName].status == 'INVALID' ||
                  formSalario.controls[campo.formControlName].value == '0') &&
                (temErro || formSalario.controls[campo.formControlName].touched)
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="form-group col-xl-6">
            <label class="text-muted font-weight-bold">Conta</label>
            <input
              type="text"
              class="form-control"
              [ngStyle]="
                formSalario.controls['conta'].status == 'INVALID' &&
                (temErro || formSalario.controls['conta'].touched)
                  ? { 'border-color': 'red' }
                  : null
              "
              placeholder="Conta"
              formControlName="conta"
              (keydown)="mask($event)"
              maxlength="15"
              required
              mask="{{ contaMask }}"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                (formSalario.controls['conta'].touched || temErro) &&
                formSalario.controls['conta'].errors
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="form-group col-xl-12">
            <label class="text-muted font-weight-bold">Salário</label>
            <input
              type="text"
              class="form-control"
              [ngStyle]="
                formSalario.controls['salario'].status == 'INVALID' && temErro
                  ? { 'border-color': 'red' }
                  : null
              "
              placeholder="Salário"
              formControlName="salario"
              currencyMask
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
            />
          </div>
        </div>
      </form>
      <!-- spinner -->
      <!-- <div
        *ngIf="
          loading ||
          !bancos ||
          !agencias ||
          !operacoes ||
          displayDadosBancarios ||
          !dadosBancario.Banco ||
          !bancoUsuario
        "
        class="row d-flex justify-content-center align-items-center"
      >
        <i class="fa fa-refresh fa-spin fa"></i>
      </div> -->
    </div>
    <div
      *ngIf="!dadosBancario.Banco || !bancoUsuario"
      class="d-flex justify-content-center align-items-center"
    >
      <i class="fa fa-refresh fa-spin fa"></i>
    </div>
  </div>
</div>
