<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<!-- Page content -->
<div class="page-content">

	<!-- Main content -->
	<div class="content-wrapper">

		<!-- Content area -->
		<div class="content d-flex justify-content-center align-items-center">

			<!-- Login form -->
			<form class="login-form" [formGroup]="formRecuperarSenha" (ngSubmit)="enviarEmail()">
				<div class="card mb-0 bg-dark text-ligth">
					<div class="card-body">
						<h3 class="mb-0"><i class="fa fa-arrow-left pr-3  text-left " aria-hidden="true" (click)="retornarTelaLogin()" style="cursor: pointer; bottom: 2px;"></i></h3>

						<div class="text-center mb-3">
							<img src="assets\img\elogica-gente.png" class="img-fluid max-width: 100% height-auto" style="max-height: 70px;"><br><br>
							<h5 class="mb-0">Esqueci Minha Senha</h5>
							<span class="d-block text-muted">Para recuperar o seu acesso, precisamos do seu E-mail ou numero de telefone.</span>
						</div>

						<div class="form-group form-group-feedback form-group-feedback-left">
							<input type="text" class="form-control" placeholder="E-mail" name="email" formControlName="emailForm" required>
							<div class="form-control-feedback">
								<i class="icon-user text-muted"></i>
							</div>
							<span class="form-text text-danger" *ngIf="formRecuperarSenha.controls['emailForm'].touched && formRecuperarSenha.controls['emailForm'].hasError('required')">Informe o e-mail</span>
						</div>

						<div class="form-group form-group-feedback form-group-feedback-left">

							<!-- <button type="submit" (click)="captchaRef.execute();" class="btn btn-primary btn-block" [disabled]="formLogin.invalid || loading">Entrar <i class="fa fa-spinner fa-spin" aria-hidden="false" *ngIf="loading"></i></button> -->
							<button type="submit" class="btn btn-primary btn-block" [disabled]="formRecuperarSenha.invalid || carregando">{{carregando == false ? 'Enviar' : 'Carregando...'}}</button>
						</div>

					</div>

				</div>
			</form>
		</div>
	</div>
</div>

