import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AcessoService } from "src/app/service/acesso/acesso.service";
import { Crypto } from "src/app/service/classes/crypto/crypto";
import { LocalStorage } from "src/app/service/classes/localstorage/LocalStorage";
import { BancosFisicos } from "./manutencao-bd-fisicos.component";



@Injectable({
  providedIn: 'root'
})

export class BancosFisicosService {
  localStorage: LocalStorage = new LocalStorage();
  url_acesso: string;
  headers = new HttpHeaders();
  cabecalho: any;
  filialAtual: string;
  tipoUsuario: string;
  tpProcessamento: string = "EM";
  data: any = new Date()
  mes: number = this.data.getMonth() + 1
  ano: number = this.data.getFullYear()
  competencia: string = this.ano.toString() + this.mes.toString().padStart(2, "0")


  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl();
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filialAtual = this.localStorage.FilialAtual.toString(); 
      this.tipoUsuario = this.localStorage.TipoUsuario
    }
  }

  buscarClientesPse() { // Apenas para teste
    return this.http.get(this.url_acesso + `pse/clientes`, { headers: this.cabecalho });
  }

  buscarTarefasPorCodigo(codigoServico: number) {
    return this.http.get(`${this.url_acesso}politica-seguranca/tarefas/${codigoServico}`, { headers: this.cabecalho });
  }

  buscarServicosPse() {
    return this.http.get(this.url_acesso + `pse/servicos`, { headers: this.cabecalho });
  }

  buscarBancosFisicos(codigoServico: string) {
    return this.http.get(this.url_acesso + `pse/bancos-fisicos/${codigoServico}`, { headers: this.cabecalho });
  }

  salvarBancoFisico(bancoFisico: BancosFisicos) {
    return this.http.post(`${this.url_acesso}pse/bancos-fisicos`, bancoFisico, { headers: this.cabecalho });
  }

  alterarBancoFisico(bancoFisico: BancosFisicos) {
    return this.http.put(`${this.url_acesso}pse/bancos-fisicos`, bancoFisico, { headers: this.cabecalho });
  }

  deletarBancoFisico(codigoServico: string, codigoBanco: string) {
    return this.http.delete(this.url_acesso + `pse/bancos-fisicos/${codigoServico}/${codigoBanco}`, { headers: this.cabecalho });
  }

}