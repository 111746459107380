import * as CryptoJS from 'crypto-js';

export class Crypto {
  tokenFromUI: string = "EloCrypto1.0";

  /**
   * Encriptar a chave passada.
   * @param chave
   */
  encryptUsingAES256(chave: string): string {
    return CryptoJS.AES.encrypt(
      chave, CryptoJS.enc.Utf8.parse(this.tokenFromUI), {
      keySize: 12,
      iv: CryptoJS.enc.Utf8.parse(this.tokenFromUI),
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
  }

  /**
   * Descriptar a chave passada.
   * @param chave
   */
  decryptUsingAES256(chave: string): string {
    return CryptoJS.AES.decrypt(
      chave, CryptoJS.enc.Utf8.parse(this.tokenFromUI), {
      keySize: 12,
      iv: CryptoJS.enc.Utf8.parse(this.tokenFromUI),
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
  }
}
