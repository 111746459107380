import {Component, OnInit} from '@angular/core';
import {Validators, FormGroup, FormBuilder} from '@angular/forms';
import {dataValidator} from 'src/app/service/Validators/dataValidator';
import {Parametro} from 'src/app/service/interfaces/Parametro';
import Swal from 'sweetalert';
import {Router} from '@angular/router';
import {ErroService} from 'src/app/service/erros/erro.service';
import {Data} from 'src/app/service/interfaces/Data';
import {CadastroAprendizService} from './cadastro-aprendiz.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {LocalStorage} from 'src/app/service/classes/localstorage/LocalStorage';
import {Crypto} from 'src/app/service/classes/crypto/crypto';
import {Nivel} from 'src/app/service/interfaces/Nivel';
import {AgenteIntegracao} from 'src/app/service/interfaces/AgenteIntegracao';
import {InstituicaoEnsino} from 'src/app/service/interfaces/InstituicaoEnsino';
import {ContratosAprendiz} from 'src/app/service/interfaces/ContratosAprendiz';
import {Supervisor} from 'src/app/service/interfaces/Supervisor';

@Component({
  selector: 'app-cadastro-aprendiz',
  templateUrl: './cadastro-aprendiz.component.html',
  styleUrls: ['./cadastro-aprendiz.component.css'],
})
export class CadastroAprendizComponent implements OnInit {
  localStorage: LocalStorage;
  numeroOrdem: string;
  temErro: boolean = false;
  editar: boolean = false;
  visualizacao: boolean = false;
  nivel: Nivel;
  listaAgenteIntegracao: AgenteIntegracao[];
  listaInstituicaoEnsino: InstituicaoEnsino[];
  listaTipoLogradouro: Parametro[];
  formAprendiz: FormGroup;

  constructor(
    public fb: FormBuilder,
    private aprendizService: CadastroAprendizService,
    private route: Router,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.localStorage = this.decryptLocalStorage();

    this.formAprendiz = this.fb.group({
      numeroProcesso: ['', Validators.required],
      NumeroProcessoAnterior: [''],

      areaAtuacao: ['', Validators.required],
      valorBolsa: [{value: '', disabled: true}, Validators.required],
      dataPrevisaoTermino: ['', [Validators.required]],
      dataInicial: ['', [Validators.required]],
      dataFinal: ['', [Validators.required]],
      instEnsinoCnpj: ['', Validators.required],
      instEnsinoId: ['', Validators.required],
      instEnsinoRazaoSocial: ['', Validators.required],
      instEnsinoCep: ['', Validators.required],
      instEnsinoLogradouro: ['', Validators.required],
      instEnsinoTipoLogradouro: [''],
      instEnsinoNumero: ['', Validators.required],
      instEnsinoMunicipio: ['', Validators.required],
      instEnsinoBairro: ['', Validators.required],
      instEnsinoUf: ['', Validators.required],
      agenteIntegracaoCnpj: ['', Validators.required],
      agenteIntegracaoId: ['', Validators.required],
      agenteIntegracaoRazaoSocial: ['', Validators.required],
      agenteIntegracaoCep: ['', Validators.required],
      agenteIntegracaoLogradouro: ['', Validators.required],
      agenteIntegracaoTipoLogradouro: [''],
      agenteIntegracaoNumero: ['', Validators.required],
      agenteIntegracaoMunicipio: ['', Validators.required],
      agenteIntegracaoBairro: ['', Validators.required],
      agenteIntegracaoUf: ['', Validators.required],
      supervisorNumOrd: ['', Validators.required],
      supervisorEmpfil: ['', Validators.required],
      supervisorMatricula: ['', Validators.required],
      supervisorNome: ['', Validators.required],
    });
    this.buscarParametro();
  }

  voltarBusca() {
    this.route.navigate(['/complemento-cadastral/aprendiz']);
  }

  buscarParametro(): void {
    this.spinner.show();
    const empresaFilial = this.localStorage.FilialAtual.toString();
    this.aprendizService.buscarParametros(empresaFilial).subscribe(
      (data: any) => {
        this.spinner.hide();
        this.listaAgenteIntegracao = data.AgenteIntegracao;
        this.listaInstituicaoEnsino = data.InstituicaoEnsino;
        this.listaTipoLogradouro = data.TiposLogradouro;
        if (history.state.data) {
          console.log(history.state);
          this.numeroOrdem = history.state.data.numeroOrdem;
          this.editar = history.state.data.editar;
          if (history.state.data.visualizacao) {
            this.visualizacao = true;
            this.formAprendiz.disable();
          }
          !history.state.data.NovoCadastro
            ? this.carregarAprendiz(history.state.data.aprendiz)
            : this.carregarValorBolsa(history.state.data.aprendiz.Nivel);
        } else {
          this.route.navigate(['/complemento-cadastral/aprendiz']);
        }
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  carregarValorBolsa(nivel: Nivel) {
    this.nivel = nivel;
    console.log('nivel', this.nivel);
    this.formAprendiz.get('valorBolsa').setValue(nivel.Valor);
  }

  carregarAprendiz(aprendiz) {
    console.log(aprendiz);
    this.formAprendiz.get('instEnsinoCnpj').setValue(aprendiz.InstituicaoEnsino.CNPJ);
    this.carregarInstEnsino();
    this.formAprendiz.get('numeroProcesso').setValue(aprendiz.NumeroProcesso);
    this.formAprendiz.get('NumeroProcessoAnterior').setValue(aprendiz.NumeroProcessoAnterior);
    this.formAprendiz.get('areaAtuacao').setValue(aprendiz.AreaAtuacao);
    this.formAprendiz.get('valorBolsa').setValue(aprendiz.ValorBolsa);
    this.formAprendiz
      .get('dataPrevisaoTermino')
      .setValue(this.corrigeDatas(aprendiz.DataTermino.ToDate));
    this.editar
      ? this.formAprendiz
          .get('dataInicial')
          .setValue(this.corrigeDatas(aprendiz.DataInicial.ToDate))
      : '';
    this.editar
      ? this.formAprendiz.get('dataFinal').setValue(this.corrigeDatas(aprendiz.DataFinal.ToDate))
      : '';
    this.formAprendiz.get('agenteIntegracaoCnpj').setValue(aprendiz.AgenteIntegracao.CNPJ);
    this.carregarAgenteIntegracao();
    this.formAprendiz.get('supervisorNumOrd').setValue(aprendiz.Supervisor.NumeroDeOrdem);
    this.formAprendiz.get('supervisorEmpfil').setValue(aprendiz.Supervisor.EmpresaFilial);
    this.formAprendiz.get('supervisorMatricula').setValue(aprendiz.Supervisor.Matricula);
    this.formAprendiz.get('supervisorNome').setValue(aprendiz.Supervisor.Nome);
    this.formAprendiz.get('valorBolsa').setValue(aprendiz.ValorBolsa.Valor);
    this.carregarInstEnsino();
    this.carregarAgenteIntegracao();
    this.nivel = aprendiz.ValorBolsa;
  }

  carregarInstEnsino(): void {
    const cnpj = this.formAprendiz.get('instEnsinoCnpj').value;
    const intstEnsino = this.listaInstituicaoEnsino.find((inst) => inst.CNPJ === cnpj);
    this.formAprendiz.get('instEnsinoId').setValue(intstEnsino.Id);
    this.formAprendiz.get('instEnsinoRazaoSocial').setValue(intstEnsino.NomeRazao);
    this.formAprendiz.get('instEnsinoCep').setValue(intstEnsino.Cep);
    this.formAprendiz.get('instEnsinoLogradouro').setValue(intstEnsino.Logradouro);
    this.formAprendiz.get('instEnsinoTipoLogradouro').setValue(intstEnsino.TipoLogradouro);
    this.formAprendiz.get('instEnsinoNumero').setValue(intstEnsino.Numero);
    this.formAprendiz.get('instEnsinoMunicipio').setValue(intstEnsino.Cidade);
    this.formAprendiz.get('instEnsinoBairro').setValue(intstEnsino.Bairro);
    this.formAprendiz.get('instEnsinoUf').setValue(intstEnsino.UF);
  }

  carregarAgenteIntegracao(): void {
    const cnpj = this.formAprendiz.get('agenteIntegracaoCnpj').value;
    const agenteIntegracao = this.listaAgenteIntegracao.find((agente) => agente.CNPJ === cnpj);

    this.formAprendiz.get('agenteIntegracaoId').setValue(agenteIntegracao.Id);
    this.formAprendiz.get('agenteIntegracaoRazaoSocial').setValue(agenteIntegracao.NomeRazao);
    this.formAprendiz.get('agenteIntegracaoCep').setValue(agenteIntegracao.Cep);
    this.formAprendiz.get('agenteIntegracaoLogradouro').setValue(agenteIntegracao.Logradouro);
    this.formAprendiz
      .get('agenteIntegracaoTipoLogradouro')
      .setValue(agenteIntegracao.TipoLogradouro);
    this.formAprendiz.get('agenteIntegracaoNumero').setValue(agenteIntegracao.Numero);
    this.formAprendiz.get('agenteIntegracaoMunicipio').setValue(agenteIntegracao.Cidade);
    this.formAprendiz.get('agenteIntegracaoBairro').setValue(agenteIntegracao.Bairro);
    this.formAprendiz.get('agenteIntegracaoUf').setValue(agenteIntegracao.UF);
  }

  verificaData(dataInicial: string, dataFinal: string): void {
    let formDataInicial = this.formAprendiz.value[dataInicial];
    let formDataFinal = this.formAprendiz.value[dataFinal];

    if (formDataInicial.length === 8 && formDataFinal.length === 8) {
      formDataInicial =
        formDataInicial.substr(4, 4) + formDataInicial.substr(2, 2) + formDataInicial.substr(0, 2);
      formDataFinal =
        formDataFinal.substr(4, 4) + formDataFinal.substr(2, 2) + formDataFinal.substr(0, 2);
      if (Number(formDataInicial) < Number(formDataFinal)) return;

      Swal('Atenção', 'Data final deve ser posterior a data inicial', 'warning');
      this.formAprendiz.get(dataInicial).setValue('');
      this.formAprendiz.get(dataFinal).setValue('');
    }
  }

  buscarSupervisor(): void {
    const matricula = this.formAprendiz.get('supervisorMatricula').value;

    this.aprendizService.buscarSupervisor(matricula).subscribe(
      (data: Supervisor) => {
        this.formAprendiz.get('supervisorEmpfil').setValue(data.EmpresaFilial);
        this.formAprendiz.get('supervisorNumOrd').setValue(data.NumeroDeOrdem);
        this.formAprendiz.get('supervisorNome').setValue(data.Nome);
      },
      (error) => this.validaErro.retornoErro(error)
    );
  }

  submitAprendiz(): void {
    if (this.formAprendiz.invalid) {
      Swal('Atenção', 'Preencha todos os campos corretamente', 'warning');
      this.temErro = true;
    } else {
      const aprendiz = this.montarAprendiz();
      aprendiz.DataFinal.SetData = aprendiz.DataFinal.ToDate;
      aprendiz.DataInicial.SetData = aprendiz.DataInicial.ToDate;
      aprendiz.DataTermino.SetData = aprendiz.DataTermino.ToDate;
      this.editar ? this.editarAprendiz(aprendiz) : this.salvarAprendiz(aprendiz);
    }
  }

  editarAprendiz(aprendiz: ContratosAprendiz): void {
    this.spinner.show();
    this.aprendizService.editarAprendiz(aprendiz, this.numeroOrdem).subscribe(
      async (data) => {
        this.spinner.hide();
        await Swal('Sucesso', 'O aprendiz foi editado', 'success');
        this.route.navigate(['/complemento-cadastral/aprendiz']);
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  salvarAprendiz(aprendiz: ContratosAprendiz): void {
    this.spinner.show();
    this.aprendizService.salvarAprendiz(aprendiz, this.numeroOrdem).subscribe(
      async (data) => {
        this.spinner.hide();
        await Swal('Sucesso', 'O aprendiz foi inserido', 'success');
        this.route.navigate(['/complemento-cadastral/aprendiz']);
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  montarAprendiz(): ContratosAprendiz {
    return {
      NumeroProcesso: this.formAprendiz.get('numeroProcesso').value,
      AreaAtuacao: this.formAprendiz.get('areaAtuacao').value,
      NumeroProcessoAnterior: this.formAprendiz.get('NumeroProcessoAnterior').value,
      CodNivelBolsa: this.nivel.Codigo,
      DataTermino: new Data(this.converteDatas(this.formAprendiz.get('dataPrevisaoTermino').value)),
      DataInicial: new Data(this.converteDatas(this.formAprendiz.get('dataInicial').value)),
      DataFinal: new Data(this.converteDatas(this.formAprendiz.get('dataFinal').value)),
      Supervisor: {
        EmpresaFilial: this.formAprendiz.get('supervisorEmpfil').value,
        Matricula: this.formAprendiz.get('supervisorMatricula').value,
        Nome: this.formAprendiz.get('supervisorNome').value,
        NumeroDeOrdem: this.formAprendiz.get('supervisorNumOrd').value,
      },
      InstituicaoEnsino: {
        Bairro: this.formAprendiz.get('instEnsinoBairro').value,
        CNPJ: this.formAprendiz.get('instEnsinoCnpj').value,
        Cep: this.formAprendiz.get('instEnsinoCep').value,
        Cidade: this.formAprendiz.get('instEnsinoMunicipio').value,
        Id: this.formAprendiz.get('instEnsinoId').value,
        Logradouro: this.formAprendiz.get('instEnsinoLogradouro').value,
        NomeRazao: this.formAprendiz.get('instEnsinoRazaoSocial').value,
        Numero: this.formAprendiz.get('instEnsinoNumero').value,
        TipoLogradouro: this.formAprendiz.get('instEnsinoTipoLogradouro').value,
        UF: this.formAprendiz.get('instEnsinoUf').value,
      },
      AgenteIntegracao: {
        Bairro: this.formAprendiz.get('agenteIntegracaoBairro').value,
        CNPJ: this.formAprendiz.get('agenteIntegracaoCnpj').value,
        Cep: this.formAprendiz.get('agenteIntegracaoCep').value,
        Cidade: this.formAprendiz.get('agenteIntegracaoMunicipio').value,
        Id: this.formAprendiz.get('agenteIntegracaoId').value,
        Logradouro: this.formAprendiz.get('agenteIntegracaoLogradouro').value,
        NomeRazao: this.formAprendiz.get('agenteIntegracaoRazaoSocial').value,
        Numero: this.formAprendiz.get('agenteIntegracaoNumero').value,
        TipoLogradouro: this.formAprendiz.get('agenteIntegracaoTipoLogradouro').value,
        UF: this.formAprendiz.get('agenteIntegracaoUf').value,
      },
    };
  }

  converteDatas(datas) {
    var [ano, mes, dia] = datas.split('-');
    return `${dia}/${mes}/${ano}`;
  }
  corrigeDatas(datas) {
    var [dia, mes, ano] = datas.split('/');
    return `${ano}-${mes}-${dia}`;
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}
