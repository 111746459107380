import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErroService } from 'src/app/service/erros/erro.service';
import { PesquisarMatriculaService } from './pesquisar-matricula.service';
import { ColaboradoresService } from '../../colaboradores/colaboradores.service';
import { AlertaModal } from '../alerta-modal/alerta-modal.component';
import { ComplementoCadastralService } from '../../complemento-cadastral/complemento-cadastral.service';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import Swal from 'sweetalert';

@Component({
  selector: 'app-pesquisar-matricula',
  templateUrl: './pesquisar-matricula.component.html',
  styleUrls: ['./pesquisar-matricula.component.css'],
})
export class PesquisarMatriculaComponent implements OnInit {
  @Input() titulo: string;
  @Input() label: string;
  @Input() lista: any[]; // caso for montar uma tela com um input select
  @Input() inputText: boolean; // caso for false ele vai criar um input select
  @Output() colaboradorEmitter = new EventEmitter();
  @Input() teste: string;
  @Input() estagio: boolean = false;

  formPesquisa: FormGroup;

  colaborador: any;
  colaboradores: any;
  colaboradoraux: any = '';
  valor: any;
  nome: string = '';
  listaFiltroColaborador: any;
  nenhumColaborador: boolean = false;

  constructor(
    private fb: FormBuilder,
    private pesquisaMatriculaService: PesquisarMatriculaService,
    private colaboradoresService: ColaboradoresService,
    private spinner: NgxSpinnerService,
    private validaErro: ErroService,
    private complementoCadastralService: ComplementoCadastralService
  ) {
    this.formPesquisa = this.fb.group({
      campo_pesquisa_matricula: ['', [Validators.required]],
      campo_pesquisa_nome: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.buscarFiltros();
  }

  buscarColaborador(matricula?: number, nome?: string) {
    if (this.formPesquisa.get('campo_pesquisa_matricula').valid && matricula) {
      this.spinner.show();
      this.formPesquisa.get('campo_pesquisa_nome').setValue('');

      this.pesquisaMatriculaService.obterUsuarioPorMatricula(matricula).subscribe(
        (data) => {
          this.colaborador = data;
          this.colaboradores = [this.colaborador];
          this.nenhumColaborador = false;
          this.spinner.hide();
          console.log('colab', this.colaborador);
        },
        (erro) => {
          this.validaErro.retornoErro(erro);
          this.spinner.hide();
        }
      );
    } else if (this.formPesquisa.get('campo_pesquisa_nome').valid && nome) {
      this.formPesquisa.get('campo_pesquisa_matricula').setValue('');
      this.colaboradoresService.buscarColaboradores(this.listaFiltroColaborador).subscribe(
        (data: any) => {
          var colaboradores = data;
          colaboradores = colaboradores.filter(
            (colaborador) =>
              colaborador.NomeCompleto.includes(nome.toUpperCase()) ||
              colaborador.NomeCompleto.includes(nome.toLowerCase())
          );

          if (colaboradores.length > 0) {
            this.colaboradores = colaboradores;
            this.colaborador = this.colaboradores;
            this.nenhumColaborador = false;
            console.log(this.colaboradores);
          } else {
            this.colaboradores = [];
            this.nenhumColaborador = true;
          }
          this.spinner.hide();
        },
        (erro) => {
          this.validaErro.retornoErro(erro);
          this.spinner.hide();
        }
      );
    } else {
      new AlertaModal(
        'erro',
        'Erro',
        'Insira no mínimo um valor para realizar sua busca ou revise os valores inseridos',
        'p'
      );
    }
  }

  buscarFiltros(): void {
    this.spinner.show();
    this.colaboradoresService.buscarFiltros().subscribe(
      (data) => {
        this.listaFiltroColaborador = data;
        this.listaFiltroColaborador = this.listaFiltroColaborador.map((filtro) => filtro.Codigo);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  mudarValor() {
    // this.colaboradoraux = this.colaborador;
    // this.colaboradorEmitter.emit(this.colaboradoraux);
    if (!this.estagio) {
      this.spinner.show();
      this.pesquisaMatriculaService
        .buscarColaboradorMatricula(this.formPesquisa.get('campo_pesquisa_matricula').value)
        .subscribe(
          (data) => {
            this.colaboradoraux = data;
            this.colaboradorEmitter.emit(this.colaboradoraux);
            this.spinner.hide();
          },
          (erro) => {
            this.validaErro.retornoErro(erro);
            this.spinner.hide();
          }
        );
    } else {
      this.complementoCadastralService
        .buscarColaboradorMatriculaEstagio(this.formPesquisa.get('campo_pesquisa_matricula').value)
        .subscribe(
          (data: Cadastro) => {
            this.colaboradoraux = data;
            this.colaboradorEmitter.emit(this.colaboradoraux);
            this.spinner.hide();
          },
          (error) => {
            this.validaErro.retornoErro(error);
            // this.retornarTelaInicial();
            // this.loading = false;
            this.spinner.hide();
          }
        );
    }
  }
}
