<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="modal-header">
  <h3 class="modal-title">{{ cadastro ? 'Cadastrar' : 'Editar' }} Comissao</h3>
  <button type="button" class="close" id="fecharModal" data-dismiss="modal" aria-label="Close">
    <p aria-hidden="true">&times;</p>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="formulario">
    <div class="row">
      <div *ngIf="!cadastro" class="form-group col-md-2">
        <label for="">Seq</label>
        <input
          type="text"
          class="form-control"
          formControlName="seq"
          readonly
          aria-describedby="Sequencial"
          placeholder="00"
        />
      </div>
      <div class="form-group" [ngClass]="cadastro ? 'col-md-12' : 'col-md-10'">
        <label for="">Nº Processo</label>
        <input
          type="text"
          class="form-control"
          mask="A*"
          formControlName="numeroProcesso"
          aria-describedby=">Nº Processo"
          placeholder="0000"
          [ngClass]="
            formulario.get('numeroProcesso').invalid && formulario.get('numeroProcesso').touched
              ? 'is-invalid'
              : null
          "
        />
        <p
          class="text-danger mt-1"
          *ngIf="
            formulario.get('numeroProcesso').invalid && formulario.get('numeroProcesso').touched
          "
        >
          Campo obrigatório
        </p>
      </div>

      <div class="form-group col-md-6">
        <label for="">Data Início</label>
        <input
          type="date"
          max="9999-12-31"
          class="form-control"
          formControlName="dataInicio"
          aria-describedby=">data início"
          [ngClass]="
            formulario.get('dataInicio').invalid && formulario.get('dataInicio').touched
              ? 'is-invalid'
              : null
          "
        />
        <p
          class="text-danger mt-1"
          *ngIf="formulario.get('dataInicio').invalid && formulario.get('dataInicio').touched"
        >
          Campo obrigatório
        </p>
      </div>
      <div class="form-group col-md-6">
        <label for="">Data Fim</label>
        <input
          type="date"
          max="9999-12-31"
          class="form-control"
          formControlName="dataFim"
          aria-describedby=">data fim"
          [ngClass]="
            formulario.get('dataFim').touched &&
            formulario.get('dataFim').hasError('dataFimMenorQueInicial')
              ? 'is-invalid'
              : null
          "
        />
        <p
          class="text-danger mt-1"
          *ngIf="formulario.get('dataFim').hasError('dataFimMenorQueInicial')"
        >
          A data final deve ser igual ou posterior à
          {{ formulario.get('dataInicio').value | date : 'dd/MM/yyyy' }}.
        </p>
      </div>

      <div
        class="form-group"
        [ngClass]="formulario.get('tipo').value === '' ? 'col-md-12' : 'col-md-6'"
      >
        <label for="">Tipo</label>
        <select
          class="form-control"
          formControlName="tipo"
          (change)="aoMudarTipo($event)"
          [ngClass]="
            formulario.get('tipo').invalid && formulario.get('tipo').touched ? 'is-invalid' : null
          "
        >
          <option value="">Selecione</option>
          <option value="1">Projeto</option>
          <option value="2">Função</option>
        </select>
        <p
          class="text-danger mt-1"
          *ngIf="formulario.get('tipo').invalid && formulario.get('tipo').touched"
        >
          Campo obrigatório
        </p>
      </div>
      <div class="form-group col-md-6" *ngIf="formulario.get('tipo').value === '1'">
        <label for="">Projeto</label>
        <select
          class="form-control"
          formControlName="numero"
          aria-describedby="numero"
          [ngClass]="
            formulario.get('numero').invalid && formulario.get('numero').touched
              ? 'is-invalid'
              : null
          "
        >
          <option value="">Selecione</option>
          <option *ngFor="let item of listaProjetos" [value]="item.Codigo">
            {{ item.Codigo }} - {{ item.Descricao }}
          </option>
        </select>
        <p
          class="text-danger mt-1"
          *ngIf="formulario.get('numero').invalid && formulario.get('numero').touched"
        >
          Campo obrigatório
        </p>
      </div>

      <div class="form-group col-md-6" *ngIf="formulario.get('tipo').value === '2'">
        <label for="cargo">Cargo</label>
        <select
          class="form-control"
          id="cargo"
          formControlName="cargo"
          (change)="buscarReferencias($event)"
          [ngClass]="
            formulario.get('cargo').invalid && formulario.get('cargo').touched ? 'is-invalid' : null
          "
        >
          <option value="">Selecione</option>
          <option *ngFor="let item of listaCargos" [value]="item.Codigo">
            {{ item.Codigo }} - {{ item.Descricao }}
          </option>
        </select>
        <p
          class="text-danger mt-1"
          *ngIf="formulario.get('cargo').invalid && formulario.get('cargo').touched"
        >
          Campo obrigatório
        </p>
      </div>
      <div class="form-group col-md-12" *ngIf="formulario.get('tipo').value === '2'">
        <label for="referencia">Referencia</label>
        <select
          class="form-control"
          id="referencia"
          formControlName="referencia"
          aria-describedby="Referência"
          [ngClass]="
            formulario.get('referencia').invalid && formulario.get('referencia').touched
              ? 'is-invalid'
              : null
          "
        >
          <option value="">Selecione</option>
          <option *ngFor="let item of listaReferencias" [value]="item.Codigo">
            {{ item.Codigo }} - {{ item.Descricao }}
          </option>
        </select>
        <p
          class="text-danger mt-1"
          *ngIf="formulario.get('referencia').invalid && formulario.get('referencia').touched"
        >
          Campo obrigatório
        </p>
      </div>
      <div class="form-group" class="col-md-12">
        <label for="motivo">Motivo</label>
        <input
          type="text"
          class="form-control"
          id="motivo"
          placeholder="Motivo"
          formControlName="motivo"
          aria-describedby="Motivo"
          [ngClass]="
            formulario.get('motivo').invalid && formulario.get('motivo').touched
              ? 'is-invalid'
              : null
          "
        />
        <p
          class="text-danger mt-1"
          *ngIf="formulario.get('motivo').invalid && formulario.get('motivo').touched"
        >
          Campo obrigatório
        </p>
      </div>
      <fieldset class="col-md-12 mt-3">
        <legend>
          <h4>Diário Oficial</h4>
        </legend>
        <div class="row">
          <div class="form-group col-md-5">
            <label for="doe">Número</label>
            <input
              type="text"
              class="form-control"
              id="doe"
              mask="A*"
              formControlName="numeroDoe"
              aria-describedby="numero Doe"
              placeholder="0000"
              [ngClass]="
                formulario.get('numeroDoe').invalid && formulario.get('numeroDoe').touched
                  ? 'is-invalid'
                  : null
              "
            />
            <p
              class="text-danger mt-1"
              *ngIf="formulario.get('numeroDoe').invalid && formulario.get('numeroDoe').touched"
            >
              Campo obrigatório
            </p>
          </div>
          <div class="form-group col-md-2">
            <label for="anoDoe">Ano</label>
            <input
              type="text"
              class="form-control"
              id="anoDoe"
              readonly
              formControlName="anoDoe"
              aria-describedby="anoDoe"
              placeholder="0000"
            />
          </div>
          <div class="form-group col-md-5">
            <label for="dtdoe">Data</label>
            <input
              type="date"
              class="form-control"
              id="dtdoe"
              max="9999-12-31"
              formControlName="dtDoe"
              aria-describedby="dtdoe"
              (change)="aoAlterarData($event, 'anoDoe')"
              [ngClass]="
                formulario.get('dtDoe').invalid && formulario.get('dtDoe').touched
                  ? 'is-invalid'
                  : null
              "
            />
            <p
              class="text-danger mt-1"
              *ngIf="
                formulario.get('dtDoe').hasError('required') && formulario.get('dtDoe').touched
              "
            >
              Campo obrigatório
            </p>
            <p
              class="text-danger mt-1"
              *ngIf="
                formulario.get('dtDoe').hasError('dataMaiorQueAtual') &&
                formulario.get('dtDoe').touched
              "
            >
              A data do Diário oficial {{ formulario.get('dtDoe').value | date : 'dd/MM/yyyy' }} não
              pode ser maior que a data atual.
            </p>

            <p
              class="text-danger mt-1"
              *ngIf="
                formulario.get('dtDoe').hasError('maxlength') && formulario.get('dtDoe').touched
              "
            >
              Data inválida
            </p>
          </div>
        </div>
      </fieldset>
      <fieldset class="col-md-12">
        <legend>
          <h4>Ato</h4>
        </legend>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="idClassificacaoAto">Classificação Ato</label>
            <select
              class="form-control"
              id="idClassificacaoAto"
              formControlName="idClassificacaoAto"
              [ngClass]="
                formulario.get('idClassificacaoAto').invalid &&
                formulario.get('idClassificacaoAto').touched
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione</option>
              <option *ngFor="let item of listaClassificacao" [value]="item.Cod">
                {{ item.Cod }} - {{ item.Nome }}
              </option>
            </select>
            <p
              class="text-danger mt-1"
              *ngIf="
                formulario.get('idClassificacaoAto').invalid &&
                formulario.get('idClassificacaoAto').touched
              "
            >
              Campo obrigatório
            </p>
          </div>
          <div class="form-group col-md-6">
            <label for="ato">Número</label>
            <input
              type="text"
              class="form-control"
              id="ato"
              mask="A*"
              formControlName="numeroAto"
              aria-describedby="ato"
              placeholder="0000"
              [ngClass]="
                formulario.get('numeroAto').invalid && formulario.get('numeroAto').touched
                  ? 'is-invalid'
                  : null
              "
            />
            <p
              class="text-danger mt-1"
              *ngIf="formulario.get('numeroAto').invalid && formulario.get('numeroAto').touched"
            >
              Campo obrigatório
            </p>
          </div>
          <div class="col-md-6 form-group">
            <label>Ano</label>
            <input
              type="text"
              class="form-control input-disabled"
              mask="0000"
              formControlName="anoAto"
              readonly
              placeholder="0000"
            />
          </div>
          <div class="col-md-6 form-group">
            <label>Data </label>
            <input
              type="date"
              max="9999-12-31"
              class="form-control"
              formControlName="dtAto"
              (change)="aoAlterarData($event, 'anoAto')"
              [ngClass]="
                formulario.get('dtAto').invalid && formulario.get('dtAto').touched
                  ? 'is-invalid'
                  : null
              "
            />
            <p
              class="text-danger mt-1"
              *ngIf="
                formulario.get('dtAto').hasError('required') && formulario.get('dtAto').touched
              "
            >
              Campo obrigatório
            </p>
            <p
              class="text-danger mt-1"
              *ngIf="
                formulario.get('dtAto').hasError('dataMaiorQueAtual') &&
                formulario.get('dtAto').touched
              "
            >
              A data do Ato {{ formulario.get('dtAto').value | date : 'dd/MM/yyyy' }} não
              pode ser maior que a data atual.
            </p>
            <p
              class="text-danger mt-1"
              *ngIf="
                formulario.get('dtAto').hasError('minlength') && formulario.get('dtAto').touched
              "
            >
              Data inválida
            </p>
          </div>
        </div>
      </fieldset>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="cadastro ? cadastrarComissao() : atualizarComissao()"
  >
    {{ cadastro ? 'Salvar' : 'Atualizar' }}
  </button>
</div>
