import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Dependente } from './dependentes';

@Injectable({
  providedIn: 'root',
})
export class DependentesService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any;
  filial: any;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }
  url_acesso: any = this.acesso.validarUrl();

  buscarTipoDependencia() {
    return this.http.get(`${this.url_acesso}tiposdependentes`, { headers: this.cabecalho });
  }
  buscarSexo() {
    return this.http.get(`${this.url_acesso}parametros/sexo`, { headers: this.cabecalho });
  }

  buscarEstado() {
    return this.http.get(`${this.url_acesso}estados/`, { headers: this.cabecalho });
  }

  buscarCidade(codigo: number) {
    return this.http.get(`${this.url_acesso}estados/${codigo}`, { headers: this.cabecalho });
  }

  atualizarDependente(dados: Dependente) {
    return this.http.put(`${this.url_acesso}dependentes`, dados, { headers: this.cabecalho });
  }
  salvarDependente(dados: Dependente) {
    return this.http.post(`${this.url_acesso}dependentes`, dados, { headers: this.cabecalho });
  }
  getDocumentos(matricula: string) {
    return this.http.get(`${this.url_acesso}uploadarquivo/${matricula}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarCadastro(numOrdem) {
    return this.http.get(`${this.url_acesso}cadastro/${numOrdem}/0000000/EM/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarMotivoInicio() {
    return this.http.get(`${this.url_acesso}dependentes/motivodependencia`, {
      headers: this.cabecalho,
    });
  }

  buscarMotivoFim() {
    return this.http.get(`${this.url_acesso}dependentes/motivofimdependencia`, {
      headers: this.cabecalho,
    });
  }

  buscarDependentes(matricula) {
    return this.http.get(`${this.url_acesso}dependentes/${this.filial}/${matricula}`, {
      headers: this.cabecalho,
    });
  }

  formatarDataBarra(data: string) {
    const partesData = data.split('-');
    const dataFormatada = partesData[2] + '/' + partesData[1] + '/' + partesData[0];
    return dataFormatada;
  }

  formatarDataHifen(data: string): string {
    let ano = data.slice(0, 4);
    let mes = data.slice(4, 6);
    let dia = data.slice(6, 8);

    return `${ano}-${mes}-${dia}`;
  }

  formatarDataSemEspaco(data: string) {
    const partesData = data.split('-');
    const dataFormatada = partesData[0] + partesData[1] + partesData[2];
    return dataFormatada;
  }
}
