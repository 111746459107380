import { Component } from '@angular/core';
import { Alert } from 'selenium-webdriver';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-alerta-modal',
  templateUrl: './alerta-modal.component.html',
  styleUrls: ['./alerta-modal.component.css'],
})
export class AlertaModalComponent {
  Enviar() {
    new AlertaModal('sucesso', 'Nova senha solicitada.', 'Veja no seu e-mail.', 'P');
  }
}

export class AlertaModal {
  constructor(icone?: string, texto1?: string, texto2?: string, tamanho?: string) {
    if (tamanho.toUpperCase() == 'P') {
      Swal.fire({
        html: `<div><img style="width: 130px; margin-bottom: 50px;" src="../../../../assets/assets_components/alerta_modal_assets/Icone ${icone}.svg"><h1><strong>${texto1}</strong></h1><span>${texto2}</span></div>`,
        showCloseButton: true,
        focusConfirm: false,
        showConfirmButton: false,
        customClass: {
          popup: 'pop-class-P',
          closeButton: 'close-button-class',
          htmlContainer: 'swal_content',
          icon: 'swal_icone',
        },
      });
    } else {
      Swal.fire({
        html: `<div><img style="width: 170px; margin-bottom: 70px;" src="../../../../assets/assets_components/alerta_modal_assets/Icone ${icone}.svg"><h1><strong>${texto1}</strong></h1><span>${texto2}</span></div>`,
        showCloseButton: true,
        focusConfirm: false,
        showConfirmButton: false,
        customClass: {
          popup: 'pop-class-G',
          closeButton: 'close-button-class',
          htmlContainer: 'swal_content',
          icon: 'swal_icone',
        },
      });
    }
  }
}
