import { Component, OnInit } from '@angular/core';
import { RegrasBeneficioService } from './regras-beneficio.service';
import { CadastroBeneficiario } from './classe/CadastroBeneficiario';
import { Router } from '@angular/router';
import { ErroService } from 'src/app/service/erros/erro.service';

@Component({
  selector: 'app-regras-beneficio',
  templateUrl: './regras-beneficio.component.html',
  styleUrls: ['./regras-beneficio.component.css']
})
export class RegrasBeneficioComponent implements OnInit {

  codigoBeneficio: any;
  cadastrosBeneficiario: CadastroBeneficiario[] = null;
  listaBeneficios: any[] = [];
  listaFilial: any[] = [];
  filtro:any = {
    Codigo: '',
    EmpresaFilial: '',
    Nome: '',
    Vencimento: '',
  }
  constructor(private regrasBeneficioService:RegrasBeneficioService, private router: Router, private validaErro:ErroService) { }

  ngOnInit() {
    this.listarCadastro();
    this.listarBeneficio();
    this.listarFilial();
  }

  listarBeneficio(){
    this.regrasBeneficioService.listarBeneficios().
    subscribe(
      (data:any) => this.listaBeneficios = data,
      error => this.validaErro.retornoErro(error)
    )
  }
  listarFilial(){
    this.regrasBeneficioService.listarFilial().
    subscribe(
      (data:any) => this.listaFilial = data,
      error => this.validaErro.retornoErro(error)
    )
  }
  listarCadastro(filtros: any = null) {
    this.regrasBeneficioService.listarCadastro(filtros)
    .subscribe(
      (data: CadastroBeneficiario[]) => {
        this.cadastrosBeneficiario = data;
      },
      error => this.validaErro.retornoErro(error)
    )
  }
  
  // filtroLista(cadastrosBeneficiario:CadastroBeneficiario[]){
  //   var chavebeneficiario = null;
  //   var cad = [];
	// 	var i = 0;
  //   var ii = 0;
    
  //   for(let cadastro of cadastrosBeneficiario){
  //     let situacao = ''
  //    // const dataAtual:any = (cadastro.DataAtualizacao.substr(0,10)).split('/');
  //    // const dataPrev:any = cadastro.DataInicial.substr(0,10).split('/');
  //    // const dataVenci:any = cadastro.DataFinal.substr(0,10).split('/');

  //     var atu = new Date(dataAtual[2], ((dataAtual[1]*1)-1), dataAtual[0]);
	// 		var ven = new Date(dataVenci[2], ((dataVenci[1]*1)-1), dataVenci[0]);
  //     var prv = new Date(dataPrev[2], ((dataPrev[1]*1)-1), dataPrev[0]);
      
  //     if (atu.getTime() > ven.getTime()){
	// 			situacao = 'V';
	// 		}else if (prv.getTime() > ven.getTime()){
	// 			situacao = 'A';
  //     }

  //     if(this.filtro.Vencimento != ''){
	// 			if(this.filtro.Vencimento != situacao){
	// 				return;
	// 			}
	// 		}
      
	// 		if(chavebeneficiario !=  cadastro.ChaveBeneficiario){
  //       let cpf = ("00000000000" + cadastro.Cpf).substr(-11);
	// 			cpf = cpf.substr(0,3) + '.' + cpf.substr(3,3) + '.' + cpf.substr(6,3) + '-' + cpf.substr(-2);
  //       cad[i] = {
	// 				Cpf: cpf, 
	// 				ChaveBeneficiario: cadastro.ChaveBeneficiario, 
	// 				Anos: cadastro.Anos,
	// 				DataNascimento: cadastro.DataNascimento.substr(0,10),
	// 				NumeroDeOrdem: cadastro.NumeroDeOrdem, 
	// 				Tipo: cadastro.Tipo, 
	// 				Matricula: cadastro.Matricula, 
	// 				FilialBeneficiario: {Codigo: cadastro.FilialBeneficiario.Codigo, NomeEmpfil: cadastro.FilialBeneficiario.NomeEmpfil}, 
	// 				Nome: cadastro.Nome, 
	// 				Beneficio:[
	// 					{
	// 						Codigo: cadastro.Beneficio.Codigo, 
	// 						Descricao: cadastro.Beneficio.Descricao,
	// 					//	DataFinal: cadastro.DataFinal,
	// 						situ: situacao
	// 					}
	// 				]
  //       };
  //       i++;
	// 			ii=0;
  //     }else{
  //       ii++;
	// 			cad[(i-1)].Beneficio[ii] = {
	// 				Codigo: cadastro.Beneficio.Codigo, 
	// 				Descricao: cadastro.Beneficio.Descricao,
	// 			//	DataFinal: cadastro.DataFinal,
	// 				situ: situacao
	// 			};
  //     }
  //     chavebeneficiario =  cadastro.ChaveBeneficiario;



  //   }
  // }
  goToEditar(cadastroBeneficiario: CadastroBeneficiario){
    this.router.navigate([`/beneficios/regras-beneficio/editar/${cadastroBeneficiario.Matricula}`]);
  }
}
