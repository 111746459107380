import { Component, OnInit } from '@angular/core';
import { GrupoBeneficioService } from './grupo-beneficio.service';
// import { Grupo } from '../quadro-beneficios/classe/Grupo';
import { Grupo } from './classes/Grupo';
import { Validators, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ErroService } from 'src/app/service/erros/erro.service';
import Swal from 'sweetalert';
import { Alert } from 'selenium-webdriver';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';

@Component({
  selector: 'app-grupo-beneficio',
  templateUrl: './grupo-beneficio.component.html',
  styleUrls: ['./grupo-beneficio.component.css']
})
export class GrupoBeneficioComponent implements OnInit {
  listaGrupos: Grupo[] = null;
  formGrupos: any;
  desabilitarExcluir: boolean = true;
  validaFormGrupo: boolean = false;
  editar: boolean = false;
  temErro: boolean = false;
  controleQuantidade: boolean = false;

  constructor(private grupoBeneficioService: GrupoBeneficioService, private validaErro:ErroService, private fb: FormBuilder, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.formGrupos = this.fb.group({
      codigo: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      descricao: ['', Validators.compose([Validators.required])],
    });
    this.listarGrupo();
  }

  listarGrupo() {
    this.grupoBeneficioService.listarGrupos()
      .subscribe(
        (data: any) => {
          this.listaGrupos = data;
        },
        error => this.validaErro.retornoErro(error)
      )
  }

  submitGrupo() {
    const formValue = this.formGrupos.getRawValue();
    const listaFiltro = this.listaGrupos.filter(item => { if (item.Codigo == formValue.codigo) return item });

    if (listaFiltro.length > 0 && !this.editar) {
      Swal('Atenção!', "Um item com este código ja foi inserido.", 'warning');
      return false;
    }
    if (this.formGrupos.invalid) {
      Swal('Atenção!', "Preencha todos os campos Obrigatórios.", 'warning');
      this.validaFormGrupo = true;
      return false;
    }

    this.salvarGrupo();
  }

  salvarGrupo() {
    const grupo = this.montarGrupo();

    this.spinner.show();
    this.grupoBeneficioService.salvarGrupo(grupo)
      .subscribe(
        data => {
          this.validaFormGrupo = false;
          this.spinner.hide();
          Swal({
            title: this.editar ? 'Grupo alterado com sucesso.' : 'Grupo salvo com sucesso.',
            text: "",
            icon: 'success',
            closeOnClickOutside: false,
            closeOnEsc: false
          }).then((result) => {
            this.limparCampos();
            this.listarGrupo();
          });


        }, error => {
          this.spinner.hide();
          this.validaErro.retornoErro(error);
        }
      );
  }

  montarGrupo(): Grupo {
    const formValue = this.formGrupos.getRawValue();
    return {
      Codigo: formValue.codigo,
      Descricao: formValue.descricao,
      ControlaQuantidade: this.controleQuantidade
    }
  }

  confirmarExcluir(codigo: string) {
    Swal({
      title: "Deseja realmente excluir o registro?",
      text: "Confirme para executar a exclusão do grupo.",
      icon: "warning",
      dangerMode: false,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true
        },
        confirm: {
          text: "Ok",
          value: true,
          visible: true
        }
      }
    })
      .then(excluir => {
        if (excluir) {
          this.spinner.show();
          this.excluirGrupo(codigo);
          this.spinner.hide();
        }
      });

  }

  excluirGrupo(codigo: string) {
    this.grupoBeneficioService.excluirGrupo(codigo)
      .subscribe(
        data => {
          this.spinner.hide();
          Swal({
            title: 'Grupo excluído com sucesso.',
            text: "",
            icon: 'success',
            closeOnClickOutside: false,
            closeOnEsc: false
          }).then((result) => {
            this.listarGrupo();
          })
        }, error => {
          this.validaErro.retornoErro(error);
        }
      )
  }

  checkControle() {
    this.controleQuantidade = !this.controleQuantidade
  }

  carregarCampos(grupo: Grupo) {
  
    this.limparCampos()
    this.spinner.show()
    setTimeout(() => {
      if (grupo.ControlaQuantidade) {
        let element = <HTMLInputElement>document.getElementById("check-controle")
        element.checked = true
        this.controleQuantidade = grupo.ControlaQuantidade
      }
      this.editar = true;
      this.formGrupos.controls['codigo'].setValue(grupo.Codigo);
      this.formGrupos.controls['descricao'].setValue(grupo.Descricao);
      const codigo = this.formGrupos.get('codigo');
      codigo.disable();
      this.spinner.hide();
    }, 300)
  }

  cancelarEditar() {
    this.editar = false;
    this.limparCampos();
    const codigo = this.formGrupos.get('codigo');
    codigo.enable();
  }

  limparCampos() {
    this.editar = false;
    this.formGrupos.controls['codigo'].setValue('');
    this.formGrupos.controls['descricao'].setValue('');
    const codigo = this.formGrupos.get('codigo');
    codigo.enable();
    let element = <HTMLInputElement>document.getElementById("check-controle");
    element.checked = false;
    this.controleQuantidade = false
  }
}
