import { Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { GraficoBeneficioService } from './grafico-beneficio.service';
import { TotalBeneficios } from './classes/total-beneficios';
import { ErroService } from 'src/app/service/erros/erro.service';
import { BeneficiosVencimento } from './classes/beneficios-vencimento';
import { RegraBeneficioVencimento } from './classes/regra-beneficio-vencimento';
import { TotalPartes } from './classes/total-partes-beneficio';

@Component({
  selector: 'app-grafico-beneficio',
  templateUrl: './grafico-beneficio.component.html',
  styleUrls: ['./grafico-beneficio.component.css']
})
export class GraficoBeneficioComponent implements OnInit {

  graficoBeneficio: EChartsOption;
  graficoTipoBeneficiario: EChartsOption;
  graficoBeneficiario: EChartsOption;
  graficoGrupoBeneficio: EChartsOption;
  graficoBeneficiarioVencimento: EChartsOption;
  graficoRegrasBeneficiosVencimento: EChartsOption;
  graficoBeneficiarioRegrasBenifiosVencimento: EChartsOption;
  graficoTotalPartesBeneficio: EChartsOption;
  dadosGraficoBeneficio: TotalBeneficios;
  dadosGraficoBeneficiosProximoVencer: BeneficiosVencimento[];
  dadosGraficoRegrasBeneficiosProximoVencer: RegraBeneficioVencimento[];
  dadosGraficoBeneficiarioRegrasBeneficiosProximoVencer: RegraBeneficioVencimento[];
  dadosGraficoTotalPartesBeneficio: TotalPartes;
  mesesCalendario: string[] = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
  diasSemana: string[] = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];


  constructor(private graficoBeneficioService: GraficoBeneficioService, private validaErro: ErroService) { }

  ngOnInit() {
    this.obterDadosGraficoBeneficio();
  }

  obterDadosGraficoBeneficio() {
    this.graficoBeneficioService.obterDadosGraficoTotalBeneficiosTotalBeneficiarios()
      .subscribe(
        (data: any) => {
          this.dadosGraficoBeneficio = data;
          this.graficoBeneficio = this.getGraficoBeneficio();
        },
        error => this.validaErro.retornoErro(error)
      );

    this.graficoBeneficioService.obterDadosGraficoBeneficiosProximosVencer()
      .subscribe(
        (data: any) => {
          this.dadosGraficoBeneficiosProximoVencer = data;
          this.graficoBeneficiarioVencimento = this.getGraficoBeneficiarioValidade();
        },
        error => this.validaErro.retornoErro(error)
      );

    this.graficoBeneficioService.obterDadosGraficoRegrasBeneficiosProximosVencer()
      .subscribe(
        (data: any) => {
          this.dadosGraficoRegrasBeneficiosProximoVencer = data;
          this.graficoRegrasBeneficiosVencimento = this.getGraficoRegraBeneficioValidade();
        },
        error => this.validaErro.retornoErro(error)
      );
    let numOrd = 1506; // Remover depois
    this.graficoBeneficioService.obterDadosGraficoBeneficiarioRegrasBeneficiosProximosVencer(numOrd)
      .subscribe(
        (data: any) => {
          this.dadosGraficoBeneficiarioRegrasBeneficiosProximoVencer = data;
          this.graficoBeneficiarioRegrasBenifiosVencimento = this.getGraficoBeneficiarioRegraBeneficioValidade();
        },
        error => this.validaErro.retornoErro(error)
      );

    this.graficoBeneficioService.obterDadosGraficoTotalPartesBeneficio()
      .subscribe(
        (data: any) => {
          this.dadosGraficoTotalPartesBeneficio = data;
          this.graficoTotalPartesBeneficio = this.getGraficoTotalPartesBeneficio();
        }
      )
  }
  getGraficoBeneficio(): EChartsOption {
    return {
      title: {
        text: `Histórico de total de pagamentos`
      },
      legend: {
        data: ['Total Beneficios', 'Beneficiarios'],
        orient: "horizontal"
        //x: 'center'
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          saveAsImage: {
            show: true,
            title: 'Salvar gráfico'
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        type: 'category',
        data: this.dadosGraficoBeneficio.Competencias
      },
      yAxis: [
        // Total beneficios
        {
          type: 'value'
        },
        // Beneficiarios
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Total Beneficios',
         // data: this.dadosGraficoBeneficio.TotalBeneficios,
          type: 'bar',
          showBackground: true,
          color: 'rgba(255, 170, 0, 1)'
        },
        {
          name: 'Beneficiarios',
         // data: this.dadosGraficoBeneficio.TotalBeneficiarios,
          type: 'line',
          yAxisIndex: 1,
          color: 'rgba(0, 0, 0, 1)'
        }
      ]
    };
  }

  //getTipoBeneficiarioChart() {
  //  let option: EChartsOption;
  //  option = {
  //    tooltip: {
  //      trigger: 'axis',
  //      axisPointer: {
  //        type: 'shadow'
  //      }
  //    },
  //    legend: {
  //      data: ['FUNCIONARIO', 'DEPENDENTE', 'PENSAO ALIMENTO']
  //    },
  //    toolbox: {
  //      show: true,
  //      orient: 'vertical',
  //      left: 'right',
  //      top: 'center',
  //      feature: {
  //        mark: { show: true },
  //        dataView: { show: true, readOnly: false },
  //        magicType: { show: true, type: ['line', 'bar', 'stack'] },
  //        restore: { show: true },
  //        saveAsImage: { show: true }
  //      }
  //    },
  //    xAxis: [
  //      {
  //        type: 'category',
  //        axisTick: { show: false },
  //        data: ['03/2022', '04/2022', '05/2022']
  //      }
  //    ],
  //    yAxis: [
  //      {
  //        type: 'value'
  //      }
  //    ],
  //    series: [
  //      {
  //        name: 'FUNCIONARIO',
  //        type: 'bar',
  //        barGap: 0,
  //        //label: labelOption,
  //        emphasis: {
  //          focus: 'series'
  //        },
  //        data: [1, 2, 1]
  //      },
  //      {
  //        name: 'DEPENDENTE',
  //        type: 'bar',
  //        //label: labelOption,
  //        emphasis: {
  //          focus: 'series'
  //        },
  //        data: [1, 2, 2]
  //      },
  //      {
  //        name: 'PENSAO ALIMENTO',
  //        type: 'bar',
  //        emphasis: {
  //          focus: 'series'
  //        },
  //        data: [1, 1, 1]
  //      }
  //    ]
  //  };
  //  return option;
  //}

  //getGraficoBeneficiario() {
  //  return {
  //    title: {
  //      text: 'Paulo Roberto'
  //    },
  //    legend: {
  //      data: ['Total Beneficios'],
  //      orient: "horizontal",
  //      x: 'center'
  //    },
  //    toolbox: {
  //      show: true,
  //      orient: 'vertical',
  //      left: 'right',
  //      top: 'center',
  //      feature: {
  //        saveAsImage: {
  //          show: true,
  //          title: 'Salvar gráfico'
  //        }
  //      }
  //    },
  //    xAxis: {
  //      type: 'category',
  //      data: ['03/2022', '04/2022', '05/2022']
  //    },
  //    yAxis: [
  //      // Total beneficios
  //      {
  //        type: 'value'
  //      }
  //    ],
  //    series: [
  //      {
  //        name: 'Total Beneficios',
  //        data: [120, 200, 150],
  //        type: 'line',
  //        showBackground: true,
  //        color: 'rgba(255, 170, 0, 1)'
  //      }
  //    ]
  //  };
  //}

  //getGraficoGrupoBeneficio() {
  //  return {
  //    title: {
  //      text: 'Grupo beneficios'
  //    },
  //    dataset: [
  //      {
  //        source: [
  //          ['Grupo', 'Quantidade', 'Competencia'],
  //          ['VALE REFEICAO/ALIMENTACAO/COMBUSTIVEL', 1, '03/2022'],
  //          ['PLANOS/SEGUROS SAUDE', 1, '03/2022'],
  //          ['VALES TRANSPORTE/PASSAGENS/BILHETES', 1, '03/2022'],
  //          ['Outros', 1, '03/2022'],
  //          ['VALE REFEICAO/ALIMENTACAO/COMBUSTIVEL', 2, '04/2022'],
  //          ['PLANOS/SEGUROS SAUDE', 1, '04/2022'],
  //          ['VALES TRANSPORTE/PASSAGENS/BILHETES', 1, '04/2022'],
  //          ['Outros', 2, '04/2022'],
  //          ['VALE REFEICAO/ALIMENTACAO/COMBUSTIVEL', 2, '05/2022'],
  //          ['PLANOS/SEGUROS SAUDE', 2, '05/2022'],
  //          ['VALES TRANSPORTE/PASSAGENS/BILHETES', 1, '05/2022'],
  //          ['Outros', 1, '05/2022']
  //        ]
  //      }
  //    ],
  //    xAxis: { type: 'category' },
  //    yAxis: {},
  //    series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
  //  }

  //}

  getGraficoBeneficiarioValidade() {
    let datas = [];
    let periodo = []
    let dados = [];

    dados = this.formatarDadosCalendario();
    periodo = this.gerarPeriodo(4);
    //periodo = ['2022-11-01', '2023-02-28'];
   
    datas = this.gerarDatasCalendario(periodo);
    let option: EChartsOption = {
      title: {
        text: 'Benefícios próximos de vencer'
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          saveAsImage: {
            show: true,
            title: 'Salvar gráfico'
          }
        }
      },
      tooltip: {
        formatter: function (params: any) {
          return params.value[2]
        }
      },
      calendar: {
        top: 'middle',
        left: 'center',
        orient: 'horizontal',
        cellSize: 35,
        yearLabel: {
          margin: 50,
          fontSize: 30
        },
        monthLabel: {
          nameMap: this.mesesCalendario,
          margin: 15
        },
        range: periodo,//['2022-11-01','2023-02-28'],
        dayLabel: {
          nameMap: this.diasSemana,
          firstDay: 0
        }
      },
      series: [
        {
          type: 'scatter',
          coordinateSystem: 'calendar',
          data: dados,
          //color: 'rgba(255, 170, 0, 1)'
        },
        {
          type: 'scatter',
          coordinateSystem: 'calendar',
          symbolSize: 1,
          label: {
            show: true,
            formatter: function (params: any) {
              var d = echarts.number.parseDate(params.value[0]);
              return d.getDate().toLocaleString();
            },
            color: '#000'
          },
          data: datas
        }
      ]
    }
    return option;
  }

  formatarDadosCalendario() {
    let dados = [];
    this.dadosGraficoBeneficiosProximoVencer.forEach((item: BeneficiosVencimento) => {
      let d = [];
      d.push(item.DataVencimento);
      d.push(item.CodigoBeneficio);
      d.push(item.NomeBeneficio);
      dados.push(d);
    });
    return dados;
  }

  getGraficoRegraBeneficioValidade() {

    let dados = [];
    let periodo = [];
    let datas = [];

    periodo = this.gerarPeriodo(5);
    datas = [] = this.gerarDatasCalendario(periodo);
    dados = this.formatarDadosCalendarioRegrasBeneficiosVencimentos(this.dadosGraficoRegrasBeneficiosProximoVencer);

    let option: EChartsOption = {
      title: {
        text: 'Regras de benefício próximos de vencer'
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          saveAsImage: {
            show: true,
            title: 'Salvar gráfico'
          }
        }
      },
      tooltip: {
        formatter: function (params: any) {
          return `${params.value[3]}: ${params.value[2]}`;
        }
      },
      calendar: {
        top: 'middle',
        left: 'center',
        orient: 'horizontal',
        cellSize: 35,
        yearLabel: {
          margin: 50,
          fontSize: 30
        },
        monthLabel: {
          nameMap: this.mesesCalendario,
          margin: 15
        },
        range: periodo,//['2022-11-01', '2023-02-28'],
        dayLabel: {
          nameMap: this.diasSemana,
          firstDay: 0
        }
      },
      series: [
        {
          type: 'scatter',
          coordinateSystem: 'calendar',
          data: dados,
          //color: 'rgba(255, 170, 0, 1)'
        },
        {
          type: 'scatter',
          coordinateSystem: 'calendar',
          symbolSize: 1,
          label: {
            show: true,
            formatter: function (params: any) {
              var d = echarts.number.parseDate(params.value[0]);
              return d.getDate().toLocaleString();
            },
            color: '#000'
          },
          data: datas
        }

      ]
    }
    return option;
  }

  getGraficoBeneficiarioRegraBeneficioValidade() {

    let dados = [];
    let periodo = [];
    let datas = [];

    periodo = this.gerarPeriodo(5);
    datas = [] = this.gerarDatasCalendario(periodo);
    dados = this.formatarDadosCalendarioRegrasBeneficiosVencimentos(this.dadosGraficoBeneficiarioRegrasBeneficiosProximoVencer);
    let nomeColaborador = "";

    if (this.dadosGraficoBeneficiarioRegrasBeneficiosProximoVencer.length > 0) {
      nomeColaborador = this.dadosGraficoBeneficiarioRegrasBeneficiosProximoVencer[0].NomeBeneficiario;
    }
    let option: EChartsOption = {
      title: {
        text: `${nomeColaborador}: Benefícios próximos de vencer`
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          saveAsImage: {
            show: true,
            title: 'Salvar gráfico'
          }
        }
      },
      tooltip: {
        formatter: function (params: any) {
          return `${params.value[3]}: ${params.value[2]}`;
        }
      },
      calendar: {
        top: 'middle',
        left: 'center',
        orient: 'horizontal',
        cellSize: 35,
        yearLabel: {
          margin: 50,
          fontSize: 30
        },
        monthLabel: {
          nameMap: this.mesesCalendario,
          margin: 15
        },
        range: periodo,
        dayLabel: {
          nameMap: this.diasSemana,
          firstDay: 0
        }
      },
      series: [
        {
          type: 'scatter',
          coordinateSystem: 'calendar',
          data: dados,
          //color: 'rgba(255, 170, 0, 1)'
        },
        {
          type: 'scatter',
          coordinateSystem: 'calendar',
          symbolSize: 1,
          label: {
            show: true,
            formatter: function (params: any) {
              var d = echarts.number.parseDate(params.value[0]);
              return d.getDate().toLocaleString();
            },
            color: '#000'
          },
          data: datas
        }

      ]
    }
    return option;
  }

  formatarDadosCalendarioRegrasBeneficiosVencimentos(data: RegraBeneficioVencimento[]) {
    let dados = [];
    data.forEach((item: RegraBeneficioVencimento) => {
      let d = [];
      d.push(item.DataVencimento);
      d.push(item.CodigoBeneficio);
      d.push(item.NomeBeneficio);
      d.push(item.NomeBeneficiario);
      dados.push(d);
    });
    return dados;
  }

  gerarPeriodo(meses: number) {
    let dataAtual = new Date();
    let periodo = [];

    let mes: string;
    let ano: string;

    mes = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
    ano = dataAtual.getFullYear().toString().padStart(4, '0');

    let dataString = `${ano}-${mes}-01`;
    let data = echarts.number.parseDate(dataString);

    data.setMonth(data.getMonth() + meses);
    data.setDate(data.getDate() - 1);

    periodo.push(dataString);
    periodo.push(echarts.format.formatTime('yyyy-MM-dd', data));

    return periodo;
  }

  gerarDatasCalendario(periodo: any[]) {

    let dataString = echarts.number.parseDate(periodo[1]);
    let data = echarts.number.parseDate(periodo[0]);

    let dataFinalNumber = Number(echarts.format.formatTime('yyyyMMdd', dataString));
    let dataNumber = Number(echarts.format.formatTime('yyyyMMdd', data));

    let datas = [];

    do {
      datas.push([echarts.format.formatTime('yyyy-MM-dd', data), 1]); // Valor 1 é adicionado para o objeto ser aceito pelo EChart
      data.setDate(data.getDate() + 1);
      dataNumber = Number(echarts.format.formatTime('yyyyMMdd', data));
    } while (dataNumber <= dataFinalNumber);

    return datas;
  }

  getGraficoTotalPartesBeneficio(): EChartsOption {
    let dataAtual = new Date();
    return {
      title: {
        text: `Total por partes - Competência: ${echarts.format.formatTime('MM/yyyy', dataAtual)}`
      },
      legend: {
        data: ['EMPRESA', 'FUNCIONARIO'],
        orient: "horizontal"
        //x: 'center'
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          saveAsImage: {
            show: true,
            title: 'Salvar gráfico'
          }
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        type: 'category',
        data: this.dadosGraficoTotalPartesBeneficio.TipoParte
      },
      yAxis: [
        // Total beneficios
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'Total Beneficios',
          //data: this.dadosGraficoTotalPartesBeneficio.TotalParte,
          type: 'bar',
          showBackground: true,
          color: 'rgba(255, 170, 0, 1)'
        }
      ]
    };
  }
}
