<div class="modal-header">
  <h3 class="modal-title">{{ cadastro ? 'Incluir' : 'Atualizar' }} Formações</h3>
  <button type="button" class="close" id="botaoFechar" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="formFormacoes" class="modal-body row">
  <div class="form-group col-4">
    <label for="CodCrs" class="font-weigth-semibold">Curso</label>
    <select
      class="form-control"
      id="CodCrs"
      formControlName="CodCrs"
      [ngClass]="
        (formFormacoes.get('CodCrs').invalid || formFormacoes.get('CodCrs').value == null) &&
        formFormacoes.get('CodCrs').touched
          ? 'is-invalid'
          : null
      "
    >
      <option value="">Selecione uma opção</option>
      <option *ngFor="let item of listaCursos" [value]="item.Codigo">
        {{ item.Codigo }} - {{ item.Descricao }}
      </option>
    </select>
    <span
      class="form-text text-danger"
      *ngIf="
        (formFormacoes.get('CodCrs').invalid || formFormacoes.get('CodCrs').value == null) &&
        formFormacoes.get('CodCrs').touched
      "
    >
      Campo Obrigatório
    </span>
  </div>
  <div class="form-group col-4">
    <label for="DtInicio" class="font-weigth-semibold">Data Início</label>
    <input
      type="date"
      max="9999-12-31"
      class="form-control"
      id="DtInicio"
      formControlName="DtInicio"
      [ngClass]="
        (formFormacoes.get('DtInicio').invalid || formFormacoes.get('DtInicio').value == null) &&
        formFormacoes.get('DtInicio').touched
          ? 'is-invalid'
          : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="
        (formFormacoes.get('DtInicio').invalid || formFormacoes.get('DtInicio').value == null) &&
        formFormacoes.get('DtInicio').touched
      "
    >
      {{
        formFormacoes.get('DtInicio').hasError('data')
          ? 'Data deve ser menor que data atual'
          : 'Campo Obrigatório'
      }}
    </span>
  </div>
  <div class="form-group col-4">
    <label for="DtTermino" class="font-weigth-semibold">Data Volta</label>
    <input
      type="date"
      max="9999-12-31"
      class="form-control"
      id="DtTermino"
      formControlName="DtTermino"
      [ngClass]="
        (formFormacoes.get('DtTermino').invalid || formFormacoes.get('DtTermino').value == null) &&
        formFormacoes.get('DtTermino').touched
          ? 'is-invalid'
          : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="
        (formFormacoes.get('DtTermino').invalid || formFormacoes.get('DtTermino').value == null) &&
        formFormacoes.get('DtTermino').touched
      "
    >
      {{
        formFormacoes.get('DtTermino').hasError('data')
          ? 'Data deve ser menor que data atual'
          : 'Campo Obrigatório'
      }}
    </span>
  </div>

  <div class="form-group col-4">
    <label for="Quadro" class="font-weigth-semibold">Quadro <small>(opcional)</small></label>
    <input
      type="number"
      class="form-control"
      id="Quadro"
      formControlName="Quadro"
      placeholder="000"
    />
  </div>
  <div class="form-group col-4">
    <label for="NumPos" class="font-weigth-semibold">Num. Pos. <small>(opcional)</small></label>
    <input
      type="number"
      class="form-control"
      id="NumPos"
      formControlName="NumPos"
      placeholder="000"
    />
  </div>
  <div class="form-group col-4">
    <label for="Conceito" class="font-weigth-semibold">Conceito <small>(opcional)</small></label>
    <select class="form-control" id="Conceito" formControlName="Conceito">
      <option value="">Selecione uma opção</option>
      <option *ngFor="let item of listaConceitos" [value]="item.Codigo">
        {{ item.Descricao }}
      </option>
    </select>
  </div>

  <div class="form-group col-5">
    <label for="Entidade" class="font-weigth-semibold">Entidade <small>(opcional)</small></label>
    <input
      type="text"
      class="form-control"
      id="Entidade"
      formControlName="Entidade"
      placeholder="Entidade"
      maxlength="50"
    />
  </div>
  <div class="form-group col-4">
    <label for="CargHor" class="font-weigth-semibold"
      >Carga Horária <small>(opcional)</small></label
    >
    <input
      type="number"
      class="form-control"
      id="CargHor"
      formControlName="CargHor"
      placeholder="000"
    />
  </div>
  <div class="form-group col-3">
    <label for="Nota" class="font-weigth-semibold">Nota <small>(opcional)</small></label>
    <input type="number" class="form-control" id="Nota" formControlName="Nota" placeholder="000" />
  </div>

  <div class="form-group col-6">
    <label for="Obs1" class="font-weigth-semibold">Observação 1 <small>(opcional)</small></label>
    <input
      type="text"
      class="form-control"
      id="Obs1"
      formControlName="Obs1"
      placeholder="Observação"
      maxlength="70"
    />
  </div>
  <div class="form-group col-6">
    <label for="Obs2" class="font-weigth-semibold">Observação 2 <small>(opcional)</small></label>
    <input
      type="text"
      class="form-control"
      id="Obs2"
      formControlName="Obs2"
      placeholder="Observação"
      maxlength="70"
    />
  </div>
</form>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="cadastro ? lancarFormacao() : lancarFormacao(formacaoAtual)"
  >
    {{ cadastro ? 'Cadastrar' : 'Atualizar' }}
  </button>
</div>
