<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<app-pesquisar-matricula
  *ngIf="!dadosComplemento"
  [label]="'Matrícula'"
  [titulo]="'Complemento Cadastral - ' + tipoComplemento"
  [inputText]="true"
  [estagio]="tipoComplemento == 'Estágio' ? true : false"
  (colaboradorEmitter)="matriculaListener($event)"
></app-pesquisar-matricula>

<section *ngIf="dadosComplemento">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title font-weight-semibold">
        <i
          class="fa fa-arrow-left pr-3 btn"
          aria-hidden="true"
          (click)="retornarTelaInicial()"
          style="cursor: pointer; bottom: 2px"
        ></i
        >Complemento Cadastral - {{ tipoComplemento }}
      </h3>
    </div>
    <div class="card-body">
      <form #colaborador="ngForm" class="row" [formGroup]="formColaborador">
        <div class="col-md-2 form-group">
          <label class="font-weight-semibold">Matrícula</label>

          <input
            type="text"
            class="form-control"
            placeholder="Matrícula"
            mask="0*"
            formControlName="matricula"
          />

          <span class="form-text" *ngIf="loading"
            >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
          ></span>
        </div>

        <div class="col-md-10 form-group">
          <label class="font-weight-semibold">Nome do Colaborador</label>
          <input type="text" class="form-control" placeholder="Nome" formControlName="nome" />
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="!loading" class="card d-flex justify-content-center">
    <div class="card-header d-inline-flex justify-content-between">
      <h3 class="card-title font-weight-semibold">{{ tipoComplemento }}</h3>
      <button
        *ngIf="
          outrosContratos == 0 &&
          ((tipoComplemento == 'Aprendiz' &&
            (listaAprendiz.length == 0 || !compararDatas(listaAprendiz[0].DataFinal.SetData))) ||
            (tipoComplemento == 'Cessão' &&
              (listaCessao.length == 0 || !compararDatas(listaCessao[0].DataFinal.SetData))) ||
            (tipoComplemento == 'Estágio' &&
              (listaEstagio.length == 0 || !compararDatas(listaEstagio[0].DataFinal.SetData))) ||
            condicoesContratoExperiencia())
        "
        type="button"
        class="btn btn-outline-primary"
        (click)="adicionarComplemento()"
      >
        <i class="fa fa-plus"></i> Adicionar Complemento
      </button>
    </div>
    <div class="card-body table-responsive mt-2">
      <table *ngIf="listaAprendiz.length > 0" class="table table-striped border-blue text-center">
        <thead class="bg-teal-400">
          <tr>
            <th>Nº Processo</th>
            <th>Data Inicial</th>
            <th>Data Final</th>
            <th>Valor da Bolsa</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let aprendizLista of listaAprendiz; let i = index">
            <td>{{ aprendizLista.NumeroProcesso }}</td>
            <td>{{ aprendizLista.DataInicial.ToDate }}</td>
            <td>{{ aprendizLista.DataFinal.ToDate }}</td>
            <td>{{ aprendiz.Nivel.Valor }}</td>
            <td class="d-flex justify-content-center">
              <button
                type="button"
                *ngIf="i === 0 && compararDatas(aprendizLista.DataFinal.SetData)"
                (click)="moverParaAprendizCadastro(true, false, aprendizLista)"
                class="btn btn-outline-primary mr-2"
              >
                <i class="fa fa-pencil"></i>
              </button>
              <button
                type="button"
                (click)="moverParaAprendizCadastro(true, true, aprendizLista)"
                class="btn btn-outline-info"
              >
                <i class="fa fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <table *ngIf="listaCessao.length > 0" class="table table-striped border-blue text-center">
        <thead class="bg-teal-400">
          <tr>
            <th>Nº Processo</th>
            <th>Data Admissão Cedente</th>
            <th>Data Inicial</th>
            <th>Data Final</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let c of listaCessao; let i = index">
            <td>{{ c.NumeroProcesso }}</td>
            <td>{{ c.DataAdmissaoCedente.ToDate }}</td>
            <td>{{ c.DataInicial.ToDate }}</td>
            <td>{{ c.DataFinal.ToDate }}</td>
            <td class="d-flex justify-content-center">
              <button
                type="button"
                (click)="moverParaCessaoCadastro(true, false, c)"
                class="btn btn-outline-primary mr-2"
              >
                <i class="fa fa-pencil"></i>
              </button>
              <!-- APP MODAL -->
              <button
                type="button"
                [ngClass]="{
                  'btn-outline-primary': c.Obs === 'N',
                  'btn-primary': c.Obs === 'S'
                }"
                class="btn mr-2"
                data-toggle="modal"
                data-target="#observacaoCompCadastroModal"
                (click)="modalObservacoes.abrirModal({ cessao: cessao, contrato: c })"
              >
                <i class="fa-solid fa-book fa-lg"></i>
              </button>
              <button
                type="button"
                [ngClass]="{
                  'btn-outline-primary': c.Doc === 'N',
                  'btn-primary': c.Doc === 'S'
                }"
                class="btn mr-2"
                data-toggle="modal"
                data-target="#anexoCompCadastroModal"
                (click)="modalAnexo.abrirModal({ cessao: cessao, contrato: c })"
              >
                <i class="fa fa-file"></i>
              </button>
              <button
                type="button"
                (click)="moverParaCessaoCadastro(true, true, c)"
                class="btn btn-outline-info"
              >
                <i class="fa fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <table *ngIf="listaEstagio.length > 0" class="table table-striped border-blue text-center">
        <thead class="bg-teal-400">
          <tr>
            <th>Nº Processo</th>
            <th>Data Inicial</th>
            <th>Data Final</th>
            <th>Valor da Bolsa</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let e of listaEstagio; let i = index">
            <td>{{ e.NumeroProcesso }}</td>
            <td>{{ e.DataInicial.ToDate }}</td>
            <td>{{ e.DataFinal.ToDate }}</td>
            <td>R$ {{ e.ValorBolsa.Valor }}</td>
            <td class="d-flex justify-content-center">
              <button
                type="button"
                *ngIf="i === 0 && compararDatas(e.DataFinal.SetData)"
                (click)="moverParaEstagioCadastro(true, false, e)"
                class="btn btn-outline-primary mr-2"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i class="fa fa-pencil"></i>
              </button>
              <button
                type="button"
                [ngClass]="{
                  'btn-outline-primary': e.Obs === 'N',
                  'btn-primary': e.Obs === 'S'
                }"
                class="btn mr-2"
                data-toggle="modal"
                data-target="#observacaoCompCadastroModal"
                (click)="modalObservacoes.abrirModal({ estagiario: estagio, contrato: e })"
              >
                <i class="fa-solid fa-book fa-lg"></i>
              </button>
              <button
                type="button"
                [ngClass]="{
                  'btn-outline-primary': e.Doc === 'N',
                  'btn-primary': e.Doc === 'S'
                }"
                class="btn mr-2"
                data-toggle="modal"
                data-target="#anexoCompCadastroModal"
                (click)="modalAnexo.abrirModal({ estagiario: estagio, contrato: e })"
              >
                <i class="fa fa-file"></i>
              </button>
              <button
                type="button"
                (click)="moverParaEstagioCadastro(true, true, e)"
                class="btn btn-outline-info"
              >
                <i class="fa fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <table
        *ngIf="listaContratoExperiencia.length > 0"
        class="table table-striped border-blue text-center"
      >
        <thead class="bg-teal-400">
          <tr>
            <th>Setor</th>
            <th>Cargo</th>
            <th>Nível</th>
            <th>Data Inicial</th>
            <th>Data Final</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let contrato of listaContratoExperiencia; let i = index">
            <td>{{ contrato.Setor }}</td>
            <td>{{ contrato.Cargo }}</td>
            <td>{{ contrato.Nivel }}</td>
            <td>{{ contrato.DataInicial.ToDate }}</td>
            <td>{{ contrato.DataFinal.ToDate }}</td>
            <td class="d-flex justify-content-center">
              <button
                type="button"
                *ngIf="i === 0 && compararDatas(contrato.DataFinal.SetData)"
                (click)="moverParaContratoCadastro(true, false, contrato)"
                class="btn btn-outline-primary mr-2"
              >
                <i class="fa fa-pencil"></i>
              </button>
              <button
                type="button"
                (click)="moverParaContratoCadastro(true, true, contrato)"
                class="btn btn-outline-info"
              >
                <i class="fa fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        *ngIf="
          listaAprendiz.length === 0 &&
          listaCessao.length === 0 &&
          listaEstagio.length === 0 &&
          listaContratoExperiencia.length === 0
        "
        class="p-4 d-flex justify-content-center align-items-center"
      >
        <h6 class="font-weight-semibold">
          Não há dados do complemento atual para esse colaborador.
          <ng-container *ngIf="outrosContratos > 0"
            >Esse colaborador possui outros contratos de complementos cadastrais.</ng-container
          >
        </h6>
      </div>
    </div>
  </div>
</section>
<ng-container *ngIf="tipoAnexoSelecionado && tipoObsSelecionado">
  <!-- OBSERVACAO -->
  <div class="modal fade" id="observacaoCompCadastroModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <app-observacoes-ficha-funcional
          [tipo]="tipoObsSelecionado"
          (gatilhoAtivado)="atualizarContratos()"
          [colaborador]="{ Matricula: matricula }"
        >
        </app-observacoes-ficha-funcional>
      </div>
    </div>
  </div>
  <!-- ANEXO -->
  <div class="modal fade" id="anexoCompCadastroModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <app-anexo
          [pagina]="tipoAnexoSelecionado"
          (gatilhoAtivado)="atualizarContratos()"
          [matricula]="matricula"
          [enviarSeparadamente]="true"
          [modal]="true"
        ></app-anexo>
      </div>
    </div>
  </div>
  <!-- FIM ESTAGIO -->
</ng-container>
