<!-- botões -->
<div class="d-flex justify-content-between">
  <p class="lead text-uppercase text-muted ml-3">{{ tipoUsuario === '1' ? 'Seu' : '' }} Endereço</p>
  <div class="btn-group">
    <ng-container *ngFor="let botao of botoes">
      <button
        class="btn"
        [class.btn-outline-warning]="botao.color === 'warning'"
        [class.btn-outline-danger]="botao.color === 'danger'"
        [class.btn-outline-primary]="botao.color === 'primary'"
        [class.btn-sm]="true"
        [class.mx-2]="botao.label === 'Salvar'"
        [class.mr-2]="botao.label === 'Editar'"
        type="botao"
        *ngIf="botao.show()"
        (click)="botao.action()"
      >
        <i *ngIf="botao.icon" class="fa {{ botao.icon }}"></i
        >{{ botao.label === 'Editar' ? '&nbsp;' : '' }}
        <span>{{ botao.label }}</span>
      </button>
    </ng-container>
  </div>
</div>
<hr />
<!-- formulário -->
<div *ngIf="displayEndereco && !loading">
  <form #endereco="ngForm" [formGroup]="formEndereco" autocomplete="off" class="form-group">
    <div class="row">
      <ng-container *ngFor="let campo of camposEndereco">
        <div class="form-group col-xl-{{ campo.tamanho }}">
          <label class="text-muted font-weight-bold">{{ campo.label }}</label>
          <a
            href="https://buscacepinter.correios.com.br/app/endereco/index.php"
            target="_blank"
            class="btn-link text-primary ml-1"
            data-toggle="tooltip"
            data-placement="left"
            title="Não sabe seu CEP?"
            *ngIf="campo.nome === 'cep'"
          >
            <i class="fa fa-envelope-open-o"></i>
          </a>
          <input
            type="text"
            name="{{ campo.nome }}"
            placeholder="{{ campo.placeholder }}"
            formControlName="{{ campo.nomeControle }}"
            class="form-control"
            [ngStyle]="styleControleFormulario(formEndereco.controls[campo.nomeControle])"
            *ngIf="campo.nome === 'cep'; else noMaskKey"
            (keyup)="onCepChange()"
            mask="00000-000"
          />
          <ng-template #noMaskKey>
            <input
              (input)="campo.nome == 'complemento' ? uppercase($event) : ''"
              type="text"
              name="{{ campo.nome }}"
              placeholder="{{ campo.placeholder }}"
              formControlName="{{ campo.nomeControle }}"
              class="form-control"
              [ngStyle]="styleControleFormulario(formEndereco.controls[campo.nomeControle])"
            />
          </ng-template>
          <span
            class="form-text text-danger"
            *ngIf="campoInvalido(formEndereco.controls[campo.nomeControle]) || !cepValido"
          >
            {{ !cepValido && campo.nome === 'cep' ? 'Cep inválido' : 'Campo obrigatório' }}
          </span>
        </div>
      </ng-container>
    </div>
  </form>
</div>
<!-- tabela -->
<div *ngIf="!displayEndereco && !loading" class="table-responsive shadow-sm">
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="text-muted" *ngFor="let coluna of colunas">{{ coluna.label }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td *ngFor="let coluna of colunas">
          {{ valorColuna(coluna.key) != '' ? valorColuna(coluna.key) : 'NÃO INFORMADO' }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="d-flex justify-content-between mt-3">
  <p class="lead text-uppercase text-muted ml-3">{{ tipoUsuario === '1' ? 'Seu' : '' }} Telefone</p>
  <div class="btn-group">
    <ng-container *ngFor="let botao of botoesTelefone">
      <button
        class="btn"
        [class.btn-outline-warning]="botao.color === 'warning'"
        [class.btn-outline-danger]="botao.color === 'danger'"
        [class.btn-outline-primary]="botao.color === 'primary'"
        [class.btn-sm]="true"
        [class.mx-2]="botao.label === 'Salvar'"
        [class.mr-2]="botao.label === 'Editar'"
        type="botao"
        *ngIf="botao.show()"
        (click)="botao.action()"
      >
        <i *ngIf="botao.icon" class="fa {{ botao.icon }}"></i
        >{{ botao.label === 'Editar' ? '&nbsp;' : '' }}
        <span>{{ botao.label }}</span>
      </button>
    </ng-container>
  </div>
</div>
<div>
  <div class="form-group">
    <div class="form-group col-12 border-bottom mt-1 pb-2 mb-2"></div>
  </div>
  <form [formGroup]="formContato">
    <div class="form-group col-sm-12 col-md-12 col-lg-12 group-phones">
      <label
        class="d-flex justify-content-between"
        *ngIf="listaContatosSecundarios && listaContatosSecundarios.length > 0"
      >
        <span>Telefone</span>
        <small class="text-muted">Descrição e Nº telefônico com DDD</small>
      </label>
      <div class="input-group group-phone mb-3" *ngIf="displayContato">
        <select class="form-control mr-2" formControlName="tipoSecundario">
          <option value="">Selecione o tipo de contato</option>
          <option value="Celular">Celular</option>
          <option value="Residencial">Residencial (fixo)</option>
          <option value="Comercial">Comercial (fixo)</option>
        </select>
        <input
          autocomplete="off"
          type="text"
          formControlName="celularSecundario"
          class="form-control telefone"
          inputmode="numeric"
          name="telefone[]"
          placeholder="(00) 00000-0000"
          mask
          telefoneMask
        />
      </div>
      <div
        class="text-center"
        *ngIf="
          !displayContato &&
          (!listaContatosSecundarios ||
            (listaContatosSecundarios && listaContatosSecundarios.length === 0))
        "
      >
        Nenhum contato encontrado
      </div>
      <div
        class="input-group group-phone mb-3"
        *ngFor="let contato of listaContatosSecundarios; let i = index"
      >
        <input
          value="{{ contato.Tipo ? contato.Tipo : 'NÃO INFORMADO' }}"
          type="text"
          class="form-control tipo"
          readonly
        />
        <input
          value="{{ formatarNumero(contato.DDD + contato.Telefone) }}"
          type="text"
          class="form-control telefone"
          readonly
        />
        <div class="input-group-append" *ngIf="displayContato">
          <button
            type="button"
            class="btn btn-outline-secondary btn-remove-phone"
            data-toggle="tooltip"
            data-placement="left"
            title="Clique para remover"
            (click)="removerContatoSecundario(contato)"
          >
            <i class="fa fa-minus"></i>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-end" *ngIf="displayContato">
        <button
          type="button"
          class="mb-2 btn btn-primary btn-sm btn-add-phone"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Clique para adicionar"
          (click)="adicionarContatoSecundario()"
        >
          <span class="mr-1">Adicionar Contato</span>
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
  </form>
</div>
<!-- spinner -->
<div *ngIf="loading" class="row d-flex justify-content-center align-items-center">
  <i class="fa fa-refresh fa-spin fa"></i>
</div>
