import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable ({
    providedIn: 'root',
})

export class CadastroWhatsappService {

    localStorage: LocalStorage = new LocalStorage();
    headers = new HttpHeaders();
    url_acesso: string;
    cabecalho: any;
    filial: string;
    




    constructor(private http: HttpClient, private acesso: AcessoService) {
        this.url_acesso = this.acesso.validarUrl();
    
        if (localStorage.getItem('util') != null) {
          this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
          this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
    
          this.filial = this.localStorage.FilialAtual.toString();
        }
      }


    buscarColaboradorMatricula(matricula: string) {
        return this.http.get(`${this.url_acesso}cadastro-todos-matricula/${matricula}/${this.filial}`, {
          headers: this.cabecalho,
        });
      }
      
      buscarCadastroWhatsapp() {
        return this.http.get(`${this.url_acesso}whatsapp/cadastro`, {
          headers: this.cabecalho,
        });
      }
    

      lancarCadastroWhatsapp(cadastroDados: { NumMovel: string; email: string }) {
        return this.http.post(
          `${this.url_acesso}whatsapp/cadastro`,
          cadastroDados, 
          {
            headers: this.cabecalho,
          }
        );
      }


      atualizarCadastroWhatsapp(numOrd: number, cadastroDados: { NumMovel: string; email: string }) {
        return this.http.put(
          `${this.url_acesso}whatsapp/cadastro/${numOrd}`, 
          cadastroDados, 
          { headers: this.cabecalho }
        );
    }
    

}


