import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { Experiencia } from './experiencia-profissional.component';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExperienciaProfissionalService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  url_acesso: string;
  cabecalho: any;
  filial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl();

    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  buscarExperiencias(numord: string) {
    return this.http.get(`${this.url_acesso}experiencia-profissional/obter/${numord}`, {
      headers: this.cabecalho,
    });
  }

  buscarCursos() {
    return this.http.get(`${this.url_acesso}experiencia-profissional/listar-cursos`, {
      headers: this.cabecalho,
    });
  }

  buscarHabilidades() {
    return this.http.get(`${this.url_acesso}experiencia-profissional/listar-habilidades`, {
      headers: this.cabecalho,
    });
  }

  buscarFuncoes() {
    return this.http.get(`${this.url_acesso}experiencia-profissional/listar-funcoes`, {
      headers: this.cabecalho,
    });
  }

  buscarRequisitos() {
    return this.http.get(`${this.url_acesso}experiencia-profissional/listar-requisitos`, {
      headers: this.cabecalho,
    });
  }

  buscarAtividades() {
    return this.http.get(`${this.url_acesso}experiencia-profissional/listar-atividades`, {
      headers: this.cabecalho,
    });
  }

  buscarAvaliacoes() {
    return this.http.get(`${this.url_acesso}experiencia-profissional/listar-avaliacoes`, {
      headers: this.cabecalho,
    });
  }

  buscarTodasListagens(numord: string ) {
    return forkJoin([
      this.buscarExperiencias(numord),
      this.buscarCursos(),
      this.buscarHabilidades(),
      this.buscarFuncoes(),
      this.buscarRequisitos(),
      this.buscarAtividades(),
      this.buscarAvaliacoes(),
    ]);
  }

  enviarExperiencias(experiencias: Experiencia[]) {
    return this.http.post(`${this.url_acesso}experiencia-profissional/incluir`, experiencias, {
      headers: this.cabecalho,
    });
  }

  excluirExperiencias(numord: string) {
    return this.http.delete(`${this.url_acesso}/experiencia-profissional/excluir/${numord}`, {
      headers: this.cabecalho,
    });
  }
}
