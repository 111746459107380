import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CadastroService } from "./cadastro.service";
import { ErroService } from '../../service/erros/erro.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Cadastro } from './interface/cadastro'
import { UploadComponent } from '../upload/upload.component';
import { distinct } from 'rxjs/operators'
import { Validacao } from '../../service/global/validacao/validacao.service';
import Swal from 'sweetalert';
import { pisValidator } from '../../service/Validators/pisValidator'
import { cpfValidator } from '../../service/Validators/cpfValidator'
import { Router } from '@angular/router';
import { AnexoComponent } from '../anexo/anexo.component';
import { ParametrosComponent } from '../parametros/parametros.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { throws } from 'assert';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.css']
})

export class CadastroComponent implements OnInit {
  localStorage: LocalStorage;
  @ViewChild(ParametrosComponent, { static: false }) parametrosComponent: ParametrosComponent;
  @ViewChild(AnexoComponent, { static: false }) anexoComponent: AnexoComponent;

  public customPatterns = { '0': { pattern: new RegExp('^\d+(\.|\,)\d{2}$') } }
  public step: number = 0;
  public stepcount: number = 0;
  urls: Array<string> = [];
  uploadForm: FormGroup;
  cadastro: Cadastro;
  imageURL: string;
  enviarEmail: boolean = false;
  validaEtapa1: boolean = false;
  validaEtapa2: boolean = false;
  validaEtapa3: boolean = false;
  validaEtapa4: boolean = false;
  boolMatricula: boolean = true;
  matriculaNull: boolean = true;
  sempreTrue: boolean = true;

  listaNumeros: any = [];
  contCamposNumeros: any = 0;
  listaDeCelulares: any = [];
  numeroEmBranco: boolean = false;

  //Constantes
  idadeMinima: number = 14;
  indicadoresCalculo: any;

  //Variáveis do dos forms

  formInformacaoBasica: FormGroup;
  formDadosPessoais: FormGroup;
  formDocumentos: FormGroup;
  formEmail: FormGroup;

  //Variáveis dos selects da etapa 1
  vinculo: any[] = [];
  departamento: any[] = [];
  setores: any[] = [];
  cargos: any[] = [];
  horaTrabalho: any[] = [];
  tipoFolha: any[] = []
  cargaHoraria: any[] = [];
  sindicatos: any[] = [];
  nivel: any[] = [];
  diasUteis: any[] = [];
  //Variáveis dos selects da etapa 2
  escolaridade: any[] = [];
  nacionalidade: any[] = [];
  cor: any[] = [];
  sexo: any[] = [];
  estadoCivil: any[] = [];
  estado: any[] = [];
  cidade: any[] = [];

  //VariÃ¡veis dos selects da etapa 3
  ufRg: any[] = [];
  categoriaCnh: any[] = [];
  ufCtps: any[] = [];
  banco: any[] = [];
  ufCnh: any[] = [];

  //Variaveis do filter
  cargaHorariaFilter: any[] = [];
  horarioTrabalhoFilter: any[] = [];

  //loadings dos campos
  carrega_setor: boolean = false;
  carrega_cargo: boolean = false;
  carrega_hora_trabalho: boolean = false;
  carrega_nivel: boolean = false;
  carrega_cep: boolean = false;

  //Variáveis e retorno
  retorno_usuario = { NumeroDeOrdem: '', Matricula: '' };
  cep = { Bairro: "", Cep: "", Cidade: "", Complemento: "", Logradouro: "", Numero: "", Uf: "", Valido: "", bairro: "", cidade: "", complemento: "", logradouro: "", uf: "" };
  codigo_uf: any;
  salario_nivel: string;
  validaCadastro: boolean = false; // verifica se o usuário já passou pela etapa do cadastro
  filial: number = Number(localStorage.getItem("filial_atual"));

  filtroTeclas: any;
  testePis: string;

  validar_cpf_blur: boolean = false;
  validar_pis_blur: boolean = false;

  nomeBanco: any;
  agencias: any = [];
  operacoes: any = [];

  //JSON da primeira etapa
  cadastro_etapa_1 = {
    NumeroDeOrdem: "",
    Etapa: "",
    Matricula: "",
    IsCadastro: null,
    Filial: {
      Codigo: this.filial
    },
    Documentos: {
      Cpf: ""
    },
    InformacoesBasicas: {
      NomeCompleto: "",
      DiasUteisColaborador: '',
      Departamento: {
        Cod: ""
      },
      Setor: {
        Cod: ""
      },
      TipoFolha: "",
      DataAdmissao: {
        SetData: "",
        ToDate: "",
        IsDate: "",
        Empty: "",
      },
      Vinculo: {
        Codigo: ""
      },
      Cargo: {
        Codigo: "",
        CBO: 0
      },
      Salario: {
        CodigoNivel: "",
        Valor: ""
      },

      HorarioTrabalho: {
        Codigo: "",
        CargaHorariaSemana: 0,
        HoraEntrada: 0,
        HoraSaida: 0,
        InicioIntervalo: 0,
        TerminoIntervalo: 0
      },
      Sindicato: {
        Codigo: ""
      }
    },
    Flags: null,
  };
  cadastro_etapa_2 = {
    NumeroDeOrdem: "",
    Etapa: 2,
    Matricula: "",
    IsCadastro: false,
    Filial: {
      Codigo: this.filial
    },
    Pessoal: {
      DadosBasicos: {
        DataNascimento: {
          SetData: "",
          ToDate: "",
          IsDate: true,
          Empty: false
        },
        GrauDeInstrucao: {
          Valor: 0
        },
        Nacionalidade: {
          Codigo: 0
        },
        Etnia: {
          Descricao: ""
        },
        Sexo: {
          Valor: 1
        },
        EstadoCivil: {
          Valor: 0
        },
        NomeMae: "",
        NomePai: ""
      },
      Endereco: {
        Cep: "",
        Logradouro: "",
        Numero: "",
        Bairro: "",
        Estado: "",
        Cidade: "",
        Complemento: ""
      },
      Contato: {
        DDDCelular: "",
        CelularP: "",
        CelularS: [],
        DDDTelefone: "",
        Telefone: "",
        Email1: "",
        Email2: ""
      }
    }
  }
  cadastro_etapa_3 = {
    NumeroDeOrdem: "",
    Etapa: 3,
    Matricula: "",
    IsCadastro: false,
    Filial: {
      Codigo: this.filial
    },
    Documentos: {
      Rg: {
        Numero: "",
        DataEmissaoRg: {
          SetData: "",
          ToDate: "",
          IsDate: false,
          Empty: true
        },
        OrgaoEmissor: "",
        UfEmissaoRg: ""
      },
      Cnh: {
        Numero: "",
        CategoriaCnh: "",
        UF: "",
        DataValidadeCnh: {
          SetData: "",
          ToDate: "",
          IsDate: false,
          Empty: true
        }
      },
      Clt: {
        CarteiraDeTrabalho: "",
        NumeroSerieCtps: "",
        Pis: "",
        UfCtps: ""
      },
      TituloEleitor: {
        Numero: "",
        ZonaEleitoral: "",
        SecaoEleitoral: ""
      },
      UploadDocumentos: []
    },
    DadosBancarios: {
      Banco: "",
      Agencia: "",
      Operacao: "",
      Conta: ""
    },
  }
  carregarInativo: boolean;


  constructor(public fb: FormBuilder, private cadastroService: CadastroService, private validaErro: ErroService, private spinner: NgxSpinnerService, private validacaoService: Validacao, public route: Router) {
    // Reactive Form
  }

  ngOnInit() {

    this.localStorage = this.decryptLocalStorage();
    localStorage.setItem("tipoTela", new Crypto().encryptUsingAES256(JSON.stringify("017")));
    this.cadastro_etapa_1.IsCadastro = true;
    this.filial = Number(this.localStorage.FilialAtual);
    window.scrollTo(0, 0);
    this.spinner.show();
    this.buscarDepartamento();

    this.formInformacaoBasica = this.fb.group({
      avatar: [null],
      cpf_form: ['', Validators.compose([Validators.required, cpfValidator])],
      nome_completo: ['', Validators.compose([Validators.required, Validators.minLength(12), Validators.maxLength(80)])],
      matricula: ['', Validators.compose([])],
      departamento_form: ['', Validators.compose([Validators.required])],
      data_admissao_form: ['', Validators.compose([Validators.required])],
      setor_form: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      cargo_form: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      vinculo_form: ['', Validators.compose([Validators.required])],
      cargaH_form: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      horaT_form: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      nivel_form: ['', Validators.compose([Validators.required])],
      tipoFolha_form: ['', Validators.compose([Validators.required])],
      sindicato_form: ['', Validators.compose([Validators.required])],
      diasUteis: ['', Validators.compose([Validators.required])]
    });

    this.formDadosPessoais = this.fb.group({
      data_nascimento_form: ['', Validators.compose([Validators.required])],
      escolaridade_form: ['', Validators.compose([Validators.required])],
      nacionalidade_form: ['', Validators.compose([Validators.required])],
      cor_form: ['', Validators.compose([Validators.required])],
      sexo_form: ['', Validators.compose([Validators.required])],
      estado_civil_form: ['', Validators.compose([Validators.required])],
      mae_form: ['', Validators.compose([Validators.required, Validators.minLength(12), Validators.maxLength(80)])],
      pai_form: ['', [Validators.maxLength(80)]],
      cep_form: ['', Validators.compose([Validators.required])],
      logradouro_form: ['', Validators.compose([Validators.required])],
      numero_form: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(4)])],
      bairro_form: ['', Validators.compose([Validators.required])],
      complemento_form: ['', Validators.maxLength(30)],
      estado_form: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      cidade_form: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      telefone_form: [''],
      celularP_form: ['', Validators.compose([Validators.required])],
      celularS_form: [this.listaDeCelulares, Validators.compose([Validators.required])],
      email_form: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(50)])],
      email2_form: ['', Validators.compose([Validators.email, Validators.maxLength(50)])]
    });

    this.formDocumentos = this.fb.group({
      rg_form: ['', Validators.compose([Validators.required, Validators.maxLength(9)])],
      data_emissao_form: ['', Validators.compose([Validators.required])],
      orgao_form: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      uf_emissaor_form: ['', Validators.compose([Validators.required])],
      cnh_form: ['', Validators.compose([Validators.minLength(11)])],
      categoria_cnh_form: [''],
      uf_cnh_form: [''],
      data_validade_form: [''],
      carteira_form: ['', Validators.compose([Validators.required])],
      serie_form: ['', Validators.compose([Validators.required])],
      uf_ctps_form: ['', Validators.compose([Validators.required])],
      pis_form: ['', Validators.compose([pisValidator, Validators.required])],
      titulo_form: ['', Validators.compose([Validators.required])],
      zona_form: ['', Validators.compose([Validators.required])],
      secao_form: ['', Validators.compose([Validators.required])],
      banco_form: ['', Validators.compose([Validators.required])],
      agencia_form: ['', Validators.compose([Validators.required])],
      operacao_form: ['', Validators.compose([Validators.required])],
      conta_form: ['', Validators.compose([Validators.required])]
    });
    this.formEmail = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });
    this.buscarTipoFolha();
    this.buscarDiasUteis();
  }


  modelChanged(event: any) {
    const cnh = this.formDocumentos.get("cnh_form").value;
    const categoria = this.formDocumentos.get("categoria_cnh_form").value;
    const uf = this.formDocumentos.get("uf_cnh_form").value;
    const data = this.formDocumentos.get("data_validade_form").value;

    if (cnh == "" && categoria == "" && uf == "" && data == "") {
      this.formDocumentos.get("cnh_form").setValidators([Validators.minLength(11)]);
      this.formDocumentos.get("categoria_cnh_form").setValidators(null);
      this.formDocumentos.get("uf_cnh_form").setValidators(null);
      this.formDocumentos.get("data_validade_form").setValidators(null);
    } else {
      this.formDocumentos.get("cnh_form").setValidators([Validators.required, Validators.minLength(11)]);
      this.formDocumentos.get("categoria_cnh_form").setValidators([Validators.required]);
      this.formDocumentos.get("uf_cnh_form").setValidators([Validators.required]);
      this.formDocumentos.get("data_validade_form").setValidators([Validators.required]);
    }
    this.formDocumentos.get("cnh_form").updateValueAndValidity();
    this.formDocumentos.get("categoria_cnh_form").updateValueAndValidity();
    this.formDocumentos.get("uf_cnh_form").updateValueAndValidity();
    this.formDocumentos.get("data_validade_form").updateValueAndValidity();
  }

  mostrarIcon: Boolean = false;
  conferenciaCheckbox: boolean = false;
  assinaturaCheckbox: boolean = false;
  exameCheckbox: boolean = false;

  clickStep: boolean = false;

  // ***********************************
  // MÉTODOS PARA A ETAPA 1 DO CADASTRO
  // ***********************************

  buscarVinculo() {
    this.cadastroService.buscarVinculo()
      .subscribe(
        data => {
          this.vinculo = JSON.parse(JSON.stringify(data));
          this.buscarSindicato();
          //this.loading = false;
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          //Swal.fire('Erro', 'Não foi possível obter as informações das filiais.', 'error');
        }
      );
  }
  buscarDiasUteis() {
    this.cadastroService.buscarDiasUteisColaborador()
      .subscribe(
        data => {
          this.diasUteis = JSON.parse(JSON.stringify(data));
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          //Swal.fire('Erro', 'Não foi possível obter as informações das filiais.', 'error');
        }
      );
  }

  matriculaNula(): boolean {
    if (this.formInformacaoBasica.get('matricula').value == "") {
      this.matriculaNull = true;
      return this.matriculaNull;
    }
    else {
      this.matriculaNull = false;
      return this.matriculaNull;
    }
  }
  alterarInput(): void {
    this.formInformacaoBasica.get('matricula').setValue(this.cadastro_etapa_1.Matricula); 0
  }
  // buscaMatricula(matricula): void {
  //   this.cadastroService.buscarColaboradorMatricula(matricula)
  //     .subscribe(
  //       () => {
  //         this.formInformacaoBasica.get('matricula').setValue('')
  //         Swal('Atenção!', 'Matrícula existente', 'warning')
  //         this.spinner.hide()
  //       },
  //       () => {
  //         Swal('Sucesso', 'Matrícula válida', 'success')
  //         this.spinner.hide()
  //       }
  //     );
  // }
  desabilitaInput() {
    if (this.boolMatricula == false) {
      this.formInformacaoBasica.get('matricula').disable()
      this.boolMatricula = true;
    }
    else {
      this.boolMatricula = false;
      this.formInformacaoBasica.get('matricula').enable()
    }
    this.formInformacaoBasica.get('matricula').setValue('');
  }

  abiriModalIndicadores(): void {
    this.parametrosComponent.abrirModal();
  }

  verificarMatricula(): void {
    // this.buscaMatricula(this.formInformacaoBasica.get('matricula').value);
  }

  buscarDepartamento() {
    this.cadastroService.buscarDepartamento()
      .subscribe(
        data => {
          this.departamento = JSON.parse(JSON.stringify(data));

          this.buscarVinculo();
          //this.loading = false;
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          //Swal.fire('Erro', 'Não foi possí­vel obter as informações das filiais.', 'error');
        }
      );
  }


  // buscarColaboradorInativo(): void{
  //   const cpf = this.formDocumentos.get('dadosPessoais').get('cpf').value
  //   this.spinner.show()
  //   this.cadastroService.buscarColaboradorInativo(cpf)
  //   .subscribe(
  //     (data: any)=> {
  //       this.carregarInativo = true;

  //       this.spinner.hide()
  //     },
  //     error =>{
  //       this.validaErro.retornoErro(error)
  //       this.spinner.hide()
  //     }

  //   )
  // }


  async verificarIndicadoresCalculo(): Promise<boolean> {
    const flags = this.parametrosComponent.retornarValores();
    let flagSelecionada: boolean = false;

    Object.entries(flags).forEach((flags: any[]) => {
      if (flags[1] !== '0') flagSelecionada = true;
    });

    if (!flagSelecionada) {
      const abrirModal: boolean = await Swal({
        title: 'Atenção ',
        text: "Deseja enviar o cadastro sem indicadores de cálculo?",
        icon: 'warning',
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ['Sim', 'Não'],
      });
      if (abrirModal) {
        this.step--;
        this.stepcount--;
        this.parametrosComponent.abrirModal();
      } else {
        this.indicadoresCalculo = { ...this.parametrosComponent.retornarValores() }
        this.insertInformacaoBasica();
      }
      return abrirModal;

    } else {
      this.indicadoresCalculo = { ...this.parametrosComponent.retornarValores() }
      this.insertInformacaoBasica();
      return false;
    }


  }



  buscarSetor(codigo) {
    if (codigo.target.value.trim() != '') {
      this.carrega_setor = true;
      this.cadastroService.buscarSetor(codigo.target.value.trim())
        .subscribe(
          data => {
            this.setores = JSON.parse(JSON.stringify(data));
            this.spinner.hide();
            // Habilita o setor
            this.carrega_setor = false;
            this.formInformacaoBasica.get('setor_form').enable();
            //this.loading = false;
          },
          error => {
            this.carrega_setor = false;
            this.spinner.hide();
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          }
        );
    } else {
      this.desabilitarCamposInformacoesBacicas('full');
    }
  }

  adicionarCelular(){
    if (this.listaNumeros.length < 4) {
      this.listaNumeros.push(this.formDadosPessoais.get('celularS_form').value);
      this.formDadosPessoais.get('celularS_form').setValue('');
    } else {
      new AlertaModal("erro", "Erro", "Você só pode adicionar até 4 números adicionais.", 'p');
    }
  }

  removerCampoNumero(numero){
    let index = this.listaNumeros.indexOf(numero);
    this.listaNumeros.splice(index, 1);
  }

  campoNumeroEmBranco(): boolean{
    if(this.formDadosPessoais.get('celularS_form').value.length == 10){
      return true;
    } else {
      return false;
    }
  }

  buscarCargo(codigo) {

    if (codigo.target.value.trim() != '') {
      this.carrega_cargo = true;
      this.cadastroService.buscarCargo(codigo.target.value)
        .subscribe(
          data => {

            this.cargos = JSON.parse(JSON.stringify(data));
            this.spinner.hide();
            // Habilita o setor
            this.carrega_cargo = false;
            this.formInformacaoBasica.get('cargo_form').enable();

            this.formInformacaoBasica.get('nivel_form').enable();
          },
          error => {
            this.carrega_cargo = false;
            this.spinner.hide();
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
            //Swal.fire('Erro', 'Não foi possÃ­vel obter as informaÃ§Ãµes das filiais.', 'error');
          }
        );
    } else {
      this.desabilitarCamposInformacoesBacicas('setor');
    }
  }

  buscarHoraTrabalho(codigo) {
    if (codigo.target.value.trim() != '') {
      this.carrega_hora_trabalho = true;
      this.cadastroService.buscarHoraTrabalho(codigo.target.value.trim())
        .subscribe(
          data => {
            this.horaTrabalho = JSON.parse(JSON.stringify(data));
            this
            this.cargaHorariaFilter = [];
            this.removerRepetido(this.horaTrabalho);
            this.spinner.hide();
            // Habilita o setor
            this.carrega_hora_trabalho = false;
            this.formInformacaoBasica.get('cargaH_form').enable();

          },
          error => {
            this.carrega_hora_trabalho = false;
            this.spinner.hide();
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
            //Swal.fire('Erro', 'NÃ£o foi possÃ­vel obter as informaÃ§Ãµes das filiais.', 'error');
          }
        );
    } else {
      this.desabilitarCamposInformacoesBacicas('cargo');
    }
  }

  buscarTipoFolha() {
    this.formInformacaoBasica.get('matricula').disable()
    this.spinner.show()
    this.cadastroService.buscarTipoFolha()
      .subscribe(
        data => {
          this.spinner.hide();
          this.tipoFolha = JSON.parse(JSON.stringify(data))

        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      )
  }


  buscarNivel(codigo) {
    if (codigo.target.value.trim() != '') {
      this.carrega_hora_trabalho = true
      this.carrega_nivel = true;
      this.cadastroService.buscarNivelCadastroPublico(codigo.target.value.trim())
        .subscribe(
          data => {
            this.nivel = JSON.parse(JSON.stringify(data));
            // Habilita o setor
            this.carrega_hora_trabalho = false
            this.carrega_nivel = false;
            this.formInformacaoBasica.get('nivel_form').enable();
          },
          error => {
            this.carrega_hora_trabalho = false
            this.carrega_nivel = false;
            this.spinner.hide();
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
            //Swal.fire('Erro', 'NÃ£o foi possÃ­vel obter as informaÃ§Ãµes das filiais.', 'error');
          }
        );
    } else {
      this.desabilitarCamposInformacoesBacicas('cargo');
    }
  }


  habilitarHoraTrabalho(codigo) {
    if (codigo.target.value.trim() != '') {
      var codigoCargaHoraria = parseInt(codigo.target.value);

      this.horarioTrabalhoFilter = this.horaTrabalho.filter(element => {
        return element.CargaHorariaSemana === codigoCargaHoraria;
      });
      this.formInformacaoBasica.get('horaT_form').enable();
    } else {
      this.desabilitarCamposInformacoesBacicas('cargaHora');
    }
  }

  buscarSindicato() {
    this.cadastroService.buscarSindicato()
      .subscribe(
        data => {
          this.sindicatos = JSON.parse(JSON.stringify(data));
          this.spinner.hide();
          //this.loading = false;
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          //Swal.fire('Erro', 'NÃ£o foi possÃ­vel obter as informaÃ§Ãµes das filiais.', 'error');
        }
      );
  }
  // ***********************************
  // MÃTODOS PARA A ETAPA 2 DO CADASTRO
  // ***********************************

  buscarEscolaridade() {
    this.cadastroService.buscarEscolaridade()
      .subscribe(
        data => {
          this.escolaridade = JSON.parse(JSON.stringify(data));
          this.buscarNacionalidade();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarNacionalidade() {
    this.cadastroService.buscarNacionalidade()
      .subscribe(
        data => {
          this.nacionalidade = JSON.parse(JSON.stringify(data));
          //this.formDadosPessoais.controls['nacionalidade_form'].setValue("")
          this.buscarCor();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarCor() {
    this.cadastroService.buscarCor()
      .subscribe(
        data => {
          this.cor = JSON.parse(JSON.stringify(data));
          this.buscarSexo();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarSexo() {
    this.cadastroService.buscarSexo()
      .subscribe(
        data => {
          this.sexo = JSON.parse(JSON.stringify(data));
          this.buscarEstadoCivil();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarEstadoCivil() {
    this.cadastroService.buscarEstadoCivil()
      .subscribe(
        data => {
          this.estadoCivil = JSON.parse(JSON.stringify(data));
          this.spinner.hide();
          //this.buscarEstado();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarCep(cod_cep) {
    var cep = cod_cep.target.value;
    cep = cep.replace("-", "");

    if (cep.trim() == '') {
      new AlertaModal("alerta", "Cep Inválido.", "Digite um cep para buscar o endereço.", 'p');
      this.zerarEndereco();
    } else {
      this.carrega_cep = true;
      this.cadastroService.buscarCep(cep)
        .subscribe(
          data => {
            this.cep = JSON.parse(JSON.stringify(data));

            if (this.cep.Logradouro.trim() == '') {
              new AlertaModal("alerta", "Cep Inválido.", "Digite um cep para buscar o endereço.", 'p');
              this.zerarEndereco();
              this.carrega_cep = false;
            } else {
              this.formDadosPessoais.controls['logradouro_form'].setValue(this.cep.Logradouro);
              this.formDadosPessoais.controls['bairro_form'].setValue(this.cep.Bairro);
              this.buscarEstado();
            }
            this.carrega_cep = false;
          },
          error => {
            this.carrega_cep = false;
            this.spinner.hide();
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          }
        );
    }
  }

  buscarEstado() {
    this.cadastroService.buscarEstado()
      .subscribe(
        data => {
          this.estado = JSON.parse(JSON.stringify(data));
          this.formDadosPessoais.controls['estado_form'].setValue(this.cep.Uf);
          this.formDadosPessoais.get('estado_form').enable();

          this.buscarCidade(this.estado, this.cep.Uf);
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarCidade(codigo, uf) {

    this.buscarCodigoEstado(codigo, uf);

    this.cadastroService.buscarCidade(this.codigo_uf)
      .subscribe(
        data => {
          this.cidade = JSON.parse(JSON.stringify(data));
          this.formDadosPessoais.controls['cidade_form'].setValue(this.cep.Cidade);
          this.formDadosPessoais.get('cidade_form').enable();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  // ***********************************
  // MÃTODOS PARA A ETAPA 3 DO CADASTRO
  // ***********************************

  buscarUf() {
    this.cadastroService.buscarEstado()
      .subscribe(
        data => {
          this.ufRg = JSON.parse(JSON.stringify(data));
          this.ufCtps = JSON.parse(JSON.stringify(data));
          this.ufCnh = JSON.parse(JSON.stringify(data));
          this.buscarCategoriaCnh();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarCategoriaCnh() {
    this.cadastroService.buscarCategoriaCnh()
      .subscribe(
        data => {
          this.categoriaCnh = JSON.parse(JSON.stringify(data));
          this.buscarBanco();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarAgencia(event) {
    if (!this.formDocumentos.get('banco_form').value) {
      this.formDocumentos.get('agencia_form').setValue('');
    } else {
      this.nomeBanco = event.target.value;
      this.cadastroService.buscarAgencia(this.nomeBanco).subscribe(data => {
        this.agencias = data;
        this.buscarOperacoes(this.nomeBanco);
      }, error => {
        this.spinner.hide();
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
      });
    }
  }

  buscarOperacoes(nomeBanco){
    this.cadastroService.buscarOperacoes(nomeBanco).subscribe(data => {
      this.operacoes = data;
    }, error => {
      this.spinner.hide();
      new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
    });
  }

  buscarBanco() {
    this.cadastroService.buscarBanco()
      .pipe(distinct())
      .subscribe(
        data => {
          this.banco = JSON.parse(JSON.stringify(data));
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }



  // ***********************************
  // MÃTODOS DE INSERÃÃO E UPDATE DAS ETAPAS
  // ***********************************


  insertInformacaoBasica() {
    this.spinner.show();
    this.cadastro_etapa_1.Documentos.Cpf = this.formInformacaoBasica.get('cpf_form').value;
    this.cadastro_etapa_1.Flags = this.indicadoresCalculo,
      this.cadastro_etapa_1.Etapa = '1';
    this.cadastro_etapa_1.Filial.Codigo = this.localStorage.FilialAtual;
    this.cadastro_etapa_1.InformacoesBasicas.NomeCompleto = this.formInformacaoBasica.get('nome_completo').value;
    this.cadastro_etapa_1.InformacoesBasicas.Departamento.Cod = this.formInformacaoBasica.get('departamento_form').value;
    this.cadastro_etapa_1.InformacoesBasicas.DiasUteisColaborador = this.formInformacaoBasica.get('diasUteis').value;
    this.cadastro_etapa_1.InformacoesBasicas.Setor.Cod = this.formInformacaoBasica.get('setor_form').value;
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.SetData = this.converteDatas(this.formInformacaoBasica.get('data_admissao_form').value, 0);
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.ToDate = this.converteDatas(this.formInformacaoBasica.get('data_admissao_form').value, 1);
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.IsDate = "true";
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.Empty = "false";
    this.cadastro_etapa_1.InformacoesBasicas.Vinculo.Codigo = this.formInformacaoBasica.get('vinculo_form').value;
    this.cadastro_etapa_1.InformacoesBasicas.Cargo.Codigo = this.formInformacaoBasica.get('cargo_form').value;
    this.cadastro_etapa_1.Matricula = this.formInformacaoBasica.get('matricula').value;
    this.cadastro_etapa_1.InformacoesBasicas.Salario.CodigoNivel = this.formInformacaoBasica.get('nivel_form').value;
    this.cadastro_etapa_1.InformacoesBasicas.TipoFolha = this.formInformacaoBasica.get('tipoFolha_form').value;
    this.buscarSalarioNivel(this.formInformacaoBasica.get('nivel_form').value);
    this.organizarCargaHoraria(this.formInformacaoBasica.get('horaT_form').value);
    this.organizarCargo(this.cadastro_etapa_1.InformacoesBasicas.Cargo.Codigo);
    // this.cadastro_etapa_1.InformacoesBasicas.Salario.Valor = this.salario_nivel;
    // this.cadastro_etapa_1.InformacoesBasicas.HorarioTrabalho.Codigo = this.formInformacaoBasica.get('cargaH_form').value;
    this.cadastro_etapa_1.InformacoesBasicas.Sindicato.Codigo = this.formInformacaoBasica.get('sindicato_form').value;
    if (this.cadastro_etapa_1.IsCadastro == true) {
      this.cadastro_etapa_1.IsCadastro = true;
    }
    this.cadastroService.inserirEtapa1(this.cadastro_etapa_1)
      .subscribe(
        data => {

          this.retorno_usuario = JSON.parse(JSON.stringify(data));
          console.log(this.retorno_usuario)
          // Salva o nÃºmero de ordem e matrÃ­cula
          this.cadastro_etapa_1.IsCadastro = false;
          this.cadastro_etapa_1.NumeroDeOrdem = this.retorno_usuario.NumeroDeOrdem;
          this.cadastro_etapa_1.Matricula = this.retorno_usuario.Matricula;
          localStorage.setItem("matricula", new Crypto().encryptUsingAES256(JSON.stringify(this.cadastro_etapa_1.Matricula)));
          this.cadastro_etapa_1.IsCadastro = false;

          this.cadastro_etapa_2.NumeroDeOrdem = this.retorno_usuario.NumeroDeOrdem;
          this.cadastro_etapa_2.Matricula = this.retorno_usuario.Matricula;

          this.cadastro_etapa_3.NumeroDeOrdem = this.retorno_usuario.NumeroDeOrdem;
          this.cadastro_etapa_3.Matricula = this.retorno_usuario.Matricula;
          document.getElementById("btEnviarEmail").click();
          // Vai para a prÃ³xima etapa
          this.buscarEscolaridade();
          this.validaCadastro = true;
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.PreviewStep();
        }
      );

  }

  // Altera os dados das etapas
  updateCadastro(dadosEtapa) {

    this.spinner.show();
    this.cadastroService.updateEtapa(dadosEtapa)
      .subscribe(
        data => {

          this.retornoUpdate();
          //this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          this.step--;
          this.stepcount--;
          window.scrollTo(0, 0);
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          //this.validaErro.retornoErro(error);
          // Swal( 'Atenção ',error.message, 'warning');
        }
      );
  }

  // ***************************************************
  // MÃ©todos das validaÃ§Ãµes das etapas
  // ***************************************************


  // Faz a validaÃ§Ã£o da etapa pra chamar o mÃ©todo do momento
  validaMetodoEtapa(etapa: number) {
    if (etapa == 1) {
      this.validarEtapa1();
    } else if (etapa == 2) {
      this.validarEtapa2();
    } else if (etapa == 3) {
      this.validarEtapa3();
    }
  }

  retornoUpdate() {
    if (this.step == 1) {
      ///todo
      this.buscarEscolaridade();
    } else if (this.step == 2) {
      this.buscarUf();
    } else if (this.step == 3) {
      this.spinner.hide();
      this.finalizarCadastro();
    }
  }

  organizarEtapa1() {
    this.cadastro_etapa_1.Etapa = '1';
    this.spinner.show();
    this.cadastro_etapa_1.Documentos.Cpf = this.formInformacaoBasica.get('cpf_form').value;
    this.cadastro_etapa_1.Flags = this.indicadoresCalculo,
      this.cadastro_etapa_1.InformacoesBasicas.NomeCompleto = this.formInformacaoBasica.get('nome_completo').value;
    this.cadastro_etapa_1.InformacoesBasicas.DiasUteisColaborador = this.formInformacaoBasica.get('diasUteis').value;
    this.cadastro_etapa_1.InformacoesBasicas.Departamento.Cod = this.formInformacaoBasica.get('departamento_form').value;
    this.cadastro_etapa_1.InformacoesBasicas.Setor.Cod = this.formInformacaoBasica.get('setor_form').value;
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.SetData = this.converteDatas(this.formInformacaoBasica.get('data_admissao_form').value, 0);
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.ToDate = this.converteDatas(this.formInformacaoBasica.get('data_admissao_form').value, 1);
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.IsDate = "true";
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.Empty = "false";
    this.cadastro_etapa_1.InformacoesBasicas.Vinculo.Codigo = this.formInformacaoBasica.get('vinculo_form').value;
    this.cadastro_etapa_1.InformacoesBasicas.Cargo.Codigo = this.formInformacaoBasica.get('cargo_form').value;
    this.cadastro_etapa_1.InformacoesBasicas.Salario.CodigoNivel = this.formInformacaoBasica.get('nivel_form').value;
    this.cadastro_etapa_1.InformacoesBasicas.TipoFolha = this.formInformacaoBasica.get('tipoFolha_form').value;
    this.buscarSalarioNivel(this.formInformacaoBasica.get('nivel_form').value);
    this.organizarCargaHoraria(this.formInformacaoBasica.get('horaT_form').value);
    this.organizarCargo(this.cadastro_etapa_1.InformacoesBasicas.Cargo.Codigo);
    this.cadastro_etapa_1.InformacoesBasicas.Sindicato.Codigo = this.formInformacaoBasica.get('sindicato_form').value;
  }

  validarEtapa1() {
    if (this.formInformacaoBasica.invalid) {
      new AlertaModal('alerta', 'Preencha todos os campos!', "Verifique os campos incorretos.", 'p');
      this.validaEtapa1 = true;
      this.step--;
      this.stepcount--;
      window.scrollTo(0, 0);
      return false;
    } else {
      if (this.validaCadastro) {
        this.organizarEtapa1()
        this.updateCadastro(this.cadastro_etapa_1);
        const abrirIndicadores = this.verificarIndicadoresCalculo();
        if (abrirIndicadores) return;
      } else {
        const abrirIndicadores = this.verificarIndicadoresCalculo();
        if (abrirIndicadores) return;

      }
    }
  }

  validarEtapa2() {
    if (this.formDadosPessoais.invalid) {
      new AlertaModal('alerta', 'Preencha todos os campos!', "Verifique os campos incorretos.", 'p');
      this.validaEtapa2 = true;
      this.step--;
      this.stepcount--;
      window.scrollTo(0, 0);
    } else {
      this.setarValoresEtapa2();
      this.updateCadastro(this.cadastro_etapa_2);
    }
  }

  validarEtapa3() {
    this.cadastro_etapa_3.Documentos.UploadDocumentos = this.anexoComponent.retornarValores();
    if (this.formDocumentos.invalid) {
      new AlertaModal('alerta', 'Preencha todos os campos!', "Verifique os campos incorretos.", 'p');
      this.validaEtapa3 = true;
      this.step--;
      this.stepcount--;
      window.scrollTo(0, 0);
    } else if (this.cadastro_etapa_3.Documentos.UploadDocumentos.length == 0) {
      new AlertaModal('alerta', 'Aviso!', "Envie ao menos um documento!", 'p');
      this.step--;
      this.stepcount--;
      window.scrollTo(0, 0);
    } else {
      this.setarValoresEtapa3();
      //this.cadastro_etapa_3.Documentos.UploadDocumentos = this.anexoComponent.retornarValores();
      this.updateCadastro(this.cadastro_etapa_3);
    }
  }

  finalizarCadastro() {
    new AlertaModal("sucesso", "Sucesso!", "Cadastro realizado!", "p");
    location.reload();
  }

  // ***************************************************
  // MÃ©todos genÃ©ricos para validar rotinas e campos
  // ***************************************************

  zerarEndereco() {
    this.formDadosPessoais.controls['logradouro_form'].setValue('');
    this.formDadosPessoais.controls['bairro_form'].setValue('');
    this.formDadosPessoais.controls['cidade_form'].setValue('');
    this.formDadosPessoais.controls['estado_form'].setValue('');
    this.formDadosPessoais.controls['numero_form'].setValue('');
    this.formDadosPessoais.get('estado_form').disable();
    this.formDadosPessoais.get('cidade_form').disable();
    this.cidade = [];
    this.estado = [];
  }

  desabilitarCamposInformacoesBacicas(tipo) {

    if (tipo == 'full') {
      this.formInformacaoBasica.get('setor_form').disable();
      this.formInformacaoBasica.controls['setor_form'].setValue("");
      this.setores = [];

      this.formInformacaoBasica.get('cargo_form').disable();
      this.formInformacaoBasica.controls['cargo_form'].setValue("");
      this.cargos = [];

      this.formInformacaoBasica.get('cargaH_form').disable();
      this.formInformacaoBasica.controls['cargaH_form'].setValue("");
      this.cargaHoraria = [];

      this.formInformacaoBasica.get('horaT_form').disable();
      this.formInformacaoBasica.controls['horaT_form'].setValue("");
      this.horaTrabalho = [];
      this.cargaHorariaFilter = [];

      this.formInformacaoBasica.get('nivel_form').disable();
      this.formInformacaoBasica.controls['nivel_form'].setValue("");
      this.nivel = [];
    } else if (tipo == 'setor') {
      this.formInformacaoBasica.get('cargo_form').disable();
      this.formInformacaoBasica.controls['cargo_form'].setValue("");
      this.cargos = [];

      this.formInformacaoBasica.get('cargaH_form').disable();
      this.formInformacaoBasica.controls['cargaH_form'].setValue("");
      this.cargaHoraria = [];

      this.formInformacaoBasica.get('horaT_form').disable();
      this.formInformacaoBasica.controls['horaT_form'].setValue("");
      this.horaTrabalho = [];
      this.cargaHorariaFilter = [];

      this.formInformacaoBasica.get('nivel_form').disable();
      this.formInformacaoBasica.controls['nivel_form'].setValue("");
      this.nivel = [];
    } else if (tipo == 'cargo') {
      this.formInformacaoBasica.get('cargaH_form').disable();
      this.formInformacaoBasica.controls['cargaH_form'].setValue("");
      this.cargaHoraria = [];

      this.formInformacaoBasica.get('horaT_form').disable();
      this.formInformacaoBasica.controls['horaT_form'].setValue("");
      this.horaTrabalho = [];
      this.cargaHorariaFilter = [];
    } else if (tipo == 'cargaHora') {
      this.formInformacaoBasica.get('horaT_form').disable();
      this.formInformacaoBasica.controls['horaT_form'].setValue("");
      //this.horaTrabalho = [];
    }

  }

  somenteNumero(event) {
    return ((event.charCode >= 48 && event.charCode <= 57) /*|| (event.keyCode == 45 || event.charCode == 46)*/)
  }

  compararDatas(data1: string, data2: string, etapa, campo1, campo2) {

    if (data1.trim() != '' && data2.trim() != '') {

      data1 = this.converteDatas(data1, 1);
      data2 = this.converteDatas(data2, 1);
    }
  }

  // ***************************************************
  // MÃ©todos para setar os valores do cadastro
  // ***************************************************

  setarValoresEtapa2() {
    this.cadastro_etapa_2.Etapa = 2;
    //this.cadastro_etapa_2.Filial.Codigo = 100001;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.DataNascimento.SetData = this.converteDatas(this.formDadosPessoais.get('data_nascimento_form').value, 0);
    this.cadastro_etapa_2.Pessoal.DadosBasicos.DataNascimento.ToDate = this.converteDatas(this.formDadosPessoais.get('data_nascimento_form').value, 1);
    this.cadastro_etapa_2.Pessoal.DadosBasicos.DataNascimento.IsDate = true;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.DataNascimento.Empty = false;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.GrauDeInstrucao.Valor = this.formDadosPessoais.get('escolaridade_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.Nacionalidade.Codigo = this.formDadosPessoais.get('nacionalidade_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.Etnia.Descricao = this.formDadosPessoais.get('cor_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.Sexo.Valor = this.formDadosPessoais.get('sexo_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.EstadoCivil.Valor = this.formDadosPessoais.get('estado_civil_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.NomeMae = this.formDadosPessoais.get('mae_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.NomePai = this.formDadosPessoais.get('pai_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Cep = this.formDadosPessoais.get('cep_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Complemento = this.formDadosPessoais.get('complemento_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Logradouro = this.formDadosPessoais.get('logradouro_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Numero = this.formDadosPessoais.get('numero_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Bairro = this.formDadosPessoais.get('bairro_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Estado = this.formDadosPessoais.get('estado_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Cidade = this.formDadosPessoais.get('cidade_form').value;
    this.cadastro_etapa_2.Pessoal.Contato.DDDCelular = this.formDadosPessoais.get('celularP_form').value.substring(0, 2);
    this.cadastro_etapa_2.Pessoal.Contato.CelularP = this.formDadosPessoais.get('celularP_form').value.substring(2, 11);
    this.cadastro_etapa_2.Pessoal.Contato.CelularS = this.formDadosPessoais.get('celularS_form').value;
    this.cadastro_etapa_2.Pessoal.Contato.DDDTelefone = this.formDadosPessoais.get('telefone_form').value.substring(0, 2);
    this.cadastro_etapa_2.Pessoal.Contato.Telefone = this.formDadosPessoais.get('telefone_form').value.substring(2, 10);
    this.cadastro_etapa_2.Pessoal.Contato.Email1 = this.formDadosPessoais.get('email_form').value;
    this.cadastro_etapa_2.Pessoal.Contato.Email2 = this.formDadosPessoais.get('email2_form').value;
  }

  setarValoresEtapa3() {
    this.cadastro_etapa_3.Etapa = 3;
    //this.cadastro_etapa_3.Filial.Codigo = 100001;
    // this.cadastro_etapa_3.Documentos.Cpf = this.formDocumentos.get('cpf_form').value;
    this.cadastro_etapa_3.Documentos.Rg.Numero = this.formDocumentos.get('rg_form').value;
    this.cadastro_etapa_3.Documentos.Rg.DataEmissaoRg.SetData = this.converteDatas(this.formDocumentos.get('data_emissao_form').value, 0);
    this.cadastro_etapa_3.Documentos.Rg.DataEmissaoRg.ToDate = this.converteDatas(this.formDocumentos.get('data_emissao_form').value, 1);
    this.cadastro_etapa_3.Documentos.Rg.DataEmissaoRg.IsDate = true;
    this.cadastro_etapa_3.Documentos.Rg.DataEmissaoRg.Empty = false;
    this.cadastro_etapa_3.Documentos.Rg.OrgaoEmissor = this.formDocumentos.get('orgao_form').value;
    this.cadastro_etapa_3.Documentos.Rg.UfEmissaoRg = this.formDocumentos.get('uf_emissaor_form').value;
    this.cadastro_etapa_3.Documentos.Cnh.Numero = this.formDocumentos.get('cnh_form').value;
    this.cadastro_etapa_3.Documentos.Cnh.CategoriaCnh = this.formDocumentos.get('categoria_cnh_form').value;
    this.cadastro_etapa_3.Documentos.Cnh.UF = this.formDocumentos.get('uf_cnh_form').value;
    this.cadastro_etapa_3.Documentos.Cnh.DataValidadeCnh.SetData = this.converteDatas(this.formDocumentos.get('data_validade_form').value, 0);
    this.cadastro_etapa_3.Documentos.Cnh.DataValidadeCnh.ToDate = this.converteDatas(this.formDocumentos.get('data_validade_form').value, 1);
    this.cadastro_etapa_3.Documentos.Cnh.DataValidadeCnh.IsDate = true;
    this.cadastro_etapa_3.Documentos.Cnh.DataValidadeCnh.Empty = false;
    this.cadastro_etapa_3.Documentos.Clt.CarteiraDeTrabalho = this.formDocumentos.get('carteira_form').value;
    this.cadastro_etapa_3.Documentos.Clt.NumeroSerieCtps = this.formDocumentos.get('serie_form').value;
    this.cadastro_etapa_3.Documentos.Clt.Pis = this.formDocumentos.get('pis_form').value;
    this.cadastro_etapa_3.Documentos.Clt.UfCtps = this.formDocumentos.get('uf_ctps_form').value;
    this.cadastro_etapa_3.Documentos.TituloEleitor.Numero = this.formDocumentos.get('titulo_form').value;
    this.cadastro_etapa_3.Documentos.TituloEleitor.ZonaEleitoral = this.formDocumentos.get('zona_form').value;
    this.cadastro_etapa_3.Documentos.TituloEleitor.SecaoEleitoral = this.formDocumentos.get('secao_form').value;
    this.cadastro_etapa_3.DadosBancarios.Banco = this.formDocumentos.get('banco_form').value;
    this.cadastro_etapa_3.DadosBancarios.Agencia = this.formDocumentos.get('agencia_form').value;
    this.cadastro_etapa_3.DadosBancarios.Operacao = this.formDocumentos.get('operacao_form').value;
    this.cadastro_etapa_3.DadosBancarios.Conta = this.formDocumentos.get('conta_form').value;
  }


  // ***************************************************
  // MÃ©todos para validar os steps
  // ***************************************************


  PreviewStep() {
    if (this.step == 1) {
      this.formInformacaoBasica.get("cpf_form").disable()
      this.formInformacaoBasica.get("matricula").disable()
    }
    this.step = this.step - 1;
    window.scrollTo(0, 0);

  }

  NextStep() {

    if (this.step <= this.stepcount) {
      this.step = this.step + 1;
      window.scrollTo(0, 0);
    }

    if (this.stepcount < this.step) {
      this.stepcount = this.stepcount + 1;
    }

    // Verifica a etapa e chama o mÃ©todo recorrente
    this.validaMetodoEtapa(this.step);
  }
  inicioStep() {
    if (this.step > 0) {
      this.step = 0;

      window.scrollTo(0, 0);
    }
    this.step = this.step;
    this.insertInformacaoBasica();

  }
  primeiroStep() {
    if (this.step > 1 || this.stepcount > 1) {
      this.step = 1;
      this.clickStep = true;
      window.scrollTo(0, 0);

    }
  }
  segundoStep() {
    if (this.step > 2 || this.stepcount > 2) {
      this.step = 2
      this.clickStep = true;
      window.scrollTo(0, 0);
    }
  }



  // ***************************************************
  // MÃ©todos para a rotina dos retornos das funÃ§Ãµes
  // ***************************************************

  removerRepetido(dados) {
    var count = 0;
    var horas = [];

    dados.forEach(obj => {
      if (count != 0) {
        if (horas.indexOf(obj.CargaHorariaSemana) == -1) {
          horas.push(obj.CargaHorariaSemana);
          this.cargaHorariaFilter.push({ cod: obj.CargaHorariaSemana });
        }
      } else {
        horas.push(obj.CargaHorariaSemana);
        this.cargaHorariaFilter.push({ cod: obj.CargaHorariaSemana });
      }
      count++;
    });
  }


  buscarSalarioNivel(codigo): any {
    this.nivel.map(nivel => {
      if (nivel.Codigo == codigo) {
        this.cadastro_etapa_1.InformacoesBasicas.Salario.Valor = nivel.Valor
        this.cadastro_etapa_1.InformacoesBasicas.Salario.CodigoNivel = codigo
      }
    });
  }

  organizarCargo(codigo): any {
    this.cargos.map(cargo => {
      if (cargo.Codigo == codigo) {
        this.cadastro_etapa_1.InformacoesBasicas.Cargo.CBO = Number(cargo.CBO)
      }
    });
  }
  organizarCargaHoraria(codigo) {
    this.horarioTrabalhoFilter.map(horaTrabalho => {
      if (horaTrabalho.Codigo == codigo) {
        this.cadastro_etapa_1.InformacoesBasicas.HorarioTrabalho.Codigo = horaTrabalho.Codigo
        this.cadastro_etapa_1.InformacoesBasicas.HorarioTrabalho.HoraEntrada = horaTrabalho.HoraEntrada
        this.cadastro_etapa_1.InformacoesBasicas.HorarioTrabalho.HoraSaida = horaTrabalho.HoraSaida
        this.cadastro_etapa_1.InformacoesBasicas.HorarioTrabalho.InicioIntervalo = horaTrabalho.InicioIntervalo
        this.cadastro_etapa_1.InformacoesBasicas.HorarioTrabalho.TerminoIntervalo = horaTrabalho.TerminoIntervalo
        this.cadastro_etapa_1.InformacoesBasicas.HorarioTrabalho.CargaHorariaSemana = horaTrabalho.CargaHorariaSemana

      }
    });
  }



  buscarCodigoEstado(estado, uf): any {
    estado.some(obj => {
      if (obj.SiglaUf == uf) {
        this.codigo_uf = obj.Codigo;
        return true;
      }
    });
  }

  deletarIcon(indice) {
    let numeroIndex = indice
    this.urls.splice(numeroIndex, 1)
  }

  mascaraHora(val) {
    var hora = val.substring(0, 2);
    var minuto = val.substring(2, 4);
    return hora + ":" + minuto;
  }

  formatarNumero(numero) {
    let pt1 = numero.substring(0, 2);
    let pt2 = numero.substring(2, 6);
    let pt3 = numero.substring(6, 10);
    return `(${pt1}) ${pt2}-${pt3}`
  }

  formataReal(valor) {
    valor = String(valor);
    var salario = valor.indexOf(".");
    if (salario == -1) {
      salario = valor + ".00";
    }

    //Instanciando o objeto
    var formatter = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
    /*FORMATA CONFORME CONFIGURAÃÃES DEFINIDAS NO formatter*/
    salario = formatter.format(salario);
    //Coloca o valor formatado no campo resultado

    return salario;
  }

  verificaDados(dados, nome, etapa, tipo) {

    // 1 para data
    // 2 para cpf
    var data = dados.target.value;
    var elemento = nome;
    let aux = 0;
    // if(elemento === 'data_admissao_form'){
    //   if(!this.ValidarValoresDatas(data, 1)){
    //     aux = 1
    //   }
    // }else
    if (elemento === 'data_emissao_ctps_form' || elemento === 'data_emissao_form') {
      if (!this.ValidarValoresDatas(data, 2)) {
        aux = 1
      }
    }
    else if (elemento === 'data_nascimento_form') {
      if (!this.ValidarValoresDatas(data, 3)) {
        aux = 1
        // Swal(' Não é Possível cadastrar menores de 16 anos', "Por favor, preencha uma data válida.", 'warning');
      }
    } else if (elemento === 'data_validade_form') {
      if (!this.ValidarValoresDatas(data, 4)) {
        aux = 1
      }
    }

    if (tipo == 1) {
      if (!this.validacaoService.validarData(data) || aux === 1) {
        //Swal('Data invÃ¡lida!', "Por favor, preencha uma data vÃ¡lida.", 'warning');
        if (etapa == 1) {
          this.formInformacaoBasica.controls[elemento].setValue("");
        } else if (etapa == 2) {
          this.formDadosPessoais.controls[elemento].setValue("");
        } else if (etapa == 3) {
          this.formDocumentos.controls[elemento].setValue("");
        }
      }
    } else if (tipo == 2) {
      if (!this.validacaoService.validarCpf(data)) {
        // if(etapa == 1){
        //   this.formInformacaoBasica.controls[elemento].setValue("");
        // }else if(etapa == 2){
        //   this.formDadosPessoais.controls[elemento].setValue("");
        // }else if(etapa == 3){
        //   this.formDocumentos.controls[elemento].setValue("");
        // }
        this.validar_cpf_blur = true;
      } else {
        this.validar_cpf_blur = false;
      }
    } else if (tipo == 3) {
      if (!this.validacaoService.validarPis(data)) {
        // if(etapa == 1){
        //   this.formInformacaoBasica.controls[elemento].setValue("");
        // }else if(etapa == 2){
        //   this.formDadosPessoais.controls[elemento].setValue("");
        // }else if(etapa == 3){
        //   this.formDocumentos.controls[elemento].setValue("");
        // }
        this.validar_pis_blur = true;
      } else {
        this.validar_pis_blur = false;
      }
    }
  }


  converteDatas(data, tipo) {
    // 0 para converter em yyyymmdd
    // 1 para converter em dd/mm/yyyy

    var dia, mes, ano;
    var datas = data.split('-');
    dia = datas[2];
    mes = datas[1];
    ano = datas[0];

    if (tipo == 0) {
      return ano + mes + dia;
    } else {
      return dia + '/' + mes + '/' + ano;
    }
  }

  validarDataAtual(data) {


    let dateAtual: Date = new Date();

    var dia, mes, ano;
    var datas = data.split('-');

    var teste = 'T00:00:00'

    dia = datas[2];
    mes = datas[1];
    ano = datas[0];



    var time = new Date(ano + '-' + mes + '-' + dia + teste);

    if (dateAtual >= time) {

      return true;

    } else {

      return false
    }
  }

  verificarMenor(data: any) {
    if (!this.ValidarValoresDatas2(data.target.value.replaceAll("-", ""))) {
      this.formDadosPessoais.controls['data_nascimento_form'].setValue('');
    }
  }

  ValidarValoresDatas2(data) {
    var dia, mes, ano;
    var complementoData = 'T00:00:00'
    dia = data.substring(6, 8);
    mes = data.substring(4, 6);
    ano = data.substring(0, 4);
    let dateAtual: Date = new Date();
    var time = new Date(ano + '-' + mes + '-' + dia + complementoData);
    let auxTime = time.getFullYear()
    let auxDate = dateAtual.getFullYear()
    if (!this.validarData(dia + "/" + mes + "/" + ano)) {
      return true
    }
    if ((auxDate - this.idadeMinima) >= auxTime) {
      return true;
    } else {
      Swal('Não é Possível cadastrar menores de 16 anos', "Por favor, preencha uma data válida.", 'warning');
      return false
    }
  }
  validarData(data) {
    var reg = /[^\d\/\.]/gi;  // Mascara = dd/mm/aaaa | dd.mm.aaaa
    var valida = data.replace(reg, '');    // aplica mascara e valida sÃ³ numeros
    if (valida && valida.length == 10) {  // Ã© vÃ¡lida, entÃ£o ;)
      var ano = data.substr(6),
        mes = data.substr(3, 2),
        dia = data.substr(0, 2),
        M30 = ['04', '06', '09', '11'],
        v_mes = /(0[1-9])|(1[0-2])/.test(mes),
        v_ano = /(19[1-9]\d)|(20\d\d)|2100/.test(ano),
        rexpr = new RegExp(mes),
        fev29 = ano % 4 ? 28 : 29;

      if (v_mes && v_ano) {
        if (mes == '02') return (dia >= 1 && dia <= fev29);
        else if (rexpr.test(M30.toString())) return /((0[1-9])|([1-2]\d)|30)/.test(dia);
        else return /((0[1-9])|([1-2]\d)|3[0-1])/.test(dia);
      }
    }
    return false;
  }

  ValidarValoresDatas(data, tipo) {

    var dia, mes, ano;
    var teste = 'T00:00:00'

    dia = data.substring(0, 2);
    mes = data.substring(3, 5);
    ano = data.substring(6, 10);

    let dateAtual: Date = new Date();
    var time = new Date(ano + '-' + mes + '-' + dia + teste);

    let auxTime = time.getFullYear()
    let auxDate = dateAtual.getFullYear()
    if (!this.validacaoService.validarData(data)) {
      return true
    }

    if (tipo === 1) {
      if (dateAtual <= time) {
        return true;

      } else {
        new AlertaModal('alerta', 'Data de admissão incorreta!', "Porfavor, preencha uma data válida!.", 'p');

        return false
      }
    } else if (tipo === 2) {
      if (dateAtual >= time) {
        return true;

      } else {
        new AlertaModal('alerta', 'Data de emissão incorreta!', "Porfavor, preencha uma data válida!.", 'p');

        return false
      }
    } else if (tipo === 3) {
      if ((auxDate - this.idadeMinima) >= auxTime) {

        return true;

      } else {
        new AlertaModal('alerta', 'Não é possivel cadastrar menores de 14 anos!', "Porfavor, preencha uma data válida!.", 'p');
        return false
      }
    } else {
      if ((auxDate - 5) <= auxTime && (auxDate + 5) >= auxTime) {

        return true;

      } else {
        new AlertaModal('alerta', 'Validade não aceita!', "Porfavor, preencha uma data válida!.", 'p');

        return false
      }
    }
  }

  salarioMaior() { //verifica se o salario está maior do que o teto da categoria
    const salario = this.formInformacaoBasica.get('nivel_form').value;
    if (!this.nivel || this.nivel.length == 0) {
      return false;
    }
    const salariosNivel = this.nivel.filter(nivel => nivel.Valor < salario);
    if (salariosNivel.length == this.nivel.length) {
      return true;
    }
    return false;
  }

  sendEmail() {
    document.getElementById("btEnviarEmail").click();
    this.spinner.show()
    const EmailDestinatario = this.formEmail.value.email;
    const { NumeroDeOrdem } = this.cadastro_etapa_1;
    const Url = window.location.href.split('/')[2];

    this.cadastroService.gerarLink({ EmailDestinatario, NumeroDeOrdem, Url })
      .subscribe(
        data => {
          this.spinner.hide();
          new AlertaModal("sucesso", "Sucesso!", "Email com link para cadastro foi enviado.", "p");
          this.route.navigate(['home']);
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      )
    return false;
  }

  buscaMatricula(matricula): void {
    this.cadastroService.buscarColaboradorMatricula(matricula)
      .subscribe(
        () => {
          this.formInformacaoBasica.get('matricula').setValue('')
          Swal('Atenção!', 'Matrícula existente', 'warning')
          this.spinner.hide()
        },
        () => {

          this.spinner.hide()
        }
      );
  }
  somenteLetras(event) {
    return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode === 32))
  }
  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }
}
