import { TelefonesSecundarios } from '../../interfaces/TelefonesSecundarios';

export class Endereco {
  Logradouro: string;
  ComplementoEndereco: string;
  Numero: number | string;
  Bairro: string;
  Cidade: string = '';
  Uf: string = '';
  Cep: number | string;
  Estado?: string = '';
  Valido?: boolean;
  Telefones?: TelefonesSecundarios[];
}
