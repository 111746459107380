<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  name="email"
>
  <p style="font-size: 20px; color: white; z-index: 20000">Enviando...</p>
</ngx-spinner>
<div>
  <div *ngIf="!displayDocumento && !loading" class="col-12">
    <div class="d-flex justify-content-between">
      <p class="lead text-uppercase text-muted ml-3">Anexos</p>
    </div>
    <hr />
    <div class="tab-pane fade show d-flex justify-content-between" id="relatorio">
      <app-anexo
        [pagina]="'017'"
        [matricula]="cadastroUsuario.Matricula"
        [nomeUsuario]="cadastroUsuario.InformacoesBasicas.NomeCompleto"
        [enviarSeparadamente]="true"
        [perfil]="localStorage.TipoUsuario == '1' ? true : false"
        [emailColaborador]="cadastroUsuario.Pessoal.Contato.Email1"
        [anexoSexo]="anexoSexo"
        [anexoCarteira]="anexoCarteira"
        [anexoCasamento]="anexoCasamento"
        (listaPendencias)="emissaoProfile($event)"
      ></app-anexo>
      <div class="d-flex align-items-end">
        <button
          class="btn btn-secondary"
          (click)="abrirSolicitacaoAnexo()"
          style="margin-left: 10px"
          id="btEnviarEmail"
          data-toggle="modal"
          data-target="#modalEnviarEmail"
          *ngIf="tipoUsuario == '5' && existeCredencial"
        >
          Solicitar correção
        </button>
      </div>
    </div>
    <p *ngIf="!existeCredencial" class="text-center alert-warning p-2 mt-2 rounded">
      <i class="fa fa-info-circle fa-3x my-2"></i><br />
      Para solicitar correções nas documentações do colaborador, ele precisa ter credenciais válidas
      antes. Você pode criá-las no botão "Criar Credenciais" na seção superior da página.
    </p>
    <p
      *ngIf="anexosPendentes && anexosPendentes.length > 0 && localStorage.TipoUsuario != '1'"
      class="text-center alert-warning p-2 mt-2 rounded"
    >
      <i class="fa fa-exclamation-circle fa-3x my-2"></i><br />
      Existe(m) {{ anexosPendentes.length }} anexo(s) pendente(s) aguardando a atualização pelo
      colaborador. Você pode editar essa(s) pendência(s) e enviar um novo e-mail clicando no botão
      "Solicitar correção".
    </p>
  </div>
  <div
    class="modal fade"
    id="modalEnviarEmail"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" *ngIf="correcaoAnexo" style="padding: 30px">
        <form
          #email="ngForm"
          [formGroup]="formEmail"
          (ngSubmit)="salvarAnexosMarcados()"
          style="display: flex; flex-direction: column; gap: 15px"
        >
          <h5 class="text-start">
            Solicitar correção de anexo ao colaborador
            <!-- <button
            type="button"
            class="btn btn-sm btn-outline-primary px-1 py-0"
            data-toggle="popover"
            data-content="Caso o colaborador ainda tenha alguma pendência no documento, a respectiva opção ficará
          desabilitada até o devido ajuste"
          >
            <i class="fa fa-info"></i>
          </button> -->
          </h5>
          <button
            type="button"
            class="close position-absolute"
            data-dismiss="modal"
            aria-label="Fechar"
            title="Fechar"
            style="top: 1rem; right: 1rem; z-index: 2"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <table class="table table-striped">
            <tbody class="tabela-anexo">
              <tr>
                <th class="text-muted">
                  <input type="checkbox" formControlName="nomeacaoAnexo" />
                </th>
                <td><span>Nomeação</span></td>

                <th class="text-muted">
                  <input type="checkbox" formControlName="fotoAnexo" />
                </th>
                <td><span>Foto</span></td>
              </tr>
              <tr>
                <th class="text-muted">
                  <input type="checkbox" formControlName="identidadeAnexo" />
                </th>
                <td><span>Identidade</span></td>

                <th class="text-muted">
                  <input type="checkbox" formControlName="carteiraProfAnexo" />
                </th>
                <td><span>Carteira Profissional</span></td>
              </tr>
              <tr>
                <th class="text-muted">
                  <input type="checkbox" formControlName="cpfAnexo" />
                </th>
                <td><span>CPF</span></td>

                <th class="text-muted">
                  <input type="checkbox" formControlName="pisPasepAnexo" />
                </th>
                <td><span>PIS/Pasep</span></td>
              </tr>
              <tr>
                <th class="text-muted">
                  <input type="checkbox" formControlName="certificadoMilitarAnexo" />
                </th>
                <td>
                  <span>Certificado Militar</span>
                </td>

                <th class="text-muted">
                  <input type="checkbox" formControlName="carteiraDeMotoristaAnexo" />
                </th>
                <td>
                  <span>Carteira de Motorista</span>
                </td>
              </tr>
              <tr>
                <th class="text-muted">
                  <input type="checkbox" formControlName="certidaoDeNascimentoAnexo" />
                </th>
                <td><span>Certidão de Nascimento</span></td>

                <th class="text-muted">
                  <input type="checkbox" formControlName="certidaoDeCasamentoAnexo" />
                </th>
                <td>
                  <span>Certidão de Casamento</span>
                </td>
              </tr>
              <tr>
                <th class="text-muted">
                  <input type="checkbox" formControlName="comprovanteDeResidenciaAnexo" />
                </th>
                <td><span>Comprovante de Residência</span></td>
              </tr>
            </tbody>
          </table>
          <textarea
            class="form-control"
            formControlName="descEmail"
            placeholder="Escreva uma descrição personalizada"
          ></textarea>
          <button class="btn btn-primary" type="submit" [disabled]="formEmail.invalid">
            Enviar
          </button>
        </form>
      </div>
    </div>
  </div>

  <div *ngIf="loading" class="row d-flex justify-content-center align-items-center">
    <i class="fa fa-refresh fa-spin fa"></i>
  </div>
</div>
