import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { ElogioPenalidade } from './elogios-e-penalidades.component';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root'
})
export class ElogiosEPenalidadesService {
  
  localStorage: LocalStorage;
  url_acesso:string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  filial: string;
  sessao: string;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.url_acesso = this.acesso.validarUrl();
      this.sessao = this.localStorage.Sessao;
    }
   }

  inserirElogiosEPenalidades(elopen: ElogioPenalidade){
    return this.http.post(this.url_acesso + `elogios-penalidades/${this.filial}`, elopen, {headers:this.cabecalho});
  }

  obterElogiosEPenalidades(){
    return 0
  }

  obterUsuarioPorMatricula(matricula :number){
    return this.http.get(this.url_acesso + `elogios-penalidades/usuario/${matricula}/${this.filial}`,  {headers:this.cabecalho});


  }
  obterListaEloPen(matricula: number){
    return this.http.get(this.url_acesso + `elogios-penalidades/lista/${matricula}/${this.filial}`,  {headers:this.cabecalho});

  }
  removerElogioPenalidade(data: string, tipo: string, autor: string, matricula: number){
    

    return this.http.delete(this.url_acesso + `elogios-penalidades/${this.filial}/${data}/${tipo}/${autor}/${matricula}`,   {headers:this.cabecalho});

  }
  editarElogioPenalidade(epe: ElogioPenalidade){

    return this.http.put(this.url_acesso + `elogios-penalidades/${this.filial}`, epe,  {headers:this.cabecalho});

  }
  obterTiposEPE(){
    console.log(this.sessao);
    return this.http.get(this.url_acesso + `elogios-penalidades/tipos/${this.filial}`, {headers: this.cabecalho});
  }
  


}

