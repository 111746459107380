<!-- MODAL CADASTRO -->
<div class="modal-header">
  <h3 class="modal-title">Cadastro de Processo</h3>
  <button type="button" class="close" id="botaoFechar" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="parent.formProcessos" #processos="ngForm" class="modal-body row">
  <div class="col-md-6 form-group">
    <label class="font-weight-semibold">Assunto</label>
    <input
      class="form-control"
      type="text"
      formControlName="assunto"
      maxlength="80"
      placeholder="Assunto"
      [ngStyle]="
        parent.temErro && parent.formProcessos.controls['assunto'].hasError('required')
          ? { 'border-color': 'red' }
          : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="parent.temErro && parent.formProcessos.controls['assunto'].hasError('required')"
    >
      Campo obrigatório
    </span>
  </div>
  <div class="col-md-6 form-group">
    <label class="font-weight-semibold">Requerente</label>
    <input
      class="form-control"
      type="text"
      formControlName="requerente"
      maxlength="50"
      placeholder="Requerente"
      (blur)="parent.addRequerenteNome()"
      [ngStyle]="
        parent.temErro && parent.formProcessos.controls['requerente'].hasError('required')
          ? { 'border-color': 'red' }
          : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="parent.temErro && parent.formProcessos.controls['requerente'].hasError('required')"
    >
      Campo obrigatório
    </span>
  </div>
  <div class="col-md-4 form-group">
    <label class="font-weight-semibold">Número do Processo</label>
    <input
      mask="A*"
      class="form-control"
      type="text"
      formControlName="numeroProcesso"
      maxlength="30"
      placeholder="Número do Processo"
      [ngStyle]="
        parent.temErro && parent.formProcessos.controls['numeroProcesso'].hasError('required')
          ? { 'border-color': 'red' }
          : null
      "
      (blur)="parent.armazenarNumProcesso()"
    />
    <span
      class="form-text text-danger"
      *ngIf="parent.temErro && parent.formProcessos.controls['numeroProcesso'].hasError('required')"
    >
      Campo obrigatório
    </span>
  </div>
  <div class="col-md-4 form-group">
    <label class="font-weight-semibold">Situação</label>
    <select
      class="form-control"
      type="text"
      (change)="parent.onSituacaoChange()"
      formControlName="situacao"
      [ngStyle]="
        parent.temErro && parent.formProcessos.controls['situacao'].hasError('required')
          ? { 'border-color': 'red' }
          : null
      "
    >
      <option value="">Selecione...</option>
      <option *ngFor="let situacao of parent.listaSituacao" value="{{ situacao.Codigo }}">
        {{ situacao.Descricao }}
      </option>
    </select>
    <span
      class="form-text text-danger"
      *ngIf="parent.temErro && parent.formProcessos.controls['situacao'].hasError('required')"
    >
      Campo obrigatório
    </span>
  </div>
  <div class="col-md-4 form-group">
    <label class="font-weight-semibold">Data Início de Duração</label>
    <!-- <input class="form-control" type="text" (keyup)="verificaData(parent.formProcessos.get('dataInicio'), parent.formProcessos.get('dataFinal'))" formControlName="dataInicio" mask="00/00/0000" placeholder="dd/mm/aaaa" [ngStyle]="((parent.temErro || parent.formProcessos.controls['dataInicio'].dirty) && parent.formProcessos.controls['dataInicio'].status == 'INVALID') ? {'border-color':'red'} : ''"/> 				 -->
    <div class="input-group">
      <input
        type="date"
        class="form-control"
        id="dataInicio"
        max="9999-12-31"
        formControlName="dataInicio"
        style="padding-right: 0"
        [ngStyle]="
          parent.temErro &&
          (parent.formProcessos.controls['dataInicio'].hasError('required') ||
            parent.formProcessos.controls['dataInicio'].invalid)
            ? { 'border-color': 'red' }
            : null
        "
      />
    </div>
    <span
      class="form-text text-danger"
      *ngIf="
        parent.temErro &&
        (parent.formProcessos.controls['dataInicio'].hasError('required') ||
          parent.formProcessos.controls['dataInicio'].invalid)
      "
    >
      {{
        parent.formProcessos.controls['dataInicio'].hasError('required')
          ? 'Campo obrigatório'
          : 'Data inválida. Deve ser inferior ou igual à data atual.'
      }}
    </span>
  </div>
  <div class="col-md-4 form-group">
    <label class="font-weight-semibold">Data Final de Duração</label>
    <!-- <input class="form-control" type="text" (keyup)="verificaData(parent.formProcessos.get('dataInicio'), parent.formProcessos.get('dataFinal'))" formControlName="dataFinal" mask="00/00/0000" placeholder="dd/mm/aaaa" [ngStyle]="((parent.temErro || parent.formProcessos.controls['dataFinal'].dirty) && parent.formProcessos.controls['dataFinal'].status == 'INVALID') ? {'border-color':'red'} : ''"/> 				 -->
    <div class="input-group">
      <input
        type="date"
        class="form-control"
        id="dataFinal"
        max="9999-12-31"
        formControlName="dataFinal"
        style="padding-right: 0"
        [ngStyle]="
          parent.temErro &&
          (parent.formProcessos.controls['dataFinal'].hasError('required') ||
            parent.formProcessos.controls['dataFinal'].hasError('dataFimMenorQueInicial'))
            ? { 'border-color': 'red' }
            : null
        "
      />
    </div>
    <span
      class="form-text text-danger"
      *ngIf="parent.temErro && parent.formProcessos.controls['dataFinal'].hasError('required')"
    >
      Campo obrigatório
    </span>
    <span
      class="form-text text-danger"
      *ngIf="
        parent.temErro &&
        parent.formProcessos.controls['dataFinal'].hasError('dataFimMenorQueInicial')
      "
    >
      Data inválida. Deve ser posterior ou igual à data de início.
    </span>
  </div>
  <div *ngIf="parent.clientePublico" class="col-md-4 form-group">
    <label class="font-weight-semibold">Ato/Portaria <small>(opcional)</small></label>
    <input
      class="form-control"
      type="text"
      maxlength="50"
      formControlName="atoPortaria"
      placeholder="Ato/Portaria"
    />
  </div>
  <div *ngIf="parent.clientePublico" class="col-md-4 form-group">
    <label class="font-weight-semibold">Data Ato <small>(opcional)</small></label>
    <!-- <input class="form-control" type="text" formControlName="dataAto" mask="00/00/0000" placeholder="dd/mm/aaaa" [ngStyle]="((parent.temErro || parent.formProcessos.controls['dataAto'].dirty) && parent.formProcessos.controls['dataAto'].status == 'INVALID') ? {'border-color':'red'} : ''"/> 				 -->
    <div class="input-group">
      <input
        type="date"
        class="form-control"
        id="dataAto"
        max="9999-12-31"
        formControlName="dataAto"
        style="padding-right: 0"
        [ngStyle]="
          (parent.temErro && parent.formProcessos.controls['dataAto'].hasError('data')) ||
          (parent.formProcessos.controls['dataAto'].dirty &&
            !parent.formProcessos.controls['dataAto'].valid)
            ? { 'border-color': 'red' }
            : null
        "
      />
    </div>
    <span
      class="form-text text-danger"
      *ngIf="parent.formProcessos.controls['dataAto'].hasError('dataMenorQueAtual')"
    >
      Data inválida
    </span>
  </div>
  <div *ngIf="parent.clientePublico" class="col-md-4 form-group">
    <label class="font-weight-semibold">DOE <small>(opcional)</small></label>
    <input
      class="form-control"
      type="text"
      formControlName="doe"
      maxlength="50"
      placeholder="DOE"
    />
  </div>
  <div *ngIf="parent.clientePublico" class="col-md-4 form-group">
    <label class="font-weight-semibold">Data DOE <small>(opcional)</small></label>
    <!-- <input class="form-control" type="text" formControlName="dataDoe" mask="00/00/0000" placeholder="dd/mm/aaaa" [ngStyle]="((parent.temErro || parent.formProcessos.controls['dataDoe'].dirty) && parent.formProcessos.controls['dataDoe'].status == 'INVALID') ? {'border-color':'red'} : ''"/> 				 -->
    <div class="input-group">
      <input
        type="date"
        class="form-control"
        id="dataDoe"
        max="9999-12-31"
        formControlName="dataDoe"
        style="padding-right: 0"
        [ngStyle]="
          (parent.temErro && parent.formProcessos.controls['dataDoe'].hasError('required')) ||
          (parent.formProcessos.controls['dataDoe'].dirty &&
            !parent.formProcessos.controls['dataDoe'].valid)
            ? { 'border-color': 'red' }
            : null
        "
      />
    </div>
    <span
      class="form-text text-danger"
      *ngIf="parent.formProcessos.controls['dataDoe'].hasError('data')"
    >
      Data inválida
    </span>
  </div>
  <div class="col-md-12 form-group d-flex align-items-center">
    <label for="refFinanceiraCheckBox" class="font-weight-semibold m-0"
      >Referência Financeira <small>(opcional)</small></label
    >
    <input
      (change)="parent.onRefFinanceiraChange()"
      id="refFinanceiraCheckBox"
      class="ml-1"
      type="checkbox"
      formControlName="referenciaFinanceira"
      [ngStyle]="
        (parent.temErro || parent.formProcessos.controls['referenciaFinanceira'].dirty) &&
        parent.formProcessos.controls['referenciaFinanceira'].status == 'INVALID'
          ? { 'border-color': 'red' }
          : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="
        parent.temErro && parent.formProcessos.controls['referenciaFinanceira'].hasError('required')
      "
    >
      Campo obrigatório
    </span>
  </div>
  <div class="col-md-6 form-group" *ngIf="parent.formProcessos.value.referenciaFinanceira">
    <label class="font-weight-semibold">Tipo Reflexo</label>
    <select
      class="form-control"
      type="text"
      formControlName="tipoReflexo"
      (change)="parent.selecionarTipoReflexo()"
      [ngStyle]="
          (parent.formProcessos.controls['tipoReflexo'].touched &&
            !parent.formProcessos.controls['tipoReflexo'].valid)
            ? { 'border-color': 'red' }
            : null
        "
    >
      <option value="">Selecione...</option>
      <option *ngFor="let tipoReflexo of parent.listaTipoReflexo" value="{{tipoReflexo.Codigo}}">
        {{ tipoReflexo.Descricao }}
      </option>
    </select>
    <span
      class="form-text text-danger"
      *ngIf="
        parent.formProcessos.controls['tipoReflexo'].hasError('required') && 
        parent.formProcessos.controls['tipoReflexo'].touched
      "
    >
      Campo obrigatório
    </span>
  </div>
  <div class="col-md-6 form-group" *ngIf="parent.formProcessos.value.referenciaFinanceira">
    <label class="font-weight-semibold">EfeitoRetroativo</label>
    <select
      class="form-control"
      type="text"
      formControlName="efeitoRetroativo"
      
      (change)="parent.efeitoRetroativoChange($event)"
      [ngStyle]="
          (parent.formProcessos.controls['efeitoRetroativo'].touched &&
            !parent.formProcessos.controls['efeitoRetroativo'].valid)
            ? { 'border-color': 'red' }
            : null
        "
      
    >
      <option value="">Selecione...</option>
      <option>SIM</option>
      <option>NAO</option>
    </select>
    <span
      class="form-text text-danger"
      *ngIf="
        parent.formProcessos.controls['efeitoRetroativo'].hasError('required') && 
        parent.formProcessos.controls['efeitoRetroativo'].touched
      "
    >
      Campo obrigatório
    </span>
   
  </div>
  <!-- datas comentadas pois deveria usar data normal do processo -->
  <div class="col-md-4 form-group" *ngIf="parent.formProcessos.value.referenciaFinanceira">
    <label class="font-weight-semibold">Data Início do Reflexo</label>
    <div class="input-group">
      <input
      
        type="date"
        max="9999-12-31"
        class="form-control"
        id="dataInicioReflexo"
        formControlName="dataInicioReflexo"
        style="padding-right: 0"
        [ngStyle]="
          (parent.formProcessos.controls['dataInicioReflexo'].touched &&
            !parent.formProcessos.controls['dataInicioReflexo'].valid)
            ? { 'border-color': 'red' }
            : null
        "
      />
    </div>
    <span
      class="form-text text-danger"
      *ngIf="
        parent.formProcessos.controls['dataInicioReflexo'].hasError('required') && 
        parent.formProcessos.controls['dataInicioReflexo'].touched
      "
    >
      Campo obrigatório
    </span>
    <span
    class="form-text text-danger"
    *ngIf="parent.formProcessos.controls['dataInicioReflexo'].hasError('dataInvalida')"
  >
   Data inválida. A data deve ser até ontem ou anterior.
  </span>
  </div>
  <div class="col-md-4 form-group" *ngIf="parent.formProcessos.value.referenciaFinanceira">
    <label class="font-weight-semibold">Data Final do Reflexo</label>
    <div class="input-group">
      <input
      
        type="date"
        max="9999-12-31"
        class="form-control"
        id="dataFinalReflexo"
        formControlName="dataFinalReflexo"
        style="padding-right: 0"
        [ngStyle]="
          parent.temErro &&
            (parent.formProcessos.controls['dataFinalReflexo'].hasError('required')) ||
          parent.formProcessos.controls['dataFinalReflexo'].hasError('dataFimMenorQueInicial')
            ? { 'border-color': 'red' }
            : null
        "
      />
    </div>
    <span
      class="form-text text-danger"
      *ngIf="
        parent.temErro && parent.formProcessos.controls['dataFinalReflexo'].hasError('required')
      "
    >
      Campo obrigatório
    </span>
    <span
      class="form-text text-danger"
      *ngIf="
      
      parent.formProcessos.controls['dataFinalReflexo'].hasError('dataFimMenorQueInicial')"
    >
    Data inválida. Deve ser posterior ou igual à data de início.
    </span>
  </div>
</form>
<div class="modal-footer text-right">
  <ng-container *ngIf="!parent.editar">
    <ng-container *ngIf="parent.formProcessos.dirty">
      <button class="btn btn-danger" (click)="parent.limparCampos()" type="button">
        {{ parent.editar ? 'Cancelar' : 'Limpar' }}
      </button>
    </ng-container>
    <button class="btn btn-primary ml-2" (click)="parent.onSubmit(processos)" type="button">
      Salvar
    </button>
  </ng-container>

  <ng-container *ngIf="parent.editar">
    <ng-container *ngIf="parent.formProcessos.dirty">
      <button class="btn btn-danger" (click)="parent.limparCampos()" type="button">Cancelar</button>
    </ng-container>
    <button
      *ngIf="
        parent.processoSelecionado.Situacao != '3' ||
        (parent.processoSelecionado.Situacao == '3' &&
          parent.permissoesService.procurarPorPermissao('Deferido'))
      "
      class="btn btn-primary ml-2"
      (click)="parent.onSubmit(processos)"
      type="button"
    >
      Salvar
    </button>
  </ng-container>
</div>
