import { RegraBeneficio } from './classe/Regrabeneficio';
import { AcessoService } from './../../../../service/acesso/acesso.service';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';


@Injectable({
  providedIn: 'root'
})
export class EdicaoBeneficioService {

  localStorage: LocalStorage;
  url_acesso:string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  filial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) { 
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.url_acesso = this.acesso.validarUrl();
    }
  }

  buscarBeneficios(tipoProcessamento : string){
    let filtro = null
    return this.http.post(`${this.url_acesso}beneficios/000000000/${tipoProcessamento}/${this.filial}`, filtro, {headers:this.cabecalho})
  }
  buscarBeneficios1(tipoProcessamento : string){
    let filtro = null
    return this.http.get(`${this.url_acesso}beneficioscombo/000000000/${tipoProcessamento}/${this.filial}`, {headers:this.cabecalho})
  }
  buscarBeneficiosDoColaborador(tipoProcessamento : string, matricula,){
    return this.http.get(`${this.url_acesso}beneficios/colaboradordependente/1234/${tipoProcessamento}/${this.filial}/${matricula}/F`, {headers:this.cabecalho})
  }
  buscarBeneficiosDoDependente(tipoProcessamento : string, matricula,){
    return this.http.get(`${this.url_acesso}beneficios/colaboradordependente/1234/${tipoProcessamento}/${this.filial}/${matricula}/D`, {headers:this.cabecalho})
  }

  buscarDependentes(chave: any, tipoprocessamento: string){
    return this.http.post(`${this.url_acesso}beneficios/dependentesbeneficiario/000000000/${tipoprocessamento}/${this.filial}`, chave, {headers: this.cabecalho})
  }
  
  salvarbeneficio(beneficio : RegraBeneficio, tipoprocessamento: string){
    return this.http.put(`${this.url_acesso}beneficios/inserirregrabeneficio/000000000/${tipoprocessamento}/${this.filial}`, beneficio, {headers: this.cabecalho}) 
	}

	listarRegrabeneficio(chave: any, tipoprocessamento: string ){ 
		return this.http.post(`${this.url_acesso}beneficio/listarregrabeneficio/000000000/${tipoprocessamento}/${this.filial}`, chave, {headers: this.cabecalho}) 
	}

	excluirBeneficio(tipoprocessamento: string, beneficiario :any, beneficio, chaveparte1 :string, chaveparte2 : string, tipo : number){
		return this.http.delete(`${this.url_acesso}beneficio/excluirregrabeneficio/000000000/${tipoprocessamento}/${this.filial}/${beneficiario.NumeroDeOrdem}/${tipo}/${beneficio.Codigo}/${chaveparte1}/${chaveparte2}`, {headers: this.cabecalho} )
	}
}
