import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.css']
})
export class ScrollTopComponent {
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (window.innerHeight < 650) this.showScrollButton = window.scrollY > window.innerHeight * 0.7;
    else this.showScrollButton = window.scrollY > (window.innerHeight / 4);
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }  
  showScrollButton = false;

}
