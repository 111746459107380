import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { BancosLogicos } from './manutencao-bd-logicos.component';

@Injectable({
  providedIn: 'root'
})
export class BancosLogicosService {
  localStorage: LocalStorage = new LocalStorage();
  url_acesso:string ='';
  headers = new HttpHeaders();
  cabecalho: any = null;
  tpProcessamento: string = "EM";
  filialAtual: string ='';

  constructor(private http: HttpClient, private acesso: AcessoService) { 
    this.url_acesso = this.acesso.validarUrl();
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filialAtual = this.localStorage.FilialAtual.toString(); 
    }
  }

  buscarServicosPse(){
    return this.http.get(this.url_acesso + `pse/servicos`, {headers:this.cabecalho});
  }

  buscarBancosLogicos(codigoServico: string) {
    return this.http.get(this.url_acesso + `pse/bancos-logicos/${codigoServico}`, { headers: this.cabecalho });
  }

  salvarBdadosLogico(bancoLogico: BancosLogicos){
    return this.http.post(`${this.url_acesso}pse/bancos-logicos`, bancoLogico, { headers: this.cabecalho });
  }

  alterarBancoLogico(bancoLogico: BancosLogicos) {
    return this.http.put(`${this.url_acesso}pse/bancos-fisicos`, bancoLogico, { headers: this.cabecalho });
  }

  deletarBancoLogico(codigoServico: string, codigoBanco: string) {
    return this.http.delete(this.url_acesso + `pse/bancos-logicos/${codigoServico}/${codigoBanco}`, { headers: this.cabecalho });
  }

  buscarBancosFisicos(codigoServico: string) {
    return this.http.get(this.url_acesso + `pse/bancos-fisicos/${codigoServico}`, { headers: this.cabecalho });
  }
}