import { Stiuacao } from './stiuacao';
import { InformacoesBasicas } from './InformacoesBasicas/InformacoesBasicas';
import { Pessoal } from './Pessoal';
import { Filial } from './Filial/filial';
import { Documentos } from './Documentos';
import { DadosBancarios } from './DadosBancarios';
import { Ferias } from './Ferias';
import { ComplementoAsa } from './ComplementoAsa';

export class Cadastro {
  NumeroDeOrdem: string;
  ColaboradorInativo?: boolean;
  Etapa: number;
  Matricula: string;
  DigitoMatricula: string;
  ColaboradorRescindido?: boolean;
  PermiteLancamento?: boolean;
  Stiuacao: Stiuacao = new Stiuacao();
  Filial: Filial = new Filial();
  InformacoesBasicas: InformacoesBasicas = new InformacoesBasicas();
  Pessoal: Pessoal = new Pessoal();
  Documentos: Documentos = new Documentos();
  DadosBancarios: DadosBancarios = new DadosBancarios();
  Ferias: Ferias = new Ferias();
  ComplementoAsa: ComplementoAsa = new ComplementoAsa();
  UploadDocumentos?: any[] = [];
  CargoEfetivo: string;
  DescricaoCargoEfetivo: string;
  Nivel: string;
  DepSalarioFamilia: string;
  DepImpostoRenda: string;
  constructor() {}
}
