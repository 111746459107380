<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<!-- FORM COMORBIDADE -->
<div class="card">
    <div class="card-header">
        <h3>Saúde</h3>
    </div>
    <div class="card-body">
        <form [formGroup]="formSaude">
            <div class="container">
                <div class="row mb-4">
                    <div class="col-6">
                        <div>
                            <label for="tipoDeficiencia">Tipo de comorbidade</label>
                            <select name="tipoDeficiencia" class="form-control" formControlName="tipoDeficiencia">
                                <option value="">selecione...</option>
                                <option *ngFor="let rebilit of listaTipoDeficiencia" value={{rebilit.Codigo}}>
                                    {{rebilit.Descricao}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-3">
                        <div>
                            <label for="reabilitado">Reabilitado</label>
                            <select name="reabilitado" class="form-control" formControlName="reabilitado">
                                <option value="">selecione...</option>
                                <option *ngFor="let rebilit of listaSimOuNao" value="{{rebilit}}">
                                    {{rebilit?"Sim":"Não"}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-3">
                        <div>
                            <label for="readaptado">Readaptado</label>
                            <select name="readaptado" class="form-control" formControlName="readaptado">
                                <option value="">selecione...</option>
                                <option *ngFor="let readapt of listaSimOuNao" value="{{readapt}}">
                                    {{readapt?"Sim":"Não"}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-4">
                        <div>
                            <div _ngcontent-uxj-c4="" class="form-group">
                                <label for="dataInicial">Data inicial <small>(opcional)</small></label>
                                <input type="date" class="form-control" name="dataInicial" style="padding-right: 0;"
                                    formControlName="dataInicial">
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div>
                            <div _ngcontent-uxj-c4="" class="form-group">
                                <label for="dataFinal" >Data final <small>(opcional)</small></label>
                                <input type="date" class="form-control" name="dataFinal" style="padding-right: 0;"
                                    formControlName="dataFinal" (change)="validarDataFinal()">
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div>
                            <label for="preencherCota">Preencher cota</label>
                            <select name="preencherCota" class="form-control" formControlName="preencherCota">
                                <option value="">selecione...</option>
                                <option *ngFor="let cota of listaSimOuNao" value="{{cota}}">
                                    {{cota?"Sim":"Não"}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <label for="descricaoComorbidade">Descritivo da comorbidade</label>
                        <textarea type="text" name="descricaoComorbidade" class="form-control"
                            formControlName="descricaoComorbidade">
                        </textarea>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="card-footer">
        <div class="row d-flex flex-row-reverse">
            <button *ngIf="!editandoSaude" type="button" class="btn btn-primary ml-2" (click)="salvarSaude()">Salvar</button>
            <button *ngIf="editandoSaude" type="button" class="btn btn-primary ml-2" (click)="alteraSaude()">Editar</button>
            <button *ngIf="habilitarLimparSaude()" type="button" class="btn btn-danger mr-2"
                (click)="limparCamposSaude()">Limpar</button>
        </div>
    </div>
</div>

<!-- LISTA COMORBIDADE -->

<div class="card">
    <div class="card-header">
        <div class="form-group header-elements-inline">
            <h3 class="card-title"> Lista de Comorbidades</h3>
            <div class="header-elements">
            </div>
        </div>
    </div>
    <div class="card-body">
        <div class="form-group row">
            <div class="input-group col-lg-12">
                <input type="text" class="form-control" name="searchText" value="" placeholder="Pesquisar Comorbidade">
                <span class="input-group-append">
                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                </span>
            </div>
        </div>
        <div class="row align-baseline">
            <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 form-group">
                <h5 class="font-weight-semibold">Comorbidades Encontradas </h5>
            </div>
        </div>
        <div class="form-group row d-none d-md-block ">
            <div *ngIf="listaComorbidade.length == 0" class="col-12">
                <h4 class="d-flex justify-content-center">Nenhuma comorbidade encontrada</h4>
            </div>

            <div *ngIf="listaComorbidade.length != 0" class="col-lg-12 form-group text-center">
                <div class="table-responsive" style="max-height:45vh">
                    <table class="table table-striped table-hover">
                        <thead class="text-start">
                            <tr class="text-start" style="white-space:nowrap">
                                <th class="sticky-top  bg-white">Comorbidade </th>
                                <th class="sticky-top  bg-white">Data Inicial</th>
                                <th class="sticky-top  bg-white">Data Final</th>
                                <th class="sticky-top  bg-white">Excluir/Editar</th>
                            </tr>
                        </thead>
                        <tbody id="lista-processos">
                            <tr *ngFor="let comorbidade of listaComorbidade" class="text-start"
                                style="white-space:nowrap">
                                <td>{{comorbidade.TipoDeficiencia.Descricao}}</td>
                                <td>{{formatarDataLista(comorbidade.DataInicial,false)}}</td>
                                <td>{{formatarDataLista(comorbidade.DataFinal,false)}}</td>
                                <td>
                                    <div>
                                        <button class="btn btn-danger mr-2"(click)="deletarComorbidade(comorbidade.TipoDeficiencia.Codigo,this.rotaMatricula)">
                                            <i class="fa fa-trash"></i>
                                        </button>
                                        <button class="btn bg-teal-400" (click)="editarSaude(comorbidade)">
                                            <i class="fa fa-pencil"></i>
                                        </button>
                                    </div>
                                </td>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>