<!-- SPINNER -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<!-- BUSCAR COLABORADOR -->
<div [ngStyle]="{'display': !telaTransferencia ? 'flex' : 'none'}" class="content justify-content-center align-items-center">
  <form class="login-form" [formGroup]="formBuscarColaborador" #telainicial="ngForm">
    <div class="card  bg-dark mb-0 text-ligth">
      <div class="card-body">
        <div class="text-center mb-3">
          <h5 class="mb-0">Buscar Colaborador</h5>
        </div>
        <div class="s-12 col-sm-12 col-md-12 col-lg-12 form-group">
          <label class="justify-content-start">Matrícula</label>
          <div class="input-group col-md-12">
            <input class="form-control" mask="0*" formControlName="matricula" [maxLength]="9" placeholder="Matrícula"/>
            <div class="input-group-append">
              <button class="btn bg-primary border-primary" (click)="buscarCadastroPorMatricula()">
                <i class="fa fa-search"></i>
              </button>
            </div>

            <!-- NOME COLABORADOR E ÍCONE -->
            <div *ngIf="cadastroUsuario.Matricula" class="col-md-12 justify-content-center w-80 rounded-top rounded-bottom pt-2" [ngStyle]="{'background-color': '#728098'}" >
              <div class="row ">
                <div class="col-4"></div> 
                <div class="col-6"><img style="width: 5em"
                  src="{{cadastroUsuario.Pessoal.DadosBasicos.Sexo.Valor ? 'assets/img/avatar-masculino.svg' : 'assets/img/avatar-feminino.svg'}}" width="30" height="40" alt="">
                </div>
                <div class="col-4"></div>
              </div>
              <div class="row pt-2">
                <div class="col-1"></div>
                <div class="col-10 text-center"><h4 class="h3  pb-2" style="font-size: 15px;">{{cadastroUsuario.InformacoesBasicas.NomeCompleto}}</h4></div>
              </div>
            </div>
          </div>
          <span class="form-text text-danger" *ngIf="temErro && formBuscarColaborador.controls['matricula'].hasError('required')">
            Campo Obrigatório
          </span>
        </div>

        <div class=" text-center" *ngIf="exibirContinuar">
          <button class="btn btn-primary" (click)="avancarFormTransferencia()" >Continuar</button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="card" [ngStyle]="{'display': telaTransferencia ? '' : 'none'}">
  <!-- CABEÇALHO - HEADER -->
  <div class="card-header header-elements-inline">
    <h3 class="card-title"><button class="btn" (click)="voltarTelaBuscarColaborador()"><i class="fa fa-arrow-left" aria-hidden="true" style="color: black;"></i></button>Transferência de Colaborador</h3>
  </div>

  <form #transferencia="ngForm" [formGroup]="formTransferencia">
    <div class="card-body" *ngIf="cadastroUsuario.Matricula">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <!-- MATRÍCULA -->
            <div *ngIf="cadastroUsuario.Matricula" class="form-group col-md-3">
              <label> Matrícula</label>
              <input type="number"  name="matriculaOrigem" formControlName="matriculaOrigem"  class="form-control" autocomplete="off" required/>
            </div>

            <!-- NOME COLABORADOR -->
            <div *ngIf="cadastroUsuario.Matricula" class="form-group col-md-9">
              <label>Nome</label>
              <input type="text" name="matriculaOrigem" formControlName="nomeOrigem" class="form-control" autocomplete="off" required/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body">

      <div class="col-lg-12 form-group">
        <h5 >Destino</h5>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <!-- EMPRESA FILIAL DE DESTINO -->
            <div class="form-group col-md-4">
              <label>Empresa Filial</label>
              <select [ngStyle]="temErro && formTransferencia.controls['empfilDestino'].hasError('required') || formTransferencia.controls['empfilDestino'].dirty && !formTransferencia.controls['empfilDestino'].valid ? {'border-color':'red'} : null"
              class="form-control" formControlName="empfilDestino" name="empfilDestino" (change)="buscarLotacao()" required>
                  <option value="">Selecione...</option>
                  <option *ngFor="let fil of filiais" value="{{fil.Codigo}}">{{fil.Nome}}</option>
              </select>
              <span class="form-text text-danger"
                *ngIf="temErro && formTransferencia.controls['empfilDestino'].hasError('required') || formTransferencia.controls['empfilDestino'].dirty && !formTransferencia.controls['empfilDestino'].valid">
                Campo obrigatório
              </span>
            </div>

            <!-- LOTAÇÃO -->
            <div class="form-group col-md-3">
              <label>Lotação</label>
              <select class="form-control" formControlName="lotacaoDestino" name="lotacaoDestino"
              [ngStyle]="temErro && formTransferencia.controls['lotacaoDestino'].hasError('required') || formTransferencia.controls['lotacaoDestino'].dirty && !formTransferencia.controls['lotacaoDestino'].valid ? {'border-color':'red'} : null">
                <option value="">Selecione...</option>
                <option *ngFor="let lotacao of departamentos" value="{{lotacao.CodLot}}">{{lotacao.Nome}}</option>
              </select>
              <span class="form-text" *ngIf="spinner_pesquisa_lotacao">Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i></span>
              <span class="form-text text-danger"
                *ngIf="temErro && formTransferencia.controls['lotacaoDestino'].hasError('required') || formTransferencia.controls['lotacaoDestino'].dirty && !formTransferencia.controls['lotacaoDestino'].valid">
                Campo obrigatório
              </span>
            </div>

            <!-- DATA DO EVENTO -->
            <div class="form-group">
              <label>Data</label>
              <div class="input-group">
                <input max="9999-12-31" type="date" class="form-control" id="dataEventoDestino" formControlName="dataEventoDestino" [ngStyle]="temErro && formTransferencia.controls['dataEventoDestino'].hasError('required') || formTransferencia.controls['dataEventoDestino'].dirty && !formTransferencia.controls['dataEventoDestino'].valid ? {'border-color':'red'} : null">
                <!-- <span class="input-group-prepend">
                  <span class="input-group-text"><i class="icon-calendar5"></i></span>
                </span>
                <input type="text" class="form-control pickadate" id="dataEventoDestino" formControlName="dataEventoDestino" placeholder="Data transferência"
                [ngStyle]="temErro && formTransferencia.controls['dataEventoDestino'].hasError('required') || formTransferencia.controls['dataEventoDestino'].dirty && !formTransferencia.controls['dataEventoDestino'].valid ? {'border-color':'red'} : null"> -->
              </div>
              <span class="form-text text-danger" *ngIf="temErro && formTransferencia.controls['dataEventoDestino'].hasError('required') || formTransferencia.controls['dataEventoDestino'].dirty && !formTransferencia.controls['dataEventoDestino'].valid">Campo obrigatório</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BOTÕES LIMPAR E TRANSFERIR -->
    <div class="card-footer text-right">
      <button class="btn btn-danger mr-2" *ngIf="formTransferencia.dirty || formTransferencia.valid" type="button" (click)="limparFormulario()"><i class="ml-2"></i>Limpar<i class="mr-2"></i></button>
      <button class="btn btn-primary" type="button" [disabled]="!formTransferencia.valid" (click)=" transferir()"><!-- <i class="fa fa-exchange mr-2"></i> -->Transferir</button>
    </div>
  </form>
</div>
<div [ngStyle]="{'height':'200px'}"></div>
