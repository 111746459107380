<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<div class="card">
  <div class="card-header">
    <h3 class="font-weight-semibold">Contrato de Experiência</h3>
  </div>
  <form #contrato="ngForm" [formGroup]="formContrato" (ngSubmit)="submitContrato()">
    <div class="card-body">
      <div class="row">
        <div class="col-md-4 form-group">
          <label class="font-weight-semibold">Setor</label>
          <select
            class="form-control"
            formControlName="contratoSetor"
            [ngClass]="
              formContrato.get('contratoSetor').invalid &&
              (temErro || formContrato.get('contratoSetor').touched)
                ? 'is-invalid'
                : null
            "
          >
            <option value="">Selecione...</option>
            <option *ngFor="let setor of listaSetor" value="{{ setor.Codigo }}">
              {{ setor.Descricao }}
            </option>
          </select>
          <span
            class="form-text text-danger"
            *ngIf="
              formContrato.get('contratoSetor').invalid &&
              (temErro || formContrato.get('contratoSetor').touched)
            "
          >
            Campo Obrigatório
          </span>
        </div>

        <div class="col-md-4 form-group">
          <label class="font-weight-semibold">Cargo</label>
          <select
            name=""
            class="form-control"
            formControlName="contratoCargo"
            (change)="onNivelChange()"
            [ngClass]="
              formContrato.get('contratoCargo').invalid &&
              (temErro || formContrato.get('contratoCargo').touched)
                ? 'is-invalid'
                : null
            "
          >
            <option value="">Selecione...</option>
            <option *ngFor="let cargos of listaCargo" value="{{ cargos.Codigo }}">
              {{ cargos.Descricao }}
            </option>
          </select>
          <span
            class="form-text text-danger"
            *ngIf="
              formContrato.get('contratoCargo').invalid &&
              (temErro || formContrato.get('contratoCargo').touched)
            "
          >
            Campo Obrigatório
          </span>
        </div>

        <div class="col-md-4 form-group">
          <label class="font-weight-semibold">Nível</label>
          <select
            class="form-control"
            formControlName="contratoNivel"
            [ngClass]="
              formContrato.get('contratoNivel').invalid &&
              (temErro || formContrato.get('contratoNivel').touched)
                ? 'is-invalid'
                : null
            "
          >
            <option value="">Selecione...</option>
            <option *ngFor="let nivel of listaNivel" value="{{ nivel.Codigo }}">
              {{ nivel.Nome }}
            </option>
          </select>
          <span
            class="form-text text-danger"
            *ngIf="
              formContrato.get('contratoNivel').invalid &&
              (temErro || formContrato.get('contratoNivel').touched)
            "
          >
            Campo Obrigatório
          </span>
          <span class="form-text" *ngIf="loadingCargo"
            >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
          ></span>
        </div>

        <div class="col-md-4 form-group">
          <label class="font-weight-semibold">Data Inicial</label>
          <!-- (change)="verificaData('contratoDataInicial', 'contratoDataFinal')" -->
          <input
            (keyup)="gerarDataFinal()"
            type="date"
            max="9999-12-31"
            class="form-control pr-0"
            formControlName="contratoDataInicial"
            [ngClass]="
              formContrato.get('contratoDataInicial').invalid &&
              (temErro || formContrato.get('contratoDataInicial').touched)
                ? 'is-invalid'
                : null
            "
          />
          <span
            class="form-text text-danger"
            *ngIf="
              formContrato.get('contratoDataInicial').invalid &&
              (temErro || formContrato.get('contratoDataInicial').touched)
            "
          >
            {{
              formContrato.get('contratoDataInicial').hasError('dataMenorQueAtual')
                ? 'Data Inválida'
                : 'Campo Obrigatório'
            }}
          </span>
        </div>

        <div class="col-md-4 form-group">
          <label class="font-weight-semibold">Dias</label>
          <!-- <input
            (keyup)="gerarDataFinal()"
            type="text"
            placeholder="Dias"
            mask="0*"
            class="form-control"
            formControlName="contratoDias"
            [ngClass]="
              formContrato.get('contratoDias').invalid &&
              (temErro || formContrato.get('contratoDias').touched)
                ? 'is-invalid'
                : null
            "
          /> -->
          <select
            (change)="gerarDataFinal()"
            class="form-control"
            formControlName="contratoDias"
            [ngClass]="
              formContrato.get('contratoDias').invalid &&
              (temErro || formContrato.get('contratoDias').touched)
                ? 'is-invalid'
                : null
            "
          >
            <option value="">Selecione...</option>
            <option *ngFor="let dias of listaDias" value="{{ dias }}">
              {{ dias }}
            </option>
          </select>
          <span
            class="form-text text-danger"
            *ngIf="
              formContrato.get('contratoDias').invalid &&
              (temErro || formContrato.get('contratoDias').touched)
            "
          >
            Campo Obrigatório
          </span>
        </div>

        <div class="col-md-4 form-group">
          <label class="font-weight-semibold">Data Final</label>
          <input
            type="date"
            [readonly]="editar"
            class="form-control"
            max="9999-12-31"
            formControlName="contratoDataFinal"
            (change)="verificaData('contratoDataInicial', 'contratoDataFinal')"
            [ngClass]="
              formContrato.get('contratoDataFinal').invalid &&
              (temErro || formContrato.get('contratoDataFinal').touched)
                ? 'is-invalid'
                : null
            "
            style="padding-right: 0"
          />
          <span
            class="form-text text-danger"
            *ngIf="
              formContrato.get('contratoDataFinal').invalid &&
              (temErro || formContrato.get('contratoDataFinal').touched)
            "
          >
            {{
              formContrato.get('contratoDataFinal').hasError('data')
                ? 'Data Inválida'
                : 'Campo Obrigatório'
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="card-footer text-right">
      <button (click)="voltarBusca()" class="btn btn-danger mr-2">
        {{ visualizacao ? 'Voltar' : 'Cancelar' }}
      </button>
      <button type="submit" class="btn btn-primary">
        {{ editar ? 'Atualizar' : 'Submeter' }} <i class="fa fa-paper-plane ml-2"></i>
      </button>
    </div>
  </form>
</div>
