import { ValidatorFn, ValidationErrors, FormGroup } from '@angular/forms';
//Validar se a data final é maior ou igual a data inicial
//Exemplo de uso:
// this.form = this.fb.group({
//   dataInicio: ['', Validators.required],
//   dataFim: ['', [Validators.required],
// }, { validators: validarDataFimMaiorIgual('dataInicio', 'dataFim') }
// );
export function validarDataFimMaiorIgual(
  controleInicial: string,
  controleFinal: string
): ValidatorFn {
  return (group: FormGroup): ValidationErrors | null => {
    const control1 = group.controls[controleInicial]?.value; // Valor do campo a ser validado
    const control2 = group.controls[controleFinal]?.value; // Valor do campo a ser validado
    const dataIni = new Date(control1);
    const dataFim = new Date(control2);

    if (!control1 || !control2) {
      return null;
    }
    const controlErrors = group.controls[controleFinal].errors;

    if (dataFim < dataIni) {
      group.controls[controleFinal].setErrors({ ...controlErrors, dataFimMenorQueInicial: true });
    } else if (!controlErrors) {
      group.controls[controleFinal].setErrors(null);
      return null;
    } else {
      delete controlErrors['dataFimMenorQueInicial'];
      group.controls[controleFinal].setErrors(controlErrors);
    }
  };
}
