import { Data } from 'src/app/service/interfaces/Data';
import { Sexo } from './DadosBasicos/Sexo';
import { Etnia } from './DadosBasicos/Etnia';
import { Nacionalidade } from './DadosBasicos/Nacionalidade';
import { EstadoCivil } from './DadosBasicos/EstadoCivil';
import { GrauDeInstrucao } from './DadosBasicos/GrauDeInstrucao';
import { Naturalidade } from './DadosBasicos/Naturalidade';
import { ParametrosClass } from './ParametrosClass';

export class DadosBasicos {
  DataNascimento: Data = new Data();
  GrauDeInstrucao: GrauDeInstrucao = new GrauDeInstrucao();
  Nacionalidade: Nacionalidade = new Nacionalidade();
  Naturalidade: Naturalidade = new Naturalidade();
  Etnia: ParametrosClass = new ParametrosClass();
  Sexo: ParametrosClass = new ParametrosClass();
  EstadoCivil: ParametrosClass = new ParametrosClass();
  NomeMae: string;
  NomePai: string;

  constructor() {}
}
