import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { SituacaoFuncionalService } from './situacao-funcional.service';
import { ErroService } from 'src/app/service/erros/erro.service';
import { Parametro } from 'src/app/service/interfaces/Parametro';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { Router } from '@angular/router';
import { ObservacaoEnvio } from '../componentes-funcionais/observacoes-ficha-funcional/observacoes-ficha-funcional.component';
import { Anexo, AnexoComponent } from '../anexo/anexo.component';

@Component({
  selector: 'app-situacao-funcional',
  templateUrl: './situacao-funcional.component.html',
  styleUrls: ['./situacao-funcional.component.css'],
})
export class SituacaoFuncionalComponent implements OnInit {
  @ViewChild(AnexoComponent, { static: false }) anexoComponent: AnexoComponent;
  localStorage: LocalStorage;

  formSituacaoFuncional: UntypedFormGroup;

  // listaFuncao: Parametro[];
  listaAnexo: Anexo[];
  listaFuncao: any[];
  listaLotacoes: Parametro[];
  filiais: any[];
  listaEvento: any[];
  listaEventoMotivo: any[];
  listaCargoReferencia: any[];
  mapListaCargos: any[];
  listaMotivos: any[];
  listaTipoCargo: any[];
  listaNivel: any[];
  valorNivelAtual: any[];
  listaReferencia: any[];
  valorReferenciaAtual: any[];
  flag02: string = '';
  f01Evento: string = '';
  tipoCargo: string = '';
  filial: string = '';
  filialDestino: string = '';
  transferencia: string = '0';
  tipoProcessamento: string = 'EM';
  filialAtual: string = '';
  buscarFuncao: string = '';

  loadingFuncaoNivel: boolean = false;
  temErro: boolean = false;
  clientePublico: boolean;

  telaFormulario: boolean = false;
  formTelaInicial: any;
  validaTelaInicial: boolean = false;
  objetoSituacaoFuncional: any[] = [];

  numeroMatricula: string;

  dadosColaborador: DadosSituacao;

  isHistorico: boolean;

  codigoFuncaoSelecionada: any;
  niveisDaFuncao: any[];
  todosNiveis: any[];

  constructor(
    public fb: UntypedFormBuilder,
    private situacaoFuncionalService: SituacaoFuncionalService,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.localStorage = this.decryptLocalStorage();

    this.clientePublico = this.localStorage.ClientePublico;
    this.filialAtual = String(this.localStorage.FilialAtual);

    this.formTelaInicial = this.fb.group({
      matricula_form: ['', [Validators.required, Validators.maxLength(9)]],
    });

    const navigation = this.router.getCurrentNavigation();
    if (navigation.extras.state) {
      this.formTelaInicial
        .get('matricula_form')
        .setValue(navigation.extras.state.usuario.matricula);
      this.buscarDados();
    }
  }

  ngOnInit() {
    this.formSituacaoFuncional = this.fb.group({
      evento: ['', [Validators.required]],
      dtEvento: ['', [Validators.required]],
      funcao: [{ value: '' }],
      nivel: [{ value: '' }],
      valorNivel: [{ value: '', disabled: true }],
      motivo: [''],
      atoPortaria: [''],
      dataAto: [''],
      dataDoe: [''],
      cargo: [''],
      tipoCargo: [''],
      referencia: [{ value: '', disabled: true }],
      valorReferencia: [{ value: '', disabled: true }],
      filialDestino: [''],
      lotacao: [{ value: '', disabled: true }],
      dataObs: [''],
      observacao: [''],

      nome: [''],
      funcaoInicial: [''],
      nivelAtual: [''],
      matricula_readonly: [''],
      vinculo: [''],
      cargoInicial: [''],
      referenciaInicial: [''],
    });

    this.filiais = this.localStorage.Filiais.filter(
      (filial: any) => filial.Codigo != this.filialAtual
    );

    this.validarClientePublico();
  }

  retornarTelaInicial() {
    this.formSituacaoFuncional.get('evento').reset();
    this.formSituacaoFuncional.get('motivo').reset();
    this.formSituacaoFuncional.get('atoPortaria').reset();
    this.formSituacaoFuncional.get('dataAto').reset();
    this.formSituacaoFuncional.get('dataDoe').reset();

    this.formTelaInicial.get('matricula_form').setValue('');
    this.telaFormulario = false;
    this.temErro = false;
    this.limparCamposIniciais();
    this.f01Evento = '';
  }

  buscarDados(matricula?: string) {
    let mat = matricula ? matricula : this.formTelaInicial.get('matricula_form').value;
    this.situacaoFuncionalService.buscarDados(this.filialAtual, mat).subscribe(
      (resp: DadosSituacao) => {
        this.buscarParametros();
        this.limparCampos();
        this.limparVariaveis();

        this.dadosColaborador = resp;
        console.log('dados colaborador', this.dadosColaborador);
        this.numeroMatricula = resp['Matricula'];
        this.objetoSituacaoFuncional = resp['ListaHistoricoPromocoes'];
        if (resp['ListaHistoricoPromocoes'].length > 0) {
          this.formSituacaoFuncional.get('nome').setValue(resp['Nome']);
          this.formSituacaoFuncional.get('matricula_readonly').setValue(resp['Matricula']);
          this.formSituacaoFuncional
            .get('vinculo')
            .setValue(
              this.dadosColaborador.Vinculo +
                ' - ' +
                this.dadosColaborador.ListaHistoricoPromocoes[0].Vinculo_Descricao
            );

          resp['ListaHistoricoPromocoes'][0]['CodRef'] != ''
            ? this.formSituacaoFuncional
                .get('referenciaInicial')
                .setValue(resp['ListaHistoricoPromocoes'][0]['CodRef'])
            : null;
          resp['ListaHistoricoPromocoes'][0]['CodFun'] != ''
            ? this.formSituacaoFuncional
                .get('cargoInicial')
                .setValue(
                  resp['ListaHistoricoPromocoes'][0]['CodFun'] +
                    ' - ' +
                    resp['ListaHistoricoPromocoes'][0]['CodFun_Descricao']
                )
            : null;
          resp['ListaHistoricoPromocoes'][0]['CodCar'] != ''
            ? this.formSituacaoFuncional
                .get('funcaoInicial')
                .setValue(
                  resp['ListaHistoricoPromocoes'][0]['CodCar'] +
                    ' - ' +
                    resp['ListaHistoricoPromocoes'][0]['CodCar_Descricao']
                )
            : null;
          resp['ListaHistoricoPromocoes'][0]['CodNiv'] != ''
            ? this.formSituacaoFuncional
                .get('nivelAtual')
                .setValue(
                  resp['ListaHistoricoPromocoes'][0]['CodNiv'] +
                    ' - ' +
                    resp['ListaHistoricoPromocoes'][0]['CodNiv_Descricao']
                )
            : null;

          matricula ? null : (this.telaFormulario = !this.telaFormulario);
        } else {
          new AlertaModal('erro', 'Atenção!', 'Nenhum dado encontrado para esta matrícula', 'p');
        }
      },
      (error) => {
        new AlertaModal('alerta', 'Erro!', error.error.Mensagem, 'p');
      }
    );
  }

  buscarParametros(): void {
    this.spinner.show();
    this.situacaoFuncionalService
      .buscarParametros(this.filialAtual, this.formTelaInicial.get('matricula_form').value)
      .subscribe(
        (data: any) => {
          this.listaEventoMotivo = data.EventoMotivo;
          this.buscarEvento();
          this.spinner.hide();
        },
        (error) => {
          new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
          this.telaFormulario = false;
          this.spinner.hide();
        }
      );
  }

  buscarFuncoes() {
    if (this.flag02) {
      this.spinner.show();
      this.situacaoFuncionalService.buscarFuncoes(this.filialAtual, this.flag02).subscribe(
        (data: any) => {
          this.listaFuncao = data['Funcao'];
          this.spinner.hide();
        },
        (error) => {
          new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
          this.spinner.hide();
          this.flag02 = '';
        }
      );
    }
  }

  buscarNivel() {
    this.spinner.show();
    const codFuncao = this.formSituacaoFuncional.get('funcao').value;
    this.situacaoFuncionalService.buscarNiveis(this.filialAtual, this.flag02, codFuncao).subscribe(
      (data: any) => {
        this.listaNivel = data.Funcao[0].Nivel;
        this.spinner.hide();
      },
      (error) => {
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
        this.spinner.hide();
      }
    );
  }

  mudarNivel(evento: any) {
    this.buscarNivel();
    if (this.flag02 !== '1' && this.flag02 !== '') {
      this.formSituacaoFuncional.get('referencia').enable();
      this.formSituacaoFuncional.get('nivel').disable();
    } else {
      this.formSituacaoFuncional.get('referencia').disable();
      this.formSituacaoFuncional.get('nivel').enable();
    }
  }

  validarClientePublico() {
    if (this.clientePublico) {
      this.formSituacaoFuncional.get('atoPortaria').setValidators(Validators.required);
      this.formSituacaoFuncional.get('atoPortaria').updateValueAndValidity();

      this.formSituacaoFuncional.get('dataAto').setValidators([Validators.required]);
      this.formSituacaoFuncional.get('dataAto').updateValueAndValidity();

      this.formSituacaoFuncional.get('dataDoe').setValidators([Validators.required]);
      this.formSituacaoFuncional.get('dataDoe').updateValueAndValidity();
    }
  }

  buscarEvento(): void {
    let listaEvento = this.listaEventoMotivo.map((evento) => {
      return {
        Codigo: evento.CodigoEvento,
        Descricao: evento.NomeEvento,
        Flag: evento.FlagEventoFuncional01,
        Flag02: evento.F02,
        Motivo: evento.CodigoMotivo,
        Grupo: evento.Grupo,
      };
    });

    listaEvento = listaEvento.filter(function (evento) {
      //filtra as duplicações da lista
      return !this[JSON.stringify(evento.Codigo)] && (this[JSON.stringify(evento.Codigo)] = true);
    }, Object.create(null));

    this.listaEvento = listaEvento;
  }

  onEventoChange(): void {
    const eventoSelecionado = document.querySelector('select[name="evento"]') as HTMLSelectElement;
    const opcaoEventoSelecionada = eventoSelecionado.options[eventoSelecionado.selectedIndex];
    const textoOpcao = opcaoEventoSelecionada.text;
    if (textoOpcao.includes('EXONERACAO')) {
      this.formSituacaoFuncional.get('funcao').disable();
    } else {
      this.formSituacaoFuncional.get('funcao').enable();
    }

    this.encontrarFlag();
    this.encontraFlag02();
    this.formSituacaoFuncional.markAsPristine();
    this.buscarFuncoes();
    this.buscarCargos();

    this.buscarMotivos();
    this.atualizarValidacoes();
    this.limparCampos();
    this.transferencia = '';
  }

  encontrarFlag(): void {
    const { evento } = this.formSituacaoFuncional.value;
    let eventoAtual = this.listaEvento.filter((eventoAux: any) => eventoAux.Codigo == evento)[0]; //filtrar onde o evento atual é igual  para achar a flag

    this.f01Evento = evento == '' ? '' : eventoAtual.Flag;

    if (this.f01Evento == '3') {
      this.formSituacaoFuncional.get('nivel').disable();
      this.formSituacaoFuncional.get('referencia').disable();
    }
  }

  encontraFlag02() {
    const { evento } = this.formSituacaoFuncional.value;
    let eventoAtual = this.listaEvento.filter((eventoAux: any) => eventoAux.Codigo == evento)[0]; //filtrar onde o evento atual é igual  para achar a flag
    this.flag02 = evento == '' ? '' : eventoAtual.Flag02;
  }

  buscarCargos() {
    const { filialDestino } = this.formSituacaoFuncional.value;
    if (this.f01Evento == EventoFlag.nomeacao && this.transferencia != '1') {
      this.buscarCargoNomeacao();
    } else if (
      this.f01Evento == EventoFlag.nomeacao &&
      this.transferencia == '1' &&
      filialDestino != ''
    ) {
      this.buscarCargosMotivoTransferencia();
    }
  }

  buscarCargoNomeacao(): void {
    this.situacaoFuncionalService.buscarNomeacao(this.filialAtual).subscribe(
      (data: any) => {
        this.listaCargoReferencia = data.CargoReferencia;
        this.mapListaCargos = this.mapearListaCargos(this.listaCargoReferencia).sort(
          this.ordenarListas
        );
        this.buscarTipoCargo();
      },
      (error) => this.validaErro.retornoErro(error)
    );
  }

  buscarCargosMotivoTransferencia(): void {
    const { filialDestino } = this.formSituacaoFuncional.value;

    this.situacaoFuncionalService.buscarCargosMotivoTransferencia(filialDestino).subscribe(
      (data: any) => {
        this.listaCargoReferencia = data.CargoReferencia;
        this.mapListaCargos = this.mapearListaCargos(this.listaCargoReferencia).sort(
          this.ordenarListas
        );
        this.listaLotacoes = data.Lotacao.sort(this.ordenarListas);
        this.buscarTipoCargo();
      },
      (error) => {
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
        this.formSituacaoFuncional.get('filialDestino').setValue('');
        this.formSituacaoFuncional.get('filialDestino').markAsPristine();
        this.formSituacaoFuncional.get('lotacao').setValue('');
        this.formSituacaoFuncional.get('lotacao').disable();
      }
    );
  }

  ordenarListas(a: any, b: any): number {
    if (a.Descricao < b.Descricao) {
      return -1;
    } else if (a.Descricao > b.Descricao) {
      return 1;
    } else {
      return 0;
    }
  }

  mapearListaCargos(lista): object[] {
    const mapListaCargos = lista.map((evento) => {
      return {
        Codigo: evento.CodigoCargo,
        Descricao: evento.DescricaoCargo,
        Referencia: evento.CodigoReferencia,
        ValorReferencia: evento.ValorReferencia,
        Vinculo: evento.Vinculo,
      };
    });
    return mapListaCargos;
  }

  buscarMotivos(): void {
    this.filial = this.transferencia == '1' ? this.filialDestino : this.filialAtual;
    this.mapearMotivos(this.filial);
  }

  mapearMotivos(filial: any): void {
    let listaMotivos = this.listaEventoMotivo.map((evento) => {
      return {
        Codigo: evento.CodigoMotivo,
        Descricao: evento.NomeMotivo,
        Transferencia: evento.Transferencia,
        Flag: evento.FlagEventoFuncional01,
        EvpF04: evento.EvpF04,
        MopF04: evento.MopF04,
      };
    });
    listaMotivos = listaMotivos.filter(function (motivo) {
      return !this[JSON.stringify(motivo)] && (this[JSON.stringify(motivo)] = true);
    }, Object.create(null));

    listaMotivos = listaMotivos.filter((motivoAux: any) => {
      return motivoAux.Flag == this.f01Evento;
      // return motivoAux.EvpF04 === motivoAux.MopF04;
    });
    this.listaMotivos = listaMotivos.sort(this.ordenarListas);
  }

  atualizarValidacoes(): void {
    this.limparValidacoes();

    if (this.listaMotivos.length > 0) {
      this.formSituacaoFuncional.get('motivo').setValidators(Validators.required);
    }
    if (this.f01Evento == EventoFlag.promocao) {
      this.formSituacaoFuncional.get('funcao').setValidators(Validators.required);
      this.formSituacaoFuncional.get('nivel').setValidators(Validators.required);
    } else if (this.f01Evento == EventoFlag.nomeacao) {
      this.formSituacaoFuncional.get('motivo').setValidators(Validators.required);
    } else if (this.f01Evento == EventoFlag.exoneracao) {
      this.formSituacaoFuncional.get('motivo').setValidators(Validators.required);
    }
  }

  limparValidacoes(): void {
    this.formSituacaoFuncional.get('funcao').setValidators(null);
    this.formSituacaoFuncional.get('nivel').setValidators(null);
    this.formSituacaoFuncional.get('cargo').setValidators(null);
    this.formSituacaoFuncional.get('referencia').setValidators(null);
    this.formSituacaoFuncional.get('filialDestino').setValidators(null);
    this.formSituacaoFuncional.get('lotacao').setValidators(null);
    this.formSituacaoFuncional.get('motivo').setValidators(null);
  }

  limparCampos(): void {
    this.formSituacaoFuncional.get('funcao').setValue('');
    this.formSituacaoFuncional.get('nivel').setValue('');
    this.formSituacaoFuncional.get('valorNivel').setValue('');
    this.formSituacaoFuncional.get('motivo').setValue('');
    this.formSituacaoFuncional.get('atoPortaria').setValue('');
    this.formSituacaoFuncional.get('dataAto').setValue('');
    this.formSituacaoFuncional.get('dataDoe').setValue('');
    this.formSituacaoFuncional.get('tipoCargo').setValue('');
    this.formSituacaoFuncional.get('cargo').setValue('');
    this.formSituacaoFuncional.get('referencia').setValue('');
    this.formSituacaoFuncional.get('valorReferencia').setValue('');
    this.formSituacaoFuncional.get('filialDestino').setValue('');
    this.formSituacaoFuncional.get('lotacao').setValue('');
    this.formSituacaoFuncional.get('dtEvento').setValue('');
    this.formSituacaoFuncional.get('dataObs').setValue('');
    this.formSituacaoFuncional.get('observacao').setValue('');
    this.formSituacaoFuncional.markAsUntouched();
  }

  limparCamposIniciais() {
    this.formSituacaoFuncional.get('funcaoInicial').setValue('');
    this.formSituacaoFuncional.get('nivelAtual').setValue('');
    this.formSituacaoFuncional.get('cargoInicial').setValue('');
    this.formSituacaoFuncional.get('referenciaInicial').setValue('');
    this.formSituacaoFuncional.get('vinculo').setValue('');
  }

  limparVariaveis(): void {
    this.filial = '';
    this.filialDestino = '';
    this.listaMotivos = [];
    this.listaCargoReferencia = [];
    this.mapListaCargos = [];
    this.listaFuncao = [];
    this.listaLotacoes = [];
    this.f01Evento = ''; //precisa zerar com certeza
    this.tipoCargo = '';
    this.listaTipoCargo = [];
    this.listaNivel = [];
    this.valorNivelAtual = [];
    this.listaReferencia = [];
    this.valorReferenciaAtual = [];
    this.transferencia = '0'; //precisa zerar com certeza
    this.flag02 = '';
  }

  onMotivoChange(): void {
    const { motivo } = this.formSituacaoFuncional.value;
    let motivoAtual = this.listaMotivos.filter((motivoAux: any) => motivoAux.Codigo == motivo)[0];
    this.transferencia = motivo == '' ? '0' : motivoAtual.Transferencia;

    this.formSituacaoFuncional.get('filialDestino').setValue('');
    this.formSituacaoFuncional.get('lotacao').setValue('');

    this.formSituacaoFuncional.get('lotacao').disable();

    this.formSituacaoFuncional.get('filialDestino').setValidators(null);
    this.formSituacaoFuncional.get('filialDestino').updateValueAndValidity();

    if (
      this.f01Evento == EventoFlag.nomeacao &&
      this.transferencia == '1' &&
      this.filiais.length == 1
    ) {
      this.formSituacaoFuncional.get('filialDestino').setValue(this.filiais[0].Codigo);
      this.buscarCargos(); //se só tiver uma filial, não vai poder chamar no onFilialDestinoChange
      this.formSituacaoFuncional.get('lotacao').enable();
    } else if (this.f01Evento == EventoFlag.nomeacao && this.transferencia != '1') {
      this.formSituacaoFuncional.get('filialDestino').markAsPristine();
      this.buscarCargos(); // para se alterar motivo depois de já ter selecionado transferência uma vez
    } else if (this.f01Evento == EventoFlag.nomeacao && this.transferencia == '1') {
      this.formSituacaoFuncional.get('filialDestino').setValidators(Validators.required);
      this.formSituacaoFuncional.get('lotacao').setValidators(Validators.required);
    }
  }

  buscarTipoCargo(): void {
    const filtroTipoCargo = this.mapListaCargos.filter(function (tipo) {
      return !this[JSON.stringify(tipo.Vinculo)] && (this[JSON.stringify(tipo.Vinculo)] = true);
    }, Object.create(null));

    this.listaTipoCargo = filtroTipoCargo.map((tipo) => {
      return {
        Descricao: tipo.Vinculo == '2' ? 'Cargo Comissão' : 'Função Gratifcada',
        Vinculo: tipo.Vinculo,
      };
    });
  }

  submitSituacaoFuncional(form: any): void {
    this.listaAnexo = this.anexoComponent.retornarValores();
    if (this.formSituacaoFuncional.invalid) {
      this.temErro = true;
      new AlertaModal('alerta', 'Atenção', 'Preencha todos os campos obrigatórios!', 'p');
    } else if (this.f01Evento == EventoFlag.promocao) {
      this.temErro = false;
      this.salvarPromocao(form);
    } else if (this.f01Evento == EventoFlag.nomeacao) {
      this.temErro = false;
      this.salvarNomeacao(form);
    } else if (this.f01Evento == EventoFlag.exoneracao) {
      this.temErro = false;
      this.salvarExoneracao(form);
    }
  }

  capslock(event) {
    event.target.value = event.target.value.toUpperCase();
    this.formSituacaoFuncional.get('atoPortaria').setValue(event.target.value);
  }

  carregarRead() {
    this.formSituacaoFuncional.get('nome').setValue(this.dadosColaborador['Nome']);
    this.formSituacaoFuncional
      .get('matricula_readonly')
      .setValue(this.dadosColaborador['Matricula']);
    this.formSituacaoFuncional
      .get('referenciaInicial')
      .setValue(this.dadosColaborador['ListaHistoricoPromocoes'][0]['CodRef']);
    this.formSituacaoFuncional
      .get('cargoInicial')
      .setValue(this.dadosColaborador['ListaHistoricoPromocoes'][0]['CodFun']);
    this.formSituacaoFuncional
      .get('funcaoInicial')
      .setValue(this.dadosColaborador['ListaHistoricoPromocoes'][0]['CodCar']);
    this.formSituacaoFuncional
      .get('nivelAtual')
      .setValue(this.dadosColaborador['ListaHistoricoPromocoes'][0]['CodNiv']);
  }

  onFilialDestinoChange(): void {
    this.formSituacaoFuncional.get('lotacao').setValue('');
    this.formSituacaoFuncional.get('lotacao').enable();
    this.buscarCargos();
  }

  limitarCaracteresNumero(e: any) {
    let charCode = e.charCode ? e.charCode : e.keyCode;
    if (charCode != 8 && charCode != 9) {
      let max = 15;

      if (charCode < 48 || charCode > 57 || e.target.value.length >= max) return false;
    }
  }

  recarregarLista2() {
    this.situacaoFuncionalService.buscarDados(this.filialAtual, this.numeroMatricula).subscribe(
      (resp) => {
        this.objetoSituacaoFuncional = resp['ListaHistoricoPromocoes'];
        this.formSituacaoFuncional.get('nome').setValue(resp['Nome']);
        this.formSituacaoFuncional.get('matricula_readonly').setValue(resp['Matricula']);
        this.formSituacaoFuncional
          .get('referenciaInicial')
          .setValue(resp['ListaHistoricoPromocoes'][0]['CodRef']);
        this.formSituacaoFuncional
          .get('cargoInicial')
          .setValue(resp['ListaHistoricoPromocoes'][0]['CodFun']);
        this.formSituacaoFuncional
          .get('funcaoInicial')
          .setValue(resp['ListaHistoricoPromocoes'][0]['CodCar']);
        this.formSituacaoFuncional
          .get('nivelAtual')
          .setValue(resp['ListaHistoricoPromocoes'][0]['CodNiv']);
      },
      (error) => {
        new AlertaModal('erro', 'Erro!', error.error.Mensagem, 'p');
      }
    );
  }

  salvarPromocao(form: any): void {
    this.spinner.show();
    const promocao = this.montarPromocao();
    this.situacaoFuncionalService.salvarPromocao(promocao).subscribe(
      (data) => {
        this.temErro = false;
        new AlertaModal('sucesso', 'Sucesso', 'Promoção efetivada com sucesso!', 'p');

        this.formSituacaoFuncional.get('evento').setValue('');
        this.limparCampos();
        this.flag02 = '';
        this.buscarDados(this.dadosColaborador.Matricula);
        this.spinner.hide();
      },
      (error) => {
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
        this.spinner.hide();
      }
    );
  }

  salvarNomeacao(form: any): void {
    this.spinner.show();
    this.situacaoFuncionalService.salvarNomeacao(this.montarNomeacao()).subscribe(
      (data) => {
        this.temErro = false;
        new AlertaModal('sucesso', 'Sucesso', 'Nomeação efetivada com sucesso!', 'p');

        this.formSituacaoFuncional.get('evento').setValue('');
        // this.limparVariaveis();
        this.limparCampos();
        this.flag02 = '';
        this.formSituacaoFuncional.get('referencia').disable();
        this.buscarDados(this.dadosColaborador.Matricula);
        this.spinner.hide();
        //this.telaFormulario = false;
      },
      (error) => {
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
        this.spinner.hide();
        this.carregarRead();
      }
    );
  }

  salvarExoneracao(form: any): void {
    this.spinner.show();
    this.situacaoFuncionalService.salvarExoneracao(this.montarExoneracao()).subscribe(
      (data) => {
        form.reset();
        this.temErro = false;
        new AlertaModal('sucesso', 'Sucesso', 'Exoneração efetivada com sucesso! ', 'p');
        this.formSituacaoFuncional.get('evento').setValue('');
        this.limparCampos();
        this.flag02 = '';
        this.spinner.hide();
        this.telaFormulario = false;
      },
      (error) => {
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
        this.spinner.hide();
      }
    );
  }

  montarPromocao(): Promocao {
    const formSituacaoFuncional = this.formSituacaoFuncional.value;
    const { dataAto, dataDoe, dtEvento } = formSituacaoFuncional;

    return {
      empresaFilial: this.filialAtual,
      matricula: formSituacaoFuncional.matricula_readonly,
      funcaoCargo: {
        codigoFuncao: formSituacaoFuncional.funcao,
        codigoReferencia: formSituacaoFuncional.referencia,
        codigoNivel: formSituacaoFuncional.nivel,
      },
      atoPortaria: this.clientePublico ? formSituacaoFuncional.atoPortaria : '',
      dataAto: this.clientePublico ? this.formatarDataEnvio(dataAto) : '00000000',
      dataDoe: this.clientePublico ? this.formatarDataEnvio(dataDoe) : '00000000',
      dataInicio: this.clientePublico ? this.formatarDataEnvio(dtEvento) : '00000000',
      tipoProcessamento: this.tipoProcessamento,
      eventoFuncional: {
        codigoEvento: formSituacaoFuncional.evento,
      },
      motivoPromocaoExoneracao: {
        codigoMotivo: formSituacaoFuncional.motivo,
        transferencia: this.transferencia,
      },
      observacoes:
        this.formSituacaoFuncional.get('observacao').value != '' ? this.montarObservacao() : null,
    };
  }

  montarNomeacao(): Nomeacao {
    const formSituacaoFuncional = this.formSituacaoFuncional.value;
    const { dataAto, dataDoe, dtEvento } = formSituacaoFuncional;

    return {
      empresaFilial: this.filialAtual,
      matricula: formSituacaoFuncional.matricula_readonly,
      empfilDestino: formSituacaoFuncional.filialDestino,
      lotacaoDestino: formSituacaoFuncional.lotacao,
      funcaoCargo: {
        CodigoCargo: this.flag02 !== '1' ? formSituacaoFuncional.funcao : '',
        CodigoFuncao: this.flag02 === '1' ? formSituacaoFuncional.funcao : '',
        CodigoNivel: formSituacaoFuncional.nivel,
        CodigoReferencia: formSituacaoFuncional.referencia,
        TipoFuncaoCargo: formSituacaoFuncional.funcao,
      },
      atoPortaria: this.clientePublico ? formSituacaoFuncional.atoPortaria : '',
      dataAto: this.clientePublico ? this.formatarDataEnvio(dataAto) : '00000000',
      dataDoe: this.clientePublico ? this.formatarDataEnvio(dataDoe) : '00000000',
      dataInicio: this.clientePublico ? this.formatarDataEnvio(dtEvento) : '00000000',

      tipoProcessamento: this.tipoProcessamento,
      eventoFuncional: {
        codigoEvento: formSituacaoFuncional.evento,
      },
      motivoPromocaoExoneracao: {
        codigoMotivo: formSituacaoFuncional.motivo,
        transferencia: this.transferencia,
      },
      observacoes:
        this.formSituacaoFuncional.get('observacao').value != '' ? this.montarObservacao() : null,
    };
  }

  montarExoneracao(): Exoneracao {
    const formSituacaoFuncional = this.formSituacaoFuncional.value;
    const { dataAto, dataDoe, dtEvento } = formSituacaoFuncional;

    return {
      empresaFilial: this.filialAtual,
      matricula: formSituacaoFuncional.matricula_readonly,
      atoPortaria: this.clientePublico ? formSituacaoFuncional.atoPortaria : '',
      dataAto: this.clientePublico ? this.formatarDataEnvio(dataAto) : '00000000',
      dataDoe: this.clientePublico ? this.formatarDataEnvio(dataDoe) : '00000000',
      dataInicio: this.clientePublico ? this.formatarDataEnvio(dtEvento) : '00000000',
      tipoprocessamento: this.tipoProcessamento,
      eventoFuncional: {
        codigoEvento: formSituacaoFuncional.evento,
      },
      motivoPromocaoExoneracao: {
        codigoMotivo: formSituacaoFuncional.motivo,
      },
      observacoes:
        this.formSituacaoFuncional.get('observacao').value != '' ? this.montarObservacao() : null,
    };
  }

  montarObservacao() {
    const formSituacaoFuncional = this.formSituacaoFuncional.value;
    let dataAtual = new Date();
    let ano = dataAtual.getFullYear().toString();
    let mes = (dataAtual.getMonth() + 1).toString();
    if (mes.length == 1) {
      mes = '0' + mes;
    }
    let dia = dataAtual.getDate().toString();
    if (dia.length == 1) {
      dia = '0' + dia;
    }
    let hora = dataAtual.getHours().toString();
    if (hora.length == 1) {
      hora = '0' + hora;
    }
    let minuto = dataAtual.getMinutes().toString();
    if (minuto.length == 1) {
      minuto = '0' + minuto;
    }

    let observacaoMontada: ObservacaoEnvio = {
      Sigla: 'FAPP',
      Empfil: parseInt(this.filialAtual),
      Mat: parseInt(formSituacaoFuncional.matricula_readonly),
      Tipo: this.buscarTipoObservacao(),
      CompChave: '',
      DTCSeq: this.formSituacaoFuncional.get('dtEvento').value.replace(/-/g, ''),
      Obs: this.formSituacaoFuncional.get('observacao').value,
      DataRet: ano + mes + dia,
      HoraRet: hora + minuto,
      CodCad: '',
    };
    return observacaoMontada;
  }

  buscarTipoObservacao(): string {
    if (
      this.dadosColaborador.Vinculo != '7' &&
      this.f01Evento == '3'
      //revisar regra
    ) {
      return '010';
    } else {
      return '007';
    }
  }

  formatarDataEnvio(data: string): string {
    let dataSplit = data.split('-');
    const dataFormatada = `${dataSplit[0]}${dataSplit[1]}${dataSplit[2]}`;
    return dataFormatada;
  }

  formatarData(data: string): string {
    let dataSplit = data.split('/');
    const dataFormatada = `${dataSplit[2]}-${dataSplit[1]}-${dataSplit[0]}`;
    return dataFormatada;
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}

export interface Promocao {
  empresaFilial: string;
  matricula: string;
  atoPortaria: string;
  dataAto: string;
  dataDoe: string;
  dataInicio: string;
  tipoProcessamento: string;
  eventoFuncional: EventoFuncional;
  motivoPromocaoExoneracao: MotivoPromocaoExoneracao;
  funcaoCargo: Funcao;
  observacoes: ObservacaoEnvio;
}

export interface Nomeacao {
  empresaFilial: string;
  matricula: string;
  atoPortaria: string;
  dataAto: string;
  dataDoe: string;
  dataInicio: string;
  tipoProcessamento: string;
  eventoFuncional: EventoFuncional;
  motivoPromocaoExoneracao: MotivoPromocaoExoneracao;
  empfilDestino: string;
  lotacaoDestino: string;
  funcaoCargo: FuncaoCargo;
  observacoes: ObservacaoEnvio;
}

export interface Exoneracao {
  //Define campos e tipos que existirão na interface do envio pro back
  empresaFilial: string;
  matricula: string;
  atoPortaria: string;
  dataAto: string;
  dataDoe: string;
  dataInicio: string;
  tipoprocessamento: string;
  eventoFuncional: EventoFuncional;
  motivoPromocaoExoneracao: MotivoPromocaoExoneracao;
  observacoes: ObservacaoEnvio;
}

interface EventoFuncional {
  //define o tipo do objeto que será chamado dentro do outro objeto
  codigoEvento: string;
}

interface Funcao {
  codigoFuncao: string;
  codigoNivel: string;
  codigoReferencia: string;
}

interface FuncaoCargo {
  CodigoCargo: string;
  CodigoFuncao: string;
  CodigoNivel: string;
  CodigoReferencia: string;
  TipoFuncaoCargo: string;
}

interface MotivoPromocaoExoneracao {
  codigoMotivo: string;
  transferencia?: string; // essa ? é porque se o campo não for obrigatório (caso da exoneração, ele não puxa)
}

enum EventoFlag {
  promocao = '1',
  nomeacao = '2',
  exoneracao = '3',
}

interface DadosSituacao {
  IsHistorico: boolean;
  Matricula: string;
  Nome: string;
  Vinculo: string;
  ListaHistoricoPromocoes: any[];
}
