import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CadastroTipoUsuarioService } from './cadastro-tipo-usuario.service';

import Swal from 'sweetalert'
import { ErroService } from 'src/app/service/erros/erro.service';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';



@Component({
  selector: 'app-cadastro-tipo-usuario',
  templateUrl: './cadastro-tipo-usuario.component.html',
  styleUrls: ['./cadastro-tipo-usuario.component.css']
})
export class CadastroTipoUsuarioComponent implements OnInit {
  formTelaInicial: FormGroup;
  formTipoUsuario: FormGroup;

  tarefaSelecionada: any[] = [];
  listaTipoUsuario: TipoUsuario[];
  listaTarefas: any[] = [];
  listaTodasTarefas: any = [];
  listaServicos: any[] = [];
  tarefasUsuario: any[] = [];
  listaTarefasPai: any[] = [];
  TREE_DATA: VehicleNode[] = [];
  TREE_DATA_EDIT: VehicleNode[] = [];

  codigoTarefa: any;
  idExcluindo: string = '';
  filter: Event;
  codigoServico: string = '';

  isPai: boolean = false;
  telaFormulario: boolean = false;
  habilitarCancelar: boolean = false;
  temErro: boolean = false;
  editar: boolean = false;
  salvo: boolean = false;

  public treeControl = new NestedTreeControl<VehicleNode>(node => node.Filhos);
  public dataSource = new MatTreeNestedDataSource<VehicleNode>();
  public dataSourceEdit = new MatTreeNestedDataSource<VehicleNode>();
  @ViewChild('outputDiv', { static: false })
  public outputDivRef !: ElementRef<HTMLParagraphElement>;


  constructor(private fb: FormBuilder, public validaErro: ErroService, private tipoUsuarioService: CadastroTipoUsuarioService, public spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.formTelaInicial = this.fb.group({
      codServico_form: ['', [Validators.required]],
    });
    this.formTipoUsuario = this.fb.group({
      codigo: '',
      tipo: ['', Validators.required],
      descricao: ['', Validators.required],
      tarefa: [""],
      nivel: 0
    });
    this.buscarServicos();
  }

  buscarServicos(): void {
    this.tipoUsuarioService.buscarServicosPse()
      .subscribe(
        (data: any[]) => {
          this.listaServicos = data;
          this.spinner.hide();
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        }
      );
  }

  buscarArvore(servico: any) {
    this.tipoUsuarioService.buscarArvoreTarefas(servico).subscribe(
      (data: any) => {
        this.TREE_DATA.length != 0 ? this.TREE_DATA.pop() : null;
        this.TREE_DATA = data
        this.dataSource.data = this.TREE_DATA;
        Object.keys(this.dataSource.data).forEach((key: any) => {
          this.setParent(this.dataSource.data[key], null);
        });
      },
      error =>
        this.validaErro.retornoErro(error)
    )
  }

  buscarArvoreEditar(servico: any, tipoUSuairo: string) {
    this.tipoUsuarioService.buscarArvoreTarefasEditar(servico, tipoUSuairo).subscribe(
      (data: any) => {
        this.TREE_DATA_EDIT.length != 0 ? this.TREE_DATA_EDIT.pop() : null;
        this.TREE_DATA_EDIT = data
        this.dataSourceEdit.data = this.TREE_DATA_EDIT;
        Object.keys(this.dataSourceEdit.data).forEach((key: any) => {
          this.setParent(this.dataSourceEdit.data[key], null);
        });
      },
      error =>
        this.validaErro.retornoErro(error)
    )
  }

  listarTipoUsuario(): void {
    this.spinner.show();
    this.tipoUsuarioService.listarTipoUsuario()
      .subscribe(
        (data: any[]) => {
          this.listaServicos = data;
          this.spinner.hide();
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        }
      );
  }

  salvarTarefaPai() {
    if (this.tarefaSelecionada.length != 0) {
      this.fecharModal();
      this.salvo = true;
    } else {
      new AlertaModal('alerta','Atenção!','Selecione uma Tarefa!','p')
    }
  }

  buscarTarefasPorCodigo(): void {
    const codigo = this.formTelaInicial.get('codServico_form').value;
    this.codigoServico = codigo
    if (codigo != '') {
      this.spinner.show();
      this.tipoUsuarioService.buscarTarefasPorCodigo(codigo)
        .subscribe(
          (data: any) => {
            this.listaTarefas = data;
            this.listaTodasTarefas = data;
            this.buscarTiposUsuario();
            this.telaFormulario = !this.telaFormulario;
            this.spinner.hide();
            this.buscarArvore(codigo)
          },
          error => {
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
            this.spinner.hide();
          }
        );
    } else {
      new AlertaModal('alerta','Atenção!','Você precisa digitar um código.','p')
    }
  }

  buscarTiposUsuario(): void {
    this.tipoUsuarioService.buscarTiposUsuario()
      .subscribe(
        (data: TipoUsuario[]) =>
          this.listaTipoUsuario = data
        ,
        error =>
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p')
      );
  }

  buscarTipoUsuario(tipoUsuario: TipoUsuario): void {
    if (tipoUsuario.CodigoTipoUsuario != "") {
      this.buscarArvoreEditar(this.codigoServico, tipoUsuario.CodigoTipoUsuario)
      this.tipoUsuarioService.buscarTipoUsuario(tipoUsuario.CodigoTipoUsuario)
        .subscribe(
          (data: any) => {
            this.tarefaSelecionada = data.Tarefas;
            this.tarefasUsuario = data.Tarefas;
            this.editar = true;
            this.formTipoUsuario.setValue({
              codigo: data.CodigoTipoUsuario,
              descricao: data.Descricao,
              tipo: data.TipoDoUsuario,
              tarefa: data.Tarefas,
              nivel: data.Tarefas.length > 0 ? data.Tarefas[0].Nivel : 0
            });
            // implementar carregar selecionado.
            let tarefa = this.formTipoUsuario.get('tarefa').value;
            if (tarefa.length > 0) { let nivel = tarefa[0].Nivel; this.listaTarefas = this.listaTodasTarefas.filter(valor => valor.Nivel == nivel); }

            this.formTipoUsuario.get('codigo').disable();
            window.scrollTo(0, 0);
          },
          error =>
            this.validaErro.retornoErro(error)
        );
    } else {
      new AlertaModal('alerta','Atenção!','Você precisa digitar um código.','p')
    }
  }

  submitTipoUsuario(): void {
    if (this.formTipoUsuario.valid && this.tarefaSelecionada.length != 0) {
      this.temErro = false;
      this.editar ? this.editarTipoUsuario() : this.salvarTipoUsuario();
    } else {
      if (this.formTipoUsuario.invalid) {
        new AlertaModal('alerta','Atenção!','Preencha todos os campos!','p')
        this.temErro = true;
      } else {
        new AlertaModal('alerta','Atenção!','Selecione pelo menos uma tarefa!','p')
        this.temErro = true;
      }
    }
  }

  salvarTipoUsuario(): void {
    const tipoUsuario: TipoUsuario = this.montarTipoUsuario();
    this.spinner.show();

    this.tipoUsuarioService.salvarTipoUsuario(tipoUsuario)
      .subscribe(
        () => {
          this.temErro = false;
          this.editar = false;
          this.spinner.hide();
          this.cancelarModal();
          this.buscarTiposUsuario();
          this.limparCampos();
          new AlertaModal('sucesso',"Sucesso","Operação realizada com sucesso",'p')
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  editarTipoUsuario(): void {
    const tipoUsuario: TipoUsuario = this.montarTipoUsuario();
    this.spinner.show();

    this.tipoUsuarioService.editarTipoUsuario(tipoUsuario)
      .subscribe(
        () => {
          this.temErro = false;
          this.spinner.hide();
          this.buscarTiposUsuario();
          this.limparCampos();
          new AlertaModal('sucesso',"Sucesso", "Tipo de usuário foi editado",'p')
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  deletar(item: TipoUsuario, index?: string): void {
    this.idExcluindo = index;
    Swal({
      title: "Você tem certeza?",
      text: "Esta operação não pode ser desfeita.",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true
        }
      }
    })
      .then((willDelete) => {
        if (willDelete) {
          this.excluirTipoUsuario(item);
        }
      });
    this.idExcluindo = '';
  }

  excluirTipoUsuario(item: TipoUsuario) {
    this.spinner.show();
    this.tipoUsuarioService.deletarTipoUsuario(item.CodigoTipoUsuario)
      .subscribe(
        () => {
          new AlertaModal('sucesso',"Sucesso", 'Tipo de usuário excluído com sucesso!','p')
          this.limparCampos();
          this.buscarTiposUsuario();
          this.spinner.hide();
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        })
  }

  atualizarListaTipoUsuario(tipoUsuario: TipoUsuario): void {
    if (this.editar) {
      const elemento = this.listaTipoUsuario.find(elemento => elemento.CodigoTipoUsuario === tipoUsuario.CodigoTipoUsuario);
      const index = this.listaTipoUsuario.indexOf(elemento);
      this.listaTipoUsuario[index] = tipoUsuario;
      return;
    }
    this.listaTipoUsuario.push(tipoUsuario);
  }

  fecharModal() {
    document.getElementById("closeModal").click();
  }
  
  cancelarModal() {
    if (!this.salvo) {
      if (!this.editar) {
        this.listaTarefas = this.listaTodasTarefas.filter(valor => valor.Nivel == 0);
        this.tarefaSelecionada = [];
        this.listaTarefasPai = [];
        this.fecharModal();
        this.buscarArvore(this.codigoServico);
      }
    }
  }

  selecionarTarefa(tarefa: any, isChecked) {
    let pai;
    if(isChecked instanceof Event ){
      isChecked = (isChecked.target as HTMLInputElement).checked;    

    }
    if (isChecked) {
      for (let index = 0; index < this.tarefaSelecionada.length; index++) {
        if (tarefa.Codigo === this.tarefaSelecionada[index].Codigo) {
          this.popTarefa(tarefa)
        }
      }
      this.tarefaSelecionada.push(tarefa);
    } else if (!isChecked) {
      this.popTarefa(tarefa);      
    }
  }

  popTarefa(tarefa) {
    for (let index = 0; index < this.tarefaSelecionada.length; index++) {
      if (this.tarefaSelecionada[index].Codigo === tarefa.Codigo) {
        this.tarefaSelecionada.splice(index, 1);
      }
    }
  }

  montarTipoUsuario(): TipoUsuario {
    let temp = [];
    for (let i = 0; i < this.listaTodasTarefas.length; i++) {
      for (let j = 0; j < this.tarefaSelecionada.length; j++) {
        if (this.tarefaSelecionada[j].Codigo == this.listaTodasTarefas[i].Codigo) {
          temp.push(this.listaTodasTarefas[i]);
        }
      }
    }
    return {
      CodigoTipoUsuario: this.formTipoUsuario.get('codigo').value,
      TipoDoUsuario: this.formTipoUsuario.get('tipo').value,
      Descricao: this.formTipoUsuario.get('descricao').value,
      Tarefas: temp
    }
  }

  limparCampos(): void {
    this.editar = false;
    this.salvo = false;
    this.formTipoUsuario.setValue({
      codigo: '',
      tipo: '',
      descricao: '',
      tarefa: '',
      nivel: ''
    });
    this.cancelarModal();
    this.tarefasUsuario = [];
    this.listaTarefasPai = [];
    this.formTipoUsuario.markAsPristine();
    this.buscarArvore(this.codigoServico);
  }

  // arvore tarefas
  temFilho = (_: number, node: VehicleNode) =>
    !!node.Filhos && node.Filhos.length > 0;

  setParent(node: VehicleNode, parent: any) {
    node.parent = parent;
    
    if (node.Filhos) {
      node.Filhos.forEach(childNode => {
        this.setParent(childNode, node);
      });
    }
  }
//aqui
  private marcarTodosParentes(node: VehicleNode) {
    if (node.parent) {
      node.parent.indeterminate = false
      const descendants = this.treeControl.getDescendants(node.parent);
      node.parent.Checado =
      descendants.every(child => child.Checado);
      node.parent.indeterminate =
      descendants.some(child => child.Checado);
      
      this.marcarTodosParentes(node.parent);
      this.selecionarTarefa(node.parent,descendants.every(child => child.Checado) || descendants.some(child => child.Checado));
    }
  }

  marcarItemArvore(checked, node: VehicleNode) {
    if(checked instanceof Event ){
      checked = (checked.target as HTMLInputElement).checked; 
    }
    node.Checado = checked;
    if (node.Filhos) {
      node.Filhos.forEach(child => {
        this.marcarItemArvore(checked, child);
      });
    }
    this.marcarTodosParentes(node);
    this.selecionarTarefa(node, checked)

  }

  retornarTelaInicial(){
    this.telaFormulario = false
  }
}

export interface TipoUsuario {
  CodigoTipoUsuario?: string;
  TipoDoUsuario: string;
  Descricao: string;
  Tarefas: any[];
}

interface VehicleNode {
  Descricao: string;
  Codigo?: string;
  CodigoServico?: string
  Filhos?: VehicleNode[];
  Checado?: boolean;
  indeterminate?: boolean;
  parent?: VehicleNode;
}
