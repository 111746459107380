import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AcessoService} from 'src/app/service/acesso/acesso.service';
import {LocalStorage} from 'src/app/service/classes/localstorage/LocalStorage';
import {Crypto} from 'src/app/service/classes/crypto/crypto';
import {ObservacaoEnvio} from './observacoes-diversas-cadastro/observacoes-diversas-cadastro.component';

@Injectable({
  providedIn: 'root',
})
export class ObservacoesDiversasService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  url_acesso: string;
  cabecalho: any;
  filial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl();

    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  buscarObservacoes(filial: string, matricula: string) {
    return this.http.get(`${this.url_acesso}observacao/listar/FAPP/${filial}/${matricula}/014`, {
      headers: this.cabecalho,
    });
  }

  inserirObservacao(observacao: ObservacaoEnvio) {
    return this.http.post(`${this.url_acesso}observacao/salvar`, observacao, {
      headers: this.cabecalho,
    });
  }

  atualizarObservacao(observacao: ObservacaoEnvio) {
    return this.http.put(`${this.url_acesso}observacao/alterar`, observacao, {
      headers: this.cabecalho,
    });
  }

  deletarObservacao(filial: string, matricula: string, dtcseq: string) {
    return this.http.delete(
      `${this.url_acesso}observacao/excluir/FAPP/${filial}/${matricula}/014/${dtcseq}`,
      {headers: this.cabecalho}
    );
  }
}
