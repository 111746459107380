import { LancamentoFin } from "./classes/LancamentoFin";
import { AcessoService } from "./../../service/acesso/acesso.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorage } from "src/app/service/classes/localstorage/LocalStorage";
import { Crypto } from "src/app/service/classes/crypto/crypto";

@Injectable({
  providedIn: "root",
})
export class LancamentoFinanceiroService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any;
  filial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(
        new Crypto().decryptUsingAES256(localStorage.getItem("util"))
      );
      this.cabecalho = this.headers.append("sessao", this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }
  url_acesso: any = this.acesso.validarUrl();
  buscarTipoProcessamento() {
    return this.http.get(`${this.url_acesso}tipoProcessamento/${this.filial}`, {
      headers: this.cabecalho,
    });
  }
  buscarTipoCompetencia(TipoProcessamento: any) {
    return this.http.get(
      `${this.url_acesso}competenciaAberta/${TipoProcessamento}/${this.filial}`,
      { headers: this.cabecalho }
    );
  }

  verificarTipoProcessamento(){
    return this.http.get(`${this.url_acesso}vtbuso`,{
      headers: this.cabecalho
    });
  }

  buscarColaboradorMatricula(matricula: string) {
    return this.http.get(
      `${this.url_acesso}cadastro-ativo-matricula/${matricula}/${this.filial}`,
      { headers: this.cabecalho }
    );
  }

  buscarColaboradorMatriculaSituacao(
    competencia: any,
    TipoProcessamento: any,
    matricula: string
  ) {
    return this.http.get(
      `${this.url_acesso}lancamento-obter-colaborador/${competencia}/${TipoProcessamento}/${this.filial}/${matricula}`,
      { headers: this.cabecalho }
    );
  }

  buscarColaborador(cpf: string) {
    return this.http.get(
      `${this.url_acesso}lancamento-financeiro/${this.filial}/${cpf}`,
      { headers: this.cabecalho }
    );
    // Anterior - return this.http.get(`${this.url_acesso}cadastro/${cpf}/0000000/EM/${this.filial}`,{headers:this.cabecalho});
  }

  BuscarVerbas() {
    return this.http.get(`${this.url_acesso}verbasLancamentoFinanceiro`, {
      headers: this.cabecalho,
    });
  }

  BuscarVerbasRRA() {
    return this.http.get(`${this.url_acesso}verbasLancamentoFinanceiroRRA`, {
      headers: this.cabecalho,
    });
  }

  buscaNiveis(competencia: any) {
    return this.http.get(
      `${this.url_acesso}niveisLancamentoFinanceiro/${competencia}`,
      { headers: this.cabecalho }
    );
  }

  buscarLancamentos(matricula: any) {
    return this.http.get(
      `${this.url_acesso}lancamentoFinanceiro/listar/${this.filial}/${matricula}`,
      { headers: this.cabecalho }
    );
  }
  substituirLancamento(
    competencia: any,
    TipoProcessamento: any,
    matricula: any,
    dados: LancamentoFin
  ) {
    return this.http.post(
      `${this.url_acesso}lancamentosFinanceiro/substituir/${competencia}/${TipoProcessamento}/${this.filial}/${matricula}`,
      [dados],
      { headers: this.cabecalho }
    );
  }

  alterarLancamento(
    competencia: any,
    TipoProcessamento: any,
    matricula: any,
    dados: LancamentoFin
  ) {
    return this.http.put(
      `${this.url_acesso}lancamentosFinanceiro/alterar/${competencia}/${TipoProcessamento}/${this.filial}/${matricula}`,
      [dados],
      { headers: this.cabecalho }
    );
  }
  incluirLancamento(
    competencia: any,
    TipoProcessamento: any,
    matricula: any,
    dados: LancamentoFin
  ) {
    return this.http.post(
      `${this.url_acesso}lancamentosFinanceiro/incluir/${competencia}/${TipoProcessamento}/${this.filial}/${matricula}`,
      [dados],
      { headers: this.cabecalho }
    );
  }

  excluirLancamento(
    competencia: any,
    TipoProcessamento: any,
    matricula: any,
    dados: LancamentoFin
  ) {
    return this.http.post(
      `${this.url_acesso}lancamentosFinanceiro/excluir/${competencia}/${TipoProcessamento}/${this.filial}/${matricula}`,
      [dados],
      { headers: this.cabecalho }
    );
  }

  alterarPagas(
    competencia: any,
    TipoProcessamento: any,
    matricula: any,
    dados: LancamentoFin
  ) {
    return this.http.put(
      `${this.url_acesso}lancamentosFinanceiro/alterar-pagas/${competencia}/${TipoProcessamento}/${this.filial}/${matricula}`,
      [dados],
      { headers: this.cabecalho }
    );
  }

  validarLancamento(
    parcela: string,
    parcelaAnterior: string,
    parcelaOcorrida: string,
    parcelasOcorridaAnteriormente: string
  ) {
    return this.http.get(
      `${this.url_acesso}lancamento-financeiro-valida/${parcela}/${parcelaAnterior}/${parcelaOcorrida}/${parcelasOcorridaAnteriormente}`,
      { headers: this.cabecalho }
    );
  }
}
