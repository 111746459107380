import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert'
import { NgxSpinnerService } from 'ngx-spinner';
import { ErroService } from 'src/app/service/erros/erro.service';
import { PermissaoEmpftService } from './permissao-empft.service'; 
import { Parametro } from 'src/app/service/interfaces/Parametro';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';

@Component({
  selector: 'app-permissao-empft',
  templateUrl: './permissao-empft.component.html',
  styleUrls: ['./permissao-empft.component.css']
})
export class PermissaoEmpftComponent implements OnInit {

  listaTipo: Parametro[] = [];
  temErro: boolean = false;
  formPermissaoEmpft: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder, public spinner: NgxSpinnerService, public validaErro: ErroService, private permissaoEmpftService: PermissaoEmpftService) { }

  ngOnInit() {
    this.formPermissaoEmpft = this.fb.group({
      codigo: ['', Validators.required],
      nome: ['', Validators.required],
      empft: ['', Validators.required],
      tipos: ['', Validators.required],
    })
    this.buscarTipo();
  }


  buscarTipo() {
    this.permissaoEmpftService.buscarTipo()
    .subscribe(
      (data: any) => {
        this.listaTipo = data;
      },
      error => {
        this.validaErro.retornoErro(error);
      }
    )
  }

  submitFormPermissao(): void {
    if (this.formPermissaoEmpft.valid) {
      this.temErro = false;
      this.spinner.show();
      const permissao: Permissao = this.montarPermissao();
      this.permissaoEmpftService.salvarPermissao(permissao)
      .subscribe( 
        (data: any) => {
          this.spinner.hide();
          new AlertaModal('sucesso',"Sucesso!", "Teste estágio foi salvo",'p')
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        }
      )
    } else {
      this.temErro = true
      new AlertaModal('alerta','Atenção', 'Verifique se os campos inválidos.','p')
    }
  }

  montarPermissao(): Permissao {
    return {
      codigo: this.formPermissaoEmpft.get('codigo').value,
      nome: this.formPermissaoEmpft.get('nome').value,
      empft: this.formPermissaoEmpft.get('empft').value,
      tipos: this.formPermissaoEmpft.get('tipo').value
    }
  }

}

export interface Permissao {
  codigo: number;
  nome: string;
  empft: string;
  tipos: string;
}
