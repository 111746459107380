import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { EsocialService } from '../esocial/esocial.service';
import { ErroService} from '../../../service/erros/erro.service';

declare var $: any

@Component({
  selector: 'app-esocial',
  templateUrl: './esocial.component.html',
  styleUrls: ['./esocial.component.css']
})
export class EsocialComponent implements OnInit {

  constructor( private esocial_service:EsocialService, private validaErro:ErroService) { }

  // EventoEsocialTemp:{

  //   Empfil : string,
  //   NomeEmpresa : string,
  //   NumeroOrdem : string,
  //   TipoProcessamento : string,
  //   DataCompetencia : string,
  //   NumeroEvento : string,
  //   DataEvento : string,
  //   AcessoEvento : string,
  //   ConteudoAnterior : string,
  //   IdEventoXml : string,
  //   StatusXml : string,
  //   NumerodeLoteEvento : string,
  //   EventoXml : string,
  //   NumeroReciboXml : string,
  //   Chave : string,
  //   Detalhe : string,
    


  // }

  // listaEventosEsocial:any[] = [];
  // MudarTela:boolean = false;
  // Pagina:number =1;//1
  // PaginaPesquisa:number =1;//1
  // Carregando:boolean = false;
  // FimRolagem:boolean = false;
  // PesquisaEvento:boolean = false;
  // NaoEncontrado:boolean = false;
  // PesquisaTexto:string ="";

  
  // PesquisarEvento(pagina,pesquisa)
  // {
  //   if(pagina==1)
  //   {
  //     this.FimRolagem = false;
  //     this.NaoEncontrado = false;
  //     this.PesquisaEvento =true;
  //     this.PaginaPesquisa =1;
  //     this.Carregando = true;
  //     this.listaEventosEsocial = [];
  //     this.PesquisaTexto = pesquisa;
  //   }

  //   this.esocial_service.PesquisarEsocialPaginado("20001001",pagina,pesquisa)
  //   .subscribe(
  //     data  => {
  //       var retorno = JSON.parse(JSON.stringify(data));
        
  //       if(retorno != "")
  //       {
  //         this.NaoEncontrado =false;
  //         retorno.forEach(element => {

  //           if(element.StatusXml == "P")
  //           {
  //             element.StatusXml = "Processado com Sucesso";
  //           } 
  //           else if(element.StatusXml.toString().trim() == "E")
  //           {
  //             element.StatusXml = "Erro no Processamento"
  //           }
  //           else if(element.StatusXml == "I")
  //           {
  //             element.StatusXml = "Pronto para Inclusão"
  //           }
  //           else if(element.StatusXml == "A")
  //           {
  //             element.StatusXml = "Aguardando Retorno Esocial"
  //           }
  //           else if( element.StatusXml.trim() =="" || element.StatusXml == null)
  //           {
  //             element.StatusXml = "Aguardando criação XML"
  //           }

  //           this.listaEventosEsocial.push(element);
  //         });
  
  
  //       } else{
          
  //         if(this.PaginaPesquisa==1)
  //         {
  //           this.NaoEncontrado =true;

  //         }
  //         else{
  //         this.FimRolagem = true;

  //         }
  //       }
        
  //       this.Carregando = false;

  //     },
  //     error  => { 
  //       this.validaErro.retornoErro(error);
  //     }
  //   );


  // }

  // PopularListaPaginada(pagina){

  //   this.esocial_service.ListarEsocialPaginado("20001001",pagina)
  //   .subscribe(
  //     data  => {
  //       var retorno = JSON.parse(JSON.stringify(data));
        
  //       if(retorno != "")
  //       {
  //         retorno.forEach(element => {

  //           if(element.StatusXml == "P")
  //           {
  //             element.StatusXml = "Processado com Sucesso";
  //           } 
  //           else if(element.StatusXml.toString().trim() == "E")
  //           {
  //             element.StatusXml = "Erro no Processamento"
  //           }
  //           else if(element.StatusXml == "I")
  //           {
  //             element.StatusXml = "Pronto para Inclusão"
  //           }
  //           else if(element.StatusXml == "A")
  //           {
  //             element.StatusXml = "Aguardando Retorno Esocial"
  //           }
  //           else if( element.StatusXml.trim() =="" || element.StatusXml == null)
  //           {
  //             element.StatusXml = "Aguardando criação XML"
  //           }

  //           this.listaEventosEsocial.push(element);
  //         });
  
  
  //       } else{
          
  //         this.FimRolagem = true;
  //       }
        
  //       this.Carregando = false;

  //     },
  //     error  => { 
  //       this.validaErro.retornoErro(error);
  //     }
  //   );



    
  // }

  // VoltarParaTelaPrincipal()
  // {
  //   this.MudarTela = false;
  // }

  // ObterCor(esocial)
  // {
  //   if(esocial.StatusXml =="Processado com Sucesso")
  //   {
  //     return '#DDFFDE';
  //   }
  //   else if(esocial.StatusXml == "Pronto para Inclusão" || esocial.StatusXml =="Aguardando Retorno Esocial" || esocial.StatusXml =="Aguardando criação XML")
  //   {
  //     return '#FFF8C4';
  //   }
  //   else if(esocial.StatusXml =="Erro no Processamento")
  //   {
  //     return '#FFDCDC';
  //   }
   
  // }

  ngOnInit() {
   // this.Carregando = true;
   // this.PopularListaPaginada(1);
  }


  // VerificaTela(i){

  //   this.EventoEsocialTemp = this.listaEventosEsocial[i];

  //   this.MudarTela = true;
  // }

  

  // @HostListener("window:scroll", ["$event"])
  //   onWindowScroll() {
  //  if(!this.MudarTela && !this.FimRolagem)
  //  {
  //     let pos = (document.documentElement.scrollTop + document.body.scrollTop);
  //     let max = document.documentElement.scrollHeight-800;
  //     if(!this.Carregando)
  //     {

  //       if (pos >= max) {
  //         this.Carregando = true;

  //         if(!this.PesquisaEvento)
  //         {
  //           this.Pagina++;
  //           this.PopularListaPaginada(this.Pagina);
  //         }
  //         else{
  //           this.PaginaPesquisa++;
  //           this.PesquisarEvento( this.PaginaPesquisa, this.PesquisaTexto);
  //         }

  //       }


  //     }
  //   }
  // }
 


}


