import { Colaborador } from './../colaboradores/classes/Colaborador';
import { Pipe, PipeTransform } from "@angular/core";

function compare (n1 , n2) {
  if (n1.nome > n2.nome) {
    return 1;
  }

  if (n1.nome < n2.nome) {
      return -1;
  }

  return 0;
}

function removerAcentos( newStringComAcento ) {
  var string = newStringComAcento;
  var mapaAcentosHex 	= {
      a : /[\xE0-\xE6]/g,
      A : /[\xC0-\xC6]/g,
      e : /[\xE8-\xEB]/g,
      E : /[\xC8-\xCB]/g,
      i : /[\xEC-\xEF]/g,
      I : /[\xCC-\xCF]/g,
      o : /[\xF2-\xF6]/g,
      O : /[\xD2-\xD6]/g,
      u : /[\xF9-\xFC]/g,
      U : /[\xD9-\xDC]/g,
      c : /\xE7/g,
      C : /\xC7/g,
      n : /\xF1/g,
      N : /\xD1/g
  };

  for ( var letra in mapaAcentosHex ) {
      var expressaoRegular = mapaAcentosHex[letra];
      string = string.replace( expressaoRegular, letra );
  }

  return string;
}


@Pipe({
  name: 'pesquisaPipe'
})
export class PesquisaPipe implements PipeTransform {
  tam = 0;
  transform(ListaDoFiltro:any[], texto: string): any {
    if(texto != ""  && texto !== null && texto !== undefined ){
      if(ListaDoFiltro[0] instanceof Colaborador){
        ListaDoFiltro = ListaDoFiltro.filter(item => removerAcentos( item.nome).toLowerCase().search(texto) > -1).sort(compare);
        this.tam = ListaDoFiltro.length;
        return ListaDoFiltro;
      }else{
        ListaDoFiltro = ListaDoFiltro.filter(item =>  item.NumeroProcesso.search(texto) > -1).sort(compare);
        this.tam = ListaDoFiltro.length;
        return ListaDoFiltro;
     

      }
  
    }
    else{
      if(ListaDoFiltro != undefined){
        ListaDoFiltro = ListaDoFiltro.sort(compare);
            
      }
      return  ListaDoFiltro;
    }    
  }

}