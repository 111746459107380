<!-- <p>grupo-beneficio works!</p> -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<!-- <p>tipo-beneficiario works!</p> -->
<div class="card">
  <div class="card-header">
    <h3 class="card-title" id="target">Grupo de Benefício</h3>
  </div>
  <form #beneficio="ngForm" (ngSubmit)="submitGrupo()" [formGroup]="formGrupos" id="form-tipo-beneficiario">
    <div class="card-body">
      <div class="row">
        <div class="col-md-4 form-group">
          <label>Código</label>
          <input mask="0*" maxlength="4" type="text" class="form-control" name="codigo" placeholder="Código"
            formControlName="codigo"
            [ngStyle]="formGrupos.controls['codigo'].status == 'INVALID' && validaFormGrupo ? {'border-color':'red'} : null" />
          <span class="form-text text-danger"
            *ngIf="formGrupos.controls['codigo'].dirty && formGrupos.controls['codigo'].hasError('required') && validaFormGrupo">Insira
            um código</span>
        </div>
        <div class="col-md-6 form-group">
          <label>Descrição</label>
          <input type="text" class="form-control" name="descricao" placeholder="Descrição" formControlName="descricao" maxlength="30"
            [ngStyle]="formGrupos.controls['descricao'].status == 'INVALID' && validaFormGrupo ? {'border-color':'red'} : null" />
          <span class="form-text text-danger"
            *ngIf="formGrupos.controls['descricao'].dirty && formGrupos.controls['descricao'].hasError('required') && validaFormGrupo">Insira
            uma descrição</span>
        </div>
        <div class="col-md-2 form-group">
          <label>Controla Quantidade</label> <br>
          <input type="checkbox" id="check-controle" name="cahvebeneficiariofun" (click)="checkControle()" />

        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="text-right">
        <!--> Card botões<-->
        <div class="btn-group">
          <button *ngIf="editar" type="button" (click)="cancelarEditar()" class="btn btn-danger mr-2">
            <span>Cancelar</span>
          </button>
          <button type="submit" class="btn btn-primary mr-2">
            <span>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="table-responsive" style="max-height:45vh">
  <div class="card ">
    <!--> Card div tabelas<-->
    <div class="card-header">
      <h3 class="card-title">Listagem dos Grupos de Benefícios Cadastrados</h3>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead class="text-center">
            <tr class="text-center" style="white-space:nowrap">
              <th class="sticky-top  bg-white">Código</th>
              <th class="sticky-top  bg-white">Descrição</th>
              <th class="sticky-top  bg-white">Editar</th>
              <th class="sticky-top  bg-white">Excluir</th>
            </tr>
          </thead>
          <tbody *ngIf="listaGrupos && listaGrupos.length > 0" id="lista-tipo-beneficiario">
            <tr *ngFor="let grupo of listaGrupos" class="text-center" style="white-space:nowrap">
              <td>{{ grupo.Codigo }}</td>
              <td class="text-uppercase">{{ grupo.Descricao }}</td>
              <td> <button type="button" class="btn bg-teal-400" (click)="carregarCampos(grupo)"><i class="fa fa-pencil"
                    aria-hidden="true"></i></button> </td>
              <td> <button type="button" class="btn btn-danger" (click)="confirmarExcluir(grupo.Codigo)"><i
                    class="fa fa-trash" aria-hidden="true"></i></button> </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="!listaGrupos" class="row  d-flex justify-content-center align-items-center mt-3">
        <i class="fa fa-refresh fa-spin fa-2x "></i>
        <h4 class="ml-2">Aguarde...</h4>
      </div>
      <div *ngIf="listaGrupos && listaGrupos.length == 0">
        <div class="d-flex justify-content-center align-items-center mt-3">
          <h4>Não há dados para ser exibido</h4>
        </div>
      </div>
    </div>
  </div>
</div>