import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { LicencaPremioService } from '../licenca-premio.service';
import { LicencaPremioComponent } from '../licenca-premio.component';
import Swal from 'sweetalert';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErroService } from 'src/app/service/erros/erro.service';
import { HttpErrorResponse } from '@angular/common/http';

export interface Item {
  Cod: string;
  Nome: string;
  Codigo: string;
  Descricao: string;
}

@Component({
  selector: 'app-licenca-premio-cadastro',
  templateUrl: './licenca-premio-cadastro.component.html',
  styleUrls: ['./licenca-premio-cadastro.component.css'],
})
export class LicencaPremioCadastroComponent {
  @Input() cadastro: boolean = true;
  @Input() colaborador: Cadastro = null;
  @Input() licencaAtual: any = null;
  @Input() formTelaInicial: FormGroup;
  formLicencaPremio: FormGroup;
  licencaindex: number = null;

  listaClassificacaoAto: Item[] = [];
  Seq: number = null;
  listaLicenca: Item[] = [];
  listaFormasQuitacao: Item[] = [];

  constructor(
    private licencaPremioService: LicencaPremioService,
    private licencaPremioComponent: LicencaPremioComponent,
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private validaErro: ErroService
  ) {
    this.formLicencaPremio = this.fb.group({
      Sigla: [''],
      Empfil: [null],
      Mat: [null],
      DataInicio: ['', Validators.required],
      DataFim: ['', Validators.required],
      NumDias: [{ value: '', disabled: true }],
      QtdFaltas: [null],
      SitLip: [''],
      TpLip: [''],
      Condesp: [0],
      NumeroProcesso: ['', Validators.required],
      NumeroDoe: ['', Validators.required],
      IdClassificacaoAto: [null, Validators.required],
      NumeroAto: ['', Validators.required],
      AnoAto: [''],
      DtAto: ['', Validators.required],
      AnoDoe: [''],
      DtDoe: ['', Validators.required],
      DtComp: [''],
      TpProc: [''],
      FormaQuitacao: [null, Validators.required],
    });
    this.observarMudancasData();
  }
  ngOnInit() {
    console.log('licenca atual', this.formTelaInicial);
  }
  private observarMudancasData() {
    const inicioObs = this.formLicencaPremio.get('DataInicio').valueChanges;
    const fimObs = this.formLicencaPremio.get('DataFim').valueChanges;

    inicioObs.subscribe(() => {
      this.atualizarNumDias();
    });

    fimObs.subscribe(() => {
      this.atualizarNumDias();
    });
  }

  private atualizarNumDias() {
    const inicio = this.formLicencaPremio.get('DataInicio').value;
    const fim = this.formLicencaPremio.get('DataFim').value;

    if (inicio && fim) {
      const dataInicio = new Date(inicio);
      const dtFim = new Date(fim);
      const diff = dtFim.getTime() - dataInicio.getTime();
      const numDias = diff / (1000 * 3600 * 24);

      this.formLicencaPremio.get('NumDias').setValue(numDias);
    }
  }

  lancarLicenca() {
    this.spinner.show();
    this.formLicencaPremio.markAllAsTouched();
    let botaoFechar = document.getElementById('botaoFechar');
    console.log('payload form puro', this.formLicencaPremio);
    if (this.formLicencaPremio.valid) {
      let licencaLancar = this.montarLicenca().Licenca;
      console.log('payload', licencaLancar);

      if (this.licencaindex !== null && this.licencaindex !== undefined) {
        this.licencaPremioService.atualizarLicenca(licencaLancar).subscribe(
          (data) => {
            Swal('Sucesso', 'Licença atualizada com sucesso.', 'success');
            this.licencaPremioComponent.buscarLicenca();
            if (botaoFechar) botaoFechar.click();
            console.log('atualizado', data);
            this.spinner.hide();
          },
          (err: HttpErrorResponse) => {
            if (err.status === 500) {
              Swal('Erro', 'Não foi possível atualizar essa falta.', 'error');
            } else {
              const errorMessage = err.error?.Mensagem || 'Ocorreu um erro ao atualizar essa falta.';
              Swal('Erro', errorMessage, 'error');
            }
            this.spinner.hide();
          }
        );
      } else {
        this.licencaPremioService.inserirLicenca(licencaLancar).subscribe(
          (data) => {
            Swal('Sucesso', 'Licença cadastrada com sucesso.', 'success');
            this.licencaPremioComponent.buscarLicenca();
            if (botaoFechar) botaoFechar.click();
            this.spinner.hide();
          },
          (err: HttpErrorResponse) => {
            if (err.status === 500) {
              Swal('Erro', 'Não foi possível inserir essa falta.', 'error');
            } else {
              const errorMessage = err.error?.Mensagem || 'Ocorreu um erro ao atualizar essa falta.';
              Swal('Erro', errorMessage, 'error');
            }
            this.spinner.hide();
          }
        );
      }
    } else {
      Swal('Atenção', 'Por favor, preencha todos os campos corretamente.', 'warning');
      this.spinner.hide();
    }
  }

  buscarIdClassificacaoAto() {
    this.listaClassificacaoAto = this.licencaPremioService.retornarLista('classificacao');
    console.log(this.listaClassificacaoAto);
    if (this.listaLicenca.length == 0) {
      this.spinner.show();
      this.licencaPremioService.buscarIdClassificacaoAto().subscribe(
        (data: Item[]) => {
          this.listaClassificacaoAto = data;
          this.licencaPremioService.inserirLista('classificacao', this.listaClassificacaoAto);
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.validaErro.retornoErro(err);
        }
      );
    }
  }

  montarLicenca() {
    let licenca = {
      Sigla: 'FAPP',
      Mat: this.colaborador.Matricula,
      Empfil: this.colaborador.Filial.Codigo,
      DataInicio: this.formLicencaPremio.get('DataInicio').value
        ? {
            SetData: this.formLicencaPremio.get('DataInicio').value.replace(/-/g, ''),
            ToDate: this.licencaPremioService.formatarDataBarra(
              this.formLicencaPremio.get('DataInicio').value.replace(/-/g, '')
            ),
            IsDate: true,
            Empty: false,
          }
        : '00000000',
      DataFim: this.formLicencaPremio.get('DataFim').value
        ? {
            SetData: this.formLicencaPremio.get('DataFim').value.replace(/-/g, ''),
            ToDate: this.licencaPremioService.formatarDataBarra(
              this.formLicencaPremio.get('DataFim').value.replace(/-/g, '')
            ),
            IsDate: true,
            Empty: false,
          }
        : '00000000',
      NumDias: this.formLicencaPremio.get('NumDias').value ?? null,
      QtdFaltas: this.formLicencaPremio.get('QtdFaltas').value ?? null,
      SitLip: this.formLicencaPremio.get('SitLip').value ?? '',
      TpLip: this.formLicencaPremio.get('TpLip').value ?? '',
      Condesp: 0,
      NumeroProcesso: this.formLicencaPremio.get('NumeroProcesso').value ?? '',
      NumeroDoe: this.formLicencaPremio.get('NumeroDoe').value ?? '',
      IdClassificacaoAto: parseInt(this.formLicencaPremio.get('IdClassificacaoAto').value) ?? null,
      FormaQuitacao: this.formLicencaPremio.get('FormaQuitacao').value ?? null,
      NumeroAto: this.formLicencaPremio.get('NumeroAto').value ?? '',
      AnoAto: this.formLicencaPremio.get('DtAto').value?.substring(0, 4) ?? '',
      DtAto: this.formLicencaPremio.get('DtAto').value
        ? {
            SetData: this.formLicencaPremio.get('DtAto').value.replace(/-/g, ''),
            ToDate: this.licencaPremioService.formatarDataBarra(
              this.formLicencaPremio.get('DtAto').value.replace(/-/g, '')
            ),
            IsDate: true,
            Empty: false,
          }
        : '00000000',
      AnoDoe: this.formLicencaPremio.get('DtDoe').value?.substring(0, 4) ?? '',
      DtDoe: this.formLicencaPremio.get('DtDoe').value
        ? {
            SetData: this.formLicencaPremio.get('DtDoe').value.replace(/-/g, ''),
            ToDate: this.licencaPremioService.formatarDataBarra(
              this.formLicencaPremio.get('DtDoe').value.replace(/-/g, '')
            ),
            IsDate: true,
            Empty: false,
          }
        : '00000000',
      DtComp: this.formLicencaPremio.get('DtComp').value ?? '',
      TpProc: 'EM',
      Flag: 2,
      Seq: this.Seq ?? 0,
    };

    return { Licenca: licenca };
  }

  buscarFormasQuitacao() {
    if (!this.listaFormasQuitacao) {
      this.listaFormasQuitacao = [];
    }

    if (this.listaFormasQuitacao.length === 0) {
      this.spinner.show();
      this.licencaPremioService.buscarFormasQuitacao().subscribe({
        next: (data: Item[]) => {
          this.listaFormasQuitacao = data;
          this.licencaPremioService.inserirLista('formaQuitacao', this.listaFormasQuitacao);
          this.spinner.hide();
        },
        error: (error) => {
          console.error('Erro ao buscar formas de quitação:', error);
          this.spinner.hide();
        },
      });
    }
  }
}
