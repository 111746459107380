import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert';
import { NgxSpinnerService } from 'ngx-spinner';
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { filtrarServico } from '../../pse/servicos/filtrar-servico.pipe';
import { ElogiosEPenalidadesService } from './elogios-e-penalidades.service';
//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ErroService } from 'src/app/service/erros/erro.service';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';

@Component({
  selector: 'app-elogios-e-penalidades',
  templateUrl: './elogios-e-penalidades.component.html',
  styleUrls: ['./elogios-e-penalidades.component.css']
})
export class ElogiosEPenalidadesComponent implements OnInit {
  formTelaInicial: FormGroup;
  formPrincipal: FormGroup;
  habilitarCancelar: boolean = true;
  habilitarSalvar: boolean = true;
  telaPrincipal: boolean = true;
  matriculaFormError: boolean = false;
  tipoFormError: boolean = false;
  autorFormError: boolean = false;
  motivoFormError: boolean = false;
  continuar: boolean = false;
  editado: boolean = false;
  dataFormError: boolean = false;
  valorMaximoMotivo: number = 300;
  valorCount: number = 0;
  filter: any = '';
  motivoContador: string = "";
  listaEPE: ElogioPenalidade[] = [];
  listTiposEPE: TipoEPE[] = [];
  resultado: UsuarioEPE = new UsuarioEPE;
  listEPE: any =[]
  objeto: ElogioPenalidade = null;
  constructor(public fb: FormBuilder, private router: Router, private ePEService: ElogiosEPenalidadesService, private spinner: NgxSpinnerService, private validaError: ErroService) {}

  ngOnInit() {
    ///Formulario da tela inicial
    this.formTelaInicial = this.fb.group({
      matricula_form: ['', [Validators.required]],
      Nome_form: ['']
    });
    ///Formulario da tela principal
    this.formPrincipal = this.fb.group({
      tipo_form: ['', [Validators.required]],
      autor_form: ['', [Validators.required, Validators.maxLength(80)]],
      motivo_form: ['', [Validators.required, Validators.maxLength(this.valorMaximoMotivo)]],
      data_form: ['']
    });

  }

  ///Método que não permite letras no input da matricula
  somenteNumero(evento) {
    return ((evento.charCode >= 48 && evento.charCode <= 57));
  }

  ///Método respnsável por identificar a quantidade de caracteres no textArea do motivo
  onContador(evento) {
    this.motivoContador = evento.target.value;
  }

  ///Metodo responsavel por montar o objeto antes de enviar
  montarObjeto() {
    this.objeto = {
      Nome: this.resultado.Nome,
      Matricula: this.formTelaInicial.get("matricula_form").value,
      Tipo: this.formPrincipal.get("tipo_form").value,
      Autor: this.formPrincipal.get("autor_form").value,
      Motivo: this.formPrincipal.get("motivo_form").value,
      Data: (document.getElementById("data_input") as HTMLInputElement).value != null || "" ? (document.getElementById("data_input") as HTMLInputElement).value : (document.getElementById("data_disabled") as HTMLInputElement).value
    };
  }
  ///Método da mascara para a data
  dataTransform(data: string) {
    20220907
    let ano = data.substring(0, 4);
    let mes = data.substring(4, 6);
    let dia = data.substring(6);
    return (dia + "/" + mes + "/" + ano);
  }

  ///Método para aplicar a bordar de erro no input de data
  onNgEstilo(): Object {
    if (this.dataFormError) {

      return { 'border-color': 'red' }
    }
    else if (this.editado) {
      return { 'display': 'none' }
    }
    return {}
  }

  obterListaEPE(matricula: number){
    try {
      this.ePEService.obterListaEloPen(matricula).subscribe(
        (a:ElogioPenalidade[]) => {
          this.listEPE = a ;
        },
        error => {
          this.listEPE = [];
          this.validaError.retornoErro(error);

        }
      )
    } catch (error) {
      
    }
  }
  ///Método para obter a lista de tipos(Elogios ou penaliades)
  obterListaTiposEPE() {
    try {
      this.ePEService.obterTiposEPE().subscribe(
        a => {
          this.listTiposEPE = a as TipoEPE[];
        },
        error => {
          this.validaError.retornoErro(error);
        }
      )
    } catch (error) { }
  }
  ///Método para buscar um usuário por matricula
  buscaPorMatricula() {
    try {
      if (this.formTelaInicial.get("matricula_form").valid) {
        this.spinner.show();
        this.ePEService.obterUsuarioPorMatricula(parseInt(this.formTelaInicial.get("matricula_form").value)).subscribe(
          data => {
            this.resultado = data as UsuarioEPE;

            this.obterListaTiposEPE();
            this.obterListaEPE(this.resultado.Matricula);
            this.spinner.hide();
            this.matriculaFormError = false;
            this.continuar = true;
          },
          error => {
            this.validaError.retornoErro(error);
            this.matriculaFormError = true;
            this.spinner.hide();
          }
        );
      } else {
        this.matriculaFormError = true;
      }
    } catch (error) {
      new AlertaModal("alerta", "Atenção!", error.error.Mensagem, 'p');
     }
    this.spinner.hide();
  }

  ///Método para enviar um novo objeto
  enviarNovoElogioPenalidade() {
    try {
      this.spinner.show();
      this.ePEService.inserirElogiosEPenalidades(this.objeto).subscribe(
        data => {
          new AlertaModal("sucesso", "Sucesso!", this.objeto.Tipo.toUpperCase() == 'ELOGIO' ? 'Elogio enviado com sucesso!' : 'Penalidade enviada com sucesso!', 'p');
          this.buscaPorMatricula();
          this.spinner.hide();
          this.limparCampos();
          this.dataFormError = false;
        },
        error => {
          this.validaError.retornoErro(error);
          this.spinner.hide();
        }
      )
    } catch (error) {
      new AlertaModal("alerta", "Atenção!", error.Mensagem, 'p');
      this.spinner.hide();
    }
  }
  ///Método para enviar um objeto alterado
  enviarAlteracaoElogioPenalidade() {
    try {
      this.spinner.show();
      this.ePEService.editarElogioPenalidade(this.objeto).subscribe(
        data => {
          this.spinner.hide();
          if (data) {
            new AlertaModal("sucesso", "Sucesso!", this.objeto.Tipo.toUpperCase() == 'ELOGIO' ? 'Elogio alterado com sucesso!' : 'Penalidade alterada com sucesso!', 'p');
            this.buscaPorMatricula();
            this.limparCampos();
            this.dataFormError = false;
            this.editado = false;
            this.resultado.ListaEPE[this.resultado.ListaEPE.findIndex(a => a.Data == this.objeto.Data)] = this.objeto;
          } else {
            new AlertaModal("alerta", "Atenção!", 'Não foi possivel completar a atualização!', 'p');
          }
          this.spinner.hide();
        },
        error => {
          this.validaError.retornoErro(error);
          this.spinner.hide();
        }
      )
    } catch (error) {
      new AlertaModal("alerta", "Atenção!", error.Mensagem, 'p');
      this.spinner.hide();
    }
  }

  ///Método de submit da tela de pesquisar por matricula
  onSubmitFormTelaInicial() {
    if (this.formTelaInicial.valid && this.continuar == true) {
      this.telaPrincipal = false;
    }
    else {
      this.matriculaFormError = true;
    }
  }
  ///Método de submit do form principal
  onSubmitFormPrincipal() {
    if (this.formPrincipal.valid) {
      this.montarObjeto();
      if (this.objeto.Data == "" || this.objeto.Data == null) {
        this.dataFormError = true;
        new AlertaModal("alerta", "Atenção!", 'Indique uma data.', 'p');
        this.spinner.hide();
        return 0
      }
      else {
        if (!this.editado) {
          this.enviarNovoElogioPenalidade();
        } else {
          this.enviarAlteracaoElogioPenalidade();
        }
      }
    } else {
      new AlertaModal("alerta", "Atenção!", 'Preencha todos os campos!', 'p');
      if (this.formPrincipal.get('tipo_form').invalid) {
        this.tipoFormError = true;
      }
      if (this.formPrincipal.get('autor_form').invalid) {
        this.autorFormError = true;
      }
      if (this.formPrincipal.get('motivo_form').invalid) {
        this.motivoFormError = true;
      }
      this.montarObjeto();
      if (this.objeto.Data == "" || this.objeto.Data == null) {
        this.dataFormError = true;
      }
    }
  }
  ///Método para retornar para a tela inicial
  retornarTelaInicial() {
    this.limparCampos();
    this.continuar = false;
    this.resultado = new UsuarioEPE;
    this.formTelaInicial.controls["matricula_form"].setValue('');
    this.telaPrincipal = true;
  }
  ///Método para preencher o formulario com todos da dados de um objeto já existente
  editarElogioPersonalidade(item: ElogioPenalidade) {
    this.limparCampos();
    this.formPrincipal.controls["tipo_form"].setValue(this.listTiposEPE.find(a => a.Descricao.toLowerCase() == item.Tipo.toLowerCase()).Descricao);
    this.formPrincipal.controls["tipo_form"].disable();
    this.formPrincipal.controls["autor_form"].setValue(item.Autor);
    this.formPrincipal.controls["motivo_form"].setValue(item.Motivo);
    this.editado = true;
    var datas = item.Data.substring(6) + "/" + item.Data.substring(4, 6) + "/" + item.Data.substring(0, 4);
    var atributo = (document.getElementById("data_disabled") as HTMLInputElement)
    var atributoReal = (document.getElementById("data_input") as HTMLInputElement)
    atributo.value = datas;
    atributoReal.value = datas;
    window.scroll(0, 0);
  }
  ///Método para remover um objeto
  excluirElogioPersonalidade(epe: ElogioPenalidade, i: number) {
    Swal({
      title: "Você tem certeza?",
      text: "Esta operação não pode ser desfeita.",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true
        }
      }
    })
      .then((willDelete) => {
        if (willDelete) {
          try {
            this.spinner.show();
            this.ePEService.removerElogioPenalidade(epe.Data, epe.Tipo, epe.Autor, epe.Matricula).subscribe(
              a => {
                new AlertaModal("sucesso", 'Sucesso!', epe.Tipo.toUpperCase() == 'ELOGIO' ? 'Elogio excluido com sucesso!' : 'Penalidade excluida com sucesso!', 'p');
                let indecie = this.resultado.ListaEPE.indexOf(epe);
                this.buscaPorMatricula();
                this.spinner.hide();
              },
              error => {
                new AlertaModal("alerta", 'Erro!', error.error.Mensagem, 'p');
                this.spinner.hide();
              }
            )
          } catch (error) { }
        }
      });
  }

  ///Método para detectar quando o input da matricula foi alterado
  detectarBackSpace(evento) {
    if (evento.key == "Backspace" || evento.key == "Delete") {
      this.resultado = new UsuarioEPE();
    }
  }

  ///Método para limpar todos os inputs
  limparCampos() {
    this.formPrincipal.controls["tipo_form"].setValue('');
    this.formPrincipal.controls["tipo_form"].enable();
    this.formPrincipal.controls["autor_form"].setValue('');
    this.formPrincipal.controls["motivo_form"].setValue('');
    this.formPrincipal.controls["data_form"].setValue('');
    this.motivoContador = "";
    this.dataFormError = false;
    this.tipoFormError = false;
    this.autorFormError = false;
    this.motivoFormError = false;
    this.dataFormError = false;
    this.editado = false;
  }
  onKeyUp(target : any) {
    if(target instanceof EventTarget) {
      var elemento = target as HTMLInputElement;
      this.filter = elemento.value;
    }
  }
}

///Classe que represente um Elogio ou Penalidade
export class ElogioPenalidade {
  Nome: String;
  Matricula: number;
  Autor: string;
  Motivo: string;
  Tipo: string;
  Data: string;
}
///Usuário obtido através da pesquisa
export class UsuarioEPE {
  Nome: string;
  Sexo: string;
  Matricula: number;
  ListaEPE: ElogioPenalidade[] = [];
}
///Classe que recebe os dois tipos(Elogios ou Penalidades)
export class TipoEPE {
  ChaveUnica: string;
  Valor: string;
  Descricao: string;
}
