import { Injectable } from '@angular/core';
import { AcessoService } from '../../service/acesso/acesso.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Matricula } from './contracheque.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root',
})
export class ContrachequeService {
  localStorage: LocalStorage = new LocalStorage();

  headers = new HttpHeaders();

  url_acesso: string;
  cabecalho: any;
  filial: string;
  codTarefa: string = '000000000';
  tpProcessamento: string = 'EM';

  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl();

    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      console.log(this.cabecalho);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  buscarContracheque(matricula: Matricula, competencia: string, tipoProcessamento: string) {
    return this.http.get(
      `${this.url_acesso}relatorio/contra-cheque/${competencia}/${tipoProcessamento}/${matricula.EmpFil}/${matricula.Matricula}`,
      { headers: this.cabecalho }
    );
  }

  buscarColaborador(matricula: string) {
    return this.http.get(
      `${this.url_acesso}colaborador/historico/${this.codTarefa}/${this.tpProcessamento}/${this.filial}/${matricula}`,
      { headers: this.cabecalho }
    );
  }

  buscarColaboradorMatricula(matricula: string) {
    return this.http.get(`${this.url_acesso}cadastro-ativo-matricula/${matricula}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarColaboradorGeral(matricula: string) {
    return this.http.get(`${this.url_acesso}cadastro-todos-matricula/${matricula}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarPrevia(matricula: Matricula, tipo, tpProcessamento, dtComp) {
    return this.http.get(
      `${this.url_acesso}relatorio/previa-contracheque/${dtComp}/${tpProcessamento}/${matricula.EmpFil}/${matricula.Matricula}/${tipo}`,
      { headers: this.cabecalho }
    );
  }

  buscarVerba(matricula: Matricula) {
    return this.http.get(
      `${this.url_acesso}relatorio/previa-contracheque/${this.tpProcessamento}/${matricula.EmpFil}/${matricula.Matricula}/0`,
      { headers: this.cabecalho }
    );
  }

  buscarTipoProcessamento(dtcomp: string, numord: string) {
    return this.http.get(
      `${this.url_acesso}relatorio/processamentos/${dtcomp}/${numord}/${this.filial}`,
      { headers: this.cabecalho }
    );
  }

  buscarTipoProcessamentoPrevia() {
    return this.http.get(`${this.url_acesso}relatorio/tipo-processamento/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarTipoProcessamentoCompetencia() {
    return this.http.get(`${this.url_acesso}tipoProcessamento/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarPdfTraacer(dtcomp: string) {
    return this.http.get(`${this.url_acesso}pdf/trace-contra-cheque/${dtcomp}/EM/${this.filial}`, {
      headers: this.cabecalho,
    });
  }
}
