import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InfoColaborador } from '../cadastro-complementar/cadastro-complementar.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';
import { Data } from 'src/app/service/interfaces/Data';
import { InformacoesConcursoService } from './informacoes-concurso.service';
import { ErroService } from 'src/app/service/erros/erro.service';
import Swal from 'sweetalert';

@Component({
  selector: 'app-informacoes-concurso',
  templateUrl: './informacoes-concurso.component.html',
  styleUrls: ['./informacoes-concurso.component.css'],
})
export class InformacoesConcursoComponent implements OnInit {
  formCadastro: FormGroup;
  infoColaborador: InfoColaborador;
  listaDescricao: Descricao[] = [];
  cadastro: boolean = true;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private concursoService: InformacoesConcursoService,
    private spinner: NgxSpinnerService,
    public validaErro: ErroService
  ) {
    this.formCadastro = this.fb.group({
      dtConcurso: ['', Validators.required],
      descricao: ['', Validators.required],
      classificacao: ['', Validators.required],
      dtConvocacao: ['', Validators.required],
      dtEstagioInicial: ['', Validators.required],
      dtEstagioFinal: ['', Validators.required],
    });
  }

  ngOnInit() {
    if (history.state.usuario) {
      this.infoColaborador = history.state.usuario;
      this.buscarDescricao();
      this.buscarInformacoes();
    } else {
      this.retornarTelaColaboradores();
    }
  }

  retornarTelaColaboradores() {
    this.router.navigate(['/colaboradores/']);
  }

  buscarDescricao() {
    this.spinner.show();
    this.listaDescricao = [];
    this.concursoService.buscarDescricao(this.infoColaborador.matricula).subscribe({
      next: (data: Descricao[]) => {
        this.spinner.hide();
        this.listaDescricao = data;
      },
      error: (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      },
    });
  }

  buscarInformacoes() {
    this.spinner.show();
    const numOrdemColaborador = this.infoColaborador.numOrdem;
    this.concursoService.buscarInformacoes(numOrdemColaborador).subscribe({
      next: (data: InformacoesConcurso) => {
        this.spinner.hide();
        if (data[0]) {
          this.montarInformacoesForm(data[0]);
          this.cadastro = false;
        } else {
          this.cadastro = true;
        }
      },
      error: (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
        this.retornarTelaColaboradores();
      },
    });
  }

  submitCadastro() {
    if (this.formCadastro.invalid) {
      new AlertaModal('alerta', 'Atenção!', 'Preencha os campos corretamente.', 'p');
      this.formCadastro.markAllAsTouched();
    } else {
      this.cadastro ? this.cadastrarInformacoes() : this.atualizarInformacoes();
    }
  }

  cadastrarInformacoes() {
    this.spinner.show();
    const objCadastro: InformacoesConcurso = this.montarObjCadastro();
    const numOrdemColaborador = this.infoColaborador.numOrdem;
    this.concursoService.cadastrarInformacoes(objCadastro, numOrdemColaborador).subscribe({
      next: () => {
        this.buscarInformacoes();
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'Cadastro realizado com sucesso.', 'p');
      },
      error: (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      },
    });
  }
  atualizarInformacoes() {
    this.spinner.show();
    const objAtualizacao: InformacoesConcurso = this.montarObjCadastro();
    const numOrdemColaborador = this.infoColaborador.numOrdem;
    this.concursoService.atualizarInformacoes(objAtualizacao, numOrdemColaborador).subscribe({
      next: () => {
        this.buscarInformacoes();
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'Cadastro atualizado com sucesso.', 'p');
      },
      error: (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      },
    });
  }

  montarObjCadastro(): InformacoesConcurso {
    const dadosForm = this.formCadastro.getRawValue();

    return {
      Numord: this.infoColaborador.numOrdem,
      NumeroEdital: dadosForm.descricao,
      classificacao: dadosForm.classificacao,
      DataConcurso: new Data(dadosForm.dtConcurso),
      DataConvocacao: new Data(dadosForm.dtConvocacao),
      DataEstProbatorio_Inicio: new Data(dadosForm.dtEstagioInicial),
      DataEstProbatorio_Final: new Data(dadosForm.dtEstagioFinal),
    };
  }

  montarInformacoesForm(informacoesConcurso: InformacoesConcurso): void {
    this.formCadastro.setValue({
      dtConcurso: this.formatarDataRecebida(informacoesConcurso.DataConcurso.ToDate),
      descricao: informacoesConcurso.NumeroEdital,
      classificacao: informacoesConcurso.classificacao,
      dtConvocacao: this.formatarDataRecebida(informacoesConcurso.DataConvocacao.ToDate),
      dtEstagioInicial: this.formatarDataRecebida(
        informacoesConcurso.DataEstProbatorio_Inicio.ToDate
      ),
      dtEstagioFinal: this.formatarDataRecebida(informacoesConcurso.DataEstProbatorio_Final.ToDate),
    });
    console.log(this.formCadastro.value);
  }

  formatarDataRecebida(data: string): string {
    console.log();
    if (data !== '') {
      const arrayData = data.split('/');
      const dataFormatada = `${arrayData[2]}-${arrayData[1]}-${arrayData[0]}`;
      return dataFormatada;
    } else {
      return '';
    }
  }
}

export interface InformacoesConcurso {
  Numord: string;
  NumeroEdital: string;
  classificacao: string;
  DataConcurso: Data;
  DataConvocacao: Data;
  DataEstProbatorio_Inicio: Data;
  DataEstProbatorio_Final: Data;
}

export interface Descricao {
  NumeroEdital: string;
  Titulo: string;
  DataPublicacao: Data;
}
