import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export function validarDataAtualMenorIgual(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value; // Valor do campo a ser validado
    const dataAtual = new Date(); // Data atual

    const dataInput = new Date(controlValue);

    if (dataInput > dataAtual) {
      return { dataMaiorQueAtual: true };
    } else {
      return null;
    }
  };
}
