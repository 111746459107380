import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import Swal from 'sweetalert';
import {Data} from '../../service/interfaces/Data';
import {FeriasService} from './ferias.service';
import {ErroService} from '../../service/erros/erro.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {dataValidator} from 'src/app/service/Validators/dataValidator';
import {Periodo, RetornoFerias, InformacaoGenerica} from 'src/app/service/interfaces/RetornoFerias';
import {LocalStorage} from 'src/app/service/classes/localstorage/LocalStorage';
import {Crypto} from 'src/app/service/classes/crypto/crypto';

@Component({
  selector: 'app-ferias',
  templateUrl: './ferias.component.html',
  styleUrls: ['./ferias.component.css'],
})
export class FeriasComponent implements OnInit {
  localStorage: LocalStorage;

  formFerias: FormGroup;

  listaFerias: RetornoFerias[] = [];
  listaFeriasAnterior: RetornoFerias[] = [];
  parcelas: Periodo[] = [];
  parcelasAnterior: Periodo[] = [];
  listaClassificacao: InformacaoGenerica[] = [];
  listaSituacao: Periodo[] = [];

  backupColaborador: RetornoFerias = null;
  Ferias: RetornoFerias;
  colaboradorAtual: RetornoFerias;
  colaboradorEnviar: RetornoFerias;
  searchText: string = '';
  filtroPesquisa: string = 'T0';
  tipoUsuario: string;
  parcelaFerias: any;
  index: number = 0;
  somaDiasFerias: number = 0;
  selectValue: number = 15;
  statusSolicitacao: number;


  temErro: boolean = false;
  clientePublico: boolean;
  enableMotivo: boolean = false;
  solicitarFerias: boolean = false;
  setarMotivo: boolean = false;
  temRejeitada: boolean = false;
  btnSolicitar: boolean = true;
  isSolicitacao: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private feriasService: FeriasService,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService
    
  ) {
    this.localStorage = this.decryptLocalStorage();
    
    this.tipoUsuario = this.localStorage.TipoUsuario;
    this.clientePublico = this.localStorage.ClientePublico === true;
  }
  
  ngOnInit() {
    this.spinner.show();
    this.formFerias = this.formBuilder.group({
      
      venderDias: [{ value: "",}, [Validators.required]],
      adiantar13: [{ value: "", }, [Validators.required]],
      diasFerias: ['', [Validators.required]],
      parcela: [{ value: "",  }, Validators.compose([Validators.required])],
      inicioFerias: ['', [Validators.required, dataValidator]],
      fimFerias: ['', [Validators.required, dataValidator]],
      descricao: [''],
      classificacao: [{ value: "",  }],
      numeroAto: [''],
      ano: [''],
      dataAto: ['', dataValidator],
      numeroDiarioOficial: [''],
      dataDiarioOficial: [''],
    });
    this.buscarClassificacao();
    this.buscarFerias();
    
  }

  validarClientePublico() {
    if (this.clientePublico) {
      this.formFerias.get('numeroDiarioOficial').setValidators(Validators.required);
      this.formFerias.get('numeroDiarioOficial').updateValueAndValidity();

      this.formFerias.get('dataDiarioOficial').setValidators([Validators.required, dataValidator]);
      this.formFerias.get('dataDiarioOficial').updateValueAndValidity();

      this.formFerias.get('classificacao').setValidators(Validators.required);
      this.formFerias.get('classificacao').updateValueAndValidity();

      this.formFerias.get('numeroAto').setValidators(Validators.required);
      this.formFerias.get('numeroAto').updateValueAndValidity();

      this.formFerias.get('ano').setValidators(Validators.required);
      this.formFerias.get('ano').updateValueAndValidity();

      this.formFerias.get('dataAto').setValidators([Validators.required, dataValidator]);
      this.formFerias.get('dataAto').updateValueAndValidity();
    }
  }

  buscarFerias() {
    this.spinner.show("ferias");

    this.feriasService.buscarFerias(this.filtroPesquisa).subscribe(
      (data: RetornoFerias[]) => {
        this.listaFeriasAnterior = [...data];
        this.listaFerias = data;
        console.log(this.listaFerias);
        this.spinner.hide("ferias");
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide("ferias");
      }
    );
  }

  formatarCodigoVenderDias(n: number) {
    return n == 0 ? 'Não' : 'Sim';
  }

  mudarSituacao(parcelas: Periodo[]): void {
    const {codigoSituacao, descricao} = this.formFerias.getRawValue();
    this.solicitarFerias = codigoSituacao === '15' ? true : false;

    if (codigoSituacao === '15' && descricao != '') {
      this.formFerias.get('codigoSituacao').disable();
      this.formFerias.get('descricao').disable();
    } else if (codigoSituacao === '1' || codigoSituacao === '13') {
      this.formFerias.get('codigoSituacao').enable();
      this.formFerias.get('descricao').enable();
      this.formFerias.get('codigoSituacao').setValue('1');
      this.formFerias.get('codigoSituacao').updateValueAndValidity();
    } else {
      this.formFerias.get('codigoSituacao').enable();
      this.formFerias.get('codigoSituacao').setValue('14');
      this.formFerias.get('codigoSituacao').updateValueAndValidity();
      this.formFerias.get('descricao').enable();
      this.btnSolicitar = false;
    }

   
  }

  buscarClassificacao(): void {
    this.spinner.show();

    this.feriasService.buscarClassificacao().subscribe(
      (data: InformacaoGenerica[]) => {
        this.listaClassificacao = data;
        this.spinner.hide();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  validaDiasParcelas() {
    if (
      this.formFerias.get('diasFerias').invalid ||
      this.formFerias.get('fimFerias').invalid ||
      this.formFerias.get('diasFerias').invalid
    ) {
      // se estiver invalido
      Swal('Dados Invalidos.', 'Data ou Dias invalidos.', 'warning');
    } else {
      var parcela = this.MontarListaParcela();

      this.somaDiasFerias =
        parseInt(this.somaDiasFerias.toString()) + parseInt(parcela.Dias.toString());
      this.parcelas.push({...parcela});

      if (this.parcelas.length > 3) {
        this.parcelas.pop();
        Swal('Data Inválida.', 'A quantidade de parcelas não pode ser maior que 3', 'warning');
      } else if (this.parcelas.length === 3 && !this.parcelas.find((dia) => dia.Dias >= 14)) {
        this.parcelas.pop();
        Swal('Dias Inválidos.', 'Uma das parcelas deve conter no mínimo 14 dias.', 'warning');
      } else if (this.somarDiasParcelados() > this.somarDias()) {
        this.parcelas.pop();
        Swal(
          'Dias Inválidos.',
          'Soma dos dias excede o limite de ' + this.somarDias() + ' dias.',
          'warning'
        );
      } else if (
        this.parcelas.length == 2 &&
        Number(this.parcelas[0].Dias) + Number(this.parcelas[1].Dias) > 16 &&
        this.somarDias() === 30 &&
        !this.parcelas.find((dia) => dia.Dias >= 14)
      ) {
        this.parcelas.pop();
        Swal(
          'Dias Inválidos.',
          'A soma das parcelas deve dar a possibilidade de inserir uma parcela com 14 dias.',
          'warning'
        );
      } else {
        if (this.somarDiasParcelados() !== this.somarDias()) {
          this.limparCamposDataFerias();
          Swal('Sucesso', 'A ' + this.parcelas.length + 'ª parcela' + ' foi inserida', 'success');
        } else if (this.somarDiasParcelados() === this.somarDias()) {
          // se a soma dos dias já for = 30
          Swal(
            'Sucesso',
            'A ' +
              this.parcelas.length +
              'ª parcela' +
              ' dos ' +
              this.somarDias() +
              ' dias foi inserida',
            'success'
          );
        }
      }
    }
  }

  somarDiasParcelados(): number {
    var total = this.parcelas.reduce(
      (accumulator, currentValue) => Number(accumulator) + Number(currentValue.Dias),
      0
    );
    return total;
  }

  verificarDiarioOficialObrigatorio(): void {
    const {numeroDiarioOficial, dataDiarioOficial} = this.formFerias.value;
    if (numeroDiarioOficial == '' && dataDiarioOficial === '') this.setarDiarioOficialOpcional();
    else this.setarDiarioOficialObrigatorio();
  }

  setarDiarioOficialObrigatorio(): void {
    this.formFerias.get('numeroDiarioOficial').setValidators(Validators.required);
    this.formFerias.get('dataDiarioOficial').setValidators([Validators.required, dataValidator]);
    this.formFerias.get('numeroDiarioOficial').updateValueAndValidity();
    this.formFerias.get('dataDiarioOficial').updateValueAndValidity();
  }

  setarDiarioOficialOpcional(): void {
    this.formFerias.get('numeroDiarioOficial').setValidators(null);
    this.formFerias.get('dataDiarioOficial').setValidators(dataValidator);
    this.formFerias.get('numeroDiarioOficial').updateValueAndValidity();
    this.formFerias.get('dataDiarioOficial').updateValueAndValidity();
  }

  carregarColaborador(colaborador: RetornoFerias): void {
    this.temErro = false;
    this.temRejeitada = false;
    this.formFerias.markAsPristine();
    this.parcelasAnterior = [...[]];
  
    this.parcelasAnterior = [...colaborador.Parcelas];
  

    this.colaboradorAtual = JSON.parse(JSON.stringify(colaborador));
   
    this.formFerias.setValue({
      
      parcela: colaborador.Parcelas.length > 1 ? '2' : '1',
      classificacao: colaborador.Opcional.IdClassificacaoAto,
      numeroAto: colaborador.Opcional.NumeroAto,
      ano: colaborador.Opcional.AnoAto,
      dataAto: colaborador.Opcional.DataAto.ToDate,
      numeroDiarioOficial: colaborador.Opcional.NumeroDoe,
      dataDiarioOficial: colaborador.Opcional.DataDoe.ToDate,
      venderDias: colaborador.Vender10Dias,
      adiantar13: colaborador.Adiantar13,
      diasFerias: colaborador.Parcelas[colaborador.Parcelas.length - 1].Dias,
      inicioFerias: colaborador.Parcelas[colaborador.Parcelas.length - 1].Inicio.ToDate,
      fimFerias: colaborador.Parcelas[colaborador.Parcelas.length - 1].Fim.ToDate,
      descricao: colaborador.Parcelas[colaborador.Parcelas.length - 1].MotivoRejeicao,
    });
   
 if (colaborador.TipoFerias === 14) { 
        this.formFerias.get('venderDias').enable();
        this.formFerias.get('adiantar13').enable();
        this.formFerias.get('parcela').enable();
        this.formFerias.get('classificacao').enable();
    } else {
       
        this.formFerias.get('venderDias').disable();
        this.formFerias.get('adiantar13').disable();
        this.formFerias.get('parcela').disable();
        this.formFerias.get('classificacao').disable();
    }


  }

  submitFerias(): void {
    if (this.formFerias.invalid) {
      // se estiver invalido
      this.temErro = true;
      Swal('Dados Invalidos.', 'Preencha todos os campos.', 'warning');
    } else {
      this.OrganizarDados();
      this.spinner.show();
      this.feriasService.salvarFerias(this.Ferias).subscribe(
        async (data) => {
          if (data) {
            
            document.getElementById('closeModalbt').click();

            await Swal('Sucesso', 'Férias Atualizada com Sucesso', 'success');
            this.limparCamposAoVender();
            this.buscarFerias();
            this.temErro = false;
            this.spinner.hide();
          }
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        }
      );
    }
  }

  OrganizarDados(): void {
    if (this.temRejeitada) {
      for (var i = 0; i < this.colaboradorAtual.Parcelas.length; i++) {
        if (this.colaboradorAtual.Parcelas[i].Status.Codigo === '15') {
          this.colaboradorAtual.Parcelas[i].MotivoRejeicao = this.formFerias.get('descricao').value;
        }
      }
    }

    this.Ferias = this.montarFerias();
    
  }

  // ao alterar a situação de alguma parcela, ela vai ser chamada
  setarMotivoRejeicaoObrigatorio(id, parcela: any, index: number): void {
    let descricao: string = '';
    if (id.target.value.trim() == '1') {
      descricao = 'APROVADA';
    } else if (id.target.value.trim() == '15') {
      descricao = 'REJEITADA';
    } else if (id.target.value.trim() == '14') {
      descricao = 'PENDENTE';
    }

    this.colaboradorAtual.Parcelas[index].Status.Codigo = id.target.value.trim();
    this.colaboradorAtual.Parcelas[index].Status.Descricao = descricao;
    this.colaboradorAtual.Parcelas[index].MotivoRejeicao = '';

    if (
      this.colaboradorAtual.Parcelas.filter((p) => p.Status.Codigo == '15').length > 0
        ? true
        : false
    ) {
      this.enableMotivo = true;
      this.temRejeitada = true;
      this.formFerias.get('descricao').setValidators(Validators.required);
      this.formFerias.get('descricao').updateValueAndValidity();
    } else {
      this.enableMotivo = false;
      this.temRejeitada = false;
      this.formFerias.get('descricao').setValue('');
      this.formFerias.get('descricao').clearValidators();
      this.formFerias.get('descricao').updateValueAndValidity();
    }

    if (this.colaboradorAtual.Parcelas[0].Status.Codigo === '15') {
      for (var i = 0; i < this.colaboradorAtual.Parcelas.length; i++) {
        this.colaboradorAtual.Parcelas[i].Status.Codigo = '15';
        this.colaboradorAtual.Parcelas[i].Status.Descricao = 'REJEITADA';
      }
    }
  }

  limparCamposAoVender() {
    this.limparCamposDataFerias();
    this.parcelas = [];
    
  }

  MontarPeriodo(): Periodo {
    const {diasFerias, inicioFerias, fimFerias, codigoSituacao} = this.formFerias.getRawValue();

    return {
      Dias: diasFerias,
      Inicio: new Datas(inicioFerias),
      Fim: new Datas(fimFerias),

      DiasRestantes: 30,
      CodigoFeriasRejeitadas: this.colaboradorAtual.Parcelas[0].CodigoFeriasRejeitadas,
      MotivoRejeicao: this.colaboradorAtual.Parcelas[0].MotivoRejeicao,
      Competencia: this.colaboradorAtual.Parcelas[0].Inicio.SetData.substr(0, 4),
      CompetenciaAtualizacao: this.colaboradorAtual.Parcelas[0].CompetenciaAtualizacao,
      Status: {
        Codigo: codigoSituacao,
        Descricao: this.colaboradorAtual.Parcelas[0].Status.Descricao,
        Tipo: '',
      },
      Editavel: true,
    };
  }

  limparCamposDataFerias() {
    this.formFerias.get('diasFerias').setValue('');
    this.formFerias.get('fimFerias').setValue('');
    this.formFerias.get('inicioFerias').setValue('');

    this.formFerias.markAsPristine();
  }

  limparCampos(): void {
    this.formFerias.setValue({
      parcela: '',
      classificacao: '',
      numeroAto: '',
      ano: '',
      dataAto: '',
      numeroDiarioOficial: '',
      dataDiarioOficial: '',
      venderDias: '',
      adiantar13: '',
      diasFerias: '',
      inicioFerias: '',
      fimFerias: '',
      descricao: '',
    });

    
    this.formFerias.get('descricao').setValue('');
    this.formFerias.get('descricao').clearValidators();
    this.formFerias.get('descricao').updateValueAndValidity();

    this.enableMotivo = false;
  }

  onKeyUp(target: any) {
    if (target instanceof EventTarget) {
      var elemento = target as HTMLInputElement;
      return elemento;
    }
  }

  calculaData(evento) {
    const dataInicioFerias = this.formFerias.get('inicioFerias').value;
    // -----------
    if (dataInicioFerias.length === 8 && this.formFerias.get('diasFerias').value != null) {
      if (this.verificaDataPassado()) {
        this.formFerias.controls['inicioFerias'].setValue('');
        Swal('Data Inválida.', 'Data não pode ser menor que a data atual.', 'warning');
      } else {
        this.gerarDataFinal();
      }
    } else {
      this.formFerias.controls['fimFerias'].setValue('');
    }
  }
  verificaDataPassado(): boolean {
    //verifica se a data inserida é anterior que a data atual
    let dataInicioFerias = this.formFerias.get('inicioFerias').value;
    let dateAtual: Date = new Date();

    var dataOrganizada = new Date(
      `${dataInicioFerias.substring(0, 2)}-${dataInicioFerias.substring(
        2,
        4
      )}-${dataInicioFerias.substring(4, 8)}'T00:00:00'`
    );

    if (dateAtual < dataOrganizada) return true;
    else return false;
  }
  gerarDataFinal() {
    const dataInicioFerias = this.formFerias.get('inicioFerias').value;

    const dataFormatada = new Date(
      dataInicioFerias.substring(4, 8),
      dataInicioFerias.substring(2, 4) - 1,
      dataInicioFerias.substring(0, 2)
    );

    dataFormatada.setDate(
      dataFormatada.getDate() + Number(this.formFerias.get('diasFerias').value)
    );

    let mes = (dataFormatada.getMonth() + 1).toString().padStart(2, '0');
    let dia = dataFormatada.getDate().toString().padStart(2, '0');
    var diaSubtraido = Number(dia) - 1;
    diaSubtraido == 0 ? (dia = '01') : (dia = diaSubtraido.toString().padStart(2, '0'));
    let ano = dataFormatada.getFullYear();

    // verificar como diminuir um dia da data passada.
    if (this.formFerias.get('diasFerias').invalid)
      this.formFerias.controls['fimFerias'].setValue('');
    else this.formFerias.get('fimFerias').setValue(`${dia}${mes}${ano}`);
  }

  montarFerias(): RetornoFerias {
    const {
      venderDias,
      classificacao,
      numeroAto,
      ano,
      dataAto,
      numeroDiarioOficial,
      dataDiarioOficial,
      descricao,
      parcela,
    } = this.formFerias.getRawValue();
    return {
      TelaPerfil: false,
      Adiantar13: this.colaboradorAtual.Adiantar13,
      DiasDireito: this.colaboradorAtual.DiasDireito,
      DiasRestantes: this.colaboradorAtual.DiasRestantes,
      TipoFerias: this.colaboradorAtual.TipoFerias,
      TipoProcessamento: this.colaboradorAtual.TipoProcessamento,
      Faltas: this.colaboradorAtual.Faltas,
      Vender10Dias: venderDias,
      Parcelas: ([] = this.colaboradorAtual.Parcelas),

      DadosColaborador: {
        EmpresaFilial: this.colaboradorAtual.DadosColaborador.EmpresaFilial,
        Matricula: this.colaboradorAtual.DadosColaborador.Matricula,
        NomeCargo: this.colaboradorAtual.DadosColaborador.NomeCargo,
        NomeColaborador: this.colaboradorAtual.DadosColaborador.NomeColaborador,
        NomeSetor: this.colaboradorAtual.DadosColaborador.NomeSetor,
      },
      Vencimento: {
        Fim: this.colaboradorAtual.Vencimento.Fim,
        Inicio: this.colaboradorAtual.Vencimento.Inicio,
      },
      Opcional: {
        AnoAto: dataAto.substr(4, 4),
        NumeroAto: numeroAto,
        NumeroDoe: numeroDiarioOficial,
        DataAto: dataAto ? new Datas(dataAto) : null,
        DataDoe: dataDiarioOficial ? new Datas(dataDiarioOficial) : null,
        AnoDoe: dataDiarioOficial.substr(4, 4),
        IdClassificacaoAto: classificacao,
      },
    };
  }

  MontarListaParcela(): Periodo {
    const {diasFerias, inicioFerias, fimFerias, codigoSituacao} = this.formFerias.getRawValue();

    return {
      Status: {
        Codigo: codigoSituacao,
        Descricao: '',
        Tipo: '',
      },
      Dias: diasFerias,
      Inicio: new Datas(inicioFerias),
      Fim: new Datas(fimFerias),
      DiasRestantes:
        this.parcelas.length == 0
          ? this.colaboradorAtual.DiasDireito - Number(diasFerias)
          : Number(this.somarDias()) - Number(this.somarDiasParcelados()) - Number(diasFerias),
      Editavel: true,
    };
  }

  deletarParcela(index: number) {
    this.parcelas.splice(index, 1);
  }

  somarDias(): number {
    if (this.formFerias.get('venderDias').value === 'true') {
      return this.colaboradorAtual.Parcelas[this.index].Dias - 10;
    } else {
      return this.colaboradorAtual.Parcelas[this.index].Dias;
    }
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}

export interface Parcela {
  diasFerias: number;
  inicioFerias: Data;
  fimFerias: Data;
  diasRestantes: number;
}

export class Datas {
  SetData: string;
  ToDate?: string;
  IsDate?: boolean;
  Empty?: boolean;

  constructor(data: string = null) {
    if (data != null) {
      data = data.replace(/\//g, '');
      this.ToDate = this.dataComBarra(data);
      this.SetData = this.dataSemBarra(data);
    }
  }

  dataComBarra?(data: string): string {
    const dia = data.substr(0, 2);
    const mes = data.substr(2, 2);
    const ano = data.substr(4, 4);

    return `${dia}/${mes}/${ano}`;
  }

  dataSemBarra(data: string) {
    const dia = data.substr(0, 2);
    const mes = data.substr(2, 2);
    const ano = data.substr(4, 4);

    return ano + mes + dia;
  }
}
