import { Pipe, PipeTransform } from "@angular/core"
import { Servico } from "./servicos.component"

@Pipe({name : 'filtrarServico'})
export class filtrarServico implements PipeTransform{
    transform(servico : Servico[], filtrarServico : string) {
        filtrarServico = filtrarServico.toLowerCase()
        if (filtrarServico != "") {
           servico = servico.filter(serv => serv.Codigo.toLowerCase().includes(filtrarServico) )
          
           return servico
           
        }else{
            return servico
        }
    }

}