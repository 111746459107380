import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColaboradorComponent } from './colaborador/colaborador.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ColaboradoresHomeComponent } from './colaboradores-home/colaboradores-home.component';
import { NgxEchartsModule } from 'ngx-echarts';



@NgModule({
  declarations: [ColaboradorComponent ,ColaboradoresHomeComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    NgxEchartsModule
  ],
  exports:[ColaboradorComponent]
})
export class ColaboradoresModule { }
