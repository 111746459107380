import Rg from './documentos/Rg';
import Cnh from './documentos/Cnh';
import Clt from './documentos/Clt';
import { TituloEleitor } from './documentos/TitulEleitor';
import { CertificadoMilitar } from './documentos/CertificadoMilitar';

export class Documentos {
  Cpf: string;
  Rg: Rg = new Rg();
  Cnh: Cnh = new Cnh();
  Clt: Clt = new Clt();
  TituloEleitor: TituloEleitor = new TituloEleitor();
  UfEmissaoRg?: string;
  CertificadoMilitar: CertificadoMilitar = new CertificadoMilitar();
  constructor() {}
}
