<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="card">
    <div class="card-header header-elements-inline">
        <h3 class="card-title">Cliente</h3>
        <div class="header-elements"></div>
    </div>

    <form [formGroup]="formCadastroCliente" (ngSubmit)="submitCadastroCliente()" #formCadastroClienteForm='ngForm'>
        <div class="card-body">
            <div class="row">
                <div class="col-md-6 col-lg-2 form-group">
                    <label class="font-weight-semibold">Sigla</label>
                    <div class="input-group">
                        <input (keypress)='somenteLetras($event)' autofocus class="form-control" formControlName="sigla"
                            maxlength="4" placeholder="Sigla" autofocus type="text"
                            [ngStyle]="((temErro || formCadastroCliente.controls['sigla'].dirty) && formCadastroCliente.controls['sigla'].status == 'INVALID') ? {'border-color':'red'} : null">
                        <div class="input-group-append">
                        </div>
                    </div>
                    <span class="form-text" *ngIf="false">Carregando <i class="fa fa-spinner fa-spin"
                            aria-hidden="false"></i></span>
                    <span class="form-text text-danger"
                        *ngIf="temErro && formCadastroCliente.controls['sigla'].hasError('required')">
                        Campo obrigatório
                    </span>
                    <span class="form-text text-danger"
                        *ngIf="formCadastroCliente.controls['sigla'].hasError('maxlength')">
                        Número de Caracteres inváido (máximo 5)
                    </span>
                </div>

                <div class="col-md-4 form-group">
                    <label
                        [ngStyle]=" temErro && formCadastroCliente.controls['nome'].hasError('required') || formCadastroCliente.controls['nome'].dirty && formCadastroCliente.controls['nome'].status == 'INVALID' ? {'border-color':'red'} : null"
                        class="font-weight-semibold">Razão Social/Nome</label>
                    <input (keypress)='somenteLetras($event)' class="form-control" type="text" formControlName="nome"
                        maxlength="30" placeholder="Nome/Razão Social"
                        [ngStyle]="((temErro || formCadastroCliente.controls['nome'].dirty) && formCadastroCliente.controls['nome'].status == 'INVALID') ? {'border-color':'red'} : null" />
                    <span class="form-text text-danger"
                        *ngIf="temErro && formCadastroCliente.get('nome').hasError('required')">
                        Campo obrigatório
                    </span>
                </div>

                <div class="col-md-2 form-group">
                    <label
                        [ngStyle]=" temErro && formCadastroCliente.controls['cnpj'].hasError('required') || formCadastroCliente.controls['cnpj'].dirty && formCadastroCliente.controls['cnpj'].status == 'INVALID' ? {'border-color':'red'} : null"
                        maxlength="14" class="font-weight-semibold">CNPJ</label>
                    <input (keypress)='somenteNumero($event)' [mask]="mascaraCertificado()" class="form-control"
                        type="text" formControlName="cnpj" placeholder="CNPJ"
                        [ngStyle]="((temErro || formCadastroCliente.controls['cnpj'].dirty) && formCadastroCliente.controls['cnpj'].status == 'INVALID') ? {'border-color':'red'} : null" />
                    <span class="form-text text-danger"
                        *ngIf="temErro && formCadastroCliente.get('cnpj').hasError('required')">
                        Campo obrigatório
                    </span>
                </div>

                <div class="col-md-10 col-lg-2 form-group">
                    <label class="justify-content-start">Tipo de empresa</label>
                    <select class="form-control" type="text" formControlName="empresaPublica"
                        [ngStyle]="((temErro || formCadastroCliente.controls['empresaPublica'].dirty) && formCadastroCliente.controls['empresaPublica'].status == 'INVALID') ? {'border-color':'red'} : null">
                        <option value=""> Selecione...</option>
                        <option value="true"> Pública
                        <option value="false"> Privada
                        </option>
                    </select>
                    <span class="form-text text-danger"
                        *ngIf="formCadastroCliente.get('empresaPublica').invalid && temErro">
                        Campo Obrigatório
                    </span>
                    <span class="form-text text-danger"
                        *ngIf="formCadastroCliente.value.empresaPublica.length > 0 && formCadastroCliente.get('empresaPublica').hasError('codServicoError')">
                        Código inválido
                    </span>
                </div>
                <div class="col-md-10 col-lg-2 form-group text-center">
                    <label class="font-weight-semibold">Configurações</label><br>
                    <button type="button" class="btn btn-secondary btn-circle" data-toggle="modal"
                        data-target="#configuracaoCliente">
                        <i aria-hidden="true" class="fa fa-cog"></i>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-2 form-group" *ngIf="editando">
                    <label class="font-weight-semibold">Código Único</label>
                    <div class="input-group">
                        <input [disabled]="true" (keypress)='somenteNumero($event)' autofocus class="form-control"
                            formControlName="codigoUnico" maxlength="10" placeholder="Código" autofocus type="text"
                            [ngStyle]="((temErro || formCadastroCliente.controls['codigoUnico'].dirty) && formCadastroCliente.controls['codigoUnico'].status == 'INVALID') ? {'border-color':'red'} : null">
                        <div class="input-group-append">
                        </div>
                    </div>
                    <span class="form-text" *ngIf="false">Carregando <i class="fa fa-spinner fa-spin"
                            aria-hidden="false"></i></span>
                    <span class="form-text text-danger" *ngIf="false">
                        Campo obrigatório
                    </span>
                </div>
            </div>
        </div>

        <div class="card-footer text-right ">
            <!--Se todos os campos forem preenchidos e as configurações tambem, disabled = false-->
            <button type="button" *ngIf="servicosSelecionados.length != 0 || formCadastroCliente.valid"
                (click)="limparCampos()" class="btn btn-danger mr-2">Limpar</button>
            <button [disabled]="servicosSelecionados.length == 0 || formCadastroCliente.invalid" class="btn btn-primary"
                type="submit">Salvar</button>
        </div>
    </form>
</div>

<div class="card">
    <div class="card-header">
        <h3 class="card-title"> Listagem de Clientes</h3>
    </div>

    <div class="card-body">
        <div class="d-flex justify-content-center align-items-center"></div>
        <div class="form-group row">
            <div class="input-group col-lg-12">
                <input type="text" class="form-control" name="searchText" value="" placeholder="Pesquisar Cliente"
                    (keyup)='filter = $event'>
                <span class="input-group-append">
                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                </span>
            </div>
        </div>

        <div class="table-responsive" style="max-height:45vh">
            <table class="table table-striped table-hover">
                <thead class="text-center">
                    <tr class="text-center" style="white-space:nowrap">
                        <th class="sticky-top bg-white">Excluir/Editar</th>
                        <th class="sticky-top bg-white">Código Único</th>
                        <th class="sticky-top bg-white">Nome</th>
                        <th class="sticky-top bg-white">CPNJ</th>
                    </tr>
                </thead>
                <tbody id="lista-tarefas">
                    <tr *ngFor="let cliente of listaClientes | filtrarCliente: filter; let i = index"
                        class="text-center" style="white-space:nowrap">
                        <td>
                            <div>
                                <button class="btn btn-danger mr-2" (click)="deletar(cliente,i)">
                                    <i class="fa fa-trash"></i>
                                </button>
                                <button class="btn bg-teal-400" (click)="carregarCliente(cliente)">
                                    <i class="fa fa-pencil"></i>
                                </button>
                            </div>
                        </td>
                        <td>{{cliente.CodigoUnico}}</td>
                        <td>{{cliente.Nome}}</td>
                        <td>{{formataCnpj(cliente.CNPJ)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Inicio do botão e tela de Configurações-->
        <div class="modal fade" id="configuracaoCliente" data-backdrop="static" data-keyboard="false" tabindex="-1"
            role="dialog" aria-labelledby="configuracaoClienteLabel" aria-hidden="true">
            <div class="modal-dialog " role="document">
                <div class="modal-content">

                    <div class="card" *ngIf="contadorPag == 1">
                        <div class="row">
                            <div class="card-header col-11">
                                <h5 id="configuracaoClienteLabel">Configurações Cliente</h5>
                            </div>
                            <button id="closeModal2" class="mb-4 close" data-dismiss="modal" type="button"></button>
                            <button _ngcontent-nbj-c13="" (click)="limparModal()" aria-label="Close" id="closeModal"
                                class="mb-4 close" data-dismiss="modal" type="button"><span _ngcontent-nbj-c13=""
                                    aria-hidden="true">x</span></button>
                        </div>

                        <div class="card-body wizard">
                            <div class="steps clearfix">
                                <ul role="tablist">
                                    <li role="tab" class="first" aria-disabled="false" aria-selected="true">
                                        <a id="steps-uid-0-t-0" aria-controls="steps-uid-0-p-0" class="">
                                            <span class="current-info audible">current step: </span>
                                            <span class="number">1</span>
                                            Serviços do Cliente
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <form [formGroup]="formServicoCliente">
                                <div class="form-check row col-md-12 justify-content-center">
                                    <div class="col-12 table-responsive text-center">
                                        <div class="row form-group text-center">
                                            <table class="table table-striped table-hover mb-0 text-center">
                                                <thead class="bg-teal-400">
                                                    <tr class="text-start">
                                                        <th style="text-align:left;">
                                                            Serviços do Cliente
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <a *ngIf="listaServicos.length != servicosSelecionados.length"
                                                        (click)="marcarTodosServicos()" style="color: rgb(84, 84, 238);"
                                                        class="d-flex justify-content-end mr-2">Selecionar todos</a>
                                                    <a *ngIf="listaServicos.length == servicosSelecionados.length"
                                                        (click)="desmarcarTodosServicos()"
                                                        style="color: rgb(84, 84, 238);"
                                                        class="d-flex justify-content-end mr-2">Desmarcar todos</a>
                                                    <tr>
                                                        <td style="text-align:left;">
                                                            <div class="form-check"
                                                                *ngFor="let servico of listaServicos">
                                                                <div>
                                                                    <input class="form-check-input "
                                                                        (change)="onChangeServicos(servico, $event)"
                                                                        name="{{servico.Descricao}}" type="checkbox"
                                                                        id="{{servico.Codigo}}">
                                                                    <label class="form-check-label"
                                                                        for="{{servico}}">{{servico.Descricao}}</label>
                                                                </div>
                                                            </div>
                                                            <span class="form-text text-danger"
                                                                *ngIf="campoObrigatorio">
                                                                Selecione ao menos um serviço!
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="card-footer">
                            <div class="row d-flex flex-row-reverse bd-highlight">

                                <div class="col-2 p-2 ">
                                    <button type="button" (click)="validarConfiguracoes()"
                                        class="btn btn-primary ">Salvar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>