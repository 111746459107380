import { async } from '@angular/core/testing';
import { Cadastro } from '../../service/classes/cadastro/cadastro';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export class ProfilePDF {
  localStorage: LocalStorage = this.decryptLocalStorage();
  constructor() {}

  async gerarPDF(cadastro: Cadastro) {
    cadastro = await this.preencherCampos(cadastro);

    const data: string = this.gerarDataRodape();
    const documentDefinition = {
      info: {
        title:
          'RELATORIO_DADOS_COLABORADOR-' +
          cadastro.InformacoesBasicas.NomeCompleto.replace(/\s+/g, '_'),
        author: this.localStorage.NomeUsuario,
        subject: 'Relatório Dados Colaborador',
        keywords: 'dados colaborador relatório',
      },
      content: [
        {
          columns: [
            {
              image: await this.getBase64ImageFromURL(
                window.location.href.substring(0, window.location.href.indexOf('/perfil')) +
                  '/assets/img/governo-de-alagoas.svg'
              ),
              width: 89,
              height: 89,
              alignment: 'center',
            },
            {
              width: 420,
              text:
                '\n00' +
                cadastro.Filial.Codigo.toString().substring(0, 1) +
                ' - ' +
                cadastro.InformacoesBasicas.Cargo.Filial.NomeMatriz +
                '\n' +
                cadastro.Filial.Codigo.toString().substring(1, 6) +
                ' - ' +
                cadastro.InformacoesBasicas.Cargo.Filial.NomeCompleto +
                '\n MATRÍCULA - ' +
                cadastro.Matricula +
                '\n CPF - ' +
                this.formataCPF(cadastro.Documentos.Cpf),
              style: 'titulo',
            },
          ],
        },
        //DADOS PESSOAIS
        {
          text: 'Relatório de Dados Pessoais',
          style: 'subtitulo',
          alignment: 'center',
        },
        {
          table: {
            widths: [244, 245],
            body: [
              [
                {
                  colSpan: 2,
                  text: [
                    { text: 'Nome\n', bold: true },
                    { text: cadastro.InformacoesBasicas.NomeCompleto },
                  ],
                  border: [true, true, true, true],
                },
                {
                  text: '',
                },
              ],
              [
                {
                  text: [{ text: 'Matrícula\n', bold: true }, { text: cadastro.Matricula }],
                },
                {
                  text: [
                    { text: 'Número De Ordem\n', bold: true },
                    { text: cadastro.NumeroDeOrdem },
                  ],
                },
              ],
              [
                {
                  text: [
                    { text: 'Data Nascimento\n', bold: true },
                    { text: cadastro.Pessoal.DadosBasicos.DataNascimento.ToDate },
                  ],
                },
                {
                  text: [
                    { text: 'Data Admissão\n', bold: true },
                    { text: cadastro.InformacoesBasicas.DataAdmissao.ToDate },
                  ],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: [160, 160, 160],
            body: [
              [
                {
                  rowSpan: cadastro.Pessoal.DadosBasicos.Nacionalidade.Codigo != 10 ? 1 : 2,
                  text: [
                    { text: 'Nacionalidade\n', bold: true },
                    { text: cadastro.Pessoal.DadosBasicos.Nacionalidade.Nome },
                  ],
                  border: [true, false, true, true],
                },
                {
                  rowSpan: cadastro.Pessoal.DadosBasicos.Nacionalidade.Codigo != 10 ? 1 : 2,
                  text: [
                    { text: 'Cor/Raça\n', bold: true },
                    { text: cadastro.Pessoal.DadosBasicos.Etnia.Descricao },
                  ],
                  border: [true, false, true, true],
                },
                {
                  rowSpan: cadastro.Pessoal.DadosBasicos.Nacionalidade.Codigo != 10 ? 1 : 2,
                  text: [
                    { text: 'Sexo\n', bold: true },
                    { text: cadastro.Pessoal.DadosBasicos.Sexo.Descricao },
                  ],
                  border: [true, false, true, true],
                },
              ],
              cadastro.Pessoal.DadosBasicos.Nacionalidade.Codigo != 10
                ? [
                    {
                      text: [
                        { text: 'Data Chegada\n', bold: true },
                        { text: cadastro.Pessoal.DadosBasicos.Nacionalidade.DataDeChegada.ToDate },
                      ],
                      border: [true, false, true, true],
                    },
                    {
                      text: [
                        { text: 'Casado(a) com brasileiro(a)\n', bold: true },
                        {
                          text:
                            cadastro.Pessoal.DadosBasicos.Nacionalidade.CasadoComBrasileiro == 1
                              ? 'SIM'
                              : 'NÃO',
                        },
                      ],
                      border: [true, false, true, true],
                    },
                    {
                      text: [
                        { text: 'Filho(a) com brasileiro(a)\n', bold: true },
                        {
                          text:
                            cadastro.Pessoal.DadosBasicos.Nacionalidade.FilhoComBrasileiro == 1
                              ? 'SIM'
                              : 'NÃO',
                        },
                      ],
                      border: [true, false, true, true],
                    },
                  ]
                : [{}, {}, {}],
              [
                {
                  text: [
                    { text: 'Celular\n', bold: true },
                    {
                      text:
                        cadastro.Pessoal.Contato.DDDCelular != '' &&
                        cadastro.Pessoal.Contato.DDDCelular != '0' &&
                        cadastro.Pessoal.Contato.DDDTelefone != 'NÃO INFORMADO'
                          ? '(' +
                            cadastro.Pessoal.Contato.DDDCelular +
                            ') ' +
                            this.formataTelefone(cadastro.Pessoal.Contato.Celular)
                          : 'NÃO INFORMADO',
                    },
                  ],
                },
                {
                  text: [
                    { text: 'Telefone\n', bold: true },
                    {
                      text:
                        cadastro.Pessoal.Contato.DDDTelefone != '' &&
                        cadastro.Pessoal.Contato.DDDTelefone != '00' &&
                        cadastro.Pessoal.Contato.DDDTelefone != 'NÃO INFORMADO'
                          ? '(' +
                            cadastro.Pessoal.Contato.DDDTelefone +
                            ') ' +
                            this.formataTelefone(cadastro.Pessoal.Contato.Telefone)
                          : 'NÃO INFORMADO',
                    },
                  ],
                },
                {
                  text: [
                    { text: 'E-mail\n', bold: true },
                    { text: cadastro.Pessoal.Contato.Email1 },
                  ],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: [244, 245],
            body: [
              [
                {
                  text: [
                    { text: 'Estado Civil\n', bold: true },
                    { text: cadastro.Pessoal.DadosBasicos.EstadoCivil.Descricao },
                  ],
                  border: [true, false, true, true],
                },
                {
                  text: [
                    { text: 'Grau de Instrução\n', bold: true },
                    { text: cadastro.Pessoal.DadosBasicos.GrauDeInstrucao.Descricao },
                  ],
                  border: [true, false, true, true],
                },
              ],
              [
                {
                  text: [
                    { text: 'Sindicado\n', bold: true },
                    { text: cadastro.InformacoesBasicas.Sindicato.Nome },
                  ],
                  border: [true, false, true, true],
                },
                {
                  text: [
                    { text: 'Vínculo\n', bold: true },
                    {
                      text:
                        cadastro.InformacoesBasicas.Vinculo.Codigo +
                        ' - ' +
                        cadastro.InformacoesBasicas.Vinculo.Nome,
                    },
                  ],
                  border: [true, false, true, true],
                },
              ],
              [
                {
                  text: [
                    { text: 'Cargo\n', bold: true },
                    {
                      text:
                        cadastro.InformacoesBasicas.Cargo.Codigo +
                        ' - ' +
                        cadastro.InformacoesBasicas.Cargo.Nome,
                    },
                  ],
                  border: [true, false, true, true],
                },
                {
                  text: [
                    { text: 'Setor\n', bold: true },
                    {
                      text:
                        cadastro.InformacoesBasicas.Setor.Cod +
                        ' - ' +
                        cadastro.InformacoesBasicas.Setor.Nome,
                    },
                  ],
                  border: [true, false, true, true],
                },
              ],
              [
                {
                  text: [
                    { text: 'Nome Mãe\n', bold: true },
                    { text: cadastro.Pessoal.DadosBasicos.NomeMae },
                  ],
                  border: [true, false, true, true],
                },
                {
                  text: [
                    { text: 'Nome Pai\n', bold: true },
                    { text: cadastro.Pessoal.DadosBasicos.NomePai },
                  ],
                  border: [true, false, true, true],
                },
              ],
            ],
          },
        },
        //DOCUMENTOS
        {
          text: 'Documentos',
          decoration: 'underline',
          style: 'tamanhoLetra',
          alignment: 'center',
        },
        {
          table: {
            widths: [118, 118, 118, 117],
            body: [
              [
                {
                  text: [
                    { text: 'CPF\n', bold: true },
                    { text: this.formataCPF(cadastro.Documentos.Cpf) },
                  ],
                },
                {
                  text: [{ text: 'RG\n', bold: true }, { text: cadastro.Documentos.Rg.Numero }],
                },
                {
                  text: [
                    { text: 'Órgão Emissor RG\n', bold: true },
                    { text: cadastro.Documentos.Rg.OrgaoEmissor },
                  ],
                },
                {
                  text: [
                    { text: 'UF RG\n', bold: true },
                    { text: cadastro.Documentos.Rg.UfEmissaoRg },
                  ],
                },
              ],
              [
                {
                  text: [{ text: 'CNH\n', bold: true }, { text: cadastro.Documentos.Cnh.Numero }],
                },
                {
                  text: [
                    { text: 'Categoria CNH\n', bold: true },
                    { text: cadastro.Documentos.Cnh.CategoriaCnh },
                  ],
                },
                {
                  text: [{ text: 'UF CNH\n', bold: true }, { text: cadastro.Documentos.Cnh.UF }],
                },
                {
                  text: [
                    { text: 'Data Validade CNH\n', bold: true },
                    { text: cadastro.Documentos.Cnh.DataValidadeCnh.ToDate },
                  ],
                },
              ],
              [
                {
                  text: [
                    { text: 'PIS\n', bold: true },
                    { text: this.formataPIS(cadastro.Documentos.Clt.Pis) },
                  ],
                },
                {
                  text: [
                    { text: 'Carteira de Trabalho\n', bold: true },
                    { text: cadastro.Documentos.Clt.CarteiraDeTrabalho },
                  ],
                },
                {
                  text: [
                    { text: 'Número Série CTPS\n', bold: true },
                    { text: cadastro.Documentos.Clt.NumeroSerieCtps },
                  ],
                },
                {
                  text: [
                    { text: 'UF CTPS\n', bold: true },
                    { text: cadastro.Documentos.Clt.UfCtps },
                  ],
                },
              ],
              [
                {
                  colSpan: 2,
                  text: [
                    { text: 'Título Eleitor\n', bold: true },
                    { text: this.formataTitulo(cadastro.Documentos.TituloEleitor.Numero) },
                  ],
                },
                { text: '' },
                {
                  text: [
                    { text: 'Seção Eleitoral\n', bold: true },
                    { text: cadastro.Documentos.TituloEleitor.SecaoEleitoral },
                  ],
                },
                {
                  text: [
                    { text: 'Zona Eleitoral\n', bold: true },
                    { text: cadastro.Documentos.TituloEleitor.ZonaEleitoral },
                  ],
                },
              ],
            ],
          },
        },
        //ENDEREÇO
        {
          text: 'Endereço',
          decoration: 'underline',
          style: 'tamanhoLetra',
          alignment: 'center',
        },
        {
          table: {
            widths: [240, 50, 190],
            body: [
              [
                {
                  text: [
                    { text: 'Logradouro\n', bold: true },
                    { text: cadastro.Pessoal.Endereco.Logradouro },
                  ],
                  border: [true, true, true, false],
                },
                {
                  text: [
                    { text: 'Número\n', bold: true },
                    { text: cadastro.Pessoal.Endereco.Numero },
                  ],
                  border: [true, true, true, false],
                },
                {
                  text: [
                    { text: 'Complemento\n', bold: true },
                    {
                      text: cadastro.Pessoal.Endereco.ComplementoEndereco
                        ? cadastro.Pessoal.Endereco.ComplementoEndereco
                        : 'NÃO INFORMADO',
                    },
                  ],
                  border: [true, true, true, false],
                },
              ],
            ],
          },
        },
        {
          table: {
            widths: [190, 190, 100],
            body: [
              [
                [{ text: 'Bairro', bold: true }, cadastro.Pessoal.Endereco.Bairro],
                [
                  { text: 'Cidade - UF', bold: true },
                  cadastro.Pessoal.Endereco.Cidade + ' - ' + cadastro.Pessoal.Endereco.Estado,
                ],
                [
                  { text: 'CEP', bold: true },
                  cadastro.Pessoal.Endereco.Cep.toString().substr(0, 5) +
                    '-' +
                    cadastro.Pessoal.Endereco.Cep.toString().substr(5, 3),
                ],
              ],
            ],
          },
        },

        //Fim
        {
          margin: [0, 9, 0, 0],
          text: 'Elógica Processamento de Dados - Este documento contém informações confidenciais e destina-se apenas ao uso previsto. Não deve ser considerado como prova ou substituir documentação oficial.',
          alignment: 'center',
          style: 'rodape',
        },
        {
          columns: [
            {
              margin: [0, 9, 0, 0],
              width: 215,
              text: `Usuário Emissor: ${this.localStorage.NomeUsuario}`,
              style: 'rodape',
            },
            {
              margin: [0, 9, 0, 0],
              width: 215,
              text: data,
              style: 'rodape',
            },
            {
              margin: [0, 9, 0, 0],
              width: 250,
              text: 'Página 01/01',
              style: 'rodape',
            },
          ],
        },
      ],
      styles: {
        header: {
          fontSize: 8,
          bold: false,
          alignment: 'justify',
          margin: [0, 0, 0, 0],
        },
        subtitulo: {
          margin: [0, 5, 0, 6],
          fontSize: 16,
          bold: false,
          alignment: 'justify',
        },
        titulo: {
          margin: [30, 0, 0, 0],
          fontSize: 12,
          bold: true,
        },
        tamanhoLetra: {
          margin: [0, 5, 0, 6],
          fontSize: 12,
          bold: false,
          alignment: 'justify',
        },
        rodape: {
          fontSize: 8,
        },
      },
    };

    return new Promise((resolve, reject) => {
      resolve(documentDefinition);
      reject({ status: false });
    });
  }

  gerarDataRodape(): string {
    let data = new Date();
    var dia, mes, ano, minutos, hora;
    mes = data.getMonth() + 1;
    dia = data.getDate();
    ano = data.getFullYear();
    hora = data.getHours();
    minutos = data.getMinutes();
    if (dia < 10) {
      dia = '0';
    }
    if (mes < 10) {
      mes = '0' + mes;
    }
    if (minutos < 10) {
      minutos = '0' + minutos;
    }
    if (hora < 10) {
      hora = '0' + hora;
    }
    return hora + ':' + minutos + ' - ' + dia + '/' + mes + '/' + ano;
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
  }

  preencherCampos(cadastro: Cadastro): Cadastro {
    cadastro.InformacoesBasicas.NomeCompleto =
      cadastro.InformacoesBasicas.NomeCompleto == ''
        ? 'NÃO INFORMADO'
        : cadastro.InformacoesBasicas.NomeCompleto;
    cadastro.Matricula = cadastro.Matricula == '' ? 'NÃO INFORMADO' : cadastro.Matricula;
    cadastro.Pessoal.Contato.Email1 =
      cadastro.Pessoal.Contato.Email1 == '' ? 'NÃO INFORMADO' : cadastro.Pessoal.Contato.Email1;
    cadastro.Pessoal.DadosBasicos.Nacionalidade.Nome =
      cadastro.Pessoal.DadosBasicos.Nacionalidade.Nome == ''
        ? 'NÃO INFORMADO'
        : cadastro.Pessoal.DadosBasicos.Nacionalidade.Nome;
    cadastro.Pessoal.DadosBasicos.Etnia.Descricao =
      cadastro.Pessoal.DadosBasicos.Etnia.Descricao == ''
        ? 'NÃO INFORMADO'
        : cadastro.Pessoal.DadosBasicos.Etnia.Descricao;
    cadastro.Pessoal.DadosBasicos.Sexo.Descricao =
      cadastro.Pessoal.DadosBasicos.Sexo.Descricao == ''
        ? 'NÃO INFORMADO'
        : cadastro.Pessoal.DadosBasicos.Sexo.Descricao;
    cadastro.Pessoal.Contato.Telefone =
      cadastro.Pessoal.Contato.Telefone.length < 8
        ? 'NÃO INFORMADO'
        : cadastro.Pessoal.Contato.Telefone;
    cadastro.Pessoal.Contato.DDDTelefone =
      cadastro.Pessoal.Contato.DDDTelefone.length < 2
        ? 'NÃO INFORMADO'
        : cadastro.Pessoal.Contato.DDDTelefone;
    cadastro.Pessoal.Contato.Celular =
      cadastro.Pessoal.Contato.Celular.length < 8
        ? 'NÃO INFORMADO'
        : cadastro.Pessoal.Contato.Celular;
    cadastro.Pessoal.Contato.DDDCelular =
      cadastro.Pessoal.Contato.DDDCelular.length < 2
        ? 'NÃO INFORMADO'
        : cadastro.Pessoal.Contato.DDDCelular;
    cadastro.Pessoal.DadosBasicos.EstadoCivil.Descricao =
      cadastro.Pessoal.DadosBasicos.EstadoCivil.Descricao == ''
        ? 'NÃO INFORMADO'
        : cadastro.Pessoal.DadosBasicos.EstadoCivil.Descricao;
    cadastro.InformacoesBasicas.Sindicato.Nome =
      cadastro.InformacoesBasicas.Sindicato.Nome == ''
        ? 'NÃO INFORMADO'
        : cadastro.InformacoesBasicas.Sindicato.Nome;
    cadastro.InformacoesBasicas.Vinculo.Nome =
      cadastro.InformacoesBasicas.Vinculo.Nome == ''
        ? 'NÃO INFORMADO'
        : cadastro.InformacoesBasicas.Vinculo.Nome;
    cadastro.InformacoesBasicas.Cargo.Nome =
      cadastro.InformacoesBasicas.Cargo.Nome == ''
        ? 'NÃO INFORMADO'
        : cadastro.InformacoesBasicas.Cargo.Nome;
    cadastro.InformacoesBasicas.Setor.Nome =
      cadastro.InformacoesBasicas.Setor.Nome == ''
        ? 'NÃO INFORMADO'
        : cadastro.InformacoesBasicas.Setor.Nome;
    cadastro.Pessoal.DadosBasicos.NomeMae =
      cadastro.Pessoal.DadosBasicos.NomeMae == ''
        ? 'NÃO INFORMADO'
        : cadastro.Pessoal.DadosBasicos.NomeMae;
    cadastro.Pessoal.DadosBasicos.NomePai =
      cadastro.Pessoal.DadosBasicos.NomePai == ''
        ? 'NÃO INFORMADO'
        : cadastro.Pessoal.DadosBasicos.NomePai;
    cadastro.Documentos.Clt.NumeroSerieCtps =
      cadastro.Documentos.Clt.NumeroSerieCtps == ''
        ? 'NÃO INFORMADO'
        : cadastro.Documentos.Clt.NumeroSerieCtps;
    cadastro.Documentos.Clt.UfCtps =
      cadastro.Documentos.Clt.UfCtps == '' ? 'NÃO INFORMADO' : cadastro.Documentos.Clt.UfCtps;
    cadastro.Documentos.Cpf =
      cadastro.Documentos.Cpf == '' ? 'NÃO INFORMADO' : cadastro.Documentos.Cpf;
    cadastro.Documentos.Rg.UfEmissaoRg =
      cadastro.Documentos.Rg.UfEmissaoRg == ''
        ? 'NÃO INFORMADO'
        : cadastro.Documentos.Rg.UfEmissaoRg;
    cadastro.Documentos.Rg.Numero =
      cadastro.Documentos.Rg.Numero == '' ? 'NÃO INFORMADO' : cadastro.Documentos.Rg.Numero;
    cadastro.Documentos.Rg.OrgaoEmissor =
      cadastro.Documentos.Rg.OrgaoEmissor == ''
        ? 'NÃO INFORMADO'
        : cadastro.Documentos.Rg.OrgaoEmissor;
    cadastro.Documentos.Cnh.Numero =
      cadastro.Documentos.Cnh.Numero == '' ? 'NÃO INFORMADO' : cadastro.Documentos.Cnh.Numero;
    cadastro.Documentos.Cnh.UF =
      cadastro.Documentos.Cnh.UF == '' ? 'NÃO INFORMADO' : cadastro.Documentos.Cnh.UF;
    cadastro.Documentos.Cnh.CategoriaCnh =
      cadastro.Documentos.Cnh.CategoriaCnh == ''
        ? 'NÃO INFORMADO'
        : cadastro.Documentos.Cnh.CategoriaCnh;
    cadastro.Documentos.Cnh.DataValidadeCnh.ToDate =
      cadastro.Documentos.Cnh.DataValidadeCnh.ToDate == ''
        ? 'NÃO INFORMADO'
        : cadastro.Documentos.Cnh.DataValidadeCnh.ToDate;
    cadastro.Documentos.TituloEleitor.Numero =
      cadastro.Documentos.TituloEleitor.Numero == '' ||
      cadastro.Documentos.TituloEleitor.Numero == '0'
        ? 'NÃO INFORMADO'
        : cadastro.Documentos.TituloEleitor.Numero;
    cadastro.Documentos.TituloEleitor.SecaoEleitoral =
      cadastro.Documentos.TituloEleitor.SecaoEleitoral == '' ||
      cadastro.Documentos.TituloEleitor.SecaoEleitoral == '0'
        ? 'NÃO INFORMADO'
        : cadastro.Documentos.TituloEleitor.SecaoEleitoral;
    cadastro.Documentos.Clt.Pis =
      cadastro.Documentos.Clt.Pis == '' ? 'NÃO INFORMADO' : cadastro.Documentos.Clt.Pis;
    cadastro.Documentos.Clt.CarteiraDeTrabalho =
      cadastro.Documentos.Clt.CarteiraDeTrabalho == ''
        ? 'NÃO INFORMADO'
        : cadastro.Documentos.Clt.CarteiraDeTrabalho;
    cadastro.Pessoal.Endereco.Cidade =
      cadastro.Pessoal.Endereco.Cidade == '' ? 'NÃO INFORMADO' : cadastro.Pessoal.Endereco.Cidade;
    cadastro.Pessoal.Endereco.Cep =
      cadastro.Pessoal.Endereco.Cep == '' || cadastro.Pessoal.Endereco.Cep == '00000000'
        ? 'NÃO INFORMADO'
        : cadastro.Pessoal.Endereco.Cep;
    cadastro.Pessoal.Endereco.Bairro =
      cadastro.Pessoal.Endereco.Bairro == '' ? 'NÃO INFORMADO' : cadastro.Pessoal.Endereco.Bairro;
    cadastro.Pessoal.Endereco.Logradouro =
      cadastro.Pessoal.Endereco.Logradouro == ''
        ? 'NÃO INFORMADO'
        : cadastro.Pessoal.Endereco.Logradouro;
    cadastro.Pessoal.Endereco.Numero =
      cadastro.Pessoal.Endereco.Numero == '' || cadastro.Pessoal.Endereco.Numero == '0'
        ? 'NÃO INFORMADO'
        : cadastro.Pessoal.Endereco.Numero;

    return cadastro;
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }

  formataCPF(cpf: string) {
    if (cpf != null) {
      if (cpf.length < 11) {
        const resto = 11 - cpf.length;
        cpf = '0'.repeat(resto > 0 ? resto : 0) + cpf;
      }
      cpf = cpf.replace(/[^\d]/g, '');
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      return '00000000';
    }
  }

  formataTitulo(titulo) {
    const tituloNumerico = titulo.replace(/\D/g, '');

    if (tituloNumerico.length < 12) {
      const resto = 12 - tituloNumerico.length;
      titulo = '0'.repeat(resto > 0 ? resto : 0) + titulo;
    }

    const tituloFormatado = titulo.replace(/^(\d{4})(\d{4})(\d{4})$/, '$1 $2 $3');
    return tituloFormatado;
  }

  formataPIS(pis) {
    const regex = /^(\d{3})(\d{5})(\d{2})(\d{1})$/;
    const match = pis.match(regex);

    if (!match) {
      return pis;
    }

    const formatted = match[1] + '.' + match[2] + '.' + match[3] + '-' + match[4];
    return formatted;
  }

  formataTelefone(tel: any) {
    if (tel !== undefined) {
      if (tel.length === 8) {
        tel = tel.substr(0, 4) + '-' + tel.substr(4, 4);
      } else if (tel.length === 9) {
        tel = tel.substr(0, 5) + '-' + tel.substr(5, 4);
      }
    }
    return tel;
  }
}
