<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="card">
  <div class="card-header">
    <h3 class="card-title">Permissão</h3>
  </div>
  <form [formGroup]="formPermissaoEmpfil" #permissaoempfil="ngForm">
    <div class="card-body">
      <div class="row">
        <div class="col-md-2 form-group">
          <label class="font-weight-semibold">Código de usuário</label>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="codigo" placeholder="Codigo"
              [ngStyle]="formPermissaoEmpfil.get('codigo').invalid && (formPermissaoEmpfil.get('codigo').dirty || temErro) ? {'border-color': 'red'} : null ">
            <div class="input-group-append">
              <button class="btn bg-blue border-blue" (click)="buscarNome()" type="button">
                <i class="fa fa-search"></i>&nbsp;
              </button>
            </div>
          </div>
          <span *ngIf="formPermissaoEmpfil.get('codigo').hasError('required') && temErro" class="form-text text-danger">
            Campo obrigatório </span>
        </div>

        <div class="col-md-10 form-group">
          <label class="font-weight-semibold">Nome do usuário</label>
          <input formControlName="nome" readonly type="text" class="form-control" placeholder="Nome do usuário"
            [ngStyle]="formPermissaoEmpfil.get('nome').invalid && (formPermissaoEmpfil.get('nome').dirty || temErro) ? {'border-color': 'red'} : null ">
          <span *ngIf="formPermissaoEmpfil.get('nome').hasError('required') && temErro" class="form-text text-danger">
            Campo obrigatório </span>
        </div>

        <div *ngIf="formPermissaoEmpfil.get('nome').valid" class="col-md-6 form-group">
          <label class="font-weight-semibold">Empfil</label>
          <select formControlName="empfil" class="form-control" (change)="obterSituacao()"
            [ngStyle]="formPermissaoEmpfil.get('empfil').invalid && (formPermissaoEmpfil.get('empfil').dirty || temErro) ? {'border-color': 'red'} : null ">
            <option value="">Selecione</option>
            <option *ngFor="let lista of listaEmpfil" value="{{lista.Codigo}}">{{lista.Descricao}}</option>
          </select>
          <span *ngIf="formPermissaoEmpfil.get('empfil').hasError('required') && temErro" class="form-text text-danger">
            Campo obrigatório </span>
        </div>

        <div class="col-md-6 form-group" *ngIf="formPermissaoEmpfil.get('nome').valid">
          <label class="font-weight-semibold">Tipo do usuário</label>
          <select formControlName="tipo" class="form-control"
            [ngStyle]="formPermissaoEmpfil.get('tipo').invalid && (formPermissaoEmpfil.get('tipo').dirty || temErro) ? {'border-color': 'red'} : null ">
            <option value="">Selecione</option>
            <option *ngFor="let tipo of listaTipo" value="{{tipo.CodigoTipoUsuario}}">{{tipo.TipoDoUsuario}}</option>
          </select>
          <span *ngIf="formPermissaoEmpfil.get('tipo').hasError('required') && temErro" class="form-text text-danger">
            Campo obrigatório </span>
        </div>
      </div>
    </div>
  </form>
  <div class="card-footer">
    <div class="row ">
      <div class="col-6 d-flex justify-content-start">
        <div *ngIf="(situacao.ExisteUsuario && situacao.Ativo) && situacao != null">
          <button (click)="desativar()" type="btn" class="btn btn-primary">Desativar</button>
        </div>
        <div *ngIf="(situacao.ExisteUsuario && !situacao.Ativo) && situacao != null">
          <button (click)="ativar()" type="btn" class="btn btn-primary">Ativar</button>
        </div>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <div class="" *ngIf="formPermissaoEmpfil.get('nome').valid">
          <button (click)="limparCampos()" type="btn" class="btn btn-danger">limpar</button>
        </div>
        <div class="ml-3" *ngIf="!situacao.ExisteUsuario">
          <button  (click)="salvarPermissao()" type="btn"
            class="btn btn-primary">Salvar</button>
        </div>
      </div>

    </div>
  </div>
</div>