<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" name="medico" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<app-pesquisar-matricula
  *ngIf="matricula == null"
  [titulo]="'Prontuário Médico'"
  [inputText]="true"
  (colaboradorEmitter)="matriculaListener($event)"
></app-pesquisar-matricula>

<section class="container" *ngIf="matricula != null">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title font-weight-semibold">
        <i
          class="fa fa-arrow-left pr-3 btn"
          aria-hidden="true"
          (click)="retornarTelaInicial()"
          style="cursor: pointer; bottom: 2px"
        ></i
        >Prontuário Médico
      </h3>
    </div>
    <div class="card-body">
      <form #colaborador="ngForm" class="row" [formGroup]="formColaborador">
        <div class="col-md-2 form-group">
          <label class="font-weight-semibold">Matrícula</label>

          <input
            type="text"
            class="form-control"
            placeholder="Matrícula"
            mask="0*"
            formControlName="matricula"
          />

          <span class="form-text" *ngIf="loading"
            >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
          ></span>
        </div>

        <div class="col-md-10 form-group">
          <label class="font-weight-semibold">Nome do Colaborador</label>
          <input type="text" class="form-control" placeholder="Nome" formControlName="nome" />
        </div>
      </form>
    </div>
  </div>

  <div class="card d-flex justify-content-center">
    <div class="card-header d-inline-flex justify-content-between">
      <h3 class="card-title font-weight-semibold">Lista de Prontuários</h3>
      <div>
        <button type="button" class="btn btn-outline-primary" (click)="downloadPdf()">
          <i class="bi bi-download"></i> Download
        </button>
        <button
          type="button"
          class="btn btn-outline-primary ml-3"
          (click)="aoAbrirModal()"
          data-toggle="modal"
          data-target="#cadastroProntuarioModal"
        >
          <i class="fa fa-plus"></i> Adicionar Prontuário
        </button>
      </div>
    </div>
    <div class="card-body table-responsive mt-2">
      <table
        class="table table-striped border-blue text-left mt-2"
        *ngIf="listaProntuario.length > 0"
      >
        <thead class="bg-teal-400">
          <tr>
            <th>DataInsp</th>
            <th>CRM.</th>
            <th>Código Afastamento</th>
            <th>Código Motivo</th>
            <th>Data Inicial</th>
            <th>Data Final</th>
            <th class="text-right" style="padding-right: 110px">Ações</th>
          </tr>
        </thead>

        <tbody *ngFor="let prontuario of listaProntuario; let i = index">
          <tr>
            <td>{{ prontuario.DtInsp?.ToDate }}</td>
            <td>{{ prontuario.Crm }}</td>
            <td>{{ getDescricaoCodAfa(prontuario.CodAfa.toString()) }}</td>
            <td>{{ getDescricaoCodMot(prontuario.CodMot.toString()) }}</td>
            <td>{{ prontuario.Dt1LM?.ToDate }}</td>
            <td>{{ prontuario.Dt2LM?.ToDate }}</td>

            <td class="d-flex justify-content-center">
              <button
                type="button"
                class="btn btn-outline-primary mr-2"
                (click)="aoAbrirModal(i, prontuario.Seq)"
                data-toggle="modal"
                data-target="#cadastroProntuarioModal"
              >
                <i class="fa fa-pencil"></i>
              </button>
              <button
                type="button"
                [ngClass]="{
                  'btn-outline-primary': prontuario.Obs === 'N',
                  'btn-primary': prontuario.Obs === 'S'
                }"
                class="btn mr-2"
                data-toggle="modal"
                data-target="#observacoesProntuarioModal"
                (click)="modalObservacoes.abrirModal(prontuario)"
              >
                <i class="fa-solid fa-book fa-lg"></i>
              </button>
              <button
                type="button"
                [ngClass]="{
                  'btn-outline-primary': prontuario.Documento === 'N',
                  'btn-primary': prontuario.Documento === 'S'
                }"
                class="btn mr-2"
                data-toggle="modal"
                data-target="#anexoProntuarioModal"
                (click)="modalAnexo.abrirModal(prontuario)"
              >
                <i class="fa fa-file"></i>
              </button>
              <button
                type="button"
                class="btn btn-outline-danger"
                (click)="deletarProntuario(prontuario)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        *ngIf="listaProntuario.length === 0"
        class="p-4 d-flex justify-content-center align-items-center"
      >
        <h6 class="font-weight-semibold">
          Não há dados de Prontuário Médico para esse colaborador.
        </h6>
      </div>
    </div>
  </div>
</section>

<div class="modal fade" id="cadastroProntuarioModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-prontuario-medico-cadastro
        [cadastro]="cadastroProntuario"
        [colaborador]="colaborador"
        [prontuarioAtual]="prontuarioAtual"
      ></app-prontuario-medico-cadastro>
    </div>
  </div>
</div>

<div class="modal fade" id="observacoesProntuarioModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-observacoes-ficha-funcional
        (gatilhoAtivado)="buscarProntuario()"
        [colaborador]="colaborador"
        [tipo]="tipoPagina"
      >
      </app-observacoes-ficha-funcional>
    </div>
  </div>
</div>

<div class="modal fade" id="anexoProntuarioModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-anexo
        (gatilhoAtivado)="buscarProntuario()"
        [pagina]="tipoPagina"
        [matricula]="colaborador?.Matricula"
        [enviarSeparadamente]="true"
        [modal]="true"
      ></app-anexo>
    </div>
  </div>
</div>
