<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<app-pesquisar-matricula
  *ngIf="matricula == null"
  [titulo]="'Contracheque'"
  [inputText]="true"
  (colaboradorEmitter)="matriculaListener($event)"
></app-pesquisar-matricula>

<div class="container" *ngIf="matricula != null">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">
        <i
          class="fa fa-arrow-left pr-3 btn"
          aria-hidden="true"
          (click)="retornarTelaInicial()"
          style="cursor: pointer; bottom: 2px"
        ></i
        >Contracheque
      </h3>
    </div>
    <div class="card-body">
      <form #colaborador="ngForm" class="row" [formGroup]="formcolaborador">
        <div class="col-md-3 form-group">
          <label class="font-weight-semibold">Matrícula</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Matrícula"
              mask="0*"
              formControlName="matricula"
              (keyup.enter)="
                buscarColaboradorMatricula(this.formcolaborador.get('matricula').value)
              "
            />
            <span class="input-group-append" *ngIf="tipoUsuario != tipoUsuarioEnum.portal">
              <button
                type="button"
                class="input-group-text bg-blue border-blue"
                [disabled]="loadingMatriculas"
                (click)="buscarColaboradorMatricula(this.formcolaborador.get('matricula').value)"
              >
                <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
          <span class="form-text text-danger" *ngIf="matriculaInvalida"
            >Digite uma Matrícula válida</span
          >
        </div>

        <div class="col-md-6 form-group">
          <label class="font-weight-semibold">Nome do Colaborador</label>
          <input type="text" class="form-control" placeholder="Nome" formControlName="nome" />
        </div>
        <div class="col-md-3 form-group">
          <label class="font-weight-semibold">Vínculo</label>
          <input type="text" class="form-control" placeholder="Vínculo" formControlName="vinculo" />
        </div>
        <!-- <div class="col-md-2 form-group d-flex align-items-end">
        <button class="btn bg-blue border-blue">
          <i class="fa fa-search"></i> Buscar por nome
        </button>
      </div> -->
      </form>
    </div>
  </div>

  <div
    class="card d-flex justify-content-center"
    style="min-height: 300px"
    *ngIf="listaMatricula.length > 0 || loadingMatriculas"
  >
    <div *ngIf="listaMatricula.length > 0 && !loadingMatriculas" class="card-header">
      <h3 class="card-title">Lista de Vínculos do Colaborador</h3>
    </div>
    <div *ngIf="listaMatricula.length > 0 && !loadingMatriculas" class="card-body table-responsive">
      <table class="table table-striped border-blue">
        <thead class="bg-teal-400">
          <tr>
            <th class="text-center">Matrícula</th>
            <th class="text-center">Lotação</th>
            <th class="text-center">Data admissão</th>
            <th class="text-center">Situação</th>
            <!-- <th class="text-center">Condição</th> -->
            <th class="text-center">Anterior</th>
            <th class="text-center">Prévio</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let matricula of listaMatricula; let i = index">
            <td class="text-center">{{ matricula.Matricula }}</td>
            <td>{{ matricula.EmpFil }} - {{ matricula.NomeFilial }}</td>
            <td class="text-center">{{ matricula.DataAdmissao.ToDate }}</td>
            <td class="text-center">
              {{ matricula.DescAfa == '' ? 'NÃO INFORMADA' : matricula.DescAfa }}
            </td>
            <!-- <td class="text-center">
              {{
                matricula.Ativo == 1
                  ? "NORMAL"
                  : "AFAST. EM " + matricula.DataAfastamento.ToDate
              }}
            </td> -->
            <td class="text-center">
              <button
                type="button"
                (click)="abrirModalContracheque(i, false)"
                class="btn bg-teal-400 border-teal-400"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i class="fa fa-file"></i>
              </button>
            </td>
            <td class="text-center">
              <button
                type="button"
                (click)="abrirModalContracheque(i, true)"
                class="btn bg-teal-400 border-teal-400"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <i class="fa fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="loadingMatriculas" class="d-flex justify-content-center">
      <i class="fa fa-spinner fa-spin" aria-hidden="false"></i>
    </div>
  </div>

  <!-- Modal Prévia -->
  <div
    *ngIf="matriculaSelecionada >= 0 && modalAnterior == false"
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">
            Contracheque Prévio - {{ cadastroColaborador.InformacoesBasicas.NomeCompleto }} - Mat:
            {{ cadastroColaborador.Matricula }}-{{ cadastroColaborador.DigitoMatricula }}
          </h3>
          <button
            class="btn btn-outline-primary py-0 px-1 ml-2 btn-sm collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#cabecalhoModal"
            aria-expanded="false"
            aria-controls="cabecalhoModal"
          >
            <i class="fa fa-angle-down"></i>
          </button>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ng-container *ngTemplateOutlet="cabecalhoModal" class="col-12"></ng-container>
          <!-- <div class="row"> -->
          <form class="col-12 row col-12 row justify-content-end">
            <div class="form-check ml-2">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="option1"
                checked
              />
              <label class="form-check-label" for="exampleRadios1"> Prévia </label>
            </div>
            <!-- <div class="form-check ml-2 mr-2">
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios2"
              value="option2"
              (click)="checkTipoDeBusca(2)"
            />
            <label class="form-label" for="exampleRadios2"> Verba </label>
          </div>
          <div class="form-check mr-2">
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios2"
              value="option2"
              (click)="checkTipoDeBusca(3)"
            />
            <label class="form-label" for="exampleRadios2"> Trace </label>
          </div> -->
          </form>
          <!-- </div> -->

          <div *ngIf="!telaTrace">
            <div>
              <form #contracheque="ngForm" [formGroup]="formContracheque" class="row">
                <div
                  *ngIf="this.listaTipoProcessamento.length > 0"
                  class="form-group"
                  [ngClass]="relatorioB64 && !loadingContracheque ? 'col-9' : 'col-12'"
                >
                  <label class="font-weight-semibold">Tipo de Processamento/Competência</label>
                  <select
                    class="form-control"
                    (change)="buscarPrevia()"
                    formControlName="tipoProcessamento"
                  >
                    <option value="">Selecione...</option>
                    <option
                      *ngFor="let tipoProcessamento of listaTipoProcessamento"
                      value="{{ tipoProcessamento.Codigo }}"
                    >
                      {{ tipoProcessamento.Descricao }} -
                      {{ tipoProcessamento.DtComp.substring(4, 6) }}/{{
                        tipoProcessamento.DtComp.substring(0, 4)
                      }}
                    </option>
                  </select>
                </div>

                <div
                  *ngIf="relatorioB64 && !loadingContracheque"
                  class="col-3 form-group d-flex align-items-end"
                >
                  <button
                    [disabled]="loadingDownload"
                    (click)="downloadRelatorio()"
                    class="btn bg-blue w-100 border-blue"
                  >
                    <div *ngIf="!loadingDownload"><i class="fa fa-download"></i> Download</div>
                    <i
                      *ngIf="loadingDownload"
                      class="fa fa-spinner fa-spin"
                      aria-hidden="false"
                    ></i>
                  </button>
                </div>
              </form>

              <div
                *ngIf="totalContracheque && !loadingContracheque"
                class="col-12 d-flex justify-content-end"
              >
                <span class="badge badge-primary badge-pill mb-2 p-2">
                  <h6 class="font-weight-semibold m-0">
                    Salário Líquido {{ totalContracheque.Liquido }}
                  </h6>
                </span>
              </div>

              <div
                *ngIf="loadingContracheque"
                style="min-height: 200px"
                class="col-12 d-flex justify-content-center align-items-center"
              >
                <i class="fa fa-spinner fa-spin" aria-hidden="false"></i>
              </div>

              <div
                *ngIf="listaVerba && listaVerba.length > 0 && !loadingContracheque"
                class="col-12 table-responsive"
              >
                <table class="table table-striped">
                  <thead class="bg-teal-400">
                    <tr class="text-center">
                      <th>Código</th>
                      <th class="text-left">Descrição</th>
                      <th>Nº de Horas/QTD</th>
                      <th>Ocor.</th>
                      <th>Pag.</th>
                      <th>Vantagens</th>
                      <th>Descontos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let verba of listaVerba" class="text-center">
                      <td>
                        {{ verba.CodigoVerba }}
                        {{ verba.TransacaoVerba.padStart(2, '0') }}
                      </td>
                      <td class="text-left">{{ verba.NomeVerba }}</td>
                      <td>{{ verba.ValorHora }}</td>
                      <td>{{ verba.Ocorrencia }}</td>
                      <td>{{ verba.Pagos }}</td>
                      <td>
                        {{ verba.VerbaC01 == '1' ? verba.ValorFicha : '' }}
                      </td>
                      <td>
                        {{ verba.VerbaC01 == '2' ? verba.ValorFicha : '' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-striped">
                  <thead class="bg-teal-400">
                    <tr class="text-center">
                      <th>Salário Base</th>
                      <th>FGTS</th>
                      <th>Margem Consignável</th>
                      <th>Salário Bruto</th>
                      <th>Descontos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-center">
                      <td>
                        {{
                          !totalContracheque.SalarioBase ? 'R$ 0,00' : totalContracheque.SalarioBase
                        }}
                      </td>
                      <td>
                        {{ !totalContracheque.Fgts ? 'R$ 0,00' : totalContracheque.Fgts }}
                      </td>
                      <td>
                        {{
                          !totalContracheque.MargemConsignada
                            ? 'R$ 0,00'
                            : totalContracheque.MargemConsignada
                        }}
                      </td>
                      <td>
                        {{ !totalContracheque.Bruto ? 'R$ 0,00' : totalContracheque.Bruto }}
                      </td>
                      <td>
                        {{
                          !totalContracheque.DescontoTotal
                            ? 'R$ 0,00'
                            : totalContracheque.DescontoTotal
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-striped col-md-4">
                  <thead class="bg-teal-400">
                    <tr class="text-center">
                      <th>Margem Cartão</th>
                      <th>Margem Sindicato</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-center">
                      <td>{{ totalContracheque.MargemConsignadaCartao }}</td>
                      <td>{{ totalContracheque.MargemSindicato }}</td>
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="row justify-content-end">
                  <button type="button" class="btn btn-primary" disabled="true">
                    Imprimir contracheque
                  </button>
                </div> -->
              </div>
            </div>
          </div>
          <div *ngIf="telaTrace">
            <div class="row">
              <form #contracheque="ngForm" [formGroup]="formContracheque" class="col-12 row">
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Período</label>
                  <input
                    type="text"
                    class="form-control"
                    (change)="onPeriodoChange()"
                    (input)="formatarDataCompInput($event)"
                    formControlName="periodo"
                    placeholder="00/0000"
                    mask="00/0000"
                    maxlength="7"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button">Button</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Anterior-->
  <div
    *ngIf="matriculaSelecionada >= 0 && modalAnterior == true"
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">
            Contracheque Anterior - {{ cadastroColaborador.InformacoesBasicas.NomeCompleto }} - Mat:
            {{ cadastroColaborador.Matricula }}-{{ cadastroColaborador.DigitoMatricula }}
          </h3>
          <button
            class="btn btn-outline-primary py-0 px-1 ml-2 btn-sm collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#cabecalhoModal"
            aria-expanded="false"
            aria-controls="cabecalhoModal"
          >
            <i class="fa fa-angle-down"></i>
          </button>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <ng-container *ngTemplateOutlet="cabecalhoModal" class="col-12"></ng-container>
          <div class="row">
            <form #contracheque="ngForm" [formGroup]="formContracheque" class="col-12 row">
              <div class="col-md-3 form-group">
                <label class="font-weight-semibold">Período</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    (input)="formatarDataCompInput($event)"
                    formControlName="periodo"
                    placeholder="00/0000"
                    mask="00/0000"
                    maxlength="7"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="button" (click)="onPeriodoChange()">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="loadingTipoProcessamento">
                  <i class="fa fa-spinner fa-spin" aria-hidden="false"></i>
                  carregando
                </div>
              </div>

              <div *ngIf="this.listaTipoProcessamento.length > 0" class="col-md-4 form-group">
                <label class="font-weight-semibold">Tipo de Processamento</label>
                <select
                  class="form-control"
                  (change)="buscarContracheque()"
                  formControlName="tipoProcessamento"
                >
                  <option *ngIf="this.listaTipoProcessamento.length > 1" value="">
                    Selecione...
                  </option>
                  <option
                    *ngFor="let tipoProcessamento of listaTipoProcessamento"
                    value="{{ tipoProcessamento.Codigo }}"
                  >
                    {{ tipoProcessamento.Descricao }}
                  </option>
                </select>
              </div>

              <div
                *ngIf="relatorioB64 && !loadingContracheque"
                class="col-md-2 form-group d-flex align-items-end"
              >
                <button (click)="downloadRelatorio()" class="btn bg-blue border-blue">
                  <div *ngIf="!loadingDownload"><i class="fa fa-download"></i> Download</div>
                  <i *ngIf="loadingDownload" class="fa fa-spinner fa-spin" aria-hidden="false"></i>
                </button>
              </div>
            </form>

            <div
              *ngIf="totalContracheque && !loadingContracheque"
              class="col-12 d-flex justify-content-end"
            >
              <span class="badge badge-primary badge-pill mb-2 p-2">
                <h6 class="font-weight-semibold m-0">
                  Salário Líquido {{ totalContracheque.Liquido }}
                </h6>
              </span>
            </div>

            <div
              *ngIf="loadingContracheque"
              style="min-height: 200px"
              class="col-12 d-flex justify-content-center align-items-center"
            >
              <i class="fa fa-spinner fa-spin" aria-hidden="false"></i>
            </div>

            <div
              *ngIf="listaVerba.length > 0 && !loadingContracheque"
              class="col-12 table-responsive"
            >
              <table class="table table-striped">
                <thead class="bg-teal-400">
                  <tr class="text-center">
                    <th>Código</th>
                    <th class="text-left">Descrição</th>
                    <th>Nº de Horas/QTD</th>
                    <th>Ocor.</th>
                    <th>Pag.</th>
                    <th>Vantagens</th>
                    <th>Descontos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let verba of listaVerba" class="text-center">
                    <td>
                      {{ verba.CodigoVerba }}
                      {{ verba.TransacaoVerba.padStart(2, '0') }}
                    </td>
                    <td class="text-left">{{ verba.NomeVerba }}</td>
                    <td>{{ verba.TempoVerba }}</td>
                    <td>{{ verba.Ocorrencia }}</td>
                    <td>{{ verba.Pagos }}</td>
                    <td>{{ verba.VerbaC01 == '1' ? verba.ValorFicha : '' }}</td>
                    <td>{{ verba.VerbaC01 == '2' ? verba.ValorFicha : '' }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped">
                <thead class="bg-teal-400">
                  <tr class="text-center">
                    <th>Salário Base</th>
                    <th>FGTS</th>
                    <th>Margem Consignável</th>
                    <th>Salário Bruto</th>
                    <th>Descontos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-center">
                    <td>
                      {{
                        !totalContracheque.SalarioBase ? 'R$ 0,00' : totalContracheque.SalarioBase
                      }}
                    </td>
                    <td>
                      {{ !totalContracheque.Fgts ? 'R$ 0,00' : totalContracheque.Fgts }}
                    </td>
                    <td>
                      {{
                        !totalContracheque.MargemConsignada
                          ? 'R$ 0,00'
                          : totalContracheque.MargemConsignada
                      }}
                    </td>
                    <td>
                      {{ !totalContracheque.Bruto ? 'R$ 0,00' : totalContracheque.Bruto }}
                    </td>
                    <td>
                      {{
                        !totalContracheque.DescontoTotal
                          ? 'R$ 0,00'
                          : totalContracheque.DescontoTotal
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-striped col-md-4">
                <thead class="bg-teal-400">
                  <tr class="text-center">
                    <th>Margem Cartão</th>
                    <th>Margem Sindicato</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-center">
                    <td>{{ totalContracheque.MargemConsignadaCartao }}</td>
                    <td>{{ totalContracheque.MargemSindicato }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #cabecalhoModal>
  <div class="table-responsive shadow-sm mb-3 collapse" id="cabecalhoModal">
    <table class="table table-striped">
      <tbody>
        <!-- <tr>
          <th class="font-weight-semibold" colspan="3">Nome</th>
          <td>
            {{ cadastroColaborador.InformacoesBasicas.NomeCompleto }}
          </td>
        </tr> -->
        <tr>
          <th class="font-weight-semibold">Local de Trabalho</th>
          <td>{{ cadastroColaborador.InformacoesBasicas.Departamento.Nome }}</td>
          <th class="font-weight-semibold">Data Nascimento</th>
          <td>
            {{ cadastroColaborador.Pessoal.DadosBasicos.DataNascimento.ToDate }}
          </td>
          <th class="font-weight-semibold">Data Admissão</th>
          <td>
            {{ cadastroColaborador.InformacoesBasicas.DataAdmissao.ToDate }}
          </td>
        </tr>
        <!-- <tr>
          <th class="font-weight-semibold">Local de Trabalho</th>
          <td>{{ cadastroColaborador.InformacoesBasicas.Departamento.Nome }}</td>
          <th class="font-weight-semibold">Município</th>
          <td>{{ cadastroColaborador.Pessoal.Endereco.Cidade }}</td>
          <th class="font-weight-semibold">Data Admissão</th>
          <td>{{ cadastroColaborador.InformacoesBasicas.DataAdmissao.ToDate }}</td>
        </tr>-->
        <tr>
          <th class="font-weight-semibold">Cargo Efetivo</th>
          <td>
            {{
              cadastroColaborador.CargoEfetivo != ''
                ? cadastroColaborador.CargoEfetivo +
                  ' - ' +
                  cadastroColaborador.DescricaoCargoEfetivo
                : 'NÃO INFORMADO'
            }}
          </td>
          <th class="font-weight-semibold">Nível</th>
          <td>
            {{ cadastroColaborador.Nivel != '' ? cadastroColaborador.Nivel : 'NÃO INFORMADO' }}
          </td>
          <th class="font-weight-semibold">Vínculo</th>
          <td>
            {{ cadastroColaborador.InformacoesBasicas.Vinculo.Codigo }} -
            {{ cadastroColaborador.InformacoesBasicas.Vinculo.Nome }}
          </td>
        </tr>
        <tr>
          <th class="font-weight-semibold">Cargo Comissionado</th>
          <td>
            {{ cadastroColaborador.InformacoesBasicas.Cargo.Codigo }} -
            {{ cadastroColaborador.InformacoesBasicas.Cargo.Nome }}
          </td>
          <th class="font-weight-semibold">Referência</th>
          <td>{{ cadastroColaborador.InformacoesBasicas.Salario.CodigoNivel }}</td>
          <th class="font-weight-semibold">Situação</th>
          <td>
            {{ cadastroColaborador.Stiuacao.Codigo }} - {{ cadastroColaborador.Stiuacao.Descricao }}
          </td>
        </tr>
        <!-- <tr>
          <th class="font-weight-semibold">Identidade</th>
          <td>
            {{ cadastroColaborador.Documentos.Rg.Numero }} -
            {{ cadastroColaborador.Documentos.Rg.OrgaoEmissor }} -
            {{ cadastroColaborador.Documentos.Rg.UfEmissaoRg }}
          </td>
          <th class="font-weight-semibold">CPF</th>
          <td>{{ cadastroColaborador.Documentos.Cpf }}</td>
          <th class="font-weight-semibold">Órgão Pagador</th>
          <td>
            {{ cadastroColaborador.DadosBancarios.Banco }} -
            {{ cadastroColaborador.DadosBancarios.Agencia }} -
            {{ cadastroColaborador.DadosBancarios.Conta }}
          </td>
        </tr> -->
        <tr>
          <th class="font-weight-semibold">Dep. S.F</th>
          <td>{{ cadastroColaborador.DepSalarioFamilia == '1' ? 'SIM' : 'NÃO' }}</td>
          <th class="font-weight-semibold">Dep. I.R</th>
          <td>{{ cadastroColaborador.DepImpostoRenda == '1' ? 'SIM' : 'NÃO' }}</td>
          <th class="font-weight-semibold">Carga Horária</th>
          <td>
            {{ cadastroColaborador.InformacoesBasicas.HorarioTrabalho.CargaHorariaSemana }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- <form class="row" [formGroup]="formModal">
    <div class="col-6 form-group">
      <label class="font-weight-semibold">Nome</label>
      <input
        type="text"
        class="form-control"
        readonly
        placeholder="Nome"
        formControlName="nomeServidor"
      />
    </div>
    <div class="col-3 form-group">
      <label class="font-weight-semibold">Matrícula-Dig</label>
      <input
        type="text"
        class="form-control"
        readonly
        placeholder="Matrícula"
        formControlName="matDig"
      />
    </div>
    <div class="col-3 form-group">
      <label class="font-weight-semibold">Nº de Ordem</label>
      <input
        type="text"
        class="form-control"
        readonly
        placeholder="Nº de Ordem"
        formControlName="numOrd"
      />
    </div>

    <div class="col-6 form-group">
      <label class="font-weight-semibold">Local de Trabalho</label>
      <input
        type="text"
        class="form-control"
        readonly
        placeholder="Local de Trabalho"
        formControlName="localTrabalho"
      />
    </div>
    <div class="col-3 form-group">
      <label class="font-weight-semibold">Município</label>
      <input
        type="text"
        class="form-control"
        readonly
        placeholder="Município"
        formControlName="municipio"
      />
    </div>
    <div class="col-3 form-group">
      <label class="font-weight-semibold">Data Admissão</label>
      <input
        type="text"
        class="form-control"
        readonly
        placeholder="Data Admissão"
        formControlName="dataAdmissao"
      />
    </div>
  </form> -->
</ng-template>
