import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AcessoService } from '../../service/acesso/acesso.service';
import { Colaborador } from '../colaboradores/classes/Colaborador';
import { Data } from 'src/app/service/interfaces/Data';
import { RetornoFerias } from 'src/app/service/interfaces/RetornoFerias';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root'
})
export class FeriasService {
  localStorage: LocalStorage = new LocalStorage();

  url_acesso = this.acesso.validarUrl();
  headers = new HttpHeaders();
  filial: string;
  cabecalho: any;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString(); 
    }
   }

  buscarFerias(periodo: string){
    
    return this.http.get(this.url_acesso + `ferias/situacao/${this.filial}/${periodo}`, {headers:this.cabecalho});
  }

  salvarFerias(dadoFerias: RetornoFerias){
    return this.http.put(this.url_acesso + `ferias/000000000/EF/${this.filial}/`, dadoFerias, {headers:this.cabecalho});
  }

  buscarParcelas(empresaFilial:number, matricula:Number, dataAquisicaoInicial:Data, dataAquisicaoFinal:Data, situacao:string ){
    return this.http.get(this.url_acesso + `ferias/${empresaFilial}/${matricula}/${dataAquisicaoInicial.SetData}/${dataAquisicaoFinal.SetData}/${situacao}`, {headers:this.cabecalho})
  }

  buscarClassificacao(){
    return this.http.get(this.url_acesso + `classificacao-ato`, {headers:this.cabecalho})
  }
  

  


}
