<div class="row justify-content-center">
    <div  class="col-xs-12 col-sm-12 col-md-12 col-lg-12 row">
        <div class="col-sm-6 col-xl-3" >
            <div  class="card text-center shadow-sm">
                <div class="card-body">
                    <div class="chart-container">
                        <div style="height: 40vh; max-height: 300px;" echarts [options]="txEficiencia15" class="d-flex justify-content-center"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-xl-3">
            <div class="card text-center shadow-sm">
                <div class="card-body">
                    <div class="chart-container">
                        <div style="height: 40vh; max-height: 300px;" echarts [options]="txEficiencia30" class="d-flex justify-content-center"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-xl-3">
            <div class="card text-center shadow-sm">
                <div class="card-body">
                    <div class="chart-container">
                        <div style="height: 40vh; max-height: 300px;" echarts [options]="qtdAtraso" class="d-flex justify-content-center"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-xl-3">
            <div class="card text-center shadow-sm">
                <div class="card-body">
                    <div class="chart-container">
                        <div style="height: 40vh; max-height: 300px;" echarts [options]="qtdAguardando" class="d-flex justify-content-center"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div>
            <div class="card">
                <div class="card-hearder p-2 bg-light header-elements-inline">
                    <h6 class="card-title font-weight-bold m-0">
                        Solicitações Em Andamento x Aguardando Andamento x Concluído(mês)
                    </h6>
                    <div class="header-elements">
                        <div class="list-icons">
                            <a class="list-icons-item" data-action="collapse"></a>
                            <a class="list-icons-item" data-action="reload"></a>
                            <a style="cursor:pointer;" class="list-icons-item fa fa-download" (click)="download($event)" ></a>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="chart-container ">
                        <div echarts [options]="solicitacao" class="d-flex justify-content-center"></div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div>
            <div id="accordion">
                <div class="card p-0 mb-0">
                    <div class="card-header p-1 bg-light" id="headingOne">
                        <a  data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapseOne">
                            <div class="row">
                                <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                                    <h6 class="card-title font-weight-bold text-uppercase">
                                        Solicitações que vencem nos próximos 02 dias
                                    </h6>
                                </div>
                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 text-right ">
                                    <span class="badge  badge-warning badge-pill text-right">2</span>
                                </div>
                            </div>
                        </a>
                    </div>
                
                    <div id="collapse1" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body p-0">
                            <div class="border-top">
                                <div class="pl-1 pr-1">
                                    <p class="font-weight-semibold">7800 - Projeto Elógica - Início 01/10/2019 - Fim 01/12/2019</p>
                                </div>
                            </div>
                            <div class="border-top">
                                <div class="pl-1 pr-1">
                                    <p class="font-weight-semibold">7896 - Projeto Novo RH - Início 05/06/2019 - Fim 01/12/2019</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card p-0 mb-0">
                    <div class="card-header p-1 bg-light" id="headingOne">
                        <a  data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapseOne">
                            <div class="row">
                                <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                                    <h6 class="card-title font-weight-bold text-uppercase">
                                        Solicitações não iniciadas há mais de 7 dias
                                    </h6>
                                </div>
                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 text-right ">
                                    <span class="badge  badge-danger badge-pill text-right">3</span>
                                </div>
                            </div>
                        </a>
                    </div>
                
                    <div id="collapse2" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body p-0">
                            <div class="border-top">
                                <div class="pl-1 pr-1">
                                    <p class="font-weight-semibold">7955 - Projeto Inovação - Início 02/10/2019 - Fim 01/12/2020</p>
                                </div>
                            </div>
                            <div class="border-top">
                                <div class="pl-1 pr-1">
                                    <p class="font-weight-semibold">7802 - Projeto Migração Código - Inicio 01/10/2019 - Fim 01/12/2020</p>
                                </div>
                            </div>
                            <div class="border-top">
                                <div class="pl-1 pr-1">
                                    <p class="font-weight-semibold">7869 - Projeto Arquitetura - Inicio 05/02/2020 - Fim 01/12/2020</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card p-0 mb-0">
                    <div class="card-header p-1 bg-light" id="headingOne">
                        <a  data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapseOne">
                            <div class="row">
                                <div class="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                                    <h6 class="card-title font-weight-bold text-uppercase">
                                        Solicitações não iniciada há mais de 10 dias
                                    </h6>
                                </div>
                                <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 text-right ">
                                    <span class="badge  badge-danger badge-pill text-right">1</span>
                                </div>
                            </div>
                        </a>
                    </div>
                
                    <div id="collapse3" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body p-0">
                            <div class="border-top">
                                <div class="pl-1 pr-1">
                                    <p class="font-weight-semibold">7844 - Projeto de Manutenção - Inicio 01/08/2019 - Fim 01/03/2020</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>



    
