<!-- <button
  type="button"
  class="btn btn-outline-primary mr-2"
  data-toggle="modal"
  [attr.data-target]="'#' + 'modal' + 'oscar'"
  (click)="buscarObservacoes()"
>
  {{ 'oscar' }}
</button> -->
<ngx-spinner
  name="createOrUpdate"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Atualizando...</p>
</ngx-spinner><ngx-spinner
  name="buscarObs"
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Buscando...</p>
</ngx-spinner>
<!-- MODAL HEADER -->
<div class="modal-header">
  <!-- MODAL TITLE -->
  <h3 class="modal-title">Observação</h3>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<ng-container *ngIf="podeEditar">
  <!-- MODAL BODY -->
  <form [formGroup]="formObservacoes" class="modal-body row" [class.d-none]="!podeEditar">
    <div class="form-group col-12">
      <label class="font-weigth-semibold">Data</label>
      <input
        type="date"
        class="form-control"
        formControlName="data"
        [ngClass]="
          formObservacoes.get('data').invalid && formObservacoes.get('data').touched
            ? 'is-invalid'
            : null
        "
      />
      <span
        class="form-text text-danger"
        *ngIf="formObservacoes.get('data').invalid && formObservacoes.get('data').touched"
      >
        Campo Obrigatório
      </span>
    </div>

    <div class="form-group col-12">
      <label class="font-weigth-semibold">Observação</label>
      <textarea
        type="text"
        rows="10"
        class="form-control"
        formControlName="observacao"
        placeholder="Insira aqui sua observação..."
        [ngClass]="
          formObservacoes.get('observacao').invalid && formObservacoes.get('observacao').touched
            ? 'is-invalid'
            : null
        "
      ></textarea>
      <span
        class="form-text text-danger"
        *ngIf="
          formObservacoes.get('observacao').invalid && formObservacoes.get('observacao').touched
        "
      >
        Campo Obrigatório
      </span>
    </div>
  </form>
  <!-- MODAL FOOTER -->
  <div class="modal-footer">
    <button
      *ngIf="cadastro"
      type="button"
      (click)="limparForm()"
      class="btn btn-secondary"
      data-dismiss="modal"
      id="btnCancelarObservacoesModal"
    >
      Cancelar
    </button>
    <button *ngIf="!cadastro" type="button" (click)="limparForm()" class="btn btn-secondary">
      Cancelar
    </button>
    <button
      type="button"
      style="min-width: 87px"
      class="btn btn-primary fixed-size"
      (click)="cadastrarOuAtualizarObservacao()"
    >
      {{ cadastro ? 'Cadastrar' : 'Atualizar' }}
    </button>
  </div>
</ng-container>
<!-- TABELA -->
<div class="modal-body">
  <div class="card-body table-responsive mt-2">
    <table
      class="table table-striped border-blue text-center mt-2"
      *ngIf="listaObservacoes.length > 0"
    >
      <thead class="bg-teal-400">
        <tr>
          <th>Data</th>
          <th>Observação</th>

          <ng-container *ngIf="podeEditar">
            <th>Ações</th>
          </ng-container>
        </tr>
      </thead>

      <tbody *ngFor="let obs of listaObservacoes; let i = index">
        <tr>
          <td>{{ formatarDataBarra(obs.dtcseq) }}</td>
          <td>{{ obs.obs }}</td>
          <ng-container *ngIf="podeEditar">
            <td class="d-flex justify-content-center">
              <button
                type="button"
                class="btn btn-outline-primary mr-2"
                data-toggle="modal"
                data-target="#cadastroObservacoesModal"
                (click)="editarObservacao(obs)"
              >
                <i class="fa fa-pencil"></i>
              </button>
              <button type="button" class="btn btn-outline-danger" (click)="deletarObservacao(obs)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>

    <div
      *ngIf="listaObservacoes.length === 0 || listaObservacoes === null"
      class="p-4 d-flex justify-content-center align-items-center"
    >
      <h6 class="font-weight-semibold">Não há dados de observações para esse colaborador.</h6>
    </div>
  </div>
</div>
<!-- FIM TABELA -->
