import { Injectable } from '@angular/core';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { AcessoService } from 'src/app/service/acesso/acesso.service';

@Injectable({
  providedIn: 'root'
})
export class ColaboradorService {
  localStorage: LocalStorage = new LocalStorage();

  headers = new HttpHeaders();
  cabecalho: any = null;
  filial: string = "";
  url_acesso: any = this.acesso.validarUrl();

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString(); 
    }
   }
   habilitarFiltro(pesquisa:string){
    return this.http.get(`${this.url_acesso}cadastro/${this.filial}`,{headers:this.cabecalho});
  }
   buscarColaboradorAtivo(pesquisa:string){
    return this.http.get(`${this.url_acesso}cadastro/${pesquisa}/${this.filial}`,{headers:this.cabecalho});
  }
}
