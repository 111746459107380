export class Naturalidade {
  Municipio: {
    Codigo: number;
    Estado: {
      Codigo: number;
      Nome: string;
      SiglaUf: string;
    };
    Nome: string;
  };
  constructor() {}
}
