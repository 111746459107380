import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErroService } from 'src/app/service/erros/erro.service';
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { ClienteService } from './cadastro-cliente.service';
import Swal from 'sweetalert';
import { Router } from '@angular/router';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Component({
  selector: 'app-cadastro-cliente',
  templateUrl: './cadastro-cliente.component.html',
  styleUrls: ['./cadastro-cliente.component.css']
})
export class CadastroClienteComponent implements OnInit {

  localStorage: LocalStorage;

  formCadastroCliente: FormGroup;

  listaClientes: any[];
  servicosSelecionados: any[] = []; // objeto que sera enviado para o back 
  listaServicos: any[] = [];

  formServicoCliente: any;
  contadorPag: number;
  public step: number = 0;
  idExcluindo: number;
  filter: Event;

  clientePublico: boolean;
  marcarTodosServicosAux: boolean = true;
  editando: boolean = false;
  servicosubmt: boolean = false
  carregaNome: boolean = false;
  temErro: boolean = false;
  campoObrigatorio: boolean;
  liberarBt: boolean = true;
  edicao: boolean = false;

  constructor(public fb: FormBuilder, private myRoute: Router, private validaErro: ErroService, private spinner: NgxSpinnerService, private clienteService: ClienteService) {
    this.localStorage = this.decryptLocalStorage();
    this.clientePublico = this.localStorage.ClientePublico === true;
  }

  ngOnInit() {
    this.formCadastroCliente = this.fb.group({
      sigla: ['', Validators.compose([Validators.required ,Validators.maxLength(4)])],
      codigo: '',
      codigoUnico: '',
      nome: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
      cnpj: ['', Validators.compose([Validators.required])],
      empresaPublica: ['', Validators.compose([Validators.required])],
    })

    this.formServicoCliente = this.fb.group({
      cb: ([Validators.required]),
    });

    this.contadorPag = 1;
    this.buscarServicos();
    this.buscarClientes();
  }

  buscarClienteCod(matricula) {
    this.spinner.show();
    this.clienteService.buscarCliente(matricula).subscribe(
      (data: Cliente) => {
        this.servicosSelecionados = data.Servicos
        this.servicosSelecionados.forEach(servico => {
          let element = <HTMLInputElement>document.getElementById(servico.Codigo);
          element.checked = true;
        });
        this.spinner.hide();
      },
      error => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    )
  }

  buscarClientes(): void {
    this.spinner.show();

    this.clienteService.buscarClientesPse()
      .subscribe(
        (data: any[]) => {
          this.listaClientes = data;
          this.spinner.hide();
        },
        error => {
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        }
      );
  }

  somenteNumero(event) {
    return ((event.charCode >= 48 && event.charCode <= 57));
  }

  somenteLetras(event) {
    return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode === 32));
    
  }

  mascaraCertificado(): string {
    return '00.000.000/0000-00';
  }

  isCPF(): boolean {
    return this.formCadastroCliente.controls['cnpj'].value == '' ? true : this.formCadastroCliente.controls['cnpj'].value.length < 12 ? true : false;
  }
  formataCnpj(cnpj) {
    if (cnpj.length < 14) {
      const resto = 14 - cnpj.length;
      cnpj = '0'.repeat(resto > 0 ? resto : 0) + cnpj;
    }

    cnpj = cnpj.replace(/[^\d]/g, "");
    
   return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }

  montarCompetencia(): string {
    let data = new Date();
    let mes = data.getMonth() + 1;
    let ano = data.getFullYear();
    return ano.toString() + mes.toString().padStart(2, "0");
  }

  submitCadastroCliente(): void {
    let existeCliente = this.listaClientes.filter((cliente: Cliente) => cliente.CodigoUnico == this.formCadastroCliente.controls["codigoUnico"].value).length

    if (this.formCadastroCliente.invalid || this.servicosSelecionados.length <= 0) {
      this.temErro = true;
      this.campoObrigatorio = true;
      Swal('Atenção!', 'Preencha todos os campos obrigatórios!', 'warning');
    }
    else {
      const clienteMontado = this.montarCliente();

      if (existeCliente > 0) {
        this.editarCliente(clienteMontado)
        this.editando = false;
        this.temErro = false
      }
      else {
        this.salvarCadastroCliente(clienteMontado);
        this.editando = false;
        this.servicosubmt = false;
        this.temErro = false
      }
      this.limparCampos();
    }
  }

  salvarCadastroCliente(cliente: Cliente) {
    this.spinner.show();
    this.clienteService.salvarCliente(cliente)
      .subscribe(
        data => {
          this.buscarClientes();
          Swal('Sucesso!', 'Cliente salvo com sucesso!', 'success');
          this.limparCampos();
          this.spinner.hide();
        },
        error => {
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        })
  }

  editarCliente(cliente: Cliente) {
    this.spinner.show();
    this.clienteService.editarCliente(cliente)
      .subscribe(
        data => {
          this.buscarClientes();
          Swal('Sucesso!', 'Cliente alterado com sucesso!', 'success');
          this.limparCampos();
          this.spinner.hide();
        },
        error => {
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        })
  }

  fecharModal() {
    document.getElementById("closeModal2").click();
  }

  limparModal() {
    if (!this.editando) {
      if (!this.servicosubmt) {
        this.servicosSelecionados.forEach(servico => {
          let element = <HTMLInputElement>document.getElementById(servico.Codigo); element.checked = false
        })
        this.servicosSelecionados = [];
      }
    } else {

    }
  }

  validarConfiguracoes() {
    if (this.servicosSelecionados.length > 0) {
      this.liberarBt = false;
      this.campoObrigatorio = false;
      Swal('Sucesso!', 'Serviço Configurado com sucesso!', 'success');
      this.servicosubmt = true;
      this.fecharModal()
    } else {
      this.campoObrigatorio = true
      Swal('Atenção!', 'Selecione ao menos um serviço!', 'warning');
    }
  }

  deletar(cliente: Cliente, index: number): void {
    this.idExcluindo = index;
    Swal({
      title: "Você tem certeza?",
      text: "Esta operação não pode ser desfeita.",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true
        }
      }
    })
      .then((willDelete) => {
        if (willDelete) {
          this.excluirCliente(cliente);
        }
      });
    this.idExcluindo = null;
  }

  excluirCliente(cliente: Cliente) {
    this.spinner.show();
    this.clienteService.deletarCliente(cliente.CodigoUnico)
      .subscribe(
        data => {
          Swal('Sucesso!', 'Cliente excluído com sucesso!', 'success');
          this.limparCampos();
          this.buscarClientes();
          this.spinner.hide();
        },
        error => {
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        })
  }

  carregarCliente(cliente: Cliente): void {
    this.limparCampos();
    if (cliente != null) {
      this.buscarClienteCod(cliente.CodigoUnico)
      this.temErro = true;
      this.formCadastroCliente.markAsPristine();
      this.editando = true;

      this.formCadastroCliente.setValue({
        sigla: cliente.Sigla,
        codigo: cliente.Codigo,
        codigoUnico: cliente.CodigoUnico,
        nome: cliente.Nome,
        cnpj: cliente.CNPJ,
        empresaPublica: cliente.EmpresaPublica
      });
      this.edicao = true;
      this.liberarBt = false;
    } else {
      Swal('Atenção!', 'Cliente vazio ou nulo!', 'warning');
    }
    scroll(0, 0);
  }

  montarCliente(): Cliente {
    const cliente = this.formCadastroCliente.getRawValue()
    return {
      Codigo: cliente.codigo ? cliente.codigo : "",
      Sigla: cliente.sigla,
      Nome: cliente.nome,
      CNPJ: cliente.cnpj,
      EmpresaPublica: cliente.empresaPublica,
      CodigoUnico: cliente.codigoUnico,
      Servicos: this.servicosSelecionados
    }
  }

  limparCampos(): void {
    this.formCadastroCliente.controls['codigo'].setValue("")
    this.formCadastroCliente.controls['sigla'].setValue("")
    this.formCadastroCliente.controls['codigoUnico'].setValue("")
    this.formCadastroCliente.controls['nome'].setValue("")
    this.formCadastroCliente.controls['cnpj'].setValue("")
    this.formCadastroCliente.controls['empresaPublica'].setValue("")
    this.formCadastroCliente.markAsPristine()
    this.servicosSelecionados.forEach(servico => {
      let element = <HTMLInputElement>document.getElementById(servico.Codigo); element.checked = false
    })
    this.servicosSelecionados = []
    this.editando = false
    this.servicosubmt = false
    this.temErro = false
  }

  buscarServicos(): void {
    this.clienteService.buscarServicosPse()
      .subscribe(
        (data: any[]) => {
          this.listaServicos = data
          this.spinner.hide();
        },
        error => {
          this.validaErro.retornoErro(error);
          if (error.error.Mensagem = 'Usuário sem permissão para esta funcionalidade.') { this.myRoute.navigate(["home"]); } // Resolve, mas verificar outra forma de fazer.
          this.spinner.hide();
        }
      );
  }

  marcarTodosServicos(): void {
    this.listaServicos.forEach(empfil => {
      let element = <HTMLInputElement>document.getElementById(String(empfil.Codigo));
      if (element.checked == false) {
        element.click();
      }
    });
    this.marcarTodosServicosAux = false;
  }

  desmarcarTodosServicos(): void {
    this.listaServicos.forEach(empfil => {
      let element = <HTMLInputElement>document.getElementById(String(empfil.Codigo));
      element.checked = false;
    });
    this.servicosSelecionados = [];
    this.marcarTodosServicosAux = true;
  }

  onChangeServicos(servico: string[], isChecked) {
    if(isChecked instanceof Event){
      isChecked = (isChecked.target as HTMLInputElement).checked
    }
    if (isChecked) {
      this.servicosSelecionados.push(servico);
    } else {
      this.popServicos(servico);
    }
    if (this.servicosSelecionados.length > this.listaServicos.length) {
      this.servicosSelecionados.pop()
    }
  }

  popServicos(servico) {
    for (let index = 0; index < this.servicosSelecionados.length; index++) {
      if (this.servicosSelecionados[index].Codigo === servico.Codigo) {
        this.servicosSelecionados.splice(index, 1)
      }
    }
  }
  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }
}

export interface Cliente {
  Codigo: number
  CodigoUnico: number
  EmpresaPublica: number
  Nome: string
  CNPJ: string
  Sigla: string
  Servicos: any[]
}