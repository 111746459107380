import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { TransferenciaInativoService } from './transferencia-inativo.service';
import { validarDataAtualMenorIgual } from 'src/app/service/Validators/dataValidatorMenorIgual';

import Swal from 'sweetalert';
import { title } from 'process';
import { forEach } from '@angular-devkit/schematics';
type destinoInativacao = {
  Empfil: string;
  NomFil: string;
  Vinculo: number;
  Codaf: number;
  SFlags: string;
  Falecido: string;
  CodLot: string;
  CodCus: string;
  Motivo: number;
  F1: number;
};
type TipoProporcao = {
  Codigo: string;
  Descricao: string;
  Grupo: string | null;
};

type TipoAposentadoria = {
  Codigo: string;
  Descricao: string;
  Grupo: string | null;
};
type ClassificacaoAto = {
  Cod: number;
  Nome: string;
};

type Usuario = {
  Nome: string;
  Sexo: string;
  Matricula: number;
  Vinculo: string;
  ListaEPE: any;
};

@Component({
  selector: 'app-transferencia-inativo',
  templateUrl: './transferencia-inativo.component.html',
  styleUrls: ['./transferencia-inativo.component.css'],
})
export class TransferenciaInativoComponent {
  formTelaInicial: FormGroup;
  form: FormGroup;
  destinoInativacao: destinoInativacao;
  listaTipoProporcao: TipoProporcao[];
  listaTipoAposentadoria: TipoAposentadoria[];
  listaClassificacaoAto: ClassificacaoAto[];
  usuario: Usuario;
  constructor(
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private service: TransferenciaInativoService
  ) {
    this.formTelaInicial = this.fb.nonNullable.group({
      matricula: [null, [Validators.required]],
      //aposentadoria value = 1
      //auxilio reclusão value = 2
      tipoTransferencia: [1, Validators.required],
    });
    // this.colaborador = {
    //   Nome: 'THALES AJUSTE SETOR',
    //   Sexo: '1',
    //   Matricula: 291,
    //   Vinculo: '1 - ESTATUTARIO CIVIL',
    //   ListaEPE: null,
    // };
  }

  onMatriculaSubmit() {
    this.spinner.show();
    this.service.buscarNumOrd(this.usuario.Matricula).subscribe({
      next: (numord: string) => {
        this.buscarInativo(numord);
      },
      error: (err) => {
        Swal({
          title: 'Atenção',
          text: `Ocorreu erro na busca do numord para matrícula escolhida.`,
          icon: 'warning',
        });
        console.warn(err);
      },
    });
  }
  buscarUsuario(matricula: number) {
    this.service.buscarUsuario(matricula).subscribe({
      next: (data: Usuario) => {
        this.usuario = data;
      },
      error: (err) => {
        if (err.error.Mensagem) {
          Swal({
            title: 'Atenção',
            text: `${err.error.Mensagem} - Colaborador com mátricula: ${
              this.formTelaInicial.get('matricula').value
            } não encontrado.`,
            icon: 'warning',
          });
        } else {
          Swal('Error', 'Houve algum erro inesperado na consulta da matrícula', 'warning');
          console.warn(err);
        }
      },
    });
  }
  buscarInativo(numord: string) {
    this.service.buscarInativo(numord).subscribe({
      next: (data: destinoInativacao) => {
        if (data) {
          this.destinoInativacao = data;
          if (this.formTelaInicial.get('tipoTransferencia').value == 1) {
            this.criarFormAposentadoria();
            this.addControlesComumnsAoForm();
            this.setarTiposAposentadoria();
            this.setarTiposProporcao(numord);
            this.setarTiposClassificacaoAto();
            this.spinner.hide();
          } else {
            this.criarFormAuxilioReclusao();
            this.addControlesComumnsAoForm();
            this.setarTiposClassificacaoAto();
            this.spinner.hide();
          }
        } else {
          Swal({
            title: 'Atenção',
            text: `Matrícula ${this.usuario.Matricula} não está apta para ser inativada.`,
            icon: 'info',
          });
          this.spinner.hide();
        }
      },
    });
  }
  setarTiposClassificacaoAto() {
    this.service.buscarClassificacaoAto().subscribe({
      next: (data: ClassificacaoAto[]) => {
        this.listaClassificacaoAto = data;
        this.spinner.hide();
      },
      error: (err) => {
        Swal({
          title: 'Atenção',
          text: `Ocorreu erro na busca dos códigos de Classificação Ato.`,
          icon: 'warning',
        });
        console.warn(err);
        this.spinner.hide();
      },
    });
  }
  setarTiposAposentadoria() {
    this.service.buscarTiposAposentadoria().subscribe({
      next: (data: TipoAposentadoria[]) => {
        this.listaTipoAposentadoria = data;
        this.spinner.hide();
      },
      error: (err) => {
        Swal({
          title: 'Atenção',
          text: `Ocorreu erro na busca dos tipos de aposentadoria.`,
          icon: 'warning',
        });
        console.warn(err);
        this.spinner.hide();
      },
    });
  }
  setarTiposProporcao(numord: string | number) {
    this.service.buscarTipoProporcao(numord).subscribe({
      next: (data: TipoProporcao[]) => {
        this.listaTipoProporcao = data;
        this.spinner.hide();
      },
      error: (err) => {
        Swal({
          title: 'Atenção',
          text: `Ocorreu erro na busca dos tipos de proporção.`,
          icon: 'warning',
        });
        console.warn(err);
        this.spinner.hide();
      },
    });
  }

  criarFormAposentadoria() {
    this.form = this.fb.nonNullable.group({
      tipoAposentadoria: [null, Validators.required],
      tipoProporcao: [null, Validators.required],
      valorProporcao: [null, [Validators.required, Validators.max(100)]],
    });
  }
  criarFormAuxilioReclusao() {
    this.form = this.fb.nonNullable.group({});
  }
  addControlesComumnsAoForm() {
    this.form.addControl(
      'dataTransferencia',
      new FormControl(null, [Validators.required, validarDataAtualMenorIgual()])
    );
    this.form.addControl('NumeroProcesso', new FormControl(null));
    this.form.addControl('numDoe', new FormControl(null));
    this.form.addControl('anoDoe', new FormControl({ value: null, disabled: true }));
    this.form.addControl('dataDoe', new FormControl(null, validarDataAtualMenorIgual()));
    this.form.addControl('classificacaoAto', new FormControl(null));
    this.form.addControl('numAto', new FormControl(null));
    this.form.addControl('anoAto', new FormControl({ value: null, disabled: true }));
    this.form.addControl('dataAto', new FormControl(null, validarDataAtualMenorIgual()));
  }

  isValid(controle: string) {
    const control = this.form.get(controle);
    if (control) {
      if (control.invalid && control.touched) {
        return 'is-invalid';
      } else return '';
    }
  }
  formAposentadoria() {
    //TIPO DE TRANSFERENCIA = APOSENTADORIA
    return this.formTelaInicial.get('tipoTransferencia').value == 1;
  }
  retornarTelaInicial() {
    this.destinoInativacao = null;
    this.form = null;
  }

  markFormAsTouched(form: FormGroup) {
    for (const controlName in form.controls) {
      if (form.controls.hasOwnProperty(controlName)) {
        form.controls[controlName].markAsTouched();
      }
    }
  }
  convertToUpperCase(controle: string) {
    this.form.get(controle).setValue(this.form.get(controle).value.toUpperCase());
  }

  setarAnoNoControle(controleFrom: string, controleTarget: string) {
    const dataControl = this.form.get(controleFrom);
    const anoControl = this.form.get(controleTarget);
    if (!dataControl || !anoControl) {
      Swal({
        title: 'Atenção',
        text: `Controle ${controleFrom} ou ${controleTarget} não existe.`,
        icon: 'warning',
      });
      return;
    } else {
      const date = new Date(dataControl.value);
      anoControl.setValue(date.getFullYear());
    }
  }
  onSubmit() {
    this.markFormAsTouched(this.form);
    if (this.form.valid) {
      // TODO SUBMIT
    }
    console.log(this.form.getRawValue());
  }
}
