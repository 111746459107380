import {Component, Input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ErroService} from 'src/app/service/erros/erro.service';
import {CadastroPublicoService} from '../../cadastro-publico/cadastro-publico.service';
import {Endereco} from 'src/app/service/classes/Endereco-Padrao/endereco';
import {TempoServicoService} from '../tempo-servico.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {Item} from '../../faltas/faltas-cadastro/faltas-cadastro.component';
import {Cadastro} from 'src/app/service/classes/cadastro/cadastro';
import Swal from 'sweetalert';
import {TempoServicoComponent} from '../tempo-servico.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-tempo-servico-cadastro',
  templateUrl: './tempo-servico-cadastro.component.html',
  styleUrls: ['./tempo-servico-cadastro.component.css'],
})
export class TempoServicoCadastroComponent {
  @Input() colaborador: Cadastro = null;
  @Input() cadastro: boolean = true;
  @Input() servicoAtual: TempoServico = null;
  formTempoServico: FormGroup;
  loadingCep: boolean = false;
  cepValido: boolean = false;
  formValues: any;

  listaContrPrevid: any[] = [
    {Descricao: 'Cont. desc. pelo 1º Emprego', Valor: 1},
    {
      Descricao:
        'Cont. desc. por outra(s) Empr. sobre valor inferior ao limite máximo do salário de contribuição',
      Valor: 2,
    },
    {
      Descricao:
        'Cont. sobre o limite máximo de salário de contribuição já descontada em outra(s) empresa(s)',
      Valor: 3,
    },
  ];
  listaCodCateg: any[] = [];
  listaTipoAverbacao: any[] = [
    {Descricao: 'TEMPO DE SERVIÇO PÚBLICO', Valor: 1},
    {Descricao: 'TEMPO DE SERVIÇO PRIVADO', Valor: 2},
    {Descricao: 'LICENÇA - PRÊMIO EM DOBRO', Valor: 3},
    {Descricao: 'EMENDA CONSTITUCIONAL 20/98', Valor: 4},
    {Descricao: 'FÉRIAS NÃO GOZADAS', Valor: 5},
  ];
  listaAverbado: any[] = ['SIM', 'NAO'];
  listaEfeitoAverbacao: any[] = [];
  listaClassificacaoAto: Item[] = [];

  constructor(
    private fb: FormBuilder,
    private cadastroPublicoService: CadastroPublicoService,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService,
    private tempoServicoService: TempoServicoService,
    private tempoServicoComponente: TempoServicoComponent
  ) {}
  oscar(){
    console.log('colaborador', this.colaborador);
  }
  ngOnInit() {
    this.formTempoServico = this.fb.group({
      dadosFuncionario: this.fb.group({
        Protoc: ['', Validators.required],
        Ultrem: ['', Validators.required],
        Ultrec: [''],
        InDmv: [''],
        Ultati: ['', Validators.required],
        CodCateg: ['', Validators.required],
      }),
      dadosAverbacao: this.fb.group({
        Nomemp: ['', Validators.required],
        EfeitosAverbacao: ['', Validators.required],
        TipoAverbacao: ['', Validators.required],
        Averbado: ['', Validators.required],
        DtIni: ['', Validators.required],
        DtFim: [''],
        NumQui: [{value: '', disabled: true}],
      }),
      dadosPublicacao: this.fb.group({
        NumeroProcesso: [''],
        IdClassificacaoAto: [''],
        NumeroDoe: [''],
        DtDoe: [''],
        NumeroAto: [''],
        DtAto: [''],
      }),
      dadosInstituicao: this.fb.group({
        Ender: [{value: '', disabled: true}],
        EnderNum: ['', Validators.required],
        Enderc: [''],
        Bairro: [{value: '', disabled: true}],
        Cidade: [{value: '', disabled: true}],
        UF: [{value: '', disabled: true}],
        Cep: ['', Validators.required],
        Telefone: ['', Validators.required],
        Cnpj: ['', Validators.required],
      }),
    });
    this.formValues = this.formTempoServico.value;
    this.buscarListaCodCateg();
    this.buscarIdClassificacaoAto();
    this.buscarEfeitoAverbacao();
  }

  buscarListaCodCateg() {
    this.listaCodCateg = this.tempoServicoService.retornarLista('categoria');
    if (this.listaCodCateg.length == 0) {
      this.spinner.show();
      this.tempoServicoService.buscarListaCategoria().subscribe(
        (data: any) => {
          this.listaCodCateg = data;
          this.tempoServicoService.inserirLista('categoria', this.listaCodCateg);
          this.spinner.hide();
        },
        (err) => {
          this.validaErro.retornoErro(err);
          this.spinner.hide();
        }
      );
    }
  }

  buscarIdClassificacaoAto() {
    this.listaClassificacaoAto = this.tempoServicoService.retornarLista('classificacao');
    if (this.listaClassificacaoAto.length == 0) {
      this.spinner.show();
      this.tempoServicoService.buscarIdClassificacaoAto().subscribe(
        (data: Item[]) => {
          this.listaClassificacaoAto = data;
          this.tempoServicoService.inserirLista('classificacao', this.listaClassificacaoAto);
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.validaErro.retornoErro(err);
        }
      );
    }
  }

  buscarEfeitoAverbacao() {
    this.listaEfeitoAverbacao = this.tempoServicoService.retornarLista('efeitoAverbacao');
    if (this.listaEfeitoAverbacao.length == 0) {
      this.spinner.show();
      this.tempoServicoService.buscarEfeitoAverbacao().subscribe(
        (data: any[]) => {
          console.log('efeito averbação', data);
          this.listaEfeitoAverbacao = data;
          this.tempoServicoService.inserirLista('efeitoAverbacao', this.listaEfeitoAverbacao);
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.validaErro.retornoErro(err);
        }
      );
    }
  }

  aoMudarCep() {
    let cep = this.formTempoServico.get('dadosInstituicao').get('Cep').value.toString();
    // this.cepValido = true;
    console.log('cep', cep);
    console.log('cep length', cep.length);

    if (cep.length == 8) {
      this.buscarEndereco(cep);
    } else {
      console.log('nao buscou');
      this.carrregarCampos();
    }
  }

  buscarEndereco(cep: string) {
    this.loadingCep = true;

    this.cadastroPublicoService.buscarCep(cep).subscribe(
      (data: Endereco) => {
        this.carrregarCampos(data);
        this.cepValido = data.Valido;
        this.loadingCep = false;
        if (!this.cepValido) {
          Swal('Atenção', 'Endereço não encontrado', 'warning');
        }
      },
      (error) => {
        this.loadingCep = false;
        this.validaErro.retornoErro(error);
      }
    );
  }

  carrregarCampos(endereco?: Endereco) {
    // if (endereco === null) endereco = this.cadastroUsuario.Pessoal.Endereco;

    this.formTempoServico
      .get('dadosInstituicao')
      .get('Cidade')
      .setValue(endereco ? endereco.Cidade : '');
    this.formTempoServico
      .get('dadosInstituicao')
      .get('Ender')
      .setValue(endereco ? endereco.Logradouro : '');
    this.formTempoServico
      .get('dadosInstituicao')
      .get('Bairro')
      .setValue(endereco ? endereco.Bairro : '');
    this.formTempoServico
      .get('dadosInstituicao')
      .get('UF')
      .setValue(endereco ? endereco.Uf : '');

    // this.bloqueiaCamposEndereco(endereco.Valido);
  }

  calcularDias(dataInicio: string, dataVolta: string) {
    if (
      this.formTempoServico.get('dadosAverbacao').get('DtIni').value != '' &&
      this.formTempoServico.get('dadosAverbacao').get('DtFim').value != '' &&
      this.tempoServicoService.calcularDias(dataInicio, dataVolta) != undefined
    ) {
      this.formTempoServico
        .get('dadosAverbacao')
        .get('NumQui')
        .setValue(this.tempoServicoService.calcularDias(dataInicio, dataVolta));
    } else {
      this.formTempoServico.get('dadosAverbacao').get('NumQui').setValue('');
    }
    console.log('valor numqui', this.formTempoServico.get('dadosAverbacao').get('NumQui').value);
  }

  lancarTempoServico(servico?: TempoServico) {
    this.spinner.show();
    this.formTempoServico.markAllAsTouched();
    let botaoFechar = document.getElementById('botaoFechar');
    if (this.formTempoServico.valid) {
      if (servico) {
        let lancamento = this.montarTempoServico(servico);
        console.log('lancamento', lancamento);

        this.tempoServicoService.atualizarTempoServico(lancamento).subscribe(
          (data) => {
            console.log('resposta put', data);
            this.tempoServicoComponente.buscarTempoServico();
            botaoFechar.click();
            Swal('Sucesso', 'Tempo de serviço atualizado com sucesso.', 'success');
            this.spinner.hide();
          },
          (err: HttpErrorResponse) => {
            if (err.status === 500) {
              Swal('Erro', 'Não foi possível atualizar essa falta.', 'error');
            } else {
              const errorMessage =
                err.error?.Mensagem || 'Ocorreu um erro ao atualizar essa falta.';
              Swal('Erro', errorMessage, 'error');
            }
            this.spinner.hide();
          }
        );
      } else {
        let lancamento = this.montarTempoServico();
        console.log('lancamento', lancamento);

        this.tempoServicoService.lancarTempoServico(lancamento).subscribe(
          (data) => {
            console.log('resposta post', data);
            this.tempoServicoComponente.buscarTempoServico();
            botaoFechar.click();
            Swal('Sucesso', 'Tempo de serviço lançado com sucesso.', 'success');
            this.spinner.hide();
          },
          (err: HttpErrorResponse) => {
            if (err.status === 500) {
              Swal('Erro', 'Não foi possível inserir essa falta.', 'error');
            } else {
              const errorMessage =
                err.error?.Mensagem || 'Ocorreu um erro ao atualizar essa falta.';
              Swal('Erro', errorMessage, 'error');
            }
            this.spinner.hide();
          }
        );
      }
    } else {
      Swal('Atenção', 'Por favor, preencha todos os campos corretamente.', 'warning');
      this.spinner.hide();
    }
  }

  montarTempoServico(servico?: TempoServico) {
    let tempoServicoMontado: TempoServico = {
      Sigla: 'FAPP',
      Empfil: this.colaborador.Filial.Codigo.toString(),
      Mat: parseInt(this.colaborador.Matricula),
      DtComp: '000000',
      TpProc: 'EM',
      Flag: '2',
      Ctaprev: '0',
      Ultcat: 0,

      Nomemp: this.formTempoServico.get('dadosAverbacao').get('Nomemp').value,
      EfeitosAverbacao: this.formTempoServico.get('dadosAverbacao').get('EfeitosAverbacao').value, //esperando rota
      TipoAverbacao: this.formTempoServico.get('dadosAverbacao').get('TipoAverbacao').value,
      Averbado: this.formTempoServico.get('dadosAverbacao').get('Averbado').value,
      DtIni: this.formTempoServico.get('dadosAverbacao').get('DtIni').value
        ? {
            SetData: this.formTempoServico
              .get('dadosAverbacao')
              .get('DtIni')
              .value.replace(/-/g, ''),
            ToDate: this.tempoServicoService.formatarDataBarra(
              this.formTempoServico.get('dadosAverbacao').get('DtIni').value.replace(/-/g, '')
            ),
            IsDate: true,
            Empty: false,
          }
        : {SetData: '00000000', ToDate: '', IsDate: false, Empty: true},
      DtFim: this.formTempoServico.get('dadosAverbacao').get('DtFim').value
        ? {
            SetData: this.formTempoServico
              .get('dadosAverbacao')
              .get('DtFim')
              .value.replace(/-/g, ''),
            ToDate: this.tempoServicoService.formatarDataBarra(
              this.formTempoServico.get('dadosAverbacao').get('DtFim').value.replace(/-/g, '')
            ),
            IsDate: true,
            Empty: false,
          }
        : {SetData: '00000000', ToDate: '', IsDate: false, Empty: true},
      NumQui: this.formTempoServico.get('dadosAverbacao').get('NumQui').value
        ? this.formTempoServico.get('dadosAverbacao').get('NumQui').value
        : '0',

      Ender:
        this.formTempoServico.get('dadosInstituicao').get('Ender').value == null
          ? ''
          : this.formTempoServico.get('dadosInstituicao').get('Ender').value,
      EnderNum: this.formTempoServico.get('dadosInstituicao').get('EnderNum').value
        ? this.formTempoServico.get('dadosInstituicao').get('EnderNum').value
        : '0',
      Enderc: this.formTempoServico.get('dadosInstituicao').get('Enderc').value,
      Bairro:
        this.formTempoServico.get('dadosInstituicao').get('Bairro').value == null
          ? ''
          : this.formTempoServico.get('dadosInstituicao').get('Bairro').value,
      Cidade:
        this.formTempoServico.get('dadosInstituicao').get('Cidade').value == null
          ? ''
          : this.formTempoServico.get('dadosInstituicao').get('Cidade').value,
      UF:
        this.formTempoServico.get('dadosInstituicao').get('UF').value == null
          ? ''
          : this.formTempoServico.get('dadosInstituicao').get('UF').value,
      Cep: this.formTempoServico.get('dadosInstituicao').get('Cep').value
        ? this.formTempoServico.get('dadosInstituicao').get('Cep').value.toString()
        : '0',
      DDD: this.formTempoServico.get('dadosInstituicao').get('Telefone').value
        ? this.formTempoServico.get('dadosInstituicao').get('Telefone').value.slice(0, 2)
        : '0',
      Telefone: this.formTempoServico.get('dadosInstituicao').get('Telefone').value
        ? this.formTempoServico.get('dadosInstituicao').get('Telefone').value.slice(2, 10)
        : '0',
      CgcNum: this.formTempoServico.get('dadosInstituicao').get('Cnpj').value
        ? this.formTempoServico.get('dadosInstituicao').get('Cnpj').value.slice(0, 8)
        : '00000000',
      CgcOrd: this.formTempoServico.get('dadosInstituicao').get('Cnpj').value
        ? this.formTempoServico.get('dadosInstituicao').get('Cnpj').value.slice(8, 12)
        : '0000',
      CgcDv: this.formTempoServico.get('dadosInstituicao').get('Cnpj').value
        ? this.formTempoServico.get('dadosInstituicao').get('Cnpj').value.slice(12, 14)
        : '00',

      Protoc: this.formTempoServico.get('dadosFuncionario').get('Protoc').value
        ? this.formTempoServico.get('dadosFuncionario').get('Protoc').value
        : '0',
      Ultrem: this.formTempoServico.get('dadosFuncionario').get('Ultrem').value
        ? parseInt(this.formTempoServico.get('dadosFuncionario').get('Ultrem').value)
        : 0.0,
      Ultrec: this.formTempoServico.get('dadosFuncionario').get('Ultrec').value
        ? parseInt(this.formTempoServico.get('dadosFuncionario').get('Ultrec').value)
        : 0.0,
      InDmv: this.formTempoServico.get('dadosFuncionario').get('InDmv').value
        ? this.formTempoServico.get('dadosFuncionario').get('InDmv').value
        : '0',
      Ultati: this.formTempoServico.get('dadosFuncionario').get('Ultati').value
        ? this.formTempoServico.get('dadosFuncionario').get('Ultati').value
        : '0',
      CodCateg: this.formTempoServico.get('dadosFuncionario').get('CodCateg').value,

      NumeroProcesso: this.formTempoServico.get('dadosPublicacao').get('NumeroProcesso').value,
      IdClassificacaoAto: this.formTempoServico.get('dadosPublicacao').get('IdClassificacaoAto')
        .value
        ? this.formTempoServico.get('dadosPublicacao').get('IdClassificacaoAto').value
        : '0',
      NumeroDoe: this.formTempoServico.get('dadosPublicacao').get('NumeroDoe').value,
      DtDoe: this.formTempoServico.get('dadosPublicacao').get('DtDoe').value
        ? {
            SetData: this.formTempoServico
              .get('dadosPublicacao')
              .get('DtDoe')
              .value.replace(/-/g, ''),
            ToDate: this.tempoServicoService.formatarDataBarra(
              this.formTempoServico.get('dadosPublicacao').get('DtDoe').value.replace(/-/g, '')
            ),
            IsDate: true,
            Empty: false,
          }
        : {SetData: '00000000', ToDate: '', IsDate: false, Empty: true},
      AnoDoe: this.formTempoServico.get('dadosPublicacao').get('DtDoe').value
        ? this.formTempoServico.get('dadosPublicacao').get('DtDoe').value.slice(0, 4)
        : '0000',
      NumeroAto: this.formTempoServico.get('dadosPublicacao').get('NumeroAto').value,
      DtAto: this.formTempoServico.get('dadosPublicacao').get('DtAto').value
        ? {
            SetData: this.formTempoServico
              .get('dadosPublicacao')
              .get('DtAto')
              .value.replace(/-/g, ''),
            ToDate: this.tempoServicoService.formatarDataBarra(
              this.formTempoServico.get('dadosPublicacao').get('DtAto').value.replace(/-/g, '')
            ),
            IsDate: true,
            Empty: false,
          }
        : {SetData: '00000000', ToDate: '', IsDate: false, Empty: true},
      AnoAto: this.formTempoServico.get('dadosPublicacao').get('DtAto').value
        ? this.formTempoServico.get('dadosPublicacao').get('DtAto').value.slice(0, 4)
        : '0000',
    };
    if (servico) {
      tempoServicoMontado.Nid = servico.Nid;
    }
    return tempoServicoMontado;
  }

  limparForm() {
    this.formTempoServico.reset();
    this.formTempoServico.patchValue(this.formValues);
  }

  setarServicoExistente(servico: TempoServico) {
    servico.Protoc != '0'
      ? this.formTempoServico.get('dadosFuncionario').get('Protoc').setValue(servico.Protoc)
      : null;
    servico.Ultrem != 0
      ? this.formTempoServico.get('dadosFuncionario').get('Ultrem').setValue(servico.Ultrem)
      : null;
    servico.Ultrec != 0
      ? this.formTempoServico.get('dadosFuncionario').get('Ultrec').setValue(servico.Ultrec)
      : null;
    servico.InDmv != '0'
      ? this.formTempoServico.get('dadosFuncionario').get('InDmv').setValue(servico.InDmv)
      : null;
    servico.Ultati != '0'
      ? this.formTempoServico.get('dadosFuncionario').get('Ultati').setValue(servico.Ultati)
      : null;
    servico.CodCateg
      ? this.formTempoServico.get('dadosFuncionario').get('CodCateg').setValue(servico.CodCateg)
      : null;

    servico.Nomemp
      ? this.formTempoServico.get('dadosAverbacao').get('Nomemp').setValue(servico.Nomemp)
      : null;
    servico.EfeitosAverbacao != 0
      ? this.formTempoServico
          .get('dadosAverbacao')
          .get('EfeitosAverbacao')
          .setValue(servico.EfeitosAverbacao)
      : null;
    servico.TipoAverbacao != 0
      ? this.formTempoServico
          .get('dadosAverbacao')
          .get('TipoAverbacao')
          .setValue(servico.TipoAverbacao)
      : null;
    servico.Averbado
      ? this.formTempoServico.get('dadosAverbacao').get('Averbado').setValue(servico.Averbado)
      : null;
    servico.DtIni.IsDate
      ? this.formTempoServico
          .get('dadosAverbacao')
          .get('DtIni')
          .setValue(this.tempoServicoService.formatarDataHifen(servico.DtIni.SetData))
      : null;
    servico.DtFim.IsDate
      ? this.formTempoServico
          .get('dadosAverbacao')
          .get('DtFim')
          .setValue(this.tempoServicoService.formatarDataHifen(servico.DtFim.SetData))
      : null;
    servico.NumQui != 0
      ? this.formTempoServico.get('dadosAverbacao').get('NumQui').setValue(servico.NumQui)
      : null;

    servico.NumeroProcesso
      ? this.formTempoServico
          .get('dadosPublicacao')
          .get('NumeroProcesso')
          .setValue(servico.NumeroProcesso)
      : null;
    servico.IdClassificacaoAto != 0
      ? this.formTempoServico
          .get('dadosPublicacao')
          .get('IdClassificacaoAto')
          .setValue(servico.IdClassificacaoAto)
      : null;
    servico.NumeroDoe
      ? this.formTempoServico.get('dadosPublicacao').get('NumeroDoe').setValue(servico.NumeroDoe)
      : null;
    servico.DtDoe.IsDate
      ? this.formTempoServico
          .get('dadosPublicacao')
          .get('DtDoe')
          .setValue(this.tempoServicoService.formatarDataHifen(servico.DtDoe.SetData))
      : null;
    servico.NumeroAto
      ? this.formTempoServico.get('dadosPublicacao').get('NumeroAto').setValue(servico.NumeroAto)
      : null;
    servico.DtAto.IsDate
      ? this.formTempoServico
          .get('dadosPublicacao')
          .get('DtAto')
          .setValue(this.tempoServicoService.formatarDataHifen(servico.DtAto.SetData))
      : null;

    // servico.Ender ? this.formTempoServico.get('dadosInstituicao').get('Ender').setValue(servico.Ender) : null;
    servico.Cep != 0
      ? this.formTempoServico.get('dadosInstituicao').get('Cep').setValue(servico.Cep)
      : null;
    this.aoMudarCep();
    servico.EnderNum != 0
      ? this.formTempoServico.get('dadosInstituicao').get('EnderNum').setValue(servico.EnderNum)
      : null;
    servico.Enderc
      ? this.formTempoServico.get('dadosInstituicao').get('Enderc').setValue(servico.Enderc)
      : null;
    servico.Telefone != 0 && servico.DDD != 0
      ? this.formTempoServico
          .get('dadosInstituicao')
          .get('Telefone')
          .setValue(servico.DDD.toString() + servico.Telefone.toString())
      : null;
    servico.CgcNum != '00000000' && servico.CgcOrd != '0000' && servico.CgcDv != '00'
      ? this.formTempoServico
          .get('dadosInstituicao')
          .get('Cnpj')
          .setValue(servico.CgcNum + servico.CgcOrd + servico.CgcDv)
      : null;
  }
}

export interface TempoServico {
  Sigla: string;
  Empfil: string;
  Mat: number;
  Numord?: string;
  Nomemp: string;
  EfeitosAverbacao: number;
  TipoAverbacao: number;
  Averbado: string;
  DtIni: {
    SetData: string;
    ToDate: string;
    IsDate: boolean;
    Empty: boolean;
  };
  DtFim: {
    SetData: string;
    ToDate: string;
    IsDate: boolean;
    Empty: boolean;
  };
  NumQui: number;
  Ender: string;
  EnderNum: number;
  Enderc: string;
  Bairro: string;
  Cidade: string;
  UF: string;
  Cep: number;
  DDD: number;
  Telefone: number;
  CgcNum: string;
  CgcOrd: string;
  CgcDv: string;
  Protoc: string;
  Ctaprev: string;
  Ultrem: number;
  Ultrec: number;
  Ultati: string;
  Ultcat: number;
  NumeroProcesso: string;
  NumeroDoe: string;
  IdClassificacaoAto: number;
  NumeroAto: string;
  AnoAto: string;
  DtAto: {
    SetData: string;
    ToDate: string;
    IsDate: boolean;
    Empty: boolean;
  };
  AnoDoe: string;
  DtDoe: {
    SetData: string;
    ToDate: string;
    IsDate: boolean;
    Empty: boolean;
  };
  DtComp: string;
  TpProc: string;
  Flag: string;
  InDmv: string;
  CodCateg: string;
  Nid?: string;
}

export interface Data {
  SetData: string;
  ToDate: string;
  IsDate: boolean;
  Empty: boolean;
}
