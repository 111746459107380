import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-atualizar-complemento-cadastral',
  templateUrl: './atualizar-complemento-cadastral.component.html',
  styleUrls: ['./atualizar-complemento-cadastral.component.css']
})
export class AtualizarComplementoCadastralComponent implements OnInit {
  formComplementoCadastral: FormGroup;

  constructor(private formBuilder: FormBuilder, private spinner: NgxSpinnerService) { }

  ngOnInit(){
    this.formComplementoCadastral = this.formBuilder.group({
      concursado: ['', [Validators.required]],
      dataConcursoForm: ['', [Validators.required]],
      previdComplementar: ['', [Validators.required]],
      dataAposentadoria: ['', [Validators.required]],
      tipoProp: ['', [Validators.required]],
      proporcionalidade: ['', [Validators.required]],
      dataInicial: ['', [Validators.required]],
      dataFinal: ['', [Validators.required]],
      dataFelecimento: ['', [Validators.required]],
      serProf: ['', [Validators.required]],
      adNotJudicial: ['', [Validators.required]],
      pagaInss: ['', [Validators.required]],
      pagaIr: ['', [Validators.required]],
      pagaPensao: ['', [Validators.required]],
      dataInicioTrabalho: ['', [Validators.required]],
      dataFimTrabalho: ['', [Validators.required]],
      consigBloque: ['', [Validators.required]],
      dtRedistCadencia: ['', [Validators.required]],
      localRedistEmp: ['', [Validators.required]],
      processos: ['', [Validators.required]],
      descricao: ['', [Validators.required]],
      classificacao: ['', [Validators.required]],
      dataDeConvocacao: ['', [Validators.required]],
      dataInialEP: ['', [Validators.required]],
      dataFinalEP: ['', [Validators.required]]
    });
  }

  

}
