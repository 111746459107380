import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AcessoService } from "../../service/acesso/acesso.service";
import {
  InformacaoGenerica,
  Transferencia,
  envioConsulta,
} from "./colaboradores.component";
import { LocalStorage } from "src/app/service/classes/localstorage/LocalStorage";
import { Crypto } from "src/app/service/classes/crypto/crypto";

@Injectable({
  providedIn: "root",
})
export class ColaboradoresService {
  localStorage: LocalStorage;
  url_acesso: string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  filial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(
        new Crypto().decryptUsingAES256(localStorage.getItem("util"))
      );
      this.cabecalho = this.headers.append("sessao", this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.url_acesso = this.acesso.validarUrl();
    }
  }

  habilitarFiltro() {
    return this.http.get(`${this.url_acesso}cadastros/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarColaboradores(situacao: envioConsulta) {
    console.log(situacao);
    return this.http.post(
      this.url_acesso + `cadastros/0000000/EM/${this.filial}/${situacao.page}`,
      situacao.filtros,
      { headers: this.cabecalho }
    );
  }

  buscarTodosColaboradores(situacao: envioConsulta) {
    return this.http.post(
      this.url_acesso + `cadastros/0000000/EM/${this.filial}`,
      situacao.filtros,
      { headers: this.cabecalho }
    );
  }

  buscarColaboradorEfetivar(ordem, matricula) {
    return this.http.get(this.url_acesso + `cadastro/${ordem}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarLotacao(filial: string) {
    return this.http.get(`${this.url_acesso}lotacao/${filial}/`, {
      headers: this.cabecalho,
    });
  }

  excluirCadastro(ordem, matricula) {
    return this.http.delete(
      this.url_acesso +
        `cadastros/${matricula}/${ordem}/0000000/EM/${this.filial}/`,
      { headers: this.cabecalho }
    );
  }

  efetivarCadastroPublico(ordem: string) {
    const dados = { NumeroDeOrdem: ordem };
    return this.http.put(
      this.url_acesso + `cadastro-publico/efetivar-cadastro/${ordem}`,
      dados,
      { headers: this.cabecalho }
    );
  }

  efetivarCadastro(ordem: string) {
    const dados = { NumeroDeOrdem: ordem };
    return this.http.put(
      this.url_acesso + `cadastros/atualizar/EM/${this.filial}`,
      dados,
      { headers: this.cabecalho }
    );
  }

  salvarTranferencia(transferencia: Transferencia) {
    return this.http.post(this.url_acesso + `transferencia`, transferencia, {
      headers: this.cabecalho,
    });
  }

  buscarFiltros() {
    return this.http.get(this.url_acesso + `cadastro/situacao/${this.filial}`, {
      headers: this.cabecalho,
    });
  }
}
