import { Pipe, PipeTransform } from "@angular/core"
import { concat } from "rxjs";
import { ElogioPenalidade } from "./elogios-e-penalidades.component";

@Pipe({name : 'filtrarElogioEPenalidade'})
export class filtrarElogioEPenalidade implements PipeTransform{
    // transform(elogioEPenalidade : ElogioPenalidade[], filtrarVerba: string) {

    //     var filtrarElogioEPenalidade = filtrarVerba

    //     if (filtrarElogioEPenalidade == "") {
    //         return elogioEPenalidade;

    //     }else{
    //         elogioEPenalidade = elogioEPenalidade.filter(objeto => objeto.Autor.toLowerCase().includes(filtrarElogioEPenalidade) || String(objeto.Data).includes(filtrarElogioEPenalidade) || objeto.Motivo.toLowerCase().includes(filtrarElogioEPenalidade) || objeto.Tipo.toLowerCase().includes(filtrarElogioEPenalidade.toLowerCase()))
    //         return elogioEPenalidade  
    //     }
    // }
    transform(elogioEPenalidade : ElogioPenalidade[], filtrarElogioEPenalidade: string) {
        if (filtrarElogioEPenalidade != "") {
            elogioEPenalidade = elogioEPenalidade.filter(objeto => objeto.Autor.toLowerCase().includes(filtrarElogioEPenalidade) || String(objeto.Data).includes(filtrarElogioEPenalidade) || objeto.Motivo.toLowerCase().includes(filtrarElogioEPenalidade) || objeto.Tipo.toLowerCase().includes(filtrarElogioEPenalidade.toLowerCase()));
            return elogioEPenalidade

        } else {
            return elogioEPenalidade
        }
    }
}