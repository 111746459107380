import { Component, Inject } from '@angular/core';
import { ProcessosComponent } from '../processos.component';

@Component({
  selector: 'app-processos-cadastro',
  templateUrl: './processos-cadastro.component.html',
  styleUrls: ['./processos-cadastro.component.css'],
})
export class ProcessosCadastroComponent {
  constructor(@Inject(ProcessosComponent) public parent: ProcessosComponent) {}
  ngOnInit(){
    console.log(this.parent.formProcessos)
  }
  
}
