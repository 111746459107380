import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Comissao } from './comissao.component';

@Injectable({
  providedIn: 'root',
})
export class ComissaoService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  url_acesso: string;
  cabecalho: any;
  filial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl() + 'comissao';
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  buscarListaComissao(numOrd: number) {
    return this.http.get(`${this.url_acesso}/listar/${numOrd}`, {
      headers: this.cabecalho,
    });
  }

  buscarClassificacao() {
    return this.http.get(
      `${this.acesso.validarUrl()}falta-ao-trabalho/listar-id-classificacao-ato`,
      {
        headers: this.cabecalho,
      }
    );
  }
  buscarCargo() {
    return this.http.get(`${this.url_acesso}/cargos/listar`, {
      headers: this.cabecalho,
    });
  }

  downloadPdf(empfil: string, matricula: number, tipo: string) {
    return this.http.get(
      `${this.acesso.validarUrl()}relatorios-pdf/download-pdf/${empfil}/${matricula}/${tipo}`,
      {
        headers: this.cabecalho,
      }
    );
  }

  buscarProjetos() {
    return this.http.get(`${this.url_acesso}/projetos/listar`, {
      headers: this.cabecalho,
    });
  }
  buscarReferencia(codCar: string) {
    return this.http.get(`${this.url_acesso}/referencias/listar/${codCar}`, {
      headers: this.cabecalho,
    });
  }

  cadastrarComissao(comissao: Comissao) {
    return this.http.post(`${this.url_acesso}/incluir`, comissao, {
      headers: this.cabecalho,
    });
  }

  atualizarComissao(comissao: Comissao) {
    return this.http.put(`${this.url_acesso}/atualizar`, comissao, {
      headers: this.cabecalho,
    });
  }

  excluirComissao(seq: number, numOrd: string) {
    return this.http.delete(`${this.url_acesso}/excluir/${seq}/${numOrd}`, {
      headers: this.cabecalho,
    });
  }

  formatarDataBarra(data: string) {
    const dataSplit = data.split('-');

    return dataSplit[2] + '/' + dataSplit[1] + '/' + dataSplit[0];
  }

  formatarDataHifen(data: string): string {
    let ano = data.slice(0, 4);
    let mes = data.slice(4, 6);
    let dia = data.slice(6, 8);

    return `${ano}-${mes}-${dia}`;
  }

  formatarDataCompObjeto(dataInput: string) {
    const mesComp = dataInput.substring(0, 2);
    const anoComp = dataInput.substring(2, 7);
    return anoComp + mesComp;
  }
}
