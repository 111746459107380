import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { Item } from './licenca-premio-cadastro/licenca-premio-cadastro.component';

@Injectable({
  providedIn: 'root',
})
export class LicencaPremioService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  url_acesso: string;
  cabecalho: any;
  filial: string;

  listaIdClassificacaoAto: Item[] = [];
  listaObservacoes: Item[] = [];
  listaCargosFuncoes: Item[] = [];

  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl();

    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);

      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  buscarColaboradorMatricula(matricula: string) {
    return this.http.get(`${this.url_acesso}cadastro-todos-matricula/${matricula}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarLicenca(matricula: number) {
    return this.http.get(`${this.url_acesso}licenca-premio/listar/${this.filial}/${matricula}`, {
      headers: this.cabecalho,
    });
  }

  deletarLicenca(empfil: string, matricula: number, Seq: number) {
    return this.http.delete(
      `${this.url_acesso}licenca-premio/excluir/${empfil}/${matricula}/${Seq}`,
      { headers: this.cabecalho }
    );
  }

  inserirLicenca(promocao: any) {
    return this.http.post(`${this.url_acesso}licenca-premio/incluir`, promocao, {
      headers: this.cabecalho,
    });
  }

  inserirLista(listaNome: string, lista: Array<Item>) {
    if (listaNome == 'buscarIdClassificacaoAto') {
      this.listaIdClassificacaoAto = lista;
    }
  }

  retornarLista(listaNome: string) {
    if (listaNome == 'buscarIdClassificacaoAto') {
      return this.listaIdClassificacaoAto;
    }
  }

  downloadPdf(empfil: string, matricula: number, tipo: string) {
    return this.http.get(
      `${this.url_acesso}relatorios-pdf/download-pdf/${empfil}/${matricula}/${tipo}`,
      {
        headers: this.cabecalho,
      }
    );
  }

  atualizarLicenca(Licenca: any) {
    console.log('payload', Licenca);
    return this.http.put(`${this.url_acesso}licenca-premio/atualizar`, Licenca, {
      headers: this.cabecalho,
    });
  }

  buscarIdClassificacaoAto() {
    return this.http.get(`${this.url_acesso}falta-ao-trabalho/listar-id-classificacao-ato`, {
      headers: this.cabecalho,
    });
  }

  formatarDataBarra(data: string): string {
    if (!data || data.length !== 8) return data;
    const ano = data.substring(0, 4);
    const mes = data.substring(4, 6);
    const dia = data.substring(6, 8);

    return `${dia}/${mes}/${ano}`;
  }

  buscarFormasQuitacao() {
    return this.http.get(`${this.url_acesso}licenca-premio/formas-quitacao/listar`, {
      headers: this.cabecalho,
    });
  }

  buscarTipoProcessamento() {
    return this.http.get(`${this.url_acesso}tipoProcessamento/${this.filial}`, {
      headers: this.cabecalho,
    });
  }
}
