import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TransitionCheckState } from '@angular/material/checkbox';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { ErroService } from 'src/app/service/erros/erro.service';
import { Saude } from '../caracteristicas-pessoais/caracteristicas-pessoais.component';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { SaudeService } from './saude.service';

@Component({
  selector: 'app-saude',
  templateUrl: './saude.component.html',
  styleUrls: ['./saude.component.css']
})


export class SaudeComponent implements OnInit {
  localStorage: LocalStorage;

  formSaude: FormGroup;

  listaComorbidade: Saude[] = [];
  listaTipoDeficiencia: any[] = [];
  listaSimOuNao: any[] = [true, false];

  rotaMatricula: string = "";
  cod: any = ''

  mostrarSaude: boolean = false;
  editandoSaude: boolean = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, public validaErro: ErroService, public spinner: NgxSpinnerService, private saudeService: SaudeService) {
    this.route.params.subscribe(params => this.rotaMatricula = params['matricula']);
  }

  ngOnInit(): void {
    this.formSaude = this.fb.group({
      tipoDeficiencia: ['', [Validators.required]],
      reabilitado: ['', [Validators.required]],
      readaptado: ['', [Validators.required]],
      dataInicial: ["00-00-0000"],
      dataFinal: ['00-00-0000'],
      preencherCota: ['', [Validators.required]],
      descricaoComorbidade: ['', [Validators.required, Validators.maxLength(255)]],
      descricao: [''],
    });

    this.buscaMatricula(this.rotaMatricula);
  }

  //METODO DE BUSCA
  buscarTipoComorbidade() {
    this.spinner.show()
    this.saudeService.buscarTipoComorbidade().subscribe(
      (data: any[]) => {
        this.listaTipoDeficiencia = data;
        this.spinner.hide();
      },
      error => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    )
  }

  buscarCormorbidade() {
    this.spinner.show();
    this.saudeService.buscarComorbidade(this.rotaMatricula).subscribe((data: []) => {
      this.listaComorbidade = data;
      this.spinner.hide();
    },
    error => {
      this.validaErro.retornoErro(error);
      this.spinner.hide();
    })
  }

  buscaMatricula(matricula): void {
    this.spinner.show()
    this.saudeService.buscarColaboradorMatricula(matricula)
      .subscribe(
        () => {
          this.buscarCormorbidade();
          this.buscarTipoComorbidade();
          this.spinner.hide();
        },
        () => {
          new AlertaModal("erro", "Colaborador Inexistente!", 'Colaborador pesquisado inexitente.', 'p');
          this.spinner.hide();
        }
      );
  }

  // SAÚDE
  montarSaude(saude): Saude {
    return {
      TipoDeficiencia: {
        Codigo: saude.tipoDeficiencia,
      },
      Reabilitado: saude.reabilitado,
      Readaptado: saude.readaptado,
      DataInicial: this.formatarDataEnvio(saude.dataInicial),
      DataFinal: this.formatarDataEnvio(saude.dataFinal),
      PreencherCota: saude.preencherCota,
      DescricaoComorbidade: saude.descricaoComorbidade,
    }
  }

  salvarSaude() {
    if (this.formSaude.valid) {
      this.spinner.show();
      this.saudeService.inserirComorbidade(this.rotaMatricula, this.montarSaude(this.formSaude.value)).subscribe(
        () => {
          new AlertaModal("sucesso", "Sucesso!", 'Operação realizada com sucesso.', 'p')
          this.limparCamposSaude();
          this.buscarCormorbidade();
          this.spinner.hide();
        },
        error => {
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        }
      )
    }
    else {
      new AlertaModal("erro", "Preencha todos os campos!", 'Por favor, preencha todos os campos obrigatórios.', 'p');
    }
  }

  editarSaude(saude: Saude){
    this.formSaude.get('tipoDeficiencia').setValue(saude.TipoDeficiencia.Codigo);
    this.formSaude.get('reabilitado').setValue(saude.Reabilitado);
    this.formSaude.get('readaptado').setValue(saude.Readaptado);
    this.formSaude.get('dataInicial').setValue(this.formatarDataLista(saude.DataInicial,true));
    this.formSaude.get('dataFinal').setValue(this.formatarDataLista(saude.DataFinal,true));
    this.formSaude.get('preencherCota').setValue(saude.PreencherCota);
    this.formSaude.get('descricaoComorbidade').setValue(saude.DescricaoComorbidade);
    this.formSaude.get('descricao').setValue(saude.TipoDeficiencia.Descricao);
    this.formSaude.get('descricao').disable()
    scroll(0,0);
    // this.formSaude.get('tipoDeficiencia').disable();

    this.editandoSaude = true;
  }

  alteraSaude(){
    this.spinner.show();
    this.saudeService.atualizarComorbidae(this.rotaMatricula,this.formSaude.get('tipoDeficiencia').value,this.montarSaude(this.formSaude.value)).subscribe(()=>{
      new AlertaModal("sucesso", "Sucesso!", 'Operação realizada com sucesso.', 'p');
      this.buscarCormorbidade();
      this.limparCamposSaude()
      this.spinner.hide();
    },
    error => {
      this.validaErro.retornoErro(error);
      this.spinner.hide();
    })
  }

  deletarComorbidade(codComor, matricula) {
    this.spinner.show();
    this.saudeService.deletarComorbidade(matricula, codComor).subscribe(() => {
      new AlertaModal("sucesso", "Sucesso!", 'Operação realizada com sucesso.', 'p');
      this.buscarCormorbidade();
      this.spinner.hide();
    },
      error => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      })
  }

  // UTILITÁRIOS
  habilitarLimparSaude(): boolean {
    if (
      this.formSaude.get('tipoDeficiencia').valid ||
      this.formSaude.get('reabilitado').valid ||
      this.formSaude.get('readaptado').valid ||
      this.formSaude.get('readaptado').valid ||
      this.formSaude.get('preencherCota').valid ||
      this.formSaude.get('descricaoComorbidade').valid
    ) {
      return true;
    }
    return false;
  }

  limparCamposSaude(): void {
    this.formSaude.get('tipoDeficiencia').setValue("");
    this.formSaude.get('reabilitado').setValue("");
    this.formSaude.get('readaptado').setValue("");
    this.formSaude.get('readaptado').setValue("");
    this.formSaude.get('dataInicial').setValue("00-00-000");
    this.formSaude.get('dataFinal').setValue("00-00-0000");
    this.formSaude.get('preencherCota').setValue("");
    this.formSaude.get('descricaoComorbidade').setValue("");
    this.editandoSaude = false
    this.formSaude.get('tipoDeficiencia').enable();
  }

  formatarDataEnvio(data: string) {
    let aux = data.split('-')
    return `${aux[0]}${aux[1]}${aux[2]}`
  }

  formatarDataLista(data: string, form: boolean) {
    
    let ano = data.substring(0, 4);
    let mes = data.substring(4, 6);
    let dia = data.substring(6, 8);
    if (form) {
      return `${ano}-${mes}-${dia}`;
    }
    return `${dia}/${mes}/${ano}`;
  }

  validarDataFinal() {
    let final = this.formSaude.get('dataFinal').value;
    let inicio = this.formSaude.get('dataInicial').value;
    if (Number(this.formatarDataEnvio(final)) < Number(this.formatarDataEnvio(inicio))) {
      new AlertaModal("erro", "Data final invalida!", 'A data final deve ser maior que a data inicial.', 'p');
      this.formSaude.get('dataFinal').setValue("00-00-0000");
    }
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }
}


