import { FeriasPerfilService } from './ferias-perfil.service';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ComponentFactoryResolver,
} from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ErroService } from 'src/app/service/erros/erro.service';
import Swal from 'sweetalert';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { Data } from 'src/app/service/interfaces/Data';
import { Parametro } from 'src/app/service/interfaces/Parametro';
import { RetornoFerias, Periodo } from 'src/app/service/interfaces/RetornoFerias';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Component({
  selector: 'app-ferias-perfil',
  templateUrl: './ferias-perfil.component.html',
  styleUrls: ['./ferias-perfil.component.css'],
})
export class FeriasPerfilComponent implements OnInit {
  @Input() cadastroUsuario: Cadastro;
  @Input() ColaboradorInativo: boolean;

  localStorage: LocalStorage;

  formSolicitarFerias: FormGroup;

  listaClassificacao: Parametro[];
  listarFerias: RetornoFerias[] = null;
  tipoFerias?: TipoFerias[] = [];
  parcelasList: Periodo[] = [];

  perfilFerias: RetornoFerias;
  Ferias: RetornoFerias;
  data: any;
  tipoUsuario: string;
  diasRestantes: number;
  index: number = 0;
  saldoFerias: number = 0;
  somaDiasFerias: number = 0;

  aparecerMotivo: boolean = false;
  temErro: boolean;
  btSolicitarFerias: boolean = false;
  solicitarFerias: boolean = true;
  feriasFormDecimoValid = false;
  loading: boolean = false;
  clientePublico: boolean;
  modalAux: boolean = true;
  diasDireito: number;
  //Variaveis Criadas aqui são temporarias e para entrega refatorar e vferificar se precisa
  btValidaparcela: boolean = false; // vaariavel para limitar parcelas até 3 desabilita botão
  blockBotao: boolean = false; // vaariavel para desabilitar o solicitar
  clickOlhinho: boolean = false;
  isDisableSolicitar: boolean = true;
  isInsertFerias: boolean = false;
  mostrarSolicitar: boolean = true;
  feriasDadosCollapse: boolean = false;
  feriasSelecionada: any;

  constructor(
    private feriasPerfilService: FeriasPerfilService,
    private fb: FormBuilder,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService
  ) {
    this.localStorage = this.decryptLocalStorage();
    this.tipoUsuario = this.localStorage.TipoUsuario;
    this.clientePublico = this.localStorage.ClientePublico === true;
  }

  ngOnInit() {
    this.formSolicitarFerias = this.fb.group({
      // codigoSituacao: [''],
      venderDias: ['', [Validators.required]],
      adiantar13: ['', [Validators.required]],
      diasFerias: ['', [Validators.required, Validators.max(30), Validators.min(5)]],
      parcela: ['', Validators.compose([Validators.required])],
      inicioFerias: ['', [Validators.required]],
      fimFerias: ['', [Validators.required, dataValidator]],
      classificacao: [''],
      numeroAto: [''],
      //ano: [''],
      dataAto: ['', dataValidator],
      numeroDiarioOficial: [''],
      dataDiarioOficial: [''],
      descricao: [''],
    });
    this.buscarHistoricoFerias();
    // this.buscarFerias();
    this.buscarClassificacao();
    this.buscarTipoFerias();
  }

  exibeDadosFerias() {
    this.feriasDadosCollapse = !this.feriasDadosCollapse;
  }

  //Buscas
  buscarHistoricoFerias(): void {
    this.loading = true;
    this.feriasPerfilService
      .buscarHistoricoFerias(
        this.cadastroUsuario.Matricula,
        this.cadastroUsuario.InformacoesBasicas.Cargo.Codigo
      )
      .subscribe(
        (data) => {
          this.listarFerias = JSON.parse(JSON.stringify(data));
          this.btSolicitarFerias =
            this.listarFerias.filter((ferias) => parseInt(ferias.Parcelas[0].Status.Codigo) == 12)
              .length > 0
              ? false
              : true;
          this.loading = false;
          this.ogarnizarPerfilFerias();
        },
        (error) => {
          this.validaErro.retornoErro(error);
        }
      );
  }

  // buscarFerias(): void {
  //   this.loading = true;

  //   this.feriasPerfilService.buscarFerias(this.cadastroUsuario.Matricula, this.cadastroUsuario.InformacoesBasicas.Cargo.Codigo).subscribe(
  //     (data) => {
  //       this.perfilFerias = JSON.parse(JSON.stringify(data));
  //       this.diasDireito = this.perfilFerias.DiasDireito;
  //       this.loading = false;
  //     },
  //     (error) => {
  //       this.loading = false;
  //       this.validaErro.retornoErro(error);
  //     }
  //   );
  // }

  ogarnizarPerfilFerias() {
    this.listarFerias.filter((ferias) => {
      ferias.Parcelas.map((parcelas) => {
        if (parseInt(parcelas.Status.Codigo) == 12) {
          this.perfilFerias = ferias;
          this.diasDireito = this.perfilFerias.DiasDireito;
          this.loading = false;
        }
      });
    });
  }

  buscarTipoFerias(): void {
    this.loading = true;
    this.feriasPerfilService.buscarTipoFerias().subscribe(
      (data: TipoFerias) => {
        this.tipoFerias = JSON.parse(JSON.stringify(data));
        this.loading = false;
        this.validarClientePublico();
      },
      (error) => {
        this.loading = false;
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarClassificacao(): void {
    this.feriasPerfilService.buscarClassificacao().subscribe(
      (data: Parametro[]) => (this.listaClassificacao = data),
      (error) => this.validaErro.retornoErro(error)
    );
  }

  validarData(): boolean {
    var indexAnterior = this.parcelasList.length - 2;
    var indexAtual = this.parcelasList.length - 1;
    if (
      Number(this.parcelasList[indexAtual].Inicio.SetData) <=
      Number(this.parcelasList[indexAnterior].Fim.SetData)
    ) {
      return true;
    } else {
      return false;
    }
  }

  validaDiasParcelas(): void {
    const { inicioFerias, diasFerias } = this.formSolicitarFerias.getRawValue();
    if (
      inicioFerias === undefined ||
      inicioFerias === '' ||
      diasFerias === undefined ||
      diasFerias === ''
    ) {
      Swal('Parcelas Inválidas.', 'Favor, inserir datas válidas.', 'warning');
    } else {
      this.parcelasList.push(this.MontarListaParcelaAlterar());

      if (this.parcelasList.length == 1) {
        this.validarUmaParcela();
      } else if (this.parcelasList.length == 2 || this.parcelasList.length == 3) {
        if (this.validaParcelasInicial()) {
          this.processaParcelas();
        }
      }
      /*else if (this.parcelasList.length == 3) {
        this.validarTresParcelas()
      }*/
    }
  }

  processaParcelas() {
    let situacaoSaldo = this.existeSaldoFerias();

    if (situacaoSaldo === 'ZERADO') {
      if (!this.temQuatorze()) {
        this.deletarParcelas();
        Swal('Parcelas Inválidas.', 'Uma das parcelas deve ter no minimo 14 dias.', 'warning');
        this.limparCamposDataFerias();
        this.isDisableSolicitar = true;
        this.btValidaparcela = false;
      } else {
        //this.habilitarSolicitar();
        this.limparCamposDataFerias();
        this.formSolicitarFerias.get('diasFerias').clearValidators();
        this.formSolicitarFerias.get('diasFerias').updateValueAndValidity();
        this.formSolicitarFerias.get('fimFerias').clearValidators();
        this.formSolicitarFerias.get('fimFerias').updateValueAndValidity();
        this.formSolicitarFerias.get('inicioFerias').clearValidators();
        this.formSolicitarFerias.get('inicioFerias').updateValueAndValidity();
        Swal('Sucesso.', 'Todas as parcelas Inseridas.', 'success');
        this.isDisableSolicitar = false;
        this.btValidaparcela = true;
      }
    } else if (situacaoSaldo === 'MAIORQUEZERO') {
      //this.habilitarSolicitar();
      this.limparCamposDataFerias();
      Swal('Sucesso.', 'Todas as parcelas Inseridas.', 'success');
      this.isDisableSolicitar = true;
      this.btValidaparcela = false;
    } else if (situacaoSaldo === 'MENORQUEZERO') {
      this.deletarParcela(this.parcelasList.length - 1);
      Swal('Parcelas Inválidas.', 'Número de dias excedido.', 'warning');
      this.limparCamposDataFerias();
      this.isDisableSolicitar = true;
      this.btValidaparcela = false;
    }
  }

  validaParcelasInicial(): boolean {
    if (this.validarData()) {
      this.deletarParcela(this.parcelasList.length - 1);
      Swal(
        'Parcelas Inválidas.',
        'Data inicial não pode ser menor ou igual que a data Final da parcela anterior.',
        'warning'
      );
      this.limparCamposDataFerias();
      this.isDisableSolicitar = true;
      this.btValidaparcela = false;
      return false;
    }
    return true;
  }

  validarUmaParcela() {
    if (this.somarDias() == this.somarDiasParcelados() && this.temQuatorze()) {
      this.parcelasList = [];
      Swal('Parcelas Invalidas.', 'Não pode inserir todos os dias em uma única parcela', 'warning');
      this.limparCamposDataFerias();
    } else {
      this.limparCamposDataFerias();
    }
  }

  /*validarDuasParcelas() {
    if (this.validarData()) {
      this.blockBotao = true
      this.deletarParcela(this.parcelasList.length - 1);
      Swal("Parcelas Inválidas.", "Data inicial não pode ser menor que a data Final da parcela anterior.", 'warning');
      this.limparCamposDataFerias();
    } else if (this.verificaSaldoFerias()) {
      this.blockBotao = true
      this.deletarParcela(this.parcelasList.length - 1);
      Swal("Parcelas Inválidas.", "Número de dias excedido.", 'warning')
      this.limparCamposDataFerias()
    } else if (!this.verificaSaldoFerias()) {
      if (this.temQuatorze()) {
        this.btValidaparcela = true
        this.blockBotao = false
        this.habilitarSolicitar()
        Swal("Sucesso.", "Todas as parcelas Inseridas.", 'success')
      } else {
        this.blockBotao = true
        this.deletarParcela(this.parcelasList.length - 1);
        Swal("Parcelas Inválidas.", "Uma das parcelas deve ter no minimo 14 dias.", 'warning')
        this.limparCamposDataFerias()
      }
    } else {
      this.limparCamposDataFerias()
    }

  }*/

  verificarAtoObrigatorio(): void {
    const { classificacao, numeroAto, dataAto } = this.formSolicitarFerias.value;
    this.setarAtoObrigatorio();
    /*if (classificacao == '' && numeroAto === '' && dataAto === '') this.setarAtoOpcional();
    else this.setarAtoObrigatorio();
    this.atualizarValidacaoAto();*/
  }

  setarAtoObrigatorio(): void {
    this.formSolicitarFerias.get('classificacao').setValidators(Validators.required);
    this.formSolicitarFerias.get('numeroAto').setValidators(Validators.required);
    this.formSolicitarFerias.get('dataAto').setValidators([Validators.required, dataValidator]);
  }

  atualizarValidacaoAto(): void {
    this.formSolicitarFerias.get('classificacao').updateValueAndValidity();
    this.formSolicitarFerias.get('numeroAto').updateValueAndValidity();
    this.formSolicitarFerias.get('dataAto').updateValueAndValidity();
  }

  setarAtoOpcional(): void {
    this.formSolicitarFerias.get('classificacao').setValidators(null);
    this.formSolicitarFerias.get('numeroAto').setValidators(null);
    this.formSolicitarFerias.get('dataAto').setValidators(dataValidator);
  }

  /*validarTresParcelas() {
    if (this.validarData()) {
      this.blockBotao = true
      this.parcelasList = []
      Swal("Parcelas Inválidas.", "Data inicial não pode ser menor que a data final da parcela anterior", 'warning')
      this.limparCamposDataFerias()
    } else if ((this.somarDias() == this.somarDiasParcelados()) && this.temQuatorze()) {
      this.btValidaparcela = true
      Swal("Sucesso.", "Todas as parcelas Inseridas", 'success')
      this.habilitarSolicitar()
      this.blockBotao = false
    } else if (this.excedeDias()) {
      this.blockBotao = true
      this.parcelasList = []
      Swal("Parcelas Inválidas.", "Número de dias excedido", 'warning')
      this.limparCamposDataFerias()
    } else {
      this.blockBotao = true
      this.parcelasList = []
      Swal("Parcelas Inválidas.", "As parcela não atendem aos requesitos ", 'warning')
      this.limparCamposDataFerias()
    }
  }*/

  verificarDiarioOficialObrigatorio(): void {
    const { numeroDiarioOficial, dataDiarioOficial } = this.formSolicitarFerias.value;
    this.setarDiarioOficialObrigatorio();
    /*if (numeroDiarioOficial == '' && dataDiarioOficial === '') this.setarDiarioOficialOpcional();
    else this.setarDiarioOficialObrigatorio();*/
  }

  setarDiarioOficialObrigatorio(): void {
    this.formSolicitarFerias.get('numeroDiarioOficial').setValidators(Validators.required);
    this.formSolicitarFerias
      .get('dataDiarioOficial')
      .setValidators([Validators.required, dataValidator]);
    this.formSolicitarFerias.get('numeroDiarioOficial').updateValueAndValidity();
    this.formSolicitarFerias.get('dataDiarioOficial').updateValueAndValidity();
  }

  setarDiarioOficialOpcional(): void {
    this.formSolicitarFerias.get('numeroDiarioOficial').setValidators(null);
    this.formSolicitarFerias.get('dataDiarioOficial').setValidators(dataValidator);
    this.formSolicitarFerias.get('numeroDiarioOficial').updateValueAndValidity();
    this.formSolicitarFerias.get('dataDiarioOficial').updateValueAndValidity();
  }

  deletarParcela(index: number) {
    this.parcelasList.splice(index, 1);
    //this.parcelasList[index - 1].DiasRestantes = this.parcelasList[index - 1].DiasRestantes + this.parcelasList[index].DiasRestantes
  }

  deletarParcelas() {
    let index = 0;
    this.parcelasList.forEach(function (parcela) {
      if (parcela.Status.Codigo !== '3' && parcela.Status.Codigo !== '16') {
        this.parcelasList.splice(index, 1);
      }
      index++;
    });
  }

  excedeDias(): boolean {
    return this.somarDias() > this.somarDiasParcelados() ? true : false;
  }

  temQuatorze(): boolean {
    var retorno = false;
    this.parcelasList.map((valor) => {
      if (valor.Dias >= 14) {
        retorno = true;
      }
    });
    return retorno;
  }

  validarTipoFerias(): void {
    var auxiliarVenderDias = this.formSolicitarFerias.get('venderDias').value;
    if (auxiliarVenderDias != '') {
      auxiliarAdiantar13 == 'true' ? (auxiliarVenderDias = '10') : (auxiliarVenderDias = '0');
    }
    var auxiliarAdiantar13 = this.formSolicitarFerias.get('adiantar13').value;
    if (auxiliarAdiantar13 != '') {
      auxiliarAdiantar13 == 'true' ? (auxiliarAdiantar13 = '1') : (auxiliarAdiantar13 = '0');
    }
    if (auxiliarVenderDias != '' && auxiliarAdiantar13 != '') {
      if (
        !this.tipoFerias.find(
          (tipo) => tipo.Abono == auxiliarVenderDias && tipo.Adiantar13 == auxiliarAdiantar13
        )
      ) {
        this.Ferias = null;
        this.limparCampos();
        Swal(
          'Tipo de Ferias Invalido.',
          'Não é possivel solicitar essa combinação de vender dias e adiantar 13º Salário para esta empresa.',
          'warning'
        );
      }
    }
  }

  guardarParcela(index: number): Periodo {
    const { diasFerias, inicioFerias, fimFerias } = this.formSolicitarFerias.getRawValue();

    return {
      Dias: parseInt(diasFerias),
      Fim: new Datas(fimFerias),
      Inicio: new Datas(this.formatarDataEnvio(inicioFerias)),
      DiasRestantes:
        this.parcelasList.length == 0
          ? 30 - Number(diasFerias)
          : this.parcelasList[index].DiasRestantes - Number(diasFerias),
      Editavel: false,
    };
  }

  validarClientePublico(): void {
    if (this.clientePublico) {
      this.formSolicitarFerias.get('numeroDiarioOficial').setValidators(Validators.required);
      this.formSolicitarFerias.get('numeroDiarioOficial').updateValueAndValidity();
      this.formSolicitarFerias
        .get('dataDiarioOficial')
        .setValidators([Validators.required, dataValidator]);
      this.formSolicitarFerias.get('dataDiarioOficial').updateValueAndValidity();
      this.formSolicitarFerias.get('classificacao').setValidators(Validators.required);
      this.formSolicitarFerias.get('classificacao').updateValueAndValidity();
      this.formSolicitarFerias.get('numeroAto').setValidators(Validators.required);
      this.formSolicitarFerias.get('numeroAto').updateValueAndValidity();
      //this.formSolicitarFerias.get('ano').setValidators(Validators.required);
      //this.formSolicitarFerias.get('ano').updateValueAndValidity();
      this.formSolicitarFerias.get('dataAto').setValidators([Validators.required, dataValidator]);
      this.formSolicitarFerias.get('dataAto').updateValueAndValidity();
    }
  }

  MontarListaParcela(): Periodo {
    const { diasFerias, inicioFerias, fimFerias } = this.formSolicitarFerias.getRawValue();
    return {
      CodigoFeriasRejeitadas: 0,
      MotivoRejeicao: '',
      Competencia: '',
      CompetenciaAtualizacao: '',
      Status: {
        Codigo: '14',
        Descricao: 'PENDENTE',
        Tipo: '',
      },
      Dias: parseInt(diasFerias),
      Inicio: new Datas(this.formatarDataEnvio(inicioFerias)),
      Fim: new Datas(fimFerias),
      DiasRestantes:
        this.parcelasList.length == 0
          ? this.perfilFerias.DiasDireito - Number(diasFerias)
          : Number(this.somarDias()) - Number(this.somarDiasParcelados()) - Number(diasFerias),
      Editavel: false,
    };
  }

  MontarListaParcelaAlterar(): Periodo {
    const { diasFerias, inicioFerias, fimFerias } = this.formSolicitarFerias.getRawValue();
    return {
      CodigoFeriasRejeitadas: 0,
      MotivoRejeicao: '',
      Competencia: '',
      CompetenciaAtualizacao: '',
      Status: {
        Codigo: '14',
        Descricao: 'PENDENTE',
        Tipo: '',
      },
      Dias: parseInt(diasFerias),
      Inicio: new Datas(this.formatarDataEnvio(inicioFerias)),
      Fim: new Datas(fimFerias),
      DiasRestantes: 0, //this.parcelasList.length == 0 ? this.perfilFerias.DiasDireito - Number(diasFerias) : (Number(this.somarDias()) - Number(this.somarDiasParcelados())),
      Editavel: false,
    };
  }

  calculaData(): void {
    const dataInicioFerias = this.formSolicitarFerias.get('inicioFerias').value;
    if (dataInicioFerias.length == 10) {
      if (!this.verificaDataPassado()) {
        this.formSolicitarFerias.controls['inicioFerias'].setValue('');
        Swal('Data Inválida.', 'Data não pode ser menor ou igual a data atual.', 'warning');
        this.formSolicitarFerias.controls['fimFerias'].setValue('');
      } else {
        this.gerarDataFinal();
        this.habilitarSolicitar();
      }
    } else {
      this.formSolicitarFerias.controls['fimFerias'].setValue('');
    }
  }

  verificaDataPassado(): boolean {
    //verifica se a data inserida é anterior que a data atual
    var dia, mes, ano;
    var teste = 'T00:00:00';
    var data = this.formSolicitarFerias.get('inicioFerias').value;
    dia = data.substring(0, 2);
    mes = data.substring(2, 4);
    ano = data.substring(4, 8);
    var time = new Date(data + teste);
    let dateAtual: Date = new Date();
    if (dateAtual < time) return true;
    else return false;
  }

  gerarDataFinal(): void {
    const dataInicioFerias = this.formSolicitarFerias.get('inicioFerias').value;
    const dataFormatada = new Date(dataInicioFerias);

    dataFormatada.setDate(
      dataFormatada.getDate() + Number(this.formSolicitarFerias.get('diasFerias').value) + 1
    );

    let dia = dataFormatada.toLocaleDateString().substring(0, 2);
    let mes = dataFormatada.toLocaleDateString().substring(3, 5);
    let ano = dataFormatada.toLocaleDateString().substring(6, 10);
    // verificar como diminuir um dia da data passada.
    if (this.formSolicitarFerias.get('diasFerias').invalid)
      this.formSolicitarFerias.controls['fimFerias'].setValue('');
    else this.formSolicitarFerias.get('fimFerias').setValue(`${dia}/${mes}/${ano}`);
  }

  somarDiasParcelados(): number {
    var total = this.parcelasList.reduce(
      (accumulator, currentValue) => Number(accumulator) + Number(currentValue.Dias),
      0
    );

    return total;
  }

  somarDias(): number {
    if (this.formSolicitarFerias.get('venderDias').value === 'true') {
      return this.diasDireito - 10;
    } else {
      // verificar se ja tem alguma parcela com status diferente do aguardando
      // se tiver, deve somar o dias direito + o dia daquela parcela e retornar esse valor, para totalizar os 30 ou 20
      let diasDireito = this.diasDireito;
      let diasParcelados = this.somarDiasParcelados();
      this.parcelasList.forEach(function (parcela) {
        if (diasDireito < diasParcelados) {
          diasDireito = diasDireito + parcela.Dias;
        }
      });

      this.diasDireito = diasDireito;
      return this.diasDireito;
    }
  }

  existeSaldoFerias(): string {
    let diasLancados: number = 0;
    let diasDireito: number = 30;
    this.parcelasList.forEach(function (parcela) {
      diasLancados = diasLancados + parcela.Dias;
    });
    if (this.formSolicitarFerias.get('venderDias').value === 'true') {
      diasDireito = diasDireito - 10;
    }
    if (diasDireito - diasLancados === 0) {
      return 'ZERADO';
    } else if (diasDireito - diasLancados < 0) {
      return 'MENORQUEZERO';
    } else if (diasDireito - diasLancados > 0) {
      return 'MAIORQUEZERO';
    }
  }

  setarValidacaoDias() {
    this.limparCamposAoVender();
    if (this.formSolicitarFerias.get('venderDias').value === 'true') {
      this.formSolicitarFerias
        .get('diasFerias')
        .setValidators([Validators.required, Validators.max(20), Validators.min(5)]);
      this.formSolicitarFerias.get('diasFerias').updateValueAndValidity();
    } else {
      this.formSolicitarFerias
        .get('diasFerias')
        .setValidators([Validators.required, Validators.max(30), Validators.min(5)]);
      this.formSolicitarFerias.get('diasFerias').updateValueAndValidity();
    }
  }

  // linpar campos
  limparCamposAoVender(): void {
    this.btValidaparcela = false;
    this.limparCamposDataFerias();
    this.parcelasList = [];
    this.habilitarSolicitar();
  }

  habilitarSolicitar(): void {
    if (this.formSolicitarFerias.get('parcela').value === 'false') {
      let dias: number = this.diasDireito;
      if (this.formSolicitarFerias.get('venderDias').value === 'true') {
        dias = dias - 10;
      }
      if (
        dias - parseInt(this.formSolicitarFerias.get('diasFerias').value) == 0 &&
        !this.formSolicitarFerias.invalid
      ) {
        this.isDisableSolicitar = false;
      } else if (dias - parseInt(this.formSolicitarFerias.get('diasFerias').value) < 0) {
        this.isDisableSolicitar = true;
        Swal('Atenção', 'Quantidade de dias de férias MAIOR do que o permitido.', 'warning');
      } else if (dias - parseInt(this.formSolicitarFerias.get('diasFerias').value) > 0) {
        this.isDisableSolicitar = true;
        Swal('Atenção', 'Quantidade de dias de férias MENOR do que o permitido.', 'warning');
      } else {
        this.isDisableSolicitar = true;
        // this.isDisableSolicitar = false;
      }
    }
  }

  abrirModalFerias2(index: number): void {
    this.isInsertFerias = false;
    this.btValidaparcela = false;
    this.mostrarSolicitar = false;
    // const colaborador;
    this.feriasSelecionada = this.listarFerias[index];
    this.modalAux =
      ['12', '14'].filter((codigo) => codigo == this.feriasSelecionada.Parcelas[0].Status.Codigo)
        .length > 0;
    this.formSolicitarFerias.markAsPristine();
    this.perfilFerias = this.feriasSelecionada;
    // this.blockBotao = false;
    this.parcelasList = [];
    this.parcelasList = JSON.parse(JSON.stringify(this.feriasSelecionada.Parcelas));
    this.clickOlhinho = true;

    this.diasDireito = this.feriasSelecionada.DiasDireito;
    this.formSolicitarFerias.setValue({
      // codigoSituacao: colaborador.Parcelas[0].Status.Codigo,
      parcela: this.parcelasList.length > 1 ? true : false,
      classificacao: this.feriasSelecionada.Opcional.IdClassificacaoAto,
      numeroAto: this.feriasSelecionada.Opcional.NumeroAto,
      //ano: colaborador.Opcional.AnoAto,
      dataAto: this.feriasSelecionada.Opcional.DataAto.ToDate,
      numeroDiarioOficial: this.feriasSelecionada.Opcional.NumeroDoe,
      dataDiarioOficial: this.feriasSelecionada.Opcional.DataDoe.ToDate,
      venderDias: this.feriasSelecionada.Vender10Dias,
      adiantar13: this.feriasSelecionada.Adiantar13,
      diasFerias: this.feriasSelecionada.Parcelas[0].Dias,
      inicioFerias: `${this.feriasSelecionada.Parcelas[0].Inicio.SetData.substring(
        0,
        4
      )}-${this.feriasSelecionada.Parcelas[0].Inicio.SetData.substring(
        4,
        6
      )}-${this.feriasSelecionada.Parcelas[0].Inicio.SetData.substring(6, 8)}`,
      fimFerias: this.feriasSelecionada.Parcelas[0].Fim.ToDate,
      descricao: this.feriasSelecionada.Parcelas[0].MotivoRejeicao,
    });

    // Caso comente esse foreach/ ele não deleta da this.parcelas
    // mas ao enviar a solicitação ele envia com 1 parcela a +

    if (this.diasDireito == 0) {
      this.isDisableSolicitar = true;
      this.formSolicitarFerias.disable();
    } else if (this.diasDireito > 0) {
      this.formSolicitarFerias.controls['inicioFerias'].setValue('');
      this.formSolicitarFerias.controls['fimFerias'].setValue('');
      this.formSolicitarFerias.controls['diasFerias'].setValue('');
      this.formSolicitarFerias.controls['parcela'].setValue(
        this.parcelasList.length > 1 ? 'true' : 'false'
      );

      this.formSolicitarFerias.controls['parcela'].disable();
      this.formSolicitarFerias.controls['adiantar13'].disable();
      this.formSolicitarFerias.controls['venderDias'].disable();

      this.formSolicitarFerias.controls['inicioFerias'].enable;
      this.formSolicitarFerias.controls['diasFerias'].enable;

      this.formSolicitarFerias.controls['inicioFerias'].markAsPristine();
      this.formSolicitarFerias.controls['fimFerias'].markAsPristine();
      this.formSolicitarFerias.controls['diasFerias'].markAsPristine();

      this.btValidaparcela = false;
    }

    if (this.feriasSelecionada.DiasDireito === 0) {
      this.parcelasList.forEach((item, index) => {
        if (item.Status.Codigo == '12') this.parcelasList.splice(index, 1);
      });
    } else {
      this.parcelasList.forEach((item, index) => {
        if (item.Status.Codigo == '12' || item.Status.Codigo == '14')
          this.parcelasList.splice(index, 1);
      });
    }

    //colaborador.Parcelas[0].Status.Descricao == "PAGA" ? this.solicitarFerias = true : this.solicitarFerias = false
    this.aparecerMotivo =
      parseInt(this.feriasSelecionada.Parcelas[0].Status.Codigo) === 15 ? true : false;
  }

  abrirModalFerias(index: number): void {
    this.modalAux = false;
    this.blockBotao = false;
    this.feriasSelecionada = this.listarFerias[index];
    this.modalAux =
      ['12', '14'].filter((codigo) => codigo == this.feriasSelecionada.Parcelas[0].Status.Codigo)
        .length > 0;
    this.formSolicitarFerias.setValue({
      // codigoSituacao: feriasSelecionada.Parcelas[0].Status.Codigo,
      parcela: this.parcelasList.length > 1 ? true : false,
      classificacao: this.feriasSelecionada.Opcional.IdClassificacaoAto,
      numeroAto: this.feriasSelecionada.Opcional.NumeroAto,
      //ano: feriasSelecionada.Opcional.AnoAto,
      dataAto: this.feriasSelecionada.Opcional.DataAto.ToDate,
      numeroDiarioOficial: this.feriasSelecionada.Opcional.NumeroDoe,
      dataDiarioOficial: this.feriasSelecionada.Opcional.DataDoe.ToDate,
      venderDias: this.feriasSelecionada.Vender10Dias,
      adiantar13: this.feriasSelecionada.Adiantar13,
      diasFerias: this.feriasSelecionada.DiasDireito,
      inicioFerias: this.feriasSelecionada.Parcelas[0].Inicio.ToDate,
      fimFerias: this.feriasSelecionada.Parcelas[0].Fim.ToDate,
      descricao: this.feriasSelecionada.Parcelas[0].MotivoRejeicao,
    });
    this.mostrarSolicitar = true;
    if (this.feriasSelecionada.Parcelas[0].Status.Descricao == 'PENDENTE') {
      this.formSolicitarFerias.enable();
    } else {
      this.formSolicitarFerias.disable();
    }
    this.feriasSelecionada.Parcelas[index].Status.Descricao == 'PAGA'
      ? (this.solicitarFerias = true)
      : (this.solicitarFerias = false);
    this.aparecerMotivo =
      parseInt(this.feriasSelecionada.Parcelas[index].Status.Codigo) === 15 ? true : false;
  }

  submitFerias() {
    this.feriasFormDecimoValid = true;
    if (this.formSolicitarFerias.invalid) {
      this.temErro = true;
      Swal('Atenção', 'Preencha todos os campos obrigatórios', 'warning');
      window.scrollTo(0, 0);
    } else if (
      this.formSolicitarFerias.get('parcela').value !== 'true' &&
      Number(this.formSolicitarFerias.get('diasFerias').value) !== this.somarDias()
    ) {
      this.temErro = true;
      Swal('Atenção', 'Campo dias de Férias menor que o permitido ', 'warning');
      window.scrollTo(0, 0);
    } else {
      if (this.parcelasList.length == 0) {
        this.parcelasList.push(this.MontarListaParcela());
      }
      this.Ferias = this.montarFerias(this.index);
      this.Ferias.Parcelas = this.parcelasList;
      this.validarTipoFerias();
      this.spinner.show();
      this.temErro = false;
      this.feriasPerfilService
        .salvarFerias(this.Ferias) // passar o ferias que eu preenchi
        .subscribe(
          (data) => {
            if (data) {
              this.spinner.hide();
              document.getElementById('closeModalbt').click();
              Swal('Sucesso', 'A Solicitação de Férias foi enviada', 'success');
              this.limparCampos();
              this.perfilFerias.Parcelas = [];
              this.buscarHistoricoFerias();
            }
          },
          (error) => {
            this.limparCamposAoVender();
            this.Ferias.Parcelas = [];
            //O erro está aqui
            //this.perfilFerias.Parcelas = [];
            this.spinner.hide();
            this.validaErro.retornoErro(error);
          }
        );
    }
  }

  updateFerias() {
    this.feriasFormDecimoValid = true;
    if (this.formSolicitarFerias.invalid) {
      this.temErro = true;
      Swal('Atenção', 'Preencha todos os campos obrigatórios', 'warning');
      window.scrollTo(0, 0);
    } else if (
      this.formSolicitarFerias.get('parcela').value !== 'true' &&
      Number(this.formSolicitarFerias.get('diasFerias').value) !== this.somarDias()
    ) {
      this.temErro = true;
      Swal('Atenção', 'Campo dias de Férias menor que o permitido ', 'warning');
      window.scrollTo(0, 0);
    } else {
      if (this.parcelasList.length == 0) {
        this.parcelasList.push(this.MontarListaParcelaAlterar());
      }
      this.Ferias = this.montarFerias(this.index);
      this.Ferias.Parcelas = JSON.parse(JSON.stringify(this.parcelasList));
      this.validarTipoFerias();
      this.spinner.show();
      this.temErro = false;
      this.feriasPerfilService
        .alterarFerias(this.Ferias) // passar o ferias que eu preenchi
        .subscribe(
          (data) => {
            if (data) {
              this.spinner.hide();
              document.getElementById('closeModalbt').click();
              this.perfilFerias.TelaPerfil = true;
              Swal('Sucesso', 'A Solicitação de Alteração das Férias foi enviada', 'success');
              this.limparCampos();
              this.perfilFerias.Parcelas = [];
              this.buscarHistoricoFerias();
            }
          },
          (error) => {
            /*this.deletarParcelas();
            this.formSolicitarFerias.get('diasFerias').setValidators(Validators.required);
            this.formSolicitarFerias.get('diasFerias').updateValueAndValidity();
            this.formSolicitarFerias.get('fimFerias').setValidators(Validators.required);
            this.formSolicitarFerias.get('fimFerias').updateValueAndValidity();
            this.formSolicitarFerias.get('inicioFerias').setValidators(Validators.required);
            this.formSolicitarFerias.get('inicioFerias').updateValueAndValidity();
            this.isDisableSolicitar = true;
            this.btValidaparcela = false;*/
            //O erro está aqui
            //this.perfilFerias.Parcelas = [];
            this.limparCamposAoVender();
            this.Ferias.Parcelas = [];
            this.spinner.hide();
            this.validaErro.retornoErro(error);
            document.getElementById('closeModalbt').click();
          }
        );
    }
  }

  limparCamposDataFerias(): void {
    this.formSolicitarFerias.get('diasFerias').setValue('');
    this.formSolicitarFerias.get('fimFerias').setValue('');
    this.formSolicitarFerias.get('inicioFerias').setValue('');
    this.formSolicitarFerias.markAsPristine();
  }

  limparCampos(): void {
    this.blockBotao = false;
    this.btValidaparcela = false;
    this.formSolicitarFerias.setValue({
      // codigoSituacao: '',
      parcela: '',
      classificacao: '',
      numeroAto: '',
      //ano: '',
      dataAto: '',
      numeroDiarioOficial: '',
      dataDiarioOficial: '',
      venderDias: '',
      adiantar13: '',
      diasFerias: '',
      inicioFerias: '',
      fimFerias: '',
      descricao: '',
    });
    this.feriasFormDecimoValid = false;
    this.clickOlhinho = true;
    this.formSolicitarFerias.enable();
    this.formSolicitarFerias.markAsPristine();
    this.parcelasList = [];
    this.perfilFerias.TelaPerfil = false;
    this.aparecerMotivo = false;
    // this.solicitarFerias = false;
    this.modalAux = true;
    this.diasDireito = 30;
    // this.diasDireito = this.diasDireitoSolicitar;
    this.mostrarSolicitar = true;
  }

  limparCamposSolicitar(): void {
    this.blockBotao = false;
    this.btValidaparcela = false;
    this.formSolicitarFerias.setValue({
      // codigoSituacao: '',
      parcela: '',
      classificacao: '',
      numeroAto: '',
      //ano: '',
      dataAto: '',
      numeroDiarioOficial: '',
      dataDiarioOficial: '',
      venderDias: '',
      adiantar13: '',
      diasFerias: '',
      inicioFerias: '',
      fimFerias: '',
      descricao: '',
    });
    this.feriasFormDecimoValid = false;
    this.clickOlhinho = true;
    this.formSolicitarFerias.enable();
    this.formSolicitarFerias.markAsPristine();
    this.parcelasList = [];
    this.perfilFerias.TelaPerfil = false;
    this.aparecerMotivo = false;
    // this.solicitarFerias = false;
    this.modalAux = true;
    this.diasDireito = 30;
    this.isInsertFerias = true;
    // this.diasDireito = this.diasDireitoSolicitar;
  }

  carregarColaborador(colaborador: RetornoFerias): void {
    this.temErro = false;
    this.formSolicitarFerias.markAsPristine();
    this.perfilFerias = colaborador;
    console.log('colaboradores', colaborador);
    this.feriasPerfilService
      .buscarParcelas(
        colaborador.DadosColaborador.EmpresaFilial,
        colaborador.DadosColaborador.Matricula,
        colaborador.Vencimento.Inicio,
        colaborador.Vencimento.Fim,
        colaborador.Parcelas[0].Status.Codigo
      )
      .subscribe(
        (data: RetornoFerias) => {
          this.parcelasList = [];
          if (data.Parcelas.length > 1) {
            this.parcelasList = data.Parcelas;
          } else {
            this.perfilFerias.Parcelas = [];
            this.perfilFerias.Parcelas = data.Parcelas;
          }
          this.formSolicitarFerias.setValue({
            // codigoSituacao: colaborador.Parcelas[0].Status.Codigo,
            parcela: this.parcelasList.length > 0 ? '2' : '1',
            classificacao: colaborador.Opcional.IdClassificacaoAto,
            numeroAto: colaborador.Opcional.NumeroAto,
            //ano: colaborador.Opcional.AnoAto,
            dataAto: colaborador.Opcional.DataAto.ToDate,
            numeroDiarioOficial: colaborador.Opcional.NumeroDoe,
            dataDiarioOficial: colaborador.Opcional.DataDoe.ToDate,
            venderDias: colaborador.Vender10Dias,
            adiantar13: colaborador.Adiantar13,
            diasFerias: colaborador.DiasDireito,
            inicioFerias: colaborador.Parcelas[0].Inicio.ToDate,
            fimFerias: colaborador.Parcelas[0].Fim.ToDate,
            descricao: colaborador.Parcelas[0].MotivoRejeicao,
          });
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        }
      );
  }
  MontarParcela(index: number): Periodo {
    const { diasFerias, inicioFerias, fimFerias, descricao } =
      this.formSolicitarFerias.getRawValue();
    const feriasSelecionada = this.listarFerias[index];
    return {
      Dias: parseInt(diasFerias),
      Inicio: new Datas(this.formatarDataEnvio(inicioFerias)),
      Fim: new Datas(fimFerias),
      DiasRestantes:
        this.parcelasList.length == 0
          ? Number(diasFerias - this.diasDireito)
          : this.somarDiasRestantes(diasFerias),
      Status: feriasSelecionada.Parcelas[0].Status,
      Editavel: false,
    };
  }
  somarDiasRestantes(dias: number): number {
    const diasParcelados = this.somarDiasParcelados();
    return Number(diasParcelados) + Number(dias) - this.diasDireito;
  }

  montarFerias(index: number): RetornoFerias {
    const {
      adiantar13,
      venderDias,
      classificacao,
      numeroAto,
      dataAto,
      numeroDiarioOficial,
      dataDiarioOficial,
      parcela,
    } = this.formSolicitarFerias.getRawValue();
    return {
      Adiantar13: adiantar13,
      DiasDireito: this.perfilFerias.DiasDireito,
      DiasRestantes: this.guardarParcela(index).DiasRestantes,
      TipoFerias: this.perfilFerias.TipoFerias,
      TipoProcessamento: this.perfilFerias.TipoProcessamento,
      TelaPerfil: true,
      Faltas: this.perfilFerias.Faltas,
      Vender10Dias: venderDias,
      Parcelas: ([] = []),
      DadosColaborador: {
        EmpresaFilial: this.perfilFerias.DadosColaborador.EmpresaFilial,
        Matricula: this.perfilFerias.DadosColaborador.Matricula,
        NomeCargo: this.perfilFerias.DadosColaborador.NomeCargo,
        NomeColaborador: this.perfilFerias.DadosColaborador.NomeColaborador,
        NomeSetor: this.perfilFerias.DadosColaborador.NomeSetor,
      },
      Vencimento: {
        Fim: this.perfilFerias.Vencimento.Fim,
        Inicio: this.perfilFerias.Vencimento.Inicio,
      },
      Opcional: {
        AnoAto: dataAto.substr(4, 4),
        NumeroAto: numeroAto,
        NumeroDoe: numeroDiarioOficial,
        DataAto: dataAto ? new Datas(dataAto) : null,
        DataDoe: dataDiarioOficial ? new Datas(dataDiarioOficial) : null,
        AnoDoe: dataDiarioOficial.substr(4, 4),
        IdClassificacaoAto: classificacao,
      },
    };
  }

  formatarDataEnvio(data: string): string {
    let dataSplit = data.split('-');
    const dataFormatada = `${dataSplit[2]}${dataSplit[1]}${dataSplit[0]}`;
    return dataFormatada;
  }

  formatarData(data: string): string {
    let dataSplit = data.split('/');
    const dataFormatada = `${dataSplit[2]}-${dataSplit[1]}-${dataSplit[0]}`;
    return dataFormatada;
  }
  formatarDtComp(data: string) {
    const ano = data.slice(0, 4);
    const mes = data.slice(4, 6);

    return mes + '/' + ano;
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}
export interface Parcela {
  diasFerias: number;
  inicioFerias: Data;
  fimFerias: Data;
  diasRestantes: number;
}

export interface TipoFerias {
  Abono: number;
  Adiantar13: string;
}

export class Datas {
  SetData: string = '';
  ToDate?: string;
  IsDate?: boolean;
  Empty?: boolean;

  constructor(data: string = null) {
    if (data != null) {
      data = data.replace(/\//g, '');
      this.ToDate = this.dataComBarra(data);
      this.SetData = this.dataSemBarra(data);
    }
  }

  dataComBarra?(data: string): string {
    const dia = data.substr(0, 2);
    const mes = data.substr(2, 2);
    const ano = data.substr(4, 4);
    return `${dia}/${mes}/${ano}`;
  }

  dataSemBarra(data: string) {
    const dia = data.substr(0, 2);
    const mes = data.substr(2, 2);
    const ano = data.substr(4, 4);
    return ano + mes + dia;
  }
}
