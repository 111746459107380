import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { Aprendiz, Estagio } from '../colaboradores/colaboradores.component';
import { ComplementoCadastralService } from './complemento-cadastral.service';
import Swal from 'sweetalert';
// import {ContratosAprendiz} from './aprendiz/aprendiz.component';
import { ErroService } from 'src/app/service/erros/erro.service';
// import {Data} from 'src/app/service/interfaces/Data';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Cessao } from 'src/app/service/interfaces/Cessao';
import { ContratosCessao } from 'src/app/service/interfaces/ContratosCessao';
import { ContratoExperiencia } from 'src/app/service/interfaces/ContratoExperiencia';
import { Experiencia } from 'src/app/service/interfaces/Experiencia';
import { ContratosEstagio } from 'src/app/service/interfaces/ContratosEstagio';
import { ContratosAprendiz } from 'src/app/service/interfaces/ContratosAprendiz';
import { NgxSpinnerService } from 'ngx-spinner';
import { PesquisarMatriculaComponent } from '../componentes-funcionais/pesquisar-matricula/pesquisar-matricula.component';
import { ObservacoesFichaFuncionalComponent } from '../componentes-funcionais/observacoes-ficha-funcional/observacoes-ficha-funcional.component';
import { AnexoComponent } from '../anexo/anexo.component';

@Component({
  selector: 'app-complemento-cadastral',
  templateUrl: './complemento-cadastral.component.html',
  styleUrls: ['./complemento-cadastral.component.css'],
})
export class ComplementoCadastralComponent {
  @ViewChild(PesquisarMatriculaComponent) pesquisarMatriculaComponent: PesquisarMatriculaComponent;
  @ViewChild(ObservacoesFichaFuncionalComponent)
  modalObservacoes: ObservacoesFichaFuncionalComponent;
  @ViewChild(AnexoComponent) modalAnexo: AnexoComponent;

  matricula: string = null;
  loading: boolean = false;
  visualizacao: boolean = true;
  dataAtual: Date = new Date();
  dadosComplemento: boolean = false;
  tipoComplemento: string;
  formColaborador: FormGroup;
  colaboradorAtivo: Cadastro;
  listaAprendiz: ContratosAprendiz[] = [];
  listaEstagio: ContratosEstagio[] = [];
  listaCessao: ContratosCessao[] = [];
  listaContratoExperiencia: ContratoExperiencia[] = [];
  tipoObsSelecionado = '';
  tipoAnexoSelecionado = '';
  estagio: Estagio;
  aprendiz: Aprendiz;
  cessao: Cessao;
  contratoExperiencia: Experiencia;
  outrosContratos: number = 0;
  botaoNovoAprendiz: boolean = false;
  navigation: any;

  constructor(
    public fb: FormBuilder,
    private complementoCadastralService: ComplementoCadastralService,
    private erroService: ErroService,
    private route: Router,
    private rotaAtiva: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
    this.formColaborador = this.fb.group({
      matricula: ['', Validators.required],
      nome: [''],
    });

    const rota = this.rotaAtiva.snapshot.routeConfig.path;
    switch (true) {
      case rota.includes('cessao'):
        // Caso a rota inclua a string 'cessao'
        this.tipoComplemento = 'Cessão';
        break;

      case rota.includes('experiencia'):
        // Caso a rota inclua a string 'experiencia'
        this.tipoComplemento = 'Contrato de Experiência';
        break;

      case rota.includes('aprendiz'):
        // Caso a rota inclua a string 'aprendiz'
        this.tipoComplemento = 'Aprendiz';
        break;

      case rota.includes('estagio'):
        // Caso a rota inclua a string 'estagio'
        this.tipoComplemento = 'Estágio';
        break;

      default:
        // Caso nenhuma das condições seja atendida
        console.log('A rota não inclui nenhuma das strings especificadas');
        break;
    }
    console.log('tipo complemento', this.tipoComplemento);
    console.log('data atual', this.dataAtual);

    this.navigation = this.route.getCurrentNavigation();
    if (this.navigation.extras.state) {
      console.log('navigation', this.navigation);
      this.matricula = this.navigation.extras.state.usuario.matricula;
      if (this.navigation.extras.state.usuario.lista) {
        this.dadosComplemento = true;
        this.setarFormColaborador(this.navigation.extras.state.usuario.nome);
        if (this.tipoComplemento == 'Estágio') {
          this.estagio = this.navigation.extras.state.usuario.item;
          this.listaEstagio = this.navigation.extras.state.usuario.lista;
          this.setarTipoObsSelecionado('Estágio');
          this.setarTipoAnexoSelecionado('Estágio');
        } else if (this.tipoComplemento == 'Cessão') {
          this.cessao = this.navigation.extras.state.usuario.item;
          this.listaCessao = this.navigation.extras.state.usuario.lista;
          this.colaboradorAtivo = this.navigation.extras.state.usuario.colaborador;
          this.setarTipoObsSelecionado('Cessão');
          this.setarTipoAnexoSelecionado('Cessão');
        }
      } else {
        this.buscarColaboradorMatricula(this.matricula);
      }
    }
    this.spinner.hide();
  }
  setarTipoObsSelecionado(tipoComplemento: string) {
    switch (tipoComplemento) {
      case 'Estágio':
        this.tipoObsSelecionado = '014';
        break;
      case 'Cessão':
        this.tipoObsSelecionado = '049';
        break;
      default:
        return;
    }
  }
  setarTipoAnexoSelecionado(tipoComplemento: string) {
    switch (tipoComplemento) {
      case 'Estágio':
        this.tipoAnexoSelecionado = '017';
        break;
      case 'Cessão':
        this.tipoAnexoSelecionado = '017';
        break;
      default:
        return;
    }
  }

  matriculaListener(evento: Cadastro) {
    console.log('entrou em matriculalistener', evento);
    if (evento.ColaboradorInativo && this.tipoComplemento != 'Estágio') {
      Swal('Atenção', 'O colaborador informado está inativo.', 'warning');
      this.retornarTelaInicial();
    } else {
      this.colaboradorAtivo = evento;
      this.matricula = evento.Matricula;
      this.mudancaTipoComplemento(evento.NumeroDeOrdem);
    }
    // this.matricula = evento.Matricula;
    // this.buscarColaboradorMatricula(this.matricula);
  }

  buscarColaboradorMatricula(matricula) {
    // this.spinner.show();
    this.loading = true;
    if (this.tipoComplemento == 'Estágio') {
      this.complementoCadastralService.buscarColaboradorMatriculaEstagio(matricula).subscribe(
        (data: Cadastro) => {
          // if (!data.ColaboradorInativo) {
          this.colaboradorAtivo = data;
          console.log('colaboradorativo', this.colaboradorAtivo);
          this.mudancaTipoComplemento(data.NumeroDeOrdem);
          // } else {
          //   Swal('Atenção', 'O colaborador informado está inativo.', 'warning');
          //   this.retornarTelaInicial();
          // }
          // this.spinner.hide();
        },
        (error) => {
          this.erroService.retornoErro(error);
          this.retornarTelaInicial();
          this.loading = false;
          // this.spinner.hide();
        }
      );
    } else {
      this.complementoCadastralService.buscarColaboradorMatricula(matricula).subscribe(
        (data: Cadastro) => {
          if (!data.ColaboradorInativo) {
            this.colaboradorAtivo = data;
            console.log('colaboradorativo', this.colaboradorAtivo);
            this.mudancaTipoComplemento(data.NumeroDeOrdem);
          } else {
            Swal('Atenção', 'O colaborador informado está inativo.', 'warning');
            this.retornarTelaInicial();
          }
          // this.spinner.hide();
        },
        (error) => {
          this.erroService.retornoErro(error);
          this.retornarTelaInicial();
          this.loading = false;
          // this.spinner.hide();
        }
      );
    }
  }

  atualizarContratos() {
    const numord = this.colaboradorAtivo.NumeroDeOrdem;
    this.mudancaTipoComplemento(numord);
    // switch (this.tipoComplemento) {
    //   case 'Cessão':
    //     this.complementoCadastralService.buscarCessao(numord).subscribe({
    //       next: (data: any) => {
    //         this.listaCessao = data.ContratosCessao;
    //       },
    //       error: (err) => {},
    //     });
    //     break;
    //   case 'Estágio':
    //     this.complementoCadastralService.buscarEstagio(numord).subscribe({
    //       next: (data: any) => {
    //         this.listaEstagio = data.ContratosEstagio;
    //       },
    //       error: (err) => {},
    //     });
    //     break;
    //   default:
    //     break;
    // }
  }
  mudancaTipoComplemento(numeroOrdem: string): void {
    this.spinner.show();
    this.listaAprendiz = [];
    this.listaCessao = [];
    this.listaContratoExperiencia = [];
    this.listaEstagio = [];
    switch (this.tipoComplemento) {
      case 'Aprendiz':
        this.buscarContrato(
          () => this.complementoCadastralService.buscarAprendiz(numeroOrdem),
          (data: Aprendiz) => {
            this.listaAprendiz = data.ContratosAprendiz;
            this.aprendiz = data;
            console.log('lista aprendiz mudanca complemento', this.aprendiz);
            this.listaAprendiz.forEach((aprendiz) => {
              aprendiz.ValorBolsa = this.aprendiz.Nivel;
              aprendiz.ValorBolsa.Valor = aprendiz.ValorBolsa.Valor.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              });
            });

            this.setarFormColaborador(data.Nome);
            if (this.listaAprendiz.length == 0) {
              this.outrosContratos = this.aprendiz.OutrosContratos;
            }
            this.spinner.hide();
          }
        );
        break;

      case 'Cessão':
        this.tipoObsSelecionado = '049';
        this.tipoAnexoSelecionado = '017';
        this.buscarContrato(
          () => this.complementoCadastralService.buscarCessao(numeroOrdem),
          (data: Cessao) => {
            this.listaCessao = data.ContratosCessao;
            this.cessao = data;
            console.log('cessao mudanca complemento', this.cessao);
            this.setarFormColaborador(data.Nome);
            if (this.listaCessao.length == 0) {
              this.outrosContratos = this.cessao.OutrosContratos;
            }
            this.spinner.hide();
          }
        );
        break;

      case 'Estágio':
        this.tipoObsSelecionado = '014';
        this.tipoAnexoSelecionado = '017';
        this.buscarContrato(
          () => this.complementoCadastralService.buscarEstagio(numeroOrdem),
          (data: Estagio) => {
            this.listaEstagio = [];
            this.estagio = null;
            this.listaEstagio = data.ContratosEstagio;
            this.estagio = data;
            console.log('data', this.estagio);
            console.log('lista estagio', this.listaEstagio);
            this.setarFormColaborador(data.Nome);
            if (this.listaEstagio.length == 0) {
              this.outrosContratos = this.estagio.OutrosContratos;
            }
            this.listaEstagio.forEach((estagio) => {
              let codNiv = estagio.CodNiv;
              let valorBolsa = estagio.ValorBolsa;
              estagio.ValorBolsa = {
                Codigo: codNiv,
                Nome: null,
                Valor: valorBolsa,
              };
            });
            this.spinner.hide();
          }
        );
        break;

      default:
        this.buscarContrato(
          () => this.complementoCadastralService.buscarContratoExperiencia(numeroOrdem),
          (data: Experiencia) => {
            this.listaContratoExperiencia = data.ContratosExperiencia;
            this.setarFormColaborador(data.Nome);
            this.contratoExperiencia = data;
            if (this.listaContratoExperiencia.length == 0) {
              this.outrosContratos = this.contratoExperiencia.OutrosContratos;
            }
            this.condicoesContratoExperiencia();
            this.spinner.hide();
          }
        );
        break;
    }
    console.log('outros contratos', this.outrosContratos);
  }

  private buscarContrato(
    buscarContratoFn: () => Observable<any>,
    successCallback: (data: any) => void
  ): void {
    this.spinner.show();
    buscarContratoFn().subscribe(
      (data) => {
        successCallback(data);
        this.dadosComplemento = true;
      },
      (error) => {
        this.spinner.hide();
        Swal({
          title: 'Atenção',
          text: error.error.Mensagem,
          icon: 'warning',
          dangerMode: false,
        }).then(() => {
          this.retornarTelaInicial();
        });
        // Lidar com o erro conforme necessário
      }
    );
  }

  setarFormColaborador(nome) {
    this.formColaborador.get('matricula').disable();
    this.formColaborador.get('matricula').setValue(this.matricula);
    this.formColaborador.get('nome').disable();
    this.formColaborador.get('nome').setValue(nome);
    this.loading = false;
  }

  adicionarComplemento() {
    switch (this.tipoComplemento) {
      case 'Aprendiz':
        this.moverParaAprendizCadastro(false);
        break;
      case 'Cessão':
        this.moverParaCessaoCadastro(false, null);
        break;
      case 'Contrato de Experiência':
        this.moverParaContratoCadastro(false);
        break;
      case 'Estágio':
        this.moverParaEstagioCadastro(false);
        break;
      default:
        // Trate outros casos, se necessário
        break;
    }
  }

  //tipar ContratosAprendiz
  moverParaAprendizCadastro(editar: boolean, visualizacao?: boolean, aprendiz?: any): void {
    let aprendizLocal: any | Aprendiz | undefined;

    if (!aprendiz) {
      // A linha abaixo só será executada se aprendiz não estiver presente
      aprendizLocal = this.aprendiz;
      console.log('aprendiz local', aprendizLocal);
    } else {
      aprendizLocal = aprendiz;
    }

    const data = {
      numeroOrdem: this.aprendiz.NumeroOrdem,
      editar,
      visualizacao: visualizacao ? true : false,
      aprendiz: aprendizLocal,
      NovoCadastro: aprendiz ? false : true,
    };

    console.log('aprendiz montado', data);

    this.route.navigate(['/complemento-cadastral/aprendiz/cadastro'], {
      state: { data },
    });
  }

  //duas viraram função acima
  // moverParaAprendizCadastro(editar: boolean): void {
  //   const {numeroOrdem} = this.formColaborador.value;
  //   const aprendiz = this.aprendiz;
  //   console.log(aprendiz);
  //   this.route.navigate(['/complemento-cadastral/aprendiz/cadastro'], {
  //     state: {data: {numeroOrdem, editar, aprendiz, NovoCadastro: true}},
  //   });
  // }
  // moverParaAprendiz(editar: boolean, aprendiz: ContratosAprendiz): void {
  //   const {numeroOrdem} = this.formColaborador.value;
  //   this.route.navigate(['/complemento-cadastral/aprendiz/cadastro'], {
  //     state: {data: {numeroOrdem, editar, aprendiz, NovoCadastro: false}},
  //   });
  // }

  moverParaEstagioCadastro(
    editar: boolean,
    visualizacao?: boolean,
    estagio?: ContratosEstagio
  ): void {
    console.log(this.estagio, ' ou ', this.navigation);
    let numeroOrdem = this.estagio
      ? this.estagio.NumeroOrdem
      : this.navigation.extras.state.usuario.numeroOrdem;
    let estagioLocal: ContratosEstagio | Estagio | undefined;

    if (!estagio) {
      estagioLocal = this.estagio;
    } else {
      estagioLocal = estagio;
    }

    const data = {
      numeroOrdem,
      nome: this.formColaborador.get('nome').value,
      matricula: this.matricula,
      editar,
      visualizacao: visualizacao ? true : false,
      estagio: estagioLocal,
      NovoCadastro: estagio ? false : true,
      lista: this.listaEstagio,
      item: this.estagio,
    };

    this.route.navigate(['/complemento-cadastral/estagio/cadastro'], {
      state: { data },
    });
  }

  //duas viraram função acima
  // moverParaEstagioCadastro(editar: boolean): void {
  //   const estagio = this.estagio;
  //   const numeroOrdem = estagio.NumeroOrdem;
  //   this.route.navigate(['/complemento-cadastral/estagio/cadastro'], {
  //     state: {data: {numeroOrdem, editar, estagio, NovoCadastro: true}},
  //   });
  // }
  // moverParaEstagio(editar: boolean, estagio: ContratosEstagio): void {
  //   const obterNumord = this.estagio;
  //   const numeroOrdem = obterNumord.NumeroOrdem;

  //   this.route.navigate(['/complemento-cadastral/estagio/cadastro'], {
  //     state: {data: {numeroOrdem, editar, estagio, NovoCadastro: false}},
  //   });
  // }

  moverParaCessaoCadastro(editar: boolean, visualizacao?: boolean, cessao?: ContratosCessao): void {
    const { matricula } = this.formColaborador.value;
    let cessaoLocal: ContratosCessao | Cessao | undefined;

    if (!cessao) {
      cessaoLocal = this.cessao;
    } else {
      cessaoLocal = cessao;
      cessaoLocal.NumeroOrdem = this.cessao.NumeroOrdem;
    }
    console.log('cessao', cessaoLocal);

    this.route.navigate(['/complemento-cadastral/cessao/cadastro'], {
      state: {
        data: {
          colaborador: this.colaboradorAtivo,
          nome: this.formColaborador.get('nome').value,
          matricula: this.matricula,
          editar,
          visualizacao: visualizacao ? true : false,
          cessao: cessaoLocal,
          lista: this.listaCessao,
          item: this.cessao,
          vinculo:
            this.colaboradorAtivo.InformacoesBasicas.Vinculo.Codigo +
            ' - ' +
            this.colaboradorAtivo.InformacoesBasicas.Vinculo.Nome,
        },
      },
    });
  }

  moverParaContratoCadastro(
    editar: boolean,
    visualizacao?: boolean,
    contrato?: ContratoExperiencia
  ): void {
    const numeroOrdem = this.contratoExperiencia.NumeroOrdem;
    let contratoLocal: ContratoExperiencia | Experiencia | undefined;

    if (!contrato) {
      contratoLocal = this.contratoExperiencia;
    } else {
      contratoLocal = contrato;
    }

    console.log('contratoLocal', contratoLocal);

    const data = {
      numeroOrdem,
      editar,
      visualizacao: visualizacao ? true : false,
      contrato: contratoLocal,
      NovoCadastro: contrato ? false : true,
    };

    this.route.navigate(['/complemento-cadastral/contrato-experiencia/cadastro'], {
      state: { data },
    });
  }

  //duas viraram função acima
  // moverParaContrato(editar: boolean, contrato: ContratoExperiencia): void {
  //   const obterNumord = this.ContratoExperiencia;
  //   const numeroOrdem = obterNumord.NumeroOrdem;
  //   this.route.navigate(['/complemento-cadastral/contrato-experiencia/cadastro'], {
  //     state: {data: {numeroOrdem, editar, contrato, NovoCadastro: false}},
  //   });
  // }

  // MoverparaContratoNovoCadastro(editar: boolean) {
  //   const contrato = this.ContratoExperiencia;
  //   const numeroOrdem = contrato.NumeroOrdem;
  //   this.route.navigate(['/complemento-cadastral/contrato-experiencia/cadastro'], {
  //     state: {data: {numeroOrdem, editar, contrato, NovoCadastro: true}},
  //   });
  // }

  retornarTelaInicial() {
    this.matricula = null;
    this.dadosComplemento = false;
    this.formColaborador.reset();
    this.formColaborador.markAsPristine();
    this.listaAprendiz = null;
  }

  compararDatas(dataFinalContrato: any): boolean {
    const ano = parseInt(dataFinalContrato.substring(0, 4), 10);
    const mes = parseInt(dataFinalContrato.substring(4, 6), 10) - 1; // Mês é zero-indexado
    const dia = parseInt(dataFinalContrato.substring(6, 8), 10);

    // Obtendo a data atual
    const dataAtual = new Date().getTime();

    // Criando a data final
    const dataFinal = new Date(ano, mes, dia).getTime();

    // console.log('data atual', dataAtual, 'data final', dataFinal);
    // console.log('comparar datas', dataFinal > dataAtual);

    return dataFinal > dataAtual;
  }

  condicoesContratoExperiencia(): boolean {
    if (this.tipoComplemento !== 'Contrato de Experiência') {
      return false;
    }

    if (this.listaContratoExperiencia.length === 0) {
      console.log('length igual 0');
      return true;
    }

    const dataFinalContratoExperiencia = this.listaContratoExperiencia[0].DataFinal.SetData;

    if (!this.compararDatas(dataFinalContratoExperiencia)) {
      const diasContratoExperiencia = this.listaContratoExperiencia[0].Dias;

      if (
        ((diasContratoExperiencia === '30' || diasContratoExperiencia === '45') &&
          this.listaContratoExperiencia.length < 2) ||
        (diasContratoExperiencia === '90' && this.listaContratoExperiencia.length < 1)
      ) {
        console.log(
          `${diasContratoExperiencia} dias e menor que ${4 - this.listaContratoExperiencia.length}`
        );
        return true;
      }
    }

    console.log('condição falsa');
    return false;
  }
}
