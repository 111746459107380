<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<div class="card">
  <div class="card-header">
    <h3 class="font-weight-semibold">Cadastro de Aprendiz</h3>
  </div>
  <form #aprendiz="ngForm" [formGroup]="formAprendiz" (ngSubmit)="submitAprendiz()">
    <div class="card-body">
      <fieldset>
        <legend><h6 class="font-weight-semibold">Dados do Menor Aprendiz</h6></legend>
        <div class="row">
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Número do Processo</label>
            <input
              type="text"
              class="form-control"
              placeholder="Número processo"
              formControlName="numeroProcesso"
              [ngClass]="
                formAprendiz.get('numeroProcesso').invalid &&
                (temErro || formAprendiz.get('numeroProcesso').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formAprendiz.get('numeroProcesso').invalid &&
                (temErro || formAprendiz.get('numeroProcesso').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Área de Atuação</label>
            <input
              type="text"
              class="form-control"
              placeholder="Área de atuação"
              formControlName="areaAtuacao"
              [ngClass]="
                formAprendiz.get('areaAtuacao').invalid &&
                (temErro || formAprendiz.get('areaAtuacao').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formAprendiz.get('areaAtuacao').invalid &&
                (temErro || formAprendiz.get('areaAtuacao').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Valor da Bolsa</label>
            <input
              type="text"
              class="form-control"
              placeholder="Valor da bolsa"
              formControlName="valorBolsa"
              [ngClass]="
                formAprendiz.get('valorBolsa').invalid &&
                (temErro || formAprendiz.get('valorBolsa').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formAprendiz.get('valorBolsa').invalid &&
                (temErro || formAprendiz.get('valorBolsa').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Previsão de Término</label>
            <input
              type="date"
              class="form-control"
              formControlName="dataPrevisaoTermino"
              max="9999-12-31"
              [ngClass]="
                formAprendiz.get('dataPrevisaoTermino').invalid &&
                (temErro || formAprendiz.get('dataPrevisaoTermino').touched)
                  ? 'is-invalid'
                  : null
              "
              style="padding-right: 0"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formAprendiz.get('dataPrevisaoTermino').invalid &&
                (temErro || formAprendiz.get('dataPrevisaoTermino').touched)
              "
            >
              {{
                formAprendiz.get('dataPrevisaoTermino').hasError('data')
                  ? 'Data Inválida'
                  : 'Campo Obrigatório'
              }}
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Data Inicial</label>
            <input
              type="date"
              class="form-control"
              [readonly]="editar"
              formControlName="dataInicial"
              (change)="verificaData('dataInicial', 'dataFinal')"
              max="9999-12-31"
              [ngClass]="
                formAprendiz.get('dataInicial').invalid &&
                (temErro || formAprendiz.get('dataInicial').touched)
                  ? 'is-invalid'
                  : null
              "
              style="padding-right: 0"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formAprendiz.get('dataInicial').invalid &&
                (temErro || formAprendiz.get('dataInicial').touched)
              "
            >
              {{
                formAprendiz.get('dataInicial').hasError('data')
                  ? 'Data Inválida'
                  : 'Campo Obrigatório'
              }}
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Data final</label>
            <input
              type="date"
              class="form-control"
              [readonly]="editar"
              formControlName="dataFinal"
              (change)="verificaData('dataInicial', 'dataFinal')"
              max="9999-12-31"
              [ngClass]="
                formAprendiz.get('dataFinal').invalid &&
                (temErro || formAprendiz.get('dataFinal').touched)
                  ? 'is-invalid'
                  : null
              "
              style="padding-right: 0"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formAprendiz.get('dataFinal').invalid &&
                (temErro || formAprendiz.get('dataFinal').touched)
              "
            >
              {{
                formAprendiz.get('dataFinal').hasError('data')
                  ? 'Data Inválida'
                  : 'Campo Obrigatório'
              }}
            </span>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend><h6 class="font-weight-semibold">Instituição de Ensino</h6></legend>
        <div class="row">
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">CNPJ</label>
            <select
              class="form-control"
              (change)="carregarInstEnsino()"
              formControlName="instEnsinoCnpj"
              [ngClass]="
                formAprendiz.get('instEnsinoCnpj').invalid &&
                (temErro || formAprendiz.get('instEnsinoCnpj').touched)
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">CNPJ</option>
              <option
                *ngFor="let instEnsino of listaInstituicaoEnsino"
                value="{{ instEnsino.CNPJ }}"
              >
                {{ instEnsino.CNPJ | cnpj }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formAprendiz.get('instEnsinoCnpj').invalid &&
                (temErro || formAprendiz.get('instEnsinoCnpj').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-6 form-group">
            <label class="font-weight-semibold">Razão Social</label>
            <input
              type="text"
              formControlName="instEnsinoRazaoSocial"
              class="form-control"
              placeholder="Razão social"
              readonly
              [ngClass]="
                formAprendiz.get('instEnsinoRazaoSocial').invalid &&
                (temErro || formAprendiz.get('instEnsinoRazaoSocial').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('instEnsinoRazaoSocial').hasError('required')"
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">CEP</label>
            <input
              type="text"
              formControlName="instEnsinoCep"
              class="form-control"
              placeholder="CEP"
              readonly
              mask="00.000-000"
              [ngClass]="
                formAprendiz.get('instEnsinoCep').invalid &&
                (temErro || formAprendiz.get('instEnsinoCep').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('instEnsinoCep').hasError('required')"
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">UF</label>
            <input
              type="text"
              class="form-control"
              placeholder="UF"
              readonly
              formControlName="instEnsinoUf"
              [ngClass]="
                formAprendiz.get('instEnsinoUf').invalid &&
                (temErro || formAprendiz.get('instEnsinoUf').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('instEnsinoUf').hasError('required')"
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Município</label>
            <input
              type="text"
              formControlName="instEnsinoMunicipio"
              class="form-control"
              placeholder="Logradouro"
              readonly
              [ngClass]="
                formAprendiz.get('instEnsinoMunicipio').invalid &&
                (temErro || formAprendiz.get('instEnsinoMunicipio').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('instEnsinoMunicipio').hasError('required')"
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Bairro</label>
            <input
              type="text"
              formControlName="instEnsinoBairro"
              class="form-control"
              placeholder="Bairro"
              readonly
              [ngClass]="
                formAprendiz.get('instEnsinoBairro').invalid &&
                (temErro || formAprendiz.get('instEnsinoBairro').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('instEnsinoBairro').hasError('required')"
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Logradouro</label>
            <input
              type="text"
              formControlName="instEnsinoLogradouro"
              class="form-control"
              placeholder="Logradouro"
              readonly
              [ngClass]="
                formAprendiz.get('instEnsinoLogradouro').invalid &&
                (temErro || formAprendiz.get('instEnsinoLogradouro').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('instEnsinoLogradouro').hasError('required')"
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Número</label>
            <input
              type="text"
              formControlName="instEnsinoNumero"
              class="form-control"
              placeholder="Número"
              readonly
              mask="0*"
              [ngClass]="
                formAprendiz.get('instEnsinoNumero').invalid &&
                (temErro || formAprendiz.get('instEnsinoNumero').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('instEnsinoNumero').hasError('required')"
            >
              Campo Obrigatório
            </span>
          </div>

          <!-- <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Tipo logradouro</label>
            <select class="form-control" formControlName="instEnsinoTipoLogradouro" [ngStyle]="formAprendiz.get('instEnsinoTipoLogradouro').invalid && (temErro || formAprendiz.get('instEnsinoTipoLogradouro').dirty) ? {'border-color':'red'} : ''">
              <option value="">Selecione...</option>
              <option *ngFor="let tipoLogradouro of listaTipoLogradouro" value="{{tipoLogradouro.Codigo}}">{{tipoLogradouro.Descricao}}</option>
            </select>
            <span class="form-text text-danger" *ngIf="temErro && formAprendiz.get('instEnsinoTipoLogradouro').hasError('required')">
              Campo Obrigatório
            </span>
          </div> -->
        </div>
      </fieldset>

      <fieldset>
        <legend><h6 class="font-weight-semibold">Agente de Integração</h6></legend>
        <div class="row">
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">CNPJ</label>
            <select
              (change)="carregarAgenteIntegracao()"
              class="form-control"
              formControlName="agenteIntegracaoCnpj"
              [ngClass]="
                formAprendiz.get('agenteIntegracaoCnpj').invalid &&
                (temErro || formAprendiz.get('agenteIntegracaoCnpj').touched)
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">CNPJ</option>
              <option *ngFor="let agente of listaAgenteIntegracao" value="{{ agente.CNPJ }}">
                {{ agente.CNPJ | cnpj }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formAprendiz.get('agenteIntegracaoCnpj').invalid &&
                (temErro || formAprendiz.get('agenteIntegracaoCnpj').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-6 form-group">
            <label class="font-weight-semibold">Razão Social</label>
            <input
              type="text"
              class="form-control"
              placeholder="Razão social"
              readonly
              formControlName="agenteIntegracaoRazaoSocial"
              [ngClass]="
                formAprendiz.get('agenteIntegracaoRazaoSocial').invalid &&
                (temErro || formAprendiz.get('agenteIntegracaoRazaoSocial').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                temErro && formAprendiz.get('agenteIntegracaoRazaoSocial').hasError('required')
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">CEP</label>
            <input
              type="text"
              class="form-control"
              placeholder="CEP"
              readonly
              mask="00.000-000"
              formControlName="agenteIntegracaoCep"
              [ngClass]="
                formAprendiz.get('agenteIntegracaoCep').invalid &&
                (temErro || formAprendiz.get('agenteIntegracaoCep').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('agenteIntegracaoCep').hasError('required')"
            >
              Campo Obrigatório
            </span>
            <!-- <span class="form-text" *ngIf="loadingCepAgentIntegracao">Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i></span> -->
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">UF</label>
            <input
              type="text"
              class="form-control"
              placeholder="UF"
              readonly
              formControlName="agenteIntegracaoUf"
              [ngClass]="
                formAprendiz.get('agenteIntegracaoUf').invalid &&
                (temErro || formAprendiz.get('agenteIntegracaoUf').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('agenteIntegracaoUf').hasError('required')"
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Município</label>
            <input
              type="text"
              class="form-control"
              placeholder="Município"
              readonly
              formControlName="agenteIntegracaoMunicipio"
              [ngClass]="
                formAprendiz.get('agenteIntegracaoMunicipio').invalid &&
                (temErro || formAprendiz.get('agenteIntegracaoMunicipio').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('agenteIntegracaoMunicipio').hasError('required')"
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Bairro</label>
            <input
              type="text"
              class="form-control"
              placeholder="Bairro"
              readonly
              formControlName="agenteIntegracaoBairro"
              [ngClass]="
                formAprendiz.get('agenteIntegracaoBairro').invalid &&
                (temErro || formAprendiz.get('agenteIntegracaoBairro').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('agenteIntegracaoBairro').hasError('required')"
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Logradouro</label>
            <input
              type="text"
              class="form-control"
              placeholder="Logradouro"
              readonly
              formControlName="agenteIntegracaoLogradouro"
              [ngClass]="
                formAprendiz.get('agenteIntegracaoLogradouro').invalid &&
                (temErro || formAprendiz.get('agenteIntegracaoLogradouro').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('agenteIntegracaoLogradouro').hasError('required')"
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Número</label>
            <input
              type="text"
              class="form-control"
              placeholder="Número"
              readonly
              mask="0*"
              formControlName="agenteIntegracaoNumero"
              [ngClass]="
                formAprendiz.get('agenteIntegracaoNumero').invalid &&
                (temErro || formAprendiz.get('agenteIntegracaoNumero').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('agenteIntegracaoNumero').hasError('required')"
            >
              Campo Obrigatório
            </span>
          </div>

          <!-- <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Tipo logradouro</label>
            <select class="form-control" formControlName="agenteIntegracaoTipoLogradouro" [ngStyle]="formAprendiz.get('agenteIntegracaoTipoLogradouro').invalid && (temErro || formAprendiz.get('agenteIntegracaoTipoLogradouro').dirty) ? {'border-color':'red'} : ''">
              <option value="">Selecione...</option>
              <option *ngFor="let tipoLogradouro of listaTipoLogradouro" value="{{tipoLogradouro.Codigo}}">{{tipoLogradouro.Descricao}}</option>
            </select>
            <span class="form-text text-danger" *ngIf="temErro && formAprendiz.get('agenteIntegracaoTipoLogradouro').hasError('required')">
              Campo Obrigatório
            </span>
          </div> -->
        </div>
      </fieldset>

      <fieldset>
        <legend><h6 class="font-weight-semibold">Supervisor</h6></legend>
        <div class="row">
          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Matrícula</label>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Matrícula"
                mask="0*"
                formControlName="supervisorMatricula"
                [ngClass]="
                  formAprendiz.get('supervisorMatricula').invalid &&
                  (temErro || formAprendiz.get('supervisorMatricula').touched)
                    ? 'is-invalid'
                    : null
                "
              />
              <span class="input-group-append">
                <button
                  type="button"
                  class="input-group-text bg-blue border-blue"
                  (click)="buscarSupervisor()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </span>
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                formAprendiz.get('supervisorMatricula').invalid &&
                (temErro || formAprendiz.get('supervisorMatricula').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Empresa Filial</label>
            <input
              type="text"
              class="form-control"
              placeholder="Empresa filial"
              readonly
              formControlName="supervisorEmpfil"
              [ngClass]="
                formAprendiz.get('supervisorEmpfil').invalid &&
                (temErro || formAprendiz.get('supervisorEmpfil').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('supervisorEmpfil').hasError('required')"
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Nº de Ordem</label>
            <input
              type="text"
              class="form-control"
              mask="0*"
              placeholder="Nº de Ordem"
              readonly
              formControlName="supervisorNumOrd"
              [ngClass]="
                formAprendiz.get('supervisorNumOrd').invalid &&
                (temErro || formAprendiz.get('supervisorNumOrd').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('supervisorNumOrd').hasError('required')"
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-6 form-group">
            <label class="font-weight-semibold">Nome</label>
            <input
              type="text"
              class="form-control"
              placeholder="Nome"
              readonly
              formControlName="supervisorNome"
              [ngClass]="
                formAprendiz.get('supervisorNome').invalid &&
                (temErro || formAprendiz.get('supervisorNome').dirty)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="temErro && formAprendiz.get('supervisorNome').hasError('required')"
            >
              Campo Obrigatório
            </span>
          </div>
        </div>
      </fieldset>
    </div>
    <div class="card-footer text-right">
      <button (click)="voltarBusca()" class="btn btn-danger mr-2">
        {{ visualizacao ? 'Voltar' : 'Cancelar' }}
      </button>
      <button type="submit" class="btn btn-primary" *ngIf="!visualizacao">
        {{ editar ? 'Atualizar' : 'Submeter' }}
        <i class="fa fa-paper-plane ml-2"></i>
      </button>
    </div>
  </form>
</div>
