<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<app-pesquisar-matricula *ngIf="matricula == null" [label]="'Matrícula'" [titulo]="'Situação Funcional Falecimento'"
  [inputText]="true" (colaboradorEmitter)="matriculaListener($event)"></app-pesquisar-matricula>

<div *ngIf="matricula != null" class="card content container">
  <div class="card-header">
    <h3 class="card-title"><i class="fa fa-arrow-left pr-3 btn   " aria-hidden="true" (click)="retornarTelaInicial()"
        style="cursor: pointer; bottom: 2px;"></i> Situação Funcional Falecimento</h3>
  </div>

  <form [formGroup]="formSituacaoFuncionalFalecimento" #formSituacaoFuncionalFalecimentoForm='ngForm'>
    <div class="card-body">
      <div class="row">
        <div class="col-md-2  form-group">
          <label class="font-weight-semibold">Matrícula</label>
          <div class="input-group">
            <input
              [ngClass]="temErro && formSituacaoFuncionalFalecimento.controls['matricula'].hasError('required') || formSituacaoFuncionalFalecimento.controls['matricula'].dirty && formSituacaoFuncionalFalecimento.controls['matricula'].status == 'INVALID'  ? 'is-invalid' : null"
              autofocus class="form-control" formControlName="matricula" placeholder="Matrícula" autofocus type="text">
          </div>
          <span class="form-text" *ngIf="carregaNome">Carregando <i class="fa fa-spinner fa-spin"
              aria-hidden="false"></i></span>
          <span class="form-text text-danger" *ngIf="temErro &&
					formSituacaoFuncionalFalecimento.controls['matricula'].hasError('required')">
            Campo obrigatório
          </span>
        </div>

        <div class="col-md-4 form-group">
          <label class="font-weight-semibold">Nome</label>
          <input class="form-control" type="text" formControlName="nome" placeholder="Nome" />
        </div>

        <div class="col-md-3 form-group" *ngIf="clientePublico == true">
          <label class="font-weight-semibold">Data Diário Oficial</label>
          <input class="form-control" type="date" formControlName="dataDoe" max="9999-12-31" style="padding-right: 0 ;"
            [ngClass]="((temErro || formSituacaoFuncionalFalecimento.controls['dataDoe'].dirty) && formSituacaoFuncionalFalecimento.controls['dataDoe'].status == 'INVALID') ? 'is-invalid' : null" />
          <span class="form-text text-danger"
            *ngIf="(temErro || formSituacaoFuncionalFalecimento.controls['dataDoe'].dirty ) && formSituacaoFuncionalFalecimento.controls['dataDoe'].hasError('required')">
            Campo obrigatório
          </span>
          <span class="form-text text-danger"
            *ngIf="formSituacaoFuncionalFalecimento.controls['dataDoe'].hasError('data')">
            Data inválida
          </span>
        </div>

        <div class="col-md-3 form-group">
          <label class="font-weight-semibold">Data Óbito</label>
          <input formControlName="dataObito" max="9999-12-31" type="date" class="form-control"
            [ngClass]="((temErro || formSituacaoFuncionalFalecimento.controls['dataObito'].dirty) && formSituacaoFuncionalFalecimento.controls['dataObito'].status == 'INVALID') ? 'is-invalid' : null"
            style="padding-right: 0 ;">
          <span class="form-text text-danger"
            *ngIf="(temErro  && formSituacaoFuncionalFalecimento.controls['dataObito'].hasError('required'))">
            Campo obrigatório
          </span>
        </div>
        

      </div>
      <div class="col-md-1 form-group"> </div>
      <div class=" form-group" *ngIf="formSituacaoFuncionalFalecimento.get('nome').value != ''">
        <app-anexo [pagina]="'014'" [matricula]="formSituacaoFuncionalFalecimento.get('matricula').value"
          [enviarSeparadamente]="true" [perfil]="false"
          [compChave]="formSituacaoFuncionalFalecimento.get('numord').value"></app-anexo>
      </div>
    </div>
    <div class="card-footer text-right">
      <button class="btn btn-primary" type="button" (click)="submitSituacaoFuncional()">Salvar</button>
    </div>
  </form>
</div>