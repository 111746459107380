import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';



@Injectable
({
  providedIn: 'root',
})

export class OutrasDocumentacoesService 
{
    url_acesso: string;
    cabecalho: any;
    filial: string;
    localStorage: LocalStorage = new LocalStorage();
    headers = new HttpHeaders();

    constructor(private http: HttpClient, private acesso: AcessoService) {
        this.url_acesso = this.acesso.validarUrl();
    
        if (localStorage.getItem('util') != null) {
          this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
          this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
    
          this.filial = this.localStorage.FilialAtual.toString();
        }
      }

  buscarColaboradorMatricula(matricula: string) {
    return this.http.get(`${this.url_acesso}cadastro-todos-matricula/${matricula}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  downloadPdf(empfil: string, matricula: number, tipo: string) {
    return this.http.get(
      `${this.url_acesso}relatorios-pdf/download-pdf/${empfil}/${matricula}/${tipo}`,
      {
        headers: this.cabecalho,
      }
    );
  }
  
}