import { Component, OnInit, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { PseColaboradoresService } from './pse-colaboradores.service';
import { ErroService } from 'src/app/service/erros/erro.service';

registerLocaleData(localePt);
@Component({
  selector: 'app-pse-colaboradores',
  templateUrl: './pse-colaboradores.component.html',
  styleUrls: ['./pse-colaboradores.component.css'],
  providers: [{provide: LOCALE_ID, useValue: 'pt' }]
})
export class PseColaboradoresComponent implements OnInit {

  listaColaborador: any[];
  pesquisar: string = '';
  constructor(colaboradoresService: PseColaboradoresService, validaErro:ErroService) { }

  ngOnInit() {
    this.buscarColaboradores();
  }

  buscarColaboradores():void {
    this.listaColaborador = [];
    for(let i =0;i != 100;i++){
      this.listaColaborador.push(
        {
          Nome: 'José Augusto de Paiva Neto',
          TipoUsuario: 'Portal',
          Codigo: '1',
          Cpf: '11538132460',
          Situacao: 'ativo'
        }
      );
    }
 }

}
