import { Filial } from '../../Filial/filial';

export class Setor{

    tpCtad: string;
    ctad: string;
    tpCtac: string;
    ctac: string;
    descricao:string;
    inivalid: string;
    fimvalid: string;
    sigla:string;
    Codigo: string;
    Filial: Filial = new Filial();
    Nome:string;
    Nomecompleto: string;
    Nivel:string;
    Tipo: string;
    Efetivo: string;
    CentroDeCusto:string;
    SiglaEntrada: string;
    Flag: [];
}