import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function cnpjValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const cnpj = control.value;

    if (!cnpj) return null; // Deixe o campo vazio ser válido

    // Remover caracteres não numéricos
    const cnpjLimpo = cnpj.replace(/[^\d]+/g, '');

    // Valida se o CNPJ tem 14 dígitos
    if (cnpjLimpo.length !== 14) return { cnpjInvalido: true };

    // Elimina CNPJs inválidos conhecidos
    const cnpjsInvalidos = [
      '00000000000000',
      '11111111111111',
      '22222222222222',
      '33333333333333',
      '44444444444444',
      '55555555555555',
      '66666666666666',
      '77777777777777',
      '88888888888888',
      '99999999999999',
    ];

    if (cnpjsInvalidos.includes(cnpjLimpo)) return { cnpjInvalido: true };

    // Validação dos dígitos verificadores
    const validarDigitos = (cnpj: string): boolean => {
      const tamanho = cnpj.length - 2;
      const numeros = cnpj.substring(0, tamanho);
      const digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
      }

      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado !== Number(digitos.charAt(0))) return false;

      const tamanho2 = tamanho + 1;
      const numeros2 = cnpj.substring(0, tamanho2);
      soma = 0;
      pos = tamanho2 - 7;
      for (let i = tamanho2; i >= 1; i--) {
        soma += Number(numeros2.charAt(tamanho2 - i)) * pos--;
        if (pos < 2) pos = 9;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      return resultado === Number(digitos.charAt(1));
    };

    return validarDigitos(cnpjLimpo) ? null : { cnpjInvalido: true };
  };
}
