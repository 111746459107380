import {AcessoService} from 'src/app/service/acesso/acesso.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalStorage} from 'src/app/service/classes/localstorage/LocalStorage';
import {Crypto} from 'src/app/service/classes/crypto/crypto';
import {DadosPessoais} from 'src/app/service/interfaces/DadosPessoais';

@Injectable({
  providedIn: 'root',
})
export class DadosPessoaisService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any;
  filial: string;
  url_acesso: any = this.acesso.validarUrl();

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  AlterarCadastroPerfil(dados: DadosPessoais, numeroOrdem: string) {
    return this.http.put(
      `${this.url_acesso}colaborador/dados-pessoais/${this.filial}/${numeroOrdem}/`,
      dados,
      {headers: this.cabecalho}
    );
  }
}
