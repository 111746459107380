import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Cadastro} from 'src/app/service/classes/cadastro/cadastro';
import {AcessoService} from 'src/app/service/acesso/acesso.service';
import {CadastroPerfil} from 'src/app/service/classes/profile/CadastroPerfil';
import {LocalStorage} from 'src/app/service/classes/localstorage/LocalStorage';
import {Crypto} from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root',
})
export class SalarioService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any = null;
  filial: string = '';
  url_acesso: any = this.acesso.validarUrl();

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  alterarBanco(banco: any, numeroOrdem) {
    return this.http.put(`${this.url_acesso}colaborador/salario/${numeroOrdem}`, banco, {
      headers: this.cabecalho,
    });
  }

  buscarBancos() {
    return this.http.get(`${this.url_acesso}bancosComCodigoBco/`, {headers: this.cabecalho});
  }
}
