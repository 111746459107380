import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CadastroService } from "../cadastro/cadastro.service";
import { ErroService} from '../../service/erros/erro.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Cadastro } from '../../service/classes/cadastro/cadastro'
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';
import { UploadComponent } from '../upload/upload.component';
import { Router } from '@angular/router';
import { Validacao } from '../../service/global/validacao/validacao.service';
import {distinct} from 'rxjs/operators'
import Swal from 'sweetalert';
import { pisValidator } from '../../service/Validators/pisValidator'
import { cpfValidator } from '../../service/Validators/cpfValidator'
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { ContinuacaoCadastroService } from './continuacao-cadastro.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-continuacao-cadastro',
  templateUrl: './continuacao-cadastro.component.html',
  styleUrls: ['./continuacao-cadastro.component.css']
})
export class ContinuacaoCadastroComponent implements OnInit {

  @ViewChild(UploadComponent,  {static: false} ) upload: UploadComponent; 

  carregandoLogin:boolean = false;
  public identificado:boolean = false;
  public step: number = 1;
  public stepcount: number = 1;
  urls:Array<string> = [];
  imageURL: string;
  uploadForm: FormGroup;
  cadastro = new Cadastro();
  validaEtapa1:boolean = false;
  validaEtapa2:boolean = false;
  validaEtapa3:boolean = false;
  validaEtapa4:boolean = false;

  //Variáveis do dos forms
  formInformacaoBasica:any;
  formDadosPessoais:any;
  formDocumentos: FormGroup;
  formSalt:any;

  idadeMinima: number = 14

  //Variáveis dos selects da etapa 1
  vinculo:any[] = [];
  departamento:any[] = [];
  setores:any[]=[];
  cargos:any[]=[];
  horaTrabalho:any[]=[];
  cargaHoraria:any[]=[];
  sindicatos:any[]=[];
  nivel:any[]=[];

  //Variáveis dos selects da etapa 2
  escolaridade:any[] = [];
  nacionalidade:any[] = [];
  cor:any[]=[];
  sexo:any[]=[];
  estadoCivil:any[]=[];
  estado:any[]=[];
  cidade:any[]=[];

  //Variáveis dos selects da etapa 3
  ufRg:any[] = [];
  categoriaCnh:any[] = [];
  ufCtps:any[]=[];
  banco:any[] = [];
  ufCnh:any[]=[];

  //Variaveis do filter
  cargaHorariaFilter:any[]=[];
  horarioTrabalhoFilter:any[]=[];
  horarioTrabalho:any;
  retornoHorario:any;
  
  //loadings dos campos
  carrega_setor:boolean = false;
  carrega_cargo:boolean = false;
  carrega_hora_trabalho:boolean = false;
  carrega_nivel:boolean = false;
  carrega_cep:boolean = false;

  //Variáveis e retorno
  retorno_usuario = {NumeroDeOrdem:'', Matricula:''};
  cep = { Bairro: "", Cep: "", Cidade: "", Complemento: "", Logradouro: "", Numero: "", Uf: "", Valido: "", bairro: "", cidade: "", complemento: "", logradouro: "", uf: ""};
  codigo_uf:any;
  salario_nivel:string;
  validaCadastro:boolean = false; // verifica se o usuário já passou pela etapa do cadastro
  telefone_form:string;
  celular_form:string;
  filial:number = Number(localStorage.getItem("filial_atual"));
  //
  validar_cpf_blur:boolean = false;
  validar_pis_blur:boolean = false;
  //
  filtroTeclas:any;
  rotaParametro:any;

  codigoAgencia: any;
  agencias: any = [];

  //JSON da primeira etapa
  cadastro_etapa_1 = {
    NumeroDeOrdem:"",
    Etapa:"",
    Matricula:"",
    Filial:{
      Codigo: this.filial
    },
    InformacoesBasicas:{
      NomeCompleto:"",
      Departamento:{
        Cod:""
      },
      Setor:{
        Cod:""
      },
      DataAdmissao:{
        SetData:"",
        ToDate:"",
        IsDate:"",
        Empty:"",
      },
      Vinculo:{
        Codigo:""
      },
      Cargo:{
        Codigo:""
      },
      Salario:{
        CodigoNivel:"",
        Valor:""
      },
      HorarioTrabalho:{
        Codigo:""
      },
      Sindicato:{
        Codigo:""
      }
    }
  };

  cadastro_etapa_2 = {
    NumeroDeOrdem: "",
    Etapa: 2,
    Matricula: "",
    Documentos:{
      Cpf:""
    },
    Filial: {
        Codigo: this.filial
    },
    Pessoal: {
        DadosBasicos: {
            DataNascimento: {
                SetData: "",
                ToDate: "",
                IsDate: true,
                Empty: false
            },
            GrauDeInstrucao: {
                Valor: 0
            },
            Nacionalidade: {
                Codigo: 0
            },
            Etnia: {
              Descricao:""
            },
            Sexo: {
                Valor: 0
            },
            EstadoCivil: {
                Valor: 0
            },
            NomeMae: "",
            NomePai: ""
        },
        Endereco: {
            Cep: "",
            Logradouro: "",
            Numero: "",
            Bairro: "",
            Estado: "",
            Cidade: ""
        },
        Contato: {
            DDDCelular: "",
            Celular: "",
            DDDTelefone: "",
            Telefone: "",
            Email1: "",
            Email2: ""
        }
    }
  }

  cadastro_etapa_3 = {
    NumeroDeOrdem: "" ,
    Etapa: 3,
    Matricula: "",
    Filial: {
        Codigo: this.filial
    },
    Documentos: {
        Rg: {
            Numero: "",
            DataEmissaoRg: {
                SetData: "",
                ToDate: "",
                IsDate: false,
                Empty: true
            },
            OrgaoEmissor:"",
            UfEmissaoRg: ""
        },
        Cnh: {
            Numero: "",
            CategoriaCnh: "",
            UF:"",
            DataValidadeCnh: {
                SetData: "",
                ToDate: "",
                IsDate: false,
                Empty: true
            }
        },
        Clt: {
            CarteiraDeTrabalho: "",
            NumeroSerieCtps: "",
            Pis: "",
            UfCtps:""
        },
        TituloEleitor: {
            Numero: "",
            ZonaEleitoral: "",
            SecaoEleitoral: ""
        },
      UploadDocumentos:[]
    },
    DadosBancarios: {
        Banco: "",
        Agencia: "",
        Operacao: "",
        Conta: ""
    },
  }

  constructor(public activatedRoute: ActivatedRoute, public auth: AuthService,public fb:FormBuilder, private cadastroService:CadastroService, private continuacaoCadastroService: ContinuacaoCadastroService, private validaErro:ErroService, private spinner: NgxSpinnerService, private route: ActivatedRoute,private router: Router, private validacaoService:Validacao) {
    // Reactive Form
    this.route.params.subscribe(params => this.rotaParametro = params['numOrdem']);
  }


  // Os métodos de busca e alterações do cadastro estão no service do cadastro, o service do cadastro pendente é para métodos específicos do pendente.

  ngOnInit() {
    localStorage.setItem("tipoTela", "017");//infromar qual a tela para o upload de arquivos

    window.scrollTo(0, 0);
    this.spinner.show();

    this.formSalt = this.fb.group({
      numOrd_form: ['', Validators.compose([Validators.required])],
      senha_form: ['',  Validators.compose([Validators.required])],
    });

    this.formDadosPessoais = this.fb.group({
      // cpf_form: ['',  Validators.compose([ Validators.required, cpfValidator])],
      data_nascimento_form: ['',  Validators.compose([ Validators.required, dataValidator])],
      escolaridade_form: ['', Validators.compose([ Validators.required])],
      nacionalidade_form: ['',  Validators.compose([ Validators.required])],
      cor_form: ['',  Validators.compose([ Validators.required])],
      sexo_form: [null,  Validators.compose([ Validators.required])],
      estado_civil_form: ['',  Validators.compose([ Validators.required])],
      mae_form: ['',  Validators.compose([ Validators.required, Validators.minLength(12)])],
      pai_form: [''],
      cep_form: ['',  Validators.compose([ Validators.required])],
      logradouro_form: ['',  Validators.compose([ Validators.required])],
      numero_form: ['',  Validators.compose([ Validators.required])],
      bairro_form: ['',  Validators.compose([ Validators.required])],
      estado_form: [{value:'',disabled:true},  Validators.compose([ Validators.required])],
      cidade_form: [{value:'',disabled:true},  Validators.compose([ Validators.required])],
      telefone_form: [''],
      celular_form: ['',  Validators.compose([ Validators.required])],
      email_form: ['',  Validators.compose([ Validators.required, Validators.email, Validators.maxLength(50)])],
      email2_form: ['',Validators.compose([ Validators.email, Validators.maxLength(50)])]
    });

    this.formDocumentos = this.fb.group({
      rg_form: ['',  Validators.compose([ Validators.required])],
      data_emissao_form: ['',  Validators.compose([ Validators.required, dataValidator])],
      orgao_form: ['',  Validators.compose([ Validators.required])],
      uf_emissor_form: ['',  Validators.compose([ Validators.required])],
      cnh_form: ['',Validators.compose([Validators.minLength(11)])],
      categoria_cnh_form: [''],
      uf_cnh_form: [''],
      data_validade_form: ['', Validators.compose([dataValidator])],
      carteira_form: ['',  Validators.compose([ Validators.required])],
      serie_form: ['',  Validators.compose([ Validators.required])],
      // data_emissao_ctps_form: ['',  Validators.compose([ Validators.required])],      
      uf_ctps_form: ['',  Validators.compose([ Validators.required])],
      pis_form: ['', Validators.compose([pisValidator,Validators.required])],
      titulo_form: ['',Validators.compose([ Validators.required])],
      zona_form: ['',Validators.compose([ Validators.required])],
      secao_form: ['',Validators.compose([ Validators.required])],
      banco_form: ['',Validators.compose([ Validators.required])],
      agencia_form: ['',Validators.compose([ Validators.required])],
      operacao_form: ['',Validators.compose([ Validators.required])],
      conta_form: ['',Validators.compose([ Validators.required])]
    });

  }

  modelChanged(event: any){
    const cnh = this.formDocumentos.get("cnh_form").value;
    const categoria = this.formDocumentos.get("categoria_cnh_form").value;
    const uf = this.formDocumentos.get("uf_cnh_form").value;
    const data = this.formDocumentos.get("data_validade_form").value;

    if(cnh == "" && categoria == "" && uf == "" && data == ""){

      this.formDocumentos.get("cnh_form").setValidators([Validators.minLength(11)]);
      this.formDocumentos.get("categoria_cnh_form").setValidators(null);
      this.formDocumentos.get("uf_cnh_form").setValidators(null);
      this.formDocumentos.get("data_validade_form").setValidators(null);
    }else{
      this.formDocumentos.get("cnh_form").setValidators([Validators.required, Validators.minLength(11)]);
      this.formDocumentos.get("categoria_cnh_form").setValidators([Validators.required]);
      this.formDocumentos.get("uf_cnh_form").setValidators([Validators.required]);
      this.formDocumentos.get("data_validade_form").setValidators([Validators.required]);
    }
    this.formDocumentos.get("cnh_form").updateValueAndValidity();
    this.formDocumentos.get("categoria_cnh_form").updateValueAndValidity();
    this.formDocumentos.get("uf_cnh_form").updateValueAndValidity();
    this.formDocumentos.get("data_validade_form").updateValueAndValidity();

  }

  mostrarIcon:Boolean = false;
  conferenciaCheckbox: boolean = false;
  assinaturaCheckbox: boolean = false;
  exameCheckbox: boolean = false;

  clickStep: boolean = false;


  verificarSalt(){
    console.log('entrou na função verificarSalt()')

    this.carregandoLogin = true;

    const {numOrd_form, senha_form} = this.formSalt.value;
    const codTarefa = this.activatedRoute.snapshot.paramMap.get("codTarefa");
    const filial = this.activatedRoute.snapshot.paramMap.get("empfil");
    const tpProc = this.activatedRoute.snapshot.paramMap.get("tpProc");
    const admUser = atob(this.activatedRoute.snapshot.paramMap.get("admUser")).replace('"',"").replace('"',"");

    if(this.formSalt.invalid){
      Swal("Aviso", "Preencha todos os campos.", 'warning');
      this.carregandoLogin = false;
      return false;
    }
    this.continuacaoCadastroService.verificarSalt(numOrd_form.trim(), senha_form.trim(), filial, codTarefa, tpProc, admUser)
    .subscribe(
      data=> {
        console.log(data);
        this.identificado=true;
        // this.step = data["Etapa"] == 3 ? 2 : data["Etapa"] ; // 012 no html como etapa mas o banco vem 123 :@
        // this.stepcount = data["Etapa"] == 3 ? 2 : data["Etapa"] ; 

        // if(this.step > 1){
        //   this.cadastro = JSON.parse(JSON.stringify(data));
        //   this.carregarTela2();
        // }
        
        // localStorage.setItem("salt", senha_form);
        // localStorage.setItem("numord", numOrd_form);
        // localStorage.setItem("admUser", admUser);

        // localStorage.setItem("filial_atual", data["Filial"]["Codigo"]);
        // localStorage.setItem("matricula", data["Matricula"]);
        // this.identificado=true;
        // this.cadastro_etapa_2.Matricula = data["Matricula"];
        // this.cadastro_etapa_2.NumeroDeOrdem = numOrd_form;
        // this.cadastro_etapa_2.Filial.Codigo = data["Filial"]["Codigo"];

        // this.cadastro_etapa_3.Matricula = data["Matricula"];
        // this.cadastro_etapa_3.NumeroDeOrdem = numOrd_form;
        // this.cadastro_etapa_3.Filial.Codigo = data["Filial"]["Codigo"];
        // this.buscarEscolaridade();
        this.carregandoLogin = false;

      },
      error =>  {
        this.validaErro.retornoErro(error);
        this.carregandoLogin = false;
      }
    )
  }

  carregarTela2(){
    const { Contato } = this.cadastro.Pessoal;
    
    Contato.Email2 = Contato.Email2 == "0" ? "" : Contato.Email2;
    Contato.DDDTelefone = Contato.DDDTelefone == "0" ? "" : Contato.DDDTelefone;
    Contato.Telefone = Contato.Telefone == "0" ? "" : Contato.Telefone;
    Contato.Telefone = Contato.Telefone == "0" ? "" : Contato.Telefone;

    this.buscarCep(this.cadastro.Pessoal.Endereco.Cep,0);

    this.celular_form = Contato.DDDCelular + Contato.Celular;
    this.telefone_form = Contato.DDDTelefone + Contato.Telefone;
  }

  // Lista os cadastro pendente do usuário


  validarEtapa(){
    if(this.step == 1){
      this.cadastro.Pessoal.DadosBasicos.DataNascimento.ToDate ="";
      this.cadastro.Pessoal.DadosBasicos.GrauDeInstrucao.Valor = "";
      this.cadastro.Pessoal.DadosBasicos.Nacionalidade.Codigo = "";
      this.cadastro.Pessoal.DadosBasicos.Etnia.Descricao = "";
      this.cadastro.Pessoal.DadosBasicos.Sexo.Valor = "";
      this.cadastro.Pessoal.DadosBasicos.EstadoCivil.Valor = "";
      this.cadastro.Pessoal.DadosBasicos.NomeMae = "";
      this.cadastro.Pessoal.DadosBasicos.NomePai = "";
      this.cadastro.Pessoal.Endereco.Cep = "";
      this.cadastro.Pessoal.Endereco.Logradouro = "";
      this.cadastro.Pessoal.Endereco.Numero = "";
      this.cadastro.Pessoal.Endereco.Bairro = "";
      this.cadastro.Pessoal.Endereco.Estado = "";
      this.cadastro.Pessoal.Endereco.Cidade = "";
      this.cadastro.Pessoal.Contato.Email1 = "";
      this.cadastro.Pessoal.Contato.Email2 = "";

      this.formDadosPessoais.get('estado_form').disable();
      this.formDadosPessoais.get('cidade_form').disable();

    }
  }

  validarTelefonesPendente(){
    if(this.cadastro.Pessoal.Contato.DDDTelefone.trim() != "" && (this.cadastro.Pessoal.Contato.Telefone.trim() != "" && this.cadastro.Pessoal.Contato.Telefone.trim() != "NÃO INFORMADO")){
      this.telefone_form = this.cadastro.Pessoal.Contato.DDDTelefone.trim() + this.cadastro.Pessoal.Contato.Telefone.trim();
    }else{
      this.telefone_form = "";
    }

    if((this.cadastro.Pessoal.Contato.DDDCelular.trim() != "" && this.cadastro.Pessoal.Contato.DDDCelular.trim() != "0") && (this.cadastro.Pessoal.Contato.Celular.trim() != "" && this.cadastro.Pessoal.Contato.Celular.length == 9)){
      this.celular_form = this.cadastro.Pessoal.Contato.DDDCelular.trim() + this.cadastro.Pessoal.Contato.Celular;
    }else{
      this.celular_form = "";
    }
  }

  // ***********************************
  // MÃTODOS PARA A ETAPA 2 DO CADASTRO
  // ***********************************

  buscarEscolaridade(){
    this.continuacaoCadastroService.buscarEscolaridade()
    .subscribe(
      data  => {
        this.escolaridade = JSON.parse(JSON.stringify(data));
        this.buscarNacionalidade();
      },
      error  => { 
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarNacionalidade(){
    this.continuacaoCadastroService.buscarNacionalidade()
    .subscribe(
      data  => {
        this.nacionalidade = JSON.parse(JSON.stringify(data));
        this.buscarCor();
      },
      error  => { 
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarCor(){
    this.continuacaoCadastroService.buscarCor()
    .subscribe(
      data  => {
        this.cor = JSON.parse(JSON.stringify(data));
        this.buscarSexo();
      },
      error  => { 
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarSexo(){
    this.continuacaoCadastroService.buscarSexo()
    .subscribe(
      data  => {
        this.sexo = JSON.parse(JSON.stringify(data));
        this.buscarEstadoCivil();
      },
      error  => { 
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarEstadoCivil(){
    this.continuacaoCadastroService.buscarEstadoCivil()
    .subscribe(
      data  => {
        this.estadoCivil = JSON.parse(JSON.stringify(data));
        this.spinner.hide();
        //this.buscarCep(this.cadastro.Pessoal.Endereco.Cep,1);
        //this.buscarEstado();
      },
      error  => { 
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarCep(cod_cep,tipo){
    var cep = cod_cep.target.value;
    cep = cep.replace("-", "")
    console.log(cod_cep)

    if (cep.trim() == '' && tipo == 0){
      Swal("Cep Inválido.", "Digite um Cep para buscar o endereço.", 'warning');
      this.zerarEndereco();
    }else{
      if(this.step > 1 || tipo == 0){
        this.carrega_cep = true;
        this.continuacaoCadastroService.buscarCep(cep)
        .subscribe(
          data  => {
            this.cep = JSON.parse(JSON.stringify(data));

            if(this.cep.Logradouro.trim() == ''){
              Swal("Cep Inválido.", "Digite um Cep existente para buscar o endereço.", 'warning');
              this.zerarEndereco();
              this.carrega_cep = false;
            }else{
              this.formDadosPessoais.controls['logradouro_form'].setValue(this.cep.Logradouro);
              this.formDadosPessoais.controls['bairro_form'].setValue(this.cep.Bairro);
              this.buscarEstado();
            }
            this.carrega_cep = false;
          },
          error  => { 
            this.carrega_cep = false;
            this.validaErro.retornoErro(error);
          }
        );
      }
    }
  }

  buscarEstado(){
    this.continuacaoCadastroService.buscarEstado()
    .subscribe(
      data  => {
        this.estado = JSON.parse(JSON.stringify(data));
        this.formDadosPessoais.controls['estado_form'].setValue(this.cep.Uf);
        this.formDadosPessoais.get('estado_form').enable();

        this.buscarCidade(this.estado,this.cep.Uf);
      },
      error  => { 
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarCidade(codigo,uf){
    
    this.buscarCodigoEstado(codigo,uf);

    this.continuacaoCadastroService.buscarCidade(this.codigo_uf)
    .subscribe(
      data  => {
        this.cidade = JSON.parse(JSON.stringify(data));
        this.formDadosPessoais.controls['cidade_form'].setValue(this.cep.Cidade);
        this.formDadosPessoais.get('cidade_form').enable();
        //
        this.buscarUf();
      },
      error  => { 
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

   // ***********************************
  // MÉTODOS PARA A ETAPA 3 DO CADASTRO
  // ***********************************

  buscarUf(){
    this.continuacaoCadastroService.buscarEstado()
    .subscribe(
      data  => {
        this.ufRg = JSON.parse(JSON.stringify(data));
        this.ufCtps = JSON.parse(JSON.stringify(data));
        this.ufCnh = JSON.parse(JSON.stringify(data));
        this.buscarCategoriaCnh();
      },
      error  => { 
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }
  
  buscarCategoriaCnh(){
    this.continuacaoCadastroService.buscarCategoriaCnh()
    .subscribe(
      data  => {
        this.categoriaCnh = JSON.parse(JSON.stringify(data));
        this.buscarBanco();
      },
      error  => { 
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarBanco(){
    this.continuacaoCadastroService.buscarBanco()
    .pipe(distinct())
    .subscribe(
      data  => {
        this.banco = JSON.parse(JSON.stringify(data));
        this.spinner.hide();
      },
      error  => { 
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarAgencia(event) {
    if (!this.formDocumentos.get('banco_form').value) {
      this.formDocumentos.get('agencia_form').setValue('');
    } else {
      this.codigoAgencia = event.target.value;
      this.continuacaoCadastroService.buscarAgencia(this.codigoAgencia).subscribe(data => {
        if(data.length === 0){
          this.formDocumentos.get('agencia_form').setValue('');
        } else {
          this.agencias = data;
        }
      }, error => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      });
    }
  }



  // ***********************************
  // MÉTODOS DE INSERÇÃO E UPDATE DAS ETAPAS
  // ***********************************

  // Altera os dados das etapas
  updateCadastro(dadosEtapa){
    
    this.spinner.show();
    this.continuacaoCadastroService.updateEtapa(dadosEtapa)
    .subscribe(
      data  => {
        this.retornoUpdate();
        //this.spinner.hide();
      },
      error  => { 
        this.spinner.hide();
        this.step --; 
        this.stepcount --;
        window.scrollTo(0, 0);
        //this.validaErro.retornoErro(error);
        Swal(error.message,error,'error');
      }
    );
  }

  // ***************************************************
  // MÃ©todos das validaÃ§Ãµes das etapas
  // ***************************************************


  // Faz a validaÃ§Ã£o da etapa pra chamar o mÃ©todo do momento
  validaMetodoEtapa(etapa:number){
    if(etapa == 2){
      this.validarEtapa2();
    }else if(etapa == 3){
      this.validarEtapa3();
    }
  }

  retornoUpdate(){
    if (this.step == 2){
      this.buscarUf();
    }else if(this.step == 3){
      this.spinner.hide();
      this.finalizarCadastro();
    }
  }

  validarEtapa2(){
    if(this.formDadosPessoais.invalid){
      Swal('Preencha todos os campos!', "Verifique os campos incorretos.", 'warning');
      this.validaEtapa2 = true;
      this.step --; 
      this.stepcount --;
      window.scrollTo(0, 0); 
    } else{
      this.setarValoresEtapa2();
      this.updateCadastro(this.cadastro_etapa_2);
    }
  }

  validarEtapa3(){
    if(this.formDocumentos.invalid){
      Swal('Preencha todos os campos!',"Verifique os campos incorretos. ", 'warning');
      this.validaEtapa3 = true;
      this.step --; 
      this.stepcount --;
      window.scrollTo(0, 0); 
    }else if(this.upload.vazio()){
      Swal('Aviso!', "Envie ao menos um documento. ", 'warning');
      this.step --; 
      this.stepcount --;
      window.scrollTo(0, 0); 
    }else{
      this.setarValoresEtapa3();
      this.cadastro_etapa_3.Documentos.UploadDocumentos = this.upload.retornaFiles();
      this.updateCadastro(this.cadastro_etapa_3);
    }
  }

  finalizarCadastro(){
    Swal({
      title: 'Cadastro realizado com sucesso!',
      text: "Aguardando envio de informações do Benefí­cio.",
      icon: 'success',
      closeOnClickOutside: false,
      closeOnEsc: false
    }).then((result) => {
      if (result) {
        window.location.reload(); // Danilo
        //this.router.navigate(["/colaborador"]);
      }
    })
  }


  // ***************************************************
  // MÃ©todos genÃ©ricos para validar rotinas e campos
  // ***************************************************

  zerarEndereco(){
    this.formDadosPessoais.controls['logradouro_form'].setValue('');
    this.formDadosPessoais.controls['bairro_form'].setValue('');
    this.formDadosPessoais.controls['cidade_form'].setValue('');
    this.formDadosPessoais.controls['estado_form'].setValue('');
    this.formDadosPessoais.controls['numero_form'].setValue('');
    this.formDadosPessoais.get('estado_form').disable();
    this.formDadosPessoais.get('cidade_form').disable();
    this.cidade = [];
    this.estado = [];
  }
  
  desabilitarCamposInformacoesBacicas(tipo){

    if (tipo == 'full'){
      this.formInformacaoBasica.get('setor_form').disable();
      this.formInformacaoBasica.controls['setor_form'].setValue("");
      this.setores = [];

      this.formInformacaoBasica.get('cargo_form').disable();
      this.formInformacaoBasica.controls['cargo_form'].setValue("");
      this.cargos = [];

      this.formInformacaoBasica.get('cargaH_form').disable();
      this.formInformacaoBasica.controls['cargaH_form'].setValue("");
      this.cargaHoraria = [];

      this.formInformacaoBasica.get('horaT_form').disable();
      this.formInformacaoBasica.controls['horaT_form'].setValue("");
      this.horaTrabalho = [];
      this.cargaHorariaFilter = [];

      this.formInformacaoBasica.get('nivel_form').disable();
      this.formInformacaoBasica.controls['nivel_form'].setValue("");
      this.nivel = [];
    }else if(tipo == 'setor'){
      this.formInformacaoBasica.get('cargo_form').disable();
      this.formInformacaoBasica.controls['cargo_form'].setValue("");
      this.cargos = [];

      this.formInformacaoBasica.get('cargaH_form').disable();
      this.formInformacaoBasica.controls['cargaH_form'].setValue("");
      this.cargaHoraria = [];

      this.formInformacaoBasica.get('horaT_form').disable();
      this.formInformacaoBasica.controls['horaT_form'].setValue("");
      this.horaTrabalho = [];
      this.cargaHorariaFilter = [];

      this.formInformacaoBasica.get('nivel_form').disable();
      this.formInformacaoBasica.controls['nivel_form'].setValue("");
      this.nivel = [];
    }else if(tipo == 'cargo'){
      this.formInformacaoBasica.get('cargaH_form').disable();
      this.formInformacaoBasica.controls['cargaH_form'].setValue("");
      this.cargaHoraria = [];

      this.formInformacaoBasica.get('horaT_form').disable();
      this.formInformacaoBasica.controls['horaT_form'].setValue("");
      this.horaTrabalho = [];
      this.cargaHorariaFilter = [];
    }else if (tipo == 'cargaHora'){
      this.formInformacaoBasica.get('horaT_form').disable();
      this.formInformacaoBasica.controls['horaT_form'].setValue("");
      //this.horaTrabalho = [];
    }

  }

  somenteNumero(event) {
    return ((event.charCode >= 48 && event.charCode <= 57) /*|| (event.keyCode == 45 || event.charCode == 46)*/)
  }

  compararDatas(data1:string,data2:string,etapa,campo1,campo2){

    if(data1.trim() != '' && data2.trim() != ''){

      data1 = this.validacaoService.converteDatas(data1,1);
      data2 = this.validacaoService.converteDatas(data2,1);
    } 
  }

  // ***************************************************
  // MÃ©todos para setar os valores do cadastro
  // ***************************************************

  setarValoresEtapa1(){
    this.cadastro_etapa_1.Etapa = '1';
    //this.cadastro_etapa_1.Filial.Codigo = '100001';
    this.cadastro_etapa_1.Filial.Codigo = this.filial;
    this.cadastro_etapa_1.InformacoesBasicas.NomeCompleto = this.formInformacaoBasica.get('nome_completo').value;
    this.cadastro_etapa_1.InformacoesBasicas.Departamento.Cod = this.formInformacaoBasica.get('departamento_form').value;
    this.cadastro_etapa_1.InformacoesBasicas.Setor.Cod = this.formInformacaoBasica.get('setor_form').value;
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.SetData = this.validacaoService.converteDatas(this.formInformacaoBasica.get('data_admissao_form').value,0);
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.ToDate = this.validacaoService.converteDatas(this.formInformacaoBasica.get('data_admissao_form').value,1);
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.IsDate = "true";
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.Empty = "false";
    this.cadastro_etapa_1.InformacoesBasicas.Vinculo.Codigo = this.formInformacaoBasica.get('vinculo_form').value;
    this.cadastro_etapa_1.InformacoesBasicas.Cargo.Codigo = this.formInformacaoBasica.get('cargo_form').value;
    this.cadastro_etapa_1.InformacoesBasicas.Salario.CodigoNivel = this.formInformacaoBasica.get('nivel_form').value;
    this.buscarSalarioNivel(this.nivel,this.formInformacaoBasica.get('nivel_form').value);
    this.cadastro_etapa_1.InformacoesBasicas.Salario.Valor = this.salario_nivel;
    this.cadastro_etapa_1.InformacoesBasicas.HorarioTrabalho.Codigo = this.formInformacaoBasica.get('cargaH_form').value;
    this.cadastro_etapa_1.InformacoesBasicas.Sindicato.Codigo = this.formInformacaoBasica.get('sindicato_form').value;
  }

  setarValoresEtapa2(){
    this.cadastro_etapa_2.Etapa = 2;
    //this.cadastro_etapa_2.Filial.Codigo = 100001;
    this.cadastro_etapa_2.Filial.Codigo = this.filial;
    this.cadastro_etapa_2.Documentos.Cpf = this.cadastro.Documentos.Cpf
    this.cadastro_etapa_2.Pessoal.DadosBasicos.DataNascimento.SetData = this.validacaoService.converteDatas(this.formDadosPessoais.get('data_nascimento_form').value,0); 
    this.cadastro_etapa_2.Pessoal.DadosBasicos.DataNascimento.ToDate = this.validacaoService.converteDatas(this.formDadosPessoais.get('data_nascimento_form').value,1); 
    this.cadastro_etapa_2.Pessoal.DadosBasicos.DataNascimento.IsDate = true;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.DataNascimento.Empty = false;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.GrauDeInstrucao.Valor = this.formDadosPessoais.get('escolaridade_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.Nacionalidade.Codigo = this.formDadosPessoais.get('nacionalidade_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.Etnia.Descricao = this.formDadosPessoais.get('cor_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.Sexo.Valor = this.formDadosPessoais.get('sexo_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.EstadoCivil.Valor = this.formDadosPessoais.get('estado_civil_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.NomeMae = this.formDadosPessoais.get('mae_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.NomePai = this.retornarValorParaIndefinido(this.formDadosPessoais.get('pai_form').value, 0);
    this.cadastro_etapa_2.Pessoal.Endereco.Cep = this.formDadosPessoais.get('cep_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Logradouro = this.formDadosPessoais.get('logradouro_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Numero = this.formDadosPessoais.get('numero_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Bairro = this.formDadosPessoais.get('bairro_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Estado = this.formDadosPessoais.get('estado_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Cidade = this.formDadosPessoais.get('cidade_form').value;
    this.cadastro_etapa_2.Pessoal.Contato.DDDCelular = this.retornarValorParaIndefinido(this.formDadosPessoais.get('celular_form').value, 1);
    this.cadastro_etapa_2.Pessoal.Contato.Celular = this.retornarValorParaIndefinido(this.formDadosPessoais.get('celular_form').value, 2) ;
    this.cadastro_etapa_2.Pessoal.Contato.DDDTelefone = this.retornarValorParaIndefinido(this.formDadosPessoais.get('telefone_form').value, 1);
    this.cadastro_etapa_2.Pessoal.Contato.Telefone = this.retornarValorParaIndefinido(this.formDadosPessoais.get('telefone_form').value, 3);
    this.cadastro_etapa_2.Pessoal.Contato.Email1 = this.formDadosPessoais.get('email_form').value;
    this.cadastro_etapa_2.Pessoal.Contato.Email2 = this.retornarValorParaIndefinido(this.formDadosPessoais.get('email2_form').value, 0);
  }

  retornarValorParaIndefinido(valor: string, tipo: number)
  {
    if      (valor == undefined || valor == null) valor = "";
    else if (valor != "" && tipo == 0)            valor = valor.trim();
    else if (valor != "" && tipo == 1)            valor = valor.substring(0, 2);
    else if (valor != "" && tipo == 2)            valor = valor.substring(2, 11);
    else if (valor != "" && tipo == 3)            valor = valor.substring(2, 10);
    return valor;
  }

  setarValoresEtapa3(){
    this.cadastro_etapa_3.Etapa = 3;
    //this.cadastro_etapa_3.Filial.Codigo = 100001;
    this.cadastro_etapa_3.Filial.Codigo = this.filial;
    this.cadastro_etapa_3.Documentos.Rg.Numero = this.formDocumentos.get('rg_form').value; 
    this.cadastro_etapa_3.Documentos.Rg.DataEmissaoRg.SetData = this.validacaoService.converteDatas(this.formDocumentos.get('data_emissao_form').value,0); 
    this.cadastro_etapa_3.Documentos.Rg.DataEmissaoRg.ToDate = this.validacaoService.converteDatas(this.formDocumentos.get('data_emissao_form').value,1); 
    this.cadastro_etapa_3.Documentos.Rg.DataEmissaoRg.IsDate = true;
    this.cadastro_etapa_3.Documentos.Rg.DataEmissaoRg.Empty = false;
    this.cadastro_etapa_3.Documentos.Rg.OrgaoEmissor = this.formDocumentos.get('orgao_form').value; 
    this.cadastro_etapa_3.Documentos.Rg.UfEmissaoRg = this.formDocumentos.get('uf_emissor_form').value; 
    this.cadastro_etapa_3.Documentos.Cnh.Numero = this.formDocumentos.get('cnh_form').value; 
    this.cadastro_etapa_3.Documentos.Cnh.CategoriaCnh = this.formDocumentos.get('categoria_cnh_form').value; 
    this.cadastro_etapa_3.Documentos.Cnh.UF = this.formDocumentos.get('uf_cnh_form').value;
    this.cadastro_etapa_3.Documentos.Cnh.DataValidadeCnh.SetData = this.validacaoService.converteDatas(this.formDocumentos.get('data_validade_form').value,0);
    this.cadastro_etapa_3.Documentos.Cnh.DataValidadeCnh.ToDate = this.validacaoService.converteDatas(this.formDocumentos.get('data_validade_form').value,1);
    this.cadastro_etapa_3.Documentos.Cnh.DataValidadeCnh.IsDate = true;
    this.cadastro_etapa_3.Documentos.Cnh.DataValidadeCnh.Empty = false;
    this.cadastro_etapa_3.Documentos.Clt.CarteiraDeTrabalho = this.formDocumentos.get('carteira_form').value; 
    this.cadastro_etapa_3.Documentos.Clt.NumeroSerieCtps = this.formDocumentos.get('serie_form').value; 
    this.cadastro_etapa_3.Documentos.Clt.Pis = this.formDocumentos.get('pis_form').value; 
    this.cadastro_etapa_3.Documentos.Clt.UfCtps = this.formDocumentos.get('uf_ctps_form').value; 
    this.cadastro_etapa_3.Documentos.TituloEleitor.Numero = this.formDocumentos.get('titulo_form').value; 
    this.cadastro_etapa_3.Documentos.TituloEleitor.ZonaEleitoral = this.formDocumentos.get('zona_form').value; 
    this.cadastro_etapa_3.Documentos.TituloEleitor.SecaoEleitoral = this.formDocumentos.get('secao_form').value; 
    this.cadastro_etapa_3.DadosBancarios.Banco = this.formDocumentos.get('banco_form').value; 
    this.cadastro_etapa_3.DadosBancarios.Agencia = this.formDocumentos.get('agencia_form').value; 
    this.cadastro_etapa_3.DadosBancarios.Operacao = this.formDocumentos.get('operacao_form').value; 
    this.cadastro_etapa_3.DadosBancarios.Conta = this.formDocumentos.get('conta_form').value; 
  }


  // ***************************************************
  // MÃ©todos para validar os steps
  // ***************************************************


  PreviewStep(){
    
    this.step= this.step - 1;
    window.scrollTo(0, 0); 
   
  }
  
  NextStep(){
    // console.log(this.step);
    // console.log(this.stepcount);
    if( this.step <= this.stepcount )
    {
      // console.log("entrou 1");
      this.step=this.step + 1; 
      window.scrollTo(0, 0);
    }
    
    if(this.stepcount < this.step )
    {
      // console.log("entrou 2");
      this.stepcount = this.stepcount + 1;
    }

    // Verifica a etapa e chama o mÃ©todo recorrente
    this.validaMetodoEtapa(this.step);
  }

  inicioStep()  {
    if(this.step > 0){
      this.step= 0;
      
      window.scrollTo(0, 0); 
     }
    this.step = this.step;
    //this.insertInformacaoBasica();
    
  }
  primeiroStep() {
    if(this.step > 1 ||  this.stepcount > 1 ){
      this.step= 1;
      this.clickStep = true;
      window.scrollTo(0, 0); 
     
    }
  }
  segundoStep()  {
    if(this.step > 2 || this.stepcount > 2){
    this.step= 2
    this.clickStep = true;
    window.scrollTo(0, 0); 
    }
  }


// ***************************************************
// MÃ©todos para a rotina dos retornos das funÃ§Ãµes
// ***************************************************
    
  removerRepetido(dados){
    var count = 0;
    var horas = [];

    dados.forEach(obj => {
      if(count != 0){
        if(horas.indexOf(obj.CargaHorariaSemana) == -1) {  
          horas.push(obj.CargaHorariaSemana);
          this.cargaHorariaFilter.push({cod:obj.CargaHorariaSemana,Codigo:obj.Codigo});
        }
      }else{
        horas.push(obj.CargaHorariaSemana);
        this.cargaHorariaFilter.push({cod:obj.CargaHorariaSemana,Codigo:obj.Codigo});
      }
      count ++;
    });
  }
  
  buscarCodigoHora(dados){

      var codigoCargaHoraria = parseInt(dados);
      this.horarioTrabalho = this.cargaHorariaFilter.filter(element => {
        if(element.cod == codigoCargaHoraria){
          this.retornoHorario = element.Codigo;
          return true;
        }
      });
      this.formInformacaoBasica.get('horaT_form').enable();

  }

  buscarSalarioNivel(estado,codigo):any{
    estado.some(obj => {
      if(obj.CodigoNivel == codigo ){
        this.salario_nivel = obj.Valor;
        return true;
      }
    });
  }

  buscarCodigoEstado(estado,uf):any{
    estado.some(obj => {
      if(obj.SiglaUf == uf ){
        this.codigo_uf = obj.Codigo;
        return true;
      }
    });
  }

  deletarIcon(indice){
    let numeroIndex = indice
    this.urls.splice(numeroIndex, 1)
  }

  mascaraHora(val){
    var hora = val.substring(0,2);
    var minuto = val.substring(2,4);
    return hora + ":" + minuto;
  }

  formataReal( valor ) {
    valor = String(valor);
    var salario = valor.indexOf(".");
    if(salario == -1){
      salario = valor + ".00";
    }

    //Instanciando o objeto
    var formatter = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
    /*FORMATA CONFORME CONFIGURAÃÃES DEFINIDAS NO formatter*/
    salario = formatter.format(salario); 
    //Coloca o valor formatado no campo resultado

    return salario;
  }

  verificaDados(dados,nome,etapa,tipo){

    // 1 para data 
    // 2 para cpf
    let aux =  0;
    var data = dados.target.value;
    var elemento = nome;

     // if(elemento === 'data_admissao_form'){
    //   if(!this.ValidarValoresDatas(data, 1)){
    //     aux = 1
    //   }
    // }else
    if(elemento ==='data_emissao_ctps_form' || elemento === 'data_emissao_form'){
      if(!this.ValidarValoresDatas(data, 2)){
        aux = 1
      }
    }
    else if(elemento === 'data_nascimento_form' ){
      if(!this.ValidarValoresDatas(data, 3)){
        aux = 1
        // Swal(' Não é Possível cadastrar menores de 16 anos', "Por favor, preencha uma data válida.", 'warning');
      }
    }else if (elemento === 'data_validade_form'){
      if(!this.ValidarValoresDatas(data, 4)){
        aux = 1
      }
    }

    if (tipo == 1){
      if(!this.validacaoService.validarData(data) || aux === 1){
        //Swal('Data invÃ¡lida!', "Por favor, preencha uma data vÃ¡lida.", 'warning');
        if(etapa == 1){
          this.formInformacaoBasica.controls[elemento].setValue("");
        }else if(etapa == 2){
          this.formDadosPessoais.controls[elemento].setValue("");
        }else if(etapa == 3){
          this.formDocumentos.controls[elemento].setValue("");
        }
      }
    }else if(tipo == 2){
      if(!this.validacaoService.validarCpf(data)){
        // if(etapa == 1){
        //   this.formInformacaoBasica.controls[elemento].setValue("");
        // }else if(etapa == 2){
        //   this.formDadosPessoais.controls[elemento].setValue("");
        // }else if(etapa == 3){
        //   this.formDocumentos.controls[elemento].setValue("");
        // }
        this.validar_cpf_blur = true;
      }else{
        this.validar_cpf_blur = false;
      }
    }else if(tipo == 3){
      if(!this.validacaoService.validarPis(data)){
        // if(etapa == 1){
        //   this.formInformacaoBasica.controls[elemento].setValue("");
        // }else if(etapa == 2){
        //   this.formDadosPessoais.controls[elemento].setValue("");
        // }else if(etapa == 3){
        //   this.formDocumentos.controls[elemento].setValue("");
        // }
        this.validar_pis_blur = true;
      }else{
        this.validar_pis_blur = false;
      }
    }
  }
  
  validarDataAtual(data){

    
    let dateAtual: Date = new Date();  
    var dia, mes, ano;
    var teste = 'T00:00:00'
    

    dia = data.substring(0, 2);
    mes = data.substring(3, 5);
    ano = data.substring(6, 10);
    
    
    
    var time = new Date(ano+'-'+ mes + '-'+dia + teste);

    if (dateAtual >= time){
      
      return true;
      
    }else {
      
      return false
    }
   
    // outraData.setDate(time.getDate()); // Adiciona os dias
    // console.log(outraData.getDate(),  outraData.getMonth(), outraData.getFullYear())

  }

  ValidarValoresDatas(data, tipo){
 
    var dia, mes, ano;
    var teste = 'T00:00:00'

    dia = data.substring(0, 2);
    mes = data.substring(3, 5);
    ano = data.substring(6, 10);

    let dateAtual: Date = new Date(); 
    var time = new Date(ano+'-'+ mes + '-'+dia + teste);

    let auxTime =  time.getFullYear()
    let auxDate = dateAtual.getFullYear()
    if(!this.validacaoService.validarData(data)){
      return true
    }
      
    if (tipo === 1){
        if (dateAtual <= time){          
          return true;
          
        }else {
          Swal('Data de Admissão Incorreta ', "Por favor, preencha uma data válida.", 'warning');       
          return false
        }
    }else if (tipo === 2){
      if (dateAtual >= time){          
        return true;
        
      }else {
        Swal('Data de Emissão Incorreta ', "Por favor, preencha uma data válida.", 'warning');       
        return false
      }
    }else if (tipo === 3){
        if ( (auxDate - this.idadeMinima)  >= auxTime ){
        
          return true;
      
        }else {
          Swal(' Não é Possível cadastrar menores de 14 anos', "Por favor, preencha uma data válida.", 'warning');
          return false
        }
    }else{  
      if ( (auxDate -5)  <= auxTime && (auxDate +5)  >= auxTime  ){
      
        return true;
    
      }else {
        Swal('Validade não Aceita', "Por favor, preencha uma data válida.", 'warning');
        return false
      }
    } 
  }
  



}
