import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  NgForm,
  FormControlName,
  AbstractControl,
  FormsModule,
} from '@angular/forms';
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { Parametro } from 'src/app/service/interfaces/Parametro';
import Swal from 'sweetalert';
import { Data } from 'src/app/service/interfaces/Data';
import { ErroService } from 'src/app/service/erros/erro.service';
import { ProcessosService } from './processos.service';
import { AnexoComponent } from '../anexo/anexo.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { TipoUsuario } from 'src/app/service/enum/tipoUsuario';
import { Router, ActivatedRoute } from '@angular/router';
import { ObservacoesFichaFuncionalComponent } from '../componentes-funcionais/observacoes-ficha-funcional/observacoes-ficha-funcional.component';
import { validarDataAtualMenorIgual } from '../../service/Validators/dataValidatorMenorIgual';
import { validarDataFimMaiorIgual } from 'src/app/service/Validators/dataFimMaiorIgualValidator';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { PermissoesService } from 'src/app/service/permissoes/permissoes.service';
import { validarDataInicioReflexo } from 'src/app/service/Validators/efeitoRetroativo';
@Component({
  selector: 'app-processos',
  templateUrl: './processos.component.html',
  styleUrls: ['./processos.component.css'],
  providers: [PermissoesService],
})
export class ProcessosComponent implements OnInit {
  @ViewChild(AnexoComponent, { static: false }) anexoComponent: AnexoComponent;
  @ViewChild(ObservacoesFichaFuncionalComponent)
  modalObservacoes: ObservacoesFichaFuncionalComponent;
  @ViewChild('cadastroProcessoModal') cadastroProcessoModal: ElementRef;
  localStorage: LocalStorage;
  crypto: Crypto;
  navigation: any;
  formProcessos: FormGroup;
  formProcessosAux: FormGroup;

  listaSituacao: Situacao[];
  situacaoAtual: Situacao;
  listaProcessos: object[] = null;
  processoSelecionado: Processo | null = null;
  listaTipoReflexo: any[];
  efeitoRetroativo: boolean = false;

  numOrd: number = null;
  matricula: number = null;
  matriculaAux: any;
  nomeAux: any;
  processo: string;
  idExcluindo: string = '';
  tipoProcessamento: string = 'EM';
  searchText: string = '';

  loadingColaborador: boolean = false;
  editar: boolean = false;
  validaMatricula: boolean = false;
  temErro: boolean = false;
  clientePublico: boolean;
  numProcesso: number;

  constructor(
    public fb: FormBuilder,
    private processosService: ProcessosService,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private renderer: Renderer2,
    public permissoesService: PermissoesService
  ) {
    this.clientePublico = (
      JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util'))) as LocalStorage
    ).ClientePublico;
    this.navigation = this.router.getCurrentNavigation();
  }

  ngOnInit(): void {
    console.log(this.permissoesService.permissoes);

    this.localStorage = this.decryptLocalStorage();
    this.formProcessos = this.fb.group(
      {
        efeitoRetroativo: ['', Validators.required],
        matricula: ['', [Validators.required]],
        nome: [{ value: '', disabled: true }, [Validators.required]],
        numeroProcesso: ['', [Validators.required]],
        assunto: ['', [Validators.required]],
        referenciaFinanceira: [''],
        dataInicio: ['', [Validators.required, validarDataAtualMenorIgual()]],
        dataFinal: [{ value: '', disabled: true }, [Validators.required]],
        situacao: ['', [Validators.required]],
        requerente: ['', [Validators.required]],
        atoPortaria: [''],
        dataAto: [''],
        doe: [''],
        dataDoe: [''],
        tipoReflexo: [''],
        dataInicioReflexo: ['', [validarDataInicioReflexo()]],
        dataFinalReflexo: [''],
        // motivoReabertura:['', [Validators.required]]
      },
      {
        validators: [
          validarDataFimMaiorIgual('dataInicio', 'dataFinal'),
          validarDataFimMaiorIgual('dataInicioReflexo', 'dataFinalReflexo'),
        ],
      }
    );

    this.formProcessos.get('efeitoRetroativo').valueChanges.subscribe((value) => {
      this.efeitoRetroativoChange(value);
    });
    // Observar mudanças no valor de 'dataInicio' e atualizar os validadores de 'dataFinal'
    // this.formProcessos.get('dataInicio').valueChanges.subscribe((newValue) => {
    //   this.formProcessos
    //     .get('dataFinal')
    //     .setValidators([Validators.required, validarDataAtualMenorIgual(newValue)]);
    //   this.formProcessos.get('dataFinal').updateValueAndValidity();
    // });

    this.buscarCompetenciaAberta();

    if (this.navigation.extras.state) {
      this.matricula = this.navigation.extras.state.usuario.matricula;
      this.buscarColaborador(this.matricula);
      this.formProcessos.get('matricula').setValue(this.matricula);

      this.formProcessos.get('matricula').disable();
      // this.matriculaListener(this.navigation.extras.state.usuario);
    }
  }

  buscarCompetenciaAberta() {
    this.processosService.buscarCompetenciaAberta().subscribe(
      (data: any) => {
        this.processosService.competencia = data.competencia;
        this.buscarSituacao();
        this.buscarTipoReflexo();
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  alterarValorDoControle(controle: string, valor: any) {
    this.formProcessos.get(`${controle}`).setValue(valor);
  }

  efeitoRetroativoChange(event: any): void {
    const value = event?.target?.value;
    if (value === 'NAO') {
      this.efeitoRetroativo = false;
    } else {
      this.efeitoRetroativo = true;
    }
    this.formProcessos.get('dataInicioReflexo').updateValueAndValidity();
  }

  buscarSituacao(): void {
    this.processosService.buscarSituacao().subscribe(
      (data: Situacao[]) => {
        this.listaSituacao = data.sort(this.ordenarListas);
      },
      (error) => this.validaErro.retornoErro(error)
    );
  }

  buscarColaborador(matricula: any): void {
    this.formProcessos.get('nome').setValue('');
    if (matricula != '') {
      this.loadingColaborador = true;
      this.processosService.buscarColaborador(matricula).subscribe(
        (data: any) => {
          this.limparTela();
          if (data.Nome !== '') {
            this.formProcessos.get('nome').setValue(data.Nome);
            this.nomeAux = data.Nome;
            // this.numOrd = data.
            this.loadingColaborador = false;
            this.validaMatricula = false;
            this.buscarProcessos(matricula);
          } else {
            this.validaMatricula = true;
            this.loadingColaborador = false;

            Swal('Atenção', 'A matrícula digitada não existe para esta empresa filial.', 'warning');
          }
        },
        (error) => this.validaErro.retornoErro(error)
      );
    } else {
      this.loadingColaborador = false;
      Swal('Atenção', 'Você precisa digitar uma matrícula.', 'warning');
    }
  }

  armazenarNumProcesso(): void {
    this.numProcesso = this.formProcessos.get('numeroProcesso').value;
    console.log(this.numProcesso);
  }

  ordenarListas(a: any, b: any): number {
    if (a.Descricao < b.Descricao) {
      return -1;
    } else if (a.Descricao > b.Descricao) {
      return 1;
    } else {
      return 0;
    }
  }

  buscarProcessos(matricula: string): void {
    this.processosService.buscarProcessos(matricula).subscribe(
      (data: Processo[]) => (this.listaProcessos = data),
      (error) => this.validaErro.retornoErro(error)
    );
  }

  buscarSituacaoNome(codigoSituacao: string) {
    return this.listaSituacao.filter((situacao: Situacao) => situacao.Codigo == codigoSituacao)[0]
      .Descricao;
  }

  buscarTipoReflexo(): void {
    this.processosService.buscarTipoReflexo().subscribe(
      (data: any[]) => {
        this.listaTipoReflexo = data;
      },
      (error) => this.validaErro.retornoErro(error)
    );
  }

  somenteLetras(event) {
    return (
      (event.charCode >= 65 && event.charCode <= 90) ||
      (event.charCode >= 97 && event.charCode <= 122) ||
      event.charCode === 32
    );
  }

  selecionarTipoReflexo() {
    const cod = this.formProcessos.get('tipoReflexo').value;
    let index = this.listaTipoReflexo.findIndex((data) => data.Codigo == cod);
    this.formProcessos.get('assunto').setValue(this.listaTipoReflexo[index].Descricao);
  }

  onRefFinanceiraChange(): void {
    const { referenciaFinanceira } = this.formProcessos.value;

    if (referenciaFinanceira) {
      // this.formProcessos.get('tipoReflexo').setValue('');
      this.formProcessos.get('assunto').disable();
      this.formProcessos.get('dataInicioReflexo').addValidators([Validators.required]);
      this.formProcessos.get('tipoReflexo').addValidators([Validators.required]);
      this.formProcessos.get('efeitoRetroativo').addValidators([Validators.required]);
    } else {
      this.formProcessos.get('efeitoRetroativo').clearValidators();
      this.formProcessos.get('tipoReflexo').clearValidators();
      this.formProcessos.get('dataInicioReflexo').clearValidators();
      this.formProcessos.get('dataInicioReflexo').setValidators([Validators.nullValidator]);
      this.formProcessos.get('dataFinalReflexo').setValidators([Validators.nullValidator]);
      // this.formProcessos.get('dataInicioReflexo').setValidators(Validators.required);

      // this.formProcessos.get('dataFinalReflexo').setValidators(Validators.required);
      this.formProcessos.get('efeitoRetroativo').updateValueAndValidity();
      this.formProcessos.get('tipoReflexo').updateValueAndValidity();
      this.formProcessos.get('dataFinalReflexo').updateValueAndValidity();
      // this.formProcessos.get('assunto').setValue('');
      this.formProcessos.get('assunto').enable();
    }
    this.formProcessos.get('dataInicioReflexo').updateValueAndValidity();
    // this.buscarTipoReflexo();
  }

  onSubmit(form: any): void {
    if (this.formProcessos.get('requerente').value.trim() == '') {
      this.addRequerenteNome();
    }
    // this.verificaData(this.formProcessos.get('dataInicio'), this.formProcessos.get('dataFinal'));
    if (this.formProcessos.invalid) {
      this.formProcessos.markAllAsTouched();
      this.temErro = true;
      Swal('Atenção', 'Verifique campos incorretos', 'warning');
    } else if (this.editar == true) {
      this.salvarAlteracaoProcesso(form);
      this.temErro = false;
    } else {
      this.salvarProcesso(form);
      this.temErro = false;
    }
  }

  verificaData(dataInicial: AbstractControl, dataFinal: AbstractControl) {
    if (dataInicial.value.length == 8 && dataFinal.value.length == 8) {
      const dataInicialInvertida = Number(
        `${dataInicial.value.substr(4, 4)}${dataInicial.value.substr(
          2,
          2
        )}${dataInicial.value.substr(0, 2)}`
      );
      const dataFinalInvertida = Number(
        `${dataFinal.value.substr(4, 4)}${dataFinal.value.substr(2, 2)}${dataFinal.value.substr(
          0,
          2
        )}`
      );

      if (dataInicialInvertida >= dataFinalInvertida) {
        dataFinal.setValue('');
        Swal('Atenção', 'A data final deve ser posterior à inicial.', 'warning');
      }
    }
  }

  salvarProcesso(form: NgForm): void {
    this.spinner.show();

    const processo = this.montarProcesso();

    this.processosService.salvarProcesso(processo).subscribe(
      (data) => {
        const matricula = this.formProcessos.get('matricula').value;
        this.buscarProcessos(matricula);
        Swal('Sucesso', 'Processo salvo com sucesso! ', 'success');
        this.limparTela();
        const botaoFecharModal = this.cadastroProcessoModal.nativeElement.querySelector(
          '#botaoFechar'
        ) as HTMLButtonElement;
        botaoFecharModal.click();
        this.formProcessos.get('assunto').enable();
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  salvarAlteracaoProcesso(form: NgForm): void {
    this.spinner.show();

    const processo = this.montarProcesso(this.processo);
    this.processosService.alterarProcesso(processo).subscribe(
      (data) => {
        const matricula = this.formProcessos.get('matricula').value;
        this.buscarProcessos(matricula);
        Swal('Sucesso', 'Alterações do processo salvas com sucesso! ', 'success');
        this.limparTela();
        const botaoFecharModal = this.cadastroProcessoModal.nativeElement.querySelector(
          '#botaoFechar'
        ) as HTMLButtonElement;
        botaoFecharModal.click();
        this.formProcessos.get('assunto').enable();
        this.editar = false;
        this.processoSelecionado = null;
        this.spinner.hide();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  limparTela() {
    this.formProcessos.reset({
      matricula: this.formProcessos.get('matricula').value,
      nome: this.formProcessos.get('nome').value,
      numeroProcesso: '',
      assunto: '',
      referenciaFinanceira: '',
      dataInicio: '',
      dataFinal: '',
      situacao: '',
      requerente: '',
      atoPortaria: '',
      dataAto: '',
      doe: '',
      dataDoe: '',
      tipoReflexo: 'PAGAR ABONO PERMANÊNCIA',
      dataInicioReflexo: '',
      dataFinalReflexo: '',
    });
    this.listaProcessos = null;
  }

  limparCampos() {
    // this.formProcessos.get('numeroProcesso').setValue('');
    // this.formProcessos.get('assunto').setValue('');
    // this.formProcessos.get('dataInicio').setValue('');
    // this.formProcessos.get('dataFinal').setValue('');
    // this.formProcessos.get('situacao').setValue('');
    // this.formProcessos.get('atoPortaria').setValue('');
    // this.formProcessos.get('dataAto').setValue('');
    // this.formProcessos.get('doe').setValue('');
    // this.formProcessos.get('dataDoe').setValue('');
    // this.formProcessos.get('dataInicioReflexo').setValue('');
    // this.formProcessos.get('dataFinalReflexo').setValue('');
    // this.formProcessos.get('requerente').setValue('');
    // this.formProcessos.get('referenciaFinanceira').setValue(false);
    // this.formProcessos.get('assunto').enable();
    // this.formProcessos.get('numeroProcesso').enable();
    // this.formProcessos.get('dataInicio').enable();
    // this.formProcessos.get('dataFinal').disable();

    // this.formProcessos.markAsPristine();
    this.formProcessos = this.fb.group(
      {
        matricula: [{ value: this.matriculaAux, disabled: true }, [Validators.required]],
        nome: [{ value: this.nomeAux, disabled: true }, [Validators.required]],
        numeroProcesso: ['', [Validators.required]],
        assunto: ['', [Validators.required]],
        referenciaFinanceira: [''],
        dataInicio: ['', [Validators.required, validarDataAtualMenorIgual()]],
        dataFinal: [{ value: '', disabled: true }, [Validators.required]],
        situacao: ['', [Validators.required]],
        requerente: ['', [Validators.required]],
        atoPortaria: [''],
        dataAto: [''],
        doe: [''],
        dataDoe: [''],
        tipoReflexo: [''],
        dataInicioReflexo: ['', [validarDataInicioReflexo()]],
        dataFinalReflexo: [''],
        efeitoRetroativo: [''],
        // motivoReabertura:['', [Validators.required]]
      },
      {
        validators: [
          validarDataFimMaiorIgual('dataInicio', 'dataFinal'),
          validarDataFimMaiorIgual('dataInicioReflexo', 'dataFinalReflexo'),
        ],
      }
    );
    if (this.editar) {
      let botaoFechar = this.cadastroProcessoModal.nativeElement as HTMLElement;
      botaoFechar = botaoFechar.querySelector('#botaoFechar');
      botaoFechar.click();
    }
    this.temErro = false;
    this.editar = false;
    this.processoSelecionado = null;
  }

  ativarLimparCampos(): boolean {
    if (
      this.formProcessos.get('dataInicio').value.length > 0 ||
      this.formProcessos.get('assunto').value.length > 0 ||
      this.formProcessos.get('dataFinal').value.length > 0 ||
      this.formProcessos.get('situacao').value.length > 0 ||
      this.formProcessos.get('atoPortaria').value.length > 0 ||
      this.formProcessos.get('dataAto').value.length > 0 ||
      this.formProcessos.get('doe').value.length > 0 ||
      this.formProcessos.get('dataDoe').value.length > 0 ||
      this.formProcessos.get('dataInicioReflexo').value.length > 0 ||
      this.formProcessos.get('dataFinalReflexo').value.length > 0 ||
      this.formProcessos.get('numeroProcesso').value.length > 0
    ) {
      return true;
    }
    return false;
  }

  onSituacaoChange(): void {
    const formProcessos = this.formProcessos;
    const { situacao } = this.formProcessos.value;
    console.log(situacao);
    formProcessos.get('dataFinal').setValue('');
    // if (situacao !== '14') {
    //   formProcessos
    //     .get('dataFinal')
    //     .setValidators([
    //       Validators.required,
    //       validarDataMenor(this.formProcessos.get('dataInicio').value),
    //     ]);
    // }
    if (situacao !== '3') {
      formProcessos.get('dataFinal').disable();
    }
    if (situacao === '3') {
      formProcessos.get('dataFinal').enable();
    }
  }

  montarProcesso(situacaoAnterior: string = null): Processo {
    const formProcessos = this.formProcessos.getRawValue();
    const { dataInicio, dataFinal, dataAto, dataDoe, dataInicioReflexo, dataFinalReflexo } =
      formProcessos;

    return {
      EmpresaFilial: this.localStorage.FilialAtual,
      Matricula: formProcessos.matricula,
      NumeroProcesso: formProcessos.numeroProcesso,
      Assunto: formProcessos.assunto,
      DataInicio: new Data(dataInicio),
      DataFim: new Data(dataFinal),
      Situacao: formProcessos.situacao,
      SituacaoAnterior: situacaoAnterior || '',
      ReflexoFinanceiro: formProcessos.referenciaFinanceira ? 'SIM' : 'NAO',
      Requerente: formProcessos.requerente,
      // MotivoReabertura: formProcessos.motivoReabertura,
      NumeroAto: formProcessos.atoPortaria,
      DataAto: new Data(dataAto),
      NumeroDiarioOficial: formProcessos.doe,
      DataDiarioOficial: new Data(dataDoe),
      Evento: {
        // NumeroDeOrdem: 185831,
        DataInicial: new Data(dataInicioReflexo),
        DataFinal: new Data(dataFinalReflexo),
        EfeitoRetroativo: formProcessos.efeitoRetroativo,
        Procedimento: formProcessos.tipoReflexo,
      },
      TipoUsuario: parseInt(this.localStorage.TipoUsuario),
      Anexos: this.anexoComponent.retornarValores(),
      HoraRet: '',
      TipoProcessamento: '',
      Sigla: '',
      MotivoAbertura: '',
    };
  }

  editarProcesso(processo: Processo): void {
    console.log(processo);
    Object.keys(this.formProcessos.controls).forEach((key) => {
      this.formProcessos.controls[key].enable();
    });
    this.formProcessos.markAsDirty();
    this.processoSelecionado = processo;
    if (processo.Situacao != '3' && processo.Situacao != '4') {
      this.editar = true;
      scrollTo(0, 0);
      this.formProcessos.get('numeroProcesso').setValue(processo.NumeroProcesso);
      this.formProcessos.get('assunto').setValue(processo.Assunto);
      this.formProcessos.get('dataInicio').setValue(this.formatarData(processo.DataInicio.ToDate));
      this.formProcessos.get('dataFinal').setValue(this.formatarData(processo.DataFim.ToDate));
      this.formProcessos.get('dataFinal').disable();
      this.formProcessos.get('situacao').setValue(processo.Situacao);
      this.formProcessos.get('requerente').setValue(processo.Requerente);
      this.formProcessos.get('atoPortaria').setValue(processo.NumeroAto);
      this.formProcessos.get('dataAto').setValue(this.formatarData(processo.DataAto.ToDate));
      this.formProcessos.get('doe').setValue(processo.NumeroDiarioOficial);

      this.formProcessos.get('numeroProcesso').disable();
      this.formProcessos.get('dataInicio').disable();
      // this.formProcessos.get('dataInicio').setValidators(null);
      // this.formProcessos.get('dataFinal').setValidators(null);

      this.formProcessos
        .get('dataDoe')
        .setValue(this.formatarData(processo.DataDiarioOficial.ToDate));

      if (processo.ReflexoFinanceiro == 'SIM') {
        this.formProcessos.get('efeitoRetroativo').setValue(processo.Evento.EfeitoRetroativo);
        this.formProcessos.get('tipoReflexo').setValue(processo.Evento.Procedimento);
        this.formProcessos.get('referenciaFinanceira').setValue(true);
        // this.buscarTipoReflexo();
        this.formProcessos.get('tipoReflexo').setValue(processo.Evento.Procedimento);
        this.formProcessos
          .get('dataInicioReflexo')
          .setValue(this.formatarData(processo.Evento.DataInicial.ToDate));
        this.formProcessos
          .get('dataFinalReflexo')
          .setValue(this.formatarData(processo.Evento.DataFinal.ToDate));
        this.formProcessos.get('assunto').disable();
      } else if (processo.ReflexoFinanceiro == 'NAO') {
        this.formProcessos.get('referenciaFinanceira').setValue(false);
        this.formProcessos.get('dataInicioReflexo').setValue('');
        this.formProcessos.get('dataFinalReflexo').setValue('');
        this.formProcessos.get('assunto').enable();
      } else {
        this.formProcessos.get('assunto').enable();
      }
    } else {
      this.editarProcessoDeferido(processo);
    }
  }

  editarProcessoDeferido(processo: Processo): void {
    if (this.permissoesService.procurarPorPermissao('Deferido')) {
      Object.keys(this.formProcessos.controls).forEach((key) => {
        this.formProcessos.controls[key].enable();
      });
    }
    this.processo = processo.Situacao;
    this.editar = true;
    scrollTo(0, 0);
    // this.formProcessos.get('situacao').value == "15"
    this.formProcessos.get('numeroProcesso').setValue(processo.NumeroProcesso);
    this.formProcessos.get('assunto').setValue(processo.Assunto);
    this.formProcessos.get('dataInicio').setValue(this.formatarData(processo.DataInicio.ToDate));
    this.formProcessos.get('dataFinal').setValue(this.formatarData(processo.DataFim.ToDate));
    // this.formProcessos.get('situacao').setValue('15'); Entender isso
    this.formProcessos.get('situacao').setValue(processo.Situacao);
    this.formProcessos.get('requerente').setValue(processo.Requerente);
    // this.formProcessos.get('motivoReabertura').setValue(processo.MotivoReabertura);
    this.formProcessos.get('atoPortaria').setValue(processo.NumeroAto);
    this.formProcessos.get('dataAto').setValue(this.formatarData(processo.DataAto.ToDate));
    this.formProcessos.get('doe').setValue(processo.NumeroDiarioOficial);
    this.formProcessos
      .get('dataDoe')
      .setValue(this.formatarData(processo.DataDiarioOficial.ToDate));

    if (processo.ReflexoFinanceiro == 'SIM') {
      this.formProcessos.get('referenciaFinanceira').setValue(true);
      // this.buscarTipoReflexo();
      this.formProcessos
        .get('dataInicioReflexo')
        .setValue(this.formatarData(processo.Evento.DataInicial.ToDate));
      this.formProcessos
        .get('dataFinalReflexo')
        .setValue(this.formatarData(processo.Evento.DataFinal.ToDate));
    } else if (processo.ReflexoFinanceiro == 'NAO') {
      this.formProcessos.get('referenciaFinanceira').setValue(false);
      this.formProcessos.get('dataInicioReflexo').setValue('0000-00-00');
      this.formProcessos.get('dataFinalReflexo').setValue('0000-00-00');
    } else {
      this.formProcessos.get('assunto').enable();
    }
    if (!this.permissoesService.procurarPorPermissao('Deferido')) {
      this.formProcessos.disable();
    }
  }

  deletar(processo: Processo, index: any): void {
    this.idExcluindo = index;
    Swal({
      title: 'Você tem certeza?',
      text: 'Esta operação não pode ser desfeita.',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: {
          text: 'Cancelar',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Confirmar',
          value: true,
          visible: true,
        },
      },
    }).then((willDelete) => {
      console.log(willDelete);
      this.spinner.show();
      if (willDelete) {
        this.excluirProcesso(processo);
      } else {
        this.spinner.hide();
        this.idExcluindo = '';
      }
    });
  }

  excluirProcesso(processo: Processo) {
    this.processosService.excluirProcesso(processo).subscribe(
      (data) => {
        Swal('Sucesso', 'Exclusão do processo realizada com sucesso! ', 'success');
        this.editar = false;
        this.processoSelecionado = null;
        this.buscarProcessos(processo.Matricula.toString());
        this.idExcluindo = '';
        this.spinner.hide();
      },
      (error) => {
        console.log(error);
        this.validaErro.retornoErro(error);
        this.idExcluindo = '';
        this.spinner.hide();
        Swal('Erro', `${error.error.Mensagem}`, 'error');
      }
    );
  }

  formatarDataEnvio(data: string): string {
    let dataSplit = data.split('-');
    const dataFormatada = `${dataSplit[2]}/${dataSplit[1]}/${dataSplit[0]}`;
    return dataFormatada;
  }

  formatarData(data: string): string {
    let dataSplit = data.split('/');
    const dataFormatada = `${dataSplit[2]}-${dataSplit[1]}-${dataSplit[0]}`;
    return dataFormatada;
  }

  matriculaListener(evento) {
    this.matricula = evento.Matricula;
    this.buscarColaborador(this.matricula);
    this.formProcessos.get('matricula').setValue(this.matricula);
    this.matriculaAux = this.matricula;
    this.formProcessos.get('matricula').disable();
    this.anexoComponent.inicializarComponente(evento);
  }

  retornarTelaInicial() {
    this.matricula = null;
    this.limparCampos();
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
  addRequerenteNome() {
    if (this.formProcessos.get('requerente').value.trim() == '') {
      this.formProcessos.get('requerente').setValue(this.formProcessos.get('nome').value.trim());
    }
  }
}

export interface Processo {
  Sequencial?: string;
  Obs?: 'S' | 'N';
  Sigla?: string;
  EmpresaFilial: number;
  Matricula: number;
  NumeroProcesso: string;
  Assunto: string;
  ReflexoFinanceiro: string;
  DataInicio: Data;
  DataFim: Data;
  Situacao: string;
  SituacaoAnterior?: string;
  Requerente: string;
  // MotivoReabertura: string;
  MotivoAbertura: string;
  DataRet?: Data;
  HoraRet?: string;
  NumeroDiarioOficial?: string;
  IdClassificacaoAto?: number;
  NumeroAto: string;
  AnoAto?: string;
  DataAto?: Data;
  AnoDiarioOficial?: string;
  DataDiarioOficial: Data;
  DataCompetencia?: string;
  TipoProcessamento?: string;
  FLAG?: string;
  Evento: EventoFinanceiro;
  TipoUsuario: number;
  Anexos: any[];
}

interface Situacao {
  Codigo: string;
  Descricao: string;
  Tipo: string;
}

interface EventoFinanceiro {
  // NumeroDeOrdem: number;
  DataInicial: Data;
  DataFinal: Data;
  Procedimento: number;
  EfeitoRetroativo?: string;
}
