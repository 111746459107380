import { Data } from 'src/app/service/interfaces/Data';
import { Filial } from '../Filial/filial';
import { Setor } from './Cargo/Setor';
export class Cargo{
    Codigo: string;
    Setor: Setor = new Setor() ;
    Nivel: string;
    Filial:Filial = new Filial();
    Nome:string;
    Vinculo:string;
    Natureza:string;
    Categoria:string;
    Processo:string;
    TempoDeProducao:number;
    TempoDeExp: number;
    PeriodoDeAvaliacao:number;
    CBO: string;
    Acumulacao:string
    ContagemEspecial:string
    CodigoDoAto: number;
    CodigoDaCarreira: number;
    Descricao:string
    DataDeInicio:Data;
    DataDeTermino:Data;
    Flag : []
                   
}
