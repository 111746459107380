import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { StringifyOptions } from 'querystring';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { ErroService } from 'src/app/service/erros/erro.service';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { CaracteristicasPessoaisService } from './caracteristicas-pessoais.service';


@Component({
  selector: 'app-caracteristicas-pessoais',
  templateUrl: './caracteristicas-pessoais.component.html',
  styleUrls: ['./caracteristicas-pessoais.component.css']
})
export class CaracteristicasPessoaisComponent implements OnInit {
  localStorage: LocalStorage;

  formSaude: FormGroup;
  formCaracteristicasPessoais: FormGroup;

  listaRacaCorDePele: any[] = [];
  listaSimOuNao: any[] = [true, false];

  caractPessoal: CaracteristicasPessoais

  editando: boolean = false;
  carregado: boolean = false;

  constructor(private fb: FormBuilder, public validaErro: ErroService, public spinner: NgxSpinnerService, private caracteristicasPessoaisService: CaracteristicasPessoaisService) {
    this.caracteristicaPessoal()
  }

  ngOnInit(): void {
    this.formCaracteristicasPessoais = this.fb.group({
      tCamisa: ['', [Validators.required]],
      tCalca: ['', [Validators.required]],
      tSapato: ['', [Validators.required]],
      tCabeca: ['', [Validators.required]],
      altura: ['', [Validators.required]],
      peso: ['', [Validators.required]],
      corCabelo: ['', [Validators.required]],
      corOlhos: ['', [Validators.required]],
      raca: ['', [Validators.required]]
    });
    this.buscarRaca();
  }

  //MÉTODOS DE BUSCAR
  buscarRaca() {
    this.caracteristicasPessoaisService.buscarRaca().subscribe(
      (data: any[]) => {
        this.listaRacaCorDePele = data;
      },
      error => {
        this.validaErro.retornoErro(error)
        this.spinner.hide();
      })
  }

  caracteristicaPessoal() {
    this.caracteristicasPessoaisService.buscarCaracteristicaPessoal("21").subscribe(
      (data: any) => {
        this.caractPessoal = data[0];
        this.carregado = true;
        this.spinner.hide();
      },
      error => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      })
  }

  //CARACTERISTICAS PESSOAIS
  montarCaracteristicasPessoais(caracteristicas): CaracteristicasPessoais {
    return {
      Altura: Number(caracteristicas.altura),
      TamanhoCamisa: Number(caracteristicas.tCamisa),
      CorCabelo: caracteristicas.corCabelo,
      CorOlhos: caracteristicas.corOlhos,
      Peso: Number(caracteristicas.peso),
      Raca: { Descricao: caracteristicas.raca },
      TamanhoCabeca: Number(caracteristicas.tCabeca),
      TamanhoCalca: Number(caracteristicas.tCalca),
      TamanhoSapato: Number(caracteristicas.tSapato),
    }
  }

  salvarCaracteristicas() {
    this.spinner.show()
    if (this.formCaracteristicasPessoais.valid) {
      this.caracteristicasPessoaisService.SalvarCaracteristica(this.montarCaracteristicasPessoais(this.formCaracteristicasPessoais.value), "11310").subscribe(
        () => {
          new AlertaModal("sucesso", "Sucesso!", `Operação realizada com sucesso!`, 'p');
          this.caracteristicaPessoal();
          this.limparCamposCaracteristicas();
          this.spinner.hide();
        },
        error => {
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        }
      )
    }
  }

  //UTILITÁRIOS
  habilitarLimparCaracteristica(): boolean {
    if (
      this.formCaracteristicasPessoais.get("tCamisa").valid ||
      this.formCaracteristicasPessoais.get("tCalca").valid ||
      this.formCaracteristicasPessoais.get("tSapato").valid ||
      this.formCaracteristicasPessoais.get("tCabeca").valid ||
      this.formCaracteristicasPessoais.get("altura").valid ||
      this.formCaracteristicasPessoais.get("peso").valid ||
      this.formCaracteristicasPessoais.get("corCabelo").valid ||
      this.formCaracteristicasPessoais.get("corOlhos").valid ||
      this.formCaracteristicasPessoais.get("raca").valid) {
      return true;
    }
    return false;
  }

  limparCamposCaracteristicas(): void {
    this.formCaracteristicasPessoais.get("tCamisa").setValue("");
    this.formCaracteristicasPessoais.get("tCalca").setValue("");
    this.formCaracteristicasPessoais.get("tSapato").setValue("");
    this.formCaracteristicasPessoais.get("tCabeca").setValue("");
    this.formCaracteristicasPessoais.get("altura").setValue("");
    this.formCaracteristicasPessoais.get("peso").setValue("");
    this.formCaracteristicasPessoais.get("corCabelo").setValue("");
    this.formCaracteristicasPessoais.get("corOlhos").setValue("");
    this.formCaracteristicasPessoais.get("raca").setValue("");
    this.editando = false;
  }

  editarCaracteristicas(): void {
    this.formCaracteristicasPessoais.get("tCamisa").setValue(this.caractPessoal.TamanhoCamisa);
    this.formCaracteristicasPessoais.get("tCalca").setValue(this.caractPessoal.TamanhoCalca);
    this.formCaracteristicasPessoais.get("tSapato").setValue(this.caractPessoal.TamanhoSapato);
    this.formCaracteristicasPessoais.get("tCabeca").setValue(this.caractPessoal.TamanhoCabeca);
    this.formCaracteristicasPessoais.get("altura").setValue(this.caractPessoal.Altura);
    this.formCaracteristicasPessoais.get("peso").setValue(this.caractPessoal.Peso);
    this.formCaracteristicasPessoais.get("corCabelo").setValue(this.caractPessoal.CorCabelo);
    this.formCaracteristicasPessoais.get("corOlhos").setValue(this.caractPessoal.CorOlhos);
    this.formCaracteristicasPessoais.get("raca").setValue(this.caractPessoal.Raca.Descricao);
    this.editando = true;
  }

  formatarDataEnvio(data: string) {
    let aux = data.split('-')
    return `${aux[2]}${aux[1]}${aux[0]}`
  }

  somenteNumero(event) {
    return (((event.charCode >= 46 && event.charCode <= 57)) /*|| (event.keyCode == 45 || event.charCode == 46)*/)
  }

  somenteLetras(event) {
    return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode === 32))
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }
}

export class CaracteristicasPessoais {

  TamanhoCamisa: number;
  TamanhoCalca: number;
  TamanhoSapato: number;
  TamanhoCabeca: number;
  Altura: number;
  Peso: number;
  CorCabelo: string;
  CorOlhos: string;
  Raca: Raca;
}

export class Saude {
  TipoDeficiencia: TipoDeficiencia;
  Reabilitado: boolean;
  Readaptado: boolean;
  DataInicial: string;
  DataFinal: string;
  PreencherCota: boolean;
  DescricaoComorbidade: string;
}

export class TipoDeficiencia {
  Codigo: number;
  Descricao?: string;

}

export class Raca {
  Descricao: string;
  Codigo?: string;
}