<div [class]="telaDependente ? 'card shadow-sm' : ''" *ngIf="telaDependente">
  <div class="card-header">
    <div class="d-flex justify-content-between">
      <h4 *ngIf="urlIgual()">Dependentes</h4>
      <h5 class="card-title text-uppercase text-muted" *ngIf="!urlIgual()">
        <i class="fa fa-users"></i>&nbsp;<span>Dependentes</span>
      </h5>

      <button
        *ngIf="listaDependente && (!ColaboradorInativo || urlIgual())"
        class="btn btn-outline-warning btn-sm"
        (click)="mudarTela()"
        type="button"
      >
        <i class="fa fa-plus-circle"></i>&nbsp;
        <span>Adicionar</span>
      </button>
    </div>
    <hr class="mb-0" />
  </div>
  <div class="card-body">
    <div
      class="row d-flex justify-content-center"
      *ngIf="listaDependente && listaDependente.length > 0"
    >
      <!--  -->
      <p *ngIf="verificarErros()" class="text-center alert-warning p-2 mt-2 rounded">
        <i class="fa fa-info-circle fa-3x my-2"></i><br />
        Por favor, preencha todas as informações obrigatórias para garantir a integridade dos dados
        e a precisão das informações. Se você identificar algum dependente com informações
        incompletas, atualize os dados necessários.
      </p>
      <div
        class="col-md-4 col-lg-3 p-2 card-mobile"
        *ngFor="let dep of listaDependente"
        [ngClass]="urlIgual() ? 'col-xl-3' : 'col-xl-6'"
      >
        <div class="card shadow-sm">
          <ng-template
            [ngIf]="
              dep.Cpf === '' ||
              dep.DataNascimento.SetData === '' ||
              dep.Nome === '' ||
              dep.Sexo === null ||
              dep.Tipo === '' ||
              dep.Uf === '' ||
              dep.CodigoMunicipio === ''
            "
          >
            <a
              class="btn-link text-primary"
              data-toggle="tooltip"
              data-placement="left"
              title="Atualize os dados necessários!"
            >
              <i
                class="fa fa-info-circle fa-2x mt-3 my-1 d-flex justify-content-center fs-6 text-warning"
              ></i>
            </a>
          </ng-template>
          <div class="card-header bg-light">
            <img
              [src]="
                dep.Sexo === 1
                  ? 'assets/img/avatar-masculino.svg'
                  : 'assets/img/avatar-feminino.svg'
              "
              class="card-img-top"
            />
          </div>
          <div class="card-body">
            <button
              type="button"
              (click)="mudarTela(dep)"
              class="btn btn-outline-primary btn-block nome-mobile"
            >
              <span>{{ dep.TipoDependente.descricao }}: {{ dep.Nome }}</span>
              &nbsp;<i class="fa fa-chevron-right"></i>&nbsp;
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row d-flex justify-content-center"
      *ngIf="listaDependente && listaDependente.length <= 0"
    >
      <div class="col-md-4 col-lg-3 col-xl-6 p-2">
        <p class="text-center">Sem Dependentes</p>
      </div>
    </div>
    <div *ngIf="!listaDependente" class="d-flex justify-content-center align-items-center">
      <i class="fa fa-refresh fa-spin fa"></i>
    </div>
  </div>
</div>
<!-- Cadastro dependente-->

<section *ngIf="cadastroDependente" [class]="telaDependente ? 'content clearfix' : ''">
  <app-dependentes [telaCadastro]="telaCadastro" [matricula]="matricula"></app-dependentes>
</section>
