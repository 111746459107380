import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { EChartsOption } from 'echarts';
import { NgxSpinnerService } from 'ngx-spinner';
import { DecimalPipe } from '@angular/common';
import { ErroService } from 'src/app/service/erros/erro.service';
import { DadoSintetico } from './classes/DadoSintetico';
//import { fbind } from 'q';
import { FiltroListarSintetico } from './classes/FiltroListarSintetico';
import { RelatorioSinteticoService } from './relatorio-sintetico.service';
import { FilialBeneficiario } from '../regras-beneficio/classe/FilialBeneficiario';

@Component({
  selector: 'app-relatorio-sintetico',
  templateUrl: './relatorio-sintetico.component.html',
  styleUrls: ['./relatorio-sintetico.component.css']
})
export class RelatorioSinteticoComponent implements OnInit {

  formFiltro: FormGroup;

  relatorioSintetico: DadoSintetico[] = [];
  listaFilial: FilialBeneficiario[] = [];
  loading: boolean = false;
  dtcomp: string;
  dado: DadoSintetico; // Variável de teste

  dadosGrafico1: any[] = [];
  dadosGrafico2: any[] = [];
  dadosGrafico3: any[] = [];
  dadosGrafico4: any[] = [];

  grafico1: EChartsOption;
  grafico2: EChartsOption;
  grafico3: EChartsOption;
  grafico4: EChartsOption;

  constructor(private relatorioSinteticoService: RelatorioSinteticoService, private validaErro:ErroService, private fb: FormBuilder, private spinner: NgxSpinnerService) { }
  
  ngOnInit() {
    this.loading = false;
    this.listarFilial();

    this.formFiltro = this.fb.group({
      competencia: [''],
      empresa: [''],
      ordenar: ['']
    })
  }

  async submitFiltro()
  {
    this.loading = true;
    if(!this.formFiltro.valid){
      Swal.fire('Atenção', 'Todos os campos são obrigatórios', 'warning');
      this.loading = false;

      return false;
    }
    this.listarSintetico();
  }

  listarSintetico(){
    const filtro:FiltroListarSintetico =  this.montaFiltro();

    this.relatorioSinteticoService.listarSintetico(filtro)
    .subscribe(
      (data:any) => {
        this.relatorioSintetico = data;
        this.carregarDados();
        this.loading = false;
      }, error => {
        this.validaErro.retornoErro(error);
        this.loading = false;
      }
    );

  }

  montaFiltro(): FiltroListarSintetico{
    const { competencia, empresa, ordenar } = this.formFiltro.value;
    return {
      Competencia : competencia != '' ? competencia.substr(2,4) + competencia.substr(0,2) : '',
      EmpresaFilial: empresa,
      Ordenacao: ordenar
    }
  }

  carregarDados(){
    for (let index = 0; index < this.relatorioSintetico.length; index++) {
      this.dadosGrafico1.push(
        {
          name: this.relatorioSintetico[index].Descricao,
          value: this.relatorioSintetico[index].Qtd,
          label:  {position: 'inside'}
        }
      );
      this.dadosGrafico2.push(
        {
          name: this.relatorioSintetico[index].Descricao,
          value: this.relatorioSintetico[index].Valor,
        }
      );
      this.dadosGrafico3.push(
        {
          name: this.relatorioSintetico[index].Descricao,
          value: this.relatorioSintetico[index].ValorPercapto,
        }
      );
    }
    this.dadosGrafico4 = [
      {
        name: "Cobertos",
        value: this.relatorioSintetico[0].TotalCobertos,
      },
      {
        name: "Descobertos",
        value: this.relatorioSintetico[0].TotalDescobertos,
      }
    ];
    this.gerarGraficos();
  }

  gerarGraficos(){
    this.grafico1 = this.getGraficoBarra(this.dadosGrafico1, "Quantidade");
    this.grafico2 = this.getGraficoBarra(this.dadosGrafico2, "Custo",'R$');
    this.grafico3 = this.getGraficoBarra(this.dadosGrafico3, "Valor",'R$');
    this.grafico4 = this.getGrafico(this.dadosGrafico4);
  }

  listarFilial()
  {
    this.relatorioSinteticoService.listarFilial()
    .subscribe(
      (data:any) => {
        this.listaFilial = JSON.parse(JSON.stringify(data));
      }, error => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  getGraficoBarra(dados, nome, mask = ''): EChartsOption{
    return {
      tooltip: {
        formatter: `{b} <br/> ${mask} {c}`,

        trigger: 'axis',
        backgroundColor: 'rgba(0,0,0,0.75)',
        padding: [10, 15],
        textStyle: {
            fontSize: 15,
            fontFamily: 'Roboto, sans-serif'
        },
        axisPointer: {
            type: 'shadow',
            shadowStyle: {
                color: 'rgba(0,0,0,0.025)'
            }
        }
      },
      grid: {
        left: 0,
        right: 30,
        top: 30,
        bottom: 0,
        containLabel: true
      },
      xAxis: {
          type: 'category',
          data: dados.map(dados => dados.name)
      },
      yAxis: {
          name: '     ' +nome,
          type: 'value',
          axisLabel: {
            color: '#333'
          },
          axisLine: {
              lineStyle: {
                  color: '#999'
              }
          },
          splitLine: {
              show: true,
              lineStyle: {
                  color: '#eee',
                  type: 'dashed'
              }
          }
      },
      series: [{
          data: dados.map(dados => dados.value),
          type: 'bar',
          showBackground: true,
          label: {
            formatter: 'R$ {c}'
            /*normal: {
                textStyle: {
                    color: '#682d19'
                },
                position: 'left',
                show: false,
            }*/
            },
          backgroundStyle: {
              color: 'rgba(220, 220, 220, 0.8)'
          }
      }]
    }
  }

  getGrafico(dados): EChartsOption{
    return{
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)'
    },
    legend: {
        orient: 'vertical',
        left: 'left',
    },
    series: [
        {
            name: '',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: dados,
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
     
    }
    
  }

  
}
