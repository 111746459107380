import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErroService } from 'src/app/service/erros/erro.service';
import Swal from 'sweetalert';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';
import { ServicosService } from './servicos.service';

@Component({
  selector: 'app-servicos',
  templateUrl: './servicos.component.html',
  styleUrls: ['./servicos.component.css']
})

export class ServicosComponent implements OnInit {
  formCadastroServico: FormGroup

  listaServicos: Servico[] = []

  filter: string = ""
  
  temErro: boolean
  salvarEdicao: boolean

  constructor(private validaErro: ErroService, private fb: FormBuilder, private servicosService: ServicosService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.temErro = false
    this.salvarEdicao = false
    this.buscarServico()

    this.formCadastroServico = this.fb.group({
      codigo: ['', Validators.compose([Validators.required])],
      descricao: ['', Validators.compose([Validators.required,])],
      denominacaoCliente: ['', Validators.compose([Validators.required])],
      prefixoServico: ['', Validators.compose([Validators.required, Validators.maxLength(3)])],

      geraLog: [false],
      servidorApoio: [false],
      checarNormaNomenclatura: [false],
      classeExecucaoAutenticacao: [''],
      classeSincronizado: [false],
      clienteSincronizado: [false],
      codigoAnterior: [''],
      codigoProgramaSincronizado: [''],
      enderecoDominio: [''],
      grupoClasseSincronizado: [false],
      metodoExecucaoAutenticacao: [''],
      servicoSincronizado: [false],
      usuarioSincronizado: [false],
      xmlServico: [''],
    })
  }

  buscarServico() {
    this.spinner.show()
    this.servicosService.buscarServicosPse().subscribe((data: any[]) => {
      this.listaServicos = data
      this.spinner.hide();
    },
      error => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        this.spinner.hide();
      })
  }

  somenteLetras(event) {
    return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode === 32))
  }
  
  somenteNumero(event) {
    return ((event.charCode >= 48 && event.charCode <= 57) /*|| (event.keyCode == 45 || event.charCode == 46)*/)
  }

  salvarServico() {
    if (this.formCadastroServico.invalid) {
      this.temErro = true;
      new AlertaModal('alerta','Atenção','Preencha todos os campos obrigatórios!','p')
    }
    else {
      const servicoMontado = this.montarServico()
      let existeServico = this.listaServicos.filter((servico: Servico) => servico.Codigo == this.formCadastroServico.controls["codigo"].value).length
      if (existeServico > 0) {
        this.editarServico(servicoMontado)
      }
      else {
        this.adicionarServico(servicoMontado);
      }
    }
  }

  adicionarServico(servicoMontado: Servico) {
    if (this.formCadastroServico.status != 'INVALID') {
      this.spinner.show()
      this.servicosService.salvarServicoPse(servicoMontado).subscribe(data => {
        new AlertaModal('sucesso',"Sucesso","Operação realizada com sucesso",'p')
        this.buscarServico();
        this.limparCampos();
        this.spinner.hide();
        this.formCadastroServico.get('codigoAnterior').setValue(this.formCadastroServico.get("codigo").value)
      }, error => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        this.spinner.hide();
      })
    }
    else {
      this.temErro = true
      new AlertaModal('alerta','Atenção',"Preencha todos os campos",'p')
    }
  }

  editarServico(servicoMontado: Servico) {
    this.spinner.show()
    this.servicosService.editarServicoPse(servicoMontado).subscribe(data => {
      new AlertaModal('sucesso',"Sucesso","ediçao realizada com sucesso",'p')
      this.buscarServico()
      this.limparCampos()
      this.spinner.hide();
    }, error => {
      new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
      this.spinner.hide();
    })
  }

  carregarCampos(servico: Servico) {
    if (servico != null) {
      this.temErro = false
      this.salvarEdicao = true

      this.limparCampos()
      this.formCadastroServico.controls['codigo'].disable();
      this.formCadastroServico.markAsPristine()

      this.formCadastroServico.setValue({
        codigo: servico.Codigo,
        descricao: servico.Descricao,
        denominacaoCliente: servico.DenominacaoCliente,
        prefixoServico: servico.PrefixoServico,

        geraLog: false,
        servidorApoio: false,
        checarNormaNomenclatura: false,
        classeExecucaoAutenticacao: '',
        classeSincronizado: false,
        clienteSincronizado: false,
        codigoAnterior: '',
        codigoProgramaSincronizado: '',
        enderecoDominio: '',
        grupoClasseSincronizado: false,
        metodoExecucaoAutenticacao: '',
        servicoSincronizado: false,
        usuarioSincronizado: false,
        xmlServico: '',
      });
      window.scroll(0,0);
    } else {
      new AlertaModal('alerta','Atenção','Serviço vazio ou nulo!','p')
    }
  }

  montarServico(): Servico {
    const montarServico = this.formCadastroServico.getRawValue()
    return {
      Codigo: montarServico.codigo,
      Descricao: montarServico.descricao,
      DenominacaoCliente: montarServico.denominacaoCliente,
      PrefixoServico: montarServico.prefixoServico,
      GeraLog: montarServico.geraLog,
      ServidorApoio: montarServico.servidorApoio,
      ChecarNormaNomenclatura: montarServico.checarNormaNomenclatura,

      ClasseExecucaoAutenticacao: montarServico.classeExecucaoAutenticacao,
      ClasseSincronizado: montarServico.classeSincronizado,
      ClienteSincronizado: montarServico.clienteSincronizado,
      CodigoAnterior: montarServico.codigo,
      CodigoProgramaSincronizado: montarServico.codigoProgramaSincronizado,
      EnderecoDominio: montarServico.enderecoDominio,
      GrupoClasseSincronizado: montarServico.grupoClasseSincronizado,
      MetodoExecucaoAutenticacao: montarServico.metodoExecucaoAutenticacao,
      ServicoSincronizado: montarServico.servicoSincronizado,
      UsuarioSincronizado: montarServico.usuarioSincronizado,
      XmlServico: montarServico.xmlServico,
    }
  }

  limparCampos() {
    this.salvarEdicao = false
    this.temErro = false
    this.formCadastroServico.get('codigo').enable()

    this.formCadastroServico.get('codigo').setValue("");
    this.formCadastroServico.controls['descricao'].setValue("");
    this.formCadastroServico.controls['denominacaoCliente'].setValue("");
    this.formCadastroServico.controls['prefixoServico'].setValue("");
    this.formCadastroServico.markAsPristine()
  }

  deletar(servico: Servico) {
    Swal({
      title: "Você tem certeza?",
      text: "Esta operação não pode ser desfeita.",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true
        }
      }
    })
      .then((willDelete) => {
        if (willDelete) {
          this.excluirServico(servico);
        }
      });
  }

  excluirServico(servico: Servico) {
    this.spinner.show()
    this.servicosService.excluirServicoPse(servico.Codigo).subscribe(data => {
      new AlertaModal('sucesso',"Sucesso","Serviço Apagado Com Sucesso",'p')
      this.buscarServico()
      this.limparCampos()
      this.spinner.hide()
    }, error => {
      new AlertaModal('alerta','Atenção','Serviço vazio ou nulo!','p')
      new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
      this.spinner.hide();
    })
  }
}

export class Servico {
  GeraLog: boolean;
  Codigo: string;
  Descricao: string;
  DenominacaoCliente: string;
  PrefixoServico: string;
  ServidorApoio: boolean;
  ChecarNormaNomenclatura: string;

  ClasseExecucaoAutenticacao: string
  ClasseSincronizado: boolean
  ClienteSincronizado: boolean
  CodigoAnterior: string
  CodigoProgramaSincronizado: string
  EnderecoDominio: string
  GrupoClasseSincronizado: boolean
  MetodoExecucaoAutenticacao: string
  ServicoSincronizado: boolean
  UsuarioSincronizado: boolean
  XmlServico: string

}