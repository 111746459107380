import { Endereco } from '../../Endereco-Padrao/endereco';

export class Sindicato{
    Sigla: string;
    Codigo: number;
    Nome: string;
    Endereco:Endereco     
    Cnpj: number;
    Telefone: number;
    DDD: number;
    Cgcnum: string
    Cgcord:string;
    Cgcdv:string;
    Codent:string;
    F01:string;
    F02:string;
    F03:string;
    F04:string;
    F05:string;
    Mesbase:string;
    Piso:  number;
    FormaCalcMedias13SalTipo:string;
    FormaCalcMedias13SalaPurBase:string;
    FormaCalcMedias13SalQuant: number;
    FormaCalcMedias13SalMaiores: number;
    FormaCalcMediasFeriasTipo: string;
    FormaCalcMediasFeriasApurBase:string;
    FormaCalcMediasFeriasQuant: number;
    FormaCalcMediasFeriasMaiores: number;
    FormaCalcMediasAvPrevioTipo: string;
    FormaCalcMediasAvPrevioApurBase:string;
    FormaCalcMediasAvPrevioQuant:string;
    FormaCalcMediasAvPrevioMaiores:  number;
    DuracaoAvisoPrevio: number;
    VrBbase:  number;
    VrbDesconto: number;
    Patronal: number;
}