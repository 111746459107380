<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="card">
  <div class="card-header">
    <h3 class="card-title" id="target">Quadro de Benefícios</h3>
  </div>
  <div class="card-body">
    <div>
      <div class="row">
        <div class="col-md-4 form-group">
          <label>Grupo</label>
          <select (change)="listarFiltro()" name="grupo" [(ngModel)]="grupo" class="form-control">
            <option value="">Selecione...</option>
            <option *ngFor="let item of grupos" value="{{item.Codigo}}" ng-reflect-value="">{{item.Descricao}}</option>
          </select>
        </div>
        <div class=" form-group d-flex align-items-end">
          <button routerLink="/beneficios/quadro-beneficios/cadastro" type="button" class="btn btn-info form-control">
            <span>Novo Benefício</span>
          </button>
        </div>
      </div>

    </div>
    <div class="card-header">
      <h3 class="card-title"> Listagem de Benefícios</h3>
    </div>
    <div class="card-body">
      <div class="table-responsive" style="max-height:45vh">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Benefício</th>
              <th>Partes Envolvidas</th>
              <th>Valor e Verba</th>
              <th>Vigência</th>
              <!--<th scope="col" class="col-auto">Faixa Etária</th>
              <th scope="col" class="col-auto">Valoração do Benefício</th>
              <th scope="col" class="col-auto">Verba do Benefício</th>-->
              <th class="text-center">Editar</th>
            </tr>
          </thead>
          <tbody *ngIf="beneficios && beneficios.length > 0" id="lista-beneficios">
            <tr *ngFor="let item of beneficios">
              <td>{{item.Descricao}}</td>
              <td *ngIf="item.PartesBeneficio.length > 0">
                <div *ngFor="let parte of item.PartesBeneficio">
                  <div *ngIf="parte.Parte === 'E' && parte.Valor !== 0 && parte.Tipo !== '%'"><strong>Empresa:</strong> R$ {{parte.Valor}}</div>
                  <div *ngIf="parte.Parte === 'F' && parte.Valor !== 0 && parte.Tipo !== '%'"><strong>Funcionário:</strong> R$ {{parte.Valor}}</div>
                  <div *ngIf="parte.Parte === 'E' && parte.Valor !== 0 && parte.Tipo === '%'"><strong>Empresa:</strong> {{parte.Valor}}%</div>
                  <div *ngIf="parte.Parte === 'F' && parte.Valor !== 0 && parte.Tipo === '%'"><strong>Funcionário:</strong> {{parte.Valor}}%</div>
                </div>
              </td>
              <td>
                <div><strong>Valor: </strong>R$ {{item.ConstanteExterna.Valor}}</div>
                <div><strong>Verba: </strong> {{item.Verba.Cod}} - {{item.Verba.Nome}}</div>
                <!--<div *ngIf="item.Frequencia === 999"><strong>Frequência:</strong> Indeterminada</div>
                <div *ngIf="item.Frequencia !== 999"><strong>Frequência:</strong> {{item.Frequencia}}</div>
                <div *ngIf="item.ValidadeMeses > 0"><strong>Validade:</strong> {{item.ValidadeMeses}} meses</div>
                <div *ngIf="item.ValidadeMeses <= 0"><strong>Validade:</strong> Sem Validade</div>-->
              </td>
              <td>
                <div><strong>Data Inicial:</strong> {{item.DataInicial.substr(0, 10)}} </div>
                <div *ngIf="!item.DataFinal"><strong>Data Final:</strong> Indeterminada</div>
                <div *ngIf="item.DataFinal"><strong>Data Final:</strong> {{item.DataFinal.substr(0, 10)}} </div>
              </td>
              <!--<td>
                <div *ngIf="item.IdadeMinimaMeses == 0 && item.IdadeMaximaMeses == 0">
                  <i>Inexistente</i>
                </div>
                <div *ngIf="item.IdadeMinimaMeses != 0 || item.IdadeMaximaMeses != 0" scope="col" class="col-auto">
                  <li class="list-group-item list-group-item-info"><strong>Data Mínima: </strong> {{getAnosMeses(item.IdadeMinimaMeses)}} </li>
                  <li class="list-group-item list-group-item-info"><strong>Data Máxima: </strong>  {{getAnosMeses(item.IdadeMaximaMeses)}}</li>
                </div>
              </td>
              <td>
                <ul class="list-group p-0">
                  <li *ngIf="!item.ConstanteExterna.Observacao" class="list-group-item list-group-item-warning"><strong>Verba: </strong> {{item.ConstanteExterna.Verbas}} </li>
                  <li *ngIf="!item.ConstanteExterna.Observacao" class="list-group-item list-group-item-danger"><i class="fa fa-chain-broken" aria-hidden="true"></i> Não vinculado</li>
                  <li *ngIf="item.ConstanteExterna.Observacao" class="list-group-item list-group-item-warning"><strong>Verba: </strong> {{item.ConstanteExterna.Verbas}} </li>
                  <li *ngIf="item.ConstanteExterna.Observacao" class="list-group-item list-group-item-warning"><strong>Observação: </strong>  {{item.ConstanteExterna.Observacao}} </li>
                  <!-- <li *ngIf="this.ConstanteExterna.Observacao" class="list-group-item list-group-item-warning active"><strong>Valor:</strong> R$  number_format(this.ConstanteExterna.Valor, 2, ',', '.') </li> -->
              <!--<li *ngIf="item.ConstanteExterna.Observacao && item.F02 == 1" class="list-group-item list-group-item-danger"><strong>Classificação: </strong> Valor variável com teto máximo</li>
                  <li *ngIf="item.ConstanteExterna.Observacao && item.F02 != 1" class="list-group-item list-group-item-success"><strong>Classificação: </strong> Valor fixo</li>
                </ul>
              </td>
              <td>
                <ul class="list-group p-0">
                  <li class="list-group-item list-group-item-success"><strong>Verba: </strong> {{item.Verba.Cod}} </li>
                  <li class="list-group-item list-group-item-success"><strong>Descrição: </strong>  {{item.Verba.Nome}} </li>
                </ul>
              </td>-->
              <td class="text-center">
                <button class="btn bg-teal-400" (click)="goToCadatro(item)">
                  <i class="fa fa-pencil"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="!beneficios" class="row  d-flex justify-content-center align-items-center mt-3">
        <i class="fa fa-refresh fa-spin fa-2x "></i>
        <h4 class="ml-2">Aguarde...</h4>
      </div>
      <div *ngIf="beneficios && beneficios.length == 0">
        <div class="d-flex justify-content-center align-items-center mt-3">
          <h4>Não há dados para serem exibidos!</h4>
        </div>
      </div>
    </div>
  </div>
</div>
