import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AcessoService } from '../../service/acesso/acesso.service';
import { PensaoAlimento } from './pensao-alimenticia.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root',
})
export class PensaoAlimenticiaService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any = null;
  filial: string = '';
  data: any = new Date();
  mes: number = this.data.getMonth() + 1;
  ano: number = this.data.getFullYear();
  competencia: string = this.ano.toString() + this.mes.toString();
  url_acesso: any = this.acesso.validarUrl();

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  buscarColaborador(matricula: string) {
    return this.http.get(
      `${this.url_acesso}elogios-penalidades/usuario/${this.competencia}/${matricula}/${this.filial}`,
      { headers: this.cabecalho }
    );
  }

  buscarUf() {
    return this.http.get(`${this.url_acesso}estados/`, {
      headers: this.cabecalho,
    });
  }
  buscarMunicipios(uf: string) {
    return this.http.get(`${this.url_acesso}estados/${uf}`, {
      headers: this.cabecalho,
    });
  }
  buscarCep(cep) {
    return this.http.get(`${this.url_acesso}cep/${cep}`, {
      headers: this.cabecalho,
    });
  }

  buscarTipoPensionista() {
    return this.http.get(`${this.url_acesso}pensao-alimenticia/tipo-pensionista/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarAgencias(nomeBanco: any) {
    return this.http.get(`${this.url_acesso}agencia/${nomeBanco}`, {
      headers: this.cabecalho,
    });
  }

  buscarOperacoes(nomeBanco: any) {
    return this.http.get(`${this.url_acesso}operacoes/${nomeBanco}`, {
      headers: this.cabecalho,
    });
  }

  buscarPensionistas(matricula: string) {
    return this.http.get(
      `${this.url_acesso}pensao-alimenticia/pensionista/${this.filial}/${matricula}/`,
      { headers: this.cabecalho }
    );
  }

  salvarPensao(pensao: PensaoAlimento) {
    return this.http.post(`${this.url_acesso}pensao-alimenticia/salvar/${this.filial}`, pensao, {
      headers: this.cabecalho,
    });
  }

  buscarBanco() {
    return this.http.get(`${this.url_acesso}bancos`, {
      headers: this.cabecalho,
    });
  }

  buscarTipoCalculo() {
    return this.http.get(`${this.url_acesso}pensao-alimenticia/tipo-calculo/${this.filial}`, {
      headers: this.cabecalho,
    });
  }
  buscarVerbas(nomeVerba: string, matricula: string) {
    let params = new HttpParams().set('nome', nomeVerba);
    return this.http.get(
      `${this.url_acesso}pensao-alimenticia/verbas/${this.filial}/${matricula}`,
      { headers: this.cabecalho, params: params }
    );
  }

  buscarLotacao() {
    return this.http.get(`${this.url_acesso}pensao-alimenticia/lotacao/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarVerbaDesconto(matricula: string) {
    return this.http.get(
      `${this.url_acesso}pensao-alimenticia/verbas-pensao-alimenticia-existente/${this.filial}/${matricula}`,
      { headers: this.cabecalho }
    );
  }

  buscarTipoConta() {
    return this.http.get(`${this.url_acesso}pensao-alimenticia/tipo-conta`, {
      headers: this.cabecalho,
    });
  }

  buscarOperacao(banco: number) {
    return this.http.get(`${this.url_acesso}pensao-alimenticia/operacao/${banco}`, {
      headers: this.cabecalho,
    });
  }

  alterarPensao(pensao: PensaoAlimento) {
    return this.http.put(`${this.url_acesso}pensao-alimenticia/alterar/${this.filial}`, pensao, {
      headers: this.cabecalho,
    });
  }

  testeParamentro(campos: any) {
    return this.http.post(`${this.url_acesso}parametros/listar-parametros`, campos, {
      headers: this.cabecalho,
    });
  }
}
