import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErroService } from 'src/app/service/erros/erro.service';
import { ItemMenuService } from './item-menu.service';
import Swal from 'sweetalert';
import { Tarefa } from '../tarefas/tarefas.component';
import { timeStamp } from 'console';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';

@Component({
  selector: 'app-item-menu',
  templateUrl: './item-menu.component.html',
  styleUrls: ['./item-menu.component.css']
})
export class ItemMenuComponent implements OnInit {
  formItemMenu: FormGroup;
  formTelaInicial: FormGroup;

  listaServicos: any[];
  listaItensReduzida: any[] = [];
  listaTodasTarefas: any[] = [];
  listaTarefas: any[] = [];

  itemEditar: ItemMenu = null;
  listaItensMenu: any;
  codigoItemMenu: any;
  tarefaSelecionada: any;
  itemPaiSelecionado: any;
  codigoTarefa: any = null;
  nivelTarefa: number;
  nivelItem: number;
  idExcluindo: string = '';
  filter: any = "";

  temErro: boolean;
  habilitarVoltar: boolean = false;
  habilitarCancelar: boolean = false;
  telaFormulario: boolean = false;
  habilitarVoltarItem: boolean = false;
  habilitarCancelarItem: boolean = false;
  editando: boolean = false;
  isItemPai: boolean = false;
  desabilitarBt: boolean;
  isPai: boolean = false;
  constructor(private fb: FormBuilder, public validaErro: ErroService, private itemMenuService: ItemMenuService, public spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.formItemMenu = this.fb.group({
      codigo: ['', [Validators.required, Validators.maxLength(9)]],
      descricao: ['', [Validators.required, Validators.maxLength(29)]],
      icone: ['', Validators.required],
      tarefaVinculada: "",
      itemVinculado: "",
      link: ['', Validators.required],
      ePai: ['', [Validators.required]],
      exibeMenu: ['', Validators.required],
      nivel: 0,
      edicao: false
    });

    this.formTelaInicial = this.fb.group({
      codServico_form: ['', [Validators.required]],
    });
    this.buscarServicos();
    this.telaFormulario = false;
  }

  buscarServicos(): void {
    this.itemMenuService.buscarServicosPse()
      .subscribe(
        (data: any[]) => {
          this.listaServicos = data;
          this.spinner.hide();
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        }
      );
  }

  contarFilhos(tarefa: Tarefa): number {
    let codigoPai = tarefa.Codigo;
    let lista = this.listaTodasTarefas.filter(valor => valor.CodigoPai == codigoPai);
    let contador = lista.length;
    return contador;
  }

  contarFilhosItem(item: ItemMenu): number {
    let codigo = item.CodigoItemMenu;
    let lista = this.listaItensMenu.filter(valor => valor.CodigoItemPai == codigo);
    let contador = lista.length;
    return contador;
  }

  fecharModal(id) {
    document.getElementById(id).click();
  }

  selecionarTarefa(tarefa: any, isChecked: boolean) {
    if (isChecked) {
      this.tarefaSelecionada = tarefa;
      this.codigoTarefa = tarefa.Codigo;
    }
    else {
      this.tarefaSelecionada = null;
    }
  }

  selecionarItem(item: ItemMenu, isChecked: boolean) {
    if (isChecked) {

      this.itemPaiSelecionado = item;
      this.codigoItemMenu = item.CodigoItemMenu;
    }
    else {
      this.itemPaiSelecionado = null;
    }
  }

  buscarTarefasPorCodigo(): void {
    const codigo = this.formTelaInicial.get('codServico_form').value;
    if (codigo != '') {
      this.itemMenuService.buscarTarefasPorCodigo(codigo)
        .subscribe(
          (data: any) => {
            this.listaTodasTarefas = data;
          },
          error => this.validaErro.retornoErro(error)
        );
    } else {
      new AlertaModal('alerta',"Atenção!", 'Você precisa digitar um código.','p')
    }
  }

  buscarItensMenuTb(): void {
    const codigo = this.formTelaInicial.get('codServico_form').value;
    if (codigo != '') {
      this.temErro = false;
      this.itemMenuService.buscarItensMenu(codigo)
        .subscribe(
          (data: any) => {
            this.listaItensMenu = data;
            this.telaFormulario = true;
            this.buscarTarefasPorCodigo();
          },
          error => this.validaErro.retornoErro(error)
        );
    } else {
      this.temErro = true;
      new AlertaModal('alerta',"Atenção!", 'Você precisa digitar um código.','p')
    }
  }

  montarListaInicial() {
    this.listaTarefas = this.listaTodasTarefas.filter(valor => valor.Nivel == 0);
    this.habilitarVoltar = false;
    this.habilitarCancelar = true;
  }

  montarItensInicial() {
    this.listaItensReduzida = this.listaItensMenu.filter(valor => valor.NivelItemMenu == 0);
    this.habilitarVoltarItem = false;
    this.habilitarCancelarItem = true;

    this.fecharModal("closeModalTarefa");
  }

  selecionarItemPai(item: ItemMenu) {
    if (this.contarFilhosItem(item) > 0) {
      this.habilitarVoltarItem = true;
      this.habilitarCancelarItem = false;
      let codigoPai = item.CodigoItemMenu;
      this.listaItensReduzida = this.listaItensMenu.filter(valor => valor.CodigoItemPai == codigoPai);
      setTimeout(() => this.checarItemMenu(), 300)
    }
    else {
      new AlertaModal('alerta',"Atenção!", 'Esse item de menu não possui filho(s).','p')
    }
  }

  selecionarTipoTarefa(tarefa: Tarefa) {
    if (tarefa.TemFilho == true) {
      this.habilitarVoltar = true;
      this.habilitarCancelar = false;
      this.codigoTarefa = tarefa.Codigo;
      let codigoPai = tarefa.Codigo;;
      this.listaTarefas = this.listaTodasTarefas.filter(valor => valor.CodigoPai == codigoPai);
      setTimeout(a => this.checarTarefa(), 300);
    }
    else {
      new AlertaModal('alerta',"Atenção!", 'Essa tarefa não possui filho(s).','p')
    }
  }

  carregarItemMenu(item: ItemMenu): void {
    if (item != null) {
      this.itemEditar = item;
      this.limparCampos();
      this.temErro = false;
      this.formItemMenu.markAsPristine();
      this.formItemMenu.get('link').setValidators(Validators.nullValidator);

      this.formItemMenu.setValue({
        codigo: item.CodigoItemMenu,
        descricao: item.Descricao,
        icone: item.IconeMenu,
        tarefaVinculada: item.CodigoTarefaVinculada,
        itemVinculado: item.CodigoItemPai,
        link: item.Link,
        nivel: item.NivelItemMenu,
        exibeMenu: item.ExibirItemMenu,
        ePai: item.NivelItemMenu == 0 ? true : false,
        edicao: true
      });

      this.formItemMenu.get('codigo').disable();
      this.formItemMenu.get('icone').disable();
      this.formItemMenu.get('link').disable();
      this.formItemMenu.get('ePai').disable();
      this.isPai = true;
      this.isItemPai = true;
      this.editando = true;
      this.codigoTarefa = item.CodigoTarefaVinculada;

      this.listaTarefas = this.listaTodasTarefas.filter(valor => valor.Nivel == item.NivelItemMenu && item.CodigoTarefaVinculada == valor.Codigo);

      let tarefaPai = this.listaItensMenu.filter(valor => valor.NivelItemMenu == this.formItemMenu.get('nivel').value && valor.CodigoItemMenu == this.formItemMenu.get('itemVinculado').value);
      if (tarefaPai.length > 0) {
        this.listaItensReduzida = this.listaItensMenu.filter(valor => valor.NivelItemMenu == this.formItemMenu.get('nivel').value && valor.CodigoItemPai == tarefaPai[0].CodigoItemPai);
      }
      this.tarefaSelecionada = this.listaTarefas[0];
      window.scroll(0, 0);

    } else {
      new AlertaModal('alerta',"Atenção!", 'Item vazio ou nulo!','p')
    }

  }

  voltarNivel(tarefa: Tarefa) {
    this.nivelTarefa = tarefa.Nivel;
    if (this.nivelTarefa != 1) {
      this.nivelTarefa--;
      let nivel = this.nivelTarefa;
      let temporario: Array<Tarefa> = this.listaTodasTarefas.filter((valor: Tarefa) => valor.Nivel == nivel && valor.Codigo == tarefa.CodigoPai)
      this.listaTarefas = this.listaTodasTarefas.filter((valor: Tarefa) => valor.Nivel == temporario[0].Nivel && valor.CodigoPai == temporario[0].CodigoPai)
      setTimeout(() => this.checarTarefa(), 300);

    } else if (this.nivelTarefa == 1) {
      this.nivelTarefa--;
      let nivel = this.nivelTarefa;
      this.listaTarefas = this.listaTodasTarefas.filter(valor => valor.Nivel == nivel);
      this.habilitarVoltar = false;
      this.habilitarCancelar = true;
      setTimeout(() => this.checarTarefa(), 300);
    }
  }

  voltarNivelItem(item: ItemMenu) {
    
    this.nivelItem = item.NivelItemMenu;
    this.nivelItem--;
    if (this.nivelItem > 0) {
      let nivelMenu = this.nivelItem;
      let temporario: Array<ItemMenu> = this.listaItensMenu.filter((valor: ItemMenu) => valor.NivelItemMenu == nivelMenu && valor.CodigoItemMenu == item.CodigoItemPai)
      this.listaItensReduzida = this.listaItensMenu.filter((valor: ItemMenu) => valor.NivelItemMenu == temporario[0].NivelItemMenu && valor.CodigoItemPai == temporario[0].CodigoItemPai)
      // this.listaItensReduzida = this.listaItensReduzida = this.listaItensMenu.filter((valor : ItemMenu)=> valor.NivelItemMenu == nivelMenu && valor.CodigoItemMenu == item.CodigoItemPai);
      setTimeout(() => this.checarItemMenu(), 300);
    } else if (this.nivelItem == 0) {
      let nivel = this.nivelItem;
      this.listaItensReduzida = this.listaItensMenu.filter(valor => valor.NivelItemMenu == nivel);
      this.habilitarVoltarItem = false;
      this.habilitarCancelarItem = true;
      setTimeout(() => this.checarItemMenu(), 300);
    }
  }

  deletar(item: ItemMenu): void {
    Swal({
      title: "Você tem certeza?",
      text: "Esta operação não pode ser desfeita.",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true
        }
      }
    })
      .then((willDelete) => {
        if (willDelete) {
          this.excluirItemMenu(item);
        }

      });
    this.idExcluindo = '';
  }

  excluirItemMenu(item: ItemMenu) {
    this.spinner.show();
    this.itemMenuService.deletarItemMenu(item.CodigoItemMenu, this.formTelaInicial.get('codServico_form').value)
      .subscribe(
        data => {
          new AlertaModal('sucesso',"Sucesso!", 'Item de menu excluído com sucesso!','p')
          this.limparCampos();
          this.buscarItensMenuTb();
          this.spinner.hide();
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        })
  }

  salvarItemMenu(item: ItemMenu) {
    this.spinner.show();
    this.itemMenuService.salvarItemMenu(item)
      .subscribe(
        data => {
          new AlertaModal('sucesso',"Sucesso!", 'Item de menu salvo com sucesso!','p')
          this.buscarItensMenuTb();
          this.limparCampos();
          this.spinner.hide();
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        })
  }

  submitItemMenu(): void {
    let existeItemMenu = this.listaItensMenu.filter((item: ItemMenu) => item.CodigoItemMenu == this.formItemMenu.controls["codigo"].value).length
    if ((this.formItemMenu.invalid || (this.formItemMenu.get('ePai').value == "false" && this.itemPaiSelecionado == null) || this.tarefaSelecionada == null) && !this.editando) {
      this.temErro = true;
      this.tratarErrosSubmit();
    }
    else {
      if (existeItemMenu > 0 && this.editando) {
        const itemMontado = this.montarItemMenuEditar();
        if (this.formItemMenu.valid) {
          this.editarItemMenu(itemMontado);
          this.editando = false;
          this.limparCampos();
        } else {
          this.temErro = true
          new AlertaModal('alerta','Atenção!', 'Preencha os campos corretamente!','p')
        }
      }
      else {
        const itemMontado = this.montarItemMenuSalvar();
        this.salvarItemMenu(itemMontado);
        this.editando = false;
        this.limparCampos();
      }
    }
  }

  editarItemMenu(item: ItemMenu) {
    this.spinner.show();
    this.itemMenuService.editarItemMenu(item)
      .subscribe(
        data => {
          this.buscarItensMenuTb();
          new AlertaModal('sucesso',"Sucesso!", 'Item do menu alterado com sucesso!','p')
          this.limparCampos();
          this.spinner.hide();
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        })
  }

  tratarErrosSubmit() {
    if (this.formItemMenu.invalid) {
      new AlertaModal('alerta','Atenção!', 'Preencha os campos corretamente!','p')
    }
    else if (this.itemPaiSelecionado == null && this.formItemMenu.get('ePai').value == "false") {
      new AlertaModal('alerta','Atenção!','Selecione um item pai!','p')

    } else if (this.tarefaSelecionada == null) {
      new AlertaModal('alerta','Atenção!','Selecione uma tarefa!','p')
    } else {
      new AlertaModal('alerta','Atenção!','Preencha todos os campos obrigatórios!','p')
    }
  }

  montarItemMenuSalvar(): ItemMenu {
    const item = this.formItemMenu.getRawValue();
    let nivel = this.itemPaiSelecionado != null ? Number(this.itemPaiSelecionado.NivelItemMenu) + 1 : 0

    let length = 0;
    if (this.itemPaiSelecionado == null || this.itemPaiSelecionado.length == 0) { this.itemPaiSelecionado = null; }
    if (this.itemPaiSelecionado != null && item.ePai == "false") { length = 1; }

    return {
      CodigoItemPai: length > 0 ? this.itemPaiSelecionado.CodigoItemMenu : "0",
      CodigoServico: this.formTelaInicial.get('codServico_form').value,
      CodigoItemMenu: item.codigo,
      Descricao: item.descricao,
      Dica: "",
      CodigoTarefaVinculada: this.tarefaSelecionada.Codigo,
      Link: item.link,
      ExibirItemMenu: item.exibeMenu,
      NivelItemMenu: nivel,
      IconeMenu: item.icone,
      ExisteFilho: false
    }
  }

  cancelarModalItem() {
    let element = <HTMLInputElement>document.getElementById(`${this.codigoItemMenu}IM`);
    if (element != null) { element.checked = false; }
    this.itemPaiSelecionado = null;
  }

  cancelarModalTarefa() {
    let element = <HTMLInputElement>document.getElementById(`${this.codigoTarefa}T`);
    if (element != null) { element.checked = false; }
    this.tarefaSelecionada = null;
  }

  montarItemMenuEditar(): ItemMenu {
    const item = this.formItemMenu.getRawValue();

    let length = 0;
    if (this.itemPaiSelecionado == null || this.itemPaiSelecionado.length == 0) { this.itemPaiSelecionado = null; }
    if (this.itemPaiSelecionado != null && item.ePai == "false") { length = 1; }

    return {
      CodigoItemPai: this.itemEditar.CodigoItemPai,
      CodigoServico: this.formTelaInicial.get('codServico_form').value,
      CodigoItemMenu: item.codigo,
      Descricao: item.descricao,
      Dica: "",
      CodigoTarefaVinculada: this.codigoTarefa,
      Link: this.itemEditar.Link,
      ExibirItemMenu: this.itemEditar.ExisteFilho,
      NivelItemMenu: this.itemEditar.NivelItemMenu,
      IconeMenu: item.icone == "" ? "null" : this.itemEditar.IconeMenu,
      ExisteFilho: length > 0 ? this.itemPaiSelecionado.ExisteFilho : false
    }
  }

  clickTarefaVinculada(item: any) {
    if (this.formItemMenu.get('edicao').value) {
      let tarefaPai = this.listaTodasTarefas.filter(valor => valor.Nivel == item.nivel && valor.Codigo == item.tarefaVinculada);
      this.listaTarefas = this.listaTodasTarefas.filter(valor => valor.Nivel == item.nivel && valor.CodigoPai == tarefaPai[0].CodigoPai);
      item.codigo = this.formItemMenu.get('codigo').value;
    }
    if (this.listaTarefas.length != 0) {

      if (this.tarefaSelecionada != null) {
        this.montarTarefaCheck();
      }
      if (item.nivel > 1) { this.habilitarVoltar = true; this.habilitarCancelar = false; };
    } else {
      this.montarListaInicial();
    }
  }

  montarTarefaCheck(): void {
    this.listaTarefas.forEach(tarefa => {
      if (tarefa.Codigo == this.codigoTarefa) {
        let element = <HTMLInputElement>document.getElementById(`${this.codigoTarefa}T`);
        element.checked = true;
      }
    });
  }

  montarItemMenuCheck(): void {
    this.listaItensMenu.forEach(itemMenu => {
      if (itemMenu.CodigoItemMenu == this.codigoItemMenu) {
        let element = <HTMLInputElement>document.getElementById(`${this.codigoItemMenu}IM`);
        element.checked = true;
      }
    });
  }

  clickItemPai(item: any) {
    if (this.formItemMenu.get('edicao').value) {
      item.CodigoItemMenu = this.formItemMenu.get('codigo').value
      let tarefaPai = this.listaItensMenu.filter(valor => valor.NivelItemMenu == item.nivel && valor.CodigoItemMenu == item.itemVinculado)
      this.listaItensReduzida = this.listaItensMenu.filter(valor => valor.NivelItemMenu == item.nivel && valor.CodigoItemPai == tarefaPai[0].CodigoItemPai);
    }

    if (this.listaItensReduzida.length != 0) {

      if (this.itemPaiSelecionado != null) {
        this.montarItemMenuCheck();
      }

      if (item.nivel > 1) { this.habilitarVoltarItem = true; this.habilitarCancelarItem = false; };
    } else {
      this.montarItensInicial();
    }
  }

  checarTarefa() {
    for (let i = 0; i < this.listaTarefas.length; i++) {
      if (this.tarefaSelecionada != null && this.listaTarefas[i].Codigo == this.tarefaSelecionada.Codigo) {
        let element = <HTMLInputElement>document.getElementById(`${this.listaTarefas[i].Codigo}T`);
        
        element.checked = true;
      }
    }
  }
  
  checarItemMenu() {
    for (let i = 0; i < this.listaItensReduzida.length; i++) {
      if (this.itemPaiSelecionado != null && this.listaItensReduzida[i].CodigoItemMenu == this.itemPaiSelecionado.CodigoItemMenu) {
        let element = <HTMLInputElement>document.getElementById(`${this.listaItensReduzida[i].CodigoItemMenu}IM`);
        element.checked = true;
      }
    }
  }

  salvarTarefaPai(item: any) {
    if (this.tarefaSelecionada != null) {
      new AlertaModal('sucesso',"Sucesso!", 'Tarefa Pai selecionada com sucesso!','p')
      this.fecharModal("closeModalTarefa");
    } else {
      new AlertaModal('alerta','Atenção!', 'Selecione uma Tarefa Pai!','p')
    }
  }

  salvarItemPai() {
    if (this.itemPaiSelecionado != null) {
      new AlertaModal('sucesso',"Sucesso!", 'Item Pai selecionado com sucesso!','p')
      this.fecharModal("closeModalItem");
    } else {
      new AlertaModal('alerta','Atenção!', 'Selecione uma Tarefa Pai!','p')
    }
  }

  criarPai(): boolean {
    const codigo = this.formItemMenu.get('ePai').value;
    if (codigo == "true") {
      this.itemPaiSelecionado = null;
      this.isItemPai = true;
    }
    else { this.isItemPai = false; }
    return this.isItemPai;
  }

  limparCampos(): void {
    this.formItemMenu.controls['codigo'].setValue("");
    this.formItemMenu.controls['descricao'].setValue("");
    this.formItemMenu.controls['icone'].setValue("");
    this.formItemMenu.controls['link'].setValue("");
    this.formItemMenu.controls['exibeMenu'].setValue("");
    this.formItemMenu.controls['nivel'].setValue("");
    this.formItemMenu.controls['ePai'].setValue("");
    this.formItemMenu.controls['tarefaVinculada'].setValue("");
    this.formItemMenu.controls['itemVinculado'].setValue("");
    this.formItemMenu.controls['edicao'].setValue("");
    this.tarefaSelecionada = null;
    this.itemPaiSelecionado = null;
    this.listaItensReduzida = [];
    this.listaTarefas = [];
    this.isItemPai = false;
    this.isPai = false;
    this.temErro = false;
    this.editando = false;

    let element = <HTMLInputElement>document.getElementById(`${this.codigoItemMenu}IM`);
    if (element != null) { element.checked = false; }

    this.formItemMenu.get('codigo').enable();
    this.formItemMenu.get('icone').enable();
    this.formItemMenu.get('link').enable();
    this.formItemMenu.get('ePai').enable();
    this.formItemMenu.markAsPristine();
    this.formItemMenu.get('link').setValidators(Validators.required);
  }

  onChange(target : any) {
    if(target instanceof EventTarget) {
      var elemento = target as HTMLInputElement;
      return elemento.checked;
    }
  }

  retornarTelaInicial(){
    this.telaFormulario = false
  }
}

export interface ItemMenu {
  CodigoServico: string,
  CodigoItemPai: string,
  CodigoItemMenu: string,
  Descricao: string,
  Dica: string,
  CodigoTarefaVinculada: string,
  NivelItemMenu: number,
  Link: string,
  ExibirItemMenu: boolean,
  IconeMenu: string
  ExisteFilho: boolean
}
