<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<div class="page-content">
	<div class="content-wrapper">
		<div class="d-flex justify-content-center">
			<form class="login-form" #login="ngForm" [formGroup]="formLogin" (submit)="logar(login)" *ngIf="!validaCliente && !validaServico">
				<div class="card py-1 px-2 m-0 bg-dark text-ligth shadow">
					<div class="card-body">
						<div class="text-center mb-3">
							<a href="./">
								<img border="0" src="assets/img/elogica-gente.svg" class="img-fluid my-3" />
							</a>
							<h5 class="mb-0">Faça seu Login</h5>
							<span class="d-block text-muted">Entre com seu código do usuário ou CPF</span>
						</div>
						<div class="form-group form-group-feedback form-group-feedback-left">
							<input type="text" class="form-control form-control-lg shadow" placeholder="Login, CPF ou e-mail" name="login" formControlName="login" [mask]="LoginMask()" required autocomplete="off" autofocus />
							<div class="form-control-feedback">
								<i class="icon-user text-muted"></i>
							</div>
							<span class="form-text text-danger" *ngIf="formLogin.controls['login'].touched && formLogin.controls['login'].hasError('required')">Digite o Login</span>
						</div>
						<div class="form-group form-group-feedback form-group-feedback-left">
							<input type="password" class="form-control form-control-lg shadow" placeholder="Senha" name="Senha" formControlName="senha" required minlength="8"  autocomplete="off" />
							<div class="form-control-feedback">
								<i class="icon-lock2 text-muted"></i>
							</div>
							<span class="form-text text-danger" *ngIf="formLogin.controls['senha'].touched && formLogin.controls['senha'].hasError('required')">Digite a Senha</span>
							<span class="form-text text-danger" *ngIf="formLogin.controls['senha'].touched && formLogin.controls['senha'].hasError('minlength')">Digite no mínimo 8 dígitos</span>
						</div>
						<div class="form-group form-group-feedback form-group-feedback-left">
							<button type="submit" class="btn btn-primary btn-block btn-lg shadow" [disabled]="formLogin.invalid || loading">
								<span>{{ loading == false ? 'Entrar' : 'Carregando...' }}</span>
								<i class="ml-2 fa {{ loading == false ? 'fa-sign-in' : 'fa-spinner fa-spin' }} text-light"></i>
							</button>
							<a href="#" class="btn btn-link my-2 btn-block text-light" (click)="recuperarSenha()">Esqueci minha senha</a>
						</div>
					</div>
				</div>
				<p class="text-center text-muted mt-4">
					<small>Elógica Processamento de Dados</small>
				</p>
			</form>
		</div>
		<div class="content d-flex justify-content-center align-items-center">
			<form #login="ngForm" [formGroup]="formLoginComplementar" class="login-form">
				<div class="card bg-dark text-ligth" *ngIf="validaCliente">
					<div mx-4 class="card-body">
						<div class="text-center mb-5">
							<h5 class="mb-0">Selecione o Cliente</h5>
						</div>
						<div class="form-group form-group-feedback form-group-feedback-left">
							<select class="form-control" formControlName="cliente" (change)="selecionarClienteServico(true)" required>
								<option value="">Selecione ...</option>
								<option *ngFor="let cli of info.Usuario.Clientes" value="{{ cli.IdCliente }}">
									{{ cli.Nome }}
								</option>
							</select>
						</div>
					</div>
				</div>
				<div mx-4 class="card mb-0 bg-dark text-ligth" *ngIf="validaServico">
					<div class="card-body">
						<div class="text-center mb-5">
							<h5 class="mb-0">Selecione o Serviço</h5>
						</div>
						<div class="form-group form-group-feedback form-group-feedback-left">
							<select class="form-control" (change)="selecionarClienteServico(false)" formControlName="servico" required >
								<option value="">Selecione ...</option>
								<option *ngFor="let cli of info.Usuario.Servicos" value="{{ cli.Id }}">{{ cli.Descricao }}</option>
							</select>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>								