<div class="d-flex justify-content-between" *ngIf="cadastroUsuario?.NumeroDeOrdem">
  <p *ngIf="tipoUsuario === '1'" class="lead text-uppercase text-muted ml-3">
    Verifique Seus Dados Pessoais
  </p>
  <p *ngIf="tipoUsuario !== '1'" class="lead text-uppercase text-muted ml-3">Dados Pessoais</p>
  <div class="btn-group">
    <ng-container *ngFor="let botao of botoes">
      <button
        class="btn"
        [class.btn-outline-warning]="botao.color === 'warning'"
        [class.btn-outline-danger]="botao.color === 'danger'"
        [class.btn-outline-primary]="botao.color === 'primary'"
        [class.btn-sm]="true"
        [class.mx-2]="botao.label === 'Salvar'"
        [class.mr-2]="botao.label === 'Editar'"
        type="botao"
        *ngIf="botao.show()"
        (click)="botao.action()"
      >
        <i *ngIf="botao.icon" class="fa {{ botao.icon }}"></i
        >{{ botao.label === 'Editar' ? '&nbsp;' : '' }}
        <span>{{ botao.label }}</span>
      </button>
    </ng-container>
  </div>
</div>
<hr />

<div
  class="table-responsive shadow-sm table-dados-pessoais"
  *ngIf="!displayDadosPessoais && !loading && cadastroUsuario?.NumeroDeOrdem"
>
  <table class="table table-striped">
    <tbody class="tabela-dados-pessoais">
      <tr>
        <th class="text-muted">Matrícula</th>
        <td>{{ cadastroUsuario.Matricula }}</td>
        <th class="text-muted">Data de Admissão</th>
        <td>
          {{
            cadastroUsuario.InformacoesBasicas.DataAdmissao.ToDate == ''
              ? 'NÃO INFORMADA'
              : cadastroUsuario.InformacoesBasicas.DataAdmissao.ToDate
          }}
        </td>
      </tr>
      <tr>
        <th class="text-muted">Data de Nascimento</th>
        <td>
          {{
            cadastroUsuario.Pessoal.DadosBasicos.DataNascimento.ToDate == ''
              ? 'NÃO INFORMADA'
              : cadastroUsuario.Pessoal.DadosBasicos.DataNascimento.ToDate
          }}
        </td>
        <th class="text-muted">E-mail</th>
        <td *ngIf="cadastroUsuario.Pessoal.Contato.Email1 == ''">NÃO INFORMADO</td>
        <td *ngIf="cadastroUsuario.Pessoal.Contato.Email1 != ''">
          {{ cadastroUsuario.Pessoal.Contato.Email1 }}
        </td>
      </tr>
      <tr>
        <th class="text-muted">Celular</th>
        <td
          *ngIf="
            cadastroUsuario.Pessoal.Contato.DDDCelular == '' ||
            cadastroUsuario.Pessoal.Contato.DDDCelular == '0' ||
            cadastroUsuario.Pessoal.Contato.DDDCelular == 'NÃO INFORMADO'
          "
        >
          NÃO INFORMADO
        </td>
        <td
          *ngIf="
            cadastroUsuario.Pessoal.Contato.DDDCelular != '' &&
            cadastroUsuario.Pessoal.Contato.DDDCelular != '0' &&
            cadastroUsuario.Pessoal.Contato.DDDTelefone != 'NÃO INFORMADO'
          "
        >
          ({{ cadastroUsuario.Pessoal.Contato.DDDCelular }})
          {{ formataTelefone(cadastroUsuario.Pessoal.Contato.Celular) }}
        </td>
        <th class="text-muted">Telefone</th>
        <td
          *ngIf="
            cadastroUsuario.Pessoal.Contato.DDDTelefone == '' ||
            cadastroUsuario.Pessoal.Contato.DDDTelefone == '00' ||
            cadastroUsuario.Pessoal.Contato.DDDTelefone == 'NÃO INFORMADO'
          "
        >
          NÃO INFORMADO
        </td>
        <td
          *ngIf="
            cadastroUsuario.Pessoal.Contato.DDDTelefone != '' &&
            cadastroUsuario.Pessoal.Contato.DDDTelefone != '00' &&
            cadastroUsuario.Pessoal.Contato.DDDTelefone != 'NÃO INFORMADO'
          "
        >
          ({{ cadastroUsuario.Pessoal.Contato.DDDTelefone }})
          {{ formataTelefone(cadastroUsuario.Pessoal.Contato.Telefone) }}
        </td>
      </tr>
      <tr>
        <th class="text-muted">Tipo Sanguíneo</th>
        <td>
          {{
            cadastroUsuario.InformacoesBasicas.TipoSanguineo != ''
              ? cadastroUsuario.InformacoesBasicas.TipoSanguineo
              : 'NÃO INFORMADA'
          }}
        </td>
        <th class="text-muted">Sexo</th>
        <td>
          {{
            cadastroUsuario.Pessoal.DadosBasicos.Sexo
              ? cadastroUsuario.Pessoal.DadosBasicos.Sexo.Valor == 1
                ? 'MASCULINO'
                : cadastroUsuario.Pessoal.DadosBasicos.Sexo.Valor == 2
                ? 'FEMININO'
                : 'NÃO INFORMADO'
              : 'NÃO INFORMADO'
          }}
        </td>
      </tr>
      <tr>
        <th class="text-muted">Cor/Raça</th>
        <td>
          {{
            cadastroUsuario.Pessoal.DadosBasicos.Etnia.Descricao != ''
              ? cadastroUsuario.Pessoal.DadosBasicos.Etnia.Descricao
              : 'NÃO INFORMADA'
          }}
        </td>
        <th class="text-muted">Estado Civil</th>
        <td>
          {{
            !cadastroUsuario.Pessoal.DadosBasicos.EstadoCivil
              ? 'NÃO INFORMADO'
              : cadastroUsuario.Pessoal.DadosBasicos.EstadoCivil.Valor == 0
              ? 'NÃO INFORMADO'
              : cadastroUsuario.Pessoal.DadosBasicos.EstadoCivil.Descricao
          }}
        </td>
      </tr>
      <tr>
        <th class="text-muted">Nacionalidade</th>
        <td>
          {{
            cadastroUsuario.Pessoal.DadosBasicos.Nacionalidade.Codigo != 0
              ? cadastroUsuario.Pessoal.DadosBasicos.Nacionalidade.Nome
              : 'NÃO INFORMADA'
          }}
        </td>
        <th class="text-muted">Naturalidade</th>
        <td>
          {{
            cadastroUsuario.Pessoal.DadosBasicos.Naturalidade.Municipio.Nome == '' ||
            cadastroUsuario.Pessoal.DadosBasicos.Nacionalidade.Codigo != 10
              ? 'NÃO INFORMADA'
              : cadastroUsuario.Pessoal.DadosBasicos.Naturalidade.Municipio.Nome
          }}
          -
          {{
            cadastroUsuario.Pessoal.DadosBasicos.Naturalidade.Municipio.Estado.Nome == '' ||
            cadastroUsuario.Pessoal.DadosBasicos.Nacionalidade.Codigo != 10
              ? 'NÃO INFORMADO'
              : cadastroUsuario.Pessoal.DadosBasicos.Naturalidade.Municipio.Estado.SiglaUf
          }}
        </td>
      </tr>
      <tr>
        <th class="text-muted">Tipo de Folha</th>
        <td>
          {{
            cadastroUsuario.InformacoesBasicas.TipoFolha == '' ||
            cadastroUsuario.InformacoesBasicas.TipoFolha == '0'
              ? 'NÃO INFORMADO'
              : exibirDescricaoParametro(
                  listaTipoFolha,
                  cadastroUsuario.InformacoesBasicas.TipoFolha
                )
          }}
        </td>
        <th class="text-muted">Vínculo</th>
        <td>
          {{
            cadastroUsuario.InformacoesBasicas.Vinculo
              ? cadastroUsuario.InformacoesBasicas.Vinculo.Nome
              : 'NÃO INFORMADO'
          }}
        </td>
      </tr>
      <tr>
        <th class="text-muted">Sindicato</th>
        <td>
          {{
            !cadastroUsuario.InformacoesBasicas.Sindicato
              ? 'NÃO INFORMADO'
              : cadastroUsuario.InformacoesBasicas.Sindicato.Codigo == 0
              ? 'NÃO INFORMADO'
              : cadastroUsuario.InformacoesBasicas.Sindicato.Nome
          }}
        </td>
        <th class="text-muted">Carga Horária</th>
        <td>
          {{
            !cadastroUsuario.InformacoesBasicas.HorarioTrabalho.CargaHorariaSemana
              ? 'NÃO INFORMADO'
              : cadastroUsuario.InformacoesBasicas.HorarioTrabalho.CargaHorariaSemana
          }}H
        </td>
        <!----<td>Celetista</td>-->
      </tr>
      <tr>
        <th class="text-muted">Horário</th>
        <td>
          {{
            cadastroUsuario.InformacoesBasicas.HorarioTrabalho.HoraEntrada == ''
              ? 'NÃO INFORMADO'
              : formatarCodigoHorario(
                  cadastroUsuario.InformacoesBasicas.HorarioTrabalho.HoraEntrada
                )
          }}
        </td>
        <th class="text-muted">Repouso</th>
        <td>
          {{
            cadastroUsuario.InformacoesBasicas.Descanso == ''
              ? 'NÃO INFORMADO'
              : cadastroUsuario.InformacoesBasicas.Descanso
          }}
        </td>
      </tr>
      <tr>
        <th class="text-muted">Dias úteis</th>
        <td>
          {{
            !cadastroUsuario.InformacoesBasicas.DiasUteisColaborador
              ? 'NÃO INFORMADO'
              : exibirDescricaoParametro(
                  listaDiasUteis,
                  cadastroUsuario.InformacoesBasicas.DiasUteisColaborador
                )
          }}
        </td>
        <th class="text-muted">Grau de Instrução</th>
        <td>
          {{
            !cadastroUsuario.Pessoal.DadosBasicos.GrauDeInstrucao
              ? 'NÃO INFORMADO'
              : cadastroUsuario.Pessoal.DadosBasicos.GrauDeInstrucao.Valor == 0
              ? 'NÃO INFORMADO'
              : cadastroUsuario.Pessoal.DadosBasicos.GrauDeInstrucao.Descricao
          }}
        </td>
      </tr>
      <tr>
        <th class="text-muted">Nome da Mãe</th>
        <td>
          {{
            cadastroUsuario.Pessoal.DadosBasicos.NomeMae == ''
              ? 'NÃO INFORMADO'
              : cadastroUsuario.Pessoal.DadosBasicos.NomeMae
          }}
        </td>
        <th class="text-muted">Nome do Pai</th>
        <td>
          {{
            cadastroUsuario.Pessoal.DadosBasicos.NomePai == ''
              ? 'NÃO INFORMADO'
              : cadastroUsuario.Pessoal.DadosBasicos.NomePai
          }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="displayDadosPessoais && !loading && cadastroUsuario?.NumeroDeOrdem">
  <form
    [formGroup]="formDadosPessoais"
    autocomplete="off"
    class="form-group"
    #dadosPessoais="ngForm"
  >
    <div class="row">
      <div class="form-group col-xl-6">
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold">Nome </label>
        <input
          [ngStyle]="
            (formDadosPessoais.controls['nomeDadosPessoais'].touched || temErro) &&
            (formDadosPessoais.controls['nomeDadosPessoais'].hasError('minlength') ||
              formDadosPessoais.controls['nomeDadosPessoais'].hasError('required'))
              ? { 'border-color': 'red' }
              : null
          "
          type="text"
          name="nomeDadosPessoais"
          placeholder="Nome"
          formControlName="nomeDadosPessoais"
          class="form-control"
          minlength="3"
          required
        />
        <span
          class="form-text text-danger"
          *ngIf="
            (formDadosPessoais.controls['nomeDadosPessoais'].touched || temErro) &&
            (formDadosPessoais.controls['nomeDadosPessoais'].hasError('required') ||
              formDadosPessoais.controls['nomeDadosPessoais'].hasError('minlength'))
          "
        >
          {{
            formDadosPessoais.controls['nomeDadosPessoais'].hasError('required')
              ? 'Campo obrigatório'
              : 'Digite um nome válido'
          }}
        </span>
      </div>
      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold"
          >Admissão
        </label>
        <input
          type="date"
          name="admissaoDadosPessoais"
          formControlName="admissaoDadosPessoais"
          class="form-control"
          max="9999-12-31"
          [ngStyle]="
            (formDadosPessoais.controls['admissaoDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['admissaoDadosPessoais'].hasError('required')
              ? { 'border-color': 'red' }
              : null
          "
          required
        />
        <span
          class="form-text text-danger"
          *ngIf="
            (formDadosPessoais.controls['admissaoDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['admissaoDadosPessoais'].hasError('required')
          "
        >
          Campo obrigatório
        </span>
      </div>
      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold"
          >Nascimento
        </label>
        <input
          type="date"
          name="nascimentoDadosPessoais"
          formControlName="nascimentoDadosPessoais"
          class="form-control"
          max="9999-12-31"
          [ngStyle]="
            (formDadosPessoais.controls['nascimentoDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['nascimentoDadosPessoais'].hasError('minlength')
              ? { 'border-color': 'red' }
              : null
          "
        />
        <!-- <span
          class="form-text text-danger"
          *ngIf="
            formDadosPessoais.controls['nascimentoDadosPessoais'].dirty &&
            (temErro || formDadosPessoais.controls['nascimentoDadosPessoais'].hasError('minlength'))
          "
        >
          Digite uma data válida
        </span> -->
      </div>
      <div class="form-group col-xl-6">
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold"
          >E-mail
        </label>
        <input
          type="email"
          name="emailDadosPessoais"
          placeholder="E-mail"
          formControlName="emailDadosPessoais"
          class="form-control"
          [ngStyle]="
            (formDadosPessoais.controls['emailDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['emailDadosPessoais'].hasError('email')
              ? { 'border-color': 'red' }
              : null
          "
        />
        <span
          class="form-text text-danger"
          *ngIf="
            (formDadosPessoais.controls['emailDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['emailDadosPessoais'].hasError('email')
          "
        >
          O e-mail deve seguir os padrões nome@dominio, nome@dominio.com ou nome@dominio.com.br
        </span>
      </div>
      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold"
          >Celular</label
        >
        <input
          type="phone"
          class="form-control"
          name="celularDadosPessoais"
          formControlName="celularDadosPessoais"
          placeholder="(99) 99999-9999"
          mask="(00)00000-0000"
          [ngStyle]="
            (formDadosPessoais.controls['celularDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['celularDadosPessoais'].hasError('minlength')
              ? { 'border-color': 'red' }
              : null
          "
        />
        <span
          class="form-text text-danger"
          *ngIf="
            (formDadosPessoais.controls['celularDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['celularDadosPessoais'].hasError('minlength')
          "
        >
          O celular precisa de 11 dígitos, exemplo: (00) 00000-0000
        </span>
      </div>
      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold"
          >Telefone <small>(opcional)</small></label
        >
        <input
          type="phone"
          class="form-control"
          name="telefoneDadosPessoais"
          formControlName="telefoneDadosPessoais"
          placeholder="(99) 9999-9999"
          mask="(00)0000-0000"
          [ngStyle]="
            (formDadosPessoais.controls['telefoneDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['telefoneDadosPessoais'].hasError('minlength')
              ? { 'border-color': 'red' }
              : null
          "
        />
        <span
          class="form-text text-danger"
          *ngIf="
            (formDadosPessoais.controls['telefoneDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['telefoneDadosPessoais'].hasError('minlength')
          "
        >
          O telefone precisa de 10 dígitos, exemplo: (00) 0000-0000
        </span>
      </div>
      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold">Tipo Sanguíneo <small>(opcional)</small></label>
        <select
          class="form-control"
          formControlName="tipoSangue"
          [ngClass]="
            formDadosPessoais.get('tipoSangue').invalid &&
            (temErro || formDadosPessoais.get('tipoSangue').touched)
              ? 'is-invalid'
              : null
          "
        >
          <option value="">Selecione...</option>
          <option *ngFor="let tpSangue of listaTipoSanquineo" value="{{ tpSangue.Descricao }}">
            {{ tpSangue.Descricao }}
          </option>
        </select>
      </div>
      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold">Sexo </label>
        <select class="form-control" name="sexoDadosPessoais" formControlName="sexoDadosPessoais">
          <option value="0">Selecione...</option>
          <option
            *ngFor="let sexo of listaSexo | orderBy : 'Descricao' : false"
            value="{{ sexo.Codigo }}"
          >
            {{ sexo.Descricao }}
          </option>
        </select>
        <!-- <span
          class="form-text text-danger"
          *ngIf="
            formDadosPessoais.controls['sexoDadosPessoais'].touched &&
            (temErro || formDadosPessoais.controls['sexoDadosPessoais'].hasError('required'))
          "
        >
          Campo obrigatório
        </span> -->
      </div>

      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold"
          >Cor/Raça</label
        >
        <select class="form-control" name="corDadosPessoais" formControlName="corDadosPessoais">
          <option value="0">Selecione...</option>
          <option *ngFor="let cor of listaEtnia" value="{{ cor.Descricao }}">
            {{ cor.Descricao }}
          </option>
        </select>
        <!-- <span
          class="form-text text-danger"
          *ngIf="
            formDadosPessoais.controls['corDadosPessoais'].touched &&
            (temErro || formDadosPessoais.controls['corDadosPessoais'].hasError('required'))
          "
        >
          Campo obrigatório
        </span> -->
      </div>
      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold"
          >Estado Civil</label
        >
        <select
          class="form-control"
          name="estadoCivilDadosPessoais"
          formControlName="estadoCivilDadosPessoais"
          [ngClass]="
            formDadosPessoais.controls['estadoCivilDadosPessoais'].touched &&
            (temErro || formDadosPessoais.controls['estadoCivilDadosPessoais'].hasError('required'))
              ? 'is-invalid'
              : null
          "
        >
          <option value="0">Selecione...</option>
          <option *ngFor="let est of listaEstadoCivil" value="{{ est.Codigo }}">
            {{ est.Descricao }}
          </option>
        </select>
        <!-- <span
          class="form-text text-danger"
          *ngIf="
            formDadosPessoais.controls['estadoCivilDadosPessoais'].touched &&
            (temErro || formDadosPessoais.controls['estadoCivilDadosPessoais'].hasError('required'))
          "
        >
          Campo obrigatório
        </span> -->
      </div>
      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold"
          >Vínculo
        </label>
        <select
          class="form-control"
          name="vinculoDadosPessoais"
          formControlName="vinculoDadosPessoais"
          [ngStyle]="
            (formDadosPessoais.controls['vinculoDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['vinculoDadosPessoais'].hasError('pattern')
              ? { 'border-color': 'red' }
              : null
          "
          required
        >
          <option value="0">Selecione...</option>
          <option *ngFor="let vinculo of listaVinculos" value="{{ vinculo.Codigo }}">
            {{ vinculo.Nome }}
          </option>
        </select>
        <span
          class="form-text text-danger"
          *ngIf="
            (formDadosPessoais.controls['vinculoDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['vinculoDadosPessoais'].hasError('pattern')
          "
        >
          Campo obrigatório
        </span>
      </div>
      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold"
          >Sindicato
        </label>
        <select
          class="form-control"
          name="sindicatoDadosPessoais"
          formControlName="sindicatoDadosPessoais"
          [ngClass]="
            formDadosPessoais.get('sindicatoDadosPessoais').invalid &&
            (temErro || formDadosPessoais.get('sindicatoDadosPessoais').touched)
              ? 'is-invalid'
              : null
          "
        >
          <option value="0">Selecione...</option>
          <option
            _ngcontent-uxj-c4=""
            *ngFor="let sindicato of sindicatos"
            value="{{ sindicato.Codigo }}"
            ng-reflect-value=""
          >
            {{ sindicato.Nome }}
          </option>
        </select>
        <!-- <span
          class="form-text text-danger"
          *ngIf="
            formDadosPessoais.controls['sindicatoDadosPessoais'].touched &&
            (temErro || formDadosPessoais.controls['sindicatoDadosPessoais'].hasError('required'))
          "
        >
          Campo obrigatório
        </span> -->
      </div>

      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold">Carga Horária <small> (Semanal)</small> </label>
        <input
          type="number"
          min="0"
          max="50"
          class="form-control"
          formControlName="cargaHorariaDadosPessoais"
          placeholder="Carga Horária"
          [ngClass]="
            formDadosPessoais.get('cargaHorariaDadosPessoais').invalid &&
            (temErro || formDadosPessoais.get('cargaHorariaDadosPessoais').touched)
              ? 'is-invalid'
              : null
          "
        />
      </div>

      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold">Tipo de folha</label>
        <select
          class="form-control"
          formControlName="tipoFolhaDadosPessoais"
          [ngClass]="
            formDadosPessoais.get('tipoFolhaDadosPessoais').invalid &&
            (temErro || formDadosPessoais.get('tipoFolhaDadosPessoais').touched)
              ? 'is-invalid'
              : null
          "
        >
          <option value="">Selecione...</option>
          <option *ngFor="let tipoFolha of listaTipoFolha" value="{{ tipoFolha.Codigo }}">
            {{ tipoFolha.Descricao }}
          </option>
        </select>
      </div>

      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold">Horário</label>
        <select
          class="form-control"
          formControlName="horarioDadosPessoais"
          (change)="aoMudarHorario()"
          [ngClass]="
            formDadosPessoais.get('horarioDadosPessoais').invalid &&
            (temErro || formDadosPessoais.get('horarioDadosPessoais').touched)
              ? 'is-invalid'
              : null
          "
        >
          <option value="">Selecione...</option>
          <option *ngFor="let horario of listaHorario" value="{{ horario.Codigo }}">
            {{ horario.Codigo }}
          </option>
        </select>
      </div>
      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold">Repouso</label>
        <input
          type="text"
          class="form-control input-disabled"
          formControlName="repousoDadosPessoais"
          placeholder="Repouso"
          [ngClass]="
            formDadosPessoais.get('repousoDadosPessoais').invalid &&
            (temErro || formDadosPessoais.get('repousoDadosPessoais').touched)
              ? 'is-invalid'
              : null
          "
          readonly
        />
      </div>
      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold">Dias Úteis </label>
        <select
          type="text"
          class="form-control"
          formControlName="diasUteisDadosPessoais"
          [ngClass]="
            formDadosPessoais.get('diasUteisDadosPessoais').invalid &&
            (temErro || formDadosPessoais.get('diasUteisDadosPessoais').touched)
              ? 'is-invalid'
              : null
          "
        >
          <option value="">Selecione...</option>
          <option *ngFor="let diasUteis of listaDiasUteis" value="{{ diasUteis.Codigo }}">
            {{ diasUteis.Codigo }} - {{ diasUteis.Descricao }}
          </option>
        </select>
      </div>
      <div class="form-group col-xl-3">
        <label class="text-muted font-weight-bold">Grau de Instrução </label>
        <select
          class="form-control"
          formControlName="GrauDadosPessoais"
          [ngClass]="
            formDadosPessoais.get('GrauDadosPessoais').invalid &&
            (temErro || formDadosPessoais.get('GrauDadosPessoais').touched)
              ? 'is-invalid'
              : null
          "
        >
          <option value="">Selecione...</option>
          <option
            *ngFor="let grauInstrucao of listaGrauInstrucao"
            value="{{ grauInstrucao.Codigo }}"
          >
            {{ grauInstrucao.Descricao }}
          </option>
        </select>
      </div>
      <div class="form-group col-xl-6">
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold"
          >Nome da mãe</label
        >
        <input
          [ngStyle]="
            (formDadosPessoais.controls['nomeMaeDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['nomeMaeDadosPessoais'].hasError('minlength')
              ? { 'border-color': 'red' }
              : null
          "
          type="text"
          name="nomeMaeDadosPessoais"
          placeholder="Nome da mãe"
          formControlName="nomeMaeDadosPessoais"
          class="form-control"
          minlength="3"
        />
        <span
          class="form-text text-danger"
          *ngIf="
            (formDadosPessoais.controls['nomeMaeDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['nomeMaeDadosPessoais'].hasError('minlength')
          "
        >
          O nome deve estar completo
        </span>
      </div>
      <div class="form-group col-xl-6">
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold"
          >Nome do pai</label
        >
        <input
          [ngStyle]="
            (formDadosPessoais.controls['nomePaiDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['nomePaiDadosPessoais'].hasError('minlength')
              ? { 'border-color': 'red' }
              : null
          "
          type="text"
          name="nomePaiDadosPessoais"
          placeholder="Nome do pai"
          formControlName="nomePaiDadosPessoais"
          class="form-control"
          minlength="3"
        />
        <span
          class="form-text text-danger"
          *ngIf="
            (formDadosPessoais.controls['nomePaiDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['nomePaiDadosPessoais'].hasError('minlength')
          "
        >
          O nome deve estar completo
        </span>
      </div>

      <div class="form-group col-xl-6">
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold"
          >Nacionalidade</label
        >
        <select
          class="form-control"
          name="nacionalidadeDadosPessoais"
          formControlName="nacionalidadeDadosPessoais"
          (change)="onNacionalidadeChange()"
        >
          <option value="0">Selecione...</option>
          <option *ngFor="let nac of listaNacionalidade" value="{{ nac.Valor }}">
            {{ nac.Descricao }}
          </option>
        </select>
      </div>
      <div
        *ngIf="formDadosPessoais.controls['nacionalidadeDadosPessoais'].value == 10"
        class="form-group col-xl-3"
      >
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold">UF </label>
        <select
          class="form-control"
          name="ufDadosPessoais"
          formControlName="ufDadosPessoais"
          (change)="buscarCidade()"
          [ngStyle]="
            (formDadosPessoais.controls['ufDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['ufDadosPessoais'].status == 'INVALID'
              ? { 'border-color': 'red' }
              : null
          "
          required
        >
          <option value="">Selecione...</option>
          <option *ngFor="let estado of listaUf" value="{{ estado.Codigo }}">
            {{ estado.Descricao }}
          </option>
        </select>
        <span
          class="form-text text-danger"
          *ngIf="
            (formDadosPessoais.controls['ufDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['ufDadosPessoais'].hasError('required')
          "
        >
          Campo obrigatório
        </span>
      </div>
      <div
        *ngIf="formDadosPessoais.controls['nacionalidadeDadosPessoais'].value == 10"
        class="form-group col-xl-3"
      >
        <label class="text-muted font-weight-bold" class="text-muted font-weight-bold"
          >Cidade
        </label>
        <select
          class="form-control"
          name="cidadeDadosPessoais"
          formControlName="cidadeDadosPessoais"
          [ngStyle]="
            (formDadosPessoais.controls['cidadeDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['cidadeDadosPessoais'].status == 'INVALID'
              ? { 'border-color': 'red' }
              : null
          "
          required
        >
          <option value="">Selecione...</option>
          <option *ngFor="let cid of listaMunicipio" value="{{ cid.Codigo }}">
            {{ cid.Nome }}
          </option>
        </select>
        <span
          class="form-text text-danger"
          *ngIf="
            (formDadosPessoais.controls['cidadeDadosPessoais'].touched || temErro) &&
            formDadosPessoais.controls['cidadeDadosPessoais'].hasError('required')
          "
        >
          Campo obrigatório
        </span>
      </div>
    </div>
  </form>
</div>
<div
  *ngIf="loading || !cadastroUsuario?.NumeroDeOrdem"
  class="row d-flex justify-content-center align-items-center"
>
  <i class="fa fa-refresh fa-spin fa"></i>
</div>

<!-- Modal -->
<button
  type="button"
  id="btHorarioModal"
  class="btn btn-primary d-none"
  data-toggle="modal"
  data-target="#horarioModal"
></button>
<div class="modal animated fadeIn" id="horarioModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content col-12">
      <div class="modal-header">
        <h4 class="modal-title">Horário</h4>
      </div>
      <div class="modal-body table-responsive">
        <table *ngIf="horarioEscolhido" class="table table-striped border borda-clara rounded">
          <thead class="thead-dark">
            <tr>
              <th scope="col" class="text-center">#</th>
              <ng-container *ngFor="let dia of listaDiasSemana">
                <th scope="col" class="text-center">{{ dia }}</th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" class="font-weight-bold text-center">Horário pré-intervalo</th>
              <td class="text-center" *ngFor="let dia of formatarSeteDias(horarioEscolhido)">
                <ng-container *ngIf="dia !== null && dia.EntradaAntesAlmoco != '0'">
                  {{ dia?.EntradaAntesAlmoco }} às {{ dia?.SaidaAntesAlmoco }}
                </ng-container>
                <ng-container *ngIf="dia === null || dia.EntradaAntesAlmoco == '0'">
                  -
                </ng-container>
              </td>
            </tr>
            <tr>
              <th scope="row" class="font-weight-bold text-center">Horário pós-intervalo</th>
              <td class="text-center" *ngFor="let dia of formatarSeteDias(horarioEscolhido)">
                <ng-container *ngIf="dia !== null && dia.EntradaDepoisAlmoco != '0'">
                  {{ dia?.EntradaDepoisAlmoco }} às {{ dia?.SaidaDepoisAlmoco }}
                </ng-container>
                <ng-container *ngIf="dia === null || dia.EntradaDepoisAlmoco == '0'">
                  -
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="modal-footer border-0">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="limpaHorario()"
        >
          Cancelar
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">Confirmar</button>
      </div>
    </div>
  </div>
</div>
