import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FiltroListarSintetico } from './classes/FiltroListarSintetico';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root'
})
export class RelatorioSinteticoService {
  localStorage: LocalStorage;
  url_acesso:string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  filial: string;
  codTarefa = "000000000";
  tpProcessamento = "EM";

  constructor( private http: HttpClient, private acesso: AcessoService ) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.url_acesso = this.acesso.validarUrl();
    }
  }

  listarSintetico(filtro: FiltroListarSintetico)
  {
    return this.http.post(`${this.url_acesso}beneficio/listarsintetico/${this.filial}/${this.tpProcessamento}/${this.filial}`, filtro,{headers:this.cabecalho});
  }

  listarFilial()
  {
    return this.http.get(`${this.url_acesso}beneficio/listarfilial/${this.filial}/${this.tpProcessamento}/${this.filial}`, {headers:this.cabecalho});
  }
}
