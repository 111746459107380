import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AcessoService} from '../../service/acesso/acesso.service';
import {StringifyOptions} from 'querystring';
import {LocalStorage} from 'src/app/service/classes/localstorage/LocalStorage';
import {Crypto} from 'src/app/service/classes/crypto/crypto';
import {Console} from 'console';

@Injectable({
  providedIn: 'root',
})
export class CadastroService {
  localStorage: LocalStorage;
  url_acesso: string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  filial: string;
  codTarefa = '000000000';
  tpProcessamento = 'EM';

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.url_acesso = this.acesso.validarUrl();
    }
  }

  // ***********************************
  // MÉTODOS PARA A ETAPA 1  DO CADASTRO
  // ***********************************

  buscarCadastro(numOrdem) {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}cadastro/${numOrdem}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarColaboradorMatricula(matricula: string) {
    return this.http.get(`${this.url_acesso}cadastro-ativo-matricula/${matricula}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  listarCadastro() {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}cadastros/98`, {headers: this.cabecalho});
  }

  buscarVinculo() {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}vinculos`, {headers: this.cabecalho});
  }

  buscarDiasUteisColaborador() {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}diasUteis/${this.tpProcessamento}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarDepartamento() {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}lotacao/${this.filial}`, {headers: this.cabecalho});
  }

  buscarSetor(codigo) {
    var cod_lotacao = codigo.substring(0, 5);
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}lotacao/${this.filial}/${cod_lotacao}`, {
      headers: this.cabecalho,
    });
  }

  buscarCargo(codigo) {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}funcoes/${codigo}`, {headers: this.cabecalho});
  }

  buscarHoraTrabalho(codigo) {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}turnos/${codigo}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarNivel(codigo) {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}niveis/${codigo}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarSindicato() {
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}sindicatos/`, {headers: this.cabecalho});
  }

  inserirEtapa1(dados) {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.put(`${this.url_acesso}cadastros/${this.filial}/`, dados, {
      headers: this.cabecalho,
    });
  }

  // ***********************************
  // MÉTODOS PARA A ETAPA 2  DO CADASTRO
  // ***********************************

  buscarEscolaridade() {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}parametros/grau_instrucao`, {headers: this.cabecalho});
  }

  buscarNacionalidade() {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}parametros/nacionalidade`, {headers: this.cabecalho});
  }

  buscarNaturalidade() {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}parametros/naturalidade`, {headers: this.cabecalho});
  }

  buscarCor() {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}parametros/cor_pele`, {headers: this.cabecalho});
  }

  buscarSexo() {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}parametros/sexo`, {headers: this.cabecalho});
  }

  buscarEstadoCivil() {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}parametros/estado_civil`, {headers: this.cabecalho});
  }

  buscarCep(cep) {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}cep/${cep}`, {headers: this.cabecalho});
  }

  buscarEstado() {
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}estados/`, {headers: this.cabecalho});
  }

  buscarCidade(codigo) {
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}estados/${codigo}`, {headers: this.cabecalho});
  }

  updateEtapa(dados) {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.put(`${this.url_acesso}cadastros/${this.filial}/`, dados, {
      headers: this.cabecalho,
    });
  }

  // ***********************************
  // MÉTODOS PARA A ETAPA 3 DO CADASTRO
  // ***********************************

  buscarCategoriaCnh() {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}parametros/cnh`, {headers: this.cabecalho});
  }

  // buscarBanco() {
  //   //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
  //   this.url_acesso = this.acesso.validarUrl();
  //   return this.http.get(`${this.url_acesso}bancos`, { headers: this.cabecalho });
  // }

  buscarBanco() {
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}bancosBco/`, {headers: this.cabecalho});
  }

  // buscarAgencia(codigoAgencia){
  //   this.url_acesso = this.acesso.validarUrl();
  //   return this.http.get(`${this.url_acesso}agencia/${codigoAgencia}`, { headers: this.cabecalho });
  // }

  buscarAgencia(nomeBanco) {
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}agencia/${nomeBanco}`, {headers: this.cabecalho});
  }

  buscarOperacoes(nomeBanco) {
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}operacoes/${nomeBanco}`, {headers: this.cabecalho});
  }

  buscarColaboradorInativo(cpf: string) {
    return this.http.get(`${this.url_acesso}cadastro-publico/etapas/${cpf}`, {
      headers: this.cabecalho,
    });
  }

  buscarTipoFolha() {
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}tabelas/tipofolha`, {headers: this.cabecalho});
  }

  gerarLink(dados: any) {
    this.url_acesso = this.acesso.validarUrl();
    return this.http.post(`${this.url_acesso}cadastrogerarlink/${this.filial}`, dados, {
      headers: this.cabecalho,
    });
  }

  // ***********************************
  // FUNÇÕES DOS MÉTODOS
  // ***********************************

  mascaraHora(val) {
    var hora = val.substring(0, 2);
    var minuto = val.substring(2, 4);
    return hora + ':' + minuto;
  }

  buscarNivelCadastroPublico(codigo) {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}cadastro-publico/nivel/${this.filial}/${codigo}`, {
      headers: this.cabecalho,
    });
  }
}
