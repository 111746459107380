<!-- <p>relatorio-sintetico works!</p> -->
<div role="tabpanel" class="card" id="tabpanel-relatorio-sintetico">
    <div class="card-header">
        <h3 class="card-title">Relatório Sintético / <small>Conteúdo com gráficos</small></h3>
    </div>
    <div class="card-body">
        <div class="col-md-12">
            <form [formGroup]="formFiltro" (ngSubmit)="submitFiltro()">
                <div class="row">
                    <div class="col-md-3 form-group">
                        <label>Competência</label>
                        <input type="text" class="form-control competencia" name="competencia" formControlName="competencia"  placeholder="Mês / Ano" autocomplete="off" mask="00/0000"/>
                    </div>
                    <div class="col-md-3 form-group">
                        <label>Empresa</label>
                        <select id="sintetico-empfil" name="empresa" formControlName="empresa" class="form-control" >
                            <option value="">Selecione...</option>
                            <option *ngFor="let filial of listaFilial | orderBy: 'NomeEmpfil': false" value="{{filial.Codigo}}"> {{ filial.NomeEmpfil }} </option>
                        </select>
                    </div>
                    <div class="col-md-3 form-group">
                        <label>Ordenar por...</label>
                        <select class="form-control" name="ordenar" formControlName="ordenar">
                            <option value="">Selecione...</option>
                            <option value="TOTAL">Maior Quantidade de beneficiários</option>
                            <option value="VALOR">Maior custo total</option>
                            <option value="PERCAPTA">Maior Custo Percato (R$)</option>
                        </select>
                    </div>
                    <div class="col-md-3 form-group">
                        <label>&nbsp;</label>
                        <button type="submit" class="btn btn-block btn-info">Filtrar <i class="fa fa-filter"></i></button>
                    </div>
                </div>
            </form>
            
        </div>
        <div class="col-md-12">
            <hr />
        </div>
        <div class="col-md-12 text-muted text-center loading" *ngIf="loading" >
            <h3 class="">Aguarde...</h3>
            <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
        </div>
        
        <div class="mt-4" *ngIf="!loading && relatorioSintetico.length > 0" >
            <div>
                <div class="visible-print-block">
                    <h3>Dados</h3>
                </div>
                <div>
                    <hr />
                </div>
                <div>
                    <div class="table-responsive">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>Benefício</th>
                                    <th>QTD</th>
                                    <th>Representação QTD (%)</th>
                                    <th>Custo (R$)</th>
                                    <th>Representação Custo (%)</th>
                                    <th>Custo Percato (R$)</th>
                                </tr>
                            </thead>
                            <tbody id="lista-00">
                                <tr *ngIf="relatorioSintetico.length == 0">
                                    <td colspan="7">...</td>
                                </tr>

                                <tr *ngFor="let dadoSintetico of relatorioSintetico ">
                                    <td> {{ dadoSintetico.Descricao }}</td>
                                    <td class="text-right"> {{ dadoSintetico.Qtd }} </td>
                                    <td class="text-right"> {{ dadoSintetico.RepresentacaoQtd }} </td>
                                    <td class="text-right"> {{ dadoSintetico.Valor }} </td>
                                    <td class="text-right"> {{ dadoSintetico.RepresentacaoValor }} </td>
                                    <td class="text-right"> {{ dadoSintetico.ValorPercapto }} </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colspan="3" class="text-success">
                                        <i class="fa fa-handshake-o" aria-hidden="true"></i>
                                        <strong id="total-sintetico-coberto"></strong>
                                        <span>dos beneficiários estão cobertos</span>
                                    </th>
                                    <th colspan="3" class="text-danger">
                                        <i class="fa fa-ban" aria-hidden="true"></i>
                                        <strong id="total-sintetico-descoberto"></strong>
                                        <span>dos beneficiários estão descobertos</span>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>


            <div>
                <h3>Gráficos</h3>
                <hr />
                <div class="row">
                    <div class=" col-lg-6">
                        <h4 class="text-muted">Quantidade</h4>
                        <hr class="m-1"/>
                        <div echarts [options]="grafico1" id="folhaChart" class="chart has-fixed-heightd-flex d-flex justify-content-center"></div>
                    </div>
                    <div class="col-lg-6">
                        <h4 class="text-muted">Custo (R$)</h4>
                        <hr class="m-1"/>
                        <div echarts [options]="grafico2" id="folhaChart" class="chart has-fixed-heightd-flex d-flex justify-content-center"></div>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <h4 class="text-muted">Valor Percato</h4>
                        <hr class="m-1"/>
                        <div echarts [options]="grafico3" id="folhaChart" class="chart has-fixed-heightd-flex d-flex justify-content-center"></div>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <h4 class="text-muted">Corbertura Benefícios</h4>
                        <hr class="m-1"/>
                        <div echarts [options]="grafico4" id="folhaChart" class="chart has-fixed-heightd-flex d-flex justify-content-center"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>