<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<div class="card">
  <div class="card-header">
    <h3 class="font-weight-semibold">
      <i
        class="fa fa-arrow-left pr-3 btn"
        aria-hidden="true"
        (click)="voltarBusca()"
        style="cursor: pointer; bottom: 2px"
      ></i
      >Cadastro de Cessão
    </h3>
  </div>
  <form #cessao="ngForm" [formGroup]="formCessao" (ngSubmit)="submitCessao()">
    <div class="card-body">
      <div class="row" *ngIf="colaborador">
        <div class="form-group col-md-2">
          <label class="justify-content-start font-weight-semibold">Matricula </label>
          <input
            type="text"
            placeholder="Funcionario"
            class="form-control"
            disabled
            [value]="navigation.matricula"
          />
        </div>

        <div class="form-group col-md-6">
          <label class="justify-content-start font-weight-semibold">Nome do Colaborador </label>
          <input
            type="text"
            placeholder="Funcionario"
            class="form-control"
            disabled
            [value]="navigation.nome"
          />
        </div>
        <div class="form-group col-md-4">
          <label class="justify-content-start font-weight-semibold">Vínculo </label>
          <input
            type="text"
            placeholder="Funcionario"
            class="form-control"
            disabled
            [value]="navigation.vinculo"
          />
        </div>
      </div>
      <hr />
      <fieldset>
        <legend>
          <h6 class="font-weight-semibold">Dados da Cessão</h6>
        </legend>
        <div class="row">
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Número do Processo</label>
            <input
              type="text"
              placeholder="Número do processo"
              formControlName="numeroProcesso"
              class="form-control"
              [ngClass]="
                formCessao.get('numeroProcesso').invalid &&
                (temErro || formCessao.get('numeroProcesso').touched)
                  ? 'is-invalid'
                  : null
              "
              maxlength="30"
              mask="0*"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('numeroProcesso').invalid &&
                (temErro || formCessao.get('numeroProcesso').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Regime de Trabalho</label>
            <select
              class="form-control"
              formControlName="regimeTrabalho"
              [ngClass]="
                formCessao.get('regimeTrabalho').invalid &&
                (temErro || formCessao.get('regimeTrabalho').touched)
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option
                *ngFor="let regimeTrabalho of listaRegimeTrabalho"
                value="{{ regimeTrabalho.Codigo }}"
              >
                {{ regimeTrabalho.Descricao }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('regimeTrabalho').invalid &&
                (temErro || formCessao.get('regimeTrabalho').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Regime de Previdência</label>
            <select
              class="form-control"
              formControlName="regimePrevidencia"
              [ngClass]="
                formCessao.get('regimePrevidencia').invalid &&
                (temErro || formCessao.get('regimePrevidencia').touched)
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option
                *ngFor="let regimePrevidencia of listaRegimePrevidencia"
                value="{{ regimePrevidencia.Codigo }}"
              >
                {{ regimePrevidencia.Descricao }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('regimePrevidencia').invalid &&
                (temErro || formCessao.get('regimePrevidencia').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Modalidade</label>
            <select
              class="form-control"
              formControlName="modalidade"
              [ngClass]="
                formCessao.get('modalidade').invalid &&
                (temErro || formCessao.get('modalidade').touched)
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option *ngFor="let modalidade of listaModalidade" value="{{ modalidade.Codigo }}">
                {{ modalidade.Descricao }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('modalidade').invalid &&
                (temErro || formCessao.get('modalidade').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Data Inicial</label>
            <!-- (keyup)="verificaData('dataInicial', 'dataFinal')" -->
            <input
              type="date"
              formControlName="dataInicial"
              class="form-control pr-0"
              max="9999-12-31"
              [ngClass]="
                formCessao.get('dataInicial').invalid &&
                (temErro || formCessao.get('dataInicial').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('dataInicial').invalid &&
                (temErro || formCessao.get('dataInicial').touched)
              "
            >
              {{
                formCessao.get('dataInicial').hasError('dataInvalida')
                  ? 'Data deve ser igual ou menor à data atual'
                  : 'Campo Obrigatório'
              }}
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Data Final <small>(opcional)</small></label>
            <input
              type="date"
              formControlName="dataFinal"
              class="form-control"
              max="9999-12-31"
              [ngClass]="
                formCessao.get('dataFinal').invalid &&
                (temErro || formCessao.get('dataFinal').touched)
                  ? 'is-invalid'
                  : null
              "
              style="padding-right: 0"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('dataFinal').invalid &&
                (temErro || formCessao.get('dataFinal').touched)
              "
            >
              {{
                formCessao.get('dataFinal').hasError('data') ? 'Data Inválida' : 'Campo Obrigatório'
              }}
            </span>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>
          <h6 class="font-weight-semibold">Dados Origem</h6>
        </legend>
        <div class="row">
          <div class="col-md-5 form-group">
            <label class="font-weight-semibold">Categoria Origem</label>
            <select
              class="form-control"
              formControlName="origemCategoria"
              [ngClass]="
                formCessao.get('origemCategoria').invalid &&
                (temErro || formCessao.get('origemCategoria').touched)
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option *ngFor="let categoria of listaCategoria" value="{{ categoria.Codigo }}">
                {{ categoria.Descricao }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('origemCategoria').invalid &&
                (temErro || formCessao.get('origemCategoria').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Data Adm Cedente</label>
            <input
              type="date"
              class="form-control"
              formControlName="origemDataAdmCedente"
              max="9999-12-31"
              [ngClass]="
                formCessao.get('origemDataAdmCedente').invalid &&
                (temErro || formCessao.get('origemDataAdmCedente').touched)
                  ? 'is-invalid'
                  : null
              "
              style="padding-right: 0"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('origemDataAdmCedente').invalid &&
                (temErro || formCessao.get('origemDataAdmCedente').touched)
              "
            >
              {{
                formCessao.get('origemDataAdmCedente').hasError('data')
                  ? 'Data Inválida'
                  : 'Campo Obrigatório'
              }}
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Matrícula Cedente <small>(opcional)</small></label>
            <input
              type="text"
              formControlName="matriculaCedente"
              class="form-control"
              placeholder="Matrícula cedente"
              maxlength="30"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">CNPJ Cedente</label>
            <input
              type="text"
              formControlName="cnpjCedente"
              class="form-control"
              placeholder="CNPJ cedente"
              mask="00.000.000/0000-00"
              [ngClass]="
                formCessao.get('cnpjCedente').invalid &&
                (temErro || formCessao.get('cnpjCedente').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('cnpjCedente').invalid &&
                (temErro || formCessao.get('cnpjCedente').touched)
              "
            >
              {{
                formCessao.get('cnpjCedente').errors?.cnpjInvalido
                  ? 'CNPJ Inválido'
                  : 'Campo Obrigatório'
              }}
            </span>
          </div>
          <!-- <div class="col-md-3 form-group">
            <label class="font-weight-semibold">CNPJ Cedente</label>
            <select
              (change)="carregarCedente()"
              class="form-control"
              formControlName="cnpjCedente"
              [ngClass]="
                formCessao.get('cnpjCedente').invalid &&
                (temErro || formCessao.get('cnpjCedente').touched)
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">CNPJ</option>
              <option *ngFor="let agente of listaCedente" value="{{ agente.CNPJ }}">
                {{ agente.CNPJ | cnpj }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('cnpjCedente').invalid &&
                (temErro || formCessao.get('cnpjCedente').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div> -->

          <div class="col-md-5 form-group">
            <label class="font-weight-semibold">Razão social</label>
            <input
              type="text"
              class="form-control"
              placeholder="Razão social"
              formControlName="cedenteRazaoSocial"
              maxlength="80"
              [ngClass]="
                formCessao.get('cedenteRazaoSocial').invalid &&
                (temErro || formCessao.get('cedenteRazaoSocial').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('cedenteRazaoSocial').invalid &&
                (temErro || formCessao.get('cedenteRazaoSocial').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">CEP</label>
            <input
              type="text"
              class="form-control"
              placeholder="CEP"
              mask="00.000-000"
              formControlName="cedenteCep"
              (keyup)="carregarCep()"
              [ngClass]="
                formCessao.get('cedenteCep').invalid &&
                (temErro || formCessao.get('cedenteCep').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('cedenteCep').invalid &&
                (temErro || formCessao.get('cedenteCep').touched)
              "
            >
              {{
                formCessao.get('cedenteCep').hasError('invalid')
                  ? 'CEP Inválido'
                  : 'Campo Obrigatório'
              }}
            </span>
            <span class="form-text" *ngIf="loadingCep">
              Carregando
              <i class="fa fa-spinner fa-spin" aria-hidden="false"></i>
            </span>
            <!-- <span class="form-text" *ngIf="loadingCepAgentIntegracao">Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i></span> -->
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">UF</label>
            <input
              type="text"
              class="form-control"
              placeholder="UF"
              [readonly]="!formCessao.get('cedenteCep').hasError('invalid')"
              formControlName="cedenteUf"
              [ngClass]="
                formCessao.get('cedenteUf').invalid &&
                (temErro || formCessao.get('cedenteUf').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('cedenteUf').invalid &&
                (temErro || formCessao.get('cedenteUf').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-6 form-group">
            <label class="font-weight-semibold">Município <small>(opcional)</small></label>
            <input
              type="text"
              class="form-control"
              placeholder="Município"
              maxlength="50"
              [readonly]="!formCessao.get('cedenteCep').hasError('invalid')"
              formControlName="cedenteMunicipio"
              [ngClass]="
                formCessao.get('cedenteMunicipio').value === '' &&
                ((temErro && formCessao.get('cedenteCep').value.length === 8) ||
                  (formCessao.get('cedenteCep').touched &&
                    formCessao.get('cedenteCep').invalid &&
                    formCessao.get('cedenteCep').value.length === 8) ||
                  (formCessao.get('cedenteCep').touched && formCessao.get('cedenteCep').valid))
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('cedenteMunicipio').value === '' &&
                ((temErro && formCessao.get('cedenteCep').value.length === 8) ||
                  (formCessao.get('cedenteCep').touched &&
                    formCessao.get('cedenteCep').invalid &&
                    formCessao.get('cedenteCep').value.length === 8) ||
                  (formCessao.get('cedenteCep').touched && formCessao.get('cedenteCep').valid))
              "
            >
              O campo não foi carregado corretamente. Por favor, verifique os dados e tente
              novamente.
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Bairro</label>
            <input
              type="text"
              class="form-control"
              placeholder="Bairro"
              maxlength="40"
              [readonly]="!formCessao.get('cedenteCep').hasError('invalid')"
              formControlName="cedenteBairro"
              [ngClass]="
                formCessao.get('cedenteBairro').invalid &&
                (temErro || formCessao.get('cedenteBairro').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('cedenteBairro').invalid &&
                (temErro || formCessao.get('cedenteBairro').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-6 form-group">
            <label class="font-weight-semibold">Logradouro</label>
            <input
              type="text"
              class="form-control"
              placeholder="Logradouro"
              maxlength="60"
              [readonly]="!formCessao.get('cedenteCep').hasError('invalid')"
              formControlName="cedenteLogradouro"
              [ngClass]="
                formCessao.get('cedenteLogradouro').invalid &&
                (temErro || formCessao.get('cedenteLogradouro').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('cedenteLogradouro').invalid &&
                (temErro || formCessao.get('cedenteLogradouro').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-2 form-group">
            <label class="font-weight-semibold">Número <small>(opcional)</small></label>
            <input
              type="text"
              class="form-control"
              placeholder="Número"
              mask="0*"
              formControlName="cedenteNumero"
              [ngClass]="
                formCessao.get('cedenteNumero').invalid &&
                (temErro || formCessao.get('cedenteNumero').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('cedenteNumero').invalid &&
                (temErro || formCessao.get('cedenteNumero').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Complemento <small>(opcional)</small></label>
            <input
              type="text"
              class="form-control"
              placeholder="Complemento"
              maxlength="40"
              formControlName="cedenteComplemento"
              [ngClass]="
                formCessao.get('cedenteComplemento').invalid &&
                (temErro || formCessao.get('cedenteComplemento').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('cedenteComplemento').invalid &&
                (temErro || formCessao.get('cedenteComplemento').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Telefone <small>(opcional)</small></label>
            <input
              type="text"
              class="form-control"
              placeholder="Telefone"
              mask="(00) 00000-0000"
              formControlName="cedenteTelefone"
              [ngClass]="
                formCessao.get('cedenteTelefone').invalid &&
                (temErro || formCessao.get('cedenteTelefone').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('cedenteTelefone').invalid &&
                (temErro || formCessao.get('cedenteTelefone').touched)
              "
            >
              Telefone inválido
            </span>
          </div>
          <div class="col-md-5 form-group">
            <label class="font-weight-semibold">E-mail <small>(opcional)</small></label>
            <input
              type="text"
              class="form-control"
              placeholder="E-mail"
              maxlength="80"
              formControlName="cedenteEmail"
              [ngClass]="
                formCessao.get('cedenteEmail').invalid &&
                (temErro || formCessao.get('cedenteEmail').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('cedenteEmail').invalid &&
                (temErro || formCessao.get('cedenteEmail').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Contato <small>(opcional)</small></label>
            <input
              type="text"
              class="form-control"
              placeholder="Contato"
              maxlength="80"
              formControlName="cedenteContato"
              [ngClass]="
                formCessao.get('cedenteContato').invalid &&
                (temErro || formCessao.get('cedenteContato').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('cedenteContato').invalid &&
                (temErro || formCessao.get('cedenteContato').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>
          <h6 class="font-weight-semibold">Dados do Contrato</h6>
        </legend>
        <div class="row">
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Cargo</label>
            <input
              type="text"
              class="form-control"
              formControlName="cargo"
              placeholder="Cargo"
              maxlength="100"
              [ngClass]="
                formCessao.get('cargo').invalid && (temErro || formCessao.get('cargo').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('cargo').invalid && (temErro || formCessao.get('cargo').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">CBO Cargo</label>
            <select
              class="form-control"
              formControlName="cboCargo"
              [ngClass]="
                formCessao.get('cboCargo').invalid &&
                (temErro || formCessao.get('cboCargo').touched)
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option *ngFor="let cargoCbo of listaCargoCbo" value="{{ cargoCbo.Codigo }}">
                {{ cargoCbo.Descricao }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('cboCargo').invalid &&
                (temErro || formCessao.get('cboCargo').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">Cargo Comissionado <small>(opcional)</small></label>
            <input
              type="text"
              class="form-control"
              formControlName="cargoComissionado"
              placeholder="Cargo comissionado"
              maxlength="100"
            />
          </div>
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold">CBO Comissão <small>(opcional)</small></label>
            <select class="form-control" formControlName="cboComissao">
              <option value="">Selecione...</option>
              <option *ngFor="let cargoCbo of listaCargoCbo" value="{{ cargoCbo.Codigo }}">
                {{ cargoCbo.Descricao }}
              </option>
            </select>
          </div>

          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Alíquota (%)</label>
            <input
              type="text"
              class="form-control"
              formControlName="aliquota"
              placeholder="Alíquota"
              (input)="mascaraAliquotaPatronal($event, 'aliquota')"
              [ngClass]="
                formCessao.get('aliquota').invalid &&
                (temErro || formCessao.get('aliquota').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('aliquota').invalid &&
                (temErro || formCessao.get('aliquota').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Patronal (%)</label>
            <input
              type="text"
              class="form-control"
              formControlName="patronal"
              placeholder="Patronal"
              (input)="mascaraAliquotaPatronal($event, 'patronal')"
              [ngClass]="
                formCessao.get('patronal').invalid &&
                (temErro || formCessao.get('patronal').touched)
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('patronal').invalid &&
                (temErro || formCessao.get('patronal').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Instituto de Previdência</label>
            <select
              class="form-control"
              formControlName="institutoPrevidencia"
              [ngClass]="
                formCessao.get('institutoPrevidencia').invalid &&
                (temErro || formCessao.get('institutoPrevidencia').touched)
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option
                *ngFor="let instituto of listaInstitutoPrevidencia"
                value="{{ instituto.Codigo }}"
              >
                {{ instituto.Descricao }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formCessao.get('institutoPrevidencia').invalid &&
                (temErro || formCessao.get('institutoPrevidencia').touched)
              "
            >
              Campo Obrigatório
            </span>
          </div>

          <div class="col-12 form-group">
            <div
              #divRemuneracao
              class="form-check form-check-left"
              (click)="visualizacao ? null : mudarRemuneracaoContinua()"
            >
              <label
                [ngClass]="
                  remuneracaoContinua ? 'form-check-label font-weight-semibold' : 'form-check-label'
                "
                for="divRemuneracao"
              >
                <!-- <div class="uniform-checker">
                  <span [ngClass]="remuneracaoContinua ? 'checked bg-primary' : ''">
                    <input
                      type="checkbox"
                      class="form-input-styled"
                      checked=""
                      data-fouc=""
                      formControlName="remuneracaoContinua"
                    />
                  </span>
                </div>
                Continuar a remuneração em outro Órgão? -->
              </label>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>
          <h6 class="font-weight-semibold">Nomeação</h6>
        </legend>
        <div class="row">
          <div class="form-group col-8">
            <label for="idClassificacaoAto">Classificação do Ato <small>(opcional)</small></label>
            <select
              class="form-control"
              id="idClassificacaoAto"
              formControlName="idClassificacaoAto"
            >
              <option value="">Selecione uma opção</option>
              <option *ngFor="let item of listaIdClassificacaoAto" [value]="item.Cod">
                {{ item.Cod }} - {{ item.Nome }}
              </option>
            </select>
          </div>
          <div class="form-group col-4">
            <label for="numeroDoe">Número DOE <small>(opcional)</small></label>
            <input
              class="form-control"
              placeholder="Número DOE"
              type="text"
              id="numeroDoe"
              formControlName="numeroDoe"
            />
          </div>

          <div class="form-group col-4">
            <label for="dtDoe" class="font-weigth-semibold"
              >Data DOE <small>(opcional)</small></label
            >
            <input
              type="date"
              max="9999-12-31"
              class="form-control"
              id="dtDoe"
              formControlName="dtDoe"
            />
          </div>
          <div class="form-group col-4">
            <label for="numeroAto">Número Ato <small>(opcional)</small></label>
            <input
              class="form-control"
              placeholder="Número Ato"
              type="text"
              id="numeroAto"
              formControlName="numeroAto"
            />
          </div>
          <div class="form-group col-4">
            <label for="dtAto" class="font-weigth-semibold"
              >Data Ato <small>(opcional)</small></label
            >
            <input
              type="date"
              max="9999-12-31"
              class="form-control"
              id="dtAto"
              formControlName="dtAto"
            />
          </div>
        </div>
      </fieldset>
    </div>
    <div class="card-footer text-right">
      <button (click)="voltarBusca()" class="btn btn-danger mr-2">
        {{ visualizacao ? 'Voltar' : 'Cancelar' }}
      </button>
      <button type="submit" class="btn btn-primary" *ngIf="!visualizacao">
        {{ editar ? 'Atualizar' : 'Submeter' }}
        <i class="fa fa-paper-plane ml-2"></i>
      </button>
    </div>
  </form>
</div>
