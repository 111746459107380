import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filtroFiliais',
})
export class FiltroFiliaisPipe implements PipeTransform {
  transform(items: any[], buscaTexto: string): any[] {
    if (!items) {
      return [];
    }
    if (!buscaTexto) {
      return items;
    }

    buscaTexto = buscaTexto.toLowerCase();

    return items.filter((filial) => {
      return (
        filial.Nome.toLowerCase().includes(buscaTexto) ||
        filial.Codigo.toString().includes(buscaTexto)
      );
    });
  }
}
