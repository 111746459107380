<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<div class="container card">
  <div class="card-header header-elements-inline">
    <h3 class="card-title">Cadastro de Benefício</h3>
  </div>
  <form #beneficio="ngForm" [formGroup]="formBeneficio" (ngSubmit)="submitBeneficio()" name="beneficio" id="beneficio">

    <div class="card-body">
      <div class="row">
        <div class="col-md-2 form-group">
          <label>Código</label>
          <input type="number" formControlName="codigo" class="form-control input-lg" placeholder="Código" id="input-modal-codigo" name="Codigo" min="0" required
                 [ngStyle]="formBeneficio.controls['codigo'].status == 'INVALID' && (formBeneficio.controls['codigo'].dirty || temErro) ? {'border-color':'red'} : null"
                 oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                 maxlength="4" />
          <span *ngIf="formBeneficio.controls['codigo'].status == 'INVALID' &&  temErro" class="form-text text-danger">Campo Obrigatório</span>
        </div>
        <div class="col-md-6 form-group">
          <label>Descrição</label>
          <input type="text" formControlName="descricao" class="form-control input-lg" placeholder="Descrição" id="input-modal-descricao" name="Descricao" required maxlength="30"
                 [ngStyle]="formBeneficio.controls['descricao'].status == 'INVALID' && (formBeneficio.controls['descricao'].dirty || temErro) ? {'border-color':'red'} : null" />
          <span *ngIf="formBeneficio.controls['descricao'].status == 'INVALID' && temErro" class="form-text text-danger">Campo Obrigatório</span>
        </div>

        <div class="col-md-2 form-group">
          <label>Data Inicial</label>
          <input type="date" formControlName="dataInicial" class="form-control input-sm calendario formato-data" style="padding-right: 0;" required
                 [ngStyle]="formBeneficio.controls['dataInicial'].status == 'INVALID' && (formBeneficio.controls['dataInicial'].dirty || temErro) ? {'border-color':'red'} : null" />
          <span *ngIf="formBeneficio.controls['dataInicial'].status == 'INVALID' && temErro" class="form-text text-danger">Campo Obrigatório</span>
          <span *ngIf="formBeneficio.controls['dataInicial'].hasError('data')" class="form-text text-danger">Data inválida</span>
        </div>
        <div class="col-md-2 form-group ">
          <label class="d-flex align-items-center">
            Data Final
          </label>
          <input type="date"
                 formControlName="dataFinal"
                 class="form-control controle-data-final input-sm calendario formato-data"
                 [ngStyle]="formBeneficio.controls['dataFinal'].status == 'INVALID' && (formBeneficio.controls['dataFinal'].dirty || temErro) ? {'border-color':'red'} : null"
                  />
          <span *ngIf="formBeneficio.controls['dataFinal'].status == 'INVALID' && temErro" class="form-text text-danger">Campo Obrigatório</span>
          <span *ngIf="formBeneficio.controls['dataFinal'].hasError('data')" class="form-text text-danger">Data inválida</span>
        </div>
        <div class="col-md-8 form-group">
          <label>Grupo</label>
          <select class="form-control" formControlName="grupo" name="grupo"
                  [ngStyle]="formBeneficio.controls['grupo'].status == 'INVALID' && (formBeneficio.controls['grupo'].dirty || temErro) ? {'border-color':'red'} : null" required>
            <option value="">Selecione...</option>
            <option *ngFor="let item of grupo" value="{{item.Codigo}}" ng-reflect-value="">{{item.Descricao}}</option>
          </select>
          <span *ngIf="formBeneficio.controls['grupo'].status == 'INVALID' && temErro" class="form-text text-danger">Campo Obrigatório</span>
        </div>
        <div class="col-md-4 form-group">
          <label class="d-flex align-items-center">
            Exclusivo <small>(Opcional)</small>
          </label>
          <select class="form-control controle-exclusivo" formControlName="f03" name="F03"
                  [ngStyle]="formBeneficio.controls['f03'].status == 'INVALID' && (formBeneficio.controls['f03'].dirty || temErro) ? {'border-color' : 'red'} : null">
            <option value="">Selecione...</option>
            <option *ngFor="let item of tpBeneficio" value="{{item.Codigo}}" ng-reflect-value="">{{item.Descricao}}</option>
          </select>

        </div>


        <div class="col-md-8">
          <div class="card">

            <div class="card-header pr-3 pl-3">
              <h4 class="text-muted"> Origem do Recurso </h4>
              <span *ngIf="!formBeneficio.get('tpEmpresaCheckBox').value && !formBeneficio.get('tpFuncCheckBox').value  && temErro"
                    [ngStyle]="{'color': 'red'}">
                Informe valores para <strong>Empresa</strong> e/ou  <strong>Funcionário</strong>
              </span>
            </div>

            <div class="card-body">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Entidade</th>
                    <!--<th>Verba</th>-->
                    <th>Tipo</th>
                    <th>Quantidade</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="p-2">
                      <div class="checkbox">
                        <label class="d-flex align-items-center">
                          <input type="checkbox" class="controle-empresa" formControlName="tpEmpresaCheckBox" (change)="empresaCheckBox()" name="tipo-empresa" value="E" /> &nbsp;
                          <span> Empresa</span>
                        </label>
                      </div>
                    </td>
                    <td class="p-2">
                      <select formControlName="tipoEmpresa"
                              class="form-control controle-empresa"
                              name="tipoEmpresa"
                              [ngStyle]="formBeneficio.controls['tipoEmpresa'].status == 'INVALID' && (formBeneficio.controls['tipoEmpresa'].dirty || temErro) ? {'border-color':'red'} : null">
                        <option value="">Selecione...</option>
                        <option value="V">R$ (valor)</option>
                        <option value="%">% (pecentual)</option>
                      </select>
                      <span *ngIf="formBeneficio.controls['tipoEmpresa'].status == 'INVALID' && temErro" class="form-text text-danger">Campo Obrigatório</span>
                    </td>
                    <td class="p-2">
                      <input type="text" currencyMask [options]="{ prefix: '', thousands: '', decimal: ',', align: 'left' }" formControlName="valorEmpresa" class="form-control controle-empresa dinheiro" name="valor-empresa" placeholder="Valor" required
                             [ngStyle]="formBeneficio.controls['valorEmpresa'].status == 'INVALID' && (formBeneficio.controls['valorEmpresa'].dirty || temErro) ? {'border-color':'red'} : null" />
                      <span *ngIf="formBeneficio.controls['valorEmpresa'].status == 'INVALID' && temErro" class="form-text text-danger">Campo Obrigatório</span>
                    </td>

                  </tr>
                  <tr>
                    <td class="p-1">
                      <div class="checkbox pl-1">
                        <label class="d-flex align-items-center">
                          <input type="checkbox" class="controle-funcionario" formControlName="tpFuncCheckBox" (change)="funcionarioCheckBox()" name="tipo-funcionario" value="F" /> &nbsp;
                          <span> Funcionário</span>
                        </label>
                      </div>
                    </td>
    
                    <td class="p-2">
                      <select formControlName="tipoFuncionario"
                              class="form-control controle-funcionario"
                              name="tipoFuncionario"
                              [ngStyle]="formBeneficio.controls['tipoFuncionario'].status == 'INVALID' && (formBeneficio.controls['tipoFuncionario'].dirty || temErro) ? {'border-color':'red'} : null">
                        <option value="">Selecione...</option>
                        <option value="V">R$ (valor)</option>
                        <option value="%">% (pecentual)</option>
                      </select>
                      <span *ngIf="formBeneficio.controls['tipoFuncionario'].status == 'INVALID' && temErro" class="form-text text-danger">Campo Obrigatório</span>
                    </td>
                    <td class="p-2">
                      <input type="text" currencyMask [options]="{ prefix: '', thousands: '', decimal: ',', align: 'left' }" formControlName="valorFuncionario" class="form-control controle-funcionario dinheiro" name="valor-funcionario" placeholder="Valor" required
                             [ngStyle]="formBeneficio.controls['valorFuncionario'].status == 'INVALID' && (formBeneficio.controls['valorFuncionario'].dirty || temErro) ? {'border-color':'red'} : null" />
                      <span *ngIf="formBeneficio.controls['valorFuncionario'].status == 'INVALID' && temErro" class="form-text text-danger">Campo Obrigatório</span>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <p class="text-muted">Informe ao menos para uma das entidades: <strong>Empresa</strong> e/ou  <strong>Funcionário</strong></p>
            </div>
          </div>
        </div>

        <div class="col-md-4 form-group">
          <div class="h-100 card">
            <div class="card-header">
              <h4 class="text-muted checkbox">
                <label class="d-flex align-items-center">
                  Faixa Etária <small> (Opcional)</small>
                </label>
              </h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 form-group">
                  <label>Inicial</label>
                  <div class="input-group">
                    <input type="number" formControlName="idadeMinimaMeses" class="form-control controle-faixa-etaria" placeholder="Em meses" name="faixa-ini" min="0"
                           [ngStyle]="formBeneficio.controls['idadeMinimaMeses'].status == 'INVALID' && (formBeneficio.controls['idadeMinimaMeses'].dirty || temErro) ? {'border-color':'red'} : null" />
                  </div>
                  <span *ngIf="formBeneficio.controls['idadeMinimaMeses'].status == 'INVALID' && temErro" class="form-text text-danger">Campo Obrigatório</span>
                </div>
                <div class="col-md-6 form-group">
                  <label>Final</label>
                  <div class="input-group">
                    <input type="number" formControlName="idadeMaximaMeses" class="form-control controle-faixa-etaria" placeholder="Em meses" name="faixa-fin" min="0"
                           [ngStyle]="formBeneficio.controls['idadeMaximaMeses'].status == 'INVALID' && (formBeneficio.controls['idadeMaximaMeses'].dirty || temErro) ? {'border-color':'red'} : null" />
                    <span *ngIf="formBeneficio.controls['idadeMaximaMeses'].status == 'INVALID' && temErro" class="form-text text-danger">Campo Obrigatório</span>
                  </div>
                </div>
                <span *ngIf="formBeneficio.controls['idadeMaximaMeses'].status == 'INVALID' && temErro" class="form-text text-danger">Campo Obrigatório</span>
              </div>
            </div>
            <div class="card-footer">
              <p class="text-muted">Informe os valores em meses</p>
            </div>
          </div>
        </div>
        <div class=" col-md-12 form-group">
          <div class=" h-100  card">
            <div class="card-header">
              <h4 class="text-muted">
                Valoração do Benefício <span class="badge lb-modal-valor-verba">R$ {{badgeValoracao}}</span>
              </h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-2 form-group">
                  <label>Competência</label>
                  <input type="month" class="form-control competencia input-lg" formControlName="dtcomp" (change)="dtcompChange()" name="dtcomp" data-vrbexterna=""  required
                         [ngStyle]="formBeneficio.controls['dtcomp'].status == 'INVALID' && (formBeneficio.controls['dtcomp'].dirty || temErro) ? {'border-color':'red'} : null"  style="padding-right: 0;"/>
                  <span *ngIf="formBeneficio.controls['dtcomp'].status == 'INVALID' && temErro" class="form-text text-danger">Campo Obrigatório</span>
                </div>
                <div class="col-md-4 form-group">
                  <label>Verba p/ Cálculo</label>
                  <select formControlName="constanteExternaVrb" (change)="alteraValoracao()" class="form-control" id="input-modal-verba-externa" name="verbaexterna"
                          [ngStyle]="formBeneficio.controls['constanteExternaVrb'].status == 'INVALID' && (formBeneficio.controls['constanteExternaVrb'].dirty || temErro) ? {'border-color':'red'} : null" required>
                    <option value="">Selecione...</option>
                    <option *ngFor="let item of vrbExterna" value="{{item.Verbas}}" [attr.data-valor]="item.Valor">{{item.Verbas}} - {{item.Observacao}} (Valor: {{item.Valor.toFixed(2).replace('.',',')}})</option>
                  </select>
                  <span *ngIf="formBeneficio.controls['constanteExternaVrb'].status == 'INVALID' && temErro" class="form-text text-danger">Campo Obrigatório</span>
                </div>
                <div class="col-md-3 form-group">
                  <label>Classificação</label>
                  <select formControlName="f02" class="form-control" name="F02"
                          [ngStyle]="formBeneficio.controls['f02'].status == 'INVALID' && (formBeneficio.controls['f02'].dirty || temErro) ? {'border-color':'red'} : null" required>
                    <option value="">Selecione...</option>
                    <option value="0">Valor fixo</option>
                    <option value="1">Valor variável com teto máximo</option>
                  </select>
                  <span *ngIf="formBeneficio.controls['f02'].status == 'INVALID' && temErro" class="form-text text-danger">Campo Obrigatório</span>
                </div>
                <div class="col-md-3 form-group">
                  <label>Verba do Benefício</label>
                  <select formControlName="verba" class="form-control" id="input-modal-verba" name="verba"
                          [ngStyle]="formBeneficio.controls['verba'].status == 'INVALID' && (formBeneficio.controls['verba'].dirty || temErro) ? {'border-color':'red'} : null" required>
                    <option value="">Selecione...</option>
                    <option *ngFor="let item of verbas" value="{{item.Cod}}" ng-reflect-value="">{{item.Nome}}</option>
                  </select>
                  <span *ngIf="formBeneficio.controls['verba'].status == 'INVALID' && temErro" class="form-text text-danger">Campo Obrigatório</span>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <p class="text-muted">A <strong>classificação</strong> determina o limite máximo do benefício</p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="card-footer text-right">
      <button type="button" (click)="voltar()" class="mr-2 btn btn-danger">Voltar</button>
      <button type="submit" form="beneficio" class="btn btn-primary">Salvar</button>
    </div>
  </form>

</div>
