<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="card">
  <div class="card-header">
    <h3 class="card-title">
      <i
        class="fa fa-arrow-left pr-3 btn"
        aria-hidden="true"
        style="cursor: pointer; bottom: 2px"
        (click)="retornarTelaColaboradores()"
      ></i>
      Dados do Benefício
    </h3>
  </div>
  <div class="card-body row">
    <!-- *ngIf="colaborador" -->

    <div class="form-group col-md-2">
      <label class="justify-content-start font-weight-semibold">Matricula </label>
      <input
        type="text"
        placeholder="Matrícula"
        class="form-control"
        [value]="infoColaborador.matricula"
        disabled
      />
    </div>

    <div class="form-group col-md-6">
      <label class="justify-content-start font-weight-semibold">Nome do Colaborador </label>
      <input
        type="text"
        placeholder="Colaborador"
        class="form-control"
        [value]="infoColaborador.nome"
        disabled
      />
    </div>
    <div class="form-group col-md-4">
      <label class="justify-content-start font-weight-semibold">Vínculo </label>
      <input
        type="text"
        placeholder="Vínculo"
        class="form-control"
        [value]="infoColaborador.vinculo"
        disabled
      />
    </div>
  </div>
</div>

<div class="card">
  <form class="card-body" [formGroup]="formCadastro">
    <div class="row">
      <!-- dados benefício -->
      <fieldset class="col-md-12">
        <legend>
          <h6 class="font-weight-semibold">Dados do Benefício</h6>
        </legend>
        <div class="row">
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold" for="dtPublicacao">Data Publicação</label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="dtPublicacao"
                id="dtPublicacao"
                max="9999-12-31"
                [ngClass]="
                  formCadastro.get('dtPublicacao').invalid &&
                  formCadastro.get('dtPublicacao').touched
                    ? 'is-invalid'
                    : null
                "
              />
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('dtPublicacao').invalid && formCadastro.get('dtPublicacao').touched
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold" for="tipo">Tipo</label>
            <select
              class="form-control"
              type="text"
              formControlName="tipo"
              id="tipo"
              [ngClass]="
                formCadastro.get('tipo').invalid && formCadastro.get('tipo').touched
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option *ngFor="let tipo of listaTipo" [value]="tipo.Codigo">
                {{ tipo.Codigo }} - {{ tipo.Descricao }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="formCadastro.get('tipo').invalid && formCadastro.get('tipo').touched"
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold" for="infoOrigem">Informação Que Originou</label>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                formControlName="infoOrigem"
                id="infoOrigem"
                placeholder="Info"
                [ngClass]="
                  formCadastro.get('infoOrigem').invalid && formCadastro.get('infoOrigem').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <!-- qual length max? -->
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('infoOrigem').invalid && formCadastro.get('infoOrigem').touched
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-2 form-group">
            <label class="font-weight-semibold" for="deterJudicial">Deter. Judicial</label>
            <select
              class="form-control"
              type="text"
              formControlName="deterJudicial"
              id="deterJudicial"
              [ngClass]="
                formCadastro.get('deterJudicial').invalid &&
                formCadastro.get('deterJudicial').touched
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option value="0">SIM</option>
              <option value="1">NÃO</option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('deterJudicial').invalid &&
                formCadastro.get('deterJudicial').touched
              "
            >
              Campo obrigatório
            </span>
          </div>

          <div class="col-md-3 form-group">
            <label class="font-weight-semibold" for="dtCessacao">Data Cessação</label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="dtCessacao"
                id="dtCessacao"
                max="9999-12-31"
                [ngClass]="
                  formCadastro.get('dtCessacao').invalid && formCadastro.get('dtCessacao').touched
                    ? 'is-invalid'
                    : null
                "
              />
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('dtCessacao').invalid && formCadastro.get('dtCessacao').touched
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold" for="motivoCessacao">Motivo Cessação</label>
            <select
              class="form-control"
              type="text"
              formControlName="motivoCessacao"
              id="motivoCessacao"
              [ngClass]="
                formCadastro.get('motivoCessacao').invalid &&
                formCadastro.get('motivoCessacao').touched
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option *ngFor="let motivo of listaMotivoCessacao" [value]="motivo.Codigo">
                {{ motivo.Codigo }} - {{ motivo.Descricao }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('motivoCessacao').invalid &&
                formCadastro.get('motivoCessacao').touched
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold" for="dtReativacao">Data Reativação</label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="dtReativacao"
                id="dtReativacao"
                max="9999-12-31"
                [ngClass]="
                  formCadastro.get('dtReativacao').invalid &&
                  formCadastro.get('dtReativacao').touched
                    ? 'is-invalid'
                    : null
                "
              />
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('dtReativacao').invalid && formCadastro.get('dtReativacao').touched
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold" for="dtFinanceiro">Data Financeiro</label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="dtFinanceiro"
                id="dtFinanceiro"
                max="9999-12-31"
                [ngClass]="
                  formCadastro.get('dtFinanceiro').invalid &&
                  formCadastro.get('dtFinanceiro').touched
                    ? 'is-invalid'
                    : null
                "
              />
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('dtFinanceiro').invalid && formCadastro.get('dtFinanceiro').touched
              "
            >
              Campo obrigatório
            </span>
          </div>
        </div>
      </fieldset>
      <!-- insenções ir e prev -->
      <fieldset class="col-md-12">
        <legend>
          <h6 class="font-weight-semibold">Insenções de IR e Prev.</h6>
        </legend>
        <div class="row">
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold" for="dtLaudo">Data do Laudo</label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="dtLaudo"
                id="dtLaudo"
                max="9999-12-31"
                [ngClass]="
                  formCadastro.get('dtLaudo').invalid && formCadastro.get('dtLaudo').touched
                    ? 'is-invalid'
                    : null
                "
              />
            </div>
            <span
              class="form-text text-danger"
              *ngIf="formCadastro.get('dtLaudo').invalid && formCadastro.get('dtLaudo').touched"
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold" for="dtInicial">Data Inicial</label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="dtInicial"
                id="dtInicial"
                max="9999-12-31"
                [ngClass]="
                  formCadastro.get('dtInicial').invalid && formCadastro.get('dtInicial').touched
                    ? 'is-invalid'
                    : null
                "
              />
            </div>
            <span
              class="form-text text-danger"
              *ngIf="formCadastro.get('dtInicial').invalid && formCadastro.get('dtInicial').touched"
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold" for="dtFinal">Data Final</label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="dtFinal"
                id="dtFinal"
                max="9999-12-31"
                [ngClass]="
                  formCadastro.get('dtFinal').invalid && formCadastro.get('dtFinal').touched
                    ? 'is-invalid'
                    : null
                "
              />
            </div>
            <span
              class="form-text text-danger"
              *ngIf="formCadastro.get('dtFinal').invalid && formCadastro.get('dtFinal').touched"
            >
              Campo obrigatório
            </span>
          </div>
        </div>
      </fieldset>
      <!-- aposentadoria -->
      <fieldset class="col-md-12">
        <legend>
          <h6 class="font-weight-semibold">Aposentadoria</h6>
        </legend>
        <div class="row">
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold" for="tipoAposentadoria"
              >Tipo de Aposentadoria</label
            >
            <select
              class="form-control"
              type="text"
              formControlName="tipoAposentadoria"
              id="tipoAposentadoria"
              [ngClass]="
                formCadastro.get('tipoAposentadoria').invalid &&
                formCadastro.get('tipoAposentadoria').touched
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option *ngFor="let tipo of listaTipoAposentadoria" [value]="tipo.Codigo">
                {{ tipo.Codigo }} - {{ tipo.Descricao }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('tipoAposentadoria').invalid &&
                formCadastro.get('tipoAposentadoria').touched
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold" for="dtAposentadoria">Data Aposentadoria</label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="dtAposentadoria"
                id="dtAposentadoria"
                max="9999-12-31"
                [ngClass]="
                  formCadastro.get('dtAposentadoria').invalid &&
                  formCadastro.get('dtAposentadoria').touched
                    ? 'is-invalid'
                    : null
                "
              />
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('dtAposentadoria').invalid &&
                formCadastro.get('dtAposentadoria').touched
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-2 form-group">
            <label class="font-weight-semibold" for="tipoProp">Tipo Prop.</label>
            <select
              class="form-control"
              type="text"
              formControlName="tipoProp"
              id="tipoProp"
              [ngClass]="
                formCadastro.get('tipoProp').invalid && formCadastro.get('tipoProp').touched
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option *ngFor="let tipo of listaTipoProp" [value]="tipo.Codigo">
                {{ tipo.Codigo }} - {{ tipo.Descricao }}
              </option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="formCadastro.get('tipoProp').invalid && formCadastro.get('tipoProp').touched"
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-3 form-group">
            <label class="font-weight-semibold" for="proporcionalidade">Proporcionalidade</label>
            <div class="input-group">
              <input
                class="form-control"
                type="number"
                formControlName="proporcionalidade"
                id="proporcionalidade"
                placeholder="Proporcionalidade"
                [ngClass]="
                  formCadastro.get('proporcionalidade').invalid &&
                  formCadastro.get('proporcionalidade').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <!-- qual length max? -->
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('proporcionalidade').invalid &&
                formCadastro.get('proporcionalidade').touched
              "
            >
              Campo obrigatório
            </span>
          </div>
        </div>
      </fieldset>
    </div>
  </form>
  <div class="card-footer mt-4 d-flex justify-content-end">
    <button type="submit" class="btn btn-primary" (click)="submitCadastro()">
      Submeter
      <i class="fa fa-paper-plane ml-2"></i>
    </button>
  </div>
</div>
