import { Injectable } from '@angular/core';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Saude } from '../caracteristicas-pessoais/caracteristicas-pessoais.component';

@Injectable({
  providedIn: 'root'
})
export class SaudeService {
  localStorage: LocalStorage;

  url_acesso:string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  filial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) { 
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.url_acesso = this.acesso.validarUrl();
    }
  }

  buscarTipoComorbidade(){
    return this.http.get(`${this.url_acesso}informacoestipocomorbidade`, {headers:this.cabecalho})
  }

  buscarComorbidade(matricula){
    return this.http.get(`${this.url_acesso}informacoes/saude/${matricula}`, {headers:this.cabecalho})
  }

  buscarColaboradorMatricula(matricula: string) {
    return this.http.get(`${this.url_acesso}cadastro-ativo-matricula/${matricula}/${this.filial}`, { headers: this.cabecalho });
  }

  inserirComorbidade(matricula, saude:Saude){
    return this.http.post(`${this.url_acesso}informacoes/saude/${matricula}/${this.filial}`, saude ,{headers:this.cabecalho})
  }

  atualizarComorbidae(matricula, cod, saude:Saude){
    return this.http.put(`${this.url_acesso}informacoes/saude/${cod}/${matricula}/${this.filial}`, saude ,{headers:this.cabecalho})
  }
 
  deletarComorbidade(matricula: string , codComor:number){
    return this.http.delete(`${this.url_acesso}informacoes/saude/${codComor}/${matricula}/${this.filial}`,{headers:this.cabecalho})
  }
}
