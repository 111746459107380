import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AlertaModal } from 'src/app/componentes/componentes-funcionais/alerta-modal/alerta-modal.component';
import Swal from 'sweetalert';

@Injectable({
  providedIn: 'root',
})
export class ErroService {
  constructor(private myRoute: Router) {}

  // Validação do retorno do erro
  retornoErro(erro) {
    console.log('error service', erro);
    if (erro.status == 500) {
      console.log('erro 500');
      Swal('Erro', `${erro.error.Mensagem}`, 'error');
      // this.myRoute.navigate(['erro']);
    } else if (erro.status == 401) {
      Swal({
        title: 'Sua sessão atual é inválida',
        text: 'Por favor, refaça seu login.',
        icon: 'error',
        closeOnClickOutside: false,
        closeOnEsc: false,
      }).then((result) => {
        localStorage.clear();
        this.myRoute.navigate(['login']);
      });
    } else {
      if (erro.error.Redirect == 0) {
        new AlertaModal('erro', 'Atenção!', `${erro.error.Mensagem}`, 'p');
        console.error('erro', 'Atenção!', `${erro.error.Mensagem}`, 'p');
      } else {
        Swal({
          title: 'Erro',
          text: 'Pedimos desculpas, estamos trabalhando para solucionar o problema.',
          icon: 'error',
          buttons: ['Recarregar', 'Ir para página inicial'],
        }).then((result) => {
          if (result) {
            this.myRoute.navigate(['home']);
          } else {
            window.location.reload();
          }
        });

        //   Swal({
        //     title: '',
        //     text: erro.error.Mensagem,
        //     icon: 'warning',
        //     closeOnClickOutside: false,
        //     closeOnEsc: false,
        //   }).then((result) => {
        //     localStorage.clear();
        //     this.myRoute.navigate(['login']);
        //   });
        // }
      }
    }
  }
}
