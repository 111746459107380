import { SituacaoFuncionalFalecimentoService } from './situacao-funcional-falecimento.service';
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErroService } from 'src/app/service/erros/erro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert';
import { Data } from 'src/app/service/interfaces/Data';
import { AnexoComponent } from '../anexo/anexo.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { element } from 'protractor';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';


@Component({
  selector: 'app-situacao-funcional-falecimento',
  templateUrl: './situacao-funcional-falecimento.component.html',
  styleUrls: ['./situacao-funcional-falecimento.component.css']
})
export class SituacaoFuncionalFalecimentoComponent implements OnInit {
  @ViewChild(AnexoComponent, { static: false }) anexoComponent: AnexoComponent;
  
  localStorage: LocalStorage;

  formSituacaoFuncionalFalecimento: FormGroup;

  numord: Object;
  matricula: number = null

  clientePublico: boolean;
  carregaNome: boolean = false;
  temErro: boolean = false;
  isFalecido: boolean = false;

  constructor(public fb: FormBuilder, private validaErro: ErroService, private spinner: NgxSpinnerService, private situacaoService: SituacaoFuncionalFalecimentoService) {
    this.localStorage = this.decryptLocalStorage();
    this.clientePublico = this.localStorage.ClientePublico;
  }

  ngOnInit() {
    this.formSituacaoFuncionalFalecimento = this.fb.group({
      matricula: ['', Validators.compose([Validators.required])],
      nome: [{ value: '', disabled: true }],
      dataDoe: ['', Validators.compose([Validators.required])],
      dataObito: ['', Validators.compose([Validators.required])],
      numord: ''
    })
    if (!this.clientePublico) { this.validarClientePublico() }
  }

  validarClientePublico() {
    if (!this.clientePublico) {
      this.formSituacaoFuncionalFalecimento.get('dataDoe').setValidators(null);
      this.formSituacaoFuncionalFalecimento.get('dataDoe').updateValueAndValidity();
    }
  }

  submitSituacaoFuncional(): void {
    if (this.formSituacaoFuncionalFalecimento.invalid) {
      this.temErro = true;
      new AlertaModal('alerta',"Atenção", "Preencha todos os campos obrigatórios!",'p')
    }
    else {
      if (this.isFalecido) {
        if (this.localStorage.TipoUsuario  == '5') {
          this.alterarSituacaoFalecimento();
        }
        else{
          this.temErro = true;
          new AlertaModal('alerta',"Atenção", "Alteração disponível apenas para permissões elevadas!",'p')
        }
      }
      else {
        this.salvarSituacaoFalecimento();
      }
    }
  }

  salvarSituacaoFalecimento(): void {
    this.spinner.show()
    const situacaoFalecimento = this.montarSituacaoFalecimento()
    if (situacaoFalecimento.Anexos.length != 0 && this.formSituacaoFuncionalFalecimento.valid) {
      this.situacaoService.salvarSituacaoFalecimento(situacaoFalecimento)
        .subscribe(
          data => {
            new AlertaModal('sucesso',"Sucesso",'Situação salva com sucesso!','p')
            this.limparCampos()
            this.spinner.hide()
          },
          error => {
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
            this.spinner.hide()
          })
    } else {
      new AlertaModal('alerta',"Atenção", "O anexo para falecimento é obrigatório.",'p')
    }
  }

  alterarSituacaoFalecimento(): void {
    this.spinner.show()
    const situacaoFalecimento = this.montarSituacaoFalecimento()
    if (situacaoFalecimento.Anexos.length != 0 && this.formSituacaoFuncionalFalecimento.valid) {
      this.situacaoService.alterarSituacaoFalecimento(situacaoFalecimento)
        .subscribe(
          data => {
            new AlertaModal('sucesso',"Sucesso",'Situação alterada com sucesso!','p')
            this.limparCampos()
            this.spinner.hide()
          },
          error => {
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
            this.spinner.hide()
          })
    } else {
      new AlertaModal('alerta',"Atenção", "O anexo para falecimento é obrigatório.",'p')
    }
  }

  obterNumord(): void {
    this.spinner.show()
    const matricula: string = this.formSituacaoFuncionalFalecimento.value.matricula
    this.situacaoService.obterNumord(matricula)
      .subscribe(
        data => {
          this.formSituacaoFuncionalFalecimento.controls['numord'].setValue(data)
          this.spinner.hide()
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide()
        })
  }

  montarSituacaoFalecimento(): SituacaoFalecimento {  
    const situacaoFalecimento = this.formSituacaoFuncionalFalecimento.getRawValue()
    return {
      Matricula: situacaoFalecimento.matricula,
      DataFalecimento: situacaoFalecimento.dataObito != "" ? (new Data(this.formatarDataEnvio(situacaoFalecimento.dataObito))) : null,
      DataDiarioOficial: situacaoFalecimento.dataDoe != "" ? new Data(this.formatarDataEnvio(situacaoFalecimento.dataDoe)) : null,
      Situacao: "FALECIDO",
      EmpresaFilial: this.localStorage.FilialAtual,
      Processamento: "EM",
      Anexos: this.anexoComponent.retornarValores()
    }
  }

  buscaColaborador(matricula): void {
   
    if (matricula != "") {
      this.limparCamposSemMatricula();
      
      this.carregaNome = true;
      this.situacaoService.buscarColaboradorFalecimento(matricula)
        .subscribe(
          data => {
            if (data['Nome'] == "") {new AlertaModal('alerta',"Atenção", "Não foi encontrado nenhum colaborador com essa matrícula.",'p')}
            this.formSituacaoFuncionalFalecimento.controls['numord'].setValue(data['NumeroOrdem']);
            this.formSituacaoFuncionalFalecimento.controls['nome'].setValue(data['Nome']);
            if (data['DataFalecimento']['IsDate']) {
              this.formSituacaoFuncionalFalecimento.controls['dataObito'].setValue(this.formatarData(data['DataFalecimento']['ToDate']));
            }
            if (data['DataDiarioOficial']['IsDate']) {
              this.formSituacaoFuncionalFalecimento.controls['dataDoe'].setValue(this.formatarData(data['DataDiarioOficial']['ToDate']));
            }
            this.isFalecido = data['IsFalecido'];
            this.carregaNome = false;
          },
          error => {
            this.validaErro.retornoErro(error)
            this.carregaNome = false
          })
    } else {
      new AlertaModal('alerta',"Atenção", 'Preencha a matrícula!','p')
    }
  }

  montarCompetencia(): string {
    let data = new Date()
    let mes = data.getMonth() + 1
    let ano = data.getFullYear()
    return ano.toString() + mes.toString().padStart(2, "0")
  }

  verificarData(): void {
   let { dataObito } = this.formSituacaoFuncionalFalecimento.value
    dataObito = this.formatarDataEnvio(dataObito)
    if (dataObito.length == 8 && !this.formSituacaoFuncionalFalecimento.get('dataObito').invalid) {
      var dia, mes, ano
      dia = dataObito.substring(0, 2);
      mes = dataObito.substring(2, 4);
      ano = dataObito.substring(4, 8);

      let dataAtual: Date = new Date();
      var datadoObito = new Date(ano, Number(mes) - 1, dia);
      if (datadoObito >= dataAtual) {
        this.formSituacaoFuncionalFalecimento.controls['dataObito'].setValue("");
        new AlertaModal('alerta',"Atenção", 'Data do óbito não pode ser maior que a data atual!','p')
      }
    }
  }

  limparCampos(): void {
    this.formSituacaoFuncionalFalecimento.controls['matricula'].setValue("")
    this.formSituacaoFuncionalFalecimento.controls['dataObito'].setValue("")
    this.formSituacaoFuncionalFalecimento.controls['dataDoe'].setValue("")
    this.formSituacaoFuncionalFalecimento.controls['nome'].setValue("")
    this.formSituacaoFuncionalFalecimento.markAsPristine()
    this.matricula = null
  }

  limparCamposSemMatricula(): void {
    this.formSituacaoFuncionalFalecimento.controls['dataObito'].setValue("")
    this.formSituacaoFuncionalFalecimento.controls['dataDoe'].setValue("")
    this.formSituacaoFuncionalFalecimento.controls['nome'].setValue("")
    if (this.anexoComponent != undefined) { this.anexoComponent.limparCampos() }
    this.formSituacaoFuncionalFalecimento.markAsPristine()
  }

  formatarDataEnvio(data: string): string {
    let dataSplit = data.split("-");
    const dataFormatada = `${dataSplit[2]}${dataSplit[1]}${dataSplit[0]}`;
    return dataFormatada;
  }

  formatarData(data: string): string {
    let dataSplit = data.split("/");
    const dataFormatada = `${dataSplit[2]}-${dataSplit[1]}-${dataSplit[0]}`;
    return dataFormatada;
  }
  
  matriculaListener(evento){ 
    this.matricula = evento.Matricula;
    this.buscaColaborador(this.matricula);
    this.formSituacaoFuncionalFalecimento.get('matricula').setValue(this.matricula);
    this.formSituacaoFuncionalFalecimento.get('matricula').disable();
  }
  retornarTelaInicial(){
    this.matricula = null;
    this.limparCampos()
  }
  
  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }
}

export interface SituacaoFalecimento {
  Matricula: string
  DataFalecimento: Data
  DataDiarioOficial: Data
  Situacao: string
  EmpresaFilial: number
  Processamento: string
  Anexos: any[]
}





