<div class="row d-flex card-deck mx-1">
  <div class="card mx-1 px-1 col-xs-6 col-sm-6 col-md-3 col-lg-3" *ngFor="let item of cardItems">
    <div class="card-header d-flex justify-content-center">
      <i
        class="fa {{
          item.icon
        }} text-indigo-300 border-indigo-300 border-3 rounded-round p-3 mb-3 mt-1 ml-1"
      ></i>
    </div>
    <div class="card-body">
      <div class="row d-flex justify-content-center h-50">
        <label class="text-center font-weight-semibold">{{ item.label }}</label>
      </div>
      <div class="row d-flex justify-content-center">
        <button
          *ngIf="!item.disabledProperty"
          (click)="item.clickFunction(item.label === 'Ficha Financeira' ? $event : '')"
          class="btn bg-indigo-300 text-center"
        >
          <div *ngIf="!this[item.loadingProperty]">
            <ng-container [ngSwitch]="item.label">
              <i class="fa fa-download" aria-hidden="true"></i>
              <ng-container *ngSwitchCase="'Contracheque'">
                {{ 'Buscar' }}
              </ng-container>
              <ng-container *ngSwitchCase="'Processos'">
                {{ 'Buscar' }}
              </ng-container>
              <!-- <ng-container *ngSwitchCase="'Ficha Financeira'">
                {{ 'Imprimir' }}
              </ng-container> -->
              <ng-container *ngSwitchDefault>
                {{ 'Download' }}
              </ng-container>
            </ng-container>
          </div>

          <div *ngIf="this[item.loadingProperty]">
            <i class="fa fa-spinner fa-spin" aria-hidden="false"></i>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="ficha" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="staticBackdropLabel">Download da Ficha Financeira</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="form">
        <div class="modal-body row">
          <div class="form-group col-12">
            <label for="modoImpressao" class="font-weigth-semibold">Modo de Impressão</label>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                formControlName="modoImpressao"
                id="modoImpressao1"
                value="1"
                (change)="mudandoModoImpressao()"
                checked
              />
              <label class="form-check-label" for="modoImpressao1"> Ano Completo </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                formControlName="modoImpressao"
                id="modoImpressao2"
                (change)="mudandoModoImpressao()"
                value="2"
              />
              <label class="form-check-label" for="modoImpressao2"> 12 Meses </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                formControlName="modoImpressao"
                id="modoImpressao3"
                (change)="mudandoModoImpressao()"
                value="3"
              />
              <label class="form-check-label" for="modoImpressao3"> Períodos Anuais </label>
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                (form.get('modoImpressao').invalid || form.get('modoImpressao').value == null) &&
                form.get('modoImpressao').touched
              "
            >
              Campo Obrigatório
            </span>
          </div>
          <ng-container *ngIf="form.get('modoImpressao').value === '2'; else Anos">
            <div class="form-group col-6">
              <label for="dtInicio" class="font-weigth-semibold">Competência Inicial</label>
              <input
                class="form-control"
                id="dtInicio"
                placeholder="00/0000"
                mask="00/0000"
                formControlName="dtInicio"
                (input)="formatarDataCompInput($event)"
                (keyup)="definirDataFinal12Meses($event)"
                [ngClass]="
                  (form.get('dtInicio').invalid || form.get('dtInicio').value == null) &&
                  form.get('dtInicio').touched
                    ? 'is-invalid'
                    : null
                "
              />

              <span
                class="form-text text-danger"
                *ngIf="
                  (form.get('dtInicio').invalid || form.get('dtInicio').value == null) &&
                  form.get('dtInicio').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>

            <div class="form-group col-6">
              <label for="dtFim" class="font-weigth-semibold"> Competência Final </label>
              <input
                type="text"
                formControlName="dtFim"
                placeholder="00/0000"
                mask="00/0000"
                (input)="formatarDataCompInput($event)"
                (blur)="definirObrigatoriedadeDataFim()"
                class="form-control"
                id="dtFim"
                [ngClass]="
                  (form.get('dtFim').invalid || form.get('dtFim').value == null) &&
                  form.get('dtFim').touched
                    ? 'is-invalid'
                    : null
                "
              />

              <span
                class="form-text text-danger"
                *ngIf="
                  (form.get('dtFim').invalid || form.get('dtFim').value == null) &&
                  form.get('dtFim').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
          </ng-container>
          <ng-template #Anos>
            <div class="form-group col-6">
              <label for="dtInicio" class="font-weigth-semibold"> Competência Inicial </label>
              <input
              id="dtInicio"
              formControlName="dtInicio"
              placeholder="0000"
              type="text"
              maxlength="4"
             
              (input)="formatarAnoInput($event, 'dtInicio')"
              (keyup)="definirAnoFinal($event, 'dtInicio')"
                step="1"
                class="form-control"
                [ngClass]="
                  (form.get('dtInicio').invalid || form.get('dtInicio').value == null) &&
                  form.get('dtInicio').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  (form.get('dtInicio').invalid || form.get('dtInicio').value == null) &&
                  form.get('dtInicio').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
            <div class="form-group col-6">
              <label for="dtFim" class="font-weigth-semibold"> Competência Final </label>
              <input
                id="dtFim"
                maxlength="4"
                formControlName="dtFim"
                placeholder="0000"
                type="text"
                (blur)="definirObrigatoriedadeDataFim()"
                (input)="formatarAnoInput($event, 'dtFim')"
                class="form-control"
                step="1"
                [ngClass]="
                  (form.get('dtFim').invalid || form.get('dtFim').value == null) &&
                  form.get('dtFim').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <span
                class="form-text text-danger"
                *ngIf="
                  (form.get('dtFim').invalid || form.get('dtFim').value == null) &&
                  form.get('dtFim').touched
                "
              >
                Campo Obrigatório
              </span>
            </div>
          </ng-template>
          <div class="form-group col-12">
            <label for="modoImpressao" class="font-weigth-semibold">Modelo de Impressão</label>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                formControlName="modeloImpressao"
                id="modeloImpressao1"
                value="1"
                checked
              />
              <label class="form-check-label" for="modeloImpressao1">
                Só vantagem/desconto com totais
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                checked
                formControlName="modeloImpressao"
                id="modeloImpressao2"
                value="2"
              />
              <label class="form-check-label" for="modeloImpressao2"
                >Só vantagem/desconto sem totais
              </label>
            </div>

            <span
              class="form-text text-danger"
              *ngIf="
                (form.get('modeloImpressao').invalid ||
                  form.get('modeloImpressao').value == null) &&
                form.get('modeloImpressao').touched
              "
            >
              Campo Obrigatório
            </span>
          </div>
        </div>
      </form>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" (click)="imprimirFichaFinanceira()" class="btn btn-primary">
          <div *ngIf="loadingFichaFinanceiraBt; else download">
            <i class="fa fa-spinner fa-spin" aria-hidden="false"></i>
          </div>
          <ng-template #download> Download </ng-template>
        </button>
      </div>
    </div>
  </div>
</div>
