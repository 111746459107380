import {Component, OnInit} from '@angular/core';
import {Validators, FormGroup, FormBuilder} from '@angular/forms';
import {dataValidator} from 'src/app/service/Validators/dataValidator';
import {Parametro} from 'src/app/service/interfaces/Parametro';
import Swal from 'sweetalert';
import {Router} from '@angular/router';
import {ErroService} from 'src/app/service/erros/erro.service';
//import { Route } from '@angular/compiler/src/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {Data} from 'src/app/service/interfaces/Data';
import {CadastroContratoExperienciaService} from './cadastro-contrato-experiencia.service';
import {LocalStorage} from 'src/app/service/classes/localstorage/LocalStorage';
import {Crypto} from 'src/app/service/classes/crypto/crypto';
import {AlertaModal} from 'src/app/componentes/componentes-funcionais/alerta-modal/alerta-modal.component';
import {ContratoExperiencia} from 'src/app/service/interfaces/ContratoExperiencia';
import {Experiencia} from 'src/app/service/interfaces/Experiencia';
import {validarDataMaiorOuIgual} from '../../processos/data-validator';

@Component({
  selector: 'app-cadastro-contrato-experiencia',
  templateUrl: './cadastro-contrato-experiencia.component.html',
  styleUrls: ['./cadastro-contrato-experiencia.component.css'],
})
export class CadastroContratoExperienciaComponent implements OnInit {
  localStorage: LocalStorage;
  numeroOrdem: string;
  temErro: boolean = false;
  editar: boolean = false;
  visualizacao: boolean = false;
  loadingCargo: boolean = false;
  formContrato: FormGroup;
  listaRegimeTrabalho: Parametro[];
  listaRegimePrevidencia: Parametro[];
  listaModalidade: Parametro[];
  listaCargoCbo: Parametro[];
  listaCargo: Parametro[];
  listaSetor: Parametro[];
  listaNivel: any[];

  listaDias: string[] = ['30', '45', '90'];

  constructor(
    public fb: FormBuilder,
    private contratoService: CadastroContratoExperienciaService,
    private route: Router,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.localStorage = this.decryptLocalStorage();
    this.formContrato = this.fb.group({
      contratoSetor: ['', Validators.required],
      contratoCargo: ['', Validators.required],
      contratoNivel: ['', Validators.required],
      contratoDias: ['', Validators.required],
      contratoDataInicial: ['', [Validators.required, validarDataMaiorOuIgual()]],
      contratoDataFinal: ['', [Validators.required]],
      contratoDataInicialAnterior: [''],
      contratoDataFinalAnterior: [''],
    });
    this.buscarParametro();
    this.formContrato.get('contratoDataFinal').disable();
  }

  buscarParametro(): void {
    this.spinner.show();
    const empresaFilial = this.localStorage.FilialAtual.toString();
    this.contratoService.buscarParametros(empresaFilial).subscribe(
      (data: any) => {
        console.log(data);
        this.listaRegimeTrabalho = data.RegimesTrabalho;
        this.listaRegimePrevidencia = data.RegimesPrevidencia;
        this.listaModalidade = data.Modalidades;
        this.listaCargoCbo = data.CargosCBO;
        this.listaCargo = data.Cargos;
        this.listaSetor = data.Setor;

        //caso tenha info de contratos existentes/passados
        if (history.state.data) {
          this.numeroOrdem = history.state.data.numeroOrdem;
          this.editar = history.state.data.editar;
          if (history.state.data.visualizacao) {
            this.visualizacao = true;
            this.formContrato.disable();
          }
          if (history.state.data.NovoCadastro) {
            this.carregarCamposSemContrato(history.state.data.contrato);
            if (history.state.data.contrato.ContratosExperiencia.length > 0) {
              this.gerarDataInicial();
            }
          } else {
            this.carregarContrato(history.state.data.contrato);
          }
        } else {
          this.route.navigate(['/complemento-cadastral/contrato-experiencia']);
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  carregarContrato(contrato: ContratoExperiencia): void {
    console.log('carregarContrato');
    this.formContrato.setValue({
      contratoSetor: contrato.Setor,
      contratoCargo: contrato.Cargo,
      contratoNivel: contrato.Nivel.trim(),
      contratoDias: contrato.Dias,
      contratoDataInicial: this.editar ? this.corrigeDatas(contrato.DataInicial.ToDate) : '',
      contratoDataFinal: this.editar ? this.corrigeDatas(contrato.DataFinal.ToDate) : '',
      contratoDataInicialAnterior: this.editar
        ? this.corrigeDatas(contrato.DataInicial.ToDate)
        : '',
      contratoDataFinalAnterior: this.editar ? this.corrigeDatas(contrato.DataFinal.ToDate) : '',
    });
    this.onNivelChange();
    console.log(this.formContrato.value);
  }

  verificaData(dataInicial: string, dataFinal: string): void {
    let formDataInicial = this.formContrato.value[dataInicial];
    let formDataFinal = this.formContrato.value[dataFinal];

    if (formDataInicial.length === 8 && formDataFinal.length === 8) {
      formDataInicial =
        formDataInicial.substr(4, 4) + formDataInicial.substr(2, 2) + formDataInicial.substr(0, 2);
      formDataFinal =
        formDataFinal.substr(4, 4) + formDataFinal.substr(2, 2) + formDataFinal.substr(0, 2);
      if (Number(formDataInicial) < Number(formDataFinal)) return;

      new AlertaModal('alerta', 'Atenção!', 'Data final deve ser posterior a data inicial.', 'p');
      this.formContrato.get(dataInicial).setValue('');
      this.formContrato.get(dataFinal).setValue('');
    }
  }

  async carregarCamposSemContrato(experiencia: Experiencia) {
    console.log('entrei sem contrato');
    this.formContrato.get('contratoSetor').setValue(experiencia.Setor);
    this.formContrato.get('contratoCargo').setValue(experiencia.Cargo);

    try {
      // Aguarda a conclusão da função OnNivelChange
      await this.onNivelChange();
      this.formContrato.get('contratoNivel').setValue(experiencia.Nivel);
      console.log(experiencia);
    } catch (error) {
      // Lida com erros, se necessário
      console.error(error);
    }
  }

  gerarDataInicial() {
    const dataOriginal = new Date(
      history.state.data.contrato.ContratosExperiencia[0].DataFinal.SetData.substring(0, 4),
      parseInt(
        history.state.data.contrato.ContratosExperiencia[0].DataFinal.SetData.substring(4, 6)
      ) - 1,
      history.state.data.contrato.ContratosExperiencia[0].DataFinal.SetData.substring(6, 8)
    );

    // Adicionando um dia à data
    const umDiaEmMilissegundos = 24 * 60 * 60 * 1000; // 24 horas * 60 minutos * 60 segundos * 1000 milissegundos
    const novaData = new Date(dataOriginal.getTime() + umDiaEmMilissegundos);
    console.log('data mais um', novaData);
    const ano = novaData.getFullYear();
    const mes = (novaData.getMonth() + 1).toString().padStart(2, '0'); // Adiciona zero à esquerda se necessário
    const dia = novaData.getDate().toString().padStart(2, '0'); // Adiciona zero à esquerda se necessário
    const dataFormatoFinal = `${ano}-${mes}-${dia}`;
    console.log('data formato final', dataFormatoFinal);
    const contratoPassado = history.state.data.contrato.ContratosExperiencia[0];

    //adiciona valores no formulário
    this.formContrato.patchValue({
      contratoSetor: contratoPassado.Setor,
      contratoCargo: contratoPassado.Cargo,
      contratoNivel: contratoPassado.Nivel,
      contratoDias: contratoPassado.Dias,
      contratoDataInicial: dataFormatoFinal,
    });
    this.onNivelChange();
    this.gerarDataFinal();
    this.formContrato.get('contratoDataInicial').disable();
    this.formContrato.get('contratoDias').disable();
  }

  gerarDataFinal(): void {
    const dataInicioFerias = this.formContrato.get('contratoDataInicial').value;
    const dataFormatada = new Date(dataInicioFerias);

    dataFormatada.setDate(
      dataFormatada.getDate() + Number(this.formContrato.get('contratoDias').value)
    );

    let mes = (dataFormatada.getMonth() + 1).toString().padStart(2, '0');
    let dia = dataFormatada.getDate().toString().padStart(2, '0');
    var diaSubtraido = Number(dia);

    diaSubtraido == 0 ? (dia = '01') : (dia = diaSubtraido.toString().padStart(2, '0'));
    let ano = dataFormatada.getFullYear();

    // verificar como diminuir um dia da data passada.
    if (this.formContrato.get('contratoDias').invalid) {
      this.formContrato.controls['contratoDataFinal'].setValue('');
    } else
      this.formContrato
        .get('contratoDataFinal')
        .setValue(this.corrigeDatas(`${dia}/${mes}/${ano}`));
    console.log(this.formContrato);
  }

  submitContrato(): void {
    if (this.formContrato.invalid) {
      new AlertaModal('alerta', 'Atenção!', 'Preencha todos os campos corretamente.', 'p');
      this.temErro = true;
    } else {
      const contrato = this.montarContrato();
      contrato.DataFinal.SetData = contrato.DataFinal.ToDate;
      contrato.DataInicial.SetData = contrato.DataInicial.ToDate;
      if (this.editar) {
        contrato.DataInicialAnterior.SetData = contrato.DataInicialAnterior.ToDate;
        contrato.DataFinalAnterior.SetData = contrato.DataFinalAnterior.ToDate;
        this.editarContrato(contrato);
      } else {
        this.salvarContrato(contrato);
      }
      console.log(contrato);
    }
  }

  editarContrato(contrato: ContratoExperiencia): void {
    this.spinner.show();
    this.contratoService.editarContrato(contrato, this.numeroOrdem).subscribe(
      async (data) => {
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'O contrato foi editado.', 'p');
        this.route.navigate(['/complemento-cadastral/contrato-experiencia']);
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  salvarContrato(contrato: ContratoExperiencia): void {
    this.spinner.show();
    this.contratoService.salvarContrato(contrato, this.numeroOrdem).subscribe(
      async (data) => {
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'O contrato foi inserido!', 'p');
        this.route.navigate(['/complemento-cadastral/contrato-experiencia']);
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  montarContrato(): ContratoExperiencia {
    return {
      Cargo: this.formContrato.get('contratoCargo').value,
      DataFinal: new Data(this.converteDatas(this.formContrato.get('contratoDataFinal').value)),
      DataFinalAnterior: this.editar
        ? new Data(this.converteDatas(this.formContrato.get('contratoDataFinalAnterior').value))
        : null,
      DataInicial: new Data(this.converteDatas(this.formContrato.get('contratoDataInicial').value)),
      DataInicialAnterior: this.editar
        ? new Data(this.converteDatas(this.formContrato.get('contratoDataInicialAnterior').value))
        : null,
      Dias: this.formContrato.get('contratoDias').value,
      Nivel: this.formContrato.get('contratoNivel').value,
      Setor: this.formContrato.get('contratoSetor').value,
    };
  }

  onNivelChange(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const {contratoCargo} = this.formContrato.value;
      console.log(this.formContrato.value);
      console.log('contratoCargo', contratoCargo);
      this.loadingCargo = true;

      this.contratoService.buscarNivel(contratoCargo).subscribe(
        (data: Parametro[]) => {
          console.log('data', data);
          this.listaNivel = data;
          this.loadingCargo = false;
          resolve(); // Resolva a promessa quando a chamada assíncrona for concluída com sucesso
        },
        (error) => {
          new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
          this.loadingCargo = false;
          reject(error); // Rejeite a promessa em caso de erro
        }
      );
    });
  }

  converteDatas(datas) {
    var [ano, mes, dia] = datas.split('-');
    return `${dia}/${mes}/${ano}`;
  }

  corrigeDatas(datas) {
    var [dia, mes, ano] = datas.split('/');
    return `${ano}-${mes}-${dia}`;
  }

  voltarBusca() {
    this.route.navigate(['/complemento-cadastral/contrato-experiencia']);
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}
