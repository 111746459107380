import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { ActivatedRoute, Router } from '@angular/router';
import { AlterarSenhaService } from './alterar-senha.service';
import Swal from 'sweetalert';
import { NgxSpinnerService } from "ngx-spinner";
import { ErroService } from 'src/app/service/erros/erro.service';
import { SenhaAlteracao } from './classes/SenhaAlteracao';
import { SplitInterpolation } from '@angular/compiler';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';



@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.css']
})
export class AlterarSenhaComponent implements OnInit {
  carregando: Boolean;
  link: string;
  numOrdCriptografado: string;
  linkValido: boolean = false;
  erro : boolean = false;
  senhas: SenhaAlteracao = { Senha1 : "", Senha2 : ""};
  formAlterarSenha: UntypedFormGroup;
  localStorage: LocalStorage;
  constructor(private formBuilder: UntypedFormBuilder, private route: ActivatedRoute, private service: AlterarSenhaService, private validaError: ErroService, private spinner: NgxSpinnerService, private myRoute: Router) { }

  ngOnInit() {
    this.validaLink();
    this.localStorage = this.decryptLocalStorage();
    this.carregando = false;
    this.formAlterarSenha =  this.formBuilder.group({
      senha1Form: ['', [Validators.required, Validators.minLength(8)]],
      senha2Form: ['', [Validators.required, Validators.minLength(8)]]
    });
  }
  retornarTelaLogin(){
    this.myRoute.navigate(['login']);
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }

  validaLink(){
    this.spinner.show();
    this.link = this.route.snapshot.paramMap.get("dados");
    this.service.validarLink(this.link).subscribe(
      a => {
        this.numOrdCriptografado = a as string;
        this.linkValido = true;
        this.spinner.hide();
      },
      error => {
        this.validaError.retornoErro(error);
        this.linkValido = false;
        this.spinner.hide();
        this.retornarTelaLogin();
      }
    )
  }

  alteraSenha(){
    this.spinner.show();
    if(this.linkValido && this.formAlterarSenha.valid){
      this.senhas.Senha1 = this.formAlterarSenha.get('senha1Form').value;
      this.senhas.Senha2 = this.formAlterarSenha.get('senha2Form').value;
      if(this.senhas.Senha1 == this.senhas.Senha2){
        this.service.alterarSenha(this.senhas, this.numOrdCriptografado).subscribe(
          a => {
            this.spinner.hide();
            new AlertaModal("sucesso", "Sucesso!", "Senha alterada com sucesso!", 'p');
            this.retornarTelaLogin();
          },
          error => {
            this.spinner.hide();
            this.validaError.retornoErro(error);
          }
        );
      } else {
        new AlertaModal("erro", "Erro!", "As senhas precisam ser identicas!", 'p');
      }
    }
    else{
      this.spinner.hide();
      if(!this.formAlterarSenha.valid){
        this.erro = true;
        new AlertaModal("alerta", "Aviso!", "Preencha todos os campos!", 'p');
      }else{
        new AlertaModal("alerta", "Aviso!", "O link que está sendo utilizado para a alteração não é válido!", 'p');
        this.retornarTelaLogin();
      }
    }



  }

}
