import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';

@Injectable({
  providedIn: 'root',
})
export class ComplementoCadastralService {
  localStorage: LocalStorage;
  urlAcesso: string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  filial: string;
  private changeSubject = new Subject<any>();
  changeEmitted$ = this.changeSubject.asObservable();
  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.urlAcesso = this.acesso.validarUrl();
    }
  }
  emitChange(change: any) {
    this.changeSubject.next(change);
  }
  buscarColaboradorMatriculaEstagio(matricula: string) {
    return this.http.get(
      `${this.urlAcesso}cadastro-ativo-matricula-estagio/${matricula}/${this.filial}`,
      {
        headers: this.cabecalho,
      }
    );
  }

  buscarColaboradorMatricula(matricula: string) {
    return this.http.get(`${this.urlAcesso}cadastro-ativo-matricula/${matricula}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarAprendiz(numeroOrdem: string) {
    return this.http.get(`${this.urlAcesso}colaborador/contratos-aprendiz/${numeroOrdem}`, {
      headers: this.cabecalho,
    });
  }

  buscarContratoExperiencia(numeroOrdem: string) {
    return this.http.get(`${this.urlAcesso}colaborador/contratos-experiencia/${numeroOrdem}`, {
      headers: this.cabecalho,
    });
  }

  buscarCessao(numeroOrdem: string) {
    return this.http.get(`${this.urlAcesso}colaborador/contratos-cessao/${numeroOrdem}`, {
      headers: this.cabecalho,
    });
  }

  buscarEstagio(numeroOrdem: string) {
    return this.http.get(`${this.urlAcesso}colaborador/contratos-estagio/${numeroOrdem}`, {
      headers: this.cabecalho,
    });
  }
}
