<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="modal-header">
  <h3 class="modal-title">{{ cadastro ? 'Incluir' : 'Atualizar' }} Observação</h3>
  <button type="button" class="close" id="botaoFechar" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="formObservacoes" class="modal-body row">
  <div class="form-group col-12">
    <label for="Data" class="font-weigth-semibold">Data</label>
    <input
      type="date"
      class="form-control"
      id="Data"
      formControlName="Data"
      [ngClass]="
        formObservacoes.get('Data').invalid && formObservacoes.get('Data').touched
          ? 'is-invalid'
          : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="formObservacoes.get('Data').invalid && formObservacoes.get('Data').touched"
    >
      Campo Obrigatório
    </span>
  </div>

  <div class="form-group col-12">
    <label for="Observacao" class="font-weigth-semibold">Observação</label>
    <textarea
      type="text"
      class="form-control"
      rows="10"
      id="Observacao"
      formControlName="Observacao"
      placeholder="Insira aqui sua observação..."
      [ngClass]="
        formObservacoes.get('Observacao').invalid && formObservacoes.get('Observacao').touched
          ? 'is-invalid'
          : null
      "
    ></textarea>
    <span
      class="form-text text-danger"
      *ngIf="formObservacoes.get('Observacao').invalid && formObservacoes.get('Observacao').touched"
    >
      Campo Obrigatório
    </span>
  </div>
</form>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="cadastro ? lancarObservacao() : lancarObservacao(observacaoAtual)"
  >
    {{ cadastro ? 'Cadastrar' : 'Atualizar' }}
  </button>
</div>
