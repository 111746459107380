import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Prontuario, ProntuarioMedicoComponent } from '../prontuario-medico.component';
import { ProntuarioMedicoService } from '../prontuario-medico.service';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import Swal from 'sweetalert';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';

export interface Item {
  Codigo: string;
  Nome: string;
  Descricao: string;
}

@Component({
  selector: 'app-prontuario-medico-cadastro',
  templateUrl: './prontuario-medico-cadastro.component.html',
  styleUrls: ['./prontuario-medico-cadastro.component.css'],
})
export class ProntuarioMedicoCadastroComponent implements OnInit {
  @Input() cadastro: boolean = true;
  @Input() colaborador: Cadastro = null;
  @Input() prontuarioAtual: Prontuario = null;
  formProntuario: FormGroup;
  Seq: number = null;
  prontuarioindex: number = null;
  buscarCodAfa: Item[] = [];
  buscarCodMot: Item[] = [];

  constructor(
    public fb: FormBuilder,
    private prontuarioComponent: ProntuarioMedicoComponent,
    private prontuarioService: ProntuarioMedicoService,
    private spinner: NgxSpinnerService,
  ) {
    this.formProntuario = this.prontuarioComponent.fb.group({
      sigla: [''],
      Empfil: [null],
      Mat: [null],
      Crm: ['', Validators.required],
      Dtinsp: ['', Validators.required],
      CodAfa: ['', Validators.required],
      CodMot: ['', Validators.required],
      Dt1LM: ['', Validators.required],
      Dt2LM: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.carregarCodAfa();
    this.carregarCodMot();
    console.log('prontuário atual', this.prontuarioAtual);
  }

  carregarCodAfa() {
    this.prontuarioService.buscarCodAfa().subscribe(
      (data: Item[]) => {
        this.buscarCodAfa = data;
      },
      (error) => {
        console.error('Erro ao carregar CodAfa:', error);
      }
    );
  }
  carregarCodMot() {
    this.prontuarioService.buscarCodMot().subscribe(
      (data: Item[]) => {
        this.buscarCodMot = data;
      },
      (error) => {
        console.error('Erro ao carregar CodMot:', error);
      }
    );
  }

  montarProntuario() {
    let prontuario = {
      Sigla: 'FAPP',
      Empfil: this.colaborador.Filial.Codigo,
      Mat: Number(this.colaborador.Matricula),
      DtInsp: this.formProntuario.get('Dtinsp').value
        ? {
            SetData: this.formProntuario.get('Dtinsp').value.replace(/-/g, ''),
            ToDate: this.prontuarioService.formatarDataBarra(
              this.formProntuario.get('Dtinsp').value.replace(/-/g, '')
            ),
            IsDate: true,
            Empty: false,
          }
        : '00000000',
      Crm: this.formProntuario.get('Crm').value,
      CodAfa: Number(this.formProntuario.get('CodAfa').value),
      CodMot: Number(this.formProntuario.get('CodMot').value),
      Dt1LM: this.formProntuario.get('Dt1LM').value
        ? {
            SetData: this.formProntuario.get('Dt1LM').value.replace(/-/g, ''),
            ToDate: this.prontuarioService.formatarDataBarra(
              this.formProntuario.get('Dt1LM').value.replace(/-/g, '')
            ),
            IsDate: true,
            Empty: false,
          }
        : '00000000',

      Dt2LM: this.formProntuario.get('Dt2LM').value
        ? {
            SetData: this.formProntuario.get('Dt2LM').value.replace(/-/g, ''),
            ToDate: this.prontuarioService.formatarDataBarra(
              this.formProntuario.get('Dt2LM').value.replace(/-/g, '')
            ),
            IsDate: true,
            Empty: false,
          }
        : '00000000',
      Seq: this.Seq ?? 0,
    };

    return { Prontuario: prontuario };
  }

  lancarProntuario() {
    this.spinner.show();
    this.formProntuario.markAllAsTouched();
    let botaoFechar = document.getElementById('botaoFechar');
    console.log('payload form puro', this.formProntuario);
    
    if (this.formProntuario.valid) {
      let prontuarioLancar = this.montarProntuario().Prontuario;
      console.log('payload', prontuarioLancar);
      if (this.prontuarioindex !== null && this.prontuarioindex !== undefined) {
        this.prontuarioService.atualizarProntuario(prontuarioLancar).subscribe(
          (data) => {
            Swal('Sucesso', 'Prontuário atualizado com sucesso.', 'success');
            this.prontuarioComponent.buscarProntuario();
            if (botaoFechar) botaoFechar.click();
            console.log('atualizado', data);
            this.spinner.hide();
          },
          (err: HttpErrorResponse) => {
            if (err.status === 500) {
              Swal('Erro', 'Não foi possível atualizar essa falta.', 'error');
            } else {
              const errorMessage =
                err.error?.Mensagem || 'Ocorreu um erro ao atualizar essa falta.';
              Swal('Erro', errorMessage, 'error');
            }
            this.spinner.hide();
          }
        );
       
      } else {
        this.prontuarioService.inserirProntuario(prontuarioLancar).subscribe(
          (data) => {
            Swal('Sucesso', 'Prontuário cadastrado com sucesso.', 'success');
            this.prontuarioService.buscarProntuario(+this.colaborador.Matricula);
            this.prontuarioComponent.buscarProntuario();
            if (botaoFechar) botaoFechar.click();
            this.spinner.hide();
          },
          (err: HttpErrorResponse) => {
            if (err.status === 500) {
              Swal('Erro', 'Não foi possível inserir essa falta.', 'error');
            } else {
              const errorMessage =
                err.error?.Mensagem || 'Ocorreu um erro ao atualizar essa falta.';
              Swal('Erro', errorMessage, 'error');
            }
            this.spinner.hide();
          }
        );
      }
    } else {
      Swal('Atenção', 'Por favor, preencha todos os campos corretamente.', 'warning');
      this.spinner.hide();
    }
  }

}
