import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manutencao-servicos-cliente',
  templateUrl: './manutencao-servicos-cliente.component.html',
  styleUrls: ['./manutencao-servicos-cliente.component.css']
})
export class ManutencaoServicosClienteComponent implements OnInit {

  constructor() { }

  campoObrigatorio: boolean = true;
  listaServicos: any;
  ngOnInit() {
  }
  onChangeProvince(servico: any, evento): void { }
  submitServicoCliente(): void { }
}
