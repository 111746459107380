<div class="card">
  <div class="card-header">
    <h3 class="card-title">Usuários do sistema</h3>
  </div>
  <div class="card-body">
    <div class="col-12">
      <div class="form-group">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Pesquisar colaborador" [(ngModel)]="pesquisar">
          <span class="input-group-append">
            <button type="button" (click)="buscarColaboradores()" class="input-group-text bg-blue border-blue"><i class="fa fa-search"></i></button>
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 form-group d-flex justify-content-between align-items-center">
      <h5 class="font-weight-bold">Usuários encontrados: {{ listaColaborador.length | number }}</h5>
      <a>Exportar</a>
    </div>
    <div class="col-12 table-responsive">
      <cdk-virtual-scroll-viewport [itemSize]="50" class="col-12">
        <table class="table table-striped table-hover">
          <thead class="font-weight-semibold">
            <td class="font-weight-semibold">Nome</td>
            <td class="font-weight-semibold">Tipo Usuário</td>
            <td class="font-weight-semibold">Código</td>
            <td class="font-weight-semibold">CPF</td>
            <td class="font-weight-semibold">Situacao</td>
          </thead>
          <tbody>
            <tr *cdkVirtualFor="let colaborador of listaColaborador | filtroText: pesquisar:'Nome'">
              <td>{{colaborador.Nome}}</td>
              <td>{{colaborador.TipoUsuario}}</td>
              <td>{{colaborador.Codigo}}</td>
              <td>{{colaborador.Cpf | cpf}}</td>
              <td>{{colaborador.Situacao}}</td>
            </tr>
          </tbody>
        </table>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
