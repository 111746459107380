export class Contato {
  DDDCelular: string;
  Celular: string;
  DDDTelefone: string;
  Telefone: string;
  Email1: string;
  Email2: string;

  constructor() {}
}
