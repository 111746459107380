import {Component, Input} from '@angular/core';
import {LocalStorage} from 'src/app/service/classes/localstorage/LocalStorage';
import {Crypto} from 'src/app/service/classes/crypto/crypto';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {validarDataMaiorOuIgual} from '../../processos/data-validator';
import Swal from 'sweetalert';
import {ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {CensoConvocacaoServiceService} from './censo-convocacao.service';

@Component({
  selector: 'app-censo-convocacao',
  templateUrl: './censo-convocacao.component.html',
  styleUrls: ['./censo-convocacao.component.css'],
})
export class CensoConvocacaoComponent {
  anual: boolean = false;
  localStorage: LocalStorage;
  etapasItens = [
    {
      formControlName: 'infosPessoais',
      label: 'Informações Pessoais',
      count: 2,
      tooltip:
        'Campos: Nome da Mãe, Nome do Pai, Sexo, Tipo Sanguíneo, Raça/Cor, Estado Civil, Grau de Instrução e Nacionalidade',
    },
    {
      formControlName: 'contatoEndereco',
      label: 'Contato/Endereço',
      count: 3,
      tooltip: 'Campos: CEP, Endereço, Número, Bairro, Município, UF e Complemento',
    },
    {
      formControlName: 'especializacao',
      label: 'Especialização',
      count: 6,
      tooltip: 'Campos: Tipo de Curso, Data de Início, Data de Conclusão e Carga Horária',
    },
    {
      formControlName: 'documentos',
      label: 'Documentos',
      count: 4,
      tooltip:
        'Campos: RG, Registro Profissional, Título de Eleitor, PIS, Carteira Profissional, Certificado Militar e CNH',
    },
    {
      formControlName: 'dependentes',
      label: 'Dependentes',
      count: 5,
      tooltip:
        'Campos: CPF, Nome Completo, Sexo, Tipo de Dependência, Data de Nascimento, UF e Município',
    },
  ];
  formEtapas: FormGroup;
  formConvocacao: FormGroup;
  filtroFilial: string;
  filiaisSelecionadas: any[] = [];
  etapasSelecionadas: any[] = [];
  temErro: boolean = false;
  buscaFilial: string = '';
  censoConvocacao: any;
  EtapaCenso: string[];
  NomeUsuario: string;
  responsavelAniversario: string;

  constructor(
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private convocacaoService: CensoConvocacaoServiceService
  ) {
    const rota = this.route.snapshot.routeConfig.path;
    if (rota == 'censo/anual') {
      this.anual = true;
    }
  }
  ngOnInit() {
    this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    console.log(this.localStorage);
    const tipoCenso = this.anual ? 0 : 1;
    this.formEtapas = this.fb.group({
      infosPessoais: [false],
      contatoEndereco: [false],
      especializacao: [false],
      documentos: [false],
      dependentes: [false],
    });
    this.formConvocacao = this.fb.group({
      EtapaCenso: [this.etapasSelecionadas, Validators.required],
      Empfil: [this.filiaisSelecionadas || [], Validators.required],
      dataInicio: ['', [Validators.required, validarDataMaiorOuIgual()]],
      dataFim: ['', Validators.required],
      TipoCenso: [tipoCenso],
    });
    if (!this.anual) {
      this.configuraçõesCensoAniversario();
    }
  }

  teste() {
    this.spinner.show();
  }

  configuraçõesCensoAniversario() {
    // adicionar get de configurações de censo a partir da filial atual
    const indexAtual = this.localStorage.Filiais.findIndex(
      (filial) => filial.Codigo == this.localStorage.FilialAtual
    );
    this.adicionarFiliais(this.localStorage.Filiais[indexAtual], indexAtual);

    const dataAtual = new Date();
    const primeiroDia = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
    const ultimoDia = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0);

    // Formate a data para o formato desejado, por exemplo, "YYYY-MM-DD"
    const primeiroDiaFormatado = primeiroDia.toISOString().slice(0, 10);
    const ultimoDiaFormatado = ultimoDia.toISOString().slice(0, 10);

    // Defina o valor no controle do formulário e desabilita
    this.formConvocacao.get('dataInicio').setValue(primeiroDiaFormatado);
    this.formConvocacao.get('dataFim').setValue(ultimoDiaFormatado);
    this.formConvocacao.get('dataInicio').clearValidators();
    this.formConvocacao.get('dataInicio').setValidators([Validators.required]);
    this.formConvocacao.get('dataInicio').updateValueAndValidity();
    // this.formConvocacao.get('dataInicio').disable();
    // this.formConvocacao.get('dataFim').disable();

    this.buscarEtapasAniversario(this.formConvocacao.get('Empfil').value[0].Codigo);
  }

  buscarEtapasAniversario(empresaFilial: number) {
    this.etapasItens.forEach((etapa) => this.formEtapas.get(etapa.formControlName).setValue(false));
    this.spinner.show();
    this.convocacaoService.buscarCensoAniverario(empresaFilial).subscribe(
      async (data: any) => {
        this.responsavelAniversario = data.NomeUsuario;
        this.etapasSelecionadas = [];
        data.ListaEtapas.forEach((etapa) => {
          this.etapasSelecionadas.push(etapa);
          const nomeEtapa = this.etapasItens.find((etapaItem) => etapaItem.count == etapa);
          this.formEtapas.get(nomeEtapa.formControlName).setValue(true);
        });
        this.formConvocacao.get('EtapaCenso').setValue(this.etapasSelecionadas);
        this.formConvocacao.get('EtapaCenso').updateValueAndValidity();
        if (this.etapasSelecionadas.length == 0) {
          Swal(
            'Alerta',
            'Essa filial ainda não tem uma configuração de censo de aniversário inicial, selecione as informações/etapas necessárias e crie a configuração.',
            'warning'
          );
        }
        this.spinner.hide();
      },
      (error) => {
        Swal('Erro', error.error.Mensagem, 'error');
        this.spinner.hide();
      }
    );
    console.log(this.formConvocacao.get('Empfil').value[0].Codigo);
  }

  adicionarEtapas(etapa: string, etapaNumero: number) {
    if (this.formEtapas.get(etapa).value == true) {
      this.etapasSelecionadas.push(etapaNumero.toString());
    } else {
      const index = this.etapasSelecionadas.findIndex((etapaLista) => etapaLista == etapa);
      this.etapasSelecionadas.splice(index, 1);
    }
    this.formConvocacao.get('EtapaCenso').updateValueAndValidity();
    console.log(this.formConvocacao);
    console.log('etapas selecionadas', this.etapasSelecionadas);
  }

  adicionarFiliais(filial: any, index: number) {
    this.filiaisSelecionadas.push(filial);
    this.localStorage.Filiais.splice(index, 1);
    this.formConvocacao.get('Empfil').updateValueAndValidity();
    this.ordenarFiliaisPorCodigo(this.filiaisSelecionadas);
  }

  removerFiliais(filial: any, index: number) {
    this.localStorage.Filiais.push(filial);
    this.filiaisSelecionadas.splice(index, 1);
    this.formConvocacao.get('Empfil').updateValueAndValidity();
    this.ordenarFiliaisPorCodigo(this.localStorage.Filiais);
  }

  trocarFiliais(filial: any, index: number) {
    this.localStorage.Filiais.push(this.filiaisSelecionadas[0]);
    this.filiaisSelecionadas.splice(0, 1);
    this.localStorage.Filiais.splice(index, 1);
    this.filiaisSelecionadas.push(filial);
    this.formConvocacao.get('Empfil').updateValueAndValidity();
    this.ordenarFiliaisPorCodigo(this.localStorage.Filiais);
    const empfilAtual = this.formConvocacao.get('Empfil').value[0].Codigo;
    this.buscarEtapasAniversario(empfilAtual);
  }

  ordenarFiliaisPorCodigo(lista) {
    lista.sort((a, b) => {
      return a.Codigo - b.Codigo;
    });
  }

  verificaData(dataInicial: AbstractControl, dataFinal: AbstractControl) {
    if (dataInicial.value > dataFinal.value) {
      dataFinal.setValue('');
      this.temErro = true;
      Swal('Atenção', 'A data final deve ser posterior à inicial.', 'warning');
      return false;
    } else {
      return true;
    }
  }

  confirmarCenso() {
    if (this.formConvocacao.valid) {
      if (
        this.verificaData(this.formConvocacao.get('dataInicio'), this.formConvocacao.get('dataFim'))
      ) {
        this.temErro = false;
        this.censoConvocacao = this.formConvocacao.value;
        this.montarCenso();
      }
    } else {
      console.log('formulário inválido');
      console.log(this.formConvocacao);
      this.temErro = true;
      Swal('Atenção', 'Todos os campos são obrigatórios.', 'warning');
    }
  }

  montarCenso() {
    this.censoConvocacao.Empfil = this.formConvocacao
      .get('Empfil')
      .value.map((filial) => filial.Codigo);

    console.log('montar censo', this.censoConvocacao);
    console.log('form', this.formConvocacao.value);

    //configuração para rota nova
    // const etapasConcatenadas = this.censoAtualizacao.EtapaCenso.join(',');
    // const nomeUsuarioConcatenado = this.censoAtualizacao.NomeUsuario.replace(/\s+/g, '-');
    // console.log('montar censo string', etapasConcatenadas, nomeUsuarioConcatenado);
  }

  convocarCenso() {
    this.anual ? this.spinner.show() : '';
    this.convocacaoService.convocarCenso(this.formConvocacao.value).subscribe(
      async (data) => {
        this.spinner.hide();
        console.log('censo convocado', this.censoConvocacao.value);
        Swal('Sucesso', 'O censo foi convocado', 'success');
        // this.enviarEmails();
      },
      (error) => {
        this.spinner.hide();
        Swal('Erro', error.error.Mensagem, 'error');
      }
    );
  }

  enviarEmails() {
    this.spinner.show();
    let objetoEmails = {
      'Empfil': this.formConvocacao.get('Empfil').value.map((filial) => filial.Codigo),
      'Link': 'http://localhost:4200/login',
    };
    let tipo = this.formConvocacao.get('TipoCenso').value;
    this.convocacaoService.enviarEmails(objetoEmails, tipo).subscribe(
      async (data) => {
        this.spinner.hide();
        console.log('objeto e-mails:', objetoEmails);
      },
      (error) => {
        this.spinner.hide();
        Swal('Erro', error.error.Mensagem, 'error');
      }
    );
  }

  atualizarCenso() {
    console.log('entrar atualizar censo');
    this.spinner.show();
    this.convocacaoService.deletarCensoAniversario(this.filiaisSelecionadas[0].Codigo).subscribe(
      async (data) => {
        Swal('Sucesso', 'O censo foi deletado.', 'success');
        this.spinner.hide();
      },
      (error) => {
        console.log(error);
        Swal('Erro', error.error.Message, 'error');
        this.spinner.hide();
      }
    );

    // this.convocarCenso();
  }
}
