<div class="row mt-4">
    <i class="fa-solid fa-chevron-left fa-2x col-3 mt-2 icon_titulo" (click)="navegar('colaboradores')"></i>
    <h1 class="col-8 titulo">Colaborador</h1>
</div>
<div class="row corpo_colaborador">
    <div class="col-3 mt-3 mr-3 colaborador">
        <div class="foto_user">
            <div class="add_foto">
                <i class="fa-solid fa-camera"></i>
            </div>
        </div>
        <i *ngIf="!carregado" class="loader" ></i>
        <div *ngIf="carregado">
            <p class="dados_colaborador"><b>Matrícula: </b>{{colaborador.Matricula}}</p>
            <p class="dados_colaborador"><b>Nome: </b>{{colaborador.InformacoesBasicas.NomeCompleto}}</p>
            <p class="dados_colaborador"><b>Cargo: </b>{{colaborador.InformacoesBasicas.Cargo.Nome}}</p>
            <p class="dados_colaborador"><b>Departamento: </b>{{colaborador.InformacoesBasicas.Departamento.Nome}}</p>
            <p class="dados_colaborador"><b>Vínculo: </b>{{colaborador.InformacoesBasicas.Vinculo.Nome}}</p>
            <p class="dados_colaborador"><b>Situação: </b>{{colaborador.Stiuacao.Descricao}}</p>
            <p class="dados_colaborador"><b>Data de adimissão:
                </b>{{colaborador.InformacoesBasicas.DataAdmissao.ToDate}}</p>
            <p class="dados_colaborador"><b>Filiação sindical: </b>{{colaborador.InformacoesBasicas.Sindicato.Nome}}
            </p>
            <p class="dados_colaborador"><b>E-mail: </b>{{colaborador.Pessoal.Contato.Email1}}</p>
            <p class="dados_colaborador"><b>Celular: </b>{{colaborador.Pessoal.Contato.Celular}}</p>
        </div>
    </div>
    <div class="botoes_colaboadores">
        <div class="col_botoes">
            <div class="botao">
                <i class="fa-solid fa-users fa-2x m-2"></i>
                <span>Dados dos dependetes</span>
            </div>
            <div class="botao">
                <i class="fa-solid fa-id-card-clip fa-2x mb-2"></i>
                <span>Dados do <br> vínculo</span>
            </div>
            <div class="botao">
                <i class="fa-solid fa-file fa-2x mb-2"></i>
                <span>Documentos</span>
            </div>
        </div>
        <div class="col_botoes">
            <div class="botao">
                <i class="fa-solid fa-credit-card fa-2x mb-2"></i>
                <span>Dados de pagamento</span>
            </div>
            <div class="botao">
                <i class="fa-solid fa-user fa-2x mb-2"></i>
                <span>Dados do colaborador</span>
            </div>
        </div>
    </div>
</div>