import {Situacao} from './Situacao';
import {SituacaoFerias} from './SituacaoFerias';
import {Setor} from './Setor';
import {Cargo} from './Cargo';

export class Colaborador
  {
  
    nome: string;
    matricula: string;
    cpf: string;
    empfil: string;
    situacao: Situacao;
    situacaoFerias: SituacaoFerias;
    setor: Setor;
    cargo: Cargo;
    sexo: number;
    competencia: string;

    constructor(nome: string, matricula: string, cpf: string, empfil: string, sexo: number, situacao: Situacao, situacaoFerias: SituacaoFerias = null, cargo: Cargo = null, setor: Setor = null, competencia:string = null){
      this.nome = nome;
      this.matricula = matricula;
      this.cpf = cpf;
      this.empfil = empfil;
      this.sexo = sexo;
      this.situacao = situacao;
      this.situacaoFerias = situacaoFerias;
      this.setor = setor;
      this.cargo = cargo;
      this.competencia = competencia;
    }
  
  }