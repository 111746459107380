import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AcessoService } from '../../../service/acesso/acesso.service';
import { EstrangeiroComponent } from './estrangeiro.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable ({ providedIn : 'root' , })

export class EstrangeiroService {


  filial: string;

  url_acesso = this.acesso.validarUrl();
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any;
  tpProcessamento: string = 'EM';
  competencia: string = '';
  filialAtual: string = '';
  tipoUsuario: string = '';

  constructor(private http: HttpClient, private acesso: AcessoService) {
    
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }


buscarEstrangeiro(filial: string, matricula: string) {
  return this.http.get(`${this.url_acesso}estrangeiro-reside-fora/${filial}/${matricula}`, {
    headers: this.cabecalho,
  });
}


buscarListaPaises() {
  return this.http.get(`${this.url_acesso}estrangeiro-reside-fora/listar/paises`, {
    headers: this.cabecalho,
  });
}

buscarListalogradouros() {
  return this.http.get(`${this.url_acesso}estrangeiro-reside-fora/listar/logradouros`, {
    headers: this.cabecalho,
  });
}


buscarListaReceitas() {
  return this.http.get(`${this.url_acesso}estrangeiro-reside-fora/listar/receitas`, {
    headers: this.cabecalho,
  });
}


buscarListaRendimentos() {
  return this.http.get(`${this.url_acesso}estrangeiro-reside-fora/listar/rendimentos`, {
    headers: this.cabecalho,
  });
}


buscarListaTributacoes() {
  return this.http.get(`${this.url_acesso}estrangeiro-reside-fora/listar/tributacoes`, {
    headers: this.cabecalho,
  });
}

enviarCadastroEstrangeiro(filial: string, matricula: string, estrangeiro: EstrangeiroComponent) {
  return this.http.put(`${this.url_acesso}estrangeiro-reside-fora/${filial}/${matricula}`, estrangeiro, {
    headers: this.cabecalho,
  });
}
  
}