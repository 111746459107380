import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { ColaboradorService } from './colaborador.service';

@Component({
  selector: 'app-colaborador',
  templateUrl: './colaborador.component.html',
  styleUrls: ['./colaborador.component.css']
})
export class ColaboradorComponent implements OnInit {

  localStorage: LocalStorage;

  colaborador: any;

  carregado: boolean = false;

  constructor(public router: Router, public colaboradorService: ColaboradorService) {

  }
  ngOnInit(): void {
    this.buscarColaborador("21")
  }
  buscarColaborador(matricula: string) {
    this.colaboradorService.buscarColaboradorAtivo(matricula).subscribe((data: any) => {
      this.colaborador = data;
      this.carregado = true;
    })

  }
  navegar(rota: string) {
    this.router.navigate([rota])
  }
  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }
}

export class Colaborador {
  Nome: string;
  Matricula: string;
  Celular: string;
  Email: string;
  Situacao: string;
  Vinculo: string;
  DataAdmicao: string;
  FiliacaoSindical: string;
  Cargo: string;
  Departamento: string;
}