<!-- Button trigger modal -->
<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
    <i class="fa fa-cog" aria-hidden="true"></i>
</button>

<!-- Modal -->
<div class="modal fade bd-example" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <div class="card-header">
                    <h5 id="configuracaoClienteLabel">Configurações Cliente</h5>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="accordion" id="accordionExample">

                    <p class="d-flex justify-content-center">
                        <button class="btn btn-primary " type="button" data-toggle="collapse"
                            data-target="#collapsePermissaoClientes" aria-expanded="false"
                            aria-controls="collapsePermissaoClientes" style="width: 200px; height: 40px;">
                            Serviços do Cliente
                        </button>
                    </p>
                    <div class="collapse" id="collapsePermissaoClientes" data-parent="#accordionExample">
                        <div class="card">
                            <div class="card-body">
                                <form [formGroup]="" (ngSubmit)="submitServicoCliente()">
                                    <div class="form-check row col-md-12 justify-content-center">
                                    <div class="row form-group text-center">
                                        <table class="table table-striped table-hover mb-0 text-center">
                                            <thead class="bg-teal-400">
                                                <tr class="text-start" >
                                                    <th style="text-align:left;">
                                                        Serviços do Cliente
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr >
                                                    <td style="text-align:left;">
                                                        <div class="form-check" *ngFor="let servico of listaServicos">
                                                                <div>
                                                                    <input class="form-check-input" (change)="onChangeProvince(servico, $event)"name="{{servico.Descricao}}" type="checkbox" id="{{servico.Codigo}}">
                                                                    <label class="form-check-label" for="{{servico}}">{{servico.Descricao}}</label>
                                                                </div>
                                                                <span class="form-text text-danger"
                                                                *ngIf="campoObrigatorio">
                                                                Campo obrigatório
                                                              </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <p class="d-flex justify-content-center">
                        <button class="btn btn-primary" style="width: 200px; height: 40px;" type="button"
                            data-toggle="collapse" data-target="#collapsePermissaoUsuario" aria-expanded="false"
                            aria-controls="collapseClasseUsuário">
                            Grupos do Cliente
                        </button>
                    </p>
                    <div class="collapse" id="collapsePermissaoUsuario" data-parent="#accordionExample">
                        <div class="card">
                            <div class="card-body">
                                <div class="col-12 table-responsive text-center">
                                    <div class="row form-group text-center">
                                        <table class="table table-striped table-hover mb-0 text-center">
                                            <thead class="bg-teal-400">
                                                <tr class="text-start" >
                                                    <th style="text-align:left;">
                                                        Grupos do Cliente
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style="text-align:left;">
                                                        <div class="form-check" *ngFor="let servico of listaServicos">
                                                                <div>
                                                                 <input class="form-check-input" (change)="onChangeProvince(servico, $event)" name="{{servico.Descricao}}" type="checkbox" id="{{servico.Codigo}}">
                                                                 <label class="form-check-label" for="{{servico}}">{{servico.Descricao}}</label>
                                                                </div>
                                                                <span class="form-text text-danger"
                                                                *ngIf="campoObrigatorio">
                                                                Campo obrigatório
                                                              </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
