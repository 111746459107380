import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert'
import { NgxSpinnerService } from 'ngx-spinner';
import { ErroService } from 'src/app/service/erros/erro.service';
// import { permissaoEmpresaFilialService } from './permissao-empresa-filial.service'; 
import { Parametro } from 'src/app/service/interfaces/Parametro';
import { Filial } from '../../top-menu/interface/filial';
import { PsePermissaoEmpresaFilialService } from './pse-permissao-empresa-filial.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';

@Component({
  selector: 'app-pse-permisao-empresa-filial',
  templateUrl: './pse-permisao-empresa-filial.component.html',
  styleUrls: ['./pse-permisao-empresa-filial.component.css']
})
export class PsePermisaoEmpresaFilialComponent implements OnInit {
 
  localStorage: LocalStorage;
  
  formPermissaoEmpfil: UntypedFormGroup;

  listaTipo: TipoUsuario[] = [];
  listaEmpfil: any[] = []
  
  situacao: Situacao = { Ativo: false, ExisteUsuario: false };
  codigoCliente: string;

  temErro: boolean = false;
  desabilitarCampos: boolean = true;
  ativarDesativar: boolean = false;

  constructor(private fb: UntypedFormBuilder, public spinner: NgxSpinnerService, public validaErro: ErroService, private permissaoEmpresaFilialService: PsePermissaoEmpresaFilialService) {
    this.localStorage = this.decryptLocalStorage();
    this.codigoCliente = String(this.localStorage.ClienteId)
   }

  ngOnInit() {
    this.formPermissaoEmpfil = this.fb.group({
      codigo: ['', Validators.required],
      nome: ['', Validators.required],
      empfil: ['', Validators.required],
      tipo: ['', Validators.required],
    })
    this.buscarTipo();
  }

  buscarTipo() {
    this.permissaoEmpresaFilialService.buscarTipo()
      .subscribe(
        (data: TipoUsuario[]) => {
          this.listaTipo = data;
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        }
      )
  }

  buscarNome() {
    const { codigo } = this.formPermissaoEmpfil.value
    if (!codigo) {
      new AlertaModal('alerta','Atenção', 'Codigo vazio.','p')
    } else {
      this.permissaoEmpresaFilialService.buscarNome(codigo)
        .subscribe(
          (data: any) => {
            this.spinner.hide();
            this.formPermissaoEmpfil.get('nome').setValue(data["NomeUsuario"])
            this.preencherEmpifil(codigo)
          },
          error => {
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
            this.spinner.hide();
          }
        )
    }
  }

  obterSituacao() {
    const { codigo, empfil } = this.formPermissaoEmpfil.value;

    if (this.formPermissaoEmpfil.get("codigo").value && this.formPermissaoEmpfil.get("empfil").value) {
      this.spinner.show()
      this.permissaoEmpresaFilialService.buscarSituacao(codigo, this.codigoCliente, empfil)
        .subscribe(
          (data: Situacao) => {
            this.situacao = data;
            this.spinner.hide();
          },
          error => {
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
            this.spinner.hide();
          }
        )
    }
  }

  preencherEmpifil(codigoUnicoUsuario) {
    this.permissaoEmpresaFilialService.buscarFilial(codigoUnicoUsuario,this.codigoCliente).subscribe(
      (data:[]) =>{
        this.listaEmpfil = data;
      },
      error=>{
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
      }
    )
  }

  salvarPermissao(): void {
    if (this.formPermissaoEmpfil.valid) {
      this.temErro = false;
      this.spinner.show();
      const permissao: Permissao = this.montarPermissao();
      this.permissaoEmpresaFilialService.salvarPermissao(permissao)
        .subscribe(
          (data: any) => {
            this.spinner.hide();
            new AlertaModal('sucesso',"Sucesso","Usuário Vinculado",'p')
            this.limparCampos();
          },
          error => {
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
            this.spinner.hide();
          }
        )
    } else {
      this.temErro = true
      new AlertaModal('alerta','Atenção','Verifique se os campos inválidos.','p')
    }
  }

  ativar(): void {
    if (this.formPermissaoEmpfil.valid) {
      this.temErro = false;
      const permissao: Permissao = this.montarPermissao();
      this.permissaoEmpresaFilialService.ativarPermissao(permissao)
        .subscribe(
          (data: any) => {
            new AlertaModal('sucesso',"Sucesso","Usuário foi ativado",'p')
            this.spinner.hide()
            this.limparCampos();
            
          },
          error => {
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
            this.spinner.hide();
          }
        )
    } else {
      this.temErro = true;
      new AlertaModal('alerta','Atenção','Verifique se os campos inválidos.','p')
    }
  }

  desativar(): void {
    if (this.formPermissaoEmpfil.valid) {
      this.temErro = false;
      const permissao: Permissao = this.montarPermissao();
      this.permissaoEmpresaFilialService.desativarPermissao(permissao)
        .subscribe(
          (data: any) => {
            new AlertaModal('sucesso',"Sucesso","Usuário foi Desativado",'p')
            this.spinner.hide();
            this.limparCampos();
            
          },
          error => {
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
            this.spinner.hide();
          }
        )
    } else {
      this.temErro = true
      new AlertaModal('alerta','Atenção','Verifique se os campos inválidos.','p')
    }
  }
  
  limparCampos() {
    this.formPermissaoEmpfil.get("codigo").setValue("");
    this.formPermissaoEmpfil.get("nome").setValue("");
    this.formPermissaoEmpfil.get("empfil").setValue("");
    this.formPermissaoEmpfil.get("tipo").setValue("");
    this.situacao = null
    this.temErro = false;
    this.formPermissaoEmpfil.markAsPristine();
  }

  montarPermissao(): Permissao {
    return {
      CodigoUsuario: Number(this.formPermissaoEmpfil.get('codigo').value),
      NomeUsuario: this.formPermissaoEmpfil.get('nome').value,
      EmpresaFilial: this.formPermissaoEmpfil.get('empfil').value,
      TipoUsuario: Number(this.formPermissaoEmpfil.get('tipo').value),
      CodigoCliente: Number(this.localStorage.ClienteId),
      CodigoServico: this.localStorage.CodigoServico
    }
  }
  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }
}

export interface Permissao {
  CodigoUsuario: number;
  CodigoCliente: number;
  TipoUsuario?: number;
  CodigoServico?: string;
  NomeUsuario?: string;
  EmpresaFilial: string;
}

export interface TipoUsuario {
  CodigoTipoUsuario: string;
  Descricao: string;
  TipoDoUsuario: string;
}

export interface Situacao {
  Ativo: boolean
  ExisteUsuario: boolean
}