import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AcessoService } from "src/app/service/acesso/acesso.service";
import { LocalStorage } from "src/app/service/classes/localstorage/LocalStorage";
import { HistoricoTransferencia } from "./classes/historico-transferencia";
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root'
})
export class TransferenciaHistoricoService {
  localStorage: LocalStorage;
  url_acesso: string;
  headers = new HttpHeaders();
  cabecalho: HttpHeaders;//= this.headers.append('sessao', localStorage.getItem('sessao'));
  tpProcessamento: string = "EM";
  data: any = new Date()
  mes: number = this.data.getMonth() + 1
  ano: number = this.data.getFullYear()
  competencia: string = this.ano.toString() + this.mes.toString().padStart(2, "0")
  filialAtual: string = "";//localStorage.getItem('filial_atual');
  tipoUsuario: string = "";//localStorage.getItem('tipoUsuario');

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filialAtual = this.localStorage.FilialAtual.toString();
      this.tipoUsuario = this.localStorage.TipoUsuario;
      this.url_acesso = this.acesso.validarUrl();
    }
    this.url_acesso = this.acesso.validarUrl();
  }

  salvarRegistroHistoricoTransferencia(historico: HistoricoTransferencia) {
    return this.http.post(this.url_acesso + `transferencia-historico/${this.filialAtual}/${this.tpProcessamento}`, historico,{ headers: this.cabecalho });
  }

  alterarRegistroHistoricoTransferencia(historico: HistoricoTransferencia) {
    return this.http.put(this.url_acesso + `transferencia-historico/${this.filialAtual}/${this.tpProcessamento}`, historico, { headers: this.cabecalho });
  }

  excluirRegistroHistoricoTransferencia(matricula: string, empresafilialdestino: string, lotacao: string, datatransferencia: string, centroCusto: string, cargo: string, funcao: string) {
    return this.http.delete(this.url_acesso + `transferencia-historico/${this.filialAtual}/${this.tpProcessamento}/${matricula}/${empresafilialdestino}/${lotacao}/${datatransferencia}/?centrocusto=${centroCusto}&cargo=${cargo}&funcao=${funcao}`, { headers: this.cabecalho });
  }

  listarHistoricoTransferencia(matricula: string) {
    return this.http.get(this.url_acesso + `transferencia-historico/${matricula}/${this.filialAtual}/${this.tpProcessamento}`, { headers: this.cabecalho });
  }

  listarLotacoes(empresaFilial: any) {
    return this.http.get(`${this.url_acesso}lotacao/${empresaFilial}`,{headers:this.cabecalho});
  }

  listarFiliais() {
    var url_acesso = this.acesso.validarUrl();
    return this.http.get(url_acesso +'filiais',{headers:this.cabecalho});
  }

  buscarColaborador(matricula: string){
    return this.http.get(`${this.url_acesso}cadastro-ativo-matricula/${matricula}/${this.filialAtual}`,{headers:this.cabecalho});
  }

  listarCargos(empresaFilial: string){
    return this.http.get(`${this.url_acesso}transferencia-historico/cargos/${empresaFilial}/${this.tpProcessamento}/`,{headers:this.cabecalho});
  }

  listarFuncoes(empresaFilial: string){
    return this.http.get(`${this.url_acesso}transferencia-historico/funcoes/${empresaFilial}/${this.tpProcessamento}`,{headers:this.cabecalho});
  }

  listarCentroCusto(){
    return this.http.get(`${this.url_acesso}transferencia-historico/centro-custo/${this.filialAtual}/${this.tpProcessamento}`,{headers:this.cabecalho});
  }
}
