<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="modal-header">
  <h3 class="modal-title">{{ licencaindex !== null ? 'Atualização' : 'Inclusão' }} de Licença</h3>
  <button type="button" class="close" id="botaoFechar" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="formLicencaPremio" class="modal-body row">
  <div class="form-group col-6">
    <label for="DataInicio" class="font-weigth-semibold">Data Início</label>
    <input
      type="date"
      max="9999-12-31"
      class="form-control"
      id="DataInicio"
      formControlName="DataInicio"
      [ngClass]="
        (formLicencaPremio.get('DataInicio').invalid ||
          formLicencaPremio.get('DataInicio').value == null) &&
        formLicencaPremio.get('DataInicio').touched
          ? 'is-invalid'
          : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="
        (formLicencaPremio.get('DataInicio').invalid ||
          formLicencaPremio.get('DataInicio').value == null) &&
        formLicencaPremio.get('DataInicio').touched
      "
    >
      {{
        formLicencaPremio.get('DataInicio').hasError('data')
          ? 'Data deve ser menor que data atual'
          : 'Campo Obrigatório'
      }}
    </span>
  </div>

  <div class="form-group col-6">
    <label for="DataFim" class="font-weigth-semibold">Data Fim</label>
    <input
      type="date"
      max="9999-12-31"
      class="form-control"
      id="DataFim"
      formControlName="DataFim"
      [ngClass]="
        (formLicencaPremio.get('DataFim').invalid ||
          formLicencaPremio.get('DataFim').value == null) &&
        formLicencaPremio.get('DataFim').touched
          ? 'is-invalid'
          : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="
        (formLicencaPremio.get('DataFim').invalid ||
          formLicencaPremio.get('DataFim').value == null) &&
        formLicencaPremio.get('DataFim').touched
      "
    >
      {{
        formLicencaPremio.get('DataFim').hasError('data')
          ? 'Data deve ser menor que data atual'
          : 'Campo Obrigatório'
      }}
    </span>
  </div>

  <div class="form-group col-6">
    <label>Número de Dias</label>
    <input type="text" class="form-control" formControlName="NumDias" readonly />
  </div>

  <div class="form-group col-6">
    <label for="NumeroProcesso" class="font-weigth-semibold">Número do Processo</label>
    <input
      type="text"
      class="form-control"
      id="NumeroProcesso"
      formControlName="NumeroProcesso"
      placeholder="000"
      style="text-transform: uppercase"
      [ngClass]="
        formLicencaPremio.get('NumeroProcesso').invalid &&
        formLicencaPremio.get('NumeroProcesso').touched
          ? 'is-invalid'
          : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="
        formLicencaPremio.get('NumeroProcesso').invalid &&
        formLicencaPremio.get('NumeroProcesso').touched
      "
    >
      Campo Obrigatório
    </span>
  </div>

  <div class="form-group col-6">
    <label for="NumeroDoe" class="font-weigth-semibold">Número DOE</label>
    <input
      type="text"
      class="form-control"
      id="NumeroDoe"
      formControlName="NumeroDoe"
      placeholder="000"
      style="text-transform: uppercase"
      [ngClass]="
        formLicencaPremio.get('NumeroDoe').invalid && formLicencaPremio.get('NumeroDoe').touched
          ? 'is-invalid'
          : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="
        formLicencaPremio.get('NumeroDoe').invalid && formLicencaPremio.get('NumeroDoe').touched
      "
    >
      Campo Obrigatório
    </span>
  </div>

  <div class="form-group col-6">
    <label for="NumeroAto" class="font-weigth-semibold">Número Ato</label>
    <input
      type="text"
      class="form-control"
      id="NumeroAto"
      formControlName="NumeroAto"
      placeholder="000"
      style="text-transform: uppercase"
      [ngClass]="
        formLicencaPremio.get('NumeroAto').invalid && formLicencaPremio.get('NumeroAto').touched
          ? 'is-invalid'
          : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="
        formLicencaPremio.get('NumeroAto').invalid && formLicencaPremio.get('NumeroAto').touched
      "
    >
      Campo Obrigatório
    </span>
  </div>

  <div class="form-group col-6">
    <label for="IdClassificacaoAto" class="font-weigth-semibold">Classificação Ato</label>
    <select
      class="form-control"
      id="IdClassificacaoAto"
      formControlName="IdClassificacaoAto"
      [ngClass]="
        formLicencaPremio.get('IdClassificacaoAto').invalid &&
        formLicencaPremio.get('IdClassificacaoAto').touched
          ? 'is-invalid'
          : null
      "
    >
      <option value="null">Selecione uma opção</option>
      <option *ngFor="let item of listaClassificacaoAto" [value]="item.Cod">
        {{ item.Cod }} - {{ item.Nome }}
      </option>
    </select>
    <span
      class="form-text text-danger"
      *ngIf="
        formLicencaPremio.get('IdClassificacaoAto').invalid &&
        formLicencaPremio.get('IdClassificacaoAto').touched
      "
    >
      Campo Obrigatório
    </span>
  </div>

  <div class="form-group col-3">
    <label for="DtDoe" class="font-weigth-semibold">Data DOE</label>
    <input
      type="date"
      max="9999-12-31"
      class="form-control"
      id="DtDoe"
      formControlName="DtDoe"
      [ngClass]="
        formLicencaPremio.get('DtDoe').invalid && formLicencaPremio.get('DtDoe').touched
          ? 'is-invalid'
          : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="formLicencaPremio.get('DtDoe').invalid && formLicencaPremio.get('DtDoe').touched"
    >
      Campo Obrigatório
    </span>
  </div>

  <div class="form-group col-3">
    <label for="DtAto" class="font-weigth-semibold">Data Ato</label>
    <input
      type="date"
      max="9999-12-31"
      class="form-control"
      id="DtAto"
      formControlName="DtAto"
      [ngClass]="
        formLicencaPremio.get('DtAto').invalid && formLicencaPremio.get('DtAto').touched
          ? 'is-invalid'
          : null
      "
    />
    <span
      class="form-text text-danger"
      *ngIf="formLicencaPremio.get('DtAto').invalid && formLicencaPremio.get('DtAto').touched"
    >
      Campo Obrigatório
    </span>
  </div>

  <div class="form-group col-6">
    <label for="FormaQuitacao" class="font-weigth-semibold">Forma de Quitação</label>
    <select
      class="form-control"
      id="FormaQuitacao"
      formControlName="FormaQuitacao"
      [ngClass]="
        formLicencaPremio.get('FormaQuitacao').invalid &&
        formLicencaPremio.get('FormaQuitacao').touched
          ? 'is-invalid'
          : null
      "
    >
      <option value="null">Selecione uma opção</option>
      <option *ngFor="let item of listaFormasQuitacao" [value]="item.Codigo">
        {{ item.Codigo }} - {{ item.Descricao }}
      </option>
    </select>
    <span
      class="form-text text-danger"
      *ngIf="
        formLicencaPremio.get('FormaQuitacao').invalid &&
        formLicencaPremio.get('FormaQuitacao').touched
      "
    >
      Campo Obrigatório
    </span>
  </div>
</form>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>

  <button
    *ngIf="licencaindex !== null"
    type="button"
    class="btn btn-primary"
    (click)="lancarLicenca()"
  >
    Atualizar
  </button>
  <button
    *ngIf="licencaindex === null"
    type="button"
    class="btn btn-primary"
    (click)="lancarLicenca()"
  >
    Cadastrar
  </button>
</div>
