import Swal from 'sweetalert';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Comissao, ComissaoComponent, IdClassificacaoAto } from '../comissao.component';
import { ComissaoService } from '../comissao.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { NgxSpinnerService } from 'ngx-spinner';
import { Parametro } from 'src/app/service/interfaces/Parametro';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { validarDataAtualMenorIgual } from 'src/app/service/Validators/dataValidatorMenorIgual';
import { validarDataFimMaiorIgual } from 'src/app/service/Validators/dataFimMaiorIgualValidator';
@Component({
  selector: 'app-comissao-cadastro',
  templateUrl: './comissao-cadastro.component.html',
  styleUrls: ['./comissao-cadastro.component.css'],
})
export class ComissaoCadastroComponent implements OnInit, OnChanges {
  @Input() cadastro: boolean = true;
  @Input() numOrd: string = '';
  @Input() comissaoSelecionada: Comissao;
  formulario: FormGroup;
  listaClassificacao: IdClassificacaoAto[] = [];
  listaCargos: Parametro[] = [];
  listaReferencias: Parametro[] = [];
  listaProjetos: Parametro[] = [];
  erroData: boolean = false;
  filialAtual: number;
  constructor(
    fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private comissaoService: ComissaoService,
    private comissaoComponente: ComissaoComponent
  ) {
    const localStorageConstrutor = this.decryptLocalStorage();
    this.filialAtual = localStorageConstrutor.FilialAtual;
    this.formulario = fb.group(
      {
        dataInicio: ['', Validators.required],
        dataFim: [''],
        tipo: ['', Validators.required],
        numero: [''],
        cargo: [''],
        referencia: [{ value: '', disabled: true }],
        motivo: ['', Validators.required],
        numeroProcesso: ['', Validators.required],
        numeroDoe: ['', Validators.required],
        idClassificacaoAto: ['', Validators.required],
        numeroAto: ['', Validators.required],
        anoAto: [''],
        dtAto: ['', validarDataAtualMenorIgual()],
        dtDoe: ['', validarDataAtualMenorIgual()],
        anoDoe: [{ value: '' }],
        seq: [{ value: '' }],
      },
      { validators: validarDataFimMaiorIgual('dataInicio', 'dataFim') }
    );
  }

  ngOnInit(): void {
    this.buscarClassificacao();
    this.buscarCargos();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.cadastro === false) {
      this.preencherFormulario(this.comissaoSelecionada);
    }
  }

  buscarClassificacao() {
    this.spinner.show();
    this.comissaoService.buscarClassificacao().subscribe({
      next: (data: any) => {
        this.spinner.hide();
        this.listaClassificacao = data;
      },
      error: (erro) => {
        this.spinner.hide();
        Swal('Erro', 'Não foi possível buscar os ids de classificação.', 'error');
      },
    });
  }

  buscarCargos() {
    this.spinner.show();
    this.comissaoService.buscarCargo().subscribe({
      next: (data: any) => {
        this.spinner.hide();
        this.listaCargos = data;
      },
      error: (erro) => {
        this.spinner.hide();
        Swal('Erro', 'Não foi possível buscar cargos.', 'error');
      },
    });
  }

  buscarReferencias(evento?): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.listaReferencias = [];
      const codCargo = evento ? evento.target.value : this.formulario.get('cargo').value;
      this.spinner.show();
      this.comissaoService.buscarReferencia(codCargo).subscribe({
        next: (data: any) => {
          this.spinner.hide();
          this.formulario.get('referencia').enable();
          this.listaReferencias = data;
          resolve();
        },
        error: (erro) => {
          this.spinner.hide();
          Swal('Erro', 'Não foi possível buscar referências.', 'error');
        },
      });
    });
  }

  buscarProjetos() {
    return new Promise<void>((resolve, reject) => {
      this.listaProjetos = [];
      this.spinner.show();
      this.comissaoService.buscarProjetos().subscribe({
        next: (data: any) => {
          this.spinner.hide();
          this.listaProjetos = data;
          resolve();
        },
        error: (erro) => {
          this.spinner.hide();
          Swal('Erro', 'Não foi possível buscar projetos.', 'error');
        },
      });
    });
  }

  touchAllFields() {
    Object.keys(this.formulario.controls).forEach((key) => {
      this.formulario.get(key).markAsTouched();
    });
  }
  cadastrarComissao() {
    this.spinner.show();
    this.touchAllFields();
    const botaoFechar = document.querySelector('#fecharModal') as HTMLButtonElement;
    if (this.formulario.valid) {
      const comissao = this.montarComissao();
      this.spinner.show();
      this.comissaoService.cadastrarComissao(comissao).subscribe({
        next: (data) => {
          this.spinner.hide();
          Swal('Sucesso', 'Comissão cadastrada com sucesso.', 'success');
          this.comissaoComponente.buscarListaComissao();
          botaoFechar.click();
          this.spinner.hide();
        },
        error: (erro) => {
          this.spinner.hide();
          Swal('Erro', erro.error.Mensagem, 'error');
          this.spinner.hide();
        }, 
      });
    } else {
      Swal('Atenção', 'Por favor, preencha todos os campos corretamente.', 'warning');
      this.spinner.hide();
    }
  }

  atualizarComissao() {
    this.spinner.show();
    this.touchAllFields();
    const botaoFechar = document.querySelector('#fecharModal') as HTMLButtonElement;
    if (this.formulario.valid) {
      const comissao = this.montarComissao();
      this.spinner.show();
      this.comissaoService.atualizarComissao(comissao).subscribe({
        next: (data) => {
          this.spinner.hide();
          Swal('Sucesso', 'Comissão atualizada com sucesso.', 'success');
          this.comissaoComponente.buscarListaComissao();
          botaoFechar.click();
          this.spinner.hide();
        },
        error: (erro) => {
          this.spinner.hide();
          Swal('Erro', erro.error.Mensagem, 'error');
          this.spinner.hide();
        },
      });
    } else {
      Swal('Atenção', 'Por favor, preencha todos os campos corretamente.', 'warning');
      this.spinner.hide();
    }
  }

  aoAlterarData(evento, campo: string) {
    if (evento.target.value.length === 10) {
      const dataSplit = evento.target.value.split('-');
      this.formulario.get(campo).setValue(dataSplit[0]);
    } else {
      this.formulario.get(campo).setValue('');
    }
  }

  aoMudarTipo(evento) {
    const valorTipo = evento.target.value;
    switch (valorTipo) {
      case '1':
        this.formulario.get('numero').setValidators(Validators.required);
        this.formulario.get('cargo').setValue('');
        this.formulario.get('referencia').setValue('');

        this.formulario.get('cargo').clearValidators();
        this.formulario.get('cargo').updateValueAndValidity();
        this.formulario.get('referencia').clearValidators();
        this.formulario.get('referencia').updateValueAndValidity();

        this.buscarProjetos();
        break;
      case '2':
        this.formulario.get('numero').setValue('');

        this.formulario.get('numero').clearValidators();
        this.formulario.get('numero').updateValueAndValidity();

        this.formulario.get('cargo').setValidators(Validators.required);
        this.formulario.get('referencia').setValidators(Validators.required);
        this.formulario.updateValueAndValidity();
        break;
      default:
        break;
    }
  }

  limparFormulario() {
    this.formulario.setValue({
      dataInicio: '',
      dataFim: '',
      tipo: '',
      numero: '',
      cargo: '',
      referencia: '',
      motivo: '',
      numeroProcesso: '',
      numeroDoe: '',
      idClassificacaoAto: '',
      numeroAto: '',
      anoAto: '',
      dtAto: '',
      anoDoe: '',
      dtDoe: '',
      seq: '',
    });

    this.formulario.markAsUntouched();
  }

  montarComissao(): Comissao {
    return {
      Sigla: 'FAAP',
      Numord: this.numOrd,
      Empfil: this.filialAtual,
      DataInicio: {
        SetData: this.formulario.get('dataInicio').value.replace(/-/g, ''),
        ToDate: this.comissaoService.formatarDataBarra(this.formulario.get('dataInicio').value),
        Empty: false,
        IsDate: true,
      },
      DataFim: {
        SetData: this.formulario.get('dataFim').value.replace(/-/g, ''),
        ToDate: this.comissaoService.formatarDataBarra(this.formulario.get('dataFim').value),
        Empty: false,
        IsDate: true,
      },
      Tipo: this.formulario.get('tipo').value,
      Numero: this.formulario.get('numero').value ? this.formulario.get('numero').value : 0,
      Cargo: this.formulario.get('cargo').value ? this.formulario.get('cargo').value : '',
      Refer: this.formulario.get('referencia').value ? this.formulario.get('referencia').value : '',
      Motivo: this.formulario.get('motivo').value,
      NumeroProcesso: this.formulario.get('numeroProcesso').value,
      NumeroDoe: this.formulario.get('numeroDoe').value,
      IdClassificacaoAto: this.formulario.get('idClassificacaoAto').value,
      NumeroAto: this.formulario.get('numeroAto').value,
      AnoAto: this.formulario.get('anoAto').value,
      DtAto: {
        SetData: this.formulario.get('dtAto').value.replace(/-/g, ''),
        ToDate: this.comissaoService.formatarDataBarra(this.formulario.get('dtAto').value),
        IsDate: true,
        Empty: false,
      },
      AnoDoe: this.formulario.get('anoDoe').value,
      DtDoe: {
        SetData: this.formulario.get('dtDoe').value.replace(/-/g, ''),
        ToDate: this.comissaoService.formatarDataBarra(this.formulario.get('dtDoe').value),
        IsDate: true,
        Empty: false,
      },
      DtComp: '',
      TpProc: 'EM',
      Flag: this.cadastro ? 'I' : 'A',
      Seq: this.formulario.get('seq').value ? this.formulario.get('seq').value : '',
    };
  }

  preencherFormulario(comissao: Comissao) {
    this.formulario.get('referencia').enable();
    this.formulario.setValue({
      dataInicio: this.comissaoService.formatarDataHifen(comissao.DataInicio.SetData),
      dataFim: this.comissaoService.formatarDataHifen(comissao.DataFim.SetData),
      tipo: String(comissao.Tipo),
      numero: comissao.Numero,
      cargo: comissao.Cargo,
      referencia: comissao.Cargo,
      motivo: comissao.Motivo,
      numeroProcesso: comissao.NumeroProcesso,
      numeroDoe: comissao.NumeroDoe,
      idClassificacaoAto: comissao.IdClassificacaoAto,
      numeroAto: comissao.NumeroAto,
      anoAto: comissao.AnoAto,
      dtAto: this.comissaoService.formatarDataHifen(comissao.DtAto.SetData),
      dtDoe: this.comissaoService.formatarDataHifen(comissao.DtDoe.SetData),
      anoDoe: comissao.AnoDoe,
      seq: comissao.Seq,
    });
    if (this.formulario.get('tipo').value !== '2') {
      this.formulario.get('referencia').disable();
      this.buscarProjetos().then(() => {
        this.formulario.get('numero').setValue(comissao.Numero);
      });
    } else {
      this.buscarReferencias().then(() => {
        this.formulario.get('referencia').setValue(comissao.Refer);
      });
    }
  }

  // verificaData(dataInicial: AbstractControl, dataFinal: AbstractControl) {
  //   if (dataInicial.value && dataFinal.value) {
  //     const dataInicialDate = new Date(dataInicial.value);
  //     const dataFinalDate = new Date(dataFinal.value);

  //     if (dataInicialDate >= dataFinalDate) {
  //       this.erroData = true;
  //     } else {
  //       this.erroData = false;
  //     }
  //   }
  // }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}
