import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RescisaoService } from "./rescisao.service";
import { ErroService } from '../../service/erros/erro.service';
import { UntypedFormBuilder, Validators, NgForm, UntypedFormGroup } from '@angular/forms';
import { Data } from '../../service/interfaces/Data';
//import { Rescisao } from './interfaces/rescisao';
import Swal from 'sweetalert';
import { NgxSpinnerService } from "ngx-spinner"
import { Previa } from './interfaces/previa';
import { Calculo } from './interfaces/calculo';
import { PreviaRetorno } from './interfaces/previaRetorno';
import { RescisaoRelatorio } from './interfaces/rescisaoRelatorio';
import { Stiuacao } from 'src/app/service/classes/cadastro/stiuacao';
import { runInThisContext } from 'vm';
import { sequence } from '@angular/animations';
import { Rescisao } from './interfaces/rescisao';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';

declare let $: any;

@Component({
  selector: 'app-rescisao',
  templateUrl: './rescisao.component.html',
  styleUrls: ['./rescisao.component.css']
})
export class RescisaoComponent implements OnInit {

  localStorage: LocalStorage;
  
  rescisaoFormGroup: UntypedFormGroup;

  verbasVantagem: any[] = [];
  verbasDesconto: any[] = [];
  verbasOutros: any[] = [];
  tpAvisoPrevio: any[] = [];
  tpRescisao: any[] = [];
  listaRescisao: any[] = []
  
  rescisao: Rescisao;
  rescisaoModal: Rescisao;
  rescisaoRelatorio: RescisaoRelatorio = new RescisaoRelatorio();
  colaborador: any = { InformacoesBasicas: { NomeCompleto: "" }, Documentos: { Cpf: "" } };
  tipoDeProcessamento: string = "RS";
  radioAvisoPrevio: any;
  TipoRescisao: string = "0";
  empresaFilial: string;
  TipoAvisoPrevio: string = "0";
  VisaoPrevia: string = "0";
  matricula: string
  competenciaRelatorio: string;
  totalVantagens: number = 0;
  totalDescontos: number = 0;
  totalRemuneracao: number = 0;
  totalLiquido: number = 0;
  totalFgts: number = 0;
  matriculaRelatorio: number;
  matriculaLSTN: number = null;

  existeAviso: boolean = false;
  temErro: boolean = false;
  comprovou: boolean = false;
  carrega_rescisao: boolean = false;//spiner buscar CPF
  ComprovouNovoEmprego: boolean = false;
  DispensarIndenizacao: boolean = false;
  isCalculada: boolean = false;
  disabledER: boolean = false;

  constructor(private fb: UntypedFormBuilder, private rescisaoService: RescisaoService, private validaErro: ErroService, private myRoute: Router,private spinner: NgxSpinnerService) {
    this.localStorage = this.decryptLocalStorage();
    this.empresaFilial = String(this.localStorage.FilialAtual)
  }

  ngOnInit() {
    this.buscarAvPrevioTpResc();

    this.rescisaoFormGroup = this.fb.group(
      {
        Matricula: ['', Validators.required],
        Nome: ['', Validators.required],
        Numord: [''],
        EmpresaFilial: [''],
        TipoRescisao: ['', Validators.required],
        CodigoAvisoPrevio: [''],
        DataInicioAvisoPrevio: ['', Validators.compose([Validators.required, ])],
        DataFinalAvisoPrevio: [''],
        DataHomologacao: ['', Validators.compose([Validators.required, ])],
        DataPagamento: ['', Validators.compose([Validators.required, ])],
        DataRescisao: ['', Validators.compose([Validators.required, ])],
        QuantidadeDiasAvisoPrevio: [''],
        TipoAvisoPrevio: [''],
        ComprovouNovoEmprego: [''],
        DataInterrupcaoAvisoPrevio: [''],
        dispensarIndenizacao: [''],
        NumeroObito: [''],
        VisaoPrevia: ['0']
      }
    );
  }
  baixarRelatorio(competencia: any) {
    Swal({
      title: "Rescisão deste colaborador foi realizada. Deseja Baixar Relatório?",
      text: "Confirme para baixar Relatório.",
      icon: "info",
      dangerMode: false,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true
        },
        confirm: {
          text: "Ok",
          value: true,
          visible: true
        }
      }
    })
      .then(willDelete => {
        if (willDelete) {
          this.obterRelatorio(competencia, 2);
        }

      });
  }

  cancelar(rescisao: any) {
    if (rescisao.Tipo == 1) {
      Swal({
        title: "Colaborador será reativado, passando a fazer parte novamente da folha de pagamento. Deseja prosseguir?",
        text: "Confirme para reativar colaborador.",
        icon: "info",
        dangerMode: false,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true
          },
          confirm: {
            text: "Confirmar",
            value: true,
            visible: true
          }
        }
      })
        .then(willDelete => {
          if (willDelete) {
            this.cancelarRecisao(rescisao.Sequencial, rescisao.Competencia);
          }
        });
    } else {
      Swal({
        title: "Deseja Cancelar a rescisão ?",
        text: "Confirme para cancelar.",
        icon: "info",
        dangerMode: false,
        buttons: {
          cancel: {
            text: "Voltar",
            value: false,
            visible: true
          },
          confirm: {
            text: "Confirmar",
            value: true,
            visible: true
          }
        }
      })
        .then(willDelete => {
          if (willDelete) {
            this.cancelarRecisao(rescisao.Sequencial, rescisao.Competencia);
          }
        });
    }
  }

  reabrirRescisao(rescisao: any) {
    Swal({
      title: "Deseja Reabrir a rescisão?",
      text: "Confirme para reabrir.",
      icon: "info",
      dangerMode: false,
      buttons: {
        cancel: {
          text: "Voltar",
          value: false,
          visible: true
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true
        }
      }
    })
      .then(willDelete => {
        if (willDelete) {
          this.rescisaoService.alteraSituacaoRescisaoCalculada(rescisao.Sequencial, rescisao.Competencia, rescisao.NumeroOrdem).subscribe(
            (data: any) => {
              this.buscarColaborador(this.matriculaLSTN);
              document.getElementById('closeModalbt').click();
              new AlertaModal('sucesso',"Sucesso","Rescisão reaberta com Sucesso.",'p')
              //Swal("Sucesso", "Rescisão reaberta com Sucesso.", "success").then(retorno => { if (retorno) this.buscarColaborador(); });
            }, error => {
              new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
              this.spinner.hide();
            }
          )
        }
      });
  }

  buscarRescisao(matricula: any) {
    this.rescisaoService.buscarRescisao(matricula).subscribe(
      (data: any) => {
        this.listaRescisao = data;

      }, error => {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        this.spinner.hide();
      }
    )
  }

  onSubmit(form) {
    if (!this.validarCampos()) {
      return false;
    };

    const rescisaoFinal: Rescisao = this.montarRescisao();
    var listaSequencial = [];
    this.listaRescisao.forEach(function (item) {
      listaSequencial.push(item.Sequencial)
    });

    var sequencialCorrente = Math.max(...listaSequencial); // ultimo codigo de afastamento inserido.

    var listaCodigos = [];
    this.listaRescisao.forEach(function (item) {
      listaCodigos.push(item.CodigoAfastamento)
    });

    Swal({
      title: 'Atenção ',
      text: "Deseja alterar o Lançamento Financeiro do Colaborador?",
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: false,
      buttons: ['Não', 'Sim'],
    }).then((result) => {
      if (result) {
        if (rescisaoFinal.CodigoAfastamento == sequencialCorrente.toString() || listaCodigos.indexOf(rescisaoFinal.CodigoAfastamento) < 0) {
          this.spinner.show();

          this.rescisaoService.salvarRescisao(rescisaoFinal, this.obterCompetencia(rescisaoFinal.DataRescisao.SetData, 1), this.tipoDeProcessamento)
            .subscribe(
              data => {
                if (data) {
                  this.spinner.hide();
                  rescisaoFinal.Sequencial = String(data);
                  //this.calcularRescisao(rescisaoFinal);
                  this.limparCampos()

                  this.temErro = false
                }
              },
              error => {
                new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
                this.spinner.hide();
              }
            );
        } else {
          new AlertaModal('alerta',"Atenção", "O Tipo de rescisão deve ser o mesmo de" + sequencialCorrente.toString() + "ou diferente de um já utilizado.",'p')
        }
      } else {
        if (rescisaoFinal.CodigoAfastamento == sequencialCorrente.toString() || listaCodigos.indexOf(rescisaoFinal.CodigoAfastamento) < 0) {
          this.spinner.show();

          this.rescisaoService.salvarRescisao(rescisaoFinal, this.obterCompetencia(rescisaoFinal.DataRescisao.SetData, 1), this.tipoDeProcessamento)
            .subscribe(
              data => {
                if (data) {
                  this.spinner.hide();
                  rescisaoFinal.Sequencial = String(data);
                  this.calcularRescisao(rescisaoFinal);
                  this.limparCampos()

                  this.temErro = false
                }
              },
              error => {
                new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
                this.spinner.hide();
              }
            );
        } else {
          new AlertaModal('alerta',"Atenção", "O Tipo de rescisão deve ser o mesmo de" + sequencialCorrente.toString() + "ou diferente de um já utilizado.",'p')
        }
      }
    })
  }

  cancelarRecisao(sequencial: string, competencia: string) {
    let tipoProcessamento = this.tipoDeProcessamento
    this.rescisaoService.cancelarRescisao(sequencial, this.matricula, competencia, tipoProcessamento).subscribe(data => {
      this.buscarColaborador(this.matriculaLSTN);
      document.getElementById('closeModalbt').click();
      new AlertaModal('sucesso',"Sucesso","Rescisão cancelada com Sucesso.",'p')
    }, error => {
      new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
      this.spinner.hide();
    })
  }

  limparCampos() {  
    this.rescisaoFormGroup.controls['TipoRescisao'].setValue("")
    this.rescisaoFormGroup.controls['CodigoAvisoPrevio'].setValue("")
    this.rescisaoFormGroup.controls['VisaoPrevia'].setValue("0")
    this.existeAviso = false
    this.rescisaoFormGroup.markAsPristine()
  }
  
  limparTodosCampos(){
    this.rescisaoFormGroup.controls['TipoRescisao'].setValue("")
    this.rescisaoFormGroup.controls['CodigoAvisoPrevio'].setValue("")
    this.rescisaoFormGroup.controls['VisaoPrevia'].setValue("")
    this.rescisaoFormGroup.controls['DataInicioAvisoPrevio'].setValue("")
    this.rescisaoFormGroup.controls['DataFinalAvisoPrevio'].setValue("")
    this.rescisaoFormGroup.controls['DataHomologacao'].setValue("")
    this.rescisaoFormGroup.controls['DataRescisao'].setValue("")
    this.rescisaoFormGroup.controls['ComprovouNovoEmprego'].setValue("")
    this.rescisaoFormGroup.controls['DataInterrupcaoAvisoPrevio'].setValue("")
    this.rescisaoFormGroup.controls['DataPagamento'].setValue("")
  }

  addDezDias(data): void {
    const dataRescisao = this.rescisaoFormGroup.get("DataRescisao").value;
    const dataFormatada = new Date(dataRescisao);
      
    dataFormatada.setDate(dataFormatada.getDate() + 11);
    let dia = dataFormatada.toLocaleDateString().substring(0,2)
    let mes = dataFormatada.toLocaleDateString().substring(3,5)
    let ano = dataFormatada.toLocaleDateString().substring(6,10)
    const dataAux = `${ano}-${mes}-${dia}`

    if (this.rescisaoFormGroup.get("DataRescisao").invalid) {
      this.rescisaoFormGroup.controls['DataHomologacao'].setValue("");
      this.rescisaoFormGroup.controls['DataPagamento'].setValue("");
    }
    else { 
      this.rescisaoFormGroup.get('DataHomologacao').setValue(dataAux);
      this.rescisaoFormGroup.get('DataPagamento').setValue(dataAux);
    }
  }

  calcularRescisao(rescisao: Rescisao) {
    this.spinner.show();
    let calculoRescisao = new Calculo();
    let competencia = this.obterCompetencia(rescisao.DataRescisao.SetData, 1);
    calculoRescisao.Matricula = Number(rescisao.Matricula);
    calculoRescisao.Tipo = 0;
    calculoRescisao.DataInicial.SetData = rescisao.DataInicioAvisoPrevio.SetData;
    calculoRescisao.DataFinal.SetData = rescisao.DataRescisao.SetData;
    calculoRescisao.Sequencial = rescisao.Sequencial

    this.rescisaoService.calcularRescisao(calculoRescisao, competencia, this.tipoDeProcessamento)
      .subscribe(
        data => {
          if (data) {
            this.buscarColaborador(this.matriculaLSTN);
            document.getElementById('closeModalbt').click();
            this.spinner.hide();
            this.rescisaoRelatorio = new RescisaoRelatorio();
            this.rescisaoRelatorio.Matricula = calculoRescisao.Matricula;
            this.rescisaoRelatorio.CodigoRescisao = Number(rescisao.CodigoAfastamento);
            this.rescisaoRelatorio.Tipo = 0;
            this.rescisaoRelatorio.Verbas = [];
            this.baixarRelatorio(competencia);

            //  this.obterRelatorioRescisao(rescisao.Matricula, competencia);
          }
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  obterRelatorio(competencia: any, tipo: number) {
    this.competenciaRelatorio = this.obterCompetencia(this.rescisaoFormGroup.value.DataRescisao, 2);
    this.rescisaoRelatorio = new RescisaoRelatorio();
    this.rescisaoRelatorio.Matricula = this.matriculaRelatorio;
    this.rescisaoRelatorio.CodigoRescisao = 1;
    this.rescisaoRelatorio.Tipo = 0;
    this.rescisaoRelatorio.Verbas = [];
    if (tipo === 2) {
      this.obterRelatorioRescisao(this.matriculaRelatorio.toString(), competencia);
    } else {
      this.obterRelatorioRescisao(this.matriculaRelatorio.toString(), this.competenciaRelatorio);
    }
  }

  obterRelatorioPrevia() {
    this.obterRelatorioRescisao(this.matriculaRelatorio.toString(), this.competenciaRelatorio);
  }

  obterRelatorioRescisao(matricula: string, competencia: string) {
    this.spinner.show();
    this.rescisaoService.obterRelatorio(matricula, competencia, this.rescisaoRelatorio)
      .subscribe(
        data => {
          if (data) {

            this.spinner.hide();
            this.temErro = false;
            let pdf: any = data;
            const linkSource = `data:application/pdf;base64,${pdf.RelatorioRescisao}`;
            const downloadLink = document.createElement("a");
            const fileName = "Relatório de Rescisão do Contrato de Trabalho.pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            Swal({
              title: 'Rescisão concluída com sucesso.',
              text: "",
              icon: 'success',
              closeOnClickOutside: false,
              closeOnEsc: false
            }).then((result) => {
              if (result) {
                //this.myRoute.navigate(["home"]);
              }
            })
          }
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  obterCompetencia(data: string, tipo: number): string {

    if (data == null) { data = "00000000" }

    if (tipo == 1 && data.includes('/')) {
      return data.substr(6, 4) + data.substr(3, 2);
    } else if (!data.includes('/')) {
      return data.substr(0, 4) + data.substr(4, 2);
    } else {
      return data.substr(4, 4) + data.substr(2, 2);
    }
  }

  montarPreviaRescisao(rescisao: Rescisao): Previa {
    let previa = new Previa();
    previa.Matricula = Number(rescisao.Matricula);
    previa.CodigoAfastamento = Number(rescisao.CodigoAfastamento);
    previa.VisaoPrevia = Number(this.rescisaoFormGroup.value.VisaoPrevia);
    previa.TipoAvisoPrevio = rescisao.TipoAvisoPrevio;
    previa.CodigoAvisoPrevio = rescisao.CodigoAvisoPrevio;
    previa.DataInicioAvisoPrevio = rescisao.DataInicioAvisoPrevio;
    previa.QuantidadeDiasAvisoPrevio = rescisao.QuantidadeDiasAvisoPrevio;
    previa.DataRescisao = rescisao.DataRescisao;
    return previa;
  }

  obterPrevia() {
    this.spinner.show();
    if (!this.validarCampos()) {
      this.spinner.hide();
      return false;
    };

    const rescisaoFinal = this.montarRescisao();
    const previaRescisao = this.montarPreviaRescisao(rescisaoFinal);
    let competencia = this.obterCompetencia(previaRescisao.DataRescisao.SetData, 1);
    this.matriculaRelatorio = previaRescisao.Matricula;
    this.competenciaRelatorio = competencia;
    this.rescisaoService.previaRescisao(previaRescisao, competencia)
      .subscribe(
        data => {
          if (data) {

            this.temErro = false;
            this.spinner.hide();
            let previaRetorno: any = data;
            this.totalVantagens = previaRetorno.TotalVantagem;
            this.totalDescontos = previaRetorno.TotalDesconto;
            this.totalFgts = previaRetorno.TotalFgts;
            this.totalLiquido = previaRetorno.TotalLiquido;
            this.totalRemuneracao = previaRetorno.TotalRemuneracao;
            this.verbasVantagem = previaRetorno.Vantagens;
            this.verbasDesconto = previaRetorno.Descontos;
            this.verbasOutros = previaRetorno.Outros;

            this.rescisaoRelatorio = new RescisaoRelatorio();
            this.rescisaoRelatorio.Matricula = previaRescisao.Matricula;
            this.rescisaoRelatorio.CodigoRescisao = previaRescisao.CodigoAfastamento;
            this.rescisaoRelatorio.Tipo = 1;
            this.rescisaoRelatorio.Verbas = previaRetorno.Verbas;
            $('#modal-previa-rescisao').modal('show');
          }
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        }
      );
  }

  validarCampos(): boolean {
    this.validarMotivoAviso()
    if (this.rescisaoFormGroup.value.TipoRescisao == "80") {//limpar tudo do aviso previo
      this.rescisaoFormGroup.controls['DataInicioAvisoPrevio'].setValue("")
      this.rescisaoFormGroup.controls['ComprovouNovoEmprego'].setValue("")
      this.rescisaoFormGroup.controls['DataInterrupcaoAvisoPrevio'].setValue("")
      this.rescisaoFormGroup.controls['TipoAvisoPrevio'].setValue("")
      this.rescisaoFormGroup.controls['CodigoAvisoPrevio'].setValue("")

    }
    if (this.rescisaoFormGroup.invalid) {
      new AlertaModal('alerta',"Atenção", "Preencha todos os campos corretamente",'p')
      this.temErro = true;
      return false;
    }

    if (this.rescisaoFormGroup.value.TipoRescisao != "35") { //esses campos são apenas se for demissao sem justa causa
      this.rescisaoFormGroup.controls['ComprovouNovoEmprego'].setValue("")
      this.rescisaoFormGroup.controls['DataInterrupcaoAvisoPrevio'].setValue("")
    }

    if (this.rescisaoFormGroup.value.TipoRescisao !== '20' || this.rescisaoFormGroup.value.TipoAvisoPrevio !== 1) {
      this.rescisaoFormGroup.controls['DataInterrupcaoAvisoPrevio'].setValue("0")
    }

    if ((this.rescisaoFormGroup.value.TipoAvisoPrevio == 2 && this.rescisaoFormGroup.value.TipoRescisao == "35" && this.rescisaoFormGroup.value.ComprovouNovoEmprego == 1) && this.rescisaoFormGroup.value.DataInterrupcaoAvisoPrevio == "") {

      scrollTo(0, 0)
      new AlertaModal('alerta',"Atenção", "Preencha o campo Data de interrupção",'p')
      return false;
    }

    return true;
  }

  formataData(data: string): string {
    if (data == null) { data = "00/00/0000" }
    data = data.split("/").join("");
    data = `${data.substr(0, 2)}/${data.substr(2, 2)}/${data.substr(4)}`;

    return data;
  }

  buscarAvPrevioTpResc() {
    this.rescisaoService.buscarAvPrevioTpResc()
      .subscribe(
        data => {
          this.tpAvisoPrevio = data['TiposAvisoPrevio'];
          this.tpRescisao = data['TiposRescisao'];
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );

  }

  buscarColaborador(matricula) {
    this.rescisaoFormGroup.controls['Matricula'].setValue(matricula)
    // if (this.rescisaoFormGroup.controls['Matricula'].status == "INVALID") {
    //   Swal("Atenção", "Digite uma Matricula válida.", "warning");
    //   this.limparCampos(this.rescisaoFormGroup)
    //   // this.rescisaoFormGroup.controls['Matricula'].setValue("");
    //   return false;

    // }
    // this.spinner.show();
    this.carrega_rescisao = true;

    this.rescisaoService.buscarRescisao(matricula)
      .subscribe(
        (data: any) => {
          this.carrega_rescisao = false;
          this.matricula = data["Matricula"];
          this.matriculaRelatorio = Number(this.matricula);
          this.rescisaoFormGroup.controls['Nome'].setValue(data['Nome']);
          this.listaRescisao = data["Rescisoes"]
          this.temErro = false
          // this.buscarRescisao(data.Matricula)
          this.buscarCodMotivoAfastamento(this.listaRescisao.length + 1)

          this.buscarDiasAvPrevio();
        },
        error => {
          // this.spinner.hide()
          this.carrega_rescisao = false;
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.rescisaoFormGroup.controls['Matricula'].setValue("");
          this.limparCampos()
        }
      )
    this.limparAposMatricula();
  }

  validaCancelar(item: Rescisao) {
    var listaSequencial = [];
    this.listaRescisao.forEach(function (item) {
      listaSequencial.push(item.Sequencial)
    });

    var sequencialCorrente = Math.max(...listaSequencial); // ultimo codigo de afastamento inserido.

    if (item.Sequencial == sequencialCorrente.toString()) {
      return true
    } else {
      return false
    }
  }

  buscarDiasAvPrevio() {
    const matricula: string = this.matricula;

    this.rescisaoService.buscarDiasAvisPrevio(matricula)
      .subscribe(
        data => {
          let retorno: any = data;

          this.buscarCodMotivoAfastamento(this.listaRescisao.length + 1)
          if (retorno.CodigoMotivoAfastamento != 0 && retorno.Ativo == true) {
            this.rescisaoFormGroup.controls['TipoRescisao'].setValue(retorno.CodigoAfastamento)
            this.rescisaoFormGroup.controls['NumeroObito'].setValue(retorno.NumeroCertidao)
            this.rescisaoFormGroup.controls['DataHomologacao'].setValue(retorno.DataHomologacao.ToDate)
            this.rescisaoFormGroup.controls['DataPagamento'].setValue(retorno.DataPagamento.ToDate)
            this.rescisaoFormGroup.controls['DataRescisao'].setValue(retorno.DataRescisao.ToDate)
            this.rescisaoFormGroup.controls['DataInicioAvisoPrevio'].setValue(retorno.DataInicioAvisoPrevio.ToDate)
            this.rescisaoFormGroup.controls['ComprovouNovoEmprego'].setValue(retorno.ComprovouNovoEmprego)
            this.rescisaoFormGroup.controls['DataInterrupcaoAvisoPrevio'].setValue(retorno.DataInterrupcaoAvisoPrevio.ToDate)
            this.rescisaoFormGroup.controls['TipoAvisoPrevio'].setValue(retorno.TipoAvisoPrevio)
            this.rescisaoFormGroup.controls['CodigoAvisoPrevio'].setValue(retorno.MotivoAvisoPrevio)

            this.tipoDeAviso()
          }
          this.rescisaoFormGroup.controls['QuantidadeDiasAvisoPrevio'].setValue(retorno.QuantidadeDiasAvisoPrevio)

          this.spinner.hide()
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  montarRescisao(): Rescisao {
    const rescisao = this.rescisaoFormGroup.getRawValue();
    return {
      Cpf: rescisao.Cpf,
      Nome: rescisao.Nome,
      Matricula: this.matricula,
      CodigoAfastamento: rescisao.TipoRescisao,
      EmpresaFilial: this.empresaFilial,
      TipoAvisoPrevio: rescisao.TipoAvisoPrevio == "" || rescisao.TipoAvisoPrevio == null ? 0 : rescisao.TipoAvisoPrevio,
      CodigoAvisoPrevio: rescisao.CodigoAvisoPrevio == "" || rescisao.CodigoAvisoPrevio == null ? "0" : rescisao.CodigoAvisoPrevio,
      DataInicioAvisoPrevio: {
        SetData: rescisao.DataInicioAvisoPrevio != "" && rescisao.DataInicioAvisoPrevio != null ? this.formataData(this.formatarDataEnvio(rescisao.DataInicioAvisoPrevio)) : "00/00/0000",
      },
      QuantidadeDiasAvisoPrevio: rescisao.QuantidadeDiasAvisoPrevio,
      DataFinalAvisoPrevio: {
        SetData: rescisao.DataFinalAvisoPrevio != "" && rescisao.DataFinalAvisoPrevio != null ? this.formataData(this.formatarDataEnvio(rescisao.DataInicioAvisoPrevio)) : "00/00/0000"
      },
      DispensarIndenizacao: rescisao.dispensarIndenizacao != "" && rescisao.dispensarIndenizacao != null ? rescisao.dispensarIndenizacao : "0",
      ComprovouNovoEmprego: rescisao.ComprovouNovoEmprego != "" && rescisao.ComprovouNovoEmprego != null ? rescisao.ComprovouNovoEmprego : "0",
      Status: "1",
      DataHomologacao: {
        SetData: rescisao.DataHomologacao != "" && rescisao.DataHomologacao != null ? this.formataData(this.formatarDataEnvio(rescisao.DataHomologacao)) : "00/00/0000",
      },
      DataPagamento: {
        SetData: rescisao.DataPagamento != "" && rescisao.DataPagamento != null ? this.formataData(this.formatarDataEnvio(rescisao.DataPagamento)) : "00/00/0000",
      },
      DataRescisao: {
        SetData: rescisao.DataRescisao != "" && rescisao.DataRescisao != null ? this.formataData(this.formatarDataEnvio(rescisao.DataRescisao)) : "00/00/0000",
      },
      NumeroCertidao: rescisao.NumeroObito != null && rescisao.NumeroObito != "" ? rescisao.NumeroObito : "0",
      DataInterrupcaoAvisoPrevio: {
        SetData: rescisao.DataInterrupcaoAvisoPrevio != "" && rescisao.DataInterrupcaoAvisoPrevio != "0" ? this.formataData(this.formatarDataEnvio(rescisao.DataInterrupcaoAvisoPrevio)) : "00/00/0000",

      },
      MotivoAvisoPrevio: rescisao.CodigoAvisoPrevio == "" ? "0" : rescisao.CodigoAvisoPrevio,
      Sequencial: rescisao.Sequencial != "0" && rescisao.Sequencial != undefined && rescisao.Sequencial != null ? rescisao.Sequencial : "0",
      Tipo: rescisao.Tipo,
      Competencia: rescisao.Competencia,
      NumeroOrdem: rescisao.Numord
    }
  }

  calcularDtFimAvPrevio(event: any) {
    if (this.rescisaoFormGroup.value.DataInicioAvisoPrevio.length < 8) {
      return false;
    }
    const diasSomar = this.rescisaoFormGroup.value.QuantidadeDiasAvisoPrevio - 1;
    document.getElementById("qtdDias")["value"] = diasSomar
    const dataInicial = { "SetData": this.formataData(this.formatarDataEnvio(this.rescisaoFormGroup.value.DataInicioAvisoPrevio)) };
    this.rescisaoService.proximoDiaUtil(dataInicial)
      .subscribe(
        (data: Data) => {
          const dataFinal = this.calculaDataFinal(data, diasSomar);
          this.rescisaoFormGroup.controls['DataFinalAvisoPrevio'].setValue(dataFinal)
        },
        error => this.validaErro.retornoErro(error)
      );
  }

  calculaDataFinal(data: Data, diaSomar) {
    let dia, mes, ano;
    let diasASomar = diaSomar;
    let horario = 'T23:54:00' //forÃ§ar o dia escolhido

    dia = data.ToDate.substring(0, 2);
    mes = data.ToDate.substring(3, 5);
    ano = data.ToDate.substring(6);

    let time = new Date(ano + '-' + mes + '-' + dia + horario);
    time.setDate(time.getDate() + parseInt(diasASomar));

    mes = time.getMonth() + 1
    dia = time.getDate()
    ano = time.getFullYear()

    if (dia < 10) {
      dia = "0" + dia
    } if (mes < 10) {
      mes = "0" + mes
    }

    return ano + '-' + mes + '-' + dia ;
  }

  verificaData(data) {
    if (!this.validarData(data)) {
      this.rescisaoFormGroup.controls['DataInicioAvisoPrevio'].setValue("");
    }
  }

  // Verifica se data é valida
  validarData(data) {
    let reg = /[^\d\/\.]/gi;  // Mascara = dd/mm/aaaa | dd.mm.aaaa
    let valida = data.replace(reg, '');    // aplica mascara e valida só numeros
    if (valida && valida.length == 10) {  // é válida, então ;)
      let ano = data.substr(6),
        mes = data.substr(3, 2),
        dia = data.substr(0, 2),
        M30 = ['04', '06', '09', '11'],
        v_mes = /(0[1-9])|(1[0-2])/.test(mes),
        v_ano = /(19[1-9]\d)|(20\d\d)|2100/.test(ano),
        rexpr = new RegExp(mes),
        fev29 = ano % 4 ? 28 : 29;

      if (v_mes && v_ano) {
        if (mes == '02') return (dia >= 1 && dia <= fev29);
        else if (rexpr.test(M30.toString())) return /((0[1-9])|([1-2]\d)|30)/.test(dia);
        else return /((0[1-9])|([1-2]\d)|3[0-1])/.test(dia);
      }
    }
    return false;
  }

  verificaCpf(cpf): void {
    if (!this.validarCpf(cpf)) {
      this.rescisaoFormGroup.controls['Cpf'].setValue("");
    }
  }

  validarCpf(c: string): boolean {

    let i;

    if ((c = c.replace(/[^\d]/g, "")).length != 11)
      return false;

    if (c == "00000000000")
      return false;

    let r;
    let s = 0;

    for (i = 1; i <= 9; i++)
      s = s + parseInt(c[i - 1]) * (11 - i);

    r = (s * 10) % 11;

    if ((r == 10) || (r == 11))
      r = 0;

    if (r != parseInt(c[9]))
      return false;

    s = 0;

    for (i = 1; i <= 10; i++)
      s = s + parseInt(c[i - 1]) * (12 - i);

    r = (s * 10) % 11;

    if ((r == 10) || (r == 11))
      r = 0;

    if (r != parseInt(c[10]))
      return false;

    return true;
  }

  buscarCodMotivoAfastamento(tipo: number): void {
    switch (tipo) {
      case 1: {
        this.tipoDeProcessamento = "RS";
        break;
      }
      case 2: {
        this.tipoDeProcessamento = "R1";
        break;

      }
      case 3: {
        this.tipoDeProcessamento = "R2";
        break;
      }
      case 4: {
        this.tipoDeProcessamento = "R3";
        break;
      }
      default: {
        this.tipoDeProcessamento = "R4";
        break;
      }
    }
  }

  tipoDeAviso() {
    this.existeAviso = this.rescisaoFormGroup.value.TipoRescisao === '' ? false : this.tpRescisao.find(rescisao => rescisao.Codigo == this.rescisaoFormGroup.value.TipoRescisao).Status;
    this.mudarValidacaoData();
    this.limparcamposAviso();
  }

  mudarValidacaoData(): void {
    if (this.existeAviso) {
      this.rescisaoFormGroup.controls['DataInicioAvisoPrevio'].setValidators([Validators.required]);
      this.rescisaoFormGroup.controls["DataInicioAvisoPrevio"].updateValueAndValidity();
    } else {
      this.rescisaoFormGroup.controls['DataInicioAvisoPrevio'].setValidators(null);
      this.rescisaoFormGroup.controls["DataInicioAvisoPrevio"].updateValueAndValidity();

    }
  }

  validarMotivoAviso() {
    if (this.rescisaoFormGroup.value.TipoAvisoPrevio == "2") {
      this.rescisaoFormGroup.controls['CodigoAvisoPrevio'].setValidators(Validators.required);
      this.rescisaoFormGroup.controls["CodigoAvisoPrevio"].updateValueAndValidity();
    } else {
      this.rescisaoFormGroup.controls['CodigoAvisoPrevio'].setValidators(null);
      this.rescisaoFormGroup.controls['CodigoAvisoPrevio'].setValue("")
      this.rescisaoFormGroup.controls["CodigoAvisoPrevio"].updateValueAndValidity();

    }
  }

  limparcamposAviso() {
    if (this.rescisaoFormGroup.value.TipoAvisoPrevio == "0") {
      this.rescisaoFormGroup.get('dispensarIndenizacao').setValue('')
      this.rescisaoFormGroup.get('DataInicioAvisoPrevio').setValue('')
      this.rescisaoFormGroup.get('QuantidadeDiasAvisoPrevio').setValue('')
      this.rescisaoFormGroup.get('DataFinalAvisoPrevio').setValue('')
      this.rescisaoFormGroup.get('ComprovouNovoEmprego').setValue('')
      this.rescisaoFormGroup.get('DataInterrupcaoAvisoPrevio').setValue('')
    }
  }

  calculaRescisaoDefinitiva() {
    this.calcularRescisao(this.rescisaoModal);
  }

  cancelarRescisaoModal() {
    if (new Date(Number(this.rescisaoModal.DataHomologacao.SetData.substr(0, 4)),
      Number(this.rescisaoModal.DataHomologacao.SetData.substr(4, 2)) - 1, Number(this.rescisaoModal.DataHomologacao.SetData.substr(6, 2))).getTime().valueOf() > new Date().getTime().valueOf()) {
      this.cancelar(this.rescisaoModal);
    } else {
      new AlertaModal('alerta',"Atenção", "Rescisão não pode ser Cancelada, Data de Homologação foi ultrapassada.",'p')
    }
  }

  reabrirRescisaoModal() {
    if (new Date(Number(this.rescisaoModal.DataHomologacao.SetData.substr(0, 4)),
      Number(this.rescisaoModal.DataHomologacao.SetData.substr(4, 2)) - 1, Number(this.rescisaoModal.DataHomologacao.SetData.substr(6, 2))).getTime().valueOf() > new Date().getTime().valueOf()) {
      this.reabrirRescisao(this.rescisaoModal);
    } else {
      new AlertaModal('alerta',"Atenção", "Rescisão não pode ser Reaberta, Data de Homologação foi ultrapassada.",'p')
    }
  }

  ativarLimparCampos(): boolean{
    if(this.rescisaoFormGroup.get('DataHomologacao').value != null
    || this.rescisaoFormGroup.get('DataPagamento').value  != null
    || this.rescisaoFormGroup.get('DataRescisao').value != null
    ) {
      return true
    }

    return false
  }

  verificaRescisaoClicada(rescisao: any) {
    this.isCalculada = rescisao.Calculada;
    this.rescisaoModal = new Rescisao();
    this.rescisaoModal.Sequencial = rescisao.Sequencial;
    this.rescisaoModal.Matricula = this.matricula;
    this.rescisaoModal.CodigoAfastamento = rescisao.Codigo;
    this.rescisaoModal.DataRescisao = rescisao.Data;
    this.rescisaoModal.DataInicioAvisoPrevio = rescisao.DataAvisoPrevio;
    this.rescisaoModal.DataHomologacao = rescisao.DataHomologacao;
    this.rescisaoModal.Tipo = rescisao.Tipo;
    this.rescisaoModal.Competencia = rescisao.Competencia;
    this.rescisaoModal.NumeroOrdem = rescisao.Numord;
  }

  limparAposMatricula() {
    this.existeAviso = false;
    this.rescisaoFormGroup.reset({
      Matricula: this.rescisaoFormGroup.get('Matricula').value,
      Nome: this.rescisaoFormGroup.get('Nome').value
    });
    this.rescisaoFormGroup.get('TipoRescisao').setValue('');
    this.rescisaoFormGroup.get('VisaoPrevia').setValue('0');
    this.rescisaoFormGroup.get('CodigoAvisoPrevio').setValue('');
  }

  matriculaListener(evento){ 
    this.matriculaLSTN = evento.Matricula;
    this.buscarColaborador(this.matriculaLSTN);
    this.rescisaoFormGroup.get('Matricula').setValue(this.matriculaLSTN);
    this.rescisaoFormGroup.get('Matricula').disable();
  }

  retornarTelaInicial(){
    this.matricula = null;
    this.limparCampos()
    this.rescisaoFormGroup.reset()
    this.listaRescisao = []
  }
  formatarDataEnvio(data: string): string {
    let dataSplit = data.split("-");
    const dataFormatada = `${dataSplit[2]}${dataSplit[1]}${dataSplit[0]}`;
    return dataFormatada;
  }

  formatarData(data: string): string {
    let dataSplit = data.split("/");
    const dataFormatada = `${dataSplit[2]}-${dataSplit[1]}-${dataSplit[0]}`;
    return dataFormatada;
  }

  onChange(target : any) {
    if(target instanceof EventTarget) {
      var elemento = target as HTMLInputElement;
      return elemento.checked;
    }
  }
  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }
}
