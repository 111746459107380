import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { CadastroWhatsappService } from './cadastro-whatsapp.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import Swal from 'sweetalert';

@Component({
  selector: 'app-cadastro-whatsapp',
  templateUrl: './cadastro-whatsapp.component.html',
  styleUrls: ['./cadastro-whatsapp.component.css'],
})
export class CadastroWhatsappComponent {
  matricula: number = null;
  formColaborador: FormGroup;
  formCadastroWhatsapp: FormGroup;

  loading: boolean = false;
  carregandoCadastroWhastapp: boolean = false;

  colaborador: Cadastro;

  localStorage: LocalStorage;
  empfil: string;
  formTelaInicial: FormGroup;

  NumMovel: string;
  email: string;
  NumOrd: number;
  EmailValidado: string;
  NumMovelValidado: string;

  NumMovelPlaceholder: string = '';
  emailPlaceholder: string = '';

  constructor(
    private spinner: NgxSpinnerService,
    private cadastrowhatsapp: CadastroWhatsappService,
    private fb: FormBuilder
  ) {
    this.localStorage = this.decryptLocalStorage();
    this.empfil = this.localStorage.FilialAtual.toString();

    // Inicializa o formColaborador
    this.formColaborador = this.fb.group({
      matricula: [''],
      nome: [''],
    });

    // Inicializa o formCadastroWhatsapp com prefixo e validações
    this.formCadastroWhatsapp = this.fb.group({
      prefix: ['+55', [Validators.required]],
      NumMovel: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    });

    // Inicializa o formTelaInicial
    this.formTelaInicial = this.fb.group({
      matricula: ['', Validators.required],
      nome: [''],
    });
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }

  matriculaListener(evento) {
    this.matricula = evento.Matricula;
    this.buscarColaboradorMatricula(this.matricula);
  }

  buscarColaboradorMatricula(matricula) {
    this.spinner.show('premio');
    this.cadastrowhatsapp.buscarColaboradorMatricula(matricula).subscribe(
      (data: Cadastro) => {
        console.log(data);
        this.setarFormColaborador(data);
        this.colaborador = data;
        this.spinner.hide('premio');

        if (this.colaborador && this.colaborador.NumeroDeOrdem) {
          this.buscarCadastroWhatsapp();
        } else {
          console.error('Número de Ordem não encontrado no colaborador.');
          Swal('Erro!', 'Número de Ordem não encontrado.', 'error');
        }
      },
      (error) => {
        new AlertaModal('erro', 'Erro!', error.error.Mensagem, 'p');
        this.retornarTelaInicial();
        this.spinner.hide('premio');
      }
    );
  }

  setarFormColaborador(data) {
    this.formColaborador.get('matricula').disable();
    this.formColaborador.get('matricula').setValue(this.matricula);
    this.formColaborador.get('nome').disable();
    this.formColaborador.get('nome').setValue(data.InformacoesBasicas.NomeCompleto);
    this.loading = false;
  }

  retornarTelaInicial() {
    this.matricula = null;
    this.formColaborador.reset();
    this.formColaborador.markAsPristine();
  }

  buscarCadastroWhatsapp() {
    this.carregandoCadastroWhastapp = true;
    this.cadastrowhatsapp.buscarCadastroWhatsapp().subscribe(
      (data: any[]) => {
        let encontrado = false;

        data.forEach((cadastro: any) => {
          if (String(cadastro.NumOrd).trim() === String(this.colaborador.NumeroDeOrdem).trim()) {
            encontrado = true;
            this.NumMovelPlaceholder = this.formatarNumero(cadastro.NumMovel);
            this.emailPlaceholder = cadastro.Email;
            this.NumMovelValidado = cadastro.NumMovelValidado;
            this.EmailValidado = cadastro.EmailValidado;
          }
        });

        this.carregandoCadastroWhastapp = false;
      },
      (error) => {
        Swal('Erro!', error.error.Mensagem, 'error');
        this.carregandoCadastroWhastapp = false;
      }
    );
  }

  lancarCadastroWhatsapp() {
    this.formCadastroWhatsapp.markAllAsTouched();
    const cadastroDados = this.formCadastroWhatsapp.value;
    cadastroDados.NumOrd = this.colaborador.NumeroDeOrdem;

    // Remover o sinal de "+" do prefixo e concatenar com o número
    const prefix = cadastroDados.prefix.replace('+', '');
    const numeroCompleto = prefix + cadastroDados.NumMovel;

    // Atribuir o número completo ao objeto de dados
    cadastroDados.NumMovel = numeroCompleto;

    // Remover o campo prefix do objeto cadastroDados
    delete cadastroDados.prefix;

    this.cadastrowhatsapp.buscarCadastroWhatsapp().subscribe(
      (data: any[]) => {
        let dadosExistentes = false;
        let cadastroParaAtualizar: any = null;

        // Verifica se existe um cadastro com o mesmo número de ordem (NumOrd)
        data.forEach((cadastro: any) => {
          if (String(cadastro.NumOrd).trim() === String(cadastroDados.NumOrd).trim()) {
            dadosExistentes = true;
            cadastroParaAtualizar = cadastro;
          }
        });

        console.log('Dados do formulário capturados:', cadastroDados);

        if (dadosExistentes) {
          Swal({
            title: 'Os dados já existem',
            text: 'Deseja atualizar os dados existentes?',
            icon: 'warning',
            buttons: ['Cancelar', 'Confirmar'],
          }).then((result) => {
            if (result) {
              this.spinner.show();

              cadastroDados.EmailValidado = 'N';
              cadastroDados.NumMovelValidado = 'N';

              this.atualizarCadastroWhatsapp(cadastroDados);
            } else {
              Swal({
                title: 'Cancelado',
                text: 'A atualização foi cancelada.',
                icon: 'info',
              });
            }
          });
        } else {
          Swal({
            title: 'Cadastro',
            text: 'Deseja cadastrar os dados fornecidos?',
            icon: 'warning',
            buttons: ['Cancelar', 'Confirmar'],
          }).then((result) => {
            if (result) {
              this.spinner.show();

              cadastroDados.EmailValidado = 'N';
              cadastroDados.NumMovelValidado = 'N';

              this.cadastrarCadastroWhatsapp(cadastroDados);
            } else {
              Swal({
                title: 'Cancelado',
                text: 'O cadastro foi cancelado.',
                icon: 'info',
              });
            }
          });
        }

        if (cadastroDados.NumMovel == '' || cadastroDados.email == '') {
          Swal('Atenção', 'Por favor, preencha todos os campos corretamente.', 'warning');
          this.spinner.hide();
        }
      },
      (error) => {
        Swal({
          title: 'Erro!',
          text: error.error.Mensagem,
          icon: 'error',
        });
      }
    );
  }

  atualizarCadastroWhatsapp(cadastroDados) {
    this.spinner.show('premio');
    this.cadastrowhatsapp.atualizarCadastroWhatsapp(cadastroDados.NumOrd, cadastroDados).subscribe(
      (data) => {
        console.log(data);
        this.spinner.hide('premio');
        Swal({
          title: 'Sucesso!',
          text: 'Cadastro de WhatsApp atualizado com sucesso!',
          icon: 'success',
        });
        this.buscarCadastroWhatsapp();
      },
      (error) => {
        this.spinner.hide('premio');
        Swal({
          title: 'Erro!',
          text: error.error.Mensagem,
          icon: 'error',
        });
      }
    );
  }

  cadastrarCadastroWhatsapp(cadastroDados: { NumMovel: string; email: string }) {
    this.spinner.show('premio');
    this.cadastrowhatsapp.lancarCadastroWhatsapp(cadastroDados).subscribe(
      (data) => {
        console.log(data);
        this.spinner.hide('premio');
        Swal({
          title: 'Sucesso!',
          text: 'Cadastro de WhatsApp realizado com sucesso!',
          icon: 'success',
        });
        this.buscarCadastroWhatsapp();
      },
      (error) => {
        this.spinner.hide('premio');
        Swal({
          title: 'Erro!',
          text: error.error.Mensagem,
          icon: 'error',
        });
      }
    );
  }

  formatarNumero(numeroCompleto: string): string {
    const ddi = numeroCompleto.substring(0, 2);

    const ddd = numeroCompleto.substring(2, 4);

    const parte1 = numeroCompleto.substring(4, 9);

    const parte2 = numeroCompleto.substring(9);

    return `+${ddi} (${ddd}) ${parte1}-${parte2}`;
  }
}
