import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { Item } from '../faltas/faltas-cadastro/faltas-cadastro.component';
import { TempoServico } from './tempo-servico-cadastro/tempo-servico-cadastro.component';

@Injectable({
  providedIn: 'root',
})
export class TempoServicoService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  url_acesso: string;
  cabecalho: any;
  filial: string;
  listaClassificacaoAto: Item[] = [];
  listaCodCateg: any[] = [];
  listaEfeitoAverbacao: Item[] = [];

  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl();
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  buscarListaCategoria() {
    return this.http.get(`${this.url_acesso}tempo-de-servico/lista-codcateg`, {
      headers: this.cabecalho,
    });
  }

  buscarListaTempoServico(filial: string, matricula: number) {
    return this.http.get(
      `${this.url_acesso}tempo-de-servico/lista-tempo-servico-matricula/${filial}/${matricula}`,
      {
        headers: this.cabecalho,
      }
    );
  }

  downloadPdf(empfil: string, matricula: number, tipo: string) {
    return this.http.get(
      `${this.url_acesso}relatorios-pdf/download-pdf/${empfil}/${matricula}/${tipo}`,
      {
        headers: this.cabecalho,
      }
    );
  }

  buscarIdClassificacaoAto() {
    return this.http.get(`${this.url_acesso}falta-ao-trabalho/listar-id-classificacao-ato`, {
      headers: this.cabecalho,
    });
  }

  buscarEfeitoAverbacao() {
    return this.http.get(`${this.url_acesso}tempo-de-servico/lista-efeito-averbacao/`, {
      headers: this.cabecalho,
    });
  }

  calcularDias(dataInicio: string, dataVolta: string): string {
    dataInicio = dataInicio.replace(/^0+/, '');
    dataVolta = dataVolta.replace(/^0+/, '');
    if (
      ((dataInicio.includes('-') && dataInicio.length == 10) ||
        (!dataInicio.includes('-') && dataInicio.length == 8)) &&
      ((dataVolta.includes('-') && dataVolta.length == 10) ||
        (!dataVolta.includes('-') && dataVolta.length == 8))
    ) {
      const dataInicioFormatada = dataInicio.includes('-')
        ? this.extrairData(dataInicio.replace(/-/g, ''))
        : this.extrairData(dataInicio);
      const dataVoltaFormatada = dataVolta.includes('-')
        ? this.extrairData(dataVolta.replace(/-/g, ''))
        : this.extrairData(dataVolta);

      let diferencaEmMilissegundos = Math.abs(
        dataVoltaFormatada.getTime() - dataInicioFormatada.getTime()
      );
      let diferencaEmDias = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));

      return (diferencaEmDias +1).toString();
    }
  }

  extrairData(data: string) {
    let ano = parseInt(data.substring(0, 4));
    let mes = parseInt(data.substring(4, 6)) - 1;
    let dia = parseInt(data.substring(6, 8));
    return new Date(ano, mes, dia);
  }

  formatarDataBarra(data: string) {
    let ano = data.substring(0, 4);
    let mes = data.substring(4, 6);
    let dia = data.substring(6, 8);

    return dia + '/' + mes + '/' + ano;
  }

  retornarLista(listaNome: string) {
    if (listaNome == 'classificacao') {
      return this.listaClassificacaoAto;
    }
    if (listaNome == 'categoria') {
      return this.listaCodCateg;
    }
    if (listaNome == 'efeitoAverbacao') {
      return this.listaEfeitoAverbacao;
    }
  }

  inserirLista(listaNome: string, lista: Array<any>) {
    if (listaNome == 'classificacao') {
      this.listaClassificacaoAto = lista;
    }
    if (listaNome == 'categoria') {
      this.listaCodCateg = lista;
    }
    if (listaNome == 'efeitoAverbacao') {
      this.listaEfeitoAverbacao = lista;
    }
  }

  lancarTempoServico(tempoServico: TempoServico) {
    return this.http.post(`${this.url_acesso}tempo-de-servico/adicionar/`, tempoServico, {
      headers: this.cabecalho,
    });
  }

  atualizarTempoServico(tempoServico: TempoServico) {
    return this.http.put(`${this.url_acesso}tempo-de-servico/atualizar/`, tempoServico, {
      headers: this.cabecalho,
    });
  }

  deletarTempoServico(nid: string, filial: string, matricula: number, numeroOrdem: string) {
    return this.http.delete(
      `${this.url_acesso}tempo-de-servico/deletar/${nid}/${filial}/${matricula}/${numeroOrdem}`,
      {
        headers: this.cabecalho,
      }
    );
  }

  formatarDataHifen(data: string): string {
    let ano = data.slice(0, 4);
    let mes = data.slice(4, 6);
    let dia = data.slice(6, 8);

    return `${ano}-${mes}-${dia}`;
  }
}
