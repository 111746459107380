import { AcessoService } from '../../../service/acesso/acesso.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TipoBeneficiario } from './classes/TipoBeneficiario';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';

@Injectable({
  providedIn: 'root'
})
export class TipoBeneficiarioService {
  localStorage: LocalStorage;
  url_acesso:string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  filial: string;
  codTarefa = "000000000";
  tpProcessamento = "EM";
  
  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.url_acesso = this.acesso.validarUrl();
    }
   }

  listarTipoBeneficiario() {
    return this.http.get(`${this.url_acesso}beneficioscombotipobeneficio/${this.filial}/${this.tpProcessamento}/${this.filial}`,{headers:this.cabecalho});
  }
  salvarTipoBeneficio(tipoBeneficio: TipoBeneficiario){
    return this.http.put(`${this.url_acesso}beneficiostipobeneficiario/${this.filial}/${this.tpProcessamento}/${this.filial}`,tipoBeneficio,{headers:this.cabecalho})
  }
  deletarTipoBeneficio(codigo: any){
    return this.http.delete(`${this.url_acesso}tipobeneficiario/${this.filial}/${this.tpProcessamento}/${this.filial}/${codigo}`,{headers:this.cabecalho})
  }
}
