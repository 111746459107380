import {Data} from 'src/app/service/interfaces/Data';
export class LancamentoFin {
  Nome: string = '';

  TemConsignacaoExterna: boolean = false;
  TemRemuneracaoAnterior: boolean = false;
  SequencialRemuneracaoAnterior: string = '0';
  ContratoConsignado: string = '0';
  DevolucaoAnterior: string = '0';
  ProcessadoNaCompetenciaAnterior: string = '0';

  ParcelasOcorridasAnteriormente: string = '0'; //paga
  ParcelasAnteriores: string = '0'; //ocoa

  ValorInformadoAnterior: string = '0';
  Devolucao: string = '';
  ProcessadoNaCompetencia: string = '0';
  DATA: string = '0';
  AtualizaLct: boolean = false;
  Consignado: string = '0';

  Parcelas: string = ''; //oco
  ParcelasOcorridas: any = ''; //pag

  Referencia: string = '';
  ValorInformado: string = '';
  TransacaoAnterior: string = '0';
  Transacao: string = '';
  CodigoVerbaAnterior: string = '0';
  CodigoVerba: string = '';
  Parametro: string = '0';
  Empfil: string = '0';
  DigitoMatricula: string = '0';
  Sigla: string = '0';
  Matricula: string = '0';

  TipoReferencia: number = undefined;
  TransacaoSobreposta: string = '';
  CodigoNivel: string = '';

  RRA = {
    Exercicio: '',
    Numord: 0,
    NumProcesso: '',
    DtComp: '',
    Sigla: '',
    TpProc: '',
    VRB: 0,
    Trans: 0,
    NatRendimento: '',
    PercDirMesInicial: null,
    PercInforme: null,
    QtdMesInforme: '',
    ValorGlobal: null,
    ObjetoProcesso: '',
    OrigemProcesso: '',
  };
}

export interface RRA {
  Exercicio?: string;
  Numord?: number;
  NumProcesso?: string;
  DtComp?: string;
  TpProc?: string;
  VRB?: number;
  Trans?: number;
  NatRendimento?: string;
  PercDirMesInicial?: number;
  PercInforme?: number;
  QtdMesInforme?: string;
  ValorGlobal?: number;
  ObjetoProcesso?: string;
  OrigemProcesso?: string;
}
