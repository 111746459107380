import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InformacaoGenerica } from '../colaboradores/colaboradores.component';
import { HistoricoTransferencia } from './classes/historico-transferencia';
import Swal from 'sweetalert';
import { TransferenciaHistoricoService } from './transferencia-historico.service';
import { ErroService } from '../../service/erros/erro.service';
import { NgxSpinnerService } from 'ngx-spinner';
//import { error } from 'util';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { AnexoComponent } from '../anexo/anexo.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';



@Component({
  selector: 'app-transferencia-historico',
  templateUrl: './transferencia-historico.component.html',
  styleUrls: ['./transferencia-historico.component.css']
})
export class TransferenciaHistoricoComponent implements OnInit {
  formHistoricoTransferencia: FormGroup;
  formBuscarColaborador: FormGroup;
  localStorage: LocalStorage;
  lotacoes: any[] = [];
  centrosCustos: InformacaoGenerica[] = [];
  cargos: InformacaoGenerica[] = [];
  funcoes: InformacaoGenerica[] = [];
  listaHistoricoTransferencia: HistoricoTransferencia[] = [];
  filiais: any[] = [];

  dadosColaborador: Cadastro;
  registro: HistoricoTransferencia;
  filtro: string = "";
  telaHistoricoTransferencia: boolean = false;
  temErro: boolean = false;
  cargoFuncaoVazios = true;
  alterarRegistro: boolean = false;
  empresaPublica: boolean = false;
  exibirContinuar: boolean = false;
  @ViewChild(AnexoComponent, { static: false }) anexoComponent: AnexoComponent;
  @Input() pagina: any;
  constructor(private fb: FormBuilder, private historicoTransferenciaService: TransferenciaHistoricoService, private validaErro: ErroService, private spinner: NgxSpinnerService) {
    this.empresaPublica = (JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util"))) as LocalStorage).ClientePublico
  }

  ngOnInit() {
    this.localStorage = this.decryptLocalStorage();
    this.formHistoricoTransferencia = this.fb.group({
      empresaFilialOrigem: ['', [Validators.required]],
      matriculaOrigem: ['', [Validators.required]],
      empresaFilialDestino: ['', Validators.required],
      matriculaDestino: ['', [Validators.required]],
      dataTransferencia: ['', [Validators.required]],
      lotacaoDestino: ['', [Validators.required]],
      centroCustoDestino: ['', [Validators.required]],
      cargoDestino: [''],
      funcaoDestino: ['']
    });

    this.formBuscarColaborador = this.fb.group({
      matricula: ['', [Validators.required]],
    });

    this.dadosColaborador = this.limparDadosColaborador();

    setInterval(() => { this.formHistoricoTransferencia.get("dataTransferencia").setValue((document.getElementById("dataTransferencia") as HTMLInputElement).value); }, 1000);
  }

  getFilter(event) {
    this.filtro = event.target.value;
  }

  preencherRegistro(): HistoricoTransferencia {
    return {
      EmpfilOrigem: this.formHistoricoTransferencia.get('empresaFilialOrigem').value,
      MatriculaOrigem: this.formHistoricoTransferencia.get('matriculaOrigem').value,
      EmpfilDestino: this.formHistoricoTransferencia.get('empresaFilialDestino').value,
      CargoDestino: this.formHistoricoTransferencia.get('cargoDestino').value,
      CustoDestino: this.formHistoricoTransferencia.get('centroCustoDestino').value,
      DataEvento: { SetData: this.formHistoricoTransferencia.get('dataTransferencia').value },
      FuncaoDestino: this.formHistoricoTransferencia.get('funcaoDestino').value,
      LotacaoDestino: this.formHistoricoTransferencia.get('lotacaoDestino').value,
      MatriculaDestino: this.formHistoricoTransferencia.get('matriculaDestino').value,
      TipoProcessamento: this.alterarRegistro === false ? '' : this.registro.TipoProcessamento,
      Observacao: "HISTORICO TRANSFERENCIA"
    }
  }

  buscarColaborador() {
    if (this.formBuscarColaborador.valid == false) {
      this.temErro = true;
    }
    else {
      this.spinner.show();
      this.temErro = false;
      this.historicoTransferenciaService.buscarColaborador(this.formBuscarColaborador.get('matricula').value)
        .subscribe(
          (data: Cadastro) => {
            if (Number.parseInt(data.Matricula) > 0) {
              this.dadosColaborador = data
            }
            else {
              this.dadosColaborador.InformacoesBasicas.NomeCompleto = "";
              this.telaHistoricoTransferencia = false;;
            }
            this.exibirContinuar = true;
            this.spinner.hide();
          },
          erro => {
            this.validaErro.retornoErro(erro);
            this.spinner.hide();
          }
        )
    }
  }

  avancarFormHistorico() {
    this.spinner.show();
    this.telaHistoricoTransferencia = true;
    this.preencherOrigem();
    this.listarHistoricoTransferencias(this.dadosColaborador.Matricula);
    this.carregarCampos();
    this.spinner.hide();
  }

  voltarTelaBuscarColaborador() {
    this.telaHistoricoTransferencia = false;
    this.limparFormularioBuscarColaborador();
    this.limparFormularioHistoricoTransferencia();
    this.temErro = false;
    this.exibirContinuar = false;
  }

  limparFormularioBuscarColaborador() {
    this.formBuscarColaborador.reset({
      matricula: ''
    });
    this.dadosColaborador = this.limparDadosColaborador();
    this.temErro = false;
  }

  limparFormularioHistoricoTransferencia() {
    if (this.telaHistoricoTransferencia) {
      this.formHistoricoTransferencia.reset({
        matriculaOrigem: this.formHistoricoTransferencia.value.matriculaOrigem,
        empresaFilialOrigem: this.formHistoricoTransferencia.value.empresaFilialOrigem,
        matriculaDestino: '',
        dataTransferencia: '',
        empresaFilialDestino: '',
        lotacaoDestino: '',
        centroCustoDestino: '',
        cargoDestino: '',
        funcaoDestino: ''
      });
    }
    else {
      this.formHistoricoTransferencia.reset({
        matriculaOrigem: '',
        empresaFilialOrigem: '',
        matriculaDestino: '',
        dataTransferencia: '',
        empresaFilialDestino: '',
        lotacaoDestino: '',
        centroCustoDestino: '',
        cargoDestino: '',
        funcaoDestino: ''
      });
    }

    this.temErro = false;
    this.alterarRegistro = false;
    this.cargoFuncaoVazios = true;
    this.habilitarCampos();
  }

  limparDadosColaborador(): Cadastro {
    return new Cadastro();
  }

  verificarCargoFuncaoVazios() {
    this.cargoFuncaoVazios = this.formHistoricoTransferencia.get('cargoDestino').value == '' && this.formHistoricoTransferencia.get('funcaoDestino').value == '';
  }

  habilitarCampos() {
    this.formHistoricoTransferencia.get("matriculaDestino").enable();
    this.formHistoricoTransferencia.get("empresaFilialDestino").enable();
    this.formHistoricoTransferencia.get("dataTransferencia").enable();
  }

  desabilitarCamposAlteracao() {
    this.formHistoricoTransferencia.get("matriculaDestino").disable();
    this.formHistoricoTransferencia.get("empresaFilialDestino").disable();
    this.formHistoricoTransferencia.get("dataTransferencia").disable();
  }

  salvar() {
    this.formHistoricoTransferencia.controls['dataTransferencia'].setValue((document.getElementById("dataTransferencia") as HTMLInputElement).value);
    this.registro = this.preencherRegistro();
    this.verificarCargoFuncaoVazios();

    if ((this.formHistoricoTransferencia.valid == false) || this.cargoFuncaoVazios) {
      this.temErro = true;
    } else {
      if (this.alterarRegistro == false) {
        this.salvarRegistroTransferencia()
      }
      else {
        this.alterarRegistroTransferencia();
      }
    }
  }

  salvarRegistroTransferencia() {

    this.spinner.show()
    this.historicoTransferenciaService.salvarRegistroHistoricoTransferencia(this.registro)
      .subscribe(
        data => {
          new AlertaModal('sucesso', 'Sucesso!', 'Registro salvo com sucesso!', 'p')
          this.listarHistoricoTransferencias(this.dadosColaborador.Matricula);
          this.limparFormularioHistoricoTransferencia();
          this.spinner.hide();
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide()
        }
      );
    this.temErro = false;
  }

  alterarRegistroTransferencia() {
    this.spinner.show()
    this.historicoTransferenciaService.alterarRegistroHistoricoTransferencia(this.registro)
      .subscribe(
        data => {
          new AlertaModal('sucesso', 'Sucesso!', 'Registro salvo com sucesso!', 'p')
          this.listarHistoricoTransferencias(this.dadosColaborador.Matricula);
          this.limparFormularioHistoricoTransferencia();
          this.spinner.hide();
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide()
        }
      );
    this.temErro = false;
  }

  editarRegistroTransferencia(registroTransferencia: HistoricoTransferencia) {
    this.registro = registroTransferencia;
    this.formHistoricoTransferencia.controls['matriculaDestino'].setValue(registroTransferencia.MatriculaDestino);
    this.formHistoricoTransferencia.controls['empresaFilialDestino'].setValue(registroTransferencia.EmpfilDestino);
    this.formHistoricoTransferencia.controls['matriculaOrigem'].setValue(registroTransferencia.MatriculaOrigem);
    this.formHistoricoTransferencia.controls['empresaFilialOrigem'].setValue(registroTransferencia.EmpfilOrigem);
    this.formHistoricoTransferencia.controls['dataTransferencia'].setValue(registroTransferencia.DataEvento.ToDate);
    this.formHistoricoTransferencia.controls['lotacaoDestino'].setValue(registroTransferencia.LotacaoDestino);
    this.formHistoricoTransferencia.controls['centroCustoDestino'].setValue(registroTransferencia.CustoDestino);
    this.formHistoricoTransferencia.controls['cargoDestino'].setValue(registroTransferencia.CargoDestino);
    this.formHistoricoTransferencia.controls['funcaoDestino'].setValue(registroTransferencia.FuncaoDestino);
    this.temErro = false;
    this.alterarRegistro = true;
    this.desabilitarCamposAlteracao();
    this.cargoFuncaoVazios = false;
    window.scroll(0, 0);
  }

  avisoExcluirRegistro(registro: HistoricoTransferencia) {
    Swal({
      title: "Você tem certeza?",
      text: "Esta operação não pode ser desfeita.",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true
        }
      }
    })
      .then((willDelete) => {
        if (willDelete) {
          this.excluirRegistro(registro);
        }
      });
  }

  excluirRegistro(registro: HistoricoTransferencia) {
    this.spinner.show()

    this.historicoTransferenciaService.excluirRegistroHistoricoTransferencia(registro.MatriculaDestino, registro.EmpfilDestino, registro.LotacaoDestino, registro.DataEvento.SetData, registro.CustoDestino, registro.CargoDestino, registro.FuncaoDestino)
      .subscribe(
        data => {
          new AlertaModal('sucesso', 'Sucesso!', 'Registro excluído com sucesso!', 'p')
          this.limparFormularioHistoricoTransferencia();
          this.listarHistoricoTransferencias(this.dadosColaborador.Matricula)
          this.spinner.hide()
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide()
        });

  }

  listarHistoricoTransferencias(matricula: string) {
    this.historicoTransferenciaService.listarHistoricoTransferencia(matricula)
      .subscribe(
        (data: any) => {
          this.listaHistoricoTransferencia = data;
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  listarLotacao(): void {

    this.spinner.show();
    let empresa = '0';
    if (empresa == '') {
      empresa = '0';
    }
    this.historicoTransferenciaService.listarLotacoes(empresa)
      .subscribe(
        data => {
          this.lotacoes = JSON.parse(JSON.stringify(data));
          this.spinner.hide()
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );

  }

  listarFiliais(): void {
    this.historicoTransferenciaService.listarFiliais()
      .subscribe(
        (data: any[]) => {
          this.filiais = data
          this.spinner.hide();

        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  listarCentroCusto(): void {
    this.historicoTransferenciaService.listarCentroCusto()
      .subscribe(
        (data: any[]) => {
          this.centrosCustos = data
          this.spinner.hide();

        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  carregarCampos() {
    this.listarFiliais();
    this.listarCentroCusto();
    this.listarLotacao();
    if (this.empresaPublica) {
      this.listarCargos();
    }
    else {
      this.listarFuncoes();
    }
  }

  preencherOrigem() {
    this.formHistoricoTransferencia.controls['matriculaOrigem'].setValue(this.dadosColaborador.Matricula);
    this.formHistoricoTransferencia.controls['empresaFilialOrigem'].setValue(this.dadosColaborador.Filial.Codigo);
  }

  listarFuncoes(): void {
    let empresa = this.formHistoricoTransferencia.get('empresaFilialDestino').value;
    if (empresa == '') {
      empresa = '0';
    }
    this.historicoTransferenciaService.listarFuncoes(empresa)
      .subscribe(
        (data: InformacaoGenerica[]) => {
          this.funcoes = data;

        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  listarCargos(): void {
    let empresa = this.formHistoricoTransferencia.get('empresaFilialDestino').value;
    if (empresa == '') {
      empresa = '0';
    }
    this.historicoTransferenciaService.listarCargos(empresa)
      .subscribe(
        (data: InformacaoGenerica[]) => {
          this.cargos = data;
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }
}
