import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit {
  @Input() ID : string;
  @Output() dataEmitter = new EventEmitter();
  @Input() setData: string
  formData: FormGroup

  data: string = ''
  constructor(private fb: FormBuilder) {

    this.formData = fb.group({
      data: ['']
    })
  }

  ngOnInit() {
    
  }

  mudarValor() {
    this.dataEmitter.emit(this.formData.get('data').value)
    
    if(this.setData != ''){
      this.formData.get('data').setValue(this.setData)
    }
  }

}
