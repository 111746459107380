import { Component, ViewChild } from '@angular/core';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { FormBuilder, Validators } from '@angular/forms';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { FormGroup } from '@angular/forms';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { PromocoesService } from './promocoes.service';
import { PromocoesCadastroComponent } from './promocoes-cadastro/promocoes-cadastro.component';
import Swal from 'sweetalert';
import { ObservacoesFichaFuncionalComponent } from '../componentes-funcionais/observacoes-ficha-funcional/observacoes-ficha-funcional.component';
import { AnexoComponent } from '../anexo/anexo.component';

@Component({
  selector: 'app-promocoes',
  templateUrl: './promocoes.component.html',
  styleUrls: ['./promocoes.component.css'],
})
export class PromocoesComponent {
  @ViewChild(PromocoesCadastroComponent) modal: PromocoesCadastroComponent;
  @ViewChild(ObservacoesFichaFuncionalComponent)
  modalObservacoes: ObservacoesFichaFuncionalComponent;
  @ViewChild(AnexoComponent) modalAnexo: AnexoComponent;
  tipoPagina: string = '010';
  matricula: number = null;
  colaborador: Cadastro = null;
  loading: boolean = false;
  formColaborador: FormGroup;
  localStorage: LocalStorage;
  cadastroPromocao: boolean = true;
  listaPromocoes: Promocao[] = [];
  empfil: string;

  promocaoAtual: Promocao = null;

  constructor(
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private promocoesService: PromocoesService
  ) {
    this.localStorage = this.decryptLocalStorage();
    console.log('localstorage', this.localStorage);
    this.formColaborador = this.fb.group({
      matricula: ['', Validators.required],
      nome: [''],
    });
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }

  buscarColaboradorMatricula(matricula) {
    
    this.spinner.show("promocao");
    this.promocoesService.buscarColaboradorMatricula(matricula).subscribe(
      (data: Cadastro) => {
        console.log(data);
        this.empfil = data.Filial.Codigo
        this.setarFormColaborador(data);
        this.colaborador = data;
        this.spinner.hide("promocao");
      },
      (error) => {
        new AlertaModal('erro', 'Erro!', error.error.Mensagem, 'p');
        this.retornarTelaInicial();
        this.spinner.hide("promocao");
      }
    );
  }

  matriculaListener(evento) {
    this.matricula = evento.Matricula;
    this.buscarColaboradorMatricula(this.matricula);
    this.modalAnexo.inicializarComponente(evento);
  }

  retornarTelaInicial() {
    this.matricula = null;
    this.formColaborador.reset();
    this.formColaborador.markAsPristine();
  }

  setarFormColaborador(data) {
    this.formColaborador.get('matricula').disable();
    this.formColaborador.get('matricula').setValue(this.matricula);
    this.formColaborador.get('nome').disable();
    this.formColaborador.get('nome').setValue(data.InformacoesBasicas.NomeCompleto);
    this.loading = false;
    this.buscarPromocoes();
  }

  buscarPromocoes() {
    this.spinner.show();
    this.promocoesService.buscarPromocoes(this.matricula).subscribe(
      (data: Promocao[]) => {
        console.log('data', data);
        this.listaPromocoes = data;
        this.spinner.hide();
      },
      (err) => {
        console.error(err);
        this.spinner.hide();
      }
    );
  }
  downloadPdf() {
    this.spinner.show();

    this.promocoesService.downloadPdf(this.empfil, this.matricula, this.tipoPagina).subscribe(
      (data: any) => {
        console.log(data);

        this.spinner.hide();

        const pdf = `data:application/pdf;base64,${data.Base64}`;
        const fileName = `${data.Nome}.pdf`;

        const downloadLink = document.createElement('a');

        downloadLink.href = pdf;

        downloadLink.download = fileName;
        
        downloadLink.click();
      },
      (err) => {
        console.error(err);
        this.spinner.hide();
      }
    );
  }



  aoAbrirModal(index?: number, Sequencial?: number) {
    this.modal.formPromocoes.reset();

    if (index !== undefined) {
      const promocaoSelecionada = this.listaPromocoes[index];
      const formatarData = (data) =>
        typeof data === 'string' && data ? data.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') : '';

      const formatarDataParaInput = (dataObj) => {
        if (!dataObj || !dataObj.SetData || dataObj.SetData.length !== 8) return '';
        const ano = dataObj.SetData.substring(0, 4);
        const mes = dataObj.SetData.substring(4, 6);
        const dia = dataObj.SetData.substring(6, 8);
        return `${ano}-${mes}-${dia}`;
      };

      this.modal.formPromocoes.get('DtInicio').setValue(formatarData(promocaoSelecionada.DtInicio));
      this.modal.formPromocoes
        .get('DtDoe')
        .setValue(
          promocaoSelecionada.DtDoe ? formatarDataParaInput(promocaoSelecionada.DtDoe) : ''
        );
      this.modal.formPromocoes
        .get('DtAto')
        .setValue(
          promocaoSelecionada.DtAto ? formatarDataParaInput(promocaoSelecionada.DtAto) : ''
        );
      this.modal.formPromocoes.get('DtComp').setValue(formatarData(promocaoSelecionada.DtComp));
      this.modal.formPromocoes.get('CodFun').setValue(promocaoSelecionada.CodFun);
      this.modal.formPromocoes.get('CodCar').setValue(promocaoSelecionada.CodCar);
      this.modal.formPromocoes.get('DescCarFun').setValue(promocaoSelecionada.DescCarFun);
      this.modal.formPromocoes.get('CodNiv').setValue(promocaoSelecionada.CodNiv);
      this.modal.formPromocoes.get('CodNiv').enable();

      this.modal.formPromocoes.get('NumeroDoe').setValue(promocaoSelecionada.NumeroDoe);

      if (promocaoSelecionada.IdClassificacaoAto === 0) {
        this.modal.formPromocoes.get('IdClassificacaoAto').setValue(null);
      } else {
        this.modal.formPromocoes
          .get('IdClassificacaoAto')
          .setValue(promocaoSelecionada.IdClassificacaoAto);
      }

      this.modal.formPromocoes.get('NumeroProcesso').setValue(promocaoSelecionada.NumeroProcesso);
      this.modal.formPromocoes.get('AtoPro').setValue(promocaoSelecionada.AtoPro);
      this.modal.formPromocoes.get('Observacao').setValue(promocaoSelecionada.Observacao);

      const codParaBuscarNiveis = promocaoSelecionada.CodFun
        ? promocaoSelecionada.CodFun
        : promocaoSelecionada.CodCar;

      this.modal.buscarNiveisCargo(codParaBuscarNiveis);

      this.modal.promocaoindex = index;
      this.modal.Seq = Sequencial;
    } else {
      this.modal.promocaoindex = null;
      this.cadastroPromocao = true;
    }
  }


 

  
  formatarData(data: string): string {
    if (!data || data.length !== 6) return data;
    const ano = data.substring(0, 4);
    const mes = data.substring(4, 6);
    return `${mes}/${ano}`;
  }

  formatarData2(dataStr: string): string {
    if (!dataStr) return '';
    const ano = dataStr.substr(0, 4);
    const mes = dataStr.substr(4, 2);
    const dia = dataStr.substr(6, 2);

    return `${dia}/${mes}/${ano}`;
  }

  deletarPromocao(promocao: Promocao) {
    console.log('promocao', promocao);
    Swal({
      title: 'Deseja realmente excluir essa promoção?',
      text: 'Essa ação não pode ser desfeita.',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
    }).then((result) => {
      this.spinner.show();
      if (result) {
        this.promocoesService
          .deletarPromocao(promocao.Empfil, promocao.Mat, promocao.Seq)
          .subscribe(
            (data) => {
              this.buscarPromocoes();
              Swal({
                title: 'Excluído!',
                text: 'A promoção foi excluída com sucesso.',
                icon: 'success',
              });
              this.spinner.hide();
            },
            (err) => {
              Swal('Erro', 'Não foi possível deletar essa promoção.', 'error');
              this.spinner.hide();
            }
          );
      }else this.spinner.hide();
    });
  }
}

export interface Promocao {
  CodCar: string;
  Obs: 'S' | 'N';
  Documento: 'S' | 'N';
  Seq: number;
  CodFun: string;
  DescCarFun: string;
  DtInicio: string;
  Observacao: string;
  CodNiv: string;
  AtoPro: string;
  DtComp: string;
  Cod: string;
  Flag: string;
  Grupo: number;
  NumeroProcesso: string;
  Empfil: string;
  Mat: number;
  NumeroDoe: string;
  IdClassificacaoAto: number;
  DtAto: string;
  DtDoe: string;
}
