import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AcessoService } from '../../service/acesso/acesso.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  url_acesso = this.acesso.validarUrl();
  headers = new HttpHeaders();
 
  cabecalho = this.headers.append('sessao', localStorage.getItem('sessao'));

  filial = localStorage.getItem("filial_atual");
  codTarefa = "00000000";
  tpProcessamento = "em";

  constructor(private http: HttpClient, private acesso: AcessoService, private route: Router) { 
    this.headers = this.headers.set('salt', localStorage.getItem("salt"));
    this.headers = this.headers.set('numord', localStorage.getItem("numord"));
    this.headers = this.headers.set('admUser', localStorage.getItem("admUser"));
  }

  pegarTipo(situacao:string){
   if(this.route.url.includes("/continuacao-cadastro")){ //É uma rota que o usuario nao esta logado, deve chamar uma rota diferente
    return this.http.get(this.url_acesso + `precadastrotipoarquivo/${this.codTarefa}/${this.tpProcessamento}/${this.filial}/${situacao}`,{headers:this.headers});
   }else{
    return this.http.get(this.url_acesso + `uploadarquivo/${situacao}`,{headers:this.cabecalho});
   }  
  }

  salvar(files:any[]){
    return this.http.put(this.url_acesso + 'uploadarquivo', files, {headers:this.cabecalho});
  }
}
