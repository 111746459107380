import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErroService } from 'src/app/service/erros/erro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FeriasSetor, InformacaoGenerica } from 'src/app/service/interfaces/RelatorioFerias';
import { InformacoesBasicas } from 'src/app/service/classes/cadastro/InformacoesBasicas/InformacoesBasicas';
import { FeriasSetorService } from './relatorio-ferias-setor.service';
import { Colaborador } from 'src/app/componentes/colaboradores/classes/Colaborador';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ProfilePDF } from 'src/app/componentes/profile/profile-PDF';
// import { Content, Variable } from '@angular/compiler/src/render3/r3_ast';
import Swal from 'sweetalert2';
import { FeriasSetorPDF } from '../../ferias-PDF';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { AlertaModal } from 'src/app/componentes/componentes-funcionais/alerta-modal/alerta-modal.component';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-relatorio-ferias-setor',
  templateUrl: './relatorio-ferias-setor.component.html',
  styleUrls: ['./relatorio-ferias-setor.component.css']
})
export class FeriasSetorComponent implements OnInit {
  listaRelatorioFerias: FeriasSetor[] = [];
  listaSetorDropDown: FeriasSetor[] = [];
  tipoUsuario: string;
  formFeriasSetor: FormGroup;
  empresaFilial: string;
  FeriasSetor: FeriasSetor;
  listaSetores: FeriasSetor[] = [];
  filtroPesquisa: string = "";
  loadingFeriasSetorBt: boolean;
  valoresRelatorio: string[];
  empresaFilialFerias: Object[];
  setoresSelecionados: string[] = [];
  todosSetores: string[] = [];
  todosCheckbox: any;
  setoresSelecionadosCod: string[] = [];
  tamanhoListaSetores: string

  constructor(private formBuilder: FormBuilder, private FeriasSetorService: FeriasSetorService, private validaErro: ErroService, private spinner: NgxSpinnerService) {
    this.tipoUsuario = localStorage.getItem('tipoUsuario');
    this.empresaFilial = localStorage.getItem('filial_atual')
  }

  ngOnInit() {
    this.formFeriasSetor = this.formBuilder.group({
      adiantarDecimoTerceiro: ['', [Validators.required]],
      parcelarFerias: ['', [Validators.required]],
      venderDezDias: ['', [Validators.required]],
      dataInicio: ['', [Validators.required]],
      dataFim: ['', [Validators.required]],
      diasDeFerias: ['', [Validators.required]]
    });
    this.buscarFerias();                                    
  }

  limparCampos(){
    this.formFeriasSetor.get('adiantarDecimoTerceiro').setValue('');
    this.formFeriasSetor.get('parcelarFerias').setValue('');
    this.formFeriasSetor.get('venderDezDias').setValue('');
    this.formFeriasSetor.get('dataInicio').setValue('');
    this.formFeriasSetor.get('dataFim').setValue('');
    this.formFeriasSetor.get('diasDeFerias').setValue('');
    this.setoresSelecionados = [];
    this.setoresSelecionadosCod = [];
    let checkboxHtml = <HTMLInputElement>document.getElementById("checkboxHtml");
    checkboxHtml.checked = false;
    checkboxHtml.setAttribute('data-checado', 'false');
    this.empresaFilialFerias.forEach(data => {
      let element = <HTMLInputElement>document.getElementById(data['Nome']);
      element.checked = false;
    });
  }


  buscarFerias() {
    this.formFeriasSetor.get('adiantarDecimoTerceiro').setValue('');
    this.formFeriasSetor.get('parcelarFerias').setValue('');
    this.formFeriasSetor.get('venderDezDias').setValue('');
    this.formFeriasSetor.get('dataInicio').setValue('');
    this.formFeriasSetor.get('dataFim').setValue('');
    this.formFeriasSetor.get('diasDeFerias').setValue('');
    this.setoresSelecionados = [];
    this.setoresSelecionadosCod = [];
    let checkboxHtml  = <HTMLInputElement>document.getElementById("checkboxHtml");
    checkboxHtml.checked = false;
    checkboxHtml.setAttribute('data-checado', 'false');


    this.spinner.show();
    this.FeriasSetorService.buscarFeriasSetor().subscribe((data: object[]) => {
      this.empresaFilialFerias = data;
    }, erro => {
      this.validaErro.retornoErro(erro);
    });
    this.spinner.hide();
  }

  verificarSelecao(event, setorSelecionado: any) {
    let setorIndex = this.setoresSelecionados.indexOf(setorSelecionado['Nome']);
    let setorIndexCod = this.setoresSelecionadosCod.indexOf(setorSelecionado['Codigo']);
    if (event.target.checked) {
      this.setoresSelecionados.push(setorSelecionado.Nome);
      this.setoresSelecionadosCod.push(setorSelecionado.Codigo);
    } else {
      this.setoresSelecionados.splice(setorIndex, 1);
      this.setoresSelecionadosCod.splice(setorIndexCod, 1);
    }

    if(this.setoresSelecionados.length < this.empresaFilialFerias.length){
      let checkboxHtml = <HTMLInputElement>document.getElementById("checkboxHtml");
      checkboxHtml.checked = false;
      checkboxHtml.setAttribute('data-checado', 'false');
    } else if(this.setoresSelecionados.length = this.empresaFilialFerias.length){
      let checkboxHtml  = <HTMLInputElement>document.getElementById("checkboxHtml");
      checkboxHtml.checked = true;
      checkboxHtml.setAttribute('data-checado', 'true');
    }
  }

  formatarData(data : string): string{
    let dia = data.substring(6,8); 
    let mes = data.substring(4,6);
    let ano = data.substring(0,4);
    return `${dia}/${mes}/${ano}`
  }

  verificarSetoresSelecionados(){
    this.setoresSelecionadosCod = [];
    this.empresaFilialFerias.forEach(data => {
      let element = <HTMLInputElement>document.getElementById(data['Nome']);
      if (element.checked) {
        this.setoresSelecionadosCod.push(data['Codigo']);
      }
    });
  }

  ativarFerias() {
    this.verificarSetoresSelecionados();
    let objetoBack = 
    {
      ListaSetores: this.setoresSelecionadosCod,
      TelaFerias: {
        Adiantar13: this.formFeriasSetor.get("adiantarDecimoTerceiro").value,
        DiasDireito: 0,
        DiasRestantes: -60,
        TipoFerias: 1,
        TipoProcessamento: "EF",
        TelaPerfil: true,
        Faltas: 0,
        Vender10Dias: this.formFeriasSetor.get("venderDezDias").value,
        ParcelarFerias: this.formFeriasSetor.get("parcelarFerias").value,
        DiasDeFerias: parseInt(this.formFeriasSetor.get("diasDeFerias").value),
        Parcelas: [
          {
            CodigoFeriasRejeitadas: 0,
            MotivoRejeicao: "",
            Competencia: "",
            CompetenciaAtualizacao: "",
            Status: {
              Codigo: "14",
              Descricao: "PENDENTE",
              Tipo: ""
            },
            Dias: 30,
            Inicio: {
              SetData: (this.formFeriasSetor.get("dataInicio").value).replaceAll("-", ""),
              ToDate:  this.formatarData((this.formFeriasSetor.get("dataInicio").value).replaceAll("-", ""))
            },
            Fim: {
              SetData: (this.formFeriasSetor.get("dataFim").value).replaceAll("-", ""),
              ToDate: this.formatarData((this.formFeriasSetor.get("dataFim").value).replaceAll("-", ""))
            },
            DiasRestantes: -30,
            Editavel: false
          }
        ],
        Vencimento: {
          Fim: {
            SetData: "20220812",
            ToDate: "12/08/2022",
            IsDate: true,
            Empty: false
          },
          Inicio: {
            SetData: "20210813",
            ToDate: "13/08/2021",
            IsDate: true,
            Empty: false
          }
        },
        Opcional: {
          AnoAto: "",
          NumeroAto: "",
          NumeroDoe: "",
          DataAto: null,
          DataDoe: null,
          AnoDoe: "",
          IdClassificacaoAto: ""
        }
      },     
    }

    if(this.formFeriasSetor.valid && this.setoresSelecionados.length > 0){
      if(!this.verificarDatas(this.formFeriasSetor.get('dataInicio').value, this.formFeriasSetor.get('dataFim').value)){
        new AlertaModal('erro', "Erro!", "Data final deve ser posterior a data inicial.", "p");
      } 
      // else if(this.verificarDias() !== this.formFeriasSetor.get('diasDeFerias').value){
      //   new AlertaModal("erro", "Erro!", "Quantidade de dias não são compatíveis.", "p");
      // } 
      else {
        this.FeriasSetorService.ativarFerias(objetoBack).subscribe((resp) => {
          console.log(objetoBack)
          new AlertaModal("sucesso", "Sucesso!", "Férias incuidas ao setores selecionados!", "p");
          this.limparCampos();
        }, erro => {
          this.validaErro.retornoErro(erro);
        });
      }
    } else {
      new AlertaModal("erro", "Erro!", "Preencha todos os campos para continuar.", "p");
    }
  }

  calcularDiasDeFerias(){
    if(this.formFeriasSetor.get('dataInicio').value && this.formFeriasSetor.get('dataFim').value && this.verificarDatas(this.formFeriasSetor.get('dataInicio').value, this.formFeriasSetor.get('dataFim').value)){
      this.formFeriasSetor.get('diasDeFerias').setValue(this.verificarDias());
    } else {
      this.formFeriasSetor.get('diasDeFerias').setValue('');
    }
  }

  verificarDias(){
    let data1 = this.formFeriasSetor.get('dataInicio').value;
    let data2 = this.formFeriasSetor.get('dataFim').value;
    let dataI = new Date(data1);
    let dataF = new Date(data2);
    let diffTime = Math.abs(dataF.getTime() - dataI.getTime());
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  verificarDatas(dataInicial: string, dataFinal: string){
    const dateInicial = new Date(dataInicial);
    const dateFinal = new Date(dataFinal);
    return dateInicial < dateFinal;
  }
  
selecionarTodosToogle() {
  const checkboxHtml =  <HTMLInputElement>document.querySelector("#checkboxHtml");
  if (checkboxHtml.getAttribute("data-checado") == "false") {
    this.setoresSelecionados = [];
    this.setoresSelecionadosCod = [];
    for (let setor of this.empresaFilialFerias) {
      this.setoresSelecionados.push(setor['Nome']);
      this.setoresSelecionadosCod.push(setor['Codigo']);
    }
    this.empresaFilialFerias.forEach(data => {
      let element = <HTMLInputElement>document.getElementById(data['Nome']);
      if (element.checked == false) {
        element.click();
      }
    });
    checkboxHtml.setAttribute('data-checado', 'true');
  } else if (checkboxHtml.getAttribute("data-checado") == "true") {
    this.empresaFilialFerias.forEach(data => {
      for (let setor of this.empresaFilialFerias) {
        let setorIndex = this.setoresSelecionados.indexOf(setor['Nome']);
        let setorIndexCod = this.setoresSelecionadosCod.indexOf(setor['Codigo']);
        this.setoresSelecionados.splice(setorIndex, 1);
        this.setoresSelecionadosCod.splice(setorIndexCod, 1);
      }
      let element = <HTMLInputElement>document.getElementById(String(data['Nome']));
      element.checked = false;
    });
    this.setoresSelecionados = [];
    this.setoresSelecionadosCod = [];
    checkboxHtml.setAttribute('data-checado', 'false');
  }
}

MontarListaSetores() {
  if (this.filtroPesquisa == "") { this.listaSetores = this.listaRelatorioFerias; }
  else { this.listaSetores = this.listaRelatorioFerias.filter((colaborador) => colaborador.CodigoSetor == this.filtroPesquisa); }
}

gerarPdf() {
  const documentDefinition = { content: 'Testando o  pdfMake' };
  pdfMake.createPdf(documentDefinition).download();
}

imprimirPdf() {
  const documentDefinition = { content: 'Testando o  pdfMake' };
  pdfMake.createPdf(documentDefinition).open();
}

montarValores(colaboradores: FeriasSetor[]): any[] {
  var valores = []
  colaboradores.forEach(Colaborador => {
    valores.push({ text: Colaborador.NomeColaborador, style: 'tableHeader' });
    valores.push({ text: Colaborador.Matricula, style: 'tableHeader' });
    valores.push({ text: Colaborador.InicioFerias.ToDate + " até " + Colaborador.FimFerias.ToDate, style: 'tableHeader' });
  });

  return valores
}



getBase64ImageFromURL(url) {

  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = error => {
      reject(error);
    };
    img.src = url;
  });
}


montarBodyTabela(data, colunas) {
  var body = [];

  body.push(colunas);

  data.forEach(function (row) {
    var dataRow = [];

    colunas.forEach(function (column) {
      dataRow.push(row[column].toString());
    })

    body.push(dataRow);
  });

  return body;
}


table(data, colunas) {
  return {
    table: {
      headerRows: 1,
      widths: [210, 100, 180],
      body: this.montarBodyTabela(data, colunas),
      height: '100%',
      fontSize: 18
    }
  };
}



montarTabela(colaboradores: FeriasSetor[], setor: string): any[] {

  var listaAuxiliar = [];

  colaboradores.map(colab => {
    if (colab.CodigoSetor == setor) { // não é 01, é o setor que vai ser passado por parametro

      listaAuxiliar.push({ Nome: colab.NomeColaborador, Matrícula: colab.Matricula, Período: colab.InicioFerias.ToDate + " até " + colab.FimFerias.ToDate, fontSize: 4 })

    }

  })
  return listaAuxiliar;
}


}
