<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<ngx-spinner name="experiencia" bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<app-pesquisar-matricula
  *ngIf="matricula == null"
  [titulo]="'Experiência Profissional'"
  [inputText]="true"
  (colaboradorEmitter)="matriculaListener($event)"
></app-pesquisar-matricula>

<section class="container" *ngIf="matricula != null">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title font-weight-semibold">
        <i
          class="fa fa-arrow-left pr-3 btn"
          aria-hidden="true"
          (click)="retornarTelaInicial()"
          style="cursor: pointer; bottom: 2px"
        ></i
        >Experiência Profissional
      </h3>
    </div>
    <div class="card-body">
      <form class="row" [formGroup]="formColaborador">
        <div class="col-md-2 form-group">
          <label class="font-weight-semibold">Matrícula</label>

          <input
            type="text"
            class="form-control"
            placeholder="Matrícula"
            mask="0*"
            formControlName="matricula"
          />

          <span class="form-text" *ngIf="loading"
            >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
          ></span>
        </div>

        <div class="col-md-10 form-group">
          <label class="font-weight-semibold">Nome do Colaborador</label>
          <input type="text" class="form-control" placeholder="Nome" formControlName="nome" />
        </div>
      </form>
    </div>
  </div>

  <div class="card">
    <div class="card-header d-inline-flex justify-content-between">
      <h3 class="card-title font-weight-semibold">Lista de Experiências</h3>

      <div>
        <button type="button" class="btn btn-outline-primary" (click)="enviarExperiencias()">
          Enviar Experiências
        </button>
        <button
          type="button"
          class="btn btn-outline-primary ml-2"
          data-toggle="modal"
          data-target="#anexoExperienciaProfissionalModal"
          (click)="modalAnexo.abrirModal()"
        >
          Documentos
        </button>
        <button
          type="button"
          class="btn btn-outline-primary ml-2"
          data-toggle="modal"
          data-target="#observacoesExperienciaProfissionalModal"
          (click)="modalObservacoes.abrirModal()"
        >
          Observações
        </button>
      </div>
    </div>
    <div class="card-body">
      <p
        *ngIf="
          listaCursos.length == 0 ||
          listaHabilidades.length == 0 ||
          listaFuncoes.length == 0 ||
          listaRequisitos.length == 0 ||
          listaAtividades.length == 0
        "
        class="font-weight-semibold text-center"
      >
        Carregando Lista de Experiências <i class="fa fa-spinner fa-spin" aria-hidden="false"></i>
      </p>
      <div
        *ngIf="
          listaCursos.length > 0 &&
          listaHabilidades.length > 0 &&
          listaFuncoes.length > 0 &&
          listaRequisitos.length > 0 &&
          listaAtividades.length > 0
        "
      >
        <app-experiencia-profissional-cadastro
          #Cursos
          [titulo]="'Cursos'"
          [items]="listaCursos"
          [listaExperiencias]="listaExperiencias"
          [numord]="numeroDeOrdem"
          [listaAvaliacoes]="listaAvaliacoes"
        ></app-experiencia-profissional-cadastro>
        <app-experiencia-profissional-cadastro
          #Habilidades
          [titulo]="'Habilidades'"
          [items]="listaHabilidades"
          [listaExperiencias]="listaExperiencias"
          [numord]="numeroDeOrdem"
        ></app-experiencia-profissional-cadastro>
        <app-experiencia-profissional-cadastro
          #Funcoes
          [titulo]="'Funções'"
          [items]="listaFuncoes"
          [listaExperiencias]="listaExperiencias"
          [numord]="numeroDeOrdem"
        ></app-experiencia-profissional-cadastro>
        <app-experiencia-profissional-cadastro
          #Requisitos
          [titulo]="'Requisitos'"
          [items]="listaRequisitos"
          [listaExperiencias]="listaExperiencias"
          [numord]="numeroDeOrdem"
        ></app-experiencia-profissional-cadastro>
        <app-experiencia-profissional-cadastro
          #Atividades
          [titulo]="'Atividades'"
          [items]="listaAtividades"
          [listaExperiencias]="listaExperiencias"
          [numord]="numeroDeOrdem"
        ></app-experiencia-profissional-cadastro>
        <!-- <app-experiencia-profissional-cadastro
        #Avaliacoes
        [titulo]="'Avaliações'"
        [items]="listaAvaliacoes"
        [listaExperiencias]="listaExperiencias"
        [numord]="numeroDeOrdem"
      ></app-experiencia-profissional-cadastro> -->
      </div>
    </div>
  </div>
</section>
<div
  class="modal fade"
  id="observacoesExperienciaProfissionalModal"
  tabindex="-1"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <app-observacoes-ficha-funcional [colaborador]="colaborador" tipo="207">
      </app-observacoes-ficha-funcional>
    </div>
  </div>
</div>

<div class="modal fade" id="anexoExperienciaProfissionalModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-anexo
        pagina="207"
        [matricula]="colaborador?.Matricula"
        [enviarSeparadamente]="true"
        [modal]="true"
      ></app-anexo>
    </div>
  </div>
</div>
