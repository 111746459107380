<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="modal-header">
  <h3 class="modal-title">{{ cadastro ? 'Incluir' : 'Atualizar' }} Tempo de Serviço</h3>
  <button type="button" class="close" id="botaoFechar" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form autocomplete="off" [formGroup]="formTempoServico" class="modal-body">
  <fieldset>
    <legend>
      <h4>Dados do Funcionário</h4>
    </legend>
    <section class="row" formGroupName="dadosFuncionario">
      <div class="form-group col-4">
        <label for="Protoc">Protocolo CTC/CTS</label>
        <input
          class="form-control"
          placeholder="Protocolo"
          type="text"
          id="Protoc"
          formControlName="Protoc"
          [ngClass]="
            formTempoServico.get('dadosFuncionario').get('Protoc').invalid &&
            formTempoServico.get('dadosFuncionario').get('Protoc').touched
              ? 'is-invalid'
              : null
          "
        />
        <span
          class="form-text text-danger"
          *ngIf="
            formTempoServico.get('dadosFuncionario').get('Protoc').invalid &&
            formTempoServico.get('dadosFuncionario').get('Protoc').touched
          "
        >
          Campo Obrigatório
        </span>
      </div>
      <div class="form-group col-4">
        <label for="Ultrem">Salário</label>
        <input
          class="form-control"
          placeholder="R$0,00"
          type="text"
          id="Ultrem"
          formControlName="Ultrem"
          currencyMask
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
          [ngClass]="
            formTempoServico.get('dadosFuncionario').get('Ultrem').invalid &&
            formTempoServico.get('dadosFuncionario').get('Ultrem').touched
              ? 'is-invalid'
              : null
          "
        />
        <span
          class="form-text text-danger"
          *ngIf="
            formTempoServico.get('dadosFuncionario').get('Ultrem').invalid &&
            formTempoServico.get('dadosFuncionario').get('Ultrem').touched
          "
        >
          Campo Obrigatório
        </span>
      </div>
      <div class="form-group col-4">
        <label for="Ultrec">Rec INSS <small>(opcional)</small></label>
        <input
          class="form-control"
          placeholder="R$0,00"
          type="text"
          id="Ultrec"
          formControlName="Ultrec"
          currencyMask
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
        />
      </div>

      <div class="form-group col-7">
        <label for="InDmv"
          >Índicador de Contribuição Previdenciária <small> (opcional)</small></label
        >
        <select class="form-control" id="InDmv" formControlName="InDmv">
          <option value="">Selecione uma opção</option>
          <option *ngFor="let item of listaContrPrevid" [value]="item.Valor">
            {{ item.Descricao }}
          </option>
        </select>
      </div>
      <div class="form-group col-5">
        <label for="Ultati">Último Cargo</label>
        <input
          class="form-control"
          placeholder="Último cargo"
          type="text"
          id="Ultati"
          formControlName="Ultati"
          [ngClass]="
            formTempoServico.get('dadosFuncionario').get('Ultati').invalid &&
            formTempoServico.get('dadosFuncionario').get('Ultati').touched
              ? 'is-invalid'
              : null
          "
        />
        <span
          class="form-text text-danger"
          *ngIf="
            formTempoServico.get('dadosFuncionario').get('Ultati').invalid &&
            formTempoServico.get('dadosFuncionario').get('Ultati').touched
          "
        >
          Campo Obrigatório
        </span>
      </div>

      <div class="form-group">
        <label for="CodCateg">Categoria</label>
        <select
          class="form-control"
          id="CodCateg"
          formControlName="CodCateg"
          [ngClass]="
            formTempoServico.get('dadosFuncionario').get('CodCateg').invalid &&
            formTempoServico.get('dadosFuncionario').get('CodCateg').touched
              ? 'is-invalid'
              : null
          "
        >
          <option value="">Selecione uma opção</option>
          <option *ngFor="let item of listaCodCateg" [value]="item.Id">
            {{ item.Id }} - {{ item.Nome }}
          </option>
        </select>
        <span
          class="form-text text-danger"
          *ngIf="
            formTempoServico.get('dadosFuncionario').get('CodCateg').invalid &&
            formTempoServico.get('dadosFuncionario').get('CodCateg').touched
          "
        >
          Campo Obrigatório
        </span>
      </div>
      <div
        class="form-group col-4 d-flex"
        [ngClass]="
          formTempoServico.get('dadosFuncionario').get('CodCateg').invalid &&
          formTempoServico.get('dadosFuncionario').get('CodCateg').touched
            ? 'align-items-center'
            : 'align-items-end'
        "
      >
      </div>
    </section>
  </fieldset>

  <fieldset>
    <legend>
      <h4>Dados de Averbação</h4>
    </legend>
    <section class="row" formGroupName="dadosAverbacao">
      <div class="form-group col-7">
        <label for="Nomemp">Empresa/Descrição</label>
        <input
          class="form-control"
          placeholder="Empresa/Descrição"
          type="text"
          id="Nomemp"
          formControlName="Nomemp"
          [ngClass]="
            formTempoServico.get('dadosAverbacao').get('Nomemp').invalid &&
            formTempoServico.get('dadosAverbacao').get('Nomemp').touched
              ? 'is-invalid'
              : null
          "
        />
        <span
          class="form-text text-danger"
          *ngIf="
            formTempoServico.get('dadosAverbacao').get('Nomemp').invalid &&
            formTempoServico.get('dadosAverbacao').get('Nomemp').touched
          "
        >
          Campo Obrigatório
        </span>
      </div>
      <div class="form-group col-5">
        <label for="EfeitosAverbacao">Efeitos da Averbação</label>
        <select
          class="form-control"
          id="EfeitosAverbacao"
          formControlName="EfeitosAverbacao"
          [ngClass]="
            formTempoServico.get('dadosAverbacao').get('EfeitosAverbacao').invalid &&
            formTempoServico.get('dadosAverbacao').get('EfeitosAverbacao').touched
              ? 'is-invalid'
              : null
          "
        >
          <option value="">Selecione uma opção</option>
          <option *ngFor="let item of listaEfeitoAverbacao" [value]="item.Codigo">
            {{ item.Codigo }} - {{ item.Descricao }}
          </option>
        </select>
        <span
          class="form-text text-danger"
          *ngIf="
            formTempoServico.get('dadosAverbacao').get('EfeitosAverbacao').invalid &&
            formTempoServico.get('dadosAverbacao').get('EfeitosAverbacao').touched
          "
        >
          Campo Obrigatório
        </span>
      </div>

      <div class="form-group col-7">
        <label for="TipoAverbacao">Tipo de Averbação</label>
        <select
          class="form-control"
          id="TipoAverbacao"
          formControlName="TipoAverbacao"
          [ngClass]="
            formTempoServico.get('dadosAverbacao').get('TipoAverbacao').invalid &&
            formTempoServico.get('dadosAverbacao').get('TipoAverbacao').touched
              ? 'is-invalid'
              : null
          "
        >
          <option value="">Selecione uma opção</option>
          <option *ngFor="let item of listaTipoAverbacao" [value]="item.Valor">
            {{ item.Valor }} - {{ item.Descricao }}
          </option>
        </select>
        <span
          class="form-text text-danger"
          *ngIf="
            formTempoServico.get('dadosAverbacao').get('TipoAverbacao').invalid &&
            formTempoServico.get('dadosAverbacao').get('TipoAverbacao').touched
          "
        >
          Campo Obrigatório
        </span>
      </div>
      <div class="form-group col-5">
        <label for="Averbado">Averbado</label>
        <select
          class="form-control"
          id="Averbado"
          formControlName="Averbado"
          [ngClass]="
            formTempoServico.get('dadosAverbacao').get('Averbado').invalid &&
            formTempoServico.get('dadosAverbacao').get('Averbado').touched
              ? 'is-invalid'
              : null
          "
        >
          <option value="">Selecione uma opção</option>
          <option *ngFor="let item of listaAverbado" [value]="item">
            {{ item }}
          </option>
        </select>
        <span
          class="form-text text-danger"
          *ngIf="
            formTempoServico.get('dadosAverbacao').get('Averbado').invalid &&
            formTempoServico.get('dadosAverbacao').get('Averbado').touched
          "
        >
          Campo Obrigatório
        </span>
      </div>

      <div class="form-group col-4">
        <label for="DtIni" class="font-weigth-semibold">Data Início</label>
        <input
          type="date"
          max="9999-12-31"
          class="form-control"
          id="DtIni"
          formControlName="DtIni"
          (change)="
            calcularDias(
              formTempoServico.get('dadosAverbacao').get('DtIni').value,
              formTempoServico.get('dadosAverbacao').get('DtFim').value
            )
          "
          [ngClass]="
            formTempoServico.get('dadosAverbacao').get('DtIni').invalid &&
            formTempoServico.get('dadosAverbacao').get('DtIni').touched
              ? 'is-invalid'
              : null
          "
        />
        <span
          class="form-text text-danger"
          *ngIf="
            formTempoServico.get('dadosAverbacao').get('DtIni').invalid &&
            formTempoServico.get('dadosAverbacao').get('DtIni').touched
          "
        >
          Campo Obrigatório
        </span>
      </div>
      <div class="form-group col-4">
        <label for="DtFim" class="font-weigth-semibold"
          >Data Término <small>(opcional)</small></label
        >
        <input
          type="date"
          max="9999-12-31"
          class="form-control"
          id="DtFim"
          formControlName="DtFim"
          (change)="
            calcularDias(
              formTempoServico.get('dadosAverbacao').get('DtIni').value,
              formTempoServico.get('dadosAverbacao').get('DtFim').value
            )
          "
        />
      </div>
      <div class="form-group col-4">
        <label for="NumQui">Qtd. Dias</label>
        <input
          class="form-control"
          placeholder="Dias"
          type="text"
          id="NumQui"
          formControlName="NumQui"
        />
      </div>
    </section>
  </fieldset>

  <fieldset>
    <legend>
      <h4>Dados da Publicação</h4>
    </legend>
    <section class="row" formGroupName="dadosPublicacao">
      <div class="form-group col-4">
        <label for="NumeroProcesso">Número do Processo <small>(opcional)</small></label>
        <input
          class="form-control"
          placeholder="Número do Processo"
          type="text"
          id="NumeroProcesso"
          formControlName="NumeroProcesso"
        />
      </div>
      <div class="form-group col-4">
        <label for="NumeroDoe">Número DOE <small>(opcional)</small></label>
        <input
          class="form-control"
          placeholder="Número DOE"
          type="text"
          id="NumeroDoe"
          formControlName="NumeroDoe"
        />
      </div>
      <div class="form-group col-4">
        <label for="DtDoe" class="font-weigth-semibold">Data DOE <small>(opcional)</small></label>
        <input
          type="date"
          max="9999-12-31"
          class="form-control"
          id="DtDoe"
          formControlName="DtDoe"
        />
      </div>

      <div class="form-group col-4">
        <label for="IdClassificacaoAto">Classificação do Ato <small>(opcional)</small></label>
        <select class="form-control" id="IdClassificacaoAto" formControlName="IdClassificacaoAto">
          <option value="">Selecione uma opção</option>
          <option *ngFor="let item of listaClassificacaoAto" [value]="item.Cod">
            {{ item.Cod }} - {{ item.Nome }}
          </option>
        </select>
      </div>
      <div class="form-group col-4">
        <label for="NumeroAto">Número Ato <small>(opcional)</small></label>
        <input
          class="form-control"
          placeholder="Número Ato"
          type="text"
          id="NumeroAto"
          formControlName="NumeroAto"
        />
      </div>
      <div class="form-group col-4">
        <label for="DtAto" class="font-weigth-semibold">Data Ato <small>(opcional)</small></label>
        <input
          type="date"
          max="9999-12-31"
          class="form-control"
          id="DtAto"
          formControlName="DtAto"
        />
      </div>
    </section>
  </fieldset>

  <fieldset>
    <legend>
      <h4>Dados da Instituição</h4>
    </legend>
    <section class="row" formGroupName="dadosInstituicao">
      <div class="form-group col-4">
        <label for="Cep">CEP</label>
        <a
          href="https://buscacepinter.correios.com.br/app/endereco/index.php"
          target="_blank"
          class="btn-link text-primary ml-1"
          data-toggle="tooltip"
          data-placement="left"
          title="Não sabe seu CEP?"
        >
          <i class="fa fa-envelope-open-o"></i>
        </a>
        <input
          class="form-control"
          placeholder="CEP"
          type="text"
          id="Cep"
          formControlName="Cep"
          (input)="aoMudarCep()"
          mask="00000-000"
          [ngClass]="
            formTempoServico.get('dadosInstituicao').get('Cep').invalid &&
            formTempoServico.get('dadosInstituicao').get('Cep').touched
              ? 'is-invalid'
              : null
          "
        />
        <span
          class="form-text text-danger"
          *ngIf="
            formTempoServico.get('dadosInstituicao').get('Cep').invalid &&
            formTempoServico.get('dadosInstituicao').get('Cep').touched
          "
        >
          Campo Obrigatório
        </span>
      </div>
      <div class="form-group col-8">
        <label for="Ender">Endereço</label>
        <input
          class="form-control"
          placeholder="Endereço"
          type="text"
          id="Ender"
          formControlName="Ender"
        />
      </div>

      <div class="form-group col-5">
        <label for="Cidade">Cidade</label>
        <input
          class="form-control"
          placeholder="Cidade"
          type="text"
          id="Cidade"
          formControlName="Cidade"
        />
      </div>
      <div class="form-group col-2">
        <label for="UF">UF</label>
        <input class="form-control" placeholder="UF" type="text" id="UF" formControlName="UF" />
      </div>
      <div class="form-group col-3">
        <label for="Bairro">Bairro</label>
        <input
          class="form-control"
          placeholder="Bairro"
          type="text"
          id="Bairro"
          formControlName="Bairro"
        />
      </div>
      <div class="form-group col-2">
        <label for="EnderNum">Nº</label>
        <input
          class="form-control"
          placeholder="Nº"
          type="text"
          id="EnderNum"
          formControlName="EnderNum"
          [ngClass]="
            formTempoServico.get('dadosInstituicao').get('EnderNum').invalid &&
            formTempoServico.get('dadosInstituicao').get('EnderNum').touched
              ? 'is-invalid'
              : null
          "
        />
        <span
          class="form-text text-danger"
          *ngIf="
            formTempoServico.get('dadosInstituicao').get('EnderNum').invalid &&
            formTempoServico.get('dadosInstituicao').get('EnderNum').touched
          "
        >
          Campo Obrigatório
        </span>
      </div>

      <div class="form-group col-4">
        <label for="Enderc">Complemento <small>(opcional)</small></label>
        <input
          class="form-control"
          placeholder="Complemento"
          type="text"
          id="Enderc"
          formControlName="Enderc"
        />
      </div>
      <div class="form-group col-4">
        <label for="Telefone">Telefone</label>
        <input
          class="form-control"
          placeholder="(00) 0000-0000"
          mask
          telefoneMask
          type="text"
          id="Telefone"
          formControlName="Telefone"
          [ngClass]="
            formTempoServico.get('dadosInstituicao').get('Telefone').invalid &&
            formTempoServico.get('dadosInstituicao').get('Telefone').touched
              ? 'is-invalid'
              : null
          "
        />
        <span
          class="form-text text-danger"
          *ngIf="
            formTempoServico.get('dadosInstituicao').get('Telefone').invalid &&
            formTempoServico.get('dadosInstituicao').get('Telefone').touched
          "
        >
          Campo Obrigatório
        </span>
      </div>
      <div class="form-group col-4">
        <label for="Cnpj">CNPJ</label>
        <input
          class="form-control"
          placeholder="00 000 000/0000-00"
          mask="00 000 000/0000-00"
          type="text"
          id="Cnpj"
          formControlName="Cnpj"
          [ngClass]="
            formTempoServico.get('dadosInstituicao').get('Cnpj').invalid &&
            formTempoServico.get('dadosInstituicao').get('Cnpj').touched
              ? 'is-invalid'
              : null
          "
        />
        <span
          class="form-text text-danger"
          *ngIf="
            formTempoServico.get('dadosInstituicao').get('Cnpj').invalid &&
            formTempoServico.get('dadosInstituicao').get('Cnpj').touched
          "
        >
          Campo Obrigatório
        </span>
      </div>
    </section>
  </fieldset>
</form>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="cadastro ? lancarTempoServico() : lancarTempoServico(servicoAtual)"
  >
    {{ cadastro ? 'Cadastrar' : 'Atualizar' }}
  </button>
</div>
