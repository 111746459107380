import { AbstractControl } from '@angular/forms';

export function maxDateValidator(maxDate: Date) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const selectedDate = new Date(control.value);

    if (!selectedDate || isNaN(selectedDate.getTime())) {
      return null;
    }

    if (selectedDate <= maxDate) {
      return null; 
    } else {
      return { maxDate: true }; 
    }
  };
}
