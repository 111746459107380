import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { PensaoPrevidenciaria } from './pensao-previdenciaria.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root',
})
export class PensaoPrevidenciariaService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any = null;
  filial: string = '';
  urlAcesso: any = this.acesso.validarUrl();

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  buscarParametros() {
    return this.http.get(`${this.urlAcesso}pensao-previdencia/parametros`, {
      headers: this.cabecalho,
    });
  }
  buscarExSegurado(matricula: string) {
    return this.http.get(
      `${this.urlAcesso}pensao-previdencia/ex-segurado/${this.filial}/${matricula}`,
      { headers: this.cabecalho }
    );
  }
  buscarCadastro(numOrdem) {
    return this.http.get(`${this.urlAcesso}cadastro/${numOrdem}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }
  buscarDependentes(matricula) {
    return this.http.get(`${this.urlAcesso}dependentes/${this.filial}/${matricula}`, {
      headers: this.cabecalho,
    });
  }
  salvarPensao(pensao: PensaoPrevidenciaria) {
    return this.http.post(
      `${this.urlAcesso}pensao-previdencia/${'000000'}/EM/${this.filial}`,
      pensao,
      { headers: this.cabecalho }
    );
  }
  editarRateios(rateios: any[]) {
    return this.http.put(
      `${this.urlAcesso}pensao-previdencia/alterar-todos-rateios/000000/EM/${this.filial}`,
      rateios,
      { headers: this.cabecalho }
    );
  }
  editarPensao(pensao: PensaoPrevidenciaria) {
    return this.http.put(`${this.urlAcesso}pensao-previdencia/000000/EM/${this.filial}`, pensao, {
      headers: this.cabecalho,
    });
  }
  buscarCep(cep: string) {
    return this.http.get(`${this.urlAcesso}cep/${cep}`, { headers: this.cabecalho });
  }
  buscarMunicipio(codigo: string) {
    return this.http.get(`${this.urlAcesso}estados/${codigo}`, { headers: this.cabecalho });
  }
  buscarBancos() {
    return this.http.get(`${this.urlAcesso}bancosComCodigoBco/`, { headers: this.cabecalho });
  }
  buscarAgencias(nomeBanco: any) {
    return this.http.get(`${this.urlAcesso}agencia/${nomeBanco}`, {
      headers: this.cabecalho,
    });
  }

  buscarTipoBeneficiario() {
    return this.http.get(`${this.urlAcesso}pensao-previdencia/tipo-beneficiario/`, {
      headers: this.cabecalho,
    });
  }

  buscarMotivoCessacao() {
    return this.http.get(`${this.urlAcesso}pensao-previdencia/motivo-cessacao/`, {
      headers: this.cabecalho,
    });
  }
}
