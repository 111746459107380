<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<header class="card">
  <div class="card-header">
    <h3 class="card-title font-weight-semibold" id="target">
      <a
        class="fa fa-arrow-left pr-3 text-dark btn"
        aria-hidden="true"
        routerLink="/colaboradores"
        style="cursor: pointer; bottom: 2px"
      ></a>
      Cadastro Complementar
    </h3>
  </div>

  <div class="card-body row">
    <div class="form-group col-md-2">
      <label class="justify-content-start font-weight-semibold">Matricula </label>
      <input
        type="text"
        placeholder="Funcionario"
        class="form-control"
        disabled
        [value]="infoColaborador.matricula"
      />
    </div>

    <div class="form-group col-md-6">
      <label class="justify-content-start font-weight-semibold">Nome do Colaborador </label>
      <input
        type="text"
        placeholder="Funcionario"
        class="form-control"
        disabled
        [value]="infoColaborador.nome"
      />
    </div>
    <div class="form-group col-md-4">
      <label class="justify-content-start font-weight-semibold">Vínculo </label>
      <input
        type="text"
        placeholder="Funcionario"
        class="form-control"
        disabled
        [value]="infoColaborador.vinculo"
      />
    </div>
    <!-- <div class="form-group col-md-3">
        <label class="justify-content-start font-weight-semibold">Situação </label>
        <input
          type="text"
          placeholder="Funcionario"
          class="form-control"
          disabled
          [value]="infoColaborador.situacao.Codigo + ' - ' + infoColaborador.situacao.Descricao"
        />
      </div> -->
  </div>
</header>

<main class="card">
  <form class="card-body mt-2" [formGroup]="formCadastro">
    <div class="row">
      <fieldset class="col-md-12">
        <legend>
          <h6 class="font-weight-semibold">Dados do Cadastro</h6>
        </legend>
        <div class="row">
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Data Falecimento <small>(opcional)</small></label>
            <input
              type="date"
              formControlName="dtFalecimento"
              class="form-control pr-0"
              max="9999-12-31"
              [ngClass]="
                formCadastro.get('dtFalecimento').invalid &&
                formCadastro.get('dtFalecimento').touched
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('dtFalecimento').invalid &&
                formCadastro.get('dtFalecimento').touched
              "
            >
              Campo Obrigatório
            </span>
          </div>
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold"
              >Certidão Falecimento <small>(opcional)</small></label
            >
            <input
              type="text"
              placeholder="Número da certidão"
              formControlName="certidaoFalecimento"
              class="form-control"
              [ngClass]="
                formCadastro.get('certidaoFalecimento').invalid &&
                formCadastro.get('certidaoFalecimento').touched
                  ? 'is-invalid'
                  : null
              "
              maxlength="40"
              mask="0*"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('certidaoFalecimento').invalid &&
                formCadastro.get('certidaoFalecimento').touched
              "
            >
              Campo Obrigatório
            </span>
          </div>
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Prev Complementar</label>
            <select
              class="form-control"
              formControlName="prevComplementar"
              [ngClass]="
                formCadastro.get('prevComplementar').invalid &&
                formCadastro.get('prevComplementar').touched
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option value="1">Sim</option>
              <option value="0">Não</option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('prevComplementar').invalid &&
                formCadastro.get('prevComplementar').touched
              "
            >
              Campo Obrigatório
            </span>
          </div>
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Número do Processo <small>(opcional)</small></label>
            <input
              type="text"
              placeholder="Número do processo"
              formControlName="processo"
              class="form-control"
              [ngClass]="
                formCadastro.get('processo').invalid && formCadastro.get('processo').touched
                  ? 'is-invalid'
                  : null
              "
              maxlength="80"
              mask="0*"
            />
            <span
              class="form-text text-danger"
              *ngIf="formCadastro.get('processo').invalid && formCadastro.get('processo').touched"
            >
              Campo Obrigatório
            </span>
          </div>
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Data Inicial Trab. <small>(opcional)</small></label>
            <input
              type="date"
              formControlName="dtInicioTrabalho"
              class="form-control pr-0"
              max="9999-12-31"
              [ngClass]="
                formCadastro.get('dtInicioTrabalho').invalid &&
                formCadastro.get('dtInicioTrabalho').touched
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('dtInicioTrabalho').invalid &&
                formCadastro.get('dtInicioTrabalho').touched
              "
            >
              {{
                formCadastro.get('dtInicioTrabalho').hasError('dataInvalida')
                  ? 'Data deve ser igual ou menor à data atual'
                  : 'Campo Obrigatório'
              }}
            </span>
          </div>
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold">Data Final Trab. <small>(opcional)</small></label>
            <input
              type="date"
              formControlName="dtFinalTrabalho"
              class="form-control pr-0"
              max="9999-12-31"
              [ngClass]="
                formCadastro.get('dtFinalTrabalho').invalid &&
                formCadastro.get('dtFinalTrabalho').touched
                  ? 'is-invalid'
                  : null
              "
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('dtFinalTrabalho').invalid &&
                formCadastro.get('dtFinalTrabalho').touched
              "
            >
              {{
                formCadastro.get('dtFinalTrabalho').hasError('dataInvalida')
                  ? 'Data deve ser igual ou menor à data atual'
                  : 'Campo Obrigatório'
              }}
            </span>
          </div>
        </div>
      </fieldset>
      <fieldset class="col-md-12">
        <legend>
          <h6 class="font-weight-semibold">Processo Judicial</h6>
        </legend>
        <div class="row" formGroupName="processoJudicial">
          <div class="col-md-6 form-group">
            <label class="font-weight-semibold">Ad. Noturno</label>
            <select
              class="form-control"
              formControlName="adNoturno"
              [ngClass]="
                formCadastro.get('processoJudicial').get('adNoturno').invalid &&
                formCadastro.get('processoJudicial').get('adNoturno').touched
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option value="0">0hs</option>
              <option value="1">100hs</option>
              <option value="2">120hs</option>
              <option value="3">150hs</option>
              <option value="4">200hs</option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('processoJudicial').get('adNoturno').invalid &&
                formCadastro.get('processoJudicial').get('adNoturno').touched
              "
            >
              Campo Obrigatório
            </span>
          </div>
          <div class="col-md-6 form-group">
            <label class="font-weight-semibold">Serv. Extra</label>
            <select
              class="form-control"
              formControlName="servExtr"
              [ngClass]="
                formCadastro.get('processoJudicial').get('servExtr').invalid &&
                formCadastro.get('processoJudicial').get('servExtr').touched
                  ? 'is-invalid'
                  : null
              "
            >
              <option value="">Selecione...</option>
              <option value="0">0hs</option>
              <option value="1">100hs</option>
              <option value="2">120hs</option>
              <option value="3">150hs</option>
              <option value="4">200hs</option>
            </select>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('processoJudicial').get('servExtr').invalid &&
                formCadastro.get('processoJudicial').get('servExtr').touched
              "
            >
              Campo Obrigatório
            </span>
          </div>
        </div>
      </fieldset>
    </div>
  </form>
  <div class="card-footer mt-4 justify-content-end d-flex">
    <button (click)="submitCadastro()" class="btn btn-primary">
      Submeter
      <i class="fa fa-paper-plane ml-2"></i>
    </button>
  </div>
</main>
