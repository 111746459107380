<div class="content d-flex justify-content-center align-items-center">
  <form class="login-form" #telainicial="ngForm" [formGroup]="formPesquisa">
    <div class="card bg-dark mb-0 text-ligth">
      <div class="card-body">
        <div class="text-center mb-3">
          <h5 class="mb-0">{{ titulo }}</h5>
        </div>
        <div *ngIf="!inputText" class="s-12 col-sm-12 col-md-12 col-lg-12 form-group">
          <label class="justify-content-start">Matrícula</label>
          <select class="form-control" type="text" formControlName="campo_pesquisa_matricula">
            <option value="">Selecione...</option>
            <option *ngFor="let objeto of lista" value="{{ objeto.Codigo }}">
              {{ objeto.Descricao }}
            </option>
          </select>
          <!-- <span class="form-text text-danger" *ngIf="formPesquisa.get('codServico_form').invalid ">
              Campo Obrigatório
            </span> -->
        </div>
        <div *ngIf="inputText">
          <label class="justify-content-start">Matrícula</label>
          <div class="input-group mb-2">
            <input
              formControlName="campo_pesquisa_matricula"
              type="text"
              mask="0*"
              class="form-control"
              placeholder="Matrícula"
              maxlength="9"
              (keyup.enter)="buscarColaborador(formPesquisa.get('campo_pesquisa_matricula').value)"
            />
            <div class="input-group-prepend">
              <button
                [disabled]="formPesquisa.get('campo_pesquisa_matricula').invalid"
                (click)="buscarColaborador(formPesquisa.get('campo_pesquisa_matricula').value, '')"
                type="button"
                class="btn bg-primary border-primary"
                id="basic-addon1"
              >
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- <div *ngIf="inputText">
          <label class="justify-content-start">Nome</label>
          <div class="input-group mb-3">
            <input
              formControlName="campo_pesquisa_nome"
              type="text"
              class="form-control"
              placeholder="Nome"
              (keyup.enter)="buscarColaborador(0, formPesquisa.get('campo_pesquisa_nome').value)"
            />
            <div class="input-group-prepend">
              <button
                [disabled]="formPesquisa.get('campo_pesquisa_nome').invalid"
                (click)="buscarColaborador(0, formPesquisa.get('campo_pesquisa_nome').value)"
                type="button"
                class="btn bg-primary border-primary"
                id="basic-addon1"
              >
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div> -->
        <div *ngIf="nenhumColaborador">
          <p class="text-center">Nenhum colaborador encontrado</p>
        </div>

        <div
          *ngFor="let colab of colaboradores; let i = index"
          (click)="mudarValor()"
          class="justify-content-center w-80 rounded-top rounded-bottom pt-2 mb-0 hoverable"
          style="background-color: #728098; margin-bottom: 10%; cursor: pointer"
        >
          <div class="row">
            <div class="col-4"></div>

            <div class="col-6">
              <img
                style="width: 5em"
                src="{{
                  colab.Sexo.Valor == '1' || colab.Sexo == '1'
                    ? 'assets/img/avatar-masculino.svg'
                    : 'assets/img/avatar-feminino.svg'
                }}"
                width="30"
                height="40"
                alt=""
              />
            </div>
            <div class="col-4"></div>
          </div>
          <div class="row pt-2">
            <div class="col-1"></div>
            <div class="col-10 text-center">
              <h4 class="h3 pb-2" style="font-size: 15px">
                {{ colab.NomeCompleto ? colab.NomeCompleto : colab.Nome }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
