<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<button
  type="button"
  id="btnRepLegal"
  class="btn d-none"
  data-toggle="modal"
  data-backdrop="static"
  data-keyboard="false"
  data-target="#repLegalModal"
>
  Representante Legal
</button>

<div class="modal fade" id="repLegalModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4
          class="modal-title"
          *ngIf="mostrarForm == false"
          id="repLegalModalLabel"
        >
          Representante Legal
        </h4>
        <h4
          class="modal-title"
          *ngIf="mostrarForm == true"
          id="repLegalModalLabel"
        >
          Cadastro Representante Legal
        </h4>
        <button
          type="button"
          class="close"
          (click)="fecharModalAdd()"
          id="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="card-body">
          <div class="row">
            <div class="input-group col-lg-12">
              <input
                type="search"
                placeholder="Buscar Representante Legal"
                class="form-control"
                name="searchRepLegal"
                value=""
                [(ngModel)]="searchRepLegal"
              />
              <span>
                <span class="btn btn-primary ml-1" (click)="abrirModalAdd()">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </span>
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="!loading" class="card-body">
          <div class="row">
            <div class="col-lg-12">
              <div
                class="table-responsive"
                style="overflow-y: auto; max-height: 400px"
              >
                <table
                  *ngIf="listaRepLegalFiltrada.length !== 0"
                  style="height: 120px"
                  class="table table-striped table-hover mb-0"
                >
                  <thead class="bg-teal-400">
                    <tr>
                      <th class="text-center">Número</th>
                      <th class="text-center">Nome</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    *ngFor="
                      let representante of listaRepLegalFiltrada;
                      let i = index
                    "
                  >
                    <tr>
                      <td class="row-md-1" style="text-align: center">
                        <b>{{ representante.NumeroRepresentante }}</b>
                      </td>
                      <td class="row-md-1" style="text-align: left">
                        <b>{{ representante.NomeRepresentante }}</b>
                      </td>
                      <td class="text-center">
                        <div class="list-icons">
                          <div class="list-icons-item dropdown">
                            <a
                              href="#"
                              class="list-icons-item dropdown-toggle caret-0"
                              data-toggle="dropdown"
                              ><i class="icon-menu7"></i
                            ></a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a
                                (click)="selecionarRepresentante(representante)"
                                class="dropdown-item"
                                ><i class="fa fa-check"></i> Selecionar</a
                              >
                              <a
                                (click)="editarRepresentante(representante)"
                                class="dropdown-item"
                                ><i class="fa fa-pencil"></i> Editar</a
                              >
                              <a
                                (click)="deletar(representante, i)"
                                class="dropdown-item"
                                ><i class="fa fa-trash"></i> Excluir</a
                              >
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="text-center font-weight-bold"
                  *ngIf="listaRepLegalFiltrada.length === 0"
                >
                  <p>Sem Representante</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="loading"
          class="row d-flex justify-content-center align-items-center"
        >
          <i class="fa fa-refresh fa-spin fa"></i>
        </div>
      </div>

      <div class="modal-body" *ngIf="mostrarForm">
        <form
          [formGroup]="formRepresentanteLegal"
          (ngSubmit)="submitRepresentante()"
          #representanteLegal="ngForm"
        >
          <div class="card-body wizard">
            <div class="steps clearfix pb-3">
              <ul role="tablist">
                <li
                  role="tab"
                  [ngClass]="{ current: step == 1, done: step > 1 }"
                  class="first"
                  aria-disabled="false"
                  aria-selected="true"
                >
                  <a
                    id="steps-uid-1-t-1"
                    aria-controls="steps-uid-1-p-1"
                    class=""
                    ><span class="current-info audible">current step: </span
                    ><span [ngClass]="{ pointer: step > 1 }" class="number"
                      >1</span
                    >
                    Dados Pessoais
                  </a>
                </li>
                <li
                  role="tab"
                  [ngClass]="{
                    current: step == 2,
                    disabled: step < 2,
                    done: step > 2
                  }"
                  aria-disabled="true"
                >
                  <a id="steps-uid-1-t-2" aria-controls="steps-uid-1-p-2"
                    ><span [ngClass]="{ pointer: step > 2 }" class="number"
                      >2</span
                    >
                    Endereço
                  </a>
                </li>
                <li
                  role="tab"
                  [ngClass]="{
                    current: step == 2,
                    disabled: step < 2,
                    done: step > 2
                  }"
                  aria-disabled="true"
                >
                  <a id="steps-uid-1-t-3" aria-controls="steps-uid-2-p-3"
                    ><span [ngClass]="{ pointer: step > 3 }" class="number"
                      >3</span
                    >
                    Dados Bancários
                  </a>
                </li>
              </ul>
            </div>

            <div
              *ngIf="step == 1"
              formGroupName="dadosPessoais"
              [style.display]="true ? 'block' : 'none'"
              id="steps-uid-0-p-0"
              role="tabpanel"
              aria-labelledby="steps-uid-0-h-0"
              class="body current"
              aria-hidden="false"
            >
              <div class="row">
                <div *ngIf="editar" class="col-md-3 form-group">
                  <label class="font-weight-semibold">Nº Representante</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="numeroRepresentante"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('numeroRepresentante').dirty) &&
                      formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('numeroRepresentante').status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                </div>
                <div
                  [ngClass]="{ 'col-md-9': editar, 'col-md-12': !editar }"
                  class="form-group"
                >
                  <label class="font-weight-semibold">Nome</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="nome"
                    placeholder="Nome"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal.get('dadosPessoais').get('nome')
                          .dirty) &&
                      formRepresentanteLegal.get('dadosPessoais').get('nome')
                        .status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro &&
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('nome')
                          .hasError('required')) ||
                      (formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('nome')
                        .hasError('required') &&
                        formRepresentanteLegal.get('dadosPessoais').get('nome')
                          .dirty)
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
                <!--Período-->
                <div class="col-md-6 form-group">
                  <label class="font-weight-semibold"
                    >Data Início <small>(Opcional)</small></label
                  >
                  <input
                    class="form-control"
                    formControlName="dataInicio"
                    type="date"
                    max="9999-12-31"
                    (keyup)="
                      verificaData(
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('dataInicio'),
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('dataFim')
                      )
                    "
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('dataInicio').dirty) &&
                      formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('dataInicio').status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('dataInicio')
                        .hasError('min') ||
                      (formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('dataInicio')
                        .hasError('max') &&
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('dataInicio').dirty)
                    "
                  >
                    Data inválida
                  </span>
                </div>
                <div class="col-md-6 form-group">
                  <label class="font-weight-semibold"
                    >Data Fim <small>(Opcional)</small></label
                  >
                  <input
                    class="form-control"
                    formControlName="dataFim"
                    type="date"
                    max="9999-12-31"
                    (keyup)="
                      verificaData(
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('dataInicio'),
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('dataFim')
                      )
                    "
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('dataFim').dirty) &&
                      formRepresentanteLegal.get('dadosPessoais').get('dataFim')
                        .status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('dataFim')
                        .hasError('min') ||
                      (formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('dataFim')
                        .hasError('max') &&
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('dataFim').dirty)
                    "
                  >
                    Data inválida
                  </span>
                </div>

                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Identidade</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="identidade"
                    placeholder="Identidade"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('identidade').dirty) &&
                      formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('identidade').status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('identidade')
                        .hasError('required')
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Órgão Expedidor</label>
                  <input
                    class="form-control expedidor"
                    type="text"
                    formControlName="orgaoExpedidor"
                    placeholder="Órgão Exped."
                    pattern="[A-Za-záãâéêíóôõúçÁÃÂÉÊÍÓÔÕÚÇ\s]+$"
                    (keypress)="somenteLetras($event)"
                    maxlength="5"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('orgaoExpedidor').dirty) &&
                      formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('orgaoExpedidor').status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro &&
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('orgaoExpedidor')
                          .hasError('required')) ||
                      (formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('orgaoExpedidor')
                        .hasError('required') &&
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('orgaoExpedidor').dirty)
                    "
                  >
                    Campo obrigatório
                  </span>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('orgaoExpedidor')
                        .hasError('pattern')
                    "
                  >
                    Campo só permite letras
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">UF</label>
                  <select
                    class="form-control"
                    type="text"
                    formControlName="ufExpedicao"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('ufExpedicao').dirty) &&
                      formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('ufExpedicao').status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  >
                    <option value="" disabled selected hidden>UF</option>
                    <option *ngFor="let uf of listaUf" value="{{ uf.SiglaUf }}">
                      {{ uf.SiglaUf }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro &&
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('ufExpedicao')
                          .hasError('required')) ||
                      (formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('ufExpedicao')
                        .hasError('required') &&
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('ufExpedicao').dirty)
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
                <div class="col-md-4 form-group">
                  <label class="font-weight-semibold">CPF</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="cpf"
                    placeholder="CPF"
                    mask="000.000.000-00"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal.get('dadosPessoais').get('cpf')
                          .dirty) &&
                      formRepresentanteLegal.get('dadosPessoais').get('cpf')
                        .status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro &&
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('cpf')
                          .hasError('required')) ||
                      (formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('cpf')
                        .hasError('required') &&
                        formRepresentanteLegal.get('dadosPessoais').get('cpf')
                          .dirty)
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
                <div class="col-md-6 form-group">
                  <label class="font-weight-semibold"
                    >Tipo de Representante</label
                  >
                  <select
                    class="form-control"
                    type="text"
                    formControlName="tipoRepresentante"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('tipoRepresentante').dirty) &&
                      formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('tipoRepresentante').status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option
                      *ngFor="let tipoRepresentante of listaTipoRepresentante"
                      value="{{ tipoRepresentante.Codigo }}"
                    >
                      {{ tipoRepresentante.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro &&
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('tipoRepresentante')
                          .hasError('required')) ||
                      (formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('tipoRepresentante')
                        .hasError('required') &&
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('tipoRepresentante').dirty)
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
                <div class="col-md-6 form-group">
                  <label class="font-weight-semibold">Telefone</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="telefone"
                    placeholder="Telefone"
                    mask
                    telefoneMask
                    maxlength="15"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('telefone').dirty) &&
                      formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('telefone').status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro &&
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('telefone')
                          .hasError('required')) ||
                      (formRepresentanteLegal
                        .get('dadosPessoais')
                        .get('telefone')
                        .hasError('required') &&
                        formRepresentanteLegal
                          .get('dadosPessoais')
                          .get('telefone').dirty)
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
              </div>
            </div>

            <div
              *ngIf="step == 2"
              formGroupName="endereco"
              [style.display]="true ? 'block' : 'none'"
              id="steps-uid-0-p-0"
              role="tabpanel"
              aria-labelledby="steps-uid-0-h-0"
              class="body current"
              aria-hidden="false"
            >
              <div class="row">
                <div class="col-md-3 form-group">
                  <div class="d-flex justify-content-between">
                    <label>CEP</label>
                    <a
                      href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                      target="_blank"
                      class="btn-link text-primary"
                      data-toggle="tooltip"
                      data-placement="left"
                      title="Não sabe seu CEP?"
                    >
                      <i class="fa fa-envelope-open-o"></i>
                    </a>
                  </div>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="cep"
                    placeholder="CEP"
                    mask="00000-000"
                    (keyup)="onCepChange()"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal.get('endereco').get('cep')
                          .dirty) &&
                      formRepresentanteLegal.get('endereco').get('cep')
                        .status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro &&
                        formRepresentanteLegal
                          .get('endereco')
                          .get('cep')
                          .hasError('required')) ||
                      (formRepresentanteLegal
                        .get('endereco')
                        .get('cep')
                        .hasError('required') &&
                        formRepresentanteLegal.get('endereco').get('cep').dirty)
                    "
                  >
                    Campo obrigatório
                  </span>
                  <span class="form-text" *ngIf="loadingCep"
                    >Carregando
                    <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
                  ></span>
                </div>
                <div class="col-md-7 form-group">
                  <label class="font-weight-semibold">Logradouro</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="logradouro"
                    placeholder="Logradouro"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal.get('endereco').get('logradouro')
                          .dirty) &&
                      formRepresentanteLegal.get('endereco').get('logradouro')
                        .status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formRepresentanteLegal
                        .get('endereco')
                        .get('logradouro')
                        .hasError('required')
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">Número</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="numeroEndereco"
                    placeholder="Nº"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal
                          .get('endereco')
                          .get('numeroEndereco').dirty) &&
                      formRepresentanteLegal
                        .get('endereco')
                        .get('numeroEndereco').status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro &&
                        formRepresentanteLegal
                          .get('endereco')
                          .get('numeroEndereco')
                          .hasError('required')) ||
                      (formRepresentanteLegal
                        .get('endereco')
                        .get('numeroEndereco')
                        .hasError('required') &&
                        formRepresentanteLegal
                          .get('endereco')
                          .get('numeroEndereco').dirty)
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
                <div class="col-md-5 form-group">
                  <label class="font-weight-semibold">Bairro</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="bairro"
                    placeholder="Bairro"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal.get('endereco').get('bairro')
                          .dirty) &&
                      formRepresentanteLegal.get('endereco').get('bairro')
                        .status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formRepresentanteLegal
                        .get('endereco')
                        .get('bairro')
                        .hasError('required')
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
                <div class="col-md-5 form-group">
                  <label class="font-weight-semibold">Cidade</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="cidade"
                    placeholder="Cidade"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal.get('endereco').get('cidade')
                          .dirty) &&
                      formRepresentanteLegal.get('endereco').get('cidade')
                        .status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formRepresentanteLegal
                        .get('endereco')
                        .get('cidade')
                        .hasError('required')
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">UF</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="uf"
                    placeholder="UF"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal.get('endereco').get('uf')
                          .dirty) &&
                      formRepresentanteLegal.get('endereco').get('uf').status ==
                        'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formRepresentanteLegal
                        .get('endereco')
                        .get('uf')
                        .hasError('required')
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
                <div class="col-md-5 form-group">
                  <label class="font-weight-semibold"
                    >Complemento <small>(Opcional)</small></label
                  >
                  <input
                    class="form-control"
                    type="text"
                    formControlName="complemento"
                    placeholder="Complemento"
                    maxlength="30"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal
                          .get('endereco')
                          .get('complemento').dirty) &&
                      formRepresentanteLegal.get('endereco').get('complemento')
                        .status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formRepresentanteLegal
                        .get('endereco')
                        .get('complemento')
                        .hasError('required')
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
              </div>
            </div>

            <div
              *ngIf="step == 3"
              formGroupName="dadosBancarios"
              [style.display]="true ? 'block' : 'none'"
              id="steps-uid-0-p-0"
              role="tabpanel"
              aria-labelledby="steps-uid-0-h-0"
              class="body current"
              aria-hidden="false"
            >
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="font-weight-semibold">Banco</label>
                  <select
                    class="form-control"
                    type="text"
                    formControlName="banco"
                    (change)="buscarOperacao()"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal
                          .get('dadosBancarios')
                          .get('banco').dirty) &&
                      formRepresentanteLegal.get('dadosBancarios').get('banco')
                        .status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option
                      *ngFor="let banco of listaBancos"
                      value="{{ banco.Codigo }}"
                    >
                      {{ banco.Nome }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formRepresentanteLegal
                        .get('dadosBancarios')
                        .get('banco')
                        .hasError('required')
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
                <div class="col-md-6 form-group">
                  <label class="font-weight-semibold">Operação</label>
                  <select
                    class="form-control"
                    type="text"
                    formControlName="operacao"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal
                          .get('dadosBancarios')
                          .get('operacao').dirty) &&
                      formRepresentanteLegal
                        .get('dadosBancarios')
                        .get('operacao').status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option
                      *ngFor="let operacao of listaOperacao"
                      value="{{ operacao.Codigo }}"
                    >
                      {{ operacao.Codigo }} - {{ operacao.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formRepresentanteLegal
                        .get('dadosBancarios')
                        .get('operacao')
                        .hasError('required')
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
                <div class="col-md-2 form-group">
                  <label class="font-weight-semibold">Agência</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="agencia"
                    placeholder="Ag."
                    mask="0*"
                    maxlength="5"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal
                          .get('dadosBancarios')
                          .get('agencia').dirty) &&
                      formRepresentanteLegal
                        .get('dadosBancarios')
                        .get('agencia').status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formRepresentanteLegal
                        .get('dadosBancarios')
                        .get('agencia')
                        .hasError('required')
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
                <div class="col-md-4 form-group">
                  <label class="font-weight-semibold">Nº Conta</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="numeroConta"
                    placeholder="Nº Conta"
                    (keydown)="mask()"
                    mask="{{ contaMask }}"
                    [ngStyle]="
                      (temErro ||
                        formRepresentanteLegal
                          .get('dadosBancarios')
                          .get('numeroConta').dirty) &&
                      formRepresentanteLegal
                        .get('dadosBancarios')
                        .get('numeroConta').status == 'INVALID'
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formRepresentanteLegal
                        .get('dadosBancarios')
                        .get('numeroConta')
                        .hasError('required')
                    "
                  >
                    Campo obrigatório
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="card-footer wizard">
            <div class="actions clearfix">
              <ul role="menu" aria-label="Pagination" class="col-lg-20">
                <li
                  *ngIf="step > 1 && step <= 3"
                  class="disabled"
                  style="text-align: left"
                  aria-disabled="true"
                  class="col-lg-8"
                >
                  <button
                    style="min-width: 113px"
                    type="button"
                    (click)="step = step - 1"
                    class="btn btn-danger"
                  >
                    <i class="icon-arrow-left13 mr-2"></i>
                    Anterior
                  </button>
                </li>
                <li *ngIf="step < 3" aria-hidden="false" aria-disabled="false">
                  <button
                    style="min-width: 113px"
                    type="submit"
                    class="btn btn-primary"
                    role="menuitem"
                  >
                    Próximo <i class="icon-arrow-right14 ml-2"></i>
                  </button>
                </li>
                <li *ngIf="step == 3" aria-hidden="true">
                  <button
                    style="min-width: 113px"
                    type="submit"
                    class="btn btn-primary"
                    role="menuitem"
                  >
                    Submeter <i class="icon-arrow-right14 ml-2"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
