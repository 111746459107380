<header>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title font-weight-semibold" id="target">
        <a
          class="fa fa-arrow-left pr-3 text-dark btn"
          aria-hidden="true"
          routerLink="/colaboradores"
          style="cursor: pointer; bottom: 2px"
        ></a>
        Estrangeiro
      </h3>
    </div>

    <div class="card-body row">
      <div class="form-group col-md-2">
        <label class="justify-content-start font-weight-semibold">Matricula</label>
        <input
          type="text"
          placeholder="Funcionario"
          class="form-control"
          disabled
          [value]="infoColaborador.matricula"
        />
      </div>

      <div class="form-group col-md-6">
        <label class="justify-content-start font-weight-semibold">Nome do Colaborador</label>
        <input
          type="text"
          placeholder="Funcionario"
          class="form-control"
          disabled
          [value]="infoColaborador.nome"
        />
      </div>

      <div class="form-group col-md-4">
        <label class="justify-content-start font-weight-semibold">Vínculo</label>
        <input
          type="text"
          placeholder="Funcionario"
          class="form-control"
          disabled
          [value]="infoColaborador.vinculo"
        />
      </div>
    </div>
  </div>

  <div class="card">
    <form novalidate class="card-body mt-2 ng-untouched ng-pristine ng-valid">
      <div class="row">
        <fieldset class="col-md-12">
          <legend>
            <h6 class="font-weight-semibold">Dados Cadastrais</h6>
          </legend>

          <form [formGroup]="formEstrangeiro">
            <div class="row">
              <div class="col-md-6 form-group">
                <div class="row">
                  <div class="col-md-8 form-group">
                    <label class="font-weight-semibold">País *</label>
                    <select
                      class="form-control"
                      formControlName="ResidEstrangPaisResid"
                      [ngClass]="
                        (formEstrangeiro.get('ResidEstrangPaisResid').invalid ||
                          formEstrangeiro.get('ResidEstrangPaisResid').value == 0) &&
                        formEstrangeiro.get('ResidEstrangPaisResid').touched
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option [value]="0">Selecione...</option>
                      <option *ngFor="let pais of paises" [value]="pais.Codigo">
                        {{ pais.Descricao }}
                      </option>
                    </select>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (formEstrangeiro.get('ResidEstrangPaisResid').invalid ||
                          formEstrangeiro.get('ResidEstrangPaisResid').value == 0) &&
                        formEstrangeiro.get('ResidEstrangPaisResid').touched
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>

                  <div class="col-md-4 form-group">
                    <label class="font-weight-semibold">Tipo Logradouro</label>
                    <select class="form-control" formControlName="ResidEstrangTpLograd">
                      <option [value]="0">Selecione...</option>
                      <option *ngFor="let logradouro of logradouros" [value]="logradouro.Codigo">
                        {{ logradouro.Descricao }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-8 form-group">
                    <label class="font-weight-semibold">Logradouro</label>
                    <input
                      class="form-control"
                      placeholder="Nome do logradouro"
                      formControlName="ResidEstrangDscLograd"
                    />
                  </div>

                  <div class="col-md-4 form-group">
                    <label class="font-weight-semibold">Número</label>
                    <input
                      class="form-control"
                      placeholder="Nº do endereço"
                      formControlName="ResidEstrangNrLograd"
                    />
                  </div>

                  <div class="col-md-6 form-group">
                    <label class="font-weight-semibold">Complemento</label>
                    <input
                      class="form-control"
                      placeholder="Ex.: CASA"
                      formControlName="ResidEstrangComplemento"
                    />
                  </div>

                  <div class="col-md-6 form-group">
                    <label class="font-weight-semibold">Bairro</label>
                    <input
                      class="form-control"
                      placeholder="Nome do bairro"
                      formControlName="ResidEstrangBairro"
                    />
                  </div>

                  <div class="col-md-6 form-group">
                    <label class="font-weight-semibold">Cidade</label>
                    <input
                      class="form-control"
                      placeholder="Nome da cidade"
                      formControlName="ResidEstrangNmCidade"
                    />
                  </div>

                  <div class="col-md-6 form-group">
                    <label class="font-weight-semibold">Província/Estado</label>
                    <input
                      class="form-control"
                      placeholder="Nome da Província/Estado"
                      formControlName="ResidEstrangNmEstado"
                    />
                  </div>

                  <div class="col-md-6 form-group">
                    <label class="font-weight-semibold">Código Postal *</label>
                    <input
                      class="form-control"
                      placeholder="Código Postal"
                      formControlName="ResidEstrangCodPostal"
                      [ngClass]="
                        (formEstrangeiro.get('ResidEstrangCodPostal').invalid ||
                          formEstrangeiro.get('ResidEstrangCodPostal').value == null) &&
                        formEstrangeiro.get('ResidEstrangCodPostal').touched
                          ? 'is-invalid'
                          : null
                      "
                    />

                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (formEstrangeiro.get('ResidEstrangCodPostal').invalid ||
                          formEstrangeiro.get('ResidEstrangCodPostal').value == null) &&
                        formEstrangeiro.get('ResidEstrangCodPostal').touched
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>

                  <div class="col-md-6 form-group">
                    <label class="font-weight-semibold">Telefone</label>
                    <input
                      oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                      class="form-control"
                      placeholder="Telefone"
                      formControlName="ResidEstrangTelef"
                      maxlength="15"
                      
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6 form-group">
                <div class="card shadow" style="background-color: #f6f6f6">
                  <div class="card-header">
                    <h5 class="card-title">Receita do Brasil</h5>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 form-group">
                        <label class="font-weight-semibold">Código da Receita *</label>
                        <select
                          class="form-control"
                          formControlName="ResidEstrangCodReceita"
                          [ngClass]="
                            (formEstrangeiro.get('ResidEstrangCodReceita').invalid ||
                              formEstrangeiro.get('ResidEstrangCodReceita').value == 0) &&
                            formEstrangeiro.get('ResidEstrangCodReceita').touched
                              ? 'is-invalid'
                              : null
                          "
                        >
                          <option [value]="0">Selecione...</option>
                          <option *ngFor="let receita of receitas" [value]="receita.Codigo">
                            {{ receita.Descricao }}
                          </option>
                        </select>
                        <span
                          class="form-text text-danger"
                          *ngIf="
                            (formEstrangeiro.get('ResidEstrangCodReceita').invalid ||
                              formEstrangeiro.get('ResidEstrangCodReceita').value == 0) &&
                            formEstrangeiro.get('ResidEstrangCodReceita').touched
                          "
                        >
                          Campo Obrigatório
                        </span>
                      </div>

                      <div class="col-md-12 form-group">
                        <label class="font-weight-semibold">Tipo de Rendimento *</label>
                        <select
                          class="form-control"
                          formControlName="ResidEstrangTipoRendimento"
                          [ngClass]="
                            (formEstrangeiro.get('ResidEstrangTipoRendimento').invalid ||
                              formEstrangeiro.get('ResidEstrangTipoRendimento').value == 0) &&
                            formEstrangeiro.get('ResidEstrangTipoRendimento').touched
                              ? 'is-invalid'
                              : null
                          "
                        >
                          <option [value]="0">Selecione...</option>
                          <option
                            *ngFor="let rendimento of rendimentos"
                            [value]="rendimento.Codigo"
                          >
                            {{ rendimento.Descricao }}
                          </option>
                        </select>
                        <span
                          class="form-text text-danger"
                          *ngIf="
                            (formEstrangeiro.get('ResidEstrangTipoRendimento').invalid ||
                              formEstrangeiro.get('ResidEstrangTipoRendimento').value == 0) &&
                            formEstrangeiro.get('ResidEstrangTipoRendimento').touched
                          "
                        >
                          Campo Obrigatório
                        </span>
                      </div>

                      <div class="col-md-12 form-group">
                        <label class="font-weight-semibold">Forma de Tributação *</label>
                        <select
                          class="form-control"
                          formControlName="ResidEstrangFrmTribut"
                          [ngClass]="
                            (formEstrangeiro.get('ResidEstrangFrmTribut').invalid ||
                              formEstrangeiro.get('ResidEstrangFrmTribut').value == 0) &&
                            formEstrangeiro.get('ResidEstrangFrmTribut').touched
                              ? 'is-invalid'
                              : null
                          "
                        >
                          <option [value]="0">Selecione...</option>
                          <option
                            *ngFor="let tributacao of tributacoes"
                            [value]="tributacao.Codigo"
                          >
                            {{ tributacao.Descricao }}
                          </option>
                        </select>
                        <span
                          class="form-text text-danger"
                          *ngIf="
                            (formEstrangeiro.get('ResidEstrangFrmTribut').invalid ||
                              formEstrangeiro.get('ResidEstrangFrmTribut').value == 0) &&
                            formEstrangeiro.get('ResidEstrangFrmTribut').touched
                          "
                        >
                          Campo Obrigatório
                        </span>
                      </div>

                      <div class="col-md-6 form-group">
                        <label class="font-weight-semibold">NIF *</label>
                        <select
                          class="form-control"
                          formControlName="ResidEstrangIndNif"
                          [ngClass]="
                            (formEstrangeiro.get('ResidEstrangIndNif').invalid ||
                              formEstrangeiro.get('ResidEstrangIndNif').value == 0) &&
                            formEstrangeiro.get('ResidEstrangIndNif').touched
                              ? 'is-invalid'
                              : null
                          "
                        >
                          <option [ngValue]="null">Selecione...</option>
                          <option [ngValue]="1">1 - Dispensado do NIF</option>
                          <option [ngValue]="2">2 - País não exige</option>
                          <option [ngValue]="3">3 - Informar NIF</option>
                        </select>
                        <span
                          class="form-text text-danger"
                          *ngIf="
                            (formEstrangeiro.get('ResidEstrangIndNif').invalid ||
                              formEstrangeiro.get('ResidEstrangIndNif').value == 0) &&
                            formEstrangeiro.get('ResidEstrangIndNif').touched
                          "
                        >
                          Campo Obrigatório
                        </span>
                      </div>

                      <div class="col-md-6 form-group">
                        <label class="font-weight-semibold">Informar: NIF</label>
                        <input
                          type="text"
                          class="form-control"
                          formControlName="ResidEstrangNifBenef"
                          placeholder="Informar o NIF"
                          [ngClass]="{
                            'is-invalid':
                              formEstrangeiro.get('ResidEstrangNifBenef').invalid &&
                              formEstrangeiro.get('ResidEstrangNifBenef').touched
                          }"
                        />
                        <span
                          class="form-text text-danger"
                          *ngIf="
                            formEstrangeiro.get('ResidEstrangNifBenef').invalid &&
                            formEstrangeiro.get('ResidEstrangNifBenef').touched
                          "
                        >
                          Campo Obrigatório
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </fieldset>
      </div>
    </form>

    <div class="card-footer d-flex justify-content-end">
      <button class="btn btn-primary" (click)="enviarCadastro()">
        Submeter <i class="fa fa-paper-plane ml-2" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</header>
