import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Experiencia, ItemExp} from '../experiencia-profissional.component';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert';

@Component({
  selector: 'app-experiencia-profissional-cadastro',
  templateUrl: './experiencia-profissional-cadastro.component.html',
  styleUrls: ['./experiencia-profissional-cadastro.component.css'],
})
export class ExperienciaProfissionalCadastroComponent implements OnChanges {
  @Input() titulo: string;
  @Input() items: any[];
  @Input() listaExperiencias: Experiencia[] = [];
  @Input() numord: number;
  @Input() listaAvaliacoes: any[] = [];

  listaSelecionados: any[] = [];

  constructor(private spinner: NgxSpinnerService) {}
  ngOnChanges(changes: SimpleChanges) {
    if (this.listaExperiencias.length > 0 && this.items.length > 0) {
      this.spinner.show();
      console.log('lista exp filho', this.listaExperiencias, changes);
      this.listaExperiencias.forEach((exp) => {
        if (
          (this.titulo == 'Cursos' && exp.Tipo == 'CURSOS') ||
          (this.titulo == 'Habilidades' && exp.Tipo == 'HABILIDADE') ||
          (this.titulo == 'Funções' && exp.Tipo == 'FUNCAO DESEJADA') ||
          (this.titulo == 'Atividades' && exp.Tipo == 'ATIVIDADE') ||
          (this.titulo == 'Requisitos' && exp.Tipo == 'REQUISITO')
        ) {
          const index = this.listaSelecionados.findIndex((item) => item.Codigo === exp.Codigo);
          if (index === -1) {
            this.listaSelecionados.push(exp);
          }
        }
      });
      console.log('lista selec', this.listaSelecionados);

      this.spinner.hide();
    }
  }

  resetar() {
    console.log('resetar func');
    this.listaExperiencias = [];
    this.items = [];
    this.listaSelecionados = [];
  }

  marcarItem(evento, item) {
    let estavaMarcado = this.listaSelecionados.some(
      (itemLista) => itemLista.Codigo === item.Codigo
    );
    if (evento.target.checked && !estavaMarcado) {
      let itemMontado = this.montarItem(item);
      this.listaSelecionados.push(itemMontado);
    }
    if (!evento.target.checked && estavaMarcado) {
      let index = this.listaSelecionados.findIndex((itemLista) => itemLista.Codigo === item.Codigo);
      this.listaSelecionados.splice(index, 1);
    }
  }

  mudarAvaliacao(evento, item) {
    let index = this.listaSelecionados.findIndex(
      (itemLista) => itemLista.Codigo == item.Codigo && itemLista.Tipo == 'CURSOS'
    );
    if (index != -1) {
      this.listaSelecionados[index].Avaliacao = evento.target.value;
    } else {
      Swal('Erro', 'Não foi possível adicionar a avaliação desse curso.', 'error');
    }
  }

  opcaoMarcada(codigo: string) {
    const existeItem = this.listaSelecionados.some((item) => item.Codigo === codigo);
    if (this.titulo == 'Cursos' && existeItem) {
      let selectAvaliacao = document.getElementById(
        this.titulo.toLowerCase() + 'avaliacao' + codigo
      ) as HTMLSelectElement;
      if (selectAvaliacao != null) {
        const itemEncontrado = this.listaSelecionados.find((item) => item.Codigo === codigo);
        selectAvaliacao.value = itemEncontrado.Avaliacao;
      }
    }

    return existeItem;
  }

  montarItem(item: ItemExp): Experiencia {
    let itemMontado = {
      Avaliacao: '',
      CodCan: '0000000000',
      CodTof: '00000000000000',
      Codigo: item.Codigo,
      Numord: this.numord,
      Sigla: 'FAPP',
      Tipo: this.retornarTipo(),
    };
    return itemMontado;
  }

  retornarTipo(): string {
    if (this.titulo == 'Cursos') {
      return 'CURSOS';
    }
    if (this.titulo == 'Habilidades') {
      return 'HABILIDADE';
    }
    if (this.titulo == 'Funções') {
      return 'FUNCAO DESEJADA';
    }
    if (this.titulo == 'Atividades') {
      return 'ATIVIDADE';
    }
    if (this.titulo == 'Requisitos') {
      return 'REQUISITO';
    }
  }

  extrairSelecionados(): any[] {
    return this.listaSelecionados;
  }
}
