<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<fieldset *ngIf="items.length > 0">
  <legend class="d-flex justify-content-between">
    <h4>{{ titulo }}</h4>
    <i
      class="fa fa-angle-down btn"
      aria-hidden="true"
      style="cursor: pointer; bottom: 2px"
      data-toggle="collapse"
      [attr.data-target]="'#collapse' + titulo"
    ></i>
  </legend>
  <div class="row col-12 collapse" [id]="'collapse' + titulo">
    <div class="col-4" *ngFor="let item of items">
      <div class="card p-1">
        <div class="card-body">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              [value]="item.Codigo"
              [id]="titulo.toLowerCase() + item.Codigo"
              (click)="marcarItem($event, item)"
              [checked]="opcaoMarcada(item.Codigo)"
            />

            <label class="form-check-label" [for]="titulo.toLowerCase() + item.Codigo">
              {{ item.Descricao }}
            </label>
          </div>

          <!-- Adicionando select de Avaliações se o titulo for 'Cursos' -->
          <div *ngIf="titulo === 'Cursos'" class="mt-2">
            <select
              class="form-select"
              (change)="mudarAvaliacao($event, item)"
              [id]="titulo.toLowerCase() + 'avaliacao' + item.Codigo"
              [disabled]="!opcaoMarcada(item.Codigo)"
            >
              <option *ngFor="let av of listaAvaliacoes" [value]="av.Codigo">
                {{ av.Descricao == '' ? 'NENHUMA' : av.Descricao }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</fieldset>
