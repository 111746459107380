import {Menu} from './Menu';
import {Filial} from './Filial';
export class LocalStorage {
  Sessao: string;
  NomeFilialAtual: string;
  NomeUsuario: string;
  CodigoServico: string;
  Ambiente: string;
  TipoUsuario: string;
  Toogle: string;
  Login: boolean;
  ClientePublico: boolean;
  Loading: boolean;
  FilialPrevidenciaria: boolean;
  FilialAtual: number;
  NumeroOrdemUsuario: number;
  CodigoUsuario: string;
  ClienteId: number;
  MatriculaUsuario: number;
  Menu: Menu[] = [];
  Filiais: Filial[] = [];
  Cpf: string;
  //_grecaptcha: string;
  constructor() {}
}
