import {Data} from 'src/app/service/interfaces/Data';

export class Nacionalidade {
  Codigo: number | string = '';
  Nome: string;
  DataDeChegada?: Data = new Data();
  Naturalizado?: number;
  CasadoComBrasileiro?: number;
  FilhoComBrasileiro?: number;
  constructor() {}
}
