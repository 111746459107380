<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" name="ferias" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<div class="card">
	<div class="card-header header-elements-inline">
		<h3 class="card-title">Férias</h3>
		<div class="header-elements">
			<!-- componente de duvida -->
		</div>
	</div>
	<div class="card-body">
		<div class="form-group row">
			<div class="input-group col-12">
				<input type="text" class="form-control" name="searchText" value=""
					placeholder="Pesquisar por colaborador ou matrícula" [(ngModel)]="searchText">
					<span class="input-group-append">
						<span class="input-group-text"><i class="fa fa-search"></i></span>
					  </span>

			</div>

			 <!-- <div class="col-md-2 mt-1">
				<app-relatorio-ferias-setor> </app-relatorio-ferias-setor>
			</div>  -->
			
			

		</div>


		<ul class="nav nav-tabs nav-tabs-highlight nav-justified">
			<li class="nav-item">
				<a href="#highlighted-justified-tab1" class="nav-link active" data-toggle="tab">
					Solicitações ({{(listaFerias | situacaoFeriasFiltro: 14).length }})
				</a>
			</li>

			<li class="nav-item">
				<a href="#highlighted-justified-tab3" class="nav-link" data-toggle="tab">
					Aprovadas ({{(listaFerias | situacaoFeriasFiltro: 1 ).length }})
				</a>
			</li>
			<li class="nav-item">
				<a href="#highlighted-justified-tab4" class="nav-link" data-toggle="tab">
					Rejeitadas ({{(listaFerias | situacaoFeriasFiltro: 15).length }})
				</a>
			</li>
		</ul>

		<div class="tab-content">
			<div class="tab-pane fade show active" id="highlighted-justified-tab1">
				<div *ngIf="(listaFerias | situacaoFeriasFiltro: 14).length > 0" class="col-12 table-responsive">
					<div class="row form-group text-center">
						<table class="table table-striped table-hover mb-0">
							<thead>
								<tr class="text-center" style="white-space:nowrap">
									<th style="text-align:left;">Colaborador</th>
									<th>Descanso</th>
									<th>Adiantar 13º</th>
									<th>Vender 10 Dias</th>
									<th>Início Descanso</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let colaborador of listaFerias | pesquisaFeriasFiltro: searchText | situacaoFeriasFiltro: 14; let i = index"
									(click)="carregarColaborador(colaborador)" style=" cursor: pointer;"
									data-toggle="modal" data-target="#modalColaborador">

									<td style="text-align:left;">
										<div class="row">{{ colaborador.DadosColaborador.NomeColaborador}}</div>
										<div class="row">
											Matrícula: {{ colaborador.DadosColaborador.Matricula}}
										</div>
										<div class="row">
											{{colaborador.DadosColaborador.NomeSetor}}/{{colaborador.DadosColaborador.NomeCargo}}
										</div>
									</td>
									<td>
										{{ colaborador.Vender10Dias ? 20 : 30 }}
									</td>
									<td>
										{{colaborador.Adiantar13? "Sim" : "Não"}}
									</td>
									<td>
										{{ colaborador.Vender10Dias ? "Sim" : "Não" }}
									</td>
									<td>
										{{ colaborador.Parcelas[0].Inicio.ToDate }}
									</td>

								</tr>
							</tbody>
						</table>

					</div>
				</div>
				<div class="text-center font-weight-bold" *ngIf="(listaFerias | situacaoFeriasFiltro: 14).length === 0">
					<p>Sem férias pedentes</p>
				</div>
			</div>

			<div class="tab-pane fade" id="highlighted-justified-tab2">
				<div class="col-12 table-responsive">
					<div class="row form-group text-center">
						<table class="table table-striped table-hover mb-0">
							<thead>
								<tr class="text-center" style="white-space:nowrap">
									<th style="text-align:left;">Colaborador</th>
									<th>Saldo de Férias</th>
									<th>Início da Concessão</th>
									<th>Limite do Aviso</th>
									<th>Limite para a Saída</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let colaborador of listaFerias | pesquisaFeriasFiltro: searchText | situacaoFeriasFiltro: 1; let i = index"
									(click)="carregarColaborador(colaborador)" data-toggle="modal"
									data-target="#modalColaboradorFerias" style=" cursor: pointer;">

									<td style="text-align:left;">
										<div class="row">
											{{ colaborador.DadosColaborador.NomeColaborador}}
										</div>
										<div class="row">
											Matrícula: {{ colaborador.DadosColaborador.Matricula}}
										</div>
										<div class="row">
											{{colaborador.DadosColaborador.NomeSetor}}/{{colaborador.DadosColaborador.NomeCargo}}
										</div>
									</td>
									<td>
										{{colaborador.Vencimento.Inicio.ToDate}}
									</td>
									<td>
										inicio
										<!-- {{colaborador.situacaoFerias.InicioConcessao}} -->
									</td>
									<td>
										limite aviso
										<!-- {{ colaborador.situacaoFerias.LimiteAviso }} -->
									</td>
									<td>
										limite saida
										<!-- {{ colaborador.situacaoFerias.LimiteSaida }} -->
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="tab-pane fade" id="highlighted-justified-tab3">
				<div *ngIf="(listaFerias | situacaoFeriasFiltro: 1).length > 0" class="col-12 table-responsive">
					<div class="row form-group text-center">
						<table class="table table-striped table-hover mb-0">
							<thead>
								<tr class="text-center" style="white-space:nowrap">
									<th style="text-align:left;">Colaborador</th>
									<th>Descanso</th>
									<th>Adiantar 13º</th>
									<th>Vender 10 Dias</th>
									<th>Início Descanso</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let colaborador of listaFerias | pesquisaFeriasFiltro: searchText | situacaoFeriasFiltro:3; let i = index"
									(click)="carregarColaborador(colaborador)" data-toggle="modal"
									data-target="#modalColaborador" style=" cursor: pointer;">

									<td style="text-align:left;">
										<div class="row">
											{{ colaborador.DadosColaborador.NomeColaborador }}
										</div>
										<div class="row">
											Matrícula: {{ colaborador.DadosColaborador.Matricula }}
										</div>
										<div class="row">
											{{colaborador.DadosColaborador.NomeSetor}}/{{colaborador.DadosColaborador.NomeCargo}}
										</div>
									</td>
									<td>
										{{ colaborador.Vender10Dias ? 20 : 30 }}
									</td>
									<td>
										{{colaborador.Adiantar13? "Sim" : "Não"}}
									</td>
									<td>
										{{ colaborador.Vender10Dias ? "Sim" : "Não" }}
									</td>
									<td>
										{{ colaborador.Parcelas[0].Inicio.ToDate }}
									</td>

								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="text-center font-weight-bold" *ngIf="(listaFerias | situacaoFeriasFiltro: 1).length === 0">
					<p>Sem férias aprovadas</p>
				</div>
			</div>

			<div class="tab-pane fade" id="highlighted-justified-tab4">
				<div *ngIf="(listaFerias | situacaoFeriasFiltro: 15).length > 0" class="col-12 table-responsive">
					<div class="row form-group text-center">
						<table class="table table-striped table-hover mb-0">
							<thead>
								<tr class="text-center" style="white-space:nowrap">
									<th style="text-align:left;">Colaborador</th>
									<th>Descanso</th>
									<th>Adiantar 13º</th>
									<th>Vender 10 Dias</th>
									<th>Início Descanso</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let colaborador of listaFerias | pesquisaFeriasFiltro: searchText | situacaoFeriasFiltro: 15; let i = index"
									(click)="carregarColaborador(colaborador)" data-toggle="modal"
									data-target="#modalColaborador" style=" cursor: pointer;">

									<td style="text-align:left;">
										<div class="row">
											{{ colaborador.DadosColaborador.NomeColaborador}}
										</div>
										<div class="row">
											Matrícula: {{ colaborador.DadosColaborador.Matricula}}
										</div>
										<div class="row">
											{{colaborador.DadosColaborador.NomeSetor}}/{{colaborador.DadosColaborador.NomeCargo}}
										</div>
									</td>
									<td>
										{{ colaborador.Vender10Dias ? 20 : 30 }}
									</td>
									<td>
										{{colaborador.Adiantar13? "Sim" : "Não"}}
									</td>
									<td>
										{{ colaborador.Vender10Dias ? "Sim" : "Não" }}
									</td>
									<td>
										{{ colaborador.Parcelas[0].Inicio.ToDate }}
									</td>

								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="text-center font-weight-bold" *ngIf="(listaFerias | situacaoFeriasFiltro: 15).length === 0">
					<p>Sem férias recusada</p>
				</div>
			</div>
		</div>
	</div>
</div>


<!-- Modal Colaborador -->
<div *ngIf="colaboradorAtual" id="modalColaborador" class="modal fade" data-backdrop="static" data-keyboard="false"
	tabindex="-1">
	<div class="modal-dialog modal-dialog-scrollable modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<div class="row">

					<div class="col-11 ">
						<div class="row ml-1">
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
								<h5 class="modal-title">{{ colaboradorAtual.DadosColaborador.NomeColaborador }}</h5>
							</div>
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<label class="font-weight-semibold mb-0">Matrícula: {{
									colaboradorAtual.DadosColaborador.Matricula }} </label>
							</div>
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
								<label class="font-weight-semibold mb-0">{{
									colaboradorAtual.DadosColaborador.NomeSetor}}/{{colaboradorAtual.DadosColaborador.NomeCargo
									}}</label>
							</div>
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<label class="font-weight-semibold mb-0">Dias disponíveis: {{
									colaboradorAtual.DiasDireito }} </label>
							</div>
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<label class="font-weight-semibold mb-0">Faltas: {{ colaboradorAtual.Faltas}}
									dias</label>
							</div>
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<label class="font-weight-semibold mb-0">Período aquisitivo: {{
									colaboradorAtual.Vencimento.Inicio.ToDate}} a {{
									colaboradorAtual.Vencimento.Fim.ToDate}} </label>
							</div>
							<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<label class="font-weight-semibold mb-0">Competência:
									{{colaboradorAtual.Parcelas[0].Competencia == null ? "" :
									colaboradorAtual.Parcelas[0].Competencia.slice(4,6) + "/" +
									colaboradorAtual.Parcelas[0].Competencia.slice(0,4) }} </label>
							</div>
						</div>

					</div>

				</div>
				<button type="button" (click)="limparCampos()" id="closeModalbt" class="close"
					data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<form #SolicitarFerias="ngForm" [formGroup]="formFerias" (ngSubmit)="submitFerias()">
					<div class="modal-body">
						<ul class="nav nav-tabs">
							<li class="nav-item">
								<a class="nav-link active" data-toggle="tab" href="#dados-ferias">
									<i class="fa fa-user"></i>&nbsp;
									<span>Dados Ferias</span>
								</a>
							</li>
							<li *ngIf="clientePublico" class="nav-item">

								<a class="nav-link" data-toggle="tab" href="#portaria">
									<i class="fa fa-file"></i>&nbsp;
									<span>Ato/Portaria/Diário</span>
								</a>
							</li>
						</ul>

						<div class="tab-content" id="nav-tabContent">
							<div class="tab-pane fade show active" id="dados-ferias">
								<div class="row">

									<div class="col-md-3 form-group">
										<label class="font-weight-semibold">Adiantar 13º?</label>
										<!-- "disabled" removido do select -->
										<select class="form-control" formControlName="adiantar13"
											[ngClass]="((temErro || formFerias.get('adiantar13').dirty) && formFerias.get('adiantar13').invalid) ? 'is-invalid' : null">
											<option value="">Selecione...</option>
											<option [value]=false>Não</option>
											<option [value]=true>Sim</option>
										</select>


									</div>

									<div class="col-md-3 form-group">
										<label class="font-weight-semibold">Parcelar Férias?</label>
										<!-- "disabled" removido do select -->
										<select class="form-control" formControlName="parcela"
											[ngClass]="((temErro || formFerias.get('parcela').dirty) && formFerias.get('parcela').invalid) ? 'is-invalid' : null">
											<option value="">Selecione...</option>
											<option [value]="1">Não</option>
											<option [value]="2">Sim</option>
										</select>

									</div>

									<div class="col-md-3 form-group">
										<label class="font-weight-semibold">Vender 10 Dias?</label>
										<!-- "disabled" removido do select -->
										<select class="form-control" formControlName="venderDias" 
											(change)="somarDias() && limparCamposAoVender()"
											[ngClass]="((temErro || formFerias.get('venderDias').dirty) && formFerias.get('venderDias').invalid) ? 'is-invalid' : null">
											<option value="">Selecione...</option>
											<option [value]=false>Não</option>
											<option [value]=true>Sim</option>
										</select>
										<span class="form-text text-danger"
											*ngIf="temErro && formFerias.get('venderDias').hasError('required')">
											Campo obrigatório
										</span>
									</div>

									<div *ngIf="enableMotivo" class="col-md-12 form-group">
										<label class="font-weight-semibold">Motivo de rejeição</label>

										<textarea type="text" class="form-control" placeholder="Motivo de rejeição"
											formControlName="descricao"
											[ngClass]="((temErro || formFerias.get('descricao').dirty) && formFerias.get('descricao').invalid) ? 'is-invalid' : null"></textarea>
									</div>


									<fieldset class="col-12">
										<!-- MUDAR ISSO È PRA ENTREGAR MUDAR DEPOIS PLEASE  -->
										<legend *ngIf="!colaboradorAtual" class="font-weight-semibold"> Férias</legend>
										<div class="row" *ngIf="!colaboradorAtual">

											<div class="col-md-4  form-group">
												<label class="font-weight-semibold">Data Início</label>
												<input readonly type="text" class="form-control"
													(keyup)="calculaData(onKeyUp($event.target))" mask="00/00/0000"
													formControlName="inicioFerias" max="9999-12-31"
													placeholder="dd/mm/aaaa"
													[ngClass]="((temErro || formFerias.get('inicioFerias').dirty) && formFerias.get('inicioFerias').invalid) ? 'is-invalid' : null" />
												<span class="form-text text-danger"
													*ngIf="temErro && formFerias.get('inicioFerias').hasError('required')">
													Campo Obrigatório
												</span>
											</div>
											<div class="col-md-4  form-group">
												<label class="font-weight-semibold">Data Fim</label>
												<input readonly type="text" class="form-control" mask="00/00/0000"
													placeholder="dd/mm/aaaa" formControlName="fimFerias"
													max="9999-12-31"
													[ngClass]="((temErro || formFerias.get('fimFerias').dirty) && formFerias.get('fimFerias').invalid) ? 'is-invalid' : null" />
												<span class="form-text text-danger"
													*ngIf="temErro && formFerias.get('fimFerias').hasError('required')">
													Campo Obrigatório
												</span>
											</div>

											<div class="col-md-3  form-group">
												<label class="font-weight-semibold">Dias de Férias</label>
												<input readonly type="text" mask="0*" placeholder="Dias"
													(keyup)="calculaData(onKeyUp($event.target))" maxlength="2"
													class="form-control" formControlName="diasFerias"
													[ngClass]="((temErro || formFerias.get('diasFerias').dirty) && formFerias.get('diasFerias').invalid) ? 'is-invalid' : null" />
												<label class="text-danger"
													*ngIf="formFerias.get('diasFerias').hasError('min')">
													O Valor mínimo é de 5 dias
												</label>
												<label class="text-danger"
													*ngIf="formFerias.get('diasFerias').hasError('max')">
													O Valor máximo é de 30 dias
												</label>
												<span class="form-text text-danger"
													*ngIf="temErro && formFerias.get('diasFerias').hasError('required')">
													Campo Obrigatório
												</span>
											</div>

										</div>

									</fieldset>

									<div class="table-responsive" style="max-height:45vh">

										<table *ngIf="colaboradorAtual != null && colaboradorAtual.Parcelas.length > 0"
											class="table table-striped table-hover">
											<thead class="text-center">
												<tr class="text-center" style="white-space:nowrap">
													<th class="sticky-top  bg-white">Dias Férias</th>
													<th class="sticky-top  bg-white">Data Início</th>
													<th class="sticky-top  bg-white">Data Final</th>
													<th class="sticky-top  bg-white">Situação</th>

												</tr>
											</thead>
											<tbody id="lista-processos">
												<tr *ngFor="let parcela of colaboradorAtual.Parcelas; index as i"
													class="text-center" style="white-space:nowrap">
													<td>{{parcela.Dias}}</td>
													<td>{{parcela.Inicio.ToDate}}</td>
													<td>{{parcela.Fim.ToDate}}</td>
													<td *ngIf="parcela.Editavel">


														<select
															(change)="setarMotivoRejeicaoObrigatorio($event,parcela,i)"
															class="form-control" id="codigoSituacao{{i+1}}" disabled>
															<option [value]="14"
																[selected]="parcela.Status.Codigo === '14'">
															</option>
															<option [value]="1"
																[selected]="parcela.Status.Codigo === '1' || parcela.Status.Codigo === '13'">
																Aprovada
															</option>
															<option [value]="15"
																[selected]="parcela.Status.Codigo === '15'">Rejeitada
															</option>

														</select>
													</td>
													<td *ngIf="!parcela.Editavel">{{parcela.Status.Descricao}}</td>
												</tr>
											</tbody>
										</table>
									</div>

								</div>
							</div>
							<div *ngIf="clientePublico" class="tab-pane fade" id="portaria">
								<div class="row">

									<fieldset class="col-12">
										<legend class="font-weight-semibold">Ato/Portaria <small>(opcional)</small>
										</legend>
										<div class="row">
											<div class="col-md-3 form-group">
												<label class="font-weight-semibold">Classificação</label>
												<!-- "disabled" removido do select -->
												<select class="form-control" formControlName="classificacao" 
													[ngClass]="((temErro || formFerias.get('classificacao').dirty) && formFerias.get('classificacao').invalid) ? 'is-invalid' : null">
													<option value="">Selecione...</option>
													<option *ngFor="let classificacao of listaClassificacao"
														value="{{classificacao.Codigo}}">{{classificacao.Descricao}}
													</option>
												</select>
												<span class="form-text text-danger"
													*ngIf="temErro && formFerias.get('classificacao').hasError('required')">
													Campo Obrigatório
												</span>
											</div>

											<div class="col-md-4 form-group">
												<label class="font-weight-semibold">Número</label>
												<input readonly type="text" class="form-control" placeholder="Número"
													formControlName="numeroAto" maxlength="50"
													[ngClass]="((temErro || formFerias.get('numeroAto').dirty) && formFerias.get('numeroAto').invalid) ? 'is-invalid' : null" />
												<span class="form-text text-danger"
													*ngIf="temErro && formFerias.get('numeroAto').hasError('required')">
													Campo Obrigatório
												</span>
											</div>



											<div class="col-md-3 form-group">
												<label class="font-weight-semibold">Data</label>
												<input readonly type="text" class="form-control" mask="00/00/0000"
													placeholder="dd/mm/aaaa" formControlName="dataAto"
													[ngClass]="((temErro || formFerias.get('dataAto').dirty) && formFerias.get('dataAto').invalid) ? 'is-invalid' : null" />
												<span class="form-text text-danger"
													*ngIf="temErro && formFerias.get('dataAto').hasError('required')">
													Campo Obrigatório
												</span>
												<span class="form-text text-danger"
													*ngIf="temErro && formFerias.get('dataAto').hasError('data')">
													Data Inválida
												</span>
											</div>

										</div>
									</fieldset>
									<fieldset class="col-12" *ngIf="clientePublico">
										<legend class="font-weight-semibold">Diário <small>(opcional)</small></legend>
										<div class="row">
											<div class="col-md-4 form-group">
												<label class="font-weight-semibold">Número DOE </label>
												<input readonly type="text" class="form-control" placeholder="Número"
													formControlName="numeroDiarioOficial" maxlength="50"
													[ngClass]="((temErro || formFerias.get('numeroDiarioOficial').dirty) && formFerias.get('numeroDiarioOficial').invalid) ? 'is-invalid' : null" />
												<span class="form-text text-danger"
													*ngIf="temErro && formFerias.get('numeroDiarioOficial').hasError('required')">
													Campo obrigatório
												</span>
											</div>
											<div class="col-md-4  form-group">
												<label class="font-weight-semibold">Data DOE</label>
												<input readonly type="text" class="form-control" mask="00/00/0000"
													placeholder="dd/mm/aaaa" formControlName="dataDiarioOficial"
													[ngClass]="((temErro || formFerias.get('dataDiarioOficial').dirty) && formFerias.get('dataDiarioOficial').invalid) ? 'is-invalid' : null" />
												<span class="form-text text-danger"
													*ngIf="temErro && formFerias.get('dataDiarioOficial').hasError('required')">
													Campo obrigatório
												</span>
												<span class="form-text text-danger"
													*ngIf="temErro && formFerias.get('dataDiarioOficial').hasError('data')">
													Data inválida
												</span>
											</div>
										</div>
									</fieldset>
								</div>

							</div>
						</div>
					</div>
					
				</form>

			</div>
		</div>
	</div>
</div>