import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { Contato } from './../cadastro/interface/contato';
import { Ferias } from './interface-ferias/ferias';
import { Dependente } from './classe/dependente';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AcessoService } from '../../service/acesso/acesso.service';
import { CadastroPerfil } from 'src/app/service/classes/profile/CadastroPerfil';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  localStorage: LocalStorage = new LocalStorage();

  matriculaSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  matricula$: Observable<string> = this.matriculaSubject.asObservable();

  headers = new HttpHeaders();
  cabecalho: any = null;
  filial: string = '';
  matricula: string = '';
  url_acesso: any = this.acesso.validarUrl();

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  AlterarSenha(body: any) {
    return this.http.put(`${this.url_acesso}logins`, body, {
      headers: this.cabecalho,
    });
  }
  AlterarCadastroPerfil(dados: CadastroPerfil) {
    return this.http.put(`${this.url_acesso}perfil/000000000/EM/${this.filial}/`, dados, {
      headers: this.cabecalho,
    });
  }
  SalvarDependente(dados: Dependente) {
    return this.http.post(`${this.url_acesso}dependentes`, dados, {
      headers: this.cabecalho,
    });
  }
  AtualizarDependente(dados: Dependente) {
    return this.http.put(`${this.url_acesso}dependentes`, dados, {
      headers: this.cabecalho,
    });
  }
  SalvarFerias(dadosFerias: Ferias[]) {
    return this.http.put(`${this.url_acesso}ferias/000000000/EF/${this.filial}/`, dadosFerias, {
      headers: this.cabecalho,
    });
  }
  enviarEmail(cadastro: any) {
    return this.http.post(`${this.url_acesso}cadastro/email`, cadastro, {
      headers: this.cabecalho,
    });
  }

  // BuscarFerias(matricula) {
  //   return this.http.get(
  //     `${this.url_acesso}ferias/abertas/000000000/EF/${this.filial}/${matricula}`,
  //     {headers: this.cabecalho}
  //   );
  // }

  buscarFeriasColaborador(matricula) {
    return this.http.get(`${this.url_acesso}ferias/historico/${this.filial}/${matricula}`, {
      headers: this.cabecalho,
    });
  }
  buscarCor() {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}parametros/cor_pele`, {
      headers: this.cabecalho,
    });
  }

  buscarColaboradorAtivo(pesquisa: string) {
    return this.http.get(`${this.url_acesso}cadastro/${pesquisa}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarTipoDependencia() {
    return this.http.get(`${this.url_acesso}tiposdependentes`, {
      headers: this.cabecalho,
    });
  }
  buscarDependentes(matricula) {
    return this.http.get(`${this.url_acesso}dependentes/${this.filial}/${matricula}`, {
      headers: this.cabecalho,
    });
  }
  buscarSexo() {
    return this.http.get(`${this.url_acesso}parametros/sexo`, {
      headers: this.cabecalho,
    });
  }

  buscarTipoSanquineo() {
    return this.http.get(`${this.url_acesso}parametros/tipo_sang`, {
      headers: this.cabecalho,
    });
  }

  buscarEstado() {
    return this.http.get(`${this.url_acesso}estados/`, {
      headers: this.cabecalho,
    });
  }

  buscarCidade(codigo) {
    return this.http.get(`${this.url_acesso}estados/${codigo}`, {
      headers: this.cabecalho,
    });
  }

  buscarCadastro(numOrdem) {
    return this.http.get(`${this.url_acesso}cadastro/${numOrdem}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarCadastroMatricula(matricula) {
    return this.http.get(`${this.url_acesso}cadastro/${matricula}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  criarCredenciais(dadosUsuario: object) {
    return this.http.post(this.url_acesso + `colaborador/usuario`, dadosUsuario, {
      headers: this.cabecalho,
    });
  }

  getDocumentos(matricula: string) {
    return this.http.get(`${this.url_acesso}uploadarquivo/${matricula}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarExisteUsuario(cpf: string) {
    return this.http.get(this.url_acesso + `colaborador/usuario/${cpf}/1`, {
      headers: this.cabecalho,
    });
  }

  salvarMatricula(matricula: string) {
    this.matriculaSubject.next(matricula);
  }

  retornarMatricula(): Observable<string> {
    return this.matricula$;
  }
}
