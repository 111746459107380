import { Component } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ExcelService } from "src/app/service/arquivo/excel.service";
import { LocalStorage } from "src/app/service/classes/localstorage/LocalStorage";
import { Crypto } from "src/app/service/classes/crypto/crypto";
import Swal from "sweetalert";

@Component({
  selector: 'app-censo-pendentes',
  templateUrl: './censo-pendentes.component.html',
  styleUrls: ['./censo-pendentes.component.css']
})
export class CensoPendentesComponent {
  localStorage: LocalStorage;
  searchText: string = "";
  listaCensos: any[] = [
    {
      sexo: "1",
      colaborador: "ADRIANO FRANCELINO DA SILVA",
      matricula: "118",
      cpf: "047.683.394-92",
      status: "Bloqueado",



    },
    {
      sexo: "0",
      colaborador: "WILMA MARIA NOBREGA LIMA",
      matricula: "156",
      cpf: "222.885.464-68",
      status: "Abstenção",

    },
    {
      sexo: "0",
      colaborador: "MARISTELA BARBOSA",
      matricula: "254",
      cpf: "162.678.184-20",
      status: "Bloqueado",

    },
    {
      sexo: "1",
      colaborador: "Carlos Eduardo Rocha",
      matricula: "319",
      cpf: "123.456.789-01",
      status: "Bloqueado",
    },
    {
      sexo: "0",
      colaborador: "Juliana Martins Silva",
      matricula: "320",
      cpf: "234.567.890-12",
      status: "Abstenção",
    },
    {
      sexo: "1",
      colaborador: "Rodrigo Nascimento",
      matricula: "321",
      cpf: "345.678.901-23",
      status: "Bloqueado",
    },
    {
      sexo: "0",
      colaborador: "Ana Paula Ferreira",
      matricula: "322",
      cpf: "456.789.012-34",
      status: "Abstenção",
    },
    {
      sexo: "1",
      colaborador: "Felipe Souza",
      matricula: "323",
      cpf: "567.890.123-45",
      status: "Bloqueado",
    },
    {
      sexo: "0",
      colaborador: "Fernanda Lima",
      matricula: "324",
      cpf: "678.901.234-56",
      status: "Abstenção",
    },
    {
      sexo: "1",
      colaborador: "Ricardo Almeida",
      matricula: "325",
      cpf: "789.012.345-67",
      status: "Bloqueado",
    }
  ];
  listaFiltrada: any[];
  censoSelecionado: any;
  marcarTodos: boolean;
  ordemLetra: number = 0;
  ordemNumero: number = 0;


  constructor(
    private spinner: NgxSpinnerService,
    private excelService: ExcelService
  ) { }

  ngOnInit() {
    this.selecionarTodos();
    this.localStorage = this.decryptLocalStorage();
    this.listaFiltrada = this.listaCensos;
  }

  retornarTamanhoListagem(): number {
    return this.listaFiltrada.length;
  }

  selecionarTodos(): void {
    let elements = document.querySelectorAll(
      "#filtro-form input[type=checkbox]"
    );

    elements.forEach((element: HTMLInputElement) => {
      if (!element.checked) {
        element.click();
      }
    });
  }

  desmarcarTodos(): void {
    let elements = document.querySelectorAll(
      "#filtro-form input[type=checkbox]"
    );

    elements.forEach((element: HTMLInputElement) => {
      if (element.checked) {
        element.click();
      }
    });
  }

  habilitarFiltros(): void {
    this.spinner.show();


    let checkboxes = document.querySelectorAll("#filtro-form input[type=checkbox]");
    let filtrosSelecionados = {
      status: [],
      genero: [],
    };

    checkboxes.forEach((element: HTMLInputElement) => {
      if (element.checked) {
        if (element.value === "bloqueado" || element.value === "abstenção") {

          filtrosSelecionados.status.push(element.value.charAt(0).toUpperCase() + element.value.slice(1));
        }
        if (element.value === "masculino") {
          filtrosSelecionados.genero.push("1");
        }
        if (element.value === "feminino") {
          filtrosSelecionados.genero.push("0");
        }
      }
    });

    this.listaFiltrada = this.listaCensos.filter((element) => {
      const filtroStatus = filtrosSelecionados.status.length === 0 ||
        (element.status && filtrosSelecionados.status.includes(element.status));

      const filtroGenero = filtrosSelecionados.genero.length === 0 ||
        (element.sexo && filtrosSelecionados.genero.includes(element.sexo));

      return filtroStatus && filtroGenero;
    });

    this.spinner.hide();

  }


  resetarOrdem(ordem: string): void {
    if (ordem !== "ordemLetra") {
      this.ordemLetra = 0;
    }

    if (ordem !== "ordemNumero") {
      this.ordemNumero = 0;
    }
  }

  ordenarPorLetra(): void {
    if (this.ordemLetra == 0 || this.ordemLetra == 2) {
      this.listaFiltrada = this.listaFiltrada.sort((a, b) =>
        a.colaborador.localeCompare(b.colaborador)
      );
      this.ordemLetra = 1;
    } else {
      this.listaFiltrada = this.listaFiltrada.sort((a, b) =>
        b.colaborador.localeCompare(a.colaborador)
      );
      this.ordemLetra = 2;
    }
    this.resetarOrdem("ordemLetra");
  }



  ordemPorNumero(): void {
    if (this.ordemNumero == 0 || this.ordemNumero == 2) {
      this.listaFiltrada = this.listaFiltrada.sort(
        (a, b) => a.matricula - b.matricula
      );
      this.ordemNumero = 1;
    } else {
      this.listaFiltrada = this.listaFiltrada.sort(
        (a, b) => b.matricula - a.matricula
      );
      this.ordemNumero = 2;
    }
    this.resetarOrdem("ordemNumero");
  }

  exportAsXLSX(): void {
    this.spinner.show();
    let censosData: any[] = [];

    this.listaCensos.forEach((item) => {
      censosData.push({
        SEXO: item.sexo === "1" ? "Masculino" : "Feminino",
        COLABORADOR: item.colaborador,
        MATRICULA: item.matricula,
        CPF: item.cpf,
        STATUS: item.status
      });
    });


    this.excelService.exportAsExcelFile(censosData, "Relatório dos Censos");
    this.spinner.hide();
  }




  exportAsPDF(): void {
    window.onbeforeprint = () => {
      document.title = "Listagem de censo";
    };
    window.print();
    window.onafterprint = () => {
      document.title = "Elógica";
    };
  }




  alterarStatusTodos(novoStatus: boolean): void {

    Swal({
      title: "Confirmação",
      text: "Tem certeza de que deseja alterar o status do bloqueio de todos os itens?",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true,
        },
      },
    }).then((willChange) => {
      if (willChange) {

        this.listaCensos.forEach(item => {
          item.isLocked = novoStatus;
          item.status = novoStatus ? "Bloqueado" : "Abstenção"; 
        });


        Swal({
          title: "Alterado!",
          text: "O status do bloqueio foi alterado.",
          icon: "success",
        });
      }
    });
  }


  alterarStatusIndividual(item, status: string): void {
    item.status = status;

  }

  toggleLock(item): void {
    item.isLocked = !item.isLocked;

  }

  selecionarItem(item: any): void {
    Swal({
      title: "Confirmação",
      text: "Tem certeza de que deseja alterar o status do bloqueio do item?",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true,
        },
      },
    }).then((willChange) => {
      if (willChange) {
        
        item.status = item.status === "Bloqueado" ? "Abstenção" : "Bloqueado";
      
        this.censoSelecionado = item;
   
        Swal({
          title: "Alterado!",
          text: "O status do bloqueio foi alterado.",
          icon: "success",
        });
      }
    });
  }
  


  excluirCenso() {
    Swal({
      title: "Confirmação de Exclusão",
      text: "Tem certeza de que deseja confirmar a exclusão? Esta ação não poderá ser desfeita.",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        Swal({
          title: "Censo Excluído",
          icon: "success",
        });
      }
    });
  }



  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(
        new Crypto().decryptUsingAES256(localStorage.getItem("util"))
      );
    }
  }
}
