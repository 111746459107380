import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfiguracaoService } from './configuracao-cliente.service';
import { ErroService } from 'src/app/service/erros/erro.service';
import { Validacao } from 'src/app/service/global/validacao/validacao.service';
import Swal from 'sweetalert';
import { AlertaModal } from 'src/app/componentes/componentes-funcionais/alerta-modal/alerta-modal.component';

@Component({
  selector: 'app-configuracao-cliente',
  templateUrl: './configuracao-cliente.component.html',
  styleUrls: ['./configuracao-cliente.component.css']
})
export class ConfiguracaoClienteComponent implements OnInit {

  servicosSelecionados: any[] = []; // objeto que sera enviado para o back
  listaServicos: any[] = [];
  listaClientes: any[];

  validaErro: any;
  spinner: any;
  formServicoCliente: any;
  public step: number = 0;
  public stepcount: number = 0;
  contadorPag : number

  campoObrigatorio: boolean
  liberarBt: boolean = true
  constructor(public fb:UntypedFormBuilder, private configuracaoService: ConfiguracaoService) {}

  ngOnInit() {
    this.formServicoCliente = this.fb.group({
      cb: ([Validators.required]),
    });
    this.contadorPag = 1
    this.buscarServicos()
  }

  buscarClientes(): void{
    this.spinner.show();

    this.configuracaoService.buscarClientesPse()
      .subscribe(
        (data: any[]) => {
          this.listaClientes = data
          this.spinner.hide();
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        }
      );
  }
  submitServicoCliente(): void { }
  buscarServicos(): void{
    // this.spinner.show();

    this.configuracaoService.buscarServicosPse()
      .subscribe(
        (data: any[]) => {
          this.listaServicos = data
          // this.spinner.hide();
        },
        error => {
          // new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          // this.spinner.hide();
        }
      );
  }

  onChangeProvince(servico: string[], isChecked) {
    if(isChecked instanceof Event){
      isChecked = (isChecked.target as HTMLInputElement).checked;
    }
    if (isChecked) {
        this.servicosSelecionados.push(servico);
    } else {
        const index = this.servicosSelecionados.indexOf(servico);
        this.servicosSelecionados.splice(index, 1);
    }
    if(this.servicosSelecionados.length > this.listaServicos.length) {
      this.servicosSelecionados.pop()
    }
  }

  passartela2Dois(){
    if(this.formServicoCliente.get('cb').value) {
      this.contadorPag = 2
      this.step = 1
      this.campoObrigatorio = false
    }else{
      this.campoObrigatorio = true
      new AlertaModal('alerta','Atenção!','Preencha todos os campos obrigatórios!','p')
    }
  }

  passarTelaTres(){
    this.contadorPag = 3
    this.step = 2

  }
  voltarTelaUm(){
    this.contadorPag = 1
    this.step = 0
  }

  voltarTelaDois(){
    this.contadorPag = 2
    this.step = 1
  }
  salvar(){
    this.liberarBt = false;
    new AlertaModal('sucesso',"Sucesso!", "Concluído",'p')
  }

}
