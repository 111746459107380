<div>
  <div class="card-header">
    <div class="d-flex justify-content-between">
      <h5 class="card-title text-uppercase text-muted">
        <i class="fa fa-smile-o"></i>&nbsp;
        <span>Férias</span>
      </h5>
      <!-- <button id="solicitarBt" *ngIf="!btSolicitarFerias && !loading && !ColaboradorInativo"
        (click)="limparCamposSolicitar()" class="btn btn-outline-warning btn-sm" data-toggle="modal"
        data-target="#myModal" data-backdrop="static" type="button">
        <i class="fa fa-pencil-square-o"></i>&nbsp;
        <span> Solicitar </span>
      </button>


    Inativo por enquanto-->
    </div>
    <hr class="mb-0" />
  </div>
  <div class="card-body">
    <div *ngIf="!loading && listarFerias">
      <div *ngIf="listarFerias.length > 0">
        <table class="table table-responsive table-striped shadow-sm">
          <thead>
            <tr>
              <th class="text-muted">Status</th>
              <th class="text-muted">Data de Pagamento</th>
              <th class="text-muted">Saldo</th>
              <th class="text-muted">Visualizar</th>
              <!--<th class="text-muted">Vencimento</th>
              <th class="text-muted">Próximo Vencimento</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let fer of listarFerias; let i = index">
              <td>{{ fer.Parcelas[0].Status.Descricao }}</td>
              <td>
                {{ formatarDtComp(fer.Parcelas[0].Competencia) }}
              </td>
              <td>{{ fer.DiasDireito }}</td>
              <td>
                <button
                  *ngIf="fer.Parcelas[0].Status.Codigo !== '12'"
                  type="button"
                  (click)="abrirModalFerias2(i)"
                  class="btn bg-teal-400 border-teal-400"
                  data-toggle="modal"
                  data-target="#myModal"
                  data-backdrop="static"
                >
                  <i class="fa fa-eye"></i>
                </button>
              </td>
              <!-- <td>{{fer.Vencimento.Inicio.ToDate}}</td>
              <td>{{fer.Vencimento.Fim.ToDate}}</td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center" *ngIf="listarFerias.length == 0">
        <p>Sem Férias para Solicitar</p>
      </div>
    </div>
    <div *ngIf="!listarFerias" class="row d-flex justify-content-center align-items-center">
      <i class="fa fa-refresh fa-spin" aria-hidden="true"></i>
      <span class="sr-only">Refreshing...</span>
    </div>
  </div>
</div>

<div id="myModal" class="modal fade" tabindex="-1">
  <div class="modal-dialog modal-dialog-scrollable">
    <div *ngIf="perfilFerias" class="modal-content">
      <div class="modal-header d-flex flex-column mx-3">
        <button
          type="button"
          id="closeModalbt"
          class="close"
          data-dismiss="modal"
          (click)="feriasSelecionada = null"
        >
          &times;
        </button>
        <div class="d-flex flex-row">
          <h5 class="modal-title">
            {{ perfilFerias.DadosColaborador.NomeColaborador }}
          </h5>
          <button
            class="btn btn-outline-primary py-0 px-1 ml-2 btn-sm collapsed"
            [ngClass]="{ active: feriasDadosCollapse }"
            (click)="exibeDadosFerias()"
            type="button"
            data-toggle="collapse"
            data-target="#collapseDados"
            aria-expanded="false"
            aria-controls="collapseDados"
          >
            <i class="fa fa-angle-down"></i>
          </button>
        </div>
        <div class="table-responsive shadow-sm tabela-dados-ferias mt-1">
          <table class="table table-striped collapse" id="collapseDados">
            <tbody>
              <tr>
                <th class="font-weight-semibold">Setor/Cargo</th>
                <td>
                  {{ perfilFerias.DadosColaborador.NomeSetor }}/{{
                    cadastroUsuario.InformacoesBasicas.Cargo.Nome
                  }}
                </td>
                <th class="font-weight-semibold">Matrícula</th>
                <td>{{ perfilFerias.DadosColaborador.Matricula }}</td>
              </tr>
              <tr>
                <th class="font-weight-semibold">Dias Disponíveis</th>
                <td>{{ diasDireito }}</td>
                <th class="font-weight-semibold">Faltas</th>
                <td>{{ perfilFerias.Faltas }}</td>
              </tr>
              <tr>
                <th class="font-weight-semibold">Período Aquisitivo</th>
                <td>
                  {{ perfilFerias.Vencimento.Inicio.ToDate }} a
                  {{ perfilFerias.Vencimento.Fim.ToDate }}
                </td>
                <th class="font-weight-semibold">Competência</th>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-body">
        <form
          #SolicitarFerias="ngForm"
          [formGroup]="formSolicitarFerias"
          (ngSubmit)="isInsertFerias ? submitFerias() : updateFerias()"
        >
          <div>
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#dados-ferias">
                  <i class="fa fa-user"></i>&nbsp;
                  <span>Dados Férias</span>
                </a>
              </li>
              <li *ngIf="clientePublico" class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#portaria">
                  <i class="fa fa-file"></i>&nbsp;
                  <span>Ato/Portaria/Diário</span>
                </a>
              </li>
            </ul>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="dados-ferias">
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label class="font-weight-semibold">Adiantar 13º?</label>
                    <select
                      class="form-control"
                      formControlName="adiantar13"
                      (change)="validarTipoFerias()"
                      [ngClass]="
                        (temErro || formSolicitarFerias.get('adiantar13').dirty) &&
                        formSolicitarFerias.get('adiantar13').invalid
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">Selecione...</option>
                      <option [value]="false">Não</option>
                      <option [value]="true">Sim</option>
                    </select>
                  </div>

                  <div class="col-md-4 form-group">
                    <label class="font-weight-semibold">Parcelar Férias?</label>
                    <select
                      class="form-control"
                      formControlName="parcela"
                      (change)="limparCamposAoVender()"
                      [ngClass]="
                        (temErro || formSolicitarFerias.get('parcela').dirty) &&
                        formSolicitarFerias.get('parcela').invalid
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">Selecione...</option>
                      <option [value]="false">Não</option>
                      <option [value]="true">Sim</option>
                    </select>
                    <!-- <label class="text-danger" *ngIf="perfilFerias && perfilFerias.Complemento.DiasAbono == 99 && feriasFormDecimoValid" >
                      Seleciona uma das opções
                    </label> -->
                  </div>

                  <div class="col-md-4 form-group">
                    <label class="font-weight-semibold">Vender 10 Dias?</label>
                    <select
                      class="form-control"
                      formControlName="venderDias"
                      (change)="somarDias() && setarValidacaoDias()"
                      [ngClass]="
                        (temErro || formSolicitarFerias.get('venderDias').dirty) &&
                        formSolicitarFerias.get('venderDias').invalid
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">Selecione...</option>
                      <option [value]="false">Não</option>
                      <option [value]="true">Sim</option>
                    </select>
                    <span
                      class="form-text text-danger"
                      *ngIf="temErro && formSolicitarFerias.get('venderDias').hasError('required')"
                    >
                      Campo obrigatório
                    </span>
                  </div>

                  <!-- *ngIf="formSolicitarFerias.get('codigoSituacao').value === '15' || formSolicitarFerias.get('codigoSituacao').value === 15" -->
                  <div
                    class="col-md-12 form-group"
                    *ngIf="
                      (feriasSelecionada && feriasSelecionada.Parcelas[0].Status.Codigo != '14') ||
                      localStorage.TipoUsuario != '1'
                    "
                  >
                    <label class="font-weight-semibold">Motivo de Rejeição</label>
                    <textarea
                      type="text"
                      class="form-control"
                      placeholder="Motivo de rejeição"
                      formControlName="descricao"
                    ></textarea>
                  </div>

                  <fieldset class="col-12">
                    <legend class="font-weight-semibold">Férias</legend>
                    <div class="row" *ngIf="!btValidaparcela">
                      <div class="col-md-4 form-group">
                        <label class="font-weight-semibold">Data Início</label>
                        <input
                          type="date"
                          class="form-control"
                          (focus)="calculaData()"
                          formControlName="inicioFerias"
                          [ngClass]="
                            (temErro || formSolicitarFerias.get('inicioFerias').dirty) &&
                            formSolicitarFerias.get('inicioFerias').invalid
                              ? 'is-invalid'
                              : null
                          "
                        />

                        <!-- <input type="text" mask="00/00/0000" placeholder="dd/mm/aaaa" class="form-control" (change)="calculaData();" formControlName="inicioFerias"
                          [ngClass]="((temErro || formSolicitarFerias.get('inicioFerias').dirty) && formSolicitarFerias.get('inicioFerias').invalid) ? {'border-color':'red'} : null" /> -->
                        <span
                          class="form-text text-danger"
                          *ngIf="
                            temErro && formSolicitarFerias.get('inicioFerias').hasError('required')
                          "
                        >
                          Campo Obrigatório
                        </span>
                      </div>
                      <div class="col-md-4 form-group">
                        <label class="font-weight-semibold">Data Final</label>
                        <input
                          readonly
                          type="text"
                          class="form-control"
                          mask="00/00/0000"
                          placeholder="dd/mm/aaaa"
                          formControlName="fimFerias"
                          [ngClass]="
                            (temErro || formSolicitarFerias.get('fimFerias').dirty) &&
                            formSolicitarFerias.get('fimFerias').invalid
                              ? 'is-invalid'
                              : null
                          "
                        />
                        <span
                          class="form-text text-danger"
                          *ngIf="
                            temErro && formSolicitarFerias.get('fimFerias').hasError('required')
                          "
                        >
                          Campo Obrigatório
                        </span>
                      </div>

                      <div class="col-md-3 form-group">
                        <label class="font-weight-semibold">Dias de Férias</label>
                        <input
                          type="text"
                          mask="0*"
                          placeholder="Dias"
                          (change)="calculaData()"
                          maxlength="2"
                          class="form-control"
                          formControlName="diasFerias"
                          [ngClass]="
                            (temErro || formSolicitarFerias.get('diasFerias').dirty) &&
                            formSolicitarFerias.get('diasFerias').invalid
                              ? 'is-invalid'
                              : null
                          "
                        />
                        <label
                          class="text-danger"
                          *ngIf="formSolicitarFerias.get('diasFerias').hasError('min')"
                        >
                          Quantidade de dias menor que o valor mínimo (5)
                        </label>
                        <label
                          class="text-danger"
                          *ngIf="formSolicitarFerias.get('diasFerias').hasError('max')"
                        >
                          Quantidade de dias maior que o valor máximo {{ somarDias() }}
                        </label>
                        <span
                          class="form-text text-danger"
                          *ngIf="
                            temErro && formSolicitarFerias.get('diasFerias').hasError('required')
                          "
                        >
                          Campo Obrigatório
                        </span>
                      </div>
                      <div
                        *ngIf="this.formSolicitarFerias.get('parcela').value == 'true'"
                        class="col-md-1 py-4"
                      >
                        <button
                          [disabled]="btValidaparcela"
                          type="button"
                          class="btn btn-primary btn-sm"
                          (click)="validaDiasParcelas()"
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <!-- <button type="button" class="btn btn-primary btn-sm" [disabled]="index == 0" (click)="RolagemBotao(false)"> Anterior</button>
                    <button type="button" class="btn btn-primary btn-sm" [disabled]="index == 2 || this.formSolicitarFerias.get('parcela').value < 2" (click)="RolagemBotao(true)"> Próximo</button> -->
                  </fieldset>
                  <div class="table-responsive col-12" style="max-height: 45vh">
                    <table
                      *ngIf="parcelasList != null && parcelasList.length > 0"
                      class="table table-striped table-hover"
                    >
                      <thead class="text-center">
                        <tr class="text-center" style="white-space: nowrap">
                          <th class="sticky-top bg-white">Dias de Férias</th>
                          <th class="sticky-top bg-white">Data Início</th>
                          <th class="sticky-top bg-white">Data Final</th>
                          <th class="sticky-top bg-white">Andamento</th>
                        </tr>
                      </thead>
                      <tbody id="lista-processos">
                        <tr
                          *ngFor="let parcela of parcelasList; let i = index"
                          class="text-center"
                          style="white-space: nowrap"
                        >
                          <td>{{ parcela.Dias }}</td>
                          <td>{{ parcela.Inicio.ToDate }}</td>
                          <td>{{ parcela.Fim.ToDate }}</td>
                          <td>{{ parcela.Status.Descricao }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="portaria">
                <div class="row">
                  <fieldset class="col-12" *ngIf="clientePublico">
                    <legend class="font-weight-semibold">Ato/Portaria</legend>
                    <div class="row">
                      <div class="col-md-3 form-group">
                        <label class="font-weight-semibold">Classificação</label>
                        <select
                          class="form-control"
                          formControlName="classificacao"
                          (change)="verificarAtoObrigatorio()"
                          [ngClass]="
                            (temErro || formSolicitarFerias.get('classificacao').dirty) &&
                            formSolicitarFerias.get('classificacao').invalid
                              ? 'is-invalid'
                              : null
                          "
                        >
                          <option value="">Selecione...</option>
                          <option
                            *ngFor="let classificacao of listaClassificacao"
                            value="{{ classificacao.Codigo }}"
                          >
                            {{ classificacao.Descricao }}
                          </option>
                        </select>
                        <span
                          class="form-text text-danger"
                          *ngIf="
                            temErro && formSolicitarFerias.get('classificacao').hasError('required')
                          "
                        >
                          Campo Obrigatório
                        </span>
                      </div>
                      <div class="col-md-4 form-group">
                        <label class="font-weight-semibold">Número</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Número"
                          formControlName="numeroAto"
                          maxlength="50"
                          (keyup)="verificarAtoObrigatorio()"
                          [ngClass]="
                            (temErro || formSolicitarFerias.get('numeroAto').dirty) &&
                            formSolicitarFerias.get('numeroAto').invalid
                              ? 'is-invalid'
                              : null
                          "
                        />
                        <span
                          class="form-text text-danger"
                          *ngIf="
                            temErro && formSolicitarFerias.get('numeroAto').hasError('required')
                          "
                        >
                          Campo Obrigatório
                        </span>
                      </div>

                      <div class="col-md-3 form-group">
                        <label class="font-weight-semibold">Data</label>
                        <input
                          type="text"
                          class="form-control"
                          mask="00/00/0000"
                          placeholder="dd/mm/aaaa"
                          formControlName="dataAto"
                          (keyup)="verificarAtoObrigatorio()"
                          [ngClass]="
                            (temErro || formSolicitarFerias.get('dataAto').dirty) &&
                            formSolicitarFerias.get('dataAto').invalid
                              ? 'is-invalid'
                              : null
                          "
                        />
                        <span
                          class="form-text text-danger"
                          *ngIf="temErro && formSolicitarFerias.get('dataAto').hasError('required')"
                        >
                          Campo Obrigatório
                        </span>
                        <span
                          class="form-text text-danger"
                          *ngIf="temErro && formSolicitarFerias.get('dataAto').hasError('data')"
                        >
                          Data Inválida
                        </span>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset class="col-12" *ngIf="clientePublico">
                    <legend class="font-weight-semibold">Diário</legend>
                    <div class="row">
                      <div class="col-md-4 form-group">
                        <label class="font-weight-semibold">Número DOE </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Número"
                          formControlName="numeroDiarioOficial"
                          maxlength="50"
                          (keyup)="verificarDiarioOficialObrigatorio()"
                          [ngClass]="
                            (temErro || formSolicitarFerias.get('numeroDiarioOficial').dirty) &&
                            formSolicitarFerias.get('numeroDiarioOficial').invalid
                              ? 'is-invalid'
                              : null
                          "
                        />
                        <span
                          class="form-text text-danger"
                          *ngIf="
                            temErro &&
                            formSolicitarFerias.get('numeroDiarioOficial').hasError('required')
                          "
                        >
                          Campo obrigatório
                        </span>
                      </div>
                      <div class="col-md-4 form-group">
                        <label class="font-weight-semibold">Data DOE</label>
                        <input
                          type="text"
                          class="form-control"
                          mask="00/00/0000"
                          placeholder="dd/mm/aaaa"
                          formControlName="dataDiarioOficial"
                          (keyup)="verificarDiarioOficialObrigatorio()"
                          [ngClass]="
                            (temErro || formSolicitarFerias.get('dataDiarioOficial').dirty) &&
                            formSolicitarFerias.get('dataDiarioOficial').invalid
                              ? 'is-invalid'
                              : null
                          "
                        />
                        <span
                          class="form-text text-danger"
                          *ngIf="
                            temErro &&
                            formSolicitarFerias.get('dataDiarioOficial').hasError('required')
                          "
                        >
                          Campo obrigatório
                        </span>
                        <span
                          class="form-text text-danger"
                          *ngIf="
                            temErro && formSolicitarFerias.get('dataDiarioOficial').hasError('data')
                          "
                        >
                          Data inválida
                        </span>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer mt-3">
            <button
              *ngIf="
                feriasSelecionada &&
                feriasSelecionada.Parcelas[0].Status.Codigo != '15' &&
                feriasSelecionada.Parcelas[0].Status.Codigo != '14'
              "
              type="button"
              class="btn btn-danger mr-2"
              (click)="limparCampos()"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <!-- [disabled]="isDisableSolicitar" -->
            <button type="submit" *ngIf="mostrarSolicitar" class="btn btn-primary">
              Solicitar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<button
  id="abrirModal"
  class="d-none"
  data-toggle="modal"
  data-target="#myModal"
  data-backdrop="static"
  type="button"
></button>
