import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'converterData',
})
export class ConverterDataPipe implements PipeTransform {
  transform(value: string): string {
    const year = value.slice(0, 4);
    const month = value.slice(4);
    return `${month}/${year}`;
  }
}
