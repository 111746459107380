import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AcessoService} from 'src/app/service/acesso/acesso.service';
import {LocalStorage} from 'src/app/service/classes/localstorage/LocalStorage';
import {Crypto} from 'src/app/service/classes/crypto/crypto';
import {Falta} from './faltas.component';
import {Item} from './faltas-cadastro/faltas-cadastro.component';
import {Parametro} from 'src/app/service/interfaces/Parametro';

@Injectable({
  providedIn: 'root',
})
export class FaltasService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  url_acesso: string;
  cabecalho: any;
  filial: string;

  listaMotivos: Item[] = [];
  listaAfastamentos: Item[] = [];
  listaClassificacaoAto: Item[] = [];
  listaUF: Parametro[] = [];

  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl();

    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  buscarFaltas(matricula: number) {
    return this.http.get(
      `${this.url_acesso}falta-ao-trabalho/listar-falta-matricula/${matricula}/${this.filial}`,
      {
        headers: this.cabecalho,
      }
    );
  }

  buscarMotivoAfastamento() {
    return this.http.get(`${this.url_acesso}falta-ao-trabalho/listar-motivo-afastamento`, {
      headers: this.cabecalho,
    });
  }

  buscarCodigoAfastamento() {
    return this.http.get(`${this.url_acesso}falta-ao-trabalho/listar-codigo-afastamento`, {
      headers: this.cabecalho,
    });
  }

  buscarIdClassificacaoAto() {
    return this.http.get(`${this.url_acesso}falta-ao-trabalho/listar-id-classificacao-ato`, {
      headers: this.cabecalho,
    });
  }

  buscarParametros() {
    return this.http.get(`${this.url_acesso}cadastro-publico/parametros/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarMedico(crmBusca: any) {
    const token = 'MTIzNDU2';
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    return this.http.post('https://pablodiego.com.br/crm-api/medicos/', {crmBusca}, {headers});
  }

  buscarCIDDoencas(cid: string) {
    return this.http.get(`${this.url_acesso}cid10/${cid}`, {headers: this.cabecalho});
  }

  inserirLista(listaNome: string, lista: Array<Item>) {
    if (listaNome == 'motivos') {
      this.listaMotivos = lista;
    }
    if (listaNome == 'afastamentos') {
      this.listaAfastamentos = lista;
    }
    if (listaNome == 'classificacao') {
      this.listaClassificacaoAto = lista;
    }
  }

  inserirListaUf(lista: Array<Parametro>) {
    this.listaUF = lista;
  }

  retornarLista(listaNome: string) {
    if (listaNome == 'motivos') {
      return this.listaMotivos;
    }
    if (listaNome == 'afastamentos') {
      return this.listaAfastamentos;
    }
    if (listaNome == 'classificacao') {
      return this.listaClassificacaoAto;
    }
  }

  retornarListaUf() {
    return this.listaUF;
  }

  lancarFalta(Falta: any) {
    return this.http.post(`${this.url_acesso}falta-ao-trabalho/adiciona-falta`, Falta, {
      headers: this.cabecalho,
    });
  }

  atualizarFalta(Falta: any) {
    return this.http.put(`${this.url_acesso}falta-ao-trabalho/atualiza-falta`, Falta, {
      headers: this.cabecalho,
    });
  }

  deletarFalta(Sequencial: number, Numord: string) {
    return this.http.delete(
      `${this.url_acesso}falta-ao-trabalho/deleta-falta/${Sequencial}/${Numord}`,
      {headers: this.cabecalho}
    );
  }

  downloadPdf(empfil: string, matricula: number, tipo: string) {
    return this.http.get(
      `${this.url_acesso}relatorios-pdf/download-pdf/${empfil}/${matricula}/${tipo}`,
      {
        headers: this.cabecalho,
      }
    );
  }

  extrairData(data: string) {
    let ano = parseInt(data.substring(0, 4));
    let mes = parseInt(data.substring(4, 6)) - 1;
    let dia = parseInt(data.substring(6, 8));

    return new Date(ano, mes, dia);
  }

  formatarDataBarra(data: string) {
    let ano = parseInt(data.substring(0, 4));
    let mes = parseInt(data.substring(4, 6)) - 1;
    let dia = parseInt(data.substring(6, 8));

    return dia + '/' + mes + '/' + ano;
  }

  formatarDataHifen(data: string): string {
    let ano = data.slice(0, 4);
    let mes = data.slice(4, 6);
    let dia = data.slice(6, 8);

    return `${ano}-${mes}-${dia}`;
  }

  calcularDias(dataInicio: string, dataVolta: string): string {
    const dataInicioFormatada = this.extrairData(dataInicio);
    const dataVoltaFormatada = this.extrairData(dataVolta);
    const dataTeste = this.formatarDataBarra(dataInicio);
    // console.log(dataInicioFormatada);
    // console.log(dataVoltaFormatada);

    let diferencaEmMilissegundos = Math.abs(
      dataVoltaFormatada.getTime() - dataInicioFormatada.getTime()
    );
    let diferencaEmDias = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));

    return diferencaEmDias.toString();
  }
}
