<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="modal-header">
  <h3 class="modal-title">{{ promocaoindex !== null ? 'Atualização' : 'Inclusão' }} de Promoção</h3>
  <button type="button" class="close" id="botaoFechar" data-dismiss="modal" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="formPromocoes" class="modal-body row">



  <div class="form-group col-6">
    <label for="DtInicio" class="font-weigth-semibold">Data Início</label>
    <input
    type="date"
    max="9999-12-31"
    class="form-control"
    id="DtInicio"
    formControlName="DtInicio"
    [ngClass]="
      (formPromocoes.get('DtInicio').invalid || formPromocoes.get('DtInicio').value == null) &&
      formPromocoes.get('DtInicio').touched
        ? 'is-invalid'
        : null
    "
  />
  <span
      class="form-text text-danger"
      *ngIf="
        (formPromocoes.get('DtInicio').invalid || formPromocoes.get('DtInicio').value == null) &&
        formPromocoes.get('DtInicio').touched
      "
    >
      {{
        formPromocoes.get('DtInicio').hasError('data')
          ? 'Data deve ser menor que data atual'
          : 'Campo Obrigatório'
      }}
    </span>
  </div>

  <div class="form-group col-6">
    <label for="NumeroProcesso" class="font-weigth-semibold">Número do Processo <small>(opcional)</small></label>
    <input type="text" class="form-control" id="NumeroProcesso" formControlName="NumeroProcesso" placeholder="A00" style="text-transform: uppercase;" />
</div>


 
 <div *ngIf="formPromocoes.errors?.requireOneOf && (formPromocoes.get('CodCar').touched || formPromocoes.get('CodFun').touched)" class="alert alert-danger">
  Preencha pelo menos um dos campos: Cargo ou Função.
</div>

<div class="form-group col-6">
    <label for="CodCar">Cargo</label>
    <select class="form-control" id="CodCar" formControlName="CodCar" (change)="buscarNiveisCargo(formPromocoes.get('CodCar').value)">
      <option [value]="null">Selecione uma opção</option>
      <option *ngFor="let cargo of listaCargos" [value]="cargo.Cod">
        {{ cargo.Cod }} - {{ cargo.Nome }}
      </option>
    </select>

</div>

<div class="form-group col-6">
    <label for="CodFun">Função</label>
    <select class="form-control" id="CodFun" formControlName="CodFun" (change)="buscarNiveisCargo(formPromocoes.get('CodFun').value)">
      <option [value]="null">Selecione uma opção</option>
      <option *ngFor="let funcao of listaFuncoes" [value]="funcao.Cod">
        {{ funcao.Cod }} - {{ funcao.Nome }}
      </option>
    </select>

</div>
  

  

<div class="form-group col-6">
  <label for="CodNiv" >Nível</label>
  <select class="form-control" id="CodNiv" formControlName="CodNiv"
    [ngClass]="{
      'is-invalid': formPromocoes.get('CodNiv').invalid && formPromocoes.get('CodNiv').touched
    }">
    <option [value]="null" disabled>Selecione uma opção</option>
    <option *ngIf="!listaNiveisCargo || listaNiveisCargo.length === 0" value="">Sem Nível</option>
    <option *ngFor="let nivel of listaNiveisCargo" [value]="nivel.Cod">
      {{ nivel.Cod }} - {{ nivel.Nome }}
    </option>
  </select>
  <span class="form-text text-danger" *ngIf="formPromocoes.get('CodNiv').invalid && formPromocoes.get('CodNiv').touched">
    Campo Obrigatório
  </span>
</div>

  



  <div class="form-group col-6">
    <label for="NumeroDoe" class="font-weigth-semibold">Número DOE <small>(opcional)</small></label>
    <input type="text" class="form-control" id="NumeroDoe" formControlName="NumeroDoe" placeholder="A00" style="text-transform: uppercase;"/>
  </div>

  <div class="form-group col-6">
    <label for="IdClassificacaoAto" class="font-weigth-semibold">Classificação Ato <small>(opcional)</small></label>
    <select class="form-control" id="IdClassificacaoAto" formControlName="IdClassificacaoAto">
      <option value="null">Selecione uma opção</option>
      <option *ngFor="let item of listaClassificacaoAto" [value]="item.Cod">
        {{ item.Cod }} - {{ item.Nome }}
      </option>
    </select>
  </div>

  <div class="form-group col-3">
    <label for="DtDoe" class="font-weigth-semibold">Data DOE <small>(opcional)</small></label>
    <input type="date" max="9999-12-31" class="form-control" id="DtDoe" formControlName="DtDoe" />
  </div>



  <div class="form-group col-3">
    <label for="DtAto" class="font-weigth-semibold">Data Ato <small>(opcional)</small></label>
    <input type="date" max="9999-12-31" class="form-control" id="DtAto" formControlName="DtAto" />
  </div>

  

  <div class="form-group col-6">
    <label for="Observacao">Observação</label>
    <select class="form-control" id="Observacao" formControlName="Observacao"
      [ngClass]="{
        'is-invalid': formPromocoes.get('Observacao').invalid && formPromocoes.get('Observacao').touched
      }">
      <option value="null" disabled>Selecione uma opção</option>
      
      <option *ngFor="let observacao of listaObservacoes" [value]="observacao.Cod + ' - ' + observacao.Descricao">
        {{ observacao.Cod }} - {{ observacao.Descricao }}
      </option>      
    </select>
    <span class="form-text text-danger" *ngIf="formPromocoes.get('Observacao').invalid && formPromocoes.get('Observacao').touched">
      Campo Obrigatório
    </span>
</div>

<div class="form-group col-6">
  <label for="AtoPro" class="font-weigth-semibold">Ato <small>(opcional)</small></label>
  <input type="text" class="form-control" id="AtoPro" formControlName="AtoPro" placeholder="A00" style="text-transform: uppercase;"/>
</div>




</form>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>

  <button *ngIf="promocaoindex !== null" type="button" class="btn btn-primary" (click)="lancarPromocao()">
    Atualizar
  </button>
  <button *ngIf="promocaoindex===null" type="button" class="btn btn-primary" (click)="lancarPromocao()">
    Cadastrar
  </button>
</div>