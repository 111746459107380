<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  name="email"
>
  <p style="font-size: 20px; color: white; z-index: 20000">Enviando...</p>
</ngx-spinner>
<div>
  <!-- botões -->
  <div class="d-flex justify-content-between">
    <p class="lead text-uppercase text-muted ml-3">
      {{ tipoUsuario === '1' ? 'Sua Documentação' : 'Documentação' }}
    </p>
    <div *ngIf="!loading && !ColaboradorInativo" class="btn-group">
      <ng-container *ngFor="let botao of botoes">
        <button
          class="btn"
          [class.btn-outline-warning]="botao.color === 'warning'"
          [class.btn-outline-danger]="botao.color === 'danger'"
          [class.btn-outline-primary]="botao.color === 'primary'"
          [class.btn-sm]="true"
          [class.mx-2]="botao.label === 'Salvar'"
          [class.mr-2]="botao.label === 'Editar'"
          type="botao"
          *ngIf="botao.show()"
          (click)="botao.action()"
        >
          <i *ngIf="botao.icon" class="fa {{ botao.icon }}"></i
          >{{ botao.label === 'Editar' ? '&nbsp;' : '' }}
          <span>{{ botao.label }}</span>
        </button>
      </ng-container>
    </div>
  </div>
  <hr />
  <!-- tabela -->
  <div *ngIf="!displayDocumento && !loading" class="table-responsive shadow-sm">
    <table class="table table-striped">
      <tbody class="tabela-documentos">
        <tr>
          <th class="text-muted">CPF</th>
          <td colspan="2">
            {{
              documentos.Cpf && documentos.Cpf.trim() != ''
                ? formataCPF(documentos.Cpf)
                : 'NÃO INFORMADO'
            }}
          </td>
          <th class="text-muted">RG</th>
          <td colspan="2">
            {{
              documentos.Rg && documentos.Rg.Numero.trim() != ''
                ? documentos.Rg.Numero
                : 'NÃO INFORMADO'
            }}
          </td>
        </tr>
        <tr>
          <th class="text-muted">Órgão Emissor - UF</th>
          <td colspan="2">
            {{
              documentos.Rg &&
              documentos.Rg.OrgaoEmissor.trim() != '' &&
              documentos.Rg.UfEmissaoRg.trim() != '' &&
              documentos.Rg.UfEmissaoRg.trim() != '0'
                ? documentos.Rg.OrgaoEmissor + '-' + documentos.Rg.UfEmissaoRg
                : 'NÃO INFORMADO'
            }}
          </td>

          <th class="text-muted">Data de Emissão RG</th>
          <td colspan="2">
            {{ documentos.Rg ? documentos.Rg.DataEmissaoRg.ToDate : 'NÃO INFORMADA' }}
          </td>
        </tr>

        <tr>
          <th class="text-muted">CNH</th>
          <td colspan="2">
            {{
              (documentos.Cnh && documentos.Cnh.Numero.trim() != '') ||
              (documentos.Cnh && documentos.Cnh.Numero == '0')
                ? documentos.Cnh.Numero
                : 'NÃO INFORMADO'
            }}
          </td>

          <th class="text-muted">Categoria da CNH</th>
          <td colspan="2">
            {{
              documentos.Cnh && documentos.Cnh.CategoriaCnh != ''
                ? documentos.Cnh.CategoriaCnh
                : 'NÃO INFORMADO'
            }}
          </td>
        </tr>

        <tr>
          <th class="text-muted">UF da CNH</th>
          <td colspan="2">
            <!-- {{
              documentos.Cnh && documentos.Cnh.UF != '' && documentos.Cnh.UF != '0'
                ? documentos.Cnh.UF
                : 'NÃO INFORMADA'
            }} comentada enquanto a formatação da uf ainda não é retornada corretamente-->
            {{ documentos.Cnh.UF != '' ? documentos.Cnh.UF : 'NÃO INFORMADA' }}
          </td>

          <th class="text-muted">Data Validade da CNH</th>
          <td colspan="2">
            {{
              documentos.Cnh.DataValidadeCnh.ToDate != ''
                ? documentos.Cnh.DataValidadeCnh.ToDate
                : 'NÃO INFORMADA'
            }}
          </td>
        </tr>

        <tr>
          <th class="text-muted">Título de Eleitor</th>
          <td colspan="2">
            {{
              !documentos.TituloEleitor ||
              documentos.TituloEleitor.Numero == '0' ||
              documentos.TituloEleitor.Numero == '0'
                ? 'NÃO INFORMADO'
                : formatarTitulo(documentos.TituloEleitor.Numero)
            }}
          </td>
          <th class="text-muted">Seção/Zona Eleitoral</th>
          <td colspan="2">
            {{
              documentos.TituloEleitor.SecaoEleitoral &&
              documentos.TituloEleitor.SecaoEleitoral != '' &&
              documentos.TituloEleitor.ZonaEleitoral &&
              documentos.TituloEleitor.ZonaEleitoral != ''
                ? documentos.TituloEleitor.SecaoEleitoral +
                  '/' +
                  documentos.TituloEleitor.ZonaEleitoral
                : 'NÃO INFORMADA'
            }}
          </td>
        </tr>
        <tr>
          <th class="text-muted">UF do Título de Eleitor</th>
          <td colspan="2">
            {{
              documentos.TituloEleitor.UfEleitoral &&
              documentos.TituloEleitor.UfEleitoral != '0' &&
              documentos.TituloEleitor.UfEleitoral != ''
                ? documentos.TituloEleitor.UfEleitoral
                : 'NÃO INFORMADO'
            }}
          </td>
          <th class="text-muted">Nº do Certificado Militar</th>
          <td colspan="2">
            {{
              documentos.CertificadoMilitar && documentos.CertificadoMilitar.Numero != ''
                ? documentos.CertificadoMilitar.Numero
                : 'NÃO INFORMADO'
            }}
          </td>
        </tr>
        <tr>
          <th class="text-muted">Certificado Militar (CSM)</th>
          <td colspan="2">
            {{
              documentos.CertificadoMilitar && documentos.CertificadoMilitar.Csm != ''
                ? documentos.CertificadoMilitar.Csm
                : 'NÃO INFORMADO'
            }}
          </td>
          <th class="text-muted">Certificado Militar (Nº de série)</th>
          <td colspan="2">
            {{
              documentos.CertificadoMilitar && documentos.CertificadoMilitar.Serie != ''
                ? documentos.CertificadoMilitar.Serie
                : 'NÃO INFORMADO'
            }}
          </td>
        </tr>
        <tr>
          <th class="text-muted">UF da CTPS</th>
          <td colspan="2">
            {{
              documentos.Clt && documentos.Clt.UfCtps != '0' && documentos.Clt.UfCtps != ''
                ? documentos.Clt.UfCtps
                : 'NÃO INFORMADO'
            }}
          </td>

          <th class="text-muted">Carteira de Trabalho</th>
          <td colspan="2">
            {{
              documentos.Clt &&
              documentos.Clt.CarteiraDeTrabalho != '0' &&
              documentos.Clt.CarteiraDeTrabalho != ''
                ? documentos.Clt.CarteiraDeTrabalho
                : 'NÃO INFORMADO'
            }}
          </td>
        </tr>

        <tr>
          <th class="text-muted">Nº de série da CTPS</th>
          <td colspan="2">
            {{
              documentos.Clt &&
              documentos.Clt.NumeroSerieCtps != '0' &&
              documentos.Clt.NumeroSerieCtps != ''
                ? documentos.Clt.NumeroSerieCtps
                : 'NÃO INFORMADO'
            }}
          </td>
          <th class="text-muted">PIS</th>
          <td colspan="2">
            {{
              documentos.Clt && documentos.Clt.Pis != '0' && documentos.Clt.Pis != ''
                ? formatarPIS(documentos.Clt.Pis)
                : 'NÃO INFORMADO'
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="displayDocumento && !loading">
    <form #tese="ngForm" [formGroup]="formDocumento" autocomplete="off" class="form-group">
      <div class="row">
        <div class="form-group col-xl-3">
          <label for="" class="text-muted font-weight-bold">CPF</label>
          <input
            [ngStyle]="
              formDocumento.controls['numeroCpf'].touched &&
              (temErro ||
                formDocumento.controls['numeroCpf'].hasError('required') ||
                formDocumento.controls['numeroCpf'].hasError('minlength'))
                ? { 'border-color': 'red' }
                : null
            "
            type="text"
            k
            class="form-control"
            name="numeroCpf"
            formControlName="numeroCpf"
            placeholder="Número CPF"
            mask="0*"
            minlength="4"
            required
          />
          <span
            class="form-text text-danger"
            *ngIf="
              formDocumento.controls['numeroCpf'].touched &&
              (temErro ||
                formDocumento.controls['numeroCpf'].hasError('required') ||
                formDocumento.controls['numeroCpf'].hasError('minlength'))
            "
          >
            {{
              formDocumento.controls['numeroRg'].hasError('minlength')
                ? 'Digite um CPF válido'
                : 'Campo obrigatório'
            }}
          </span>
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">Título de Eleitor</label>
          <input
            type="text"
            class="form-control"
            maxlength="14"
            name="tituloEleitor"
            formControlName="tituloEleitor"
            placeholder="Titulo de Eleitor"
            (keypress)="somenteNumero($event)"
            [ngStyle]="
              (formDocumento.controls['tituloEleitor'].touched &&
                formDocumento.controls['tituloEleitor'].status == 'INVALID') ||
              (formDocumento.controls['tituloEleitor'].status == 'INVALID' && temErro)
                ? { 'border-color': 'red' }
                : null
            "
            required
          />
          <span
            class="form-text text-danger"
            *ngIf="
              (formDocumento.controls['tituloEleitor'].touched &&
                formDocumento.controls['tituloEleitor'].status == 'INVALID') ||
              (formDocumento.controls['tituloEleitor'].status == 'INVALID' && temErro)
            "
          >
            {{
              formDocumento.controls['tituloEleitor'].hasError('tituloInvalido')
                ? 'Título inválido'
                : 'Campo obrigatório'
            }}
          </span>
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">Seção Eleitoral</label>
          <input
            type="text"
            class="form-control"
            maxlength="6"
            (keypress)="somenteNumero($event)"
            name="secaoEleitoral"
            placeholder="Seção Eleitoral"
            formControlName="secaoEleitoral"
            [ngStyle]="
              (formDocumento.controls['secaoEleitoral'].touched || temErro) &&
              formDocumento.controls['secaoEleitoral'].status == 'INVALID'
                ? { 'border-color': 'red' }
                : null
            "
            required
          />
          <span
            class="form-text text-danger"
            *ngIf="
              formDocumento.controls['secaoEleitoral'].touched &&
              (temErro || formDocumento.controls['secaoEleitoral'].hasError('required'))
            "
          >
            Campo obrigatório
          </span>
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">Zona Eleitoral</label>
          <input
            type="text"
            class="form-control"
            maxlength="5"
            (keypress)="somenteNumero($event)"
            name="zonaEleitoral"
            placeholder="Zona Eleitoral"
            formControlName="zonaEleitoral"
            [ngStyle]="
              (formDocumento.controls['secaoEleitoral'].touched || temErro) &&
              formDocumento.controls['secaoEleitoral'].status == 'INVALID'
                ? { 'border-color': 'red' }
                : null
            "
            required
          />
          <span
            class="form-text text-danger"
            *ngIf="
              formDocumento.controls['zonaEleitoral'].touched &&
              (temErro || formDocumento.controls['zonaEleitoral'].hasError('required'))
            "
          >
            Campo obrigatório
          </span>
        </div>
        <div class="form-group col-xl-3">
          <label for="" class="text-muted font-weight-bold"> UF do Título de Eleitor </label>
          <select
            class="form-control"
            formControlName="ufEleitoral"
            [ngClass]="
              formDocumento.get('ufEleitoral').invalid &&
              (temErro || formDocumento.get('ufEleitoral').touched)
                ? 'is-invalid'
                : null
            "
          >
            <option value="">UF</option>
            <option *ngFor="let uf of listaUf" [value]="uf.SiglaUf">
              {{ uf.SiglaUf }}
            </option>
          </select>
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">RG</label>
          <input
            [ngStyle]="
              formDocumento.controls['numeroRg'].touched &&
              (temErro ||
                formDocumento.controls['numeroRg'].hasError('required') ||
                formDocumento.controls['numeroRg'].hasError('minlength'))
                ? { 'border-color': 'red' }
                : null
            "
            type="text"
            class="form-control"
            name="numeroRg"
            formControlName="numeroRg"
            placeholder="Número RG"
            mask="0*"
            minlength="4"
            required
          />
          <span
            class="form-text text-danger"
            *ngIf="
              formDocumento.controls['numeroRg'].touched &&
              (temErro ||
                formDocumento.controls['numeroRg'].hasError('required') ||
                formDocumento.controls['numeroRg'].hasError('minlength'))
            "
          >
            {{
              formDocumento.controls['numeroRg'].hasError('minlength')
                ? 'Digite um RG válido'
                : 'Campo obrigatório'
            }}
          </span>
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">Orgão Emissor RG</label>
          <input
            type="text"
            class="form-control"
            maxlength="5"
            placeholder="Orgão Emissor"
            name="orgaoEmissorRg"
            formControlName="orgaoEmissorRg"
            [ngStyle]="
              (formDocumento.controls['orgaoEmissorRg'].touched || temErro) &&
              formDocumento.controls['orgaoEmissorRg'].status == 'INVALID'
                ? { 'border-color': 'red' }
                : null
            "
            oninput="this.value = this.value.toUpperCase()"
            required
          />
          <span
            class="form-text text-danger"
            *ngIf="
              formDocumento.controls['orgaoEmissorRg'].touched &&
              (temErro || formDocumento.controls['orgaoEmissorRg'].hasError('required'))
            "
          >
            Campo obrigatório
          </span>
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">Data de Emissão do RG</label>
          <input
            type="date"
            [ngStyle]="
              (formDocumento.controls['dataEmissaoRg'].touched || temErro) &&
              formDocumento.controls['dataEmissaoRg'].status == 'INVALID'
                ? { 'border-color': 'red' }
                : null
            "
            class="form-control"
            name="dataEmissaoRg"
            formControlName="dataEmissaoRg"
            max="9999-12-31"
            required
          />
          <span
            class="form-text text-danger"
            *ngIf="
              formDocumento.controls['dataEmissaoRg'].touched &&
              (temErro || formDocumento.controls['dataEmissaoRg'].hasError('required'))
            "
          >
            Campo obrigatório
          </span>
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">UF do RG</label>
          <select
            class="form-control form-control-uniform"
            name="ufRg"
            formControlName="ufRg"
            [ngStyle]="
              (formDocumento.controls['ufRg'].touched &&
                formDocumento.controls['ufRg'].status == 'INVALID') ||
              (temErro && formDocumento.controls['ufRg'].status == 'INVALID')
                ? { 'border-color': 'red' }
                : null
            "
            required
          >
            <option value="">UF</option>
            <option *ngFor="let estado of listaUf" value="{{ estado.SiglaUf }}">
              {{ estado.SiglaUf }}
            </option>
          </select>
          <span
            class="form-text text-danger"
            *ngIf="temErro && formDocumento.controls['ufRg'].hasError('required')"
          >
            Campo obrigatório
          </span>
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">CNH <small>(opcional)</small></label>
          <input
            type="text"
            class="form-control"
            name="numeroCnh"
            placeholder="Número CNH"
            formControlName="numeroCnh"
            [ngStyle]="
              (formDocumento.controls['numeroCnh'].touched &&
                formDocumento.controls['numeroCnh'].status == 'INVALID') ||
              (formDocumento.controls['numeroCnh'].status == 'INVALID' && temErro)
                ? { 'border-color': 'red' }
                : null
            "
          />
          <span
            class="form-text text-danger"
            *ngIf="
              (formDocumento.controls['numeroCnh'].touched &&
                formDocumento.controls['numeroCnh'].hasError('minlength')) ||
              (temErro &&
                formDocumento.controls['numeroCnh'].hasError('minlength') &&
                formDocumento.controls['numeroCnh'].hasError('required'))
            "
          >
            A CNH precisa ter 11 dígitos
          </span>
        </div>

        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold"
            >Categoria da CNH <small>(opcional)</small></label
          >
          <select
            class="form-control form-control-uniform"
            name="categoriaCnh"
            formControlName="categoriaCnh"
            [ngStyle]="
              (formDocumento.controls['categoriaCnh'].touched || temErro) &&
              formDocumento.controls['categoriaCnh'].hasError('required')
                ? { 'border-color': 'red' }
                : null
            "
            [required]="cnhObrigatoria"
          >
            <option value="">Selecione...</option>
            <option
              *ngFor="let categ of listaCategoriaCnh | orderBy : 'Descricao' : false"
              value="{{ categ.Descricao }}"
            >
              {{ categ.Descricao }}
            </option>
          </select>
          <span
            class="form-text text-danger"
            *ngIf="
              formDocumento.controls['categoriaCnh'].touched &&
              (temErro || formDocumento.controls['categoriaCnh'].hasError('required'))
            "
          >
            Selecione uma categoria da CNH
          </span>
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">Validade <small>(opcional)</small></label>
          <input
            type="date"
            class="form-control"
            name="dataValidadeCnh"
            formControlName="dataValidadeCnh"
            max="9999-12-31"
            [disabled]="!cnhObrigatoria"
          />
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">UF da CNH<small> (opcional)</small></label>
          <select
            class="form-control form-control-uniform"
            name="ufCnh"
            formControlName="ufCnh"
            [ngStyle]="
              formDocumento.controls['ufCnh'].dirty &&
              formDocumento.controls['ufCnh'].status == 'INVALID'
                ? { 'border-color': 'red' }
                : null
            "
            [disabled]="!cnhObrigatoria"
          >
            <option value="">UF</option>
            <option *ngFor="let estado of listaUf" value="{{ estado.SiglaUf }}">
              {{ estado.SiglaUf }}
            </option>
          </select>
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">Carteira de Trabalho</label>
          <input
            type="text"
            class="form-control"
            name="carteiraTrabalho"
            formControlName="carteiraTrabalho"
            (keypress)="somenteNumero($event)"
            placeholder="Carteira de Trabalho"
            maxlength="8"
            [ngStyle]="
              (formDocumento.controls['carteiraTrabalho'].touched || temErro) &&
              formDocumento.controls['carteiraTrabalho'].status == 'INVALID'
                ? { 'border-color': 'red' }
                : null
            "
            required
          />
          <span
            class="form-text text-danger"
            *ngIf="
              formDocumento.controls['carteiraTrabalho'].touched &&
              (temErro || formDocumento.controls['carteiraTrabalho'].hasError('required'))
            "
          >
            Campo obrigatório
          </span>
        </div>

        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">N° de série da CTPS</label>
          <input
            type="text"
            class="form-control"
            name="numeroSerieCtps"
            (keypress)="somenteNumero($event)"
            formControlName="numeroSerieCtps"
            placeholder="Número de Série"
            maxLength="5"
            [ngStyle]="
              (formDocumento.controls['numeroSerieCtps'].touched || temErro) &&
              formDocumento.controls['numeroSerieCtps'].status == 'INVALID'
                ? { 'border-color': 'red' }
                : null
            "
            required
          />
          <span
            class="form-text text-danger"
            *ngIf="
              formDocumento.controls['numeroSerieCtps'].touched &&
              (temErro || formDocumento.controls['numeroSerieCtps'].hasError('required'))
            "
          >
            Campo obrigatório
          </span>
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">PIS</label>
          <input
            type="text"
            class="form-control"
            maxlength="11"
            (keypress)="somenteNumero($event)"
            name="pis"
            placeholder="PIS"
            formControlName="pis"
            [ngStyle]="
              (formDocumento.controls['pis'].touched || temErro) &&
              formDocumento.controls['pis'].status == 'INVALID'
                ? { 'border-color': 'red' }
                : null
            "
            required
          />
          <span
            class="form-text text-danger"
            *ngIf="
              (formDocumento.controls['pis'].touched &&
                formDocumento.controls['pis'].hasError('required')) ||
              (formDocumento.controls['pis'].hasError('required') && temErro)
            "
          >
            Campo obrigatório
          </span>
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">UF da CTPS</label>
          <select
            class="form-control form-control-uniform"
            name="ufCtps"
            formControlName="ufCtps"
            [ngStyle]="
              (formDocumento.controls['ufCtps'].touched || temErro) &&
              formDocumento.controls['ufCtps'].status == 'INVALID'
                ? { 'border-color': 'red' }
                : null
            "
            required
          >
            <option value="">UF</option>
            <option *ngFor="let estado of listaUf" value="{{ estado.SiglaUf }}">
              {{ estado.SiglaUf }}
            </option>
          </select>
          <span
            class="form-text text-danger"
            *ngIf="
              formDocumento.controls['ufCtps'].touched &&
              (temErro || formDocumento.controls['ufCtps'].hasError('required'))
            "
          >
            Campo obrigatório
          </span>
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">
            Certificado Militar <small>(opcional)</small></label
          >
          <input
            type="text"
            mask="0*"
            maxlength="30"
            class="form-control"
            formControlName="csm"
            placeholder="CSM"
          />
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold"> Nº de Série <small> (opcional)</small></label>
          <input
            type="text"
            class="form-control"
            pattern="[A-Za-z]"
            (input)="uppercase($event)"
            formControlName="serieCertificadoMilitar"
            [ngClass]="
              formDocumento.get('serieCertificadoMilitar').invalid &&
              (temErro || formDocumento.get('serieCertificadoMilitar').touched)
                ? 'is-invalid'
                : null
            "
          />
          <span
            class="form-text text-danger"
            *ngIf="
              (temErro || formDocumento.get('serieCertificadoMilitar').touched) &&
              formDocumento.get('serieCertificadoMilitar').invalid
            "
          >
            {{
              formDocumento.get('serieCertificadoMilitar').hasError('pattern')
                ? 'Campo só permite letras'
                : 'Campo Obrigatório'
            }}
          </span>
        </div>
        <div class="form-group col-xl-3">
          <label class="text-muted font-weight-bold">Número <small>(opcional)</small> </label>
          <input
            type="text"
            class="form-control"
            mask="0*"
            formControlName="numeroCertificadoMilitar"
            placeholder="Número"
          />
        </div>
      </div>
    </form>
  </div>
</div>
