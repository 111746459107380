<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <h4 class="text-white">Carregando...</h4>
</ngx-spinner>
<div class="row component">
  <div class="col-lg-12 col-xl-12 form-group" id="colaboradores">
    <div class="card">
      <div class="card-header">
        <div class="form-group header-elements-inline">
          <h3 class="card-title">Colaboradores</h3>
          <div class="header-elements"></div>
        </div>
      </div>

      <div class="card-body">
        <div class="form-group row">
          <div class="input-group col-lg-12">
            <input
              type="text"
              class="form-control"
              name="searchText"
              [placeholder]="
                listagemTodosColaboradores.length > 0
                  ? 'Buscar colaborador por nome, CPF ou matrícula'
                  : 'Carregando...'
              "
              [disabled]="listagemTodosColaboradores.length === 0"
              [(ngModel)]="searchText"
              (input)="gerarListaColaboradores()"
            />
            <span class="input-group-append">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
            </span>
          </div>
        </div>
        <div class="row px-2 pb-2" *ngIf="carregado">
          <div class="flex-grow-1">
            <h5 class="text-center text-md-left font-weight-semibold">
              Perfis Encontrados: {{ retornarTamanhoColaboradores() }}
            </h5>
          </div>
          <div class="mx-auto">
            <button
              class="btn btn-sm btn-outline-primary"
              id="openModalButton"
              data-toggle="modal"
              data-target="#filtroModal"
              data-backdrop="static"
              data-keyboard="false"
              data-backdrop="static"
            >
              <i class="fa fa-filter pr-2"></i>
              <span>Filtrar</span>
            </button>
            <button class="btn btn-sm btn-outline-primary ml-2" (click)="exportAsXLSX()">
              <i class="fa fa-download pr-2"></i>
              <span>Exportar</span>
            </button>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-lg-12 form-group text-center">
            <div class="table-responsive table-sm">
              <cdk-virtual-scroll-viewport [itemSize]="2" class="col-12">
                <table
                  class="table table-hover mb-0"
                  [ngClass]="colaborador.length > 0 ? 'table-striped' : ''"
                >
                  <thead class="sticky-top bg-white border-bottom">
                    <tr>
                      <th class="esconder-mobile">Gênero</th>
                      <th class="text-left">
                        Colaborador
                        <a
                          (click)="ordenarPorNome()"
                          class="btn-link text-primary ml-1"
                          title="Ordenar por ordem alfabética"
                        >
                          <i
                            class="fa"
                            [class]="
                              ordemNome == 0
                                ? 'fa-sort'
                                : ordemNome == 1
                                ? 'fa-arrow-down'
                                : 'fa-arrow-up'
                            "
                          ></i>
                        </a>
                      </th>
                      <th class="esconder-mobile col-2">
                        Matrícula
                        <a
                          (click)="ordenarPorMatricula()"
                          class="btn-link text-primary ml-1"
                          title="Ordenar por ordem alfabética"
                        >
                          <i
                            class="fa"
                            [class]="
                              ordemMatricula == 0
                                ? 'fa-sort'
                                : ordemMatricula == 1
                                ? 'fa-arrow-down'
                                : 'fa-arrow-up'
                            "
                          ></i>
                        </a>
                      </th>
                      <th class="esconder-pc">
                        Matrícula/ Situação
                        <a
                          (click)="ordenarPorMatricula()"
                          class="btn-link text-primary ml-1"
                          title="Ordenar por ordem alfabética"
                        >
                          <i
                            class="fa"
                            [class]="
                              ordemMatricula == 0
                                ? 'fa-sort'
                                : ordemMatricula == 1
                                ? 'fa-arrow-down'
                                : 'fa-arrow-up'
                            "
                          ></i>
                        </a>
                      </th>
                      <th class="text-uppercase esconder-mobile">
                        Cpf
                        <a
                          (click)="ordenarPorCPF()"
                          class="btn-link text-primary ml-1"
                          title="Ordenar por ordem de CPF"
                        >
                          <i
                            class="fa"
                            [class]="
                              ordemCPF == 0
                                ? 'fa-sort'
                                : ordemCPF == 1
                                ? 'fa-arrow-down'
                                : 'fa-arrow-up'
                            "
                          ></i
                        ></a>
                      </th>
                      <th class="esconder-mobile">Vínculo</th>
                      <th class="esconder-mobile">Situação</th>
                      <th class="esconder-mobile">Etapa Concluída</th>
                      <th class="esconder-mobile">Data Afastamento</th>
                    </tr>
                  </thead>
                  <!-- <ng-template></ng-template> -->
                  <tbody
                    class="search-results col-12"
                    data-toggle="modal"
                    data-target="#modalListaColaboradores"
                    *ngIf="colaborador.length > 0; else SemColaboradores"
                  >
                    <tr
                      *ngFor="
                        let colab of colaborador
                          | colaboradoresFiltro : searchText.normalize('NFKD');
                        let i = index
                      "
                      [ngStyle]="{ cursor: 'pointer' }"
                      (click)="verificaColabClicado(colab)"
                    >
                      <td class="esconder-mobile">
                        <img
                          [src]="
                            colab.Sexo.Valor === 1
                              ? 'assets/img/avatar-masculino.svg'
                              : 'assets/img/avatar-feminino.svg'
                          "
                          height="40"
                          [alt]="colab.Sexo.Valor === 1 ? 'Avatar masculino' : 'Avatar feminino'"
                        />
                      </td>
                      <td class="text-left">
                        <div class="row">
                          <b>{{ colab.NomeCompleto.normalize('NFKD') }}</b>
                        </div>
                        <div class="row" style="font-size: 10px">
                          {{ colab.NomeSetor }}/{{ colab.NomeCargo }}
                        </div>
                        <div class="row esconder-pc" style="font-size: 10px">
                          {{ colab.Vinculo }} - {{ colab.DescricaoVinculo }}
                        </div>
                        <div class="row esconder-pc" style="font-size: 10px">
                          {{ formataCPF(colab.Cpf) }}
                        </div>
                      </td>
                      <td class="esconder-mobile">
                        {{ colab.Matricula }}
                      </td>
                      <td class="esconder-pc">
                        {{ colab.Matricula }}<br />
                        {{ colab.Situacao.Descricao }}
                      </td>
                      <td width="200" class="esconder-mobile">
                        {{ formataCPF(colab.Cpf) }}
                      </td>
                      <td class="esconder-mobile">
                        {{ colab.Vinculo }} - {{ colab.DescricaoVinculo }}
                      </td>
                      <td class="esconder-mobile">
                        {{ colab.Situacao.Descricao }}
                      </td>
                      <td class="esconder-mobile">
                        {{ colab.Etapa == 0 ? 'Concluído' : colab.Etapa }}
                      </td>
                      <td class="esconder-mobile">
                        {{
                          colab.DataAfastamento.IsDate == false
                            ? 'Sem Informação'
                            : colab.DataAfastamento.ToDate
                        }}
                      </td>
                    </tr>
                  </tbody>
                  <ng-template #SemColaboradores>
                    <tbody>
                      <tr>
                        <td colspan="10">
                          <p class="text-center alert-warning p-2 mt-2 rounded">
                            <i class="fa fa-info-circle fa-3x my-2"></i><br />
                            Não foram encontrados colaboradores para os filtros selecionados.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </ng-template>
                </table>
              </cdk-virtual-scroll-viewport>
              <nav aria-label="Page navigation example">
                <!-- *ngIf="listaConsulta && listaConsulta.length > 0" -->
                <ul
                  class="pagination flex-1 mt-2 align-items-center justify-content-center d-print-none"
                >
                  <li class="page-item">
                    <button type="button" class="btn" (click)="paginaAnterior()">
                      <i class="fa fa-angle-left" aria-hidden="true"></i>
                    </button>
                  </li>
                  <li class="page-item">{{ paginacao }}</li>
                  <li class="page-item">
                    <button type="button" class="btn" (click)="proximaPagina()">
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal Lista Colaboradores -->
  <div
    class="modal fade"
    id="modalListaColaboradores"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalListaColaboradoresLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header border-bottom">
          <h5 class="modal-title mb-2" id="modalListaColaboradoresLabel">
            {{ colaboradorAtual.NomeCompleto }}
          </h5>
          <button
            id="modalClose"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row" *ngIf="colaboradorAtual.Situacao.Codigo !== 97">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
              <div class="mh-100">
                <i class="fa fa-user fa-x1 mr-2 text-info" aria-hidden="true"></i>
                <label
                  [ngStyle]="{ cursor: 'pointer' }"
                  (click)="moverParaPerfil()"
                  class="w-50 h6 text-lg-left font-weight-semibold text-info"
                  >Perfil</label
                >
              </div>
            </div>
          </div>
          <div class="row" *ngIf="colaboradorAtual.Situacao.Codigo !== 97">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">

              <div class="mh-100">
                <i class="fa fa-calculator fa-x1 mr-2 text-info" aria-hidden="true"></i>
                <label
                  [ngStyle]="{ cursor: 'pointer' }"
                  (click)="navegarParaRota('lancamento-financeiro')"
                  class="h6 text-lg-left font-weight-semibold text-info"
                  >Lançamento Financeiro</label
                >
              </div> 
            </div>
          </div>
          <!-- <div class="row" *ngIf="colaboradorAtual.VinculoF05 == '3'">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
              <div
                class="mh-100"
                data-toggle="collapse"
                data-target="#infoComplemento"
                aria-controls="infoComplemento"
              >
                <i
                  class="fa-solid fa-person-circle-plus fa-x1 mr-2 text-info"
                  aria-hidden="true"
                ></i>
                <label
                  [ngStyle]="{ cursor: 'pointer' }"
                  class="h6 text-lg-left font-weight-semibold text-info"
                  >Informações Complementares</label
                >
              </div>
            </div>
            <div class="collapse" id="infoComplemento">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
                <div class="mh-100 pl-3">
                  <i class="fa-solid fa-circle-plus fa-x1 mr-2 text-info" aria-hidden="true"></i>
                  <label
                    [ngStyle]="{ cursor: 'pointer' }"
                    (click)="navegarParaRota('cadastro-complementar')"
                    class="h6 text-lg-left font-weight-semibold text-info"
                    >Cadastro Complementar
                  </label>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
                <div class="mh-100 pl-3">
                  <i class="fa-solid fa-stethoscope fa-x1 mr-2 text-info" aria-hidden="true"></i>
                  <label
                    [ngStyle]="{ cursor: 'pointer' }"
                    class="h6 text-lg-left font-weight-semibold text-info"
                    (click)="navegarParaRota('informacoes-saude')"
                    >Informações Saúde</label
                  >
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
                <div class="mh-100 pl-3">
                  <i
                    class="fa-solid fa-file-invoice-dollar fa-x1 mr-2 text-info"
                    aria-hidden="true"
                  ></i>
                  <label
                    [ngStyle]="{ cursor: 'pointer' }"
                    class="h6 text-lg-left font-weight-semibold text-info"
                    (click)="navegarParaRota('dados-beneficio')"
                    >Dados Benefício</label
                  >
                </div>
              </div>
            </div>
          </div> -->

          <!-- opções 99 fim -->
          <div class="row" *ngIf="colaboradorAtual.Situacao.Codigo === 99">
            <div (click)="fecharModal()" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
              <i class="fa fa-exchange mr-2 text-info" aria-hidden="true"></i>
              <label
                [ngStyle]="{ cursor: 'pointer' }"
                data-toggle="modal"
                data-target="#ModalTranferir"
                data-backdrop="static"
                data-keyboard="false"
                class="w-50 h6 text-left font-weight-semibold text-info"
                >Transferência</label
              >
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
              <div class="mh-100">
                <i class="fa fa-shopping-basket fa-x1 mr-2 text-info" aria-hidden="true"></i>
                <label
                  [ngStyle]="{ cursor: 'pointer' }"
                  (click)="navegarParaRota('complemento-cadastral/cessao')"
                  class="h6 text-lg-left font-weight-semibold text-info"
                  >Complemento Cadastral - Cessão</label
                >
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
              <div class="mh-100">
                <i class="fa fa-shopping-basket fa-x1 mr-2 text-info" aria-hidden="true"></i>
                <label
                  [ngStyle]="{ cursor: 'pointer' }"
                  (click)="navegarParaRota('complemento-cadastral/estagio')"
                  class="h6 text-lg-left font-weight-semibold text-info"
                  >Complemento Cadastral - Estágio</label
                >
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
              <div class="mh-100">
                <i class="fa fa-file-text fa-x1 mr-2 text-info" aria-hidden="true"></i>
                <label
                  [ngStyle]="{ cursor: 'pointer' }"
                  (click)="navegarParaRota('contracheque')"
                  class="h6 text-lg-left font-weight-semibold text-info"
                  >Contracheque</label
                >
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
              <div class="mh-100">
                <i class="fa fa-calculator fa-x1 mr-2 text-info" aria-hidden="true"></i>
                <label
                  [ngStyle]="{ cursor: 'pointer' }"
                  (click)="navegarParaRota('lancamento-financeiro')"
                  class="h6 text-lg-left font-weight-semibold text-info"
                  >Lançamento Financeiro</label
                >
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
              <div class="mh-100">
                <i class="fa fa-child fa-x1 mr-2 text-info" aria-hidden="true"></i>
                <label
                  [ngStyle]="{ cursor: 'pointer' }"
                  (click)="navegarParaRota('pensao-alimenticia')"
                  class="h6 text-lg-left font-weight-semibold text-info"
                  >Pensão Alimentícia</label
                >
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
              <div class="mh-100">
                <i class="fa fa-id-card fa-x1 mr-2 text-info" aria-hidden="true"></i>
                <label
                  [ngStyle]="{ cursor: 'pointer' }"
                  (click)="navegarParaRota('situacao-funcional')"
                  class="h6 text-lg-left font-weight-semibold text-info"
                  >Situação Funcional</label
                >
              </div>
            </div>
            <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
              <div
                class="mh-100"
                data-toggle="collapse"
                data-target="#infoComplemento"
                aria-controls="infoComplemento"
              >
                <i
                  class="fa-solid fa-person-circle-plus fa-x1 mr-2 text-info"
                  aria-hidden="true"
                ></i>
                <label
                  [ngStyle]="{ cursor: 'pointer' }"
                  class="h6 text-lg-left font-weight-semibold text-info"
                  >Informações Complementares</label
                >
              </div>
            </div>

            <div class="collapse" id="infoComplemento">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
                <div class="mh-100 pl-3">
                  <i class="fa-solid fa-circle-plus fa-x1 mr-2 text-info" aria-hidden="true"></i>
                  <label
                    [ngStyle]="{ cursor: 'pointer' }"
                    (click)="navegarParaRota('cadastro-complementar')"
                    class="h6 text-lg-left font-weight-semibold text-info"
                    >Cadastro Complementar
                  </label>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
                <div class="mh-100 pl-3">
                  <i class="fa-solid fa-file-invoice fa-x1 mr-2 text-info" aria-hidden="true"></i>
                  <label
                    [ngStyle]="{ cursor: 'pointer' }"
                    class="h6 text-lg-left font-weight-semibold text-info"
                    (click)="navegarParaRota('informacoes-concurso')"
                    >Informações Concurso</label
                  >
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
                <div class="mh-100 pl-3">
                  <i class="fa-solid fa-stethoscope fa-x1 mr-2 text-info" aria-hidden="true"></i>
                  <label
                    [ngStyle]="{ cursor: 'pointer' }"
                    class="h6 text-lg-left font-weight-semibold text-info"
                    (click)="navegarParaRota('informacoes-saude')"
                    >Informações Saúde</label
                  >
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
                <div class="mh-100 pl-3">
                  <i class="fa-solid fa-file-signature fa-x1 mr-2 text-info" aria-hidden="true"></i>
                  <label
                    [ngStyle]="{ cursor: 'pointer' }"
                    class="h6 text-lg-left font-weight-semibold text-info"
                    (click)="navegarParaRota('nome-social')"
                    >Nome Social</label
                  >
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
                <div class="mh-100 pl-3">
                  <i class="fa-solid fa-graduation-cap fa-x1 mr-2 text-info" aria-hidden="true"></i>
                  <label
                    [ngStyle]="{ cursor: 'pointer' }"
                    class="h6 text-lg-left font-weight-semibold text-info"
                    (click)="navegarParaRota('profissional-educacao')"
                    >Profissional Educação</label
                  >
                </div>
              </div>
            </div> -->
          </div>
          <div class="row" *ngIf="colaboradorAtual.Situacao.Codigo !== 97">
            <ng-container *ngTemplateOutlet="infoComplementarF05Igual3"></ng-container>
            <ng-container *ngTemplateOutlet="infoComplementarF05Diferente3"></ng-container>
          </div>
          <!-- opções 99 fim -->
          <!-- opções Outros  -->
          <!-- opções Outros fim -->
          <!-- opções 98 fim -->
          <div class="row" *ngIf="colaboradorAtual.Situacao.Codigo == 98 && !isComplemento">
            <div
              (click)="ativarCadastro()"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left"
            >
              <i class="fa fa-handshake-o mr-2 text-success" aria-hidden="true"></i>
              <label
                [ngStyle]="{ cursor: 'pointer' }"
                class="w-50 h6 font-weight-semibold text-success"
                >Efetivar Contratação</label
              >
            </div>
            <div
              (click)="cancelarCadastro()"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left"
            >
              <i class="fa fa-user-times mr-2 text-danger" aria-hidden="true"></i>
              <label
                [ngStyle]="{ cursor: 'pointer' }"
                class="w-50 h6 font-weight-semibold text-danger"
                >Cancelar Contratação</label
              >
            </div>
          </div>

          <div class="row" *ngIf="colaboradorAtual.Situacao.Codigo == 97">
            <div
              (click)="concluirCadastro()"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left"
            >
              <i class="fa fa-pencil-square-o fa-danger mr-1 text-info" aria-hidden="true"></i>
              <label
                [ngStyle]="{ cursor: 'pointer' }"
                class="w-50 h6 font-weight-semibold text-info"
                >Continuar Cadastro</label
              >
            </div>
            <div
              (click)="cancelarCadastro()"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left"
            >
              <i class="fa fa-trash-o mr-2 text-danger" aria-hidden="true"></i>
              <label
                [ngStyle]="{ cursor: 'pointer' }"
                class="w-50 h6 font-weight-semibold text-danger"
                >Cancelar Cadastro</label
              >
            </div>
          </div>

          <!-- Vinculo relativo ao complemento cadastral -->
          <div class="row" *ngIf="colaboradorAtual.Situacao.Codigo == 98 && isComplemento">
            <div
              (click)="concluirCadastroComplemento()"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left"
            >
              <i class="fa fa-pencil-square-o fa-danger mr-1 text-info" aria-hidden="true"></i>
              <label
                [ngStyle]="{ cursor: 'pointer' }"
                class="w-50 h6 font-weight-semibold text-info"
                >Complemento Cadastral</label
              >
            </div>
            <div
              (click)="cancelarCadastro()"
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left"
            >
              <i class="fa fa-trash-o mr-2 text-danger" aria-hidden="true"></i>
              <label
                [ngStyle]="{ cursor: 'pointer' }"
                class="w-50 h6 font-weight-semibold text-danger"
                >Cancelar Cadastro</label
              >
            </div>
            <ng-container *ngTemplateOutlet="infoComplementarF05Igual3"></ng-container>
            <ng-container *ngTemplateOutlet="infoComplementarF05Diferente3"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal Lista Colaboradores fim -->

  <!-- Filtro Sidebar para Computador e Tablet(Tela grande) -->
  <div *ngIf="modalFiltro" class="col-lg-4 col-xl-3 d-none d-lg-block form-group" id="filtros">
    <div
      *ngIf="!switchFiltroBool"
      class="card overflow-auto position-fixed"
      [ngStyle]="{ height: filtroLicenciado == true ? '75%' : '' }"
    >
      <!--   [style.position]=" licenciadosCheckbox == true ? 'relative ' : 'fixed'" -->
      <div class="card-body">
        <div class="row">
          <div class="col-6" style="cursor: pointer" (click)="collapseFiltro()">
            <h3 class="text-uppercase font-size-sm font-weight-semibold">
              Filtros &nbsp;
              <span *ngIf="filtros == false" class="fa fa-angle-right"></span>
              <span *ngIf="filtros == true" class="fa fa-angle-down"></span>
            </h3>
          </div>

          <div class="col-6 text-right" style="cursor: pointer" (click)="limparfiltro()">
            <p href="#" class="text-blue ml-2">Limpar Filtros</p>
          </div>
        </div>
        <div
          [ngClass]="{
            'collapse in form-group': filtros == true,
            'collapse form-group': filtros == false
          }"
        >
          <div class="row" style="cursor: pointer" (click)="collapseFiltroSituacao()">
            <div class="form-group col-12">
              <h3 class="text-uppercase font-size-sm font-weight-semibold">
                Situações &nbsp;
                <span *ngIf="filtroSituacao == false" class="fa fa-angle-right"></span>
                <span *ngIf="filtroSituacao == true" class="fa fa-angle-down"></span>
              </h3>
            </div>
          </div>

          <div
            id="filtroSituacoes"
            class="overflow-auto"
            style="max-height: 400px"
            [ngClass]="{
              'collapse in': filtroSituacao == true,
              collapse: filtroSituacao == false
            }"
          >
            <form action="#">
              <div class="form-group mr-2">
                <div *ngFor="let situacao of distinctSituacao">
                  <div class="m-checkbox my-1">
                    <div
                      #divAtivos
                      class="form-check form-check-right"
                      (click)="
                        situacao.Situacao = situacao.Situacao == true ? false : true;
                        efetuarFiltragem()
                      "
                    >
                      <label
                        [ngClass]="
                          situacao.Situacao
                            ? 'form-check-label font-weight-semibold'
                            : 'form-check-label'
                        "
                        for="divAtivos"
                      >
                        <div class="uniform-checker">
                          <span [ngClass]="situacao.Situacao ? 'checked bg-primary' : ''">
                            <input
                              type="checkbox"
                              class="form-input-styled"
                              checked=""
                              data-fouc=""
                            />
                          </span>
                        </div>
                        {{ situacao.Descricao }} ({{ situacao.Tam.toLocaleString('pt') }})
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Filtro Sidebar para Computador e Tablet(Tela grande) -->
</div>

<!-- Basic modal -->
<div id="modal_default" class="modal fade" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Como Cadastrar</h5>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body">
        <!-- <video width="100%" controls autoplay loop>
                    <source src=" https://www.youtube.com/watch?v=1hCmkJyihVo" type="video/mp4">
                </video> -->
        <!-- <div class="embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            src="https://www.youtube.com/embed/tgbNymZ7vqY"
          ></iframe>
        </div> -->
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-link" data-dismiss="modal">Fechar</button>
        <!-- <button type="button" class="btn bg-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>
<!-- /basic modal -->
<!-- Button trigger modal -->

<!-- Modal -->
<div
  class="modal fade"
  id="filtroModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="filtroModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header navbar-light bg-light">
        <div class="container">
          <div class="row align-items-start">
            <h5 class="mr-auto mb-0">Filtro</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">×</button>
          </div>
          <div class="row pt-1">
            <button class="btn btn-sm btn-outline-primary ml-auto" (click)="limparFiltros()">
              <i class="fa fa-filter pr-2"></i>
              <span>Limpar filtros</span>
            </button>
            <button
              class="btn btn-sm btn-outline-primary ml-2 mr-auto mr-md-0"
              (click)="marcarTodosColaboradores()"
            >
              <i class="fa fa-download pr-2"></i>
              <span>Selecionar Todos</span>
            </button>
          </div>
        </div>
      </div>
      <div class="modal-bodyd mx-2 navbar-light bg-light" style="min-height: 300px">
        <div class="card-body overflow-auto pt-0 mt-3" style="max-height: 400px">
          <table class="table table-striped border-blue">
            <thead class="bg-teal-400 sticky-top">
              <tr>
                <th class="text-center esconder-mobile">Código</th>
                <th class="text-center">Descrição</th>
                <th class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let filtro of listafiltroColaborador; let i = index">
                <td class="text-center esconder-mobile">
                  {{ filtro.Codigo === '99' ? '1' : filtro.Codigo }}
                </td>
                <td>{{ filtro.Descricao }}</td>
                <td>
                  <div class="m-checkbox my-1">
                    <div
                      #divAtivos
                      class="form-check form-check-right"
                      (click)="filtro.Situacao = !filtro.Situacao"
                    >
                      <label
                        [ngClass]="
                          filtro.Situacao
                            ? 'form-check-label font-weight-semibold'
                            : 'form-check-label'
                        "
                        for="divAtivos"
                      >
                        <div class="uniform-checker">
                          <span [ngClass]="filtro.Situacao ? 'checked bg-primary' : ''">
                            <input
                              type="checkbox"
                              class="form-input-styled"
                              checked=""
                              data-fouc=""
                            />
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="modal-footer navbar-light bg-light">
        <button
          type="button"
          (click)="buscarColaboradoresFiltrados()"
          data-dismiss="modal"
          class="btn btn-primary mt-2"
          style="cursor: pointer"
        >
          Buscar
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="ModalTranferir"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ModalTranferirTitle"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" *ngIf="transferenciaNovaFilial == null">
          Transferência para uma empresa filial diferente?
        </h5>
        <h5 class="modal-title" *ngIf="transferenciaNovaFilial != null">
          Transferência de {{ capitalize(colaboradorAtual.NomeCompleto) }} -
          {{ colaboradorAtual.Matricula }}
        </h5>
        <button
          type="button"
          id="closeModalTranferencia"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="fecharModalTransferencia()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form (ngSubmit)="submitTransferencia()" [formGroup]="formTransferencia">
        <div class="modal-body">
          <div class="row" *ngIf="transferenciaNovaFilial != null">
            <div class="form-group col-md-4" *ngIf="transferenciaNovaFilial">
              <label class="font-weight-semibold">Empresa Filial</label>
              <select
                class="form-control"
                formControlName="filial"
                (change)="buscarLotacao()"
                [ngClass]="
                  formTransferencia.get('filial').invalid && formTransferencia.get('filial').touched
                    ? 'is-invalid'
                    : null
                "
              >
                <option value="">Selecione...</option>
                <option *ngFor="let filial of listaFilialDestino" value="{{ filial.Codigo }}">
                  {{ filial.Codigo }} - {{ filial.Nome }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label class="font-weight-semibold">Lotação</label>
              <select
                class="form-control"
                formControlName="lotacao"
                [ngClass]="
                  formTransferencia.get('lotacao').invalid &&
                  formTransferencia.get('lotacao').touched
                    ? 'is-invalid'
                    : null
                "
              >
                <option value="">Selecione...</option>
                <option *ngFor="let lotacao of listaLotacao" value="{{ lotacao.CodLot }}">
                  {{ lotacao.CodLot }} - {{ lotacao.Nome }}
                </option>
              </select>
              <span class="form-text" *ngIf="loadingLotacao"
                >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
              ></span>
            </div>
            <div class="form-group col-md-4">
              <label class="font-weight-semibold">Data do Evento</label>
              <input
                type="date"
                class="form-control pr-0"
                formControlName="dataEvento"
                [ngClass]="
                  formTransferencia.controls['dataEvento'].touched &&
                  formTransferencia.controls['dataEvento'].status == 'INVALID'
                    ? 'is-invalid'
                    : null
                "
              />
              <span
                class="form-text text-danger"
                *ngIf="formTransferencia.controls['dataEvento'].hasError('data')"
              >
                Data inválida
              </span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div *ngIf="transferenciaNovaFilial == null" class="col-12 d-flex justify-content-end">
            <button
              type="button"
              (click)="transferenciaMesmaEmpfil()"
              class="btn btn-secondary col-md-2 mr-2"
              data-dismiss="modal"
            >
              Não
            </button>
            <button
              type="button"
              (click)="transferenciaNovaEmpfil()"
              class="btn btn-primary col-md-2"
            >
              Sim
            </button>
          </div>
          <div *ngIf="transferenciaNovaFilial != null" class="col-12 d-flex justify-content-end">
            <button
              type="button"
              (click)="closeModal()"
              class="btn btn-secondary col-md-2 mr-2"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary col-md-2"
              [disabled]="formTransferencia.invalid || loadingTranferencia"
            >
              <p *ngIf="!loadingTranferencia" class="m-0">Salvar</p>
              <i *ngIf="loadingTranferencia" class="fa fa-spinner fa-spin" aria-hidden="false"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- template info complementar f05 = 3 -->
<ng-template #infoComplementarF05Igual3>
  <div *ngIf="colaboradorAtual.VinculoF05 == '3'">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
      <div
        class="mh-100"
        data-toggle="collapse"
        data-target="#infoComplemento"
        aria-controls="infoComplemento"
      >
        <i class="fa-solid fa-person-circle-plus fa-x1 mr-2 text-info" aria-hidden="true"></i>
        <label
          [ngStyle]="{ cursor: 'pointer' }"
          class="h6 text-lg-left font-weight-semibold text-info"
          >Informações Complementares</label
        >
      </div>
    </div>
    <div class="collapse" id="infoComplemento">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
        <div class="mh-100 pl-3">
          <i class="fa-solid fa-circle-plus fa-x1 mr-2 text-info" aria-hidden="true"></i>
          <label
            [ngStyle]="{ cursor: 'pointer' }"
            (click)="navegarParaRota('cadastro-complementar')"
            class="h6 text-lg-left font-weight-semibold text-info"
            >Cadastro Complementar
          </label>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
        <div class="mh-100 pl-3">
          <i class="fa-solid fa-stethoscope fa-x1 mr-2 text-info" aria-hidden="true"></i>
          <label
            [ngStyle]="{ cursor: 'pointer' }"
            class="h6 text-lg-left font-weight-semibold text-info"
            (click)="navegarParaRota('informacoes-saude')"
            >Informações Saúde</label
          >
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
        <div class="mh-100 pl-3">
          <i class="fa-solid fa-file-invoice-dollar fa-x1 mr-2 text-info" aria-hidden="true"></i>
          <label
            [ngStyle]="{ cursor: 'pointer' }"
            class="h6 text-lg-left font-weight-semibold text-info"
            (click)="navegarParaRota('dados-beneficio')"
            >Dados Benefício</label
          >
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- template info complementar f05 != 3 -->
<ng-template #infoComplementarF05Diferente3>
  <div
    class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left"
    *ngIf="colaboradorAtual.VinculoF05 != '3'"
  >
    <div
      class="mh-100"
      data-toggle="collapse"
      data-target="#infoComplemento"
      aria-controls="infoComplemento"
    >
      <i class="fa-solid fa-person-circle-plus fa-x1 mr-2 text-info" aria-hidden="true"></i>
      <label
        [ngStyle]="{ cursor: 'pointer' }"
        class="h6 text-lg-left font-weight-semibold text-info"
        >Informações Complementares</label
      >
    </div>
  </div>

  <div class="collapse" id="infoComplemento" *ngIf="colaboradorAtual.VinculoF05 != '3'">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
      <div class="mh-100 pl-3">
        <i class="fa-solid fa-circle-plus fa-x1 mr-2 text-info" aria-hidden="true"></i>
        <label
          [ngStyle]="{ cursor: 'pointer' }"
          (click)="navegarParaRota('cadastro-complementar')"
          class="h6 text-lg-left font-weight-semibold text-info"
          >Cadastro Complementar
        </label>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
      <div class="mh-100 pl-3">
        <i class="fa-solid fa-file-invoice fa-x1 mr-2 text-info" aria-hidden="true"></i>
        <label
          [ngStyle]="{ cursor: 'pointer' }"
          class="h6 text-lg-left font-weight-semibold text-info"
          (click)="navegarParaRota('informacoes-concurso')"
          >Informações Concurso</label
        >
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
      <div class="mh-100 pl-3">
        <i class="fa-solid fa-stethoscope fa-x1 mr-2 text-info" aria-hidden="true"></i>
        <label
          [ngStyle]="{ cursor: 'pointer' }"
          class="h6 text-lg-left font-weight-semibold text-info"
          (click)="navegarParaRota('informacoes-saude')"
          >Informações Saúde</label
        >
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
      <div class="mh-100 pl-3">
        <i class="fa-solid fa-file-signature fa-x1 mr-2 text-info" aria-hidden="true"></i>
        <label
          [ngStyle]="{ cursor: 'pointer' }"
          class="h6 text-lg-left font-weight-semibold text-info"
          (click)="navegarParaRota('nome-social')"
          >Nome Social</label
        >
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
      <div class="mh-100 pl-3">
        <i class="fa-solid fa-graduation-cap fa-x1 mr-2 text-info" aria-hidden="true"></i>
        <label
          [ngStyle]="{ cursor: 'pointer' }"
          class="h6 text-lg-left font-weight-semibold text-info"
          (click)="navegarParaRota('profissional-educacao')"
          >Profissional Educação</label
        >
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-left">
      <div class="mh-100 pl-3">
        <i class="fa-solid fa fa-map-o fa-x1 mr-2 text-info" aria-hidden="true"></i>
        <label
          [ngStyle]="{ cursor: 'pointer' }"
          class="h6 text-lg-left font-weight-semibold text-info"
          (click)="navegarParaRota('estrangeiro')"
          >Estrangeiro ou reside fora</label
        >
      </div>
    </div>
  </div>
</ng-template>
