<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p> </ngx-spinner
><ngx-spinner bdColor="rgba(51,51,51,0.8)" name="faltas" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>


<app-pesquisar-matricula
  *ngIf="matricula == null"
  [titulo]="'Faltas ao Trabalho'"
  [inputText]="true"
  (colaboradorEmitter)="matriculaListener($event)"
></app-pesquisar-matricula>

<section class="container" *ngIf="matricula != null">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title font-weight-semibold">
        <i
          class="fa fa-arrow-left pr-3 btn"
          aria-hidden="true"
          (click)="retornarTelaInicial()"
          style="cursor: pointer; bottom: 2px"
        ></i
        >Faltas ao Trabalho
      </h3>
    </div>
    <div class="card-body">
      <form class="row" [formGroup]="formColaborador">
        <div class="col-md-2 form-group">
          <label class="font-weight-semibold">Matrícula</label>

          <input
            type="text"
            class="form-control"
            placeholder="Matrícula"
            mask="0*"
            formControlName="matricula"
          />

          <span class="form-text" *ngIf="loading"
            >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
          ></span>
        </div>

        <div class="col-md-10 form-group">
          <label class="font-weight-semibold">Nome do Colaborador</label>
          <input type="text" class="form-control" placeholder="Nome" formControlName="nome" />
        </div>
      </form>
    </div>
  </div>

  <div class="card d-flex justify-content-center">
    <div class="card-header d-inline-flex justify-content-between">
      <h3 class="card-title font-weight-semibold">Lista de Faltas</h3>
      <div>
        <button type="button" class="btn btn-outline-primary" (click)="downloadPdf()">
          <i class="bi bi-download"></i> Download
        </button>
        <button
          type="button"
          class="btn btn-outline-primary ml-3"
          (click)="aoAbrirModal()"
          data-toggle="modal"
          data-target="#cadastroFaltasModal"
        >
          <i class="fa fa-plus"></i> Adicionar Falta
        </button>
      </div>
    </div>
    <div class="card-body table-responsive mt-2">
      <table
        class="table table-striped border-blue text-center mt-2"
        *ngIf="listaFaltas.length > 0"
      >
        <thead class="bg-teal-400">
          <tr>
            <th>Nº Processo</th>
            <th>Motivo</th>
            <th>Data Inicial</th>
            <th>Data Volta</th>
            <th>Dias</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody *ngFor="let falta of listaFaltas; let i = index">
          <tr>
            <td>{{ falta.NumeroProcesso ? falta.NumeroProcesso : 'Não informado' }}</td>
            <td>{{ falta.CodMot }} - {{ falta.DescMot }}</td>
            <td>{{ falta.DtInicio.ToDate }}</td>
            <td>{{ falta.DtVolta.ToDate }}</td>
            <td>
              {{
                falta.DtVolta.SetData == '00000000'
                  ? 'Dias indefinidos'
                  : calcularDias(falta.DtInicio.SetData, falta.DtVolta.SetData)
              }}
            </td>
            <td class="d-flex justify-content-center">
              <button
                type="button"
                class="btn btn-outline-info mr-2"
                data-toggle="collapse"
                [attr.data-target]="'#collapseFaltas' + falta.Seq"
              >
                <i class="fa fa-info"></i>
              </button>
              <button
                type="button"
                class="btn btn-outline-primary mr-2"
                data-toggle="modal"
                data-target="#cadastroFaltasModal"
                (click)="aoAbrirModal(falta)"
              >
                <i class="fa fa-pencil"></i>
              </button>
              <button
                type="button"
                [ngClass]="{
                  'btn-outline-primary': falta.Obs === 'N',
                  'btn-primary': falta.Obs === 'S'
                }"
                class="btn mr-2"
                data-toggle="modal"
                data-target="#observacoesFaltasModal"
                (click)="modalObservacoes.abrirModal(falta)"
              >
                <i class="fa-solid fa-book fa-lg"></i>
              </button>
              <button
                type="button"
                [ngClass]="{
                  'btn-outline-primary': falta.Documento === 'N',
                  'btn-primary': falta.Documento === 'S'
                }"
                class="btn mr-2"
                data-toggle="modal"
                data-target="#anexoFaltasModal"
                (click)="modalAnexo.abrirModal(falta)"
              >
                <i class="fa fa-file"></i>
              </button>
              <button type="button" class="btn btn-outline-danger" (click)="deletarFalta(falta)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
          <td colspan="12" class="collapse px-0" [id]="'collapseFaltas' + falta.Seq">
            <table class="table table-bordered pb-3">
              <thead class="text-center">
                <tr class="text-center table-primary">
                  <th>Afastamento</th>
                  <th>CRM Médico</th>
                  <th>Classificação Ato</th>
                  <th>Nº Ato</th>
                  <th>Nº DOE</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center table-primary">
                  <td>{{ falta.CodAfa }} - {{ falta.DescAfa }}</td>
                  <td>{{ falta.CodMed ? falta.CodMed : 'Não informado' }}</td>
                  <td>
                    {{
                      falta.IdClassificacaoAto != 0
                        ? falta.IdClassificacaoAto + ' - ' + falta.DescClassificacaoAto
                        : 'Não informado'
                    }}
                  </td>
                  <td>{{ falta.NumeroAto ? falta.NumeroAto : 'Não informado' }}</td>
                  <td>{{ falta.NumeroDoe ? falta.NumeroDoe : 'Não informado' }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tbody>
      </table>

      <div
        *ngIf="listaFaltas.length === 0"
        class="p-4 d-flex justify-content-center align-items-center"
      >
        <h6 class="font-weight-semibold">Não há dados de faltas para esse colaborador.</h6>
      </div>
    </div>
  </div>
</section>

<div class="modal fade" id="cadastroFaltasModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-faltas-cadastro
        [cadastro]="cadastroFalta"
        [colaborador]="colaborador"
        [faltaAtual]="faltaAtual"
      ></app-faltas-cadastro>
    </div>
  </div>
</div>
<div class="modal fade" id="observacoesFaltasModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-observacoes-ficha-funcional
        (gatilhoAtivado)="buscarFaltas()"
        [colaborador]="colaborador"
        [tipo]="tipoPagina"
      >
      </app-observacoes-ficha-funcional>
    </div>
  </div>
</div>
<div class="modal fade" id="anexoFaltasModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-anexo
        (gatilhoAtivado)="buscarFaltas()"
        [pagina]="tipoPagina"
        [matricula]="colaborador?.Matricula"
        [enviarSeparadamente]="true"
        [modal]="true"
      ></app-anexo>
    </div>
  </div>
</div>
