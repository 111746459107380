import { Data } from "../../../service/interfaces/Data";

export class Ferias{
    Competencia: string;
    TipoProcessamento: string;
    DT1AQF: string;
    DT2AQF: string;
    DiasFerias: number;
    DiasGozo: number;
    DiasGozoReservado: number;
    TipoFerias: number;
    Condesp: string;
    Situacao: number;
    DataInicioFerias: Data;
    DataCompetenciaAtualizacao: Data;

    constructor(){
        
    }
}