
<div >
    <div *ngIf="!identificado" class="text-center d-flex justify-content-center align-items-center" >
        <div class="col-xs-10 col-sm-10 col-md-8 col-lg-6 col-xl-4">
            <div class="text-center card p-4" style="background-color: #181818;" >
                <div class="col-md-12 col-lg-12 col-xl-6  align-self-center">
                    <img src="assets\img\elogica-gente.png" style="height: auto;width: 100%"><br><br>
                </div>
        
                <form #salt="ngForm" [formGroup]="formSalt" (ngSubmit)="verificarSalt()">
                    <h1 style="color:white" class="mb-3">Informe os dados recebidos por email</h1>
                    <div class="row d-flex justify-content-center">
                        <div class="col-md-6 col-lg-5 col-xl-5 form-group text-center align-self-center">
                            <label class="font-weight-semibold" style="color:white">Nº de Ordem</label>
                            <input type="text" class="form-control" placeholder="Nº de Ordem" name="numOrd" formControlName="numOrd_form"/>
        
                        </div>
                        <div class="col-md-6 col-lg-5 col-xl-5 form-group text-center align-self-center">
                            <label class="font-weight-semibold" style="color:white">Senha</label>
                            <input type="password" class="form-control" placeholder="Senha" name="senha" formControlName="senha_form" />
                            
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button type="submit" class="btn" style="background-color:#ffaa00; color: black"   >
                            <p class="m-0" *ngIf="!carregandoLogin">Submeter</p> 
                            <i class="fa fa-spinner fa-spin" *ngIf="carregandoLogin" aria-hidden="false"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    
    <div *ngIf="identificado">
        teste
    </div>

    <!-- Footer -->
    <div class="navbar navbar-expand-lg navbar-light fixed-bottom">
        <div class="text-center d-lg-none w-100">
            <button type="button" class="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
                <i class="icon-unfold mr-2"></i>
                Click para opções
            </button>
        </div>

        <div class="navbar-collapse collapse" id="navbar-footer">
            <!-- <span class="navbar-text overlay" >
                <b>&copy; Ayu!Gate</b>
            </span> -->
            <ul class="navbar-nav ml-lg-auto">
                <!-- <li class="nav-item"><a href="#" class="navbar-nav-link"><i class="icon-lifebuoy mr-2"></i> Suporte</a></li> -->
                <!-- <li class="nav-item mr-5"><a href="#" class="navbar-nav-link"><i class="icon-file-text2 mr-2"></i> Documentação</a></li> -->
                <span class="navbar-text overlay" >
                    <b>&copy; Elógica</b>
                </span>
            </ul>
        </div>
    </div>
    <!-- /footer -->
</div>




<!-- Basic modal -->
<div id="modal_default" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Como Cadastrar</h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <!-- <video width="100%" controls autoplay loop>
                            <source src=" https://www.youtube.com/watch?v=1hCmkJyihVo" type="video/mp4">
                        </video> -->
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-link" data-dismiss="modal">Fechar</button>
                <!-- <button type="button" class="btn bg-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
<!-- /basic modal -->