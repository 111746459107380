import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AcessoService } from '../../service/acesso/acesso.service';
import { Data } from 'src/app/service/interfaces/Data';
import { BuscarColaboradorInativo, Cadastro, Usuario } from './models/interfaces';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root',
})
export class CadastroPublicoService {
  localStorage: LocalStorage;
  url_acesso: string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  filial: string;
  codTarefa = '000000000';
  tpProcessamento = 'EM';

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.url_acesso = this.acesso.validarUrl();
    }
  }

  buscarColaboradorMatricula(matricula: string) {
    return this.http.get(`${this.url_acesso}cadastro-ativo-matricula/${matricula}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }
  buscarColaborador(colaborador: any) {
    return this.http.get(
      `${this.url_acesso}cadastro-publico/etapas/${colaborador.Empfil}/${colaborador.Matricula}`,
      { headers: this.cabecalho }
    );
  }
  buscarColaboradorInativo(parametro: BuscarColaboradorInativo) {
    return this.http.get(
      `${this.url_acesso}cadastro-publico/etapas/mat/${parametro.empfil}/${parametro.matricula}`,
      {
        headers: this.cabecalho,
      }
    );
  }

  verificarCadastroExistente(parametro: BuscarColaboradorInativo) {
    return this.http.get(
      `${this.url_acesso}/cadastro-publico/etapas/consulta-cpf/${parametro.empfil}/${parametro.cpf}`,
      { headers: this.cabecalho }
    );
  }

  buscarSindicato() {
    return this.http.get(`${this.url_acesso}cadastro-publico/sindicatos`, {
      headers: this.cabecalho,
    });
  }

  buscarParametros() {
    return this.http.get(`${this.url_acesso}cadastro-publico/parametros/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  buscarSetorPrevidencia() {
    return this.http.get(`${this.url_acesso}lotacao/previdencia`, {
      headers: this.cabecalho,
    });
  }

  buscarMunicipio(codigo: string) {
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(`${this.url_acesso}estados/${codigo}`, {
      headers: this.cabecalho,
    });
  }

  buscarCep(cep: string) {
    return this.http.get(`${this.url_acesso}cep/${cep}`, {
      headers: this.cabecalho,
    });
  }

  buscaVinculo(dataNascimento: string) {
    return this.http.get(
      `${this.url_acesso}cadastro-publico/vinculo/${dataNascimento}/${this.filial}`,
      { headers: this.cabecalho }
    );
  }

  buscaClassificacaoAto(vinculo: string) {
    return this.http.get(`${this.url_acesso}cadastro-publico/classificacao-ato/${vinculo}`, {
      headers: this.cabecalho,
    });
  }
  criarUsuario(usuario: Usuario) {
    return this.http.post(`${this.url_acesso}colaborador/usuario`, usuario, {
      headers: this.cabecalho,
    });
  }

  buscarNivel(cargo: string) {
    return this.http.get(`${this.url_acesso}cadastro-publico/nivel/${this.filial}/${cargo}`, {
      headers: this.cabecalho,
    });
  }

  buscarCargaHoraria(funcao: string) {
    return this.http.get(`${this.url_acesso}cadastro/carga-horaria/${funcao}`, {
      headers: this.cabecalho,
    });
  }

  inserirEtapa1(cadastro: Cadastro) {
    return this.http.post(
      `${this.url_acesso}cadastro-publico/informacoes-basicas/${this.filial}`,
      cadastro,
      { headers: this.cabecalho }
    );
  }

  inserirEtapa2(cadastro: Cadastro, matricula) {
    return this.http.put(
      `${this.url_acesso}cadastro-publico/profissao/${this.filial}/${matricula}`,
      cadastro,
      { headers: this.cabecalho }
    );
  }

  inserirEtapa3(cadastro: Cadastro, matricula) {
    return this.http.put(
      `${this.url_acesso}cadastro-publico/contato/${this.filial}/${matricula}`,
      cadastro,
      { headers: this.cabecalho }
    );
  }

  inserirEtapa4(cadastro: Cadastro) {
    return this.http.put(
      `${this.url_acesso}cadastro-publico/documentos/${this.filial}/${cadastro.matricula}`,
      cadastro,
      { headers: this.cabecalho }
    );
  }
  inserirEtapa6(cadastro: Cadastro) {
    return this.http.put(
      `${this.url_acesso}cadastro-publico/pagamento/${this.filial}/${cadastro.matricula}`,
      cadastro,
      { headers: this.cabecalho }
    );
  }

  gerarLink(dados: any) {
    this.url_acesso = this.acesso.validarUrl();
    // return this.http.post(`${this.url_acesso}cadastrogerarlink/${this.filial}`, dados, { headers: this.cabecalho });
    return this.http.post(`${this.url_acesso}cadastropublicogerarlink/${this.filial}`, dados, {
      headers: this.cabecalho,
    });
  }

  criarCredenciais(dadosUsuario: object) {
    return this.http.post(this.url_acesso + `colaborador/emailanexo`, dadosUsuario, {
      headers: this.cabecalho,
    });
  }

  buscarBancos() {
    return this.http.get(`${this.url_acesso}bancos/`, {
      headers: this.cabecalho,
    });
  }

  buscarAgencias(nomeBanco: any) {
    return this.http.get(`${this.url_acesso}agencia/${nomeBanco}`, {
      headers: this.cabecalho,
    });
  }

  buscarOperacoes(nomeBanco: any) {
    return this.http.get(`${this.url_acesso}operacoes/${nomeBanco}/1`, {
      headers: this.cabecalho,
    });
  }

  buscarTipoDependencia() {
    return this.http.get(`${this.url_acesso}tiposdependentes`, {
      headers: this.cabecalho,
    });
  }
  buscarSexo() {
    return this.http.get(`${this.url_acesso}parametros/sexo`, {
      headers: this.cabecalho,
    });
  }

  buscarCadastro(numOrdem) {
    return this.http.get(`${this.url_acesso}cadastro/${numOrdem}/0000000/EM/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  verificarPermissaoAPI(codUsuario: string, codTarefa: string) {
    return this.http.get(
      `${this.url_acesso}cadastro-publico/tarefa-usuario/${codUsuario}/${codTarefa}`,
      {
        headers: this.cabecalho,
      }
    );
  }
  
  enviarEtapaDependentes(numOrdem: string) {
    const dados = {
      CodigoEtapa: 5,
      Descricao: 'Etapa Dependentes',
    };
  
    return this.http.put(
      `${this.url_acesso}cadastro-publico/alterar-etapa/${numOrdem}`, 
      dados, 
      { 
        headers: this.cabecalho, 
      }
    );
  }
  
}
