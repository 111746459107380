<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" name="premio" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<app-pesquisar-matricula
  *ngIf="matricula == null"
  [titulo]="'Licença prêmio'"
  [inputText]="true"
  (colaboradorEmitter)="matriculaListener($event)"
></app-pesquisar-matricula>

<section class="container" *ngIf="matricula != null">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title font-weight-semibold">
        <i
          class="fa fa-arrow-left pr-3 btn"
          aria-hidden="true"
          (click)="retornarTelaInicial()"
          style="cursor: pointer; bottom: 2px"
        ></i
        >Licença prêmio
      </h3>
    </div>
    <div class="card-body">
      <form #colaborador="ngForm" class="row" [formGroup]="formColaborador">
        <div class="col-md-2 form-group">
          <label class="font-weight-semibold">Matrícula</label>

          <input
            type="text"
            class="form-control"
            placeholder="Matrícula"
            mask="0*"
            formControlName="matricula"
          />

          <span class="form-text" *ngIf="loading"
            >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
          ></span>
        </div>

        <div class="col-md-10 form-group">
          <label class="font-weight-semibold">Nome do Colaborador</label>
          <input type="text" class="form-control" placeholder="Nome" formControlName="nome" />
        </div>
      </form>
    </div>
  </div>

  <div class="card d-flex justify-content-center">
    <div class="card-header d-inline-flex justify-content-between">
      <h3 class="card-title font-weight-semibold">Lista de Licenças</h3>
      <div>
        <button type="button" class="btn btn-outline-primary" (click)="downloadPdf()">
          <i class="bi bi-download"></i> Download
        </button>
        <button
          type="button"
          class="btn btn-outline-primary ml-3"
          (click)="aoAbrirModal()"
          data-toggle="modal"
          data-target="#cadastroLicencaModal"
        >
          <i class="fa fa-plus"></i> Adicionar Licença
        </button>
      </div>
    </div>
    <div class="card-body table-responsive mt-2">
      <table class="table table-striped border-blue text-left mt-2" *ngIf="listaLicenca.length > 0">
        <thead class="bg-teal-400">
          <tr>
            <th>Sequencial</th>
            <th>Numero de Dias</th>
            <th>Data Inicio</th>
            <th>Data Fim</th>
            <th>Forma de Quitação</th>

            <th class="text-right" style="padding-right: 88px">Ações</th>
          </tr>
        </thead>

        <tbody *ngFor="let licenca of listaLicenca; let i = index">
          <tr>
            <td>{{ licenca.Seq }}</td>
            <td>{{ licenca.NumDias }}</td>
            <td>{{ licenca.DataInicio.ToDate }}</td>
            <td>{{ licenca.DataFim.ToDate }}</td>
            <td>{{ getDescricaoFormaQuitacao(licenca.FormaQuitacao.toString()) }}</td>

            <td class="d-flex justify-content-center">
              <button
                type="button"
                class="btn btn-outline-info mr-2"
                data-toggle="collapse"
                [attr.data-target]="'#collapseFaltas' + licenca.Seq"
              >
                <i class="fa fa-info"></i>
              </button>
              <button
                type="button"
                class="btn btn-outline-primary mr-2"
                (click)="aoAbrirModal(i, licenca.Seq)"
                data-toggle="modal"
                data-target="#cadastroLicencaModal"
              >
                <i class="fa fa-pencil"></i>
              </button>
              <button
                type="button"
                [ngClass]="{
                  'btn-outline-primary': licenca.Obs === 'N',
                  'btn-primary': licenca.Obs === 'S'
                }"
                class="btn mr-2"
                data-toggle="modal"
                data-target="#observacoesLicencaPremioModal"
                (click)="modalObservacoes.abrirModal(licenca)"
              >
                <i class="fa-solid fa-book fa-lg"></i>
              </button>
              <button
                type="button"
                [ngClass]="{
                  'btn-outline-primary': licenca.Documento === 'N',
                  'btn-primary': licenca.Documento === 'S'
                }"
                class="btn mr-2"
                data-toggle="modal"
                data-target="#anexoLicencaModal"
                (click)="modalAnexo.abrirModal(licenca)"
              >
                <i class="fa fa-file"></i>
              </button>
              <button
                type="button"
                class="btn btn-outline-danger"
                (click)="deletarLicenca(licenca)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
          <td colspan="12" class="collapse px-0" [id]="'collapseFaltas' + licenca.Seq">
            <table class="table table-bordered pb-3">
              <thead class="text-center">
                <tr class="text-center table-primary">
                  <th>Flag</th>
                  <th>Id Classificacao Ato</th>
                  <th>DataDOE</th>
                  <th>DataAto</th>
                  <th>NumeroAto</th>
                  <th>NumeroDoe</th>
                  <th>NumeroProcesso</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center table-primary">
                  <td>{{ licenca.Flag }}</td>
                  <td>
                    {{ getDescricaoidClassificacaoAto(licenca.IdClassificacaoAto.toString()) }}
                  </td>
                  <td>{{ licenca.DtDoe.ToDate }}</td>
                  <td>{{ licenca.DtAto.ToDate }}</td>
                  <td>{{ licenca.NumeroAto }}</td>
                  <td>{{ licenca.NumeroDoe }}</td>
                  <td>{{ licenca.NumeroProcesso }}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tbody>
      </table>

      <div
        *ngIf="listaLicenca.length === 0"
        class="p-4 d-flex justify-content-center align-items-center"
      >
        <h6 class="font-weight-semibold">Não há dados de Licença para esse colaborador.</h6>
      </div>
    </div>
  </div>
</section>
<div class="modal fade" id="cadastroLicencaModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-licenca-premio-cadastro
        [cadastro]="cadastroLicenca"
        [colaborador]="colaborador"
        [formTelaInicial]="formTelaInicial"
      ></app-licenca-premio-cadastro>
    </div>
  </div>
</div>
<div class="modal fade" id="observacoesLicencaPremioModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-observacoes-ficha-funcional
        (gatilhoAtivado)="buscarLicenca()"
        [colaborador]="colaborador"
        [tipo]="tipoPagina"
      >
      </app-observacoes-ficha-funcional>
    </div>
  </div>
</div>

<div class="modal fade" id="anexoLicencaModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-anexo
        (gatilhoAtivado)="buscarLicenca()"
        [pagina]="tipoPagina"
        [matricula]="colaborador?.Matricula"
        [enviarSeparadamente]="true"
        [modal]="true"
      ></app-anexo>
    </div>
  </div>
</div>
