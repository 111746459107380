import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AcessoService } from '../acesso/acesso.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import Swal from 'sweetalert';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
export type Permissao = {
  Id: string;
  Descricao: string;
};

@Injectable({
  providedIn: 'root',
})
export class PermissoesService {
  private localStorage: LocalStorage = new LocalStorage();
  private headers = new HttpHeaders();
  private url_acesso: string;
  public permissoes: Permissao[];
  public rotaAtual: string;
  constructor(
    private http: HttpClient,
    private acesso: AcessoService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.url_acesso = this.acesso.validarUrl();
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.headers = this.headers.append('sessao', this.localStorage.Sessao);
    }
    this.fetchPermissoes();
  }

  private fetchPermissoes() {
    this.spinner.show('permissoes');
    const idUsuario = this.localStorage.CodigoUsuario;
    const idServico = this.localStorage.CodigoServico;
    const url = this.router.url.replace('/', '');
    this.rotaAtual = url;
    this.http
      .get<Permissao[]>(
        `${this.url_acesso}logins/tarefas-tela-usuario/${idUsuario}/${idServico}/${url}`,
        { headers: this.headers }
      )
      .subscribe({
        next: (data) => {
          setTimeout(() => {
            this.spinner.hide('permissoes');
            if (data.length > 0) {
              this.permissoes = data;
            } else {
              Swal('Atenção', 'Não há permissões setadas para essa tela.', 'warning');
            }
          }, 2000);
        },
        error: (err) => {
          this.spinner.hide('permissoes');
          Swal('Erro', `${err.error.Mensagem}`, 'error');
          console.log(err);
        },
      });
  }

  public procurarPorPermissao(permissao: string) {
    for (let i = 0; i < this.permissoes.length; i++) {
      if (this.permissoes[i].Descricao === permissao) return true;
    }
    return false;
  }
}
