import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { Component, OnInit } from '@angular/core';
import { TipoBeneficiarioService } from './tipo-beneficiario.service';
import { ErroService } from 'src/app/service/erros/erro.service';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { TipoBeneficiario } from './classes/TipoBeneficiario';
import Swal from 'sweetalert';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';

@Component({
  selector: 'app-tipo-beneficiario',
  templateUrl: './tipo-beneficiario.component.html',
  styleUrls: ['./tipo-beneficiario.component.css']
})
export class TipoBeneficiarioComponent implements OnInit {
  listaTipoBeneficiario: TipoBeneficiario[] = null;
  formTpBeneficio: FormGroup;
  editar: boolean = false;
  temErro: boolean = false;
  loadingSpinner: boolean = false;
  isVisibleFlag: boolean = false;

  constructor(public fb: FormBuilder, private tipoBeneficiarioService: TipoBeneficiarioService, private validaErro: ErroService) { }

  ngOnInit() {
    this.listarTipoBeneficiario();
    this.formTpBeneficio = this.fb.group({
      especialCheckBox: [false],
      Codigo: ['', Validators.required],
      Descricao: ['', Validators.required],
      DataInicial: ['', Validators.compose([Validators.required])],
      DataFinal: ['', Validators.compose([Validators.required])],
      F01: [{ value: '', disabled: true },],
      F02: [{ value: '', disabled: true },],
      F03: [{ value: '', disabled: true },],
      F04: [{ value: '', disabled: true },],
      F05: [{ value: '', disabled: true },],
      F06: [{ value: '', disabled: true },],
      F07: [{ value: '', disabled: true },],
      F08: [{ value: '', disabled: true },],
      F09: [{ value: '', disabled: true },],
      F10: [{ value: '', disabled: true },],
    })
  }

  listarTipoBeneficiario() {
    this.tipoBeneficiarioService.listarTipoBeneficiario()
      .subscribe(
        (data: any) => {
          this.listaTipoBeneficiario = data;
        },
        error => this.validaErro.retornoErro(error)
      )
  }
  
  especialCheckBox() {
    const F01 = this.formTpBeneficio.get('F01');
    const F02 = this.formTpBeneficio.get('F02');
    const F03 = this.formTpBeneficio.get('F03');
    const F04 = this.formTpBeneficio.get('F04');
    const F05 = this.formTpBeneficio.get('F05');
    const F06 = this.formTpBeneficio.get('F06');
    const F07 = this.formTpBeneficio.get('F07');
    const F08 = this.formTpBeneficio.get('F08');
    const F09 = this.formTpBeneficio.get('F09');
    const F10 = this.formTpBeneficio.get('F10');
    if (this.formTpBeneficio.value.especialCheckBox) {
      this.isVisibleFlag = true;
      F01.enable();
      F02.enable();
      F03.enable();
      F04.enable();
      F05.enable();
      F06.enable();
      F07.enable();
      F08.enable();
      F09.enable();
      F10.enable();
    } else {
      this.isVisibleFlag = false;
      if (!this.editar) {
        F01.setValue('');
        F02.setValue('');
        F03.setValue('');
        F04.setValue('');
        F05.setValue('');
        F06.setValue('');
        F07.setValue('');
        F08.setValue('');
        F09.setValue('');
        F10.setValue('');
      }
      F01.disable();
      F02.disable();
      F03.disable();
      F04.disable();
      F05.disable();
      F06.disable();
      F07.disable();
      F08.disable();
      F09.disable();
      F10.disable();
    }
  }

  salvarTipoBeneficio(form) {
    if (!this.formTpBeneficio.valid) {
      this.temErro = true
      new AlertaModal("alerta", "Atenção", "Preencha todos os campos com erro!", 'p');
      return false;
    }
    const formValue = this.formTpBeneficio.getRawValue();
    if (!this.editar && (this.listaTipoBeneficiario.find(TpBeneficio => TpBeneficio.Codigo == formValue.Codigo))) {
      new AlertaModal("alerta", "Atenção", "Já existe um código cadastrado com esse valor!", 'p');
      return false;
    }

    const tipoBeneficio: TipoBeneficiario = {
      Codigo: formValue.Codigo,
      Descricao: formValue.Descricao,
      DataInicial: formValue.DataInicial,
      DataFinal: formValue.DataFinal,
      F01: formValue.F01,
      F02: formValue.F02,
      F03: formValue.F03,
      F04: formValue.F04,
      F05: formValue.F05,
      F06: formValue.F06,
      F07: formValue.F07,
      F08: formValue.F08,
      F09: formValue.F09,
      F10: formValue.F10,
    }
    this.loadingSpinner = true
    this.tipoBeneficiarioService.salvarTipoBeneficio(tipoBeneficio)
      .subscribe(
        data => {
          new AlertaModal("sucesso", "Sucesso", this.editar ? "Benefício alterado!" : "Benefício cadastrado!", 'p');
          form.reset();
          this.especialCheckBox();
          tipoBeneficio.DataInicial = tipoBeneficio.DataInicial.replace(/-/gi, "");
          tipoBeneficio.DataFinal = tipoBeneficio.DataFinal.replace(/-/gi, "");
          tipoBeneficio.DataInicial = `${(tipoBeneficio.DataInicial).substring(6, 8)}/${(tipoBeneficio.DataInicial).substring(4, 6)}/${(tipoBeneficio.DataInicial).substring(0, 4)}`;
          tipoBeneficio.DataFinal = tipoBeneficio.DataFinal != '' ? `${(tipoBeneficio.DataFinal).substring(6, 8)}/${(tipoBeneficio.DataFinal).substring(4, 6)}/${(tipoBeneficio.DataFinal).substring(0, 4)}` : '';
          this.listarTipoBeneficiario();
          this.temErro = false;
          this.editar = false;
          this.loadingSpinner = false
        },
        error => {
          this.validaErro.retornoErro(error);
          this.loadingSpinner = false
        }
      )
    const codigo = this.formTpBeneficio.get('Codigo');
    codigo.enable(); //no editar este campo fica desabilitado
  }
  validaExibirFlags(beneficio: TipoBeneficiario) {
    if (beneficio.F01 != '0' || beneficio.F02 != '0' || beneficio.F03 != '0' || beneficio.F04 != '0' ||
      beneficio.F05 != '0' || beneficio.F06 != '0' || beneficio.F07 != '0' || beneficio.F08 != '0' ||
      beneficio.F09 != '0' || beneficio.F10) {
      this.formTpBeneficio.controls['especialCheckBox'].setValue(true);
      //this.isVisibleFlag = true;
    } else {
      this.formTpBeneficio.controls['especialCheckBox'].setValue(false);
      //this.isVisibleFlag = false;
    }
    this.especialCheckBox();
  }

  carregarCampos(beneficio: TipoBeneficiario) {
    const dataInicial = beneficio.DataInicial.substr(0, 10).split('/')
    const dataFinal = beneficio.DataFinal.substr(0, 10).split('/')
    //this.formTpBeneficio.controls['especialCheckBox'].setValue(true);
    this.editar = true;
    this.validaExibirFlags(beneficio);
    this.formTpBeneficio.controls['Codigo'].setValue(beneficio.Codigo);
    this.formTpBeneficio.controls['Descricao'].setValue(beneficio.Descricao);
    this.formTpBeneficio.controls['DataInicial'].setValue(`${dataInicial[2]}-${dataInicial[1]}-${dataInicial[0]}`);
    this.formTpBeneficio.controls['DataFinal'].setValue(beneficio.DataFinal == '' ? '' : `${dataFinal[2]}-${dataFinal[1]}-${dataFinal[0]}`);
    this.formTpBeneficio.controls['F01'].setValue(beneficio.F01);
    this.formTpBeneficio.controls['F02'].setValue(beneficio.F02);
    this.formTpBeneficio.controls['F03'].setValue(beneficio.F03);
    this.formTpBeneficio.controls['F04'].setValue(beneficio.F04);
    this.formTpBeneficio.controls['F05'].setValue(beneficio.F05);
    this.formTpBeneficio.controls['F06'].setValue(beneficio.F06);
    this.formTpBeneficio.controls['F07'].setValue(beneficio.F07);
    this.formTpBeneficio.controls['F08'].setValue(beneficio.F08);
    this.formTpBeneficio.controls['F09'].setValue(beneficio.F09);
    this.formTpBeneficio.controls['F10'].setValue(beneficio.F10);
    const codigo = this.formTpBeneficio.get('Codigo');
    codigo.disable();
  }
  deletarTipoBeneficio(codigo: any) {
    Swal({
      title: 'Atenção',
      text: 'Você tem certeza que deseja excluir?',
      icon: 'warning',
      closeOnClickOutside: true,
      closeOnEsc: true,
      buttons: ["Cancelar", "Confirmar"]
    },
    ).then((result) => {
      if (result) {
        this.tipoBeneficiarioService.deletarTipoBeneficio(codigo)
          .subscribe(
            data => {
              this.listarTipoBeneficiario();
              new AlertaModal("sucesso", "Sucesso", "O tipo de benefício foi excluído!", "p",);
            },
            error => {
              this.validaErro.retornoErro(error);
            }
          )
      }
    })


  }
  cancelarEditar(form) {
    form.reset();
    this.isVisibleFlag = false;
    this.editar = false;
    const codigo = this.formTpBeneficio.get('Codigo');
    codigo.enable();
  }

}
