import { Injectable } from '@angular/core';
import { Filial } from './interface/filial';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AcessoService } from '../../service/acesso/acesso.service';
import { ErroService} from '../../service/erros/erro.service';
import { Visualizacao } from './top-menu.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root'
})
export class TopMenuService {

  localStorage: LocalStorage = new LocalStorage();
  url_acesso = this.acesso.validarUrl();
  headers = new HttpHeaders();
  cabecalho : any

  constructor(private http: HttpClient, private acesso: AcessoService, private validaErro:ErroService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
    }
   }

  listarFilial() {
    return this.http.get(`${this.url_acesso}filiaisorganico/`,{headers:this.cabecalho});
  }
  AlterarSenha(body:any){
    return this.http.put(this.url_acesso +'logins', body, {headers:this.cabecalho});
  }
  buscarVisualizacao(sessao: any) {
    this.cabecalho = this.headers.append('sessao', sessao);
    return this.http.get(`${this.url_acesso}mensagem/visualizacao`,{headers:this.cabecalho});
  }
  salvarVisualizacao(visualizacao : Visualizacao){
    return this.http.post(`${this.url_acesso}mensagem/visualizacao`,visualizacao,{headers:this.cabecalho});
  }
  editarVisualizacao(visualizacao : Visualizacao, id: number){
    return this.http.put(`${this.url_acesso}mensagem/visualizacao/${id}`,visualizacao,{headers:this.cabecalho});
  }
  excluirVisualizacao( id: number){
    return this.http.delete(`${this.url_acesso}mensagem/visualizacao/${id}`,{headers:this.cabecalho});
  }
}
