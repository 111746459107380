import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InfoColaborador } from '../cadastro-complementar/cadastro-complementar.component';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';
import { DadosBeneficioService } from './dados-beneficio.service';
import { ErroService } from 'src/app/service/erros/erro.service';
import { Parametro } from 'src/app/service/interfaces/Parametro';
import { Data } from 'src/app/service/interfaces/Data';

@Component({
  selector: 'app-dados-beneficio',
  templateUrl: './dados-beneficio.component.html',
  styleUrls: ['./dados-beneficio.component.css'],
})
export class DadosBeneficioComponent implements OnInit {
  formCadastro: FormGroup;
  infoColaborador: InfoColaborador;

  listaMotivoCessacao: Parametro[] = [
    { Codigo: '01', Descricao: 'OBITO' },
    { Codigo: '02', Descricao: 'REVERSAO' },
    { Codigo: '03', Descricao: 'POR DECISAO JUDICIAL' },
    { Codigo: '04', Descricao: 'CASSACAO' },
    { Codigo: '05', Descricao: 'TERMINO DO PRAZO DO BENEFICIO' },
    { Codigo: '06', Descricao: 'EXTINCAO DE QUOTA' },
    { Codigo: '07', Descricao: 'NAO HOMOLOGADO PELO TRIBUNAL DE CONTAS' },
    { Codigo: '08', Descricao: 'RENUNCIA EXPRESSA' },
    { Codigo: '09', Descricao: 'TRANSFERENCIA DE ORGAO ADMINISTRADOR' },
    { Codigo: '10', Descricao: 'MUDANCA DE CPF DO BENEFICIARIO' },
    { Codigo: '11', Descricao: 'NAO RECADASTRAMENTO' },
  ];
  listaTipoAposentadoria: Parametro[] = [
    { Codigo: '1', Descricao: 'Por Tempo de Serviço Integral' },
    { Codigo: '2', Descricao: 'Por Tempo de Serviço Proporcional' },
    { Codigo: '3', Descricao: 'Por Idade Integral' },
    { Codigo: '4', Descricao: 'Por Idade Proporcinal' },
    { Codigo: '5', Descricao: 'Por Invalidez Integral' },
    { Codigo: '6', Descricao: 'Por Invalidez Proporcional' },
    { Codigo: '7', Descricao: 'Por Especial Professor Regente (sala de aula)' },
    { Codigo: '8', Descricao: 'Por Especial Atividade Insalubre' },
    { Codigo: '9', Descricao: 'Por Compulsoria' },
  ];
  listaTipoProp: Parametro[] = [
    { Codigo: '1', Descricao: 'AVOS' },
    { Codigo: '2', Descricao: 'PERCENTUAL' },
  ];
  listaTipo: Parametro[] = [
    { Codigo: '101', Descricao: 'Grupo 01' },
    { Codigo: '102', Descricao: 'Grupo 01' },
    { Codigo: '103', Descricao: 'Grupo 01' },
    { Codigo: '104', Descricao: 'Grupo 01' },
    { Codigo: '105', Descricao: 'Grupo 01' },
    { Codigo: '106', Descricao: 'Grupo 01' },
    { Codigo: '107', Descricao: 'Grupo 01' },
    { Codigo: '108', Descricao: 'Grupo 01' },
    { Codigo: '109', Descricao: 'Grupo 01' },
    { Codigo: '201', Descricao: 'Grupo 02' },
    { Codigo: '202', Descricao: 'Grupo 02' },
    { Codigo: '203', Descricao: 'Grupo 02' },
    { Codigo: '204', Descricao: 'Grupo 02' },
    { Codigo: '205', Descricao: 'Grupo 02' },
    { Codigo: '206', Descricao: 'Grupo 02' },
    { Codigo: '207', Descricao: 'Grupo 02' },
    { Codigo: '208', Descricao: 'Grupo 02' },
    { Codigo: '301', Descricao: 'Grupo 03' },
    { Codigo: '302', Descricao: 'Grupo 03' },
    { Codigo: '303', Descricao: 'Grupo 03' },
    { Codigo: '304', Descricao: 'Grupo 03' },
    { Codigo: '305', Descricao: 'Grupo 03' },
    { Codigo: '401', Descricao: 'Grupo 04' },
    { Codigo: '402', Descricao: 'Grupo 04' },
    { Codigo: '501', Descricao: 'Grupo 05' },
    { Codigo: '502', Descricao: 'Grupo 05' },
    { Codigo: '503', Descricao: 'Grupo 05' },
    { Codigo: '601', Descricao: 'Grupo 06' },
    { Codigo: '602', Descricao: 'Grupo 06' },
    { Codigo: '603', Descricao: 'Grupo 06' },
    { Codigo: '604', Descricao: 'Grupo 06' },
    { Codigo: '701', Descricao: 'Grupo 07' },
    { Codigo: '702', Descricao: 'Grupo 07' },
    { Codigo: '801', Descricao: 'Grupo 08' },
    { Codigo: '802', Descricao: 'Grupo 08' },
    { Codigo: '803', Descricao: 'Grupo 08' },
    { Codigo: '804', Descricao: 'Grupo 08' },
    { Codigo: '805', Descricao: 'Grupo 08' },
    { Codigo: '806', Descricao: 'Grupo 08' },
    { Codigo: '807', Descricao: 'Grupo 08' },
    { Codigo: '808', Descricao: 'Grupo 08' },
    { Codigo: '809', Descricao: 'Grupo 08' },
    { Codigo: '810', Descricao: 'Grupo 08' },
    { Codigo: '811', Descricao: 'Grupo 08' },
    { Codigo: '812', Descricao: 'Grupo 08' },
    { Codigo: '901', Descricao: 'Grupo 09' },
    { Codigo: '902', Descricao: 'Grupo 09' },
    { Codigo: '909', Descricao: 'Grupo 09' },
    { Codigo: '1001', Descricao: 'Grupo 10' },
    { Codigo: '1009', Descricao: 'Grupo 10' },
    { Codigo: '1101', Descricao: 'Grupo 11' },
    { Codigo: '1102', Descricao: 'Grupo 11' },
    { Codigo: '1103', Descricao: 'Grupo 11' },
    { Codigo: '1104', Descricao: 'Grupo 11' },
    { Codigo: '1105', Descricao: 'Grupo 11' },
    { Codigo: '1106', Descricao: 'Grupo 11' },
  ];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    public validaErro: ErroService,
    private dadosBeneficioService: DadosBeneficioService
  ) {}

  ngOnInit() {
    if (history.state.usuario) {
      this.infoColaborador = history.state.usuario;
      this.buscarBeneficioCompleto();
    } else {
      this.retornarTelaColaboradores();
    }
    this.formCadastro = this.fb.group({
      dtPublicacao: ['', Validators.required],
      tipo: ['', Validators.required],
      infoOrigem: ['', Validators.required],
      deterJudicial: ['', Validators.required],
      dtCessacao: ['', Validators.required],
      motivoCessacao: ['', Validators.required],
      dtReativacao: ['', Validators.required],
      dtFinanceiro: ['', Validators.required],
      dtLaudo: ['', Validators.required],
      dtInicial: ['', Validators.required],
      dtFinal: ['', Validators.required],
      tipoAposentadoria: ['', Validators.required],
      dtAposentadoria: ['', Validators.required],
      tipoProp: ['', Validators.required],
      proporcionalidade: ['', Validators.required],
    });
  }

  retornarTelaColaboradores() {
    this.router.navigate(['/colaboradores/']);
  }

  submitCadastro() {
    if (this.formCadastro.invalid) {
      new AlertaModal('alerta', 'Atenção!', 'Preencha os campos corretamente.', 'p');
      this.formCadastro.markAllAsTouched();
    } else {
      console.log('submit', this.formCadastro, this.montarBeneficio());
      this.spinner.show();
      this.dadosBeneficioService
        .atualizarBeneficio(this.montarBeneficio(), this.infoColaborador.matricula)
        .subscribe(
          (data) => {
            new AlertaModal('sucesso', 'Sucesso!', 'Cadastro realizado com sucesso.', 'p');
            this.spinner.hide();
            this.buscarBeneficioCompleto();
          },
          (error) => {
            this.validaErro.retornoErro(error);
            this.spinner.hide();
          }
        );
    }
  }

  buscarBeneficioCompleto() {
    this.spinner.show();
    this.dadosBeneficioService.buscarBeneficioCompleto(this.infoColaborador.matricula).subscribe(
      (data: DadosBeneficio) => {
        this.montarDadosForm(data[0]);
        this.spinner.hide();
      },
      (error) => {
        this.validaErro.retornoErro(error);
        this.spinner.hide();
      }
    );
  }

  montarDadosForm(dadosBeneficio: DadosBeneficio) {
    console.log('dados beneficio', dadosBeneficio);
    this.formCadastro.setValue({
      dtPublicacao:
        dadosBeneficio.DtPublicacaoBeneficio.ToDate != ''
          ? this.formatarDataRecebida(dadosBeneficio.DtPublicacaoBeneficio.ToDate)
          : '',
      tipo: dadosBeneficio.TpBeneficio != '0' ? dadosBeneficio.TpBeneficio : '',
      infoOrigem: dadosBeneficio.InformacoesOriginou,
      deterJudicial: dadosBeneficio.DeterminacaoJudicial ? '0' : '1',
      dtCessacao:
        dadosBeneficio.DataCessacaoBeneficio.ToDate != ''
          ? this.formatarDataRecebida(dadosBeneficio.DataCessacaoBeneficio.ToDate)
          : '',
      motivoCessacao:
        dadosBeneficio.MotivoCessacaoBeneficio == 0 ? '' : dadosBeneficio.MotivoCessacaoBeneficio,
      dtReativacao:
        dadosBeneficio.DataReativacao.ToDate != ''
          ? this.formatarDataRecebida(dadosBeneficio.DataReativacao.ToDate)
          : '',
      dtFinanceiro:
        dadosBeneficio.DataFinanceiro.ToDate != ''
          ? this.formatarDataRecebida(dadosBeneficio.DataFinanceiro.ToDate)
          : '',
      dtLaudo:
        dadosBeneficio.DtLaudoIsencao.ToDate != ''
          ? this.formatarDataRecebida(dadosBeneficio.DtLaudoIsencao.ToDate)
          : '',
      dtInicial:
        dadosBeneficio.DtiTrab.ToDate != ''
          ? this.formatarDataRecebida(dadosBeneficio.DtiTrab.ToDate)
          : '',
      dtFinal:
        dadosBeneficio.DtfTrab.ToDate != ''
          ? this.formatarDataRecebida(dadosBeneficio.DtfTrab.ToDate)
          : '',
      tipoAposentadoria: dadosBeneficio.TpApos == '0' ? '' : dadosBeneficio.TpApos,
      dtAposentadoria:
        dadosBeneficio.DtApos.ToDate != ''
          ? this.formatarDataRecebida(dadosBeneficio.DtApos.ToDate)
          : '',
      tipoProp: dadosBeneficio.TipoProp,
      proporcionalidade: dadosBeneficio.Proporcionalidade,
    });
  }

  montarBeneficio(): DadosBeneficioObjeto {
    let objetoBeneficio: DadosBeneficioObjeto = {
      DataPulicacao: new Data(this.formCadastro.get('dtPublicacao').value),
      InformacoesOriginou: this.formCadastro.get('infoOrigem').value,
      DeterminacaoJudicial: this.formCadastro.get('deterJudicial').value == '0' ? true : false,
      DataReativacao: new Data(this.formCadastro.get('dtReativacao').value),
      DataFinanceiro: new Data(this.formCadastro.get('dtFinanceiro').value),
      TipoBeneficio: { Codigo: this.formCadastro.get('tipo').value },
      DataCessacaoBeneficio: new Data(this.formCadastro.get('dtCessacao').value),
      MotivoCessacaoBeneficio: parseInt(this.formCadastro.get('motivoCessacao').value),
      DtLaudoIsencao: new Data(this.formCadastro.get('dtLaudo').value),
      DtiTrab: new Data(this.formCadastro.get('dtInicial').value),
      DtfTrab: new Data(this.formCadastro.get('dtFinal').value),
      TpApos: this.formCadastro.get('tipoAposentadoria').value,
      DtApos: new Data(this.formCadastro.get('dtAposentadoria').value),
      TipoProp: this.formCadastro.get('tipoProp').value,
      Proporcionalidade: parseInt(this.formCadastro.get('proporcionalidade').value),
      TpProporcionalidade: this.formCadastro.get('tipoProp').value,
    };
    return objetoBeneficio;
  }

  formatarDataRecebida(data: string): string {
    let dataFormatada;
    if (data == '') {
      dataFormatada = '0000-00-00';
    } else {
      const arrayData = data.split('/');
      dataFormatada = `${arrayData[2]}-${arrayData[1]}-${arrayData[0]}`;
    }
    return dataFormatada;
  }
}

export interface DadosBeneficioObjeto {
  DataPulicacao: Data;
  InformacoesOriginou: string;
  DeterminacaoJudicial: boolean;
  DataReativacao: Data;
  DataFinanceiro: Data;
  TipoBeneficio: any;
  DataCessacaoBeneficio: Data;
  MotivoCessacaoBeneficio: number;
  DtLaudoIsencao: Data;
  DtiTrab: Data;
  DtfTrab: Data;
  TpApos: string;
  DtApos: Data;
  TipoProp: string;
  Proporcionalidade: number;
  TpProporcionalidade: string;
}

interface DadosBeneficio {
  Sigla: string;
  Matricula: string;
  Empfil: string;
  Concurso: string;
  DtConcurso: Data;
  TpApos: string;
  DtApos: Data;
  TpRedist: string;
  DtRedist: Data;
  SerProf: string;
  PgINSS: string;
  PgIR: string;
  PgPensao: string;
  DtiTrab: Data;
  DtfTrab: Data;
  F01: string;
  F02: string;
  F03: string;
  F04: string;
  F05: string;
  F06: string;
  F07: string;
  F08: string;
  F09: string;
  F10: string;
  DtInv: Data;
  Proporcionalidade: string;
  DtFalecimento: Data;
  LocdTr: string;
  Processo: string;
  DtFinalInv: Data;
  Integralidade: string;
  CertidaoFalecimento: string;
  ResideEstrangeiro_PaisResidente: string;
  ResideEstrangeiro_DscLograd: string;
  ResideEstrangeiro_NrLograd: string;
  ResideEstrangeiro_Complemento: string;
  ResideEstrangeiro_Bairro: string;
  ResideEstrangeiro_NmCID: string;
  ResideEstrangeiro_CodPostal: string;
  DtPublicacaoBeneficio: Data;
  TpBeneficio: string;
  Endereco_TpLograd: string;
  CategProfis: string;
  QualifProfis: string;
  DtLaudoIsencao: Data;
  InformacoesOriginou: string;
  DeterminacaoJudicial: boolean;
  DataCessacaoBeneficio: Data;
  MotivoCessacaoBeneficio: number;
  TipoProp: string;
  DataReativacao: Data;
  DataFinanceiro: Data;
}
