import { AbstractControl } from '@angular/forms';

let data, dia, mes, ano, reg, valida, M30, v_mes, v_ano, rexpr, fev29;

export function dataValidator(control: AbstractControl): { [key: string]: boolean } | null {
    reg = /[^\d\/\.]/gi;
    if(control.value == '' || control.value == undefined )
    {
        return null
    }
    else
    {
        data = control.value.split('/').join('');
        valida = data.replace(reg,'');
        if (valida && valida.length == 8) 
        {
            ano = data.substr(4);
            mes = data.substr(2, 2);
            dia = data.substr(0,2);
            M30 = ['04','06','09','11'];
            v_mes = /(0[1-9])|(1[0-2])/.test(mes);
            v_ano = /(19[1-9]\d)|(20\d\d)|2100/.test(ano);
            rexpr = new RegExp(mes);
            fev29 = ano % 4? 28: 29;
            if (v_mes && v_ano) 
            {
                if (mes == '02') 
                {
                    //fevereiro
                    return (dia >= 1 && dia <= fev29) == true ? null : {'data': true};
                }
                else if (rexpr.test(M30.toString()))
                {
                    return (/((0[1-9])|([1-2]\d)|30)/.test(dia)) == true ? null : {'data': true};
                }
                else 
                {
                    return (/((0[1-9])|([1-2]\d)|3[0-1])/.test(dia)) == true ? null : {'data': true};
                }
            }
            return {'data': true};
        }
        return {'data': true};
    }
  }