import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  validaLogin: boolean;
  constructor(public auth: AuthService) { }
  loading = true;

  ngAfterContentChecked() {
    this.validaLogin = this.auth.validaLogin();
  }
  ngOnInit() {
    this.validaLogin = this.auth.validaLogin();
  }

}
