import { Component } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ExcelService } from "src/app/service/arquivo/excel.service";
import { LocalStorage } from "src/app/service/classes/localstorage/LocalStorage";
import { Crypto } from "src/app/service/classes/crypto/crypto";
import Swal from "sweetalert";
import { Router } from "@angular/router";
@Component({
  selector: "app-listagem-censo",
  templateUrl: "./listagem-censo.component.html",
  styleUrls: ["./listagem-censo.component.css"],
})
export class ListagemCensoComponent {
  localStorage: LocalStorage;
  searchText: string = "";
  listaCensos: any[] = [
    {
      tipo: "Anual",
      dataInicio: "01/11/2023",
      dataFim: "12/12/2023",
      avanco: "30",
      colaboradores: 500,
      informacao: ["Informações", "Documentos"],
      filial: [
        {
          Codigo: 100001,
          Nome: "COMPANHIA DE SANEAMENTO DE ALAGOAS",
        },
        {
          Codigo: 100001,
          Nome: "COMPANHIA DE SANEAMENTO DE ALAGOAS",
        },
        {
          Codigo: 100001,
          Nome: "COMPANHIA DE SANEAMENTO DE ALAGOAS",
        },
      ],
      criado: "Ricardo Vanceslau",
    },
    {
      tipo: "Aniversário",
      dataInicio: "25/01/2024",
      dataFim: "10/11/2025",
      avanco: "100",
      colaboradores: 240,
      informacao: ["Informações", "Especialização", "Documentos"],
      filial: [
        {
          Codigo: 100001,
          Nome: "COMPANHIA DE SANEAMENTO DE ALAGOAS",
        },
        {
          Codigo: 100001,
          Nome: "COMPANHIA DE SANEAMENTO DE ALAGOAS",
        },
        {
          Codigo: 100001,
          Nome: "COMPANHIA DE SANEAMENTO DE ALAGOAS",
        },
        {
          Codigo: 100001,
          Nome: "COMPANHIA DE SANEAMENTO DE ALAGOAS",
        },
        {
          Codigo: 100001,
          Nome: "COMPANHIA DE SANEAMENTO DE ALAGOAS",
        },
      ],
      criado: "Ricardo Vanceslau",
    },
    {
      tipo: "Anual",
      dataInicio: "25/01/2024",
      dataFim: "10/11/2025",
      avanco: "100",
      colaboradores: 240,
      informacao: ["Informações", "Especialização", "Documentos"],
      filial: [
        {
          Codigo: 100001,
          Nome: "COMPANHIA DE SANEAMENTO DE ALAGOAS",
        },
        {
          Codigo: 100001,
          Nome: "COMPANHIA DE SANEAMENTO DE ALAGOAS",
        },
        {
          Codigo: 100001,
          Nome: "COMPANHIA DE SANEAMENTO DE ALAGOAS",
        },
        {
          Codigo: 100001,
          Nome: "COMPANHIA DE SANEAMENTO DE ALAGOAS",
        },
        {
          Codigo: 100001,
          Nome: "COMPANHIA DE SANEAMENTO DE ALAGOAS",
        },
      ],
      criado: "Ricardo Vanceslau",
    },
  ];
  listaFiltrada: any[];
  censoSelecionado: any;
  marcarTodos: boolean;
  ordemTipo: number = 0;
  ordemAvanco: number = 0;
  ordemColab: number = 0;
  ordemDataInicio: number = 0;
  ordemDataFim: number = 0;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private excelService: ExcelService
  ) {}

  ngOnInit() {
    this.selecionarTodos();
    this.localStorage = this.decryptLocalStorage();
    this.listaFiltrada = this.listaCensos;
  }

  retornarTamanhoListagem(): number {
    return this.listaFiltrada.length;
  }

  selecionarTodos(): void {
    let elements = document.querySelectorAll(
      "#filtro-form input[type=checkbox]"
    );

    elements.forEach((element: HTMLInputElement) => {
      if (!element.checked) {
        element.click();
      }
    });
  }

  desmarcarTodos(): void {
    let elements = document.querySelectorAll(
      "#filtro-form input[type=checkbox]"
    );

    elements.forEach((element: HTMLInputElement) => {
      if (element.checked) {
        element.click();
      }
    });
  }

  habilitarFiltros(): void {
    this.spinner.show();
    let checkboxes = document.querySelectorAll(
      "#filtro-form input[type=checkbox]"
    );
    let filtrosSelecionados = {
      tipo: [],
      avanco: [],
    };
    checkboxes.forEach((element: HTMLInputElement) => {
      if (element.value === "anual" && element.checked) {
        filtrosSelecionados.tipo.push("Anual");
      }
      if (element.value === "aniversario" && element.checked) {
        filtrosSelecionados.tipo.push("Aniversário");
      }
      if (element.value === "concluida" && element.checked) {
        filtrosSelecionados.avanco.push("concluida");
      }
      if (element.value === "andamento" && element.checked) {
        filtrosSelecionados.avanco.push("andamento");
      }
    });

    if (
      filtrosSelecionados.tipo.length > 0 ||
      filtrosSelecionados.avanco.length > 0
    ) {
      this.listaFiltrada = this.listaCensos.filter((element) => {
        const tipo =
          filtrosSelecionados.tipo.length === 0 ||
          filtrosSelecionados.tipo.includes(element.tipo);
        const avanco =
          filtrosSelecionados.avanco.length === 0 ||
          (filtrosSelecionados.avanco.includes("concluida") &&
            element.avanco === "100") ||
          (filtrosSelecionados.avanco.includes("andamento") &&
            element.avanco !== "100");

        return tipo && avanco;
      });
    } else {
      this.listaFiltrada = this.listaCensos;
    }

    this.spinner.hide();
  }

  exportAsXLSX(): void {
    this.spinner.show();
    let censosData: any[] = [];

    this.listaCensos.forEach((item) => {
      let arrayFilial = [];
      item.filial.forEach((element) => {
        arrayFilial.push(element.Nome);
      });

      censosData.push({
        TIPO: item.tipo,
        "DATA INÍCIO": item.dataInicio,
        "DATA FIM": item.dataFim,
        AVANÇO: item.avanco,
        COLABORADORES: item.colaboradores,
        INFORMAÇÃO: item.informacao.toString(),
        FILIAL: arrayFilial.toString(),
        "Criado por": item.criado,
      });
    });
    this.excelService.exportAsExcelFile(censosData, "Relatório dos Censo");
    this.spinner.hide();
  }

  exportAsPDF(): void {
    window.onbeforeprint = () => {
      document.title = "Listagem de censo";
    };
    window.print();
    window.onafterprint = () => {
      document.title = "Elógica";
    };
  }

  ordenarPorTipo(): void {
    if (this.ordemTipo == 0 || this.ordemTipo == 2) {
      this.listaFiltrada = this.listaFiltrada.sort((a, b) =>
        a.tipo.localeCompare(b.tipo)
      );
      this.ordemTipo = 1;
    } else {
      this.listaFiltrada = this.listaFiltrada.sort((a, b) =>
        b.tipo.localeCompare(a.tipo)
      );
      this.ordemTipo = 2;
    }
    this.resetarOrdem("ordemTipo");
  }

  ordemPorAvanco(): void {
    if (this.ordemAvanco == 0 || this.ordemAvanco == 2) {
      this.listaFiltrada = this.listaFiltrada.sort((a, b) =>
        a.avanco.localeCompare(b.avanco)
      );
      this.ordemAvanco = 1;
    } else {
      this.listaFiltrada = this.listaFiltrada.sort((a, b) =>
        b.avanco.localeCompare(a.avanco)
      );
      this.ordemAvanco = 2;
    }
    this.resetarOrdem("ordemAvanco");
  }

  ordemPorColab(): void {
    if (this.ordemColab == 0 || this.ordemColab == 2) {
      this.listaFiltrada = this.listaFiltrada.sort(
        (a, b) => a.colaboradores - b.colaboradores
      );
      this.ordemColab = 1;
    } else {
      this.listaFiltrada = this.listaFiltrada.sort(
        (a, b) => b.colaboradores - a.colaboradores
      );
      this.ordemColab = 2;
    }
    this.resetarOrdem("ordemColab");
  }

  converterDataMMDDYYYY(data: string): string {
    const dataConvertida = data.split("/");
    if (dataConvertida.length === 3) {
      const [dia, mes, ano] = dataConvertida;
      return `${mes}/${dia}/${ano}`;
    }
  }

  ordemPorDataInicio(): void {
    if (this.ordemDataInicio == 0 || this.ordemDataInicio == 2) {
      this.listaFiltrada = this.listaFiltrada.sort((a, b) => {
        // o new Date só aceita datas no formato MM DD YYYY
        const dataConvertidaA = this.converterDataMMDDYYYY(a.dataInicio);
        const dataConvertidaB = this.converterDataMMDDYYYY(b.dataInicio);
        let dateA = new Date(dataConvertidaA).getTime();
        let dateB = new Date(dataConvertidaB).getTime();
        return dateA - dateB;
      });
      this.ordemDataInicio = 1;
    } else {
      this.listaFiltrada = this.listaFiltrada.sort((a, b) => {
        const dataConvertidaA = this.converterDataMMDDYYYY(a.dataInicio);
        const dataConvertidaB = this.converterDataMMDDYYYY(b.dataInicio);
        let dateA = new Date(dataConvertidaA).getTime();
        let dateB = new Date(dataConvertidaB).getTime();
        console.log(dateA);
        console.log(dateB);
        return dateB - dateA;
      });
      this.ordemDataInicio = 2;
    }
    this.resetarOrdem("ordemDataInicio");
  }

  ordemPorDataFim(): void {
    if (this.ordemDataFim == 0 || this.ordemDataFim == 2) {
      this.listaFiltrada = this.listaFiltrada.sort((a, b) => {
        // o new Date só aceita datas no formato MM DD YYYY
        const dataConvertidaA = this.converterDataMMDDYYYY(a.dataInicio);
        const dataConvertidaB = this.converterDataMMDDYYYY(b.dataInicio);
        let dateA = new Date(dataConvertidaA).getTime();
        let dateB = new Date(dataConvertidaB).getTime();
        return dateA - dateB;
      });
      this.ordemDataFim = 1;
    } else {
      this.listaFiltrada = this.listaFiltrada.sort((a, b) => {
        const dataConvertidaA = this.converterDataMMDDYYYY(a.dataInicio);
        const dataConvertidaB = this.converterDataMMDDYYYY(b.dataInicio);
        let dateA = new Date(dataConvertidaA).getTime();
        let dateB = new Date(dataConvertidaB).getTime();
        console.log(dateA);
        console.log(dateB);
        return dateB - dateA;
      });
      this.ordemDataFim = 2;
    }
    this.resetarOrdem("ordemDataFim");
  }

  resetarOrdem(ordem: string): void {
    if (ordem !== "ordemTipo") {
      this.ordemTipo = 0;
    }
    if (ordem !== "ordemAvanco") {
      this.ordemAvanco = 0;
    }
    if (ordem !== "ordemColab") {
      this.ordemColab = 0;
    }
    if (ordem !== "ordemDataInicio") {
      this.ordemDataInicio = 0;
    }
    if (ordem !== "ordemDataFim") {
      this.ordemDataFim = 0;
    }
  }

  selecionarItem(item): void {
    this.censoSelecionado = item;
  }

  irParaPendentes() {
    this.router.navigate(["censo/pendentes"]);
  }

  excluirCenso() {
    Swal({
      title: "Confirmação de Exclusão",
      text: "Tem certeza de que deseja confirmar a exclusão? Esta ação não poderá ser desfeita.",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        Swal({
          title: "Censo Excluído",
          icon: "success",
        });
      }
    });
  }

  formatarDataInput(data: string): string {
    if (data) {
      const dataArray = data.split("/");
      const dataFormatada = dataArray.reverse().toString().replace(/,/g, "-");
      return dataFormatada;
    }
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(
        new Crypto().decryptUsingAES256(localStorage.getItem("util"))
      );
    }
  }
}
