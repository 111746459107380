export class HorarioTrabalho{
    Sigla:string;
    Codigo:number;
    HoraEntrada:string;
    HoraSaida:string;
    DuracaoJornada:string;
    PeriodoHoraFlexivel:string;
    InicioIntervalo:string;
    TerminoIntervalo:string;
    DuracaoIntervalo:string;
    TipoIntervalo:string;
    DiasTrabalhados:string;
    InicioValidade:string;
    FinalValidade:string;
    CargaHorariaSemana: string;
    TipoJornada: string;
    DiaVariavel:string;
    TempoParcial: string;
}