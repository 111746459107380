import {Data} from '../../../interfaces/Data';

export default class Cnh {
  Numero: string;
  CategoriaCnh: string;
  UF: string;
  DataValidadeCnh: Data = new Data();

  constructor() {}
}
