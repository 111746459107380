<!-- <p>regras-beneficio works!</p> -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<div class="card">

  <div class="card-header">
    <h3 class="card-title" id="target">Regras do Benefício</h3>
  </div>
  <div class="card-body">
    <div>
      <form id="filtro-listagem-regra" name="filtro-listagem-regra">
        <div class="form-row mt-1 mb-0 ">
          <div class="col-md-4 col-lg-2 form-group">
            <label>Tipo do Benefício</label>
            <select [(ngModel)]="filtro.Codigo" id="filtro-codigo-beneficio" name="filtro-codigo-beneficio" class="form-control">
              <option value="">Selecione...</option>
              <option *ngFor="let beneficio of listaBeneficios" value="{{beneficio.Codigo}}">{{beneficio.Descricao}}</option>

            </select>
          </div>
          <div class="col-md-4 col-lg-2 form-group">
            <label>Vencimento</label>
            <select id="filtro-vencimento-beneficiario" [(ngModel)]="filtro.Vencimento" name="filtro-vencimento-beneficiario" class="form-control">
              <option value="">Todos</option>
              <option value="A">À vencer(em até 60 dias)</option>
              <option value="V">Vencidos</option>
            </select>
          </div>
          <div class="col-md-2 col-lg-2 form-group">
            <label>&nbsp;</label>
            <button type="button" (click)="listarCadastro(filtro)" class="btn btn-info btn-block">Listar</button>
          </div>
        </div>

      </form>
    </div>

    <div>

      <div class="form-group row">
        <div class="input-group col-lg-12">
          <input placeholder="Pesquisar Colaborador" [(ngModel)]="filtro.Nome" type="search" id="filtro-nome-beneficiario" name="filtro-nome-beneficiario" class="form-control" autocomplete="off" />
          <span class="input-group-append">
            <span class="input-group-text"><i class="fa fa-search"></i></span>
          </span>
        </div>
      </div>
      <div class="table-responsive" style="max-height:45vh">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Colaborador</th>
              <th>Benefício(s)</th>
              <th class="text-center">Editar</th>
            </tr>
          </thead>

          <tbody *ngIf="cadastrosBeneficiario && cadastrosBeneficiario.length > 0">
            <tr *ngFor="let cadastro of cadastrosBeneficiario | filtro: [filtro.Nome, filtro.Codigo, filtro.EmpresaFilial, filtro.Vencimento]">
              <td>
                <div *ngIf="cadastro.Tipo == 'D'"><i class="fa fa-bullseye" aria-hidden="true"></i>{{cadastro.Nome}}</div>
                <div *ngIf="cadastro.Tipo == 'D'"><i class="fa fa-user-o" aria-hidden="true"></i> Dependente</div>
                <div *ngIf="cadastro.Tipo == 'D'"><i class="fa fa-id-card-o" aria-hidden="true"></i>{{cadastro.Cpf}} </div>
                <div *ngIf="cadastro.Tipo == 'D'"><i class="fa fa-calendar-minus-o" aria-hidden="true"></i> {{cadastro.DataNascimento }} ({{cadastro.Anos}} anos)</div>

                <div *ngIf="cadastro.Tipo != 'D'"><i class="fa fa-bullseye" aria-hidden="true"></i> {{cadastro.Nome}} </div>
                <div *ngIf="cadastro.Tipo != 'D'"><i class="fa fa-user-circle" aria-hidden="true"></i> Colaborador</div>
                <div *ngIf="cadastro.Tipo != 'D'"><i class="fa fa-id-card-o" aria-hidden="true"></i> {{cadastro.Cpf}} </div>
                <div *ngIf="cadastro.Tipo != 'D'"><i class="fa fa-calendar-minus-o" aria-hidden="true"></i> {{cadastro.DataNascimento }} ({{cadastro.Anos}} anos) </div>
              </td>
              <td>
                <div *ngFor="let beneficio of cadastro.Beneficio">
                  <div *ngIf="beneficio.Codigo > 0"><strong>{{beneficio.Descricao}}</strong> - {{beneficio.DataInicial.substr(0, 10)}} ~ {{beneficio.DataFinal.substr(0, 10)}}</div>
                </div>
                <div *ngIf="cadastro.Beneficio.length < 1">Sem Benefício</div>
              </td>
              <td class="text-center">
                <button class="btn bg-teal-400" (click)="goToEditar(cadastro)">
                  <i class="fa fa-pencil"></i>
                </button>
              </td>

            </tr>
          </tbody>
        </table>

      </div>
      <div class="row  d-flex justify-content-center align-items-center mt-3" *ngIf="!cadastrosBeneficiario">
        <i class="fa fa-refresh fa-spin fa-2x "></i>
        <h4 class="ml-2">Aguarde...</h4>
      </div>
      <div *ngIf="cadastrosBeneficiario && cadastrosBeneficiario.length == 0">
        <div class="d-flex justify-content-center align-items-center mt-3">
          <h4>Não há dados para ser exibido</h4>
        </div>
      </div>
    </div>

    <div>
      <div class="row">
        <div class="col-md-12">
        </div>
      </div>
    </div>
  </div>
</div>
