import { Pipe, PipeTransform } from '@angular/core';
//import { lista } from './classes/lista';

function removerAcentos( newStringComAcento ) {
  var string = newStringComAcento;
  var mapaAcentosHex 	= {
      a : /[\xE0-\xE6]/g,
      A : /[\xC0-\xC6]/g,
      e : /[\xE8-\xEB]/g,
      E : /[\xC8-\xCB]/g,
      i : /[\xEC-\xEF]/g,
      I : /[\xCC-\xCF]/g,
      o : /[\xF2-\xF6]/g,
      O : /[\xD2-\xD6]/g,
      u : /[\xF9-\xFC]/g,
      U : /[\xD9-\xDC]/g,
      c : /\xE7/g,
      C : /\xC7/g,
      n : /\xF1/g,
      N : /\xD1/g
  };

  for ( var letra in mapaAcentosHex ) {
      var expressaoRegular = mapaAcentosHex[letra];
      string = string.replace( expressaoRegular, letra );
  }

  return string;
}

@Pipe({
  name: 'filtroText'
})
export class FiltroTextPipe implements PipeTransform {
  tam = 0;
  transform(lista:any[], texto: string, campo: string): any {
    console.log(campo)
    texto = texto.toLowerCase();

    if(texto != ""){
      lista = lista.filter(item => removerAcentos(item[campo]).toLowerCase().search(texto) > -1)
              .sort((a,b) => {
                if (a[campo] > b[campo]) return 1;
                else if (a[campo] < b[campo]) return -1;
                else return 0;
              });
      return lista;
    }
    else return  lista;
  }

}


