import { Pipe, PipeTransform } from '@angular/core';
//import { Colaborador } from './classes/Colaborador';
import { alterarTamanhoColaboradores } from './colaboradores.component';

function compare(n1, n2) {
  if (n1.nome > n2.nome) {
    return 1;
  }

  if (n1.nome < n2.nome) {
    return -1;
  }

  return 0;
}

function removerAcentos(newStringComAcento) {
  var string = newStringComAcento;
  var mapaAcentosHex = {
    a: /[\xE0-\xE6]/g,
    A: /[\xC0-\xC6]/g,
    e: /[\xE8-\xEB]/g,
    E: /[\xC8-\xCB]/g,
    i: /[\xEC-\xEF]/g,
    I: /[\xCC-\xCF]/g,
    o: /[\xF2-\xF6]/g,
    O: /[\xD2-\xD6]/g,
    u: /[\xF9-\xFC]/g,
    U: /[\xD9-\xDC]/g,
    c: /\xE7/g,
    C: /\xC7/g,
    n: /\xF1/g,
    N: /\xD1/g,
  };

  for (var letra in mapaAcentosHex) {
    var expressaoRegular = mapaAcentosHex[letra];
    string = string.replace(expressaoRegular, letra);
  }

  return string;
}

function removerCaracteresEspeciais(stringComCaracteresEspeciais) {
  var string = stringComCaracteresEspeciais.replace(/[-.]/g, '');

  return string;
}

@Pipe({
  name: 'colaboradoresFiltro',
})
export class ColaboradoresFiltroPipe implements PipeTransform {
  tam = 0;
  transform(colaborador: any[], texto: string): any {
    texto = texto
      .toLowerCase()
      .normalize('NFD')
      .replace(/[-.]/g, '')
      .replace(/[\u0300-\u036f]/g, '');

    if (texto != '') {
      colaborador = colaborador
        .filter(
          (item) =>
            removerAcentos(item.NomeCompleto).toLowerCase().startsWith(texto) ||
            item.Matricula.startsWith(texto) ||
            removerCaracteresEspeciais(item.Cpf).startsWith(texto)
        )
        .sort(compare);

      this.tam = colaborador.length;
      alterarTamanhoColaboradores(this.tam);
      return colaborador;
    } else {
      if (colaborador != undefined) {
        colaborador = colaborador.sort(compare);
        this.tam = colaborador.length;
        alterarTamanhoColaboradores(this.tam);
      }
      return colaborador;
    }
  }
}
