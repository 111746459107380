import { Data } from './../../../service/interfaces/Data';

export class Dependente {
  Sigla: string = '';
  EmpresaFilial: number;
  Matricula: number;
  Numero: number;
  Tipo: string = '';
  Filiacao: number;
  Nome: string;
  DataNascimento = new Data();
  InstrucaoTipo: number = 0;
  InstrucaoSituacao: number = 0;
  Nacionalidade: number = 0;
  Ufnatural: string = '';
  Sexo: number = 0;
  IdentidadeNumero: string = '';
  IdentidadeSer: string = '';
  IdentidadeUf: string = '';
  CpfAntigo: string = '';
  Cpf: string = '';
  NumeroDocumento: string = '';
  Dtent = new Data();
  DependenteIr: number = 0;
  DependenteSf: number = 0;
  Snumero: number = 0;
  Stipo: number = 0;
  SdependenteIr: number = 0;
  SdependenteSf: number = 0;
  LocalNascimento: string = '';
  MotivoDependente: string = '';
  MotivoInicioDependencia: string = '';
  MotivoFimDependencia: string = '';
  TipoLogradouro: string = '';
  DescricaoLogradouro: string = '';
  NumeroLogradouro: string = '';
  Complemento: string = '';
  Bairro: string = '';
  CodigoMunicipio: string = '';
  Cep: string = '';
  Uf: string = '';
  Telefone: string = '';
  Email: string = '';
  UploadDocumentos: any[] = [];
  TipoDependente: {
    descricao: string;
    valor: number;
  };

  constructor() {}
}
