import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'cpf'
})
export class CpfPipe implements PipeTransform {
  transform(cpf:string): any {
      cpf = cpf.padStart(11,'0');
      cpf = `${cpf.substr(0,3)}.${cpf.substr(3,3)}.${cpf.substr(6,3)}-${cpf.substr(9,2)}`;
      return cpf
  }
}


