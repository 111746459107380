<!--Tela inicial de pesquisa-->

<!-- SPINNER -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<!-- BUSCAR COLABORADOR -->
<div [ngStyle]="{'display': !telaHistoricoTransferencia ? 'flex' : 'none'}" class="content justify-content-center align-items-center">
  <form class="login-form" [formGroup]="formBuscarColaborador" #telainicial="ngForm">
    <div class="card  bg-dark mb-0 text-ligth">
      <div class="card-body">
        <div class="text-center mb-3">
          <h5 class="mb-0">Buscar Colaborador</h5>
        </div>
        <div class="s-12 col-sm-12 col-md-12 col-lg-12 form-group">
          <label class="justify-content-start">Matrícula</label>
          <div class="input-group col-md-12">
            <input class="form-control" mask="0*" formControlName="matricula" [maxLength]="9" placeholder="Matrícula"/>
            <div class="input-group-append">
              <button class="btn bg-primary border-primary" (click)="buscarColaborador()">
                <i class="fa fa-search"></i>
              </button>
            </div>

            <!-- NOME COLABORADOR E ÍCONE -->
            <div *ngIf="dadosColaborador.Matricula" class="col-md-12 justify-content-center w-80 rounded-top rounded-bottom pt-2" [ngStyle]="{'background-color': '#728098'}" >
              <div class="row ">
                <div class="col-4"></div>
                <div class="col-6"><img style="width: 5em"
                  src="{{dadosColaborador.Pessoal.DadosBasicos.Sexo.Valor ? 'assets/img/avatar-masculino.svg' : 'assets/img/avatar-feminino.svg'}}" width="30" height="40" alt="">
                </div>
                <div class="col-4"></div>
              </div>
              <div class="row pt-2">
                <div class="col-1"></div>
                <div class="col-10 text-center"><h4 class="h3  pb-2" style="font-size: 15px;">{{dadosColaborador.InformacoesBasicas.NomeCompleto}}</h4></div>
              </div>
            </div>
          </div>
          <span class="form-text text-danger" *ngIf="temErro && formBuscarColaborador.controls['matricula'].hasError('required')">
            Campo obrigatório
          </span>
        </div>

        <div class=" text-center" *ngIf="exibirContinuar">
          <button class="btn btn-primary" (click)="avancarFormHistorico()" >Continuar</button>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="card" [ngStyle]="{'display': telaHistoricoTransferencia ? '' : 'none'}">
  <div class="card-header">
    <h3 class="card-title"> <button class="btn" (click)="voltarTelaBuscarColaborador()"><i class="fa fa-arrow-left" aria-hidden="true" style="color: black;"></i></button>Histórico de Transferências</h3>
  </div>

  <form [formGroup]="formHistoricoTransferencia">
    
    <!--INFORMAÇÕES DA ORIGEM-->
    <div class="card-body">
      <h4>Dados da Origem</h4>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <!-- MATRICULA ORIGEM -->
                <div class="form-group col-md-3">
                  <label class="font-weight-semibold">Matrícula</label>
                  <input class="form-control" formControlName="matriculaOrigem" mask="0*" [maxLength]="9" placeholder="Matrícula" [disabled]="true" readonly />
                  <span class="form-text text-danger" *ngIf="temErro && formHistoricoTransferencia.controls['matriculaOrigem'].hasError('required')">Campo obrigatório</span>
                </div>

                <!-- EMPRESA FILIAL ORIGEM -->
                <div class="col-md-9 form-group">
                  <label class="font-weight-semibold">Empresa Filial</label>
                  <select class="form-control" formControlName="empresaFilialOrigem" placeholder="Empresa Filial" [disabled]="true" readonly >
                    <option value="">Selecione...</option>
                    <option *ngFor="let filial of filiais" value="{{filial.Codigo}}">{{filial.Nome}}</option>
                  </select>
                  <span class="form-text text-danger" *ngIf="temErro && formHistoricoTransferencia.controls['empresaFilialOrigem'].hasError('required')">Campo obrigatório</span>
                </div>
              </div>
            </div>
          </div>
    </div>

    <!--INFORMAÇÕES DO DESTINO-->
    <div class="card-body">

      <h4>Dados do Destino</h4>

          <div class="row">
            <div class="col-md-12">
              <div class="row">

                <!--MATRÍCULA-->
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Matrícula</label>
                  <input class="form-control" mask="0*" formControlName="matriculaDestino" [maxLength]="9" placeholder="Matrícula" 
                  [ngStyle]="(temErro && formHistoricoTransferencia.controls['matriculaDestino'].hasError('required')) ? {'border-color':'red'} : null" />
                  <span class="form-text text-danger" *ngIf="temErro && formHistoricoTransferencia.controls['matriculaDestino'].hasError('required') ? {'border-color':'red'} : null" >Campo obrigatório</span>
                </div>

                <!--DATA DA TRANSEFERÊNCIA-->
                <div class="col-md-3 form-group">
                  <label class="font-weight-semibold">Data</label>
                  <!--<input class="form-control" formControlName="dataTransferencia" placeholder="Data" />-->
                  <div class="input-group">
                    <span class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-calendar5"></i></span>
                    </span>
                    <input type="text" class="form-control pickadate" id="dataTransferencia" formControlName="dataTransferencia" placeholder="Data transferência"
                    [ngStyle]="temErro && formHistoricoTransferencia.controls['dataTransferencia'].hasError('required') || formHistoricoTransferencia.controls['dataTransferencia'].dirty && !formHistoricoTransferencia.controls['dataTransferencia'].valid ? {'border-color':'red'} : null" />
                  </div>
                  <span class="form-text text-danger" *ngIf="temErro && formHistoricoTransferencia.controls['dataTransferencia'].hasError('required') || formHistoricoTransferencia.controls['dataTransferencia'].dirty && !formHistoricoTransferencia.controls['dataTransferencia'].valid ? {'border-color':'red'} : null">Campo obrigatório</span>
                </div>
              </div>
            </div>
            
            <!-- EMPRESA FILIAL DESTINO -->
            <div class="col-md-12 form-group">
              <label class="font-weight-semibold">Empresa Filial</label>
              <!-- <input class="form-control" formControlName="empresaFilialDestino" placeholder="Empresa Filial" /> -->
              <select class="form-control" formControlName="empresaFilialDestino" placeholder="Empresa Filial"
              [ngStyle]="temErro && formHistoricoTransferencia.controls['empresaFilialDestino'].hasError('required') || formHistoricoTransferencia.controls['empresaFilialDestino'].dirty && !formHistoricoTransferencia.controls['empresaFilialDestino'].valid ? {'border-color':'red'} : null" >
                <option value="">Selecione...</option>
                <option *ngFor="let filial of filiais" value="{{filial.Codigo}}">{{filial.Nome}}</option>
              </select>
              <span class="form-text text-danger" *ngIf="temErro && formHistoricoTransferencia.controls['empresaFilialDestino'].hasError('required') || formHistoricoTransferencia.controls['empresaFilialDestino'].dirty && !formHistoricoTransferencia.controls['empresaFilialDestino'].valid ? {'border-color':'red'} : null" >Campo obrigatório</span>
            </div>

            <!--LOTAÇÃO-->
            <div class="col-md-6">
              <div class="form-group">
                <label class="font-weight-semibold">Lotação</label>
                <select class="form-control" formControlName="lotacaoDestino" placeholder="Lotação"
                [ngStyle]="temErro && formHistoricoTransferencia.controls['lotacaoDestino'].hasError('required') || formHistoricoTransferencia.controls['lotacaoDestino'].dirty && !formHistoricoTransferencia.controls['lotacaoDestino'].valid ? {'border-color':'red'} : null" >
                  <option value="">Selecione...</option>
                  <option *ngFor="let lotacao of lotacoes" value="{{lotacao.CodLot}}">{{lotacao.Nome}}</option>
                </select>
                <span class="form-text text-danger" *ngIf="temErro && formHistoricoTransferencia.controls['lotacaoDestino'].hasError('required') || formHistoricoTransferencia.controls['lotacaoDestino'].dirty && !formHistoricoTransferencia.controls['lotacaoDestino'].valid ? {'border-color':'red'} : null" >Campo obrigatório</span>
              </div>
            </div>

            <!--CENTRO DE CUSTO-->
            <div class="col-md-6">
              <div class="form-group">
                <label class="font-weight-semibold">Centro de Custo</label>
                <select class="form-control" formControlName="centroCustoDestino" placeholder="Centro de Custo"
                [ngStyle]="temErro && formHistoricoTransferencia.controls['centroCustoDestino'].hasError('required') || formHistoricoTransferencia.controls['centroCustoDestino'].dirty && !formHistoricoTransferencia.controls['centroCustoDestino'].valid ? {'border-color':'red'} : null" >
                  <option value="">Selecione...</option>
                  <option *ngFor="let centro of centrosCustos" value="{{centro.Codigo}}">{{centro.Descricao}}</option>
                </select>
                <span class="form-text text-danger" *ngIf="temErro && formHistoricoTransferencia.controls['centroCustoDestino'].hasError('required') || formHistoricoTransferencia.controls['centroCustoDestino'].dirty && !formHistoricoTransferencia.controls['centroCustoDestino'].valid ? {'border-color':'red'} : null" >Campo obrigatório</span>
              </div>
            </div>

            <!--CARGO DE DESTINO-->
            <div class="col-md-6" *ngIf="empresaPublica">
              <div class="form-group">
                <label class="font-weight-semibold">Cargo de Destino</label>
                <select class="form-control" formControlName="cargoDestino" placeholder="Cargo de Destino" (change)="verificarCargoFuncaoVazios()" 
                [ngStyle]="temErro && cargoFuncaoVazios || formHistoricoTransferencia.controls['cargoDestino'].dirty && cargoFuncaoVazios ? {'border-color':'red'} : null" >
                  <option value="">Selecione...</option>
                  <option *ngFor="let cargo of cargos" value="{{cargo.Codigo}}">{{cargo.Descricao}}</option>
                </select>
                <span class="form-text text-danger" *ngIf="temErro && cargoFuncaoVazios || formHistoricoTransferencia.controls['cargoDestino'].dirty && cargoFuncaoVazios ? {'border-color':'red'} : null" >Campo obrigatório</span>
              </div>
            </div>

            <!--FUNÇÃO DE DESTINO-->
            <div class="col-md-6" *ngIf="!empresaPublica">
              <div class="form-group">
                <label class="font-weight-semibold">Função de Destino</label>
                <select class="form-control" formControlName="funcaoDestino" placeholder="Função de Destino" (change)="verificarCargoFuncaoVazios()"
                [ngStyle]="temErro && cargoFuncaoVazios || formHistoricoTransferencia.controls['funcaoDestino'].dirty && cargoFuncaoVazios ? {'border-color':'red'} : null" >
                  <option value="">Selecione...</option>
                  <option *ngFor="let funcao of funcoes" value="{{funcao.Codigo}}">{{funcao.Descricao}}</option>
                </select>
                <span class="form-text text-danger" *ngIf="temErro && cargoFuncaoVazios || formHistoricoTransferencia.controls['funcaoDestino'].dirty && cargoFuncaoVazios ? {'border-color':'red'} : null" >Campo obrigatório</span>
              </div>
            </div>

            <!--ANEXOS DA TELA-->
            <div _ngcontent-uxj-c4="" *ngIf="dadosColaborador.Matricula.length > 0" class="col-6 d-flex flex-row bd-highlight">
              <app-anexo [pagina]="'008'" [matricula]="dadosColaborador.Matricula" [enviarSeparadamente]="false" [opcional]="true" ></app-anexo>
            </div>
          </div>
    </div>
    <!--BOTÕES SALVAR E LIMPAR-->
    <div class="card-footer text-right">
      <button class="btn btn-danger mr-2" *ngIf="formHistoricoTransferencia.valid && !cargoFuncaoVazios" (click)="limparFormularioHistoricoTransferencia()">Limpar</button>
      <button class="btn btn-primary" (click)="salvar()" >Salvar</button>
    </div>
  </form>

  <!--TABELA DE LISTAGEM DE TRANSFERENCIAS REALIZADAS-->
  <div class="card-body">

    <h3 class="card-title">
      Listagem de Transferências Realizadas
    </h3>

    <!--MENSAGEM PARA CASO NÃO HAJA HISTÓRICO DE TRANSFERÊNCIAS-->
    <div *ngIf="listaHistoricoTransferencia.length === 0"
         class="d-flex justify-content-center align-items-center" style="height:20vh">
      <h5 class="font-weight-semibold">Não há informações para serem exibidas</h5>
    </div>

    <!--FILTRO DE PESQUISA-->
    <div *ngIf="listaHistoricoTransferencia.length > 0">
      <div class="form-group row">
        <div class="input-group col-lg-12">
          <input (keyup)='getFilter($event)' type="text" class="form-control" name="searchText" value=""
                 placeholder="Pesquisar Transferências">
          <span class="input-group-append">
            <span class="input-group-text"><i class="fa fa-search"></i></span>
          </span>
        </div>
      </div>

      <!--TABELA DE EXIBIÇÃO DA LISTAGEM-->
      <div class="table-responsive" style="max-height:45vh">
        <table class="table text-center">
          <!--CABEÇALHO DA TABELA-->
          <thead>
            <tr>
              <th>Excluir/Editar</th>
              <th>Empresa Origem</th>
              <th>Matricula Origem</th>
              <th>Empresa Destino</th>
              <th>Matricula Destino</th>
              <th>Lotação</th>
              <th>Centro de Custo</th>
              <th *ngIf="empresaPublica">Cargo</th>
              <th *ngIf="!empresaPublica">Função</th>
              <th>Data</th>
            </tr>
          </thead>

          <!--CONTEÚDO DA TABELA-->
          <tbody>
            <tr class="text-center" *ngFor="let registro of listaHistoricoTransferencia | filtroHistoricoTransferencia: filtro" style="white-space:nowrap">
              <td>
                <div>
                  <button class="btn btn-danger mr-2" (click)="avisoExcluirRegistro(registro)"><i class="fa fa-trash"></i></button>
                  <button class="btn bg-teal-400" (click)="editarRegistroTransferencia(registro)"><i class="fa fa-pencil"></i></button>
                </div>
              </td>
              <td>{{registro.EmpfilOrigem}}</td>
              <td>{{registro.MatriculaOrigem}}</td>
              <td>{{registro.EmpfilDestino}}</td>
              <td>{{registro.MatriculaDestino}}</td>
              <td>{{registro.LotacaoDestino}}</td>
              <td>{{registro.CustoDestino}}</td>
              <td *ngIf="empresaPublica">{{registro.CargoDestino}}</td>
              <td *ngIf="!empresaPublica">{{registro.FuncaoDestino}}</td>
              <td>{{registro.DataEvento.ToDate}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
