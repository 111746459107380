import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { AcessoService } from '../../service/acesso/acesso.service';
import { Location } from '@angular/common';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css'],
})
export class SideMenuComponent implements OnInit {
  localStorage: LocalStorage = new LocalStorage();
  menu: any;
  menuNivel1: any;
  menuNivel2: any;
  menuNivel3: any;
  tipoUsuario: string;
  rotaAtual: string;

  constructor(
    public auth: AuthService,
    public acesso: AcessoService,
    private location: Location,
    private router: Router
  ) {}
  ngOnInit() {
    this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    this.tipoUsuario = this.localStorage.TipoUsuario;
    this.menu = this.localStorage.Menu;
    this.tratarMenu();
    this.rotaAtual = this.location.path();
  }

  // inibirMenu(){
  //   let element: HTMLElement = document.getElementById('botaoInibir') as HTMLElement;
  //   element.click();
  // }

  inibirMenu() {
    if (this.acesso.verificaMobile()) {
      let element: HTMLElement = document.getElementById('botaoInibir') as HTMLElement;
      element.click();
    } else {
      if (this.localStorage.Toogle) {
        if (this.localStorage.Toogle == '1') {
          this.localStorage.Toogle = '1';
          let element: HTMLElement = document.getElementById(
            'botaoInibirToogleSide'
          ) as HTMLElement;
          element.click();
          this.localStorage.Toogle = '0';
        }
      } else {
        this.localStorage.Toogle = '1';
        window.location.href = '/';
      }
    }
  }

  tratarMenu() {
    this.menu = this.menu.map((item) => {
      item['linkExterno'] = item.ExibeMenu ? 0 : 2;
      item['icon'] = item.IconeMenu;
      return item;
    });
    this.menuNivel1 = this.menu.filter((item) => item.NivelDoItemDeMenu == 1);
    // console.log(this.menuNivel1);
    this.menuNivel2 = this.menu.filter((item) => item.NivelDoItemDeMenu == 2);
    // console.log(this.menuNivel2);
    this.menuNivel3 = this.menu.filter((item) => item.NivelDoItemDeMenu == 3);
    // console.log(this.menuNivel3);
  }

  temFilho(menuItem: any) {
    const filhos = this.menu.filter((item) => item.CodigoDoItemPai == menuItem.CodigoDoItemDeMenu);
    if (filhos.length > 0) {
      return true;
    }
    return false;
  }

  atualizarPagina(url: string) {
    if (url === window.location.pathname.substring(1)) {
      // Se já estamos na mesma rota, recarrega a página para resetar o componente
      console.log('Recarregando a página para resetar o componente');
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([url]); // Navega sem o state
      });
    } else {
      this.navigateTo(url); // Navegação normal
    }
  }

  navigateTo(link: string) {
    if (this.rotaAtual.includes('cadastro-publico')) {
      this.router.navigate([link], { state: null });
    } else {
      this.router.navigate([link]);
    }
  }
}
