import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { RecuperarSenhaService } from './recuperar-senha.service';
import Swal from 'sweetalert';
import { NgxSpinnerService } from "ngx-spinner";
import { ErroService } from 'src/app/service/erros/erro.service';
import { objetoEmailLink } from './classes/objetoEmailLink';
import { Router } from '@angular/router';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';


@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.css']
})
export class RecuperarSenhaComponent implements OnInit {

  carregando: Boolean;
  email: string;
  formRecuperarSenha: UntypedFormGroup;
  localStorage: LocalStorage;
  constructor(private formBuilder: UntypedFormBuilder, private service: RecuperarSenhaService, private validaError: ErroService, private spinner: NgxSpinnerService, private myRoute: Router) { }

  ngOnInit() {
    this.localStorage = this.decryptLocalStorage();
    this.carregando = false;
    this.formRecuperarSenha = this.formBuilder.group({
      emailForm: ['',[Validators.required, Validators.email]]
    })
  }

  retornarTelaLogin(){
    this.myRoute.navigate(['login']);
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }

  limparForm(){
    this.formRecuperarSenha.get('emailForm').setValue('');
  }

  enviarEmail(){
    this.spinner.show();
    this.email = this.formRecuperarSenha.get('emailForm').value;
    this.service.enviarEmail(this.email).subscribe(
      data => {
        this.spinner.hide();
        new AlertaModal("sucesso", "Sucesso!", "Email enviado com sucesso!", 'p');
        this.retornarTelaLogin();
        this.limparForm();
      },
      error =>{

        this.spinner.hide();
        this.validaError.retornoErro(error);
      }
    )
  }

}


