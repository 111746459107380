<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div *ngIf="!telaFormulario" class="content d-flex justify-content-center align-items-center">
    <form class="login-form" #telainicial="ngForm" [formGroup]="formTelaInicial">
        <div class="card  bg-dark mb-0 text-ligth">
            <div class="card-body">
                <div class="text-center mb-3">
                    <h5 class="mb-0">Manutenção Bancos de Dados Lógicos *</h5>
                </div>
                <div class="s-12 col-sm-12 col-md-12 col-lg-12 form-group">
                    <label class="justify-content-start">Serviço</label>
                    <select class="form-control" type="text" formControlName="codServico_form"
                        [ngStyle]="((temErro || formTelaInicial.controls['codServico_form'].dirty) && formTelaInicial.controls['codServico_form'].status == 'INVALID') ? {'border-color':'red'} : null">
                        <option value=""> Selecione...</option>
                        <option *ngFor="let servico of listaServicos" value="{{servico.Codigo}}"> {{servico.Descricao}}
                        </option>
                    </select>
                    <span class="form-text text-danger"
                        *ngIf="formTelaInicial.get('codServico_form').invalid && temErro">
                        Campo Obrigatório
                    </span>
                    <span class="form-text text-danger"
                        *ngIf="formTelaInicial.value.codServico_form.length > 0 && formTelaInicial.get('codServico_form').hasError('codServicoError')">
                        Código inválido
                    </span>
                </div>

                <div class=" text-center">
                    <button type="button" (click)="buscarBancosPorCodigo()" class="btn btn-primary">Buscar</button>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="card" *ngIf="telaFormulario">
    <div class="card-header">
        <h3 class="card-title" id="target"> Manutenção de Bancos de Dados Lógicos</h3>
    </div>

    <form [formGroup]="formBancosLogicos" (ngSubmit)="submitBancoLogico()" #bancos='ngForm'>
        <div class="card-body">
            <div class="row">
                <div class="col-md-2 form-group">
                    <label class="font-weight-semibold">Código</label>
                    <!-- Verificar, pois possivelmente esse campo sera Disabled, só ira mostrar valor no alterar-->
                    <input class="form-control" type="text" formControlName="codigo" maxlength="20" placeholder="Código"
                        [ngStyle]="((temErro || formBancosLogicos.controls['codigo'].dirty) && formBancosLogicos.controls['codigo'].status == 'INVALID') ? {'border-color':'red'} : null" />
                    <span class="form-text text-danger"
                        *ngIf="temErro && formBancosLogicos.controls['codigo'].hasError('required')">
                        Campo obrigatório
                    </span>
                    <span class="form-text" *ngIf="loadingColaborador">Carregando <i class="fa fa-spinner fa-spin"
                            aria-hidden="false"></i></span>
                </div>

                <div class="col-md-7 form-group">
                    <label class="font-weight-semibold">Descrição</label>
                    <input class="form-control" type="text" formControlName="descricao" placeholder="Descrição"
                        maxlength="80"
                        [ngStyle]="((temErro || formBancosLogicos.controls['descricao'].dirty) && formBancosLogicos.controls['descricao'].status == 'INVALID') ? {'border-color':'red'} : null" />
                    <span class="form-text text-danger"
                        *ngIf="temErro && formBancosLogicos.controls['descricao'].hasError('required')">
                        Campo obrigatório
                    </span>
                </div>
                <div class="col-2"></div>

                <div class="col-md-3 form-group">
                    <label class="font-weight-semibold">Código Serviço B.D Físico</label>
                    <select class="form-control" type="text" formControlName="codServicoFisico"
                        [ngStyle]="((temErro || formBancosLogicos.controls['codServicoFisico'].dirty) && formBancosLogicos.controls['codServicoFisico'].status == 'INVALID') ? {'border-color':'red'} : null">
                        <option value=""> Selecione...</option>
                        <option *ngFor="let servico of listaServicos" value="{{servico.Codigo}}">{{servico.Codigo}} -
                            {{servico.Descricao}}</option>
                    </select>
                    <span class="form-text text-danger"
                        *ngIf="temErro && formBancosLogicos.controls['codServicoFisico'].hasError('required')">
                        Campo obrigatório
                    </span>
                </div>

                <div class="col-md-3 form-group">
                    <label class="font-weight-semibold">Tipo de Uso</label>
                    <select class="form-control" type="text" formControlName="tipoUso"
                        [ngStyle]="((temErro || formBancosLogicos.controls['tipoUso'].dirty) && formBancosLogicos.controls['tipoUso'].status == 'INVALID') ? {'border-color':'red'} : null">
                        <option value=""> Selecione...</option>
                        <option value="1"> Por Cliente
                        <option value="2"> Por Serviço
                        </option>
                    </select>
                    <span class="form-text text-danger"
                        *ngIf="temErro && formBancosLogicos.controls['tipoUso'].hasError('required')">
                        Campo obrigatório
                    </span>
                </div>

                <div class="col-md-2 form-group">
                    <label class="font-weight-semibold">Banco de Dados Físico</label>
                    <select class="form-control" type="text" formControlName="bDadosFisicos"
                        [ngStyle]="((temErro || formBancosLogicos.controls['bDadosFisicos'].dirty) && formBancosLogicos.controls['bDadosFisicos'].status == 'INVALID') ? {'border-color':'red'} : null">
                        <option value=""> Selecione...</option>
                        <option *ngFor="let banco of listaBancosFisicos" value="{{banco.CodigoBanco}}">
                            {{banco.CodigoBanco}} - {{banco.Descricao}} </option>
                    </select>
                    <span class="form-text text-danger"
                        *ngIf="temErro && formBancosLogicos.controls['bDadosFisicos'].hasError('required')">
                        Campo obrigatório
                    </span>
                </div>

            </div>
        </div>

        <div class="card-footer text-right ">
            <!--Se todos os campos forem preenchidos e as configurações tambem, disabled = false-->
            <button *ngIf="formBancosLogicos.valid" type="button" (click)="limparCampos()"
                class="btn btn-danger mr-2">Limpar</button>
            <button [disabled]="formBancosLogicos.invalid" class="btn btn-primary" type="submit">Salvar</button>
        </div>

    </form>
</div>


<div class="card" *ngIf="telaFormulario">
    <div class="card-header">
        <h3 class="card-title"> Listagem dos Bancos de Dados Lógicos</h3>
    </div>

    <div class="card-body">
        <div class="d-flex justify-content-center align-items-center">
        </div>
        <div class="form-group row">
            <div class="input-group col-lg-12" *ngIf="listaBancosLogicos != null && listaBancosLogicos.length > 0">
                <input type="text" class="form-control" name="searchText" value="" placeholder="Pesquisar">
                <span class="input-group-append">
                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                </span>
            </div>
        </div>

        <div *ngIf="listaBancosLogicos != null && listaBancosLogicos.length > 0" class="table-responsive"
            style="max-height:45vh">
            <table class="table table-striped table-hover">
                <thead class="text-center">
                    <tr class="text-center" style="white-space:nowrap">
                        <th class="sticky-top bg-white"></th>
                        <th class="sticky-top bg-white">Código</th>
                        <th class="sticky-top bg-white">Descrição</th>
                        <th class="sticky-top bg-white">Código Serviço B.D Físico</th>
                        <th class="sticky-top bg-white">Tipo de Uso</th>
                        <th class="sticky-top bg-white">Banco de Dados Físico</th>
                        <!-- <th class="sticky-top bg-white">Usuários Da banco</th> -->

                    </tr>
                </thead>
                <tbody id="lista-bancos">
                    <tr *ngFor="let banco of listaBancosLogicos let i = index" class="text-center"
                        style="white-space:nowrap">
                        <td>
                            <div>
                                <button class="btn btn-danger mr-2" (click)="deletar(banco, i)">
                                    <i class="fa fa-trash"></i>
                                </button>
                                <button class="btn bg-teal-400" (click)="carregarBanco(banco)">
                                    <i class="fa fa-pencil"></i>
                                </button>
                            </div>
                        </td>
                        <td>{{banco.CodigoBanco}}</td>
                        <td>{{banco.Descricao}}</td>
                        <td>{{banco.CodServicoBdFisico}}</td>
                        <td>{{banco.TipoUso}}</td>
                        <td>{{banco.BancoFisico}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="listaBancosLogicos == null || listaBancosLogicos.length === 0"
            class="d-flex justify-content-center align-items-center" style="height:20vh">
            <h5 class="font-weight-semibold">Não há Bancos Lógicos para serem exibidos</h5>
        </div>
    </div>

    <!-- Modal Usuario banco-->

    <div class="modal fade" id="configuracaobanco" tabindex="-1" role="dialog" aria-labelledby="configuracaobancoTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header navbar-light bg-light">
                    <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="justify-content-start mt-2"> Tipo de banco</h5>
                            </div>
                            <button _ngcontent-nbj-c13="" aria-label="Close" class="mb-4 close" data-dismiss="modal"
                                type="button"><span _ngcontent-nbj-c13="" aria-hidden="true">x</span></button>
                        </div>
                        <br>
                    </div>

                </div>
                <div class="modal-body  navbar-light bg-light">
                    <div class="container">
                        <div class="text-center">

                            <div class="col-12 table-responsive text-center">
                                <div class="row form-group text-center">
                                    <table class="table table-striped table-hover mb-0 text-center">
                                        <thead class="bg-teal-400">
                                            <tr class="text-center" style="white-space:nowrap">
                                                <th style="text-align:left;"></th>
                                                <th>Código</th>
                                                <th>Nome</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <!--Aguardando Rota do back ( Relação de Usuários Da banco )-->
                                            <tr *ngFor="let banco of listaBancosLogicos let i = index"
                                                class="text-center" style="white-space:nowrap">
                                                <td>
                                                    <div class="form-check">
                                                        <input class="form-check-input" value="{{banco.Codigo}}"
                                                            type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                                        <label class="form-check-label" for="flexRadioDefault1">
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>{{banco.Codigo}}</td>
                                                <td>{{banco.Descricao}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>