import { ParametrosClass } from 'src/app/service/classes/cadastro/Pessoal/ParametrosClass';
import { Parametros } from './../../../../service/interfaces/Parametros';
import { Component, OnInit, Output, Input, EventEmitter, SimpleChanges } from '@angular/core';
import Swal from 'sweetalert';
import { Cadastro } from '../../../../service/classes/cadastro/cadastro';
import { CadastroPublicoService } from 'src/app/componentes/cadastro-publico/cadastro-publico.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ErroService } from 'src/app/service/erros/erro.service';
import { DadosPessoaisService } from './dados-pessoais.service';
import { CadastroService } from 'src/app/componentes/cadastro/cadastro.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { ProfileService } from '../../profile.service';
import { DadosPessoais } from 'src/app/service/interfaces/DadosPessoais';
import { error } from 'protractor';
import { EstadoCivil } from 'src/app/service/classes/cadastro/Pessoal/DadosBasicos/EstadoCivil';
import { Etnia } from 'src/app/service/classes/cadastro/Pessoal/DadosBasicos/Etnia';
import { Horario } from 'src/app/componentes/cadastro-publico/models/interfaces';

@Component({
  selector: 'app-dados-pessoais',
  templateUrl: './dados-pessoais.component.html',
  styleUrls: ['./dados-pessoais.component.css'],
})
export class DadosPessoaisComponent implements OnInit {
  @Output() attCadastro = new EventEmitter<Cadastro>();
  @Input() cadastroUsuario: Cadastro;
  @Input() ColaboradorInativo: boolean;
  @Input() uf: any[];
  @Input() mudancaTab: boolean;

  localStorage: LocalStorage;

  formDadosPessoais: FormGroup;
  dadosLinkados: DadosPessoais;
  cadastroUsuarioTemporario: Cadastro;
  listaDiasSemana: string[] = [
    'Segunda Feira',
    'Terça Feira',
    'Quarta Feira',
    'Quinta Feira',
    'Sexta Feira',
    'Sábado',
    'Domingo',
  ];
  listaEtnia: Parametros[] = [];
  sindicatos: any[] = [];
  listaVinculos: any[] = [];
  listaEstadoCivil: Parametros[] = [];
  listaMunicipio: any[] = [];
  tipoDependecias: any[] = [];
  listaCargaHoraria: any[] = [];
  listaTipoFolha: any[] = [];
  listaTipoSanquineo: any[] = [];
  listaDiasUteis: any[] = [];
  listaUf: any[] = [];
  listaCorPele: any[] = [];
  listaGrauInstrucao: any[] = [];
  listaHorario: any[] = [];
  listaSetor: any[] = [];
  listaNacionalidade: any[] = [];
  listaSexo: any[] = [];
  listaTipoFrequencia: any[] = [];
  codigo_uf: any;
  cidade: any[];
  horarioEscolhido: Horario[];
  tipoUsuario: string;

  displayDadosPessoais: boolean = false;
  temErro: boolean = false;
  loading: boolean = false;

  readonly botoes = [
    {
      label: 'Editar',
      color: 'warning',
      icon: 'fa-pencil-square-o',
      action: () => this.editarDadosPessoais(),
      show: () => !this.displayDadosPessoais && !this.ColaboradorInativo && this.tipoUsuario != '1',
    },
    {
      label: 'Cancelar',
      color: 'danger',
      action: () => this.cancelarEdicao(),
      show: () => this.displayDadosPessoais,
    },
    {
      label: 'Salvar',
      color: 'primary',
      action: () => this.submitDadosPessoais(),
      show: () => this.displayDadosPessoais,
    },
  ];

  constructor(
    private fb: FormBuilder,
    private cadastroPublicoService: CadastroPublicoService,
    private validaErro: ErroService,
    private dadosPessoaisService: DadosPessoaisService,
    private cadastroService: CadastroService,
    public profileService: ProfileService
  ) {
    this.localStorage = this.decryptLocalStorage();
    this.tipoUsuario = this.localStorage.TipoUsuario;
    this.formDadosPessoais = this.fb.group({
      nomeDadosPessoais: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      admissaoDadosPessoais: ['', Validators.required],
      nascimentoDadosPessoais: [''],
      emailDadosPessoais: ['', Validators.email],
      celularDadosPessoais: [
        '',
        Validators.compose([Validators.minLength(11), Validators.maxLength(11)]),
      ],
      telefoneDadosPessoais: [
        '',
        Validators.compose([Validators.minLength(10), Validators.maxLength(11)]),
      ],
      tipoSangue: [''],
      sexoDadosPessoais: [''],
      vinculoDadosPessoais: ['', Validators.pattern(/^(?!0$)/)],
      sindicatoDadosPessoais: ['', Validators.required],
      estadoCivilDadosPessoais: ['', Validators.required],
      nomeMaeDadosPessoais: ['', Validators.minLength(3)],
      nomePaiDadosPessoais: ['', Validators.minLength(3)],
      nacionalidadeDadosPessoais: ['', Validators.required],
      cargaHorariaDadosPessoais: [''],
      tipoFolhaDadosPessoais: ['', Validators.required],
      horarioDadosPessoais: ['', Validators.required],
      repousoDadosPessoais: [''],
      diasUteisDadosPessoais: ['', Validators.required],
      GrauDadosPessoais: ['', Validators.required],
      corDadosPessoais: [''],
      ufDadosPessoais: [''],
      cidadeDadosPessoais: [''],
    });
  }

  ngOnInit() {
    this.buscarParametros();
    this.buscarNacionalidade();
    this.buscarVinculo();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cadastroUsuario']?.currentValue && this.cadastroUsuario?.NumeroDeOrdem) {
      this.cadastroUsuario = changes['cadastroUsuario']?.currentValue;
      this.carregarCampos();
      if (!this.cadastroUsuarioTemporario) {
        this.cadastroUsuarioTemporario = this.cadastroUsuario;
      }
    }
    if (changes['mudancaTab']?.currentValue && this.displayDadosPessoais == true) {
      this.cancelarEdicao();
    }
  }

  editarDadosPessoais() {
    this.displayDadosPessoais = !this.displayDadosPessoais;
  }

  cancelarEdicao() {
    this.displayDadosPessoais = !this.displayDadosPessoais;
    this.carregarCampos();
    this.temErro = false;
  }

  buscarCidade() {
    console.log(
      'this.formDadosPessoais.get(ufDadosPessoais).value',
      this.formDadosPessoais.get('ufDadosPessoais').value
    );
    if (this.formDadosPessoais.get('ufDadosPessoais').value) {
      this.profileService
        .buscarCidade(this.formDadosPessoais.get('ufDadosPessoais').value)
        .subscribe(
          (data) => {
            this.listaMunicipio = JSON.parse(JSON.stringify(data));
            this.formDadosPessoais.controls['cidadeDadosPessoais'].setValue(
              this.cadastroUsuario.Pessoal.DadosBasicos.Naturalidade.Municipio.Codigo != 0
                ? this.cadastroUsuario.Pessoal.DadosBasicos.Naturalidade.Municipio.Codigo
                : ''
            );
          },
          (error) => {
            this.validaErro.retornoErro(error);
          }
        );
    }
  }

  buscarParametros(): void {
    this.cadastroPublicoService.buscarParametros().subscribe(
      async (data: any) => {
        this.listaDiasUteis = data.DiasUteis;
        this.listaUf = data.Estados;
        this.listaTipoSanquineo = data.TiposSanguineo;
        this.listaEstadoCivil = data.EstadosCivil;
        this.listaGrauInstrucao = data.GrausInstrucao;
        this.listaSexo = data.Sexos;
        this.listaHorario = data.Horarios;
        this.listaTipoFolha = data.TiposFolha;
        this.listaTipoFrequencia = data.TiposFrequencia;
        this.listaEtnia = data.Etnias;
      },
      (error) => {}
    );
  }

  buscarNacionalidade() {
    this.cadastroService.buscarNacionalidade().subscribe(
      (data) => {
        this.listaNacionalidade = JSON.parse(JSON.stringify(data));
        // this.onNacionalidadeChange();
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  // buscarCor() {
  //   this.cadastroService.buscarCor().subscribe(
  //     (data) => {
  //       this.etniaList = JSON.parse(JSON.stringify(data));
  //     },
  //     (error) => {
  //       this.validaErro.retornoErro(error);
  //     }
  //   );
  // }

  buscarCargaHoraria(funcao: string) {
    this.cadastroPublicoService.buscarCargaHoraria(funcao).subscribe({
      next: (data: any) => {
        this.listaCargaHoraria = data;
        if (this.listaCargaHoraria.length === 1) {
          this.formDadosPessoais
            .get('cargaHorariaDadosPessoais')
            .setValue(this.listaCargaHoraria[0].Codigo);
        }
      },
      error: (error) => {
        this.validaErro.retornoErro(error);
      },
    });
  }

  // buscarEstadoCivil() {
  //   this.cadastroService.buscarEstadoCivil().subscribe(
  //     (data) => {
  //       this.estadoCivil = JSON.parse(JSON.stringify(data));
  //     },
  //     (error) => {
  //       this.validaErro.retornoErro(error);
  //     }
  //   );
  // }

  // buscarSexo() {
  //   this.cadastroService.buscarSexo().subscribe(
  //     (data) => {
  //       this.sexo = JSON.parse(JSON.stringify(data));
  //       this.buscarVinculo();
  //     },
  //     (error) => {
  //       this.validaErro.retornoErro(error);
  //     }
  //   );
  // }

  // buscarTipoSanquineo() {
  //   this.profileService.buscarTipoSanquineo().subscribe(
  //     (data) => {
  //       this.listaTipoSanquineo = JSON.parse(JSON.stringify(data));
  //     },
  //     (error) => {
  //       this.validaErro.retornoErro(error);
  //     }
  //   );
  // }

  buscarSindicato() {
    this.cadastroService.buscarSindicato().subscribe(
      (data) => {
        this.sindicatos = JSON.parse(JSON.stringify(data));
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarVinculo() {
    this.cadastroService.buscarVinculo().subscribe(
      (data) => {
        this.listaVinculos = JSON.parse(JSON.stringify(data));
        this.buscarSindicato();
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  async submitDadosPessoais() {
    try {
      if (!this.formDadosPessoais.valid) {
        Swal(
          'Atenção',
          'Verifique os campos obrigatórios e/ou seus padrões de preenchimento',
          'warning'
        );
        this.temErro = true;
      } else {
        this.SalvarDadosPessoais();
        this.temErro = false;
      }
    } catch (err) {
      this.validaErro.retornoErro(err);
      this.temErro = true;
    }
  }

  SalvarDadosPessoais() {
    this.loading = true;
    this.montarDadosPessoais();
    this.atualizarDadosPessoais(this.dadosLinkados);
    this.dadosPessoaisService
      .AlterarCadastroPerfil(this.dadosLinkados, this.cadastroUsuario.NumeroDeOrdem)
      .subscribe(
        (data) => {
          this.displayDadosPessoais = false;

          Swal('Sucesso', 'O cadastro foi alterado', 'success');
          this.loading = false;
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.loading = false;
        }
      );
  }

  atualizarDadosPessoais(dadosPessoais: DadosPessoais) {
    const encontrarItem = (
      lista: any[],
      propriedade: string,
      valor: any
    ): ParametrosClass | undefined => {
      const item = lista.find((item) => item[propriedade] === valor);
      if (item) {
        return {
          Valor: item.Codigo,
          Descricao: item.Descricao,
        };
      }
      return undefined;
    };

    const estadoCivil = (): ParametrosClass | undefined =>
      encontrarItem(this.listaEstadoCivil, 'Codigo', dadosPessoais.estadoCivil);

    const etnia = (): ParametrosClass | undefined =>
      encontrarItem(this.listaEtnia, 'Descricao', dadosPessoais.etnia);

    const sexo = (): ParametrosClass | undefined =>
      encontrarItem(this.listaSexo, 'Codigo', dadosPessoais.sexo);

    this.cadastroUsuario = {
      ...this.cadastroUsuario,
      Pessoal: {
        ...this.cadastroUsuario.Pessoal,
        Contato: {
          Celular: dadosPessoais.celular,
          DDDCelular: dadosPessoais.DDDCelular,
          Telefone: dadosPessoais.telefone,
          DDDTelefone: dadosPessoais.DDDTelefone,
          Email1: dadosPessoais.email,
          Email2: '',
        },
        DadosBasicos: {
          ...this.cadastroUsuario.Pessoal.DadosBasicos,
          EstadoCivil: estadoCivil(),
          Etnia: dadosPessoais.etnia == '' ? { Valor: 0, Descricao: '' } : etnia(),

          Nacionalidade: {
            Codigo: dadosPessoais.nacionalidade.Codigo,
            Nome: dadosPessoais.nacionalidade.Nome,
          },
          // Verifica se a nacionalidade é 10 (brasileiro) antes de incluir "naturalidade"
          ...(dadosPessoais.nacionalidade.Codigo == 10
            ? {
                Naturalidade: {
                  Municipio: {
                    Codigo: dadosPessoais.naturalidade.Municipio.Codigo,
                    Estado: {
                      Codigo: dadosPessoais.naturalidade.Municipio.Estado.Codigo,
                      Nome: dadosPessoais.naturalidade.Municipio.Estado.Nome,
                      SiglaUf: dadosPessoais.naturalidade.Municipio.Estado.SiglaUf,
                    },
                    Nome: dadosPessoais.naturalidade.Municipio.Nome,
                  },
                },
              }
            : {}),
          NomeMae: dadosPessoais.nomeMae,
          NomePai: dadosPessoais.nomePai,
          Sexo: sexo(),
          GrauDeInstrucao: {
            Valor: dadosPessoais.dadosBasicos.grauDeInstrucao.valor,
            Descricao: '',
          },
          DataNascimento: {
            ToDate:
              dadosPessoais.nascimento == ''
                ? ''
                : this.formataData(this.formatarDataEnvio(dadosPessoais.nascimento)),
            SetData: dadosPessoais.nascimento == '' ? '0' : dadosPessoais.nascimento,
          },
        },
      },
      InformacoesBasicas: {
        ...this.cadastroUsuario.InformacoesBasicas,
        NomeCompleto: dadosPessoais.nome,
        Vinculo: this.listaVinculos.find(
          (vinculo) => vinculo.Codigo === dadosPessoais.vinculo.toString()
        ),
        Sindicato: this.sindicatos.find(
          (sindicato) => sindicato.Codigo === Number(dadosPessoais.sindicato)
        ),
        HorarioTrabalho: {
          ...this.cadastroUsuario.InformacoesBasicas.HorarioTrabalho,
          Codigo: 0,
          CargaHorariaSemana: dadosPessoais.informacoesBasicas.horarioTrabalho.cargaHorariaSemana,
        },
        Descanso: dadosPessoais.informacoesBasicas.descanso,
        DiasUteisColaborador: dadosPessoais.informacoesBasicas.diasUteisColaborador,
        TipoFolha: dadosPessoais.informacoesBasicas.tipoFolha,
        TipoSanguineo: dadosPessoais.informacoesBasicas.tipoSanguineo,
      },
    };
    if (!this.exclusaoDadoExistente()) {
      this.attCadastro.emit(this.cadastroUsuario);
    }
  }

  exclusaoDadoExistente(): boolean {
    if (
      (this.cadastroUsuarioTemporario?.Pessoal.DadosBasicos.DataNascimento.ToDate != '' &&
        this.cadastroUsuario?.Pessoal.DadosBasicos.DataNascimento.ToDate == '') ||
      (this.cadastroUsuarioTemporario?.Pessoal.Contato.Email1 != '' &&
        this.cadastroUsuario?.Pessoal.Contato.Email1 == '') ||
      (this.cadastroUsuarioTemporario?.Pessoal.Contato.Celular != '0' &&
        this.cadastroUsuario?.Pessoal.Contato.Celular == '0') ||
      (this.cadastroUsuarioTemporario?.Pessoal.DadosBasicos.Sexo.Valor != 0 &&
        (!this.cadastroUsuario?.Pessoal.DadosBasicos.Sexo ||
          this.cadastroUsuario?.Pessoal.DadosBasicos.Sexo.Valor == 0)) ||
      (this.cadastroUsuarioTemporario?.InformacoesBasicas.Sindicato.Codigo != 0 &&
        (!this.cadastroUsuario?.InformacoesBasicas.Sindicato ||
          this.cadastroUsuario?.InformacoesBasicas.Sindicato.Codigo == 0)) ||
      (this.cadastroUsuarioTemporario?.Pessoal.DadosBasicos.EstadoCivil.Valor != 0 &&
        (!this.cadastroUsuario?.Pessoal.DadosBasicos.EstadoCivil ||
          this.cadastroUsuario?.Pessoal.DadosBasicos.EstadoCivil.Valor == 0)) ||
      (this.cadastroUsuarioTemporario?.Pessoal.DadosBasicos.NomeMae != '' &&
        this.cadastroUsuario?.Pessoal.DadosBasicos.NomeMae == '') ||
      (this.cadastroUsuarioTemporario?.Pessoal.DadosBasicos.Nacionalidade.Codigo != 0 &&
        (!this.cadastroUsuario?.Pessoal.DadosBasicos.Nacionalidade ||
          this.cadastroUsuario?.Pessoal.DadosBasicos.Nacionalidade.Codigo == 0)) ||
      (this.cadastroUsuarioTemporario?.Pessoal.DadosBasicos.Etnia.Descricao != 'NÃO INFORMADA' &&
        this.cadastroUsuarioTemporario?.Pessoal.DadosBasicos.Etnia.Descricao != '' &&
        (!this.cadastroUsuario?.Pessoal.DadosBasicos.Etnia ||
          this.cadastroUsuario?.Pessoal.DadosBasicos.Etnia.Descricao == 'NÃO INFORMADA' ||
          this.cadastroUsuario?.Pessoal.DadosBasicos.Etnia.Descricao == ''))
    ) {
      this.cadastroUsuario = this.cadastroUsuarioTemporario;
      return true;
    } else {
      return false;
    }
  }

  aoMudarHorario(): void {
    const codigoHorarioSelecionado = this.formDadosPessoais.get('horarioDadosPessoais').value;
    if (codigoHorarioSelecionado != '') document.getElementById('btHorarioModal').click();
    let { Dias: dias } = this.listaHorario.filter(
      (horario: any) => horario.Codigo === codigoHorarioSelecionado
    )[0] as any;
    dias = dias.map((dia) => {
      dia.EntradaAntesAlmoco = this.formatarHorario(dia.EntradaAntesAlmoco);
      dia.EntradaDepoisAlmoco = this.formatarHorario(dia.EntradaDepoisAlmoco);
      dia.SaidaAntesAlmoco = this.formatarHorario(dia.SaidaAntesAlmoco);
      dia.SaidaDepoisAlmoco = this.formatarHorario(dia.SaidaDepoisAlmoco);
      return dia;
    });
    this.horarioEscolhido = dias;

    let repouso = this.listaHorario.filter(
      (horario: any) => horario.Codigo === codigoHorarioSelecionado
    )[0] as any;
    this.formDadosPessoais.get('repousoDadosPessoais').setValue(repouso.Descanso);
  }

  limpaHorario(): void {
    this.formDadosPessoais.get('horarioDadosPessoais').setValue('');
    this.formDadosPessoais.get('horarioDadosPessoais').updateValueAndValidity();
  }

  formatarSeteDias(arr: any[]): any[] {
    const seteDias = new Array(this.listaDiasSemana.length).fill(null);
    for (let i = 0; i < 7 && i < arr.length; i++) {
      seteDias[i] = arr[i];
    }
    return seteDias;
  }

  formatarHorario(horario: string) {
    if (horario.length === 5) return horario;
    if (horario.trim() == '0') return horario.trim();
    horario = horario.trim().padStart(4, '0');
    return `${horario.substring(0, 2)}:${horario.substring(2, 4)}`;
  }

  formatarData(data: string): string {
    let dataSplit = data.split('/');
    const dataFormatada = `${dataSplit[2]}-${dataSplit[1]}-${dataSplit[0]}`;

    return dataFormatada;
  }

  formataData(data: string): string {
    data = data.split('/').join('');
    const dia = data.substring(0, 2);
    const mes = data.substring(2, 4);
    const ano = data.substring(4, 8);

    return `${dia}/${mes}/${ano}`;
  }

  formatarDataEnvio(data: string): string {
    let dataSplit = data.split('-');
    const dataFormatada = `${dataSplit[2]}${dataSplit[1]}${dataSplit[0]}`;
    return dataFormatada;
  }

  formataTelefone(tel: any) {
    if (tel !== undefined) {
      if (tel.length === 8) {
        tel = tel.substr(0, 4) + '-' + tel.substr(4, 4);
      } else if (tel.length === 9) {
        tel = tel.substr(0, 5) + '-' + tel.substr(5, 4);
      }
    }
    return tel;
  }

  montarDadosPessoais(): void {
    const dadosPessoais = this.formDadosPessoais.getRawValue();
    const encontrarItem = (
      lista: any[],
      propriedade: string,
      valor: any
    ): ParametrosClass | undefined => {
      const item = lista.find((item) => item[propriedade] === valor);
      if (item) {
        return {
          Valor: item.Codigo,
          Descricao: item.Descricao,
        };
      }
      return undefined;
    };

    const estadoCivil = (): ParametrosClass | undefined =>
      encontrarItem(this.listaEstadoCivil, 'Codigo', dadosPessoais.estadoCivil);

    const etnia = (): ParametrosClass | undefined =>
      encontrarItem(this.listaEtnia, 'Descricao', dadosPessoais.etnia);

    const sexo = (): ParametrosClass | undefined =>
      encontrarItem(this.listaSexo, 'Codigo', dadosPessoais.sexo);

    const nacionalidade =
      dadosPessoais.nacionalidadeDadosPessoais != 0
        ? this.listaNacionalidade.find((nacionalidade) => {
            return nacionalidade.Valor == dadosPessoais.nacionalidadeDadosPessoais;
          })
        : 0;
    const cid =
      this.formDadosPessoais.controls['nacionalidadeDadosPessoais'].value == 10
        ? this.listaMunicipio.find((cid) => {
            return cid.Codigo == dadosPessoais.cidadeDadosPessoais;
          })
        : '';

    const estado =
      this.formDadosPessoais.controls['nacionalidadeDadosPessoais'].value == 10
        ? this.listaUf.find((estado) => {
            return estado.Codigo == Number(dadosPessoais.ufDadosPessoais);
          })
        : '';
    const nac =
      nacionalidade != 0
        ? { Codigo: nacionalidade.Valor, Nome: nacionalidade.Descricao }
        : { Codigo: 0, Nome: '' };

    const nat = {
      Municipio: {
        Codigo: cid.Codigo ? cid.Codigo : '',
        Estado: {
          Codigo: estado.Descricao,
          Nome: estado.Nome,
          SiglaUf: estado.Descricao,
        },
        Nome: cid.Nome ? cid.Nome : '',
      },
    };
    this.dadosLinkados = {
      nome: dadosPessoais.nomeDadosPessoais,
      nascimento:
        dadosPessoais.nascimentoDadosPessoais == 'undefined-undefined-'
          ? ''
          : dadosPessoais.nascimentoDadosPessoais,
      email: dadosPessoais.emailDadosPessoais,
      celular:
        dadosPessoais.celularDadosPessoais != ''
          ? dadosPessoais.celularDadosPessoais.substring(2, 11)
          : 0,
      DDDCelular:
        dadosPessoais.celularDadosPessoais != ''
          ? dadosPessoais.celularDadosPessoais.substring(0, 2)
          : 0,
      telefone:
        dadosPessoais.telefoneDadosPessoais != ''
          ? dadosPessoais.telefoneDadosPessoais.substring(2, 11)
          : 0,
      DDDTelefone:
        dadosPessoais.telefoneDadosPessoais != ''
          ? dadosPessoais.telefoneDadosPessoais.substring(0, 2)
          : 0,
      sexo: dadosPessoais.sexoDadosPessoais,
      vinculo: parseInt(dadosPessoais.vinculoDadosPessoais),
      sindicato: dadosPessoais.sindicatoDadosPessoais,
      estadoCivil: dadosPessoais.estadoCivilDadosPessoais,
      nomeMae: dadosPessoais.nomeMaeDadosPessoais,
      nomePai: dadosPessoais.nomePaiDadosPessoais,
      nacionalidade: nac,
      ...(nac.Codigo == 10 ? { naturalidade: nat } : {}),
      etnia: dadosPessoais.corDadosPessoais == '0' ? '' : dadosPessoais.corDadosPessoais,
      informacoesBasicas: {
        horarioTrabalho: {
          codigo: dadosPessoais.horarioDadosPessoais,
          cargaHorariaSemana: dadosPessoais.cargaHorariaDadosPessoais,
        },
        descanso: dadosPessoais.repousoDadosPessoais,
        diasUteisColaborador: dadosPessoais.diasUteisDadosPessoais,
        tipoFolha: dadosPessoais.tipoFolhaDadosPessoais,
        tipoSanguineo: dadosPessoais.tipoSangue,
      },
      dadosBasicos: {
        grauDeInstrucao: { valor: dadosPessoais.GrauDadosPessoais },
      },
    };
  }

  formatarCodigoHorario(codigo: string) {
    if (codigo.startsWith('H') && codigo.length === 4) {
      return codigo;
    } else {
      return 'H' + String(Number(codigo)).padStart(3, '0');
    }
  }

  carregarCampos() {
    const { Pessoal, InformacoesBasicas } = this.cadastroUsuario;
    const { Contato, DadosBasicos } = Pessoal;
    this.formDadosPessoais
      .get('nomeDadosPessoais')
      .setValue(this.cadastroUsuario.InformacoesBasicas.NomeCompleto);
    this.formDadosPessoais
      .get('admissaoDadosPessoais')
      .setValue(this.formatarData(this.cadastroUsuario.InformacoesBasicas.DataAdmissao.ToDate));
    if (this.cadastroUsuario.Stiuacao.Codigo != 98) {
      this.formDadosPessoais.get('admissaoDadosPessoais').disable();
    }
    this.formDadosPessoais
      .get('nascimentoDadosPessoais')
      .setValue(this.formatarData(this.cadastroUsuario.Pessoal.DadosBasicos.DataNascimento.ToDate));
    this.formDadosPessoais.get('emailDadosPessoais').setValue(Contato.Email1);
    this.formDadosPessoais
      .get('celularDadosPessoais')
      .setValue(
        Contato.DDDCelular.length > 0
          ? Contato.DDDCelular.trim() == '0' || Contato.DDDCelular.trim() == ''
            ? ''
            : Contato.DDDCelular + Contato.Celular
          : ''
      );
    this.formDadosPessoais
      .get('telefoneDadosPessoais')
      .setValue(
        Contato.DDDTelefone.length > 0
          ? Contato.DDDTelefone.trim() == '0' || Contato.DDDTelefone.trim() == ''
            ? ''
            : Contato.DDDTelefone + Contato.Telefone
          : ''
      );
    this.formDadosPessoais
      .get('sexoDadosPessoais')
      .setValue(DadosBasicos.Sexo ? DadosBasicos.Sexo.Valor.toString() : 0);
    this.formDadosPessoais
      .get('vinculoDadosPessoais')
      .setValue(InformacoesBasicas.Vinculo ? InformacoesBasicas.Vinculo.Codigo : 0);
    if (this.cadastroUsuario.Stiuacao.Codigo != 98) {
      this.formDadosPessoais.get('vinculoDadosPessoais').disable();
    }
    this.formDadosPessoais
      .get('sindicatoDadosPessoais')
      .setValue(InformacoesBasicas.Sindicato ? InformacoesBasicas.Sindicato.Codigo : 0);
    this.formDadosPessoais
      .get('estadoCivilDadosPessoais')
      .setValue(Pessoal.DadosBasicos.EstadoCivil ? Pessoal.DadosBasicos.EstadoCivil.Valor : 0);
    this.formDadosPessoais
      .get('nomeMaeDadosPessoais')
      .setValue(this.cadastroUsuario.Pessoal.DadosBasicos.NomeMae);
    this.formDadosPessoais
      .get('nomePaiDadosPessoais')
      .setValue(this.cadastroUsuario.Pessoal.DadosBasicos.NomePai);
    this.formDadosPessoais
      .get('nacionalidadeDadosPessoais')
      .setValue(this.cadastroUsuario.Pessoal.DadosBasicos.Nacionalidade.Codigo);

    this.formDadosPessoais
      .get('cargaHorariaDadosPessoais')
      .setValue(this.cadastroUsuario.InformacoesBasicas.HorarioTrabalho.CargaHorariaSemana);
    this.formDadosPessoais
      .get('tipoSangue')
      .setValue(this.cadastroUsuario.InformacoesBasicas.TipoSanguineo);
    this.formDadosPessoais
      .get('GrauDadosPessoais')
      .setValue(this.cadastroUsuario.Pessoal.DadosBasicos.GrauDeInstrucao.Valor);

    this.formDadosPessoais
      .get('repousoDadosPessoais')
      .setValue(this.cadastroUsuario.InformacoesBasicas.Descanso);

    this.formDadosPessoais
      .get('horarioDadosPessoais')
      .setValue(
        this.formatarCodigoHorario(
          this.cadastroUsuario.InformacoesBasicas.HorarioTrabalho.HoraEntrada
        )
      );

    this.formDadosPessoais
      .get('diasUteisDadosPessoais')
      .setValue(this.cadastroUsuario.InformacoesBasicas.DiasUteisColaborador);

    this.formDadosPessoais
      .get('tipoFolhaDadosPessoais')
      .setValue(this.cadastroUsuario.InformacoesBasicas.TipoFolha);

    this.formDadosPessoais
      .get('corDadosPessoais')
      .setValue(
        Pessoal.DadosBasicos.Etnia.Descricao == 'NÃO INFORMADA' ||
          Pessoal.DadosBasicos.Etnia.Descricao == ''
          ? 0
          : Pessoal.DadosBasicos.Etnia.Descricao
      );
    if (this.cadastroUsuario.Pessoal.DadosBasicos.Naturalidade.Municipio.Estado.Codigo == 0) {
    }
    this.formDadosPessoais
      .get('ufDadosPessoais')
      .setValue(
        this.cadastroUsuario.Pessoal.DadosBasicos.Naturalidade.Municipio.Estado.Codigo == 0
          ? ''
          : this.cadastroUsuario.Pessoal.DadosBasicos.Naturalidade.Municipio.Estado.Codigo
      );
    this.buscarCidade();
    this.formDadosPessoais
      .get('cidadeDadosPessoais')
      .setValue(
        this.cadastroUsuario.Pessoal.DadosBasicos.Naturalidade.Municipio.Codigo == 0
          ? ''
          : this.cadastroUsuario.Pessoal.DadosBasicos.Naturalidade.Municipio.Codigo
      );
  }

  exibirDescricaoParametro(lista: any[], parametro: string, tipoSanguineo?: boolean) {
    if (lista && lista.length > 0) {
      const objParametro = lista.find((tipo) => {
        const parametroTipo = tipoSanguineo ? tipo.Descricao : tipo.Codigo;
        return parametro === parametroTipo;
      });
      return objParametro.Descricao;
    }
    return '';
  }

  onNacionalidadeChange() {
    if (this.formDadosPessoais.get('nacionalidadeDadosPessoais').value != 10) {
      this.formDadosPessoais.get('ufDadosPessoais').clearValidators();
      this.formDadosPessoais.get('cidadeDadosPessoais').clearValidators();
      // this.formDadosPessoais.removeControl('ufDadosPessoais');
      // this.formDadosPessoais.removeControl('cidadeDadosPessoais');
    } else {
      const ufControl = this.fb.control('', Validators.required);
      if (this.formDadosPessoais.get('ufDadosPessoais')) {
        this.formDadosPessoais.addControl('ufDadosPessoais', ufControl);
      }
      if (this.formDadosPessoais.get('cidadeDadosPessoais')) {
        this.formDadosPessoais.addControl('cidadeDadosPessoais', ufControl);
      }
      this.formDadosPessoais.get('ufDadosPessoais').setValidators([Validators.required]);
      this.formDadosPessoais.get('cidadeDadosPessoais').setValidators([Validators.required]);
    }
    this.formDadosPessoais.get('ufDadosPessoais').updateValueAndValidity();
    this.formDadosPessoais.get('cidadeDadosPessoais').updateValueAndValidity();
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}
