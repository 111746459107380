import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AcessoService } from '../../../service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';


@Injectable({
  providedIn: 'root'
})
export class QuadroBeneficiosService {
  localStorage: LocalStorage;
  url_acesso:string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  filial: string;
  codTarefa = "000000000";
  tpProcessamento = "EM";

  constructor(private http: HttpClient, private acesso: AcessoService) { 
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.url_acesso = this.acesso.validarUrl();
    }
  }
  
  listarBeneficios(filtro:any = null){
    if(filtro == null){
      return this.http.post(`${this.url_acesso}beneficios/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`,null,{headers:this.cabecalho});
    }
    return this.http.post(`${this.url_acesso}beneficios/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`, filtro,{headers:this.cabecalho});

  }
  listarComboGrupo(){
    return this.http.get(`${this.url_acesso}beneficioscombogrupo/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`,{headers:this.cabecalho});
  }

  
}
