<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div *ngIf="!telaFormulario" class="content d-flex justify-content-center align-items-center">
  <form class="login-form" #telainicial="ngForm" [formGroup]="formTelaInicial">
    <div class="card  bg-dark mb-0 text-ligth">
      <div class="card-body">
        <div class="text-center mb-3">
          <h5 class="mb-0">Tipo Usuario</h5>
        </div>
        <div class="s-12 col-sm-12 col-md-12 col-lg-12 form-group">
          <label class="justify-content-start">Serviço</label>
          <select class="form-control" type="text" formControlName="codServico_form"
            [ngStyle]="((temErro || formTelaInicial.controls['codServico_form'].dirty) && formTelaInicial.controls['codServico_form'].status == 'INVALID') ? {'border-color':'red'} : null">
            <option value=""> Selecione...</option>
            <option *ngFor="let servico of listaServicos" value="{{servico.Codigo}}"> {{servico.Descricao}}</option>
          </select>
          <span class="form-text text-danger" *ngIf="formTelaInicial.get('codServico_form').invalid && temErro">
            Campo Obrigatório
          </span>
          <span class="form-text text-danger"
            *ngIf="formTelaInicial.value.codServico_form.length > 0 && formTelaInicial.get('codServico_form').hasError('codServicoError')">
            Código inválido
          </span>
        </div>
        <div class=" text-center">
          <button type="button" (click)="buscarTarefasPorCodigo()" class="btn btn-primary">Buscar</button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="card" *ngIf="telaFormulario">
  <div class="card-header">
    <h3 class="card-title" id="target"><i class="fa fa-arrow-left pr-3 btn" aria-hidden="true"
      (click)="retornarTelaInicial()" style="cursor: pointer; bottom: 2px;"></i> Tipo de Usuário</h3>
  </div>
  <form [formGroup]="formTipoUsuario" #tiposUsuario="ngForm" (ngSubmit)="submitTipoUsuario()">
    <div class="card-body">
      <div class="row">
        <div *ngIf="editar" class="col-md-2 form-group">
          <label class="font-weight-semibold">Código</label>
          <input formControlName="codigo" id="codigo" type="text" mask="0*" class="form-control" maxlength="3"
            [ngStyle]="formTipoUsuario.get('codigo').invalid && (formTipoUsuario.get('codigo').dirty || temErro) ? {'border-color': 'red'} : null ">
          <span *ngIf="formTipoUsuario.get('codigo').hasError('required') && temErro" class="form-text text-danger">
            Campo obrigatório </span>
        </div>
        <div [ngClass]="editar ? 'col-md-2': 'col-md-2'" class=" form-group">
          <label class="font-weight-semibold">Tipo do Usuário</label>
          <input formControlName="tipo" type="text" class="form-control" placeholder="Tipo" maxlength="100"
            [ngStyle]="formTipoUsuario.get('tipo').invalid && (formTipoUsuario.get('tipo').dirty || temErro) ? {'border-color': 'red'} : null ">
          <span *ngIf="formTipoUsuario.get('tipo').hasError('required') && temErro" class="form-text text-danger">
            Campo obrigatório </span>
        </div>
        <div class="col-md-4 form-group">
          <label class="font-weight-semibold">Descrição do tipo</label>
          <div class="form-group">
            <input formControlName="descricao" rows="3" cols="10" class="form-control" placeholder="Descrição do tipo"
              maxlength="200" type="text"
              [ngStyle]="formTipoUsuario.get('descricao').invalid && (formTipoUsuario.get('descricao').dirty || temErro) ? {'border-color': 'red'} : null ">
            <span *ngIf="formTipoUsuario.get('descricao').hasError('required') && temErro"
              class="form-text text-danger">
              Campo obrigatório </span>
          </div>
        </div>
        <div class="col-md-10 col-lg-2 form-group">
          <div class="text-center" style="white-space:nowrap">
            <label class="font-weight-semibold">Vincular Tarefa</label><br>
            <button type="button"
              class="btn btn-secondary btn-circle" data-target="#configuracaoUsuario" data-toggle="modal"
              data-target="#configuracaoTarefa"
              style=" width: 55px;height: 33px;text-align: center;padding: 6px 0;font-size: 14px;line-height: 1.428571429;border-radius: 16px;">
              <i class="fa fa-sitemap fa-lg"></i>
            </button>
          </div>
        </div>
        <div class="col-md-10 col-lg-2 form-group" *ngIf="editar">
          <div class="text-center" style="white-space:nowrap">
            <label class="font-weight-semibold">Tarefas Vinculadas</label><br>
            <button class="btn bg-teal-400 text-right" type="button"
              data-target="#tarefasUsuarioModal" data-toggle="modal" data-target="#tarefasUsuarioModal">
              <i class="fa fa-tasks"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-right">
      <button *ngIf="tarefaSelecionada.length != 0 || formTipoUsuario.valid" type="button" (click)="limparCampos()"
        class="btn btn-danger mr-2">Limpar</button>
      <button type="submit" [disabled]="(tarefaSelecionada == null && !editar) || formTipoUsuario.invalid"
        class="btn btn-primary">Salvar</button>
    </div>
  </form>
</div>
<div class="card" *ngIf="telaFormulario">
  <div class="card-header">
    <h3 class="card-title"> Listagem dos Tipos de Usuário</h3>
  </div>
  <div class="card-body">
    <div class="input-group col-lg-12 mb-2">
      <input type="text" class="form-control " name="searchText" value="" placeholder="Pesquisar Usuário"
        (keyup)='filter = $event'>
      <span class="input-group-append">
        <span class="input-group-text"><i class="fa fa-search"></i></span>
      </span>
    </div>

    <div class="row align-baseline">
      <div class=" col-12 form-group">
        <h5 class="font-weight-semibold">Tipos de Usuário Encontrados </h5>
      </div>
    </div>

    <div class="table-responsive" style="max-height:50vh">
      <table *ngIf="listaTipoUsuario && listaTipoUsuario.length > 0"
        class="table table-striped table-hover mb-0 text-center">
        <thead class="text-center">
          <tr class="text-center" style="white-space:nowrap">
            <th class="sticky-top  bg-white">Excluir/Editar</th>
            <th class="sticky-top  bg-white">Código</th>
            <th class="sticky-top  bg-white">Tipo Usuario</th>
            <th class="sticky-top  bg-white">Descrição</th>
          </tr>
        </thead>
        <tbody id="lista-tipoUsuario">
          <tr *ngFor="let tipoUsuario of listaTipoUsuario |filtrarTipoUsaurio: filter" class="text-center">
            <td>
              <div>
                <button class="btn btn-danger mr-2" (click)="deletar(tipoUsuario)">
                  <i class="fa fa-trash"></i>
                </button>
                <button class="btn btn-primary" (click)="buscarTipoUsuario(tipoUsuario)">
                  <i class="fa fa-pencil"></i>
                </button>
              </div>
            </td>
            <td>{{tipoUsuario.CodigoTipoUsuario}}</td>
            <td>{{tipoUsuario.TipoDoUsuario}}</td>
            <td>{{tipoUsuario.Descricao}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!listaTipoUsuario" class="row  d-flex justify-content-center align-items-center mt-3">
      <i class="fa fa-spinner fa-spin fa-2x "></i>
    </div>
  </div>
</div>

<!-- modalFilhos -->
<div class="modal fade" id="configuracaoTarefa" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="justify-content-start mt-2">Tarefa Pai</h5>
        <button type="button" id="closeModal2" (click)="cancelarModal()" class="close"
          data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <button type="button" id="closeModal"  class="close" data-dismiss="modal"
        aria-label="Close">
      </button>
      <div class="modal-body">
        <div class="text-center">
          <div class="col-12 table-responsive text-center">
            <div class="row form-group text-center">
              <table class="table table-striped table-hover mb-0 text-center">
                <thead class="bg-teal-400">
                  <tr class="text-center" style="white-space:nowrap">
                    <th>Selecionar</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Quando o editar estiver inativo -->
                  <tr *ngIf="!editar && listaTodasTarefas.length != 0">
                    <td>
                      <mat-tree *ngIf="TREE_DATA.length != 0" [dataSource]="dataSource" [treeControl]="treeControl"
                        class="example-tree">
                        <!-- qunado não tiver filho -->
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                          <li class="mat-tree-node">
                            <button [disabled]="true" class="btn btn-link" mat-icon-button matTreeNodeToggle
                              [attr.aria-label]="'toggle ' + node.name">
                              <mat-icon class="mat-icon-rtl-mirror">

                              </mat-icon>
                            </button>
                            <mat-checkbox class="checklist-leaf-node" (change)="marcarItemArvore($event.checked,node)"
                              [checked]="node.Checado">{{node.Descricao}}
                            </mat-checkbox>
                          </li>
                        </mat-tree-node>
                        <!-- qunado tiver filho -->
                        <mat-nested-tree-node *matTreeNodeDef="let node; when: temFilho">
                          <li>
                            <div class="mat-tree-node">
                              <button class="btn btn-link" mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'toggle ' + node.name">
                                <mat-icon class="mat-icon-rtl-mirror">
                                  {{treeControl.isExpanded(node) ? 'expand_more' :
                                  'chevron_right'}}
                                </mat-icon>
                              </button>
                              <mat-checkbox [checked]="node.Checado"
                                [indeterminate]="node.indeterminate && !node.Checado"
                                (change)="marcarItemArvore($event.checked,node)">{{node.Descricao}}
                              </mat-checkbox>
                            </div>
                            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                              <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                          </li>
                        </mat-nested-tree-node>
                      </mat-tree>
                    </td>
                  </tr>
                  <!-- Quando o editar estiver ativo-->
                  <tr *ngIf="editar && listaTodasTarefas.length != 0">
                    <td>
                      <!-- qunado não tiver filho -->
                      <mat-tree *ngIf="TREE_DATA_EDIT.length != 0" [dataSource]="dataSourceEdit"
                        [treeControl]="treeControl" class="example-tree">
                        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                          <li class="mat-tree-node">
                            <button [disabled]="true" class="btn btn-link" mat-icon-button matTreeNodeToggle
                              [attr.aria-label]="'toggle ' + node.name">
                              <mat-icon class="mat-icon-rtl-mirror">
                              </mat-icon>
                            </button>
                            <mat-checkbox class="checklist-leaf-node" (change)="marcarItemArvore($event.checked,node)"
                              (change)="selecionarTarefa(node,$event.checked )" [checked]="node.Checado">{{node.Descricao}}
                            </mat-checkbox>
                          </li>
                        </mat-tree-node>
                        <mat-nested-tree-node *matTreeNodeDef="let node; when: temFilho">
                          <li>
                            <!-- qunado tiver filho -->
                            <div class="mat-tree-node">
                              <button class="btn btn-link" mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'toggle ' + node.name">
                                <mat-icon class="mat-icon-rtl-mirror">
                                  {{treeControl.isExpanded(node) ? 'expand_more' :
                                  'chevron_right'}}
                                </mat-icon>
                              </button>
                              <mat-checkbox [checked]="node.Checado"
                                [indeterminate]="node.indeterminate && !node.Checado"
                                (change)="marcarItemArvore($event,node)"
                                (click)="selecionarTarefa(node,$event )">{{node.Descricao}}
                              </mat-checkbox>
                            </div>
                            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                              <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                          </li>
                        </mat-nested-tree-node>
                      </mat-tree>
                    </td>
                  </tr>
                  <tr *ngIf="listaTodasTarefas.length == 0">
                    <td>Esse serviço não possui tarefa(s)</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button (click)="cancelarModal()" type="button" class="btn btn-danger"
          data-dismiss="modal" data-toggle="modal" data-target="#configuracaoTarefa">Cancelar</button>
        <button type="button" [disabled]="tarefaSelecionada.length == 0" (click)="salvarTarefaPai()"
          class="btn btn-primary">Salvar</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="tarefasUsuarioModal" tabindex="-1" role="dialog" aria-labelledby="tarefasUsuarioModalTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header navbar-light bg-light">
        <div class="container">
          <div class="row">
            <div class="col-6">
              <h5 class="justify-content-start mt-2"> Tarefas do Tipo de Usuário</h5>
            </div>
            <button _ngcontent-nbj-c13="" aria-label="Close" class="mb-4 close" data-dismiss="modal" type="button"><span
                _ngcontent-nbj-c13="" aria-hidden="true">×</span></button>
          </div>
          <br>
        </div>
      </div>
      <div class="modal-body  navbar-light bg-light">
        <div class="container">
          <div class="text-center">
            <div class="col-12 table-responsive text-center">
              <div class="row form-group text-center">
                <table *ngIf="tarefasUsuario != null && tarefasUsuario.length != 0 "  class="table table-striped table-hover mb-0 text-center">
                  <thead class="bg-teal-400">
                    <tr class="text-center" style="white-space:nowrap">
                      <th style="text-align:center;">Código</th>
                      <th style="text-align:center;">Descrição</th>
                    </tr> 
                  </thead>
                  <tbody>
                    <tr *ngFor="let tarefa of tarefasUsuario let i = index">
                      <td style="text-align:center;">
                        {{ tarefa.Codigo}}
                      </td>
                      <td style="text-align:center;">
                        {{tarefa.Descricao}}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table *ngIf="tarefasUsuario === null || tarefasUsuario.length == 0 " class="table table-striped table-hover mb-0 text-center">
                  <thead class="bg-teal-400">
                    <tr class="text-center" style="white-space:nowrap">
                      <th style="text-align:center;">Descrição</th>                   
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-center" style="white-space:nowrap">
                      <td td style="text-align:center;">
                          <p>Sem tarefas vinculadas</p> 
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>