import { Component, Input } from '@angular/core';
import { Validators, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { FaltasService } from '../faltas.service';
import Swal from 'sweetalert';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErroService } from 'src/app/service/erros/erro.service';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { Falta, FaltasComponent } from '../faltas.component';
import { Parametro } from 'src/app/service/interfaces/Parametro';
import { dataAtualOuAnteriorValidator } from 'src/app/service/Validators/dataMenorIgualValidator';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-faltas-cadastro',
  templateUrl: './faltas-cadastro.component.html',
  styleUrls: ['./faltas-cadastro.component.css'],
})
export class FaltasCadastroComponent {
  @Input() cadastro: boolean = true;
  @Input() colaborador: Cadastro = null;
  @Input() faltaAtual: Falta = null;
  formFaltas: FormGroup;
  formValues: any;
  listaMotivos: Item[] = [];
  listaAfastamentos: Item[] = [];
  listaClassificacaoAto: Item[] = [];
  listaUF: Parametro[] = [];
  cidAtual: CID;
  buscarFaltas$
  constructor(
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private validaErro: ErroService,
    private faltasService: FaltasService,
    private faltaComponente: FaltasComponent
  ) {}

  ngOnInit() {
    this.formFaltas = this.fb.group({
      Sigla: [''],
      Mat: [null],
      Numord: [''],
      Empfil: [null],
      DtComp: [''],
      TpProc: [''],
      CodAfa: ['', Validators.required],
      CodMot: ['', Validators.required],
      DtInicio: ['', [Validators.required, dataAtualOuAnteriorValidator()]],
      DtVolta: ['', [Validators.required, dataAtualOuAnteriorValidator()]],
      NumeroProcesso: [''],
      CodMed: [''],
      CodMedUF: [''],
      NomeMed: [{ value: '', disabled: true }],
      CodDiag: [''],
      nomeCID: [{ value: '', disabled: true }],
      NumeroDoe: [''],
      IdClassificacaoAto: [''],
      NumeroAto: [''],
      DtAto: ['', dataAtualOuAnteriorValidator()],
      DtDoe: ['', dataAtualOuAnteriorValidator()],
      penalizarFalta: [],
    });
    this.formValues = this.formFaltas.value;
  }

  buscarMotivos() {
    this.listaMotivos = this.faltasService.retornarLista('motivos');
    if (this.listaMotivos.length == 0) {
      this.spinner.show();
      this.faltasService.buscarMotivoAfastamento().subscribe(
        (data: Item[]) => {
          this.listaMotivos = data;
          this.faltasService.inserirLista('motivos', this.listaMotivos);
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.validaErro.retornoErro(err);
        }
      );
    }
  }

  buscarAfastamento() {
    this.listaAfastamentos = this.faltasService.retornarLista('afastamentos');
    if (this.listaAfastamentos.length == 0) {
      this.spinner.show();
      this.faltasService.buscarCodigoAfastamento().subscribe(
        (data: Item[]) => {
          this.listaAfastamentos = data;
          this.faltasService.inserirLista('afastamentos', this.listaAfastamentos);
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.validaErro.retornoErro(err);
        }
      );
    }
  }

  buscarIdClassificacaoAto() {
    this.listaClassificacaoAto = this.faltasService.retornarLista('classificacao');
    if (this.listaClassificacaoAto.length == 0) {
      this.spinner.show();
      this.faltasService.buscarIdClassificacaoAto().subscribe(
        (data: Item[]) => {
          this.listaClassificacaoAto = data;
          this.faltasService.inserirLista('classificacao', this.listaClassificacaoAto);
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.validaErro.retornoErro(err);
        }
      );
    }
  }

  buscarParametros() {
    this.listaUF = this.faltasService.retornarListaUf();
    if (this.listaUF.length == 0) {
      this.spinner.show();
      this.faltasService.buscarParametros().subscribe(
        (data: any) => {
          this.listaUF = data.Estados;
          this.faltasService.inserirListaUf(this.listaUF);
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.validaErro.retornoErro(err);
        }
      );
    }
  }

  buscarMedico() {
    if (
      this.formFaltas.get('CodMed').value != '' &&
      this.formFaltas.get('CodMedUF').value != null
    ) {
      const crmBusca = {
        nome: '',
        crm: this.formFaltas.get('CodMed').value,
        uf: this.formFaltas.get('CodMedUF').value,
        especialidade: '',
      };

      this.faltasService.buscarMedico(crmBusca).subscribe(
        (data) => {
          console.log('busca medico', data);
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }

  buscarCID() {
    if (this.formFaltas.get('CodDiag').value != '') {
      this.faltasService.buscarCIDDoencas(this.formFaltas.get('CodDiag').value).subscribe(
        (data: CID) => {
          console.log('data cid', data);
          this.cidAtual = data;
          if (this.cidAtual != null) {
            this.formFaltas.get('nomeCID').setValue(data.Nome);
          } else {
            this.formFaltas.get('nomeCID').setValue('');
          }
        },
        (err) => {
          console.error(err);
        }
      );
    } else {
      this.formFaltas.get('nomeCID').setValue('');
      this.formFaltas.get('CodDiag').markAsPristine();
    }
  }

  setarFaltaExistente(falta: Falta) {
    //obrigatórios
    this.formFaltas.get('CodAfa').setValue(falta.CodAfa);
    this.formFaltas.get('CodMot').setValue(falta.CodMot);
    this.formFaltas
      .get('DtInicio')
      .setValue(this.faltasService.formatarDataHifen(falta.DtInicio.SetData));
    this.formFaltas
      .get('DtVolta')
      .setValue(this.faltasService.formatarDataHifen(falta.DtVolta.SetData));

    //opcionais
    falta.NumeroProcesso
      ? this.formFaltas.get('NumeroProcesso').setValue(falta.NumeroProcesso)
      : null;
    falta.CodMed ? this.formFaltas.get('CodMed').setValue(falta.CodMed.slice(0, -3)) : null;
    falta.CodMed ? this.formFaltas.get('CodMedUF').setValue(falta.CodMed.slice(-2)) : null;
    falta.CodDiag ? this.formFaltas.get('CodDiag').setValue(falta.CodDiag) : null;
    falta.NumeroDoe ? this.formFaltas.get('NumeroDoe').setValue(falta.NumeroDoe) : null;
    !falta.DtDoe.Empty
      ? this.formFaltas
          .get('DtDoe')
          .setValue(this.faltasService.formatarDataHifen(falta.DtDoe.SetData))
      : null;
    falta.IdClassificacaoAto && falta.IdClassificacaoAto != '0'
      ? this.formFaltas.get('IdClassificacaoAto').setValue(falta.IdClassificacaoAto)
      : null;
    falta.NumeroAto ? this.formFaltas.get('NumeroAto').setValue(falta.NumeroAto) : null;
    !falta.DtAto.Empty
      ? this.formFaltas
          .get('DtAto')
          .setValue(this.faltasService.formatarDataHifen(falta.DtAto.SetData))
      : null;
    falta.ValInf != 0 ? this.formFaltas.get('penalizarFalta').setValue(true) : null;
  }

  montarFalta(falta?: Falta) {
    let faltaMontada = {
      Falta: {
        Sigla: 'FAPP',
        TpProc: 'EM',
        Mat: this.colaborador.Matricula,
        Numord: this.colaborador.NumeroDeOrdem,
        Empfil: this.colaborador.Filial.Codigo,
        DtInicio: {
          SetData: this.formFaltas.get('DtInicio').value.replace(/-/g, ''),
          ToDate: this.faltasService.formatarDataBarra(
            this.formFaltas.get('DtInicio').value.replace(/-/g, '')
          ),
          Empty: false,
          IsDate: true,
        },
        DtVolta: {
          SetData: this.formFaltas.get('DtVolta').value.replace(/-/g, ''),
          ToDate: this.faltasService.formatarDataBarra(
            this.formFaltas.get('DtVolta').value.replace(/-/g, '')
          ),
          Empty: false,
          IsDate: true,
        },
        CodAfa: parseInt(this.formFaltas.get('CodAfa').value),
        CodMot: parseInt(this.formFaltas.get('CodMot').value),

        CodDiag: this.formFaltas.get('CodDiag').value ? this.formFaltas.get('CodDiag').value : '',
        CodMed: this.formFaltas.get('CodMed').value
          ? this.formFaltas.get('CodMed').value + '-' + this.formFaltas.get('CodMedUF').value
          : '',
        NumeroProcesso: this.formFaltas.get('NumeroProcesso').value
          ? this.formFaltas.get('NumeroProcesso').value
          : '',
        NumeroDoe: this.formFaltas.get('NumeroDoe').value
          ? this.formFaltas.get('NumeroDoe').value
          : '',
        IdClassificacaoAto: this.formFaltas.get('IdClassificacaoAto').value
          ? parseInt(this.formFaltas.get('IdClassificacaoAto').value)
          : 0,
        NumeroAto: this.formFaltas.get('NumeroAto').value
          ? this.formFaltas.get('NumeroAto').value
          : '',
        AnoAto: this.formFaltas.get('DtAto').value
          ? this.formFaltas.get('DtAto').value.substring(0, 4)
          : '0000',
        DtAto: this.formFaltas.get('DtAto').value
          ? {
              SetData: this.formFaltas.get('DtAto').value.replace(/-/g, ''),
              ToDate: this.faltasService.formatarDataBarra(
                this.formFaltas.get('DtAto').value.replace(/-/g, '')
              ),
              Empty: false,
              IsDate: true,
            }
          : '00000000',
        AnoDoe: this.formFaltas.get('DtDoe').value
          ? this.formFaltas.get('DtDoe').value.substring(0, 4)
          : '0000',
        DtDoe: this.formFaltas.get('DtDoe').value
          ? {
              SetData: this.formFaltas.get('DtDoe').value.replace(/-/g, ''),
              ToDate: this.faltasService.formatarDataBarra(
                this.formFaltas.get('DtDoe').value.replace(/-/g, '')
              ),
              Empty: false,
              IsDate: true,
            }
          : '00000000',
        DtComp: '',
        Seq: falta ? falta.Seq : 0,
      },
      LancamentoFinanceiro: {
        Sigla: '',
        Empfil: this.colaborador.Filial.Codigo,
        Numord: '',
        Mat: 0,
        Vrb: 0,
        Trans: 0,
        ValInf:
          this.formFaltas.get('penalizarFalta').value == true
            ? parseInt(
                this.calcularDias(
                  this.formFaltas.get('DtInicio').value.replace(/-/g, ''),
                  this.formFaltas.get('DtVolta').value.replace(/-/g, '')
                )
              )
            : 0,
        Refer: '',
        Data: '',
        ValInfa: 0,
      },
    };

    return faltaMontada;
  }

  lancarFalta(falta?: Falta) {
    this.formFaltas.markAllAsTouched();
    if (this.formFaltas.valid) {
      this.spinner.show('faltas');

      if (this.verificaData(this.formFaltas.get('DtInicio'), this.formFaltas.get('DtVolta'))) {
        let botaoFechar = document.getElementById('botaoFechar');
        console.log(this.formFaltas);
        if (falta) {
          let faltaLancar = this.montarFalta(falta);
          console.log(this.formFaltas.value);
          this.faltasService.atualizarFalta(faltaLancar).subscribe(
            (data) => {
              Swal('Sucesso', 'Falta atualizada com sucesso.', 'success');
              this.faltaComponente.buscarFaltas();
              botaoFechar.click();
              this.spinner.hide('faltas');
            },
            (err: HttpErrorResponse) => {
              if (err.status === 500) {
                Swal('Erro', 'Não foi possível atualizar essa falta.', 'error');
                this.spinner.hide('faltas');
              } else {
                const errorMessage =
                  err.error?.Mensagem || 'Ocorreu um erro ao atualizar essa falta.';
                Swal('Erro', errorMessage, 'error');
                this.spinner.hide('faltas');
              }
            }
          );
        } else {
          let faltaLancar = this.montarFalta();
          this.faltasService.lancarFalta(faltaLancar).subscribe(
            (data) => {
              Swal('Sucesso', 'Falta lançada com sucesso.', 'success');
              this.faltaComponente.buscarFaltas();
              botaoFechar.click();
              this.spinner.hide('faltas');
            },
            (err: HttpErrorResponse) => {
              if (err.status === 500) {
                Swal('Erro', 'Não foi possível inserir essa falta.', 'error');
                this.spinner.hide('faltas');
              } else {
                const errorMessage =
                  err.error?.Mensagem || 'Ocorreu um erro ao atualizar essa falta.';
                Swal('Erro', errorMessage, 'error');
                this.spinner.hide('faltas');
              }
            }
          );
        }
      }
    }
  
    else {
      Swal('Atenção', 'Por favor, preencha todos os campos corretamente.', 'warning');
      this.spinner.hide('faltas');
    }
  }

  limparForm() {
    this.formFaltas.reset();
    this.formFaltas.patchValue(this.formValues);
  }

  calcularDias(dataInicio: string, dataVolta: string): string {
    return this.faltasService.calcularDias(dataInicio, dataVolta);
  }

  verificaData(dataInicial: AbstractControl, dataFinal: AbstractControl): boolean {
    if (dataInicial.value && dataFinal.value) {
      const dataInicialDate = new Date(dataInicial.value);
      const dataFinalDate = new Date(dataFinal.value);

      if (dataInicialDate >= dataFinalDate) {
        dataFinal.setValue('');
        Swal('Atenção', 'A data final deve ser posterior à inicial.', 'warning');
        this.spinner.hide('faltas');
        return false;
      } else {
        return true;
      }
    }
  }
}

export interface Item {
  Cod: string;
  Nome: string;
}

export interface LancamentoFinanceiro {
  LancamentoFinanceiro: {
    Sigla: string;
    Empfil: number;
    Numord: string;
    Mat: number;
    Vrb: number;
    Trans: number;
    ValInf: number;
    Refer: string;
    Data: string;
    ValInfa: number;
  };
}
export interface CID {
  Categoria: string;
  Codigo: string;
  Nome: string;
}
