<div class="container">
  <div class="row">
    <div class="col-md-12">
      <app-pesquisar-matricula
        *ngIf="matricula == null"
        [titulo]="'Cadastro WhatsApp'"
        [inputText]="true"
        (colaboradorEmitter)="matriculaListener($event)"
      ></app-pesquisar-matricula>

      <section class="container" *ngIf="matricula != null">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title font-weight-semibold">
              <i
                class="fa fa-arrow-left pr-3 btn"
                aria-hidden="true"
                (click)="retornarTelaInicial()"
                style="cursor: pointer; bottom: 2px"
              ></i
              >Cadastro de Dados
            </h3>
          </div>
          <div class="card-body">
            <form class="row" [formGroup]="formColaborador">
              <div class="col-md-2 form-group">
                <label class="font-weight-semibold">Matrícula</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Matrícula"
                  mask="0*"
                  formControlName="matricula"
                />
                <span class="form-text" *ngIf="loading"
                  >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
                ></span>
              </div>

              <div class="col-md-10 form-group">
                <label class="font-weight-semibold">Nome do Colaborador</label>
                <input type="text" class="form-control" placeholder="Nome" formControlName="nome" />
              </div>
            </form>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="cadastro-tab"
                  data-toggle="tab"
                  href="#cadastro"
                  role="tab"
                  aria-controls="cadastro"
                  aria-selected="true"
                  >Cadastro</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="verificacao-tab"
                  data-toggle="tab"
                  href="#verificacao"
                  role="tab"
                  aria-controls="verificacao"
                  aria-selected="false"
                  >Verificação</a
                >
              </li>
            </ul>
          </div>

          <div class="card-body">
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="cadastro"
                role="tabpanel"
                aria-labelledby="cadastro-tab"
              >
                <form [formGroup]="formCadastroWhatsapp" class="form-row">
                  <div class="form-group col-md-6">
                    <label>Número de WhatsApp</label>

                    <div class="input-group">
                      <div class="input-group-prepend">
                        <select
                          class="form-control"
                          style="max-width: 150px"
                          formControlName="prefix"
                          name="prefix"
                          [ngClass]="
                            (formCadastroWhatsapp.get('prefix').invalid ||
                              formCadastroWhatsapp.get('prefix').value == null) &&
                            formCadastroWhatsapp.get('prefix').touched
                              ? 'is-invalid'
                              : null
                          "
                        >
                          <option value="+55">+55 (Brasil)</option>
                          <option value="+44">+44 (Reino Unido)</option>
                          <option value="+33">+33 (França)</option>
                          <option value="+49">+49 (Alemanha)</option>
                          <option value="+39">+39 (Itália)</option>
                          <option value="+34">+34 (Espanha)</option>
                          <option value="+91">+91 (Índia)</option>
                          <option value="+81">+81 (Japão)</option>
                          <option value="+86">+86 (China)</option>
                        </select>
                      </div>
                      

                      <input
                        type="text"
                        class="form-control"
                        mask="(00) 00000-0000"
                        placeholder="Digite seu Número"
                        formControlName="NumMovel"
                        name="whatsappNumber"
                        [ngClass]="
                          (formCadastroWhatsapp.get('NumMovel').invalid ||
                            formCadastroWhatsapp.get('NumMovel').value == null) &&
                          formCadastroWhatsapp.get('NumMovel').touched
                            ? 'is-invalid'
                            : null
                        "
                      />
                    </div>

                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (formCadastroWhatsapp.get('NumMovel').invalid ||
                          formCadastroWhatsapp.get('NumMovel').value == null) &&
                        formCadastroWhatsapp.get('NumMovel').touched
                      "
                    >
                      {{
                        formCadastroWhatsapp.get('NumMovel').hasError('required')
                          ? 'Campo Obrigatório'
                          : 'Número Inválido'
                      }}
                    </span>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Email</label>
                    <input
                      maxlength="80"
                      type="email"
                      class="form-control"
                      placeholder="Digite seu Email"
                      formControlName="email"
                      name="email"
                      [ngClass]="
                        (formCadastroWhatsapp.get('email').invalid ||
                          formCadastroWhatsapp.get('email').value == null) &&
                        formCadastroWhatsapp.get('email').touched
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (formCadastroWhatsapp.get('email').invalid ||
                          formCadastroWhatsapp.get('email').value == null) &&
                        formCadastroWhatsapp.get('email').touched
                      "
                    >
                      {{
                        formCadastroWhatsapp.get('email').hasError('required')
                          ? 'Campo Obrigatório'
                          : 'Email Inválido'
                      }}
                    </span>
                  </div>
                  <button class="btn btn-primary mt-2" (click)="lancarCadastroWhatsapp()">
                    Salvar
                  </button>
                </form>
              </div>

              <div
                class="tab-pane fade"
                id="verificacao"
                role="tabpanel"
                aria-labelledby="verificacao-tab"
              >
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Verificar Número</label>
                    <input
                      type="text"
                      class="form-control"
                      [placeholder]="NumMovelPlaceholder"
                      disabled
                    />
                    <button
                      [ngClass]="{
                        'btn-danger': NumMovelValidado === 'N',
                        'btn-success': NumMovelValidado === 'S'
                      }"
                      class="btn mt-2"
                      [disabled]="NumMovelValidado === 'S'"
                      [disabled]="NumMovelValidado === 'N'"
                    >
                      {{ NumMovelValidado === 'N' ? 'Verifique' : 'Verificado' }}
                    </button>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Verificar Email</label>
                    <input
                      type="email"
                      class="form-control"
                      [placeholder]="emailPlaceholder"
                      disabled
                    />
                    <button
                      [ngClass]="{
                        'btn-danger': EmailValidado === 'N',
                        'btn-success': EmailValidado === 'S'
                      }"
                      class="btn mt-2"
                      [disabled]="EmailValidado === 'S'"
                      [disabled]="EmailValidado === 'N'"
                    >
                      {{ EmailValidado === 'N' ? 'Verifique' : 'Verificado' }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
