export class Salario{
    Sigla: string;
    DataCompetencia:  string;
    TipoProcessamento: string;
    CodigoNivel:  string;
    NomeNivel: string;
    Valor: number;
    ValorC:number;
    ValorA:number;
    Flag01: string;
    Flag02: string;
    Flag03: string;
    Flag04: string;
    Flag05: string;
    Flag06: string;
    Flag07: string;
    Flag08: string;
    Flag09: string;
    Flag10: string;
    ClasseSalarial: number;
    SequencialNivel: string;
    Ordem: number;
    ClasseSalarialX:string;
    CodigoCargo: string;
    NomeCargo: string;
}