import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Local } from 'protractor/built/driverProviders';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { Anexo, Arquivo } from './assinatura-anexo.component';

@Injectable({
  providedIn: 'root'
})
export class AssinaturaAnexoService {
  localStorage: LocalStorage;
  urlAcesso: string;
  headers = new HttpHeaders();
  filial: string;
  cabecalho = new HttpHeaders();

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.urlAcesso = this.acesso.validarUrl();
    }
  }


  salvarAnexo(anexos: Anexo[]) {
  try{
    return this.http.post(`${this.urlAcesso}lancamento-fluxo/assinatura`, anexos, { headers: this.cabecalho });

  }catch{
  }

    
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }
}
