import { AbstractControl,  ValidatorFn  } from '@angular/forms';

export function celularValidator(control: AbstractControl): { [key: string]: boolean } | null { 
    if(control.value === "")
    {
      return null;
    }
    if(control.value.length != 11){
        return({'celular': true});
    }
    return null;
};
   
    




   


    
