import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'cnpj'
})
export class CnpjPipe implements PipeTransform {
  transform(cnpj: string): any {
    cnpj = cnpj.padStart(14, '0');
    cnpj = `${cnpj.substr(0, 2)}.${cnpj.substr(2, 3)}.${cnpj.substr(5, 3)}/${cnpj.substr(8, 4)}-${cnpj.substr(12, 2)}`
    return cnpj
  }
}
