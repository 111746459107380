import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
    providedIn: 'root'
  })
  export class FeriasSetorService {
    localStorage: any;
    url_acesso = this.acesso.validarUrl();
    headers = new HttpHeaders();
    filial = localStorage.getItem('filial_atual');
    cabecalho = this.headers.append('sessao', localStorage.getItem('sessao'));
  
    constructor(private http: HttpClient, private acesso: AcessoService) {
      if (localStorage.getItem("util") != null) {
        this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
        this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
        this.filial = this.localStorage.FilialAtual.toString();
        this.url_acesso = this.acesso.validarUrl();
      }
    }

    buscarFeriasSetor(){
      this.url_acesso = this.acesso.validarUrl();
      return this.http.get(`${this.url_acesso}lotacao-ativa/${this.filial}`, {headers:this.cabecalho});
    }

    ativarFerias(relatorioFerias: object) {
      this.url_acesso = this.acesso.validarUrl();
      return this.http.post(`${this.url_acesso}ferias/setor/0/EF/${this.filial}/`, relatorioFerias, {headers:this.cabecalho});
    }
}
  