import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {dataValidator} from '../../service/Validators/dataValidator';

import { TransferenciaFuncionarioService } from './transferencia-funcionario.service';
import {Filial} from './classes/classes.component';
import { ErroService} from '../../service/erros/erro.service';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert';
import { Departamento } from 'src/app/service/classes/cadastro/InformacoesBasicas/Departamento';
import { Setor } from 'src/app/service/classes/cadastro/InformacoesBasicas/Cargo/Setor';
import { ActivatedRoute, Router } from '@angular/router';
import { Data } from 'src/app/service/interfaces/Data';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';



function retornarDataAtual() : string
{
  const data = Date.now();
  const unixtime = data.valueOf();
  let dataTratada = new Date(unixtime).toISOString().substr(0, 10);
  return dataTratada;
}

function tratarData(data: string) : string
{
  data = data.trim();
  let ano = data.substr(4, 4);
  let mes = data.substr(2, 2);
  let dia = data.substr(0, 2);
  return dia + "/" + mes + "/" + ano
}

@Component({
  selector: 'app-transferencia-funcionario',
  templateUrl: './transferencia-funcionario.component.html',
  styleUrls: ['./transferencia-funcionario.component.css']
})


export class TransferenciaFuncionarioComponent implements OnInit {
  cadastroUsuario: Cadastro = new Cadastro();
  objetoRetorno: any;
  temErro:boolean= false ;
  transferencias: Transferencia[] = [];
  filiais: Filial[] = [];
  departamentos: Departamento[] = [];
  lotacoes: Setor[] = [];
  formTransferencia: any;
  formBuscarColaborador: UntypedFormGroup;
  dataDestino: string;
  statusEqual: number = 0;
  codigo: number = 0;
  loading:boolean = true;
  rotaParametro: any;
  spinner_pesquisa_funcionario: boolean = false;
  spinner_pesquisa_lotacao: boolean = false;
  buscaMatricula: boolean = false;
  exibirContinuar: boolean = false;
  telaTransferencia: boolean = false;
  constructor(private myRoute: Router, private formBuilder: UntypedFormBuilder,private http: HttpClient,private transferenciaService : TransferenciaFuncionarioService,private validaErro:ErroService, private spinner: NgxSpinnerService,
    private route: ActivatedRoute) {
    this.route.params.subscribe(params => this.rotaParametro = params['numOrdem']);
    
  }

 ngOnInit(){   
   window.scrollTo(0, 0);
   this.spinner.show();
   this.dataDestino = retornarDataAtual() ;
   this.buscarFiliais()
   this.formTransferencia = this.formBuilder.group({
     empfilOrigem:     ['',  Validators.compose([ Validators.required])],
     nomeOrigem:       ['',  Validators.compose([ Validators.required])],
     matriculaOrigem:  ['',  Validators.compose([ Validators.required])],
     empfilDestino:    ['',  Validators.compose([ Validators.required])],
     lotacaoDestino:   ['',  Validators.compose([ Validators.required])],
     dataEventoDestino:['',  Validators.compose([ Validators.required])]
   });
   this.formTransferencia.get('matriculaOrigem').disable();
   this.formTransferencia.get('nomeOrigem').disable();
   this.formBuscarColaborador = this.formBuilder.group({
    matricula: ['', [Validators.required]],
  });

   //setInterval(() => { this.formTransferencia.get("dataEventoDestino").setValue((document.getElementById("dataEventoDestino") as HTMLInputElement).value); }, 1000);
//this.buscarCadastro()
 }

  buscarFiliais(): void{
    this.transferenciaService.buscarFiliais()
    .subscribe(
      data  => {
        this.filiais = JSON.parse(JSON.stringify(data));
        this.buscarCadastro()
        this.spinner.hide();
       
      },
      error  => { 
        this.spinner.hide();
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
      }
    );
  }
  
  buscarLotacao(): void{
    var empfil = this.formTransferencia.get("empfilDestino").value
    this.formTransferencia.controls['lotacaoDestino'].setValue("")
    if(empfil !=""){
      this.spinner_pesquisa_lotacao = true
      this.transferenciaService.buscarDepartamento(empfil)
      .subscribe(
        data  => {
          this.departamentos = JSON.parse(JSON.stringify(data));
          this.spinner_pesquisa_lotacao = false   
        },
        error  => { 
          this.spinner_pesquisa_lotacao = false   
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
    }
  } 

  buscarCadastro(): void | boolean {
    if(this.rotaParametro != undefined ){
    this.transferenciaService.buscarCadastro(this.rotaParametro)
      .subscribe(
        data => {
          this.cadastroUsuario = JSON.parse(JSON.stringify(data));
          this.setarValores();
          this.exibirContinuar = true;
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');

        }
      );
    }if(this.buscaMatricula){
      if(this.formBuscarColaborador.controls['matricula'].status == "INVALID"){
        new AlertaModal('alerta',"Atenção", "Digite uma matrícula válida.",'p')
        this.formBuscarColaborador.controls['matricula'].setValue("");
        this.buscaMatricula= false
        return false;
  
      }
      this.spinner_pesquisa_funcionario = true;
      this.spinner.show();

      this.transferenciaService.buscarColaborador(this.formBuscarColaborador.value.matricula)
      .subscribe(
        data => {
         this.spinner_pesquisa_funcionario = false 
         this.buscaMatricula= false
         this.cadastroUsuario = JSON.parse(JSON.stringify(data));
         this.setarValores();
         this.exibirContinuar = true;
         this.spinner.hide();
        },
        error => {
          this.buscaMatricula= false
          this.spinner_pesquisa_funcionario = false;
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.formBuscarColaborador.controls['matricula'].setValue("");
          this.spinner.hide();
        }
      )
    
    }
    
  }


  buscarCadastroPorMatricula(): void{
    this.buscaMatricula = true;
    this.buscarCadastro()
  }

  setarValores(): void{
    this.formTransferencia.controls['nomeOrigem'].setValue(this.cadastroUsuario.InformacoesBasicas.NomeCompleto)
    this.formTransferencia.controls['matriculaOrigem'].setValue(this.cadastroUsuario.Matricula)
    const empresaFilial = this.filiais.find(param => param.Codigo.toString() == this.cadastroUsuario.Filial.Codigo)
    this.formTransferencia.controls['empfilOrigem'].setValue(empresaFilial.Codigo + " - " + empresaFilial.NomeCompleto)
  }

  limparFormulario() {
    this.formTransferencia.reset({
      matricula:       '',         
     empfilOrigem:     '',
     nomeOrigem:       '',
     matriculaOrigem:  '',
     empfilDestino:    '',
     lotacaoDestino:   '',
     dataEventoDestino:''
    });
    this.temErro = false;

    if(this.telaTransferencia) {
      this.setarValores();
    }
  }

  avancarFormTransferencia() {
    this.spinner.show();
    this.telaTransferencia = true;
    this.limparFormulario();
    // this.limparFormularioBuscarColaborador();
    // this.listarHistoricoTransferencias(this.dadosColaborador.Matricula);
    // this.carregarCampos();
    this.spinner.hide();
  }

  limparBuscaColaboradores() {
    this.formBuscarColaborador.reset(
      {
        matricula: ''
      }
    );
  }

  voltarTelaBuscarColaborador() {
    this.telaTransferencia = false;
    this.limparFormulario();
    this.limparBuscaColaboradores();
    this.cadastroUsuario = new Cadastro();
    this.temErro = false;
    this.exibirContinuar = false;
  }

  transferir():boolean{
     if(this.formTransferencia.invalid){
      new AlertaModal('alerta',"Atenção", "Preencha todos os campos corretamente",'p')
      this.temErro = true;
      return false;
     }

  
    this.spinner.show();
    const tranferencia =  this.montarTransferencia();
   
     this.transferenciaService.transferir(tranferencia)
    .subscribe(
      data => {
        if(data){
          this.spinner.hide();
          this.objetoRetorno = JSON.parse(JSON.stringify(data));
          Swal({
            title: "O Colaborador foi transferido com sucesso",
            text: "Sua matricula é: " + this.objetoRetorno.NumMatricula,
            icon: "success",
            dangerMode: false,
            closeOnClickOutside: false,
            buttons:{
              confirm: {
                text:"Ok",
                value:true,
                visible: true
              }
            }
          })
          .then(willDelete => {
            console.log("entrou");
            if (willDelete) {
              if(this.rotaParametro != undefined){       
                console.log("entrou no if");               
                this.myRoute.navigate(['colaboradores'])
              }else{               
                console.log("entrou no else");
                this.voltarTelaBuscarColaborador();
                this.limparCampos();
              }
            }   
          });
        }
      },
      error =>  {
        new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        this.spinner.hide();
          
      }
    );
  }

  montarTransferencia(): Transferencia{
    const transferencia   =  this.formTransferencia.getRawValue()
    return{
    Codigo: "",
    CodigoOrigem: "",
    CodigoDestino: "",
    Nome: transferencia.nomeOrigem,
    EmpfilOrigem : this.cadastroUsuario.Filial.Codigo,
    MatriculaOrigem: transferencia.matriculaOrigem,
    MatriculaDestino :"",
    EmpfilDestino  : transferencia.empfilDestino,
    DataEvento :{
      SetData:  transferencia.dataEventoDestino != "" ? this.formatarDataEnvio(transferencia.dataEventoDestino) : "00/00/0000",
    },
    LotacaoDestino: transferencia.lotacaoDestino,
    DigitoMatriculaDestino: ""
  }
  }

  formatarDataEnvio(data: string): string {
    let aux = data.split('-')
    return `${aux[0]}${aux[1]}${aux[2]}`
  }

  // alterarValidacaoMatricula():void{
  //   this.formTransferencia.controls['matricula'].setValidators(null);
  //   this.formTransferencia.controls["matricula"].updateValueAndValidity();
  // }

  limparCampos(){
    this.formTransferencia.controls['empfilOrigem'].setValue("");
    this.formTransferencia.controls['nomeOrigem'].setValue("");
    this.formTransferencia.controls['matriculaOrigem'].setValue("");
    this.formTransferencia.controls['empfilDestino'].setValue("");
    this.formTransferencia.controls['lotacaoDestino'].setValue("");
    this.formTransferencia.controls['dataEventoDestino'].setValue("");   
  }
  
  formataData(data:string): string{
    data = data.split("/").join("");
    data = `${data.substr(0,2)}/${data.substr(2,2)}/${data.substr(4)}`
    return data;
  }

  apenasNumeros(evento: any) {
    return (evento.charCode >= 48 && evento.charCode <= 57);
  }
}

export interface Transferencia{
  Codigo:string
  CodigoOrigem:string
  CodigoDestino:string;
  Nome:string;
  EmpfilOrigem : string;
  MatriculaOrigem: string;
  MatriculaDestino :string;
  EmpfilDestino : number;
  LotacaoDestino : string;
  DataEvento:Data 
  DigitoMatriculaDestino: string
}
