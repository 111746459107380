<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="card">
    <div class="card-header">
        <h3 class="card-title" id="target">Aviso</h3>
    </div>

    <form [formGroup]="formAvisos">
        <div class="card-body">
            <div class="row">
                <div class="col-md-6 form-group">
                    <label class="font-weight-semibold">Tempo de Exibição</label>
                    <div class="input-group">
                        <span class="input-group-prepend">
                            <span class="input-group-text"><i class="icon-calendar22"></i></span>
                        </span>
                        <input formControlName="prazo" id="setpicker" type="text" class="form-control daterange-time"
                            value="{{formatDate}}" placeholder="Selecione o Periodo..."
                            [ngStyle]=" temErro && formAvisos.controls['prazo'].invalid ? {'border-color':'red'}: null">
                    </div>
                    <p *ngIf="temErro && formAvisos.controls['prazo'].invalid" style="color: red; font-size: 8.6px;">
                        Campo Obrigatorio
                    </p>
                </div>
                <div class="col-md-6 form-group">
                    <label class="font-weight-semibold">Tipo do Aviso</label>
                    <div class="input-group">
                        <select formControlName="tipoAviso" class="form-control">
                            <option value="" disabled>Selecione...</option>
                            <option *ngFor="let tipoAviso of listaTipoAviso" [value]="tipoAviso.Descricao">
                                {{tipoAviso.Descricao}}</option>
                        </select>
                    </div>
                    <p *ngIf="temErro && formAvisos.controls['prazo'].invalid" style="color: red; font-size: 8.6px;">
                        Campo Obrigatorio
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 form-group">
                    <label class="font-weight-semibold" for="mensagem">Mensagem</label>
                    <div class="input-group">
                        <textarea (keyup)='mensagemContador = onKeyUp($event.target);' formControlName="mensagem"
                            id="mensagem" class="form-control" aria-label="With textarea"
                            [ngStyle]="temErro && formAvisos.controls['mensagem'].status == 'INVALID'? {'border-color':'red'}: null"
                            placeholder="Digite uma Mensagem..." maxlength="5000">
                        </textarea>
                    </div>
                    <p>
                        <small class="d-flex flex-row-reverse bd-highlight" [ngStyle]="valorMaximoMsn - mensagemContador.length <= 100 ? {'color':'red'} : null">{{mensagemContador.length}}/{{valorMaximoMsn}}</small>
                        <samp class="col-6" *ngIf="temErro && formAvisos.controls['mensagem'].invalid"
                            style="color: red; font-size: 8.6px;">
                            Campo Obrigatorio
                        </samp>
                    </p>
                </div>
            </div>
        </div>

        <div class="card-footer text-right">
            <button class="btn btn-danger pl-3 pr-3 mr-2" type="button" (click)="limparCampos()"
                *ngIf="formAvisos.controls['mensagem'].status == 'VALID' || formAvisos.controls['prazo'].status == 'VALID'">Limpar</button>
            <button *ngIf="ativarLista == true" [disabled]="this.formAvisos.invalid" class="btn btn-primary pl-3 pr-3 mr-2" type="button"
                (click)="submitAviso()">Salvar</button>
            <button *ngIf="ativarLista == false" data-dismiss="modal" [disabled]="this.formAvisos.invalid" class="btn btn-primary pl-3 pr-3 mr-2" type="button"
                (click)="submitAviso()">Salvar</button>
                
        </div>
    </form>
</div>

<div class="card" *ngIf="ativarLista == true">
    <div class="card-header">
        <h3 class="card-title"> Listagem de Avisos <small>
                <div class="dropdown">
                    <span><i class="icon-help mr-3"></i></span>
                    <div class="dropdown-content">
                        <p><small class="info-color-g">.</small> Mais de 31 dias</p>
                        <p><small class="info-color-y">.</small> Entre 7 e 31 dias</p>
                        <p><small class="info-color-r">.</small> Menos de 7 dias</p>
                    </div>
                </div>
            </small></h3>
    </div>

    <div class="card-body">
        <div class="form-group row">
            <div class="input-group col-lg-12">
                <input type="number" class="form-control" name="searchText" (keyup)='filter = onKeyUp($event.target)'
                    type="text" placeholder="Pesquisar Avisos">
                <span class="input-group-append">
                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                </span>
            </div>
        </div>

        <div class="table-responsive" style="max-height:45vh">
            <table class="table table-striped table-hover">
                <thead class="text-start">
                    <tr class="text-center" style="white-space:nowrap">
                        <th class="bg-white">Excluir/Editar</th>
                        <th class="bg-white">Autor</th>
                        <th class="bg-white">Data Inicial</th>
                        <th class="bg-white">Horário Inicial</th>
                        <th class="bg-white">Data Final </th>
                        <th class="bg-white">Horário Final</th>
                        <th class="bg-white">Mensagem</th>
                    </tr>
                </thead>

                <tbody id="lista-processos">
                    <tr *ngFor="let aviso of listaAvisos | filtrarAvisos: filter" class="text-center"
                        style="white-space:nowrap; ">
                        <td [ngStyle]="{'border-left':coresData(aviso)}">
                            <div>
                                <button class="btn btn-danger mr-2" (click)="deletarAviso(aviso.Id)">
                                    <i class="fa fa-trash"></i>
                                </button>
                                <button class="btn bg-teal-400" (click)="carregarCampos(aviso)">
                                    <i class="fa fa-pencil"></i>
                                </button>
                            </div>
                        </td>
                        <td>{{aviso.Usuario.toUpperCase().substring(0,10)}}</td>
                        <td>{{formatarDataObter(aviso.DataInicial)}}</td>
                        <td>{{aviso.HoraInicial}}</td>
                        <td>{{formatarDataObter(aviso.DataFinal)}}</td>
                        <td>{{aviso.HoraFinal}}</td>
                        <td (click)="obterMensagem(aviso)" class="mensagem-lista"
                            [ngStyle]="aviso.Mensagem.length > 25? {'cursor': 'pointer'}: null">
                            {{aviso.Mensagem.length > 25? aviso.Mensagem.substring(0,25)+"...":aviso.Mensagem}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>