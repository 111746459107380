<label class="font-weight-semibold">Anexar</label> <br>
<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal"> <i
        class="fa fa-paperclip"></i></button>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="exampleModalLabel">Anexar</h4>
                <button id="modalClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #anexo="ngForm" [formGroup]="formAnexo" (ngSubmit)="submitAnexo()" class="row">
                    <div class="col-md-3 form-group" *ngIf="!perfil">
                        <label for="addFile" class="btn btn-primary "><i class="fa fa-plus"></i></label>
                        <input type="file" id="addFile" accept="/*"
                            (change)="onArquivoChange($event)" multiple style="display:none" formControlName="arquivoselecionado"/>
                    </div>

                    <div *ngIf="!perfil" class="col-md-6 form-group">
                        <label class="font-weight-semibold">Arquivo Selecionado</label>
                        <div class="text-left">

                            <small *ngIf="arquivoselecionado.value != ''  "
                                ><i class="fa fa-file-image-o pr-2" aria-hidden="true"></i>{{arquivos.value.name}}</small>
                            <small *ngIf="arquivoselecionado.value == ''"
                                >Nenhum arquivo selecionado...</small>
                        </div>

                    </div>
                    <div class="d-flex justify-content-start" *ngIf="!editado" style="margin-left: 29.3em;">
                        <button type="button" data-dismiss="modal" (click)="limparCampos()" class="btn btn-danger mt-2 mr-2 col-md-6"
                            >
                            Cancelar
                        </button>
                        <button type="submit" class="btn btn-primary mt-2 mr-3 col-md-6"
                            [disabled]="(arquivos.value == '') || formAnexo.invalid">
                            Enviar
                        </button>
                        
                    </div>
                </form>



            </div>

        </div>
    </div>
</div>