<div class="card">
    <div class="card-header header-elements-inline">
        <h5 class="card-title"> Relatórios</h5>
        <div class="header-elements">
            <button type="button" class="btn btn-light" data-toggle="modal" data-target="#modal_default">Dúvidas <i class="fa fa-question"></i></button>
        </div>
    </div>
    <div class="card-body card-deck">
        <div class="col-12">
            <!-- Cadastro --> 
            <div class="col-12" (click)="alterarRelatorioCadastro()" style="cursor: pointer;">
                <h5>
                    Cadastro
                    &nbsp;
                    <span *ngIf="relatorioCadastro == false"    class="fa fa-angle-right" ></span>
                    <span *ngIf="relatorioCadastro == true"     class="fa fa-angle-down" ></span>
                </h5>
            </div>           
            <div *ngIf="relatorioCadastro == true" class="row">                
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 form-group">
                    <div class="card card-header" (click)="downloadArquivo()" 
                            style="cursor: pointer;">
                        <div class="media">
                            <div class="mr-3 align-center">
                                <i class="icon-user icon-3x text-orange-400"></i>
                            </div>
                            <div class="media-body text-left">
                                <p class="font-weight-semibold mb-0">Emissão de Relatório Cadastral</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 form-group">
                    <div class="card card-header">
                        <div class="media">
                            <div class="mr-3 align-self-center">
                                <i class="icon-file-text icon-3x text-orange-400"></i>
                            </div>
                            <div class="media-body text-left">
                                <p class="font-weight-semibold mb-0">Emissão de Contrato de Trabalho</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 form-group">
                    <div class="card card-header">
                        <div class="media">
                            <div class="mr-3 align-self-center">
                                <i class="icon-file-text2 icon-3x text-orange-400"></i>
                            </div>
                            <div class="media-body text-left">
                                <p class="font-weight-semibold mb-0">Declaração de Dependentes para fins de IR</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 form-group">
                    <div class="card card-header">
                        <div class="media">
                            <div class="mr-3 align-self-center">
                                <i class="icon-file-text3 icon-3x text-orange-400"></i>
                            </div>
            
                            <div class="media-body text-left">
                                <p class="font-weight-semibold mb-0">Termo de Compromisso de Vale Transporte</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 form-group">
                    <div class="card card-header">
                        <div class="media">
                            <div class="mr-3 align-self-center">
                                <i class="icon-file-word icon-3x text-orange-400"></i>
                            </div>
            
                            <div class="media-body text-left">
                                <p class="font-weight-semibold mb-0">Termo de Responsabilidade do Salário Família</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 form-group">
                    <div class="card card-header card-dark">
                        <div class="media">
                            <div class="mr-3 align-self-center">
                                <i class="icon-file-stats icon-3x text-orange-400"></i>
                            </div>
                            <div class="media-body text-left">
                                <p class="font-weight-semibold mb-0">Recibo de Entrega e Devolução de CTPS</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Cadastro -->
        </div>
    </div>
</div>


<!-- Basic modal -->
<div id="modal_default" class="modal fade" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Como Cadastrar</h5>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <div class="modal-body">
                <!-- <video width="100%" controls autoplay loop>
                    <source src=" https://www.youtube.com/watch?v=1hCmkJyihVo" type="video/mp4">
                </video> -->
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-link" data-dismiss="modal">Fechar</button>
                <!-- <button type="button" class="btn bg-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>
<!-- /basic modal -->
