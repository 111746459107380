import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';

export function validarDataInicioReflexo(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const formGroup = control.parent as FormGroup;

    if (!formGroup) {
      return null;
    }

    const efeitoRetroativo = formGroup.controls['efeitoRetroativo'].value;
    const dataInicioReflexo = control.value;
    const dataAtual = new Date();
    const dataOntem = new Date(dataAtual);
    dataOntem.setDate(dataAtual.getDate() - 1);

    if (efeitoRetroativo === 'SIM' && new Date(dataInicioReflexo) > dataOntem) {
      return { dataInvalida: true };
    }

    return null;
  };
}
