import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';


@Injectable({
  providedIn: 'root'
})
export class GraficoBeneficioService {
  localStorage: LocalStorage;

  url_acesso:string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  filial: string;
  codTarefa = "000000000";
  tpProcessamento = "EM";
  
  constructor( private http: HttpClient, private acesso: AcessoService ) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.url_acesso = this.acesso.validarUrl();
    }
   }

   obterDadosGraficoTotalBeneficiosTotalBeneficiarios() {
    return this.http.get(`${this.url_acesso}beneficio/totalbeneficios/`,{headers:this.cabecalho});
   }

   obterDadosGraficoBeneficiosProximosVencer() {
    return this.http.get(`${this.url_acesso}beneficio/beneficios-vencimento/`,{headers:this.cabecalho});
   }

  obterDadosGraficoRegrasBeneficiosProximosVencer() {
    return this.http.get(`${this.url_acesso}beneficio/regra-beneficios-vencimento/`, { headers: this.cabecalho });
  }

  obterDadosGraficoBeneficiarioRegrasBeneficiosProximosVencer(numOrd: Number) {
    return this.http.get(`${this.url_acesso}beneficio/beneficiario-regra-beneficios-vencimento/${numOrd}`, { headers: this.cabecalho });
  }

  obterDadosGraficoTotalPartesBeneficio() {
    return this.http.get(`${this.url_acesso}beneficio/total-partes-beneficio/`, { headers: this.cabecalho });
  }

}
