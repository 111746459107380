import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { CadastroPerfil } from 'src/app/service/classes/profile/CadastroPerfil';
import Documentos from 'src/app/service/classes/cadastro/Documento';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root'
})
export class DocumentosService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any = null;
  filial: string = "";
  url_acesso: any = this.acesso.validarUrl();

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString(); 
    }
   }

  buscarEstado() {
    return this.http.get(`${this.url_acesso}estados/`,{headers:this.cabecalho});
  }
  
  alterarCadastroPerfil(documentos:Documentos, numeroOrdem: string){
    return this.http.put(`${this.url_acesso}colaborador/documentos/${numeroOrdem}/`, documentos, {headers:this.cabecalho});
  }

  gerarLink(dados: any) {
    this.url_acesso = this.acesso.validarUrl();
    return this.http.post(`${this.url_acesso}gerarlinkcorrecaoanexo/${this.filial}`, dados, { headers: this.cabecalho });
    // return this.http.post(`${this.url_acesso}cadastrogerarlink/${this.filial}`, dados, { headers: this.cabecalho });
  }

  salvarAnexosMarcados(dados: any) {
    this.url_acesso = this.acesso.validarUrl();
    return this.http.post(`${this.url_acesso}cadastro-publico/anexos-marcados/${this.filial}`, dados, { headers: this.cabecalho });
  }


}
