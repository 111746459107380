<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="card">
  <div class="card-header">
    <h3 class="card-title">
      <i
        class="fa fa-arrow-left pr-3 btn"
        aria-hidden="true"
        style="cursor: pointer; bottom: 2px"
        (click)="retornarTelaColaboradores()"
      ></i>
      Informações de concurso
    </h3>
  </div>
  <div class="card-body row">
    <!-- *ngIf="colaborador" -->

    <div class="form-group col-md-2">
      <label class="justify-content-start font-weight-semibold">Matricula </label>
      <input
        type="text"
        placeholder="Matrícula"
        class="form-control"
        [value]="infoColaborador.matricula"
        disabled
      />
    </div>

    <div class="form-group col-md-6">
      <label class="justify-content-start font-weight-semibold">Nome do Colaborador </label>
      <input
        type="text"
        placeholder="Colaborador"
        class="form-control"
        [value]="infoColaborador.nome"
        disabled
      />
    </div>
    <div class="form-group col-md-4">
      <label class="justify-content-start font-weight-semibold">Vínculo </label>
      <input
        type="text"
        placeholder="Vínculo"
        class="form-control"
        [value]="infoColaborador.vinculo"
        disabled
      />
    </div>
  </div>
</div>

<div class="card">
  <form class="card-body" [formGroup]="formCadastro">
    <div class="row">
      <fieldset class="col-md-12">
        <legend>
          <h6 class="font-weight-semibold">Dados do Concurso</h6>
        </legend>
        <div class="row">
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold" for="dtConcurso">Data Concurso</label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="dtConcurso"
                id="dtConcurso"
                max="9999-12-31"
                [ngClass]="
                  formCadastro.get('dtConcurso').invalid && formCadastro.get('dtConcurso').touched
                    ? 'is-invalid'
                    : null
                "
              />
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('dtConcurso').invalid && formCadastro.get('dtConcurso').touched
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-8 form-group">
            <label class="font-weight-semibold" for="descricao">Descrição</label>
            <div class="input-group">
              <select
                class="form-control"
                formControlName="descricao"
                id="descricao"
                [ngClass]="
                  formCadastro.get('descricao').invalid && formCadastro.get('descricao').touched
                    ? 'is-invalid'
                    : null
                "
              >
                <option value="">Selecione...</option>
                <option *ngFor="let descricao of listaDescricao" [value]="descricao.NumeroEdital">
                  {{ descricao.Titulo }}
                </option>
                <!-- qual length max? -->
              </select>
            </div>
            <span
              class="form-text text-danger"
              *ngIf="formCadastro.get('descricao').invalid && formCadastro.get('descricao').touched"
            >
              Campo obrigatório
            </span>
          </div>
        </div>
      </fieldset>
      <fieldset class="col-md-12">
        <legend>
          <h6 class="font-weight-semibold">Dados do Candidato</h6>
        </legend>
        <div class="row">
          <div class="col-md-8 form-group">
            <label class="font-weight-semibold" for="classificacao">Classificação</label>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                maxlength="4"
                formControlName="classificacao"
                id="classificacao"
                placeholder="Classificação"
                [ngClass]="
                  formCadastro.get('classificacao').invalid &&
                  formCadastro.get('classificacao').touched
                    ? 'is-invalid'
                    : null
                "
              />
              <!-- qual length max? -->
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('classificacao').invalid &&
                formCadastro.get('classificacao').touched
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-4 form-group">
            <label class="font-weight-semibold" for="dtConvocacao">Data Convocação</label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="dtConvocacao"
                id="dtConvocacao"
                max="9999-12-31"
                [ngClass]="
                  formCadastro.get('dtConvocacao').invalid &&
                  formCadastro.get('dtConvocacao').touched
                    ? 'is-invalid'
                    : null
                "
              />
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('dtConvocacao').invalid && formCadastro.get('dtConvocacao').touched
              "
            >
              Campo obrigatório
            </span>
          </div>
        </div>
      </fieldset>
      <fieldset class="col-md-12">
        <legend>
          <h6 class="font-weight-semibold">Período do Estágio Probatório</h6>
        </legend>
        <div class="row">
          <div class="col-md-6 form-group">
            <label class="font-weight-semibold" for="dtEstagioInicial">Data Inicial</label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="dtEstagioInicial"
                id="dtEstagioInicial"
                max="9999-12-31"
                [ngClass]="
                  formCadastro.get('dtEstagioInicial').invalid &&
                  formCadastro.get('dtEstagioInicial').touched
                    ? 'is-invalid'
                    : null
                "
              />
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('dtEstagioInicial').invalid &&
                formCadastro.get('dtEstagioInicial').touched
              "
            >
              Campo obrigatório
            </span>
          </div>
          <div class="col-md-6 form-group">
            <label class="font-weight-semibold" for="dtEstagioFinal">Data Final</label>
            <div class="input-group">
              <input
                class="form-control"
                type="date"
                formControlName="dtEstagioFinal"
                id="dtEstagioFinal"
                max="9999-12-31"
                [ngClass]="
                  formCadastro.get('dtEstagioFinal').invalid &&
                  formCadastro.get('dtEstagioFinal').touched
                    ? 'is-invalid'
                    : null
                "
              />
            </div>
            <span
              class="form-text text-danger"
              *ngIf="
                formCadastro.get('dtEstagioFinal').invalid &&
                formCadastro.get('dtEstagioFinal').touched
              "
            >
              Campo obrigatório
            </span>
          </div>
        </div>
      </fieldset>
    </div>
  </form>
  <div class="card-footer mt-4 d-flex justify-content-end">
    <button type="submit" class="btn btn-primary" (click)="submitCadastro()">
      Submeter
      <i class="fa fa-paper-plane ml-2"></i>
    </button>
  </div>
</div>
