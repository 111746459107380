import { Pipe, PipeTransform } from "@angular/core"
import { Cliente } from "src/app/componentes/pse/cadastro-cliente/cadastro-cliente.component"
@Pipe({name : 'filtrarCliente'})
export class filtrarCliente implements PipeTransform{
    transform(cliente : Cliente[], event : Event) {
        var filtrarCliente = (event.target as HTMLInputElement).value
        filtrarCliente = filtrarCliente.toLowerCase()
        if (filtrarCliente != "") {
           cliente = cliente.filter(cliente => cliente.Nome.toLowerCase().includes(filtrarCliente) || String(cliente.CodigoUnico).toLowerCase().includes(filtrarCliente) || String(cliente.CNPJ).toLowerCase().includes(filtrarCliente))
           return cliente
           
        }else{
            return cliente
        }
    }

}