import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root'
})

export class ServerDate {
  url_acesso: string;
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any;

  constructor(private httpClient: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.url_acesso = this.acesso.validarUrl();
    }
  }
  /**
   * Retorna a data atual do servidor(Date) no formato - 00/00/0000
   * */
  getDateNow() {
    return this.httpClient.get(`${this.url_acesso}getdatenow`, { headers: this.cabecalho })
  }
}
