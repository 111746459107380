import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Dependente } from '../../classe/dependente';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { ProfileService } from '../../profile.service';
import { ErroService } from 'src/app/service/erros/erro.service';
import { CadastroService } from 'src/app/componentes/cadastro/cadastro.service';
import { FormBuilder, Validators } from '@angular/forms';
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { cpfValidator } from 'src/app/service/Validators/cpfValidator';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AnexoComponent } from 'src/app/componentes/anexo/anexo.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-dependentes-perfil',
  templateUrl: './dependentes-perfil.component.html',
  styleUrls: ['./dependentes-perfil.component.css'],
})
export class DependentesPerfilComponent implements OnInit, OnChanges {
  mudandoTela;
  @Output() insereDep = new EventEmitter<string>();
  @Input() cadastroUsuario: Cadastro;
  @Input() listaDependente: Dependente[];
  @Input() ColaboradorInativo: boolean = true;
  @Input() dadosUsuario: any;
  @Input() matriculaAux: any;
  @Input() telaCadastro: boolean;
  @ViewChild(AnexoComponent, { static: false }) anexoComponent: AnexoComponent;

  matricula: string = '';

  cidade: any[] = [];
  sexo: any[] = [];
  municipio: any[] = [];
  tipoDependecias: any[] = [];
  uf: any[] = [];
  ufEscolhida: any[] = [];
  nacionalidade: any[] = [];
  metodo: any;

  cadastroUsuarioTemporario = new Cadastro();
  localStorage: LocalStorage;
  tipoUsuario: string;
  idadeMinima: number = 18;

  codigo_uf: any;
  rotaParametro: any;
  formDependente: any;
  perfilDependente = new Dependente();

  existeCredencial: boolean = true;
  enviarNovoEmail: boolean = null;
  digitarEmail: boolean = false;
  cadastroDependente: boolean = false;
  solicitarFerias: boolean = false;
  telaDependente: boolean = true;
  salarioFamiliarCheckBox: boolean = false;
  irrfCheckBox: boolean = false;
  showDropDown = false;
  btDeleteDoc: boolean = true;
  btAtualizar: boolean = false;
  carregaCep: boolean = true;
  carregaCepLoad: boolean = false;
  showinput: boolean = false;
  temErroSenha: boolean = false;
  validaDependente: boolean = false;

  constructor(
    public profileService: ProfileService,
    private validaErro: ErroService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private cadastroService: CadastroService
  ) {
    this.localStorage = this.decryptLocalStorage();
    const numOrd = this.localStorage.NumeroOrdemUsuario;
    this.route.params.subscribe((params) => (this.rotaParametro = params['numOrdem']));
    this.tipoUsuario = this.localStorage.TipoUsuario;

    // if (this.tipoUsuario == '1' && numOrd != this.rotaParametro) {
    //   this.router.navigate([`/perfil`, numOrd]);
    // }
  }

  retornarMatricula() {
    this.profileService.retornarMatricula().subscribe((matricula) => {
      this.matricula = matricula;
    });
  }

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges): void {
    this.atualizarPropriedadeSeMudada(changes, 'cadastroUsuario', this.cadastroUsuario);
    this.atualizarPropriedadeSeMudada(changes, 'listaDependente', this.listaDependente);
    this.atualizarPropriedadeSeMudada(changes, 'ColaboradorInativo', this.ColaboradorInativo);
    this.atualizarPropriedadeSeMudada(changes, 'localStorage', this.localStorage);
    this.atualizarPropriedadeSeMudada(changes, 'dadosUsuario', this.dadosUsuario);
    this.atualizarPropriedadeSeMudada(changes, 'matriculaAux', this.matriculaAux);
  }

  private atualizarPropriedadeSeMudada<T>(
    mudancas: SimpleChanges,
    nomePropriedade: string,
    propriedadeComponente: T
  ): void {
    if (mudancas[nomePropriedade]?.currentValue !== undefined) {
      propriedadeComponente = mudancas[nomePropriedade].currentValue;
    }
  }

  mudarTela(dependente: Dependente = null) {
    this.limparDependentes();
    this.perfilDependente = new Dependente();
    if (dependente) {
      this.cadastroDependente = true;
      this.telaDependente = false;
      window.scrollTo(0, 0);
      console.log(dependente);
      this.MontaTelaDependente(dependente);
      this.buscarCidade();

      this.btDeleteDoc = false;
      this.perfilDependente.CpfAntigo = this.formDependente.get('cpf_form').value;
      this.btAtualizar = true;
      // if (this.tipoUsuario == '1') {
      //   this.desabilitaForm();
      // }
    } else if (this.cadastroDependente == true) {
      this.cadastroDependente = false;
      this.telaDependente = true;
      let value = '';
      this.formDependente.patchValue({ search: value });
      window.scrollTo(0, 0);
      this.btDeleteDoc = true;
      this.cidade = null;
      // this.tipoDependecias = null;
    } else {
      this.cadastroDependente = true;
      this.telaDependente = false;
      window.scrollTo(0, 0);
      this.btDeleteDoc = true;
      this.perfilDependente.CpfAntigo = this.formDependente.get('cpf_form').value;
      this.btAtualizar = false;
    }

    //importante
    this.validaDependente = false;
    this.mudandoTela.emit(this.telaDependente);
  }
  limparDependentes() {
    this.formDependente.get('cpf_form').setValue('');
    this.formDependente.get('nomeCompleto_form').setValue('');
    this.formDependente.get('sexo_form').setValue('');
    this.formDependente.get('tipoDependencia_form').setValue('valor setado manualmente');
    this.formDependente.get('dataNascimento_form').setValue('');
    this.formDependente.get('uf_form').setValue('');
    this.formDependente.get('cidade_form').setValue('');
    this.formDependente.get('dataFinal').setValue('');
    this.formDependente.get('dataInicialSF_form').setValue('');
    this.formDependente.get('dataFinalSF_form').setValue('');
    this.formDependente.get('dataInicialAC_form').setValue('');
    this.formDependente.get('dataFinalAC_form').setValue('');
    this.formDependente.get('cartorio_form').setValue('');
    this.formDependente.get('certiidao_form').setValue('');
    this.formDependente.get('search').setValue('');
    this.formDependente.markAsPristine();
    this.formDependente.markAsUntouched();
  }

  formataNome(nome) {
    var tmp = nome.split(' ');
    var tamanho = tmp.length;
    return tmp[0] + ' ' + tmp[tamanho - 1];
  }

  converteDatas(data, tipo) {
    var dia, mes, ano;
    dia = data.substring(0, 2);
    mes = data.substring(2, 4);
    ano = data.substring(4, 8);

    if (tipo == 0) {
      return ano + mes + dia;
    } else {
      return dia + '/' + mes + '/' + ano;
    }
  }
  converteCheckBox() {
    if (this.salarioFamiliarCheckBox) {
      this.perfilDependente.DependenteSf = 1;
    } else {
      this.perfilDependente.DependenteSf = 0;
    }
    if (this.irrfCheckBox) {
      this.perfilDependente.DependenteIr = 1;
    } else {
      this.perfilDependente.DependenteIr = 0;
    }
  }

  realizaConversoes() {
    this.perfilDependente.DataNascimento.SetData = this.converteDatas(
      this.perfilDependente.DataNascimento.SetData,
      1
    );
    this.perfilDependente.Cpf = this.formDependente.get('cpf_form').value;
    if (this.perfilDependente.NumeroDocumento != '') {
      this.perfilDependente.Dtent.SetData = this.converteDatas(
        this.perfilDependente.Dtent.SetData,
        1
      );
    }

    this.converteCheckBox();
    this.perfilDependente.Matricula = Number(
      this.cadastroUsuario ? this.cadastroUsuario.Matricula : this.matriculaAux
    );
    this.perfilDependente.EmpresaFilial = Number(
      this.cadastroUsuario ? this.cadastroUsuario.Filial.Codigo : this.localStorage.FilialAtual
    );
  }

  verificarErros() {
    return this.listaDependente.some((dependente) => {
      return (
        dependente.Cpf === '' ||
        dependente.DataNascimento.SetData === '' ||
        dependente.Nome === '' ||
        dependente.Sexo === null ||
        dependente.Tipo === '' ||
        dependente.Uf === '' ||
        dependente.CodigoMunicipio === ''
      );
    });
  }

  insereDependente() {
    this.realizaConversoes();
    let metodoObservable: Observable<any>;
    console.log(this.perfilDependente);
    if (this.btAtualizar) {
      metodoObservable = this.profileService.AtualizarDependente(this.perfilDependente);
    } else {
      metodoObservable = this.profileService.SalvarDependente(this.perfilDependente);
    }
    console.log(this.perfilDependente);

    metodoObservable.subscribe(
      (data) => {
        this.listaDependente = this.listaDependente.map((dep) => {
          if (dep.Numero === this.perfilDependente.Numero) {
            return this.perfilDependente;
          }
          return dep;
        });

        this.finalizarCadastro();
        this.insereDep.emit(
          this.cadastroUsuario ? this.cadastroUsuario.Matricula : this.matriculaAux.toString()
        );
        this.mudarTela();
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  validacaoEnvioDocumentos() {
    if (this.formDependente.invalid) {
      Swal('Preencha todos os campos Obrigatórios!', 'Verifique os campos incorretos. ', 'warning');
    } else {
      this.carregarEnvioDocumentos();
    }
  }

  validarCadastroDependente() {
    this.validacaoEnvioDocumentos();
    this.perfilDependente.Ufnatural = this.ufEscolhida[0].SiglaUf;

    if (this.formDependente.invalid) {
      Swal('Preencha todos os campos Obrigatórios!', 'Verifique os campos incorretos. ', 'warning');
      this.validaDependente = true;
      window.scrollTo(0, 0);
    } else if (this.perfilDependente.UploadDocumentos.length == 0) {
      Swal(
        'Insira ao menos uma certidão!',
        'Clique em Anexar Certidão e selecione um arquivo.',
        'warning'
      );
      this.validaDependente = true;
      window.scrollTo(0, 0);
    } else if (this.perfilDependente.Sexo == 0) {
      Swal('Preencha todos os campos Obrigatórios!', 'Verifique os campos incorretos.', 'warning');
      this.validaDependente = true;
      window.scrollTo(0, 0);
    } else if (
      this.perfilDependente.NumeroDocumento != '' &&
      this.perfilDependente.Dtent.SetData == ''
    ) {
      Swal('Preencha a data de entrega do documento!', 'Verifique o campo. ', 'warning');
      this.validaDependente = true;
      window.scrollTo(0, 0);
    } else if (
      this.perfilDependente.NumeroDocumento == '' &&
      this.perfilDependente.Dtent.SetData != ''
    ) {
      Swal('Preencha o número do documento!', 'Verifique o campo. ', 'warning');
      this.validaDependente = true;
      window.scrollTo(0, 0);
    } else {
      //para apenas enviar os documentos que foram adicionados nesta atualização
      this.formatarEnvioDocumentos();

      this.insereDependente();
    }
  }

  carregarEnvioDocumentos() {
    this.perfilDependente.UploadDocumentos = this.anexoComponent.retornarValores();
  }

  formatarEnvioDocumentos() {
    this.perfilDependente.UploadDocumentos = this.perfilDependente.UploadDocumentos.filter(
      (doc) => doc['adicionado'] == 1
    );
    this.perfilDependente.UploadDocumentos = this.perfilDependente.UploadDocumentos.map((doc) => {
      doc.ArquivoBase64 = doc.ArquivoBase64.split(',')[1];
      return doc;
    });
    if (this.perfilDependente.UploadDocumentos.length <= 0) {
      this.perfilDependente.UploadDocumentos.push({
        ArquivoBase64: '',
        Grupo: '',
        Tipo: '',
        Codigo: '',
        Extensao: '',
        Matricula: '',
        Empfil: '',
        Numero: '',
        MatriculaDependente: '',
      });
    }
  }

  buscarSexo() {
    this.profileService.buscarSexo().subscribe(
      (data) => {
        this.sexo = JSON.parse(JSON.stringify(data));
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarUf() {
    this.profileService.buscarEstado().subscribe(
      (data) => {
        this.uf = JSON.parse(JSON.stringify(data));
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarCidade() {
    if (this.perfilDependente.Uf != '') {
      this.ufEscolhida = this.uf.filter((uf) => uf.Codigo == this.perfilDependente.Uf);
    } else {
      this.buscarCodigoEstado(this.uf, this.perfilDependente.Ufnatural);
      this.ufEscolhida = this.uf.filter((uf) => uf.Codigo == this.codigo_uf);
      this.perfilDependente.Uf = this.codigo_uf;
    }

    console.log(this.ufEscolhida);
    if (this.ufEscolhida.length > 0) {
      this.profileService.buscarCidade(this.ufEscolhida[0].Codigo).subscribe(
        (data) => {
          this.cidade = JSON.parse(JSON.stringify(data));
          this.formDependente.controls['cidade_form'].setValue(
            this.perfilDependente.CodigoMunicipio
          );
          // if (this.tipoUsuario != '1') {
          this.formDependente.get('cidade_form').enable();
          // }
        },
        (error) => {
          this.validaErro.retornoErro(error);
        }
      );
    }
  }

  buscarTipoDependencia() {
    this.profileService.buscarTipoDependencia().subscribe(
      (data) => {
        this.tipoDependecias = JSON.parse(JSON.stringify(data));
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  MontaTelaDependente(dependente: Dependente) {
    this.perfilDependente = JSON.parse(JSON.stringify(dependente)); //para criar uma copia e não uma referencia
    this.perfilDependente.Cpf = this.perfilDependente.Cpf.toString().padStart(11, '0');
    this.formDependente.get('cpf_form').setValue(this.perfilDependente.Cpf);

    this.profileService
      .getDocumentos(
        this.cadastroUsuario ? this.cadastroUsuario.Matricula : this.matriculaAux.toString()
      )
      .subscribe(
        (data: any) => {
          const documentos = data.filter(
            (documento) => documento.MatriculaDependente == dependente.Numero
          );
          this.perfilDependente.UploadDocumentos = documentos.map((documento) => {
            documento.ArquivoBase64 = 'data:image/jpeg;base64,' + documento.ArquivoBase64;
            documento['adicionado'] = 0;
            return documento;
          });
        },
        (error) => this.validaErro.retornoErro(error)
      );

    this.irrfCheckBox = this.perfilDependente.DependenteIr == 1 ? true : false;
    this.salarioFamiliarCheckBox = this.perfilDependente.DependenteSf == 1 ? true : false;

    this.perfilDependente.Dtent.SetData = dependente.Dtent.ToDate.replace('/', '').replace('/', '');
    this.perfilDependente.DataNascimento.SetData = dependente.DataNascimento.ToDate.replace(
      '/',
      ''
    ).replace('/', '');
  }

  desabilitaForm() {
    const camposDesabilitar = [
      'cpf_form',
      'nomeCompleto_form',
      'sexo_form',
      'tipoDependencia_form',
      'dataNascimento_form',
      'uf_form',
      // 'dataFinal',
      // 'dataInicialSF_form',
      // 'dataFinalSF_form',
      // 'dataInicialAC_form',
      // 'dataFinalAC_form',
      // 'cartorio_form',
      // 'certidao_form',
      // 'search',
    ];

    camposDesabilitar.forEach((campo) => {
      this.formDependente.get([campo]).disable();
    });
  }

  async detectFiles(event) {
    let files = event.target.files;
    let listNaoAceita = [];
    let numdoc = await this.buscarUltimoNumDoc();
    for (var i = 0; i < files.length; i++) {
      if (files[i].size / 1024 > 9000) {
        Swal('Atenção!', 'Envie documentos com tamanho menor que 9mb', 'warning');
        return false;
      } else if (files[i].type.includes('image') || files[i].type.includes('application/pdf')) {
        let file = files[i];
        let reader = new FileReader();
        let pagina = i + 1;
        reader.onload = (e: any) => {
          this.perfilDependente.UploadDocumentos.push({
            ArquivoBase64: e.target.result,
            NomeUsuario: this.localStorage.NomeUsuario,
            Matricula: this.cadastroUsuario ? this.cadastroUsuario.Matricula : this.matriculaAux,
            Empfil: this.cadastroUsuario
              ? this.cadastroUsuario.Filial.Codigo
              : this.localStorage.FilialAtual,
            Grupo: '001',
            Tipo: '003',
            Codigo: '011003',
            Extensao:
              file.type.includes('image') && !file.type.includes('x-emf') ? 'imagem' : 'documento',
            Pagina: pagina,
            NumDoc: numdoc,
            Numero: this.perfilDependente.Numero,
            MatriculaDependente: this.perfilDependente.Numero,
            adicionado: 1,
          });
        };
        reader.readAsDataURL(file);
      } else listNaoAceita[i] = files[i].name;
    }
    if (listNaoAceita.length == 1) {
      Swal(
        'Seu documento não possui uma extensão válida!',
        'Por favor, coloque apenas imagens ou PDF',
        'warning'
      );
    } else if (listNaoAceita.length > 1) {
      Swal(
        'Algum documento não possui uma extensão válida!',
        'Por favor, coloque apenas imagens ou PDF',
        'warning'
      );
    }
  }

  async buscarUltimoNumDoc() {
    const documentosOrdenados = await this.perfilDependente.UploadDocumentos.sort((a, b) => {
      if (Number(a.NumDoc) > Number(b.NumDoc)) return -1;
      else if (Number(a.NumDoc) < Number(b.NumDoc)) return 1;
      else return 0;
    });
    return documentosOrdenados.length == 0 ? 1 : Number(documentosOrdenados[0].NumDoc) + 1;
  }

  deletarIcon(indice) {
    let numeroIndex = indice;
    this.perfilDependente.UploadDocumentos.splice(numeroIndex, 1);
  }

  finalizarCadastro() {
    Swal({
      title: 'Cadastro realizado com sucesso!',
      text: 'Aguardando envio de informações do Benefício.',
      icon: 'success',
      closeOnClickOutside: false,
    });
    // .then((result) => {
    //   if (result) {
    //     window.location.reload(); //revisar reload
    //   }
    // });
  }

  buscarCodigoEstado(estado, uf): any {
    estado.some((obj) => {
      if (obj.SiglaUf == uf) {
        this.codigo_uf = obj.Codigo;
        return true;
      }
    });
  }

  verificaData(data, tipo, nome_form) {
    // tipo 1 para dependente e 2 para férias

    if (!this.validarData(data)) {
      if (tipo == 1) {
        this.formDependente.controls[nome_form].setValue('');
      }
    }
  }

  // Verifica se data é valida
  validarData(data) {
    var reg = /[^\d\/\.]/gi; // Mascara = dd/mm/aaaa | dd.mm.aaaa
    var valida = data.replace(reg, ''); // aplica mascara e valida sÃ³ numeros
    if (valida && valida.length == 10) {
      // Ã© vÃ¡lida, entÃ£o ;)
      var ano = data.substr(6),
        mes = data.substr(3, 2),
        dia = data.substr(0, 2),
        M30 = ['04', '06', '09', '11'],
        v_mes = /(0[1-9])|(1[0-2])/.test(mes),
        v_ano = /(19[1-9]\d)|(20\d\d)|2100/.test(ano),
        rexpr = new RegExp(mes),
        fev29 = ano % 4 ? 28 : 29;

      if (v_mes && v_ano) {
        if (mes == '02') return dia >= 1 && dia <= fev29;
        else if (rexpr.test(M30.toString())) return /((0[1-9])|([1-2]\d)|30)/.test(dia);
        else return /((0[1-9])|([1-2]\d)|3[0-1])/.test(dia);
      }
    }
    return false;
  }

  verificaCpf(cpf) {
    if (!this.validarCpf(cpf)) {
      this.formDependente.controls['cpf_form'].setValue('');
    }
  }

  validarCpf(c) {
    var i;

    if ((c = c.replace(/[^\d]/g, '')).length != 11) return false;

    if (c == '00000000000') return false;

    var r;
    var s = 0;

    for (i = 1; i <= 9; i++) s = s + parseInt(c[i - 1]) * (11 - i);

    r = (s * 10) % 11;

    if (r == 10 || r == 11) r = 0;

    if (r != parseInt(c[9])) return false;

    s = 0;

    for (i = 1; i <= 10; i++) s = s + parseInt(c[i - 1]) * (12 - i);

    r = (s * 10) % 11;

    if (r == 10 || r == 11) r = 0;

    if (r != parseInt(c[10])) return false;

    return true;
  }

  validarDataAtual(data) {
    let dateAtual: Date = new Date();
    var dia, mes, ano;
    var complementoData = 'T00:00:00';
    dia = data.substring(0, 2);
    mes = data.substring(3, 5);
    ano = data.substring(6, 10);

    var time = new Date(ano + '-' + mes + '-' + dia + complementoData);

    if (dateAtual < time) {
      return true;
    } else {
      return false;
    }
  }

  verificarMenor() {
    if (
      this.perfilDependente.Tipo == '3' ||
      this.perfilDependente.Tipo == '6' ||
      this.perfilDependente.Tipo == '8' ||
      this.perfilDependente.Tipo == '10' ||
      this.perfilDependente.Tipo == '11' ||
      this.perfilDependente.Tipo == '23'
    ) {
      if (!this.ValidarValoresDatas(this.perfilDependente.DataNascimento.SetData)) {
        this.formDependente.controls['dataNascimento_form'].setValue('');
      }
    }
  }

  ValidarValoresDatas(data) {
    var dia, mes, ano;
    var complementoData = 'T00:00:00';

    dia = data.substring(0, 2);
    mes = data.substring(2, 4);
    ano = data.substring(4, 8);

    let dateAtual: Date = new Date();
    var time = new Date(ano + '-' + mes + '-' + dia + complementoData);

    let auxTime = time.getFullYear();
    let auxDate = dateAtual.getFullYear();
    if (!this.validarData(dia + '/' + mes + '/' + ano)) {
      return true;
    }
    if (auxDate - this.idadeMinima <= auxTime) {
      return true;
    } else {
      Swal(
        ' Não é Possível cadastrar maiores de 18 anos para esse tipo de dependência ',
        'Por favor, preencha uma data válida.',
        'warning'
      );
      return false;
    }
  }

  DataMaior(dataInicial, dataFinal) {
    var dia, mes, ano, dia1, mes1, ano1;
    var complementoData = 'T00:00:00';

    var data = this.formDependente.get(dataInicial).value;
    var data1 = this.formDependente.get(dataFinal).value;

    dia = data.substring(0, 2);
    mes = data.substring(2, 4);
    ano = data.substring(4, 8);
    dia1 = data1.substring(0, 2);
    mes1 = data1.substring(2, 4);
    ano1 = data1.substring(4, 8);

    var time = new Date(ano + '-' + mes + '-' + dia + complementoData);
    var time1 = new Date(ano1 + '-' + mes1 + '-' + dia1 + complementoData);

    if (time > time1) {
      Swal(
        'Data inicial não pode ser maior que a final',
        'Por favor, preencha uma data válida.',
        'warning'
      );
      return true;
    }
  }

  urlIgual(): boolean {
    const currentUrl = this.route.snapshot.url.toString();
    return currentUrl.includes('cadastro-publico');
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
  montarDependente() {
    return {};
  }
}
