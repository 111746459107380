import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { CadastroComplementar } from '../cadastro-complementar/cadastro-complementar.component';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { InformacoesConcurso } from './informacoes-concurso.component';

@Injectable({
  providedIn: 'root',
})
export class InformacoesConcursoService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any;
  filial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }
  url_acesso: any = this.acesso.validarUrl();

  cadastrarInformacoes(informacoesConcurso: InformacoesConcurso, numeroOrdem: string) {
    return this.http.post(
      `${this.url_acesso}informacoes/concurso-cadidato/${numeroOrdem}`,
      informacoesConcurso,
      { headers: this.cabecalho }
    );
  }
  atualizarInformacoes(informacoesConcurso: InformacoesConcurso, numeroOrdem: string) {
    return this.http.put(
      `${this.url_acesso}informacoes/concurso-cadidato/${numeroOrdem}`,
      informacoesConcurso,
      { headers: this.cabecalho }
    );
  }

  buscarInformacoes(numeroOrdem: string) {
    return this.http.get(`${this.url_acesso}informacoes/concurso-cadidato/${numeroOrdem}`, {
      headers: this.cabecalho,
    });
  }
  buscarDescricao(matricula: string) {
    return this.http.get(`${this.url_acesso}informacoes/concurso`, {
      headers: this.cabecalho,
    });
  }
}
