import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AcessoService } from '../../service/acesso/acesso.service';
//import { Rescisao } from './interfaces/rescisao';
import { Data } from 'src/app/service/interfaces/Data';
import { Previa } from './interfaces/previa';
import { Calculo } from './interfaces/calculo';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';


@Injectable({
  providedIn: 'root'
})
export class RescisaoService {
  localStorage: LocalStorage = new LocalStorage();
  url_acesso = this.acesso.validarUrl();
  headers = new HttpHeaders();
  cabecalho: any = null;
  filial: string = '';

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString(); 
    }
   }

  alteraSituacaoRescisaoCalculada(sequencial: string, competencia: string, numeroOrdem: string) {
    return this.http.put(`${this.url_acesso}rescisao-reabrir/${sequencial}/${competencia}/${numeroOrdem}/${this.filial}`, {}, { headers: this.cabecalho });
  }

  buscarRescisao(matricula: string) {
    return this.http.get(`${this.url_acesso}rescisoes/${this.filial}/${matricula}`, { headers: this.cabecalho })
  }

  buscarAvPrevioTpResc() {
    return this.http.get(`${this.url_acesso}rescisoes`, { headers: this.cabecalho });
  }

  buscarColaborador(cpf: string) {
    return this.http.get(`${this.url_acesso}cadastro-ativo/${cpf}/${this.filial}`, { headers: this.cabecalho });
  }

  salvarRescisao(rescisao: any, competencia: string, tipoProcessamento: string) {
    return this.http.put(`${this.url_acesso}rescisao/${competencia}/${tipoProcessamento}/${this.filial}`, rescisao, { headers: this.cabecalho });
  }
  buscarDiasAvisPrevio(matricula: string) {
    return this.http.get(`${this.url_acesso}rescisao/${this.filial}/${matricula}`, { headers: this.cabecalho });
  }
  proximoDiaUtil(data: Data) {
    return this.http.post(`${this.url_acesso}diasUteis/0000000/EM/${this.filial}`, data, { headers: this.cabecalho });
  }

  buscarColaboradorMatricula(matricula: string) {
    return this.http.get(`${this.url_acesso}cadastro-ativo-matricula/${matricula}/${this.filial}`, { headers: this.cabecalho });
  }

  previaRescisao(previa: Previa, competencia: string) {
    return this.http.post(`${this.url_acesso}rescisao/${competencia}/RS/${this.filial}/${previa.Matricula}`, previa, { headers: this.cabecalho });
  }

  calcularRescisao(calculo: Calculo, competencia: string, tipoProcessamento: string) {
    return this.http.post(`${this.url_acesso}rescisao/${competencia}/${tipoProcessamento}/${this.filial}`, calculo, { headers: this.cabecalho });
  }
  
  obterRelatorio(matricula: string, competencia: string, relatorio: any) {
    return this.http.post(`${this.url_acesso}rescisao/relatorio/${competencia}/RS/${this.filial}/${matricula}`, relatorio, { headers: this.cabecalho });
  }
  cancelarRescisao(sequencial: string, matricula: string, competencia: string, tipoProcessamento: string) {
    return this.http.put(`${this.url_acesso}rescisao/${competencia}/${tipoProcessamento}/${this.filial}/${sequencial}/${matricula}`, {}, { headers: this.cabecalho });
  }
}
