<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<div class="card">
  <div class="card-header">
    <h3 class="card-title">Permissão</h3>
  </div>
  <form [formGroup]="formPermissaoEmpft" #permissaoEmpft="ngForm" (ngSubmit)="submitFormPermissao()">
    <div class="card-body">
      <div class="row">
        <div class="col-md-2 form-group">
          <label class="font-weight-semibold">Código de usuário</label>
          <input formControlName="codigo" mask="0*" type="text" class="form-control" placeholder="código"
          [ngStyle]="formPermissaoEmpft.get('codigo').invalid && (formPermissaoEmpft.get('codigo').dirty || temErro) ? {'border-color': 'red'} : null ">
          <span *ngIf="formPermissaoEmpft.get('codigo').hasError('required') && temErro" class="form-text text-danger">
            Campo obrigatório </span>
        </div>

        <div class="col-md-10 form-group">
          <label class="font-weight-semibold">Nome do usuário</label>
          <input formControlName="nome" type="text" class="form-control" placeholder="Nome do usuário"
          [ngStyle]="formPermissaoEmpft.get('nome').invalid && (formPermissaoEmpft.get('nome').dirty || temErro) ? {'border-color': 'red'} : null ">
          <span *ngIf="formPermissaoEmpft.get('nome').hasError('required') && temErro" class="form-text text-danger">
            Campo obrigatório </span>
        </div>


        <div class="col-md-6 form-group">
          <label class="font-weight-semibold">Empft</label>
          <select formControlName="empft" class="form-control"
            [ngStyle]="formPermissaoEmpft.get('empft').invalid && (formPermissaoEmpft.get('empft').dirty || temErro) ? {'border-color': 'red'} : null ">
            <option value="">Selecione</option>
            <option *ngFor="let tipo of listaTipo" value="{{tipo.Codigo}}">{{tipo.Descricao}}</option>
          </select>
          <span *ngIf="formPermissaoEmpft.get('empft').hasError('required') && temErro" class="form-text text-danger">
            Campo obrigatório </span>
        </div>

        <div class="col-md-6 form-group">
          <label class="font-weight-semibold">Tipo do usuário</label>
          <select formControlName="tipos" class="form-control"
          [ngStyle]="formPermissaoEmpft.get('tipos').invalid && (formPermissaoEmpft.get('tipos').dirty || temErro) ? {'border-color': 'red'} : null ">
            <option value="">Selecione</option>
            <option *ngFor="let tipo of listaTipo" value="{{tipo.Codigo}}">{{tipo.Descricao}}</option>
          </select>
          <span *ngIf="formPermissaoEmpft.get('tipos').hasError('required') && temErro" class="form-text text-danger">
            Campo obrigatório </span>
        </div>
      </div>

    </div>

    <div class="card-footer text-right">
      <button type="submit" class="btn btn-primary">Salvar</button>
    </div>
  </form>
</div>
