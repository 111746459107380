
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parametrosFiltro'
})
export class ParametroFiltroPipe implements PipeTransform {
  tam = 0;
  transform(parametros:any[], texto: string): any {
    if(texto.trim() !== ''){
      texto = texto.toLowerCase();
      return parametros.filter(paremtro => {
        const nomeParemtro = `${paremtro.key} ${paremtro.value.Descricao == '' ? '' : '-' + paremtro.value.Descricao}`.toLowerCase();
        if(nomeParemtro.includes(texto)) return true;
        return false;
      })
    }else{
      return parametros;
    }
  }

}
