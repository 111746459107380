<!-- Button trigger modal -->
<button type="button" id="modal" class="btn d-none" data-toggle="modal" data-target="#modalFlags">
  Launch demo modal
</button>
<!-- Button trigger modal -->

   
 
<div class="modal fade" id="modalFlags" tabindex="-1" role="dialog" aria-labelledby="modalFlagsLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="modalFlagsLabel">Informar indicadores de cálculo </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group ">
          <div class="input-group">
            <input class="form-control"  type="text" placeholder="Pesquise pelo nome da verba" [(ngModel)]="pesquisarFlag"/>
            <span class="input-group-append">
              <button type="button" class="input-group-text bg-blue border-blue"><i class="fa fa-search"></i></button>
            </span>
          </div>

        </div> 
        <!-- <div *ngIf="loadingVerbas" style="min-height: 10vh" class="d-flex justify-content-center align-items-center">
          <i class="fa fa-spinner fa-spin"></i>
        </div> -->
        <div *ngIf="!loading" style="max-height: 60vh; overflow:auto" [formGroup]="formParametros" >
          <div *ngFor="let flag of parametros | keyvalue:returnZero | parametrosFiltro: pesquisarFlag ; index as i" class="col-md-12 form-group" formArrayName="flags" >
            <label class="font-weight-semibold">{{flag.key}}  {{ flag.value.Descricao == '' ? '' : '-' + flag.value.Descricao }}</label>
            <select [formControlName]="i" class="form-control">
              <option *ngFor="let parametro of flag.value.Campos" value="{{parametro.Codigo}}">{{parametro.Descricao}}</option>
            </select>
          </div> 
        </div>

        <!-- <h5 class="font-weight-bold">Flags selecionadas</h5>
        <div style="max-height: 26vh; overflow:auto" *ngIf="formPensaoAlimenticia.get('dadosPagamento').get('verbasRemovidas').value.length > 0">
          <div *ngFor="let verba of formPensaoAlimenticia.get('dadosPagamento').get('verbasRemovidas').value" class="col-md-12 form-group d-flex align-items-center" >
            <input id="removido{{verba}}" type="checkbox" value="{{verba}}" (change)="onVerbaCheckChange($event)" checked/> 
            <label for="removido{{verba}}" class="font-weight-semibold m-0 pl-1">{{verba}} - {{buscarNomeVerba(verba)}}</label> 
          </div> 
        
        </div> -->
      </div>
      <div class="modal-footer">
        <button type="button"  data-dismiss="modal" class="btn btn-primary">Confirmar</button>
      </div>
    </div>
  </div>
</div>


