<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<header>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title font-weight-semibold" id="target">
        <a
          class="fa fa-arrow-left pr-3 text-dark btn"
          aria-hidden="true"
          routerLink="/colaboradores"
          style="cursor: pointer; bottom: 2px"
        ></a>
        Informações de Saúde
      </h3>
    </div>

    <div class="card-body row">
      <div class="form-group col-md-2">
        <label class="justify-content-start font-weight-semibold">Matricula </label>
        <input
          type="text"
          placeholder="Funcionario"
          class="form-control"
          disabled
          [value]="infoColaborador.matricula"
        />
      </div>

      <div class="form-group col-md-6">
        <label class="justify-content-start font-weight-semibold">Nome do Colaborador </label>
        <input
          type="text"
          placeholder="Funcionario"
          class="form-control"
          disabled
          [value]="infoColaborador.nome"
        />
      </div>
      <div class="form-group col-md-4">
        <label class="justify-content-start font-weight-semibold">Vínculo </label>
        <input
          type="text"
          placeholder="Funcionario"
          class="form-control"
          disabled
          [value]="infoColaborador.vinculo"
        />
      </div>
      <!-- <div class="form-group col-md-3">
        <label class="justify-content-start font-weight-semibold">Situação </label>
        <input
          type="text"
          placeholder="Funcionario"
          class="form-control"
          disabled
          [value]="infoColaborador.situacao.Codigo + ' - ' + infoColaborador.situacao.Descricao"
        />
      </div> -->
    </div>
  </div>
</header>
<main>
  <div class="card d-flex justify-content-center">
    <div class="card-header d-inline-flex justify-content-between">
      <h3 class="card-title font-weight-semibold">Lista de Informações de Saúde</h3>
      <button
        type="button"
        class="btn btn-outline-primary"
        data-toggle="modal"
        data-target="#modalCadastro"
        (click)="abrirModal()"
      >
        <i class="fa fa-plus"></i> Adicionar Informações
      </button>
    </div>
    <div class="card-body table-responsive mt-2">
      <table
        *ngIf="listaInformacoesSaude && listaInformacoesSaude.length > 0"
        class="table table-striped border-blue text-center"
      >
        <thead class="bg-teal-400">
          <tr>
            <th>Tipo de Deficiência</th>
            <th>Reabilitado</th>
            <th>Readaptado</th>
            <th>Data Inicial</th>
            <th>Data Final</th>
            <th>Cota</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngFor="let info of listaInformacoesSaude; let i = index">
            <tr>
              <td>{{ info.TipoDeficiencia.Descricao }}</td>
              <td>{{ simOuNao(info.Reabilitado) }}</td>
              <td>{{ simOuNao(info.Readaptado) }}</td>
              <td>{{ info.DataInicial.ToDate }}</td>
              <td>{{ info.DataFinal.ToDate | colunaVazia }}</td>
              <td>{{ simOuNao(info.PreencherCota) }}</td>

              <td class="d-flex justify-content-center">
                <button
                  type="button"
                  class="btn btn-outline-info mr-2"
                  data-toggle="collapse"
                  [attr.data-target]="'#collapseFaltas' + i"
                >
                  <i class="fa fa-info"></i>
                </button>
                <button
                  type="button"
                  (click)="editarInformacoes(info)"
                  data-toggle="modal"
                  data-target="#modalCadastro"
                  class="btn btn-outline-primary mr-2"
                >
                  <i class="fa fa-pencil"></i>
                </button>
              </td>
            </tr>
            <tr>
              <td colspan="12" class="collapse px-0" [id]="'collapseFaltas' + i">
                <table class="table table-bordered pb-3">
                  <thead class="text-center">
                    <tr class="text-center table-primary">
                      <th>Descrição</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-center table-primary">
                      <td>
                        {{ info.DescricaoComorbidade }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <div
        *ngIf="listaInformacoesSaude.length === 0"
        class="p-4 d-flex justify-content-center align-items-center"
      >
        <h6 class="font-weight-semibold">Não há informações para esse colaborador.</h6>
      </div>
    </div>
  </div>
</main>

<div class="modal fade" id="modalCadastro" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-cadastro-saude
        [cadastro]="cadastro"
        [matricula]="infoColaborador.matricula"
      ></app-cadastro-saude>
    </div>
  </div>
</div>
