import { Component, OnInit } from '@angular/core';
import { Beneficio } from './classe/Beneficio';
import { ErroService} from '../../../service/erros/erro.service';

import { QuadroBeneficiosService } from './quadro-beneficios.service';
import { Router } from '@angular/router';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';

@Component({
  selector: 'app-quadro-beneficios',
  templateUrl: './quadro-beneficios.component.html',
  styleUrls: ['./quadro-beneficios.component.css']
})
export class QuadroBeneficiosComponent implements OnInit {

  beneficios: any[] = [];
  dtcomp: string = '';
  grupo: string = '';
  grupos: Array<any> = [];

  constructor( private quadroBeneficiosService:QuadroBeneficiosService, private validaErro:ErroService, private router: Router) {
  }

  ngOnInit() {
    this.listarComboGrupo();
  }


  listarFiltro(){
    if(this.grupo == ''){
      new AlertaModal("alerta", "Atenção!", "É necessário informar o grupo!", "p");
      return false;
    }
    const filtro = {
      "Grupo": this.grupo,
       "Competencia": "000000"
    }
    this.listarBeneficios(filtro);
  }

  listarBeneficios(filtro:any = null){ //listagem
    this.beneficios = null;
    this.quadroBeneficiosService.listarBeneficios(filtro)
    .subscribe(
      (data: any) => this.beneficios = data,
      error => this.validaErro.retornoErro(error)

    )
  }
  listarComboGrupo(){
    this.quadroBeneficiosService.listarComboGrupo()
    .subscribe(
      (data: any) => { this.grupos = data},
      error => this.validaErro.retornoErro(error)
    )
  }
  getAnosMeses(meses: number){
    const ano = Math.floor(meses/12);
    const mes = (meses - (ano*12));
    return `${ano} ano(s) ${mes > 0 ? `e ${mes} mes(es)` : ''}`
  }
  goToCadatro(beneficio: any){
    this.router.navigate(['/beneficios/quadro-beneficios/cadastro'], {state: {data:{...beneficio}} });
  }

}
