import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AcessoService} from 'src/app/service/acesso/acesso.service';
import {LocalStorage} from 'src/app/service/classes/localstorage/LocalStorage';
import {Crypto} from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root',
})
export class CensoConvocacaoServiceService {
  localStorage: LocalStorage;
  urlAcesso: string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  empresaFilial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.empresaFilial = this.localStorage.FilialAtual.toString();
      this.urlAcesso = this.acesso.validarUrl();
    }
  }

  convocarCenso(convocacao: any) {
    return this.http.post(
      `${this.urlAcesso}cadastro-publico/censo/${this.empresaFilial}`,
      convocacao,
      {headers: this.cabecalho}
    );
  }

  enviarEmails(censo: any, tipo: number) {
    return this.http.post(`${this.urlAcesso}censo/${tipo}`, censo, {
      headers: this.cabecalho,
    });
  }

  //CENSO NÃO RETORNA A ID PARA SER USADA NA ROTA DE DELETAR
  //ERRO DE TABELA EM DIFERENTES AMBIENTES
  buscarCensoAniverario(filial: number) {
    return this.http.get(`${this.urlAcesso}censo/${filial}/1`, {headers: this.cabecalho});
  }

  //REVISAR MÉTODO DA REQUISIÇÃO E RESPOSTA
  deletarCensoAniversario(filial: number) {
    return this.http.put(`${this.urlAcesso}censo/1/${filial}`, {headers: this.cabecalho});
  }
}
