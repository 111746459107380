<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple"><p style="font-size: 20px; color: white">Carregando...</p></ngx-spinner>

<h3 style="margin-bottom: 27px;">Complemento cadastral</h3>

<div class="card">
    <div class="card-header">
        <form [formGroup]="formComplementoCadastral">
            <div class="row">
                <div class="col-md-2">
                    <label>Poder</label>
                    <input type="text">
                </div>
                <div class="col-md-2">
                    <label>Orgão</label>
                    <input type="text">
                </div>
                <div class="col-md-2">
                    <label>Matrícula</label>
                    <input type="text">
                </div>
                <div class="col-md-6">
                    <label>Nome</label>
                    <input type="text">
                </div>
            </div>

            <div class="col-12 mt-3">
                <div class="card shadow-sm">
                    <div class="card-body">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#dados-pessoais">
                                    <i class="fa fa-user"></i>&nbsp;
                                    <span>Cadastro</span>
                                </a>
                            </li>
                            <!-- <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#documentos">
                                    <i class="fa fa-user"></i>&nbsp;
                                    <span>Cadastro (cont.)</span>
                                </a>
                            </li> -->
                            <!-- <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#endereco">
                                    <i class="fa fa-info"></i>&nbsp;
                                    <span>Informações do concurso</span>
                                </a>
                            </li> -->
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="dados-pessoais">
                                <div class="row">
                                    <div class="col-md-2">
                                        <label>Concursado</label>
                                        <select formControlName="concursado">
                                            <option value=""></option>
                                            <option value="true">Sim</option>
                                            <option value="false">Não</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2" formControlName="dataConcursoForm">
                                        <label>Data concurso</label>
                                        <input type="date">
                                    </div>
                                    <div class="col-md-6">
                                        <label>Certidão de falecimento</label>
                                        <input type="text">
                                    </div>
                                    <div class="col-md-2">
                                        <label>Previd. complementar</label>
                                        <select formControlName="previdComplementar">
                                            <option value=""></option>
                                            <option value="true">Sim</option>
                                            <option value="false">Não</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-2">
                                        <label>Data aposentadoria</label>
                                        <input type="date" formControlName="dataAposentadoria">
                                    </div>
                                    <div class="col-md-3">
                                        <label>Tipo prop.</label>
                                        <input type="text" formControlName="tipoProp">
                                    </div>
                                    <div class="col-md-3">
                                        <label>Proporcionalidade</label>
                                        <input type="text" formControlName="proporcionalidade">
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-2">
                                        <label>Data inicial</label>
                                        <input type="date" formControlName="dataInicial">
                                    </div>
                                    <div class="col-md-2">
                                        <label>Data final</label>
                                        <input type="date" formControlName="dataFinal">
                                    </div>
                                    <div class="col-md-2">
                                        <label>Data falecimento</label>
                                        <input type="date" formControlName="dataFelecimento">
                                    </div>
                                    <div class="col-md-3">
                                        <label>Ser. prof.</label>
                                        <input type="text" formControlName="serProf">
                                    </div>
                                    <div class="col-md-3">
                                        <label>AdNot. judicial</label>
                                        <select formControlName="adNotJudicial">
                                            <option value=""></option>
                                            <option value="true">Sim</option>
                                            <option value="false">Não</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-md-2">
                                        <label>Paga INSS</label>
                                        <select formControlName="pagaInss">
                                            <option value=""></option>
                                            <option value="true">Sim</option>
                                            <option value="false">Não</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <label>Paga IR</label>
                                        <select formControlName="pagaIr">
                                            <option value=""></option>
                                            <option value="true">Sim</option>
                                            <option value="false">Não</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <label>Paga pensão</label>
                                        <select formControlName="pagaPensao">
                                            <option value=""></option>
                                            <option value="true">Sim</option>
                                            <option value="false">Não</option>
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <label>Data início trabalho</label>
                                        <input type="date" formControlName="dataInicioTrabalho">
                                    </div>
                                    <div class="col-md-2">
                                        <label>Data fim trabalho</label>
                                        <input type="date" formControlName="dataFimTrabalho">
                                    </div>
                                    <div class="col-md-2">
                                        <label>Consig. bloque.</label>
                                        <select formControlName="consigBloque">
                                            <option value=""></option>
                                            <option value="true">Sim</option>
                                            <option value="false">Não</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-md-2">
                                        <label>Dt. Redist. Cadencia.</label>
                                        <input type="date" formControlName="dtRedistCadencia">
                                    </div>
                                    <div class="col-md-2">
                                        <label>Local de redist. (EMPFIL)</label>
                                        <input type="text" formControlName="localRedistEmp">
                                    </div>
                                    <div class="col-md-8">
                                        <label>Local de redist. (NOME)</label>
                                        <input type="text" formControlName="localRedistNome">
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-md-12">
                                        <label>Processos</label>
                                        <input type="text" formControlName="processos">
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="tab-pane fade show" id="documentos">
                                teste
                            </div> -->

                            <!-- <div class="tab-pane fade show" id="endereco">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label>Descrição</label>
                                        <select formControlName="descricao">
                                            <option value=""></option>
                                            <option value="true">Sim</option>
                                            <option value="false">Não</option>
                                        </select>
                                    </div>          
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-6">
                                        <label>Classificação</label>
                                        <input type="text" formControlName="classificacao">
                                    </div>  
                                    <div class="col-md-2">
                                        <label>Data da convocação</label>
                                        <input type="date" formControlName="dataDeConvocacao">
                                    </div> 
                                    <div class="col-md-2">
                                        <label>Data inicil (estágio prob.)</label>
                                        <input type="date" formControlName="dataInialEP">
                                    </div> 
                                    <div class="col-md-2">
                                        <label>Data final (estágio prob.)</label>
                                        <input type="date" formControlName="dataFinalEP">
                                    </div>         
                                </div>
                            </div> -->
                        </div>
                       
                    </div>
                </div>
            </div>
        </form>
        
    </div>
</div>