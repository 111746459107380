import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { DadosBeneficioObjeto } from './dados-beneficio.component';

@Injectable({
  providedIn: 'root',
})
export class DadosBeneficioService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any;
  filial: string;
  url_acesso: any = this.acesso.validarUrl();

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  buscarBeneficioCompleto(matricula: string) {
    return this.http.get(`${this.url_acesso}dados/beneficio-completo/${matricula}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }

  atualizarBeneficio(dadosBeneficioObjeto: DadosBeneficioObjeto, matricula: string) {
    return this.http.put(
      `${this.url_acesso}dados-beneficio/tb-complemento/${matricula}/${this.filial}`,
      dadosBeneficioObjeto,
      {
        headers: this.cabecalho,
      }
    );
  }
}
