<h1 class="texto">Colaboradores</h1>
<div class="container">
    <div class="card">
        <i class="fa-solid fa-id-card"></i>
        <span>Colaborador</span>
    </div>
    <div class="card">
        <i class="fa-solid fa-hand-holding-medical"></i>
        <span>Solicitações</span>
    </div>
    <div class="card_2" echarts [options]="option"></div>  
    <div class="card">
        <i class="fa-solid fa-stopwatch"></i>
        <span>Banco de horas</span>
    </div>
    <div class="card">
        <i class="fa-solid fa-money-bills"></i>
        <span>Ficha financeira</span>
    </div>
    <div class="card">
        <i class="fa-solid fa-people-group"></i>
        <span>Pensionista</span>
    </div>
</div>
