<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" name="servico" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>

<app-pesquisar-matricula
  *ngIf="matricula == null"
  [titulo]="'Tempo de Serviço'"
  [inputText]="true"
  (colaboradorEmitter)="matriculaListener($event)"
></app-pesquisar-matricula>

<section class="container" *ngIf="matricula != null">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title font-weight-semibold">
        <i
          class="fa fa-arrow-left pr-3 btn"
          aria-hidden="true"
          (click)="retornarTelaInicial()"
          style="cursor: pointer; bottom: 2px"
        ></i
        >Tempo de Serviço
      </h3>
    </div>
    <div class="card-body">
      <form class="row" [formGroup]="formColaborador">
        <div class="col-md-2 form-group">
          <label class="font-weight-semibold" for="matricula">Matrícula</label>

          <input
            type="text"
            class="form-control"
            placeholder="Matrícula"
            mask="0*"
            formControlName="matricula"
          />

          <span class="form-text" *ngIf="loading"
            >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
          ></span>
        </div>

        <div class="col-md-10 form-group">
          <label class="font-weight-semibold">Nome do Colaborador</label>
          <input type="text" class="form-control" placeholder="Nome" formControlName="nome" />
        </div>
      </form>
    </div>
  </div>

  <div class="card d-flex justify-content-center">
    <div class="card-header d-inline-flex justify-content-between">
      <h3 class="card-title font-weight-semibold">Histórico de Serviços</h3>
      <div>
        <button type="button" class="btn btn-outline-primary" (click)="downloadPdf()">
          <i class="bi bi-download"></i> Download
        </button>
        <button
          type="button"
          class="btn btn-outline-primary ml-3"
          data-toggle="modal"
          data-target="#cadastroTempoServiceModal"
          (click)="aoAbrirModal()"
        >
          <i class="fa fa-plus"></i> Adicionar Serviço
        </button>
      </div>
    </div>

    <div class="card-body table-responsive mt-2">
      <table
        class="table table-striped border-blue text-center mt-2"
        *ngIf="listaTempoServico.length > 0"
        aria-label="Tempo de Serviço"
      >
        <thead class="bg-teal-400">
          <tr>
            <th>Empresa</th>
            <th>Cargo/Categoria</th>
            <th>Data Início</th>
            <th>Data Fim</th>
            <th>Tempo de Cargo</th>
            <th>Ações</th>
          </tr>
        </thead>

        <tbody *ngFor="let servico of listaTempoServico; let i = index">
          <tr>
            <td>{{ servico.Nomemp ? servico.Nomemp : 'Não informada' }}</td>
            <td>{{ servico.CodCateg ? servico.CodCateg : 'Não informado' }}</td>
            <td>{{ servico.DtIni.IsDate ? servico.DtIni.ToDate : 'Não informada' }}</td>
            <td>{{ servico.DtFim.IsDate ? servico.DtFim.ToDate : 'Vínculo atual' }}</td>
            <td>
              {{
                servico.NumQui == '0'
                  ? calcularDiasAtual(servico.DtIni.SetData) + ' dias'
                  : servico.NumQui + ' dias'
              }}
            </td>
            <td class="d-flex justify-content-center">
              <!-- <button type="button" class="btn btn-outline-info mr-2">
                 data-toggle="collapse"
              [attr.data-target]="'#collapseFaltas' + falta.Seq
                <i class="fa fa-info"></i>
              </button> -->
              <button
                type="button"
                class="btn btn-outline-primary mr-2"
                data-toggle="modal"
                data-target="#cadastroTempoServiceModal"
                (click)="aoAbrirModal(servico)"
              >
                <i class="fa fa-pencil"></i>
              </button>
              <button
                type="button"
                [ngClass]="{
                  'btn-outline-primary': servico.Obs === 'N',
                  'btn-primary': servico.Obs === 'S'
                }"
                class="btn mr-2"
                data-toggle="modal"
                data-target="#observacoesTempoServicoModal"
                (click)="modalObservacoes.abrirModal(servico)"
              >
                <i class="fa-solid fa-book fa-lg"></i>
              </button>
              <button
                type="button"
                [ngClass]="{
                  'btn-outline-primary': servico.Documento === 'N',
                  'btn-primary': servico.Documento === 'S'
                }"
                class="btn mr-2"
                data-toggle="modal"
                data-target="#anexoTempoServico"
                (click)="modalAnexo.abrirModal(servico)"
              >
                <i class="fa fa-file"></i>
              </button>
              <button
                type="button"
                class="btn btn-outline-danger"
                (click)="deletarTempoServico(servico)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
          <!-- <td colspan="12" class="collapse px-0" [id]="'collapseFaltas' + falta.Seq">
          <table class="table table-bordered pb-3">
            <thead class="text-center">
              <tr class="text-center table-primary">
                <th>Afastamento</th>
                <th>CRM Médico</th>
                <th>Classificação Ato</th>
                <th>Nº Ato</th>
                <th>Nº DOE</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center table-primary">
                <td>oi</td>
                <td>oii</td>
                <td>
                  oiii
                </td>
                <td>oiiii</td>
                <td>oiiiii</td>
              </tr>
            </tbody>
          </table>
        </td> -->
        </tbody>
      </table>

      <div
        *ngIf="listaTempoServico.length === 0"
        class="p-4 d-flex justify-content-center align-items-center"
      >
        <h6 class="font-weight-semibold">Não há dados de faltas para esse colaborador.</h6>
      </div>
    </div>
  </div>
</section>

<div class="modal fade" id="cadastroTempoServiceModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-tempo-servico-cadastro
        [colaborador]="colaborador"
        [cadastro]="cadastroServico"
        [servicoAtual]="servicoAtual"
      ></app-tempo-servico-cadastro>
    </div>
  </div>
</div>
<div class="modal fade" id="observacoesTempoServicoModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-observacoes-ficha-funcional
        (gatilhoAtivado)="buscarTempoServico()"
        [colaborador]="colaborador"
        [tipo]="tipoObs"
      >
      </app-observacoes-ficha-funcional>
    </div>
  </div>
</div>
<div class="modal fade" id="anexoTempoServico" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <app-anexo
        (gatilhoAtivado)="buscarTempoServico()"
        [pagina]="tipoDoc"
        [matricula]="colaborador?.Matricula"
        [enviarSeparadamente]="true"
        [modal]="true"
      ></app-anexo>
    </div>
  </div>
</div>
