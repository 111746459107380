import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';

@Component({
  selector: 'app-recrutamento',
  templateUrl: './recrutamento.component.html',
  styleUrls: ['./recrutamento.component.css']
})
export class RecrutamentoComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService,private myRoute: Router) { }

  ngOnInit() {
  }

  inibirMenu(){
    this.spinner.show();

    setTimeout(()=>{    //<<<---    using ()=> syntax
      let element: HTMLElement = document.getElementById('submeter') as HTMLElement;
      element.click();
      this.myRoute.navigate(["home"]);
      this.spinner.hide();
    }, 3000);
  }

  redirecionar(){
    this.inibirMenu();
  }
}
