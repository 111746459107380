import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AcessoService } from '../../service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root',
})
export class HomePageService {
  url_acesso: string;
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any;
  filial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  buscarAvisos() {
    return this.http.get(`${this.url_acesso}ficha-funcional/aviso`, { headers: this.cabecalho });
  }

  buscarHome() {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(this.url_acesso + `homegerencial/${this.filial}/`, {
      headers: this.cabecalho,
    });
  }

  buscarHomeVariacaoFolha() {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(this.url_acesso + `homefolha/${this.filial}/`, {
      headers: this.cabecalho,
    });
  }

  buscarHomeCurvaColaboradores() {
    //return this.http.post('http://elogicarhapi.elogica.com/api/logins',dados);
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(this.url_acesso + `homecolaboradores/${this.filial}/`, {
      headers: this.cabecalho,
    });
  }

  buscarHomeLancamentoFolhaPonto() {
    this.url_acesso = this.acesso.validarUrl();
    return this.http.get(this.url_acesso + `homelancamento/${this.filial}/`, {
      headers: this.cabecalho,
    });
  }

  buscarEventosListar(sigla: string, empfil: string) {
    return this.http.get(`${this.url_acesso}homecolaboradores/eventos/listar/${sigla}/${empfil}`, {
      headers: this.cabecalho,
    });
  }
}
