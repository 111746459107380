import { Injectable } from "@angular/core";
import { AcessoService } from "src/app/service/acesso/acesso.service";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Ferias } from "src/app/service/interfaces/Ferias";
import { RetornoFerias } from "src/app/service/interfaces/RetornoFerias";
import { Data } from "src/app/service/interfaces/Data";
import { LocalStorage } from "src/app/service/classes/localstorage/LocalStorage";
import { Crypto } from "src/app/service/classes/crypto/crypto";

@Injectable({
  providedIn: "root",
})
export class FeriasPerfilService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any;
  filial: string;
  url_acesso: any = this.acesso.validarUrl();

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(
        new Crypto().decryptUsingAES256(localStorage.getItem("util"))
      );
      this.cabecalho = this.headers.append("sessao", this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }

  buscarHistoricoFerias(matricula: string, codigoFuncao: string) {
    return this.http.get(
      `${this.url_acesso}ferias/historico/${this.filial}/${matricula}/${codigoFuncao}`,
      { headers: this.cabecalho }
    );
  }

  // buscarFerias(matricula: string, codigoFuncao: string){
  //   return this.http.get(`${this.url_acesso}ferias/abertas/000000000/EF/${this.filial}/${matricula}/${codigoFuncao}`, {headers:this.cabecalho});
  // }
  salvarFerias(dadosFerias: RetornoFerias) {
    return this.http.post(
      `${this.url_acesso}ferias/000000000/EF/${this.filial}/`,
      dadosFerias,
      { headers: this.cabecalho }
    );
  }

  alterarFerias(dadoFerias: RetornoFerias) {
    return this.http.put(
      this.url_acesso + `ferias/000000000/EF/${this.filial}/`,
      dadoFerias,
      { headers: this.cabecalho }
    );
  }

  buscarClassificacao() {
    return this.http.get(`${this.url_acesso}classificacao-ato`, {
      headers: this.cabecalho,
    });
  }

  buscarTipoFerias() {
    return this.http.get(`${this.url_acesso}ferias/tipo-ferias`, {
      headers: this.cabecalho,
    });
  }

  buscarParcelas(
    empresaFilial: number,
    matricula: Number,
    dataAquisicaoInicial: Data,
    dataAquisicaoFinal: Data,
    situacao: string
  ) {
    return this.http.get(
      this.url_acesso +
        `ferias/${empresaFilial}/${matricula}/${dataAquisicaoInicial.SetData}/${dataAquisicaoFinal.SetData}/${situacao}`,
      { headers: this.cabecalho }
    );
  }
}
