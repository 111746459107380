<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<div *ngIf="carregado" class="card">
  <div class="card-header">
    <h3 class="card-title"> <i class="fa fa-arrow-left pr-3 btn" aria-hidden="true" (click)="voltar()"
      style="cursor: pointer; bottom: 2px;"></i>Benefício do Colaborador</h3>
    <div>
      <div class="form-group">
        <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
          <label><strong>Nome :</strong> {{beneficiario.Nome}} </label>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="col-md-12  form-group ">
        <div class="panel panel-warning" *ngIf="telaAdicionarFuncionario">
          <div class="panel-body">
            <div class="col-sm-12 form-group">
              <form #telaFormulario="ngForm" [formGroup]="formularioEdicaoBeneficio">
                <input type="hidden" class="form-control" formControlName="numeroDeordemFun" name="numeroDeordemFun" />
                <input type="hidden" class="form-control" formControlName="codigobeneficiarioFun"
                  name="codigobeneficiarioFun" />
                <input type="hidden" class="form-control" formControlName="chavebeneficiarioFun"
                  name="cahvebeneficiariofun" />
                <div *ngIf="mostarCheck">
                  <label class="mr-2" for="">Definir data final</label>
                  <input type="checkbox" name="cahvebeneficiariofun" (change)="checkDataFinal()" />
                </div>
                <div class="form-row">
                  <div class="col-md-4">
                    <label>Benefício</label>
                    <select class="form-control" formControlName="novoBeneficio" name="novoBeneficio"
                      (change)='selecionarBeneficio()'
                      [ngStyle]="(formularioEdicaoBeneficio.controls['novoBeneficio'].status == 'INVALID' && validaEdicaoFuncionario) ? {'border-color':'red'} :null">
                      <option value="">Selecione...</option>
                      <option *ngFor="let listBeneficio of beneficioListFunciorio" value="{{listBeneficio.Codigo}}">
                        {{listBeneficio.Descricao}}</option>
                    </select>
                  </div>
                  <div class="col-md-2">
                    <label>Data inicial</label>
                    <input type="date" class="form-control" formControlName="dataInicial" name="dataInicial"
                      [ngStyle]="(formularioEdicaoBeneficio.controls['dataInicial'].status == 'INVALID' && validaEdicaoFuncionario) ? {'border-color':'red'} :null" />
                  </div>
                  <div *ngIf="controleDataFinal" class="col-md-2">
                    <label>Data Final</label>
                    <input type="date" class="form-control" formControlName="dataFinal" name="dataFinal"
                      [ngStyle]="(formularioEdicaoBeneficio.controls['dataFinal'].status == 'INVALID' && validaEdicaoFuncionario) ? {'border-color':'red'} :null" />
                  </div>
                  <div *ngIf="controleQuantidade" class="col-md-2">
                    <label>Quantidade</label>
                    <input type="text" class="form-control" formControlName="quantidadeBeneficio"
                      name="quantidadeBeneficio"  maxlength="4"
                      [ngStyle]="(formularioEdicaoBeneficio.controls['quantidadeBeneficio'].status == 'INVALID' && validaEdicaoFuncionario) ? {'border-color':'red'} :null" />
                  </div>
                  <div class="col-md-2">
                    <label>&nbsp;</label>
                    <button type="button" (click)="limparCampos()" class="btn btn-danger btn-block">Cancelar</button>
                  </div>
                  <div class="col-md-2">
                    <label>&nbsp;</label>
                    <button type="button" (click)="adicionarBeneficioFuncionario()"
                      class="btn btn-primary btn-block">Salvar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="table-responsive" style="max-height:45vh">
          <table class="table table-striped table-hover">
            <thead>
              <tr class="text-center">
                <th *ngIf="showBtnAdicionar">
                  <button type="button" class="btn btn-info" (click)="adicionarTelaFomrulario()"
                    id="qtd-funcionario-modal" data-chavebeneficiario="">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </th>
                <th>Benefício</th>
                <th>Valor</th>
                <th>Data Inicial</th>
                <th>Data Final</th>
                <th>Editar</th>
                <th>Excluir</th>
              </tr>
            </thead>
            <tbody id="lista-beneficios">
              <tr class="text-center" *ngFor="let item of beneficiario.Beneficio; let i = index">
                <td *ngIf="showBtnAdicionar"></td>
                <td>{{item.Codigo}} - {{item.Descricao}}</td>
                <td>{{item.Valor}}R$</td>
                <td>{{item.DataInicial}}</td>
                <td>{{item.DataFinal}}</td>
                <td> <button type="button" class="btn bg-teal-400" (click)="carregarEditarBeneficioColaborador(i)"><i
                      class="fa fa-pencil" aria-hidden="true"></i></button> </td>
                <td> <button type="button" class="btn btn-danger" (click)="deletarBeneficio(i, 1)"><i
                      class="fa fa-trash" aria-hidden="true"></i></button> </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="beneficiario !== null && beneficiario.Beneficio.length === 0"
            class="d-flex justify-content-center align-items-center" style="height:20vh">
            <h5 class="font-weight-semibold">Colaborador não possui benefício</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--  BENEFICIO DEPENDENTE  -->
<div class="card">
  <div class="card-header">
    <h3 class="card-title">Benefício do Dependente</h3>
  </div>
  <div class="col-md-12 card-body">
    <div class="table-responsive" *ngIf="dependenteList.length != 0">
      <table class="table table-striped table-hover">
        <thead class="text-center">
          <tr class="text-center" style="white-space:nowrap">
            <th>Chave</th>
            <th>CPF</th>
            <th>Nome</th>
            <th>Dt. Nasc</th>
            <th>Idade</th>
            <th>Benefícios</th>
          </tr>
        </thead>
        <tbody id="lista-dependenres-modal">
          <tr  *ngFor="let dependente of dependenteList; let i = index" class="text-center" style="white-space:nowrap">
            <td>{{dependente.ChaveBeneficiario}}</td>
            <td>{{dependente.Cpf}} </td>
            <td>{{dependente.Nome }} </td>
            <td>{{dependente.DataNascimento}}</td>
            <td>{{dependente.Anos}} </td>
            <td><button type="button" data-toggle="modal" data-target="#myModal"
                class="btn btn-info qtd-dependente-modal" (click)="adicionarNoModal(i)"><i class="fa fa-shopping-basket"
                  aria-hidden="true"></i></button></td>
          </tr>
        </tbody>
      </table>
    </div>
    <h4 class="text-center" *ngIf="dependenteList.length == 0">Sem Dependente</h4>
  </div>
</div>

<!-- MODAL -->
<div id="myModal" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="panel-heading">
          <h4>Benefícios do Dependente</h4>
        </div>
      </div>
      <div class="modal-body">
        <div role="dialog">
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="col-sm-12 form-group">
                  <form #telaFormularioDependente="ngForm" [formGroup]="formularioEdicaoBeneficioDependente">
                    <div class="form-row">
                      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 form-group">
                        <label>Benefício</label>
                        <select class="form-control" formControlName="novoBeneficioDependente"
                          (change)='selecionarBeneficioDependente()' name="novoBeneficioDependente"
                          [ngStyle]="(formularioEdicaoBeneficioDependente.controls['novoBeneficioDependente'].status == 'INVALID' && validaEdicaoDependente) ? {'border-color':'red'} : null">
                          <option value="">Selecione...</option>
                          <option *ngFor="let listBeneficio of beneficioListDependente"
                            value="{{listBeneficio.Codigo}}">{{listBeneficio.Descricao}}</option>
                        </select>
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 form-group">
                        <label>Data Inicial</label>
                        <input type="date" class="form-control" formControlName="dataInicialDependente"
                          name="dataInicialDependente"
                          [ngStyle]="(formularioEdicaoBeneficioDependente.controls['dataInicialDependente'].status == 'INVALID' && validaEdicaoDependente) ? {'border-color':'red'} : null" />
                      </div>
                      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 form-group">
                        <label>Data Final</label>
                        <input type="date" class="form-control" formControlName="dataFinalDependente"
                          [ngStyle]="(formularioEdicaoBeneficioDependente.controls['dataFinalDependente'].status == 'INVALID' && validaEdicaoDependente) ? {'border-color':'red'} : null" />
                      </div>

                      <div *ngIf="controleQuantidadeDependente" class="col-md-2">
                        <label>Quantidade</label>
                        <input class="form-control" formControlName="quantidadeBeneficio" maxlength="4"
                          name="quantidadeBeneficio" (keyup)="somenteNumero($event)"
                          [ngStyle]="(formularioEdicaoBeneficio.controls['quantidadeBeneficio'].status == 'INVALID' && validaEdicaoFuncionario) ? {'border-color':'red'} :null" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="table-responsive">
                  <table class="table table-striped table-hover">
                    <thead class="text-center">
                      <tr class="text-center" style="white-space:nowrap">
                        <th>Benefício</th>
                        <th>Valor</th>
                        <th>Data Inicial</th>
                        <th>Data Final</th>
                        <th>Editar</th>
                        <th>Excluir</th>
                      </tr>
                    </thead>
                    <tbody class="lista-regra-beneficios-dependente">
                      <tr *ngFor="let item of beneficioModal; let i = index" class="text-center"
                        style="white-space:nowrap">

                        <td>{{item.Codigo}} - {{item.Descricao}}</td>
                        <td>R$ {{item.Quantidade}}</td>
                        <td>{{item.DataInicial}}</td>
                        <td>{{item.DataFinal}}</td>
                        <td> <button type="button" class="btn bg-teal-400"
                            (click)="carregarEditarBeneficioDependente(i)"><i class="fa fa-pencil"
                              aria-hidden="true"></i></button> </td>
                        <td> <button type="button" class="btn btn-warning" (click)="deletarBeneficio(i, 2)"><i
                              class="fa fa-trash" aria-hidden="true"></i></button> </td>
                      </tr>
                    </tbody>
                  </table>
                  <div *ngIf="beneficioModal !== null && beneficioModal.length === 0"
                    class="d-flex justify-content-center align-items-center" style="height:20vh">
                    <h5 class="font-weight-semibold">Dependente não possui benefício</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="limparCamposModal()" data-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="onSubmit()">Salvar</button>
      </div>
    </div>

  </div>
</div>