export class Vinculo {
    Codigo:number ;
    Nome: string;
    Transacao:  string;
    Categoria:  string;
    Ocorrencia:  string;
    CodTipoContrato:  string;
    CategoriaESocial:  string;
    VinculoApos:  string;
    Grupo:  string;
    AfastamentoInicial:  string;
    F01:  string;
    F02:  string;
    F03:  string;
    F04:  string;
    F05:  string;
    F06:  string;
    F07:  string;
    F08:  string;
    F09:  string;
    F10:  string;
}