import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AcessoService } from '../../../../service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';


@Injectable({
  providedIn: 'root'
})
export class CadastroBeneficioService {
  localStorage: LocalStorage;
  url_acesso:string;
  headers = new HttpHeaders();
  cabecalho = new HttpHeaders();
  filial: string;
  codTarefa = "000000000";
  tpProcessamento = "EM";
  
  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
      this.url_acesso = this.acesso.validarUrl();
    }
  }
  listarComboTipoBeneficio(){
    return this.http.get(`${this.url_acesso}beneficioscombotipobeneficio/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`,{headers:this.cabecalho});
  }
  listarComboGrupo(){
    return this.http.get(`${this.url_acesso}beneficioscombogrupo/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`,{headers:this.cabecalho});
  }
  listarComboBeneficio(){
    return this.http.get(`${this.url_acesso}beneficioscombo/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`,{headers:this.cabecalho});
  }

  listaVerbasExterna(dtComp){
    return this.http.get(`${this.url_acesso}beneficiosverbasexternas/${this.codTarefa}/${this.tpProcessamento}/${this.filial}/${dtComp}`,{headers:this.cabecalho});
  }

  listaVerbasEmpresa(){
    return this.http.get(`${this.url_acesso}beneficiosverbasempresa/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`,{headers:this.cabecalho});
  }
  listaVerbasFuncionario(){
    return this.http.get(`${this.url_acesso}beneficiosverbasfuncionario/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`,{headers:this.cabecalho});
  }
  listarVerbas(){
    return this.http.get(`${this.url_acesso}beneficiosverbas/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`,{headers:this.cabecalho})
  }

  salvarBeneficio(dados){
    return this.http.put(`${this.url_acesso}beneficios/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`, dados,{headers:this.cabecalho});
  }
  editarBeneficio(dados){
    return this.http.put(`${this.url_acesso}beneficio/alterarbeneficio/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`, dados,{headers:this.cabecalho});
  }
}
