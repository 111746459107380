import { Pipe, PipeTransform } from '@angular/core';
import { RetornoFerias } from 'src/app/service/interfaces/RetornoFerias';

@Pipe({
  name: 'pesquisaFeriasFiltro'
})
export class pesquisaFeriasPipe implements PipeTransform {
  transform(listaFerias: RetornoFerias[], texto: string): any {
    if (texto) {
      return listaFerias.filter(ferias => {
        const nomeFuncionario = removerAcentos(ferias.DadosColaborador.NomeColaborador).toLowerCase();
        const textoSemAcento = removerAcentos(texto).toLowerCase();
        const matricula = ferias.DadosColaborador.Matricula.toString();

        return nomeFuncionario.includes(textoSemAcento) || matricula.includes(texto);
      });
    } else {
      return listaFerias;
    }
  }
}

function removerAcentos(newStringComAcento) {
  var string = newStringComAcento;
  var mapaAcentosHex = {
      a : /[\xE0-\xE6]/g,
      A : /[\xC0-\xC6]/g,
      e : /[\xE8-\xEB]/g,
      E : /[\xC8-\xCB]/g,
      i : /[\xEC-\xEF]/g,
      I : /[\xCC-\xCF]/g,
      o : /[\xF2-\xF6]/g,
      O : /[\xD2-\xD6]/g,
      u : /[\xF9-\xFC]/g,
      U : /[\xD9-\xDC]/g,
      c : /\xE7/g,
      C : /\xC7/g,
      n : /\xF1/g,
      N : /\xD1/g
  };

  for (var letra in mapaAcentosHex) {
      var expressaoRegular = mapaAcentosHex[letra];
      string = string.replace(expressaoRegular, letra);
  }

  return string;
}
