<div class="container">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">
        {{ anual ? 'Convocação do Censo Anual' : 'Configuração do Censo de Aniversário' }}
      </h3>
    </div>
    <!-- Instruções iniciais -->
    <div class="card-body">
      <p class="text-center alert-primary p-2 mt-2 rounded">
        Esta convocação diz respeito ao censo {{ anual ? 'anual' : 'de aniversário' }}. Se você
        deseja {{ anual ? 'configurar o censo de aniversário' : 'convocar o censo anual' }}, por
        favor, escolha a opção correspondente no menu lateral.{{
          anual
            ? ''
            : ' Ao acessar a página
        pela primeira vez, a configuração exibida diz respeito à filial ativa no menu superior. Você
        pode trocar essa filial normalmente, mas a atualização de configurações deve ser feita
        uma filial por vez'
        }}
      </p>

      <!-- Definir informações do censo -->
      <legend>
        <h4>Informações</h4>
      </legend>
      <label>Definir informações</label>
      <form class="form-group form-inline" [formGroup]="formEtapas">
        <div *ngFor="let item of etapasItens">
          <div class="form-check mr-3">
            <input
              class="form-check-input mt-0"
              type="checkbox"
              [formControlName]="item.formControlName"
              (change)="adicionarEtapas(item.formControlName, item.count)"
              [id]="item.count"
            />
            <label class="form-check-label" [for]="item.formControlName">{{ item.label }}</label>
            <a
              class="btn-link text-info ml-1"
              data-toggle="tooltip"
              data-placement="left"
              [title]="item.tooltip"
            >
              <i class="fa fa-info-circle"></i>
            </a>
          </div>
        </div>
        <span class="form-text text-danger" *ngIf="this.etapasSelecionadas.length == 0 && temErro">
          Marque no mínimo uma opção para prosseguir.
        </span>
      </form>
      <!-- Definir filiais -->
      <form [formGroup]="formConvocacao">
        <legend>
          <h4>Filiais</h4>
        </legend>
        <div
          class="d-flex align-items-center justify-content-start form-group"
          data-toggle="modal"
          data-target="#filialModal"
        >
          <button
            type="button"
            class="btn btn-primary rounded-right-0"
            [ngClass]="temErro && filiaisSelecionadas.length == 0 ? 'border-danger' : ''"
          >
            <i class="fa fa-building"></i>
          </button>
          <button
            type="button"
            class="btn btn-light my-auto rounded-left-0 bg-light"
            [ngClass]="temErro && filiaisSelecionadas.length == 0 ? 'border-danger' : ''"
          >
            <span class="text-muted" *ngIf="filiaisSelecionadas.length == 0"
              >Não há nenhuma filial selecionada.</span
            >

            <span class="text-muted" *ngIf="filiaisSelecionadas.length > 0"
              >Total de filiais selecionadas: {{ filiaisSelecionadas.length }}</span
            >
          </button>
        </div>
        <span class="form-text text-danger" *ngIf="this.filiaisSelecionadas.length == 0 && temErro">
          Selecione no mínimo uma filial para prosseguir.
        </span>
        <!-- Definir datas -->
        <legend>
          <h4>Datas</h4>
        </legend>
        <div class="form-row">
          <div class="col-4">
            <label>Data de Início</label>
            <input
              type="date"
              class="form-control"
              max="9999-12-31"
              formControlName="dataInicio"
              [ngClass]="
                (formConvocacao.get('dataInicio')?.touched || temErro) &&
                (formConvocacao.controls['dataInicio'].hasError('required') ||
                  formConvocacao.controls['dataInicio'].hasError('dataMenorQueAtual'))
                  ? 'is-invalid'
                  : ''
              "
              [readonly]="!anual"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                (formConvocacao.get('dataInicio')?.touched || temErro) &&
                (formConvocacao.controls['dataInicio'].hasError('required') ||
                  formConvocacao.controls['dataInicio'].hasError('dataMenorQueAtual'))
              "
            >
              {{
                formConvocacao.controls['dataInicio'].hasError('required')
                  ? 'Campo obrigatório'
                  : 'Data inválida'
              }}
            </span>
          </div>
          <div class="col-4">
            <label>Data de Conclusão</label>
            <input
              type="date"
              class="form-control"
              max="9999-12-31"
              formControlName="dataFim"
              [ngClass]="
                (formConvocacao.get('dataFim')?.touched || temErro) &&
                (formConvocacao.controls['dataFim'].hasError('required') ||
                  formConvocacao.get('dataInicio')?.value > formConvocacao.get('dataFim')?.value)
                  ? 'is-invalid'
                  : ''
              "
              [readonly]="!anual"
            />
            <span
              class="form-text text-danger"
              *ngIf="
                (formConvocacao.get('dataFim')?.touched || temErro) &&
                (formConvocacao.controls['dataFim'].hasError('required') ||
                  formConvocacao.get('dataInicio')?.value > formConvocacao.get('dataFim')?.value)
              "
            >
              {{
                formConvocacao.controls['dataFim'].hasError('required')
                  ? 'Campo obrigatório'
                  : 'Data inválida'
              }}
            </span>
          </div>
        </div>
      </form>
      <legend *ngIf="responsavelAniversario">
        <h4>Responsável</h4>
      </legend>
      <div *ngIf="responsavelAniversario">
        <p>Configurado por {{ responsavelAniversario }}</p>
      </div>
    </div>
    <!-- confirmação -->
    <div class="card-footer text-right">
      <button
        type="button"
        class="btn btn-primary ml-2"
        (click)="confirmarCenso()"
        data-toggle="modal"
        data-target="#confirmacaoModal"
      >
        {{ anual ? 'Convocar' : 'Atualizar' }}
      </button>
    </div>
    <!-- modal filial -->
    <div
      class="modal fade"
      id="filialModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="filialModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header pb-2 border-bottom">
            <h5 class="modal-title" id="filialModalLabel">Definição de Filiais</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row mb-2">
              <div class="input-group col-lg-12">
                <input
                  type="search"
                  placeholder="Buscar Filial"
                  class="form-control"
                  name="buscaFilial"
                  [(ngModel)]="buscaFilial"
                />
                <span>
                  <span class="btn btn-primary ml-1">
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </span>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="table-responsive" style="overflow-y: auto; max-height: 400px">
                  <table style="height: 120px" class="table table-striped table-hover mb-0">
                    <thead class="bg-teal-400">
                      <tr>
                        <th>Código</th>
                        <th>Nome</th>
                        <th class="text-center">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let filial of localStorage.Filiais | filtroFiliais : buscaFilial;
                          let i = index
                        "
                      >
                        <td class="row-md-1">
                          <b>{{ filial.Codigo }}</b>
                        </td>
                        <td class="row-md-1">
                          <b>{{ filial.Nome }}</b>
                        </td>
                        <td class="text-center">
                          <button
                            class="btn btn-sm btn-outline-primary"
                            (click)="anual ? adicionarFiliais(filial, i) : trocarFiliais(filial, i)"
                          >
                            <i class="fa" [class]="anual ? 'fa-plus' : 'fa-refresh'"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h5
                    class="modal-title py-2 border-top mt-1"
                    id="filialModalLabel"
                    *ngIf="filiaisSelecionadas.length > 0"
                  >
                    {{ anual ? 'Filiais Selecionadas' : 'Filial selecionada' }}
                  </h5>
                  <table
                    class="table table-striped table-hover mb-0"
                    *ngIf="filiaisSelecionadas.length > 0"
                  >
                    <thead class="bg-teal-400">
                      <tr>
                        <th>Código</th>
                        <th>Nome</th>
                        <th class="text-center" *ngIf="anual">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let filial of filiaisSelecionadas; let i = index">
                        <td class="row-md-1">
                          <b>{{ filial.Codigo }}</b>
                        </td>
                        <td class="row-md-1">
                          <b>{{ filial.Nome }}</b>
                        </td>
                        <td class="text-center" *ngIf="anual">
                          <button
                            class="btn btn-sm btn-outline-danger"
                            (click)="removerFiliais(filial, i)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal confirmação -->
    <div
      class="modal fade"
      id="confirmacaoModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="confirmacaoModalLabel"
      aria-hidden="true"
      *ngIf="!temErro"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header pb-2 border-bottom">
            <h5 class="modal-title" id="confirmacaoModalLabel">
              Confirmação de {{ anual ? 'Convocação' : 'Atualização' }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h5>Informações</h5>
            <p *ngFor="let etapa of etapasSelecionadas" class="text-capitalize">
              {{
                etapa == 2
                  ? 'Informações Pessoais'
                  : etapa == 3
                  ? 'Contato/Endereço'
                  : etapa == 4
                  ? 'Documentos'
                  : etapa == 5
                  ? 'Dependentes'
                  : 'Especialização'
              }}
            </p>

            <h5 class="modal-title py-2 border-top mt-1">Filiais Selecionadas</h5>
            <table
              class="table table-striped table-hover mb-2"
              *ngIf="filiaisSelecionadas.length > 0"
            >
              <thead class="bg-teal-400">
                <tr>
                  <th>Código</th>
                  <th>Nome</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let filial of filiaisSelecionadas; let i = index">
                  <td class="row-md-1">
                    <b>{{ filial.Codigo }}</b>
                  </td>
                  <td class="row-md-1">
                    <b>{{ filial.Nome }}</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <h5 class="modal-title py-2 border-top mt-1">Datas</h5>
            <div class="form-row">
              <div class="col-6">
                <label>Data de Início</label>
                <input
                  type="date"
                  class="form-control"
                  id="dataInicioConf"
                  [value]="formConvocacao.get('dataInicio').value"
                  disabled
                />
              </div>
              <div class="col-6">
                <label>Data de Conclusão</label>
                <input
                  type="date"
                  class="form-control"
                  id="dataConclusaoConf"
                  [value]="formConvocacao.get('dataFim').value"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary ml-2"
              (click)="anual ? convocarCenso() : atualizarCenso()"
            >
              Confirmar
            </button>
            <button type="button" class="btn btn-danger ml-2" data-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
