import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Aviso } from './avisos.component';

@Injectable({
  providedIn: 'root'
})
export class AvisosService {
  localStorage: LocalStorage = new LocalStorage();

  headers = new HttpHeaders();
  url_acesso: string;
  cabecalho : any;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl();
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
    }
  }
  buscarAvisos() {
    return this.http.get(`${this.url_acesso}ficha-funcional/aviso`, { headers: this.cabecalho });
  }
  buscarTipoAvisos() {
    return this.http.get(`${this.url_acesso}ficha-funcional/aviso/tipo-aviso`, { headers: this.cabecalho });
  }
  buscarMensagem() {
    return this.http.get(`${this.url_acesso}ficha-funcional/mensagem`, { headers: this.cabecalho });
  }
  salvarAvisos(aviso: Aviso) {
    return this.http.post(`${this.url_acesso}ficha-funcional/aviso`,aviso, { headers: this.cabecalho });
  }
  editarAvisos(id: number , aviso: Aviso) {
    return this.http.put(`${this.url_acesso}ficha-funcional/aviso/${id}`,aviso, { headers: this.cabecalho });
  }
  excluirAvisos(id: number) {
    return this.http.delete(`${this.url_acesso}ficha-funcional/aviso/${id}`, { headers: this.cabecalho });
  }
}
