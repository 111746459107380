import { Vinculo } from './vinculo';
import { Cargo } from './cargo';
import { Salario } from './salario';
import { Setor } from './setor';
import { HorarioTrabalho } from './horarioTrabalho';
import { Departamento } from './Departamento';
import { Data } from '../../../interfaces/Data';
import { Responsavel } from './Responsavel';
import { Sindicato } from './sindicato';

export class InformacoesBasicas {
  NomeCompleto: string;
  Departamento: Departamento = new Departamento();
  Setor: Setor = new Setor();
  Descanso: string = '';
  TipoFolha: string;
  DiasUteisColaborador: string;
  DataAdmissao: Data = new Data();
  Vinculo: Vinculo = new Vinculo();
  Cargo: Cargo = new Cargo();
  Responsavel: Responsavel = new Responsavel();
  Salario: Salario = new Salario();
  HorarioTrabalho: HorarioTrabalho = new HorarioTrabalho();
  Sindicato: Sindicato = new Sindicato();
  TipoSanguineo: string;
  constructor() {}
}
