import { CertificadoMilitar } from './../../../cadastro-publico/models/interfaces';
import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AnexoService } from 'src/app/componentes/anexo/anexo.service';
import { Documentos } from 'src/app/service/classes/cadastro/Documentos';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { ErroService } from 'src/app/service/erros/erro.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from 'src/app/componentes/componentes-funcionais/alerta-modal/alerta-modal.component';
import { CadastroService } from 'src/app/componentes/cadastro/cadastro.service';
import { tituloValidator } from 'src/app/service/Validators/tituloValidator';
import Swal from 'sweetalert';
import { DocumentosService } from './documentos-perfil.service';
import { documentos } from 'src/app/service/interfaces/Documentos';

@Component({
  selector: 'app-documentos-perfil',
  templateUrl: './documentos-perfil.component.html',
  styleUrls: ['./documentos-perfil.component.css'],
})
export class DocumentosPerfilComponent {
  @Input() cadastroUsuario: Cadastro;
  @Input() ColaboradorInativo: boolean;
  @Input() mudancaTab: boolean = false;
  @Input() existeCredencial: boolean;

  localStorage: LocalStorage;
  documentosMontados: Documentos;

  formDocumento: FormGroup;
  formEmail: FormGroup;

  listaUf: any[];
  listaCategoriaCnh: any[];
  cnhObrigatoria: boolean = false;

  documentos: Documentos;

  tipoUsuario: string;
  docImage: string = '';

  temErro: boolean = false;
  displayDocumento: boolean = false;
  loading: boolean = false;

  readonly botoes = [
    {
      label: 'Editar',
      color: 'warning',
      icon: 'fa-pencil-square-o',
      action: () => (this.displayDocumento = !this.displayDocumento),
      show: () => !this.displayDocumento && !this.ColaboradorInativo && this.tipoUsuario != '1',
    },
    {
      label: 'Cancelar',
      color: 'danger',
      action: () => this.cancelarEdicao(),
      show: () => this.displayDocumento,
    },
    {
      label: 'Salvar',
      color: 'primary',
      action: () => this.submitDocumentos(),
      show: () => this.displayDocumento,
    },
  ];

  constructor(
    private fb: FormBuilder,
    private documentosService: DocumentosService,
    private cadastroService: CadastroService,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService
  ) {
    this.localStorage = this.decryptLocalStorage();
    this.tipoUsuario = this.localStorage.TipoUsuario;
    this.formDocumento = this.fb.group({
      numeroCpf: [''],
      numeroRg: ['', [Validators.required, Validators.minLength(4)]],
      orgaoEmissorRg: ['', Validators.required],
      dataEmissaoRg: ['', Validators.required],
      ufRg: ['', Validators.required],
      numeroCnh: [''],
      categoriaCnh: [''],
      ufCnh: [''],
      dataValidadeCnh: [''],
      carteiraTrabalho: ['', Validators.required],
      numeroSerieCtps: ['', [Validators.required, Validators.maxLength(5)]],
      ufCtps: ['', Validators.required],
      pis: ['', Validators.required],
      tituloEleitor: ['', [Validators.required, tituloValidator()]],
      secaoEleitoral: ['', Validators.required],
      zonaEleitoral: ['', Validators.required],
      ufEleitoral: ['', Validators.required],
      csm: [''],
      serieCertificadoMilitar: [''],
      numeroCertificadoMilitar: [''],
    });
  }

  ngOnInit() {
    this.tipoUsuario = this.localStorage.TipoUsuario;
    this.buscarCategoriaCnh();
    this.buscarUf();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cadastroUsuario']?.currentValue && this.cadastroUsuario.NumeroDeOrdem) {
      this.documentos = this.cadastroUsuario.Documentos;
      this.carrregarCampos();
    }

    if (changes['mudancaTab']?.currentValue && this.displayDocumento == true) {
      this.cancelarEdicao();
    }
  }

  cancelarEdicao() {
    this.displayDocumento = !this.displayDocumento;
    this.carrregarCampos();
  }
  uppercase(event) {
    event.target.value = event.target.value.toUpperCase();
  }
  formataCPF(cpf: string) {
    if (cpf != null) {
      // Colocar zero a esquerda do cpf
      if (cpf.length < 11) {
        const resto = 11 - cpf.length;
        cpf = '0'.repeat(resto > 0 ? resto : 0) + cpf;
      }
      //retira os caracteres indesejados...
      cpf = cpf.replace(/[^\d]/g, '');
      //realizar a formatação...
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      return '00000000';
    }
  }

  formatarTitulo(titulo) {
    // Remover todos os caracteres não numéricos
    const tituloNumerico = titulo.replace(/\D/g, '');

    if (tituloNumerico.length < 12) {
      const resto = 12 - tituloNumerico.length;
      titulo = '0'.repeat(resto > 0 ? resto : 0) + titulo;
    }

    // Aplicar a máscara para título
    const tituloFormatado = titulo.replace(/^(\d{4})(\d{4})(\d{4})$/, '$1 $2 $3');
    return tituloFormatado;
  }

  formatarPIS(pis) {
    const regex = /^(\d{3})(\d{5})(\d{2})(\d{1})$/;
    const match = pis.match(regex);

    if (!match) {
      return pis;
    }

    const formatted = match[1] + '.' + match[2] + '.' + match[3] + '-' + match[4];
    return formatted;
  }

  formataData(data: string): string {
    data = data.split('/').join('');
    const dia = data.substring(0, 2);
    const mes = data.substring(2, 4);
    const ano = data.substring(4, 8);

    return `${dia}/${mes}/${ano}`;
  }

  buscarCategoriaCnh() {
    this.cadastroService.buscarCategoriaCnh().subscribe(
      (data: any[]) => {
        this.listaCategoriaCnh = data;
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarUf() {
    this.documentosService.buscarEstado().subscribe(
      (data) => {
        this.listaUf = JSON.parse(JSON.stringify(data));
        //this.buscarCidade(this.estado,this.cep.Uf);
      },
      (error) => {
        this.validaErro.retornoErro(error);
      }
    );
  }

  carrregarCampos() {
    const { Documentos } = this.cadastroUsuario;
    if (
      Documentos.TituloEleitor.SecaoEleitoral == '' ||
      Documentos.TituloEleitor.SecaoEleitoral == '0'
    ) {
      Documentos.TituloEleitor.SecaoEleitoral = 'NÃO INFORMADA';
    }
    if (
      Documentos.TituloEleitor.ZonaEleitoral == '' ||
      Documentos.TituloEleitor.ZonaEleitoral == '0'
    ) {
      Documentos.TituloEleitor.ZonaEleitoral = 'NÃO INFORMADA';
    }

    this.formDocumento.get('numeroCpf').setValue(Documentos.Cpf);
    if (this.cadastroUsuario.Stiuacao.Codigo != 98) {
      this.formDocumento.get('numeroCpf').disable();
    }
    this.formDocumento.get('numeroRg').setValue(Documentos.Rg.Numero);
    this.formDocumento.get('orgaoEmissorRg').setValue(Documentos.Rg.OrgaoEmissor);
    this.formDocumento
      .get('dataEmissaoRg')
      .setValue(this.formatarData(Documentos.Rg.DataEmissaoRg.ToDate));
    this.formDocumento.get('ufRg').setValue(Documentos.Rg.UfEmissaoRg);
    this.formDocumento.get('numeroCnh').setValue(Documentos.Cnh.Numero);
    this.formDocumento.get('categoriaCnh').setValue(Documentos.Cnh.CategoriaCnh);
    this.formDocumento.get('ufCnh').setValue(Documentos.Cnh.UF);
    this.formDocumento
      .get('dataValidadeCnh')
      .setValue(
        Documentos.Cnh.DataValidadeCnh.ToDate != ''
          ? this.formatarData(Documentos.Cnh.DataValidadeCnh.ToDate)
          : ''
      );
    this.formDocumento.get('carteiraTrabalho').setValue(Documentos.Clt.CarteiraDeTrabalho);
    this.formDocumento.get('numeroSerieCtps').setValue(Documentos.Clt.NumeroSerieCtps);
    this.formDocumento.get('ufCtps').setValue(Documentos.Clt.UfCtps);
    this.formDocumento.get('pis').setValue(Documentos.Clt.Pis);
    this.formDocumento.get('tituloEleitor').setValue(Documentos.TituloEleitor.Numero);
    this.formDocumento.get('secaoEleitoral').setValue(Documentos.TituloEleitor.SecaoEleitoral);
    this.formDocumento.get('zonaEleitoral').setValue(Documentos.TituloEleitor.ZonaEleitoral);
    this.formDocumento.get('ufEleitoral').setValue(Documentos.TituloEleitor.UfEleitoral);
    this.formDocumento.get('csm').setValue(Documentos.CertificadoMilitar.Csm);
    this.formDocumento.get('serieCertificadoMilitar').setValue(Documentos.CertificadoMilitar.Serie);
    this.formDocumento.get('numeroCertificadoMilitar').setValue(Documentos.CertificadoMilitar.Numero);
    // this.formDocumento
    //   .get('serieCertificadoMilitar')
    //   .setValue(Documentos.CertificadoMilitar.Numero);
    // this.formDocumento
    //   .get('serieCertificadoMilitar')
    //   .setValue(Documentos.CertificadoMilitar.Numero);
  }

  submitDocumentos() {
    if (!this.formDocumento.valid) {
      Swal('Atenção', 'Preencha os campos corretamente', 'warning');
      this.temErro = true;
    } else {
      this.salvarDocumentos();
      this.temErro = false;
    }
  }

  salvarDocumentos() {
    this.loading = true;

    this.montarDocumentos();
    const documentos = this.documentosMontados;
    this.documentosService
      .alterarCadastroPerfil(documentos, this.cadastroUsuario.NumeroDeOrdem)
      .subscribe(
        (data) => {
          this.displayDocumento = false;
          Swal('Sucesso', 'O cadastro foi alterado', 'success');
          this.cadastroUsuario = { ...this.cadastroUsuario, Documentos: documentos };

          this.documentos = this.cadastroUsuario.Documentos;
          this.loading = false;
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.loading = false;
        }
      );
  }

  montarDocumentos() {
    const documentos: documentos = this.formDocumento.getRawValue();

    this.documentosMontados = {
      Rg: {
        DataEmissaoRg: {
          SetData: documentos.dataEmissaoRg,
          ToDate: this.formataData(this.formatarDataEnvio(documentos.dataEmissaoRg)),
        },
        Numero: documentos.numeroRg,
        OrgaoEmissor: documentos.orgaoEmissorRg,
        UfEmissaoRg: documentos.ufRg,
      },
      Cnh: {
        CategoriaCnh: documentos.categoriaCnh ? documentos.categoriaCnh : '',
        DataValidadeCnh: {
          SetData: documentos.dataValidadeCnh,
          ToDate:
            documentos.dataValidadeCnh != ''
              ? this.formataData(this.formatarDataEnvio(documentos.dataValidadeCnh))
              : '',
        },
        Numero: documentos.numeroCnh ? documentos.numeroCnh : '',
        UF: documentos.ufCnh ? documentos.ufCnh : '',
      },
      Clt: {
        CarteiraDeTrabalho: documentos.carteiraTrabalho,
        NumeroSerieCtps: documentos.numeroSerieCtps,
        Pis: documentos.pis,
        UfCtps: documentos.ufCtps,
      },
      TituloEleitor: {
        Numero: documentos.tituloEleitor,
        SecaoEleitoral: documentos.secaoEleitoral,
        ZonaEleitoral: documentos.zonaEleitoral,
        UfEleitoral: documentos.ufEleitoral,
      },
      Cpf: documentos.numeroCpf,
      CertificadoMilitar: {
        Numero: documentos.numeroCertificadoMilitar,
        Csm: documentos.csm,
        Serie: documentos.serieCertificadoMilitar.toUpperCase(),
      },
    };
  }

  // onCnhChange() {
  //   const { numeroCnh } = this.formDocumento.value;
  //   if (numeroCnh !== '') {
  //     if (this.cnhObrigatoria == false) {
  //       // this.cnhObrigatorio();
  //       // this.cnhObrigatoria = true;
  //     }
  //   } else {
  //     if (this.cnhObrigatoria == true) {
  //       // this.cnhNaoObrigatorio();
  //       // this.cnhObrigatoria = false;
  //     }
  //   }
  // }

  // cnhObrigatorio() {
  //   this.formDocumento.get('numeroCnh').setValidators(Validators.minLength(11));
  //   this.formDocumento.get('categoriaCnh').setValidators(Validators.required);
  //   this.formDocumento.get('ufCnh').setValidators(Validators.required);
  //   this.formDocumento.get('dataValidadeCnh').setValidators(Validators.required);
  // }

  // cnhNaoObrigatorio() {
  //   this.formDocumento.get('numeroCnh').setValidators(null);
  //   this.formDocumento.get('numeroCnh').updateValueAndValidity();
  //   this.formDocumento.get('categoriaCnh').setValidators(null);
  //   this.formDocumento.get('categoriaCnh').updateValueAndValidity();
  //   this.formDocumento.get('ufCnh').setValidators(null);
  //   this.formDocumento.get('ufCnh').updateValueAndValidity();
  //   this.formDocumento.get('dataValidadeCnh').setValidators(null);
  //   this.formDocumento.get('dataValidadeCnh').updateValueAndValidity();
  // }

  somenteNumero(event) {
    return (
      event.charCode >= 48 &&
      event.charCode <= 57 /*|| (event.keyCode == 45 || event.charCode == 46)*/
    );
  }

  formatarDataEnvio(data: string): string {
    let dataSplit = data.split('-');
    const dataFormatada = `${dataSplit[2]}${dataSplit[1]}${dataSplit[0]}`;
    return dataFormatada;
  }

  formatarData(data: string): string {
    let dataSplit = data.split('/');
    const dataFormatada = `${dataSplit[2]}-${dataSplit[1]}-${dataSplit[0]}`;

    return dataFormatada;
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}
