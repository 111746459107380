import { Stiuacao } from './../../service/classes/cadastro/stiuacao';
// import { InformacoesBasicas } from './../../service/classes/cadastro/informacoesbasicas/informacoesBasicas';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { TransferenciaFuncionarioService } from '../transferencia-funcionario/transferencia-funcionario.service';
import { ColaboradoresService } from './colaboradores.service';
import { Usuario } from './../cadastro-publico/models/interfaces';
import { ErroService } from '../../service/erros/erro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert';
import { ExcelService } from 'src/app/service/arquivo/excel.service';
import { Data } from 'src/app/service/interfaces/Data';
import { CadastroPublicoService } from '../cadastro-publico/cadastro-publico.service';
import { Sexo } from '../cadastro/interface/sexo';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { Filial } from 'src/app/service/classes/localstorage/Filial';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { AnexoService } from '../anexo/anexo.service';
import { CadastroAprendizService } from '../complemento-cadastral/cadastro-aprendiz/cadastro-aprendiz.service';
import { CadastroEstagioService } from '../complemento-cadastral/cadastro-estagio/cadastro-estagio.service';
let tamanhoColaboradores: number = 0;

export function alterarTamanhoColaboradores(valor: number) {
  tamanhoColaboradores = valor;
}

@Component({
  selector: 'app-colaboradores',
  templateUrl: './colaboradores.component.html',
  styleUrls: ['./colaboradores.component.css'],
})
export class ColaboradoresComponent implements OnInit, OnDestroy {
  listagemTodosColaboradores: any[] = [];
  page: number = 1;
  paginacao: string = '01';
  totalColaboradores: number = 0;
  listaFiltros: string[];
  localStorage: LocalStorage;
  rota: string = '/colaborador';
  seta: boolean = false;
  filialForm: FormGroup;
  formTransferencia: FormGroup;
  filtroLicenciado: boolean = false;
  filtros: boolean = true;
  filtroSituacao: boolean = true;
  empfil!: number;
  filiais: Filial[];
  listaFilialDestino: Filial[];
  transferenciaNovaFilial: boolean = null;
  listaLotacao: any[];
  loadingLotacao: boolean = false;
  loadingTranferencia: boolean = false;
  modalFiltro: boolean = false;

  listafiltroColaborador!: any[];

  colaborador: any[] = [];
  colaborador2: any[];
  usuarioColaborador: any[];

  ativos: any[] = [];
  ativosCheckbox: boolean = false;

  emAdmissao: any[] = [];
  emAdmissaoCheckbox: boolean = false;

  cadastroPendente: any[] = [];
  cadastroPendenteCheckbox: boolean = false;

  emDesligamento: any[] = [];
  emDesligamentoCheckbox: boolean = false;

  licenciados: any[] = [];
  licenciadosCheckbox: boolean = false;

  ferias: any[] = [];
  feriasCheckbox: boolean = false;

  colaboradorAtual: any;
  colaboradores: any[] = [];
  searchText: string = '';

  // filtro pra licenciados

  ordemNome: number = 0;
  ordemMatricula: number = 1;
  ordemCPF: number = 0;
  checado: boolean = false;
  auxLicenciado: boolean = false;
  switchFiltroBool: boolean = false;
  carregado: boolean = false;
  limpaFiltro: boolean = false;
  isComplemento: boolean = false;
  aprendiz: Aprendiz;
  estagio: Estagio;

  distinctSituacao: SituacaoColaborador[] = [];
  efetivarParametros = { NumeroDeOrdem: '', Matricula: '', Etapa: 0 };
  matricula: number = 0;
  filialAtual: number;
  listaFiliais: any[];
  //mostraDrop = true
  constructor(
    private transferenciaServico: TransferenciaFuncionarioService,
    private aprendizService: CadastroAprendizService,
    private estagioService: CadastroEstagioService,
    private formBuilder: FormBuilder,
    private colaborador_service: ColaboradoresService,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private excelService: ExcelService,
    private cadastroPublicoService: CadastroPublicoService,
    private anexoService: AnexoService
  ) {
    const localStorageConstrutor = this.decryptLocalStorage();
    this.listaFiliais = localStorageConstrutor.Filiais;
    this.filialAtual = localStorageConstrutor.FilialAtual;

    this.listaFilialDestino = this.listaFiliais.filter(
      (filial) => this.filialAtual != filial.Codigo
    );
  }
  ngOnDestroy(): void {}

  ngOnInit() {
    this.page = 1;
    this.paginacao = String(this.page).padStart(2, '0');
    this.buscarFiltros();
    this.localStorage = this.decryptLocalStorage();
    this.empfil = Number(this.localStorage.FilialAtual);
    this.buscarTodosColaboradores();
    this.filiais = [
      this.localStorage.Filiais.find(
        (a) => a.Codigo == this.empfil && a.Nome == this.localStorage.NomeFilialAtual
      ),
    ];
    this.colaboradorAtual = {
      Matricula: '',
      Situacao: {
        Codigo: '0',
      },
    };

    window.scrollTo(0, 0);
    this.filialForm = this.formBuilder.group({
      filialControl: this.filiais[0],
    });

    this.formTransferencia = this.formBuilder.group({
      filial: ['', Validators.required],
      lotacao: [{ value: '', disabled: true }, Validators.required],
      dataEvento: ['', [Validators.required]],
    });
  }

  // habilitarFiltro() {
  //   this.spinner.show();
  //   this.colaborador_service.habilitarFiltro().subscribe(
  //     (data: any[]) => {
  //       console.log("habilitar filtro", data);
  //       this.buscarFiltros();
  //     },
  //     (error) => {
  //       this.spinner.hide();
  //     }
  //   );
  // }
  buscarTodosColaboradores() {
    const filtroSituacao = this.listaFiltros ? this.listaFiltros : ['99'];
    const parametros = this.montarConsulta(filtroSituacao);
    if (this.listagemTodosColaboradores && this.listagemTodosColaboradores.length === 0) {
      this.colaborador_service.buscarTodosColaboradores(parametros).subscribe(
        (data: any) => {
          this.listagemTodosColaboradores = data;
        },
        (error) => {
          new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
        }
      );
    }
  }

  gerarListaColaboradores() {
    if (this.searchText === '') {
      this.colaborador = this.colaborador2;
    } else {
      this.colaborador = this.listagemTodosColaboradores;
    }
  }

  verificaColabClicado(colab: any) {
    this.colaboradorAtual = colab;

    this.matricula = colab.Matricula;

    if (colab.TipoComplemento !== '') {
      this.isComplemento = true;
    }
  }

  buscarFiltros(): void {
    this.spinner.show();
    this.colaborador_service.buscarFiltros().subscribe(
      (data: InformacaoGenerica[]) => {
        this.listafiltroColaborador = data;

        // if (data.some((valor) => valor.Codigo == '99')) {
        // }
        this.buscarColaboradorAtivo();
        // else {
        //   this.verificaCheckFiltro();
        // }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  exportAsXLSX(): void {
    this.spinner.show();
    let colaboradoresData: any[] = [];
    this.colaborador.forEach((item) => {
      colaboradoresData.push({
        'NOME COMPLETO': item.NomeCompleto,
        'EMPRESA FILIAL': item.Empfil,
        'NÚMERO DE ORDEM': item.NumeroDeOrdem,
        MATRÍCULA: item.Matricula,
        CPF: item.Cpf,
        ETAPA: item.Etapa,
        SETOR: item.NomeSetor,
        CARGO: item.NomeCargo,
        SEXO: item.Sexo.Descricao,
        SITUAÇÃO: item.Situacao.Descricao,
      });
    });
    this.excelService.exportAsExcelFile(colaboradoresData, 'Relatório dos Colaboradores');
    this.spinner.hide();
  }

  retornarColaboradoresTotal() {
    return this.colaborador2;
  }

  // Faz a listagem de todos os colaboradores
  moverParaPerfil() {
    if (this.colaboradorAtual.Situacao.Codigo != 97) {
      this.rota = '/perfil/' + this.colaboradorAtual.NumeroDeOrdem;
      this.router.navigate([this.rota]);
    }
    this.fecharModal();
  }

  moverParaTransferencia(colab: any) {
    this.rota = '/transferencia/' + colab.NumeroDeOrdem;
    this.router.navigate([this.rota]);
  }

  navegarParaRota(rota: string) {
    const navigationExtras: NavigationExtras = {
      state: {
        usuario: {
          cpf: this.colaboradorAtual.Cpf,
          nome: this.colaboradorAtual.NomeCompleto,
          matricula: this.colaboradorAtual.Matricula,
          numOrdem: this.colaboradorAtual.NumeroDeOrdem,
          vinculo: this.colaboradorAtual.Vinculo + ' - ' + this.colaboradorAtual.DescricaoVinculo,
          situacao: this.colaboradorAtual.Situacao,
        },
      },
    };
    this.router.navigate([rota], navigationExtras);
    this.fecharModal();
  }

  // Verifica se algum elemento do filtro será marcado ao abrir o

  verificaCheckFiltro() {
    this.carregado = true;
    // 0 - Marca como ativo
    // 1 - Marca como férias
    // 2 - Marca como em admissão/pendente
    let homeClick = localStorage.getItem('colaboradores');
    if (homeClick != null) {
      homeClick = JSON.parse(
        new Crypto().decryptUsingAES256(localStorage.getItem('colaboradores'))
      );
      if (homeClick == '0') {
        this.listafiltroColaborador.map((lista) => {
          lista.Descricao === 'ATIVO' ? (lista.Situacao = true) : (lista.Situacao = false);
        });
        var listaFiltrada = this.listafiltroColaborador.filter((lista) => {
          lista.Situacao;
        });
        // listaFiltrada.length > 0
        //  ? this.buscarColaboradoresFiltrados()
        //  : document.getElementById('openModalButton').click();
      } else if (homeClick == '1') {
        this.listafiltroColaborador.map((lista) => {
          lista.Descricao === 'FERIAS' || lista.Descricao === 'FÉRIAS'
            ? (lista.Situacao = true)
            : (lista.Situacao = false);
        });
        var listaFiltrada = this.listafiltroColaborador.filter((lista) => {
          lista.Situacao;
        });
        // listaFiltrada.length > 0
        //   ? this.buscarColaboradoresFiltrados()
        //   : document.getElementById('openModalButton').click();
      } else if (homeClick == '2') {
        this.listafiltroColaborador.map((lista) => {
          lista.Descricao === 'EM ADMISSAO' ||
          lista.Descricao === 'EM ADMISSÃO' ||
          lista.Descricao === 'CADASTRO PENDENTE'
            ? (lista.Situacao = true)
            : (lista.Situacao = false);
        });
        var listaFiltrada = this.listafiltroColaborador.filter((lista) => {
          lista.Situacao;
        });
        // listaFiltrada.length > 0
        //   ? this.buscarColaboradoresFiltrados()
        //   : document.getElementById('openModalButton').click();
      }
      localStorage.removeItem('colaboradores');
    }
    // else {
    // document.getElementById('openModalButton').click();
    // }
  }

  efetuarFiltragem() {
    this.colaborador = this.colaborador2;
    var filtro = this.distinctSituacao.filter((filtro) => filtro.Situacao == true);

    if (filtro.length == 0) {
      this.colaborador = this.colaborador2;
    } else {
      this.colaborador = this.colaborador.filter((colab) =>
        filtro.find((fil) => fil.Codigo == colab.Situacao.Codigo)
      );
      alterarTamanhoColaboradores(this.colaborador.length);
    }
  }

  montarConsulta(listaFiltros: string[]): envioConsulta {
    if (history.state.filtro && history.state.filtro.length > 0) {
      return {
        filtros: history.state.filtro,
        page: this.page,
      };
    } else {
      return {
        filtros: listaFiltros,
        page: this.page,
      };
    }
  }

  montaSituacaoDinamica(data: any) {
    const situacao: SituacaoColaborador[] = data.map(function (item, indice) {
      var obj2 = { Situacao: false, Tam: 0 };
      var situacaoAux = { ...item.Situacao, ...obj2 };

      return situacaoAux;
    });
    this.distinctSituacao = situacao.filter(
      (thing, i, arr) => arr.findIndex((t) => t.Codigo === thing.Codigo) === i
    );

    this.distinctSituacao.map(
      (xy) =>
        (xy.Tam = data.filter(function (x) {
          return x.Situacao.Codigo == xy.Codigo;
        }).length)
    );

    this.distinctSituacao.sort(this.ordenarListas);
  }

  ordenarListas(a: any, b: any): number {
    if (a.Descricao < b.Descricao) {
      return -1;
    } else if (a.Descricao > b.Descricao) {
      return 1;
    } else {
      return 0;
    }
  }

  listarColaboradores() {
    this.spinner.show();
    const parametros = this.montarConsulta(this.listafiltroColaborador);
    this.colaborador_service.buscarColaboradores(parametros).subscribe(
      (data: any) => {
        this.montaSituacaoDinamica(data.CadastrosFiltrados);

        if (this.limpaFiltro) {
          this.limparfiltro();
        }

        this.colaborador = data.CadastrosFiltrados;
        this.colaborador2 = data.CadastrosFiltrados;

        //
        this.spinner.hide();
        //
        // this.efetuarFiltragemCalculando();
        this.verificaCheckFiltro();
        //
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  switchFiltro(alterar: boolean) {
    this.switchFiltroBool = alterar;
    const filtro = document.getElementById('filtros');
    const colaboradores = document.getElementById('colaboradores');
    const arrow = document.getElementById('arrowIcon');

    if (this.switchFiltroBool) {
      arrow.classList.remove('fa-arrow-right');
      arrow.classList.add('fa-arrow-left');
      filtro.classList.add('d-none');
      colaboradores.classList.remove('col-lg-8', 'col-xl-9');
      colaboradores.classList.add('col-xl-12', 'col-lg-12');
    } else {
      filtro.classList.remove('d-none');
      arrow.classList.remove('fa-arrow-left');
      arrow.classList.add('fa-arrow-right');
      colaboradores.classList.remove('col-xl-12', 'col-lg-12');
      colaboradores.classList.add('col-lg-8', 'col-xl-9');
    }
  }

  alterarTamanhoColaboradoresFiltroPipe() {
    if (tamanhoColaboradores == 0) {
      tamanhoColaboradores = this.colaborador.length;
    }
  }

  collapseFiltro() {
    if (this.filtros) {
      this.filtros = false;
      this.filtroSituacao = false;
    } else {
      this.filtros = true;
    }
  }

  collapseFiltroSituacao() {
    if (this.filtroSituacao) {
      this.filtroSituacao = false;
    } else {
      this.filtroSituacao = true;
    }
  }

  limparfiltro() {
    this.distinctSituacao.filter((filtro) => (filtro.Situacao = false));
    this.efetuarFiltragem();
    this.limpaFiltro = false;

    // this.alterarfiltroLicenciado();
    // this.efetuarFiltragem();
  }
  capitalize(nome: string) {
    const arrayNome = nome.toLowerCase().split(' ');
    const nomeInicialMaiuscula = arrayNome
      .map((nome) => nome.charAt(0).toUpperCase() + nome.slice(1))
      .join(' ');
    return nomeInicialMaiuscula;
  }
  collapseFiltroLicenciados() {
    if (this.filtroLicenciado) {
      this.filtroLicenciado = false;
    } else {
      this.filtroLicenciado = true;
    }
  }

  retornarTamanhoColaboradores() {
    return this.totalColaboradores.toLocaleString('pt');
  }

  formataCPF(cpf) {
    // Colocar zero a esquerda do cpf
    if (cpf.length < 11) {
      const resto = 11 - cpf.length;
      cpf = '0'.repeat(resto > 0 ? resto : 0) + cpf;
    }

    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, '');

    //realizar a formataÃ§Ã£o...
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  fecharModal() {
    document.getElementById('modalClose').click();
  }

  ativarCadastro(): void {
    this.spinner.show();
    const pendencias = [];
    let mensagem = '';
    this.anexoService
      .buscarPendencias(this.colaboradorAtual.NumeroDeOrdem)
      .subscribe((data: any) => {
        for (const chave in data.documentosAnexados) {
          if (data.documentosAnexados[chave] === 1) {
            pendencias.push(chave);
          }
        }
        mensagem =
          'Deseja efetivar o cadastro de ' +
          this.colaboradorAtual.NomeCompleto +
          '?' +
          (pendencias.length > 0
            ? ' Esse colaborador tem ' +
              pendencias.length +
              ' documento(s) pendente(s), caso deseje realmente prosseguir, confirme abaixo.'
            : '');
        this.fecharModal();
        this.spinner.hide();
        Swal({
          title: mensagem,
          text: 'Confirme para executar a ativação do cadastro.',
          icon: pendencias.length > 0 ? 'warning' : 'info',
          dangerMode: pendencias.length > 0 ? true : false,
          buttons: {
            cancel: {
              text: 'Cancelar',
              value: false,
              visible: true,
            },
            confirm: {
              text: 'Ok',
              value: true,
              visible: true,
            },
          },
        }).then((willDelete) => {
          if (willDelete) {
            const clientePublico = this.localStorage.ClientePublico;
            if (clientePublico) {
              this.colaboradorAtual.Etapa = 4;
              this.efetivarCadastroPublico(this.colaboradorAtual);
              location.reload();
            } else {
              this.efetivarCadastro(this.colaboradorAtual);
              location.reload();
            }
          }
        });
      });
  }

  concluirCadastro() {
    this.fecharModal();
    let colaborador = this.colaboradorAtual;
    Swal({
      title: 'Deseja Continuar o Cadastro de ' + this.colaboradorAtual.NomeCompleto + '?',
      text: 'Confirme para ir até a página de alterar o cadastro.',
      icon: 'info',
      dangerMode: false,
      buttons: {
        cancel: {
          text: 'Cancelar',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        const clientepublico = this.localStorage.ClientePublico;
        //this.router.navigate([`cadastro-pendente/${colaborador.NumeroDeOrdem}`]);
        // alterna entre telas que serãoa cessadas cadastro publico ou cadastro privado

        clientepublico === true
          ? this.router.navigate([`cadastro-publico/`], {
              state: { colaborador },
            })
          : this.router.navigate([`cadastro-pendente/${colaborador.NumeroDeOrdem}`]);
      }
    });
  }

  concluirCadastroComplemento() {
    this.fecharModal();
    let colaborador = this.colaboradorAtual;
    if (this.colaboradorAtual.TipoComplemento === 'ESTAGIARIO') {
      this.buscarEstagio(colaborador.NumeroDeOrdem);
    } else if (this.colaboradorAtual.TipoComplemento === 'APRENDIZ') {
      this.buscarAprendiz(colaborador.NumeroDeOrdem);
    }
    Swal({
      title:
        'Deseja Preencher o Complemento Cadastral de ' + this.colaboradorAtual.NomeCompleto + '?',
      text: 'Confirme para ir até a página do complemento cadastral.',
      icon: 'info',
      dangerMode: false,
      buttons: {
        cancel: {
          text: 'Cancelar',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        //const clientepublico = localStorage.getItem('clientePublico')
        let numOrd = colaborador.NumeroDeOrdem;
        let matricula = colaborador.Matricula;

        if (this.colaboradorAtual.TipoComplemento === 'CEDIDO') {
          this.router.navigate([`complemento-cadastral/cessao/cadastro`], {
            state: { data: { matricula, editar: false, cessao: null } },
          });
        } else if (this.colaboradorAtual.TipoComplemento === 'EXPERIENCIA') {
          this.router.navigate([`complemento-cadastral/contrato-experiencia/cadastro`], {
            state: {
              data: {
                numOrd,
                editar: false,
                contrato: null,
                NovoCadastro: true,
              },
            },
          });
        } else if (this.colaboradorAtual.TipoComplemento === 'ESTAGIARIO') {
          let estagio = this.estagio;
          this.router.navigate([`complemento-cadastral/estagio/cadastro`], {
            state: {
              data: { numOrd, editar: false, estagio, NovoCadastro: true },
            },
          });
        } else if (this.colaboradorAtual.TipoComplemento === 'APRENDIZ') {
          let aprendiz = this.aprendiz;
          this.router.navigate([`complemento-cadastral/aprendiz/cadastro`], {
            state: {
              data: { numOrd, editar: false, aprendiz, NovoCadastro: true },
            },
          });
        }
        //clientepublico === "true" ? this.router.navigate([`cadastro-publico/`], { state: { colaborador } }) : this.router.navigate([`cadastro-pendente/${colaborador.NumeroDeOrdem}`]);
      }
    });
  }

  cancelarCadastro() {
    Swal({
      title: 'Deseja Excluir o Cadastro de ' + this.colaboradorAtual.NomeCompleto + '?',
      text: 'Confirme para executar a exclusão do cadastro.',
      icon: 'info',
      dangerMode: false,
      buttons: {
        cancel: {
          text: 'Cancelar',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        this.excluirCadastro(this.colaboradorAtual);
      }
    });

    this.fecharModal();
  }

  onScroll() {}

  buscarEfetivarCadastro(colaborador: any) {
    this.spinner.show();
    this.colaborador_service
      .buscarColaboradorEfetivar(colaborador.NumeroDeOrdem, colaborador.Matricula)
      .subscribe(
        (data) => {
          this.spinner.hide();
          this.usuarioColaborador = JSON.parse(JSON.stringify(data));
          this.usuarioColaborador[0].Etapa = 4;
        },
        (error) => {
          this.spinner.hide();
          new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
        }
      );
  }

  // Confirma o cadastro do colaborador
  efetivarCadastro(colaborador: any): void {
    this.spinner.show();
    this.efetivarParametros.NumeroDeOrdem = colaborador.NumeroDeOrdem;
    this.efetivarParametros.Matricula = colaborador.Matricula;
    this.efetivarParametros.Etapa = 4;

    this.colaborador_service.efetivarCadastro(colaborador.NumeroDeOrdem).subscribe(
      (data) => {
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'Efetivado com sucesso!', 'p');
        this.limpaFiltro = true;
        this.buscarColaboradoresFiltrados();
        this.criarUsuario(colaborador);
        scrollTo(0, 0);
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  efetivarCadastroPublico(colaborador: any): void {
    this.spinner.show();
    this.efetivarParametros.NumeroDeOrdem = colaborador.NumeroDeOrdem;
    this.efetivarParametros.Matricula = colaborador.Matricula;
    this.efetivarParametros.Etapa = 4;

    this.colaborador_service.efetivarCadastroPublico(colaborador.NumeroDeOrdem).subscribe(
      (data) => {
        this.spinner.hide();
        new AlertaModal('sucesso', 'Sucesso!', 'Efetivado com sucesso!', 'p');
        this.limpaFiltro = true;
        this.buscarColaboradoresFiltrados();
        this.criarUsuario(colaborador);
        scrollTo(0, 0);
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  criarUsuario(cadastro: any): void {
    const usuario: Usuario = this.montaUsuario(cadastro);

    this.spinner.show();
    this.cadastroPublicoService.criarUsuario(usuario).subscribe(
      (data) => {
        if (data) {
          this.spinner.hide();
          new AlertaModal('sucesso', 'Sucesso!', 'Usuario Criado.', 'p');
        }
        this.buscarColaboradoresFiltrados();
      },
      (error) => {
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');

        this.spinner.hide();
      }
    );
  }

  montaUsuario(colaborador: Colaborador): Usuario {
    var filial = this.localStorage.FilialAtual.toString();
    var nomeFilial = this.localStorage.NomeFilialAtual;
    var codigoCliente = this.localStorage.ClienteId.toString();

    return {
      Email: colaborador.Email,
      Cpf: colaborador.Cpf,
      Nome: colaborador.NomeCompleto,
      EmpresaFilial: filial,
      NomeEmpresaFilial: nomeFilial,
      NumeroDeOrdem: Number(colaborador.NumeroDeOrdem),
      CodigoCliente: codigoCliente,
    };
  }

  // Faz a listagem de todos os colaboradores
  excluirCadastro(colaborador) {
    this.spinner.show();

    this.colaborador_service
      .excluirCadastro(colaborador.NumeroDeOrdem, colaborador.Matricula)
      .subscribe(
        (data) => {
          this.spinner.hide();
          new AlertaModal(
            'sucesso',
            'Sucesso!',
            'Confirmação da exclusão realizada com sucesso!',
            'p'
          );
          this.limpaFiltro = true;
          scrollTo(0, 0);

          this.buscarColaboradoresFiltrados();
        },
        (error) => {
          this.spinner.hide();
          new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
        }
      );
  }

  transferenciaMesmaEmpfil() {
    this.transferenciaNovaFilial = false;
    this.formTransferencia.get('filial').clearValidators();
    this.formTransferencia.get('filial').updateValueAndValidity();
    this.formTransferencia.get('lotacao').setValue('');
    this.formTransferencia.get('dataEvento').setValue('');

    this.buscarLotacao();
    this.formTransferencia.get('lotacao').disable();
  }

  transferenciaNovaEmpfil() {
    this.transferenciaNovaFilial = true;

    this.formTransferencia.get('filial').setValidators(Validators.required);
    this.formTransferencia.get('filial').updateValueAndValidity();
    this.formTransferencia.get('filial').setValue('');
    this.formTransferencia.get('lotacao').setValue('');
    this.formTransferencia.get('dataEvento').setValue('');
    this.formTransferencia.get('lotacao').disable();
  }

  buscarLotacao() {
    this.loadingLotacao = true;
    this.formTransferencia.get('lotacao').setValue('');

    const empFilAtual = this.localStorage.FilialAtual;
    const empresaFilial =
      this.formTransferencia.get('filial').value == ''
        ? empFilAtual
        : this.formTransferencia.get('filial').value;
    this.colaborador_service.buscarLotacao(empresaFilial).subscribe(
      (data) => {
        this.listaLotacao = JSON.parse(JSON.stringify(data));
        this.formTransferencia.get('lotacao').enable();
        this.loadingLotacao = false;
      },
      (error) => {
        this.loadingLotacao = false;
        this.validaErro.retornoErro(error);
      }
    );
  }

  // verificaDataEvento(dataEvento: AbstractControl) {
  //   const dataEventoValue = dataEvento.value.replace(/-/gi, '').replace(/^0+/, '');

  //   if (dataEventoValue.length == 8) {
  //     const dataAtual = new Date();
  //     const dataAtualInvertida = Number(
  //       dataAtual.getFullYear().toString() +
  //         (dataAtual.getMonth() + 1).toString().padStart(2, '0') +
  //         dataAtual.getDate().toString().padStart(2, '0')
  //     );
  //     if (dataAtualInvertida > Number(dataEventoValue)) {
  //       dataEvento.setValue('');
  //       new AlertaModal(
  //         'alerta',
  //         'Atenção',
  //         'A data da transferência não pode ser anterior a data atual.',
  //         'p'
  //       );
  //     }
  //   }
  // }
  closeModal() {
    //document.getElementById('closeModalTranferencia').click();
    this.transferenciaNovaFilial = null;
    this.formTransferencia.markAsPristine();
  }

  marcarTodosColaboradores() {
    this.listafiltroColaborador.map((filtro) => {
      filtro.Situacao = true;
    });
  }

  limparFiltros() {
    this.listafiltroColaborador.map((filtro) => {
      filtro.Situacao = false;
    });
  }

  buscarColaboradoresFiltrados(existeAtivo?: any): void {
    this.page = 1;

    this.ordemNome = 0;
    this.ordemMatricula = 1;
    this.listaFiltros = this.listafiltroColaborador
      .filter((situacao) => situacao.Situacao)
      .map((ObjSituacao) => ObjSituacao.Codigo);

    let parametros = this.montarConsulta(this.listaFiltros);
    if (
      this.listafiltroColaborador
        .filter((situacao) => situacao.Situacao)
        .map((ObjSituacao) => ObjSituacao.Codigo).length > 0
    ) {
      this.spinner.show();
      this.colaborador_service.buscarColaboradores(parametros).subscribe(
        (data: any) => {
          this.colaborador = data.CadastrosFiltrados;
          this.colaborador2 = data.CadastrosFiltrados;
          this.totalColaboradores = data.TotalRegistros;

          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
        }
      );
    } else {
      this.spinner.hide();
      this.buscarColaboradoresSemFiltro();
      // new AlertaModal('erro', 'Selecione um item', 'Por favor selecione pelo menos um item', 'p');
    }
    let filtroSituacao = this.listaFiltros
      ? this.listaFiltros.length > 0
        ? this.listaFiltros
        : ['99']
      : ['99'];
    const parametroBuscarTodos = this.montarConsulta(filtroSituacao);

    this.colaborador_service.buscarTodosColaboradores(parametroBuscarTodos).subscribe(
      (data: any) => {
        this.listagemTodosColaboradores = data;

        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
    
  }

  buscarColaboradorAtivo() {
    this.spinner.show();
    let aux: string[] = ['99'];
    let parametros = this.montarConsulta(aux);
    this.colaborador_service.buscarColaboradores(parametros).subscribe(
      (data: any) => {
        this.carregado = true;
        this.colaborador = data.CadastrosFiltrados;
        this.colaborador2 = data.CadastrosFiltrados;
        this.totalColaboradores = data.TotalRegistros;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  buscarColaboradoresSemFiltro() {
    this.spinner.show();
    let aux: string[] = ['99'];
    let parametros = this.montarConsulta(aux);
    this.colaborador_service.buscarColaboradores(parametros).subscribe(
      (data: any) => {
        this.carregado = true;
        this.colaborador = data.CadastrosFiltrados;
        this.colaborador2 = data.CadastrosFiltrados;
        this.totalColaboradores = data.TotalRegistros;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
      }
    );
  }

  paginaAnterior() {
    this.spinner.show();
    if (this.page > 1) {
      --this.page;
      const filtro = this.listaFiltros ? this.listaFiltros : ['99'];
      let parametros = this.montarConsulta(filtro);
      this.colaborador_service.buscarColaboradores(parametros).subscribe({
        next: (data: any) => {
          this.spinner.hide();
          this.colaborador = data.CadastrosFiltrados;
          this.colaborador2 = data.CadastrosFiltrados;
          this.paginacao = String(this.page).padStart(2, '0');
        },
        error: () => {
          this.spinner.hide();
          new AlertaModal('erro', 'Erro', 'Não há resultados a serem exibidos!', 'p');
        },
      });
    } else {
      this.spinner.hide();
      new AlertaModal('erro', 'Erro', 'Não há resultados a serem exibidos!', 'p');
    }
    this.spinner.hide();
  }

  proximaPagina() {
    this.spinner.show();
    ++this.page;
    const filtro = this.listaFiltros ? this.listaFiltros : ['99'];
    const parametros = this.montarConsulta(filtro);
    this.colaborador_service.buscarColaboradores(parametros).subscribe(
      (data: any) => {
        this.spinner.hide();
        if (data.CadastrosFiltrados.length === 0) {
          --this.page;
          new AlertaModal('erro', 'Erro', 'Não há resultados a serem exibidos!', 'p');
        } else {
          this.colaborador = data.CadastrosFiltrados;
          this.colaborador2 = data.CadastrosFiltrados;
          this.paginacao = String(this.page).padStart(2, '0');
        }
      },
      (error) => {
        this.spinner.hide();
        new AlertaModal('erro', 'Erro', 'Não há resultados a serem exibidos!', 'p');
      }
    );
  }

  submitTransferencia() {
    this.loadingTranferencia = true;
    if (this.formTransferencia.valid) {
      const transferencia: Transferencia = this.montarTransferencia();

      this.colaborador_service.salvarTranferencia(transferencia).subscribe(
        (data: any) => {
          this.loadingTranferencia = false;
          document.getElementById('closeModalTranferencia').click();
          new AlertaModal(
            'sucesso',
            'Successo!',

            this.transferenciaNovaFilial
              ? `O colaborador foi tranferido para a filial ${data.EmpresaFilial} com matricula ${data.NumMatricula}`
              : 'Transferência realizada com sucesso',
            'p'
          );
          if (this.transferenciaNovaFilial) this.buscarColaboradoresFiltrados();
        },
        (error) => {
          this.loadingTranferencia = false;
          this.validaErro.retornoErro(error);
        }
      );
      this.limparTransferencia();
    } else {
      new AlertaModal('alerta', 'Atenção', 'Preencha os campos corretamente.', 'p');
      this.formTransferencia.markAllAsTouched();
    }
  }

  fecharModalTransferencia() {
    this.transferenciaNovaFilial = null;
    this.limparTransferencia();
    this.formTransferencia.markAsUntouched();
  }

  limparTransferencia() {
    this.formTransferencia.get('dataEvento').setValue('');
    this.formTransferencia.get('lotacao').setValue('');
    this.formTransferencia.get('filial').setValue('');
  }

  montarTransferencia(): Transferencia {
    const { filial, lotacao, dataEvento } = this.formTransferencia.value;
    var [ano, mes, dia] = dataEvento.split('-');
    return {
      DataEvento: {
        SetData: `${dia}/${mes}/${ano}`,
        ToDate: `${dia}/${mes}/${ano}`,
      },
      EmpfilDestino: filial === '' ? this.localStorage.FilialAtual.toString() : filial,
      EmpfilOrigem: this.localStorage.FilialAtual.toString(),
      LotacaoDestino: lotacao,
      MatriculaOrigem: this.matricula.toString(),
    };
  }

  buscarAprendiz(numeroOrdem: string): void {
    this.aprendizService.buscarAprendiz(numeroOrdem).subscribe(
      (data: Aprendiz) => {
        this.aprendiz = data;
      },
      (error) => {}
    );
  }

  buscarEstagio(numeroOrdem: string): void {
    this.estagioService.buscarEstagio(numeroOrdem).subscribe(
      (data: Estagio) => {
        this.estagio = data;
      },
      (error) => {}
    );
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }

  ordenarPorNome() {
    if (this.ordemNome == 0 || this.ordemNome == 2) {
      this.colaborador = this.colaborador.sort((a, b) =>
        a.NomeCompleto.localeCompare(b.NomeCompleto)
      );
      this.ordemNome = 1;
    } else {
      this.colaborador = this.colaborador.sort((a, b) =>
        b.NomeCompleto.localeCompare(a.NomeCompleto)
      );
      this.ordemNome = 2;
    }
    this.ordemMatricula = 0;
    this.ordemCPF = 0;
  }

  ordenarPorMatricula() {
    if (this.ordemMatricula == 0 || this.ordemMatricula == 2) {
      this.colaborador = this.colaborador.sort(
        (a, b) => parseInt(a.Matricula) - parseInt(b.Matricula)
      );
      this.ordemMatricula = 1;
    } else {
      this.colaborador = this.colaborador.sort(
        (a, b) => parseInt(b.Matricula) - parseInt(a.Matricula)
      );
      this.ordemMatricula = 2;
    }
    this.ordemNome = 0;
    this.ordemCPF = 0;
  }

  ordenarPorCPF() {
    if (this.ordemCPF == 0 || this.ordemCPF == 2) {
      this.colaborador = this.colaborador.sort(
        (a, b) => parseInt(a.Cpf.replace(/[.-]/g, '')) - parseInt(b.Cpf.replace(/[.-]/g, ''))
      );
      this.ordemCPF = 1;
    } else {
      this.colaborador = this.colaborador.sort(
        (a, b) => parseInt(b.Cpf.replace(/[.-]/g, '')) - parseInt(a.Cpf.replace(/[.-]/g, ''))
      );
      this.ordemCPF = 2;
    }
    this.ordemMatricula = 0;
    this.ordemNome = 0;
  }
}

interface SituacaoColaborador {
  Codigo: number;
  Descricao: string;
  Situacao: boolean;
  Tam: number;
}

export interface Transferencia {
  LotacaoDestino: string;
  MatriculaOrigem: string;
  EmpfilOrigem: string;
  DataEvento: Data;
  EmpfilDestino: string;
}

export interface Colaborador {
  Cpf: string;
  Email: string;
  Empfil: string;
  Etapa: number;
  Matricula: string;
  NomeCargo: string;
  NomeCompleto: string;
  NomeSetor: string;
  NumeroDeOrdem: string;
  Sexo: Sexo;
  Situacao: Stiuacao;
}

export interface InformacaoGenerica {
  Codigo: string;
  Descricao: string;
  Situacao: boolean;
}

export interface Estagio {
  NumeroOrdem: string;
  Nome: string;
  ContratosEstagio: [];
  Nivel: Nivel;
  OutrosContratos: number;
}

export interface Aprendiz {
  NumeroOrdem: string;
  Nome: string;
  ContratosAprendiz: [];
  Nivel: Nivel;
  OutrosContratos: number;
}

export interface Nivel {
  Codigo: string;
  Nome: string;
  Valor: number;
}

export interface envioConsulta {
  page: number;
  filtros: string[];
}
