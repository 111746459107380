<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
	<p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<!-- Page content -->
<div class="page-content">

	<!-- Main content -->
	<div class="content-wrapper">

		<!-- Content area -->
		<div class="content d-flex justify-content-center align-items-center">

			<!-- Login form -->
			<form class="login-form" [formGroup]="formAlterarSenha" (ngSubmit)="alteraSenha()">
				<div class="card mb-0 corpo-form">
					<div class="card-body">
						<div class="formulario">

							<div class="text-center mb-3">
								<img src="../../../assets/global_assets/images/tela-login/LogoMarca.svg"
									class="img-fluid max-width: 100% height-auto" style="max-height: 70px;"><br><br>
								<h5 class="mb-0">Alteração de Senha</h5>
								<span class="d-block text-muted">Preencha os campos com a sua nova senha!</span>
							</div>

							<div class="form-group form-group-feedback form-group-feedback-left">
								<input type="password" class="form-control" placeholder="Senha" name="senha1"
									formControlName="senha1Form" required>
								<div class="form-control-feedback">
									<i class="icon-lock2 text-muted"></i>
								</div>
								<span class="form-text text-danger"
									*ngIf="erro && formAlterarSenha.controls['senha1Form'].hasError('required')">Digite
									a nova senha</span>
								<span class="form-text text-danger"
									*ngIf="formAlterarSenha.controls['senha1Form'].hasError('minlength')">A senha deve
									ter pelo menos 8 caracteres!</span>

							</div>
							<div class="form-group form-group-feedback form-group-feedback-left">
								<input type="password" class="form-control" placeholder="Confirme a senha" name="senha2"
									formControlName="senha2Form" required>
								<div class="form-control-feedback">
									<i class="icon-lock2 text-muted"></i>
								</div>
								<span class="form-text text-danger"
									*ngIf="erro && formAlterarSenha.controls['senha2Form'].hasError('required')">Digite
									novamente a senha</span>

							</div>

							<div class="form-group form-group-feedback form-group-feedback-left">

								<!-- <button type="submit" (click)="captchaRef.execute();" class="btn btn-primary btn-block" [disabled]="formLogin.invalid || loading">Entrar <i class="fa fa-spinner fa-spin" aria-hidden="false" *ngIf="loading"></i></button> -->
								<button style="background-color: #6E8DCA ;" type="submit" class="btn btn-primary btn-block"
									[disabled]="formAlterarSenha.invalid || carregando">Enviar</button>
							</div>
						</div>

					</div>

				</div>
			</form>
		</div>
	</div>
</div>