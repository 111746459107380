import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErroService } from 'src/app/service/erros/erro.service';
import Swal from 'sweetalert';
import { EstrangeiroService } from './estrangeiro.service';
import { InfoColaborador } from '../cadastro-complementar/cadastro-complementar.component';
import { Router } from '@angular/router';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';


@Component({
  selector: 'app-estrangeiro',
  templateUrl: './estrangeiro.component.html',
  styleUrls: ['./estrangeiro.component.css']
})
export class EstrangeiroComponent {

  @Input() colaborador: Cadastro = null;
  
  infoColaborador: InfoColaborador;

  formEstrangeiro: FormGroup;

  desabilitarNif: boolean = true;
  empfil: string;
  localStorage: LocalStorage;


  paises: any[] = [];
  logradouros: any[] = [];
  receitas: any[] = [];
  rendimentos: any[] = [];
  tributacoes: any[] = [];

ngOnInit() {
  this.infoColaborador = history.state.usuario;
  if (!this.infoColaborador) {
    this.router.navigate(['/colaboradores']);
  }

  
  this.verificarEstadoNif();

  this.formEstrangeiro.get('ResidEstrangIndNif')?.valueChanges.subscribe((value) => {
    this.onNifChange(value);
  });
  
  this.buscarEstrangeiro();
  this.buscarListaPaises();
  this.buscarListalogradouros();
  this.buscarListaReceitas();
  this.buscarListaRendimentos();
  this.buscarListaTributacoes();
}



constructor(
  private router: Router,
  private fb: FormBuilder,
  private estrangeiroService: EstrangeiroService,
  
) { 
  this.localStorage = this.decryptLocalStorage();
  this.empfil = this.localStorage.FilialAtual.toString();

  this.formEstrangeiro = this.fb.group({
    ResidEstrangPaisResid: [0, Validators.required],        // País é obrigatório
    ResidEstrangCodReceita: [0, Validators.required],        // Código da Receita é obrigatório
    ResidEstrangTipoRendimento: ['', Validators.required],    // Tipo de Rendimento é obrigatório
    ResidEstrangIndNif: [ null, Validators.required],            // Indicador de NIF é obrigatório
    ResidEstrangNifBenef: [{ value: '', disabled: true }, Validators.required],         // NIF do Beneficiário é obrigatório
    ResidEstrangFrmTribut: ['', Validators.required],         // Forma de Tributação é obrigatória
    ResidEstrangTpLograd: [''],                               // Tipo de Logradouro
    ResidEstrangDscLograd: [''],                             // Descrição do Logradouro
    ResidEstrangNrLograd: [''],                              // Número do Logradouro
    ResidEstrangComplemento: [''],                           // Complemento
    ResidEstrangBairro: [''],                                // Bairro
    ResidEstrangNmCidade: [''],                              // Nome da Cidade
    ResidEstrangNmEstado: [''],                              // Nome do Estado
    ResidEstrangCodPostal: ['', Validators.required],        // Código Postal é obrigatório
    ResidEstrangTelef: ['']                                  // Telefone
  });

  this.formEstrangeiro.get('ResidEstrangIndNif')?.valueChanges.subscribe((value) => {
    this.updateNifValidator(value);
  });
}


private updateNifValidator(selectedValue: number) {
  const nifControl = this.formEstrangeiro.get('ResidEstrangNifBenef');
  if (selectedValue === 3) {
    nifControl?.setValidators([Validators.required]); 
  } else {
    nifControl?.clearValidators(); 
  }
  nifControl?.updateValueAndValidity();
}




decryptLocalStorage(): LocalStorage {
  if (localStorage.getItem('util') != null) {
    return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
  }
}



buscarEstrangeiro() {
  this.estrangeiroService.buscarEstrangeiro(this.empfil, this.infoColaborador.matricula).subscribe(
    (res: any) => {
      
      if (res) {
        this.formEstrangeiro.patchValue(res);
        this.formEstrangeiro.get('ResidEstrangIndNif')?.setValue(this.formEstrangeiro.get('ResidEstrangIndNif')?.value === 0 ? null : this.formEstrangeiro.get('ResidEstrangIndNif')?.value);

        this.verificarEstadoNif(); 
      } else {
        console.warn('Nenhum dado encontrado para a combinação de filial e matrícula.');
      }
    },
    (error) => {
      console.error('Erro ao buscar estrangeiro:', error);
     
    }
  );
}


buscarListaPaises() {
  this.estrangeiroService.buscarListaPaises()
    .subscribe(
      (res: any) => {
        
        if (res && Array.isArray(res)) {
          this.paises = res;
        } else {
          console.warn('Nenhum dado encontrado na lista de países.');
        }
      },
      (error) => {
        console.error('Erro ao buscar lista de países:', error);
       
      }
    );
}

buscarListalogradouros() {
  this.estrangeiroService.buscarListalogradouros()
    .subscribe(
      (res: any) => {
        
        if (res && Array.isArray(res)) {
          this.logradouros = res; 
        } else {
          console.warn('Nenhum dado encontrado na lista de logradouros.');
        }
      },
      (error) => {
        console.error('Erro ao buscar lista de logradouros:', error);
        
      }
    );
}

buscarListaReceitas() {
  this.estrangeiroService.buscarListaReceitas()
    .subscribe(
      (res: any) => {
       
        if (res && Array.isArray(res)) {
          this.receitas = res; 
        } else {
          console.warn('Nenhum dado encontrado na lista de receitas.');
        }
      },
      (error) => {
        console.error('Erro ao buscar lista de receitas:', error);
        
      }
    );
}

buscarListaRendimentos() {
  this.estrangeiroService.buscarListaRendimentos()
    .subscribe(
      (res: any) => {
        
        if (res && Array.isArray(res)) {
          this.rendimentos = res;
        } else {
          console.warn('Nenhum dado encontrado na lista de rendimentos.');
        }
      },
      (error) => {
        console.error('Erro ao buscar lista de rendimentos:', error);
       
      }
    );
}


buscarListaTributacoes() {
  this.estrangeiroService.buscarListaTributacoes()
    .subscribe(
      (res: any) => {
        
        if (res && Array.isArray(res)) {
          this.tributacoes = res; 
        } else {
          console.warn('Nenhum dado encontrado na lista de tributações.');
        }
      },
      (error) => {
        console.error('Erro ao buscar lista de tributações:', error);
        
      }
    );
}

enviarCadastro() {

  this.formEstrangeiro.markAllAsTouched(); 

  if (this.formEstrangeiro.valid) {
    const dadosEstrangeiro = this.formEstrangeiro.getRawValue(); 

    
    this.estrangeiroService.enviarCadastroEstrangeiro(this.empfil, this.infoColaborador.matricula, dadosEstrangeiro)
      .subscribe(
        (res: any) => {
         
          Swal({
            title: 'Sucesso!',
            text: 'Cadastro enviado com sucesso!',
            icon: 'success',
          });
        },
        (error) => {
          console.error('Erro ao enviar cadastro:', error);
          Swal({
            title: 'Erro!',
            text: 'Ocorreu um erro ao enviar o cadastro.',
            icon: 'error',
          });
        }
      );
  } else {
    Swal({
      title: 'Formulário Inválido!',
      text: 'Preencha todos os campos obrigatórios corretamente.',
      icon: 'warning',
    });
  }


}



  
onNifChange(value: any): void {
  const nifBenefField = this.formEstrangeiro.get('ResidEstrangNifBenef');

  if (value === 3) {
    this.desabilitarNif = false;
    nifBenefField?.enable();
  } else {
    this.desabilitarNif = true;
    nifBenefField?.disable();
    nifBenefField?.setValue('');  
  }
}




verificarEstadoNif() {
  const valorNif = this.formEstrangeiro.get('ResidEstrangIndNif')?.value;
  this.onNifChange(valorNif); 
}

 
}
