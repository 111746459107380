import { ProfissionalEducacaoService } from './profissional-educacao.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InfoColaborador } from '../cadastro-complementar/cadastro-complementar.component';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertaModal } from '../../componentes-funcionais/alerta-modal/alerta-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Parametro } from 'src/app/service/interfaces/Parametro';
import { ErroService } from 'src/app/service/erros/erro.service';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import Swal from 'sweetalert';

@Component({
  selector: 'app-profissional-educacao',
  templateUrl: './profissional-educacao.component.html',
  styleUrls: ['./profissional-educacao.component.css'],
})
export class ProfissionalEducacaoComponent implements OnInit {
  @ViewChild('qualificacao') descricaoQualificacao: ElementRef<HTMLTextAreaElement>;
  @ViewChild('categoria') descricaoCategoria: ElementRef<HTMLTextAreaElement>;
  infoColaborador: InfoColaborador;
  formCadastro: FormGroup;
  listaCategoria: any = [];
  listaQualificacao: any = [];
  categoriaSelecionada: ParametroProfissional;
  qualificacaoSelecionada: ParametroProfissional;
  descricaoItemSelecionado: string;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private profissionalService: ProfissionalEducacaoService,
    public validaErro: ErroService
  ) {}

  ngOnInit() {
    this.formCadastro = this.fb.group({
      qualificacaoProfissional: [''],
      categoriaProfissional: [''],
    });
    this.infoColaborador = history.state.usuario;
    if (!this.infoColaborador) {
      this.retornarTelaColaboradores();
    }
    forkJoin({
      categoria: this.profissionalService.buscarCategoria(),
      qualificacao: this.profissionalService.buscarQualificacao(),
    })
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe({
        next: ({ categoria, qualificacao }) => {
          this.listaCategoria = categoria;
          this.listaQualificacao = qualificacao;
          this.buscarDados();
        },
        error: (error) => {
          this.validaErro.retornoErro(error);
        },
      });
  }

  retornarTelaColaboradores() {
    this.router.navigate(['/colaboradores']);
  }

  buscarCategoria() {
    return this.profissionalService.buscarCategoria();
  }

  buscarQualificacao() {
    return this.profissionalService.buscarQualificacao();
  }

  buscarDados() {
    this.spinner.show();
    this.profissionalService.buscarDados(this.infoColaborador.matricula).subscribe({
      next: (data: ProfissionalEducacaoObjeto) => {
        this.categoriaSelecionada = data.Categoria;
        this.qualificacaoSelecionada = data.Qualificacao;
        this.filtrarListas();
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
        this.retornarTelaColaboradores();
      },
    });
  }

  filtrarListas() {
    if (this.qualificacaoSelecionada) {
      const qualificacaoEncontrada = this.listaQualificacao.find(
        (qualificacao) =>
          qualificacao.Descricao.trim() === this.qualificacaoSelecionada.Descricao.trim()
      );
      if (qualificacaoEncontrada) {
        this.formCadastro.get('qualificacaoProfissional').setValue(qualificacaoEncontrada.Codigo);
      }
    }

    if (this.categoriaSelecionada) {
      const categoriaEncontrada = this.listaCategoria.find(
        (categoria) => categoria.Descricao.trim() === this.categoriaSelecionada.Descricao.trim()
      );
      if (categoriaEncontrada) {
        this.formCadastro.get('categoriaProfissional').setValue(categoriaEncontrada.Codigo);
      }
    }
  }

  submitCadastro(): void {
    if (this.formCadastro.invalid) {
      new AlertaModal('alerta', 'Atenção!', 'Preencha os campos corretamente.', 'p');
      this.formCadastro.markAllAsTouched();
    } else {
      this.atualizarInfo();
    }
  }

  atualizarInfo(): void {
    this.spinner.show();
    const objForm = this.formCadastro.value;
    this.profissionalService.atualizarDados(this.infoColaborador.matricula, objForm).subscribe({
      next: () => {
        this.spinner.hide();
        this.buscarDados();
        new AlertaModal('sucesso', 'Sucesso!', 'Informações atualizadas com sucesso.', 'p');
      },
      error: (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      },
    });
  }

  buscarDescricao(evento, lista: any[]) {
    const codigoItemSelecionado = evento.target.value;
    const itemSelecionado: ParametroProfissional = lista.find(
      (categoria: ParametroProfissional) => categoria.Codigo == codigoItemSelecionado
    );

    if (lista == this.listaCategoria) {
      this.descricaoCategoria.nativeElement.textContent = itemSelecionado.Descricao_Completa;
    } else {
      this.descricaoQualificacao.nativeElement.textContent = itemSelecionado.Descricao_Completa;
    }
  }

  limparForm(): void {
    this.formCadastro.setValue({
      qualificacaoProfissional: '',
      categoriaProfissional: '',
    });
  }
}

interface ProfissionalEducacaoObjeto {
  Categoria: ParametroProfissional;
  Qualificacao: ParametroProfissional;
}

interface ParametroProfissional {
  Codigo: number;
  Descricao: string;
  Descricao_Completa: string;
  Parametrizado: string;
}

export interface ProfissionalAtualizacao {
  qualificacaoProfissional: string;
  categoriaProfissional: string;
}
