export class RescisaoRelatorio
{
    CodigoRescisao: number;
    Matricula: number;
    //0 - Rescis�o normal, 1 - Rescis�o Pr�via
    Tipo: number;
    Verbas: any[];
}

export class Verba
{
    Verba: number;
    NomeVerba: string;
    Valor: string;
    Pag: string;
    Oco: string;
    Refer: string;
    Trans: string;
    Pn: string;
}