import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import Swal from 'sweetalert';
import { FormacaoBasicaService } from './formacao-basica.service';
import { FormacaoBasicaCadastroComponent } from './formacao-basica-cadastro/formacao-basica-cadastro.component';
import { ObservacoesFichaFuncionalComponent } from '../componentes-funcionais/observacoes-ficha-funcional/observacoes-ficha-funcional.component';
import { AnexoComponent } from '../anexo/anexo.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-formacao-basica',
  templateUrl: './formacao-basica.component.html',
  styleUrls: ['./formacao-basica.component.css'],
})
export class FormacaoBasicaComponent {
  @ViewChild(FormacaoBasicaCadastroComponent) modal: FormacaoBasicaCadastroComponent;
  @ViewChild(ObservacoesFichaFuncionalComponent)
  modalObservacoes: ObservacoesFichaFuncionalComponent;
  @ViewChild(AnexoComponent) modalAnexo: AnexoComponent;
  matricula: number = null;
  numeroDeOrdem: number = null;
  colaborador: Cadastro = null;
  loading: boolean = false;
  formColaborador: FormGroup;
  localStorage: LocalStorage;
  listaFormacoes: any[] = [];
  cadastroFormacao: boolean = true;
  formacaoAtual: Formacao;
  empfil: string;
  tipoPagina: string = '009';

  constructor(
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private formacaoService: FormacaoBasicaService
  ) {
    this.localStorage = this.decryptLocalStorage();
    this.empfil = this.localStorage.FilialAtual.toString();
    this.formColaborador = this.fb.group({
      matricula: ['', Validators.required],
      nome: [''],
    });
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }

  matriculaListener(evento) {
    this.colaborador = evento;
    this.numeroDeOrdem = parseInt(this.colaborador.NumeroDeOrdem);
    this.matricula = parseInt(this.colaborador.Matricula);
    this.setarFormColaborador(evento);
    this.modalAnexo.inicializarComponente(evento);
  }

  setarFormColaborador(data) {
    this.formColaborador.get('matricula').disable();
    this.formColaborador.get('matricula').setValue(this.matricula);
    this.formColaborador.get('nome').disable();
    this.formColaborador.get('nome').setValue(data.InformacoesBasicas.NomeCompleto);
    this.loading = false;
    this.buscarFormacoes();
  }

  retornarTelaInicial() {
    this.matricula = null;
    this.formColaborador.reset();
    this.formColaborador.markAsPristine();
  }

  buscarFormacoes() {
    this.spinner.show("formacao");
    this.formacaoService.buscarFormacoes(this.matricula).subscribe(
      (data: any) => {
        console.log('formacoes', data);
        this.listaFormacoes = data;
        this.spinner.hide("formacao");
      },
      (err) => {
        console.error(err);
        this.spinner.hide("formacao");
      }
    );
  }

  aoAbrirModal(formacao?: Formacao) {
    this.modal.limparForm();

    if (formacao) {
      this.cadastroFormacao = false;
      this.formacaoAtual = formacao;
      this.modal.setarFormacaoExistente(formacao);
    } else {
      this.cadastroFormacao = true;
      this.formacaoAtual = null;
    }
  }

  downloadPdf() {
    this.spinner.show();

    this.formacaoService.downloadPdf(this.empfil, this.matricula, this.tipoPagina).subscribe(
      (data: any) => {
        console.log(data);

        this.spinner.hide();

        const pdf = `data:application/pdf;base64,${data.Base64}`;
        const fileName = `${data.Nome}.pdf`;

        const downloadLink = document.createElement('a');

        downloadLink.href = pdf;

        downloadLink.download = fileName;
        
        downloadLink.click();
      },
      (err) => {
        console.error(err);
        this.spinner.hide();
      }
    );
  }

  deletarFormacao(formacao: Formacao) {
    Swal({
      title: 'Deseja realmente excluir essa formação?',
      text: 'Essa ação não pode ser desfeita.',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
    }).then((result) => {
      this.spinner.show();
      if (result)
        this.formacaoService.deletarFormacao(formacao).subscribe(
          (data) => {
            this.buscarFormacoes();
            Swal('Sucesso', 'Formação deletada com sucesso.', 'success');
            this.spinner.hide();
          },
          (err: HttpErrorResponse) => {
            if (err.status === 500) {
              Swal('Erro', 'Não foi possível excluir essa Formação.', 'error');
              this.spinner.hide()
            } else {
              const errorMessage =
                err.error?.Mensagem || 'Ocorreu um erro ao excluir essa Formação.';
              Swal('Erro', errorMessage, 'error');
              this.spinner.hide()
            }
          }
        );else this.spinner.hide();
    });
  }
}

export interface Formacao {
  Sigla: string;
  Empfil: string;
  Mat: number;
  DtInicio: {
    SetData: string;
    ToDate: string;
    IsDate: boolean;
    Empty: boolean;
  };
  DtTermino: {
    SetData: string;
    ToDate: string;
    IsDate: boolean;
    Empty: boolean;
  };
  CodCrs: string;
  DescCrs?: string;
  Obs1: string;
  Obs2: string;
  CodTof: string;
  Quadro: number;
  NumPos: number;
  Conceito: string;
  Entidade: string;
  CargHor: number;
  Nota: number;
}
