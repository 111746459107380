import { ConstanteExterna } from './../../quadro-beneficios/classe/ConstanteExterna';
import { Beneficio } from './../../quadro-beneficios/classe/Beneficio';
import { RegraBeneficio } from './classe/Regrabeneficio';
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { BeneficioModal } from './classe/BeneficioModal';
import { EdicaoBeneficioService } from './edicao-beneficio.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ErroService } from 'src/app/service/erros/erro.service';
import Swal from 'sweetalert';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal, AlertaModalComponent } from 'src/app/componentes/componentes-funcionais/alerta-modal/alerta-modal.component';



@Component({
  selector: 'app-edicao-beneficio',
  templateUrl: './edicao-beneficio.component.html',
  styleUrls: ['./edicao-beneficio.component.css']
})
export class EdicaoBeneficioComponent implements OnInit {
  localStorage: LocalStorage;
  formularioEdicaoBeneficioDependente: FormGroup;

  beneficioModal: BeneficioModal[] = [];// beneficio do dependente
  listaBeneficios: any[] = [];

  beneficio: RegraBeneficio;
  beneficiario: any; // Funcionario
  beneficioList: any; // lista de beneficios
  beneficioListFunciorio: any; // lista de beneficios
  beneficioListDependente: any; // lista de beneficios 
  formularioEdicaoBeneficio: any;
  dependenteList: any [] = [];
  dependenteModal: any;
  tipoProcessamneto: string = "EM";
  tipoProcessamento: string = "EM";
  matricula: any = ''
  indexDependente: number;
  indexBeneficio: number;
  tipoFuncionario: number = 1;
  tipoDependente: number = 2;
  Quantidade: number = 0;


  showBtnAdicionar: boolean = true;
  telaAdicionarFuncionario: boolean = false;
  validaEdicaoFuncionario: boolean = false;
  validaEdicaoDependente: boolean = false;
  controleQuantidade: boolean = false;
  controleQuantidadeDependente: boolean = false;
  controleDataFinal: boolean = false;
  mostarCheck: boolean = true;
  destivarBeneficio: boolean = false;
  carregado: boolean = false;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private edicaoBeneficioService: EdicaoBeneficioService, private spinner: NgxSpinnerService, private validaErro: ErroService, private router: Router) {
    this.route.params.subscribe(params => this.matricula = params['Matricula'])
  }


  ngOnInit() {
    this.formularioEdicaoBeneficio = this.fb.group({
      numeroDeordemFun: [''],
      codigobeneficiarioFun: [''],
      chavebeneficiarioFun: [''],
      novoBeneficio: ['', Validators.compose([Validators.required])],
      dataInicial: ['', Validators.compose([Validators.required])],
      dataFinal: [''],
      vencimentoBeneficio: [''],
      quantidadeBeneficio: ['', Validators.maxLength(4)]
    })
    this.formularioEdicaoBeneficioDependente = this.fb.group({
      novoBeneficioDependente: ['', Validators.compose([Validators.required])],
      dataInicialDependente: ['', Validators.compose([Validators.required])],
      dataFinalDependente: ['', Validators.compose([Validators.required])],
      quantidadeBeneficio: ['', Validators.maxLength(4)]
    })
    this.buscarBeneficiosDoColaborador()
    this.buscarDependentes()

  }

  buscarBeneficios() {
    this.edicaoBeneficioService.buscarBeneficios(this.tipoProcessamneto)
      .subscribe(
        data => {

          this.spinner.hide()
          this.beneficioList = JSON.parse(JSON.stringify(data));
          this.beneficioListFunciorio = this.beneficioList.filter(b => b.F03 === "0" || b.F03 === "1");
          this.beneficioListDependente = this.beneficioList.filter(b => b.F03 === "0" || b.F03 === "2");
          for (var i = 0; i < this.beneficiario.Beneficio.length; i++) {
            this.beneficiario.Beneficio[i].Valor = this.beneficioList.find(beneficio => beneficio.Codigo == this.beneficiario.Beneficio[i].Codigo).ConstanteExterna.Valor;
          }
        },
        error => {
          this.spinner.hide()
          this.validaErro.retornoErro(error)
        }
      );
  }

  buscarDependentes(chave?: string) {
    let data = { ChaveBeneficiario: chave }
    this.spinner.show();
    this.edicaoBeneficioService.buscarBeneficiosDoDependente(this.tipoProcessamneto, this.matricula)
      .subscribe(
        data => {
          this.dependenteList = JSON.parse(JSON.stringify(data));
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
        }
      );
  }

  buscarBeneficiosDoColaborador() {
    this.spinner.show()
    this.edicaoBeneficioService.buscarBeneficiosDoColaborador(this.tipoProcessamento, this.matricula).subscribe(
      (valor: any) => {
        console.log(valor)
        this.listaBeneficios = valor[0].Beneficio
        this.beneficiario = valor[0]
        this.carregado = true;
        this.spinner.hide()

        this.buscarBeneficios()
      },
      erro => {

      }
    )
  }


  // BENEFICIOS

  montarDados(tipo: number): RegraBeneficio {
    if (tipo == this.tipoDependente) {
      let dataInicialDependente = this.formularioEdicaoBeneficioDependente.get("dataInicialDependente").value;
      let dataFinalDependente = this.formularioEdicaoBeneficioDependente.get("dataFinalDependente").value;

      let mensagemRetornoDependente = this.validaDataRangeBeneficio(dataInicialDependente, dataFinalDependente, this.formularioEdicaoBeneficioDependente.get("novoBeneficioDependente").value, "Dependente");
      if (mensagemRetornoDependente != '') {
        Swal("Atenção", mensagemRetornoDependente, "warning");
        return null;
      }

      return {
        Numord: this.dependenteModal.NumeroDeOrdem,
        Quantidade: this.controleQuantidade ? this.formularioEdicaoBeneficioDependente.get('quantidadeBeneficio').value : "0",
        DataInicial: dataInicialDependente,
        DataFinal: dataFinalDependente,
        IdArquivo: 2,
        TipoBeneficiario: {
          Codigo: this.tipoDependente
        },
        Beneficio: {
          Codigo: this.formularioEdicaoBeneficioDependente.get("novoBeneficioDependente").value
        },
        CadastroBeneficiario: {
          ChaveBeneficiario: this.dependenteModal.ChaveBeneficiario
        }
      }
    }
    else {

      let dataInicialColaborador = this.formularioEdicaoBeneficio.get("dataInicial").value;
      let dataFinalColaborador = this.formularioEdicaoBeneficio.get("dataFinal").value;

      let mensagemRetornoColaborador = this.validaDataRangeBeneficio(dataInicialColaborador, dataFinalColaborador, this.formularioEdicaoBeneficio.get("novoBeneficio").value, "Colaborador");
      if (mensagemRetornoColaborador != '') {
        Swal("Atenção", mensagemRetornoColaborador, "warning");
        return null;
      }
      return {
        Numord: this.beneficiario.NumeroDeOrdem,
        Quantidade: this.controleQuantidade ? this.formularioEdicaoBeneficio.get('quantidadeBeneficio').value : "0",
        DataInicial: dataInicialColaborador,
        DataFinal: this.controleDataFinal ? dataFinalColaborador : this.add30Anos("dataInicial"),
        IdArquivo: 1,
        TipoBeneficiario: {
          Codigo: this.tipoFuncionario
        },
        Beneficio: {
          Codigo: this.formularioEdicaoBeneficio.get("novoBeneficio").value
        },
        CadastroBeneficiario: {
          ChaveBeneficiario: this.beneficiario.ChaveBeneficiario
        }
      }
    }
  }

  deletarBeneficio(index: number, tipo: number) {
    let chaveDividida
    this.indexBeneficio = index

    Swal({
      title: "Deseja excluir este Benefício?",
      text: "Selecione uma das opções.",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true
        },
        confirm: {
          text: "Excluir",
          value: true,
          visible: true
        }
      }
    })
      .then(willDelete => {
        if (willDelete) {
          if (tipo == this.tipoDependente) {
            this.dependenteModal = this.dependenteList[this.indexDependente]
            chaveDividida = this.dependenteModal.ChaveBeneficiario.split(".")
            this.excluirBeneficio(this.dependenteModal, this.beneficioModal[index], chaveDividida[0], chaveDividida[1], tipo);
          } else {
            chaveDividida = this.beneficiario.ChaveBeneficiario.split(".")
            this.excluirBeneficio(this.beneficiario, this.beneficiario.Beneficio[index], chaveDividida[0], chaveDividida[1], tipo);
          }
        }
      });
  }

  incluirBeneficio(tipo: number) {
    this.spinner.show()
    this.edicaoBeneficioService.salvarbeneficio(this.beneficio, this.tipoProcessamento)
      .subscribe(
        data => {
          if (data) {
            this.atualizarListaInclusao(tipo);
            Swal("Sucesso!", "Inclusão Realizada Com Sucesso", "success");
            this.spinner.hide();
            this.buscarBeneficiosDoColaborador();
          }
        },
        error => {
          this.validaErro.retornoErro(error)
          this.spinner.hide()

        })
  }

  excluirBeneficio(beneficiario: any, beneficio: BeneficioModal, chave1: string, chave2: string, tipo: number) {
    this.spinner.show()
    this.edicaoBeneficioService.excluirBeneficio(this.tipoProcessamento, beneficiario, beneficio, chave1, chave2, tipo)
      .subscribe(
        data => {
          if (data) {
            Swal("Sucesso!", "Exclusão Realizada Com Sucesso", "success");
            this.buscarBeneficiosDoColaborador();
            this.spinner.hide();
          }
        },
        error => {
          this.validaErro.retornoErro(error)
          this.spinner.hide()
        })
  }

  //UTILITARIOS
  checkDataFinal() {
    this.controleDataFinal = !this.controleDataFinal;
    if (this.controleDataFinal) {
      this.formularioEdicaoBeneficio.controls['dataFinal'].setValidators([Validators.required, dataValidator]);
    } else {
      this.formularioEdicaoBeneficio.controls['dataFinal'].setValidators('');
    }
  }

  selecionarBeneficio() {
    let index = this.beneficioListFunciorio.findIndex(tipo => tipo.Codigo == this.formularioEdicaoBeneficio.get("novoBeneficio").value);
    this.controleQuantidade = this.beneficioListFunciorio[index].Grupo.ControlaQuantidade;
  }
  selecionarBeneficioDependente() {
    let index = this.beneficioListDependente.findIndex(tipo => tipo.Codigo == this.formularioEdicaoBeneficioDependente.get("novoBeneficioDependente").value);

    this.controleQuantidadeDependente = this.beneficioListDependente[index].Grupo.ControlaQuantidade;
    if (this.controleQuantidadeDependente) {
      this.formularioEdicaoBeneficioDependente.get("quantidadeBeneficio").setValue('');
      this.formularioEdicaoBeneficioDependente.get("quantidadeBeneficio").setValidators(Validators.nullValidator);
    }
    else this.formularioEdicaoBeneficioDependente.get("quantidadeBeneficio").setValidators(Validators.required);
  }

  add30Anos(formControl) {
    let dataFinal = Number(this.formularioEdicaoBeneficio.get(formControl).value.substr(0, 4)) + 30;
    return `${dataFinal}-01-01`;
  }

  carregarFuncionario() {
    let data = history.state["data"]
    if (data != undefined) {
      localStorage.setItem('beneficiario', JSON.stringify(history.state["data"]))
      this.beneficiario = history.state["data"]
    } else {
      this.beneficiario = JSON.parse(localStorage.getItem('beneficiario'))

      if (this.beneficiario == undefined) {
        this.router.navigate(['/beneficios/regras-beneficio'])
      }
    }
  }

  adicionarNoModal(index: number) {
    this.indexDependente = index
    this.dependenteModal = this.dependenteList[index]
    for (var i = 0; i < this.dependenteList[index].Beneficio.length; i++) {
      this.dependenteList[index].Beneficio[i].Quantidade = this.beneficioList.find(beneficio => beneficio.Codigo == this.dependenteList[index].Beneficio[i].Codigo).ConstanteExterna.Valor;
    }
    this.beneficioModal = this.dependenteList[index].Beneficio;
  }

  validaDataRangeBeneficio(dataInicial: string, dataFinal: string, codigoBeneficio: string, complementoMensagem: string): string {
    let beneficio = this.beneficioList.find(beneficio => beneficio.Codigo == codigoBeneficio);
    if (((new Date(dataInicial + 'T00:00:00')) < (new Date(beneficio.DataInicial === '' ? '' : beneficio.DataInicial.substr(6, 4) + '-' + beneficio.DataInicial.substr(3, 2) + '-' + beneficio.DataInicial.substr(0, 2) + 'T00:00:00'))) || ((new Date(dataFinal + 'T00:00:00')) > (new Date(beneficio.DataFinal === '' ? '' : beneficio.DataFinal.substr(6, 4) + '-' + beneficio.DataFinal.substr(3, 2) + '-' + beneficio.DataFinal.substr(0, 2) + 'T00:00:00')))) {
      return "Data Inicial e Data Final do benefício do " + complementoMensagem + ", devem estar dentro da data de validade do benefício selecionado. Validade do Benefício: " + beneficio.DataInicial.substr(0, 10) + ' ~ ' + beneficio.DataFinal.substr(0, 10);
    }
    if (new Date(dataInicial + 'T00:00:00') > new Date(dataFinal + 'T00:00:00')) {
      return "Data Inicial não pode ser maior que a Data Final";
    }
    return "";
  }

  somenteNumero(event) {
    return ((event.charCode >= 48 && event.charCode <= 57) /*|| (event.keyCode == 45 || event.charCode == 46)*/)
  }

  onSubmit() {
    if (this.formularioEdicaoBeneficioDependente.invalid) {
      Swal("Atenção", "Preencha Todos os Campos.", "warning");
      this.validaEdicaoDependente = true;
    }
    else {
      this.beneficio = this.montarDados(this.tipoDependente)
      if (this.beneficio != null) {
        this.incluirBeneficio(this.tipoDependente);
        this.buscarDependentes();
      }
    }
  }

  adicionarTelaFomrulario() {
    this.telaAdicionarFuncionario = true;
  }

  adicionarBeneficioFuncionario() {
    if (this.formularioEdicaoBeneficio.invalid) {
      Swal("Atenção", "Preencha Todos os Campos ", "warning");
      this.validaEdicaoFuncionario = true;
    } else {

      this.beneficio = this.montarDados(this.tipoFuncionario);

      if (this.beneficio != null) {
        this.incluirBeneficio(this.tipoFuncionario)
        this.showBtnAdicionar = true;
      }
    }
  }

  carregarEditarBeneficioColaborador(index: number) {
    console.log(this.listaBeneficios)
    this.showBtnAdicionar = false;
    this.formularioEdicaoBeneficio.controls['novoBeneficio'].setValue(this.beneficiario.Beneficio[index].Codigo);
    this.formularioEdicaoBeneficio.controls['dataInicial'].setValue(this.formatarDataEditar(this.beneficiario.Beneficio[index].DataInicial));
    this.formularioEdicaoBeneficio.get("dataFinal").setValue(this.formatarDataEditar(this.beneficiario.Beneficio[index].DataFinal));
    this.formularioEdicaoBeneficio.controls['quantidadeBeneficio'].setValue(this.listaBeneficios[index].Quantidade);
    this.telaAdicionarFuncionario = true;
    this.destivarBeneficio = true;
    this.formularioEdicaoBeneficio.get("novoBeneficio").disable()

    if (this.beneficiario.Beneficio[index].Quantidade != 0) {
      this.controleQuantidade = true;
    } else {
      this.controleQuantidade = false;
    }
    this.controleDataFinal = true;
    this.mostarCheck = false;
  }

  carregarEditarBeneficioDependente(index: number) {
    this.formularioEdicaoBeneficioDependente.controls['novoBeneficioDependente'].setValue(this.beneficioModal[index].Codigo);
    this.formularioEdicaoBeneficioDependente.controls['dataInicialDependente'].setValue(this.formatarDataEditar(this.beneficioModal[index].DataInicial));
    this.formularioEdicaoBeneficioDependente.controls['dataFinalDependente'].setValue(this.formatarDataEditar(this.beneficioModal[index].DataFinal));
  }//aqui

  limparCamposModal() {
    this.formularioEdicaoBeneficioDependente.controls['novoBeneficioDependente'].setValue("");
    this.formularioEdicaoBeneficioDependente.controls['dataInicialDependente'].setValue("");
    this.formularioEdicaoBeneficioDependente.controls['dataFinalDependente'].setValue("");
    this.validaEdicaoDependente = false

  }

  limparCampos() {
    this.formularioEdicaoBeneficio.controls['novoBeneficio'].setValue("");
    this.formularioEdicaoBeneficio.controls['dataInicial'].setValue("");
    this.formularioEdicaoBeneficio.controls['dataFinal'].setValue("");
    this.telaAdicionarFuncionario = false;
    this.validaEdicaoFuncionario = false;
    this.showBtnAdicionar = true;
    this.controleDataFinal = false;
    this.controleQuantidade = false;
    this.mostarCheck = true;
    this.formularioEdicaoBeneficio.get("novoBeneficio").enable()
  }

  atualizarListaInclusao(tipoObejeto: number) {
    let codigo = this.beneficioList.find(beneficio => beneficio.Codigo == this.beneficio.Beneficio.Codigo).Codigo
    let Descricao = this.beneficioList.find(beneficio => beneficio.Codigo == this.beneficio.Beneficio.Codigo).Descricao
    let data = this.formularioEdicaoBeneficio.get("dataInicial").value

    if (tipoObejeto == this.tipoFuncionario) {
      this.beneficiario.Beneficio.forEach((item, index) => {
        if (item.Codigo == codigo) this.beneficiario.Beneficio.splice(index, 1);
      });
      let beneficioAux = {
        Codigo: codigo,
        Descricao: Descricao,
        DataInicial: this.formularioEdicaoBeneficio.get("dataInicial").value.substr(0, 2) + "/" + this.formularioEdicaoBeneficio.get("dataInicial").value.substr(2, 2) + "/" + this.formularioEdicaoBeneficio.get("dataInicial").value.substr(4, 4),
        DataFinal: this.controleDataFinal ? this.formularioEdicaoBeneficio.get("dataFinal").value.substr(0, 2) + "/" + this.formularioEdicaoBeneficio.get("dataFinal").value.substr(2, 2) + "/" + this.formularioEdicaoBeneficio.get("dataFinal").value.substr(4, 4) : this.formatarDataEnvio(this.add30Anos("dataInicial")),
        Quantidade: this.beneficioList.find(beneficio => beneficio.Codigo == this.beneficio.Beneficio.Codigo).ConstanteExterna.Valor,
        Situacao: ""
      }
      this.beneficiario.Beneficio.push(beneficioAux)
      this.limparCampos()
    } else {
      this.dependenteModal.Beneficio.forEach((item, index) => {
        if (item.Codigo == codigo) this.dependenteModal.Beneficio.splice(index, 1);
      });
      let beneficioAuxDependente = {
        Codigo: codigo,
        Descricao: Descricao,
        DataInicial: this.formularioEdicaoBeneficioDependente.get("dataInicialDependente").value.substr(0, 2) + "/" + this.formularioEdicaoBeneficioDependente.get("dataInicialDependente").value.substr(2, 2) + "/" + this.formularioEdicaoBeneficioDependente.get("dataInicialDependente").value.substr(4, 4),
        DataFinal: this.formularioEdicaoBeneficioDependente.get("dataFinalDependente").value.substr(0, 2) + "/" + this.formularioEdicaoBeneficioDependente.get("dataFinalDependente").value.substr(2, 2) + "/" + this.formularioEdicaoBeneficioDependente.get("dataFinalDependente").value.substr(4, 4),
        Quantidade: this.beneficioList.find(beneficio => beneficio.Codigo == this.beneficio.Beneficio.Codigo).ConstanteExterna.Valor,
        Situacao: ""
      }
      this.dependenteModal.Beneficio.push(beneficioAuxDependente)
      this.limparCamposModal();
      this.spinner.hide();
    }
  }

  atualizarListaExclusao(tipoObejeto: number) {
    if (tipoObejeto == this.tipoFuncionario) {
      this.beneficiario.Beneficio.splice(this.indexBeneficio, 1)
    } else {
      this.dependenteModal.Beneficio.splice(this.indexBeneficio, 1)
    }
  }

  formatarDataEnvio(data: string): string {
    let aux = data.split('-')
    return `${aux[2]}/${aux[1]}/${aux[0]}`
  }
  formatarDataEditar(data: string): string {
    let aux = data.split('/')
    return `${aux[2]}-${aux[1]}-${aux[0]}`
  }



  voltar() {
    this.formularioEdicaoBeneficio.reset();
    this.formularioEdicaoBeneficioDependente.reset();
    this.router.navigate(['/beneficios/regras-beneficio/']);
  }
}
