import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AcessoService } from '../../../service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root'
})
export class EsocialService {
  localStorage: LocalStorage = new LocalStorage();

  headers = new HttpHeaders();
  
  url_acesso:string;
  cabecalho : any;
  filial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) { 
    this.url_acesso = this.acesso.validarUrl();
    
    if (localStorage.getItem("util") != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString(); 
    }
  }

  ListarEsocialPaginado(empfil,pagina) {
    return this.http.get(`${this.url_acesso}esocial/${empfil}/${pagina}/`,{headers:this.cabecalho});
  }

  PesquisarEsocialPaginado(empfil,pagina,pesquisa) {
    return this.http.get(`${this.url_acesso}esocial/${empfil}/${pagina}/${pesquisa}/`,{headers:this.cabecalho});
  }
}
