import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.css']
})
export class RelatoriosComponent implements OnInit {

  fileUrl;
  relatorioCadastro = true;

  alterarRelatorioCadastro(){
    if(this.relatorioCadastro){
      this.relatorioCadastro = false;
    }
    else{
      this.relatorioCadastro = true;
    }
  }

  downloadArquivo(){
    window.location.href = "https://drive.google.com/uc?authuser=0&id=1Cv5fNCo8SqJQ9hYD0U_3SqURsbMnx9eG&export=download";
  }
  constructor(private sanitizer: DomSanitizer, private http: HttpClient) { }

  ngOnInit() {

  }

}
