import { Inscricao } from '../inscricao';


export class Filial{
    Codigo: string
    Nome:string;
    NomeCompleto:string
    NomeMatriz:string
    Cnpj: string;
    Inscricao: Inscricao = new Inscricao() ;
    NaturezaJuridica: number
    constructor(){
        
    }
}