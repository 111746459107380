import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { NgxSpinnerService } from 'ngx-spinner';
import { ComissaoService } from './comissao.service';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import Swal from 'sweetalert';
import { ComissaoCadastroComponent } from './comissao-cadastro/comissao-cadastro.component';
import { ObservacoesFichaFuncionalComponent } from '../componentes-funcionais/observacoes-ficha-funcional/observacoes-ficha-funcional.component';
import { AnexoComponent } from '../anexo/anexo.component';

@Component({
  selector: 'app-comissao',
  templateUrl: './comissao.component.html',
  styleUrls: ['./comissao.component.css'],
})
export class ComissaoComponent implements OnInit {
  @ViewChild(ComissaoCadastroComponent)
  modalCadastro: ComissaoCadastroComponent;
  @ViewChild(ObservacoesFichaFuncionalComponent)
  modalObservacoes: ObservacoesFichaFuncionalComponent;
  @ViewChild(AnexoComponent) modalAnexo: AnexoComponent;

  matricula: number = null;
  numeroDeOrdem: any = null;
  colaborador: Cadastro = null;
  loading: boolean = false;
  cadastro: boolean = true;
  formColaborador: FormGroup;
  localStorage: LocalStorage;
  empfil: string;
  tipoPagina: string = '110';

  listaComissao: Comissao[] = [];
  comissaoSelecionada: Comissao;

  constructor(
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private comissaoService: ComissaoService
  ) {
    this.localStorage = this.decryptLocalStorage();
    this.empfil = this.localStorage.FilialAtual.toString();
    this.formColaborador = this.fb.group({
      matricula: ['', Validators.required],
      nome: [''],
    });
  }

  ngOnInit(): void {}

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }

  matriculaListener(evento) {
    this.colaborador = evento;
    this.numeroDeOrdem = parseInt(this.colaborador.NumeroDeOrdem);
    this.matricula = parseInt(this.colaborador.Matricula);
    this.buscarListaComissao();
    this.setarFormColaborador(evento);
    this.modalAnexo.inicializarComponente(evento);
  }

  setarFormColaborador(data) {
    this.formColaborador.get('matricula').disable();
    this.formColaborador.get('matricula').setValue(this.matricula);
    this.formColaborador.get('nome').disable();
    this.formColaborador.get('nome').setValue(data.InformacoesBasicas.NomeCompleto);
    this.loading = false;
    // this.buscarComissoes();
  }

  retornarTelaInicial() {
    this.matricula = null;
    this.formColaborador.reset();
    this.formColaborador.markAsPristine();
  }

  buscarListaComissao() {
    this.spinner.show("comissao");
    this.comissaoService.buscarListaComissao(this.numeroDeOrdem).subscribe({
      next: (data: Comissao[]) => {
        this.listaComissao = data;
        this.spinner.hide("comissao");
      },
      error: (erro) => {
        this.spinner.hide("comissao");
        new AlertaModal('erro', 'Erro', erro.error.message, 'P');
      },
    });
  }

  downloadPdf() {
    this.spinner.show();

    this.comissaoService.downloadPdf(this.empfil, this.matricula, this.tipoPagina).subscribe(
      (data: any) => {
        console.log(data);

        this.spinner.hide();

        const pdf = `data:application/pdf;base64,${data.Base64}`;
        const fileName = `${data.Nome}.pdf`;

        const downloadLink = document.createElement('a');

        downloadLink.href = pdf;

        downloadLink.download = fileName;

        downloadLink.click();
      },
      (err) => {
        console.error(err);
        this.spinner.hide();
      }
    );
  }

  aoAbrirModal() {
    this.modalCadastro.limparFormulario();
  }

  adicionarComissao() {
    this.cadastro = true;
    this.aoAbrirModal();
  }

  editarComissao(comissao: Comissao) {
    this.cadastro = false;
    this.aoAbrirModal();
    this.comissaoSelecionada = comissao;
  }

  excluirComissao(comissao: Comissao) {
    const { Seq, Numord } = comissao;
    Swal({
      title: 'Deseja realmente deletar essa comissão?',
      text: 'Essa ação não pode ser desfeita.',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
    }).then((result) => {
      if (result) {
        this.spinner.show();
        this.comissaoService.excluirComissao(Seq, Numord).subscribe({
          next: (data) => {
            this.spinner.hide();
            this.buscarListaComissao();
          },
          error: (erro) => {
            this.spinner.hide();
            Swal('Erro', 'Não foi possível deletar essa comissão.', 'error');
          },
        });
      }else this.spinner.hide();
    });
  }
}

export interface Comissao {
  Sigla: string;
  Documento?: 'S' | 'N';
  Obs?: 'S' | 'N';
  Numord: string;
  Empfil: number;
  DataInicio: DataInicio;
  DataFim: DataFim;
  Tipo: number;
  Numero: number;
  Cargo: string;
  Refer: string;
  Motivo: string;
  NumeroProcesso: string;
  NumeroDoe: string;
  IdClassificacaoAto: number;
  NumeroAto: string;
  AnoAto: string;
  DtAto: DtAto;
  AnoDoe: string;
  DtDoe: DtDoe;
  DtComp: string;
  TpProc: string;
  Flag: string;
  Seq: any;
}

export interface DataInicio {
  SetData: string;
  ToDate: string;
  IsDate: boolean;
  Empty: boolean;
}

export interface DataFim {
  SetData: string;
  ToDate: string;
  IsDate: boolean;
  Empty: boolean;
}

export interface DtAto {
  SetData: string;
  ToDate: string;
  IsDate: boolean;
  Empty: boolean;
}

export interface DtDoe {
  SetData: string;
  ToDate: string;
  IsDate: boolean;
  Empty: boolean;
}
export interface IdClassificacaoAto {
  Cod: number;
  Nome: string;
}
