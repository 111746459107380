


export class CampoChave {
  listaCampoChave: string[] = ["ANOS_REPUBLICA", "BANCO", "CNH", "CNH_DEFICIENTE", "COR_PELE",
    "DIRF_CPFRESP", "DIRF_DDDRESP", "DIRF_FONERESP", "DIRF_NOMERESP", "ESTABILIDADE", "ESTADO_CIVIL",
    "FGTS_OPCAO", "GRAU_INSTRUCAO", "LOGAR_ERRO", "MODELO_CSS_REL", "MODELO_HTML_ATO", "MODELO_HTML_CABEC_REL",
    "MODELO_HTML_CABECALHO", "MODELO_HTML_TXT", "NACIONALIDADE", "NOME_EMPRESA_GERAL", "NOME_GOVERNADOR", "SEXO",
    "SITUACAO_PROCESSO", "SITUACAOFERIAS", "TEMPO_SERVICO", "TIMEOUT_SESSAO", "TIPO_AVISO_PREVIO",
    "TIPO_CALCULO_PENSAO", "TIPO_CONTA", "TIPO_DEPENDENTE", "TIPO_FREQUENCIA", "TIPO_QUINQUENIO", "TIPO_SANG",
    "UNIDADE_FEDERATIVA", "URL_WS_CEP"]
  campoSelecionado: string[]=[];

  montarlistaParamentros(listaparamentros: string[]): ChaveUnica {
    listaparamentros.map(paramentro =>
      this.listaCampoChave.map(campoChave => {
        if (campoChave == paramentro) {
          this.campoSelecionado.push(campoChave)         
        }
      }))
    return this.montarCampoChave()

  }

  montarCampoChave(): ChaveUnica {
    return {
      ChaveUnica: this.campoSelecionado
    }
  }
}
interface ChaveUnica {
  ChaveUnica: string[]
}