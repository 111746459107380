import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable, OnInit} from '@angular/core';
import {AcessoService} from 'src/app/service/acesso/acesso.service';
import {Crypto} from 'src/app/service/classes/crypto/crypto';
import {LocalStorage} from 'src/app/service/classes/localstorage/LocalStorage';

@Injectable({
  providedIn: 'root',
})
export class PesquisarMatriculaService {
  localStorage: LocalStorage = new LocalStorage();
  url_acesso: string;
  headers = new HttpHeaders();
  cabecalho: any;
  filialAtual: string;
  data: Date = new Date();
  mes: number = this.data.getMonth() + 1;
  ano: number = this.data.getFullYear();
  competencia: string = this.ano.toString() + this.mes.toString();
  constructor(private http: HttpClient, private acesso: AcessoService) {
    this.url_acesso = this.acesso.validarUrl();
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filialAtual = this.localStorage.FilialAtual.toString();
      this.url_acesso = this.acesso.validarUrl();
    }
  }

  obterUsuarioPorMatricula(matricula: number) {
    return this.http.get(
      this.url_acesso +
        `elogios-penalidades/usuario/${this.competencia}/${matricula}/${this.filialAtual}`,
      {headers: this.cabecalho}
    );
  }

  buscarColaboradorMatricula(matricula: number) {
    return this.http.get(
      `${this.url_acesso}cadastro-todos-matricula/${matricula}/${this.filialAtual}`,
      {
        headers: this.cabecalho,
      }
    );
  }
}
