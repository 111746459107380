import { Data } from '../../../service/interfaces/Data';

export class Previa{
    Matricula: number;
    MatriculaDigito:  number = 0;
    CodigoAfastamento: number;
    VisaoPrevia: number;
    TipoAvisoPrevio:  number = 0;
    CodigoAvisoPrevio: string = "0";
    DataInicioAvisoPrevio: Data = new Data() ;
    QuantidadeDiasAvisoPrevio: number;
    DataRescisao: Data= new Data();
}