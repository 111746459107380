<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Redirecionando para a Job Coach ...</p>
</ngx-spinner>
<div class="row">
    <div class="col-md-12 text-center">
        <div class="col-md-6 ml-auto mr-auto mt-5">
            <img src="assets/img/job-coach.png">
        </div>
        <div class="mt-3">
            <h5>JobCoach é uma plataforma completa para avaliação de candidatos e equipes</h5>
        </div>
        <div class="mt-3">
            <button type="button" class="btn btn-primary" (click)="redirecionar()">
                Redirecionar 
                <i class="fa fa-arrow-circle-o-right"></i>
            </button>
        </div>
        <div style="display:none">
            <a href="javascript:void(0);" onclick="document.getElementById('formJC').submit();" id="submeter"></a>
            <form action="https://www.jobcoach.com.br/homologacao/partner_elogica_authentication.aspx" method="post" id="formJC" target="new">



                <input type="hidden" name="company_doc" value="123.456.789/0001-21"><br><br>
                <input type="hidden" name="company_name" value="Elógica"><br><br>
                <input type="hidden" name="company_recruiter_name" value="Hugo Arruda"><br><br>
                <input type="hidden" name="company_recruiter_email" value="recrutador-elogica@teste.com"><br><br>
                <input type="hidden" name="elogica_token" value="{Z?@syP_[kq>N}R+D:A3^?Tw=xJ!8Y]%w7FoK%9fX8uJ.E4tIc+OY[bq86BKF!6}A}1p-e+EB6E2$vb/1a">
            
                
            
            </form>
        </div>
    </div>
</div>
