import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AcessoService } from "src/app/service/acesso/acesso.service";
import { LocalStorage } from "src/app/service/classes/localstorage/LocalStorage";
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { Tarefa } from "./tarefas.component";



@Injectable({
    providedIn: 'root'
  })

  export class TarefasService {
    localStorage: LocalStorage;
    url_acesso:string;
    headers = new HttpHeaders();
    cabecalho = new HttpHeaders();
    filialAtual: string;

    tpProcessamento: string = "EM";
    data: any = new Date()
    mes:number = this.data.getMonth() + 1
    ano:number = this.data.getFullYear()
    competencia: string = this.ano.toString() + this.mes.toString().padStart(2,"0")
    tipoUsuario:string;;
  
  
    constructor(private http: HttpClient, private acesso: AcessoService) {
      if (localStorage.getItem("util") != null) {
        this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
        this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
        this.filialAtual = this.localStorage.FilialAtual.toString();
        this.tipoUsuario = this.localStorage.TipoUsuario;
        this.url_acesso = this.acesso.validarUrl();
      }
    }
  
    buscarTarefas(codigoServico: string){
      return this.http.get(this.url_acesso + `pse/tarefas/${codigoServico}`, {headers:this.cabecalho});
    } 
    
    buscarClientesPse(){ // Apenas para teste
      return this.http.get(this.url_acesso + `pse/clientes`, {headers:this.cabecalho});
    }

    buscarTarefasPorCodigo(codigoServico: number){
      return this.http.get(`${this.url_acesso}pse/tarefas/${codigoServico}`,{headers:this.cabecalho});
    } 

    buscarServicosPse(){
      return this.http.get(this.url_acesso + `pse/servicos`, {headers:this.cabecalho});
    }

    salvarTarefa(tarefa: Tarefa){
      return this.http.post(`${this.url_acesso}pse/tarefas`, tarefa, {headers:this.cabecalho});
    }

    editarTarefa(tarefa: Tarefa){
      return this.http.put(`${this.url_acesso}pse/tarefas`, tarefa, {headers:this.cabecalho});
    }

    buscarUsuariosTarefa(codigoTarefa: number){ // Alterar para rota que vai ser criada
      return this.http.post(`${this.url_acesso}situacao-funcional/falecimento`, codigoTarefa, {headers:this.cabecalho});
    }

    deletarTarefa(codigoServico: string, codigo: number) {
      return this.http.delete(this.url_acesso + `pse/tarefas/${codigoServico}/${codigo}`, { headers: this.cabecalho });
  }

  buscarArvoreTarefas(servico: any, tipoUsuario: any ){
    return this.http.get(`${this.url_acesso}pse/tarefas/arvore/${servico}/${tipoUsuario}`,{headers:this.cabecalho})
  }
}