import { Component, ViewChild } from '@angular/core';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { FaltasService } from './faltas.service';
import { ErroService } from 'src/app/service/erros/erro.service';
import Swal from 'sweetalert';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { FaltasCadastroComponent } from './faltas-cadastro/faltas-cadastro.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { ObservacoesFichaFuncionalComponent } from '../componentes-funcionais/observacoes-ficha-funcional/observacoes-ficha-funcional.component';
import { AnexoComponent } from '../anexo/anexo.component';

@Component({
  selector: 'app-faltas',
  templateUrl: './faltas.component.html',
  styleUrls: ['./faltas.component.css'],
})
export class FaltasComponent {
  @ViewChild(FaltasCadastroComponent) modal: FaltasCadastroComponent;
  @ViewChild(ObservacoesFichaFuncionalComponent)
  modalObservacoes: ObservacoesFichaFuncionalComponent;
  @ViewChild(AnexoComponent) modalAnexo: AnexoComponent;
  matricula: number = null;
  colaborador: Cadastro = null;
  loading: boolean = false;
  formColaborador: FormGroup;
  localStorage: LocalStorage;
  empfil: string;
  tipoPagina: string = '007';

  cadastroFalta: boolean = true;
  faltaAtual: Falta = null;
  listaFaltas: Falta[] = [];
  constructor(
    public fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private faltasService: FaltasService,
    private erroService: ErroService
  ) {
    this.localStorage = this.decryptLocalStorage();
    this.empfil = this.localStorage.FilialAtual.toString();
    console.log('localstorage', this.localStorage);
    this.formColaborador = this.fb.group({
      matricula: ['', Validators.required],
      nome: [''],
    });
  }
  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }

  matriculaListener(evento) {
    this.colaborador = evento;
    this.matricula = parseInt(this.colaborador.Matricula);
    this.setarFormColaborador(evento);
    this.modalAnexo.inicializarComponente(evento);
  }

  retornarTelaInicial() {
    this.matricula = null;
    this.formColaborador.reset();
    this.formColaborador.markAsPristine();
  }

  setarFormColaborador(data) {
    this.formColaborador.get('matricula').disable();
    this.formColaborador.get('matricula').setValue(this.matricula);
    this.formColaborador.get('nome').disable();
    this.formColaborador.get('nome').setValue(data.InformacoesBasicas.NomeCompleto);
    this.loading = false;
    this.buscarFaltas();
  }

  buscarFaltas() {
    this.spinner.show('faltas');
    this.faltasService.buscarFaltas(this.matricula).subscribe(
      (data: Falta[]) => {
        console.log('data', data);

        this.listaFaltas = data;
        this.spinner.hide('faltas');
      },
      (err) => {
        console.error(err);
        Swal('Erro', 'Não foi possível fazer a listagem das faltas.', 'error');

        this.spinner.hide('faltas');
      }
    );
  }

  calcularDias(dataInicio: string, dataVolta: string): string {
    return this.faltasService.calcularDias(dataInicio, dataVolta);
  }

  aoAbrirModal(falta?: Falta) {
    this.modal.limparForm();
    this.modal.buscarAfastamento();
    this.modal.buscarIdClassificacaoAto();
    this.modal.buscarMotivos();
    this.modal.buscarParametros();
    if (falta) {
      this.cadastroFalta = false;
      this.faltaAtual = falta;
      this.modal.setarFaltaExistente(falta);
    } else {
      this.cadastroFalta = true;
      this.faltaAtual = null;
    }
  }

  downloadPdf() {
    this.spinner.show();

    this.faltasService.downloadPdf(this.empfil, this.matricula, this.tipoPagina).subscribe(
      (data: any) => {
        console.log(data);

        this.spinner.hide();

        const pdf = `data:application/pdf;base64,${data.Base64}`;
        const fileName = `${data.Nome}.pdf`;

        const downloadLink = document.createElement('a');

        downloadLink.href = pdf;

        downloadLink.download = fileName;

        downloadLink.click();
      },
      (err) => {
        console.error(err);
        this.spinner.hide();
      }
    );
  }

  deletarFalta(falta: Falta) {
    Swal({
      title: 'Deseja realmente excluir essa falta?',
      text: 'Essa ação não pode ser desfeita.',
      icon: 'warning',
      buttons: ['Cancelar', 'Confirmar'],
    }).then((result) => {
      this.spinner.show();
      if (result)
        this.faltasService.deletarFalta(falta.Seq, falta.Numord).subscribe(
          (data) => {
            this.buscarFaltas();
            this.spinner.hide();
          },

          (err) => {
            Swal('Erro', 'Não foi possível deletar essa falta.', 'error');
            this.spinner.hide();
          }
        );else this.spinner.hide();
    });
  }
}

export interface Falta {
  Sigla: string;
  Obs: 'S' | 'N';
  Documento: 'S' | 'N';
  Mat: number;
  Numord: string;
  Empfil: number;
  DtInicio: {
    SetData: string;
    ToDate: string;
    IsDate: boolean;
    Empty: boolean;
  };
  DtVolta: {
    SetData: string;
    ToDate: string;
    IsDate: boolean;
    Empty: boolean;
  };
  CodAfa: number;
  DescAfa?: string;
  CodMot: number;
  DescMot?: string;
  CodDiag: string;
  CodMed: string;
  NumeroProcesso: string;
  NumeroDoe: string;
  IdClassificacaoAto: number | string;
  DescClassificacaoAto?: string;
  NumeroAto: string;
  AnoAto: string;
  DtAto: {
    SetData: string;
    ToDate: string;
    IsDate: boolean;
    Empty: boolean;
  };
  AnoDoe: string;
  DtDoe: {
    SetData: string;
    ToDate: string;
    IsDate: boolean;
    Empty: boolean;
  };
  DtComp: string;
  TpProc: string;
  Seq: number;
  ValInf: number;
}
