import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertaModal } from 'src/app/componentes/componentes-funcionais/alerta-modal/alerta-modal.component';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { ErroService } from 'src/app/service/erros/erro.service';
import Swal from 'sweetalert';
import { isNullOrUndefined } from 'util';
import { BancosFisicosService } from './manutencao-bd-fisico.service';

@Component({
  selector: 'app-manutencao-bd-fisicos',
  templateUrl: './manutencao-bd-fisicos.component.html',
  styleUrls: ['./manutencao-bd-fisicos.component.css']
})
export class ManutencaoBdFisicosComponent implements OnInit {

  localStorage: LocalStorage;

  formBancosFisicos: FormGroup;
  formTelaInicial: FormGroup;

  selectedProvinces = []
  listaServicos: any[];
  listaBancosFisicos: any[] = [];

  tipoUsuario: string;
  searchText: string;
  idExcluindo: number;

  clientePublico: boolean;
  loadingColaborador: boolean;
  validaTelaInicial: boolean = true;
  telaFormulario: boolean = false;
  temErro: boolean = false;
  revelarOlho: boolean;

  constructor(public fb: FormBuilder, private bdFisicosService: BancosFisicosService, private validaErro: ErroService, private spinner: NgxSpinnerService) {
    this.localStorage = this.decryptLocalStorage();
    this.tipoUsuario = this.localStorage.TipoUsuario
    this.clientePublico = this.localStorage.ClientePublico === true;
  }

  ngOnInit() {
    this.formBancosFisicos = this.fb.group({
      codigo: ['', Validators.required],
      descricao: ['', Validators.required],
      servidor: ['', Validators.required],
      nomeBanco: ['', Validators.required],
      login: ['', Validators.required],
      senha: ['', Validators.required]
    });

    this.formTelaInicial = this.fb.group({
      codServico_form: ['', [Validators.required]],
    });

    this.buscarServicos()
  }

  buscarServicos(): void {
    this.bdFisicosService.buscarServicosPse()
      .subscribe(
        (data: any[]) => {
          this.listaServicos = data
          // this.spinner.hide();
         
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide();
        }
      );
  }


  carregarBanco(banco: BancosFisicos): void {
    if (banco != null) {
      this.limparCampos()
      this.temErro = false

      this.formBancosFisicos.setValue({
        codigo: banco.CodigoBanco,
        descricao: banco.Descricao,
        servidor: banco.Servidor,
        nomeBanco: banco.NomeBanco,
        login: banco.CodigoUsuarioBd,
        senha: banco.Senha
      });
      this.formBancosFisicos.controls["codigo"].disable()
    } else {
      new AlertaModal('alerta','Atenção!','Cliente vazio ou nulo!','p')

    }

  }

  revelarSenha() {
    let input = document.querySelector('#password');

    if (input.getAttribute('type') == 'password') {
      input.setAttribute('type', 'text');
      this.revelarOlho = true
    } else {
      input.setAttribute('type', 'password');
      this.revelarOlho = false
    }
  }

  limparCampos(): void {
    this.formBancosFisicos.controls["codigo"].enable()

    this.formBancosFisicos.controls['codigo'].setValue("")
    this.formBancosFisicos.controls['descricao'].setValue("")
    this.formBancosFisicos.controls['servidor'].setValue("")
    this.formBancosFisicos.controls['nomeBanco'].setValue("")
    this.formBancosFisicos.controls['login'].setValue("")
    this.formBancosFisicos.controls['senha'].setValue("")

    this.formBancosFisicos.markAsPristine()
  }

  buscarBancosPorCodigo(): void {
    this.bdFisicosService.buscarBancosFisicos(this.formTelaInicial.get('codServico_form').value)
      .subscribe(
        (data: any[]) => {
          this.listaBancosFisicos = data
          // this.spinner.hide();
          if (!this.telaFormulario) { this.telaFormulario = !this.telaFormulario }
        },
        error => {
          // new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          // this.spinner.hide();
        }
      );
  }

  montarBancosFisicos(): BancosFisicos {
    const banco = this.formBancosFisicos.getRawValue()
    return {
      CodigoBanco: banco.codigo,
      CodigoServico: this.formTelaInicial.controls['codServico_form'].value,
      Descricao: banco.descricao,
      Servidor: banco.servidor,
      NomeBanco: banco.nomeBanco,
      CodigoUsuarioBd: banco.login,
      Senha: banco.senha
    }
  }

  salvarBancosFisicos(banco: BancosFisicos) {
    if (banco == null) {new AlertaModal('alerta','Atenção!','Preencha todos os campos obrigatórios!','p')}
    else {
      this.spinner.show()
      this.bdFisicosService.salvarBancoFisico(banco)
        .subscribe(
          data => {
            this.buscarBancosPorCodigo()
            new AlertaModal('sucesso','Sucesso!','Cliente salvo com sucesso!','p')

            this.limparCampos()
            this.spinner.hide()
          },
          error => {
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
            this.spinner.hide()
          })
    }
  }

  alterarBancoFisico(banco: BancosFisicos) {
    this.spinner.show()
    this.bdFisicosService.alterarBancoFisico(banco)
      .subscribe(
        data => {
          this.buscarBancosPorCodigo()
          new AlertaModal('sucesso','Sucesso!','Banco Físico alterado com sucesso!','p')
          this.limparCampos()
          this.spinner.hide()
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide()
        })
  }
// alteração swal pendente
  deletar(bancoFisico: BancosFisicos, index: number): void {
    this.idExcluindo = index;
    Swal({
      title: "Você tem certeza?",
      text: "Esta operação não pode ser desfeita.",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true
        }
      }
    })
      .then((willDelete) => {
        if (willDelete) {
          this.excluirBancoFisico(bancoFisico);
        }

      });
    this.idExcluindo = null;
  }

  excluirBancoFisico(bancoFisico: BancosFisicos) {
    this.spinner.show()
    this.bdFisicosService.deletarBancoFisico(bancoFisico.CodigoServico, bancoFisico.CodigoBanco)
      .subscribe(
        data => {
          new AlertaModal('sucesso','Sucesso!','Banco Físico excluído com sucesso!','p')
          this.limparCampos()
          this.buscarBancosPorCodigo()
          this.spinner.hide()
        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          this.spinner.hide()
        })
  }

  submitBancoFisico(): void {
    if (this.formBancosFisicos.invalid) {
      this.temErro = true;
      // this.campoObrigatorio = true
      new AlertaModal('alerta','Atenção!','Preencha todos os campos obrigatórios!','p')
    }
    else {
      const bancoMontado = this.montarBancosFisicos()

      let existeBanco = this.listaBancosFisicos.filter((banco: BancosFisicos) => banco.CodigoBanco == this.formBancosFisicos.controls["codigo"].value).length

      if (existeBanco > 0) {
        this.alterarBancoFisico(bancoMontado)
      }
      else {
        this.salvarBancosFisicos(bancoMontado);
      }
    }
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }
}

export interface BancosFisicos {
  CodigoBanco: string
  CodigoServico: string
  Descricao: string
  Servidor: string
  NomeBanco: string
  CodigoUsuarioBd: string
  Senha: string

}
