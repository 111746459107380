<app-top-menu></app-top-menu>
 
  <!-- Page content -->
  <div class="page-content">
 
    <app-side-menu></app-side-menu>
 
    <!-- Main content -->
    <div class="content-wrapper">
 
      <!-- Content area -->
      <div class="content mt-5">
 

        <router-outlet></router-outlet>

 
      </div>
      <!-- /content area -->
 
      <!-- Footer -->
      <div class="navbar navbar-expand-lg navbar-light">
        <div class="text-center d-lg-none w-100">
          <button type="button" class="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
            <i class="icon-unfold mr-2"></i>
            Footer
          </button>
        </div>
 
        <div class="navbar-collapse collapse" id="navbar-footer">
          <span class="navbar-text">
            Elógica Gente 2019
          </span>
        </div>
      </div>
      <!-- /footer -->
 
    </div>
    <!-- /main content -->
 
  </div>
  <!-- /page content -->
 <!-- <app-login></app-login> -->