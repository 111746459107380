import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';
import { OutrasDocumentacoesService } from './outras-documentacoes.service';



@Component({
  selector: 'app-outras-documentacoes',
  templateUrl: './outras-documentacoes.component.html',
  styleUrls: ['./outras-documentacoes.component.css']
})
export class OutrasDocumentacoesComponent {

matricula: number = null;
loading: boolean = false;
formColaborador: FormGroup;
empfil: string;
colaborador: Cadastro = null;
tipoPagina: string

constructor(
  public fb: FormBuilder,
  private spinner: NgxSpinnerService,
  private OutrasDocumentacoesService: OutrasDocumentacoesService
) {
 
  this.formColaborador = this.fb.group({
    matricula: ['', Validators.required],
    nome: [''],
  });
}



  matriculaListener(evento) {
    this.matricula = evento.Matricula;
    this.buscarColaboradorMatricula(this.matricula);
  }

  buscarColaboradorMatricula(matricula) {
    this.spinner.show();
    this.OutrasDocumentacoesService.buscarColaboradorMatricula(matricula).subscribe(
      (data: Cadastro) => {
        console.log(data);
        this.empfil = data.Filial.Codigo;
        this.setarFormColaborador(data);
        this.colaborador = data;
        this.spinner.hide();
      },
      (error) => {
        new AlertaModal('erro', 'Erro!', error.error.Mensagem, 'p');
        this.retornarTelaInicial();
        this.spinner.hide();
      }
    );
  }

  setarFormColaborador(data) {
    this.formColaborador.get('matricula').disable();
    this.formColaborador.get('matricula').setValue(this.matricula);
    this.formColaborador.get('nome').disable();
    this.formColaborador.get('nome').setValue(data.InformacoesBasicas.NomeCompleto);
    this.loading = false;
   
  }

  toggleAllCheckboxes(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const checkboxes = document.querySelectorAll('.document-checkbox');
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      checkbox.checked = isChecked;
    });
  }

  downloadPdf(documentType) {
    this.spinner.show();

    
    this.OutrasDocumentacoesService.downloadPdf(this.empfil, this.matricula, documentType).subscribe(
      (data: any) => {
        console.log(data);
        this.spinner.hide();

        const pdf = `data:application/pdf;base64,${data.Base64}`;
        const fileName = `${data.Nome}.pdf`;

        const downloadLink = document.createElement('a');
        downloadLink.href = pdf;
        downloadLink.download = fileName;
        downloadLink.click();
      },
      (err) => {
        console.error(err);
        this.spinner.hide();
      }
    );
}

downloadSelectedPdfs() {
  const checkboxes = document.querySelectorAll('.document-checkbox:checked');
  const types = Array.from(checkboxes).map((checkbox: HTMLInputElement) => checkbox.value);

  if (types.length > 0) {
    this.spinner.show();
    this.downloadPdfsSequentially(types);
  } else {
    alert('Nenhum documento selecionado.');
  }
}

async downloadPdfsSequentially(types: string[]) {
  for (const type of types) {
    await this.downloadPdf(type);
  }
  this.spinner.hide();
}

  retornarTelaInicial() {
    this.matricula = null;
    this.formColaborador.reset();
    this.formColaborador.markAsPristine();
  }
}
