<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<!-- FORMULARIO INICIAL -->
<div *ngIf="!destinoInativacao" class="content d-flex justify-content-center align-items-center">
  <form
    #telainicial="ngForm"
    [formGroup]="formTelaInicial"
    (ngSubmit)="buscarUsuario(formTelaInicial.get('matricula').value)"
  >
    <div class="card bg-dark mb-0 text-ligth">
      <div class="card-body">
        <div class="text-center mb-3">
          <h5 class="mb-0">
            <i class="fa fa-pencil-square-o 2x mr-1"></i>Transferência de Inativos
          </h5>
        </div>
        <!-- MATRICULA -->
        <div class="col-12">
          <label class="justify-content-start">Matrícula</label>
          <div class="input-group mb-2">
            <input
              formControlName="matricula"
              type="number"
              class="form-control"
              placeholder="Matrícula"
              maxlength="9"
            />
            <div class="input-group-prepend">
              <button
                [disabled]="formTelaInicial.get('matricula').invalid"
                (click)="buscarUsuario(formTelaInicial.get('matricula').value)"
                type="button"
                class="btn bg-primary border-primary"
                id="basic-addon1"
              >
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- USUARIO -->
        <div
          *ngIf="usuario"
          (click)="onMatriculaSubmit()"
          class="col-12 form-group"
          style="cursor: pointer; width: 240px"
        >
          <div
            class="col-12 p-1 rounded d-flex flex-column justify-content-center align-items-center"
            style="background-color: #728098"
          >
            <div class="col-12 mb-1 text-center">
              <img
                style="width: 5em"
                src="{{
                  usuario.Sexo == '1'
                    ? 'assets/img/avatar-masculino.svg'
                    : 'assets/img/avatar-feminino.svg'
                }}"
                width="30"
                height="40"
                alt=""
              />
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center text-center">
              <h4 class="mb-0" style="font-size: 15px; width: 210px">
                {{ usuario.Nome }}
              </h4>
            </div>
          </div>
        </div>
        <!-- TIPO DE PROCESSAMENTO -->
        <div class="col-12 form-group">
          <label><b>Tipo de Procedimento:</b></label>
          <div>
            <div class="mb-2 d-flex justify-content-between">
              <label class="mb-0 mr-2" [ngStyle]="{ cursor: 'pointer' }" for="radioAposentadoria"
                >Aposentadoria</label
              >
              <input
                id="radioAposentadoria"
                formControlName="tipoTransferencia"
                [value]="1"
                [ngStyle]="{ cursor: 'pointer' }"
                type="radio"
              />
            </div>
            <div class="mb-2 d-flex justify-content-between">
              <label class="mb-0 mr-2" [ngStyle]="{ cursor: 'pointer' }" for="radioAuxilioReclusao"
                >Auxílio Reclusão</label
              >
              <input
                id="radioAuxilioReclusao"
                [value]="2"
                formControlName="tipoTransferencia"
                [ngStyle]="{ cursor: 'pointer' }"
                type="radio"
              />
            </div>
          </div>
        </div>

        <!-- <div class="text-center">
          <button
            [disabled]="formTelaInicial.get('matricula').invalid"
            type="submit"
            class="btn btn-primary"
          >
            Buscar
          </button>
        </div> -->
      </div>
    </div>
  </form>
</div>
<!-- FIM FORMULARIO INICIAL -->

<!-- FORM TRANSFERENCIA INATIVO -->
<ng-container *ngIf="destinoInativacao">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title" id="target">
        <i
          class="fa fa-arrow-left pr-3 btn"
          aria-hidden="true"
          (click)="retornarTelaInicial()"
          style="cursor: pointer; bottom: 2px"
        ></i>
        <span>{{ formAposentadoria() ? 'Aposentadoria' : 'Auxílio Reclusão' }}</span>
      </h3>
    </div>
    <form [formGroup]="form" #processos="ngForm">
      <!-- BODY -->
      <div class="card-body">
        <div class="row">
          <!-- Data Transferencia -->
          <div class="col-sm-12 col-md-3 form-group">
            <label class="font-weight-semibold">{{
              formAposentadoria() ? 'Data de Aposentadoria' : 'Data de Auxílio'
            }}</label>
            <input
              class="form-control"
              type="date"
              formControlName="dataTransferencia"
              [ngClass]="isValid('dataTransferencia')"
              placeholder="Nome"
            />
            <span *ngIf="isValid('dataTransferencia')" class="form-text text-danger">
              {{
                form.get('dataTransferencia').hasError('dataMaiorQueAtual')
                  ? 'Data não pode ser maior que a data atual'
                  : 'Campo obrigatório'
              }}
            </span>
          </div>
          <!-- Tipo Aposentadoria -->
          <ng-container *ngIf="formAposentadoria()">
            <div class="col-sm-12 col-md-9 form-group">
              <label for="tipoAposentadoria" class="font-weight-semibold"
                >Tipo da Aposentadoria</label
              >
              <select
                [ngClass]="isValid('tipoAposentadoria')"
                class="form-control"
                type="text"
                formControlName="tipoAposentadoria"
              >
                <option [ngValue]="null">Selecione...</option>
                <option
                  *ngFor="let tipoAposentadoria of listaTipoAposentadoria"
                  [ngValue]="tipoAposentadoria"
                >
                  {{ tipoAposentadoria.Codigo }} - {{ tipoAposentadoria.Descricao }}
                </option>
              </select>

              <span *ngIf="isValid('tipoAposentadoria')" class="form-text text-danger">
                Campo obrigatório
              </span>
            </div>
          </ng-container>
          <!-- Tipo Proporção -->
          <ng-container *ngIf="formAposentadoria()">
            <div class="col-sm-12 col-md-3 form-group">
              <label for="tipoProporcao" class="font-weight-semibold">Tipo de Proporção</label>
              <select
                [ngClass]="isValid('tipoProporcao')"
                class="form-control"
                type="text"
                formControlName="tipoProporcao"
              >
                <option [ngValue]="null">Selecione...</option>
                <option *ngFor="let tipoProporcao of listaTipoProporcao" [ngValue]="tipoProporcao">
                  {{ tipoProporcao.Codigo }} - {{ tipoProporcao.Descricao }}
                </option>
              </select>
              <span *ngIf="isValid('tipoProporcao')" class="form-text text-danger">
                Campo obrigatório
              </span>
            </div>
          </ng-container>
          <!-- Valor da Proporção -->
          <ng-container *ngIf="formAposentadoria()">
            <div class="col-sm-12 col-md-3 form-group">
              <label class="font-weight-semibold"> Valor da Proporção </label>
              <input
                class="form-control"
                type="number"
                step="0.10"
                formControlName="valorProporcao"
                [ngClass]="isValid('valorProporcao')"
                placeholder="Valor da Proporção"
              />
              <span *ngIf="isValid('valorProporcao')" class="form-text text-danger">
                {{
                  form.get('valorProporcao').hasError('max')
                    ? 'Valor máximo: 100'
                    : 'Campo obrigatório'
                }}
              </span>
            </div>
          </ng-container>
          <!-- Número Processo -->
          <div class="col-sm-12 col-md-3 form-group">
            <label class="font-weight-semibold"> Nº Processo <small>opcional</small> </label>
            <input
              class="form-control"
              type="text"
              mask="A*"
              formControlName="NumeroProcesso"
              placeholder="Número do Processo"
              (change)="convertToUpperCase('NumeroProcesso')"
              [ngClass]="isValid('NumeroProcesso')"
            />
            <span *ngIf="isValid('NumeroProcesso')" class="form-text text-danger">
              "VALIDAR O ERRO"
            </span>
          </div>
          <!-- Número Doe -->
          <div class="col-sm-12 col-md-3 form-group">
            <label class="font-weight-semibold"> Nº DOE <small>opcional</small></label>
            <input
              class="form-control"
              type="text"
              mask="A*"
              formControlName="numDoe"
              placeholder="Número DOE"
              (change)="convertToUpperCase('numDoe')"
              [ngClass]="isValid('numDoe')"
            />
            <span *ngIf="isValid('numDoe')" class="form-text text-danger"> "VALIDAR O ERRO" </span>
          </div>
          <!-- Data DOE-->
          <div class="col-sm-12 col-md-3 form-group">
            <label class="font-weight-semibold"> Data DOE <small>opcional</small> </label>
            <input
              class="form-control"
              type="date"
              formControlName="dataDoe"
              [ngClass]="isValid('dataDoe')"
              (change)="setarAnoNoControle('dataDoe', 'anoDoe')"
            />
            <span *ngIf="isValid('dataDoe')" class="form-text text-danger">
              Data não pode ser maior que a data atual
            </span>
          </div>
          <!-- Ano Doe -->
          <div class="col-sm-12 col-md-3 form-group">
            <label class="font-weight-semibold"> Ano DOE <small>opcional</small></label>
            <input
              class="form-control"
              type="text"
              formControlName="anoDoe"
              [ngClass]="isValid('anoDoe')"
            />
          </div>
          <!-- Classificação Ato -->
          <div class="col-sm-12 col-md-6 form-group">
            <label for="classificacaoAto" class="font-weight-semibold"
              >Classificação Ato <small>opcional</small></label
            >
            <select
              [ngClass]="isValid('classificacaoAto')"
              class="form-control"
              type="text"
              formControlName="classificacaoAto"
            >
              <option [ngValue]="null">Selecione...</option>
              <option
                *ngFor="let classificacaoAto of listaClassificacaoAto"
                [ngValue]="classificacaoAto"
              >
                {{ classificacaoAto.Cod }} - {{ classificacaoAto.Nome }}
              </option>
            </select>

            <span *ngIf="isValid('classificacaoAto')" class="form-text text-danger">
              Campo obrigatório
            </span>
          </div>
          <!-- Número Ato -->
          <div class="col-sm-12 col-md-3 form-group">
            <label class="font-weight-semibold"> Nº ATO <small>opcional</small></label>
            <input
              class="form-control"
              type="text"
              mask="A*"
              formControlName="numAto"
              placeholder="Número ATO"
              (change)="convertToUpperCase('numAto')"
              [ngClass]="isValid('numAto')"
            />
            <span *ngIf="isValid('numAto')" class="form-text text-danger"> "VALIDAR O ERRO" </span>
          </div>
          <!-- Data ATO-->
          <div class="col-sm-12 col-md-3 form-group">
            <label class="font-weight-semibold"> Data ATO <small>opcional</small> </label>
            <input
              class="form-control"
              type="date"
              formControlName="dataAto"
              [ngClass]="isValid('dataAto')"
              (change)="setarAnoNoControle('dataAto', 'anoAto')"
            />
            <span *ngIf="isValid('dataAto')" class="form-text text-danger">
              Data não pode ser maior que a data atual
            </span>
          </div>
          <!-- Ano ATO -->
          <div class="col-sm-12 col-md-3 form-group">
            <label class="font-weight-semibold"> Ano ATO <small>opcional</small></label>
            <input
              class="form-control"
              type="text"
              formControlName="anoAto"
              [ngClass]="isValid('anoAto')"
            />
          </div>
        </div>

        <!-- FOOTER -->
        <div class="card-footer text-right">
          <button
            class="btn btn-danger"
            type="button"
            (click)="form.dirty ? form.reset() : retornarTelaInicial()"
          >
            {{ form.dirty ? 'Limpar' : 'Voltar' }}
          </button>
          <button class="btn btn-primary ml-2" (click)="onSubmit()" type="button">Salvar</button>
        </div>
        <!-- FIM FOOTER -->
      </div>
      <!-- FIM CARD BODY -->
    </form>
  </div>
</ng-container>
