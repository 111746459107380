<!--<p>grafico-beneficio works!</p>-->
<div>
  <div class="card">
    <div class="card-body">
      <div class="card-row chart has-fixed-heightd-flex d-flex justify-content-center" echarts [options]="graficoBeneficio" id="beneficioChart" ></div>
    </div>

    <div class="card-body">
      <div class="card-row chart has-fixed-heightd-flex d-flex justify-content-center" echarts [options]="graficoTotalPartesBeneficio" id="graficoTotalPartesBeneficio" ></div>
    </div>

    <div class="card-body">
      <div class="card-row chart has-fixed-heightd-flex d-flex justify-content-center" echarts [options]="graficoBeneficiarioVencimento" id="gupoBeneficiarioVencimentoChart" ></div>
    </div>

    <div class="card-body">
      <div class="card-row chart has-fixed-heightd-flex d-flex justify-content-center" echarts [options]="graficoRegrasBeneficiosVencimento" id="graficoRegrasBenifiosVencimentoChart" ></div>
    </div>

    <!--Gráfico para ser exibido no perfil de um colaborador-->
    <!--<div class="card-body">
      <div class="card-row"echarts [options]="graficoBeneficiarioRegrasBenifiosVencimento" id="graficoBeneficiarioRegrasBenifiosVencimento" class="chart has-fixed-heightd-flex d-flex justify-content-center"></div>
    </div>-->

  </div>
  <!--<div>-->
  <!-- <select>
      <option ngFor="let tipo for tiposBeneficiarios" value="{{tipo}}">{{tipo}}</option>
  </select> -->
  <!--<div echarts [options]="graficoTipoBeneficiario" id="tipoBeneficiarioChart" class="chart has-fixed-heightd-flex d-flex justify-content-center"></div>
  </div>-->
  <!--<div echarts [options]="graficoBeneficiario" id="beneficiarioChart" class="chart has-fixed-heightd-flex d-flex justify-content-center"></div>-->
  <!--<div echarts [options]="graficoGrupoBeneficio" id="gupoBeneficioChart" class="chart has-fixed-heightd-flex d-flex justify-content-center"></div>-->
</div>
