import { Injectable } from '@angular/core';
import { InformacoesSaude } from './cadastro-saude/cadastro-saude.component';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
@Injectable({
  providedIn: 'root',
})
export class InformacoesSaudeService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any;
  filial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }
  url_acesso: any = this.acesso.validarUrl();

  cadastrarInfo(informacoesSaude: InformacoesSaude, matricula: string) {
    return this.http.post(
      `${this.url_acesso}informacoes/saude/${matricula}/${this.filial}`,
      informacoesSaude,
      {
        headers: this.cabecalho,
      }
    );
  }

  atualizarInfo(informacoesSaude: InformacoesSaude, matricula: string) {
    return this.http.put(
      `${this.url_acesso}informacoes/saude/${matricula}/${this.filial}`,
      informacoesSaude,
      {
        headers: this.cabecalho,
      }
    );
  }

  buscarListaInformacoesSaude(matricula: string) {
    return this.http.get(`${this.url_acesso}informacoes/saude/${matricula}/${this.filial}`, {
      headers: this.cabecalho,
    });
  }
}
