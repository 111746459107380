<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
    <div _ngcontent-uxj-c4="" class="card" *ngIf="step <= 2">
            <div class="card-header header-elements-inline">
                <h3 class="card-title">Cadastro de Colaborador</h3>
            </div>
            <div _ngcontent-uxj-c4="" class="wizard-form steps-state-saving ng-untouched ng-pristine ng-valid wizard clearfix" novalidate="" role="application" id="steps-uid-0">
                <div  class="steps clearfix" *ngIf="step <= 2">
                    <ul role="tablist">
                        <li role="tab" [ngClass]="{'current': step == 0 , 'done':stepcount > 0  }" class="first" aria-disabled="false" aria-selected="true"><a id="steps-uid-0-t-0" aria-controls="steps-uid-0-p-0" class="" ><span class="current-info audible">current step: </span><span [ngClass]="{'pointer': stepcount > 0}" class="number">1</span> Informações Básicas</a></li>
                        <li role="tab" [ngClass]="{'current': step == 1 , 'disabled': stepcount < 1 ,'done':stepcount > 1 }" aria-disabled="true"><a id="steps-uid-0-t-1" aria-controls="steps-uid-0-p-1" ><span [ngClass]="{'pointer': stepcount > 1}" class="number">2</span> Dados Pessoais</a></li>
                        <li role="tab" [ngClass]="{'current': step == 2 , 'disabled': stepcount < 2 ,'done':stepcount > 2 }" aria-disabled="true"><a id="steps-uid-0-t-2" aria-controls="steps-uid-0-p-2" ><span [ngClass]="{'pointer': stepcount > 2}" class="number ">3</span> Documentos</a></li>
                        <!-- <li role="tab" [ngClass]="{'current': step == 3 , 'disabled': stepcount < 3 }" class="last" aria-disabled="true"><a id="steps-uid-0-t-3" aria-controls="steps-uid-0-p-3" class="disabled" ><span  class="number">4</span> Conclusão</a></li> -->
                    </ul>
                </div>
                <div class="content clearfix">
                    <h6 _ngcontent-uxj-c4="" *ngIf="step == 0" id="steps-uid-0-h-0" tabindex="-1" class="title current" >Informações Básicas</h6>
                    <form #informacaoBasica="ngForm" [formGroup]="formInformacaoBasica" [style.display]="step == 0 ? 'block' : 'none'" id="steps-uid-0-p-0" role="tabpanel" aria-labelledby="steps-uid-0-h-0" class="body current" aria-hidden="false">
                        <h3 _ngcontent-uxj-c4="" class="mt-2">Informações Básicas</h3>
                        <div _ngcontent-uxj-c4="" class="row">

                            <div _ngcontent-uxj-c4="" class="col-md-5">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">CPF</label>
                                    <input type="text" [ngStyle]="((formInformacaoBasica.controls['cpf_form'].status == 'INVALID' && formInformacaoBasica.controls['cpf_form'].dirty) || (formInformacaoBasica.controls['cpf_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control"  mask="000.000.000-00" placeholder="000.000.000-00" name="cpf_form" formControlName="cpf_form" [(ngModel)] = "cadastro_etapa_1.Documentos.Cpf" required>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['cpf_form'].dirty && formInformacaoBasica.controls['cpf_form'].status == 'INVALID' && !validaEtapa3">Digite um CPF válido</span>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['cpf_form'].status == 'INVALID' && validaEtapa3 ">Digite um CPF Válido</span>
                                </div>
                            </div>

                            <div _ngcontent-uxj-c4="" class="col-md-4 ">
                                <div _ngcontent-uxj-c4="" class="form-group ">
                                    <label class="justify-content-start">Nome Completo</label>
                                    <input type="text" [ngStyle]="(formInformacaoBasica.controls['nome_completo'].status == 'INVALID' && validaEtapa1) ? {'border-color':'red'} : null" class="form-control" id="nome_completo" name="nome_completo" formControlName="nome_completo" [(ngModel)] = "cadastro.InformacoesBasicas.NomeCompleto" minlength="12" maxlength="80" required>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['nome_completo'].hasError('required') && validaEtapa1 ">Digite o Nome Completo</span>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['nome_completo'].dirty && formInformacaoBasica.controls['nome_completo'].hasError('required') && !validaEtapa1">Digite o Nome Completo</span>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['nome_completo'].hasError('minlength')">Digite o Nome com 12 dígitos no mínimo</span>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['nome_completo'].hasError('maxlength')">Digite o Nome com 80 dígitos no máximo</span>
                                </div>
                            </div>

                            <div class="col-md-5" *ngIf="cadastro_etapa_1.IsCadastro ==true">
                                <div  class="form-group" >
                                    <div class="row">
                                        <label class="col-4">Matrícula </label>
                                            <label style="margin-left: -40px;" >Inserir Manualmente</label>
                                            <input class="form-check-input" style="margin-left:80px; accent-color: #007bff;" type="checkbox" value="" id="FlexCheckDefault">   
                                    </div>
                                    <div class="row" *ngIf="boolMatricula == false">
                                        <input  type="text" mask="0*" [disabled]="true" [ngStyle]="(formInformacaoBasica.controls['matricula'].status == 'INVALID' && validaEtapa1) ? {'border-color':'red'} : null" class="form-control col-9 ml-2" id="matricula" name="matricula" formControlName="matricula" >
                                    </div>
                                    <input *ngIf="boolMatricula == true" type="number" placeholder="Automática" [ngStyle]="(formInformacaoBasica.controls['matricula'].status == 'INVALID' && validaEtapa1) ? {'border-color':'red'} : null" class="form-control col-12" id="matricula" name="matricula" formControlName="matricula"> 
                                </div>
                            </div>
                            <div class="col-md-3" *ngIf="cadastro_etapa_1.IsCadastro ==false">
                                <label class="col-4">Matrícula </label>
                                <input type="text" mask="0*"  class="form-control col-12" id="matricula" name="matricula" formControlName="matricula">
                            </div>

                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Departamento</label>
                                    <select [ngStyle]="((formInformacaoBasica.controls['departamento_form'].status == 'INVALID' && formInformacaoBasica.controls['departamento_form'].dirty) || (formInformacaoBasica.controls['departamento_form'].status == 'INVALID' && validaEtapa1)) ? {'border-color':'red'} : null" class="form-control form-control-uniform" name="departamento_form" (change)="buscarSetor($event,0)" formControlName="departamento_form" [(ngModel)] = "cadastro.InformacoesBasicas.Departamento.Cod" required>
                                        <option value ="" > Selecione...</option>
                                        <option *ngFor="let dep of departamento"  value ="{{dep.CodLot}}" >{{dep.Nome}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['departamento_form'].dirty && formInformacaoBasica.controls['departamento_form'].hasError('required') && !validaEtapa1">Selecione o Departamento</span>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['departamento_form'].status == 'INVALID' && validaEtapa1 ">Selecione o Departamento</span>
                                </div>
                            </div>  
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Setor</label>
                                    <select [ngStyle]="((formInformacaoBasica.controls['setor_form'].status == 'INVALID' && formInformacaoBasica.controls['setor_form'].dirty) || (formInformacaoBasica.controls['setor_form'].status == 'INVALID' && validaEtapa1)) ? {'border-color':'red'} : null" class="form-control form-control-uniform" (change)="buscarCargo($event,0)" name="setor_form" formControlName="setor_form" [(ngModel)] = "cadastro.InformacoesBasicas.Setor.Cod" required>
                                        <option value ="" > Selecione...</option>
                                        <option _ngcontent-uxj-c4="" *ngFor="let setor of setores"  value="{{setor.CodLot}}" ng-reflect-value="">{{setor.Nome}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['setor_form'].dirty && formInformacaoBasica.controls['setor_form'].hasError('required') && !validaEtapa1">Selecione o Setor</span>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['setor_form'].status == 'INVALID' && validaEtapa1 ">Selecione o Setor</span>
                                    <span class="form-text" *ngIf="carrega_setor">Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i></span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Data de Admissão</label>
                                    <input type="date" style="padding-right: 0;" [ngStyle]="((formInformacaoBasica.controls['data_admissao_form'].status == 'INVALID' && formInformacaoBasica.controls['data_admissao_form'].dirty) || (formInformacaoBasica.controls['data_admissao_form'].status == 'INVALID' && validaEtapa1)) ? {'border-color':'red'} : null" class="form-control" formControlName="data_admissao_form" [(ngModel)] = "cadastro.InformacoesBasicas.DataAdmissao.ToDate" required>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['data_admissao_form'].dirty && formInformacaoBasica.controls['data_admissao_form'].hasError('data') && !validaEtapa1">Digite uma Data Válida</span>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['data_admissao_form'].status == 'INVALID' && validaEtapa1 ">Digite uma Data Válida</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Vínculo</label>
                                    <select [ngStyle]="((formInformacaoBasica.controls['vinculo_form'].status == 'INVALID' && formInformacaoBasica.controls['vinculo_form'].dirty) || (formInformacaoBasica.controls['vinculo_form'].status == 'INVALID' && validaEtapa1)) ? {'border-color':'red'} : null" class="form-control form-control-uniform"  name="vinculo_form" formControlName="vinculo_form" [(ngModel)] = "cadastro.InformacoesBasicas.Vinculo.Codigo" required>
                                        <option value ="" > Selecione...</option>
                                        <option _ngcontent-uxj-c4="" *ngFor="let vin of vinculo" value="{{vin.Codigo}}" ng-reflect-value=""> {{vin.Nome}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['vinculo_form'].dirty && formInformacaoBasica.controls['vinculo_form'].hasError('required') && !validaEtapa1">Selecione um Vínculo</span>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['vinculo_form'].status == 'INVALID' && validaEtapa1 ">Selecione um Vínculo</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Cargo</label>
                                    <select [ngStyle]="((formInformacaoBasica.controls['cargo_form'].status == 'INVALID' && formInformacaoBasica.controls['cargo_form'].dirty) || (formInformacaoBasica.controls['cargo_form'].status == 'INVALID' && validaEtapa1)) ? {'border-color':'red'} : null" class="form-control form-control-uniform" (change)="buscarHoraTrabalho($event,0);" name="cargo_form" formControlName="cargo_form" [(ngModel)] = "cadastro.InformacoesBasicas.Cargo.Codigo" required>
                                        <option value ="" > Selecione...</option>
                                        <option _ngcontent-uxj-c4="" *ngFor="let cargo of cargos" value="{{cargo.Codigo}}">{{cargo.Nome}} </option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['cargo_form'].dirty && formInformacaoBasica.controls['cargo_form'].hasError('required') && !validaEtapa1">Selecione um Cargo</span>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['cargo_form'].status == 'INVALID' && validaEtapa1 ">Selecione um Cargo</span>
                                    <span class="form-text" *ngIf="carrega_cargo">Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i></span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Salário</label>
                                    <select  [ngStyle]="((formInformacaoBasica.controls['nivel_form'].status == 'INVALID' && formInformacaoBasica.controls['nivel_form'].dirty) || (formInformacaoBasica.controls['nivel_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control form-control-uniform" name="estado_form" formControlName="nivel_form" [(ngModel)] = "cadastro.InformacoesBasicas.Salario.CodigoNivel" required>
                                        <option value ="" > Selecione...</option>
                                        <option *ngFor="let ni of nivel | orderBy: 'Nome': false" value="{{ni.Codigo}}">{{ni.Nome}} - {{ ni.Valor}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['nivel_form'].dirty && formInformacaoBasica.controls['nivel_form'].hasError('required') && !validaEtapa2">Selecione o Estado</span>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['nivel_form'].status == 'INVALID' && validaEtapa2 ">Selecione o Salario </span>
                                </div>
                            </div>        

                                                
                        </div>
                        <div _ngcontent-uxj-c4="" class="row">
                         
                                            
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Sindicato</label>
                                    <select [ngStyle]="((formInformacaoBasica.controls['sindicato_form'].status == 'INVALID' && formInformacaoBasica.controls['sindicato_form'].dirty) || (formInformacaoBasica.controls['sindicato_form'].status == 'INVALID' && validaEtapa1)) ? {'border-color':'red'} : null" class="form-control form-control-uniform" name="sindicato_form" formControlName="sindicato_form" [(ngModel)] = "cadastro.InformacoesBasicas.Sindicato.Codigo" required >
                                        <option value ="" > Selecione...</option>
                                        <option _ngcontent-uxj-c4="" *ngFor="let sindicato of sindicatos" value="{{sindicato.Codigo}}" ng-reflect-value="">{{sindicato.Nome}} </option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['sindicato_form'].dirty && formInformacaoBasica.controls['sindicato_form'].hasError('required') && !validaEtapa1 ">Selecione um Sindicato</span>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['sindicato_form'].status == 'INVALID' && validaEtapa1 ">Selecione um Sindicato</span>
                                </div>
                                
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Dias úteis </label>
                                    <select [ngStyle]="(formInformacaoBasica.controls['diasUteis'].status == 'INVALID' && validaEtapa1) ? {'border-color':'red'} : null" class="form-control form-control-uniform"  name="diasUteis" formControlName="diasUteis" required>
                                        <option value ="" > Selecione...</option>
                                        <option _ngcontent-uxj-c4="" *ngFor="let dias of diasUteis"  value="{{dias.Codigo}}" ng-reflect-value="">{{dias.Descricao}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['diasUteis'].dirty && formInformacaoBasica.controls['diasUteis'].hasError('required') && !validaEtapa1">Selecione o Setor</span>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['diasUteis'].status == 'INVALID' && validaEtapa1 ">Selecione o Setor</span>
                                    <span class="form-text" *ngIf="carrega_setor">Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i></span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-4">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Carga Horária</label>
                                    <select [ngStyle]="((formInformacaoBasica.controls['cargaH_form'].status == 'INVALID' && formInformacaoBasica.controls['cargaH_form'].dirty) || (formInformacaoBasica.controls['cargaH_form'].status == 'INVALID' && validaEtapa1)) ? {'border-color':'red'} : null" class="form-control form-control-uniform" (change)="habilitarHoraTrabalho($event,0)" name="cargaH_form" formControlName="cargaH_form" [(ngModel)] = "cadastro.InformacoesBasicas.HorarioTrabalho.CargaHorariaSemana" required>
                                        <option value ="" > Selecione...</option>
                                        <option _ngcontent-uxj-c4="" *ngFor="let carga of cargaHorariaFilter | orderBy: 'CargaHorariaSemana': true" value="{{carga.cod}}">{{carga.cod}} Horas </option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['cargaH_form'].dirty && formInformacaoBasica.controls['cargaH_form'].hasError('required') && !validaEtapa1">Selecione a Carga Horária</span>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['cargaH_form'].status == 'INVALID' && validaEtapa1 ">Selecione a Carga Horária</span>
                                    <span class="form-text" *ngIf="carrega_hora_trabalho">Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i></span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-4">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Tipo Folha</label>
                                    <select [ngStyle]="(formInformacaoBasica.controls['tipoFolha_form'].status == 'INVALID' && validaEtapa1) ? {'border-color':'red'} : null" class="form-control form-control-uniform" name="tipoFolha_form" formControlName="tipoFolha_form" [(ngModel)]="cadastro.InformacoesBasicas.TipoFolha" required>
                                        <option value ="" > Selecione...</option>
                                        <option _ngcontent-uxj-c4="" *ngFor="let tipo of tipoFolha" value="{{tipo.Codigo}}" ng-reflect-value="">{{tipo.Descricao}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['tipoFolha_form'].dirty && formInformacaoBasica.controls['tipoFolha_form'].hasError('required') && !validaEtapa1">Selecione a Hora de Trabalho</span>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['tipoFolha_form'].status == 'INVALID' && validaEtapa1 ">Selecione Tipo de Folha </span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-4">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Hora de Trabalho (Entrada/Intervalo/Saída)</label>
                                    <select [ngStyle]="((formInformacaoBasica.controls['horaT_form'].status == 'INVALID' && formInformacaoBasica.controls['horaT_form'].dirty) || (formInformacaoBasica.controls['horaT_form'].status == 'INVALID' && validaEtapa1)) ? {'border-color':'red'} : null" class="form-control form-control-uniform" name="horaT_form" formControlName="horaT_form" [(ngModel)] = "cadastro.InformacoesBasicas.HorarioTrabalho.Codigo" required>
                                        <option value ="" > Selecione...</option>
                                        <option _ngcontent-uxj-c4="" *ngFor="let hora of horarioTrabalhoFilter" value="{{hora.Codigo}}" ng-reflect-value="">{{mascaraHora(hora.HoraEntrada)}} - {{mascaraHora(hora.InicioIntervalo)}} / {{mascaraHora(hora.InicioIntervalo)}} - {{mascaraHora(hora.TerminoIntervalo)}} / {{mascaraHora(hora.TerminoIntervalo)}} - {{mascaraHora(hora.HoraSaida)}} </option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['horaT_form'].dirty && formInformacaoBasica.controls['horaT_form'].hasError('required') && !validaEtapa1">Selecione a Hora de Trabalho</span>
                                    <span class="form-text text-danger" *ngIf="formInformacaoBasica.controls['horaT_form'].status == 'INVALID' && validaEtapa1 ">Selecione a Hora de Trabalho</span>
                                </div>
                            </div>
                        </div>
                        <app-parametros ></app-parametros>
                        <button *ngIf="step ===0" type="button" class="btn btn-primary ml-2" (click)="abiriModalIndicadores()">Indicadores do Cálculo</button>
                    </form>
    
                    <!-- início da etapa 2 -->
                    <h6 *ngIf="step == 1" id="steps-uid-0-h-1" tabindex="-1" class="title">Dados Pessoais</h6>
                    <form #dadospessoais="ngForm" [formGroup]="formDadosPessoais"  [style.display]="step == 1 ? 'block' : 'none'" id="steps-uid-0-p-1" role="tabpanel" aria-labelledby="steps-uid-0-h-1" class="body" aria-hidden="true">
                        <h3>Dados Pessoais</h3>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label _ngcontent-uxj-c4="">Data de Nascimento</label>
                                    <input type="date" style="padding-right: 0;" [ngStyle]="((formDadosPessoais.controls['data_nascimento_form'].status == 'INVALID' && formDadosPessoais.controls['data_nascimento_form'].dirty) || (formDadosPessoais.controls['data_nascimento_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control"  formControlName="data_nascimento_form"  [(ngModel)] = "cadastro.Pessoal.DadosBasicos.DataNascimento.ToDate" required>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['data_nascimento_form'].dirty && formDadosPessoais.controls['data_nascimento_form'].hasError('data') && !validaEtapa2">Digite uma Data Válida</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['data_nascimento_form'].status == 'INVALID' && validaEtapa2 ">Digite uma Data Válida</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>Escolaridade</label>
                                    <select [ngStyle]="((formDadosPessoais.controls['escolaridade_form'].status == 'INVALID' && formDadosPessoais.controls['escolaridade_form'].dirty) || (formDadosPessoais.controls['escolaridade_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control form-control-uniform" name="escolaridade_form" formControlName="escolaridade_form" [(ngModel)] = "cadastro.Pessoal.DadosBasicos.GrauDeInstrucao.Valor" required>
                                        <option value ="" > Selecione...</option>    
                                        <option *ngFor="let esc of escolaridade | orderBy: 'Descricao': false" value="{{esc.Valor}}">{{esc.Descricao}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['escolaridade_form'].dirty && formDadosPessoais.controls['escolaridade_form'].hasError('required') && !validaEtapa2">Selecione a Escolaridade</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['escolaridade_form'].status == 'INVALID' && validaEtapa2 ">Selecione a Escolaridade</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>Nacionalidade</label>
                                    <select [ngStyle]="((formDadosPessoais.controls['nacionalidade_form'].status == 'INVALID' && formDadosPessoais.controls['nacionalidade_form'].dirty) || (formDadosPessoais.controls['nacionalidade_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control form-control-uniform"  name="nacionalidade_form" formControlName="nacionalidade_form" [(ngModel)] = "cadastro.Pessoal.DadosBasicos.Nacionalidade.Codigo" required>
                                        <option value ="" > Selecione...</option>    
                                        <option *ngFor="let nac of nacionalidade | orderBy: 'Descricao': false" value="{{nac.Valor}}">{{nac.Descricao}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['nacionalidade_form'].dirty && formDadosPessoais.controls['nacionalidade_form'].hasError('required') && !validaEtapa2">Selecione a Nacionalidade</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['nacionalidade_form'].status == 'INVALID' && validaEtapa2 ">Selecione a Nacionalidade</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label >Cor/Raça</label>
                                    <select [ngStyle]="((formDadosPessoais.controls['cor_form'].status == 'INVALID' && formDadosPessoais.controls['cor_form'].dirty) || (formDadosPessoais.controls['cor_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control form-control-uniform"  name="cor_form" formControlName="cor_form" [(ngModel)] = "cadastro.Pessoal.DadosBasicos.Etnia.Descricao" required>
                                        <option value ="" > Selecione...</option>
                                        <option *ngFor="let co of cor | orderBy: 'Descricao': false" value="{{co.Descricao}}">{{co.Descricao}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['cor_form'].dirty && formDadosPessoais.controls['cor_form'].hasError('required') && !validaEtapa2">Selecione a Cor/Raça</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['cor_form'].status == 'INVALID' && validaEtapa2 ">Selecione a Cor/Raça</span>
                                </div>
                            </div>
                        </div>
                        <div _ngcontent-uxj-c4="" class="row">
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>Sexo</label>
                                    <select  [ngStyle]="((formDadosPessoais.controls['sexo_form'].status == 'INVALID' && formDadosPessoais.controls['sexo_form'].dirty) || (formDadosPessoais.controls['sexo_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control form-control-uniform"  name="sexo_form" formControlName="sexo_form" [(ngModel)] = "cadastro.Pessoal.DadosBasicos.Sexo.Valor" required>
                                        <option value ="" > Selecione...</option>
                                        <option *ngFor="let sex of sexo | orderBy: 'Descricao': false" value="{{sex.Valor}}">{{sex.Descricao}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['sexo_form'].dirty && formDadosPessoais.controls['sexo_form'].hasError('required') && !validaEtapa2">Selecione o Sexo</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['sexo_form'].status == 'INVALID' && validaEtapa2 ">Selecione o Sexo</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>Estado Civil</label>
                                    <select [ngStyle]="((formDadosPessoais.controls['estado_civil_form'].status == 'INVALID' && formDadosPessoais.controls['estado_civil_form'].dirty) || (formDadosPessoais.controls['estado_civil_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control form-control-uniform"  name="estado_civil_form" formControlName="estado_civil_form" [(ngModel)] = "cadastro.Pessoal.DadosBasicos.EstadoCivil.Valor" required>
                                        <option value ="" > Selecione...</option>
                                        <option *ngFor="let est of estadoCivil | orderBy: 'Descricao': false" value="{{est.Valor}}">{{est.Descricao}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['estado_civil_form'].dirty && formDadosPessoais.controls['estado_civil_form'].hasError('required') && !validaEtapa2">Selecione o Estado Civil</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['estado_civil_form'].status == 'INVALID' && validaEtapa2 ">Selecione o Estado Civil</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Nome da Mãe</label>
                                    <input placeholder="Nome da Mãe" [ngStyle]="((formDadosPessoais.controls['mae_form'].status == 'INVALID' && formDadosPessoais.controls['mae_form'].dirty) || (formDadosPessoais.controls['mae_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" type="text" class="form-control" name="mae_form" formControlName="mae_form" [(ngModel)] = "cadastro.Pessoal.DadosBasicos.NomeMae" required>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['mae_form'].dirty && formDadosPessoais.controls['mae_form'].hasError('required') && !validaEtapa2">Digite o Nome da Mãe</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['mae_form'].status == 'INVALID' && validaEtapa2 ">Digite o Nome da Mãe</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Nome do Pai <small>(opcional)</small></label>
                                    <input placeholder="Nome do pai" type="text" [ngStyle]="((formDadosPessoais.controls['pai_form'].status == 'INVALID' && formDadosPessoais.controls['pai_form'].dirty) || (formDadosPessoais.controls['pai_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control" name="pai_form" formControlName="pai_form" [(ngModel)] = "cadastro.Pessoal.DadosBasicos.NomePai" >
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['pai_form'].dirty && formDadosPessoais.controls['pai_form'].status == 'INVALID' && !validaEtapa2">Digite o Nome do Pai</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['pai_form'].status == 'INVALID' && validaEtapa2 ">Digite o Nome do Pai</span>
                                </div>
                            </div>
                        </div>
                        <h3 _ngcontent-uxj-c4="">Endereço</h3>
                        <div _ngcontent-uxj-c4="" class="row">
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">CEP</label>
                                    <input placeholder="CEP" type="text" [ngStyle]="((formDadosPessoais.controls['cep_form'].status == 'INVALID' && formDadosPessoais.controls['cep_form'].dirty) || (formDadosPessoais.controls['cep_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control" name="cep_form" formControlName="cep_form" (blur)="buscarCep(onBlur($event.target), 0)" mask="00000-000" [(ngModel)] = "cadastro.Pessoal.Endereco.Cep" required>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['cep_form'].dirty && formDadosPessoais.controls['cep_form'].hasError('required') && !validaEtapa2">Digite o Cep</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['cep_form'].status == 'INVALID' && validaEtapa2 ">Digite o Cep</span>
                                    <span class="form-text" *ngIf="carrega_cep">Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i></span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>Logradouro</label>
                                    <input type="text" placeholder="Logradouro" [ngStyle]="((formDadosPessoais.controls['logradouro_form'].status == 'INVALID' && formDadosPessoais.controls['logradouro_form'].dirty) || (formDadosPessoais.controls['logradouro_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control" name="logradouro_form" formControlName="logradouro_form" maxlength="60" [(ngModel)] = "cadastro.Pessoal.Endereco.Logradouro" required>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['logradouro_form'].dirty && formDadosPessoais.controls['logradouro_form'].hasError('required') && !validaEtapa2">Digite o Logradouro</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['logradouro_form'].status == 'INVALID' && validaEtapa2 ">Digite o Logradouro</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Nº</label>
                                    <input type="text" placeholder="Nº"  [ngStyle]="((formDadosPessoais.controls['numero_form'].status == 'INVALID' && formDadosPessoais.controls['numero_form'].dirty) || (formDadosPessoais.controls['numero_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control"  name="numero_form" formControlName="numero_form" [(ngModel)] = "cadastro.Pessoal.Endereco.Numero" required>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['numero_form'].dirty && formDadosPessoais.controls['numero_form'].hasError('required') && !validaEtapa2">Digite o Número</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['numero_form'].status == 'INVALID' && validaEtapa2 ">Digite o Número</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Complemento <small>(opcional)</small></label>
                                    <input type="text" class="form-control" placeholder="Complemento"  name="complemento_form" formControlName="complemento_form">
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Bairro</label>
                                    <input placeholder="Bairro" type="text" readonly [ngStyle]="((formDadosPessoais.controls['bairro_form'].status == 'INVALID' && formDadosPessoais.controls['bairro_form'].dirty) || (formDadosPessoais.controls['bairro_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control"  name="bairro_form" formControlName="bairro_form" maxlength="60" [(ngModel)] = "cadastro.Pessoal.Endereco.Bairro" required>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['bairro_form'].dirty && formDadosPessoais.controls['bairro_form'].hasError('required') && !validaEtapa2">Digite o Bairro</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['bairro_form'].status == 'INVALID' && validaEtapa2 ">Digite o Bairro</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Estado</label>
                                    <select readonly [ngStyle]="((formDadosPessoais.controls['estado_form'].status == 'INVALID' && formDadosPessoais.controls['estado_form'].dirty) || (formDadosPessoais.controls['estado_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control form-control-uniform" name="estado_form" formControlName="estado_form" [(ngModel)] = "cadastro.Pessoal.Endereco.Estado" required>
                                        <option value ="" > Selecione...</option>
                                        <option *ngFor="let est of estado | orderBy: 'Nome': false" value="{{est.SiglaUf}}">{{est.Nome}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['estado_form'].dirty && formDadosPessoais.controls['estado_form'].hasError('required') && !validaEtapa2">Selecione o Estado</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['estado_form'].status == 'INVALID' && validaEtapa2 ">Selecione o Estado</span>
                                </div>
                            </div>
                        </div>
                        <div _ngcontent-uxj-c4="" class="row">
                           
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Cidade</label>
                                    <select readonly [ngStyle]="((formDadosPessoais.controls['cidade_form'].status == 'INVALID' && formDadosPessoais.controls['cidade_form'].dirty) || (formDadosPessoais.controls['cidade_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control form-control-uniform" name="cidade_form" formControlName="cidade_form" [(ngModel)] = "cadastro.Pessoal.Endereco.Cidade" required>
                                        <option value ="" > Selecione...</option>
                                        <option *ngFor="let cid of cidade | orderBy: 'Nome': false" value="{{cid.Nome}}">{{cid.Nome}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['cidade_form'].dirty && formDadosPessoais.controls['cidade_form'].hasError('required') && !validaEtapa2">Selecione a Cidade</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['cidade_form'].status == 'INVALID' && validaEtapa2 ">Selecione a Cidade</span>
                                </div>
                            </div>
        
                        </div>
                        <h3 _ngcontent-uxj-c4="">Contatos</h3>
                        <div _ngcontent-uxj-c4="" class="row">
        
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Telefone Fixo <small>(opcional)</small></label>
                                    <input placeholder="(81)0000-0000" type="text" [ngStyle]="((formDadosPessoais.controls['telefone_form'].status == 'INVALID' && formDadosPessoais.controls['telefone_form'].dirty) || (formDadosPessoais.controls['telefone_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null"  class="form-control"  name="telefone_form" formControlName="telefone_form" mask="(00)0000-0000" [(ngModel)] = "telefone_form">
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['telefone_form'].dirty && formDadosPessoais.controls['telefone_form'].status == 'INVALID ' && !validaEtapa2">Digite o Telefone</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['telefone_form'].status == 'INVALID' && validaEtapa2 ">Digite o Telefone</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>Telefone Celular</label>
                                    <input type="text" placeholder="(81)90000-0000" [ngStyle]="((formDadosPessoais.controls['celular_form'].status == 'INVALID' && formDadosPessoais.controls['celular_form'].dirty) || (formDadosPessoais.controls['celular_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control"  name="celular_form" formControlName="celular_form" required mask="(00)00000-0000" [(ngModel)] = "celular_form" required>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['celular_form'].dirty && formDadosPessoais.controls['celular_form'].hasError('required') && !validaEtapa2">Digite o Celular</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['celular_form'].status == 'INVALID' && validaEtapa2 ">Digite o Celular</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Email Principal</label>
                                    <input placeholder="Exemplo@Email.com" type="email" [ngStyle]="((formDadosPessoais.controls['email_form'].status == 'INVALID' && formDadosPessoais.controls['email_form'].dirty) || (formDadosPessoais.controls['email_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control"  name="email_form" formControlName="email_form" maxlength="50" [(ngModel)] = "cadastro.Pessoal.Contato.Email1" required>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['email_form'].dirty && formDadosPessoais.controls['email_form'].hasError('required') && !validaEtapa2">Digite o Email</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['email_form'].status == 'INVALID' && validaEtapa2 ">Digite o Email</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['email_form'].hasError('maxlength')">Digite o Email com 50 dígitos no máximo</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Email Secundário <small>(opcional)</small></label>
                                    <input placeholder="Exemplo@Email.com" type="email" [ngStyle]="((formDadosPessoais.controls['email2_form'].status == 'INVALID' && formDadosPessoais.controls['email2_form'].dirty) || (formDadosPessoais.controls['email2_form'].status == 'INVALID' && validaEtapa2)) ? {'border-color':'red'} : null" class="form-control" name="email2_form" formControlName="email2_form" [(ngModel)] = "cadastro.Pessoal.Contato.Email2 == 'NÃO INFORMADO'? '' : cadastro.Pessoal.Contato.Email2">
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['email2_form'].dirty && formDadosPessoais.controls['email2_form'].status == 'INVALID' && !validaEtapa2">Digite um Email Válido</span>
                                    <span class="form-text text-danger" *ngIf="formDadosPessoais.controls['email2_form'].status == 'INVALID' && validaEtapa2 ">Digite um Email Válido</span>
                                </div>
                            </div>
                        </div>
                    </form>
        
                    <h6 _ngcontent-uxj-c4="" *ngIf="step == 2" id="steps-uid-0-h-2" tabindex="-1" class="title">Documentos</h6>
                    <form #documentos="ngForm" [formGroup]="formDocumentos" [style.display]="step == 2 ? 'block' : 'none'" id="steps-uid-0-p-2" role="tabpanel" aria-labelledby="steps-uid-0-h-2" class="body" aria-hidden="true">
                        <h3 _ngcontent-uxj-c4="">Documentos</h3>
                        <div _ngcontent-uxj-c4="" class="row">
                            <!-- <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">CPF</label>
                                    <input type="text" [ngStyle]="((formDocumentos.controls['cpf_form'].status == 'INVALID' && formDocumentos.controls['cpf_form'].dirty) || (formDocumentos.controls['cpf_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control"  mask="000.000.000-00" name="cpf_form" formControlName="cpf_form" required>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['cpf_form'].dirty && formDocumentos.controls['cpf_form'].status == 'INVALID' && !validaEtapa3">Digite um CPF válido</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['cpf_form'].status == 'INVALID' && validaEtapa3 ">Digite um CPF Válido</span>
                                </div>
                            </div> -->
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">RG</label>
                                    <input type="text" mask="00.000.000-0" placeholder="00.000.000-0" [ngStyle]="((formDocumentos.controls['rg_form'].status == 'INVALID' && formDocumentos.controls['rg_form'].dirty) || (formDocumentos.controls['rg_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control" name="rg_form" formControlName="rg_form" (keypress)='somenteNumero($event)' required>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['rg_form'].dirty && formDocumentos.controls['rg_form'].hasError('required') && !validaEtapa3">Digite o RG</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['rg_form'].status == 'INVALID' && validaEtapa3 ">Digite um RG Válido</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Data Emissão RG</label>
                                    <input  type="date" style="padding-right: 0;" [ngStyle]="((formDocumentos.controls['data_emissao_form'].status == 'INVALID' && formDocumentos.controls['data_emissao_form'].dirty) || (formDocumentos.controls['data_emissao_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control"formControlName="data_emissao_form" required>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['data_emissao_form'].dirty && formDocumentos.controls['data_emissao_form'].hasError('data') && !validaEtapa3">Digite uma Data Válida</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['data_emissao_form'].status == 'INVALID' && validaEtapa3 ">Digite uma Data Válida</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-3">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Órgão Emissor</label>
                                    <input placeholder="Órgão" type="text" [ngStyle]="((formDocumentos.controls['orgao_form'].status == 'INVALID' && formDocumentos.controls['orgao_form'].dirty) || (formDocumentos.controls['orgao_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control" name="orgao_form" formControlName="orgao_form" maxlength="8">
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['orgao_form'].dirty && formDocumentos.controls['orgao_form'].hasError('required') && !validaEtapa3">Digite o Orgão Emissor</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['orgao_form'].status == 'INVALID' && validaEtapa3 ">Digite o Orgão Emissor</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-3">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">UF Emissor RG</label>
                                    <select [ngStyle]="((formDocumentos.controls['uf_emissor_form'].status == 'INVALID' && formDocumentos.controls['uf_emissor_form'].dirty) || (formDocumentos.controls['uf_emissor_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control form-control-uniform" name="uf_emissor_form" formControlName="uf_emissor_form" required>
                                        <option value ="" > Selecione...</option>
                                        <option *ngFor="let rg of ufRg | orderBy: 'Nome': false" value="{{rg.SiglaUf}}">{{rg.Nome}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['uf_emissor_form'].dirty && formDocumentos.controls['uf_emissor_form'].hasError('required') && !validaEtapa3">Selecione o UF Emissor</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['uf_emissor_form'].status == 'INVALID' && validaEtapa3 ">Selecione o UF Emissor</span>
                                </div> 
                            </div>
                        </div>
                        <div _ngcontent-uxj-c4="" class="row">
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">CNH <small>(opcional)</small></label>
                                    <input placeholder="CNH" [ngStyle]="((formDocumentos.controls['cnh_form'].status == 'INVALID' && formDocumentos.controls['cnh_form'].dirty) || (formDocumentos.controls['cnh_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" type="text"  class="form-control" (ngModelChange)="modelChanged($event)" name="cnh_form" formControlName="cnh_form" (keypress)='somenteNumero($event)' maxlength="11"> 
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['cnh_form'].hasError('minlength')">Digite o CNH com 11 dígitos no mínimo</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['cnh_form'].dirty && formDocumentos.controls['cnh_form'].hasError('required') && !validaEtapa3">Digite a CNH</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['cnh_form'].status == 'INVALID' && validaEtapa3 ">Digite a CNH</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Categoria CNH <small>(opcional)</small></label>
                                    <select [ngStyle]="((formDocumentos.controls['categoria_cnh_form'].status == 'INVALID' && formDocumentos.controls['categoria_cnh_form'].dirty) || (formDocumentos.controls['categoria_cnh_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" (ngModelChange)="modelChanged($event)" class="form-control form-control-uniform"  name="categoria_cnh_form" formControlName="categoria_cnh_form">
                                        <option value ="" > Selecione...</option>
                                        <option *ngFor="let cnh of categoriaCnh | orderBy: 'Descricao': false" value="{{cnh.Descricao}}">{{cnh.Descricao}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['categoria_cnh_form'].dirty && formDocumentos.controls['categoria_cnh_form'].hasError('required') && !validaEtapa3">Selecione a Categoria CNH</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['categoria_cnh_form'].status == 'INVALID' && validaEtapa3 ">Selecione a Categoria CNH</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                    <div _ngcontent-uxj-c4="" class="form-group">
                                        <label _ngcontent-uxj-c4="">Uf CNH <small>(opcional)</small></label>
                                        <select [ngStyle]="((formDocumentos.controls['uf_cnh_form'].status == 'INVALID' && formDocumentos.controls['uf_cnh_form'].dirty) || (formDocumentos.controls['uf_cnh_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" (ngModelChange)="modelChanged($event)" class="form-control form-control-uniform"  name="uf_cnh_form" formControlName="uf_cnh_form">
                                            <option value ="" > Selecione...</option>
                                            <option *ngFor="let uf of ufCnh | orderBy: 'Nome': false" value="{{uf.SiglaUf}}">{{uf.Nome}}</option>
                                        </select>
                                        <span class="form-text text-danger" *ngIf="formDocumentos.controls['uf_cnh_form'].dirty && formDocumentos.controls['uf_cnh_form'].hasError('required') && !validaEtapa3">Selecione a Categoria CNH</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['uf_cnh_form'].status == 'INVALID' && validaEtapa3 ">Selecione a Categoria CNH</span>
                                    </div>
                                </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>Data Validade CNH <small>(opcional)</small></label>
                                    <input [ngStyle]="((formDocumentos.controls['data_validade_form'].status == 'INVALID' && formDocumentos.controls['data_validade_form'].dirty) || (formDocumentos.controls['data_validade_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" type="date" style="padding-right: 0;" (ngModelChange)="modelChanged($event)" class="form-control"  formControlName="data_validade_form">
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['data_validade_form'].dirty && formDocumentos.controls['data_validade_form'].hasError('required') && !validaEtapa3">Digite uma Data Válida</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['data_validade_form'].status == 'INVALID' && validaEtapa3 ">Digite uma Data Válida</span>
                                </div>
                            </div>
                        </div>
                        <div _ngcontent-uxj-c4="" class="row">
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>Carteira de Trabalho​</label>
                                    <input type="text" placeholder="CTPS" [ngStyle]="((formDocumentos.controls['carteira_form'].status == 'INVALID' && formDocumentos.controls['carteira_form'].dirty) || (formDocumentos.controls['carteira_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control" name="carteira_form" formControlName="carteira_form" (keypress)='somenteNumero($event)'  maxlength="8" required>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['carteira_form'].dirty && formDocumentos.controls['carteira_form'].hasError('required') && !validaEtapa3">Digite a Carteira de Trabalho</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['carteira_form'].status == 'INVALID' && validaEtapa3 ">Digite a Carteira de Trabalho</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-3">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">Nº Série CTPS</label>
                                    <input type="text" placeholder="Série" [ngStyle]="((formDocumentos.controls['serie_form'].status == 'INVALID' && formDocumentos.controls['serie_form'].dirty) || (formDocumentos.controls['serie_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control" name="serie_form" formControlName="serie_form" (keypress)='somenteNumero($event)'  maxlength="5" required >
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['serie_form'].dirty && formDocumentos.controls['serie_form'].hasError('required') && !validaEtapa3">Digite o Nº de Série</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['serie_form'].status == 'INVALID' && validaEtapa3 ">Digite o Nº de Série</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-3">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label _ngcontent-uxj-c4="">UF da CTPS​</label>
                                    <select [ngStyle]="((formDocumentos.controls['uf_ctps_form'].status == 'INVALID' && formDocumentos.controls['uf_ctps_form'].dirty) || (formDocumentos.controls['uf_ctps_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control form-control-uniform" name="uf_ctps_form" formControlName="uf_ctps_form" required>
                                        <option value ="" > Selecione...</option>
                                        <option *ngFor="let ctps of ufCtps | orderBy: 'Nome': false" value="{{ctps.SiglaUf}}">{{ctps.Nome}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['uf_ctps_form'].dirty && formDocumentos.controls['uf_ctps_form'].hasError('required') && !validaEtapa3">Selecione a UF</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['uf_ctps_form'].status == 'INVALID' && validaEtapa3 ">Selecione a UF</span>
                                </div>
                            </div>
                        </div>
                        <div _ngcontent-uxj-c4="" class="row">
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>PIS</label>
                                    <!-- <input type="text" [ngStyle]="((formDocumentos.controls['pis_form'].status == 'INVALID' && formDocumentos.controls['pis_form'].dirty) || (formDocumentos.controls['pis_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control" name="pis_form" formControlName="pis_form" (keypress)='somenteNumero($event)' maxlength="11"> -->
                                    <input type="text" placeholder="000.00000.00-0" mask="000.00000.00-0" [ngStyle]="(formDocumentos.controls['pis_form'].status == 'INVALID' && validaEtapa3) ? {'border-color':'red'} : null" class="form-control" name="pis_form" formControlName="pis_form" (keypress)='somenteNumero($event)'>
                                    <span class="form-text text-danger" *ngIf="(formDocumentos.controls['pis_form'].hasError('pis') && validaEtapa3) || (formDocumentos.controls['pis_form'].status == 'INVALID' && validaEtapa3 )">Digite um  PIS válido</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>Título de Eleitor</label>
                                    <input placeholder="Título de Eleitor" type="text" place [ngStyle]="((formDocumentos.controls['titulo_form'].status == 'INVALID' && formDocumentos.controls['titulo_form'].dirty) || (formDocumentos.controls['titulo_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control" name="titulo_form" formControlName="titulo_form" (keypress)='somenteNumero($event)'  maxlength="12">
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['titulo_form'].dirty && formDocumentos.controls['titulo_form'].hasError('required') && !validaEtapa3">Digite o Título de Eleitor</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['titulo_form'].status == 'INVALID' && validaEtapa3 ">Digite o Título de Eleitor</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>Zona Eleitoral </label>
                                    <input placeholder="Zona Eleitoral" type="text" [ngStyle]="((formDocumentos.controls['zona_form'].status == 'INVALID' && formDocumentos.controls['zona_form'].dirty) || (formDocumentos.controls['zona_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control" name="zona_form" formControlName="zona_form" (keypress)='somenteNumero($event)'  maxlength="5">
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['zona_form'].dirty && formDocumentos.controls['zona_form'].hasError('required') && !validaEtapa3">Digite a Zona Eleitoral</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['zona_form'].status == 'INVALID' && validaEtapa3 ">Digite a Zona Eleitoral</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-6">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>Seção Eleitoral​ </label>
                                    <input placeholder="Seção Eleitoral" type="text"  [ngStyle]="((formDocumentos.controls['secao_form'].status == 'INVALID' && formDocumentos.controls['secao_form'].dirty) || (formDocumentos.controls['secao_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control" nname="secao_form" formControlName="secao_form" (keypress)='somenteNumero($event)'  maxlength="5">
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['secao_form'].dirty && formDocumentos.controls['secao_form'].hasError('required') && !validaEtapa3">Digite a Seção Eleitoral</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['secao_form'].status == 'INVALID' && validaEtapa3 ">Digite a Seção Eleitoral</span>
                                </div>
                            </div>
                        </div>
                        <h3 _ngcontent-uxj-c4="">Dados Bancários</h3>
                        <div _ngcontent-uxj-c4="" class="row">
                            <div _ngcontent-uxj-c4="" class="col-md-3">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>Banco</label>
                                    <select [ngStyle]="((formDocumentos.controls['banco_form'].status == 'INVALID' && formDocumentos.controls['banco_form'].dirty) || (formDocumentos.controls['banco_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control form-control-uniform" name="banco_form" formControlName="banco_form">
                                        <option value ="" > Selecione...</option>
                                        <option *ngFor="let bc of banco | orderBy: 'Nome': false" value="{{bc.Codigo}}">{{bc.Nome}}</option>
                                    </select>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['banco_form'].dirty && formDocumentos.controls['banco_form'].hasError('required') && !validaEtapa3">Selecione o Banco</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['banco_form'].status == 'INVALID' && validaEtapa3 ">Selecione o Banco</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-3">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>Agência</label>
                                    <input placeholder="Agência" [ngStyle]="((formDocumentos.controls['agencia_form'].status == 'INVALID' && formDocumentos.controls['agencia_form'].dirty) || (formDocumentos.controls['agencia_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control" name="agencia_form" formControlName="agencia_form" (keypress)='somenteNumero($event)' maxlength="8">
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['agencia_form'].dirty && formDocumentos.controls['agencia_form'].hasError('required') && !validaEtapa3">Digite a Agência</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['agencia_form'].status == 'INVALID' && validaEtapa3 ">Digite a Agência</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-3">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>Operação</label>
                                    <input placeholder="Operação" type="text" [ngStyle]="((formDocumentos.controls['operacao_form'].status == 'INVALID' && formDocumentos.controls['operacao_form'].dirty) || (formDocumentos.controls['operacao_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control" name="operacao_form" formControlName="operacao_form" (keypress)='somenteNumero($event)' maxlength="2">
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['operacao_form'].dirty && formDocumentos.controls['operacao_form'].hasError('required') && !validaEtapa3">Digite a Operação</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['operacao_form'].status == 'INVALID' && validaEtapa3 ">Digite a Operação</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" class="col-md-3">
                                <div _ngcontent-uxj-c4="" class="form-group">
                                    <label>Conta</label>
                                    <input placeholder="Conta" type="text" [ngStyle]="((formDocumentos.controls['conta_form'].status == 'INVALID' && formDocumentos.controls['conta_form'].dirty) || (formDocumentos.controls['conta_form'].status == 'INVALID' && validaEtapa3)) ? {'border-color':'red'} : null" class="form-control" name="conta_form" formControlName="conta_form" (keypress)='somenteNumero($event)' maxlength="15">
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['conta_form'].dirty && formDocumentos.controls['conta_form'].hasError('required') && !validaEtapa3">Digite a Conta</span>
                                    <span class="form-text text-danger" *ngIf="formDocumentos.controls['conta_form'].status == 'INVALID' && validaEtapa3 ">Digite a Conta</span>
                                </div>
                            </div>
                            <div _ngcontent-uxj-c4="" *ngIf="cadastro.Matricula" class="col-12">
                                <app-anexo   [pagina]="'017'" [matricula]="cadastro.Matricula" [enviarSeparadamente]="true"></app-anexo>
                                <!-- <app-upload #upload></app-upload> -->
                            </div>
                        </div>
                    </form>
    
                    <!-- <h6 _ngcontent-uxj-c4="" *ngIf="step == 3" id="steps-uid-0-h-3" tabindex="-1" class="title">Conclusão</h6>
                    <div _ngcontent-uxj-c4="" [style.display]="step == 3 ? 'block' : 'none'" id="steps-uid-0-p-3" role="tabpanel" aria-labelledby="steps-uid-0-h-3" class="body" aria-hidden="true">
                        <div class="row">
    
                        </div>
                    </div> -->
                </div>
                <div class="actions clearfix">
                    <ul role="menu" aria-label="Pagination">
        
                        <li class="disabled" aria-disabled="true">
                            <button class="btn btn-primary" [hidden]="step == 0 || step > 2" role="menuitem" (click)="PreviewStep()"><i class="icon-arrow-left13 mr-2"></i> Anterior</button>
                        </li>
                        <li aria-hidden="false" *ngIf="step < 2" aria-disabled="false">
                            <button class="btn btn-primary" role="menuitem" (click)="NextStep()">Próximo <i class="icon-arrow-right14 ml-2"></i></button>
                        </li>
                        <li *ngIf="step == 2" aria-hidden="true">
                            <button class="btn btn-primary" role="menuitem" (click)="NextStep()">Submeter <i class="icon-arrow-right14 ml-2"></i></button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- Basic modal -->
        <div id="modal_default" class="modal fade" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Como Cadastrar</h5>
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
        
                    <div class="modal-body">
                        <!-- <video width="100%" controls autoplay loop>
                                    <source src=" https://www.youtube.com/watch?v=1hCmkJyihVo" type="video/mp4">
                                </video> -->
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe>
                        </div>
                    </div>
        
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link" data-dismiss="modal">Fechar</button>
                        <!-- <button type="button" class="btn bg-primary">Save changes</button> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- /basic modal -->