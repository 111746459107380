import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErroService } from 'src/app/service/erros/erro.service';
import { Data } from 'src/app/service/interfaces/Data';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { AssinaturaAnexoService } from './assinatura-anexo.service';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';


@Component({
  selector: 'app-assinatura-anexo',
  templateUrl: './assinatura-anexo.component.html',
  styleUrls: ['./assinatura-anexo.component.css']
})
export class AssinaturaAnexoComponent implements OnInit {

 @Input() perfil: boolean;
 @Output() arquivoenviado = new EventEmitter<Arquivo>();


  listaArquivos: Arquivo[] = [];
  selectedFile: Set<File>;
  numDocDisponivel: number;
  formAnexo: FormGroup;
  listaTipoArquivo: any[] = [];
  meses: string[] = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'junho', 'Julho', 'Agosto', 'Setembro', 'Outrubro', 'Novembro', 'Dezembro'];
  listaAnexo: Anexo[] = [];
  ListaArquivos:Arquivo[]= [];
  loading: boolean = false;
  editado: boolean = false;
  indexSelecionado : number = undefined;
  arquivosEnviadoSessaoAtual: Anexo[] = [];
  ArquivoSelecionadoAtual:Anexo;
  localStorage: LocalStorage;

  constructor(private fb: FormBuilder, private assinaturaService: AssinaturaAnexoService, private validaErro: ErroService) { }
  ngOnInit() {
    this.localStorage = this.decryptLocalStorage();
    this.formAnexo = this.fb.group({
      arquivos: [''],
      arquivoselecionado: [''],
      string64: [''],
      descricao: [''],
      tipoArquivo: ['foto']
    });
   }










  onArquivoChange(evento): void {

    this.arquivos.reset();
    const { files } = evento.target;
    const arquivo = files[0];

    if (arquivo != null) {
      this.arquivos.setValue(arquivo);
      this.retornaArquivo();
      let reader = new FileReader();

      reader.readAsDataURL(arquivo);
      reader.onload = (e: any) =>{

        this.string64.setValue(e.target.result);
      }
    } else {
      this.arquivos.setValue('');

    }


  }

  get arquivoselecionado(): FormControl{
    return this.formAnexo.get('arquivoselecionado') as FormControl;
  }
  get string64(): FormControl{
    return this.formAnexo.get('string64') as FormControl;
  }
  get arquivos(): FormControl {
    return this.formAnexo.get('arquivos') as FormControl;
  }
  get descricao(): FormControl{
    return this.formAnexo.get('arquivos') as FormControl;
  }

  criaAnexo(){
    this.listaArquivos.push(this.montaArquivos())
    let anexo_a : Anexo = {
      NomeUsuario: localStorage.NomeUsuario,
      Comentario: "",
      ArquivoSalvo: false,
      Arquivos: this.listaArquivos
    }
    this.listaAnexo.push(anexo_a);
    return this.listaAnexo;
  }


  limparCampos(): void {

    this.arquivos.setValue('');
    this.arquivoselecionado.setValue('');

  }
  fecahrmodal(){
     document.getElementById("modalClose").click()
  }
  submitAnexo(): void {


      this.salvarAnexo();


  }

  salvarAnexo(): void {
    let arquivo_a : Arquivo = this.montaArquivos();

      this.assinaturaService.salvarAnexo(this.criaAnexo()).subscribe((a) => {
        new AlertaModal("sucesso", "Sucesso!", "Anexo enviado com sucesso!", "P");
      }, error => {
        new AlertaModal("alerta", "Atenção!", error.error.Mensagem, "P");
      });

    this.fecahrmodal()
  }


  retornaArquivo(){
    this.arquivoenviado.emit(this.arquivos.value);
  }


  montaArquivos(): Arquivo {

    const date = new Date();


    let arquivo : Arquivo = null;

    if (this.arquivos.value != '') {

      arquivo =  {

          IdUsuario: this.localStorage.CodigoUsuario,
          NomeUsuario: this.localStorage.NomeUsuario,
          Nome:this.arquivos.value.name,
          ArquivoBase64: this.string64.value,
          Matricula: 0,
          Empfil: this.localStorage.FilialAtual.toString(),
          Grupo: '030',
          Tipo: '000',
          Codigo: `021000`,
          Extensao: this.arquivos.value.type.substring(6),
          NumDoc: '01',
          Numord: this.localStorage.NumeroOrdemUsuario.toString(),
          CompChave: '0',
          Pagina: '01',
          DataCadastro: new Data(date.getDay().toString().padStart(2, '0') + date.getMonth() + date.getFullYear()),
          Descricao : "ARQUIVO ELOGIO/PENALIDADE"

      };
      return arquivo;
    }

    return arquivo;

  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }



}

export interface Anexo {
  NomeUsuario:string;
  Comentario: string;
  ArquivoSalvo: boolean;
  Arquivos: Arquivo[];
}
export class Arquivo {
  ArquivoBase64: string;
  Codigo: string;
  Empfil: string;
  Extensao: string;
  Grupo: string;
  Matricula: number;
  IdUsuario: string;
  NomeUsuario: string;
  NumDoc: string;
  Tipo: string;
  Nome?: string;
  FotoPerfil?: string;
  FotoPerfilBytes?: string;
  DataCadastro?: Data;
  Descricao : string;
  Numord: string;
  CompChave: string;
  Pagina: string;
}


