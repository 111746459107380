<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Carregando...</p>
</ngx-spinner>
<div class="d-flex justify-content-center">
  <div style="max-width: 1200px">
    <div class="card">
      <div class="card-header">
        <h3>Cadastro de Colaborador</h3>
      </div>
      <form #beneficio="ngForm" [formGroup]="formCadastro" (ngSubmit)="submitCadastro()">
        <div class="card-body wizard">
          <div class="steps clearfix pb-3">
            <ul role="tablist">
              <li
                *ngIf="localStorage.TipoUsuario != '10'"
                role="tab"
                [ngClass]="{ current: step == 1, done: step > 1 }"
                class="first"
                aria-disabled="false"
                aria-selected="true"
              >
                <a id="steps-uid-1-t-1" aria-controls="steps-uid-1-p-1" class=""
                  ><span class="current-info audible">current step: </span
                  ><span [ngClass]="{ pointer: step > 1 }" class="number">1</span> Dados Básicos</a
                >
              </li>
              <li
                role="tab"
                [ngClass]="{
                  current: step == 2,
                  disabled: step < 2,
                  done: step > 2
                }"
                aria-disabled="true"
              >
                <a id="steps-uid-0-t-1" aria-controls="steps-uid-2-p-2"
                  ><span [ngClass]="{ pointer: step > 2 }" class="number">{{
                    localStorage.TipoUsuario == '10' ? '1' : '2'
                  }}</span
                  >Informações Pessoais</a
                >
              </li>
              <li
                role="tab"
                [ngClass]="{
                  current: step == 3,
                  disabled: step < 3,
                  done: step > 3
                }"
                aria-disabled="true"
              >
                <a id="steps-uid-0-t-3" aria-controls="steps-uid-3-p-3" class="disabled"
                  ><span [ngClass]="{ pointer: step > 3 }" class="number">{{
                    localStorage.TipoUsuario == '10' ? '2' : '3'
                  }}</span>
                  Contato/Endereço</a
                >
              </li>
              <li
                role="tab"
                [ngClass]="{
                  current: step == 4,
                  disabled: step < 4,
                  done: step > 4
                }"
                aria-disabled="true"
              >
                <a id="steps-uid-0-t-4" aria-controls="steps-uid-4-p-4" class="disabled"
                  ><span [ngClass]="{ pointer: step > 4 }" class="number">
                    {{ localStorage.TipoUsuario == '10' ? '3' : '4' }}</span
                  >
                  Documentos</a
                >
              </li>
              <li
                role="tab"
                [ngClass]="{
                  current: step == 5,
                  disabled: step < 5,
                  done: step > 5
                }"
                aria-disabled="true"
              >
                <a id="steps-uid-0-t-5" aria-controls="steps-uid-0-p-5" class="disabled"
                  ><span [ngClass]="{ pointer: step > 5 }" class="number">
                    {{ localStorage.TipoUsuario == '10' ? '4' : '5' }}</span
                  >
                  Dependentes</a
                >
              </li>
              <li
                *ngIf="localStorage.TipoUsuario != '10'"
                role="tab"
                [ngClass]="{ current: step == 6, disabled: step < 6 }"
                class="last"
                aria-disabled="true"
              >
                <a id="steps-uid-0-t-6" aria-controls="steps-uid-0-p-6" class="disabled"
                  ><span class="number">6</span> Dados de Pagamento</a
                >
              </li>
            </ul>
          </div>
          <p *ngIf="step > 1" class="text-center alert-info p-2 mt-2 rounded">
            <i class="fa fa-user fa-3x my-2"></i><br />
            Nome: {{ nomeInicio }} <br />CPF: {{ cpfInicio }} <br />Matrícula:
            {{ matInicio == '' ? matricula : matInicio }} <br />
            {{ vinculoInicio ? 'Vínculo:' + vinculoInicio : '' }}
          </p>
          <!-- step1 -->
          <div
            *ngIf="step == 1 && localStorage.TipoUsuario != '10'"
            [style.display]="true ? 'block' : 'none'"
            id="steps-uid-0-p-1"
            role="tabpanel"
            aria-labelledby="steps-uid-0-h-1"
            class="body current"
            aria-hidden="false"
          >
            <fieldset>
              <legend>
                <h4>Dados Básicos</h4>
              </legend>
              <div class="row" formGroupName="dadosPessoais">
                <div class="col-md-6 col-lg-3 form-group">
                  <label>CPF </label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="cpf"
                      placeholder="CPF"
                      (input)="mascaraCPF($event)"
                      (change)="verificarCadastroExistente()"
                      [ngClass]="
                        formCadastro.get('dadosPessoais').get('cpf').invalid &&
                        (temErro || formCadastro.get('dadosPessoais').get('cpf').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <div class="input-group-append">
                      <button
                        [disabled]="formCadastro.get('dadosPessoais').get('cpf').invalid || !usuarioCadastrado"
                        class="btn bg-blue border-blue"
                        (click)="buscarColaboradorInativo()"
                        type="button"
                      >
                        <i class="fa fa-search"></i>&nbsp;
                      </button>
                    </div>
                  </div>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('dadosPessoais').get('cpf').invalid &&
                      (formCadastro.get('dadosPessoais').get('cpf').touched || temErro)
                    "
                  >
                    {{
                      formCadastro.get('dadosPessoais').get('cpf').hasError('cpfError')
                        ? 'CPF Inválido'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-6 col-lg-4 form-group">
                  <label>Nome Completo </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="nome"
                    placeholder="Nome Completo"
                    pattern="[A-Za-záãâéêíóôõúçÁÃÂÉÊÍÓÔÕÚÇ\s]+$"
                    [ngClass]="
                      formCadastro.get('dadosPessoais').get('nome').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('nome').touched)
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('dadosPessoais').get('nome').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('nome').touched)
                    "
                  >
                    {{
                      formCadastro.get('dadosPessoais').get('nome').hasError('pattern')
                        ? 'Campo só permite letras'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>

                <div class="col-md-6 col-lg-3 form-group">
                  <label>Data de Nascimento</label>
                  <input
                    type="date"
                    class="form-control"
                    max="9999-12-31"
                    (blur)="
                      verificarMenor($event);
                      onDataNascimentoChange();
                      verificaTamanhoData('dataNascimento')
                    "
                    formControlName="dataNascimento"
                    style="padding-right: 0"
                    [ngClass]="
                      formCadastro.get('dadosPessoais').get('dataNascimento').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('dataNascimento').touched)
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('dadosPessoais').get('dataNascimento').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('dataNascimento').touched)
                    "
                  >
                    {{
                      formCadastro.get('dadosPessoais').get('dataNascimento').hasError('data')
                        ? 'Data Inválida'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>

                <div class="col-md-6 col-lg-2 form-group" [formGroup]="formCadastro">
                  <label class="mr-2">Matricula</label>
                  <input
                    *ngIf="isCadastro == true"
                    type="number"
                    placeholder="Automática"
                    class="form-control col-12 input-disabled"
                    id="matricula"
                    name="matricula"
                    formControlName="matricula"
                  />
                </div>

                <div class="col-md-6 form-group">
                  <label>Setor </label>
                  <select
                    class="form-control"
                    formControlName="setor"
                    [ngClass]="
                      formCadastro.get('dadosPessoais').get('setor').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('setor').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let setor of listaSetor" value="{{ setor.Codigo }}">
                      {{ setor.Codigo }} - {{ setor.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro || formCadastro.get('dadosPessoais').get('setor').touched) &&
                      formCadastro.get('dadosPessoais').get('setor').invalid
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="col-md-6 form-group">
                  <label>Data de Admissão</label>
                  <input
                    type="date"
                    class="form-control"
                    max="9999-12-31"
                    formControlName="dataAdmissao"
                    (blur)="verificaTamanhoData('dataAdmissao')"
                    style="padding-right: 0"
                    [ngClass]="
                      formCadastro.get('dadosPessoais').get('dataAdmissao').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('dataAdmissao').touched)
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro || formCadastro.get('dadosPessoais').get('dataAdmissao').touched) &&
                      formCadastro.get('dadosPessoais').get('dataAdmissao').invalid
                    "
                  >
                    {{
                      formCadastro.get('dadosPessoais').get('dataAdmissao').hasError('data')
                        ? 'Data Inválida'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>

                <div class="col-md-6 form-group">
                  <label>Vínculo</label>
                  <select
                    class="form-control"
                    formControlName="vinculo"
                    (change)="onVinculoChange()"
                    [ngClass]="
                      formCadastro.get('dadosPessoais').get('vinculo').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('vinculo').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let vinculo of listaVinculo" value="{{ vinculo.Codigo }}">
                      {{ vinculo.Codigo }} - {{ vinculo.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro || formCadastro.get('dadosPessoais').get('vinculo').touched) &&
                      formCadastro.get('dadosPessoais').get('vinculo').invalid
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="col-md-6 form-group">
                  <label>Sindicato</label>
                  <select
                    type="text"
                    class="form-control"
                    formControlName="sindicato"
                    [ngClass]="
                      formCadastro.get('dadosPessoais').get('sindicato').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('sindicato').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let sindicato of listaSindicato" value="{{ sindicato.Codigo }}">
                      {{ sindicato.Codigo }} - {{ sindicato.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('dadosPessoais').get('sindicato').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('sindicato').touched)
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="col-md-6 form-group" *ngIf="tipoVinculo == '1'">
                  <label>Cargo </label>
                  <select
                    class="form-control"
                    (change)="onCargoChange()"
                    formControlName="cargo"
                    [ngClass]="
                      formCadastro.get('dadosPessoais').get('cargo').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('cargo').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let cargo of listaCargo" value="{{ cargo.Codigo }}">
                      {{ cargo.Codigo }} - {{ cargo.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('dadosPessoais').get('cargo').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('cargo').touched)
                    "
                  >
                    Campo Obrigatório
                  </span>
                  <span class="form-text" *ngIf="loadingCargo"
                    >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
                  ></span>
                </div>

                <div class="col-md-6 form-group" *ngIf="tipoVinculo == '1'">
                  <label>Nível </label>
                  <select
                    class="form-control"
                    formControlName="nivel"
                    [ngClass]="
                      formCadastro.get('dadosPessoais').get('nivel').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('nivel').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let nivel of listaNivel" value="{{ nivel.Codigo.trim() }}">
                      {{ nivel.Codigo }} -
                      {{ +nivel.Valor.replace(',', '.') | currency : 'BRL' : 'symbol' }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('dadosPessoais').get('nivel').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('nivel').touched)
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="col-md-6 form-group" *ngIf="tipoVinculo != '1'">
                  <label>Função </label>
                  <select
                    class="form-control"
                    formControlName="funcao"
                    (change)="onCargoChange()"
                    [ngClass]="
                      formCadastro.get('dadosPessoais').get('funcao').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('funcao').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let cargo of ListaFuncao" value="{{ cargo.Codigo }}">
                      {{ cargo.Codigo }} - {{ cargo.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('dadosPessoais').get('funcao').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('funcao').touched)
                    "
                  >
                    Campo Obrigatório
                  </span>
                  <span class="form-text" *ngIf="loadingCargo"
                    >Carregando <i class="fa fa-spinner fa-spin" aria-hidden="false"></i
                  ></span>
                </div>

                <div class="col-md-6 form-group" *ngIf="tipoVinculo != '1'">
                  <label>Referência</label>
                  <select
                    class="form-control"
                    formControlName="referencia"
                    [ngClass]="
                      formCadastro.get('dadosPessoais').get('referencia').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('referencia').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let nivel of listaNivel" value="{{ nivel.Codigo }}">
                      {{ nivel.Codigo }} -
                      {{ +nivel.Valor | currency : 'BRL' : 'symbol' }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('dadosPessoais').get('referencia').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('referencia').touched)
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="col-md-6 form-group">
                  <label>Carga Horária <small> (Semanal)</small> </label>
                  <input
                    *ngIf="listaCargaHoraria && listaCargaHoraria.length < 1; else cargaSelect"
                    type="number"
                    min="0"
                    max="50"
                    class="form-control"
                    formControlName="cargaHoraria"
                    placeholder="Carga Horária"
                    [ngClass]="
                      formCadastro.get('dadosPessoais').get('cargaHoraria').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('cargaHoraria').touched)
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <ng-template #cargaSelect>
                    <select
                      class="form-control"
                      formControlName="cargaHoraria"
                      [ngClass]="
                        formCadastro.get('dadosPessoais').get('cargaHoraria').invalid &&
                        (temErro || formCadastro.get('dadosPessoais').get('cargaHoraria').touched)
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">Selecione...</option>
                      <option
                        *ngFor="let cargaHoraria of listaCargaHoraria"
                        value="{{ cargaHoraria.Codigo }}"
                      >
                        {{ cargaHoraria.Codigo }}
                      </option>
                    </select>
                  </ng-template>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro || formCadastro.get('dadosPessoais').get('cargaHoraria').touched) &&
                      formCadastro.get('dadosPessoais').get('cargaHoraria').hasError('required')
                    "
                  >
                    {{
                      formCadastro.get('dadosPessoais').get('cargaHoraria').hasError('min') ||
                      formCadastro.get('dadosPessoais').get('cargaHoraria').hasError('max')
                        ? 'Insira um número entre 0 e 50'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>

                <div class="col-md-6 form-group">
                  <label>Tipo de folha</label>
                  <select
                    class="form-control"
                    formControlName="tipoFolha"
                    [ngClass]="
                      formCadastro.get('dadosPessoais').get('tipoFolha').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('tipoFolha').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let tipoFolha of listaTipoFolha" value="{{ tipoFolha.Codigo }}">
                      {{ tipoFolha.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro || formCadastro.get('dadosPessoais').get('tipoFolha').touched) &&
                      formCadastro.get('dadosPessoais').get('tipoFolha').invalid
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="col-md-6 form-group">
                  <label>Horário</label>
                  <select
                    class="form-control"
                    formControlName="horario"
                    (change)="onHorarioChange()"
                    [ngClass]="
                      formCadastro.get('dadosPessoais').get('horario').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('horario').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let horario of listaHorario" value="{{ horario.Codigo }}">
                      {{ horario.Codigo }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('dadosPessoais').get('horario').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('horario').touched)
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="col-md-6 form-group">
                  <label>Repouso</label>
                  <input
                    type="text"
                    class="form-control input-disabled"
                    formControlName="repouso"
                    placeholder="Repouso"
                    [ngClass]="
                      formCadastro.get('dadosPessoais').get('repouso').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('repouso').touched)
                        ? 'is-invalid'
                        : null
                    "
                    readonly
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('dadosPessoais').get('repouso').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('repouso').touched)
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="col-md-6 form-group">
                  <label>Dias Úteis </label>
                  <select
                    type="text"
                    class="form-control"
                    formControlName="diasUteis"
                    [ngClass]="
                      formCadastro.get('dadosPessoais').get('diasUteis').invalid &&
                      (temErro || formCadastro.get('dadosPessoais').get('diasUteis').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let diasUteis of listaDiasUteis" value="{{ diasUteis.Codigo }}">
                      {{ diasUteis.Codigo }} - {{ diasUteis.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro || formCadastro.get('dadosPessoais').get('diasUteis').touched) &&
                      formCadastro.get('dadosPessoais').get('diasUteis').invalid
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="row col-md-7">
                  <button
                  (click)="abiriModalIndicadores()"
                    *ngIf="step === 1"
                    type="button"
                    class="btn btn-primary ml-2"
                    [disabled]="isDisabled"
                  >
                    Indicadores do Cálculo
                  </button>
                </div>
              </div>
            </fieldset>
          </div>
          <!-- step 2 -->
          <div
            *ngIf="step == 2"
            [style.display]="step == 2 ? 'block' : 'none'"
            id="steps-uid-0-p-2"
            role="tabpanel"
            aria-labelledby="steps-uid-0-h-2"
            class="body"
            aria-hidden="true"
          >
            <fieldset>
              <legend>
                <h4>Informações Pessoais</h4>
              </legend>
              <div class="row" formGroupName="profissao">
                <div class="col-md-6 col-lg-4 form-group">
                  <label>Nome da Mãe </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="nomeMae"
                    placeholder="Nome da Mãe"
                    pattern="[A-Za-záãâéêíóôõúçÁÃÂÉÊÍÓÔÕÚÇ\s]+$"
                    [ngClass]="
                      formCadastro.get('profissao').get('nomeMae').invalid &&
                      (temErro || formCadastro.get('profissao').get('nomeMae').touched)
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('profissao').get('nomeMae').invalid &&
                      (temErro || formCadastro.get('profissao').get('nomeMae').touched)
                    "
                  >
                    {{
                      formCadastro.get('profissao').get('nomeMae').hasError('pattern')
                        ? 'Campo só permite letras'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>

                <div class="col-md-6 col-lg-4 form-group">
                  <label>Nome do Pai <small>(opcional)</small></label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="nomePai"
                    pattern="[A-Za-záãâéêíóôõúçÁÃÂÉÊÍÓÔÕÚÇ\s]+$"
                    placeholder="Nome do Pai"
                    [ngClass]="
                      formCadastro.get('profissao').get('nomePai').invalid &&
                      (temErro || formCadastro.get('profissao').get('nomePai').touched)
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('profissao').get('nomePai').invalid &&
                      (temErro || formCadastro.get('profissao').get('nomePai').touched)
                    "
                  >
                    Campo só permite letras
                  </span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 form-group">
                  <label>Sexo </label>
                  <select
                    class="form-control"
                    formControlName="sexo"
                    (change)="onSexoChange()"
                    [ngClass]="
                      formCadastro.get('profissao').get('sexo').invalid &&
                      (temErro || formCadastro.get('profissao').get('sexo').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let sexo of listaSexo" value="{{ sexo.Codigo }}">
                      {{ sexo.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('profissao').get('sexo').invalid &&
                      (temErro || formCadastro.get('profissao').get('sexo').touched)
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 form-group">
                  <label>Tipo Sanguíneo <small>(opcional)</small></label>
                  <select
                    class="form-control"
                    formControlName="tipoSangue"
                    [ngClass]="
                      formCadastro.get('profissao').get('tipoSangue').invalid &&
                      (temErro || formCadastro.get('profissao').get('tipoSangue').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option
                      *ngFor="let tpSangue of listaTipoSanquineo"
                      value="{{ tpSangue.Descricao }}"
                    >
                      {{ tpSangue.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('profissao').get('tipoSangue').invalid &&
                      (temErro || formCadastro.get('profissao').get('tipoSangue').touched)
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 form-group">
                  <label>Raça/Cor </label>
                  <select
                    class="form-control"
                    formControlName="corPele"
                    [ngClass]="
                      formCadastro.get('profissao').get('corPele').invalid &&
                      (temErro || formCadastro.get('profissao').get('corPele').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option *ngFor="let corPele of listaCorPele" value="{{ corPele.Descricao }}">
                      {{ corPele.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('profissao').get('corPele').invalid &&
                      (temErro || formCadastro.get('profissao').get('corPele').touched)
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 form-group">
                  <label>Estado Civil </label>
                  <select
                    class="form-control"
                    formControlName="estadoCivil"
                    [ngClass]="
                      formCadastro.get('profissao').get('estadoCivil').invalid &&
                      (temErro || formCadastro.get('profissao').get('estadoCivil').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option
                      *ngFor="let estadoCivil of listaEstadoCivil"
                      value="{{ estadoCivil.Codigo }}"
                    >
                      {{ estadoCivil.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('profissao').get('estadoCivil').invalid &&
                      (temErro || formCadastro.get('profissao').get('estadoCivil').touched)
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="col-lg-6 col-md-4 col-sm-6 form-group">
                  <label>Grau de Instrução </label>
                  <select
                    class="form-control"
                    formControlName="grauInstrucao"
                    [ngClass]="
                      formCadastro.get('profissao').get('grauInstrucao').invalid &&
                      (temErro || formCadastro.get('profissao').get('grauInstrucao').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option
                      *ngFor="let grauInstrucao of listaGrauInstrucao"
                      value="{{ grauInstrucao.Codigo }}"
                    >
                      {{ grauInstrucao.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('profissao').get('grauInstrucao').invalid &&
                      (temErro || formCadastro.get('profissao').get('grauInstrucao').touched)
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <!-- <div class="col-lg-6 col-md-4 col-sm-6 form-group">
                  <label>Estabilidade</label>
                  <select
                    type="text"
                    class="form-control"
                    formControlName="estabilidade"
                    [ngStyle]="
                      formCadastro.get('profissao').get('estabilidade').invalid &&
                      (temErro || formCadastro.get('profissao').get('estabilidade').dirty)
                        ? {'border-color': 'red'}
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option
                      *ngFor="let estabilidade of listaEstabilidade"
                      value="{{ estabilidade.Codigo }}"
                    >
                      {{ estabilidade.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formCadastro.get('profissao').get('estabilidade').hasError('required')
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div> -->

                <!-- <div class="col-lg-6 col-md-4 col-sm-6 form-group">
                  <label>Tipo de Frequência </label>
                  <select
                    type="text"
                    class="form-control"
                    formControlName="tipoFrequencia"
                    [ngStyle]="
                      formCadastro.get('profissao').get('tipoFrequencia').invalid &&
                      (temErro || formCadastro.get('profissao').get('tipoFrequencia').dirty)
                        ? {'border-color': 'red'}
                        : null
                    "
                  >
                    >
                    <option value="">Selecione...</option>
                    <option
                      *ngFor="let tipoFrequencia of listaTipoFrequencia"
                      value="{{ tipoFrequencia.Codigo }}"
                    >
                      {{ tipoFrequencia.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formCadastro.get('profissao').get('tipoFrequencia').hasError('required')
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div> -->

                <div class="col-lg-6 col-md-4 col-sm-6 form-group">
                  <label>Nacionalidade </label>
                  <select
                    class="form-control"
                    formControlName="nacionalidade"
                    (change)="onNacionalidadeChange()"
                    [ngClass]="
                      formCadastro.get('profissao').get('nacionalidade').invalid &&
                      (temErro || formCadastro.get('profissao').get('nacionalidade').touched)
                        ? 'is-invalid'
                        : null
                    "
                  >
                    <option value="">Selecione...</option>
                    <option
                      *ngFor="let nacionalidade of listaNacionalidade"
                      value="{{ nacionalidade.Codigo }}"
                    >
                      {{ nacionalidade.Descricao }}
                    </option>
                  </select>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('profissao').get('nacionalidade').invalid &&
                      (temErro || formCadastro.get('profissao').get('nacionalidade').touched)
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
              </div>
            </fieldset>

            <fieldset>
              <div *ngIf="formCadastro.value.profissao.nacionalidade == nacionalidade.Brasileiro">
                <legend>
                  <h4>Região de Naturalidade</h4>
                </legend>
                <div class="row" formGroupName="profissao">
                  <div class="col-md-5 col-sm-12 form-group">
                    <label>UF de Naturalidade </label>
                    <select
                      class="form-control"
                      (change)="onUfChange()"
                      formControlName="uf"
                      [ngClass]="
                        formCadastro.get('profissao').get('uf').invalid &&
                        (temErro || formCadastro.get('profissao').get('uf').touched)
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">Selecione...</option>
                      <option *ngFor="let uf of listaUf" value="{{ uf.Descricao }}">
                        {{ uf.Descricao }}
                      </option>
                    </select>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        formCadastro.get('profissao').get('uf').invalid &&
                        (temErro || formCadastro.get('profissao').get('uf').touched)
                      "
                    >
                      Campo Obrigatório
                    </span>
                    <span class="form-text" *ngIf="loadingUf"
                      >Carregando
                      <i class="fa fa-spinner fa-spin" aria-hidden="false"></i>
                    </span>
                  </div>
                  <div class="col-md-7 col-sm-12 form-group">
                    <label>Municipio de Naturalidade</label>
                    <select
                      class="form-control"
                      formControlName="municipio"
                      [ngClass]="
                        formCadastro.get('profissao').get('municipio').invalid &&
                        (temErro || formCadastro.get('profissao').get('municipio').touched)
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">Selecione...</option>
                      <option
                        *ngFor="let municipio of listaMunicipios"
                        value="{{ municipio.Codigo }}"
                      >
                        {{ municipio.Nome }}
                      </option>
                    </select>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        formCadastro.get('profissao').get('municipio').invalid &&
                        (temErro || formCadastro.get('profissao').get('municipio').touched)
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <div
                *ngIf="
                  formCadastro.value.profissao.nacionalidade != nacionalidade.Brasileiro &&
                  formCadastro.value.profissao.nacionalidade != ''
                "
              >
                <legend>
                  <h4>Estrangeiro</h4>
                </legend>
                <div class="row" formGroupName="estrangeiro">
                  <div class="col-md-4 col-sm-6 form-group">
                    <label>Naturalizado </label> <br />
                    <input
                      type="radio"
                      id="simNaturalizado"
                      value="1"
                      formControlName="naturalizado"
                    />
                    <label for="simNaturalizado">&nbsp; Sim</label>&nbsp; &nbsp;
                    <input
                      type="radio"
                      id="naoNaturalizado"
                      value="0"
                      formControlName="naturalizado"
                    />
                    <label for="naoNaturalizado">&nbsp; Não</label><br />
                    <span
                      class="form-text text-danger"
                      *ngIf="formCadastro.get('estrangeiro').get('naturalizado').invalid && temErro"
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-md-4 col-sm-6 form-group">
                    <label>Filho brasileiro(a)</label> <br />
                    <input
                      type="radio"
                      id="simFilhoBr"
                      value="1"
                      formControlName="filhoBrasileiro"
                    />
                    <label for="simFilhoBr">&nbsp; Sim</label>&nbsp; &nbsp;
                    <input
                      type="radio"
                      id="naoFilhoBr"
                      value="0"
                      formControlName="filhoBrasileiro"
                    />
                    <label for="naoFilhoBr">&nbsp; Não</label><br />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        formCadastro.get('estrangeiro').get('filhoBrasileiro').invalid && temErro
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-md-4 col-sm-6 form-group">
                    <label>Casado com brasileiro(a)</label> <br />
                    <input
                      type="radio"
                      id="simCasadoBr"
                      value="1"
                      formControlName="casadoComBrasileiro"
                    />
                    <label for="simCasadoBr">&nbsp; Sim</label>&nbsp; &nbsp;
                    <input
                      type="radio"
                      id="naoCasadoBr"
                      value="0"
                      formControlName="casadoComBrasileiro"
                    />
                    <label for="naoCasadoBr">&nbsp; Não</label><br />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        formCadastro.get('estrangeiro').get('casadoComBrasileiro').invalid &&
                        temErro
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-md-6 col-sm-6 form-group">
                    <label>Data de Chegada </label>
                    <input
                      type="date"
                      max="9999-12-31"
                      class="form-control"
                      formControlName="dataChegada"
                      style="padding-right: 0"
                      [ngClass]="
                        formCadastro.get('estrangeiro').get('dataChegada').invalid &&
                        (temErro || formCadastro.get('estrangeiro').get('dataChegada').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        formCadastro.get('estrangeiro').get('dataChegada').invalid &&
                        (temErro || formCadastro.get('estrangeiro').get('dataChegada').touched)
                      "
                    >
                      {{
                        formCadastro.get('estrangeiro').get('dataChegada').hasError('data')
                          ? 'Data Inválida'
                          : 'Campo Obrigatório'
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </fieldset>
            <!-- <fieldset>
              <legend>
                <h4>Emprego</h4>
              </legend>
              <div class="row" formGroupName="emprego">
                <div class="col-md-4 col-sm-6 form-group">
                  <label>Primeiro Emprego </label> <br />
                  <input
                    type="radio"
                    (change)="onPrimeiroEmpregoChange()"
                    class="mt-1"
                    id="simPrimeiroEmprego"
                    value="1"
                    formControlName="primeiroEmprego"
                  />
                  <label for="simPrimeiroEmprego">&nbsp; Sim</label>&nbsp;
                  &nbsp;
                  <input
                    type="radio"
                    (change)="onPrimeiroEmpregoChange()"
                    class="mt-1"
                    id="naoPrimeiroEmprego"
                    value="0"
                    formControlName="primeiroEmprego"
                  />
                  <label for="naoPrimeiroEmprego">&nbsp; Não</label><br />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('emprego').get('primeiroEmprego')
                        .invalid && temErro
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div
                  class="col-md-4 col-sm-6 form-group"
                  *ngIf="
                    formCadastro.value.emprego.primeiroEmprego != '' &&
                    formCadastro.value.emprego.primeiroEmprego == 0
                  "
                >
                  <label>Possui Outro Emprego? </label> <br />
                  <input
                    type="radio"
                    (change)="onOutroEmpregoChange()"
                    class="mt-1"
                    id="simOutroEmprego"
                    value="1"
                    formControlName="outroEmprego"
                  />
                  <label for="simOutroEmprego">&nbsp; Sim</label>&nbsp; &nbsp;
                  <input
                    type="radio"
                    (change)="onOutroEmpregoChange()"
                    class="mt-1"
                    id="naoOutroEmprego"
                    value="0"
                    formControlName="outroEmprego"
                  />
                  <label for="naoOutroEmprego">&nbsp; Não</label><br />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('emprego').get('outroEmprego').invalid &&
                      temErro
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div
                  class="col-md-4 col-sm-6 form-group"
                  *ngIf="
                    formCadastro.value.emprego.primeiroEmprego != '' &&
                    formCadastro.value.emprego.primeiroEmprego == 0
                  "
                >
                  <label>Paga Contribuição </label> <br />
                  <input
                    type="radio"
                    id="simContri"
                    value="1"
                    class="mt-1"
                    formControlName="pagaContribuicao"
                  />
                  <label for="simContri">&nbsp; Sim</label>&nbsp; &nbsp;
                  <input
                    type="radio"
                    id="naoContri"
                    value="0"
                    class="mt-1"
                    formControlName="pagaContribuicao"
                  />
                  <label for="naoContri">&nbsp; Não</label><br />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('emprego').get('pagaContribuicao')
                        .invalid && temErro
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div
                  class="col-md-6 col-sm-6 form-group"
                  *ngIf="
                    formCadastro.value.emprego.primeiroEmprego != '' &&
                    formCadastro.value.emprego.primeiroEmprego == 0
                  "
                >
                  <label>Data de Exame Médico </label>
                  <input
                    type="date"
                    max="9999-12-31"
                    class="form-control"
                    formControlName="dataExameMedico"
                    [ngStyle]="
                      formCadastro.get('emprego').get('dataExameMedico')
                        .invalid &&
                      (temErro ||
                        formCadastro.get('emprego').get('dataExameMedico')
                          .dirty)
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro
                        .get('emprego')
                        .get('dataExameMedico')
                        .hasError('required') && temErro
                    "
                  >
                    Campo Obrigatório
                  </span>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro
                        .get('emprego')
                        .get('dataExameMedico')
                        .hasError('data')
                    "
                  >
                    Data Inválida
                  </span>
                </div>

                <div
                  class="col-md-6 col-sm-6 form-group"
                  *ngIf="
                    formCadastro.value.emprego.primeiroEmprego != '' &&
                    formCadastro.value.emprego.outroEmprego == 1
                  "
                >
                  <label>Carga Horária <small>(Outro emprego)</small></label>
                  <input
                    type="number"
                    min="20"
                    max="44"
                    class="form-control"
                    formControlName="cargaHoraria"
                    placeholder="Carga Horária"
                    [ngStyle]="
                      formCadastro.get('emprego').get('cargaHoraria').invalid &&
                      (temErro ||
                        formCadastro.get('emprego').get('cargaHoraria').dirty)
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro &&
                      formCadastro
                        .get('emprego')
                        .get('cargaHoraria')
                        .hasError('required')
                    "
                  >
                    Campo Obrigatório
                  </span>
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (temErro &&
                        formCadastro
                          .get('emprego')
                          .get('cargaHoraria')
                          .hasError('min')) ||
                      formCadastro
                        .get('emprego')
                        .get('cargaHoraria')
                        .hasError('max')
                    "
                  >
                    Insira um número entre 20 e 44
                  </span>
                </div>
              </div>
            </fieldset> -->
          </div>
          <!-- step 3 -->
          <div
            *ngIf="step == 3"
            [style.display]="step == 3 ? 'block' : 'none'"
            id="steps-uid-0-p-3"
            role="tabpanel"
            aria-labelledby="steps-uid-0-h-3"
            class="body"
            aria-hidden="true"
          >
            <fieldset>
              <legend>
                <h4>Endereço</h4>
              </legend>
              <div class="row" formGroupName="endereco">
                <div class="col-lg-3 col-md-4 col-sm-6 form-group">
                  <div class="d-flex justify-content-between">
                    <label>CEP</label>
                    <a
                      href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                      target="_blank"
                      class="btn-link text-primary"
                      data-toggle="tooltip"
                      data-placement="left"
                      title="Não sabe seu CEP?"
                    >
                      <i class="fa fa-envelope-open-o"></i>
                    </a>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    (keyup)="onCepChange()"
                    formControlName="cep"
                    placeholder="CEP"
                    mask="00000-000"
                    [ngClass]="
                      formCadastro.get('endereco').get('cep').invalid &&
                      (temErro || formCadastro.get('endereco').get('cep').touched)
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('endereco').get('cep').invalid &&
                      (temErro || formCadastro.get('endereco').get('cep').touched)
                    "
                  >
                    {{
                      formCadastro.get('endereco').get('cep').hasError('invalid')
                        ? 'CEP Inválido'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                  <span class="form-text" *ngIf="loadingCep">
                    Carregando
                    <i class="fa fa-spinner fa-spin" aria-hidden="false"></i>
                  </span>
                </div>
                <div class="col-lg-3 col-md-5 col-sm-6 form-group">
                  <label>Logradouro</label>
                  <input
                    type="text"
                    maxLength="60"
                    class="form-control"
                    formControlName="logradouro"
                    placeholder="Logradouro"
                    [ngClass]="
                      formCadastro.get('endereco').get('logradouro').invalid && temErro
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro && formCadastro.get('endereco').get('logradouro').hasError('required')
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-5 form-group">
                  <label>Número</label>
                  <input
                    type="number"
                    min="0"
                    max="9999"
                    class="form-control"
                    formControlName="numero"
                    placeholder="Número"
                    [ngClass]="
                      formCadastro.get('endereco').get('numero').invalid &&
                      (temErro || formCadastro.get('endereco').get('numero').touched)
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('endereco').get('numero').invalid &&
                      (temErro || formCadastro.get('endereco').get('numero').touched)
                    "
                  >
                    {{
                      formCadastro.get('endereco').get('numero').hasError('min') ||
                      formCadastro.get('endereco').get('numero').hasError('max')
                        ? 'Insira um número entre 0 e 9999'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-7 form-group">
                  <label>Complemento <small>(Opcional)</small></label>
                  <input
                    type="text"
                    maxLength="38"
                    class="form-control"
                    formControlName="complemento"
                    placeholder="Complemento"
                  />
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 form-group">
                  <label>Bairro</label>
                  <input
                    type="text"
                    maxLength="40"
                    class="form-control"
                    formControlName="bairro"
                    placeholder="Bairro"
                    [ngClass]="
                      formCadastro.get('endereco').get('bairro').invalid && temErro
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro && formCadastro.get('endereco').get('bairro').hasError('required')
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 form-group">
                  <label>Cidade</label>
                  <input
                    type="text"
                    class="form-control input-disabled"
                    formControlName="cidade"
                    placeholder="Cidade"
                    [ngClass]="
                      formCadastro.get('endereco').get('cidade').invalid && temErro
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      temErro && formCadastro.get('endereco').get('cidade').hasError('required')
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 form-group">
                  <label>UF</label>
                  <input
                    type="text"
                    class="form-control input-disabled"
                    formControlName="uf"
                    placeholder="UF"
                    [ngClass]="
                      formCadastro.get('endereco').get('uf').invalid && temErro
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="temErro && formCadastro.get('endereco').get('uf').hasError('required')"
                  >
                    Campo Obrigatório
                  </span>
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend>
                <h4>Contato</h4>
              </legend>
              <div class="row" formGroupName="contato">
                <div class="col-md-6 form-group">
                  <label>Email Principal</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="emailPrincipal"
                    placeholder="Email Principal"
                    [ngClass]="
                      formCadastro.get('contato').get('emailPrincipal').invalid &&
                      (temErro || formCadastro.get('contato').get('emailPrincipal').touched)
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('contato').get('emailPrincipal').invalid &&
                      (temErro || formCadastro.get('contato').get('emailPrincipal').touched)
                    "
                  >
                    {{
                      formCadastro.get('contato').get('emailPrincipal').hasError('email')
                        ? 'Formato Inválido'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-6 form-group">
                  <label>Email Secundário <small>(Opcional)</small> </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="emailSecundario"
                    placeholder="Email Secundário"
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="formCadastro.get('contato').get('emailSecundario').hasError('email')"
                  >
                    Formato Inválido
                  </span>
                </div>
                <div class="col-md-6 form-group">
                  <label>Celular</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="celular"
                    placeholder="Celular"
                    mask="(00) 00000-0000"
                    [ngClass]="
                      formCadastro.get('contato').get('celular').invalid &&
                      (temErro || formCadastro.get('contato').get('celular').touched)
                        ? 'is-invalid'
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      formCadastro.get('contato').get('celular').invalid &&
                      (temErro || formCadastro.get('contato').get('celular').touched)
                    "
                  >
                    {{
                      formCadastro.get('contato').get('celular').hasError('celular')
                        ? 'Formato Inválido'
                        : 'Campo Obrigatório'
                    }}
                  </span>
                </div>
                <div class="col-md-6 form-group">
                  <label>Telefone Fixo <small>(Opcional)</small></label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="telefone"
                    placeholder="Telefone"
                    mask="(00) 0000-0000"
                    [ngStyle]="
                      formCadastro.get('contato').get('telefone').invalid
                        ? { 'border-color': 'red' }
                        : null
                    "
                  />
                  <span
                    class="form-text text-danger"
                    *ngIf="formCadastro.get('contato').get('telefone').hasError('telefone')"
                  >
                    Formato Inválido
                  </span>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group" formGroupName="contatosSecundarios">
                  <div class="form-group col-12 border-bottom mt-1 mb-2">
                    <div class="d-flex justify-content-between">
                      <h4 class="text-muted lead">Contatos Secundários</h4>
                      <!-- <button
                        type="button"
                        class="mb-2 btn btn-primary btn-sm btn-add-phone"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Clique para adicionar"
                        (click)="adicionarContatoSecundario()"
                        [disabled]="listaContatosSecundarios.length >= 3"
                      >
                        <span class="mr-1">Adicionar Contato</span>
                        <i class="fa fa-plus"></i>
                      </button> -->
                    </div>
                  </div>
                  <div class="form-group col-sm-12 col-md-12 col-lg-12 group-phones">
                    <label class="d-flex justify-content-between">
                      <span>Telefone</span>
                      <small class="text-muted">Descrição e Nº telefônico com DDD</small>
                    </label>
                    <div class="input-group group-phone mb-3">
                      <!-- <input
                        autocomplete="off"
                        type="text"
                        formControlName="descSecundario"
                        class="form-control tipo"
                        name="tipo[]"
                        placeholder="Ex.: Celular"
                      /> -->
                      <select
                        class="form-control mr-2"
                        formControlName="descSecundario"
                        name="tipo[]"
                      >
                        <option value="">Selecione o tipo de contato</option>
                        <option value="Celular">Celular</option>
                        <option value="Residencial">Residencial (fixo)</option>
                        <option value="Comercial">Comercial (fixo)</option>
                      </select>
                      <input
                        autocomplete="off"
                        type="text"
                        formControlName="celularSecundario"
                        class="form-control telefone"
                        inputmode="numeric"
                        name="telefone[]"
                        placeholder="(00) 00000-0000"
                        mask
                        telefoneMask
                      />
                    </div>

                    <div
                      class="input-group group-phone mb-3"
                      *ngFor="let contato of listaContatosSecundarios; let i = index"
                    >
                      <input
                        value="{{ contato.DescSecundario }}"
                        type="text"
                        class="form-control tipo"
                        readonly
                      />
                      <input
                        value="{{ formatarNumero(contato.CelularSecundario) }}"
                        type="text"
                        class="form-control telefone"
                        readonly
                      />
                      <div class="input-group-append">
                        <button
                          type="button"
                          class="btn btn-outline-secondary btn-remove-phone"
                          data-toggle="tooltip"
                          data-placement="left"
                          title="Clique para remover"
                          (click)="removerContatoSecundario(i)"
                        >
                          <i class="fa fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end">
                      <button
                        type="button"
                        class="mb-2 btn btn-primary btn-sm btn-add-phone"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Clique para adicionar"
                        (click)="adicionarContatoSecundario()"
                        [disabled]="listaContatosSecundarios.length >= 3"
                      >
                        <span class="mr-1">Adicionar Contato</span>
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <!-- step 4 -->
          <div
            *ngIf="step == 4"
            [style.display]="step == 4 ? 'block' : 'none'"
            id="steps-uid-0-p-4"
            role="tabpanel"
            aria-labelledby="steps-uid-0-h-4"
            class="body"
            aria-hidden="true"
          >
            <div class="row" formGroupName="documentacao">
              <fieldset class="col-12">
                <legend>
                  <h4>Identidade</h4>
                </legend>
                <div class="row">
                  <div class="col-lg-3 col-md-6 form-group">
                    <label>RG </label>
                    <input
                      type="text"
                      maxLength="9"
                      class="form-control"
                      formControlName="identidade"
                      mask="0*"
                      placeholder="000000000"
                      [ngClass]="
                        (formCadastro.get('documentacao').get('identidade').touched || temErro) &&
                        formCadastro.get('documentacao').get('identidade').invalid
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (formCadastro.get('documentacao').get('identidade').touched || temErro) &&
                        formCadastro.get('documentacao').get('identidade').invalid
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-lg-3 col-md-6 form-group">
                    <label>Data de Expedição </label>
                    <input
                      type="date"
                      max="9999-12-31"
                      class="form-control"
                      formControlName="idDataExpedicao"
                      (keyup)="
                        verificaData(
                          formCadastro.get('dadosPessoais').get('dataNascimento'),
                          formCadastro.get('documentacao').get('idDataExpedicao')
                        )
                      "
                      [ngClass]="
                        (formCadastro.get('documentacao').get('idDataExpedicao').touched ||
                          temErro) &&
                        formCadastro.get('documentacao').get('idDataExpedicao').invalid
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (formCadastro.get('documentacao').get('idDataExpedicao').touched ||
                          temErro) &&
                        formCadastro.get('documentacao').get('idDataExpedicao').invalid
                      "
                    >
                      {{
                        formCadastro.get('documentacao').get('idDataExpedicao').hasError('data')
                          ? 'Data Inválida'
                          : 'Campo Obrigatório'
                      }}
                    </span>
                  </div>
                  <div class="col-lg-3 col-md-6 form-group">
                    <label>Órgão Expedidor </label>
                    <input
                      type="text"
                      maxLength="8"
                      class="form-control"
                      formControlName="idOrgaoExpedido"
                      placeholder="Órgão Expedidor"
                      pattern="[A-Za-záãâéêíóôõúçÁÃÂÉÊÍÓÔÕÚÇ\s]+$"
                      (input)="uppercase($event)"
                      [ngClass]="
                        formCadastro.get('documentacao').get('idOrgaoExpedido').invalid &&
                        (temErro || formCadastro.get('documentacao').get('idOrgaoExpedido').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro ||
                          formCadastro.get('documentacao').get('idOrgaoExpedido').touched) &&
                        formCadastro.get('documentacao').get('idOrgaoExpedido').invalid
                      "
                    >
                      {{
                        formCadastro.get('documentacao').get('idOrgaoExpedido').hasError('pattern')
                          ? 'Campo só permite letras'
                          : 'Campo Obrigatório'
                      }}
                    </span>
                  </div>
                  <div class="col-lg-3 col-md-6 form-group">
                    <label>UF </label>
                    <select
                      class="form-control"
                      formControlName="idUf"
                      [ngClass]="
                        formCadastro.get('documentacao').get('idUf').invalid &&
                        (temErro || formCadastro.get('documentacao').get('idUf').touched)
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">UF</option>
                      <option *ngFor="let uf of listaUf" value="{{ uf.Descricao }}">
                        {{ uf.Descricao }}
                      </option>
                    </select>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro || formCadastro.get('documentacao').get('idUf').touched) &&
                        formCadastro.get('documentacao').get('idOrgaoExpedido').invalid
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                </div>
              </fieldset>

              <fieldset class="col-12">
                <legend>
                  <h4>Registro Profissional <small>(Opcional)</small></h4>
                </legend>
                <div class="row">
                  <div class="col-md-4 col-sm-6 form-group">
                    <label>Registro </label>
                    <input
                      type="text"
                      mask="0*"
                      maxlength="8"
                      class="form-control"
                      formControlName="profissaoRegistro"
                      placeholder="Registro"
                      (keyup)="onRegistroChange()"
                      [ngClass]="
                        formCadastro.get('documentacao').get('profissaoRegistro').invalid &&
                        (temErro ||
                          formCadastro.get('documentacao').get('profissaoRegistro').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                  </div>
                  <div class="col-md-4 col-sm-6 form-group">
                    <label>Órgão Expedidor </label>
                    <input
                      type="text"
                      maxLength="7"
                      class="form-control"
                      formControlName="profissaoOrgExpedidor"
                      placeholder="Órgão Expedidor"
                      (input)="uppercase($event)"
                      (keyup)="onRegistroChange()"
                      [ngClass]="
                        formCadastro.get('documentacao').get('profissaoOrgExpedidor').invalid &&
                        (temErro ||
                          formCadastro.get('documentacao').get('profissaoOrgExpedidor').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro ||
                          formCadastro.get('documentacao').get('profissaoOrgExpedidor').touched) &&
                        formCadastro.get('documentacao').get('profissaoOrgExpedidor').invalid
                      "
                    >
                      {{
                        formCadastro
                          .get('documentacao')
                          .get('profissaoOrgExpedidor')
                          .hasError('pattern')
                          ? 'Campo só permite letras'
                          : 'Campo Obrigatório'
                      }}
                    </span>
                  </div>
                  <div class="col-md-4 col-sm-6 form-group">
                    <label>UF </label>
                    <select
                      class="form-control"
                      formControlName="profissaoUf"
                      (change)="onRegistroChange()"
                      [ngClass]="
                        formCadastro.get('documentacao').get('profissaoUf').invalid &&
                        (temErro || formCadastro.get('documentacao').get('profissaoUf').touched)
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">UF</option>
                      <option *ngFor="let uf of listaUf" value="{{ uf.Descricao }}">
                        {{ uf.Descricao }}
                      </option>
                    </select>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro || formCadastro.get('documentacao').get('profissaoUf').touched) &&
                        formCadastro.get('documentacao').get('profissaoUf').invalid
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                </div>
              </fieldset>

              <fieldset class="col-12">
                <legend>
                  <h4>Título de Eleitor <small>(Opcional)</small></h4>
                </legend>
                <div class="row">
                  <div class="col-lg-4 col-md-7 form-group">
                    <label>Título de Eleitor </label>
                    <input
                      type="text"
                      mask="0000 0000 0000"
                      minlength="12"
                      class="form-control"
                      formControlName="tituloEleitor"
                      placeholder="Título de Eleitor"
                      [ngClass]="
                        formCadastro.get('documentacao').get('tituloEleitor').invalid &&
                        (temErro || formCadastro.get('documentacao').get('tituloEleitor').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro ||
                          formCadastro.get('documentacao').get('tituloEleitor').touched) &&
                        formCadastro.get('documentacao').get('tituloEleitor').invalid
                      "
                    >
                      {{
                        formCadastro.get('documentacao').get('tituloEleitor').hasError('minlength')
                          ? 'Mínimo de 12 dígitos'
                          : formCadastro
                              .get('documentacao')
                              .get('tituloEleitor')
                              .hasError('tituloInvalido')
                          ? 'Título inválido'
                          : 'Campo obrigatório'
                      }}
                    </span>
                  </div>
                  <div class="col-lg-3 col-md-5 form-group">
                    <label>Zona Eleitoral </label>
                    <input
                      type="text"
                      mask="0*"
                      maxlength="5"
                      class="form-control"
                      formControlName="zonaEleitoral"
                      placeholder="Zona Eleitoral"
                      [ngClass]="
                        formCadastro.get('documentacao').get('zonaEleitoral').invalid &&
                        (temErro || formCadastro.get('documentacao').get('zonaEleitoral').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro ||
                          formCadastro.get('documentacao').get('zonaEleitoral').touched) &&
                        formCadastro.get('documentacao').get('zonaEleitoral').invalid
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-lg-3 col-md-7 form-group">
                    <label>Seção Eleitoral </label>
                    <input
                      type="text"
                      mask="0*"
                      maxlength="6"
                      class="form-control"
                      formControlName="secaoEleitoral"
                      placeholder="Seção Eleitoral"
                      [ngClass]="
                        formCadastro.get('documentacao').get('secaoEleitoral').invalid &&
                        (temErro || formCadastro.get('documentacao').get('secaoEleitoral').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro ||
                          formCadastro.get('documentacao').get('secaoEleitoral').touched) &&
                        formCadastro.get('documentacao').get('secaoEleitoral').invalid
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-lg-2 col-md-5 form-group">
                    <label>UF </label>
                    <select
                      class="form-control"
                      formControlName="ufEleitoral"
                      [ngClass]="
                        formCadastro.get('documentacao').get('ufEleitoral').invalid &&
                        (temErro || formCadastro.get('documentacao').get('ufEleitoral').touched)
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">UF</option>
                      <option *ngFor="let uf of listaUf" value="{{ uf.Descricao }}">
                        {{ uf.Descricao }}
                      </option>
                    </select>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro || formCadastro.get('documentacao').get('ufEleitoral').touched) &&
                        formCadastro.get('documentacao').get('ufEleitoral').invalid
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                </div>
              </fieldset>

              <fieldset class="col-lg-6 col-md-12">
                <legend>
                  <h4>PIS</h4>
                </legend>
                <div class="row">
                  <div class="col-md-12 form-group">
                    <label>Número</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="numeroPis"
                      placeholder="Número"
                      mask="000.00000.00-0"
                      [ngClass]="
                        formCadastro.get('documentacao').get('numeroPis').invalid &&
                        (temErro || formCadastro.get('documentacao').get('numeroPis').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro || formCadastro.get('documentacao').get('numeroPis').touched) &&
                        formCadastro.get('documentacao').get('numeroPis').invalid
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <!-- <div class="col-md-4 form-group">
                    <label>Data Emissão </label>
                    <input
                      type="date"
                      max="9999-12-31"
                      class="form-control"
                      formControlName="dataEmissaoPis"
                      style="padding-right: 0"
                      [ngStyle]="
                        formCadastro.get('documentacao').get('dataEmissaoPis').invalid &&
                        (temErro || formCadastro.get('documentacao').get('dataEmissaoPis').dirty)
                          ? {'border-color': 'red'}
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        temErro &&
                        formCadastro.get('documentacao').get('dataEmissaoPis').hasError('required')
                      "
                    >
                      Campo Obrigatório
                    </span>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        formCadastro.get('documentacao').get('dataEmissaoPis').hasError('data')
                      "
                    >
                      Data Inválida
                    </span>
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Agência</label>
                    <input
                      type="text"
                      maxlength="8"
                      class="form-control"
                      formControlName="agenciaPis"
                      mask="0*"
                      placeholder="Agência"
                      [ngStyle]="
                        formCadastro.get('documentacao').get('agenciaPis').invalid &&
                        (temErro || formCadastro.get('documentacao').get('agenciaPis').dirty)
                          ? {'border-color': 'red'}
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        temErro &&
                        formCadastro.get('documentacao').get('agenciaPis').hasError('required')
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div> -->
                </div>
              </fieldset>

              <fieldset class="col-lg-6 col-md-12">
                <legend>
                  <h4>Carteira Profissional</h4>
                </legend>
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label>Número </label>
                    <input
                      type="text"
                      mask="0*"
                      maxlength="8"
                      class="form-control"
                      formControlName="carteiraProfissional"
                      placeholder="Número"
                      [ngClass]="
                        formCadastro.get('documentacao').get('carteiraProfissional').invalid &&
                        (temErro ||
                          formCadastro.get('documentacao').get('carteiraProfissional').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <!-- <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro ||
                          formCadastro.get('documentacao').get('carteiraProfissional').touched) &&
                        formCadastro.get('documentacao').get('carteiraProfissional').invalid
                      "
                    >
                      Campo Obrigatório
                    </span> -->
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Série CTPS </label>
                    <input
                      type="text"
                      mask="0*"
                      maxlength="5"
                      class="form-control"
                      formControlName="serieCtps"
                      placeholder="Número"
                      [ngClass]="
                        formCadastro.get('documentacao').get('serieCtps').invalid &&
                        (temErro || formCadastro.get('documentacao').get('serieCtps').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <!-- <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro || formCadastro.get('documentacao').get('serieCtps').touched) &&
                        formCadastro.get('documentacao').get('serieCtps').invalid
                      "
                    >
                      Campo Obrigatório
                    </span> -->
                  </div>
                  <div class="col-md-4 form-group">
                    <label>UF </label>
                    <select
                      class="form-control"
                      formControlName="ufCarteiraTrabalho"
                      [ngClass]="
                        formCadastro.get('documentacao').get('ufCarteiraTrabalho').invalid &&
                        (temErro ||
                          formCadastro.get('documentacao').get('ufCarteiraTrabalho').touched)
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">UF</option>
                      <option *ngFor="let uf of listaUf" value="{{ uf.Descricao }}">
                        {{ uf.Descricao }}
                      </option>
                    </select>
                    <!-- <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro ||
                          formCadastro.get('documentacao').get('ufCarteiraTrabalho').touched) &&
                        formCadastro.get('documentacao').get('ufCarteiraTrabalho').invalid
                      "
                    >
                      Campo Obrigatório
                    </span> -->
                  </div>
                </div>
              </fieldset>

              <fieldset class="col-lg-6 col-md-12">
                <legend>
                  <h4 *ngIf="!camposMilitarObrigatorios">
                    Certificado Militar <small>(opcional)</small>
                  </h4>
                  <h4 *ngIf="camposMilitarObrigatorios">Certificado Militar</h4>
                </legend>
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label>CSM </label>
                    <input
                      type="text"
                      mask="0*"
                      maxlength="30"
                      class="form-control"
                      formControlName="csm"
                      placeholder="CSM"
                      (keyup)="onCertificadoMilitarChange()"
                      [ngClass]="
                        formCadastro.get('documentacao').get('csm').invalid &&
                        (temErro || formCadastro.get('documentacao').get('csm').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro || formCadastro.get('documentacao').get('csm').touched) &&
                        formCadastro.get('documentacao').get('csm').invalid
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Série <small>(Apenas 1 letra)</small></label>
                    <input
                      type="text"
                      maxlength="1"
                      class="form-control"
                      pattern="[A-Za-z]"
                      formControlName="serieCertificadoMilitar"
                      placeholder="Série"
                      (keyup)="onCertificadoMilitarChange()"
                      [ngClass]="
                        formCadastro.get('documentacao').get('serieCertificadoMilitar').invalid &&
                        (temErro ||
                          formCadastro.get('documentacao').get('serieCertificadoMilitar').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro ||
                          formCadastro.get('documentacao').get('serieCertificadoMilitar')
                            .touched) &&
                        formCadastro.get('documentacao').get('serieCertificadoMilitar').invalid
                      "
                    >
                      {{
                        formCadastro
                          .get('documentacao')
                          .get('serieCertificadoMilitar')
                          .hasError('pattern')
                          ? 'Campo só permite letras'
                          : 'Campo Obrigatório'
                      }}
                    </span>
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Número </label>
                    <input
                      type="text"
                      class="form-control"
                      mask="0*"
                      maxlength="12"
                      formControlName="numeroCertificadoMilitar"
                      placeholder="Número"
                      (keyup)="onCertificadoMilitarChange()"
                      [ngClass]="
                        formCadastro.get('documentacao').get('numeroCertificadoMilitar').invalid &&
                        (temErro ||
                          formCadastro.get('documentacao').get('numeroCertificadoMilitar').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro ||
                          formCadastro.get('documentacao').get('numeroCertificadoMilitar')
                            .touched) &&
                        formCadastro.get('documentacao').get('numeroCertificadoMilitar').invalid
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                </div>
              </fieldset>

              <fieldset *ngIf="maiorIdade()" class="col-lg-6 col-md-12">
                <legend>
                  <h4>CNH <small>(opcional)</small></h4>
                </legend>
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label>Número</label>
                    <input
                      type="text"
                      class="form-control"
                      mask="0*"
                      maxlength="11"
                      formControlName="cnhNumero"
                      placeholder="Número"
                      (keyup)="onCnhChange()"
                      (blur)="onCnhChange()"
                      [ngClass]="
                        formCadastro.get('documentacao').get('cnhNumero').invalid &&
                        (temErro || formCadastro.get('documentacao').get('cnhNumero').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro || formCadastro.get('documentacao').get('cnhNumero').touched) &&
                        formCadastro.get('documentacao').get('cnhNumero').invalid
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Categoria</label>
                    <select
                      type="text"
                      class="form-control"
                      formControlName="cnhCategoria"
                      (keyup)="onCnhChange()"
                      (blur)="onCnhChange()"
                      [ngClass]="
                        formCadastro.get('documentacao').get('cnhCategoria').invalid &&
                        (temErro || formCadastro.get('documentacao').get('cnhCategoria').touched)
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">Selecione...</option>
                      <option *ngFor="let cnh of listaCnh" value="{{ cnh.Descricao }}">
                        {{ cnh.Descricao }}
                      </option>
                    </select>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro || formCadastro.get('documentacao').get('cnhCategoria').touched) &&
                        formCadastro.get('documentacao').get('cnhCategoria').invalid
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Data Validade</label>
                    <input
                      type="date"
                      max="9999-12-31"
                      class="form-control"
                      formControlName="cnhDataValidade"
                      style="padding-right: 0"
                      (keyup)="onCnhChange()"
                      (blur)="onCnhChange()"
                      [ngClass]="
                        formCadastro.get('documentacao').get('cnhDataValidade').invalid &&
                        (temErro || formCadastro.get('documentacao').get('cnhDataValidade').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro ||
                          formCadastro.get('documentacao').get('cnhDataValidade').touched) &&
                        formCadastro.get('documentacao').get('cnhDataValidade').hasError('required')
                      "
                    >
                      {{
                        formCadastro.get('documentacao').get('cnhDataValidade').hasError('data')
                          ? 'Data Inválida'
                          : 'Campo Obrigatório'
                      }}
                    </span>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <!-- step 6 -->
          <div
            *ngIf="step == 6 && localStorage.TipoUsuario != '10'"
            [style.display]="step == 6 ? 'block' : 'none'"
            class="body"
          >
            <div class="row" formGroupName="dadosPagamento">
              <fieldset class="col-md-12">
                <legend>
                  <h4>Conta Corrente para o Crédito</h4>
                </legend>
                <div class="row" formGroupName="contaCorrenteCredito">
                  <div class="col-md-3 form-group">
                    <label>Banco</label>
                    <select
                      class="form-control"
                      formControlName="banco"
                      [ngClass]="
                        (formCadastro.get('dadosPagamento').get('contaCorrenteCredito').get('banco')
                          .invalid &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('contaCorrenteCredito')
                            .get('banco').touched) ||
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('contaCorrenteCredito')
                            .get('banco').invalid)
                          ? 'is-invalid'
                          : null
                      "
                      (change)="buscarAgencias($event)"
                    >
                      <option value="">Selecione...</option>
                      <option *ngFor="let banco of listaBancos" value="{{ banco?.Codigo.trim() }}">
                        {{ banco?.Nome }}
                      </option>
                    </select>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('contaCorrenteCredito')
                            .get('banco')
                            .hasError('required')) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('contaCorrenteCredito')
                          .get('banco')
                          .hasError('required') &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('contaCorrenteCredito')
                            .get('banco').touched)
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-md-3 form-group">
                    <label>Agência</label>
                    <select
                      class="form-control"
                      formControlName="agencia"
                      placeholder="Agência"
                      [ngClass]="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('contaCorrenteCredito')
                            .get('agencia').invalid) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('contaCorrenteCredito')
                          .get('agencia').invalid &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('contaCorrenteCredito')
                            .get('agencia').touched)
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">Selecione...</option>
                      <option
                        *ngFor="let agencia of listaAgencias"
                        value="{{ agencia['CodAgencia'] }}"
                      >
                        {{ agencia['CodAgencia'] }} ({{ agencia['NomeAgencia'] }})
                      </option>
                    </select>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('contaCorrenteCredito')
                            .get('agencia')
                            .hasError('required')) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('contaCorrenteCredito')
                          .get('agencia')
                          .hasError('required') &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('contaCorrenteCredito')
                            .get('agencia').touched)
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-md-3 form-group">
                    <label>Operação</label>
                    <select
                      placeholder="Operação"
                      class="form-control"
                      formControlName="operacao"
                      [ngClass]="
                        (formCadastro
                          .get('dadosPagamento')
                          .get('contaCorrenteCredito')
                          .get('operacao').invalid &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('contaCorrenteCredito')
                            .get('operacao').touched) ||
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('contaCorrenteCredito')
                            .get('operacao').invalid)
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">Selecione...</option>
                      <option
                        *ngFor="let operacao of listaOperacoes"
                        value="{{ operacao['TipoOperacao'] }}"
                      >
                        {{ operacao['TipoOperacao'] }} -
                        {{ operacao['NomeOperacao'] }}
                      </option>
                    </select>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('contaCorrenteCredito')
                            .get('operacao')
                            .hasError('required')) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('contaCorrenteCredito')
                          .get('operacao')
                          .hasError('required') &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('contaCorrenteCredito')
                            .get('operacao').touched)
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-md-3 form-group">
                    <label>Conta</label>
                    <input
                      type="text"
                      maxLength="10"
                      mask="0*"
                      class="form-control"
                      formControlName="contaCorrente"
                      placeholder="Conta Corrente"
                      [ngClass]="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('contaCorrenteCredito')
                            .get('contaCorrente').invalid) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('contaCorrenteCredito')
                          .get('contaCorrente').invalid &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('contaCorrenteCredito')
                            .get('contaCorrente').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (formCadastro
                          .get('dadosPagamento')
                          .get('contaCorrenteCredito')
                          .get('contaCorrente')
                          .hasError('required') &&
                          temErro) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('contaCorrenteCredito')
                          .get('contaCorrente')
                          .hasError('required') &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('contaCorrenteCredito')
                            .get('contaCorrente').touched)
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                </div>
              </fieldset>

              <fieldset class="col-lg-6 col-md-6">
                <legend>
                  <h4>Contrato Temporário <small>(opcional)</small></h4>
                </legend>
                <div class="row" formGroupName="contratoTemporario">
                  <div class="col-md-6 form-group">
                    <label>Data Início</label>
                    <input
                      type="date"
                      max="9999-12-31"
                      (keyup)="onContratoChange()"
                      (blur)="onContratoChange()"
                      class="form-control input-disabled"
                      style="padding-right: 0"
                      formControlName="dataInicio"
                      style="padding-right: 0"
                    />
                  </div>
                  <div class="col-md-6 form-group">
                    <label>Data Fim </label>
                    <input
                      type="date"
                      max="9999-12-31"
                      (keyup)="onContratoChange()"
                      (blur)="onContratoChange()"
                      class="form-control input-disabled"
                      style="padding-right: 0"
                      formControlName="dataFim"
                      style="padding-right: 0"
                    />
                  </div>
                </div>
              </fieldset>

              <fieldset class="col-lg-6 col-md-6">
                <legend>
                  <h4>Período de Experiência<small> (opcional)</small></h4>
                </legend>
                <div class="row" formGroupName="periodoExperiencia">
                  <div class="col-md-6 form-group">
                    <label>Data Inicial </label>
                    <input
                      type="date"
                      max="9999-12-31"
                      (keyup)="onPeriodoChange()"
                      (blur)="onPeriodoChange()"
                      class="form-control"
                      style="padding-right: 0"
                      formControlName="dataInicio"
                    />
                  </div>
                  <div class="col-md-6 form-group">
                    <label>Data Final </label>
                    <input
                      type="date"
                      max="9999-12-31"
                      (keyup)="onPeriodoChange()"
                      (blur)="onContratoChange()"
                      class="form-control"
                      style="padding-right: 0"
                      formControlName="dataFim"
                    />
                  </div>
                </div>
              </fieldset>

              

              <fieldset class="col-lg-12 col-md-6 d-flex">
                <div
                  class="col-md-12 mt-lg-3 mt-md-5 form-group"
                  [ngClass]="
                    formCadastro.get('dadosPagamento').get('bloqueadoConsignado').invalid && temErro
                      ? 'is-invalid'
                      : null
                  "
                >
                  <label>Bloqueado Consignado </label> <br />
                  <input
                    type="radio"
                    id="simBloqueado"
                    value="1"
                    formControlName="bloqueadoConsignado"
                  />
                  <label for="simBloqueado">&nbsp; Sim</label>&nbsp; &nbsp;
                  <input
                    type="radio"
                    id="naoBloqueado"
                    value="0"
                    formControlName="bloqueadoConsignado"
                  />
                  <label for="naoBloqueado">&nbsp; Não</label><br />
                  <span
                    class="form-text text-danger"
                    *ngIf="
                      (formCadastro.get('dadosPagamento').get('bloqueadoConsignado').invalid &&
                        formCadastro.get('dadosPagamento').get('bloqueadoConsignado').touched) ||
                      (temErro &&
                        formCadastro.get('dadosPagamento').get('bloqueadoConsignado').invalid)
                    "
                  >
                    Campo Obrigatório
                  </span>
                </div>
              </fieldset>

              <fieldset class="col-lg-5 col-md-12">
                <legend>
                  <h4>Nomeação</h4>
                </legend>
                <div class="row" formGroupName="nomeacao">
                  <div class="col-md-6 form-group">
                    <label
                      >Classificação do Ato<small *ngIf="tipoNomeacaoObrigatoria"
                        >(opcional)</small
                      ></label
                    >
                    <select
                      type="text"
                      class="form-control"
                      formControlName="classificacaoAto"
                      [ngClass]="
                        (temErro &&
                          formCadastro.get('dadosPagamento').get('nomeacao').get('classificacaoAto')
                            .invalid) ||
                        (formCadastro.get('dadosPagamento').get('nomeacao').get('classificacaoAto')
                          .invalid &&
                          formCadastro.get('dadosPagamento').get('nomeacao').get('classificacaoAto')
                            .touched)
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">Selecione...</option>
                      <option
                        *ngFor="let classificacao of listaClassificacaoAto"
                        value="{{ classificacao.Codigo }}"
                      >
                        {{ classificacao.Descricao }}
                      </option>
                    </select>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('nomeacao')
                            .get('classificacaoAto')
                            .hasError('required')) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('nomeacao')
                          .get('classificacaoAto')
                          .hasError('required') &&
                          formCadastro.get('dadosPagamento').get('nomeacao').get('classificacaoAto')
                            .touched)
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-md-6 form-group">
                    <label
                      >Número do Processo
                      <small *ngIf="tipoNomeacaoObrigatoria">(opcional)</small></label
                    >
                    <input
                      type="text"
                      maxLength="50"
                      style="text-transform: uppercase"
                      class="form-control"
                      formControlName="numeroProcesso"
                      placeholder="A00"
                      [ngClass]="
                        (temErro &&
                          formCadastro.get('dadosPagamento').get('nomeacao').get('numeroProcesso')
                            .invalid) ||
                        (formCadastro.get('dadosPagamento').get('nomeacao').get('numeroProcesso')
                          .invalid &&
                          formCadastro.get('dadosPagamento').get('nomeacao').get('numeroProcesso')
                            .touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('nomeacao')
                            .get('numeroProcesso')
                            .hasError('required')) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('nomeacao')
                          .get('numeroProcesso')
                          .hasError('required') &&
                          formCadastro.get('dadosPagamento').get('nomeacao').get('numeroProcesso')
                            .touched)
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                </div>
              </fieldset>

              <fieldset class="col-lg-7 col-md-12">
                <legend>
                  <h4>Descontos Compulsórios</h4>
                </legend>
                <div class="row" formGroupName="descontosCompulsorios">
                  <div class="col-md-4 form-group">
                    <label>Previdência</label>
                    <select
                      class="form-control"
                      formControlName="previdencia"
                      [ngClass]="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('descontosCompulsorios')
                            .get('previdencia').invalid) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('descontosCompulsorios')
                          .get('previdencia').invalid &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('descontosCompulsorios')
                            .get('previdencia').touched)
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">Selecione...</option>
                      <option value="1">SIM</option>
                      <option value="0">NÃO</option>
                    </select>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('descontosCompulsorios')
                            .get('previdencia').invalid) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('descontosCompulsorios')
                          .get('previdencia')
                          .hasError('required') &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('descontosCompulsorios')
                            .get('previdencia').touched)
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Imposto de Renda</label>
                    <select
                      class="form-control"
                      formControlName="impostoRenda"
                      [ngClass]="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('descontosCompulsorios')
                            .get('impostoRenda').invalid) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('descontosCompulsorios')
                          .get('impostoRenda').invalid &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('descontosCompulsorios')
                            .get('impostoRenda').touched)
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">Selecione...</option>
                      <option value="1">SIM</option>
                      <option value="0">NÃO</option>
                    </select>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('descontosCompulsorios')
                            .get('impostoRenda')
                            .hasError('required')) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('descontosCompulsorios')
                          .get('impostoRenda')
                          .hasError('required') &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('descontosCompulsorios')
                            .get('impostoRenda').touched)
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-md-4 form-group">
                    <label>Pensão</label>
                    <select
                      class="form-control"
                      formControlName="pensao"
                      [ngClass]="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('descontosCompulsorios')
                            .get('pensao').invalid) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('descontosCompulsorios')
                          .get('pensao').invalid &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('descontosCompulsorios')
                            .get('pensao').touched)
                          ? 'is-invalid'
                          : null
                      "
                    >
                      <option value="">Selecione...</option>
                      <option value="1">SIM</option>
                      <option value="0">NÃO</option>
                    </select>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('descontosCompulsorios')
                            .get('pensao')
                            .hasError('required')) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('descontosCompulsorios')
                          .get('pensao')
                          .hasError('required') &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('descontosCompulsorios')
                            .get('pensao').touched)
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                </div>
              </fieldset>

              <fieldset class="col-lg-7 col-md-12">
                <legend>
                  <h4>Ato</h4>
                </legend>
                <div class="row" formGroupName="ato">
                  <div class="col-md-4 form-group">
                    <label>Número </label>
                    <input
                      type="text"
                      maxLength="50"
                      style="text-transform: uppercase"
                      class="form-control"
                      formControlName="numero"
                      placeholder="A00"
                      [ngClass]="
                        (temErro &&
                          formCadastro.get('dadosPagamento').get('ato').get('numero').invalid) ||
                        (formCadastro.get('dadosPagamento').get('ato').get('numero').invalid &&
                          formCadastro.get('dadosPagamento').get('ato').get('numero').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('ato')
                            .get('numero')
                            .hasError('required')) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('ato')
                          .get('numero')
                          .hasError('required') &&
                          formCadastro.get('dadosPagamento').get('ato').get('numero').touched)
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-md-3 form-group">
                    <label>Ano </label>
                    <input
                      type="text"
                      class="form-control input-disabled"
                      mask="0000"
                      formControlName="ano"
                      placeholder="Ano"
                    />
                  </div>
                  <div class="col-md-5 form-group">
                    <label>Data </label>
                    <input
                      type="date"
                      max="9999-12-31"
                      class="form-control"
                      formControlName="data"
                      style="padding-right: 0"
                      (keyup)="onDataAtoChange()"
                      (blur)="onDataAtoChange()"
                      [ngClass]="
                        (temErro &&
                          formCadastro.get('dadosPagamento').get('ato').get('data').invalid) ||
                        (formCadastro.get('dadosPagamento').get('ato').get('data').invalid &&
                          formCadastro.get('dadosPagamento').get('ato').get('data').touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('ato')
                            .get('data')
                            .hasError('required')) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('ato')
                          .get('data')
                          .hasError('required') &&
                          formCadastro.get('dadosPagamento').get('ato').get('data').touched)
                      "
                    >
                      Campo Obrigatório
                    </span>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('ato')
                            .get('data')
                            .hasError('data')) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('ato')
                          .get('data')
                          .hasError('data') &&
                          formCadastro.get('dadosPagamento').get('ato').get('data').touched)
                      "
                    >
                      Data Inválida
                    </span>
                  </div>
                </div>
              </fieldset>

              <fieldset class="col-lg-5 col-md-12">
                <legend>
                  <h4>Diário Oficial</h4>
                </legend>
                <div class="row" formGroupName="diarioOficial">
                  <div class="col-md-6 form-group">
                    <label>Número </label>
                    <input
                      type="text"
                      style="text-transform: uppercase"
                      maxLength="50"
                      class="form-control"
                      formControlName="numero"
                      placeholder="A00"
                      [ngClass]="
                        (temErro &&
                          formCadastro.get('dadosPagamento').get('diarioOficial').get('numero')
                            .invalid) ||
                        (formCadastro.get('dadosPagamento').get('diarioOficial').get('numero')
                          .invalid &&
                          formCadastro.get('dadosPagamento').get('diarioOficial').get('numero')
                            .touched)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('diarioOficial')
                            .get('numero')
                            .hasError('required')) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('diarioOficial')
                          .get('numero')
                          .hasError('required') &&
                          formCadastro.get('dadosPagamento').get('diarioOficial').get('numero')
                            .touched)
                      "
                    >
                      Campo Obrigatório
                    </span>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>Data </label>
                    <input
                      type="date"
                      max="9999-12-31"
                      class="form-control"
                      formControlName="data"
                      style="padding-right: 0"
                      [ngClass]="
                        (formCadastro.get('dadosPagamento').get('diarioOficial').get('data')
                          .invalid &&
                          formCadastro.get('dadosPagamento').get('diarioOficial').get('data')
                            .touched) ||
                        (temErro &&
                          formCadastro.get('dadosPagamento').get('diarioOficial').get('data')
                            .invalid)
                          ? 'is-invalid'
                          : null
                      "
                    />
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('diarioOficial')
                            .get('data')
                            .hasError('required')) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('diarioOficial')
                          .get('data')
                          .hasError('required') &&
                          formCadastro.get('dadosPagamento').get('diarioOficial').get('data')
                            .touched)
                      "
                    >
                      Campo Obrigatório
                    </span>
                    <span
                      class="form-text text-danger"
                      *ngIf="
                        (temErro &&
                          formCadastro
                            .get('dadosPagamento')
                            .get('diarioOficial')
                            .get('data')
                            .hasError('data')) ||
                        (formCadastro
                          .get('dadosPagamento')
                          .get('diarioOficial')
                          .get('data')
                          .hasError('data') &&
                          formCadastro.get('dadosPagamento').get('diarioOficial').get('data').dirty)
                      "
                    >
                      Data Inválida
                    </span>
                  </div>
                </div>
              </fieldset>

              <legend></legend>
              <!-- <div class="col-md-6 form-group">
                <label>Estabilidade</label>
                <select
                  type="text"
                  class="form-control"
                  formControlName="estabilidade"
                  [ngClass]="
                    (temErro && formCadastro.get('dadosPagamento').get('estabilidade').invalid) ||
                    (formCadastro.get('dadosPagamento').get('estabilidade').invalid &&
                      formCadastro.get('dadosPagamento').get('estabilidade').touched)
                      ? 'is-invalid'
                      : null
                  "
                >
                  <option value="">Selecione...</option>
                  <option
                    *ngFor="let estabilidade of listaEstabilidade"
                    value="{{ estabilidade.Codigo }}"
                  >
                    {{ estabilidade.Descricao }}
                  </option>
                </select>
                <span
                  class="form-text text-danger"
                  *ngIf="
                    (temErro &&
                      formCadastro
                        .get('dadosPagamento')
                        .get('estabilidade')
                        .hasError('required')) ||
                    (formCadastro.get('dadosPagamento').get('estabilidade').hasError('required') &&
                      formCadastro.get('dadosPagamento').get('estabilidade').touched)
                  "
                >
                  Campo Obrigatório
                </span>
              </div> -->

              <div class="col-md-4 form-group">
                <label>Tipo de Frequência </label>
                <select
                  type="text"
                  class="form-control"
                  formControlName="tipoFrequencia"
                  [ngClass]="
                    (temErro && formCadastro.get('dadosPagamento').get('tipoFrequencia').invalid) ||
                    (formCadastro.get('dadosPagamento').get('tipoFrequencia').invalid &&
                      formCadastro.get('dadosPagamento').get('tipoFrequencia').touched)
                      ? 'is-invalid'
                      : null
                  "
                >
                  >
                  <option value="">Selecione...</option>
                  <option
                    *ngFor="let tipoFrequencia of listaTipoFrequencia"
                    value="{{ tipoFrequencia.Codigo }}"
                  >
                    {{ tipoFrequencia.Descricao }}
                  </option>
                </select>
                <span
                  class="form-text text-danger"
                  *ngIf="
                    (temErro &&
                      formCadastro
                        .get('dadosPagamento')
                        .get('tipoFrequencia')
                        .hasError('required')) ||
                    (formCadastro
                      .get('dadosPagamento')
                      .get('tipoFrequencia')
                      .hasError('required') &&
                      formCadastro.get('dadosPagamento').get('tipoFrequencia').touched)
                  "
                >
                  Campo Obrigatório
                </span>
              </div>
            </div>
          </div>

          <app-anexo
            *ngIf="step == 4 && telaDependente"
            [pagina]="'017'"
            [matricula]="matricula"
            [enviarSeparadamente]="true"
            [anexoSexo]="formCadastro.get('profissao').get('sexo').value == 1 ? 1 : 0"
            [anexoCasamento]="formCadastro.get('profissao').get('estadoCivil').value == 2 ? 1 : 0"
            (listaAnexoCadastro)="recebeListaAnexo($event)"
          ></app-anexo>
          <!-- <app-dependentes-perfil
            *ngIf="step == 5"
            [telaCadastro]="true"
            (mudandoTela)="emissao($event)"
            (insereDep)="buscarDependentes($event)"
            [listaDependente]="listaDependente"
            [matriculaAux]="
            localStorage.TipoUsuario == '10' ? localStorage.MatriculaUsuario : matriculaAux
            "
            >

            [cadastroUsuario]="cadastro"

            [ColaboradorInativo]="ColaboradorInativo"
            [dadosUsuario]="dadosUsuario"
          </app-dependentes-perfil>
        -->
          <app-dependentes
            *ngIf="step == 5"
            [telaCadastro]="true"
            (mudandoTela)="emissao($event)"
            [matricula]="matricula"
            [listaDependente]="listaDependente"
            [estadoCivil]="this.formCadastro.get('profissao').get('estadoCivil').value"
          ></app-dependentes>
          <h3
            *ngIf="localStorage.TipoUsuario == '10' && step > 4 && telaDependente"
            class="text-center alert-success p-2 rounded"
          >
            <i class="fa fa-check-circle fa-3x my-2"></i><br />
            Obrigado por preencher suas informações, agora o RH responsável vai finalizar o
            cadastro. Enquanto isso, você ainda pode adicionar e editar os dados de seus
            dependentes. Após o prosseguimento de seu cadastro, a adição e edição de dependentes
            ficará indisponível até a criação de seu perfil.
          </h3>
          <app-parametros></app-parametros>
          <button
            *ngIf="step === 6 && localStorage.TipoUsuario != '10'"
            type="button"
            class="btn btn-primary ml-2 mt-2"
            [disabled]="isDisabled"
            (click)="abiriModalIndicadores()"
            >
            Indicadores do Cálculo
          </button>
        </div>

        <div class="card-footer wizard">
          <div class="actions clearfix">
            <ul role="menu" aria-label="Pagination">
              <li
                *ngIf="
                  (step < 6 && localStorage.TipoUsuario != '10') ||
                  (step < 5 && localStorage.TipoUsuario == '10')
                "
                aria-hidden="false"
                aria-disabled="false"
              >
                <button
                  style="min-width: 113px"
                  type="submit"
                  class="btn btn-primary"
                  role="menuitem"
                >
                  Próximo <i class="icon-arrow-right14 ml-2"></i>
                </button>
              </li>
              <li *ngIf="step == 6 && localStorage.TipoUsuario != '10'" aria-hidden="true">
                <button
                  style="min-width: 113px"
                  type="submit"
                  class="btn btn-primary"
                  role="menuitem"
                >
                  Submeter <i class="fa fa-paper-plane ml-2"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Botões de navegação entre etapas para debbug -->
  <!-- <button class="acao-etapa voltar" (click)="step = step > 1 ? step - 1 : 1">-</button>
	<button class="acao-etapa passar" (click)="step = step < 5 ? step + 1 : 5">+</button> -->
  <scroll-top></scroll-top>
</div>

<!-- MODAL EMAIL -->
<button
  class="d-none"
  id="btEnviarEmail"
  data-toggle="modal"
  data-target="#modalEnviarEmail"
></button>
<div
  class="modal fade"
  id="modalEnviarEmail"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content" *ngIf="!enviarEmail">
      <div class="modal-header">
        <h5 class="modal-title text-center" id="exampleModalLabel">
          Deseja que o colaborador continue o Cadastro?
        </h5>
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Não</button>
        <button type="button" (click)="enviarEmail = true" class="btn btn-primary">Sim</button>
      </div>
    </div>
    <div class="modal-content" *ngIf="enviarEmail">
      <form #email="ngForm" [formGroup]="formEmail" (ngSubmit)="criarCredenciais()">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="exampleModalLabel">
            Informe o email do colaborador
          </h5>
        </div>
        <div class="modal-body">
          <div _ngcontent-uxj-c4="" class="form-group col-12 p-0">
            <input
              type="text"
              formControlName="email"
              name="email"
              class="form-control"
              placeholder="Email"
              required
            />
            <span
              class="form-text text-danger"
              *ngIf="
                formEmail.controls['email'].status == 'INVALID' && formEmail.controls['email'].dirty
              "
              >Digite um email válido</span
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="enviarEmail = false"
            data-dismiss="modal"
          >
            Cancelar
          </button>
          <button type="submit" [disabled]="formEmail.status == 'INVALID'" class="btn btn-primary">
            Confirmar
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal -->
<button
  type="button"
  id="btHorarioModal"
  class="btn btn-primary d-none"
  data-toggle="modal"
  data-target="#horarioModal"
></button>
<div class="modal animated fadeIn" id="horarioModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content col-12">
      <div class="modal-header">
        <h4 class="modal-title">Horário</h4>
      </div>
      <div class="modal-body table-responsive">
        <table *ngIf="horarioEscolhido" class="table table-striped border borda-clara rounded">
          <thead class="thead-dark">
            <tr>
              <th scope="col" class="text-center">#</th>
              <ng-container *ngFor="let dia of listaDiasSemana">
                <th scope="col" class="text-center">{{ dia }}</th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" class="font-weight-bold text-center">Horário pré-intervalo</th>
              <td class="text-center" *ngFor="let dia of formatarSeteDias(horarioEscolhido)">
                <ng-container *ngIf="dia !== null && dia.EntradaAntesAlmoco != '0'">
                  {{ dia?.EntradaAntesAlmoco }} às {{ dia?.SaidaAntesAlmoco }}
                </ng-container>
                <ng-container *ngIf="dia === null || dia.EntradaAntesAlmoco == '0'">
                  -
                </ng-container>
              </td>
            </tr>
            <tr>
              <th scope="row" class="font-weight-bold text-center">Horário pós-intervalo</th>
              <td class="text-center" *ngFor="let dia of formatarSeteDias(horarioEscolhido)">
                <ng-container *ngIf="dia !== null && dia.EntradaDepoisAlmoco != '0'">
                  {{ dia?.EntradaDepoisAlmoco }} às {{ dia?.SaidaDepoisAlmoco }}
                </ng-container>
                <ng-container *ngIf="dia === null || dia.EntradaDepoisAlmoco == '0'">
                  -
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="modal-footer border-0">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="limpaHorario()"
        >
          Cancelar
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">Confirmar</button>
      </div>
    </div>
  </div>
</div>
