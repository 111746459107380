import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  localStorage: LocalStorage = new LocalStorage();
  constructor(private auth: AuthService, private myRoute: Router) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
    console.log('local storage', this.localStorage);
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    /*if (window.location.href.includes('localhost')) {
      return true;
    }*/
    let menu: any;
    if (this.localStorage.Menu.length > 0) {
      menu = this.localStorage.Menu.map((menuItem) => menuItem.Link);
      if (
        next.routeConfig.path != undefined &&
        next.routeConfig.path != 'home' &&
        !menu.includes(next.routeConfig.path)
      ) {
        this.myRoute.navigate(['home']);
        return true;
      }
    }
    if (this.auth.validaSessao()) {
      if (
        this.localStorage.TipoUsuario == '1' &&
        state.url != `/perfil/${this.localStorage.NumeroOrdemUsuario}` &&
        state.url != '/contracheque' &&
        state.url != '/erro' &&
        state.url != '/processos'
      ) {
        // this.myRoute.navigate([`/cadastro-publico`]);
        this.myRoute.navigate([`/perfil/${this.localStorage.NumeroOrdemUsuario}`]);
        return true;
      } else if (this.localStorage.TipoUsuario == '10' && state.url != `/cadastro-publico`) {
        this.myRoute.navigate([`/cadastro-publico`]);
        return true;
      } else {
        return true;
      }
      // return true;
    } else {
      localStorage.clear();
      this.myRoute.navigate(['login']);
      return false;
    }
  }
}
