import { Contrato } from './contrato';
import { Data } from '../../../service/interfaces/Data';

export class Rescisao {
  Cpf: string;
  Nome: string;
  Matricula: string;
  CodigoAfastamento: string = "0";
  EmpresaFilial: string;
  TipoAvisoPrevio: number = 0;
  CodigoAvisoPrevio: string = "0";
  DataInicioAvisoPrevio: Data = new Data();
  QuantidadeDiasAvisoPrevio: number;
  DataFinalAvisoPrevio: Data = new Data();
  DispensarIndenizacao: string;
  ComprovouNovoEmprego: string = "0";
  Status: string = "1";
  DataHomologacao: Data = new Data();
  DataPagamento: Data = new Data();
  DataRescisao: Data = new Data();
  NumeroCertidao: string;
  DataInterrupcaoAvisoPrevio: Data = new Data();
  MotivoAvisoPrevio: string = "0";
  Sequencial: string;
  Tipo: number = 0;
  Competencia: string;
  NumeroOrdem: number;
}
