import { AbstractControl,  ValidatorFn  } from '@angular/forms';

export function cpfValidator(control: AbstractControl): { [key: string]: boolean } | null { 

    var i;
    var c = control.value
    if (c === '' || c === undefined || c ===null)
    {
        return ;
    } 
    else{
        if((c = c.replace(/[^\d]/g,"")).length != 11)
        return {'cpflength': true}

        if (c == "00000000000")
        return {'cpfError': true}

        var r;
        var s = 0;   

        for (i=1; i<=9; i++)
        s = s + parseInt(c[i-1]) * (11 - i); 

        r = (s * 10) % 11;

        if ((r == 10) || (r == 11)) 
        r = 0;

        if (r != parseInt(c[9]))
        return {'cpfError': true}

        s = 0;

        for (i = 1; i <= 10; i++)
        s = s + parseInt(c[i-1]) * (12 - i);

        r = (s * 10) % 11;

        if ((r == 10) || (r == 11)) 
        r = 0;

        if (r != parseInt(c[10]))
        return {'cpfError': true}


        
        return null
    }
}