import { Verba } from './classes/Verba';
import { Nivel } from './classes/Nivel';
import { ErroService } from './../../service/erros/erro.service';
import { LancamentoFinanceiroService } from './lancamento-financeiro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { LancamentoFin } from './classes/LancamentoFin';
import Swal from 'sweetalert';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { ItemExp } from '../experiencia-profissional/experiencia-profissional.component';
import { Router } from '@angular/router';
import { ObservacoesFichaFuncionalComponent } from '../componentes-funcionais/observacoes-ficha-funcional/observacoes-ficha-funcional.component';
import { AnexoComponent } from '../anexo/anexo.component';

@Component({
  selector: 'app-lancamento-financeiro',
  templateUrl: './lancamento-financeiro.component.html',
  styleUrls: ['./lancamento-financeiro.component.css'],
})
export class LancamentoFinanceiroComponent implements OnInit {
  @ViewChild(ObservacoesFichaFuncionalComponent)
  modalObservacoes: ObservacoesFichaFuncionalComponent;
  @ViewChild(AnexoComponent) modalAnexo: AnexoComponent;
  localStorage: LocalStorage;
  formLancamentoFin: FormGroup;
  verbas: any[] = [new Verba()];
  niveis: Nivel[] = [new Nivel()];
  verbasValidas: Verba[] = [new Verba()];
  todasverbas: Verba[] = [new Verba()];
  objetolancamento: any[] = [];

  formTelaInicial: FormGroup;
  lancamentoFin: LancamentoFin;
  competencia = { competencia: '' };
  tipoProcessamento: any;
  matricula: any;
  numord: string;
  situacao: any;
  processamento: any;
  competenciaTela: string = '';
  tam: number;
  filter: any = '';
  nome: any;

  validaTelaInicial: boolean = false;
  editado: boolean = false;
  ativaTransacaoSobreposta: boolean = false;
  ativaNivelReferencia: boolean = false;
  incluido: boolean = true;
  substituido: boolean = false;
  excluido: boolean = false;
  existeColaborador: boolean = false;
  telaFormulario: boolean = false;
  validaLancamentoFin: boolean = false;
  exibeData: boolean = false;
  f07: string;
  modoEdicao: boolean = false;
  codigoTpProc: string = '';

  listaRendimentos: string[] = [
    '1889 - acumulados - Art. 12-A da Lei nº 7.713/1988.',
    '1895 - decorrentes de decisão da Justiça dos Estados, Distrito Federal.',
    '5928 - decorrente de decisão da Justiça Federal.',
    '5936 - decorrente de decisão da Justiça do Trabalho.',
  ];
  listaOrigemProcesso: ItemExp[] = [
    { Codigo: 'A', Descricao: 'Administrativa' },
    { Codigo: 'J', Descricao: 'Judicial' },
  ];

  constructor(
    private fb: FormBuilder,
    private lancamentoFinService: LancamentoFinanceiroService,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService,
    private error: ErroService,
    private route: Router
  ) {
    this.localStorage = this.decryptLocalStorage();

    this.formTelaInicial = this.fb.group({
      matricula: ['', [Validators.required]],
      tipoProcessamento: ['', Validators.compose([Validators.required])],
      competenciaRS: [''],
    });

    const navigation = this.route.getCurrentNavigation();
    if (navigation.extras.state) {
      console.log(navigation);
      this.matricula = navigation.extras.state.usuario.matricula;
      this.formTelaInicial.get('matricula').setValue(this.matricula);
    }
  }
  @HostListener('window:keyup.enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (!this.telaFormulario) {
      this.buscarCompetencia();
    }
  }

  buscarEnter(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.buscarCompetencia();
    }
  }
  ngOnInit() {
    this.buscarTipoProcessamento();
    this.lancamentoFin = new LancamentoFin();
    console.log('lanc fin ng on init', this.lancamentoFin);
    this.formLancamentoFin = this.fb.group({
      nome: [''],
      vinculo: [''],
      verba: ['', Validators.compose([Validators.required])],
      matricula: [''],
      situacao: [''],
      competencia: [''],
      tpProcessamento: [''],
      transacao: ['', Validators.compose([Validators.required])],
      nivel: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      transacaoSobreposta: [
        { value: '', disabled: true },
        Validators.compose([Validators.required]),
      ],
      parcelas: ['', Validators.compose([Validators.required])],
      pagas: [''],
      valor: ['', Validators.compose([Validators.required])],
      simbolo: [''],
      tipoDeReferencia: ['', Validators.compose([Validators.required])],
      devolucao: [''],

      exercicio: [''],
      numProcesso: [''],
      natRendimento: [''],
      percDirMesInicial: [''],
      percInforme: [''],
      qtdMesInforme: [''],
      valorGlobal: [''],
      objetoProcesso: [''],
      origemProcesso: [''],
    });
  }

  atualizarLancamentoFinanceiro() {
    if (this.formLancamentoFin.valid) {
      if (this.f07 == '1') {
        this.lancamentoFin.TemRemuneracaoAnterior = true;
      } else {
        this.lancamentoFin.RRA = null;
      }
      this.lancamentoFin['ParcelasOcorridas'] = this.formLancamentoFin.get('pagas').value;
      this.lancamentoFinService
        .validarLancamento(
          this.lancamentoFin['Parcelas'],
          this.lancamentoFin['ParcelasAnteriores'],
          this.lancamentoFin['ParcelasOcorridas'],
          this.lancamentoFin['ParcelasOcorridasAnteriormente']
        )
        .subscribe(
          (resp) => {
            if (resp) {
              this.lancamentoFinService
                .alterarPagas(
                  this.competencia.competencia,
                  this.processamento,
                  this.lancamentoFin.Matricula,
                  this.lancamentoFin
                )
                .subscribe(
                  (resp) => {
                    Swal('Sucesso!', 'Lancamento Financeiro Alterado Com Sucesso', 'success');
                    this.formLancamentoFin.get('verba').enable();
                    this.buscarLancamentos(this.matricula);
                    this.modoEdicao = false;
                    this.limparCampos();
                    this.cancelar();
                  },
                  (erro) => {
                    this.error.retornoErro(erro);
                  }
                );
            } else {
              Swal({
                title: 'O lançamento está em vigência, deseja alterar?',
                text: 'Selecione uma das opções.',
                icon: 'info',
                dangerMode: false,
                buttons: {
                  cancel: {
                    text: 'Cancelar',
                    value: false,
                    visible: true,
                  },
                  confirm: {
                    text: 'Alterar',
                    value: true,
                    visible: true,
                  },
                },
              }).then(() => {
                this.lancamentoFinService
                  .alterarLancamento(
                    this.competencia.competencia,
                    this.processamento,
                    this.lancamentoFin.Matricula,
                    this.lancamentoFin
                  )
                  .subscribe(
                    (resp) => {
                      Swal('Sucesso!', 'Lancamento Financeiro Alterado Com Sucesso', 'success');
                      this.formLancamentoFin.get('verba').enable();
                      this.buscarLancamentos(this.matricula);
                      this.modoEdicao = false;
                      this.limparCampos();
                      this.cancelar();
                    },
                    (erro) => {
                      this.error.retornoErro(erro);
                    }
                  );
              });
            }
          },
          (erro) => {}
        );
    }
  }
  // Ativa o modo de edição
  editarLancamento(lancamentoDaLista: LancamentoFin) {
    this.modoEdicao = true;
    this.formLancamentoFin.get('verba').disable();
    window.scrollTo(0, 0);
    this.verbas = this.todasverbas;
    this.validaLancamentoFin = false;
    console.log(lancamentoDaLista);
    this.lancamentoFin = { ...lancamentoDaLista };

    this.formLancamentoFin.get('tipoDeReferencia').setValue(this.lancamentoFin.TipoReferencia);

    if (this.lancamentoFin.TipoReferencia === 7) {
      this.formLancamentoFin.get('tipoDeReferencia').disable();
    } else {
      this.formLancamentoFin.get('tipoDeReferencia').enable();
    }

    this.onTipoRefChange();

    setTimeout(() => {
      this.formLancamentoFin.get('verba').setValue(this.lancamentoFin.CodigoVerba);
      if (this.lancamentoFin.TipoReferencia == 1) {
        this.formLancamentoFin
          .get('transacaoSobreposta')
          .setValue(this.lancamentoFin.Referencia.substr(3, 2));
      } else if (this.lancamentoFin.TipoReferencia == 2) {
        this.formLancamentoFin.get('nivel').setValue(this.lancamentoFin.Referencia);
      }
    }, 300);

    this.lancamentoFin.ValorInformado = this.lancamentoFin.ValorInformado.toString().replace(
      ',',
      '.'
    );

    this.substituido = true;
    this.incluido = false;
  }

  limparCampos() {
    this.validaLancamentoFin = false;
    this.lancamentoFin.CodigoVerba = '';
    this.lancamentoFin.Transacao = '';
    this.lancamentoFin.TipoReferencia = undefined;
    this.lancamentoFin.Parcelas = '';
    this.lancamentoFin.ValorInformado = '';
    this.lancamentoFin.Devolucao = '';
    this.lancamentoFin.Referencia = '';
    this.lancamentoFin.CodigoNivel = '';
    this.lancamentoFin.TransacaoSobreposta = '';
    this.lancamentoFin.ParcelasOcorridas = '';
    if (this.lancamentoFin.RRA) {
      this.lancamentoFin.RRA.Exercicio = '';
      this.lancamentoFin.RRA.Numord = 0;
      this.lancamentoFin.RRA.NumProcesso = '';
      this.lancamentoFin.RRA.DtComp = '';
      this.lancamentoFin.RRA.Sigla = '';
      this.lancamentoFin.RRA.TpProc = '';
      this.lancamentoFin.RRA.VRB = 0;
      this.lancamentoFin.RRA.Trans = 0;
      this.lancamentoFin.RRA.NatRendimento = '';
      this.lancamentoFin.RRA.PercDirMesInicial = null;
      this.lancamentoFin.RRA.PercInforme = null;
      this.lancamentoFin.RRA.ValorGlobal = null;
      this.lancamentoFin.RRA.QtdMesInforme = '';
      this.lancamentoFin.RRA.ObjetoProcesso = '';
      this.lancamentoFin.RRA.OrigemProcesso = '';
    }
    this.formLancamentoFin.markAsUntouched();
  }

  cancelar() {
    if (!this.substituido) {
      this.telaFormulario = !this.telaFormulario;
      this.substituido = false;
      this.incluido = true;
      this.formTelaInicial.controls['matricula'].setValue('');
      this.formTelaInicial.controls['tipoProcessamento'].setValue('');
      this.validarProcessamentoRescisao();
      this.objetolancamento = [];
      this.validaTelaInicial = false;
    }
    let element = <HTMLInputElement>document.getElementById('idSearchText');
    element.value = '';
    this.filter = '';
    this.substituido = false;
    this.incluido = true;
    this.ativaTransacaoSobreposta = false;
    this.ativaNivelReferencia = false;
    this.modoEdicao = false;
    this.lancamentoFin = new LancamentoFin();
    this.limparCampos();
    this.formLancamentoFin.get('verba').enable();
  }

  buscarTipoProcessamento() {
    this.spinner.show();
    this.lancamentoFinService.buscarTipoProcessamento().subscribe(
      (data) => {
        this.spinner.hide();
        this.tipoProcessamento = JSON.parse(JSON.stringify(data));
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarCompetencia() {
    this.modalAnexo.matricula = this.formTelaInicial.get('matricula').value;
    this.modalAnexo.buscarApenasNumord();
    this.processamento = this.formTelaInicial.controls['tipoProcessamento'].value;
    if (this.formTelaInicial.invalid) {
      Swal('Atenção!', 'Preencha todos os campos corretamente', 'warning');
      this.validaTelaInicial = true;
    } else {
      if (this.formTelaInicial.controls['tipoProcessamento'].value !== 'RS') {
        this.spinner.show();
        this.lancamentoFinService.buscarTipoCompetencia(this.processamento).subscribe(
          (data) => {
            this.competencia = JSON.parse(JSON.stringify(data));
            this.competenciaTela =
              this.competencia.competencia.substring(4) +
              '/' +
              this.competencia.competencia.substring(0, 4);
            this.spinner.hide();
            this.buscarColaborador();
          },
          (error) => {
            this.spinner.hide();
            this.validaErro.retornoErro(error);
          }
        );
      } else {
        this.competencia.competencia =
          this.formTelaInicial.controls['competenciaRS'].value.substring(2) +
          this.formTelaInicial.controls['competenciaRS'].value.substring(0, 2);
        this.competenciaTela =
          this.formTelaInicial.controls['competenciaRS'].value.substring(0, 2) +
          '/' +
          this.formTelaInicial.controls['competenciaRS'].value.substring(2);
        this.buscarColaborador();
      }
    }
  }

  buscarColaborador() {
    this.spinner.show();
    this.lancamentoFinService
      .buscarColaboradorMatriculaSituacao(
        this.competencia.competencia,
        this.processamento,
        this.formTelaInicial.value.matricula
      )
      .subscribe(
        (data: Cadastro) => {
          // if (this.processamento !== 'RS' && data.ColaboradorRescindido) {
          //   this.formTelaInicial.controls['matricula'].setValue('');
          //   this.formTelaInicial.controls['tipoProcessamento'].setValue('');
          //   this.validarProcessamentoRescisao();
          //   this.spinner.hide();
          //   Swal(
          //     'Atenção!',
          //     'Colaborador encontra-se rescindido, escolha o Tipo de Processamento RS.',
          //     'warning'
          //   );
          // } else if (this.processamento === 'RS' && !data.ColaboradorRescindido) {
          //   this.formTelaInicial.controls['matricula'].setValue('');
          //   this.formTelaInicial.controls['tipoProcessamento'].setValue('');
          //   this.validarProcessamentoRescisao();
          //   this.spinner.hide();
          //   Swal(
          //     'Atenção!',
          //     'Tipo de Processamento RS, só pode ser usado para Colaboradores Rescindidos.',
          //     'warning'
          //   );
          // } else if (
          //   this.processamento === 'RS' &&
          //   data.ColaboradorRescindido &&
          //   !data.PermiteLancamento
          // ) {
          //   this.formTelaInicial.controls['matricula'].setValue('');
          //   this.formTelaInicial.controls['tipoProcessamento'].setValue('');
          //   this.validarProcessamentoRescisao();
          //   this.spinner.hide();
          //   Swal(
          //     'Atenção!',
          //     'Colaborador encontra-se rescindido, porém, sua rescisão encontra-se Calculada, para efetuar novos lançamentos a rescisão deve ser Reaberta ou Cancelada.',
          //     'warning'
          //   );
          // }
          //  else

          if (data != null) {
            this.formLancamentoFin.get('nome').setValue(data['InformacoesBasicas']['NomeCompleto']);
            let vinculo =
              data.InformacoesBasicas.Vinculo.Codigo + ' - ' + data.InformacoesBasicas.Vinculo.Nome;
            this.formLancamentoFin.get('vinculo').setValue(vinculo);
            this.nome = data['InformacoesBasicas']['NomeCompleto'];

            this.lancamentoFinService.verificarTipoProcessamento().subscribe((response: any) => {
              const F19 = response.F[18]; 
              if (data.ColaboradorRescindido && F19 !== "0") {
                  this.processamento = 'RS';
                
              } else if (!data.ColaboradorRescindido || F19 === "0") {
                  this.processamento = this.processamento;
              }
          });
          
          
          
            this.existeColaborador = true;
            this.matricula = data.Matricula;
            this.numord = data.NumeroDeOrdem;
            this.situacao = data.Stiuacao.Descricao;
            this.telaFormulario = !this.telaFormulario;
            this.spinner.hide();
            this.buscarVerbas();
            if (this.matricula.length > 0 && this.matricula != '' && this.matricula.length < 6) {
              this.buscarLancamentos(this.matricula);
            }
          } else {
            this.formTelaInicial.controls['matricula'].setValue('');
            this.formTelaInicial.controls['tipoProcessamento'].setValue('');
            this.validarProcessamentoRescisao();
            this.spinner.hide();
            Swal('Atenção!', 'Matrícula não encontrada  para esta empresa.', 'warning');
          }
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.validaErro.retornoErro(error);
          this.formTelaInicial.controls['matricula'].setValue('');
          this.formTelaInicial.controls['tipoProcessamento'].setValue('');
        }
      );
  }

  validarProcessamentoRescisao(evento?) {
    if (evento) {
      this.codigoTpProc = evento.target.value;
      let processamentoSelecionado = this.tipoProcessamento.find(
        (item) => item.Codigo == this.codigoTpProc
      );
      this.f07 = processamentoSelecionado.F07;
      console.log('f07 agora é ' + this.f07);
      this.adicionarRRA(this.f07);
      console.log('processamento selecionado', processamentoSelecionado);
      console.log('codigo', this.codigoTpProc);
    }

    if (this.formTelaInicial.controls['tipoProcessamento'].value !== 'RS') {
      this.exibeData = false;
      this.formTelaInicial.controls['competenciaRS'].setValue('');
      this.formTelaInicial.get('competenciaRS').clearValidators();
      this.formTelaInicial.get('competenciaRS').updateValueAndValidity();
    } else {
      this.exibeData = true;
      this.formTelaInicial.controls['competenciaRS'].setValue('');
      this.formTelaInicial.get('competenciaRS').setValidators(Validators.required);
      this.formTelaInicial.get('competenciaRS').updateValueAndValidity();
    }
    
  }

  pegarTipoVerba(verba: boolean): string {
    return verba == true ? 'Vantagem' : 'Desconto';
  }

  buscarLancamentos(matricula: number) {
    this.spinner.show();
    this.buscaNiveis();
    this.lancamentoFinService.buscarLancamentos(matricula).subscribe(
      (data) => {
        this.objetolancamento = [];
        const resp = JSON.parse(JSON.stringify(data['Lancamentos']));
        resp.forEach((element) => {
          let procAtual = this.tipoProcessamento.find(
            (item) => item.Codigo == this.formLancamentoFin.get('tpProcessamento').value
          );
          console.log('lanc', element);
          console.log('proc atual', procAtual);
          if (
            parseInt(element.Transacao) >= parseInt(procAtual.TransacaoInicial) &&
            parseInt(element.Transacao) <= parseInt(procAtual.TransacaoFinal)
          ) {
            this.objetolancamento.push(element);
          }
        });
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscaNiveis() {
    this.lancamentoFinService.buscaNiveis(this.competencia.competencia).subscribe(
      (data) => {
        this.niveis = JSON.parse(JSON.stringify(data));
        this.niveis.forEach((nivel) => {
          nivel.CodigoNivel = nivel.CodigoNivel.trim();
        });

        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.validaErro.retornoErro(error);
      }
    );
  }

  buscarVerbas() {
    if (
      this.competencia.competencia === '000000' &&
      this.formTelaInicial.controls['tipoProcessamento'].value !== 'RS'
    ) {
      Swal('Atenção!', 'Não existe competência aberta para este processamento.', 'warning');
      this.formTelaInicial.controls['matricula'].setValue('');
      this.formTelaInicial.controls['tipoProcessamento'].setValue('');
    } else {
      if (this.f07 === '0') {
        console.log('buscou verba f01 0');
        this.spinner.show();
        this.lancamentoFinService.BuscarVerbas().subscribe(
          (data) => {
            this.verbas = JSON.parse(JSON.stringify(data['Verbas']));
            this.todasverbas = JSON.parse(JSON.stringify(data['Verbas']));
            this.verbas = this.retornarVervalidas();
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.validaErro.retornoErro(error);
          }
        );
      } else if (this.f07 === '1') {
        console.log('buscou verba f01 1 BuscarVerbasRRA');
        this.spinner.show();
        this.lancamentoFinService.BuscarVerbasRRA().subscribe(
          (data) => {
            this.verbas = JSON.parse(JSON.stringify(data['Verbas']));
            this.todasverbas = JSON.parse(JSON.stringify(data['Verbas']));
            this.verbas = this.retornarVervalidas();
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.validaErro.retornoErro(error);
          }
        );
      }
    }
    this.spinner.hide();
  }

  ordenarListas(a: any, b: any): number {
    if (a.Nome < b.Nome) {
      return -1;
    } else if (a.Descricao > b.Descricao) {
      return 1;
    } else {
      return 0;
    }
  }

  adicionarRRA(f07: string) {
    let campos = [
      'exercicio',
      'numProcesso',
      'natRendimento',
      'percDirMesInicial',
      'percInforme',
      'qtdMesInforme',
      'valorGlobal',
      'objetoProcesso',
      'origemProcesso',
    ];
    console.log(this.lancamentoFin);
    if (f07 == '1') {
      this.lancamentoFin.TemRemuneracaoAnterior = true;
      campos.forEach((campo) => {
        this.formLancamentoFin.get(campo).setValidators(Validators.required);
        this.formLancamentoFin.get(campo).updateValueAndValidity();
      });
      this.lancamentoFin.RRA = {
        Exercicio: '',
        Numord: 0,
        NumProcesso: '',
        DtComp: '',
        Sigla: '',
        TpProc: '',
        VRB: 0,
        Trans: 0,
        NatRendimento: '',
        PercDirMesInicial: null,
        PercInforme: null,
        QtdMesInforme: '',
        ValorGlobal: null,
        ObjetoProcesso: '',
        OrigemProcesso: '',
      };
      // this.lancamentoFin.RRA.NatRendimento = '';
      // this.lancamentoFin.RRA.OrigemProcesso = '';
      // this.lancamentoFin.RRA.DtComp = '';
      console.log('lanc financeiro add rra', this.lancamentoFin);
    } else {
      this.lancamentoFin.TemRemuneracaoAnterior = false;
      campos.forEach((campo) => {
        this.formLancamentoFin.get(campo).clearValidators();
        this.formLancamentoFin.get(campo).updateValueAndValidity();
      });
      this.lancamentoFin.RRA ? delete this.lancamentoFin.RRA : null;
    }
  }

  async salvarLancamento() {
    if (this.f07 == '1') {
      this.lancamentoFin.RRA.Numord = parseInt(this.numord);
      this.lancamentoFin.RRA.Trans = parseInt(this.lancamentoFin.Transacao);
      this.lancamentoFin.RRA.VRB = parseInt(this.lancamentoFin.CodigoVerba);
      this.lancamentoFin.RRA.Sigla = 'FAPP';
      this.lancamentoFin.RRA.TpProc = this.codigoTpProc;
    }
    console.log('lanc financeiro', this.lancamentoFin);
    this.lancamentoFin.Matricula = this.matricula;
    this.lancamentoFin.Nome = this.nome;
    if (this.lancamentoFin.ParcelasOcorridas == '') {
      this.lancamentoFin.ParcelasOcorridas = 0;
    } else {
      this.lancamentoFin.ParcelasOcorridas = parseInt(this.lancamentoFin.ParcelasOcorridas);
    }
    if (this.formLancamentoFin.invalid) {
      Swal('Atenção!', 'Preencha todos os campos corretamente.', 'warning');
      this.validaLancamentoFin = true;
    } else if (this.verbas.find((vrb) => vrb.Codigo == this.lancamentoFin.CodigoVerba).ErroFisico) {
      console.log(
        this.verbas.find((vrb) => vrb.Codigo == this.lancamentoFin.CodigoVerba).ErroFisico
      );
      Swal('Atenção!', 'Verba Fora da Validade.', 'warning');
    } else if (this.substituido) {
      if (
        this.lancamentoFin.CodigoVerba !== this.lancamentoFin.CodigoVerbaAnterior ||
        this.lancamentoFin.Transacao !== this.lancamentoFin.TransacaoAnterior
      ) {
        this.substituirLancamento();
      } else {
        this.alterarLancamento();
      }
      this.limparCampos();

      this.substituido = false;
      this.incluido = true;
      this.finalizarModificacao;
    } else {
      this.organizarDados();
      this.incluirLancamento();
    }
  }

  MostrarLimpar(): boolean {
    if (
      this.formLancamentoFin.get('transacao').value != '' ||
      this.formLancamentoFin.get('nivel').value != '' ||
      this.formLancamentoFin.get('transacaoSobreposta').value != '' ||
      this.formLancamentoFin.get('parcelas').value != '' ||
      this.formLancamentoFin.get('valor').value != '' ||
      this.formLancamentoFin.get('verba').value != '' ||
      this.formLancamentoFin.get('tipoDeReferencia').value != ''
    ) {
      return true;
    }
    return false;
  }

  onTipoRefChange(): void {
    if (parseInt(this.formLancamentoFin.get('tipoDeReferencia').value) == 1) {
      // this.ativaTransacaoSobreposta = true;
      this.formLancamentoFin.get('transacaoSobreposta').enable();
      this.formLancamentoFin.get('transacaoSobreposta').setValidators([Validators.required]); //, Validators.minLength(3)]);
    } else {
      // this.ativaTransacaoSobreposta = false;
      this.formLancamentoFin.get('transacaoSobreposta').disable();
      this.formLancamentoFin.get('transacaoSobreposta').clearValidators();
      this.lancamentoFin.TransacaoSobreposta = '';
    }
    if (parseInt(this.formLancamentoFin.get('tipoDeReferencia').value) == 2) {
      // this.ativaNivelReferencia = true;
      this.formLancamentoFin.get('nivel').enable();
      this.formLancamentoFin.get('nivel').setValidators([Validators.required]); //, Validators.minLength(3)]);
      this.formLancamentoFin.get('valor').clearValidators();
    } else {
      // this.ativaNivelReferencia = false;
      this.formLancamentoFin.get('nivel').disable();
      this.formLancamentoFin.get('nivel').clearValidators();
      this.lancamentoFin.CodigoNivel = '';
      this.formLancamentoFin.get('valor').setValidators([Validators.required]);
    }
    this.formLancamentoFin.get('transacaoSobreposta').updateValueAndValidity();
    this.formLancamentoFin.get('nivel').updateValueAndValidity();
    this.formLancamentoFin.get('valor').updateValueAndValidity();
    //this.formLancamentoFin.get('tipoDeReferencia').disable();
  }

  substituirLancamento() {
    this.spinner.show();
    this.lancamentoFinService
      .substituirLancamento(
        this.competencia.competencia,
        this.processamento,
        this.lancamentoFin.Matricula,
        this.lancamentoFin
      )
      .subscribe(
        (data) => {
          if (data) {
            Swal('Sucesso!', 'Substituição Realizada Com Sucesso.', 'success');
            this.buscarLancamentos(this.matricula);
            //     this.objetolancamento[this.tam] = this.lancamentoFin
            this.finalizarModificacao();
            this.spinner.hide();
          }
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        }
      );
  }

  alterarLancamento() {
    this.spinner.show();
    this.lancamentoFinService
      .alterarLancamento(
        this.competencia.competencia,
        this.processamento,
        this.lancamentoFin.Matricula,
        this.lancamentoFin
      )
      .subscribe(
        (data) => {
          if (data) {
            Swal('Sucesso!', 'Alteração Realizada Com Sucesso.', 'success');
            //this.objetolancamento[this.tam] = this.lancamentoFin
            this.finalizarModificacao();
            this.buscarLancamentos(this.matricula);
            this.spinner.hide();
          }
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        }
      );
  }

  organizarDados() {
    this.lancamentoFin.Sigla = 'FAPP';
    this.lancamentoFin.Empfil = String(this.localStorage.FilialAtual);
  }

  retornarVervalidas() {
    return this.verbas.filter(function (verbas) {
      return verbas.ErroFisico == false;
    });
  }

  finalizarModificacao() {
    this.lancamentoFin = new LancamentoFin();
    this.formLancamentoFin.markAsUntouched();
    this.tam = undefined;
    this.verbas = this.retornarVervalidas();
    if (!this.excluido) {
    } else {
      this.excluido = !this.excluido;
    }
  }

  incluirLancamento() {
    this.spinner.show();
    this.lancamentoFinService
      .incluirLancamento(
        this.competencia.competencia,
        this.processamento,
        this.lancamentoFin.Matricula,
        this.lancamentoFin
      )
      .subscribe(
        (data) => {
          if (data) {
            Swal('Sucesso!', 'Inclusão Realizada Com Sucesso.', 'success');
            this.limparCampos();
            this.formLancamentoFin.markAsUntouched();
            this.buscarLancamentos(this.matricula);
            this.spinner.hide();
          }
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        }
      );
  }

  removerLancamento(indexLancamento: number, lancamento: LancamentoFin) {
    let texto =
      lancamento.ParcelasOcorridas < lancamento.Parcelas
        ? 'Lançamento ainda vigente, deseja excluir esse lançamento?'
        : 'Deseja excluir esse lançamento?';
    Swal({
      title: texto,
      text: 'Essa ação não pode ser desfeita. Confirme para excluir.',
      icon: 'info',
      dangerMode: false,
      buttons: {
        cancel: {
          text: 'Cancelar',
          value: false,
          visible: true,
        },
        confirm: {
          text: 'Confimar',
          value: true,
          visible: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        this.validaLancamentoFin = false;
        this.tam = indexLancamento;

        let lancamentoExcluir = { ...this.objetolancamento[indexLancamento] };
        this.excluirLancamento(lancamentoExcluir);
      }
    });
  }

  excluirLancamento(lancamentoExcluir) {
    this.spinner.show();
    this.lancamentoFinService
      .excluirLancamento(
        this.competencia.competencia,
        this.processamento,
        lancamentoExcluir.Matricula,
        lancamentoExcluir
      )
      .subscribe(
        (data) => {
          if (data) {
            Swal('Sucesso!', 'Exclusão Realizada Com Sucesso.', 'success');
            this.buscarLancamentos(this.matricula);
            this.finalizarModificacao();
            this.spinner.hide();
          }
        },
        (error) => {
          this.validaErro.retornoErro(error);
          this.spinner.hide();
        }
      );
  }

  LimparCamposForm() {
    this.formLancamentoFin.get('transacao').setValue('');
    this.formLancamentoFin.get('nivel').setValue('');
    this.formLancamentoFin.get('transacaoSobreposta').setValue('');
    this.formLancamentoFin.get('parcelas').setValue('');
    this.formLancamentoFin.get('valor').setValue('');
    this.formLancamentoFin.get('verba').setValue('');
    this.formLancamentoFin.get('tipoDeReferencia').setValue('');
  }

  verificaTransacaoValida() {
    let processamentoAuxiliar = this.tipoProcessamento.find(
      (processamentoEscolhido) => processamentoEscolhido.Codigo == this.processamento
    );
    console.log('processamento auxiliar', processamentoAuxiliar);
    console.log('tipo proc', this.tipoProcessamento);
    console.log('lançamento fin', this.lancamentoFin);
    if (
      parseInt(this.lancamentoFin.Transacao) > parseInt(processamentoAuxiliar.TransacaoFinal) ||
      parseInt(this.lancamentoFin.Transacao) < parseInt(processamentoAuxiliar.TransacaoInicial)
    ) {
      if (
        parseInt(processamentoAuxiliar.TransacaoFinal) ===
        parseInt(processamentoAuxiliar.TransacaoInicial)
      ) {
        Swal(
          'Atenção!',
          'Transação não pode ser diferente de ' + processamentoAuxiliar.TransacaoFinal,
          'warning'
        );
      } else {
        Swal(
          'Atenção!',
          'Código de transação inválido. A transação deve estar entre ' +
            processamentoAuxiliar.TransacaoInicial +
            ' e ' +
            processamentoAuxiliar.TransacaoFinal +
            '.',
          'warning'
        );
      }
      this.formLancamentoFin.controls['transacao'].setValue('');
    }
  }
  verificaTransacaoSobreposta(evento) {
    console.log(evento.target.value);
    let processamentoAuxiliar = this.tipoProcessamento.find(
      (processamentoEscolhido) => processamentoEscolhido.Codigo == this.processamento
    );
    if (
      this.formLancamentoFin.get('tpProcessamento').value == 'RS' &&
      Number(evento.target.value) !== 30
    ) {
      Swal('Atenção!', 'Código de transação inválido. Não pode ser diferente de 90.', 'warning');
    } else if (
      Number(evento.target.value) < parseInt(processamentoAuxiliar.TransacaoInicial) ||
      Number(evento.target.value) > parseInt(processamentoAuxiliar.TransacaoFinal)
    ) {
      if (
        parseInt(processamentoAuxiliar.TransacaoInicial) ==
        parseInt(processamentoAuxiliar.TransacaoFinal)
      ) {
        Swal(
          'Atenção!',
          'Transação não pode ser diferente de ' + processamentoAuxiliar.TransacaoFinal,
          'warning'
        );
      } else {
        Swal(
          'Atenção!',
          'Código de transação inválido. Deve estar entre ' +
            processamentoAuxiliar.TransacaoInicial +
            ' e ' +
            processamentoAuxiliar.TransacaoFinal +
            '.',
          'warning'
        );
        this.formLancamentoFin.get('transacaoSobreposta').setValue('');
      }
    }
  }
  somenteNumero(event) {
    return event.charCode >= 48 && event.charCode <= 57;
  }
  limitePorcentagem(variavel) {
    if (variavel == 'PercDirMesInicial') {
      if (this.lancamentoFin.RRA.PercDirMesInicial > 100) {
        this.lancamentoFin.RRA.PercDirMesInicial = 100;
      }
    }
    if (variavel == 'PercInforme') {
      if (this.lancamentoFin.RRA.PercInforme > 100) {
        this.lancamentoFin.RRA.PercInforme = 100;
      }
    }
    if (variavel == 'ValorGlobal') {
      if (this.lancamentoFin.RRA.ValorGlobal > 100) {
        this.lancamentoFin.RRA.ValorGlobal = 100;
      }
    }
  }

  retornarTelaInicial() {
    this.formTelaInicial.get('matricula').setValue('');
    this.formTelaInicial.get('tipoProcessamento').setValue('');

    this.formTelaInicial.reset();
    this.telaFormulario = false;
    this.formLancamentoFin.get('verba').enable();
    this.telaFormulario = !this.telaFormulario;
    this.cancelar();
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}
