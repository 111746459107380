import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AcessoService } from '../../service/acesso/acesso.service';

@Injectable({
  providedIn: 'root'
})
export class ContinuacaoCadastroService {
  url_acesso = this.acesso.validarUrl();
  headers = new HttpHeaders();
  filial: string;
  codTarefa: string;
  tpProcessamento: string;
  rotaParametro: string;// = `precadastroparametros/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`;
  admUser: string;
  

  salt = "";
  numord = "";
  constructor(private http: HttpClient, private acesso: AcessoService) {}

  verificarSalt(numOrd:string, salt: string, filial:string, codTarefa:string, tpProcessamento: string, admUser: string){
    this.filial = filial;
    this.codTarefa = codTarefa;
    this.tpProcessamento = tpProcessamento;
    this.rotaParametro = `${this.url_acesso}precadastroparametros/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`
    this.headers = this.headers.set('salt', salt);
    this.headers = this.headers.set('numord', numOrd);
    this.headers = this.headers.set('admUser', admUser);

    // return this.http.get(this.url_acesso + `cadastroconfirmalink/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`, {headers: this.headers});
    return this.http.get(this.url_acesso + `cadastroconfirmalink/${this.codTarefa}/${this.tpProcessamento}/${this.filial}/0`, {headers: this.headers});
  }


  buscarEscolaridade() {
    return this.http.get(`${this.rotaParametro}/grau_instrucao`,{headers: this.headers});
  }

  buscarNacionalidade() {
    return this.http.get(`${this.rotaParametro}/nacionalidade`,{headers: this.headers});
  }
 
  buscarCor() {
    return this.http.get(`${this.rotaParametro}/cor_pele`,{headers: this.headers});
  }

  buscarSexo() {
    return this.http.get(`${this.rotaParametro}/sexo`,{headers: this.headers});
  }

  buscarEstadoCivil() {
    return this.http.get(`${this.rotaParametro}/estado_civil`,{headers: this.headers});
  }

  buscarCep(cep) {
    return this.http.get(`${this.url_acesso}precadastropesquisacep/${this.codTarefa}/${this.tpProcessamento}/${this.filial}/${cep}`,{headers: this.headers});
  }

  buscarEstado() {
    return this.http.get(`${this.url_acesso}precadastroestados/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`,{headers: this.headers});
  }

  buscarAgencia(codigoAgencia): any{
    return this.http.get(`${this.url_acesso}precadastroagencias/${this.codTarefa}/${this.tpProcessamento}/${this.filial}/${codigoAgencia}`, { headers: this.headers });
  }

  buscarCidade(codigo) {
    return this.http.get(`${this.url_acesso}precadastromunicipios/${this.codTarefa}/${this.tpProcessamento}/${this.filial}/${codigo}` ,{headers: this.headers});
  }

  buscarCategoriaCnh() {
    return this.http.get(`${this.rotaParametro}/cnh`,{headers: this.headers});
  }
  buscarBanco() {
    return this.http.get(`${this.url_acesso}precadastrobancos/${this.codTarefa}/${this.tpProcessamento}/${this.filial}`,{headers: this.headers});
  }

  updateEtapa(dados) {
    this.url_acesso = this.acesso.validarUrl();
    return this.http.put(`${this.url_acesso}precadastro/${this.codTarefa}/${this.tpProcessamento}/${this.filial}/`,dados,{headers: this.headers});
  }
}
