import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AcessoService } from '../../../service/acesso/acesso.service';
import {HttpHeaders, HttpClientModule ,} from '@angular/common/http';
import { SenhaAlteracao } from './classes/SenhaAlteracao';

@Injectable({
  providedIn: 'root'
})
export class AlterarSenhaService {


  headers = new HttpHeaders();
  url_acesso = this.acesso.validarUrl();

  constructor(private http: HttpClient, private acesso: AcessoService) { }

  buscarIp() {
    return this.http.get('https://api.ipify.org?format=json');
  }

  validarLink(link){
    return this.http.post(`${this.url_acesso}usuario/recuperar-senha/validar-link/${link}`, null, {headers: this.headers});
  }

  alterarSenha(senhas: SenhaAlteracao, numOrd: string)
  {
    return this.http.post(`${this.url_acesso}usuario/recuperar-senha/alteracao/${numOrd}`, senhas, {headers: this.headers});
  }
}