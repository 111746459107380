import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AcessoService } from 'src/app/service/acesso/acesso.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { NomeSocial } from './nome-social.component';

@Injectable({
  providedIn: 'root',
})
export class NomeSocialService {
  localStorage: LocalStorage = new LocalStorage();
  headers = new HttpHeaders();
  cabecalho: any;
  filial: string;

  constructor(private http: HttpClient, private acesso: AcessoService) {
    if (localStorage.getItem('util') != null) {
      this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
      this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
      this.filial = this.localStorage.FilialAtual.toString();
    }
  }
  url_acesso: any = this.acesso.validarUrl();

  cadastrarNomeSocial(nomeSocial: NomeSocial) {
    return this.http.post(
      `${this.url_acesso}incluirNomeSocial/000000000/EM/${this.filial}`,
      nomeSocial,
      { headers: this.cabecalho }
    );
  }

  atualizarNomeSocial(nomeSocial: NomeSocial) {
    return this.http.put(
      `${this.url_acesso}AtualizaNomeSocial/000000000/EM/${this.filial}`,
      nomeSocial,
      { headers: this.cabecalho }
    );
  }

  buscarListaProcessos(numeroOrdem: string) {
    return this.http.get(
      `${this.url_acesso}nome/social/processos/000000000/EM/${this.filial}/${numeroOrdem}`,
      { headers: this.cabecalho }
    );
  }

  buscarNomeSocial(numeroOrdem: string) {
    return this.http.get(
      `${this.url_acesso}nome/social-crud/000000000/EM/${this.filial}/${numeroOrdem}`,
      { headers: this.cabecalho }
    );
  }
}
