<div>
  <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" (click)="buscarFerias()"
    data-target="#exampleModalCenter" style="position: relative; top: -2.7px;">
    Férias por Setor
  </button>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header navbar-light bg-light">
        <div class="container" style="margin-bottom: -70px; padding-bottom: -70px;">
          <div class="row">
            <div class="col-6">
              <h5 class="justify-content-start mt-0.1">Férias por Setor</h5>
            </div>
            <div *ngIf="listaRelatorioFerias.length != 0" class="col-1 justify-content-end">
              <label class="font-weight-semibold mt-4 align-center">Setor</label>
            </div>
            <div class=" mt-2 col-4">
              <select *ngIf="listaRelatorioFerias.length != 0" (change)="MontarListaSetores()"
                class="form-control mt-2 mr-3" name="setor" value="" [(ngModel)]="filtroPesquisa">
                <option value="">Todos</option>
                <option *ngFor="let setor of listaSetorDropDown" value="{{setor.CodigoSetor}}"> {{setor.CodigoSetor}} -
                  {{setor.NomeSetor}} </option>
              </select>
            </div>
            <button _ngcontent-nbj-c13="" aria-label="Close" class="mb-4 close" data-dismiss="modal" type="button"><span
                _ngcontent-nbj-c13="" aria-hidden="true">×</span></button>
          </div>
          <br>
        </div>
      </div>

      <div style="margin: 15px;">
        <form [formGroup]="formFeriasSetor">
          <div class="row">
            <div class="col-12" style="text-align: center;">
              <span style="font-size: 1.5rem"><b>Dados Férias</b></span>
            </div>
          </div>
          <div class="row" style=" margin-top: 10px !important;">
            <div class="col-md-4">
              <label>Adiantar 13º?</label>
              <select formControlName="adiantarDecimoTerceiro">
                <option value="">Selecione...</option>
                <option value="false">Não</option>
                <option value="true">Sim</option>
              </select>
            </div>

            <div class="col-md-4">
              <label>Parcelar Férias?</label>
              <select formControlName="parcelarFerias">
                <option value="">Selecione...</option>
                <option value="false">Não</option>
                <option value="true">Sim</option>
              </select>
            </div>

            <div class="col-md-4">
              <label>Vender 10 Dias?</label>
              <select formControlName="venderDezDias">
                <option value="">Selecione...</option>
                <option value="false">Não</option>
                <option value="true">Sim</option>
              </select>
            </div>
          </div>

          <div class="row" style=" margin-top: 10px !important;">
            <div class="col-md-4">
              <label>Data Início</label>
              <input type="date" formControlName="dataInicio" placeholder="dd/mm/yyyy" max="9999-12-31"
                (change)="calcularDiasDeFerias()">
            </div>

            <div class="col-md-4">
              <label>Data Fim</label>
              <input type="date" formControlName="dataFim" placeholder="dd/mm/yyyy" max="9999-12-31"
                (change)="calcularDiasDeFerias()">
            </div>

            <div class="col-md-4">
              <label>Dias de Férias</label>
              <input type="text" formControlName="diasDeFerias" placeholder="Dias" readonly>
            </div>
          </div>
        </form>
      </div>

      <div class="row">
        <div class="col-md-12" style="display: flex; justify-content: flex-end;">
            <div style="margin-right: 25px;"> 
                <b>
                    <label for="selecionarTodos">Selecionar todos</label>
                    <input type="checkbox" name="selecionarTodos" style="position: relative; right: -7px; top: 2.5px;" class="check_form" id="checkboxHtml" (change)="selecionarTodosToogle()" data-checado="false">
                </b>
            </div>
        </div>
    </div>
    
      <hr style="width: 100%;">

      <div class="modal-body navbar-light">
        <div class="container">
          <div class="col-12">
            <div class="row form-group">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Seleção</th>
                    <th>Nome do Setor</th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let setor of empresaFilialFerias">
                    <td>
                      <input type="checkbox" id="{{setor['Nome']}}" class="checkboxEmpfilClass"
                        (click)="verificarSelecao($event, setor)">
                    </td>
                    <td>{{setor['Nome']}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>




      <div class="modal-footer navbar-light bg-light text-right">
        <div class="container">
          <button (click)="ativarFerias()" class="btn bg-indigo-300 text-center">Ativar férias</button>
        </div>
      </div>

    </div>
  </div>
</div>