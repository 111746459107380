import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CadastroPendenteService } from "./cadastro-pendente.service";
import { CadastroService } from "../cadastro/cadastro.service";
import { ErroService } from '../../service/erros/erro.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Cadastro } from '../../service/classes/cadastro/cadastro'
import { ActivatedRoute } from '@angular/router';
import { UploadComponent } from '../upload/upload.component';
import { Router } from '@angular/router';
import { Validacao } from '../../service/global/validacao/validacao.service';
import { distinct } from 'rxjs/operators'
import Swal from 'sweetalert';
import { pisValidator } from '../../service/Validators/pisValidator'
import { cpfValidator } from '../../service/Validators/cpfValidator'
import { dataValidator } from 'src/app/service/Validators/dataValidator';
import { AnexoComponent } from '../anexo/anexo.component';
import { ParametrosComponent } from '../parametros/parametros.component';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from '../componentes-funcionais/alerta-modal/alerta-modal.component';

@Component({
  selector: 'app-cadastro-pendente',
  templateUrl: './cadastro-pendente.component.html',
  styleUrls: ['./cadastro-pendente.component.css']
})
export class CadastroPendenteComponent implements OnInit {
  @ViewChild(AnexoComponent, { static: false }) anexoComponent: AnexoComponent;
  @ViewChild(ParametrosComponent, { static: false }) parametrosComponent: ParametrosComponent;
  // @ViewChild(UploadComponent,  {static: false} ) upload: UploadComponent;

  public step: number = 0;
  public stepcount: number = 0;
  localStorage: LocalStorage;
  urls: Array<string> = [];
  imageURL: string;
  uploadForm: FormGroup;
  cadastro = new Cadastro();
  validaEtapa1: boolean = false;
  validaEtapa2: boolean = false;
  validaEtapa3: boolean = false;
  validaEtapa4: boolean = false;

  indicadoresCalculo: any;

  //Variáveis do dos forms
  formInformacaoBasica: FormGroup;
  formDadosPessoais: any;
  formDocumentos: any;
  tipoFolha: any[] = []
  idadeMinima: number = 14

  //Variáveis dos selects da etapa 1
  vinculo: any[] = [];
  diasUteis: any[]
  departamento: any[] = [];
  setores: any[] = [];
  cargos: any[] = [];
  horaTrabalho: any[] = [];
  cargaHoraria: any[] = [];
  sindicatos: any[] = [];
  nivel: any[] = [];

  //Variáveis dos selects da etapa 2
  escolaridade: any[] = [];
  nacionalidade: any[] = [];
  cor: any[] = [];
  sexo: any[] = [];
  estadoCivil: any[] = [];
  estado: any[] = [];
  cidade: any[] = [];

  //Variáveis dos selects da etapa 3
  ufRg: any[] = [];
  categoriaCnh: any[] = [];
  ufCtps: any[] = [];
  banco: any[] = [];
  ufCnh: any[] = [];

  //Variaveis do filter
  cargaHorariaFilter: any[] = [];
  horarioTrabalhoFilter: any[] = [];
  horarioTrabalho: any;
  retornoHorario: any;

  //loadings dos campos
  carrega_setor: boolean = false;
  carrega_cargo: boolean = false;
  carrega_hora_trabalho: boolean = false;
  carrega_nivel: boolean = false;
  carrega_cep: boolean = false;
  boolMatricula: boolean = false;

  //Variáveis e retorno
  retorno_usuario = { NumeroDeOrdem: '', Matricula: '' };
  cep = { Bairro: "", Cep: "", Cidade: "", Complemento: "", Logradouro: "", Numero: "", Uf: "", Valido: "", bairro: "", cidade: "", complemento: "", logradouro: "", uf: "" };
  codigo_uf: any;
  salario_nivel: string;
  validaCadastro: boolean = false; // verifica se o usuário já passou pela etapa do cadastro
  telefone_form: string;
  celular_form: string;
  filial!: number;
  //
  validar_cpf_blur: boolean = false;
  validar_pis_blur: boolean = false;
  //
  filtroTeclas: any;
  rotaParametro: any;

  //JSON da primeira etapa
  cadastro_etapa_1 = {
    DiasUteisColaborador:'',
    IsCadastro: false,
    NumeroDeOrdem: "",
    Etapa: "",
    Matricula: "",
    Filial: {
      Codigo: this.filial
    },
    Documentos: {
      Cpf: ""
    },
    InformacoesBasicas: {
      NomeCompleto: "",
      Departamento: {
        Cod: ""
      },
      Setor: {
        Cod: ""
      },
      TipoFolha: "",
      DataAdmissao: {
        SetData: "",
        ToDate: "",
        IsDate: "",
        Empty: "",
      },
      Vinculo: {
        Codigo: ""
      },
      Cargo: {
        Codigo: "",
        CBO: 0
      },
      Salario: {
        CodigoNivel: "",
        Valor: ""
      },
      HorarioTrabalho: {
        Codigo: "",
        CargaHorariaSemana: 0,
        HoraEntrada: 0,
        HoraSaida: 0,
        InicioIntervalo: 0,
        TerminoIntervalo: 0
      },
      Sindicato: {
        Codigo: ""
      }
    }
  };

  cadastro_etapa_2 = {
    NumeroDeOrdem: "",
    Etapa: 2,
    Matricula: "",
    Filial: {
      Codigo: this.filial
    },
    Pessoal: {
      DadosBasicos: {
        DataNascimento: {
          SetData: "",
          ToDate: "",
          IsDate: true,
          Empty: false
        },
        GrauDeInstrucao: {
          Valor: 0
        },
        Nacionalidade: {
          Codigo: 0
        },
        Etnia: {
          Descricao: ""
        },
        Sexo: {
          Valor: 0
        },
        EstadoCivil: {
          Valor: 0
        },
        NomeMae: "",
        NomePai: ""
      },
      Endereco: {
        Cep: "",
        Logradouro: "",
        Numero: "",
        Bairro: "",
        Estado: "",
        Cidade: ""
      },
      Contato: {
        DDDCelular: "",
        Celular: "",
        DDDTelefone: "",
        Telefone: "",
        Email1: "",
        Email2: ""
      }
    }
  }

  // cadastro_etapa_3 = {
  //   NumeroDeOrdem: "" ,
  //   Etapa: 3,
  //   Matricula: "",
  //   Filial: {
  //       Codigo: 0
  //   },
  //   Documentos: {
  //       Cpf: "",
  //       Rg: {
  //           Numero: "",
  //           DataEmissaoRg: {
  //               SetData: "",
  //               ToDate: "",
  //               IsDate: false,
  //               Empty: true
  //           },
  //           UfEmissaoRg: ""
  //       },
  //       Cnh: {
  //           Numero: "",
  //           CategoriaCnh: "",
  //           DataExpedicaoCnh: {
  //               SetData: "",
  //               ToDate: "",
  //               IsDate: false,
  //               Empty: true
  //           },
  //           DataValidadeCnh: {
  //               SetData: "",
  //               ToDate: "",
  //               IsDate: false,
  //               Empty: true
  //           }
  //       },
  //       Clt: {
  //           CarteiraDeTrabalho: "",
  //           NumeroSerieCtps: "",
  //           Pis: "",
  //           UfCtps:""
  //       },
  //       TituloEleitor: {
  //           Numero: "",
  //           ZonaEleitoral: "",
  //           SecaoEleitoral: ""
  //       }
  //   },
  //   DadosBancarios: {
  //       Banco: "",
  //       Agencia: "",
  //       Operacao: "",
  //       Conta: ""
  //   }
  // }


  cadastro_etapa_3 = {
    NumeroDeOrdem: "",
    Etapa: 3,
    Matricula: "",
    Filial: {
      Codigo: this.filial
    },
    Documentos: {
      Rg: {
        Numero: "",
        DataEmissaoRg: {
          SetData: "",
          ToDate: "",
          IsDate: false,
          Empty: true
        },
        OrgaoEmissor: "",
        UfEmissaoRg: ""
      },
      Cnh: {
        Numero: "",
        CategoriaCnh: "",
        UF: "",
        DataValidadeCnh: {
          SetData: "",
          ToDate: "",
          IsDate: false,
          Empty: true
        }
      },
      Clt: {
        CarteiraDeTrabalho: "",
        NumeroSerieCtps: "",
        Pis: "",
        UfCtps: ""
      },
      TituloEleitor: {
        Numero: "",
        ZonaEleitoral: "",
        SecaoEleitoral: ""
      },
      UploadDocumentos: []
    },
    DadosBancarios: {
      Banco: "",
      Agencia: "",
      Operacao: "",
      Conta: ""
    },
  }

  constructor(public fb: FormBuilder, private cadastroService: CadastroService, private cadastroPendenteService: CadastroPendenteService, private validaErro: ErroService, private spinner: NgxSpinnerService, private route: ActivatedRoute, private router: Router, private validacaoService: Validacao) {
    // Reactive Form
    this.route.params.subscribe(params => this.rotaParametro = params['numOrdem']);
  }


  // Os métodos de busca e alterações do cadastro estão no service do cadastro, o service do cadastro pendente é para métodos específicos do pendente.

  ngOnInit() {
    this.localStorage = this.decryptLocalStorage();
    localStorage.setItem("tipoTela", new Crypto().encryptUsingAES256(JSON.stringify("017")));//infromar qual a tela para o upload de arquivos
    this.filial = this.localStorage.FilialAtual;
    window.scrollTo(0, 0);
    this.spinner.show();
    this.buscarCadastro(this.rotaParametro);

    this.formInformacaoBasica = this.fb.group({
      diasUteis: [],
      matricula: [],
      avatar: [null],
      cpf_form: ['', Validators.compose([Validators.required, cpfValidator])],
      nome_completo: ['', Validators.compose([Validators.required, Validators.minLength(12), Validators.maxLength(80)])],
      departamento_form: ['', Validators.compose([Validators.required])],
      data_admissao_form: ['', Validators.compose([Validators.required])],
      setor_form: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      cargo_form: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      vinculo_form: ['', Validators.compose([Validators.required])],
      tipoFolha_form: ['', Validators.compose([Validators.required])],
      cargaH_form: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      horaT_form: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      nivel_form: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      sindicato_form: ['', Validators.compose([Validators.required])]
    });

    this.formDadosPessoais = this.fb.group({
      data_nascimento_form: ['', Validators.compose([Validators.required])],
      escolaridade_form: ['', Validators.compose([Validators.required])],
      nacionalidade_form: ['', Validators.compose([Validators.required])],
      cor_form: ['', Validators.compose([Validators.required])],
      sexo_form: ['', Validators.compose([Validators.required])],
      estado_civil_form: ['', Validators.compose([Validators.required])],
      mae_form: ['', Validators.compose([Validators.required])],
      pai_form: [''],
      complemento_form: [''],
      cep_form: ['', Validators.compose([Validators.required])],
      logradouro_form: ['', Validators.compose([Validators.required])],
      numero_form: ['', Validators.compose([Validators.required])],
      bairro_form: ['', Validators.compose([Validators.required])],
      estado_form: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      cidade_form: [{ value: '', disabled: true }, Validators.compose([Validators.required])],
      telefone_form: [''],
      celular_form: ['', Validators.compose([Validators.required])],
      email_form: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(50)])],
      email2_form: ['', Validators.compose([Validators.email, Validators.maxLength(50)])]
    });

    this.formDocumentos = this.fb.group({
      // cpf_form: ['', Validators.compose([Validators.required, cpfValidator])],
      rg_form: ['', Validators.compose([Validators.required])],
      data_emissao_form: ['', Validators.compose([Validators.required])],
      orgao_form: ['', Validators.compose([Validators.required])],
      uf_emissor_form: ['', Validators.compose([Validators.required])],
      cnh_form: ['', Validators.compose([Validators.minLength(11)])],
      categoria_cnh_form: [''],
      uf_cnh_form: [''],
      data_validade_form: [''],
      carteira_form: ['', Validators.compose([Validators.required])],
      serie_form: ['', Validators.compose([Validators.required])],
      // data_emissao_ctps_form: ['',  Validators.compose([ Validators.required])],
      uf_ctps_form: ['', Validators.compose([Validators.required])],
      pis_form: ['', Validators.compose([pisValidator, Validators.required])],
      titulo_form: ['', Validators.compose([Validators.required])],
      zona_form: ['', Validators.compose([Validators.required])],
      secao_form: ['', Validators.compose([Validators.required])],
      banco_form: ['', Validators.compose([Validators.required])],
      agencia_form: ['', Validators.compose([Validators.required])],
      operacao_form: ['', Validators.compose([Validators.required])],
      conta_form: ['', Validators.compose([Validators.required])]
    });

  }

  modelChanged(event: any) {
    const cnh = this.formDocumentos.get("cnh_form").value;
    const categoria = this.formDocumentos.get("categoria_cnh_form").value;
    const uf = this.formDocumentos.get("uf_cnh_form").value;
    const data = this.formDocumentos.get("data_validade_form").value;

    if (cnh == "" && categoria == "" && uf == "" && data == "") {

      this.formDocumentos.get("cnh_form").setValidators([Validators.minLength(11)]);
      this.formDocumentos.get("categoria_cnh_form").setValidators(null);
      this.formDocumentos.get("uf_cnh_form").setValidators(null);
      this.formDocumentos.get("data_validade_form").setValidators(null);
    } else {
      this.formDocumentos.get("cnh_form").setValidators([Validators.required, Validators.minLength(11)]);
      this.formDocumentos.get("categoria_cnh_form").setValidators([Validators.required]);
      this.formDocumentos.get("uf_cnh_form").setValidators([Validators.required]);
      this.formDocumentos.get("data_validade_form").setValidators([Validators.required]);
    }
    this.formDocumentos.get("cnh_form").updateValueAndValidity();
    this.formDocumentos.get("categoria_cnh_form").updateValueAndValidity();
    this.formDocumentos.get("uf_cnh_form").updateValueAndValidity();
    this.formDocumentos.get("data_validade_form").updateValueAndValidity();

  }

  mostrarIcon: Boolean = false;
  conferenciaCheckbox: boolean = false;
  assinaturaCheckbox: boolean = false;
  exameCheckbox: boolean = false;

  clickStep: boolean = false;


  // Lista os cadastro pendente do usuário
  buscarDiasUteis() {
    this.cadastroService.buscarDiasUteisColaborador().subscribe(
      (data: any[]) => {
        this.diasUteis = data;
      },
      erro => {
        this.validaErro.retornoErro(erro);
      }
    )
  }
  buscarCadastro(numOrdem) {
    this.cadastroService.buscarCadastro(numOrdem)
      .subscribe(
        data => {
          //this.spinner.hide();
          this.buscarDiasUteis();
          this.cadastro = JSON.parse(JSON.stringify(data));
          this.corrigeDatas();
          if (this.cadastro.Etapa == 3) {
            this.spinner.hide();
            new AlertaModal("alerta", "Atenção!", "O colaborador selecionado não possui cadastro pendente!", "p");
            this.router.navigate(["colaborador"]);
          } else {
            this.validaCadastro = true;
            this.buscarDepartamento();
            this.formInformacaoBasica.get("matricula").disable()
            this.formInformacaoBasica.get("cpf_form").disable()
            this.formInformacaoBasica.get("diasUteis").setValue(this.cadastro.InformacoesBasicas.DiasUteisColaborador)
            // this.formInformacaoBasica.get("diasUteis").setValue(this.cadastro.)
            this.formInformacaoBasica.get("matricula").setValue(this.cadastro.Matricula);
            this.cadastro_etapa_1.DiasUteisColaborador = this.cadastro.InformacoesBasicas.DiasUteisColaborador;
            this.cadastro_etapa_1.Documentos.Cpf = this.cadastro.Documentos.Cpf;
            this.cadastro_etapa_1.NumeroDeOrdem = this.cadastro.NumeroDeOrdem;
            this.cadastro_etapa_1.Matricula = this.cadastro.Matricula;
            this.cadastro.InformacoesBasicas.Cargo.Codigo = this.cadastro.InformacoesBasicas.Cargo.Codigo.trim();
            this.cadastro_etapa_2.NumeroDeOrdem = this.cadastro.NumeroDeOrdem;
            this.cadastro_etapa_2.Matricula = this.cadastro.Matricula;

            this.cadastro_etapa_3.NumeroDeOrdem = this.cadastro.NumeroDeOrdem;
            this.cadastro_etapa_3.Matricula = this.cadastro.Matricula;
            //
            this.step = this.cadastro.Etapa;
            this.stepcount = this.cadastro.Etapa;
            //
            localStorage.setItem("matricula", new Crypto().encryptUsingAES256(JSON.stringify(this.cadastro.Matricula)));
            //
            this.formInformacaoBasica.get('setor_form').enable();
            this.formInformacaoBasica.get('cargaH_form').enable();
            this.formInformacaoBasica.get('cargo_form').enable();
            this.formInformacaoBasica.get('horaT_form').enable();
            this.formInformacaoBasica.get('nivel_form').enable();
            //
            this.formDadosPessoais.get('estado_form').enable();
            this.formDadosPessoais.get('cidade_form').enable();
            //
            this.validarEtapa();
            this.validarTelefonesPendente();
            this.buscarTipoFolha()
            this.habilitarHoraTrabalho(this.cadastro.InformacoesBasicas.HorarioTrabalho.CargaHorariaSemana, 1);
          }
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  validarEtapa() {
    if (this.step == 1) {
      this.cadastro.Pessoal.DadosBasicos.DataNascimento.ToDate = "";
      this.cadastro.Pessoal.DadosBasicos.GrauDeInstrucao.Valor = "";
      this.cadastro.Pessoal.DadosBasicos.Nacionalidade.Codigo = "";
      this.cadastro.Pessoal.DadosBasicos.Etnia.Descricao = "";
      this.cadastro.Pessoal.DadosBasicos.Sexo.Valor = "";
      this.cadastro.Pessoal.DadosBasicos.EstadoCivil.Valor = "";
      this.cadastro.Pessoal.DadosBasicos.NomeMae = "";
      this.cadastro.Pessoal.DadosBasicos.NomePai = "";
      this.cadastro.Pessoal.Endereco.Cep = "";
      this.cadastro.Pessoal.Endereco.Logradouro = "";
      this.cadastro.Pessoal.Endereco.Numero = "";
      this.cadastro.Pessoal.Endereco.Bairro = "";
      this.cadastro.Pessoal.Endereco.Estado = "";
      this.cadastro.Pessoal.Endereco.Cidade = "";
      this.cadastro.Pessoal.Contato.Email1 = "";
      this.cadastro.Pessoal.Contato.Email2 = "";

      this.formDadosPessoais.get('estado_form').disable();
      this.formDadosPessoais.get('cidade_form').disable();
      this.formDadosPessoais.get('numero_form').enable();

    }
  }

  abiriModalIndicadores(): void {
    this.parametrosComponent.abrirModal();
  }

  validarTelefonesPendente() {
    if (this.cadastro.Pessoal.Contato.DDDTelefone.trim() != "" && (this.cadastro.Pessoal.Contato.Telefone.trim() != "" && this.cadastro.Pessoal.Contato.Telefone.trim() != "NÃO INFORMADO")) {
      this.telefone_form = this.cadastro.Pessoal.Contato.DDDTelefone.trim() + this.cadastro.Pessoal.Contato.Telefone.trim();
    } else {
      this.telefone_form = "";
    }

    if ((this.cadastro.Pessoal.Contato.DDDCelular.trim() != "" && this.cadastro.Pessoal.Contato.DDDCelular.trim() != "0") && (this.cadastro.Pessoal.Contato.Celular.trim() != "" && this.cadastro.Pessoal.Contato.Celular.length == 9)) {
      this.celular_form = this.cadastro.Pessoal.Contato.DDDCelular.trim() + this.cadastro.Pessoal.Contato.Celular;
    } else {
      this.celular_form = "";
    }
  }

  buscarTipoFolha() {
    this.spinner.show()
    this.cadastroService.buscarTipoFolha()
      .subscribe(
        data => {
          this.spinner.hide();
          this.tipoFolha = JSON.parse(JSON.stringify(data))

        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      )
  }

  async verificarIndicadoresCalculo(): Promise<boolean> {
    const flags = this.parametrosComponent.retornarValores();
    let flagSelecionada: boolean = false;

    Object.entries(flags).forEach((flags: any[]) => {
      if (flags[1] !== '0') flagSelecionada = true;
    });

    if (!flagSelecionada) {
      const abrirModal: boolean = await Swal({
        title: 'Atenção ',
        text: "Deseja enviar o cadastro sem indicadores de cálculo?",
        icon: 'warning',
        closeOnClickOutside: false,
        closeOnEsc: false,
        buttons: ['Sim', 'Não'],
      });
      if (abrirModal) {
        this.step--;
        this.stepcount--;
        this.parametrosComponent.abrirModal();
      } else {
        this.indicadoresCalculo = { ...this.parametrosComponent.retornarValores() }
        this.insertInformacaoBasica();
      }
      return abrirModal;

    } else {
      this.indicadoresCalculo = { ...this.parametrosComponent.retornarValores() }
      this.insertInformacaoBasica();
      return false;
    }

  }


  // ***********************************
  // MÉTODOS PARA A ETAPA 1 DO CADASTRO
  // ***********************************

  buscarVinculo() {
    this.cadastroService.buscarVinculo()
      .subscribe(
        data => {
          this.vinculo = JSON.parse(JSON.stringify(data));
          this.buscarSindicato();
          //this.loading = false;
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          //Swal.fire('Erro', 'NÃ£o foi possÃ­vel obter as informaÃ§Ãµes das filiais.', 'error');
        }
      );
  }

  buscarDepartamento() {
    this.cadastroService.buscarDepartamento()
      .subscribe(
        data => {
          this.spinner.hide()
          this.departamento = JSON.parse(JSON.stringify(data));
          this.buscarSetor(this.cadastro.InformacoesBasicas.Departamento.Cod, 1);
          //this.loading = false;
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarSetor(codigo, tipo) {

    let codigo_par;

    if (tipo == 0) {
      codigo_par = codigo.target.value;
    } else {
      codigo_par = codigo;
    }

    if (codigo_par.trim() != '') {
      this.carrega_setor = true;
      this.cadastroService.buscarSetor(codigo_par)
        .subscribe(
          data => {
            this.setores = JSON.parse(JSON.stringify(data));
            // Habilita o setor
            this.carrega_setor = false;
            this.formInformacaoBasica.get('setor_form').enable();
            this.buscarCargo(this.cadastro.InformacoesBasicas.Vinculo.Codigo, 1);
            //this.loading = false;
          },
          error => {
            this.spinner.hide();
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          }
        );
    } else {
      this.desabilitarCamposInformacoesBacicas('full');
    }

  }

  buscarCargo(codigo, tipo) {

    let codigo_par;

    if (tipo == 0) {
      codigo_par = codigo.target.value;
    } else {
      codigo_par = codigo;
    }

    if (codigo_par.trim() != '') {
      this.carrega_cargo = true;
      this.cadastroService.buscarCargo(codigo_par.trim())
        .subscribe(
          data => {
            this.cargos = JSON.parse(JSON.stringify(data));
            // Habilita o setor
            this.carrega_cargo = false;
            this.formInformacaoBasica.get('cargo_form').enable();
            this.buscarVinculo();
            this.buscarHoraTrabalho(this.cadastro.InformacoesBasicas.Cargo.Codigo, 1)
            this.buscarNivel(this.cadastro.InformacoesBasicas.Cargo.Codigo, 1);
          },
          error => {
            this.spinner.hide();
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          }
        );
    } else {
      this.desabilitarCamposInformacoesBacicas('setor');
    }
  }

  buscarHoraTrabalho(codigo, tipo) {

    let codigo_par;

    if (tipo == 0) {
      codigo_par = codigo.target.value;
    } else {
      codigo_par = codigo;
    }

    if (codigo_par.trim() != '') {
      this.carrega_hora_trabalho = true;
      this.cadastroService.buscarHoraTrabalho(codigo_par.trim())
        .subscribe(
          data => {
            this.spinner.hide();
            this.horaTrabalho = JSON.parse(JSON.stringify(data));
            this.cargaHorariaFilter = [];
            this.removerRepetido(this.horaTrabalho);
            // Habilita o setor
            this.carrega_hora_trabalho = false;
            this.formInformacaoBasica.get('cargaH_form').enable();

            if (tipo == 1) {
              this.habilitarHoraTrabalho(this.cadastro.InformacoesBasicas.HorarioTrabalho.CargaHorariaSemana, 1);
              this.buscarCodigoHora(this.cadastro.InformacoesBasicas.HorarioTrabalho.Codigo);
            }


          },
          error => {
            this.spinner.hide();
            new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          }
        );
    } else {
      this.desabilitarCamposInformacoesBacicas('cargo');
    }
  }

  buscarNivel(codigo, tipo) {

    let codigo_par;

    if (tipo == 0) {
      codigo_par = codigo.target.value;
    } else {
      codigo_par = codigo;
    }

    this.carrega_nivel = true;
    this.cadastroService.buscarNivelCadastroPublico(codigo_par.trim())
      .subscribe(
        data => {
          this.nivel = JSON.parse(JSON.stringify(data));
          // Habilita o setor
          this.carrega_nivel = false;
          this.formInformacaoBasica.get('nivel_form').enable();

        },
        error => {
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  habilitarHoraTrabalho(codigo, tipo) {
    let codigo_par;

    if (tipo == 0) {
      codigo_par = codigo.target.value;
    } else {
      codigo_par = String(codigo);
    }

    if (codigo_par.trim() != '') {
      var codigoCargaHoraria = parseInt(codigo_par);
      this.horarioTrabalhoFilter = this.horaTrabalho.filter(element => {
        return element.CargaHorariaSemana === codigoCargaHoraria;
      });
      this.formInformacaoBasica.get('horaT_form').enable();
    } else {
      this.desabilitarCamposInformacoesBacicas('cargaHora');
    }
  }

  buscarSindicato() {
    this.cadastroService.buscarSindicato()
      .subscribe(
        data => {
          this.sindicatos = JSON.parse(JSON.stringify(data));
          //this.loading = false;
          this.buscarEscolaridade();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  // ***********************************
  // MÃTODOS PARA A ETAPA 2 DO CADASTRO
  // ***********************************

  buscarEscolaridade() {
    this.cadastroService.buscarEscolaridade()
      .subscribe(
        data => {
          this.escolaridade = JSON.parse(JSON.stringify(data));
          this.buscarNacionalidade();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarNacionalidade() {
    this.cadastroService.buscarNacionalidade()
      .subscribe(
        data => {
          this.nacionalidade = JSON.parse(JSON.stringify(data));
          this.buscarCor();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarCor() {
    this.cadastroService.buscarCor()
      .subscribe(
        data => {
          this.cor = JSON.parse(JSON.stringify(data));
          this.buscarSexo();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarSexo() {
    this.cadastroService.buscarSexo()
      .subscribe(
        data => {
          this.sexo = JSON.parse(JSON.stringify(data));
          this.buscarEstadoCivil();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarEstadoCivil() {
    this.cadastroService.buscarEstadoCivil()
      .subscribe(
        data => {
          this.estadoCivil = JSON.parse(JSON.stringify(data));
          this.spinner.hide();
          this.buscarCep(this.cadastro.Pessoal.Endereco.Cep, 1);

        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  onBlur(target: any) {
    if (target instanceof EventTarget) {
      var elemento = target as HTMLInputElement;
      return elemento.value;
    }
  }

  buscarCep(cod_cep, tipo) {
    var cep = cod_cep;
    cep = cep.replace("-", "");

    if (cep.trim() == '' && tipo == 0) {
      new AlertaModal("alerta", "Cep inválido.", "Digite um cep para buscar o endereço.", 'p');
      this.zerarEndereco();
    } else {
      if (this.step > 1 || tipo == 0) {
        this.carrega_cep = true;
        this.cadastroService.buscarCep(cep.trim())
          .subscribe(
            data => {
              this.cep = JSON.parse(JSON.stringify(data));
              if (this.cep.Logradouro.trim() == '') {
                new AlertaModal("alerta", "Cep inválido.", "Digite um ep para buscar o endereço.", 'p');
                this.zerarEndereco();
                this.carrega_cep = false;
              } else {
                this.formDadosPessoais.controls['logradouro_form'].setValue(this.cep.Logradouro);
                this.formDadosPessoais.controls['complemento_form'].setValue(this.cep.Logradouro);
                this.formDadosPessoais.controls['bairro_form'].setValue(this.cep.Bairro);
                this.buscarEstado();
              }
              this.carrega_cep = false;
            },
            error => {
              this.carrega_cep = false;
              new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
            }
          );
      }
    }
  }

  buscarEstado() {
    this.cadastroService.buscarEstado()
      .subscribe(
        data => {
          this.estado = JSON.parse(JSON.stringify(data));
          this.formDadosPessoais.controls['estado_form'].setValue(this.cep.Uf);
          this.formDadosPessoais.get('estado_form').enable();

          this.buscarCidade(this.estado, this.cep.Uf);
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarCidade(codigo, uf) {

    this.buscarCodigoEstado(codigo, uf);

    this.cadastroService.buscarCidade(this.codigo_uf)
      .subscribe(
        data => {
          this.cidade = JSON.parse(JSON.stringify(data));
          this.formDadosPessoais.controls['cidade_form'].setValue(this.cep.Cidade);
          this.formDadosPessoais.get('cidade_form').enable();
          //
          this.buscarUf();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  // ***********************************
  // MÉTODOS PARA A ETAPA 3 DO CADASTRO
  // ***********************************

  buscarUf() {
    this.cadastroService.buscarEstado()
      .subscribe(
        data => {
          this.ufRg = JSON.parse(JSON.stringify(data));
          this.ufCtps = JSON.parse(JSON.stringify(data));
          this.ufCnh = JSON.parse(JSON.stringify(data));
          this.buscarCategoriaCnh();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarCategoriaCnh() {
    this.cadastroService.buscarCategoriaCnh()
      .subscribe(
        data => {
          this.categoriaCnh = JSON.parse(JSON.stringify(data));
          this.buscarBanco();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }

  buscarBanco() {
    this.cadastroService.buscarBanco()
      .pipe(distinct())
      .subscribe(
        data => {
          this.banco = JSON.parse(JSON.stringify(data));
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );
  }



  // ***********************************
  // MÉTODOS DE INSERÇÃO E UPDATE DAS ETAPAS
  // ***********************************


  organizarEtapa1() {
    this.cadastro_etapa_1.Etapa = '1';
    this.cadastro_etapa_1.Documentos.Cpf = this.cadastro_etapa_1.Documentos.Cpf;
    this.cadastro_etapa_1.InformacoesBasicas.NomeCompleto = this.cadastro.InformacoesBasicas.NomeCompleto;
    this.cadastro_etapa_1.InformacoesBasicas.Departamento.Cod = this.cadastro.InformacoesBasicas.Departamento.Cod;
    this.cadastro_etapa_1.InformacoesBasicas.Setor.Cod = this.cadastro.InformacoesBasicas.Setor.Cod;
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.SetData = this.cadastro.InformacoesBasicas.DataAdmissao.SetData;
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.ToDate = this.cadastro.InformacoesBasicas.DataAdmissao.ToDate;
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.IsDate = "true";
    this.cadastro_etapa_1.InformacoesBasicas.DataAdmissao.Empty = "false";
    this.cadastro_etapa_1.InformacoesBasicas.Vinculo.Codigo = this.cadastro.InformacoesBasicas.Vinculo.Codigo.toString();
    this.cadastro_etapa_1.InformacoesBasicas.Cargo.Codigo = this.cadastro.InformacoesBasicas.Cargo.Codigo.toString();
    this.cadastro_etapa_1.InformacoesBasicas.Salario.CodigoNivel = this.cadastro.InformacoesBasicas.Salario.CodigoNivel;
    this.buscarSalarioNivel(this.cadastro_etapa_1.InformacoesBasicas.Salario.CodigoNivel);
    this.organizarCargaHoraria(this.formInformacaoBasica.get('horaT_form').value);
    this.organizarCargo(this.cadastro_etapa_1.InformacoesBasicas.Cargo.Codigo);
    this.cadastro_etapa_1.InformacoesBasicas.Salario.Valor = this.cadastro.InformacoesBasicas.Salario.Valor.toString();
    this.cadastro_etapa_1.InformacoesBasicas.HorarioTrabalho.Codigo = this.cadastro.InformacoesBasicas.HorarioTrabalho.Codigo.toString();
    this.cadastro_etapa_1.InformacoesBasicas.Sindicato.Codigo = this.cadastro.InformacoesBasicas.Sindicato.Codigo.toString();

  }

  insertInformacaoBasica() {
    this.spinner.show();
    //this.cadastro_etapa_1.Filial.Codigo = '100001';
    this.organizarEtapa1();

    this.cadastroService.inserirEtapa1(this.cadastro_etapa_1)
      .subscribe(
        data => {
          this.retorno_usuario = JSON.parse(JSON.stringify(data));
          // Salva o nÃºmero de ordem e matrÃ­cula

          this.cadastro_etapa_1.NumeroDeOrdem = this.retorno_usuario.NumeroDeOrdem;
          this.cadastro_etapa_1.Matricula = this.retorno_usuario.Matricula;
          localStorage.setItem("matricula", new Crypto().encryptUsingAES256(JSON.stringify(this.cadastro_etapa_1.Matricula)));

          this.cadastro_etapa_2.NumeroDeOrdem = this.retorno_usuario.NumeroDeOrdem;
          this.cadastro_etapa_2.Matricula = this.retorno_usuario.Matricula;

          this.cadastro_etapa_3.NumeroDeOrdem = this.retorno_usuario.NumeroDeOrdem;
          this.cadastro_etapa_3.Matricula = this.retorno_usuario.Matricula;

          // Vai para a prÃ³xima etapa
          this.buscarEscolaridade();
          this.validaCadastro = true;
        },
        error => {
          this.spinner.hide();
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
        }
      );

  }

  // Altera os dados das etapas
  updateCadastro(dadosEtapa) {
    this.spinner.show();
    this.cadastroService.updateEtapa(dadosEtapa)
      .subscribe(
        data => {
          this.retornoUpdate();
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
          this.step--;
          this.stepcount--;
          window.scrollTo(0, 0);
          new AlertaModal("erro", "Erro", error.error.Mensagem, 'p');
          //Swal(error.message,error,'error');
        }
      );
  }

  // ***************************************************
  // MÃ©todos das validaÃ§Ãµes das etapas
  // ***************************************************


  // Faz a validaÃ§Ã£o da etapa pra chamar o mÃ©todo do momento
  validaMetodoEtapa(etapa: number) {
    if (etapa == 1) {
      this.validarEtapa1();
    } else if (etapa == 2) {
      this.validarEtapa2();
    } else if (etapa >= 3) {
      this.validarEtapa3();
    }
  }

  retornoUpdate() {
    if (this.step == 1) {
      this.buscarEscolaridade();
    } else if (this.step == 2) {
      this.buscarUf();
    } else if (this.step == 3) {
      this.spinner.hide();
      this.finalizarCadastro();
    }
  }

  validarEtapa1() {
    if (this.formInformacaoBasica.invalid) {
      new AlertaModal('alerta', 'Preencha todos os campos!', "Verifique os campos incorretos.", 'p');
      this.validaEtapa1 = true;
      this.step--;
      this.stepcount--;
      window.scrollTo(0, 0);
    } else {
      if (this.validaCadastro) {
        this.organizarEtapa1()
        this.updateCadastro(this.cadastro_etapa_1);
        const abrirIndicadores = this.verificarIndicadoresCalculo();
        if (abrirIndicadores) return;
      } else {
        this.insertInformacaoBasica();
        const abrirIndicadores = this.verificarIndicadoresCalculo();
        if (abrirIndicadores) return;
      }
    }
  }

  validarEtapa2() {
    if (this.formDadosPessoais.invalid) {
      new AlertaModal('alerta', 'Preencha todos os campos!', "Verifique os campos incorretos.", 'p');
      this.validaEtapa2 = true;
      this.step--;
      this.stepcount--;
      window.scrollTo(0, 0);
    } else {
      this.setarValoresEtapa2();
      this.updateCadastro(this.cadastro_etapa_2);
    }
  }

  validarEtapa3() {
    this.cadastro_etapa_3.Documentos.UploadDocumentos = this.anexoComponent.retornarValores();

    if (this.formDocumentos.invalid) {
      new AlertaModal('alerta', 'Preencha todos os campos!', "Verifique os campos incorretos.", 'p');
      this.validaEtapa3 = true;
      this.step--;
      this.stepcount--;
      window.scrollTo(0, 0);
    } else if (this.cadastro_etapa_3.Documentos.UploadDocumentos.length == 0) {
      new AlertaModal("alerta", 'Aviso!', "Envie ao menos um documento. ", 'p');
      this.step--;
      this.stepcount--;
      window.scrollTo(0, 0);
    } else {
      
      this.setarValoresEtapa3();
      //this.cadastro_etapa_3.Documentos.UploadDocumentos = this.anexoComponent.retornarValores();
      
    }
  }

  finalizarCadastro() {

    new AlertaModal("sucesso", 'Cadastro realizado com sucesso!', "Aguardando envio de informações do Benefí­cio.", 'p')
    this.router.navigate(["/cadastro"]);

  }


  // ***************************************************
  // MÃ©todos genÃ©ricos para validar rotinas e campos
  // ***************************************************

  zerarEndereco() {
    this.formDadosPessoais.controls['logradouro_form'].setValue('');
    this.formDadosPessoais.controls['complemento_form'].setValue('');
    this.formDadosPessoais.controls['bairro_form'].setValue('');
    this.formDadosPessoais.controls['cidade_form'].setValue('');
    this.formDadosPessoais.controls['estado_form'].setValue('');
    this.formDadosPessoais.controls['numero_form'].setValue('');
    this.formDadosPessoais.get('estado_form').disable();
    this.formDadosPessoais.get('cidade_form').disable();
    this.cidade = [];
    this.estado = [];
  }

  desabilitarCamposInformacoesBacicas(tipo) {

    if (tipo == 'full') {
      this.formInformacaoBasica.get('setor_form').disable();
      this.formInformacaoBasica.controls['setor_form'].setValue("");
      this.setores = [];

      this.formInformacaoBasica.get('cargo_form').disable();
      this.formInformacaoBasica.controls['cargo_form'].setValue("");
      this.cargos = [];

      this.formInformacaoBasica.get('cargaH_form').disable();
      this.formInformacaoBasica.controls['cargaH_form'].setValue("");
      this.cargaHoraria = [];

      this.formInformacaoBasica.get('horaT_form').disable();
      this.formInformacaoBasica.controls['horaT_form'].setValue("");
      this.horaTrabalho = [];
      this.cargaHorariaFilter = [];

      this.formInformacaoBasica.get('nivel_form').disable();
      this.formInformacaoBasica.controls['nivel_form'].setValue("");
      this.nivel = [];
    } else if (tipo == 'setor') {
      this.formInformacaoBasica.get('cargo_form').disable();
      this.formInformacaoBasica.controls['cargo_form'].setValue("");
      this.cargos = [];

      this.formInformacaoBasica.get('cargaH_form').disable();
      this.formInformacaoBasica.controls['cargaH_form'].setValue("");
      this.cargaHoraria = [];

      this.formInformacaoBasica.get('horaT_form').disable();
      this.formInformacaoBasica.controls['horaT_form'].setValue("");
      this.horaTrabalho = [];
      this.cargaHorariaFilter = [];

      this.formInformacaoBasica.get('nivel_form').disable();
      this.formInformacaoBasica.controls['nivel_form'].setValue("");
      this.nivel = [];
    } else if (tipo == 'cargo') {
      this.formInformacaoBasica.get('cargaH_form').disable();
      this.formInformacaoBasica.controls['cargaH_form'].setValue("");
      this.cargaHoraria = [];

      this.formInformacaoBasica.get('horaT_form').disable();
      this.formInformacaoBasica.controls['horaT_form'].setValue("");
      this.horaTrabalho = [];
      this.cargaHorariaFilter = [];
    } else if (tipo == 'cargaHora') {
      this.formInformacaoBasica.get('horaT_form').disable();
      this.formInformacaoBasica.controls['horaT_form'].setValue("");
      //this.horaTrabalho = [];
    }

  }

  somenteNumero(event) {
    return ((event.charCode >= 48 && event.charCode <= 57) /*|| (event.keyCode == 45 || event.charCode == 46)*/)
  }

  compararDatas(data1: string, data2: string, etapa, campo1, campo2) {

    if (data1.trim() != '' && data2.trim() != '') {

      data1 = this.validacaoService.converteDatas(data1, 1);
      data2 = this.validacaoService.converteDatas(data2, 1);
    }
  }

  // ***************************************************
  // MÃ©todos para setar os valores do cadastro
  // ***************************************************

  setarValoresEtapa1() {
    this.organizarEtapa1()
  }

  setarValoresEtapa2() {
    this.cadastro_etapa_2.Etapa = 2;
    //this.cadastro_etapa_2.Filial.Codigo = 100001;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.DataNascimento.SetData = this.formDadosPessoais.get('data_nascimento_form').value.replace(/-/gi, '');
    this.cadastro_etapa_2.Pessoal.DadosBasicos.DataNascimento.ToDate = this.converteDatas(this.formDadosPessoais.get('data_nascimento_form').value);
    this.cadastro_etapa_2.Pessoal.DadosBasicos.DataNascimento.IsDate = true;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.DataNascimento.Empty = false;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.GrauDeInstrucao.Valor = this.formDadosPessoais.get('escolaridade_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.Nacionalidade.Codigo = this.formDadosPessoais.get('nacionalidade_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.Etnia.Descricao = this.formDadosPessoais.get('cor_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.Sexo.Valor = this.formDadosPessoais.get('sexo_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.EstadoCivil.Valor = this.formDadosPessoais.get('estado_civil_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.NomeMae = this.formDadosPessoais.get('mae_form').value;
    this.cadastro_etapa_2.Pessoal.DadosBasicos.NomePai = this.formDadosPessoais.get('pai_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Cep = this.formDadosPessoais.get('cep_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Logradouro = this.formDadosPessoais.get('logradouro_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Logradouro = this.formDadosPessoais.get('complemento_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Numero = this.formDadosPessoais.get('numero_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Bairro = this.formDadosPessoais.get('bairro_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Estado = this.formDadosPessoais.get('estado_form').value;
    this.cadastro_etapa_2.Pessoal.Endereco.Cidade = this.formDadosPessoais.get('cidade_form').value;
    this.cadastro_etapa_2.Pessoal.Contato.DDDCelular = this.formDadosPessoais.get('celular_form').value.substring(0, 2);
    this.cadastro_etapa_2.Pessoal.Contato.Celular = this.formDadosPessoais.get('celular_form').value.substring(2, 11);
    this.cadastro_etapa_2.Pessoal.Contato.DDDTelefone = this.formDadosPessoais.get('telefone_form').value.substring(0, 2);
    this.cadastro_etapa_2.Pessoal.Contato.Telefone = this.formDadosPessoais.get('telefone_form').value.substring(2, 10);
    this.cadastro_etapa_2.Pessoal.Contato.Email1 = this.formDadosPessoais.get('email_form').value;
    this.cadastro_etapa_2.Pessoal.Contato.Email2 = this.formDadosPessoais.get('email2_form').value;
  }

  setarValoresEtapa3() {
    this.cadastro_etapa_3.Etapa = 3;
    //this.cadastro_etapa_3.Filial.Codigo = 100001;
    // this.cadastro_etapa_3.Documentos.Cpf = this.formDocumentos.get('cpf_form').value;
    this.cadastro_etapa_3.Documentos.Rg.Numero = this.formDocumentos.get('rg_form').value;
    this.cadastro_etapa_3.Documentos.Rg.DataEmissaoRg.SetData = this.formDocumentos.get('data_emissao_form').value.replace(/-/gi, '');
    this.cadastro_etapa_3.Documentos.Rg.DataEmissaoRg.ToDate = this.converteDatas(this.formDocumentos.get('data_emissao_form').value);
    this.cadastro_etapa_3.Documentos.Rg.DataEmissaoRg.IsDate = true;
    this.cadastro_etapa_3.Documentos.Rg.DataEmissaoRg.Empty = false;
    this.cadastro_etapa_3.Documentos.Rg.OrgaoEmissor = this.formDocumentos.get('orgao_form').value;
    this.cadastro_etapa_3.Documentos.Rg.UfEmissaoRg = this.formDocumentos.get('uf_emissor_form').value;
    this.cadastro_etapa_3.Documentos.Cnh.Numero = this.formDocumentos.get('cnh_form').value;
    this.cadastro_etapa_3.Documentos.Cnh.CategoriaCnh = this.formDocumentos.get('categoria_cnh_form').value;
    this.cadastro_etapa_3.Documentos.Cnh.UF = this.formDocumentos.get('uf_cnh_form').value;
    this.cadastro_etapa_3.Documentos.Cnh.DataValidadeCnh.SetData = this.formDocumentos.get('data_validade_form').value.replace(/-/gi, '');
    this.cadastro_etapa_3.Documentos.Cnh.DataValidadeCnh.ToDate = this.converteDatas(this.formDocumentos.get('data_validade_form').value);
    this.cadastro_etapa_3.Documentos.Cnh.DataValidadeCnh.IsDate = true;
    this.cadastro_etapa_3.Documentos.Cnh.DataValidadeCnh.Empty = false;
    this.cadastro_etapa_3.Documentos.Clt.CarteiraDeTrabalho = this.formDocumentos.get('carteira_form').value;
    this.cadastro_etapa_3.Documentos.Clt.NumeroSerieCtps = this.formDocumentos.get('serie_form').value;
    this.cadastro_etapa_3.Documentos.Clt.Pis = this.formDocumentos.get('pis_form').value;
    this.cadastro_etapa_3.Documentos.Clt.UfCtps = this.formDocumentos.get('uf_ctps_form').value;
    this.cadastro_etapa_3.Documentos.TituloEleitor.Numero = this.formDocumentos.get('titulo_form').value;
    this.cadastro_etapa_3.Documentos.TituloEleitor.ZonaEleitoral = this.formDocumentos.get('zona_form').value;
    this.cadastro_etapa_3.Documentos.TituloEleitor.SecaoEleitoral = this.formDocumentos.get('secao_form').value;
    this.cadastro_etapa_3.DadosBancarios.Banco = this.formDocumentos.get('banco_form').value;
    this.cadastro_etapa_3.DadosBancarios.Agencia = this.formDocumentos.get('agencia_form').value;
    this.cadastro_etapa_3.DadosBancarios.Operacao = this.formDocumentos.get('operacao_form').value;
    this.cadastro_etapa_3.DadosBancarios.Conta = this.formDocumentos.get('conta_form').value;
    this.updateCadastro(this.cadastro_etapa_3);
  }


  // ***************************************************
  // MÃ©todos para validar os steps
  // ***************************************************


  PreviewStep() {

    this.step = this.step - 1;
    window.scrollTo(0, 0);

  }

  NextStep() {
    if (this.step <= this.stepcount) {
      this.step = this.step + 1;
      window.scrollTo(0, 0);
    }

    if (this.stepcount < this.step) {
      this.stepcount = this.stepcount + 1;
    }

    // Verifica a etapa e chama o mÃ©todo recorrente
    this.validaMetodoEtapa(this.step);
  }
  inicioStep() {
    if (this.step > 0) {
      this.step = 0;

      window.scrollTo(0, 0);
    }
    this.step = this.step;
    //this.insertInformacaoBasica()

  }
  primeiroStep() {
    if (this.step > 1 || this.stepcount > 1) {
      this.step = 1;
      this.clickStep = true;
      window.scrollTo(0, 0);

    }
  }
  segundoStep() {
    if (this.step > 2 || this.stepcount > 2) {
      this.step = 2
      this.clickStep = true;
      window.scrollTo(0, 0);
    }
  }


  // ***************************************************
  // MÃ©todos para a rotina dos retornos das funÃ§Ãµes
  // ***************************************************

  removerRepetido(dados) {
    var count = 0;
    var horas = [];

    dados.forEach(obj => {
      if (count != 0) {
        if (horas.indexOf(obj.CargaHorariaSemana) == -1) {
          horas.push(obj.CargaHorariaSemana);
          this.cargaHorariaFilter.push({ cod: obj.CargaHorariaSemana, Codigo: obj.Codigo });
        }
      } else {
        horas.push(obj.CargaHorariaSemana);
        this.cargaHorariaFilter.push({ cod: obj.CargaHorariaSemana, Codigo: obj.Codigo });
      }
      count++;
    });
  }
  organizarCargo(codigo): any {
    this.cargos.map(cargo => {
      if (cargo.Codigo == codigo) {
        this.cadastro_etapa_1.InformacoesBasicas.Cargo.CBO = Number(cargo.CBO)
      }
    });
  }

  organizarCargaHoraria(codigo) {
    this.horarioTrabalhoFilter.map(horaTrabalho => {
      if (horaTrabalho.Codigo == codigo) {
        this.cadastro_etapa_1.InformacoesBasicas.HorarioTrabalho.Codigo = horaTrabalho.Codigo
        this.cadastro_etapa_1.InformacoesBasicas.HorarioTrabalho.HoraEntrada = horaTrabalho.HoraEntrada
        this.cadastro_etapa_1.InformacoesBasicas.HorarioTrabalho.HoraSaida = horaTrabalho.HoraSaida
        this.cadastro_etapa_1.InformacoesBasicas.HorarioTrabalho.InicioIntervalo = horaTrabalho.InicioIntervalo
        this.cadastro_etapa_1.InformacoesBasicas.HorarioTrabalho.TerminoIntervalo = horaTrabalho.TerminoIntervalo
        this.cadastro_etapa_1.InformacoesBasicas.HorarioTrabalho.CargaHorariaSemana = horaTrabalho.CargaHorariaSemana

      }
    });
  }

  buscarCodigoHora(dados) {

    var codigoCargaHoraria = parseInt(dados);
    this.horarioTrabalho = this.cargaHorariaFilter.filter(element => {
      if (element.cod == codigoCargaHoraria) {
        this.retornoHorario = element.Codigo;
        return true;
      }
    });
    this.formInformacaoBasica.get('horaT_form').enable();

  }

  buscarSalarioNivel(codigo): any {
    this.nivel.map(nivel => {
      if (nivel.CodigoNivel == codigo) {
        this.cadastro_etapa_1.InformacoesBasicas.Salario.Valor = nivel.Valor
      }
    });
  }

  buscarCodigoEstado(estado, uf): any {
    estado.some(obj => {
      if (obj.SiglaUf == uf) {
        this.codigo_uf = obj.Codigo;
        return true;
      }
    });
  }

  deletarIcon(indice) {
    let numeroIndex = indice
    this.urls.splice(numeroIndex, 1)
  }

  mascaraHora(val) {
    var hora = val.substring(0, 2);
    var minuto = val.substring(2, 4);
    return hora + ":" + minuto;
  }

  formataReal(valor) {
    valor = String(valor);
    var salario = valor.indexOf(".");
    if (salario == -1) {
      salario = valor + ".00";
    }

    //Instanciando o objeto
    var formatter = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
    /*FORMATA CONFORME CONFIGURAÃÃES DEFINIDAS NO formatter*/
    salario = formatter.format(salario);
    //Coloca o valor formatado no campo resultado

    return salario;
  }

  verificaDados(dados, nome, etapa, tipo) {

    // 1 para data
    // 2 para cpf
    let aux = 0;
    var data = dados.target.value;
    var elemento = nome;

    // if(elemento === 'data_admissao_form'){
    //   if(!this.ValidarValoresDatas(data, 1)){
    //     aux = 1
    //   }
    // }else
    if (elemento === 'data_emissao_ctps_form' || elemento === 'data_emissao_form') {
      if (!this.ValidarValoresDatas(data, 2)) {
        aux = 1
      }
    }
    else if (elemento === 'data_nascimento_form') {
      if (!this.ValidarValoresDatas(data, 3)) {
        aux = 1
        // Swal(' Não é Possível cadastrar menores de 16 anos', "Por favor, preencha uma data válida.", 'warning');
      }
    } else if (elemento === 'data_validade_form') {
      if (!this.ValidarValoresDatas(data, 4)) {
        aux = 1
      }
    }

    if (tipo == 1) {
      if (!this.validacaoService.validarData(data) || aux === 1) {
        //Swal('Data invÃ¡lida!', "Por favor, preencha uma data vÃ¡lida.", 'warning');
        if (etapa == 1) {
          this.formInformacaoBasica.controls[elemento].setValue("");
        } else if (etapa == 2) {
          this.formDadosPessoais.controls[elemento].setValue("");
        } else if (etapa == 3) {
          this.formDocumentos.controls[elemento].setValue("");
        }
      }
    } else if (tipo == 2) {
      if (!this.validacaoService.validarCpf(data)) {
        // if(etapa == 1){
        //   this.formInformacaoBasica.controls[elemento].setValue("");
        // }else if(etapa == 2){
        //   this.formDadosPessoais.controls[elemento].setValue("");
        // }else if(etapa == 3){
        //   this.formDocumentos.controls[elemento].setValue("");
        // }
        this.validar_cpf_blur = true;
      } else {
        this.validar_cpf_blur = false;
      }
    } else if (tipo == 3) {
      if (!this.validacaoService.validarPis(data)) {
        // if(etapa == 1){
        //   this.formInformacaoBasica.controls[elemento].setValue("");
        // }else if(etapa == 2){
        //   this.formDadosPessoais.controls[elemento].setValue("");
        // }else if(etapa == 3){
        //   this.formDocumentos.controls[elemento].setValue("");
        // }
        this.validar_pis_blur = true;
      } else {
        this.validar_pis_blur = false;
      }
    }
  }

  validarDataAtual(data) {
    let dateAtual: Date = new Date();
    var dia, mes, ano;
    var teste = 'T00:00:00'


    dia = data.substring(0, 2);
    mes = data.substring(3, 5);
    ano = data.substring(6, 10);



    var time = new Date(ano + '-' + mes + '-' + dia + teste);

    if (dateAtual >= time) {

      return true;

    } else {

      return false
    }
  }

  ValidarValoresDatas(data, tipo) {

    var dia, mes, ano;
    var teste = 'T00:00:00'

    dia = data.substring(0, 2);
    mes = data.substring(3, 5);
    ano = data.substring(6, 10);

    let dateAtual: Date = new Date();
    var time = new Date(ano + '-' + mes + '-' + dia + teste);

    let auxTime = time.getFullYear()
    let auxDate = dateAtual.getFullYear()
    if (!this.validacaoService.validarData(data)) {
      return true
    }

    if (tipo === 1) {
      if (dateAtual <= time) {
        return true;

      } else {
        new AlertaModal('alerta', 'Data de admissão incorreta!', "Porfavor, preencha uma data válida!.", 'p');
        return false
      }
    } else if (tipo === 2) {
      if (dateAtual >= time) {
        return true;

      } else {
        new AlertaModal('alerta', 'Data de emissão incorreta!', "Porfavor, preencha uma data válida!.", 'p');
        return false
      }
    } else if (tipo === 3) {
      if ((auxDate - this.idadeMinima) >= auxTime) {

        return true;

      } else {
        new AlertaModal('alerta', 'Não é possivel cadastrar menores de 14 anos!', "Porfavor, preencha uma data válida!.", 'p');
        return false
      }
    } else {
      if ((auxDate - 5) <= auxTime && (auxDate + 5) >= auxTime) {

        return true;

      } else {
        new AlertaModal('alerta', 'Validade não aceita!', "Porfavor, preencha uma data válida!.", 'p');
        return false
      }
    }
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }
  converteDatas(datas) {
    var [ano, mes, dia] = datas.split('-');
    return `${dia}/${mes}/${ano}`

  }

  corrigeDatas() {
    var [dia, mes, ano] = this.cadastro.InformacoesBasicas.DataAdmissao.ToDate.split('/');
    this.cadastro.InformacoesBasicas.DataAdmissao.ToDate = `${ano}-${mes}-${dia}`;

    var [dia, mes, ano] = this.cadastro.Pessoal.DadosBasicos.DataNascimento.ToDate.split('/');
    this.cadastro.Pessoal.DadosBasicos.DataNascimento.ToDate = `${ano}-${mes}-${dia}`;

    var [dia, mes, ano] = this.cadastro.Documentos.Rg.DataEmissaoRg.ToDate.split('/');
    this.cadastro.Documentos.Rg.DataEmissaoRg.ToDate = `${ano}-${mes}-${dia}`;

    var [dia, mes, ano] = this.cadastro.Documentos.Cnh.DataValidadeCnh.ToDate.split('/');
    this.cadastro.Documentos.Cnh.DataValidadeCnh.ToDate = `${ano}-${mes}-${dia}`;
  }




}
