import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Cadastro } from 'src/app/service/classes/cadastro/cadastro';
import Documentos from 'src/app/service/classes/cadastro/Documento';
import { ErroService } from 'src/app/service/erros/erro.service';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { AlertaModal } from '../../../componentes-funcionais/alerta-modal/alerta-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AnexoService } from 'src/app/componentes/anexo/anexo.service';
import { DocumentosService } from '../documentos-perfil/documentos-perfil.service';

@Component({
  selector: 'app-anexo-perfil',
  templateUrl: './anexo-perfil.component.html',
  styleUrls: ['./anexo-perfil.component.css'],
})
export class AnexoPerfilComponent implements OnInit {
  @Input() cadastroUsuario: Cadastro;
  @Input() ColaboradorInativo: boolean;
  @Input() mudancaTab: boolean = false;
  @Input() existeCredencial: boolean;
  @Output() listaPendencias = new EventEmitter<Array<any>>();

  localStorage: LocalStorage;
  documentosMontados: Documentos;

  formEmail: FormGroup;

  listaUf: any[];
  listaCategoriaCnh: any[];
  cnhObrigatoria: boolean = false;

  documentos: Documentos;
  tipoUsuario: string;
  docImage: string = '';

  temErro: boolean = false;
  displayDocumento: boolean = false;
  loading: boolean = false;

  correcaoAnexo: boolean = false;
  documentosPendentesData: any;
  anexosPendentes: any[] = [];
  anexoSexo: number;
  anexoCarteira: number;
  anexoCasamento: number;
  listaPendenciasAnexos: any[];

  constructor(
    private fb: FormBuilder,
    private documentosService: DocumentosService,
    private anexoService: AnexoService,
    private validaErro: ErroService,
    private spinner: NgxSpinnerService
  ) {
    this.localStorage = this.decryptLocalStorage();
    this.tipoUsuario = this.localStorage.TipoUsuario;

    if (this.tipoUsuario == '5') {
      this.formEmail = this.fb.group({
        nomeacaoAnexo: [false],
        fotoAnexo: [false],
        identidadeAnexo: [false],
        carteiraProfAnexo: [false],
        cpfAnexo: [false],
        pisPasepAnexo: [false],
        certificadoMilitarAnexo: [false],
        carteiraDeMotoristaAnexo: [false],
        certidaoDeNascimentoAnexo: [false],
        certidaoDeCasamentoAnexo: [false],
        comprovanteDeResidenciaAnexo: [false],
        descEmail: [''],
      });
    }
  }

  ngOnInit() {
    this.tipoUsuario = this.localStorage.TipoUsuario;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cadastroUsuario']?.currentValue && this.cadastroUsuario.NumeroDeOrdem) {
      this.documentos = this.cadastroUsuario.Documentos;
      //condicionais anexo
      this.anexoSexo = parseInt(this.cadastroUsuario.Pessoal.DadosBasicos.Sexo.Valor.toString());
      this.anexoCarteira = this.cadastroUsuario.Documentos.Cnh.Numero ? 1 : 0;
      this.anexoCasamento =
        this.cadastroUsuario.Pessoal.DadosBasicos.EstadoCivil.Valor == 2 ? 1 : 0;
      if (this.localStorage.TipoUsuario != '1') {
        if (this.anexoSexo != 1) {
          this.formEmail.get('certificadoMilitarAnexo').disable();
        } else {
          this.formEmail.get('certificadoMilitarAnexo').enable();
        }
        if (this.anexoCarteira == 0) {
          this.formEmail.get('carteiraDeMotoristaAnexo').disable();
        } else {
          this.formEmail.get('carteiraDeMotoristaAnexo').enable();
        }
        if (this.anexoCasamento == 0) {
          this.formEmail.get('certidaoDeCasamentoAnexo').disable();
        } else {
          this.formEmail.get('certidaoDeCasamentoAnexo').enable();
        }
      }
    }
  }

  verificarCorrecaoAnexo(): any {
    this.spinner.show();
    this.anexosPendentes = [];
    //puxar lista de pendencias
    this.anexoService.buscarPendencias(this.cadastroUsuario.NumeroDeOrdem).subscribe(
      (data: any) => {
        if (data) {
          this.formEmail.controls['descEmail'].setValue(data.descricaoEmail);
          this.documentosPendentesData = data.documentosAnexados;
          if (this.cadastroUsuario.Pessoal.DadosBasicos.Sexo.Valor == 2) {
            this.documentosPendentesData.certificadoMilitarAnexo = 0;
          }
          if (this.cadastroUsuario.Pessoal.DadosBasicos.EstadoCivil.Valor != 2) {
            this.documentosPendentesData.certidaoDeCasamentoAnexo = 0;
          }
          if (!this.cadastroUsuario.Documentos.Cnh.Numero) {
            this.documentosPendentesData.carteiraDeMotoristaAnexo = 0;
          }
          // this.abrirSolicitacaoAnexo();
          this.correcaoAnexo = true;
          for (const pendencia in this.documentosPendentesData) {
            if (
              this.documentosPendentesData.hasOwnProperty(pendencia) &&
              this.documentosPendentesData[pendencia] == 1
            ) {
              if (this.formEmail.controls[pendencia].value == false) {
                this.formEmail.controls[pendencia].setValue(true);
              }

              if (!this.anexosPendentes.includes(pendencia)) {
                this.anexosPendentes.push(pendencia);
              }
            } else {
              if (this.formEmail.controls[pendencia].value == true) {
                this.formEmail.controls[pendencia].setValue(false);
              }
            }
          }
        }
        this.spinner.hide();
      },
      (error) => {
        this.validaErro.retornoErro(error), this.spinner.hide();
      }
    );
  }

  abrirSolicitacaoAnexo() {
    this.verificarCorrecaoAnexo();
  }

  salvarAnexosMarcados() {
    this.spinner.show('email');
    const empfil = this.localStorage.FilialAtual;
    const EmailDestinatario = this.cadastroUsuario.Pessoal.Contato.Email1.toLocaleLowerCase();
    const NumeroDeOrdem = this.cadastroUsuario.NumeroDeOrdem;
    const Url = window.location.href.split('/')[2];
    const formEmail = this.formEmail.getRawValue();
    const AdmUser = this.localStorage.NomeUsuario;
    const botaoSolicitarEmail = document.getElementById('modalEnviarEmail');
    const correcoesSolicitadas = {
      documentosAnexados: {
        nomeacaoAnexo: formEmail.nomeacaoAnexo ? 1 : 0,
        fotoAnexo: formEmail.fotoAnexo ? 1 : 0,
        identidadeAnexo: formEmail.identidadeAnexo ? 1 : 0,
        carteiraProfAnexo: formEmail.carteiraProfAnexo ? 1 : 0,
        cpfAnexo: formEmail.cpfAnexo ? 1 : 0,
        pisPasepAnexo: formEmail.pisPasepAnexo ? 1 : 0,
        certificadoMilitarAnexo: formEmail.certificadoMilitarAnexo ? 1 : 0,
        carteiraDeMotoristaAnexo: formEmail.carteiraDeMotoristaAnexo ? 1 : 0,
        certidaoDeNascimentoAnexo: formEmail.certidaoDeNascimentoAnexo ? 1 : 0,
        certidaoDeCasamentoAnexo: formEmail.certidaoDeCasamentoAnexo ? 1 : 0,
        comprovanteDeResidenciaAnexo: formEmail.comprovanteDeResidenciaAnexo ? 1 : 0,
      },
      descricaoEmail: formEmail.descEmail,
    };
    const anexoMarcado = Object.keys(formEmail).some((key) => {
      return key !== 'descEmail' && formEmail[key] === true;
    });

    if (!anexoMarcado || formEmail.descEmail.trim() == '') {
      new AlertaModal(
        'erro',
        'Erro',
        'É necessário marcar pelo menos um anexo e preencher a descrição.',
        'p'
      );
      this.spinner.hide('email');
      return false;
    }
    this.documentosService
      .salvarAnexosMarcados({ EmailDestinatario, correcoesSolicitadas, NumeroDeOrdem })
      .subscribe(
        (data) => {
          this.sendEmail({ EmailDestinatario, NumeroDeOrdem, Url, EnviarEmail: true });
          this.verificarCorrecaoAnexo();
          for (const pendencia in correcoesSolicitadas.documentosAnexados) {
            const pendenciaItem = this.listaPendenciasAnexos.findIndex(
              (item) => item.Label == pendencia
            );
            if (pendenciaItem != -1) {
              if (
                this.listaPendenciasAnexos[pendenciaItem].Pendente !=
                correcoesSolicitadas.documentosAnexados[pendencia]
              ) {
                this.listaPendenciasAnexos[pendenciaItem].Pendente =
                  correcoesSolicitadas.documentosAnexados[pendencia];
              }
            }
          }
          this.listaPendencias.emit(this.listaPendenciasAnexos);
        },
        (error) => {
          new AlertaModal('erro', 'Erro', error.error.Mensagem, 'p');
          this.spinner.hide('email');
        }
      );
    return false;
  }

  sendEmail(objetoEmail) {
    this.documentosService.gerarLink(objetoEmail).subscribe((data) => {
      this.formEmail.get('nomeacaoAnexo').setValue(false);
      this.formEmail.get('fotoAnexo').setValue(false);
      this.formEmail.get('identidadeAnexo').setValue(false);
      this.formEmail.get('carteiraProfAnexo').setValue(false);
      this.formEmail.get('cpfAnexo').setValue(false);
      this.formEmail.get('pisPasepAnexo').setValue(false);
      this.formEmail.get('certificadoMilitarAnexo').setValue(false);
      this.formEmail.get('carteiraDeMotoristaAnexo').setValue(false);
      this.formEmail.get('certidaoDeNascimentoAnexo').setValue(false);
      this.formEmail.get('certidaoDeCasamentoAnexo').setValue(false);
      this.formEmail.get('comprovanteDeResidenciaAnexo').setValue(false);
      this.formEmail.get('descEmail').setValue('');
      const botaoSolicitarEmail = document.getElementById('modalEnviarEmail');
      botaoSolicitarEmail.click();
      this.spinner.hide('email');
      new AlertaModal(
        'sucesso',
        'Sucesso!',
        'Email com link para correção de anexos foi enviado.',
        'p'
      );
    });
  }

  emissaoProfile(listaPendencias: Array<any>) {
    this.listaPendencias.emit(listaPendencias);
    this.listaPendenciasAnexos = listaPendencias;
    this.anexosPendentes = [];
    for (const pendencia in this.listaPendenciasAnexos) {
      if (this.listaPendenciasAnexos[pendencia].Pendente == 1) {
        this.anexosPendentes.push(this.listaPendenciasAnexos[pendencia].Label);
      }
    }
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem('util') != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem('util')));
    }
  }
}
