import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AcessoService } from "src/app/service/acesso/acesso.service";
import { Crypto } from "src/app/service/classes/crypto/crypto";
import { LocalStorage } from "src/app/service/classes/localstorage/LocalStorage";
import { ItemMenu } from "./item-menu.component";

@Injectable({
    providedIn: 'root'
})

export class ItemMenuService {
    localStorage: LocalStorage = new LocalStorage();
    url_acesso: string = '';
    headers = new HttpHeaders();
    cabecalho: any = null;
    tpProcessamento: string = "EM";
    data: any = new Date()
    mes: number = this.data.getMonth() + 1
    ano: number = this.data.getFullYear()
    competencia: string = this.ano.toString() + this.mes.toString().padStart(2, "0")
    filialAtual: string = '';
    tipoUsuario: string = '';

    constructor(private http: HttpClient, private acesso: AcessoService) {
        this.url_acesso = this.acesso.validarUrl();
        if (localStorage.getItem("util") != null) {
            this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
            this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
            this.filialAtual = this.localStorage.FilialAtual.toString(); 
            this.tipoUsuario = this.localStorage.TipoUsuario;
          }
    }

    buscarItensMenu(codigoServico: string) {
        return this.http.get(`${this.url_acesso}pse/item-menu/${codigoServico}`, { headers: this.cabecalho });
    }

    buscarServicosPse() {
        return this.http.get(this.url_acesso + `pse/servicos`, { headers: this.cabecalho });
    }

    buscarTarefasPorCodigo(codigoServico: number) {
        return this.http.get(`${this.url_acesso}pse/tarefas/${codigoServico}`, { headers: this.cabecalho });
    }

    salvarItemMenu(item: ItemMenu) {
        return this.http.post(`${this.url_acesso}pse/item-menu`, item, { headers: this.cabecalho });
    }

    editarItemMenu(item: ItemMenu) {
        return this.http.put(`${this.url_acesso}pse/item-menu`, item, { headers: this.cabecalho });
    }

    deletarItemMenu(codigo: string, codigoServico: string) {
        return this.http.delete(this.url_acesso + `pse/item-menu/${codigo}/${codigoServico}`, { headers: this.cabecalho });
    }

}