import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AcessoService } from "src/app/service/acesso/acesso.service";
import { Crypto } from "src/app/service/classes/crypto/crypto";
import { LocalStorage } from "src/app/service/classes/localstorage/LocalStorage";

@Injectable({
    providedIn: 'root'
  })
  export class ConfiguracaoService {
    localStorage: LocalStorage = new LocalStorage();
    url_acesso: any = null;
    headers = new HttpHeaders();
    filial: string = "";
    cabecalho: any = null;
  
    constructor(private http: HttpClient, private acesso: AcessoService) { 
      this.url_acesso = this.acesso.validarUrl();
      if (localStorage.getItem("util") != null) {
        this.localStorage = JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
        this.cabecalho = this.headers.append('sessao', this.localStorage.Sessao);
        this.filial = this.localStorage.FilialAtual.toString(); 
      }
    }
  
    buscarClientesPse(){
        return this.http.get(this.url_acesso + `pse/clientes`, {headers:this.cabecalho});
    }

    buscarServicosPse(){
      return this.http.get(this.url_acesso + `pse/servicos`, {headers:this.cabecalho});
  }

}