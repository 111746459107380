//import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertaModal } from 'src/app/componentes/componentes-funcionais/alerta-modal/alerta-modal.component';
import { Crypto } from 'src/app/service/classes/crypto/crypto';
import { LocalStorage } from 'src/app/service/classes/localstorage/LocalStorage';
import { ErroService } from 'src/app/service/erros/erro.service';
import Swal from 'sweetalert';
import { CadastroUsuarioService } from './cadastro-usuario.service';

@Component({
  selector: 'app-cadastro-usuario',
  templateUrl: './cadastro-usuario.component.html',
  styleUrls: ['./cadastro-usuario.component.css']
})
export class CadastroUsuarioComponent implements OnInit {

  localStorage: LocalStorage;

  formCadastroUsuario: FormGroup;
  formConfiguracaoUsuario: FormGroup;

  listaUsuarios: Usuario[] = [];
  listaEmpfil: any[] = [];
  empfilSelecionada: any[] = [];
  listaServicos: any[] = [];
  servicosSelecionados: any[] = [];

  obtemUmUsuario: any;
  listaTipoUsuario: any;
  codigoCliente: any;
  filter: string = "";
  contadorPag: number;

  revelarOlho: boolean = false;
  temErro: boolean = false;
  habilitarCancelar: boolean;
  marcarTodosServicosAux: boolean = true;
  marcarTodasEmpfilAux: boolean = true;
  editando: boolean = false;
  modalSalvo: boolean = false;
  habilitarCampoLogin: boolean = false;

  constructor(private spinner: NgxSpinnerService, private validaError: ErroService, private fb: FormBuilder, private usuarioService: CadastroUsuarioService) {
    this.localStorage = this.decryptLocalStorage();

   }
  ngOnInit(): void {
    this.buscarServicos();
    this.buscarTipo();
    this.buscarTodosUsuario();
    this.buscarEmpfil();

    this.formConfiguracaoUsuario = this.fb.group({
      Empfil: ['', Validators.compose([Validators.required])],
      Servico: ['', Validators.compose([Validators.required])]
    });
    this.formCadastroUsuario = this.fb.group({
      codigoUnico: [''],
      login: [''],
      nome: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      cpf: ['', Validators.compose([Validators.required, Validators.maxLength(14)])],
      senhaTemporaria: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      tipo: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });
    this.contadorPag = 1;
  }

  toogleCampoLogin(){
    if(this.habilitarCampoLogin == true){
      this.habilitarCampoLogin = false;
    } else {
      this.habilitarCampoLogin = true;
    }
  }

  buscarUmUsuario(codigoUsuario): void {
    this.codigoCliente = this.localStorage.ClienteId;
    this.usuarioService.buscarUsuarios(codigoUsuario, this.codigoCliente).subscribe(
      (data) => {
        this.obtemUmUsuario = data;
        this.montarServEmpfil();
      },
      erro => {
        this.validaError.retornoErro(erro);
        this.spinner.hide();
      }
    )
  }

  buscarTodosUsuario(): void {
    this.spinner.show()
    this.codigoCliente = this.localStorage.ClienteId;
    this.usuarioService.BuscarTodosUsuarios(this.codigoCliente).subscribe(
      (data: any[]) => {
        this.listaUsuarios = data;
        this.spinner.hide();
      },
      erro => {
        this.validaError.retornoErro(erro);
        this.spinner.hide();
      }
    )
  }

  buscarEmpfil(): void {
    this.spinner.show();
    this.codigoCliente = this.localStorage.ClienteId;
    this.usuarioService.buscarEmpfil(this.codigoCliente).subscribe((data: any[]) => {
      this.listaEmpfil = data;
      this.spinner.hide();
    },
      erro => {
        this.validaError.retornoErro(erro);
        this.spinner.hide();
      });
  }

  buscarTipo(): void {
    this.spinner.show();
    this.usuarioService.buscarTipoUsuario().subscribe(
      (data: any[]) => {
        this.listaTipoUsuario = data;
        this.spinner.hide();
      },
      erro => {
        this.validaError.retornoErro(erro);
        this.spinner.hide();
      });
  }

  buscarServicos(): void {
    this.spinner.show();
    this.usuarioService.buscarServicosPse()
      .subscribe(
        (data: any[]) => {
          this.listaServicos = data;
          this.spinner.hide();
        },
        erro => {
          this.validaError.retornoErro(erro);
          this.spinner.hide();
        }
      );
  }

  selecionarServicos(servico, isChecked: boolean) {
    if (isChecked) {
      this.servicosSelecionados.push(servico);
    } else {
      this.popServicos(servico)
      // const index = this.servicosSelecionados.indexOf(servico);
      // this.servicosSelecionados.splice(index, 1);
    }
    if (this.servicosSelecionados.length > this.listaServicos.length) {
      this.servicosSelecionados.pop();
    }
  }

  popServicos(servico) {
    for (let index = 0; index < this.servicosSelecionados.length; index++) {
      if (this.servicosSelecionados[index].Codigo === servico.Codigo) {
        this.servicosSelecionados.splice(index, 1)
      }
    }
  }
  
  selecionarEmpfil(empfil, isChecked: boolean) {
    if (isChecked) {
      this.empfilSelecionada.push(empfil);
    } else {
      this.popEnpfil(empfil)
      // const index = this.empfilSelecionada.indexOf(empfil);
      // this.empfilSelecionada.splice(index, 1);
    }
    if (this.empfilSelecionada.length > this.listaEmpfil.length) {
      this.empfilSelecionada.pop();
    }
  }

  popEnpfil(empfil) {
    for (let index = 0; index < this.empfilSelecionada.length; index++) {
      if (this.empfilSelecionada[index].Codigo === empfil.Codigo) {
        this.empfilSelecionada.splice(index, 1)
      }
    }
  }

  passartela(valor: string): void {
    if (valor == "passar") {
      this.contadorPag = 2;

      setTimeout(() => {
        this.montarEmfilCheck();
      }, 300);
    }
    else if (valor == "voltar") {
      this.contadorPag = 1
      setTimeout(() => {
        this.montarServCheck();
      }, 300);
    }
    else {
      this.contadorPag;
    }
  }

  salvarModal(id: string): void {
    if (this.servicosSelecionados.length == 0) {
      new AlertaModal('alerta',"Atenção","Nenhum serviço selecionado",'p')
      this.contadorPag = 1;
    }
    else if (this.empfilSelecionada.length == 0) {
      new AlertaModal('alerta',"Atenção","Nenhuma empresa filial selecionad",'p')
      this.contadorPag = 2;
    }
    else {
      new AlertaModal('sucesso',"Sucesso","Operação Realizada Com Sucesso",'p')
      this.fecharModal('closeSubmit');
      this.editando = true;
    }
  }

  fecharModal(id: string): void {
    document.getElementById(id).click();
  }

  somenteLetras(evento: any): any {
    return ((evento.charCode >= 58 && evento.charCode <= 90) || (evento.charCode >= 97 && evento.charCode <= 252) || (evento.charCode === 32));
  }

  somenteNumero(evento): any {
    return ((evento.charCode >= 48 && evento.charCode <= 57));
  }

  mascaraCpf(): string {
    return '000.000.000-00';
  }

  formataCPF(cpf): string {
    if (cpf.length < 11) {
      const resto = 11 - cpf.length;
      cpf = '0'.repeat(resto > 0 ? resto : 0) + cpf;
    }
    cpf = cpf.replace(/[^\d]/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  revelarSenha(): void {
    let input = document.querySelector('#password');
    if (input.getAttribute('type') == 'password') {
      input.setAttribute('type', 'text');
      this.revelarOlho = true;
    } else {
      input.setAttribute('type', 'password');
      this.revelarOlho = false;
    }
  }

  limparCampos(): void {
    this.temErro = false;
    this.formCadastroUsuario.controls['cpf'].setValue("");
    this.formCadastroUsuario.controls['nome'].setValue("");
    this.formCadastroUsuario.controls['tipo'].setValue("");
    this.formCadastroUsuario.controls['senhaTemporaria'].setValue("");
    this.formCadastroUsuario.controls['codigoUnico'].setValue("");
    this.formCadastroUsuario.controls['login'].setValue("");
    this.formCadastroUsuario.controls['email'].setValue("");
    if (this.contadorPag == 1) {
      this.desmarcarTodosServicos();

    }
    else if (this.contadorPag == 2) {
      this.desmarcarTodasEmpfil();
    }
    this.contadorPag = 1;
    this.editando = false;
    this.servicosSelecionados = [];
    this.empfilSelecionada = [];
  }

  limparModal() {
    if (this.contadorPag == 1 && !this.editando) {
      this.desmarcarTodosServicos();
      this.empfilSelecionada = [];
      this.servicosSelecionados = [];
    }
    else if (this.contadorPag == 2 && !this.editando) {
      this.desmarcarTodasEmpfil();
      this.empfilSelecionada = [];
      this.servicosSelecionados = [];
    }

    if (this.servicosSelecionados.length == 0)
      this.contadorPag = 1
  }

  montarServEmpfil(): void {
    for (let i = 0; this.obtemUmUsuario.Servicos.length > i; i++) {
      this.servicosSelecionados.push(this.obtemUmUsuario.Servicos[i])
    }
    this.empfilSelecionada = this.obtemUmUsuario.EmpresasFiliais;
    this.montarServCheck();
  }

  marcarTodosServicos(): void {
    this.listaServicos.forEach(servico => {
      let element = <HTMLInputElement>document.getElementById(String(servico.Codigo));
      if (element.checked == false) {
        element.click();
      }
    })
    this.marcarTodosServicosAux = false;
  }

  desmarcarTodosServicos(): void {
    this.listaServicos.forEach(servico => {
      let element = <HTMLInputElement>document.getElementById(String(servico.Codigo));
      element.checked = false;
    });
    this.servicosSelecionados = [];
    this.marcarTodosServicosAux = true;
  }
  marcarTodasEmpfil(): void {
    this.listaEmpfil.forEach(empfil => {
      let element = <HTMLInputElement>document.getElementById(String(empfil.Codigo));
      if (element.checked == false) {
        element.click();
      }
    });
    this.marcarTodasEmpfilAux = false;
  }

  desmarcarTodasEmpfil(): void {
    this.listaEmpfil.forEach(empfil => {
      let element = <HTMLInputElement>document.getElementById(String(empfil.Codigo));
      element.checked = false;
    });
    this.empfilSelecionada = [];
    this.marcarTodasEmpfilAux = true;
  }

  montarServCheck(): void {
    this.servicosSelecionados.forEach(servico => {
      let element = <HTMLInputElement>document.getElementById(String(servico.Codigo));
      element.checked = true;
    });
  }

  montarEmfilCheck(): any {
    this.empfilSelecionada.forEach(empfil => {
      let element = <HTMLInputElement>document.getElementById(String(empfil.Codigo));
      element.checked = true;
    });
  }

  montarUsuario():  any{
    const usuario = this.formCadastroUsuario.getRawValue();
    return {
      Nome: usuario.nome,
      Cpf: usuario.cpf,
      SenhaTemporaria: usuario.senhaTemporaria,
      Tipo: usuario.tipo,
      Servicos: this.servicosSelecionados,
      EmpresasFiliais: this.empfilSelecionada,
      Senha: usuario.senhaTemporaria,
      Cliente: this.codigoCliente,
      Login: usuario.login,
      Email: usuario.email
    };
  }

  montarUsuarioEditar(): any {
    const usuario = this.formCadastroUsuario.getRawValue();
    return {
      Nome: usuario.nome,
      Cpf: usuario.cpf,
      SenhaTemporaria: usuario.senhaTemporaria,
      Tipo: usuario.tipo,
      Servicos: this.servicosSelecionados,
      EmpresasFiliais: this.empfilSelecionada,
      Senha: usuario.senhaTemporaria,
      Cliente: this.codigoCliente,
      CodigoUnico: usuario.codigoUnico,
      Login: usuario.login,
      Email: usuario.email
    };
  }

  salvarUsuario(): void {
    if (this.formCadastroUsuario.status != 'INVALID') {
      this.spinner.show();
      this.usuarioService.salvarUsuarioPse(this.montarUsuario()).subscribe(
        data => {
          new AlertaModal('sucesso',"Sucesso","Usuário criado com sucesso",'p')
          this.buscarTodosUsuario();
          this.limparCampos();
          this.spinner.hide();
        },
        erro => {
          this.validaError.retornoErro(erro);
          this.spinner.hide();
        });

    } else {
      new AlertaModal('sucesso',"Sucesso","Preencha todos os campos",'p')
      this.temErro = true;
    }
  }

  submitUsuario() {
    if (this.formCadastroUsuario.invalid) {
      new AlertaModal('alerta',"Atenção","Preencha todos os campos obrigatórios!",'p');
      this.temErro = true;
    } else if (this.servicosSelecionados.length == 0 || this.empfilSelecionada.length == 0) {
      this.temErro = true;
      new AlertaModal('alerta',"Atenção",'Preencha todas as configurações!','p');
    } else {
      const usuarioMontado = this.montarUsuarioEditar();
      let existeUsuario = this.listaUsuarios.filter((usuario: any) => usuario.CodigoUnico == this.formCadastroUsuario.controls["codigoUnico"].value).length;
      if (existeUsuario > 0) {
        this.editarUsuario(usuarioMontado);
      }
      else {
        this.salvarUsuario();
      }
    }
  }

  //FORMULARIO;
  deletar(usuario): void {
    Swal({
      title: "Você tem certeza?",
      text: "Esta operação não pode ser desfeita.",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true
        }
      }
    })
      .then((willDelete) => {
        if (willDelete) {
          this.excluirUsuario(usuario);
        }
      });
  }

  excluirUsuario(usuario): void {
    this.spinner.show();
    this.usuarioService.deletarUsuario(usuario.CodigoUnico).subscribe(data => {
      new AlertaModal('sucesso',"Sucesso","Usuário Apagado Com Sucesso",'p')
      this.limparCampos();
      this.spinner.hide();
    }), erro => {
      this.validaError.retornoErro(erro);
      this.spinner.hide();
    }
  }

  editar(usuario: any): void {
    this.limparCampos();
    this.formCadastroUsuario.get('nome').setValue(usuario.Nome);
    this.formCadastroUsuario.get('senhaTemporaria').setValue(usuario.Senha);
    this.formCadastroUsuario.get('tipo').setValue(usuario.Tipo);
    this.formCadastroUsuario.get('cpf').setValue(this.formataCPF(usuario.Cpf));
    this.formCadastroUsuario.get('codigoUnico').setValue(usuario.CodigoUnico);
    this.formCadastroUsuario.get('login').setValue(usuario.Login);
    this.buscarUmUsuario(usuario.CodigoUnico)
    this
    window.scroll(0, 0);
    this.temErro = true;
    this.editando = true;
  }

  editarUsuario(usuario: Usuario): void {
    this.spinner.show();
    this.usuarioService.editarUsuarioPse(usuario).subscribe(
      data => {
        this.buscarTodosUsuario();
        this.limparCampos();
        new AlertaModal('sucesso',"Sucesso","Edição realizada com sucesso",'p')
        this.spinner.hide();
      },
      erro => {
        this.validaError.retornoErro(erro);
        this.spinner.hide();
      }
    )
  }
  onKeyUp(target : any) {
    if(target instanceof EventTarget) {
      var elemento = target as HTMLInputElement;
      this.filter = elemento.value;
    }
  }

  onChange(target : any) {
    if(target instanceof EventTarget) {
      var elemento = target as HTMLInputElement;
      return elemento.checked;
    }
  }

  decryptLocalStorage(): LocalStorage {
    if (localStorage.getItem("util") != null) {
      return JSON.parse(new Crypto().decryptUsingAES256(localStorage.getItem("util")));
    }
  }
  //so para habilitar o cancelar quando tiver pelo menos um campo preenchido corretamente
}

export class Usuario {
  Nome: string;
  Cpf: string
  SenhaTemporaria: string;
  Tipo: string;
  Servicos: any[];
  EmpresasFiliais: any[];
  Cliente: any;
  Senha: any
  Login: string;
}
